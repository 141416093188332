import React from 'react';
import { Link } from 'react-router-dom';
import './user_device_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel, getAsLocalDatetime, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class UserDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      isActiveInput: "true",
      isActiveFilter: "true",
      devices: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getDevices() {
    const parameters = {};

    if(this.state.isActiveFilter.length > 0) {
      parameters.is_enabled = this.state.isActiveFilter;
    }

    return await getModels(setUrlParameters(routes.USER_DEVICES_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        devices: await this.getDevices(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const devices = await this.getDevices();

    this.setState({
      loadingData: false,
      devices: devices
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.USER_DEVICE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'classes':
                    errorDescription = 'Dispositivo possui dados de aulas relacionado. Por favor, ' +
                                       'desabilite este dispositivo para bloquear seu acesso à lousa.';

                    break;
                  default:
                }
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_USER_DEVICE_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DELETE_USER_DEVICE_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_USER_DEVICE_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.USER_DEVICE_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_USER_DEVICE_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getLastAccessText(entry) {
    const dateText = this.getLastAccessFilter(entry);

    return (
      <div className="user_device-list__cell-wrapper--centered">

        <p className={`user_device-list__date-text`}>

          {dateText}

        </p>

      </div>
    );
  }

  getLastAccessFilter(entry) {
    if(!entry.last_access_at) {
      return 'Não acessado';
    }

    const date = getAsLocalDatetime(entry.last_access_at, false);

    const dateFormat = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    let dateText = '';

    if(this.state.screenWidth > 515) {
      dateFormat.weekday = 'short';
    }

    if(this.state.screenWidth > 580) {
      dateFormat.weekday = 'long';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return dateText;
  }

  getStatusText(entry) {
    return (
      <div className="user_device-list__cell-wrapper--centered">

        <p className={`user_device-list__status-text${entry.enabled ? '--enabled' : '--disabled'}`}>

          {entry.enabled ?
            <i className="fas fa-check user_device-list__status-text__icon"></i>:
            <i className="fas fa-times user_device-list__status-text__icon"></i>}
          {entry.enabled ? 'Habilitado' : 'Desabilitado'}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome do dispositivo', <i className="fas fa-tag"></i>),
      Property('user_name', 'Responsável', <i className="fas fa-user"></i>),
      Property('enabled', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: (entry) => entry.enabled ? 'Habilitado' : 'Desabilitado',
      }),
      Property('classes_count', 'Aulas criadas', <i className="fas fa-chalkboard-teacher"></i>),
      Property('last_access_at', 'Último acesso', <i className="fas fa-clock"></i>, {
        getDataText: (entry) => this.getLastAccessText(entry),
        getFilterText: (entry) => this.getLastAccessFilter(entry),
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar dispositivo';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Habilitado'),
      SelectOption('false', 'Desabilitado'),
      SelectOption('', 'Todos'),
    ];
  }

  mayRefreshList() {
    if(this.state.isActiveInput !== this.state.isActiveFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.setState({
        isActiveFilter: this.state.isActiveInput
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados ao dispositivo serão removidos.'}
          confirmText="Deletar dispositivo"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.USER_DEVICE_LIST_PATH,
              text: "Listar dispositivos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar dispositivos"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="user_device-list"
            title="Lista de dispositivos de usuários"
          >

            <div className="user_device-list__filters">

              <header className="user_device-list__filters__header">

                <h4 className="user_device-list__filters__header__text">Filtros</h4>

              </header>

              <div className="user_device-list__filters__inputs">

                <div className="user_device-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveInput || ''}
                    options={this.getActiveOptions()}
                  />

                </div>

                <button
                  className="user_device-list__filters__refresh-button"
                  onClick={() => this.refreshList()}
                  disabled={!this.mayRefreshList()}
                >

                  <i className="fas fa-sync"></i>

                </button>

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.devices}
              initialOrderBy="last_access_at"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default UserDeviceList;
