import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel} from '../../utils/functions';
import AppointmentData from './appointment_data';


class AppointmentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment: {
        user: null,
        appointment_type: null,
        attended: null,
        date: '',
        scheduled_at: '',
        finish_at: ''
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let update = {
      loading: false
    };

    let appointment = getModel(`${routes.APPOINTMENT_GET_API}${this.props.match.params.appointmentId}`);

    appointment = await appointment;

    if(appointment) {
      update.appointment = {...this.state.appointment, ...appointment};

      if (update.appointment.attended === null) {
        update.appointment.attended = '';
      }
      else if (update.appointment.attended) {
        update.appointment.attended = 'true';
      }
      else {
        update.appointment.attended = 'false';
      }
    }
    else {
      this.props.history.replace(routes.APPOINTMENT_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.appointment, [name]: value};

    this.setState({
      appointment: newData
    });
  }

  inputsAreValid() {
    return true;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {};

    if (this.state.appointment.attended.length <= 0) {
      data.attended = null;
    }
    else if (this.state.appointment.attended === 'true') {
      data.attended = true;
    }
    else {
      data.attended = false;
    }

    try {
      await patchModel(`${routes.APPOINTMENT_PATCH_API}${this.props.match.params.appointmentId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.APPOINTMENT_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.APPOINTMENT_LIST_PATH,
            text: "Listar atendimentos"
          },
          {
            path: `${routes.APPOINTMENT_EDIT_PATH}${this.props.match.params.appointmentId}`,
            text: "Editar atendimento"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar atendimento"
        loading={this.state.loading}
      >

        <AppointmentData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          appointment={this.state.appointment}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.APPOINTMENT_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default AppointmentEdit;
