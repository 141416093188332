import React from 'react';
import ConfirmationWindow from '../admin/confirmation_window';
import './image_picker.scss';

class ImagePicker extends React.Component {
  IMAGE_FILE_TYPES = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];

  constructor(props) {
    super(props);
    this.state = {
      cameraWindowVisible: false,
      mayCaptureCameraImage: false,
      imageSelected: false,
      imageLoadedFromFile: false,
      videoDeviceIdOptions: [],
      currentVideoDeviceId: null,
      errorTitle: null, 
      errorMessage: null, 
      videoIsLoading: false,
      loading: false,
    };

    this.containerElement = null;
    this.cameraVideo = null;
    this.videoMask = null;
    this.controlFrame = null;
    this.canvasElement = null;
    this.selectedImageElement = null;
    this.cameraMediaStream = null;

    this.fileInput = null;

    this.lastContainerWidth = null;
    this.lastContainerHeight = null;

    this.beginFrameGrab = true;
    this.beginTopLeftFrameGrab = true;
    this.beginTopRightFrameGrab = true;
    this.beginBottomLeftFrameGrab = true;
    this.beginBottomRightFrameGrab = true;

    this.currentMouseXPosition = 0;
    this.currentMouseYPosition = 0;

    this.touchIdentifierReference = null;

    this.mouseXPositionReference = 0;
    this.mouseYPositionReference = 0;
    
    this.frameXPosition = null;
    this.frameYPosition = null;
    this.frameWidth = null;
    this.frameHeight = null;

    this.currentImageWidth = null;
    this.currentImageHeight = null;

    this.firstVideoLoaded = false;

    this.cameraWindowRef = (element) => {
      if (element == null) {
        this.cameraVideo = null;
      }
      else {
        const containerSelection = element.getElementsByClassName('image-content-wrapper');
        const cameraVideoSelection = element.getElementsByClassName('image-picker__selection-window__video');
        const videoMaskSelection = element.getElementsByClassName('image-picker__selection-window__mask-overlay');
        const controlFrameSelection = element.getElementsByClassName('image-selection-control-frame');
        const canvasSelection = element.getElementsByClassName('image-picker__selection-window__canvas');
        const selectedImageSelection = element.getElementsByClassName('image-picker__selection-window__selected-image');

        if (containerSelection.length > 0) {
          this.containerElement = containerSelection[0];
        }
        else {
          this.containerElement = null;
        }

        if (videoMaskSelection.length > 0) {
          this.videoMask = videoMaskSelection[0];
        }
        else {
          this.videoMask = null;
        }

        if (controlFrameSelection.length > 0) {
          this.controlFrame = controlFrameSelection[0];
        }
        else {
          this.controlFrame = null;
        }

        if (canvasSelection.length > 0) {
          this.canvasElement = canvasSelection[0];
        }
        else {
          this.canvasElement = null;
        }

        if (selectedImageSelection.length > 0) {
          this.selectedImageElement = selectedImageSelection[0];
        }
        else {
          this.selectedImageElement = null;
        }

        if (cameraVideoSelection.length > 0) {
          this.cameraVideo = cameraVideoSelection[0];
        }
        else {
          this.cameraVideo = null;
        }
      }
    };

    this.fileInputRef = (element) => {
      if (element == null) {
        this.fileInput = null;
      }
      else {
        this.fileInput = element;

        this.fileInput.addEventListener("change", () => this.onImageFileInputChanged());
      }
    };
  }

  async componentDidMount() {
    this.mouseMoveListener = (event) => this.updateCursorPosition(event);
    window.addEventListener("mousemove", this.mouseMoveListener);

    this.touchMoveListener = (event) => this.updateCursorPosition(event);
    window.addEventListener("touchmove", this.touchMoveListener);
    
    this.mouseUpListener = () => this.onReleaseFrameGrab();
    window.addEventListener("mouseup", this.mouseUpListener);
    
    this.touchEndListener = () => this.onReleaseFrameGrab();
    window.addEventListener("touchend", this.mouseUpListener);

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.mouseMoveListener);
    window.removeEventListener("touchmove", this.touchMoveListener);
    window.removeEventListener("pointerup", this.mouseUpListener);
    window.removeEventListener("touchend", this.touchEndListener);
    window.removeEventListener("resize", this.resizeListener);
  }

  updateCursorPosition(event) {
    if (event.clientX && event.clientY) {
      this.currentMouseXPosition = event.clientX;
      this.currentMouseYPosition = event.clientY;
    }
    else if (this.touchIdentifierReference !== null && event.changedTouches) {
      let touch = null;

      for (const entry of event.changedTouches) {
        if (entry.identifier === this.touchIdentifierReference) {
          touch = entry;
          break;
        }
      }
      
      if (touch === null) {
        return;
      }

      this.currentMouseXPosition = touch.clientX;
      this.currentMouseYPosition = touch.clientY;
    }
    else {
      return;
    }

    if (this.beginFrameGrab ||
      this.beginTopLeftFrameGrab ||
      this.beginTopRightFrameGrab ||
      this.beginBottomLeftFrameGrab ||
      this.beginBottomRightFrameGrab) {
      this.updateImageSelection();
    }
  }

  onReleaseFrameGrab() {
    this.beginFrameGrab = false;
    this.beginTopLeftFrameGrab = false;
    this.beginTopRightFrameGrab = false;
    this.beginBottomLeftFrameGrab = false;
    this.beginBottomRightFrameGrab = false;

    this.touchIdentifierReference = null;

    if (this.controlFrame !== null) {
      this.controlFrame.style.removeProperty("cursor");
      this.controlFrame.classList.toggle("image-picker__selection-window__control-frame", true);
      this.controlFrame.classList.toggle("image-picker__selection-window__control-frame--dragging", false);
    }
  }

  updateSize() {
    if (this.cameraVideo === null || this.canvasElement === null || this.containerElement === null) {
      return;
    }

    const maxWidth = document.documentElement.clientWidth - 10;
    const maxHeight = document.documentElement.clientHeight - 100;

    let finalWidth;
    let finalHeight;

    if (this.state.imageSelected) {
      if (this.currentImageWidth !== null && this.currentImageWidth !== null) {
        finalWidth = Math.min(1.1 * this.currentImageWidth, maxWidth);
        finalHeight = Math.min(1.1 * this.currentImageHeight, maxHeight);

        const originalAspectRation = this.currentImageWidth / this.currentImageHeight;
        const finalAspectRatio = finalWidth / finalHeight;

        if (originalAspectRation <= finalAspectRatio) {
          finalWidth = finalHeight * originalAspectRation;
        }
        else {
          finalHeight = finalWidth / originalAspectRation;
        }
      }
      else {
        finalWidth = maxWidth;
        finalHeight = maxHeight;
      }

      this.selectedImageElement.style.setProperty("max-width", `${maxWidth - 10}px`);
      this.selectedImageElement.style.setProperty("max-height", `${maxHeight - 10}px`);

      this.frameXPosition = null;
      this.frameYPosition = null; 
      this.frameWidth = null;
      this.frameHeight = null;
    }
    else {
      let canvasWidth;
      let canvasHeight;

      if (isNaN(this.cameraVideo.videoHeight)) {
        const size = Math.min(0.5 * maxWidth, 0.5 * maxHeight);
  
        finalWidth = size;
        finalHeight = size;
        canvasWidth = size;
        canvasHeight = size;
      }
      else {
        const screenAspectRatio = maxWidth / maxHeight;
        const videoAspectRatio = this.cameraVideo.videoWidth / this.cameraVideo.videoHeight;

        canvasWidth = this.cameraVideo.videoWidth;
        canvasHeight = this.cameraVideo.videoHeight;
  
        if (screenAspectRatio >= videoAspectRatio) {
          finalHeight = Math.min(maxHeight, this.cameraVideo.videoHeight);
          finalWidth = this.cameraVideo.videoWidth * (finalHeight / this.cameraVideo.videoHeight);
        }
        else {
          finalWidth = Math.min(maxWidth, this.cameraVideo.videoWidth);
          finalHeight = this.cameraVideo.videoHeight * (finalWidth / this.cameraVideo.videoWidth);
        }
      }
  
      // if (this.props.maxHeight) {
      //   if (finalHeight > this.props.maxHeight) {
      //     finalWidth = finalWidth * (this.props.maxHeight / finalHeight);
      //     finalHeight = this.props.maxHeight;
      //   }
      //   if (canvasHeight > this.props.maxHeight) {
      //     canvasWidth = canvasWidth * (this.props.maxHeight / canvasHeight);
      //     canvasHeight = this.props.maxHeight;
      //   }
      // }
      // if (this.props.maxWidth) {
      //   if (finalWidth > this.props.maxWidth) {
      //     finalHeight = finalHeight * (this.props.maxWidth / finalWidth);
      //     finalWidth = this.props.maxWidth;
      //   }
      //   if (canvasWidth > this.props.maxWidth) {
      //     canvasHeight = canvasHeight * (this.props.maxWidth / canvasWidth);
      //     canvasWidth = this.props.maxWidth;
      //   }
      // }

      this.cameraVideo.setAttribute("width", finalWidth);
      this.cameraVideo.setAttribute("height", finalHeight);
  
      this.canvasElement.setAttribute("height", canvasHeight);
      this.canvasElement.setAttribute("width", canvasWidth);
    }

    this.containerElement.style.setProperty("height", `${finalHeight}px`);
    this.containerElement.style.setProperty("width", `${finalWidth}px`);

    this.lastContainerWidth = finalWidth;
    this.lastContainerHeight = finalHeight;
  }

  updateImageSelection() {
    if (!this.state.imageSelected) {
      return;
    }

    const imageAspectRatio = this.selectedImageElement.clientWidth / this.selectedImageElement.clientHeight;

    const containerRect = this.containerElement.getBoundingClientRect();
    const imageRect = this.selectedImageElement.getBoundingClientRect();
    const frameRect = this.controlFrame.getBoundingClientRect();

    let minFrameSize;
    
    if (this.props.aspectRatio) {
      if (imageAspectRatio >= this.props.aspectRatio) {
        minFrameSize = 0.7 * imageRect.height * this.props.aspectRatio;
      }
      else {
        minFrameSize = 0.7 * imageRect.width / this.props.aspectRatio;
      }
    }
    else {
      minFrameSize = 0.7 * Math.min(imageRect.width, imageRect.height);
    }
    
    if ((this.frameXPosition === null || this.frameYPosition === null || this.frameWidth === null || this.frameHeight === null) &&
        !this.beginFrameGrab &&
        !this.beginTopLeftFrameGrab &&
        !this.beginTopRightFrameGrab &&
        !this.beginBottomLeftFrameGrab &&
        !this.beginBottomRightFrameGrab) {
      const frameYPosition = (imageRect.top - containerRect.top) + (0.5 * imageRect.height) - (0.5 * frameRect.height);
      const frameXPosition = (imageRect.left - containerRect.left) + (0.5 * imageRect.width) - (0.5 * frameRect.width);  
      
      let frameWidth;
      let frameHeight;

      if (this.props.aspectRatio) {
        if (imageAspectRatio >= this.props.aspectRatio) {
          frameHeight = this.selectedImageElement.clientHeight;
          frameWidth = frameHeight * this.props.aspectRatio;
        }
        else {
          frameWidth = this.selectedImageElement.clientWidth;
          frameHeight = frameWidth / this.props.aspectRatio;
        }
      }
      else {
        frameWidth = this.selectedImageElement.clientWidth;
        frameHeight = this.selectedImageElement.clientHeight;
      }
      
      let maskProperty;

      if (this.props.userCircularMask) {
        maskProperty = `radial-gradient(${0.494*frameWidth}px ${0.494*frameHeight}px at ${frameXPosition + 0.5*frameRect.width}px ${frameYPosition + 0.5*frameRect.height}px, transparent 100%, black 100%)`;  
      }
      else {
        maskProperty = `linear-gradient(to right, black, black ${frameXPosition}px, transparent ${frameXPosition}px, transparent ${frameXPosition + frameRect.width}px, black ${frameXPosition + frameRect.width}px), linear-gradient(to bottom, black, black ${frameYPosition}px, transparent ${frameYPosition}px, transparent ${frameYPosition + frameRect.height}px, black ${frameYPosition + frameRect.height}px)`;
      }
  
      this.videoMask.style.setProperty("-webkit-mask-image", maskProperty);
      this.videoMask.style.setProperty("mask-image", maskProperty);
  
      this.controlFrame.style.setProperty("width", `${frameWidth}px`);
      this.controlFrame.style.setProperty("height", `${frameHeight}px`);
  
      this.controlFrame.style.setProperty("left", `${frameXPosition}px`);
      this.controlFrame.style.setProperty("top", `${frameYPosition}px`);
    }
    else {
      if (this.frameXPosition === null || this.frameYPosition === null || this.frameWidth === null || this.frameHeight === null) {
        this.frameYPosition = (imageRect.top - containerRect.top) + (0.5 * imageRect.height) - (0.5 * frameRect.height);
        this.frameXPosition = (imageRect.left - containerRect.left) + (0.5 * imageRect.width) - (0.5 * frameRect.width);
        
        if (this.props.aspectRatio) {
          if (imageAspectRatio >= this.props.aspectRatio) {
            this.frameHeight = this.selectedImageElement.clientHeight;
            this.frameWidth = this.frameHeight * this.props.aspectRatio;
          }
          else {
            this.frameWidth = this.selectedImageElement.clientWidth;
            this.frameHeight = this.frameWidth / this.props.aspectRatio;
          }
        }
        else {
          this.frameWidth = this.selectedImageElement.clientWidth;
          this.frameHeight = this.selectedImageElement.clientHeight;
        }
      }      
      
      if (this.beginFrameGrab) {
        this.frameXPosition += this.currentMouseXPosition - this.mouseXPositionReference;
        this.frameYPosition += this.currentMouseYPosition - this.mouseYPositionReference;
  
        this.mouseXPositionReference = this.currentMouseXPosition;
        this.mouseYPositionReference = this.currentMouseYPosition;
  
        if (this.frameXPosition > (imageRect.left - containerRect.left + imageRect.width - frameRect.width)) {
          this.frameXPosition = imageRect.left - containerRect.left + imageRect.width - frameRect.width;
        }
        if (this.frameXPosition < (imageRect.left - containerRect.left)) {
          this.frameXPosition = imageRect.left - containerRect.left;
        }
    
        if (this.frameYPosition > (imageRect.top - containerRect.top + imageRect.height - frameRect.height)) {
          this.frameYPosition = imageRect.top - containerRect.top + imageRect.height - frameRect.height;
        }
        if (this.frameYPosition < (imageRect.top - containerRect.top)) {
          this.frameYPosition = imageRect.top - containerRect.top;
        }
      }
      if (this.beginTopLeftFrameGrab) {
        const xOffset = this.currentMouseXPosition - this.mouseXPositionReference;
        const yOffset = this.currentMouseYPosition - this.mouseYPositionReference;
        
        if (this.props.aspectRatio) {
          let sizeOffset = Math.max(Math.abs(xOffset), Math.abs(yOffset));
  
          if ((xOffset*Math.cos(45*Math.PI/180) + yOffset*Math.sin(45*Math.PI/180)) < 0) {
            sizeOffset = -1 * sizeOffset;
          }

          if (this.props.aspectRatio >= 1) {
            this.frameWidth -= sizeOffset;
            this.frameHeight = this.frameWidth / this.props.aspectRatio;

            this.frameXPosition += sizeOffset;
            this.frameYPosition += sizeOffset / this.props.aspectRatio;
          }
          else {
            this.frameHeight -= sizeOffset;
            this.frameWidth = this.frameHeight * this.props.aspectRatio;

            this.frameYPosition += sizeOffset;
            this.frameXPosition += sizeOffset * this.props.aspectRatio;
          }
        }
        else {
          this.frameXPosition += xOffset;
          this.frameYPosition += yOffset;

          this.frameWidth -= xOffset;
          this.frameHeight -= yOffset;
        }

        if (this.frameXPosition < (imageRect.left - containerRect.left)) {
          const positionDiff = (imageRect.left - containerRect.left) - this.frameXPosition;

          this.frameXPosition = imageRect.left - containerRect.left;
          this.frameWidth -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameHeight -= positionDiff / this.props.aspectRatio;
            this.frameYPosition += positionDiff / this.props.aspectRatio;
          }
        }        
        if (this.frameYPosition < (imageRect.top - containerRect.top)) {
          const positionDiff = (imageRect.top - containerRect.top) - this.frameYPosition;

          this.frameYPosition = imageRect.top - containerRect.top;
          this.frameHeight -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameWidth -= positionDiff * this.props.aspectRatio;
            this.frameXPosition += positionDiff * this.props.aspectRatio;
          }
        }
        if (this.frameWidth < minFrameSize) {
          const positionDiff = minFrameSize - this.frameWidth;

          this.frameWidth = minFrameSize;
          this.frameXPosition -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameHeight = minFrameSize / this.props.aspectRatio;
            this.frameYPosition -= positionDiff / this.props.aspectRatio;
          }
        }
        if (this.frameHeight < minFrameSize) {
          const positionDiff = minFrameSize - this.frameHeight;

          this.frameHeight = minFrameSize;
          this.frameYPosition -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameWidth = minFrameSize * this.props.aspectRatio;
            this.frameXPosition -= positionDiff * this.props.aspectRatio;
          }
        }
  
        this.mouseXPositionReference = this.currentMouseXPosition;
        this.mouseYPositionReference = this.currentMouseYPosition;
      }
      if (this.beginTopRightFrameGrab) {
        const xOffset = this.currentMouseXPosition - this.mouseXPositionReference;
        const yOffset = this.currentMouseYPosition - this.mouseYPositionReference;

        if (this.props.aspectRatio) {
          let sizeOffset = Math.max(Math.abs(xOffset), Math.abs(yOffset));
  
          if ((yOffset*Math.sin(45*Math.PI/180) - xOffset*Math.cos(45*Math.PI/180)) < 0) {
            sizeOffset = -1 * sizeOffset;
          }

          if (this.props.aspectRatio >= 1) {
            this.frameWidth -= sizeOffset;
            this.frameHeight = this.frameWidth / this.props.aspectRatio;
            
            this.frameYPosition += sizeOffset / this.props.aspectRatio;
          }
          else {
            this.frameHeight -= sizeOffset;
            this.frameWidth = this.frameHeight * this.props.aspectRatio;

            this.frameYPosition += sizeOffset;
          }
        }
        else {
          this.frameYPosition += yOffset;

          this.frameWidth += xOffset;
          this.frameHeight -= yOffset;
        }
        
        if ((this.frameXPosition + this.frameWidth) > (imageRect.left - containerRect.left + imageRect.width)) {
          const positionDiff = this.frameWidth - (imageRect.left - containerRect.left + imageRect.width - this.frameXPosition);

          this.frameWidth = imageRect.left - containerRect.left + imageRect.width - this.frameXPosition;
          
          if (this.props.aspectRatio) {
            this.frameHeight -= positionDiff / this.props.aspectRatio;
            this.frameYPosition += positionDiff / this.props.aspectRatio;
          }
        }    
        if (this.frameYPosition < (imageRect.top - containerRect.top)) {
          const positionDiff = (imageRect.top - containerRect.top) - this.frameYPosition;

          this.frameYPosition = imageRect.top - containerRect.top;
          this.frameHeight -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameWidth -= positionDiff * this.props.aspectRatio;
          }
        }
        if (this.frameWidth < minFrameSize) {
          const positionDiff = minFrameSize - this.frameWidth;

          this.frameWidth = minFrameSize;

          if (this.props.aspectRatio) {
            this.frameHeight = minFrameSize / this.props.aspectRatio;
            this.frameYPosition -= positionDiff / this.props.aspectRatio;
          }
        }
        if (this.frameHeight < minFrameSize) {
          const positionDiff = minFrameSize - this.frameHeight;

          this.frameHeight = minFrameSize;
          this.frameYPosition -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameWidth = minFrameSize * this.props.aspectRatio;
          }
        }
  
        this.mouseXPositionReference = this.currentMouseXPosition;
        this.mouseYPositionReference = this.currentMouseYPosition;
      }
      if (this.beginBottomLeftFrameGrab) {
        const xOffset = this.currentMouseXPosition - this.mouseXPositionReference;
        const yOffset = this.currentMouseYPosition - this.mouseYPositionReference;

        if (this.props.aspectRatio) {
          let sizeOffset = Math.max(Math.abs(xOffset), Math.abs(yOffset));
  
          if ((yOffset*Math.sin(45*Math.PI/180) - xOffset*Math.cos(45*Math.PI/180)) > 0) {
            sizeOffset = -1 * sizeOffset;
          }

          if (this.props.aspectRatio >= 1) {
            this.frameWidth -= sizeOffset;
            this.frameHeight = this.frameWidth / this.props.aspectRatio;

            this.frameXPosition += sizeOffset;
          }
          else {
            this.frameHeight -= sizeOffset;
            this.frameWidth = this.frameHeight * this.props.aspectRatio;
            
            this.frameXPosition += sizeOffset * this.props.aspectRatio;
          }
        }
        else {
          this.frameXPosition += xOffset;

          this.frameWidth -= xOffset;
          this.frameHeight += yOffset;
        }

        if (this.frameXPosition < (imageRect.left - containerRect.left)) {
          const positionDiff = (imageRect.left - containerRect.left) - this.frameXPosition;

          this.frameXPosition = imageRect.left - containerRect.left;
          this.frameWidth -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameHeight -= positionDiff / this.props.aspectRatio;
          }
        } 
        if ((this.frameYPosition + this.frameHeight) > (imageRect.top - containerRect.top + imageRect.height)) {
          const positionDiff = this.frameHeight - (imageRect.top - containerRect.top + imageRect.height - this.frameYPosition);

          this.frameHeight = imageRect.top - containerRect.top + imageRect.height - this.frameYPosition;

          if (this.props.aspectRatio) {
            this.frameWidth -= positionDiff * this.props.aspectRatio;
            this.frameXPosition += positionDiff * this.props.aspectRatio;
          }
        }
        if (this.frameWidth < minFrameSize) {
          const positionDiff = minFrameSize - this.frameWidth;

          this.frameWidth = minFrameSize;
          this.frameXPosition -= positionDiff;

          if (this.props.aspectRatio) {
            this.frameHeight = minFrameSize / this.props.aspectRatio;
          }
        }
        if (this.frameHeight < minFrameSize) {
          const positionDiff = minFrameSize - this.frameHeight;

          this.frameHeight = minFrameSize;

          if (this.props.aspectRatio) {
            this.frameWidth = minFrameSize * this.props.aspectRatio;
            this.frameXPosition -= positionDiff * this.props.aspectRatio;
          }
        }
  
        this.mouseXPositionReference = this.currentMouseXPosition;
        this.mouseYPositionReference = this.currentMouseYPosition;
      }
      if (this.beginBottomRightFrameGrab) {
        const xOffset = this.currentMouseXPosition - this.mouseXPositionReference;
        const yOffset = this.currentMouseYPosition - this.mouseYPositionReference;

        if (this.props.aspectRatio) {
          let sizeOffset = Math.max(Math.abs(xOffset), Math.abs(yOffset));
  
          if ((xOffset*Math.cos(45*Math.PI/180) + yOffset*Math.sin(45*Math.PI/180)) > 0) {
            sizeOffset = -1 * sizeOffset;
          }

          if (this.props.aspectRatio >= 1) {
            this.frameWidth -= sizeOffset;
            this.frameHeight = this.frameWidth / this.props.aspectRatio;
          }
          else {
            this.frameHeight -= sizeOffset;
            this.frameWidth = this.frameHeight * this.props.aspectRatio;
          }
        }
        else {
          this.frameWidth += xOffset;
          this.frameHeight += yOffset;
        }

        if ((this.frameYPosition + this.frameHeight) > (imageRect.top - containerRect.top + imageRect.height)) {
          this.frameHeight = imageRect.top - containerRect.top + imageRect.height - this.frameYPosition;

          if (this.props.aspectRatio) {
            this.frameWidth = this.frameHeight * this.props.aspectRatio;
          }
        }
        if ((this.frameXPosition + this.frameWidth) > (imageRect.left - containerRect.left + imageRect.width)) {
          this.frameWidth = imageRect.left - containerRect.left + imageRect.width - this.frameXPosition;

          if (this.props.aspectRatio) {
            this.frameHeight = this.frameWidth / this.props.aspectRatio;
          }
        }
        if (this.frameWidth < minFrameSize) {
          this.frameWidth = minFrameSize;

          if (this.props.aspectRatio) {
            this.frameHeight = minFrameSize / this.props.aspectRatio;
          }
        }
        if (this.frameHeight < minFrameSize) {
          this.frameHeight = minFrameSize;

          if (this.props.aspectRatio) {
            this.frameWidth = minFrameSize * this.props.aspectRatio;
          }
        }
  
        this.mouseXPositionReference = this.currentMouseXPosition;
        this.mouseYPositionReference = this.currentMouseYPosition;
      }
  
      let maskProperty;

      if (this.props.userCircularMask) {
        maskProperty = `radial-gradient(${0.494*this.frameWidth}px ${0.494*this.frameHeight}px at ${this.frameXPosition + 0.5*frameRect.width}px ${this.frameYPosition + 0.5*frameRect.height}px, transparent 100%, black 100%)`;
      }
      else {
        maskProperty = `linear-gradient(to right, black, black ${this.frameXPosition}px, transparent ${this.frameXPosition}px, transparent ${this.frameXPosition + frameRect.width}px, black ${this.frameXPosition + frameRect.width}px), linear-gradient(to bottom, black, black ${this.frameYPosition}px, transparent ${this.frameYPosition}px, transparent ${this.frameYPosition + frameRect.height}px, black ${this.frameYPosition + frameRect.height}px)`;
      }
  
      this.videoMask.style.setProperty("-webkit-mask-image", maskProperty);
      this.videoMask.style.setProperty("mask-image", maskProperty);
  
      this.controlFrame.style.setProperty("width", `${this.frameWidth}px`);
      this.controlFrame.style.setProperty("height", `${this.frameHeight}px`);
  
      this.controlFrame.style.setProperty("left", `${this.frameXPosition}px`);
      this.controlFrame.style.setProperty("top", `${this.frameYPosition}px`);
    }

    requestAnimationFrame(this.updateImageSelection.bind(this));
  }

  // clearphoto() {
  //   if (this.canvasElement === null) {
  //     return;
  //   }

  //   const context = this.canvasElement.getContext("2d");
  //   context.fillStyle = "#AAA";
  //   context.fillRect(0, 0, this.canvasElement.width, this.canvasElement.height);

  //   const image = this.canvasElement.toDataURL("image/png");
  //   photo.setAttribute("src", data);
  // }

  takepicture() {
    if (this.canvasElement === null || this.cameraVideo === null) {
      return;
    }

    const context = this.canvasElement.getContext("2d");
    
    context.drawImage(this.cameraVideo, 0, 0, this.canvasElement.width, this.canvasElement.height);

    const image = this.canvasElement.toDataURL("image/png");
    this.selectedImageElement.setAttribute("src", image);

    this.currentImageWidth = this.canvasElement.width;
    this.currentImageHeight = this.canvasElement.height;

    this.selectedImageElement.style.setProperty("display", "block");
    this.cameraVideo.style.setProperty("display", "none");

    this.onTurnCameraOff();

    this.setState({imageSelected: true});

    requestAnimationFrame(() => {
      this.updateSize();
      this.updateImageSelection();
    });
  }

  onOpenCameraWindow() {
    this.setState({cameraWindowVisible: true});

    this.onTurnCameraOn();
  }

  async requestVideoDeviceOptions() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDeviceIdOptions = [];

      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          videoDeviceIdOptions.push(device.deviceId);
        }
      });

      this.setState({videoDeviceIdOptions});
    })
    .catch((error) => {
      this.setState({
        errorTitle: error.name,
        errorMessage: error.message,
        videoIsLoading: false,
        cameraWindowVisible: false
      });
    });
  }

  onSwitchToNextCamera() {
    if (this.state.videoDeviceIdOptions.length > 1) {
      const currentIndex = this.state.videoDeviceIdOptions.indexOf(this.state.currentVideoDeviceId);

      let nextIndex;

      if (currentIndex < this.state.videoDeviceIdOptions.length - 1) {
        nextIndex = currentIndex + 1;
      }
      else {
        nextIndex = 0;
      }

      this.onTurnCameraOn(this.state.videoDeviceIdOptions[nextIndex]);
    }
  }

  onTurnCameraOff() {
    this.setState({mayCaptureCameraImage: false});

    if (this.cameraMediaStream !== null) {
      for (const track of this.cameraMediaStream.getTracks()) {
        track.stop();
      }
    }
  }

  onTurnCameraOn(deviceId=null) {
    this.setState({
      videoIsLoading: true,
      imageSelected: false
    });

    if (this.firstVideoLoaded) {
      this.cameraVideo.style.setProperty("display", "none")
    }

    this.onTurnCameraOff();

    this.selectedImageElement.style.setProperty("display", "none");
    this.currentImageWidth = null;
    this.currentImageHeight = null;

    if (this.videoMask !== null) {
      this.videoMask.style.removeProperty("display", "block");
    }

    const parameters = { video: true, audio: false };

    if (deviceId !== null) {
      parameters.video = {deviceId};
    }

    navigator.mediaDevices.getUserMedia(parameters).then((stream) => {
      this.cameraMediaStream = stream;
      this.cameraVideo.srcObject = stream;
      this.cameraVideo.play();   
      
      this.firstVideoLoaded = true;

      this.setState({currentVideoDeviceId: stream.getVideoTracks()[0].getSettings().deviceId});

      this.cameraVideo.addEventListener(
        "canplay",
        (event) => {
          this.updateSize();

          this.cameraVideo.style.setProperty("display", "block");

          // this.clearphoto();
          this.frameXPosition = null;
          this.frameYPosition = null;
          this.frameWidth = null;
          this.frameHeight = null;

          if (this.videoMask !== null) {
            this.videoMask.style.setProperty("display", "block");

            if (this.props.userCircularMask) {
              this.videoMask.style.removeProperty("-webkit-mask-image");
              this.videoMask.style.removeProperty("mask-image");
            }
            else {
              this.videoMask.style.setProperty("-webkit-mask-image", 'linear-gradient(to right, transparent, transparent)');
              this.videoMask.style.setProperty("mask-image", 'linear-gradient(to right, transparent, transparent)');              
            }
          }
          
          this.setState({
            mayCaptureCameraImage: true,
            videoIsLoading: false
          });
        },
        false,
      );

      if (this.state.videoDeviceIdOptions.length <= 0) {
        this.requestVideoDeviceOptions();
      }
    })
    .catch((error) => {
      if (error.name === "NotAllowedError") {
        this.setState({
          errorTitle: "Falha ao acessar câmera",
          errorMessage: "Permissão para acesso à câmera negada. Por favor, habilita esta permissão em seu navegador e tente novamente.",
          videoIsLoading: false,
          cameraWindowVisible: false
        });
      }
      else {
        this.setState({
          errorTitle: `Falha ao acessar câmera: ${error.name}`,
          errorMessage: error.message,
          videoIsLoading: false,
          cameraWindowVisible: false
        });
      }
    });
  }

  onCloseSelectionWindow() {
    if (!this.state.imageLoadedFromFile) {
      this.onTurnCameraOff();
    }
    else {
      this.fileInput.value = '';
    }

    this.setState({
      cameraWindowVisible: false,
      imageSelected: false,
      imageLoadedFromFile: false
    });
  }

  onBeginGrab(event) {
    if (event.clientX && event.clientY) {
      this.mouseXPositionReference = event.clientX;
      this.mouseYPositionReference = event.clientY;

      if (event.preventDefault) {
        event.preventDefault();
      }
    }
    else if (this.touchIdentifierReference === null && event.changedTouches) {
      this.touchIdentifierReference = event.changedTouches[0].identifier;

      this.mouseXPositionReference = event.changedTouches[0].clientX;
      this.mouseYPositionReference = event.changedTouches[0].clientY;
    }

    this.currentMouseXPosition = this.mouseXPositionReference;
    this.currentMouseYPosition = this.mouseYPositionReference;

    this.controlFrame.classList.toggle("image-picker__selection-window__control-frame--dragging", true);
    this.controlFrame.classList.toggle("image-picker__selection-window__control-frame", false);

    event.stopPropagation();
  }

  onBeginFrameGrab(event) {
    this.onBeginGrab(event);

    this.beginFrameGrab = true;
    this.controlFrame.style.setProperty("cursor", "grabbing");
  }

  onBeginTopLeftFrameGrab(event) {
    this.onBeginGrab(event);

    this.beginTopLeftFrameGrab = true;
  }

  onBeginTopRightFrameGrab(event) {
    this.onBeginGrab(event);

    this.beginTopRightFrameGrab = true;
  }

  onBeginBottomLeftFrameGrab(event) {
    this.onBeginGrab(event);

    this.beginBottomLeftFrameGrab = true;
  }

  onBeginBottomRightFrameGrab(event) {
    this.onBeginGrab(event);

    this.beginBottomRightFrameGrab = true;
  }

  onSelectImageFile() {
    if (this.fileInput !== null) {
      this.fileInput.click();
    }
  }

  onImageFileInputChanged() {
    if (this.fileInput !== null && this.fileInput.files.length > 0) {
      const selectedFile = this.fileInput.files[0];

      if (this.IMAGE_FILE_TYPES.includes(selectedFile.type)) {    
        const image = URL.createObjectURL(selectedFile);

        this.selectedImageElement.onload = () => {      
          this.selectedImageElement.style.setProperty("display", "block");
          this.cameraVideo.style.setProperty("display", "none");
          this.videoMask.style.setProperty("display", "block");
      
          this.setState({
            imageSelected: true,
            imageLoadedFromFile: true,
            videoIsLoading: false
          });
      
          requestAnimationFrame(() => {
            this.currentImageWidth = this.selectedImageElement.clientWidth;
            this.currentImageHeight = this.selectedImageElement.clientHeight;

            this.updateSize();
            this.updateImageSelection();
          });
        };

        this.selectedImageElement.setAttribute("src", image);        
      }
    }
  }

  async onFinishPictureSelection() {
    if (!this.props.onPictureSelected) {
      return;
    }

    this.setState({loading: true});
    
    let frameYPosition = this.frameYPosition;
    let frameXPosition = this.frameXPosition;
    let frameWidth = this.frameWidth;
    let frameHeight = this.frameHeight;
    
    const containerRect = this.containerElement.getBoundingClientRect();
    const imageRect = this.selectedImageElement.getBoundingClientRect();

    if (frameYPosition === null || frameXPosition === null || frameWidth === null || frameHeight === null) {
      const frameRect = this.controlFrame.getBoundingClientRect();
      const imageAspectRatio = this.selectedImageElement.clientWidth / this.selectedImageElement.clientHeight;
      
      frameYPosition = (imageRect.top - containerRect.top) + (0.5 * imageRect.height) - (0.5 * frameRect.height);
      frameXPosition = (imageRect.left - containerRect.left) + (0.5 * imageRect.width) - (0.5 * frameRect.width);

      if (this.props.aspectRatio) {
        if (imageAspectRatio >= this.props.aspectRatio) {
          frameHeight = this.selectedImageElement.clientHeight;
          frameWidth = frameHeight * this.props.aspectRatio;
        }
        else {
          frameWidth = this.selectedImageElement.clientWidth;
          frameHeight = frameWidth / this.props.aspectRatio;
        }
      }
      else {
        frameWidth = this.selectedImageElement.clientWidth;
        frameHeight = this.selectedImageElement.clientHeight;
      }
    }

    const naturalProportionMultiplier = this.selectedImageElement.naturalWidth / imageRect.width;
    const naturalWidth = naturalProportionMultiplier * frameWidth;
    const naturalHeight = naturalProportionMultiplier * frameHeight;

    let finalWidth = naturalWidth;
    let finalHeight = naturalHeight;

    if (this.props.maxHeight) {
      if (naturalHeight > this.props.maxHeight) {
        finalWidth = finalWidth * (this.props.maxHeight / naturalHeight);
        finalHeight = this.props.maxHeight;
      }
    }
    if (this.props.maxWidth) {
      if (finalWidth > this.props.maxWidth) {
        finalHeight = finalHeight * (this.props.maxWidth / finalWidth);
        finalWidth = this.props.maxWidth;
      }
    }

    this.canvasElement.setAttribute("width", finalWidth);
    this.canvasElement.setAttribute("height", finalHeight);

    const context = this.canvasElement.getContext("2d");

    context.drawImage(
      this.selectedImageElement, 
      naturalProportionMultiplier * (frameXPosition - (imageRect.left - containerRect.left)), 
      naturalProportionMultiplier * (frameYPosition - (imageRect.top - containerRect.top)), 
      naturalWidth, 
      naturalHeight,
      0,
      0,
      finalWidth,
      finalHeight);

    if (await this.props.onPictureSelected(this.canvasElement.toDataURL("image/png"))) {
      this.onCloseSelectionWindow();
    }

    this.setState({loading: false});
  }

  render() {
    return (
      <React.Fragment>

        <div className={`image-picker${this.props.className ? ` ${this.props.className}` : ''}`}>
        
          <div className={`image-picker__image-container${this.props.currentImage ? '--no-border' : ''}`}>

            {this.props.currentImage ? (
              <img className="image-picker__current-image" src={this.props.currentImage} alt="Avatar de usuário" />
            ) : (
              <i className="fa-solid fa-user image-picker__image-placeholder"></i>
            )}

          </div>
          
          <div className={`image-picker__controls${this.props.onPictureSelected ? '' : '--hidden'}`}>

            <button 
              className="image-picker__controls__button"
              onClick={() => this.onOpenCameraWindow()}
              disabled={this.state.loading}
            >

              <i className="fa-solid fa-camera"></i>
              
            </button>
            
            <button 
              className="image-picker__controls__button"
              onClick={() => this.onSelectImageFile()}
              disabled={this.state.loading}
            >

              <i className="fa-solid fa-arrow-up-from-bracket"></i>
              <input 
                className="image-picker__controls__file-input"
                ref={this.fileInputRef}
                type="file" 
                accept="image/*"
              />

            </button>

          </div>

        </div>

        <aside 
          className={`image-picker__selection-window${(this.state.cameraWindowVisible || this.state.imageSelected) ? '' : '--hidden'}`}
          ref={this.cameraWindowRef}
          onDragEnter={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >

          <div 
            className="image-picker__selection-window__main-container"
            draggable={false}
          >

            <div className="image-picker__selection-window__header">

              <button 
                className="image-picker__selection-window__header__close-button"
                onClick={() => this.onCloseSelectionWindow()}
                disabled={this.state.loading}
              >

                <i className="fa-solid fa-xmark image-picker__selection-window__header__close-button__icon"></i>

              </button>

              {(this.state.mayCaptureCameraImage && this.state.videoDeviceIdOptions.length > 1) &&
                <button 
                  className="image-picker__selection-window__header__switch-camera"
                  onClick={() => this.onSwitchToNextCamera()}
                  disabled={this.state.loading}
                >

                  <i className="fa-solid fa-retweet image-picker__selection-window__header__switch-camera__icon"></i>

                </button>
              }

            </div>

            <div 
              className={`image-content-wrapper image-picker__selection-window__content-wrapper${!this.state.videoIsLoading ? '--loaded' : ''}`}
              draggable={false}
            >

              <video className="image-picker__selection-window__video">
              
                Video stream not available.
                
              </video>

              <canvas className="image-picker__selection-window__canvas"></canvas>

              <img className="image-picker__selection-window__selected-image" alt="Foto do usuário selecionada" />

              <div 
                className="image-picker__selection-window__mask-overlay"
                draggable={false}
              >
              </div>

              <div 
                className={`image-selection-control-frame image-picker__selection-window__control-frame${this.state.imageSelected ? '' : '--hidden'}`}
                onMouseDown={(event) => this.onBeginFrameGrab(event)}
                onTouchStart={(event) => this.onBeginFrameGrab(event)}
              >

                <div className="image-picker__selection-window__control-frame__wrapper">

                  <div 
                    className="image-picker__selection-window__control-frame__top-left-control"
                    onMouseDown={(event) => this.onBeginTopLeftFrameGrab(event)}
                    onTouchStart={(event) => this.onBeginTopLeftFrameGrab(event)}
                  ></div>
                  <div className="image-picker__selection-window__control-frame__top-right-control"
                    onMouseDown={(event) => this.onBeginTopRightFrameGrab(event)}
                    onTouchStart={(event) => this.onBeginTopRightFrameGrab(event)}
                  ></div>
                  <div className="image-picker__selection-window__control-frame__bottom-left-control"
                    onMouseDown={(event) => this.onBeginBottomLeftFrameGrab(event)}
                    onTouchStart={(event) => this.onBeginBottomLeftFrameGrab(event)}
                  ></div>
                  <div className="image-picker__selection-window__control-frame__bottom-right-control"
                    onMouseDown={(event) => this.onBeginBottomRightFrameGrab(event)}
                    onTouchStart={(event) => this.onBeginBottomRightFrameGrab(event)}
                  ></div>

                </div>

              </div>

            </div>

            <div className="image-picker__selection-window__footer">

              {!this.state.imageSelected ? (
                <button 
                  className="image-picker__selection-window__action-button"
                  onClick={() => this.takepicture()}
                  disabled={!this.state.mayCaptureCameraImage || this.state.loading}
                >

                  <i className="fa-solid fa-camera image-picker__selection-window__action-button__icon"></i>
                  Capturar

                </button>
              ) : (
                <React.Fragment>

                  {!this.state.imageLoadedFromFile &&
                    <button 
                      className="image-picker__selection-window__action-button"
                      onClick={() => {
                        this.onTurnCameraOn();                        
                      }}
                      disabled={this.state.loading}
                    >

                      <i className="fa-solid fa-chevron-left image-picker__selection-window__action-button__icon"></i>
                      Voltar

                    </button>
                  }

                  <button 
                    className="image-picker__selection-window__action-button"
                    onClick={() => this.onFinishPictureSelection()}
                    disabled={this.state.loading}
                  >

                    <i className="fa-solid fa-check image-picker__selection-window__action-button__icon"></i>
                    Confirmar

                  </button>

                </React.Fragment>
              )}

            </div>

          </div>

        </aside>

        <ConfirmationWindow
          className="image-picker__message-prompt"
          title={this.state.loading ? 'Subindo imagem' : this.state.errorTitle}
          description={this.state.loading ? 'Por favor, aguarde enquanto a imagem é atualizada em nosso servidor.' : this.state.errorMessage}
          confirmText="ok"
          cancelText="ok"
          visible={this.state.loading || (this.state.errorTitle !== null && this.state.errorMessage !== null)}
          onCancel={() => this.setState({
            errorTitle: null,
            errorMessage: null,
          })}
          loading={this.state.loading}
          useErrorIcon={true}
          hideConfirmButton={true}
        />

      </React.Fragment>
    );
  }
}

export default ImagePicker;
