import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel, getPhoneText, setInputCursor} from '../../utils/functions';
import ExperimentalClassData from './experimental_class_data';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';

class ExperimentalClassEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experimental_class: {
        name: "",
        email: "",
        phone: "",
        physical_condition: "",
        date: "",
        hour: "",
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let experimental_class = getModel(`${routes.EXPERIMENTAL_CLASS_GET_API}${this.props.match.params.experimentalClassId}`);

    experimental_class = await experimental_class;

    let update = {
      loading: false
    }

    if(experimental_class) {
      experimental_class.date = experimental_class.date.slice(0, 10);
      experimental_class.physical_condition = experimental_class.physical_condition || '';
      update.experimental_class = {...this.state.experimental_class, ...experimental_class}
    }
    else {
      this.props.history.replace(this.getLastPath().path);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }

    const newData = {...this.state.experimental_class, [name]: value};

    this.setState({
      experimental_class: newData
    });
  }

  inputsAreValid() {
    return this.state.experimental_class.name.length > 0 &&
           this.state.experimental_class.email.length > 0 &&
           this.state.experimental_class.phone.length > 0 &&
           this.state.experimental_class.date.length > 0 &&
           this.state.experimental_class.hour.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.experimental_class}

    try {
      await patchModel(`${routes.EXPERIMENTAL_CLASS_PATCH_API}${this.props.match.params.experimentalClassId}`, data);
    }
    catch(errors) {
      // let warningMessages = [];
      let highlights = [];
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: errorDescription, //`${warningMessages.join('; ')}.`
        loading: false
      });

      return;
    }

    this.props.history.replace(this.getLastPath().path);
  }

  getLastPath() {
    if(this.props.location.state && this.props.location.state.lastPath === routes.EXPERIMENTAL_CLASS_COMPLETED_PATH) {
      return {
        path: routes.EXPERIMENTAL_CLASS_COMPLETED_PATH,
        name: 'Aulas experimentais'
      }
    }

    return {
      path: routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH,
      name: 'Aulas agendadas'
    }
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.getLastPath().path,
            text: this.getLastPath().name
          },
          {
            path: `${routes.EXPERIMENTAL_CLASS_EDIT_PATH}${this.props.match.params.experimentalClassId}`,
            text: "Editar aula experimental"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar aula experimental"
        loading={this.state.loading}
      >

        <ExperimentalClassData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          experimental_class={this.state.experimental_class}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.getLastPath().path}
        />

      </ContentFrame>
    );
  }
}

export default ExperimentalClassEdit;
