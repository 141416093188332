import React from 'react';
import preloader from '../images/preloader.gif'
import './preloader.scss';

class PreLoader extends React.Component {

  render() {
    return (
        <div className={`preloader__${this.props.local ? 'local-' : ''}container${this.props.className ? ` ${this.props.className}` : ''}`}>

          <img className="preloader__image" src={preloader} alt="Imagem temporária de carregamento de página" />

        </div>
    );
  }
}

export default PreLoader;
