import React from 'react';
import { Link } from 'react-router-dom';
import './role_experience_level_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import ModelTable, {Property} from '../../utils/model_table';
import WarningMessage from '../warning_message';
import {MANAGER_ROLE,
        COACH_ROLE,
        HEAD_COACH_ROLE,
        RECEPTIONIST_ROLE,
        ADMIN_RECEPTIONIST_ROLE,
        NUTRITIONIST_ROLE,
        ADMIN_NUTRITIONIST_ROLE} from '../../constants';

class RoleExperienceLevelData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.role_experience_level.id && this.props.role_experience_level.id > 0;
  }

  getRoleOptions() {
    const options = [
      SelectOption(MANAGER_ROLE.id, MANAGER_ROLE.name),
      SelectOption(COACH_ROLE.id, COACH_ROLE.name),
      SelectOption(HEAD_COACH_ROLE.id, HEAD_COACH_ROLE.name),
      SelectOption(ADMIN_NUTRITIONIST_ROLE.id, ADMIN_NUTRITIONIST_ROLE.name),
      SelectOption(NUTRITIONIST_ROLE.id, NUTRITIONIST_ROLE.name),
      SelectOption(RECEPTIONIST_ROLE.id, RECEPTIONIST_ROLE.name),
      SelectOption(ADMIN_RECEPTIONIST_ROLE.id, ADMIN_RECEPTIONIST_ROLE.name),
    ];

    if (this.props.role_experience_level.role_id && this.props.role_experience_level.role_id > 0) {
      return options;
    }

    return [
      SelectOption('', 'Selecione uma função'),
      ...options
    ];
  }

  getSalaryRangeInput(entry, index) {
    return (
      <div className="role-experience-level-data__salary-map__input-wrapper">

        <DefaultInput
          className="role-experience-level-data__salary-map__input"
          name={`salary_map:${entry.service}:${index}`}
          type="number"
          min="0.00"
          step="0.01"
          handleInputChange={this.props.handleInputChange}
          value={entry.salary_range[index]}
          autoComplete="off"
          prefix="R$"
          onFocus={(event) => event.target.select()}
        />

      </div>
    );
  }

  getSalaryMapProperties() {
    let properties = [
      Property('service', 'Serviço', <i className="fa-solid fa-bell-concierge"></i>),
      Property('green', '[100%, 75%]', null, {
        getDataText: (entry) => this.getSalaryRangeInput(entry, 0),
        getSortCallback: (a, b) => a.salary_range[0] - b.salary_range[0],
        cellClassName: "role-experience-level-data__salary-map__green-cell",
        headerClassName: "role-experience-level-data__salary-map__green-header"
      }),
      Property('yellow', ']75%, 50%]', null, {
        getDataText: (entry) => this.getSalaryRangeInput(entry, 1),
        getSortCallback: (a, b) => a.salary_range[1] - b.salary_range[1],
        cellClassName: "role-experience-level-data__salary-map__yellow-cell",
        headerClassName: "role-experience-level-data__salary-map__yellow-header"
      }),
      Property('red', ']50%, 0%[', null, {
        getDataText: (entry) => this.getSalaryRangeInput(entry, 2),
        getSortCallback: (a, b) => a.salary_range[2] - b.salary_range[2],
        cellClassName: "role-experience-level-data__salary-map__red-cell",
        headerClassName: "role-experience-level-data__salary-map__red-header"
      }),
      Property('white', '0%', null, {
        getDataText: (entry) => this.getSalaryRangeInput(entry, 3),
        getSortCallback: (a, b) => a.salary_range[3] - b.salary_range[3],
        cellClassName: "role-experience-level-data__salary-map__white-cell",
        headerClassName: "role-experience-level-data__salary-map__white-header"
      })
    ];

    return properties;
  }

  getSalaryMapInputs() {
    if (!this.props.role_experience_level.role_id ||
        (parseInt(this.props.role_experience_level.role_id) !== COACH_ROLE.id &&
         parseInt(this.props.role_experience_level.role_id) !== HEAD_COACH_ROLE.id)) {
      return null;
    }

    const serviceMapEntries = this.props.services.map((service) => {
      return {
        id: `${service}:salary_range`,
        service,
        salary_range: this.props.salary_map[service]
      };
    });

    return (
      <div className="role-experience-level-data__salary-map">
        <h4 className="role-experience-level-data__salary-map__title">Remuneração por aula</h4>

        <ModelTable
          properties={this.getSalaryMapProperties()}
          data={serviceMapEntries}
          initialLinesPerPage={this.props.services.length}
          initialOrderBy="service"
          hideFilter={true}
          hideLinesPerPageControl={true}
          hideNavigationControls={true}
        >

        </ModelTable>

      </div>
    );
  }

  render() {
    return (
      <DefaultSection
        className="role-experience-level-data"
        title="Dados do nível de experiência"
      >

        <div className="role-experience-level-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="role-experience-level-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.role_experience_level.is_active}
            horizontalAlign="right"
          />

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do nível"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.role_experience_level.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="role_id"
              isHighlighted={this.isHighlighted("role_id")}
              label="Função"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.role_experience_level.role_id || ''}
              options={this.getRoleOptions()}
              disabled={this.isEditMode()}
            />

          </HalfWrapper>

          <DefaultInput
            name="salary_reference"
            isHighlighted={this.isHighlighted("salary_reference")}
            label="Salário de referência"
            type="number"
            placeholder="Salário de referência"
            min="0.00"
            step="0.01"
            handleInputChange={this.props.handleInputChange}
            value={this.props.role_experience_level.salary_reference}
            autoComplete="off"
            prefix="R$"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          {this.getSalaryMapInputs()}

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do nível"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.role_experience_level.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="role-experience-level-data__buttons-container">

          <button
            className="role-experience-level-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="role-experience-level-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default RoleExperienceLevelData;
