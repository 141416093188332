import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import ModelTable, {Property} from '../../utils/model_table';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import './training_day_group_association_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import {parseTextForIcons} from '../../utils/functions';
import WarningMessage from '../warning_message';
import {compareParametersValues, getNewIntensityUpdate, getUpdatedDifficultyValue} from '../training_group_exercise_association/training_group_exercise_association_edit';
import {TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR,
        TRAINING_EXERCISE_PARAMETER_TYPE_RANGE,
        CLOCK_METHOD_STOPWATCH,
        CLOCK_METHOD_TIMER,
        CLOCK_METHOD_TABATA,
        CLOCK_METHOD_SERIES,
        CLOCK_METHOD_CUSTOM,
        SERVICE_HOME_TRAINING,
        CLOCK_COLOR_MAP,
        TARGET_SERVICE_COLOR_MAP} from '../../constants';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import VerticalBarGraph, {BarDataPoint} from '../../graphs/vertical_bar_graph';
import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import ContextPopup from '../../components/context_popup';
import * as permissions from '../../permissions';


const ADD_EXERCISE_FILTER_SESSION_KEY = 'add_exercise_filter:';

class TrainingDayGroupAssociationData extends React.Component {
  AUDIO_FILE_TYPES = [
    "audio/mpeg",
    "audio/aac",
    "audio/midi",
    "audio/x-midi",
    "audio/mpeg",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/webm",
    "audio/mp4",
    "audio/x-m4a",
  ];

  constructor(props) {
    super(props);

    let serviceFilter = '';

    if (this.props.training_period.target_service && this.props.services.includes(this.props.training_period.target_service)) {
      serviceFilter = this.props.training_period.target_service.toString();
    }

    this.state = {
      exerciseFunctionFilter: "",
      muscleGroupFilter: "",
      exerciseCategoryFilter: "",
      serviceFilter,
      replacementTargetServiceFilter: "",
      replacementCategoryFilter: "",
      replacementFunctionFilter: "",
      replacementMuscleGroupFilter: "",
      addExerciseSectionVisible: false,
      clockConfigurationVisible: false,
      statsSectionVisible: false,
      enableExerciseEdit: false,
      exerciseAssociationToReplace: null,
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth,
    };

    this.fileInput = null;

    this.fileInputRef = (element) => {
      if (element == null) {
        this.fileInput = null;
      }
      else {
        this.fileInput = element;

        this.fileInput.addEventListener("change", () => this.onAudioFileInputChanged());
      }
    };
  }

  async componentDidMount() {
    let storedParams = sessionStorage.getItem(`${ADD_EXERCISE_FILTER_SESSION_KEY}${window.location.pathname}`);

    if(storedParams) {
      const update = {};

      const filters = JSON.parse(storedParams);

      if(filters.serviceFilter) {
        update.serviceFilter = filters.serviceFilter;
      }
      if(filters.exerciseFunctionFilter) {
        update.exerciseFunctionFilter = filters.exerciseFunctionFilter;
      }
      if(filters.muscleGroupFilter) {
        update.muscleGroupFilter = filters.muscleGroupFilter;
      }
      if(filters.exerciseCategoryFilter) {
        update.exerciseCategoryFilter = filters.exerciseCategoryFilter;
      }

      this.setState(update);
    }

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.serviceFilter !== this.state.serviceFilter ||
       prevState.exerciseFunctionFilter !== this.state.exerciseFunctionFilter ||
       prevState.muscleGroupFilter !== this.state.muscleGroupFilter ||
       prevState.exerciseCategoryFilter !== this.state.exerciseCategoryFilter) {
      sessionStorage.setItem(`${ADD_EXERCISE_FILTER_SESSION_KEY}${window.location.pathname}`, JSON.stringify({
        serviceFilter: this.state.serviceFilter,
        exerciseFunctionFilter: this.state.exerciseFunctionFilter,
        muscleGroupFilter: this.state.muscleGroupFilter,
        exerciseCategoryFilter: this.state.exerciseCategoryFilter,
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description' && event.target.name !== 'note') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.association.id && this.props.association.id > 0;
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    if (name === 'replacementTargetServiceFilter' && value.length > 0 && this.state.replacementCategoryFilter.length > 0) {
      const exercise_category_id = parseInt(this.state.replacementCategoryFilter);

      if(!this.props.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === value);
      })) {
        update.replacementCategoryFilter = '';
      }
    }

    if (name === 'serviceFilter' && value.length > 0 && this.state.exerciseCategoryFilter.length > 0) {
      const exercise_category_id = parseInt(this.state.exerciseCategoryFilter);

      if(!this.props.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === this.state.serviceFilter);
      })) {
        update.exerciseCategoryFilter = '';
      }
    }

    if (name === 'enableExerciseEdit' && !value && this.props.association.exercise_associations.some((entry) => entry.mustUpdate)) {
      if (window.confirm("Existem alterações em exercícios que ainda não foram salvas e serão perdidas caso continue. Deseja mesmo desabilitar a edição?")) {
        this.props.onRevertChanges();
      }
      else {
        return;
      }
    }

    this.setState(update);
  }

  getExecutionMethodOptions() {
    const methods = [
      TRAINING_EXECUTION_METHOD_FIXED_REPS,
      TRAINING_EXECUTION_METHOD_PIRAMIDAL,
      TRAINING_EXECUTION_METHOD_QRP,
    ];

    methods.sort((a, b) => a.localeCompare(b));

    return [
      ...methods.map((method) => SelectOption(method, method))
    ];
  }

  getClockMethodOptions() {
    const methods = [
      CLOCK_METHOD_STOPWATCH,
      CLOCK_METHOD_TIMER,
      CLOCK_METHOD_CUSTOM,
      CLOCK_METHOD_SERIES,
      CLOCK_METHOD_TABATA,
    ];

    methods.sort((a, b) => a.localeCompare(b));

    return [
      ...methods.map((method) => SelectOption(method, method))
    ];
  }

  getClockColorOptions() {
    const options = [
      ...Object.entries(CLOCK_COLOR_MAP).map(([key, value]) => SelectOption(value, key))
    ];

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  }

  getExerciseActivityOptions() {
    return [
      SelectOption('', 'Não vinculado'),
      ...this.props.exerciseActivities.map((activity) => SelectOption(activity.id, `${activity.name} - ${activity.type_abbreviation} (MET: ${activity.met})`))
    ];
  }

  getClockMethodConfiguration() {
    const disableEdit = !this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID);

    if(this.props.association.clock_method === CLOCK_METHOD_SERIES) {
      return (
        <React.Fragment>

          <DefaultInput
            name="clock_preparation_period"
            isHighlighted={this.isHighlighted("clock_preparation_period")}
            label="Tempo de preparo"
            type="number"
            placeholder="Tempo de preparo"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_preparation_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_round_count"
            isHighlighted={this.isHighlighted("clock_round_count")}
            label="Número de séries"
            type="number"
            placeholder="Número de séries"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_round_count}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_round_period"
            isHighlighted={this.isHighlighted("clock_round_period")}
            label="Tempo da série"
            type="number"
            placeholder="Tempo da série"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_round_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="pause_on_round_end"
            isHighlighted={this.isHighlighted('pause_on_round_end')}
            label="Pausar entre séries:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.pause_on_round_end || false}
            disabled={disableEdit}
          />

          <div className="training-day-group-association-data__clock-configuration__color-input-container">

            <div
              className="training-day-group-association-data__clock-configuration__color-preview"
              style={{background: this.props.association.active_color || CLOCK_COLOR_MAP['Verde']}}
            >
            </div>

            <DefaultInput
              name="active_color"
              isHighlighted={this.isHighlighted("active_color")}
              label="Cor principal"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.active_color || CLOCK_COLOR_MAP['Verde']}
              options={this.getClockColorOptions()}
              disabled={disableEdit}
            />

          </div>

        </React.Fragment>
      );
    }
    else if(this.props.association.clock_method === CLOCK_METHOD_TABATA) {
      return (
        <React.Fragment>

          <DefaultInput
            name="clock_preparation_period"
            isHighlighted={this.isHighlighted("clock_preparation_period")}
            label="Tempo de preparo"
            type="number"
            placeholder="Tempo de preparo"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_preparation_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_round_count"
            isHighlighted={this.isHighlighted("clock_round_count")}
            label="Número de ciclos"
            type="number"
            placeholder="Número de ciclos"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_round_count}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_round_period"
            isHighlighted={this.isHighlighted("clock_round_period")}
            label="Tempo de trabalho"
            type="number"
            placeholder="Tempo de trabalho"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_round_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_rest_period"
            isHighlighted={this.isHighlighted("clock_rest_period")}
            label="Tempo de descanço"
            type="number"
            placeholder="Tempo de descanço"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_rest_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

        </React.Fragment>
      );
    }
    else if(this.props.association.clock_method === CLOCK_METHOD_CUSTOM) {
      return (
        <React.Fragment>

          <DefaultInput
            name="clock_preparation_period"
            isHighlighted={this.isHighlighted("clock_preparation_period")}
            label="Tempo de preparo"
            type="number"
            placeholder="Tempo de preparo"
            min="0"
            step="0.1"
            suffix="segundos"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_preparation_period}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <DefaultInput
            name="clock_round_count"
            isHighlighted={this.isHighlighted("clock_round_count")}
            label="Número de séries"
            type="number"
            placeholder="Número de séries"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.clock_round_count}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={disableEdit}
          />

          <HorizontalRule />

          <div className="training-day-group-association-data__clock-configuration__table-wrapper">

            <table className="training-day-group-association-data__clock-configuration__table">

              <thead>

                <tr className="training-day-group-association-data__clock-configuration__table__row">

                  <th></th>
                  <th className="training-day-group-association-data__clock-configuration__table__header-cell">Rótulo</th>
                  <th className="training-day-group-association-data__clock-configuration__table__header-cell">Tempo</th>
                  <th className="training-day-group-association-data__clock-configuration__table__header-cell">Cor</th>
                  <th className="training-day-group-association-data__clock-configuration__table__header-cell">Pausar no final</th>

                </tr>

              </thead>

              <tbody>

                {this.props.association.clock_custom_array.map((entry, index) => (
                  <tr
                    className="training-day-group-association-data__clock-configuration__table__row"
                    key={`training_day_group_association:clock_custom_configuration:round:${index}`}
                  >

                    <td width="38px">

                      <p className="training-day-group-association-data__clock-configuration__round-index">
                        {index+1}
                      </p>

                    </td>

                    <td className="training-day-group-association-data__clock-configuration__table__row-cell">

                      <DefaultInput
                        className="training-day-group-association-data__clock-configuration__cell-input"
                        name={`clock_custom_array:${index}:count_label`}
                        type="text"
                        placeholder={index+1}
                        handleInputChange={this.props.handleInputChange}
                        value={entry.count_label || ''}
                        autoComplete="off"
                        onFocus={(event) => event.target.select()}
                        disabled={disableEdit}
                      />

                    </td>

                    <td className="training-day-group-association-data__clock-configuration__table__row-cell">

                      <DefaultInput
                        className="training-day-group-association-data__clock-configuration__cell-input"
                        name={`clock_custom_array:${index}:period`}
                        type="number"
                        placeholder="Tempo"
                        min="0"
                        step="0.1"
                        suffix={this.state.screenWidth > 510 ? 'segundos' : 's'}
                        handleInputChange={this.props.handleInputChange}
                        value={entry.period}
                        autoComplete="off"
                        onFocus={(event) => event.target.select()}
                        disabled={disableEdit}
                      />

                    </td>

                    <td className="training-day-group-association-data__clock-configuration__table__row-cell">

                      <div className="training-day-group-association-data__clock-configuration__color-input-container--tabled">

                        <div
                          className="training-day-group-association-data__clock-configuration__color-preview--tabled"
                          style={{background: entry.color || CLOCK_COLOR_MAP['Verde']}}
                        >
                        </div>

                        <DefaultInput
                          className="training-day-group-association-data__clock-configuration__cell-input"
                          name={`clock_custom_array:${index}:color`}
                          isHighlighted={this.isHighlighted("active_color")}
                          type="select"
                          handleInputChange={this.props.handleInputChange}
                          value={entry.color || CLOCK_COLOR_MAP['Verde']}
                          options={this.getClockColorOptions()}
                          disabled={disableEdit}
                        />

                      </div>

                    </td>

                    <td className="training-day-group-association-data__clock-configuration__table__row-cell">

                      <DefaultInput
                        className="training-day-group-association-data__clock-configuration__cell-input"
                        name={`clock_custom_array:${index}:pause_on_end`}
                        type="toggle"
                        activeText="Sim"
                        inactiveText="Não"
                        handleInputChange={this.props.handleInputChange}
                        value={entry.pause_on_end}
                        disabled={disableEdit}
                      />

                    </td>

                  </tr>
                ))}

              </tbody>

            </table>

          </div>

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <DefaultInput
          name="clock_preparation_period"
          isHighlighted={this.isHighlighted("clock_preparation_period")}
          label="Tempo de preparo"
          type="number"
          placeholder="Tempo de preparo"
          min="0"
          step="0.1"
          suffix="segundos"
          handleInputChange={this.props.handleInputChange}
          value={this.props.association.clock_preparation_period}
          autoComplete="off"
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
        />

        <DefaultInput
          name="clock_time_limit"
          isHighlighted={this.isHighlighted("clock_time_limit")}
          label="Tempo limite"
          type="number"
          placeholder="Tempo limite"
          min="0"
          step="0.1"
          suffix="minutos"
          handleInputChange={this.props.handleInputChange}
          value={this.props.association.clock_time_limit}
          autoComplete="off"
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
        />

      </React.Fragment>
    );
  }

  getExerciseGroupOptions() {
    return [
      ...this.props.exerciseGroups.map((exerciseGroup) => SelectOption(exerciseGroup.id, exerciseGroup.name))
    ];
  }

  getExerciseCategoryOptions() {
    let filteredExerciseCategories = this.props.exerciseCategories;

    if (this.state.serviceFilter.length > 0) {
      filteredExerciseCategories = filteredExerciseCategories.filter((entry) => entry.target_service === null || entry.target_service === this.state.serviceFilter);
    }

    return [
      SelectOption('', 'Todos os pilares'),
      ...filteredExerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.name, exerciseCategory.name))
    ];
  }

  getReplacementExerciseCategoryOptions() {
    let filteredExerciseCategories = this.props.exerciseCategories;

    if (this.state.replacementTargetServiceFilter.length > 0) {
      filteredExerciseCategories = filteredExerciseCategories.filter((entry) => entry.target_service === null || entry.target_service === this.state.replacementTargetServiceFilter);
    }

    return [
      SelectOption('', 'Todos os pilares'),
      ...filteredExerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
    ];
  }

  getExerciseFunctionOptions() {
    return [
      SelectOption('', 'Todas as funções'),
      ...this.props.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.name, exerciseFunction.name))
    ];
  }

  getReplacementExerciseFunctionOptions() {
    return [
      SelectOption('', 'Todas as funções'),
      ...this.props.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
    ];
  }

  getMuscleGroupOptions() {
    return [
      SelectOption('', 'Todos os agrupamentos'),
      ...this.props.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.name, muscleGroup.name))
    ];
  }

  getReplacementMuscleGroupOptions() {
    return [
      SelectOption('', 'Todos os agrupamentos'),
      ...this.props.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.id, muscleGroup.name))
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.props.services,
      SERVICE_HOME_TRAINING
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos os treinamentos'),
      ...services.map((service) => SelectOption(service, service)),
    ];
  }

  getIntensityInput(exerciseAssociation, index, cycleIndex=null) {
    let inputName = `group_exercise_associations:${exerciseAssociation.id}:intensity_value:${index}`;
    let inputValue = exerciseAssociation.intensity_value[index];

    if(cycleIndex !== null) {
      inputName += `:${cycleIndex}`;
      inputValue = inputValue[cycleIndex];
    }

    if(!exerciseAssociation.intensity_name) {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="text"
          placeholder="-"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.intensity_unit}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else if(exerciseAssociation.intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR) {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="number"
          placeholder="-"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.intensity_unit}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else if(exerciseAssociation.intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
      return (
        <React.Fragment>
          <span
            className="training-day-group-association-data__association__configuration-entry__value--extended"
          >
            {exerciseAssociation.intensity_value[index].join(' - ')}
          </span>
        </React.Fragment>
      );
    }

    return "ERRO";
  }

  getDifficultInput(exerciseAssociation, index) {
    let inputName = `group_exercise_associations:${exerciseAssociation.id}:difficult_value:${index}`;
    let inputValue = exerciseAssociation.difficult_value[index];

    if(!exerciseAssociation.difficult_name) {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="text"
          placeholder="-"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="number"
          placeholder="-"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getIntermediateDifficultInput(exerciseAssociation, index) {
    let inputName = `group_exercise_associations:${exerciseAssociation.id}:difficult_intermediate_value:${index}`;
    let inputValue = exerciseAssociation.difficult_intermediate_value[index];

    if(!exerciseAssociation.difficult_name) {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="text"
          placeholder="-"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="number"
          placeholder="-"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getAdvancedDifficultInput(exerciseAssociation, index) {
    let inputName = `group_exercise_associations:${exerciseAssociation.id}:difficult_advanced_value:${index}`;
    let inputValue = exerciseAssociation.difficult_advanced_value[index];

    if(!exerciseAssociation.difficult_name) {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="text"
          placeholder="-"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          className="training-day-group-association-data__association__configuration-entry__input-value"
          name={inputName}
          type="number"
          placeholder="-"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={exerciseAssociation.difficult_unit}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getExerciseAssociationConfiguration(association) {
    let configurationEntries = [];

    const is_phase_constant = this.props.association.group.is_phase_constant || !this.props.isDefaultUnit;

    let repetitionCount = is_phase_constant ? 1 : this.props.training_period.repetition_count;

    for(let i=0; i < repetitionCount; ++i) {
      let repetitionColor = '#ffffff';

      if(i < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
        repetitionColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[i-1];
      }

      let intensityValue = null;
      let difficultValue = null;
      let difficultIntermediateValue = null;
      let difficultAdvancedValue = null;

      const intensityIsNumeric = association.intensity_name ? true : false;
      const difficultIsNumeric = association.difficult_name ? true : false;

      let intensityValueClassName = intensityIsNumeric ? 'training-day-group-association-data__association__configuration-entry__value' : 'training-day-group-association-data__association__configuration-entry__value--extended';
      const difficultValueClassName = difficultIsNumeric ? 'training-day-group-association-data__association__configuration-entry__value' : 'training-day-group-association-data__association__configuration-entry__value--extended';

      if(association.intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
        intensityValueClassName = 'training-day-group-association-data__association__configuration-entry__value--extended';
      }

      if(this.props.association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
        if (!this.state.enableExerciseEdit) {
          intensityValue = (
            <React.Fragment>
              <span
                className={intensityValueClassName}
              >
                {association.intensity_value[i] instanceof Array ? association.intensity_value[i].join(' - ') : association.intensity_value[i]}
              </span>
            </React.Fragment>
          );
        }
        else {
          intensityValue = this.getIntensityInput(association, i);
        }

        if(association.difficult_value[i] || (association.backup && association.backup.difficult_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultValue = this.getDifficultInput(association, i);
          }
        }

        if(association.difficult_intermediate_value[i] || (association.backup && association.backup.difficult_intermediate_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultIntermediateValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_intermediate_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultIntermediateValue = this.getIntermediateDifficultInput(association, i);
          }
        }

        if(association.difficult_advanced_value[i] || (association.backup && association.backup.difficult_advanced_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultAdvancedValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_advanced_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultAdvancedValue = this.getAdvancedDifficultInput(association, i);
          }
        }
      }
      else if(this.props.association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
        if (!this.state.enableExerciseEdit) {
          intensityValue = association.intensity_value[i].map((value, cycleIndex) => (
            <React.Fragment
              key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}:cycle:${cycleIndex+1}`}
            >
              {cycleIndex > 0 &&
                '-'
              }

              <span
                className={intensityValueClassName}
              >
                {value instanceof Array ? value.join(' - ') : value}
              </span>
            </React.Fragment>
          ));
        }
        else {
          intensityValue = association.intensity_value[i].map((value, cycleIndex) => (
            <React.Fragment
              key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}:cycle:${cycleIndex+1}`}
            >
              {cycleIndex > 0 &&
                '-'
              }

              {this.getIntensityInput(association, i, cycleIndex)}
            </React.Fragment>
          ));
        }

        if(association.difficult_value[i] || (association.backup && association.backup.difficult_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultValue = this.getDifficultInput(association, i);
          }
        }

        if(association.difficult_intermediate_value[i] || (association.backup && association.backup.difficult_intermediate_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultIntermediateValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_intermediate_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultIntermediateValue = this.getIntermediateDifficultInput(association, i);
          }
        }

        if(association.difficult_advanced_value[i] || (association.backup && association.backup.difficult_advanced_value)) {
          if (!this.state.enableExerciseEdit) {
            difficultAdvancedValue = (
              <span
                className={difficultValueClassName}
              >
                {parseTextForIcons(association.difficult_advanced_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
              </span>
            );
          }
          else {
            difficultAdvancedValue = this.getAdvancedDifficultInput(association, i);
          }
        }
      }
      else if(this.props.association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
        intensityValue = [];

        let j;

        for(j = 0; j < 3; ++j) {
          if(j > 0) {
            intensityValue.push('-');
          }

          let cycleValue = association.intensity_value[i];

          if(cycleValue instanceof Array) {
            cycleValue = [...cycleValue];
            cycleValue[0] += cycleValue[0] + (j*association.intensity_value_step[i]);
            cycleValue[1] += cycleValue[1] + (j*association.intensity_value_step[i]);

            if(cycleValue[0] <= 0) {
              cycleValue[0] = 0;
            }
            if(cycleValue[1] <= 0) {
              cycleValue[1] = 0;
            }

            if(cycleValue[0] === 0 && cycleValue[1] === 0) {
              cycleValue = '-';
            }
            else {
              cycleValue = cycleValue.join(' - ');
            }
          }
          else {
            cycleValue += (j*association.intensity_value_step[i]);

            if(cycleValue <= 0) {
              cycleValue = '-';
            }
          }

          intensityValue.push(
            <span
              key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}:cycle:${j+1}`}
              className={intensityValueClassName}
            >
              {cycleValue}
            </span>
          );
        }
        intensityValue.push('-');

        intensityValue.push(
          <span
            key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}:cycle:${j+1}`}
            className={intensityValueClassName}
          >
            <i className="fas fa-ellipsis-h"></i>
          </span>
        );

        if(association.difficult_value[i]) {
          difficultValue = (
            <span
              className={difficultValueClassName}
            >
              {parseTextForIcons(association.difficult_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
            </span>
          );
        }

        if(association.difficult_intermediate_value[i]) {
          difficultIntermediateValue = (
            <span
              className={difficultValueClassName}
            >
              {parseTextForIcons(association.difficult_intermediate_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
            </span>
          );
        }

        if(association.difficult_advanced_value[i]) {
          difficultAdvancedValue = (
            <span
              className={difficultValueClassName}
            >
              {parseTextForIcons(association.difficult_advanced_value[i], `group_exercise_associations:${association.id}:configuration_repetition:${i+1}:dificult_text`, 'training-day-group-association-data__parsed-text')}
            </span>
          );
        }
      }

      if(is_phase_constant) {
        configurationEntries.push(
          <React.Fragment
            key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}`}
          >
            <div className="training-day-group-association-data__association__configuration-entry__text-container">

              <div className="training-day-group-association-data__association__configuration-entry__text">

                <span
                  className="training-day-group-association-data__association__configuration-entry__sub-title"
                >
                  {`${association.intensity_name || 'Repetições'}`}{association.intensity_unit ? ` (${association.intensity_unit})` : ''}:
                </span>
                {intensityValue}

              </div>

              <div className="training-day-group-association-data__association__configuration-entry__text">

                {difficultValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      {`${association.difficult_name || 'Dificuldade'}`}{association.difficult_unit ? ` (${association.difficult_unit})` : ''}:
                    </span>
                    {difficultValue}
                  </React.Fragment>
                }

                {difficultIntermediateValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      *:
                    </span>
                    {difficultIntermediateValue}
                  </React.Fragment>
                }

                {difficultAdvancedValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      AV:
                    </span>
                    {difficultAdvancedValue}
                  </React.Fragment>
                }

              </div>

            </div>
          </React.Fragment>
        );
      }
      else {
        configurationEntries.push(
          <div
            key={`group_exercise_associations:${association.id}:configuration_repetition:${i+1}`}
            className="training-day-group-association-data__association__configuration-entry"
            style={{borderColor: repetitionColor}}
          >

            <p className="training-day-group-association-data__association__detail" style={{background: repetitionColor}}>{`Fase ${i+1}`}</p>

            <div className="training-day-group-association-data__association__configuration-entry__text-container">

              <div className="training-day-group-association-data__association__configuration-entry__text">

                <span
                  className="training-day-group-association-data__association__configuration-entry__sub-title"
                >
                  {`${association.intensity_name || 'Repetições'}`}{association.intensity_unit ? ` (${association.intensity_unit})` : ''}:
                </span>
                {intensityValue}

              </div>

              <div className="training-day-group-association-data__association__configuration-entry__text">

                {difficultValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      {`${association.difficult_name || 'Dificuldade'}`}{association.difficult_unit ? ` (${association.difficult_unit})` : ''}:
                    </span>
                    {difficultValue}
                  </React.Fragment>
                }

                {difficultIntermediateValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      *:
                    </span>
                    {difficultIntermediateValue}
                  </React.Fragment>
                }

                {difficultAdvancedValue &&
                  <React.Fragment>
                    <span
                      className="training-day-group-association-data__association__configuration-entry__sub-title"
                    >
                      AV:
                    </span>
                    {difficultAdvancedValue}
                  </React.Fragment>
                }

              </div>

            </div>

          </div>
        );
      }
    }

    return configurationEntries;
  }

  getGroupExerciseAssociations() {
    if(!this.props.association.exercise_associations) {
      return null;
    }

    const entriesCount = this.props.association.exercise_associations.length;
    const maySwitchOrder = this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID) && entriesCount > 1;

    return this.props.association.exercise_associations.map((association, position) => {
      const intensityReference = getNewIntensityUpdate(this.props.association, this.props.training_period.repetition_count, association.intensity_value_type, association.intensity_name);
      const difficultReference = getUpdatedDifficultyValue(this.props.association, this.props.training_period.repetition_count);

      let requiresConfiguration = false;
      let alertText = 'Requer configuração!';

      if((intensityReference.intensity_value_step && (!association.intensity_value_step || intensityReference.intensity_value_step.length !== association.intensity_value_step.length)) ||
         (!compareParametersValues(intensityReference.intensity_value, association.intensity_value)) ||
         (!compareParametersValues(difficultReference.difficult_value, association.difficult_value))) {
        requiresConfiguration = true;
      }
      else if(!association.intensity_name && this.props.association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
        requiresConfiguration = true;
        alertText = 'Tipo de parâmetro não configurado!';
      }

      return (
        <div
          className="training-day-group-association-data__association"
          key={`group_exercise_associations:${association.id}`}
        >

          <p className="training-day-group-association-data__association__detail"> {association.order} </p>

          <div className="training-day-group-association-data__association__content-wrapper">

            {(this.state.enableExerciseEdit && !requiresConfiguration) ? (
              <p className="training-day-group-association-data__association__content">
                {association.exercise_name}

                <button
                  className="training-day-group-association-data__association__content__edit-button"
                  onClick={() => {
                    let replacementTargetServiceFilter = '';

                    if (this.props.training_period.target_service && this.props.services.includes(this.props.training_period.target_service)) {
                      replacementTargetServiceFilter = this.props.training_period.target_service.toString();
                    }

                    let replacementCategoryFilter = association.exercise.category_id ? association.exercise.category_id.toString() : '';

                    if (replacementTargetServiceFilter.length > 0 && replacementCategoryFilter.length > 0) {
                      const exercise_category_id = parseInt(replacementCategoryFilter);

                      if(!this.props.exerciseCategories.some((entry) => {
                        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === replacementTargetServiceFilter);
                      })) {
                        replacementCategoryFilter = '';
                      }
                    }

                    this.setState({
                      exerciseAssociationToReplace: association,
                      replacementTargetServiceFilter,
                      replacementCategoryFilter,
                      replacementFunctionFilter: association.exercise.function_id ? association.exercise.function_id.toString() : '',
                      replacementMuscleGroupFilter: association.exercise.muscle_group_id ? association.exercise.muscle_group_id.toString() : ''
                    });
                  }}
                >

                  <i className="fa-solid fa-pen-to-square"></i>

                </button>
              </p>
            ) : (
              <p className="training-day-group-association-data__association__content">
                {association.exercise_name}
                {requiresConfiguration &&
                  <span
                    className="training-day-group-association-data__association__content__alert-text"
                  >
                    {alertText}
                  </span>
                }
              </p>
            )}

            {!requiresConfiguration &&
              <div className="training-day-group-association-data__association__configuration-container">

                {this.getExerciseAssociationConfiguration(association)}

              </div>
            }

          </div>

          {this.state.enableExerciseEdit ? (
            association.mustUpdate ? (
              <button
                className="training-day-group-association-data__save-association-button"
                onClick={() => this.props.onSaveExerciseAssociation(association)}
              >

                <i className="fa-solid fa-floppy-disk training-day-group-association-data__save-association-button__icon"></i>
                Salvar

              </button>
            ) : null
          ) : (
            <React.Fragment>

              {(!this.state.enableExerciseEdit && this.state.screenWidth > 1150) &&
                <div className="training-day-group-association-data__association__additional-info-container">

                  <p className="training-day-group-association-data__association__additional-info">
                    <i className="fas fa-tag training-day-group-association-data__association__additional-info__icon"></i>
                    {association.exercise.category_name}
                  </p>

                  <p className="training-day-group-association-data__association__additional-info">
                    <i className="fas fa-box training-day-group-association-data__association__additional-info__icon"></i>
                    {association.exercise.function_name}
                  </p>

                </div>
              }

              <button
                className={`training-day-group-association-data__switch-order-button${(maySwitchOrder && association.order > 1) ? '' : '--hidden'}`}
                onClick={() => this.props.onSwitchOrder(association, -1)}
              >

                <i className="fas fa-chevron-up"></i>

              </button>

              <button
                className={`training-day-group-association-data__switch-order-button${(maySwitchOrder && association.order < entriesCount) ? '' : '--hidden'}`}
                onClick={() => this.props.onSwitchOrder(association, 1)}
              >

                <i className="fas fa-chevron-down"></i>

              </button>

              {this.props.userPermissionIds.includes(permissions.VIEW_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID) &&
                <button
                  className="training-day-group-association-data__edit-association-button"
                  onClick={() => this.props.onEditExerciseAssociation(association)}
                >

                  {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) ?
                    <i className="far fa-edit"></i>:
                    <i className="fas fa-eye"></i>
                  }

                </button>
              }

              {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.DELETE_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) &&
                <button
                  className="training-day-group-association-data__delete-association-button"
                  onClick={() => this.props.onDeleteExerciseAssociation(association)}
                >

                  <i className="far fa-trash-alt"></i>

                </button>
              }

              {association.exercise.reference_url !== null &&
                <a
                  className="training-day-group-association-data__exercise-url-button"
                  href={association.exercise.reference_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={`training_exercise:${association.exercise.id}:reference`}
                  >

                    <i className="fas fa-link"></i>

                  </a>
              }

            </React.Fragment>
          )}


        </div>
      );
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="training-day-group-association-data__add-exercise-button"
          onClick={() => this.props.addExercise(entry.id)}
        >

          <i className="fas fa-plus training-day-group-association-data__add-exercise-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Adicionar' : ''}

        </button>

      </div>
    );
  }

  getReferenceLinkText(entry) {
    if(!entry.reference_url) {
      return null;
    }

    return (
      <a
        className="model-table__default-link-button"
        href={entry.reference_url}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i>

      </a>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => (
          <p className="training-day-group-association-data__exercise-name">

              {entry.name}

              {entry.description && (
                <i
                  className="fa-solid fa-circle-info training-day-group-association-data__exercise-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="training-day-group-association-data__exercise-info">{entry.description}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.name,
        getCellStyle: (entry) => {
          if (entry.target_service in TARGET_SERVICE_COLOR_MAP) {
            return {background: TARGET_SERVICE_COLOR_MAP[entry.target_service]};
          }

          return null;
        }
      }),
    ];

    // if(this.state.screenWidth > 710) {
    //   properties.push(
    //     Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "training-day-group-association-data__description-cell"}),
    //   );
    // }

    properties.push(Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>));
    properties.push(Property('category_name', 'Pilar', <i className="fas fa-tag"></i>));
    properties.push(Property('function_name', 'Função', <i className="fas fa-box"></i>));

    properties.push(Property('reference_url', 'URL', <i className="fas fa-link"></i>, {
      getDataText: (entry) => this.getReferenceLinkText(entry),
      getFilterText: (entry) => ''
    }));

    return properties;
  }

  getExercises() {
    let filteredExercises = this.props.exercises;

    if(this.state.serviceFilter) {
      filteredExercises = filteredExercises.filter((entry) => entry.target_service === null || entry.target_service === this.state.serviceFilter);
    }

    if(this.state.exerciseCategoryFilter) {
      filteredExercises = filteredExercises.filter((entry) => entry.category_name.toLocaleLowerCase().includes(this.state.exerciseCategoryFilter.toLocaleLowerCase()));
    }

    if(this.state.exerciseFunctionFilter) {
      filteredExercises = filteredExercises.filter((entry) => entry.function_name.toLocaleLowerCase().includes(this.state.exerciseFunctionFilter.toLocaleLowerCase()));
    }

    if(this.state.muscleGroupFilter) {
      filteredExercises = filteredExercises.filter((entry) => entry.muscle_group_name.toLocaleLowerCase().includes(this.state.muscleGroupFilter.toLocaleLowerCase()));
    }

    return filteredExercises;
  }

  getExerciseList() {
    return this.props.exercises.filter((entry) => {
      let include = true;

      if (entry.target_service !== null && this.state.replacementTargetServiceFilter.length > 0 && entry.target_service !== this.state.replacementTargetServiceFilter) {
        include = false;
      }
      if (this.state.replacementCategoryFilter.length > 0 && entry.category_id !== parseInt(this.state.replacementCategoryFilter)) {
        include = false;
      }
      if (this.state.replacementFunctionFilter.length > 0 && entry.function_id !== parseInt(this.state.replacementFunctionFilter)) {
        include = false;
      }
      if (this.state.replacementMuscleGroupFilter.length > 0 && entry.muscle_group_id !== parseInt(this.state.replacementMuscleGroupFilter)) {
        include = false;
      }

      return include;
    });
  }

  getReplacementProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => (
          <p className="training-day-group-association-data__exercise-name">

              {entry.name}

              {entry.description && (
                <i
                  className="fa-solid fa-circle-info training-day-group-association-data__exercise-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="training-day-group-association-data__exercise-info">{entry.description}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.name,
        getCellStyle: (entry) => {
          if (entry.target_service in TARGET_SERVICE_COLOR_MAP) {
            return {background: TARGET_SERVICE_COLOR_MAP[entry.target_service]};
          }

          return null;
        }
      }),
    ];

    properties.push(Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>));
    properties.push(Property('category_name', 'Pilar', <i className="fas fa-tag"></i>));
    properties.push(Property('function_name', 'Função', <i className="fas fa-box"></i>));
    properties.push(Property('reference_url', 'URL', <i className="fas fa-link"></i>, {
      getDataText: (entry) => this.getReferenceLinkText(entry),
      getFilterText: (entry) => ''
    }));

    return properties;
  }

  getReplacementActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="training-day-group-association-data__activate-button"
          onClick={() => {
            this.props.onReplaceExercise(this.state.exerciseAssociationToReplace, entry);
            this.setState({exerciseAssociationToReplace: null});
          }}
          disabled={entry.id === this.state.exerciseAssociationToReplace.exercise_id}
        >

          {entry.id === this.state.exerciseAssociationToReplace.exercise_id ? (
            <React.Fragment>

              <i className="far fa-check-square training-day-group-association-data__activate-button__icon"></i>
              Selecionado

            </React.Fragment>
          ) : (
            <React.Fragment>

              <i className="fas fa-exchange-alt training-day-group-association-data__activate-button__icon"></i>
              Alterar

            </React.Fragment>
          )}

        </button>

      </div>
    );
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPieHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getStatsData() {
    const exercises = this.props.association.exercise_associations.map((entry) => entry.exercise);
    const exercisesCount = exercises.length;

    const functionMap = new Map();
    const muscleGroupMap = new Map();
    const categoryMap = new Map();

    for(let exercise of exercises) {
      let functionPoint;
      let muscleGroupPoint;
      let categoryPoint;

      if(!functionMap.has(exercise.function_name)) {
        let barColor = '#000000';

        if(functionMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[functionMap.size];
        }

        functionPoint = BarDataPoint(0, exercise.function_name, barColor);
        functionMap.set(exercise.function_name, functionPoint);
      }
      else {
        functionPoint = functionMap.get(exercise.function_name);
      }

      functionPoint.value += 1;

      let muscle_group_name;

      if(exercise.muscle_group_name && exercise.muscle_group_name.length > 0) {
        muscle_group_name = exercise.muscle_group_name;
      }
      else {
        muscle_group_name = 'Indefinido';
      }

      if(!muscleGroupMap.has(muscle_group_name)) {
        let barColor = '#000000';

        if(muscleGroupMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[muscleGroupMap.size];
        }

        muscleGroupPoint = BarDataPoint(0, muscle_group_name, barColor);
        muscleGroupMap.set(muscle_group_name, muscleGroupPoint);
      }
      else {
        muscleGroupPoint = muscleGroupMap.get(muscle_group_name);
      }

      muscleGroupPoint.value += 1;

      if(!categoryMap.has(exercise.category_name)) {
        let barColor = '#000000';

        if(categoryMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[categoryMap.size];
        }

        categoryPoint = PiePoint(0, exercisesCount, exercise.category_name, barColor);
        categoryMap.set(exercise.category_name, categoryPoint);
      }
      else {
        categoryPoint = categoryMap.get(exercise.category_name);
      }

      categoryPoint.value += 1;
    }

    const functionData = [...functionMap.values()];
    const muscleGroupData = [...muscleGroupMap.values()];

    functionData.sort((a, b) => b.value - a.value);
    muscleGroupData.sort((a, b) => b.value - a.value);

    return {
      exercisesCount: exercisesCount,
      functionData: functionData,
      muscleGroupData: muscleGroupData,
      categoryData: [...categoryMap.values()],
    };
  }

  getSupportTableRows() {
    if(!this.props.association.support_table) {
      return null;
    }

    const allowEdit = (this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID));

    const rows = this.props.association.support_table.map((row, index) => (
      <tr
        className="training-day-group-association-data__support-table-configuration__table__row"
        key={`training_day_group_association:support_table:${index}`}
      >

        <td className="training-day-group-association-data__support-table-configuration__table__row-cell">

          <DefaultInput
            className="training-day-group-association-data__support-table-configuration__cell-input"
            name={`support_table:${index}:cycle`}
            type="text"
            placeholder="Série"
            handleInputChange={this.props.handleInputChange}
            value={row[0]}
            autoComplete="off"
            onFocus={(event) => event.target.select()}
            disabled={!allowEdit}
          />

        </td>

        <td className="training-day-group-association-data__support-table-configuration__table__row-cell">

          <DefaultInput
            className="training-day-group-association-data__support-table-configuration__cell-input"
            name={`support_table:${index}:repetition`}
            type="text"
            placeholder="Reps"
            handleInputChange={this.props.handleInputChange}
            value={row[1]}
            autoComplete="off"
            onFocus={(event) => event.target.select()}
            disabled={!allowEdit}
          />

        </td>

        {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) &&
          <td width="38px">

            <button
              className="training-day-group-association-data__support-table-configuration__remove-row-button"
              onClick={() => this.props.removeSupportTableRow(index)}
            >

              <i className="fas fa-minus training-day-group-association-data__support-table-configuration__remove-row-button__icon"></i>

            </button>

          </td>
        }

      </tr>
    ));

    if(allowEdit) {
      rows.push(
        <tr
          className="training-day-group-association-data__support-table-configuration__table__row"
          key="training_day_group_association:support_table:add_row_button"
        >

          <td
            className="training-day-group-association-data__support-table-configuration__table__row-cell"
            colSpan="2"
          >

            <button
              className="training-day-group-association-data__support-table-configuration__add-row-button"
              onClick={() => this.props.addSupportTableRow()}
            >

              <i className="fas fa-plus training-day-group-association-data__support-table-configuration__add-row-button__icon"></i>
              Adicionar linha

            </button>

          </td>

        </tr>
      );
    }

    return rows;
  }

  mayResetReplacementFilterInputs() {
    if(this.state.replacementCategoryFilter.length > 0 ||
       this.state.replacementFunctionFilter.length > 0 ||
       this.state.replacementMuscleGroupFilter.length > 0) {
      return true;
    }

    return false;
  }

  resetReplacementFilterInputs() {
    this.setState({
      replacementCategoryFilter: '',
      replacementFunctionFilter: '',
      replacementMuscleGroupFilter: ''
    });
  }

  onSelectAudioFile() {
    if (this.fileInput !== null) {
      this.fileInput.click();
    }
  }

  onAudioFileInputChanged() {
    if (this.fileInput !== null && this.fileInput.files.length > 0) {
      const selectedFile = this.fileInput.files[0];

      if (this.AUDIO_FILE_TYPES.includes(selectedFile.type)) {
        this.props.onSelectClockAudioFile(selectedFile);

        this.fileInput.value = '';
      }
    }
  }

  render() {
    const statsData = this.getStatsData();

    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <OverlayWindow
          className="training-day-group-association-data__overlay"
          visible={this.state.exerciseAssociationToReplace != null}
          actions={(
            <div className="training-day-group-association-data__overlay__action-container">

              <DefaultMenuButton
                className="training-day-group-association-data__overlay__action-button"
                onClick={() => this.setState({
                  exerciseAssociationToReplace: null
                })}
                text="Cancelar"
              />

            </div>
          )}
        >

          <header className="training-day-group-association-data__overlay__header">

            <h3 className="training-day-group-association-data__overlay__header__title">
              {`Substituir exercício: ${this.state.exerciseAssociationToReplace !== null ? this.state.exerciseAssociationToReplace.exercise_name : ''}`}
            </h3>

          </header>

          <hr className="training-day-group-association-data__horizontal-rule" />

          <div className="training-day-group-association-data__overlay__content">

            <div className="training-day-group-association-data__exercise-filters">

              <header className="training-day-group-association-data__exercise-filters__header">

                <h4 className="training-day-group-association-data__exercise-filters__header__text">Filtros</h4>

              </header>

              <div className="training-day-group-association-data__exercise-filters__inputs">

                <div className="training-day-group-association-data__exercise-filters__inputs-wrapper">

                  <DefaultInput
                    name="replacementTargetServiceFilter"
                    label="Serviço alvo"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.replacementTargetServiceFilter || ''}
                    options={this.getServiceOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="replacementCategoryFilter"
                      label="Pilar"
                      labelMessage={this.state.replacementTargetServiceFilter.length > 0 ? 'Filtrado pelo serviço' : null}
                      type="select"
                      value={this.state.replacementCategoryFilter || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getReplacementExerciseCategoryOptions()}
                    />

                    <DefaultInput
                      name="replacementFunctionFilter"
                      label="Função"
                      type="select"
                      value={this.state.replacementFunctionFilter || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getReplacementExerciseFunctionOptions()}
                    />

                  </HalfWrapper>

                  <DefaultInput
                    name="replacementMuscleGroupFilter"
                    label="Agrupamento muscular"
                    type="select"
                    value={this.state.replacementMuscleGroupFilter || ''}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    options={this.getReplacementMuscleGroupOptions()}
                  />

                </div>

                <button
                  className="training-day-group-association-data__exercise-filters__reset-button"
                  onClick={() => this.resetReplacementFilterInputs()}
                  disabled={!this.mayResetReplacementFilterInputs()}
                >

                  <i className="fas fa-times"></i>

                </button>

              </div>

            </div>

            <ModelTable
              properties={this.getReplacementProperties()}
              getActions={(entry) => this.getReplacementActions(entry)}
              data={this.getExerciseList()}
              initialOrderBy="name"
              enableMinRowHeight={true}
            >
            </ModelTable>

          </div>

        </OverlayWindow>

        <DefaultSection
          className="training-day-group-association-data"
          title="Dados do agrupamento"
        >

          <div className="default-section__links-wrapper">

            <DefaultInput
              className="training-day-group-association-data__id-label"
              name="id"
              type="number"
              placeholder="ID do treino"
              min="0"
              step="1"
              value={this.props.association.id}
              prefix="ID"
              isHorizontal={true}
              disabled={true}
            />

          </div>

          <div className="training-day-group-association-data__warning-container">

            <WarningMessage
              message={this.props.warningMessage}
              onClose={this.props.onCloseWarning}
              background={this.props.warningMessageBackground}
              color={this.props.warningMessageColor}
              visible={this.props.showWarningMessage}
            />

          </div>

          <div className="training-day-group-association-data__input-container">

            <HalfWrapper>

              <DefaultInput
                name="group_id"
                isHighlighted={this.isHighlighted("group_id")}
                label="Tipo do grupo"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.group_id}
                options={this.getExerciseGroupOptions()}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

              <DefaultInput
                name="activity_reference_id"
                isHighlighted={this.isHighlighted("activity_reference_id")}
                label="Atividade de referência"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.activity_reference_id || ''}
                options={this.getExerciseActivityOptions()}
                // isHorizontal={this.state.screenWidth > 580}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="name"
                isHighlighted={this.isHighlighted("name")}
                label="Nome"
                type="text"
                placeholder="Nome do treino"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.name}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

              <DefaultInput
                name="execution_method"
                isHighlighted={this.isHighlighted("execution_method")}
                label="Método de execução"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.execution_method || ''}
                options={this.getExecutionMethodOptions()}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="time_required"
                isHighlighted={this.isHighlighted("time_required")}
                label="Tempo do grupo"
                type="number"
                placeholder="Tempo do grupo"
                min="0"
                step="0.1"
                suffix="minutos"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.time_required}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

              {(this.props.association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL || this.props.association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) ?
                <DefaultInput
                  name="cycle_number"
                  isHighlighted={this.isHighlighted("cycle_number")}
                  label="Número de séries"
                  type="number"
                  placeholder="Número de séries"
                  min="1"
                  step="1"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.association.cycle_number}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                  disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
                />:
                <DefaultInput
                  name="clock_time_limit"
                  isHighlighted={this.isHighlighted("clock_time_limit")}
                  label="Tempo limite"
                  type="number"
                  placeholder="Tempo limite"
                  min="0"
                  step="0.1"
                  suffix="minutos"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.association.clock_time_limit}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                  disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
                />
              }

            </HalfWrapper>

            <DefaultInput
              name="note"
              isHighlighted={this.isHighlighted("note")}
              label="Observações"
              type="textarea"
              placeholder="Observações de execução"
              rows="3"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.note || ''}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
            />

          <DefaultInput
            name="merge_with_previous_group"
            isHighlighted={this.isHighlighted('merge_with_previous_group')}
            label="Unificar com agrupamento anterior:"
            labelMessage="Na lousa, os agrupamentos unificados precisam ter seus cronômetros configurados para serem exibidos"
            type="toggle"
            isHorizontal={this.state.screenWidth > 350}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.merge_with_previous_group}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
          />

            <DefaultSubSectionTitle
              className="training-day-group-association-data__sub-section"
              icon={<i className="fas fa-heartbeat"></i>}
              text="Frequência cardíaca"
            />

            <HalfWrapper>

              <DefaultInput
                name="hr_range:0"
                isHighlighted={this.isHighlighted("hr_range")}
                label="FC mínima"
                type="number"
                placeholder="Porcentagem mínima"
                step="1"
                min="0"
                max={this.props.association.hr_range !== null ? this.props.association.hr_range[1] : '100'}
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.hr_range !== null ? this.props.association.hr_range[0] : ''}
                autoComplete="off"
                suffix="%"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

              <DefaultInput
                name="hr_range:1"
                isHighlighted={this.isHighlighted("hr_range")}
                label="FC máxima"
                type="number"
                placeholder="Porcentagem máxima"
                step="1"
                min={this.props.association.hr_range !== null ? this.props.association.hr_range[0] : '0'}
                max="100"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.hr_range !== null ? this.props.association.hr_range[1] : ''}
                autoComplete="off"
                suffix="%"
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
              />

            </HalfWrapper>

            <HorizontalRule />

            <section className="training-day-group-association-data__clock-configuration">

              <header
                className="training-day-group-association-data__clock-configuration__header"
                onClick={() => this.setState({clockConfigurationVisible: !this.state.clockConfigurationVisible})}
              >

                <h3 className="training-day-group-association-data__clock-configuration__header__text">
                  <i className="fas fa-stopwatch training-day-group-association-data__clock-configuration__header__text-icon"></i>
                  Configuração de relógio
                </h3>

                {this.state.clockConfigurationVisible ?
                  <i className="fas fa-chevron-down training-day-group-association-data__clock-configuration__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up training-day-group-association-data__clock-configuration__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container training-day-group-association-data__clock-configuration__content"
                pose={this.state.clockConfigurationVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container training-day-group-association-data__clock-configuration__content-wrapper">

                  <DefaultInput
                    name="has_clock"
                    isHighlighted={this.isHighlighted('has_clock')}
                    label="Habilitar relógio:"
                    type="toggle"
                    isHorizontal={true}
                    activeText="Sim"
                    inactiveText="Não"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.association.has_clock || false}
                    disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
                  />

                  {this.props.association.has_clock ?
                    (
                      <React.Fragment>

                        <HorizontalRule />

                        {this.props.association.music_file_url !== null ? (
                          <div className="training-day-group-association-data__music-file">

                            <audio
                              className="training-day-group-association-data__music-file__audio-player"
                              controls
                              src={this.props.association.music_file_url}
                            >
                            </audio>

                            {this.props.userPermissionIds.includes(permissions.MANAGE_TRAINING_MUSIC_PERMISSION_ID) &&
                              <button
                                className="training-day-group-association-data__music-file__remove-button"
                                onClick={() => this.props.onRemoveClockAudioFile()}
                                disabled={this.state.loading}
                              >

                                <i className="fa-solid fa-trash-can training-day-group-association-data__music-file__remove-button__icon"></i>
                                {this.state.screenWidth <= 420 ? 'Remover' : ''}

                              </button>
                            }

                          </div>
                        ) : this.props.userPermissionIds.includes(permissions.MANAGE_TRAINING_MUSIC_PERMISSION_ID) ? (
                          <button
                            className="training-day-group-association-data__file-input__button"
                            onClick={() => this.onSelectAudioFile()}
                            disabled={this.state.loading}
                          >

                            <i className="fa-solid fa-arrow-up-from-bracket training-day-group-association-data__file-input__button__icon"></i>
                            Vincular música
                            <input
                              className="training-day-group-association-data__file-input__input"
                              ref={this.fileInputRef}
                              type="file"
                              accept="audio/*"
                            />

                          </button>
                        ) : null}

                        {(this.props.association.music_file_url !== null || this.props.userPermissionIds.includes(permissions.MANAGE_TRAINING_MUSIC_PERMISSION_ID)) &&
                          <HorizontalRule />
                        }

                        <DefaultInput
                          name="clock_method"
                          isHighlighted={this.isHighlighted("clock_method")}
                          label="Método de contagem"
                          type="select"
                          handleInputChange={this.props.handleInputChange}
                          value={this.props.association.clock_method || CLOCK_METHOD_STOPWATCH}
                          options={this.getClockMethodOptions()}
                          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
                        />

                        {this.getClockMethodConfiguration()}

                      </React.Fragment>
                    ):
                    null
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.ADD_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) &&
              <React.Fragment>

                <HorizontalRule />

                <section className="training-day-group-association-data__add-exercise">

                  <header
                    className="training-day-group-association-data__add-exercise__header"
                    onClick={() => this.setState({addExerciseSectionVisible: !this.state.addExerciseSectionVisible})}
                  >

                    <h3 className="training-day-group-association-data__add-exercise__header__text">
                      <i className="fas fa-plus training-day-group-association-data__add-exercise__header__text-icon"></i>
                      Adicionar exercício
                    </h3>

                    {this.state.addExerciseSectionVisible ?
                      <i className="fas fa-chevron-down training-day-group-association-data__add-exercise__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up training-day-group-association-data__add-exercise__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container training-day-group-association-data__add-exercise__content"
                    pose={this.state.addExerciseSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container training-day-group-association-data__add-exercise__content-wrapper">

                      <DefaultInput
                        name="serviceFilter"
                        isHighlighted={this.isHighlighted("serviceFilter")}
                        label="Filtrar treinamento"
                        type="select"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.serviceFilter || ''}
                        options={this.getServiceOptions()}
                      />

                      <HalfWrapper>

                        <DefaultInput
                          name="exerciseCategoryFilter"
                          label="Filtrar pilar"
                          labelMessage={this.state.serviceFilter.length > 0 ? 'Filtrado pelo serviço' : null}
                          type="select"
                          value={this.state.exerciseCategoryFilter}
                          handleInputChange={(event) => this.handleInputChange(event)}
                          options={this.getExerciseCategoryOptions()}
                        />

                        <DefaultInput
                          name="exerciseFunctionFilter"
                          label="Filtrar função"
                          type="select"
                          value={this.state.exerciseFunctionFilter}
                          handleInputChange={(event) => this.handleInputChange(event)}
                          options={this.getExerciseFunctionOptions()}
                        />

                      </HalfWrapper>

                      <DefaultInput
                        name="muscleGroupFilter"
                        label="Filtrar agrupamento muscular"
                        type="select"
                        value={this.state.muscleGroupFilter}
                        handleInputChange={(event) => this.handleInputChange(event)}
                        options={this.getMuscleGroupOptions()}
                      />

                      <HorizontalRule />

                      <ModelTable
                        properties={this.getProperties()}
                        getActions={(entry) => this.getActions(entry)}
                        data={this.getExercises()}
                        initialOrderBy="name"
                      >

                      </ModelTable>

                    </div>

                  </VerticalAccordionContainer>

                </section>

              </React.Fragment>
            }

            <HorizontalRule />

            <section className="training-day-group-association-data__support-table-configuration">

              <header
                className="training-day-group-association-data__support-table-configuration__header"
                onClick={() => this.setState({supportTableVisible: !this.state.supportTableVisible})}
              >

                <h3 className="training-day-group-association-data__support-table-configuration__header__text">
                  <i className="fas fa-table training-day-group-association-data__support-table-configuration__header__text-icon"></i>
                  Tabela auxiliar
                </h3>

                {this.state.supportTableVisible ?
                  <i className="fas fa-chevron-down training-day-group-association-data__support-table-configuration__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up training-day-group-association-data__support-table-configuration__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container training-day-group-association-data__support-table-configuration__content"
                pose={this.state.supportTableVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container training-day-group-association-data__support-table-configuration__content-wrapper">

                  <DefaultInput
                    name="has_support_table"
                    isHighlighted={this.isHighlighted('has_support_table')}
                    label="Definir tabela auxiliar:"
                    type="toggle"
                    isHorizontal={true}
                    activeText="Sim"
                    inactiveText="Não"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.association.has_support_table || false}
                    disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
                  />

                  {this.props.association.has_support_table ?
                    (
                      <React.Fragment>

                        <HorizontalRule />

                        <div className="training-day-group-association-data__support-table-configuration__table-wrapper">

                          <table className="training-day-group-association-data__support-table-configuration__table">

                            <thead>

                              <tr className="training-day-group-association-data__support-table-configuration__table__row">

                                <th className="training-day-group-association-data__support-table-configuration__table__header-cell">Nº Série</th>
                                <th className="training-day-group-association-data__support-table-configuration__table__header-cell">Repetições</th>

                                {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) &&
                                  <th></th>
                                }

                              </tr>

                            </thead>

                            <tbody>

                              {this.getSupportTableRows()}

                            </tbody>

                          </table>

                        </div>

                      </React.Fragment>
                    ):
                    null
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

            {this.isEditMode() && this.props.association.exercise_associations.length > 0 &&
              <React.Fragment>

                <DefaultSubSectionTitle
                  className="training-day-group-association-data__sub-section"
                  icon={<i className="fas fa-dumbbell"></i>}
                  text="Exercícios do agrupamento"
                />

                {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) &&
                  <DefaultInput
                    name="enableExerciseEdit"
                    label="Habilitar edição:"
                    type="toggle"
                    isHorizontal={true}
                    activeText="Sim"
                    inactiveText="Não"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.enableExerciseEdit}
                    horizontalAlign="right"
                  />
                }

                {this.state.enableExerciseEdit &&
                  <p className="training-day-group-association-data__alert-message">

                    <i className="fas fa-exclamation training-day-group-association-data__alert-message__icon"></i>
                    <span className="training-day-group-association-data__alert-message__text">
                      Ordem de exercícios somente será salvo ao clicar em salvar no final da página, enquanto que dados de repetições precisam ser salvos individualmente.
                    </span>

                  </p>
                }

                <div className="training-day-group-association-data__associations-container">

                  {this.props.association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS &&
                    <span
                      className="training-day-group-association-data__cycle-number"
                    >
                      {`${this.props.association.cycle_number}x`}
                    </span>
                  }

                  <div className="training-day-group-association-data__associations-wrapper">

                    {this.getGroupExerciseAssociations()}

                  </div>

                </div>

              </React.Fragment>
            }

            {statsData.exercisesCount > 0 &&
              <React.Fragment>

                <HorizontalRule />

                <section className="training-day-group-association-data__stats">

                  <header
                    className="training-day-group-association-data__stats__header"
                    onClick={() => this.setState({statsSectionVisible: !this.state.statsSectionVisible})}
                  >

                    <h3 className="training-day-group-association-data__stats__header__text">
                      <i className="far fa-chart-bar training-day-group-association-data__stats__header__text-icon"></i>
                      Resumo
                    </h3>

                    {this.state.statsSectionVisible ?
                      <i className="fas fa-chevron-down training-day-group-association-data__stats__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up training-day-group-association-data__stats__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container training-day-group-association-data__stats__content"
                    pose={this.state.statsSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container training-day-group-association-data__stats__content-wrapper">

                      <DefaultSubSectionTitle
                        className="training-day-group-association-data__sub-section"
                        icon={<i className="far fa-chart-bar"></i>}
                        text="Funções"
                      />

                      <VerticalBarGraph
                        xLabel="Função"
                        yLabel="Quantidade"
                        data={statsData.functionData}
                        height={this.getDefaultGraphHeight()}
                        yInterval={1}
                      />

                      <DefaultSubSectionTitle
                        className="training-day-group-association-data__sub-section"
                        icon={<i className="fas fa-chart-pie"></i>}
                        text="Pilares"
                      />

                      <PieGraph
                        data={statsData.categoryData}
                        height={this.getPieHeight()}
                        legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                        legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                        valueTextCallback={(value) => value}
                        indexLabel="{value}"
                        // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                        // defaultMultiplier={1}
                      />

                      <DefaultSubSectionTitle
                        className="training-day-group-association-data__sub-section"
                        icon={<i className="far fa-chart-bar"></i>}
                        text="Agrupamentos musculares"
                      />

                      <VerticalBarGraph
                        xLabel="Músculo alvo"
                        yLabel="Quantidade"
                        data={statsData.muscleGroupData}
                        height={this.getDefaultGraphHeight()}
                        yInterval={1}
                      />

                    </div>

                  </VerticalAccordionContainer>

                </section>

              </React.Fragment>
            }

          </div>

          <HorizontalRule />

          <div className="training-day-group-association-data__buttons-container">

            {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) &&
              <button
                className="training-day-group-association-data__save-button"
                disabled={!this.props.enableSave}
                onClick={this.props.onSave}
              >

                Salvar

              </button>
            }

            <Link
              className="training-day-group-association-data__cancel-button"
              to={this.props.onCancelPath}
            >

              Voltar

            </Link>

          </div>

        </DefaultSection>

      </React.Fragment>
    );
  }
}

export default TrainingDayGroupAssociationData;
