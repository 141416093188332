import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {SelectOption} from '../../../utils/default_input';
import {getModels, deleteModel, setUrlParameters} from '../../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';
import * as permissions from '../../../permissions';
import './appointment_room_list.scss';


class AppointmentRoomList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActive = queryParameters.get('is_active');

    if(!isActive) {
      isActive = 'true';
    }

    this.state = {
      isActive,
      isActiveInput: isActive,
      loadingData: true,
      appointment_rooms: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getAppointmentRooms() {
    const parameters = {};

    if (this.state.isActive !== 'all') {
      parameters.is_active = this.state.isActive;
    }

    return await getModels(setUrlParameters(routes.APPOINTMENT_ROOMS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const appointment_rooms = await this.getAppointmentRooms();

    this.setState({
      loadingData: false,
      appointment_rooms: appointment_rooms
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.APPOINTMENT_ROOM_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'appointment_schedules':
                    descriptions.push('Sala vinculada à períodos de atendimento');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Neste caso, sugere-se a desativação do cadastro.`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_APPOINTMENT_ROOM_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_APPOINTMENT_ROOM_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_APPOINTMENT_ROOM_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.APPOINTMENT_ROOM_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_APPOINTMENT_ROOM_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`appointment-room-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "appointment-room-list__description-cell"}),
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando sala';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar sala';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('all', 'Todos'),
    ];
  }

  mayUpdateFilterInputs() {
    if(this.state.isActiveInput !== this.state.isActive) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.isActiveInput !== 'all') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      isActiveInput: 'all',
    });
  }

  applyFilterInputChanges() {
    if(this.mayUpdateFilterInputs()) {
      this.props.history.replace(setUrlParameters(routes.APPOINTMENT_ROOM_LIST_PATH, {
        is_active: this.state.isActiveInput || 'all'
      }));

      this.setState({
        isActive: this.state.isActiveInput
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados a sala de atendimento serão removidos'}
          confirmText="Deletar sala"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.APPOINTMENT_ROOM_LIST_PATH,
              text: "Listar salas"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar salas"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="appointment-room-list"
            title="Lista de salas de atendimento"
          >

            <div className="appointment-list__filters">

              <header className="appointment-list__filters__header">

                <h4 className="appointment-list__filters__header__text">Filtros</h4>

              </header>

              <div className="appointment-list__filters__inputs">

                <div className="appointment-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveInput || ''}
                    options={this.getActiveOptions()}
                  />

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="appointment-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateFilterInputs() &&
                  <button
                    className="appointment-list__filters__refresh-button"
                    onClick={() => this.applyFilterInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.appointment_rooms}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_APPOINTMENT_ROOM_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.APPOINTMENT_ROOM_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova sala

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default AppointmentRoomList;
