import React from 'react';
import { Link } from 'react-router-dom';
import './operational_task_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import {getModels, deleteModel, setUrlParameters, getAsLocalDateString} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        OPERATIONAL_TASK_ROLE_MANAGEMENT,
        OPERATIONAL_TASK_ROLE_COACHES,
        OPERATIONAL_TASK_ROLE_RECEPTIONISTS} from '../../constants';
import ContextPopup from '../../components/context_popup';
import * as permissions from '../../permissions';

class OperationalTaskList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let statusFilter = queryParameters.get('status_filter');
    let responsibleIdFilter = queryParameters.get('responsible_id_filter');
    let targetServiceFilter = queryParameters.get('target_service_filter');
    let targetRoleFilter = queryParameters.get('target_role_filter');

    if(!statusFilter) {
      statusFilter = 'open';
    }
    if(!responsibleIdFilter) {
      responsibleIdFilter = 'any';
    }
    if(!targetServiceFilter) {
      targetServiceFilter = 'any';
    }
    if(!targetRoleFilter) {
      targetRoleFilter = 'any';
    }

    this.state = {
      statusFilter,
      statusFilterInput: statusFilter,
      responsibleIdFilter,
      responsibleIdFilterInput: responsibleIdFilter,
      targetServiceFilter,
      targetServiceFilterInput: targetServiceFilter,
      targetRoleFilter,
      targetRoleFilterInput: targetRoleFilter,
      loadingData: true,
      operational_tasks: [],
      users: [],
      services: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth
    };
  }

  async getOperationalTasks() {
    const parameters = {};

    if (this.state.statusFilter !== 'all') {
      parameters.status_filter = this.state.statusFilter;
    }
    if (this.state.responsibleIdFilter !== 'any') {
      parameters.responsible_id_filter = this.state.responsibleIdFilter;
    }
    if (this.state.targetServiceFilter !== 'any') {
      parameters.target_service_filter = this.state.targetServiceFilter;
    }
    if (this.state.targetRoleFilter !== 'any') {
      parameters.target_role_filter = this.state.targetRoleFilter;
    }

    return await getModels(setUrlParameters(routes.OPERATIONAL_TASKS_GET_API, parameters));
  }

  async getCoaches() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_COACH_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.COACHES_GET_API, parameters));
  }

  async getReceptionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.RECEPTIONISTS_GET_API, parameters));
  }

  async getManagers() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_MANAGER_USER_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.USERS_GET_API, parameters));
  }

  async getNutritionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_NUTRITIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.NUTRITIONISTS_GET_API, parameters));
  }

  async getServices() {
    return await getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
  }

  async componentDidMount() {
    let update = this.reloadList(false);

    let coaches = this.getCoaches();
    let receptionists = this.getReceptionists();
    let managers = this.getManagers();
    let nutritionists = this.getNutritionists();
    let services = this.getServices();

    update = await update;

    const userIdSet = new Set();
    update.users = [];

    coaches = await coaches;

    if (coaches) {
      for (const entry of coaches) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    receptionists = await receptionists;

    if (receptionists) {
      for (const entry of receptionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    managers = await managers;

    if (managers) {
      for (const entry of managers) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    nutritionists = await nutritionists;

    if (nutritionists) {
      for (const entry of nutritionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    services = await services;

    if (services) {
      update.services = services;
      update.services.sort((a, b) => a.localeCompare(b));
    }

    update.users.sort((a, b) => a.name.localeCompare(b.name));
    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.statusFilter !== this.state.statusFilter ||
        prevState.responsibleIdFilter !== this.state.responsibleIdFilter ||
        prevState.targetServiceFilter !== this.state.targetServiceFilter ||
        prevState.targetRoleFilter !== this.state.targetRoleFilter) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateState=true) {
    if (updateState) {
      this.setState({
        loadingData: true
      });
    }

    const update = {loadingData: false};

    const operational_tasks = await this.getOperationalTasks();

    update.operational_tasks = operational_tasks;
    
    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.OPERATIONAL_TASK_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_OPERATIONAL_TASK_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DELETE_OPERATIONAL_TASK_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_OPERATIONAL_TASK_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.OPERATIONAL_TASK_EDIT_PAGE}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_OPERATIONAL_TASK_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getStatusText(entry) {
    return (
      <div className="operational-task-list__cell-wrapper--centered">

        <p className={`operational-task-list__status-text`}>

          {entry.status}

        </p>

      </div>
    );
  }

  getPriorityText(entry) {
    if (entry.priority === 1) {
      return 'normal';
    }
    else if (entry.priority === 2) {
      return 'Alta';
    }
    else if (entry.priority === 3) {
      return 'Urgente';
    }

    return entry.priority;
  }

  getResponsibleText(entry) {
    let text = '';

    if (entry.target_role !== null) {
      text += entry.target_role;
    }
    if (entry.target_service !== null) {
      if (text.length > 0) {
        text += '|';
      }

      text += entry.target_service;
    }
    if (entry.responsible_id !== null) {
      if (text.length > 0) {
        text += '|';
      }

      text += entry.responsible.name;
    }

    return text;
  }

  getDateText(dateIsostring) {
    if (dateIsostring === null) {
      return '';
    }

    return getAsLocalDateString(dateIsostring);
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getProperties() {
    let properties = [
      Property('target_service', 'Setor/Serv./Resp.', <i className="fa-solid fa-users-rectangle"></i>, {
        getDataText: (entry) => this.getResponsibleText(entry),
        getFilterText: (entry) => this.getResponsibleText(entry),
        sortable: false
      }),
      Property('task', 'Tarefa', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => (
          <p className="operational-task-list__task-name">

              {entry.task}

              {entry.commentary && (
                <i
                  className="fa-solid fa-circle-info operational-task-list__task-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="operational-task-list__exercise-info">{entry.commentary}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.task
      }),
      Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: (entry) => entry.status,
      }),
      Property('priority', 'Prioridade', <i className="fa-solid fa-list-ol"></i>, {
        getDataText: (entry) => this.getPriorityText(entry),
        getFilterText: (entry) => this.getPriorityText(entry),
        getSortCallback: (a, b) => {
          if (a.priority !== b.priority) {
            return a.priority - b.priority;
          }

          return b.created_at.localeCompare(a.created_at);
        }
      }),
      Property('initiated_at', 'Iniciado em', <i className="fa-solid fa-calendar-day"></i>, {
        getDataText: (entry) => this.getDateText(entry.initiated_at),
        getFilterText: (entry) => this.getDateText(entry.initiated_at),
      }),
      Property('finished_at', 'Finalizado em', <i className="fa-solid fa-calendar-day"></i>, {
        getDataText: (entry) => this.getDateText(entry.finished_at),
        getFilterText: (entry) => this.getDateText(entry.finished_at),
      })
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar pendência';
  }

  getStatusFilterOptions() {
    return [
      SelectOption('open', 'Aberto'),
      SelectOption('closed', 'Fechado'),
      SelectOption('all', 'Todos'),
    ];
  }

  getResponsibleOptions() {
    let options = this.state.users.map((entry) => SelectOption(entry.id, entry.name));

    return [
      SelectOption('any', 'Não especificado'),
      ...options
    ];
  }

  getRoleOptions() {
    const options = [
      OPERATIONAL_TASK_ROLE_MANAGEMENT,
      OPERATIONAL_TASK_ROLE_COACHES,
      OPERATIONAL_TASK_ROLE_RECEPTIONISTS
    ];

    return [
      SelectOption('', 'Não especificado'),
      ...options.map((service) => SelectOption(service, service))
    ];
  }

  getServiceOptions() {
    return [
      SelectOption('', 'Não especificado'),
      ...this.state.services.map((service) => SelectOption(service, service))
    ];
  }

  mayUpdateFilterInputs() {
    if(this.state.statusFilterInput !== this.state.statusFilter ||
       this.state.responsibleIdFilterInput !== this.state.responsibleIdFilter ||
       this.state.targetServiceFilterInput !== this.state.targetServiceFilter ||
       this.state.targetRoleFilterInput !== this.state.targetRoleFilter) {
      return true;
    }
    
    return false;
  }

  mayResetFilterInputs() {
    if(this.state.statusFilterInput !== 'all' ||
       this.state.responsibleIdFilterInput !== 'any' ||
       this.state.targetServiceFilterInput !== 'any' ||
       this.state.targetRoleFilterInput !== 'any') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      statusFilterInput: 'all',
      responsibleIdFilterInput: 'any',
      targetServiceFilterInput: 'any',
      targetRoleFilterInput: 'any',
    });
  }

  applyFilterInputChanges() {
    if(this.mayUpdateFilterInputs()) {
      this.props.history.replace(setUrlParameters(routes.OPERATIONAL_TASK_LIST_PAGE, {
        status_filter: this.state.statusFilterInput || 'all',
        responsible_id_filter: this.state.responsibleIdFilterInput || 'any',
        target_service_filter: this.state.targetServiceFilterInput || 'any',
        target_role_filter: this.state.targetRoleFilterInput || 'any',
      }));

      this.setState({
        statusFilter: this.state.statusFilterInput,
        responsibleIdFilter: this.state.responsibleIdFilterInput,
        targetServiceFilter: this.state.targetServiceFilterInput,
        targetRoleFilter: this.state.targetRoleFilterInput,
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          className="operational-task-list__commentary-popup"
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados à pendência serão removidos.'}
          confirmText="Deletar pendência"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.OPERATIONAL_TASK_LIST_PAGE,
              text: "Listar pendências"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar pendências"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="operational-task-list"
            title="Lista de pendências"
          >

            <div className="operational-task-list__filters">

            <header className="operational-task-list__filters__header">

              <h4 className="operational-task-list__filters__header__text">Filtros</h4>

            </header>

            <div className="operational-task-list__filters__inputs">

              <div className="operational-task-list__filters__inputs-wrapper">

                <HalfWrapper>

                  <DefaultInput
                    name="statusFilterInput"
                    label="Situação"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.statusFilterInput || 'all'}
                    options={this.getStatusFilterOptions()}
                  />

                  <DefaultInput
                    name="responsibleIdFilterInput"
                    label="Responsável"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.responsibleIdFilterInput || 'any'}
                    options={this.getResponsibleOptions()}
                  />

                </HalfWrapper>

                <HalfWrapper>

                  <DefaultInput
                    name="targetServiceFilterInput"
                    label="Serviço vinculado"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.targetServiceFilterInput || 'any'}
                    options={this.getServiceOptions()}
                  />

                  <DefaultInput
                    name="targetRoleFilterInput"
                    label="Setor"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.targetRoleFilterInput || 'any'}
                    options={this.getRoleOptions()}
                  />

                </HalfWrapper>

              </div>

              {this.mayResetFilterInputs() &&
                <button
                  className="operational-task-list__filters__reset-button"
                  onClick={() => this.resetFilterInputs()}
                >

                  <i className="fas fa-times"></i>

                </button>
              }

              {this.mayUpdateFilterInputs() &&
                <button
                  className="operational-task-list__filters__refresh-button"
                  onClick={() => this.applyFilterInputChanges()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              }

            </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.operational_tasks}
              initialOrderBy="priority"
              initialOrderIsDecrescent={true}
            >

              {this.props.userPermissionIds.includes(permissions.ADD_OPERATIONAL_TASK_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.OPERATIONAL_TASK_ADD_PAGE}
                >

                  <i className="fas fa-plus"></i> Adicionar pendência

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default OperationalTaskList;
