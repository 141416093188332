import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {SERVICE_PERSONAL_SERVICE, TRAINING_PERIOD_OBJECTIVES, TRAINING_PERIOD_PHYSICAL_LEVELS} from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel, postModel, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './training_period_list.scss';


class TrainingPeriodList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActiveInput = queryParameters.get('is_active');
    let targetServiceInput = queryParameters.get('target_service');
    let mainObjectiveInput = queryParameters.get('main_objective');
    let physicalLevelInput = queryParameters.get('suggested_physical_level');
    let discProfileInput = queryParameters.get('suggested_disc_profile');
    let codeInput = queryParameters.get('code');
    let targetGenderInput = queryParameters.get('target_gender');

    if(!targetServiceInput) {
      targetServiceInput = '';
    }
    if(!mainObjectiveInput) {
      mainObjectiveInput = '';
    }
    if(!physicalLevelInput) {
      physicalLevelInput = '';
    }
    if(!discProfileInput) {
      discProfileInput = '';
    }
    if(!codeInput) {
      codeInput = '';
    }
    if(!targetGenderInput) {
      targetGenderInput = '';
    }
    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }

    this.state = {
      loadingData: true,
      targetServiceInput: targetServiceInput,
      targetServiceFilter: targetServiceInput,
      mainObjectiveInput: mainObjectiveInput,
      mainObjectiveFilter: mainObjectiveInput,
      physicalLevelInput: physicalLevelInput,
      physicalLevelFilter: physicalLevelInput,
      discProfileInput: discProfileInput,
      discProfileFilter: discProfileInput,
      codeInput: codeInput,
      codeFilter: codeInput,
      targetGenderInput: targetGenderInput,
      targetGenderFilter: targetGenderInput,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      training_periods: [],
      services: [],
      deleteId: null,
      trainingPeriodIdToDuplicate: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getTrainingPeriods() {
    const parameters = {};

    if(this.state.targetServiceFilter) {
      parameters.target_service = this.state.targetServiceFilter;
    }
    if(this.state.mainObjectiveFilter) {
      parameters.main_objective = this.state.mainObjectiveFilter;
    }
    if(this.state.physicalLevelFilter) {
      parameters.suggested_physical_level = this.state.physicalLevelFilter;
    }
    if(this.state.discProfileFilter) {
      parameters.suggested_disc_profile = this.state.discProfileFilter;
    }
    if(this.state.codeFilter) {
      parameters.code_filter = this.state.codeFilter;
    }
    if(this.state.targetGenderFilter) {
      parameters.target_gender = this.state.targetGenderFilter;
    }
    if(this.state.isActiveFilter.length > 0) {
      parameters.active_only = this.state.isActiveFilter;
    }

    if(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) {
      return await getModels(setUrlParameters(routes.TRAINING_PERIODS_GET_API, parameters));
    }

    parameters.active_only = true;

    return await getModels(setUrlParameters(routes.TRAINING_PERIODS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.targetServiceFilter !== this.state.targetServiceFilter ||
        prevState.mainObjectiveFilter !== this.state.mainObjectiveFilter ||
        prevState.physicalLevelFilter !== this.state.physicalLevelFilter ||
        prevState.discProfileFilter !== this.state.discProfileFilter ||
        prevState.targetGenderFilter !== this.state.targetGenderFilter ||
        prevState.isActiveFilter !== this.state.isActiveFilter ||
        prevState.codeFilter !== this.state.codeFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        training_periods: await this.getTrainingPeriods(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const update = {
      loadingData: false
    }

    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
    const training_periods = await this.getTrainingPeriods();

    if(training_periods) {
      update.training_periods = training_periods;
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDuplicateTrainingPeriod(entryId) {
    this.setState({
      trainingPeriodIdToDuplicate: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      trainingPeriodIdToDuplicate: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteId != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_PERIOD_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'schedules':
                      descriptions.push('Periodização já foi ativada. Os cadastros de ativação ' +
                                        'devem ser deletados antes de deletar a periodização');

                      break;
                    case 'classes':
                      descriptions.push('Periodização possui dados de alunos vinculados');

                      break;
                    case 'personal_data_entries':
                      descriptions.push('Periodização possui dados de alunos vinculados');

                      break;
                    case 'personal_history':
                      descriptions.push('Histórico de aluno vinculado com periodização');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Nesta situação, recomenda-se que o cadastro da periodização seja simplesmente desativado.`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {

      try{
        if(await postModel(`${routes.TRAINING_PERIOD_DUPLICATE_POST_API}${this.state.trainingPeriodIdToDuplicate}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 102:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'intensity_value':
                      const errorValue = parameter.value.split(';');
                      errorDescription = `Periodização possui um erro de configuração. Verifique o treino ${errorValue[0]}, agrupamento "${errorValue[1]}" da periodização desejada`;

                      break;
                    default:
                  }
                }

                break;
              case 103:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'name':
                      errorDescription = 'Periodização já duplicada. Para duplicar novamente esta periodização, altere o nome dá última cópia feita.'

                      break;
                    default:
                  }
                }

                break;
              case 106:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'training_exercise.is_active':
                      errorDescription = 'Periodização contem exercício inativado.'

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      trainingPeriodIdToDuplicate: null,
      confirmInProgress: false,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_PERIOD_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.TRAINING_PERIOD_EDIT_PATH}${entry.id}`}
          >

            {(entry.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) ?
              <i className="fas fa-edit"></i>:
              <i className="fas fa-eye"></i>
            }

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) &&
          <button
            className="model-table__default-link-button"
            onClick={() => this.onDuplicateTrainingPeriod(entry.id)}
          >

            <i className="fas fa-clone"></i>

          </button>
        }

        {(entry.editable && this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_PERIOD_PERMISSION_ID)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getStatusText(entry) {
    const status = this.getStatusFilterText(entry);

    return (
      <p className={`training-period-list__status-text--${status.toLowerCase()}`}>{status}</p>
    );
  }

  getStatusFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getProperties() {
    let properties = [];

    properties.push(
      Property('code', 'Código', <i className="fas fa-tag"></i>),
    );

    properties.push(
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getCellClassName: (entry) => entry.suggested_disc_profile !== null ? `training-period-list__name-cell--${entry.suggested_disc_profile.toLocaleLowerCase()}` : 'training-period-list__name-cell'
      })
    );
    properties.push(Property('target_gender', 'Gên.', <i className="fas fa-venus-mars"></i>));
    properties.push(Property('main_objective', 'Obj.', <i className="fas fa-bullseye"></i>));
    properties.push(Property('suggested_physical_level', 'Nvl fís.', <i className="fas fa-fist-raised"></i>));

    if(this.state.screenWidth > 710) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "training-period-list__description-cell"}),
      );
    }

    properties.push(
      Property('target_service', 'Serv.', <i className="fas fa-concierge-bell"></i>, {cellClassName: "training-period-list__target-service-cell"}),
    );

    if(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) {
      properties.push(Property('is_active', 'Sit.', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: this.getStatusFilterText,
        cellClassName: "training-period-list__status-cell"
      }));
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando periodização';
      }
      else if(this.state.trainingPeriodIdToDuplicate !== null) {
        return 'Duplicando periodização';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar';
      }
      else if(this.state.trainingPeriodIdToDuplicate !== null) {
        return 'Falha ao duplicar periodização';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar periodização';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Duplicar periodização';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'Todos os dados relacionados à periodização serão removidos';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Uma cópia da periodização selecionada será criada';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Deletar periodização';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Duplicar';
    }

    return 'Unknown';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  getGenderOptions() {
    return [
      SelectOption('', 'Todos'),
      SelectOption('Feminino', 'Feminino'),
      SelectOption('Masculino', 'Masculino'),
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.state.services,
      SERVICE_PERSONAL_SERVICE
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...services.map((service) => SelectOption(service, service))
    ];
  }

  getTrainingObjectiveOptions() {
    const objectives = [...TRAINING_PERIOD_OBJECTIVES];

    objectives.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  getSuggestedPhysicalLevelOptions() {
    const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

    return [
      SelectOption('', 'Todos'),
      ...levels.map((level) => SelectOption(level, level))
    ];
  }

  getSuggestedDiscProfileOptions() {
    const options = [
      SelectOption('', 'Todos'),
      SelectOption('D', 'D'),
      SelectOption('I', 'I'),
      SelectOption('S', 'S'),
      SelectOption('C', 'C')
    ];

    return options;
  }

  mayRefreshList() {
    if(this.state.targetServiceInput !== this.state.targetServiceFilter ||
       this.state.mainObjectiveInput !== this.state.mainObjectiveFilter ||
       this.state.physicalLevelInput !== this.state.physicalLevelFilter ||
       this.state.discProfileInput !== this.state.discProfileFilter ||
       this.state.targetGenderInput !== this.state.targetGenderFilter ||
       this.state.isActiveInput !== this.state.isActiveFilter ||
       this.state.codeInput !== this.state.codeFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.TRAINING_PERIOD_LIST_PATH, {
        target_service: this.state.targetServiceInput,
        main_objective: this.state.mainObjectiveInput,
        suggested_physical_level: this.state.physicalLevelInput,
        suggested_disc_profile: this.state.discProfileInput,
        target_gender: this.state.targetGenderInput,
        is_active: this.state.isActiveInput || 'all',
        code: this.state.codeInput
      }));

      this.setState({
        targetServiceFilter: this.state.targetServiceInput,
        mainObjectiveFilter: this.state.mainObjectiveInput,
        physicalLevelFilter: this.state.physicalLevelInput,
        discProfileFilter: this.state.discProfileInput,
        targetGenderFilter: this.state.targetGenderInput,
        codeFilter: this.state.codeInput,
        isActiveFilter: this.state.isActiveInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.trainingPeriodIdToDuplicate !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.TRAINING_PERIOD_LIST_PATH,
              text: "Listar periodizações"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar periodizações"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-period-list"
            title="Lista de periodizações"
          >

            <div className="training-period-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.TRAINING_PERIOD_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova periodização

                </Link>
              }

            </div>

            <div className="training-period-list__filters">

              <header className="training-period-list__filters__header">

                <h4 className="training-period-list__filters__header__text">Filtros</h4>

              </header>

              <div className="training-period-list__filters__inputs">

                <div className="training-period-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveInput || ''}
                    options={this.getActiveOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="codeInput"
                      label="Código do serviço"
                      type="text"
                      placeholder="Código do serviço"
                      maxLength="32"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.codeInput || ''}
                      autoComplete="off"
                    />

                    <DefaultInput
                      name="targetGenderInput"
                      label="Gênero alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.targetGenderInput || ''}
                      options={this.getGenderOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="targetServiceInput"
                      label="Serviço alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.targetServiceInput || ''}
                      options={this.getServiceOptions()}
                    />

                    <DefaultInput
                      name="mainObjectiveInput"
                      label="Objetivo do treinamento"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.mainObjectiveInput || ''}
                      options={this.getTrainingObjectiveOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="physicalLevelInput"
                      label="Nível físico sugerido"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.physicalLevelInput || ''}
                      options={this.getSuggestedPhysicalLevelOptions()}
                    />

                    <DefaultInput
                      name="discProfileInput"
                      label="Perfil DISC sugerido"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.discProfileInput || ''}
                      options={this.getSuggestedDiscProfileOptions()}
                    />

                  </HalfWrapper>

                </div>

                <button
                  className="training-period-list__filters__refresh-button"
                  onClick={() => this.refreshList()}
                  disabled={!this.mayRefreshList()}
                >

                  <i className="fas fa-sync"></i>

                </button>

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.training_periods}
              initialOrderBy="code"
            >
            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingPeriodList;
