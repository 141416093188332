
class BluetoothDevices {
    constructor() {
      this.bluetoothAvailable = false;

      this.initializedDevices = new Map();
      this.disconnectCallbackMap = new Map();
      this.reconnectCallbackMap = new Map();
      this.connectedDevices = new Map();
      this.deviceReconnectMap = new Map();
      this.routineIsUp = false;
    }

    async isBluetoothAvailable() {
      if(!this.bluetoothAvailable && navigator.bluetooth) {
        this.bluetoothAvailable = await navigator.bluetooth.getAvailability();
      }

      return this.bluetoothAvailable;
    }

    routine() {
      if(this.deviceReconnectMap.size > 0) {
        const now = Date.now();

        for(const entry of [...this.deviceReconnectMap.values()]) {
          if(entry.mayRetry && entry.reconnectAt <= now) {
            entry.mayRetry = false;

            this.connectToDevice(entry.device).then((response) => {
              if(response.error === null) {
                this.deviceReconnectMap.delete(entry.deviceName);
              }
              else {
                entry.reconnectAt = Date.now() + 2000;
                entry.mayRetry = true;
              }
            });
          }
        }

        requestAnimationFrame(this.routine.bind(this));
      }
      else {
        this.routineIsUp = false;
      }
    }

    startReconnectionProcess(deviceName) {
      if(!this.initializedDevices.has(deviceName)) {
        return;
      }

      const reconnectAt = Date.now() + 5000;

      this.deviceReconnectMap.set(deviceName, {
        deviceName,
        device: this.initializedDevices.get(deviceName),
        reconnectAt,
        mayRetry: true
      });

      if(!this.routineIsUp) {
        this.routineIsUp = true;

        requestAnimationFrame(this.routine.bind(this));
      }
    }

    async onDiconnectDevice(deviceName) {
      if(this.connectedDevices.has(deviceName)) {
        this.connectedDevices.delete(deviceName);
        this.startReconnectionProcess(deviceName);
      }

      if(this.disconnectCallbackMap.has(deviceName)) {
        this.disconnectCallbackMap.get(deviceName)();
      }
    }

    async connectToDevice(device, disconnectCallback=null, reconnectCallback=null) {
      let server = null;

      try {
        if(this.connectedDevices.has(device.name)) {
          server = this.connectedDevices.get(device.name);
        }
        else {
          server = await device.gatt.connect();

          this.connectedDevices.set(device.name, server);
        }
      }
      catch (error) {
        console.log(error);

        return {server: null, error: error.message};
      }

      if(disconnectCallback !== null) {
        this.disconnectCallbackMap.set(device.name, disconnectCallback);
      }

      if(reconnectCallback !== null) {
        this.reconnectCallbackMap.set(device.name, reconnectCallback);
        return await reconnectCallback(server)
      }
      else if(this.reconnectCallbackMap.has(device.name)) {
        return await this.reconnectCallbackMap.get(device.name)(server);
      }

      return {completed: true, error: null};
    }

    async disconnectAllDevices() {
      for(const devicename of [...this.initializedDevices.keys()]) {
        this.disconnectDevice(devicename);
      }
    }

    async disconnectDevice(deviceName) {
      this.deviceReconnectMap.delete(deviceName);
      this.disconnectCallbackMap.delete(deviceName);
      this.reconnectCallbackMap.delete(deviceName);

      if(!this.connectedDevices.has(deviceName)) {
        return;
      }

      const server = this.connectedDevices.get(deviceName);
      this.connectedDevices.delete(deviceName);
      server.disconnect();
    }

    async subscribeToCharacteristic(deviceName, serviceId, characteristicId, updateCallback, disconnectCallback) {
      const deviceResponse = await this.requestDevice(deviceName, [serviceId]);

      if(deviceResponse.error !== null) {
        return {completed: false, error: deviceResponse.error};
      }

      const device = deviceResponse.device;

      const onConnectCallback = async (server) => {
        try {
          const service = await server.getPrimaryService(serviceId);
          const characteristic = await service.getCharacteristic(characteristicId);

          await characteristic.startNotifications();
          characteristic.addEventListener('characteristicvaluechanged', (event) => {
            updateCallback(event.target.value);
          });
        }
        catch (error) {
          console.log(error);

          return {completed: false, error: error.message};
        }

        return {completed: true, error: null};
      };

      return await this.connectToDevice(device, disconnectCallback, onConnectCallback);
    }

    async requestDevice(deviceName=null, servicesRequired=null) {
      const filter = {};

      if(deviceName !== null) {
        if(this.initializedDevices.has(deviceName)) {
          return {device: this.initializedDevices.get(deviceName), error: null};
        }

        filter.name = deviceName;
      }

      if(servicesRequired !== null && servicesRequired instanceof Array) {
        filter.services = [...servicesRequired];
      }

      if(!await this.isBluetoothAvailable()) {
        return {device: null, error: "Serviço de bluetooth indisponível"};
      }

      let deviceRequested;

      try {
        deviceRequested = await navigator.bluetooth.requestDevice({filters: [filter]});
        deviceRequested.addEventListener('gattserverdisconnected', this.onDiconnectDevice.bind(this, deviceRequested.name));
      }
      catch (error) {
        if (error instanceof TypeError) {
          return {device: null, error: "TypeError"};
        }
        else if (error instanceof DOMException) {
          if(error.message.toLowerCase().includes('user cancelled')) {
            return {device: null, error: 'Sincronização cancelada'};
          }
          else {
            return {device: null, error: error.message};
          }
        }
      }

      this.initializedDevices.set(deviceRequested.name, deviceRequested);

      return {device: deviceRequested, error: null};
    }

    deviceHasInitialized(deviceName) {
      return this.initializedDevices.has(deviceName);
    }
}

export default BluetoothDevices;
