const TRAINING_DAY_EDIT_PATH = '/editar-treino/';

const TRAINING_DAY_POST_PATH = '/training-days';

const IMPORT_TRAINING_DAY_POST_API = '/import-training-day/';

export {
  TRAINING_DAY_EDIT_PATH,
  TRAINING_DAY_POST_PATH,
  IMPORT_TRAINING_DAY_POST_API
};
