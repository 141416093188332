import React from 'react';
import './feedback_report.scss';
import * as routes from '../../../constants';
import ContentFrame from '../../content_frame';
import {DefaultSubSectionTitle, HorizontalRule} from '../../../utils/default_section';
import {getModels} from '../../../utils/functions';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
// import PieGraph, {PiePoint} from '../../../graphs/pie_graph';
import VerticalBarGraph, {BarDataPoint} from '../../../graphs/vertical_bar_graph';


class FeedbackReport extends React.Component {
  constructor(props) {
    super(props);

    const initialDate = new Date();
    initialDate.setDate(1);
    initialDate.setMonth(initialDate.getMonth() - 3);
    const finalDate = new Date();

    this.state = {
      targetServiceInput: "",
      targetService: "",
      initialDate: initialDate.toISOString().slice(0, 10),
      initialDateInput: initialDate.toISOString().slice(0, 10),
      finalDate: finalDate.toISOString().slice(0, 10),
      finalDateInput: finalDate.toISOString().slice(0, 10),
      feedbacks: [],
      services: [],
      loadingData: true,
      screenWidth: window.innerWidth,
      graphContainerWidth: null
    };
  }

  async getFeedbacks() {
    let path = `${routes.FEEDBACKS_GET_API}?initial_reference_date=${this.state.initialDate}&final_reference_date=${this.state.finalDate}`;

    const target_service = this.state.targetService.trim();

    if(target_service.length > 0) {
      path = `${path}&target_service=${target_service}`;
    }

    return await getModels(path);
  }

  async getExperimentalClassesServices() {
    let services = await getModels(routes.EXPERIMENTAL_CLASS_SERVICES_GET_API);

    services.sort((a, b) => a.localeCompare(b));

    return services;
  }

  async refreshData(reloadServices=false) {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    const update = {
      loadingData: false
    }

    let feedbacks = this.getFeedbacks();

    if(reloadServices) {
      let services = await this.getExperimentalClassesServices();

      if(services) {
        update.services = services;
      }
    }

    feedbacks = await feedbacks;

    if(feedbacks) {
      update.feedbacks = feedbacks;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.refreshData(true);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate || prevState.targetService !== this.state.targetService) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.refreshData();
    }
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getDiscGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getGraphs() {
    const questionMap = new Map();

    for(let feedback of this.state.feedbacks) {
      for(let question of feedback.answers) {
        let answerMap;

        if(!questionMap.has(question.question_text)) {
          answerMap = new Map();
          questionMap.set(question.question_text, answerMap);
        }
        else {
          answerMap = questionMap.get(question.question_text);
        }

        for(let answer of question.value) {
          let count = 1;

          if(answerMap.has(answer)) {
            count = answerMap.get(answer) + 1;
          }

          answerMap.set(answer, count);
        }
      }
    }

    let graphs = [];

    for(let [questionText, answerMap] of questionMap.entries()) {
      const graphData = [...answerMap.entries()].map(([answerText, count]) => BarDataPoint(count, answerText));

      graphData.sort((a, b) => a.value - b.value).reverse(); // a.label.localeCompare(b.label)

      graphs.push(
        <React.Fragment key={`feedback_question_${questionText.replace(/\s+/g, '_').toLocaleLowerCase()}`}>
          <DefaultSubSectionTitle
            icon={<i className="fas fa-question-circle"></i>}
            text={questionText}
          />

          <VerticalBarGraph
            xLabel="Resposta"
            yLabel="Frequência"
            data={graphData}
            height={this.getDefaultGraphHeight()}
            animationEnabled={false}
          />

          <HorizontalRule />
        </React.Fragment>
      );
    }


    return graphs;
  }

  mayUpdateData() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate || this.state.targetServiceInput !== this.state.targetService) {
      return true;
    }

    return false;
  }

  applyInputChanges() {
    if(this.mayUpdateData()) {
      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
        targetService: this.state.targetServiceInput,
      });
    }
  }

  getServiceOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.services.map((service) => SelectOption(service, service))
    ];
  }

  render() {

    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FEEDBACK_REPORT_PATH,
            text: "Relatório de satisfação"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Relatório de satisfação"
        loading={this.state.loadingData}
      >

        <div className="feedback-report__wrapper">

          <div className="feedback-report__period-control">

            <h3 className="feedback-report__period-control__title">Período de avaliação</h3>

            <div className="feedback-report__inputs-container">

              <div className="feedback-report__inputs-wrapper">

                <DefaultInput
                  name="targetServiceInput"
                  label="Serviço"
                  type="select"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.targetServiceInput || ''}
                  options={this.getServiceOptions()}
                />

                <HalfWrapper>

                  <DefaultInput
                    name="initialDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data inicial"
                    type="date"
                    placeholder="Data inicial"
                    max={this.state.finalDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.initialDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                  <DefaultInput
                    name="finalDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data final"
                    type="date"
                    placeholder="Data final"
                    min={this.state.initialDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.finalDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                </HalfWrapper>

              </div>

              <button
                className="feedback-report__refresh-button"
                onClick={() => this.applyInputChanges()}
                disabled={!this.mayUpdateData()}
              >

                <i className="fas fa-sync"></i>

              </button>

            </div>

          </div>

          <HorizontalRule />

          <div className="feedback-report__indicator-container">

            <div className="feedback-report__indicator">

              <h2 className="feedback-report__indicator__label">Pesquisas no período:</h2>
              <p className="feedback-report__indicator__value">{this.state.feedbacks.length}</p>

            </div>

          </div>

          <HorizontalRule />

          {this.getGraphs()}

        </div>

      </ContentFrame>
    );
  }
}

export default FeedbackReport;
