import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';


class DateLinePoint {
  constructor(value, date, index=null, color=null) {
    this.value = value;
    this.date = date;
    this.index = index;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, x: this.date};

    if(this.index) {
      point.indexContent = this.index;
    }

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

class LinePoint {
  constructor(value, label, index=null, color=null) {
    this.value = value;
    this.label = label;
    this.index = index;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, label: this.label};

    if(this.index) {
      point.indexContent = this.index;
    }

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

export {DateLinePoint, LinePoint};


class SimpleLineGraph extends React.Component {
  getData() {
    const data = [
      {
        type: "line",
        color: this.props.lineColor,
        name: this.props.name,
        showInLegend: false,
        indexLabel: "{indexContent}",
        indexLabelFontWeight: "bold",
        indexLabelFontColor: "#333",
        dataPoints: this.props.data.map((point) => point.graphPoint)
      }
    ];

    return data;
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			// colorSet: "colorSet2",
      culture: 'pt-BR',
      axisY: {
				labelFormatter: (entry) => this.props.labelFormatter(entry.value)
			},
			data: this.getData()
		};

    if(this.props.title) {
      options.title = {
				text: this.props.title,
        fontFamily: "Open Sans, sans-serif",
        fontColor: "#333"
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.dataPointWidth ) {
      options.dataPointWidth  = this.props.dataPointWidth ;
    }

    if(this.props.stripLine) {
      options.axisY.stripLines = [this.props.stripLine];
    }

    if(this.props.xLabelAngle) {
      options.axisX.labelAngle = this.props.xLabelAngle;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
		<div className={this.props.className}>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
				onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default SimpleLineGraph;
