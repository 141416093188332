import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel, getModels} from '../../utils/functions';
import ExerciseCategoryData from './exercise_category_data';

class ExerciseCategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercise_category: {
        name: "",
        description: "",
        target_service: "",
      },
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    let exercise_category = getModel(`${routes.EXERCISE_CATEGORY_GET_API}${this.props.match.params.exerciseCategoryId}`);
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    exercise_category = await exercise_category;

    let update = {
      loading: false
    }

    if(exercise_category) {
      update.exercise_category = exercise_category;
    }
    else {
      this.props.history.replace(routes.EXERCISE_CATEGORY_LIST_PATH);
      return;
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const newData = {...this.state.exercise_category, [name]: value};

    this.setState({
      exercise_category: newData
    });
  }

  inputsAreValid() {
    return this.state.exercise_category.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.exercise_category}

    try {
      await patchModel(`${routes.EXERCISE_CATEGORY_PATCH_API}${this.props.match.params.exerciseCategoryId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.EXERCISE_CATEGORY_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.EXERCISE_CATEGORY_LIST_PATH,
            text: this.state.screenWidth > 480 ? 'Listar pilares de exercícios' : 'Pilares de exercícios'
          },
          {
            path: `${routes.EXERCISE_CATEGORY_EDIT_PATH}${this.props.match.params.exerciseCategoryId}`,
            text: "Editar pilar"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar pilar de exercício"
        loading={this.state.loading}
      >

        <ExerciseCategoryData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          exercise_category={this.state.exercise_category}
          services={this.state.services}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.EXERCISE_CATEGORY_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default ExerciseCategoryEdit;
