import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {patchModel, getModels, getModel} from '../../utils/functions';
import TrainingScheduleActivePeriodData from './training_schedule_active_period_data';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';

class TrainingScheduleActivePeriodEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: {
        training_period_id: "",
        start_date: ""
      },
      trainingPeriods: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loadingData: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    let trainingPeriods = getModels(`${routes.TRAINING_PERIODS_GET_API}`);
    let schedule = getModel(`${routes.TRAINING_PERIOD_SCHEDULE_GET_API}${this.props.match.params.trainingPeriodScheduleId}`);

    const update = {
      loadingData: false
    }

    trainingPeriods = await trainingPeriods;

    if(trainingPeriods) {
      update.trainingPeriods = trainingPeriods;
    }

    schedule = await schedule;

    if(schedule) {
      update.schedule = {...this.state.schedule, ...schedule};
      update.schedule.training_period_id = update.schedule.period_id;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.schedule, [name]: value};

    this.setState({
      schedule: newData
    });
  }

  inputsAreValid() {
    return this.state.schedule.start_date.length > 0 &&
           this.state.schedule.training_period_id > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loadingData: true
    });

    const data = {...this.state.schedule}
    data.training_period_id = parseInt(data.training_period_id);

    try {
      await patchModel(`${routes.TRAINING_PERIOD_SCHEDULE_PATCH_API}${this.props.match.params.trainingPeriodScheduleId}`, data);
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada']

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loadingData: false
      });

      return;
    }

    this.props.history.replace(routes.TRAINING_SCHEDULE_MANAGE_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.TRAINING_SCHEDULE_MANAGE_PATH,
            text: "Calendário de treinos"
          },
          {
            path: `${routes.TRAINING_SCHEDULE_EDIT_ACTIVE_PERIOD_PATH}${this.props.match.params.trainingPeriodScheduleId}`,
            text: "Editar periodização ativada"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar periodização ativada"
        loading={this.state.loadingData}
      >

        <TrainingScheduleActivePeriodData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          schedule={this.state.schedule}
          trainingPeriods={this.state.trainingPeriods}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.TRAINING_SCHEDULE_MANAGE_PATH}
        />

      </ContentFrame>
    );
  }
}

export default TrainingScheduleActivePeriodEdit;
