import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, postModel} from '../../utils/functions';
import HrDeviceData from './hr_device_data';

class HrDeviceAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: {
        device_identifier: "",
        user_id: null,
        name: "",
        target_service: "",
      },
      services: [],
      highlights: [],
      hasBluetooth: false,
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

      const update = {
        loading: false
      }

      services = await services;

      if(services) {
        update.services = services;
        update.services.sort((a, b) => a.localeCompare(b));
      }

      if(navigator.bluetooth) {
        update.hasBluetooth = await navigator.bluetooth.getAvailability();
      }

      this.setState(update);
    }
    catch(errors) {
      this.props.history.replace(routes.HR_DEVICE_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.device, [name]: value};

    this.setState({
      device: newData
    });
  }

  inputsAreValid() {
    return this.state.device.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.device};

    try {
      await postModel(routes.HR_DEVICE_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'identifiers':
                    warningMessages.push('Dispositivo já cadastro. Conjunto nome, ID, serviço e usuário precisam ser únicos');
                    highlights.push('name');
                    highlights.push('device_identifier');
                    highlights.push('user_id');
                    highlights.push('target_service');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.HR_DEVICE_LIST_PATH);
  }

  onSyncToDevice() {
    navigator.bluetooth.requestDevice({
      filters: [{services: ['heart_rate']}]
    })
    .then(device => {
      this.setState({device: {...this.state.device, device_identifier: device.name}});
    })
    .catch(error => { console.log(error); });
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.HR_DEVICE_LIST_PATH,
            text: "Lista de frequencímetros"
          },
          {
            path: routes.HR_DEVICE_ADD_PATH,
            text: "Adicionar frequencímetro"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar frequencímetro"
        loading={this.state.loading}
      >

        <HrDeviceData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          device={this.state.device}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.HR_DEVICE_LIST_PATH}
          services={this.state.services}
          hasBluetooth={this.state.hasBluetooth}
          onSyncToDevice={() => this.onSyncToDevice()}
        />

      </ContentFrame>
    );
  }
}

export default HrDeviceAdd;
