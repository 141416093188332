import React from 'react';
import { Link } from 'react-router-dom';
import './horizontal_menu.scss';

class HorizontalMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getLinks() {
    return this.props.links.map((link, position) => (
      <li
        className="horizontal-menu__link-wrapper"
        key={`horizontal_menu_link_${position}`}
      >

        <Link
          className={`horizontal-menu__link${this.props.location.pathname === link.to ? '--selected' : ''}`}
          to={link.to}
        >

          {link.icon}
          <span className="horizontal-menu__link__text">

            {link.text}

          </span>

        </Link>

      </li>
    ));
  }

  render() {
    return (
      <nav className="horizontal-menu">

        <ul className="horizontal-menu__links-container">

          {this.getLinks()}

        </ul>

      </nav>
    );
  }
}

export default HorizontalMenu;
