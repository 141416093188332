import React from 'react';
import { Link } from 'react-router-dom';
import './meal_period_list.scss';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection from '../../../utils/default_section';
import {getModels, deleteModel} from '../../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';
import * as permissions from '../../../permissions';

class MealPeriodList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      meal_periods: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getMealPeriods() {
    return await getModels(routes.MEAL_PERIODS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const meal_periods = await this.getMealPeriods();

    this.setState({
      loadingData: false,
      meal_periods: meal_periods
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.MEAL_PERIOD_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'food_prescription_associations':
                    descriptions.push('Período vinculado à uma dieta');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar o período de refeição`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_MEAL_PERIOD_PERMISSION) || this.props.userPermissionIds.includes(permissions.DELETE_MEAL_PERIOD_PERMISSION);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_MEAL_PERIOD_PERMISSION) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.MEAL_PERIOD_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_MEAL_PERIOD_PERMISSION) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getVisibleAtHistoryFormFilterText(entry) {
    return entry.visible_at_history_form ? 'Presente' : 'Oculto';
  }

  getVisibleAtHistoryFormText(entry) {
    const text = entry.visible_at_history_form ? 'Presente' : 'Oculto';

    return (
      <p className={`meal-period-list__is-active-text${entry.visible_at_history_form ? '--green' : '--red'}`}>{text}</p>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('start_time', 'Hora inicial', <i className="fas fa-clock"></i>),
      Property('end_time', 'Hora final', <i className="fas fa-clock"></i>),
      Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "meal-period-list__description-cell"}),
      Property('visible_at_history_form', 'Histórico', <i className="fa-solid fa-list-check"></i>, {getDataText: this.getVisibleAtHistoryFormText, getFilterText: this.getVisibleAtHistoryFormFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando período de refeição';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar período de refeição';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao período de refeição serão removidos'}
          confirmText="Deletar período"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.MEAL_PERIOD_LIST_PATH,
              text: "Listar períodos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar períodos"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="meal-period-list"
            title="Lista de períodos de refeições"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.meal_periods}
              initialOrderBy="start_time"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_MEAL_PERIOD_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={routes.MEAL_PERIOD_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo período

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default MealPeriodList;
