import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {BOTH_TYPE_NAME,
        EXPENSE_TYPE_NAME,
        INCOME_TYPE_NAME} from '../../constants';
import './financial_search_tag_data.scss';


class FinancialSearchTagData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceFilter: "",
      servicesSectionVisible: false
    };
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.financial_search_tag.id && this.props.financial_search_tag.id > 0;
  }

  getOwnerOptions() {
    let options = this.props.users.map((entry) => SelectOption(entry.id, entry.name));

    return [
      SelectOption('', 'Não vinculado'),
      ...options
    ];
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <DefaultSection
        className="financial-search-tag-data"
        title="Dados da palavra-chave (financeiro)"
      >

        <div className="financial-search-tag-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="financial-search-tag-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Palavra-chave"
              type="text"
              placeholder="Palavra-chave"
              maxLength="64"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_search_tag.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="type"
              isHighlighted={this.isHighlighted("type")}
              label="Tipo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_search_tag.type}
              options={[
                SelectOption(BOTH_TYPE_NAME),
                SelectOption(EXPENSE_TYPE_NAME),
                SelectOption(INCOME_TYPE_NAME),
              ]}
              // disabled={this.isEditMode()}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da palavra-chave"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.financial_search_tag.description || ''}
          />

        </div>

        <HorizontalRule />

        <div className="financial-search-tag-data__buttons-container">

          <button
            className="financial-search-tag-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="financial-search-tag-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default FinancialSearchTagData;
