import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import './personal_training_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import * as permissions from '../../permissions';

const DIFFUCULTY_OPTION_EASY = 'Adaptado';
const DIFFUCULTY_OPTION_NORMAL = 'Normal';
const DIFFUCULTY_OPTION_INTERMEDIATE = '*';
const DIFFUCULTY_OPTION_ADVANCED = 'AV';

class PersonalTrainingData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supportTableVisible: false,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getDifficultyOptions() {
    const options = [
      DIFFUCULTY_OPTION_EASY,
      DIFFUCULTY_OPTION_NORMAL,
      DIFFUCULTY_OPTION_INTERMEDIATE,
      DIFFUCULTY_OPTION_ADVANCED,
    ];

    return [
      SelectOption('', 'Selecione uma dificuldade'),
      ...options.map((option) => SelectOption(option, option))
    ];
  }

  hasSupportTable() {
    if(!this.props.personal_training_data || !this.props.personal_training_data.training_day) {
      return false;
    }

    return this.props.personal_training_data.training_day.group_associations.some((association) => association.has_support_table);
  }

  getSupportTables() {
    const filteredAssociations = this.props.personal_training_data.training_day.group_associations.filter((association) => association.has_support_table);

    return filteredAssociations.map((association) => {
      const rows = association.support_table.map((row, index) => (
        <div
          className="personal-training-data__support-table__table__row-wrapper"
          key={`training_day_group_association:${association.id}:support_table:row:${index}`}
        >

          <p className="personal-training-data__support-table__table__cell">
            {row[0]}
          </p>

          <p className="personal-training-data__support-table__table__cell">
            {row[1]}
          </p>

        </div>
      ));

      return (
        <div
          className="personal-training-data__support-table__table"
          key={`training_day_group_association:${association.id}:support_table`}
        >

          <div className="personal-training-data__support-table__table__header">

            <h3 className="personal-training-data__support-table__table__title">
              {association.name}
            </h3>

          </div>

          <div className="personal-training-data__support-table__table__row-wrapper">

            <p className="personal-training-data__support-table__table__column-label">
              Nº Série
            </p>

            <p className="personal-training-data__support-table__table__column-label">
              Repetições
            </p>

          </div>

          {rows}

        </div>
      );
    });
  }

  render() {
    let minutes = '';
    let seconds = '';

    if(this.props.personal_training_data.total_seconds) {
      minutes = Math.floor(this.props.personal_training_data.total_seconds / 60);
      seconds = this.props.personal_training_data.total_seconds % 60;
    }

    let workoutMinutes = '';
    let workoutSeconds = '';

    if(this.props.personal_training_data.workout_time) {
      workoutMinutes = Math.floor(this.props.personal_training_data.workout_time / 60);
      workoutSeconds = this.props.personal_training_data.workout_time % 60;
    }

    return (
      <DefaultSection
        className="personal-training-data"
        title="Dados do serviço"
      >

        <div className="personal-training-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="personal-training-data__input-container">

          <DefaultInput
            name="training_day_name"
            label="Treino"
            type="text"
            placeholder="Nome do treino"
            maxLength="128"
            value={this.props.personal_training_data.training_day.name}
            autoComplete="off"
            disabled={true}
          />

          <DefaultInput
            name="date"
            label="Data de execução:"
            type="date"
            placeholder="Data de execução"
            handleInputChange={this.props.handleInputChange}
            value={this.props.personal_training_data.date}
            autoComplete="off"
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
          />

          <HalfWrapper>

            <DefaultInput
              name="difficulty"
              isHighlighted={this.isHighlighted("difficulty")}
              label="Dificuldade:"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.personal_training_data.difficulty || ''}
              options={this.getDifficultyOptions()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

            <DefaultInput
              name="total_repetitions"
              label="Repetições:"
              type="number"
              placeholder="-"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.personal_training_data.total_repetitions || ''}
              autoComplete="off"
              suffix="reps"
              onFocus={(event) => event.target.select()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="minutes"
              label="Tempo total:"
              type="number"
              placeholder="-"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={minutes}
              autoComplete="off"
              suffix={this.state.screenWidth > 680 ? 'minutos' : 'min'}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

            <DefaultInput
              className="personal-training-data__seconds-input"
              name="seconds"
              type="number"
              placeholder="-"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={seconds}
              autoComplete="off"
              suffix={this.state.screenWidth > 680 ? 'segundos' : 'seg'}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="workoutMinutes"
              label="Tempo do workout:"
              type="number"
              placeholder="-"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={workoutMinutes}
              autoComplete="off"
              suffix={this.state.screenWidth > 680 ? 'minutos' : 'min'}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

            <DefaultInput
              className="personal-training-data__seconds-input"
              name="workoutSeconds"
              type="number"
              placeholder="-"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={workoutSeconds}
              autoComplete="off"
              suffix={this.state.screenWidth > 680 ? 'segundos' : 'seg'}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
            />

          </HalfWrapper>

          <DefaultInput
            name="total_weight"
            label="Kilagem média total:"
            type="number"
            placeholder="-"
            min="0"
            step="0.1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.personal_training_data.total_weight || ''}
            autoComplete="off"
            suffix="kg"
            onFocus={(event) => event.target.select()}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)}
          />

          <div className="personal-training-data__input-wrapper--vertical">

            <h4 className="personal-training-data__input-label">Percepção Subjetiva de Esforço (PSE):</h4>

            <div className="personal-training-data__notification-container">

              <i className="fas fa-exclamation personal-training-data__notification-container__icon"></i>

              <p className="personal-training-data__notification-container__text">

                O preenchimento da PSE deve ser feito cerca de 20-30 minutos após o fim da atividade física para
                utilizar nossa metodologia de análise de carga de forma precisa.

              </p>

            </div>

            <ul className="personal-training-data__scale">

              <li className="personal-training-data__scale__group">

                <div className="personal-training-data__scale__group__image--2">

                  <i className="fas fa-kiss-beam personal-training-data__scale__group__image-icon"></i>

                </div>

                <div className="personal-training-data__scale__group__options">

                  <button
                    className="personal-training-data__scale__group__option--1"
                    onClick={() => this.props.onSetPse(1)}
                    disabled={this.props.personal_training_data.pse === 1}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      1
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Muito leve
                    </p>

                  </button>

                  <button
                    className="personal-training-data__scale__group__option--2"
                    onClick={() => this.props.onSetPse(2)}
                    disabled={this.props.personal_training_data.pse === 2}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      2
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Leve
                    </p>

                  </button>

                </div>

              </li>

              <li className="personal-training-data__scale__group">

                <div className="personal-training-data__scale__group__image--4">

                  <i className="fas fa-grin personal-training-data__scale__group__image-icon"></i>

                </div>

                <div className="personal-training-data__scale__group__options">

                  <button
                    className="personal-training-data__scale__group__option--3"
                    onClick={() => this.props.onSetPse(3)}
                    disabled={this.props.personal_training_data.pse === 3}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      3
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Tranquilo
                    </p>

                  </button>

                  <button
                    className="personal-training-data__scale__group__option--4"
                    onClick={() => this.props.onSetPse(4)}
                    disabled={this.props.personal_training_data.pse === 4}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      4
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Moderado
                    </p>

                  </button>

                </div>

              </li>

              <li className="personal-training-data__scale__group">

                <div className="personal-training-data__scale__group__image--6">

                  <i className="fas fa-meh personal-training-data__scale__group__image-icon"></i>

                </div>

                <div className="personal-training-data__scale__group__options">

                  <button
                    className="personal-training-data__scale__group__option--5"
                    onClick={() => this.props.onSetPse(5)}
                    disabled={this.props.personal_training_data.pse === 5}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      5
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Requer esforço
                    </p>

                  </button>

                  <button
                    className="personal-training-data__scale__group__option--6"
                    onClick={() => this.props.onSetPse(6)}
                    disabled={this.props.personal_training_data.pse === 6}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      6
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Pouco pesado
                    </p>

                  </button>

                </div>

              </li>

              <li className="personal-training-data__scale__group">

                <div className="personal-training-data__scale__group__image--8">

                  <i className="fas fa-frown-open personal-training-data__scale__group__image-icon"></i>

                </div>

                <div className="personal-training-data__scale__group__options">

                  <button
                    className="personal-training-data__scale__group__option--7"
                    onClick={() => this.props.onSetPse(7)}
                    disabled={this.props.personal_training_data.pse === 7}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      7
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Pesado
                    </p>

                  </button>

                  <button
                    className="personal-training-data__scale__group__option--8"
                    onClick={() => this.props.onSetPse(8)}
                    disabled={this.props.personal_training_data.pse === 8}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      8
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Muito pesado
                    </p>

                  </button>

                </div>

              </li>

              <li className="personal-training-data__scale__group">

                <div className="personal-training-data__scale__group__image--10">

                  <i className="fas fa-tired personal-training-data__scale__group__image-icon"></i>

                </div>

                <div className="personal-training-data__scale__group__options">

                  <button
                    className="personal-training-data__scale__group__option--9"
                    onClick={() => this.props.onSetPse(9)}
                    disabled={this.props.personal_training_data.pse === 9}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      9
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Extremamente pesado
                    </p>

                  </button>

                  <button
                    className="personal-training-data__scale__group__option--10"
                    onClick={() => this.props.onSetPse(10)}
                    disabled={this.props.personal_training_data.pse === 10}
                  >

                    <p className="personal-training-data__scale__group__option-number">
                      10
                    </p>

                    <p className="personal-training-data__scale__group__option-text--flex">
                      Quase impossível
                    </p>

                  </button>

                </div>

              </li>

            </ul>

            <p className="personal-training-data__small-note">

              *A PSE é uma estimativa para quantificar o esforço físico necessário para realizar a atividade
              proposta e está diretamente relacionada com o cansaço físico persistente depois de sua execução.

            </p>

          </div>

        </div>

        {this.hasSupportTable() &&
          <React.Fragment>

            <HorizontalRule />

            <section className="personal-training-data__support-table-section">

              <header
                className="personal-training-data__support-table-section__header"
                onClick={() => this.setState({supportTableVisible: !this.state.supportTableVisible})}
              >

                <h3 className="personal-training-data__support-table-section__header__text">
                  <i className="fas fa-info-circle personal-training-data__support-table-section__header__text-icon"></i>
                  Tabela auxiliar
                </h3>

                {this.state.supportTableVisible ?
                  <i className="fas fa-chevron-down personal-training-data__support-table-section__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training-data__support-table-section__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training-data__support-table-section__content"
                pose={this.state.supportTableVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container personal-training-data__support-table-section__content-wrapper">

                  {this.getSupportTables()}

                </div>

              </VerticalAccordionContainer>

            </section>

          </React.Fragment>
        }

        <HorizontalRule />

        <div className="personal-training-data__buttons-container">

          {this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION) &&
            <button
              className="personal-training-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <Link
            className="personal-training-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION) ? 'Cancelar' : 'Voltar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default PersonalTrainingData;
