import React from 'react';
import { Link } from 'react-router-dom';
import './service_group_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {SERVICE_COLOR_MAP} from '../../constants';

class ServiceGroupData extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //   };
  // }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  enableAllTimes() {
    return this.props.restriction_map[this.props.activeService] ? this.props.restriction_map[this.props.activeService].enable_all : false;
  }

  getWebsiteColorOptions() {
    const options = [
      ...Object.entries(SERVICE_COLOR_MAP).map(([key, value]) => SelectOption(value, key))
    ];

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  }

  render() {
    return (
      <DefaultSection
        className="service-group-data"
        title="Dados do agrupamento"
      >

        <div className="service-group-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="service-group-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.service_group.is_active}
            horizontalAlign="right"
          />

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do agrupamento"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service_group.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="order"
              isHighlighted={this.isHighlighted("order")}
              label="Prioridade"
              type="number"
              placeholder="Prioridade de exibição"
              min="1"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service_group.order}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <div className="service-group-data__website-color__color-input-container">

            <div
              className="service-group-data__website-color__color-preview"
              style={{background: this.props.service_group.color || SERVICE_COLOR_MAP['Vermelho']}}
            >
            </div>

            <DefaultInput
              name="color"
              isHighlighted={this.isHighlighted("color")}
              label="Cor"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.service_group.color || SERVICE_COLOR_MAP['Vermelho']}
              options={this.getWebsiteColorOptions()}
            />

          </div>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do serviço"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.service_group.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="service-group-data__buttons-container">

          <button
            className="service-group-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="service-group-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ServiceGroupData;
