import React from 'react';
import { PoseGroup } from 'react-pose';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import {getModels, postModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE} from '../../constants';
import DefaultInput from '../../utils/default_input';
import {HorizontalContainer, FadeContainer} from '../../utils/pose_containers';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import './training_time.scss';

class TrainingTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: true,
      activeService: '',
      training_times: [],
      services: [],
      class_duration_map: {},
      original_class_duration_map: {},
      checkin_limit_map: {},
      original_checkin_limit_map: {},
      idsToDelete: [],
      timesToAdd: [],
      trainingTimeToUpdate: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      selectedDay: 0,
      novoHorario: "",
      showDataSaved: false,
    };
  }

  async componentDidMount() {
    let available_calendar = await getModels(routes.TRAINING_TIMES_GET_API);
    const services = [];
    let activeService = '';
    let class_duration_map = {};
    let checkin_limit_map = {};

    if(available_calendar) {
      for(let service of available_calendar.services) {
        services.push(service);

        if(!activeService) {
          activeService = service;
        }
      }

      if(available_calendar.class_duration_map) {
        class_duration_map = available_calendar.class_duration_map;
      }

      if(available_calendar.checkin_limit_map) {
        checkin_limit_map = available_calendar.checkin_limit_map;
      }

      services.sort((a, b) => a.localeCompare(b));

      const training_times = available_calendar.training_times.map((entry) => {
        entry.updated = false;

        return entry;
      });

      this.setState({
        training_times,
        activeService,
        services,
        class_duration_map,
        original_class_duration_map: {...class_duration_map},
        checkin_limit_map,
        original_checkin_limit_map: {...checkin_limit_map},
        loadingData: false,
      });
    }
    else {
      this.props.history.replace(routes.DESKTOP_PATH);
      return;
    }

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    if(name === 'time' || name === 'target_student_count') {
      if (name === 'target_student_count' && value.length <= 0) {
        value = null;
      }

      const trainingTimeToUpdate = {
        ...this.state.trainingTimeToUpdate,
        [name]: value
      };

      this.setState({
        trainingTimeToUpdate
      });
    }
  }

  async onSave() {
    if(!this.state.activeService) {
      return;
    }

    this.setState({
      loadingData: true
    });

    const class_duration_map = {};

    for(const [key, value] of Object.entries(this.state.class_duration_map)) {
      class_duration_map[key] = parseInt(value);
    }

    const checkin_limit_map = {};

    for(const [key, value] of Object.entries(this.state.checkin_limit_map)) {
      checkin_limit_map[key] = parseInt(value);
    }

    const data = {
      delete_ids: this.state.idsToDelete,
      new_training_times: this.state.timesToAdd,
      updated_training_times: this.state.training_times.filter((entry) => entry.updated),
      class_duration_map,
      checkin_limit_map
    };

    const update = {};

    try {
      const response = await postModel(routes.TRAINING_TIMES_POST_API, data, true);
      update.training_times = response.training_times;
      update.idsToDelete = [];
      update.timesToAdd = [];
    }
    catch(errors) {
      window.alert(DEFAULT_UNKNOWN_ERROR_MESSAGE);

      this.setState({
        loadingData: false,
      });

      return;
    }

    this.setState({
      loadingData: false,
      showDataSaved: true,
      class_duration_map,
      original_class_duration_map: {...class_duration_map},
      checkin_limit_map,
      original_checkin_limit_map: {...checkin_limit_map},
      ...update
    });

    setTimeout(() => this.setState({showDataSaved: false}), 1500);
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onAcceptConfirmation() {
    if (this.state.deleteId === null) {
      return;
    }

    if(this.state.deleteId.id) {
      const idsToDelete = [...this.state.idsToDelete];

      idsToDelete.push(this.state.deleteId.id);

      this.setState({
        deleteId: null,
        idsToDelete
      });
    }
    else {
      const timesToAdd = [...this.state.timesToAdd];

      timesToAdd.splice(this.state.timesToAdd.indexOf(this.state.deleteId), 1);

      this.setState({
        deleteId: null,
        timesToAdd
      });
    }
  }

  getDays() {
    if(!this.state.activeService) {
      return;
    }

    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap.map((day, dayId) => {
      return (
        <React.Fragment key={`training_time:day:${dayId}`}>

          <input
            type="radio"
            id={`training_time:day:${dayId}`}
            name="day"
            value={dayId}
            onChange={() => this.setState({selectedDay: dayId})}
            className="training-time__days__input"
            checked={dayId === this.state.selectedDay}
          />

          <label
            htmlFor={`training_time:day:${dayId}`}
            className="training-time__days__label"
            dayid={dayId}
          >

            <span>{day}</span>

          </label>

        </React.Fragment>
      );
    });
  }

  getHours() {
    if(!this.state.activeService) {
      return;
    }

    const filteredTimes = [
      ...this.state.training_times.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.state.activeService && !this.state.idsToDelete.includes(entry.id)),
      ...this.state.timesToAdd.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.state.activeService)
    ];

    if(filteredTimes.length <= 0) {
      return null;
    }

    filteredTimes.sort((a, b) => a.time.localeCompare(b.time));

    return filteredTimes.map((entry) => {
      return (
        <HorizontalContainer
          key={entry.id ? `training_time:${entry.id}` : `training_time:temporary:service${entry.target_service}:time:${entry.time}`}
          className="training-time__hour"
        >

          <button
            className="training-time__hour__select-time-button"
            onClick={() => this.onClickTime(entry)}
          >

            {entry.is_active &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="training-time__hour__text-container">

            <span className="training-time__hour__time">{entry.time}</span>
            <span className={`training-time__hour__target-student-count${entry.target_student_count !== null ? '--highlighted' : ''}`}>({entry.target_student_count || this.getDefaultTargetStudentCount(this.state.activeService)})</span>

          </p>

          <div className="training-time__hour__buttons-container">

            <button
              className="training-time__hour__edit-button"
              onClick={() => this.setState({trainingTimeToUpdate: {...entry}})}
            >

              <i className="fa-solid fa-pen"></i>

            </button>

            <button
              className="training-time__hour__remove-button"
              onClick={() => this.setState({deleteId: entry})}
            >

              <i className="fas fa-trash-alt"></i>

            </button>

          </div>

        </HorizontalContainer>
      );
    });
  }

  onClickTime(training_time_entry) {
    if(!this.state.activeService) {
      return;
    }

    if(training_time_entry.id) {
      const training_times = [...this.state.training_times];
      const index = training_times.findIndex((entry) => entry.id === training_time_entry.id);

      training_times[index] = {
        ...training_times[index],
        is_active: !training_times[index].is_active,
        updated: true
      };

      this.setState({
        training_times
      });
    }
    else {
      const timesToAdd = [...this.state.timesToAdd];
      const index = timesToAdd.indexOf(training_time_entry);

      timesToAdd[index] = {
        ...timesToAdd[index],
        is_active: !timesToAdd[index].is_active
      };

      this.setState({
        timesToAdd
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.addHorario();
    }
  }

  addHorario() {
    if(!this.state.activeService) {
      return;
    }

    if(this.state.novoHorario) {
      if (this.state.training_times.find((entry) => entry.target_service === this.state.activeService && entry.time === this.state.novoHorario && entry.day_id === this.state.selectedDay) ||
          this.state.timesToAdd.find((entry) => entry.target_service === this.state.activeService && entry.time === this.state.novoHorario && entry.day_id === this.state.selectedDay)) {
        this.setState({
          confirmFailed: true,
          confirmFailDescription: "Horário já cadastrado para o dia selecionado."
        });
        return;
      }

      const timesToAdd = [...this.state.timesToAdd];

      timesToAdd.push({
        target_service: this.state.activeService,
        time: this.state.novoHorario,
        day_id: this.state.selectedDay,
        target_student_count: null,
        is_active: true
      });

      this.setState({
        novoHorario: "",
        timesToAdd
      });
    }
  }

  getServiceSelectors() {
    return this.state.services.map((service) => {
      const serviceSelected = service === this.state.activeService;

      return (
        <button
          key={`training_time:service:${service.toLowerCase().replace(/ /g, '_')}`}
          className={`training-time__service-button`}
          disabled={serviceSelected}
          onClick={() => this.setState({
            activeService: service
          })}
        >

          {service}

        </button>
      );
    });
  }

  onChangeClassDuration(newValue) {
    if(this.state.class_duration_map) {
      this.setState({class_duration_map: {
        ...this.state.class_duration_map,
        [this.state.activeService]: newValue
      }});
    }
  }

  onChangeCheckinLimit(newValue) {
    if(this.state.checkin_limit_map) {
      this.setState({checkin_limit_map: {
        ...this.state.checkin_limit_map,
        [this.state.activeService]: newValue
      }});
    }
  }

  getClassDuration() {
    if(this.state.class_duration_map !== null && (typeof this.state.class_duration_map[this.state.activeService] !== 'undefined')) {
      return this.state.class_duration_map[this.state.activeService];
    }

    return 60;
  }

  getCheckinLimit() {
    if(this.state.checkin_limit_map !== null && (typeof this.state.checkin_limit_map[this.state.activeService] !== 'undefined')) {
      return this.state.checkin_limit_map[this.state.activeService];
    }

    return '';
  }

  hasClassDurationChange() {
    for(const [key, value] of Object.entries(this.state.class_duration_map)) {
      if(!this.state.original_class_duration_map[key] || this.state.original_class_duration_map[key] !== value) {
        return true;
      }
    }

    return false;
  }

  hasCheckinLimitChange() {
    for(const [key, value] of Object.entries(this.state.checkin_limit_map)) {
      if(!this.state.original_checkin_limit_map[key] || this.state.original_checkin_limit_map[key] !== value) {
        return true;
      }
    }

    return false;
  }

  getSelectedDayName() {
    const weekdayMap = [
      'segunda-feira',
      'terça-feira',
      'quarta-feira',
      'quinta-feira',
      'sexta-feira',
      'sábado',
      'domingo',
    ];

    return weekdayMap[this.state.selectedDay];
  }

  getDefaultTargetStudentCount(service) {
    if (this.state.checkin_limit_map[service]) {
      return Math.ceil(parseFloat(this.state.checkin_limit_map[service]) * DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE);
    }

    return 'INDEFINIDO'
  }

  confirmTrainingTimeUpdate() {
    let target_student_count = this.state.trainingTimeToUpdate.target_student_count;

    if (target_student_count !== null) {
      target_student_count = parseInt(target_student_count);
    }

    if(this.state.trainingTimeToUpdate.id) {
      const training_times = [...this.state.training_times];
      const index = training_times.findIndex((entry) => entry.id === this.state.trainingTimeToUpdate.id);

      training_times[index] = {
        ...training_times[index],
        time: this.state.trainingTimeToUpdate.time,
        target_student_count: target_student_count,
        updated: true
      };

      this.setState({
        training_times,
        trainingTimeToUpdate: null
      });
    }
    else {
      const timesToAdd = [...this.state.timesToAdd];
      const index = timesToAdd.findIndex((entry) => entry.target_service === this.state.trainingTimeToUpdate.target_service && entry.time === this.state.trainingTimeToUpdate.time && entry.day_id === this.state.trainingTimeToUpdate.day_id);

      timesToAdd[index] = {
        ...timesToAdd[index],
        time: this.state.trainingTimeToUpdate.time,
        target_student_count: target_student_count
      };

      this.setState({
        timesToAdd,
        trainingTimeToUpdate: null
      });
    }
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando horário';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar horário';
      }

      return 'Falha ao adicionar horário';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar horário';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'O horário será removido temporariamente até que clique em "salvar".';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Remover horário';
    }

    return 'Unknown';
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="training-time__overlay"
          visible={this.state.trainingTimeToUpdate != null}
          actions={(
            <div className="training-time__overlay__action-container">

              <DefaultMenuButton
                className="training-time__overlay__action-button"
                onClick={() => this.setState({
                  trainingTimeToUpdate: null,
                })}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-time__overlay__action-button"
                onClick={() => this.confirmTrainingTimeUpdate()}
                color="green"
                text="Confirmar"
              />

            </div>
          )}
        >

          <header className="training-time__overlay__header">

            <h3 className="training-time__overlay__header__title">
              {`${this.state.activeService} - ${this.getSelectedDayName()}: editar horário`}
            </h3>

          </header>

          <hr className="training-time__horizontal-rule" />

          <div className="training-time__overlay__content">

            {this.state.trainingTimeToUpdate != null &&
              <React.Fragment>

                <DefaultInput
                  name="time"
                  label="Horário da aula"
                  type="time"
                  placeholder="Horário da aula"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.trainingTimeToUpdate.time}
                  autoComplete="off"
                  disabled={true}
                />

                <DefaultInput
                  name="target_student_count"
                  label="Meta de ocupação na aula"
                  type="number"
                  placeholder="Meta de ocupação"
                  step="1"
                  min="1"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.trainingTimeToUpdate.target_student_count || ''}
                  autoComplete="off"
                  suffix="aluno(s)"
                  labelMessage={`Em caso de não preenchimento a meta de ocupação será de ${100 * DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE}% da ocupação máxima (${this.getDefaultTargetStudentCount(this.state.activeService)}).`}
                  onFocus={(event) => event.target.select()}
                />

              </React.Fragment>
            }

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          hideConfirmButton={this.state.confirmFailed}
          visible={this.state.deleteId !== null || this.state.confirmFailed}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.MANAGE_TRAINING_TIMES_PATH,
              text: "Horários de funcionamento"
            },
          ]}
          titleIcon={<i className="far fa-clock"></i>}
          title="Horários de funcionamento"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-time"
            title="Gerenciar Horários de funcionamento"
          >

            <DefaultSubSectionTitle
              className="training-time__service-selector-header"
              icon={<i className="fas fa-list"></i>}
              text="Selecione o serviço que deseja configurar"
            />

            <div className="training-time__service-selector">

              {this.getServiceSelectors()}

            </div>

            <HorizontalRule />

            <div className="training-time__content-wrapper">

              <div className="training-time__days">

                <h3 className="training-time__days-title">Dias</h3>

                <div className="training-time__days__wrapper">

                  {this.getDays()}

                </div>


              </div>

              <div className="training-time__hour-manager">

                <h3 className="training-time__hour-manager-title">Horários</h3>

                <div className="training-time__hours">

                  <PoseGroup flipMove={false}>

                    {this.getHours()}

                  </PoseGroup>

                </div>

                <div className="training-time__add-hour-wrapper">

                  <DefaultInput
                    name="name"
                    type="time"
                    placeholder="Digite um novo horário"
                    handleInputChange={(event) => this.setState({novoHorario: event.target.value})}
                    value={this.state.novoHorario}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    isHorizontal={true}
                  />

                  <button
                    className="training-time__add-hour-button"
                    disabled={!this.state.novoHorario}
                    onClick={() => this.addHorario()}
                  >

                    Adicionar horário

                  </button>

                </div>

              </div>

            </div>

            <HorizontalRule />

            <DefaultInput
              className="training-time__class-duration-input"
              name="class_duration_map"
              type="number"
              label="Duração da aula:"
              min="1"
              step="1"
              suffix="minutos"
              placeholder="Duração da aula"
              handleInputChange={(event) => this.onChangeClassDuration(event.target.value)}
              value={this.getClassDuration()}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={this.state.screenWidth > 510}
            />

            <DefaultInput
              className="training-time__checkin-limit-input"
              name="checkin_limit_map"
              type="number"
              label="Limite de checkin por aula:"
              min="1"
              step="1"
              suffix="aluno(s)"
              placeholder="Limite de checkin"
              handleInputChange={(event) => this.onChangeCheckinLimit(event.target.value)}
              value={this.getCheckinLimit()}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={this.state.screenWidth > 510}
            />

            <HorizontalRule />

            <div className="training-time__buttons-container">

              <button
                className="training-time__save-button"
                onClick={() => {this.onSave()}}
                disabled={this.state.idsToDelete.length <= 0 && this.state.timesToAdd.length <= 0 && !this.state.training_times.some((entry) => entry.updated) && !this.hasClassDurationChange() && !this.hasCheckinLimitChange()}
              >

                Salvar

              </button>

              <PoseGroup>

                {this.state.showDataSaved &&
                  <FadeContainer key="training-time__data-saved-text">

                    <p className="training-time__data-saved-text">Dados de salvos!</p>

                  </FadeContainer>
                }

              </PoseGroup>

            </div>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingTime;
