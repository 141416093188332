import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from './color_pallet';
import {getCurrencyText} from '../utils/functions';

function Stack100Group(name, points, includeInTotal=false, color=null) {
  return {
    name: name,
    points: points,
    includeInTotal: includeInTotal,
    color: color,
  }
}

function Stack100Point(value, label, index=null) {
  return {
    value,
    label,
    index
  }
}

export {Stack100Group, Stack100Point};

function getPointData(value, name, index=null, includeInTotal=false) {
  const point = {y: value, label: name, includeInTotal};

  if(index) {
    point.indexContent = index;
  }

  return point;
}


class Stacked100BarGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getData() {
    this.props.data.sort((a, b) => {
      const reducer = (sum, point) => sum + point.value;

      return b.points.reduce(reducer, 0) - a.points.reduce(reducer, 0);
    });

    return this.props.data.map((group, index) => ({
      type: "stackedColumn100",
      name: group.name,
      indexLabel: "{indexContent}",
      indexLabelFontWeight: "bold",
      indexLabelFontColor: "#333",
      showInLegend: true,
      color: group.color || (index < DEFAULT_SEQUENTIAL_COLOR_PALLET.length ? DEFAULT_SEQUENTIAL_COLOR_PALLET[index] : 'black'),
      yValueFormatString: "R$ #,##0.00",
      dataPoints: group.points.map((point) => getPointData(point.value, point.label, point.index, group.includeInTotal))
    }));
  }

  getOptions() {
    const getToolTipValue = this.props.ToolTipValueCallback || getCurrencyText;

    const options = {
			animationEnabled: true,
			colorSet: DEFAULT_SEQUENTIAL_COLOR_PALLET,
      culture: 'pt-BR',
      toolTip: {
				shared: true,
        contentFormatter: (e) => {
  				let content = "";

          let totalValue = 0;

  				for (let i = e.entries.length-1; i >= 0; i--) {
            if(i === e.entries.length-1) {
              content += `${e.entries[i].dataPoint.label}<br/>`;
            }

            if(this.props.totalToolTipLabel && e.entries[i].dataPoint.includeInTotal) {
              totalValue += e.entries[i].dataPoint.y;
            }

  					content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataSeries.name}:</span> ${getToolTipValue(e.entries[i].dataPoint.y)}<br/>`;
  				}

          if(this.props.totalToolTipLabel) {
            content += `<hr/><b>${this.props.totalToolTipLabel} :</b> ${getToolTipValue(totalValue)}`;
          }

  				return content;
  			}
			},
      axisY: {
				labelFormatter: (entry) => `${entry.value}%`
			},
      axisX: {
				labelFormatter: (entry) => entry.label ? entry.label.split(' ')[0] : entry.label
			},
      legend: {
        verticalAlign: this.props.legendVerticalAlign || "center",
				horizontalAlign: this.props.legendHorizontalAlign || "right",
				reversed: true,
				cursor: "pointer",
				itemclick: (e) => this.toggleDataSeries(e)
			},
			data: this.getData()
		};

    if(this.props.title) {
      options.title = {
				text: this.props.title
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
		<div>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
				onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default Stacked100BarGraph;
