import React from 'react';
import { Link } from 'react-router-dom';
import './user_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, MANAGER_ROLE} from '../../constants';
import * as routes from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import {postModel} from '../../utils/functions';

class UserData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendValidationEmail: false,
      triggerEmailChange: false,
      triggerPasswordRecovery: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      validationEmailSent: false,
      accountChangeEmailSent: false,
      passwordRecoveryEmailSent: false,
    };
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.user.id && this.props.user.id > 0;
  }

  onCancelAction() {
    this.setState({
      sendValidationEmail: false,
      triggerEmailChange: false,
      triggerPasswordRecovery: false,
    });
  }

  maySendValidationEmail() {
    return this.isEditMode() &&
           this.props.user.email_is_validated === null;
  }

  mayTriggerEmailChange() {
    return this.props.mayTriggerEmailChange &&
           this.props.user.email_is_validated === true;
  }

  onSendValidationEmail() {
    this.setState({
      sendValidationEmail: true,
      triggerEmailChange: false,
      triggerPasswordRecovery: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onTriggerEmailChange() {
    this.setState({
      triggerEmailChange: true,
      triggerPasswordRecovery: false,
      sendValidationEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onTriggerPasswordRecovery() {
    this.setState({
      triggerPasswordRecovery: true,
      triggerEmailChange: false,
      sendValidationEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.sendValidationEmail) {
        return 'Enviando email';
      }
      else if(this.state.triggerEmailChange) {
        return 'Enviando email';
      }
      else if(this.state.triggerPasswordRecovery) {
        return 'Enviando email';
      }

      return 'Não definido';
    }
    else if(this.state.confirmFailed) {
      if(this.state.sendValidationEmail) {
        return 'Falha ao enviar email';
      }
      else if(this.state.triggerEmailChange) {
        return 'Falha ao enviar email';
      }
      else if(this.state.triggerPasswordRecovery) {
        return 'Falha ao enviar email';
      }

      return 'Não definido';
    }

    if(this.state.sendValidationEmail) {
      return 'Reenviar email de validação';
    }
    else if(this.state.triggerEmailChange) {
      return 'Enviar notificação para mudança de email';
    }
    else if(this.state.triggerPasswordRecovery) {
      return 'Enviar email para redefinição de senha';
    }

    return 'Não definido';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.sendValidationEmail) {
      return 'Outro email de validação de conta será enviado ao usuário. ' +
             'Caso o usuário não esteja recebendo o email, confira se o email foi cadastrado corretamente ' +
             'e oriente o usuário para conferir sua caixa de spam.';
    }
    else if(this.state.triggerEmailChange) {
      return 'Um email será enviado ao usuário contendo um link para realizar a alteração ' +
             'do email vinculado à conta.';
    }
    else if(this.state.triggerPasswordRecovery) {
      return 'Um email será enviado ao usuário contendo um link para realizar a redefinição ' +
             'de suas senhas de acesso ao sistema administrativo.';
    }

    return 'Não definido';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.sendValidationEmail) {
      return 'Enviar email';
    }
    else if(this.state.triggerEmailChange) {
      return 'Enviar email';
    }
    else if(this.state.triggerPasswordRecovery) {
      return 'Enviar email';
    }

    return 'Não definido';
  }

  async onConfirmAction() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.sendValidationEmail) {
      const userData = {user_id: this.props.user.id};

      try {
        if(await postModel(routes.USER_SEND_VALIDATION_EMAIL_POST_API, userData)) {
          this.setState({
            validationEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          validationEmailSent: false
        });

        return;
      }
    }
    else if(this.state.triggerEmailChange) {
      const userData = {user_id: this.props.user.id};

      try {
        if(await postModel(routes.USER_INITIATE_EMAIL_CHANGE_POST_API, userData)) {
          this.setState({
            accountChangeEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            if(error.code === 203) {
              if(error.message.includes('Requires higher access level')) {
                errorDescription = 'Você não possui permissão para realizar esta ação para este cadastro. Por favor, entre em contato com o suporte FYD caso necessário.';
                break;
              }
            }
            else if(error.code === 209) {
              errorDescription = 'Sessão do usuário expirada.';
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          accountChangeEmailSent: false
        });

        return;
      }
    }
    else if(this.state.triggerPasswordRecovery) {
      const userData = {user_id: this.props.user.id};

      try {
        if(await postModel(routes.INITIATE_PASSWORD_RECOVERY_POST_API, userData)) {
          this.setState({
            passwordRecoveryEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            if(error.code === 203) {
              if(error.message.includes('Requires higher access level')) {
                errorDescription = 'Você não possui permissão para realizar esta ação para este cadastro. Por favor, entre em contato com o suporte FYD caso necessário.';
                break;
              }
            }
            else if(error.code === 209) {
              errorDescription = 'Sessão do usuário expirada.';
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          passwordRecoveryEmailSent: false
        });

        return;
      }
    }

    this.setState({
      sendValidationEmail: false,
      triggerEmailChange: false,
      triggerPasswordRecovery: false,
    });
  }

  getRoleExperienceLevelOptions() {
    return [
      SelectOption('', 'Indefinido'),
      ...this.props.role_experience_levels.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.sendValidationEmail || this.state.triggerEmailChange || this.state.triggerPasswordRecovery}
          onCancel={() => this.onCancelAction()}
          onConfirm={() => this.onConfirmAction()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <DefaultSection
          className="user-data"
          title="Dados do gerente"
        >

          {(this.maySendValidationEmail() || this.mayTriggerEmailChange()) &&
            <React.Fragment>

              <div className="user-data__actions-container">

                {this.maySendValidationEmail() &&
                  <button
                    className="user-data__action-button"
                    disabled={this.state.validationEmailSent}
                    onClick={(event) => this.onSendValidationEmail()}
                  >

                    <i className="fas fa-envelope"></i> {this.state.validationEmailSent ? 'Email enviado' : 'Reenviar email de validação'}

                  </button>
                }

                {this.mayTriggerEmailChange() &&
                  <button
                    className="user-data__action-button"
                    disabled={this.state.accountChangeEmailSent}
                    onClick={(event) => this.onTriggerEmailChange()}
                  >

                    <i className="fas fa-envelope"></i> {this.state.accountChangeEmailSent ? 'Email enviado' : 'Iniciar alteração de email'}

                  </button>
                }

                {this.mayTriggerEmailChange() &&
                  <button
                    className="user-data__action-button"
                    disabled={this.state.passwordRecoveryEmailSent}
                    onClick={(event) => this.onTriggerPasswordRecovery()}
                  >

                    <i className="fas fa-envelope"></i> {this.state.passwordRecoveryEmailSent ? 'Email enviado' : 'Iniciar recuperação de senha'}

                  </button>
                }

              </div>

              <HorizontalRule />

            </React.Fragment>

          }

          <div className="user-data__warning-container">

            <WarningMessage
              message={this.props.warningMessage}
              onClose={this.props.onCloseWarning}
              visible={this.props.showWarningMessage}
            />

          </div>

          <div className="user-data__input-container">

            {this.isEditMode() &&
              <DefaultInput
                name="is_active"
                isHighlighted={this.isHighlighted("is_active")}
                label="Ativo:"
                type="toggle"
                isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.is_active}
                horizontalAlign="right"
              />
            }

            <HalfWrapper>

              <DefaultInput
                name="name"
                isHighlighted={this.isHighlighted("name")}
                label="Nome"
                type="text"
                placeholder="Nome do gerente"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.name}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

              <DefaultInput
                name="email"
                isHighlighted={this.isHighlighted("email")}
                label="E-mail"
                type="email"
                placeholder="E-mail do gerente"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.email}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={this.isEditMode() && !this.props.mayChangeEmail && this.props.user.email_is_validated}
              />

            </HalfWrapper>

            {(!this.isEditMode() || this.props.user.role_names.includes(MANAGER_ROLE.name)) &&
              <DefaultInput
                name="role_experience_level_id"
                isHighlighted={this.isHighlighted("role_experience_level_id")}
                label="Nível de experiência"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.role_experience_level_id || ''}
                options={this.getRoleExperienceLevelOptions()}
              />
            }

            <HalfWrapper>

              <DefaultInput
                name="password"
                isHighlighted={this.isHighlighted("password")}
                label="Senha"
                type="password"
                placeholder="Senha de acesso do gerente"
                maxLength="32"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.password}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

              <DefaultInput
                name="password_confirmation"
                isHighlighted={this.isHighlighted("password_confirmation")}
                label="Confirmação de senha"
                type="password"
                placeholder="Confirmação de senha"
                maxLength="32"
                handleInputChange={this.props.handleInputChange}
                value={this.props.user.password_confirmation}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

            </HalfWrapper>

          </div>

          <HorizontalRule />

          <div className="user-data__buttons-container">

            <button
              className="user-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>

            <Link
              className="user-data__cancel-button"
              to={this.props.onCancelPath}
            >

              Cancelar

            </Link>

          </div>

        </DefaultSection>

      </React.Fragment>
    );
  }
}

export default UserData;
