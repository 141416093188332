import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {postModel} from '../../../utils/functions';
import MealClassificationData from './meal_classification_data';

class MealClassificationAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meal_classification: {
        name: "",
        description: ""
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.meal_classification, [name]: value};

    this.setState({
      meal_classification: newData
    });
  }

  inputsAreValid() {
    return this.state.meal_classification.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.meal_classification};

    try {
      await postModel(routes.MEAL_CLASSIFICATION_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.MEAL_CLASSIFICATION_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.MEAL_CLASSIFICATION_LIST_PATH,
            text: "Listar classificações"
          },
          {
            path: routes.MEAL_CLASSIFICATION_ADD_PATH,
            text: "Adicionar classificação"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar classificação de refeição"
        loading={this.state.loading}
      >

        <MealClassificationData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          meal_classification={this.state.meal_classification}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.MEAL_CLASSIFICATION_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default MealClassificationAdd;
