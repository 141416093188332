import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        MANAGER_ROLE,
        COACH_ROLE,
        HEAD_COACH_ROLE,
        ADMIN_NUTRITIONIST_ROLE,
        NUTRITIONIST_ROLE,
        RECEPTIONIST_ROLE,
        ADMIN_RECEPTIONIST_ROLE} from '../../constants';
import * as permissions from '../../permissions';
import './role_experience_level_list.scss';


class RoleExperienceLevelList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActiveInput = queryParameters.get('is_active');
    let roleIdInput = queryParameters.get('role_id');

    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }

    if(!roleIdInput) {
      roleIdInput = '';
    }

    this.state = {
      loadingData: true,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      roleIdInput: roleIdInput,
      roleIdFilter: roleIdInput,
      role_experience_levels: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getRoleExperienceLevels() {
    const parameters = {};

    if(this.state.isActiveFilter.length > 0) {
      parameters.is_active = this.state.isActiveFilter;
    }
    if(this.state.roleIdFilter.length > 0) {
      parameters.role_id = this.state.roleIdFilter;
    }

    return await getModels(setUrlParameters(routes.ROLE_EXPERIENCE_LEVELS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isActiveFilter !== this.state.isActiveFilter || prevState.roleIdFilter !== this.state.roleIdFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        role_experience_levels: await this.getRoleExperienceLevels(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const role_experience_levels = await this.getRoleExperienceLevels();

    this.setState({
      loadingData: false,
      role_experience_levels
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.ROLE_EXPERIENCE_LEVEL_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'user_associations':
                    errorDescription = 'Cadastro vinculado à outro(s) usuário(s).';

                    break;
                  default:
                }
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.ROLE_EXPERIENCE_LEVEL_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getPeriodText(entry) {
    return (
      <div className="role-experience-level-list__cell-wrapper">

        <p className="role-experience-level-list__period-text">{`${entry.period} dia${entry.period > 1 ? 's' : ''}`}</p>

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`role-experience-level-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nível de experiência', <i className="fas fa-tag"></i>),
      Property('rolename', 'Função', <i className="fa-solid fa-helmet-safety"></i>),
    ];

    if(this.state.screenWidth > 700) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "role-experience-level-list__description-cell"}),
      );
    }

    // if(this.state.screenWidth > 430) {
    //   properties.push(
    //     Property('period', 'Período', <i className="fas fa-calendar-week"></i>, {getDataText: this.getPeriodText}),
    //   );
    // }

    properties = [
      ...properties,
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando nível de experiência';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar nível de experiência';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  getRoleOptions() {
    return [
      SelectOption('', 'Todas'),
      SelectOption(MANAGER_ROLE.id, MANAGER_ROLE.name),
      SelectOption(COACH_ROLE.id, COACH_ROLE.name),
      SelectOption(HEAD_COACH_ROLE.id, HEAD_COACH_ROLE.name),
      SelectOption(ADMIN_NUTRITIONIST_ROLE.id, ADMIN_NUTRITIONIST_ROLE.name),
      SelectOption(NUTRITIONIST_ROLE.id, NUTRITIONIST_ROLE.name),
      SelectOption(RECEPTIONIST_ROLE.id, RECEPTIONIST_ROLE.name),
      SelectOption(ADMIN_RECEPTIONIST_ROLE.id, ADMIN_RECEPTIONIST_ROLE.name),
    ];
  }

  mayResetFilterInputs() {
    if(this.state.isActiveInput.length > 0 || this.state.roleIdInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      isActiveInput: '',
      roleIdInput: '',
    });
  }

  mayRefreshList() {
    if(this.state.isActiveInput !== this.state.isActiveFilter || this.state.roleIdInput !== this.state.roleIdFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH, {
        is_active: this.state.isActiveInput || 'all',
        role_id: this.state.roleIdInput,
      }));

      this.setState({
        isActiveFilter: this.state.isActiveInput,
        roleIdFilter: this.state.roleIdInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao nível de experiência serão removidos'}
          confirmText="Deletar"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH,
              text: "Listar níveis de experiência"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar níveis de experiência"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="role-experience-level-list"
            title="Lista de níveis de experiência"
          >

            <div className="role-experience-level-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_ROLE_EXPERIENCE_LEVEL_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.ROLE_EXPERIENCE_LEVEL_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nível de experiência

                </Link>
              }

            </div>

            <div className="role-experience-level-list__filters">

              <header className="role-experience-level-list__filters__header">

                <h4 className="role-experience-level-list__filters__header__text">Filtros</h4>

              </header>

              <div className="role-experience-level-list__filters__inputs">

                <div className="role-experience-level-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="isActiveInput"
                      label="Situação do cadastro"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.isActiveInput || ''}
                      options={this.getActiveOptions()}
                    />

                    <DefaultInput
                      name="roleIdInput"
                      label="Função"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.roleIdInput || ''}
                      options={this.getRoleOptions()}
                    />

                  </HalfWrapper>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="role-experience-level-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayRefreshList() &&
                  <button
                    className="role-experience-level-list__filters__refresh-button"
                    onClick={() => this.refreshList()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.role_experience_levels}
              initialOrderBy="rolename"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default RoleExperienceLevelList;
