import React from 'react';
import './home_training_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultSection from '../../utils/default_section';
import {getModels, postModel, deleteModel} from '../../utils/functions';
import DefaultInput from '../../utils/default_input';
import DefaultMenuButton from '../../components/default_menu_button';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class HomeTrainingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      trainings: [],
      willEnableTraining: null,
      trainingIdToEnable: 0,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getHomeTrainings() {
    return await getModels(routes.HOME_TRAININGS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const trainings = await this.getHomeTrainings();

    this.setState({
      loadingData: false,
      trainings: trainings
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      willEnableTraining: null,
      trainingIdToEnable: 0,
      confirmFailed: false,
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.HOME_TRAINING_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.DELETE_HOME_TRAINING_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.DELETE_HOME_TRAINING_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            Desabilitar

          </button>
        }

      </div>
    );
  }

  getPeriodText(entry) {
    return (
      <div className="home-training-list__cell-wrapper">

        <p className="home-training-list__period-text">{`${entry.period} dia${entry.period > 1 ? 's' : ''}`}</p>

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`home-training-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getPeriodName(training) {
    return training.period.name;
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('period', 'Periodização', <i className="fas fa-layer-group"></i>, {
        getDataText: (entry) => this.getPeriodName(entry),
        getFilterText: (entry) => this.getPeriodName(entry),
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Desabilitando treino para casa';
    }
    else if(this.state.confirmFailed) {
      if(this.state.willEnableTraining != null) {
        return 'Falha ao habilitar treino para casa';
      }

      return 'Falha ao desabilitar treino para casa';
    }

    return 'Desabilitar treino para casa';
  }

  async proceedEnableTraining() {
    if(this.state.trainingIdToEnable <= 0) {
      return;
    }

    this.setState({
      loadingData: true
    });

    if(this.state.willEnableTraining != null && this.state.trainingIdToEnable > 0) {
      const data = {
        training_day_id: parseInt(this.state.trainingIdToEnable),
      };

      try{
        if(await postModel(routes.HOME_TRAINING_POST_API, data)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 102:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'training_day.description_url':
                      errorDescription = 'Treino não possui vídeo descritivo cadastrado.';

                      break;
                    case 'training_day_id':
                      errorDescription = 'ID inválido.';

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          loadingData: false
        });

        return;
      }
    }

    this.setState({
      willEnableTraining: null,
      trainingIdToEnable: 0,
      loadingData: false
    });
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'O treino deixará de ser disponibilizado aos alunos como treino para casa';
    }

    return 'Unknown';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Desabilitar treino';
    }

    return 'Unknown';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="home-training-list__overlay"
          visible={this.state.willEnableTraining != null && this.state.loadingData !== true}
          actions={(
            <div className="home-training-list__overlay__action-container">

              <DefaultMenuButton
                className="home-training-list__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="home-training-list__overlay__action-button"
                onClick={() => {
                  this.proceedEnableTraining();
                }}
                text="Confirmar"
                disabled={this.state.trainingIdToEnable <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="home-training-list__overlay__header">

            <h3 className="home-training-list__overlay__header__title">
              Habilitar treino para casa
            </h3>

          </header>

          <hr className="home-training-list__horizontal-rule" />

          <div className="home-training-list__overlay__content">

            <DefaultInput
              name="trainingIdToEnable"
              label="ID do treino"
              type="number"
              placeholder="ID do treino"
              min="0"
              step="1"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.trainingIdToEnable}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={true}
            />

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || (this.state.confirmFailed === true && this.state.willEnableTraining != null)}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.HOME_TRAINING_LIST_PATH,
              text: "Home training"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar treinos para casa"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="home-training-list"
            title="Lista de treinos para casa"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.trainings}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_HOME_TRAINING_PERMISSION_ID) &&
                <button
                  className="model-table__default-button"
                  onClick={() => this.setState({willEnableTraining: true, confirmInProgress: false})}
                >

                  <i className="fas fa-plus"></i> Habilitar treino

                </button>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default HomeTrainingList;
