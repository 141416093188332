import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import ModelTable, {Property} from '../../utils/model_table';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption, FiledSet} from '../../utils/default_input';
import {deleteModel,
        postModel,
        patchModel,
        getModel,
        getModels,
        setUrlParameters,
        getDefaultLocalDateStringFromIsoString,
        getAsLocalDate,
        getLocalDateIsoString,
        getAsLocalDateString,
        getAsLocalDatetime,
        getLocalTimeIsoString,
        getAbbreviatedName} from '../../utils/functions';
import * as routes from '../../constants';
import * as paths from './constants';
import {WEEKDAY_NAMES,
        CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
        PROTEIN_TO_KCAL_CONVERSION_CONTANT,
        FAT_TO_KCAL_CONVERSION_CONTANT,
        BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT,
        getFoodPrescriptionNutritionalData} from '../../utils/fyd_food';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        STUDENT_ACTIVE_STATUS,
        TRAINING_PERIOD_OBJECTIVES,
        TRAINING_PERIOD_PHYSICAL_LEVELS,
        NUTRIENT_FIBER_ID,
        NUTRIENT_PROTEIN_ID,
        NUTRIENT_FAT_ID,
        NUTRIENT_CARBOHYDRATE_ID,
        F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS,
        FYD_FORM_FOLLOWUP_QUESTION_DIET_ID,
        FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID,
        FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID,
        FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID,
        FYD_FORM_FOLLOWUP_QUESTION_WATER_ID,
        FYD_FORM_FOLLOWUP_QUESTION_DIFFICULTIES_ID,
        SERVICE_FUNCTIONAL_SERVICE,
        SERVICE_PILATES_SERVICE,
        SERVICE_BIKE_SERVICE,
        SERVICE_GYM_SERVICE,
        SERVICE_CARDIO,
        SERVICE_CORE,
        SERVICE_BOOTCAMP} from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import DefaultMenuButton from '../../components/default_menu_button';
import OverlayWindow from '../../components/overlay_window';
import StackedBarGraph, {StackGroup, StackPoint, ErrorPoint} from '../../graphs/stacked_bar_graph';
import DietGraph, {WeightPoint} from '../../graphs/diet_graph';
import logo from '../../images/logo_fyd_club.png';
import HorizontalBarGraph from '../../graphs/custom/horizontal_bar_graph';
import hexOuterBorderImg from '../../images/hexagon_border_white.svg';
import hexOuterBorderSmallImg from '../../images/hexagon_border_white_small.svg';
import hexInnerBorderImg from '../../images/hexagon_border_dashed_white.svg';
import hexInnerBorderSmallImg from '../../images/hexagon_border_dashed_white_small.svg';
import reportBackground from '../../images/nutritional_report/background.svg';
import './food_prescription.scss';
import * as permissions from '../../permissions';


class FoodPrescription extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let genderInput = queryParameters.get('gender');
    let mainObjectiveInput = queryParameters.get('main_objective');
    let suggestedPrescriptionsOnlyInput = queryParameters.get('suggested_prescriptions_only');
    let minWeightInput = queryParameters.get('min_weight');
    let maxWeightInput = queryParameters.get('max_weight');

    if(!genderInput) {
      genderInput = '';
    }
    if(!mainObjectiveInput) {
      mainObjectiveInput = '';
    }
    if(!minWeightInput) {
      minWeightInput = '';
    }
    if(!maxWeightInput) {
      maxWeightInput = '';
    }
    if(suggestedPrescriptionsOnlyInput && suggestedPrescriptionsOnlyInput === 'true') {
      suggestedPrescriptionsOnlyInput = true;
    }
    else {
      suggestedPrescriptionsOnlyInput = false;
    }

    this.state = {
      selectedTabIndex: 1,
      reloadingAvailableFoodPrescriptions: false,
      loadingData: true,
      last_exam: null,
      studentStatus: null,
      activePrescription: null,
      diet_sample: null,
      activePrescriptionData: null,
      dietSampleData: null,
      food_history_auth_hash: null,
      food_prescription_history: [],
      meal_periods: [],
      initialPrescriptionDate: null,
      initialPrescriptionDateInput: null,
      finalPrescriptionDate: null,
      finalPrescriptionDateInput: null,
      visibleToStudent: false,
      visibleToStudentInput: false,
      graphVisibleToStudent: false,
      graphVisibleToStudentInput: false,
      indicatorsVisibleToStudent: false,
      indicatorsVisibleToStudentInput: false,
      prescriptionDuration: "",
      genderInput: genderInput,
      genderFilter: genderInput,
      mainObjectiveInput: mainObjectiveInput,
      mainObjectiveFilter: mainObjectiveInput,
      suggestedPrescriptionsOnlyInput: suggestedPrescriptionsOnlyInput,
      suggestedPrescriptionsOnlyFilter: suggestedPrescriptionsOnlyInput,
      minWeightInput: minWeightInput,
      minWeightFilter: minWeightInput,
      maxWeightInput: maxWeightInput,
      maxWeightFilter: maxWeightInput,
      food_prescriptions: [],
      food_recipes: [],
      student_food_allergens: [],
      user_service_status: null,
      training_data: [],
      weekly_exercise_frequency: null,
      weight_measurements: [],
      followup_form_submissions: [],
      contract_expiration_date: null,
      originalPhysicalEvaluationData: [],
      physicalEvaluationData: [],
      activatePrescriptionSectionVisible: false,
      foodPrescriptionHistorySectionVisible: false,
      profileSectionVisible: false,
      physicalActivitySectionVisible: false,
      responsibleSectionVisible: false,
      dietSampleSectionVisible: false,
      idToActivate: null,
      foodPrescriptioIdToDuplicate: null,
      foodRecipeIdToDuplicate: null,
      foodPrescriptioIdToDelete: null,
      foodRecipeIdToDelete: null,
      foodPrescriptionHistoryIdToDelete: null,
      deactivatePrescription: false,
      enableTracking: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      notificationEmailSent: false,
      clipboardWritePermission: false,
      linkCopiedSet: new Set(),
      downloadPdfClicked: false,
      ignoreExerciseEnergyExpense: false,
      weeklyExerciseEnergyExpense: 0,
      service_energy_expense_map: null,
      weeklyExerciseFrequency: null,
      finishRevision: false,
      screenWidth: window.innerWidth,
    };
  }

  async getPersonalTraining() {
    return await getModel(routes.PERSONAL_TRAINING_PERIOD_GET_API.replace('{id}', this.props.student.id));
  }

  async getStudentFoodPrescription() {
    return await getModel(routes.STUDENT_FOOD_PRESCRIPTION_GET_API.replace('{id}', this.props.student.id));
  }

  async getPhysicalEvaluationData() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return await getModels(routes.PHYSICAL_EVALUATION_DATA_GET_API.replace('{student_id}', this.props.student.id));
    }

    return [];
  }

  async reloadActivePrescription() {
    this.setState({
      loadingData: true
    });

    const studentFoodPrescription = await this.getStudentFoodPrescription();

    const update = {loadingData: false};

    if(studentFoodPrescription) {
      if(studentFoodPrescription.food_prescription) {
        update.activePrescription = studentFoodPrescription.food_prescription;
        update.activePrescriptionData = getFoodPrescriptionNutritionalData(update.activePrescription);
        update.initialPrescriptionDate = studentFoodPrescription.initial_date;
        update.initialPrescriptionDateInput = studentFoodPrescription.initial_date;
        update.visibleToStudent = studentFoodPrescription.visible_to_student;
        update.visibleToStudentInput = studentFoodPrescription.visible_to_student;
        update.graphVisibleToStudent = studentFoodPrescription.graph_visible_to_student;
        update.graphVisibleToStudentInput = studentFoodPrescription.graph_visible_to_student;
        update.indicatorsVisibleToStudent = studentFoodPrescription.indicators_visible_to_student;
        update.indicatorsVisibleToStudentInput = studentFoodPrescription.indicators_visible_to_student;

        if (studentFoodPrescription.final_date !== null) {
          const initialDate = getAsLocalDate(studentFoodPrescription.initial_date);
          const finalDate = getAsLocalDate(studentFoodPrescription.final_date);

          const timeDiff = Math.abs(finalDate.getTime() - initialDate.getTime());
          const daysCount = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)));

          initialDate.setDate(initialDate.getDate() + (daysCount));
          update.prescriptionDuration = daysCount;
          update.finalPrescriptionDate =  getLocalDateIsoString(initialDate);
          update.finalPrescriptionDateInput = getLocalDateIsoString(initialDate);
        }
        else {
          update.prescriptionDuration = '';
          update.finalPrescriptionDate = null;
          update.finalPrescriptionDateInput = null;
        }
      }
      else {
        update.activePrescription = null;
        update.activePrescriptionData = null;
        update.initialPrescriptionDate = null;
        update.initialPrescriptionDateInput = null;
        update.visibleToStudent = false;
        update.visibleToStudentInput = false;
        update.graphVisibleToStudent = true;
        update.graphVisibleToStudentInput = true;
        update.indicatorsVisibleToStudent = true;
        update.indicatorsVisibleToStudentInput = true;
        update.finalPrescriptionDate = null;
        update.finalPrescriptionDateInput = null;
        update.prescriptionDuration = null;
        update.last_exam = null;
        update.studentStatus = null;
      }

      if (studentFoodPrescription.auth_hash) {
        update.food_history_auth_hash = studentFoodPrescription.auth_hash;
      }
      else {
        update.food_history_auth_hash = null;
      }

      if(studentFoodPrescription.last_exam) {
        update.last_exam = studentFoodPrescription.last_exam;
      }
      else {
        update.last_exam = null;
      }

      if(studentFoodPrescription.diet_sample) {
        update.diet_sample = studentFoodPrescription.diet_sample;

        update.diet_sample.food_recipe_associations.forEach((association) => {
          association.food_recipe.basic_ingredient_associations.sort((a, b) => b.order - a.order);
        });

        update.dietSampleData = getFoodPrescriptionNutritionalData(update.diet_sample);
      }
      else {
        update.diet_sample = null;
        update.dietSampleData = null;
      }

      if(studentFoodPrescription.student_status) {
        update.studentStatus = studentFoodPrescription.student_status;
      }

      if(studentFoodPrescription.food_prescription_history) {
        update.food_prescription_history = studentFoodPrescription.food_prescription_history;
      }

      if(studentFoodPrescription.service_energy_expense_map) {
        update.service_energy_expense_map = studentFoodPrescription.service_energy_expense_map;
      }

      if(studentFoodPrescription.training_data) {
        update.training_data = studentFoodPrescription.training_data;
      }

      if(studentFoodPrescription.weekly_exercise_frequency) {
        update.weekly_exercise_frequency = studentFoodPrescription.weekly_exercise_frequency;
      }

      if(studentFoodPrescription.weight_measurements) {
        update.weight_measurements = studentFoodPrescription.weight_measurements;
      }

      if(studentFoodPrescription.followup_form_submissions) {
        update.followup_form_submissions = studentFoodPrescription.followup_form_submissions;
      }

      if(studentFoodPrescription.contract_expiration_date) {
        update.contract_expiration_date = studentFoodPrescription.contract_expiration_date;
      }

      if(studentFoodPrescription.student_food_allergens) {
        update.student_food_allergens = studentFoodPrescription.student_food_allergens;
      }

      if(studentFoodPrescription.user_service_status) {
        update.user_service_status = studentFoodPrescription.user_service_status;
      }
    }
    else {
      update.activePrescription = null;
      update.activePrescriptionData = null;
      update.initialPrescriptionDate = null;
      update.initialPrescriptionDateInput = null;
      update.visibleToStudent = false;
      update.visibleToStudentInput = false;
      update.graphVisibleToStudent = true;
      update.graphVisibleToStudentInput = true;
      update.indicatorsVisibleToStudent = true;
      update.indicatorsVisibleToStudentInput = true;
      update.finalPrescriptionDate = null;
      update.finalPrescriptionDateInput = null;
      update.prescriptionDuration = null;
      update.last_exam = null;
      update.studentStatus = null;
      update.diet_sample = null;
      update.dietSampleData = null;
      update.food_history_auth_hash = null;
      update.food_prescription_history = [];
      update.service_energy_expense_map = null;
      update.training_data = [];
      update.weekly_exercise_frequency = null;
      update.weight_measurements = [];
      update.followup_form_submissions = [];
      update.contract_expiration_date = null;
      update.student_food_allergens = [];
      update.user_service_status = null;
    }

    this.setState(update);
  }

  getPhysicalEvaluationQuestionValue(question_id) {
    const question = this.state.originalPhysicalEvaluationData.find((entry) => entry.question_id === question_id);

    if(question) {
      return question.value;
    }
    else if(question_id === 10) {
      return 'Iniciante';
    }
    else {
      return '';
    }
  }

  async reloadAvailableFoodPrescriptions() {
    this.setState({
      reloadingAvailableFoodPrescriptions: true
    });

    const update = {reloadingAvailableFoodPrescriptions: false};

    const parameters = {
      user_id: this.props.student.id,
      active_only: true
    };

    if(this.state.suggestedPrescriptionsOnlyFilter) {
      parameters.main_objective = this.getPhysicalEvaluationQuestionValue(1);
      parameters.target_gender = this.props.student.gender;
    }
    else {
      if(this.state.mainObjectiveFilter) {
        parameters.main_objective = this.state.mainObjectiveFilter;
      }
      if(this.state.genderFilter) {
        parameters.target_gender = this.state.genderFilter;
      }
    }

    if(this.state.minWeightFilter) {
      parameters.min_weight = this.state.minWeightFilter;
    }
    if(this.state.maxWeightFilter) {
      parameters.max_weight = this.state.maxWeightFilter;
    }

    let food_prescriptions;
    let food_recipes;

    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION)) {
      food_prescriptions = getModels(setUrlParameters(routes.FOOD_PRESCRIPTIONS_GET_API, parameters));
    }
    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION)) {
      food_recipes = getModels(setUrlParameters(routes.FOOD_RECIPES_GET_API, {is_active: true, user_id: this.props.student.id}));
    }

    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION)) {
      food_prescriptions = await food_prescriptions;

      if(food_prescriptions) {
        update.food_prescriptions = food_prescriptions;
      }
    }
    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION)) {
      food_recipes = await food_recipes;

      if(food_recipes) {
        update.food_recipes = food_recipes;
      }
    }

    this.setState(update);
  }

  async reloadPhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let physicalEvaluationData = this.getPhysicalEvaluationData();

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          if (entry.value.length > 0) {
            update.weeklyExerciseFrequency = parseInt(entry.value);
          }
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPhysicalEvaluationData = physicalEvaluationData;
    }

    this.setState(update);
  }

  async componentDidMount() {
    let studentFoodPrescription = this.getStudentFoodPrescription();
    let physicalEvaluationData = this.getPhysicalEvaluationData();
    let personalTraining = this.getPersonalTraining();
    let meal_periods;
    let food_recipes;

    if (this.props.userPermissionIds.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION)) {
      meal_periods = getModels(setUrlParameters(routes.MEAL_PERIODS_GET_API, {visible_at_history: true}));
    }

    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION)) {
      food_recipes = getModels(setUrlParameters(routes.FOOD_RECIPES_GET_API, {is_active: true, user_id: this.props.student.id}));
    }

    const update = {
      loadingData: false
    };

    studentFoodPrescription = await studentFoodPrescription;

    if(studentFoodPrescription) {
      if(studentFoodPrescription.food_prescription) {
        update.activePrescription = studentFoodPrescription.food_prescription;
        update.activePrescriptionData = getFoodPrescriptionNutritionalData(update.activePrescription);
        update.initialPrescriptionDate = studentFoodPrescription.initial_date;
        update.initialPrescriptionDateInput = studentFoodPrescription.initial_date;
        update.visibleToStudent = studentFoodPrescription.visible_to_student;
        update.visibleToStudentInput = studentFoodPrescription.visible_to_student;
        update.graphVisibleToStudent = studentFoodPrescription.graph_visible_to_student;
        update.graphVisibleToStudentInput = studentFoodPrescription.graph_visible_to_student;
        update.indicatorsVisibleToStudent = studentFoodPrescription.indicators_visible_to_student;
        update.indicatorsVisibleToStudentInput = studentFoodPrescription.indicators_visible_to_student;

        if (studentFoodPrescription.final_date !== null) {
          const initialDate = getAsLocalDate(studentFoodPrescription.initial_date);
          const finalDate = getAsLocalDate(studentFoodPrescription.final_date);

          const timeDiff = Math.abs(finalDate.getTime() - initialDate.getTime());
          const daysCount = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)));

          initialDate.setDate(initialDate.getDate() + (daysCount));
          update.prescriptionDuration = daysCount;
          update.finalPrescriptionDate =  getLocalDateIsoString(initialDate);
          update.finalPrescriptionDateInput = getLocalDateIsoString(initialDate);
        }
      }

      if(studentFoodPrescription.diet_sample) {
        update.diet_sample = studentFoodPrescription.diet_sample;

        update.diet_sample.food_recipe_associations.forEach((association) => {
          association.food_recipe.basic_ingredient_associations.sort((a, b) => b.order - a.order);
        });

        update.dietSampleData = getFoodPrescriptionNutritionalData(update.diet_sample);
      }

      if (studentFoodPrescription.auth_hash) {
        update.food_history_auth_hash = studentFoodPrescription.auth_hash;
      }

      if(studentFoodPrescription.last_exam) {
        update.last_exam = studentFoodPrescription.last_exam;
      }

      if(studentFoodPrescription.student_status) {
        update.studentStatus = studentFoodPrescription.student_status;
      }

      if(studentFoodPrescription.food_prescription_history) {
        update.food_prescription_history = studentFoodPrescription.food_prescription_history;
      }

      if(studentFoodPrescription.service_energy_expense_map) {
        update.service_energy_expense_map = studentFoodPrescription.service_energy_expense_map;
      }

      if(studentFoodPrescription.training_data) {
        update.training_data = studentFoodPrescription.training_data;
      }

      if(studentFoodPrescription.weekly_exercise_frequency) {
        update.weekly_exercise_frequency = studentFoodPrescription.weekly_exercise_frequency;
      }

      if(studentFoodPrescription.weight_measurements) {
        update.weight_measurements = studentFoodPrescription.weight_measurements;
      }

      if(studentFoodPrescription.followup_form_submissions) {
        update.followup_form_submissions = studentFoodPrescription.followup_form_submissions;
      }

      if(studentFoodPrescription.contract_expiration_date) {
        update.contract_expiration_date = studentFoodPrescription.contract_expiration_date;
      }

      if(studentFoodPrescription.student_food_allergens) {
        update.student_food_allergens = studentFoodPrescription.student_food_allergens;
      }

      if(studentFoodPrescription.user_service_status) {
        update.user_service_status = studentFoodPrescription.user_service_status;
      }
    }

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          if (entry.value.length > 0) {
            update.weeklyExerciseFrequency = parseInt(entry.value);
          }
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPhysicalEvaluationData = physicalEvaluationData;
    }
    else {
      physicalEvaluationData = [];
    }

    const parameters = {
      user_id: this.props.student.id,
      active_only: true
    };

    if(this.state.suggestedPrescriptionsOnlyFilter) {
      const main_objective = physicalEvaluationData.find((entry) => entry.question_id === 1);

      if(main_objective) {
        parameters.main_objective = main_objective.value;
      }
      else {
        parameters.main_objective = '';
      }

      parameters.target_gender = this.props.student.gender;
    }
    else {
      if(this.state.mainObjectiveFilter) {
        parameters.main_objective = this.state.mainObjectiveFilter;
      }
      if(this.state.genderFilter) {
        parameters.target_gender = this.state.genderFilter;
      }
    }

    if(this.state.minWeightFilter) {
      parameters.min_weight = this.state.minWeightFilter;
    }
    if(this.state.maxWeightFilter) {
      parameters.max_weight = this.state.maxWeightFilter;
    }

    let food_prescriptions;

    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION)) {
      food_prescriptions = getModels(setUrlParameters(routes.FOOD_PRESCRIPTIONS_GET_API, parameters));

      food_prescriptions = await food_prescriptions;

      if(food_prescriptions) {
        update.food_prescriptions = food_prescriptions;
      }
      else {
        this.props.history.replace(routes.DESKTOP_PATH);
        return;
      }
    }

    if (this.props.userPermissionIds.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION)) {
      meal_periods = await meal_periods;

      if(meal_periods) {
        update.meal_periods = meal_periods;
        update.meal_periods.sort((a, b) => {
          if (a.start_time !== null && b.start_time !== null) {
            const result = a.start_time.localeCompare(b.start_time);

            if (result !== 0) {
              return result;
            }

            return a.name.localeCompare(b.name);
          }
          else if (a.start_time !== null && b.start_time === null) {
            return -1;
          }
          else if (a.start_time === null && b.start_time !== null) {
            return 1;
          }
          else {
            return a.name.localeCompare(b.name);
          }
        });
      }
    }

    personalTraining = await personalTraining;

    if (personalTraining && personalTraining.training_period && personalTraining.student_weight) {
      let totalEnergy = 0;

      for(const trainingDay of personalTraining.training_period.training_days) {
        for(const association of trainingDay.group_associations) {
          if(association.activity_reference) {
            totalEnergy += personalTraining.student_weight * (association.time_required / 60) * association.activity_reference.met;
          }
        }
      }

      const periodReference = personalTraining.training_period.period_reference || 7;

      update.weeklyExerciseEnergyExpense = 7 * (totalEnergy / periodReference);
    }

    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION)) {
      food_recipes = await food_recipes;

      if(food_recipes) {
        update.food_recipes = food_recipes;
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    const permissionStatus = await navigator.permissions.query({name: 'clipboard-write'});

    if(permissionStatus.state === 'granted') {
      this.setState({
        clipboardWritePermission: true
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.genderFilter !== this.state.genderFilter ||
        prevState.mainObjectiveFilter !== this.state.mainObjectiveFilter ||
        prevState.minWeightFilter !== this.state.minWeightFilter ||
        prevState.maxWeightFilter !== this.state.maxWeightFilter ||
        prevState.suggestedPrescriptionsOnlyFilter !== this.state.suggestedPrescriptionsOnlyFilter) {
      this.reloadAvailableFoodPrescriptions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  onDuplicateFoodPrescription(entryId) {
    this.setState({foodPrescriptioIdToDuplicate: entryId});
  }

  onDuplicateFoodRecipe(entryId) {
    this.setState({foodRecipeIdToDuplicate: entryId});
  }

  activatePrescription(food_prescription_id) {
    this.setState({idToActivate: food_prescription_id});
  }

  deactivatePrescription() {
    this.setState({deactivatePrescription: true});
  }

  onChangeTrackingStatus(enableTracking) {
    this.setState({
      enableTracking: enableTracking,
      confirmFailed: false
    });
  }

  onDeleteFoodPrescription(entryId) {
    this.setState({foodPrescriptioIdToDelete: entryId});
  }

  onDeleteFoodRecipe(entryId) {
    this.setState({foodRecipeIdToDelete: entryId});
  }

  onDeleteFoodPrescriptionHistory(entryId) {
    this.setState({foodPrescriptionHistoryIdToDelete: entryId});
  }

  onFinishRevision() {
    this.setState({finishRevision: true});
  }

  getAvailablePrescriptionProperties() {
    let properties = [
      Property('name', 'Dieta', <i className="fas fa-clipboard-list"></i>),
      Property('main_objective', 'Objetivo', <i className="fas fa-bullseye"></i>),
      Property('target_gender', 'Gênero', <i className="fas fa-venus-mars"></i>),
      Property('target_weight', 'Peso (kg)', <i className="fas fa-weight"></i>),
    ];

    return properties;
  }

  getAvailablePrescriptionActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="food-prescription__activate-button"
          onClick={() => this.activatePrescription(entry.id)}
        >

          <i className="far fa-check-square food-prescription__activate-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Ativar' : ''}

        </button>

        <Link
          className="model-table__default-edit-button"
          to={`${routes.FOOD_PRESCRIPTION_EDIT_PATH}${entry.id}`}
        >

          {this.props.userPermissionIds.includes(permissions.EDIT_FOOD_PRESCRIPTION_PERMISSION) ?
            <i className="fas fa-edit"></i>:
            <i className="fas fa-eye"></i>
          }

        </Link>

        {(entry.user_id !== null && this.props.userPermissionIds.includes(permissions.DELETE_FOOD_PRESCRIPTION_PERMISSION)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteFoodPrescription(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION) &&
          <button
            className="model-table__default-link-button"
            onClick={() => this.onDuplicateFoodPrescription(entry.id)}
          >

            <i className="fas fa-clone"></i>

          </button>
        }

      </div>
    );
  }

  getClassificationText(entry) {
    return entry.meal_classification !== null ? entry.meal_classification.name : '';
  }

  getEnergyText(entry) {
    if (entry.total_energy === null || !entry.total_energy_unit) {
      return '-';
    }

    let total_energy = Math.round(entry.total_energy * 10) / 10;

    return `${total_energy} ${entry.total_energy_unit.shortname}`;
  }

  getDistributionText(entry) {
    if (entry.total_protein_percentage === null || entry.total_fat_percentage === null || entry.total_carbohydrate_percentage === null) {
      return '-';
    }

    const total_carbohydrate_percentage = Math.round(100 * entry.total_carbohydrate_percentage);
    const total_protein_percentage = Math.round(100 * entry.total_protein_percentage);
    const total_fat_percentage = Math.round(100 * entry.total_fat_percentage);

    return (
      <div className="food-prescription__macronutrient-distribution">

        <div className="food-prescription__macronutrient-distribution__nutrient--carb">

          <h4 className="food-prescription__macronutrient-distribution__nutrient__label">C</h4>
          <div
            className="food-prescription__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_carbohydrate_percentage}em`}}
          >
          </div>
          <p className="food-prescription__macronutrient-distribution__nutrient__value">{total_carbohydrate_percentage}%</p>

        </div>

        <div className="food-prescription__macronutrient-distribution__nutrient--protein">

          <h4 className="food-prescription__macronutrient-distribution__nutrient__label">P</h4>
          <div
            className="food-prescription__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_protein_percentage}em`}}
          >
          </div>
          <p className="food-prescription__macronutrient-distribution__nutrient__value">{total_protein_percentage}%</p>

        </div>

        <div className="food-prescription__macronutrient-distribution__nutrient--fat">

          <h4 className="food-prescription__macronutrient-distribution__nutrient__label">G</h4>
          <div
            className="food-prescription__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_fat_percentage}em`}}
          >
          </div>
          <p className="food-prescription__macronutrient-distribution__nutrient__value">{total_fat_percentage}%</p>

        </div>

      </div>
    );
  }

  getFoodRecipeProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Receita', <i className="fas fa-utensils"></i>)
    );
    properties.push(
      Property(
        'total_energy',
        'Energia (1 pessoa)',
        <i className="fas fa-bolt"></i>,
        {getDataText: this.getEnergyText, getFilterText: this.getEnergyText})
    );
    properties.push(
      Property(
        'people_served',
        'Rendimento',
        <i className="fa-solid fa-bowl-food"></i>)
    );
    properties.push(
      Property(
        'total_protein_percentage',
        'Macronutrientes',
        <i className="fas fa-chart-pie"></i>,
        {getDataText: this.getDistributionText, applyFilter: false, sortable: false})
    );
    properties.push(
      Property(
        'meal_classification_id',
        'Classificação',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getClassificationText, getFilterText: this.getClassificationText})
    );

    return properties;
  }

  getFoodRecipeActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-edit-button"
          to={`${routes.FOOD_RECIPE_EDIT_PATH}${entry.id}`}
        >

          {this.props.userPermissionIds.includes(permissions.EDIT_FOOD_RECIPE_PERMISSION) ?
            <i className="fas fa-edit"></i>:
            <i className="fas fa-eye"></i>
          }

        </Link>

        {(entry.user_id !== null && this.props.userPermissionIds.includes(permissions.DELETE_FOOD_RECIPE_PERMISSION)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteFoodRecipe(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION) &&
          <button
            className="model-table__default-link-button"
            onClick={() => this.onDuplicateFoodRecipe(entry.id)}
          >

            <i className="fas fa-clone"></i>

          </button>
        }

      </div>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.idToActivate !== null) {
        return 'Ativando dieta';
      }
      else if(this.state.deactivatePrescription) {
        return 'Desativando dieta';
      }
      else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
        return 'Deletando entrada do histórico';
      }
      else if(this.state.foodPrescriptioIdToDelete !== null) {
        return 'Deletando dieta';
      }
      else if(this.state.foodRecipeIdToDelete !== null) {
        return 'Deletando receita';
      }
      else if(this.state.foodPrescriptioIdToDuplicate !== null) {
        return 'Duplicando dieta';
      }
      else if(this.state.foodRecipeIdToDuplicate !== null) {
        return 'Duplicando receita';
      }
      else if(this.state.enableTracking !== null) {
        if (this.state.enableTracking) {
          return 'Habilitando acompanhamento'
        }

        return 'Desabilitando acompanhamento';
      }
      else if(this.state.finishRevision) {
        return 'Concluindo revisão';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.idToActivate !== null) {
        return 'Falha ao ativar dieta';
      }
      else if(this.state.deactivatePrescription) {
        return 'Falha ao desativar dieta';
      }
      else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
        return 'Falha ao deletar entrada de histórico';
      }
      else if(this.state.foodPrescriptioIdToDelete !== null) {
        return 'Falha ao deletar dieta';
      }
      else if(this.state.foodRecipeIdToDelete !== null) {
        return 'Falha ao deletar receita';
      }
      else if(this.state.foodPrescriptioIdToDuplicate !== null) {
        return 'Falha ao duplicar dieta';
      }
      else if(this.state.foodRecipeIdToDuplicate !== null) {
        return 'Falha ao duplicar receita';
      }
      else if(this.state.enableTracking !== null) {
        return 'Falha ao habilitar/desabilitar acompanhamento';
      }
      else if(this.state.finishRevision) {
        return 'Falha ao concluir revisão';
      }

      return 'Unknown fail';
    }

    if(this.state.idToActivate !== null) {
      return 'Ativar dieta';
    }
    else if(this.state.deactivatePrescription) {
      return 'Desativar dieta';
    }
    else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
      return 'Deletar entrada de histórico';
    }
    else if(this.state.foodPrescriptioIdToDelete !== null) {
      return 'Deletar dieta';
    }
    else if(this.state.foodRecipeIdToDelete !== null) {
      return 'Deletar receita';
    }
    else if(this.state.foodPrescriptioIdToDuplicate !== null) {
      return 'Duplicar dieta';
    }
    else if(this.state.foodRecipeIdToDuplicate !== null) {
      return 'Duplicar receita';
    }
    else if(this.state.enableTracking !== null) {
      if (this.state.enableTracking) {
        return 'Habilitar acompanhamento';
      }

      return 'Desabilitar acompanhamento';
    }
    else if(this.state.finishRevision) {
      return 'Concluir revisão';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.idToActivate != null) {
      return 'A dieta selecionada será ativada para o aluno. Caso haja uma dieta já ativa ela será substituída.';
    }
    else if(this.state.deactivatePrescription) {
      return 'A dieta atual do aluno será desativada.';
    }
    else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
      return 'A entrada de histórico de dieta será apagada permanentemente.';
    }
    else if(this.state.foodPrescriptioIdToDelete !== null) {
      return 'A dieta será apagada permanentemente.';
    }
    else if(this.state.foodRecipeIdToDelete !== null) {
      return 'A receita será apagada permanentemente.';
    }
    else if(this.state.foodPrescriptioIdToDuplicate !== null) {
      return 'Uma cópia da dieta selecionada será criada para o aluno.';
    }
    else if(this.state.foodRecipeIdToDuplicate !== null) {
      return 'Uma cópia da receita selecionada será criada para o aluno.';
    }
    else if(this.state.enableTracking !== null) {
      return 'O cadastro do aluno será atualizado.';
    }
    else if(this.state.finishRevision) {
      return 'A revisão de acompanhamento do cliente será marcada como concluída.';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.idToActivate != null) {
      return 'Ativar';
    }
    else if(this.state.deactivatePrescription) {
      return 'Desativar';
    }
    else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
      return 'Deletar';
    }
    else if(this.state.foodPrescriptioIdToDelete !== null) {
      return 'Deletar';
    }
    else if(this.state.foodRecipeIdToDelete !== null) {
      return 'Deletar';
    }
    else if(this.state.foodPrescriptioIdToDuplicate !== null) {
      return 'Duplicar';
    }
    else if(this.state.foodRecipeIdToDuplicate !== null) {
      return 'Duplicar';
    }
    else if(this.state.enableTracking !== null) {
      if (this.state.enableTracking) {
        return 'Habilitar';
      }

      return 'Desabilitar';
    }
    else if(this.state.finishRevision) {
      return 'Concluir';
    }

    return 'Unknown';
  }

  onCancelConfirmation() {
    this.setState({
      idToActivate: null,
      deactivatePrescription: false,
      foodPrescriptionHistoryIdToDelete: null,
      foodPrescriptioIdToDelete: null,
      foodRecipeIdToDelete: null,
      finishRevision: false,
      foodPrescriptioIdToDuplicate: null,
      foodRecipeIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.idToActivate != null) {
      const data = {food_prescription_id: this.state.idToActivate};

      try{
        if(await postModel(routes.STUDENT_FOOD_PRESCRIPTION_POST_API.replace('{id}', this.props.student.id), data)) {
          this.reloadActivePrescription();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if(error.message.includes('No active contract')) {
                  errorDescription = 'Aluno não possui contrato ativo para acessar este serviço.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deactivatePrescription) {
      try{
        if(await deleteModel(routes.STUDENT_FOOD_PRESCRIPTION_DELETE_API.replace('{id}', this.props.student.id))) {
          this.reloadActivePrescription();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if(error.message.includes('No active contract')) {
                  errorDescription = 'Aluno não possui contrato ativo para acessar este serviço.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.foodPrescriptionHistoryIdToDelete !== null) {
      try{
        if(await deleteModel(`${routes.STUDENT_FOOD_PRESCRIPTION_HISTORY_DELETE_API}${this.state.foodPrescriptionHistoryIdToDelete}`)) {
          this.reloadActivePrescription();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.foodPrescriptioIdToDelete !== null) {
      try{
        if(await deleteModel(`${routes.FOOD_PRESCRIPTION_DELETE_API}${this.state.foodPrescriptioIdToDelete}`)) {
          this.reloadAvailableFoodPrescriptions();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'personal_history':
                      descriptions.push('Dieta possui dados de alunos vinculados');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Nesta situação, recomenda-se que o cadastro da dieta seja simplesmente desativado.`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.foodRecipeIdToDelete !== null) {
      try{
        if(await deleteModel(`${routes.FOOD_RECIPE_DELETE_API}${this.state.foodRecipeIdToDelete}`)) {
          this.reloadAvailableFoodPrescriptions();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'food_prescription_associations':
                      descriptions.push('Receita vinculada à dietas alimentares');

                      break;
                    case 'advanced_ingredient_associations':
                      descriptions.push('Receita vinculada à outras receitas');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                   `desfeitos antes de deletar a receita`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.foodPrescriptioIdToDuplicate !== null) {
      try{
        if(await postModel(`${routes.STUDENT_FOOD_PRESCRIPTION_DUPLICATE_POST_API.replace('{student_id}', this.props.student.id.toString())}${this.state.foodPrescriptioIdToDuplicate}`)) {
          this.reloadAvailableFoodPrescriptions();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.foodRecipeIdToDuplicate !== null) {
      try{
        if(await postModel(`${routes.STUDENT_FOOD_RECIPE_DUPLICATE_POST_API.replace('{student_id}', this.props.student.id.toString())}${this.state.foodRecipeIdToDuplicate}`)) {
          this.reloadAvailableFoodPrescriptions();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.enableTracking !== null) {
      const data = {track_food_prescription_service: this.state.enableTracking};

      try{
        if(await patchModel(routes.STUDENT_PRESCRIPTION_SERVICE_TRACKING_PATCH_API.replace('{student_id}', this.props.student.id.toString()), data)) {
          this.reloadActivePrescription();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.finishRevision !== null) {
      try {
        if(await postModel(routes.STUDENT_NUTRI_SERVICE_FINISH_REVISION_POST_API.replace('{student_id}', this.props.student.id.toString()))) {
          this.reloadActivePrescription();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          notificationEmailSent: false
        });

        return;
      }
    }

    this.setState({
      idToActivate: null,
      deactivatePrescription: false,
      foodPrescriptionHistoryIdToDelete: null,
      foodPrescriptioIdToDelete: null,
      foodRecipeIdToDelete: null,
      foodPrescriptioIdToDuplicate: null,
      foodRecipeIdToDuplicate: null,
      enableTracking: null,
      finishRevision: false,
      confirmInProgress: false,
    });
  }

  mayUpdateActivePrescription() {
    const valueChecks = (this.state.initialPrescriptionDateInput !== null && this.state.initialPrescriptionDateInput.length > 0) &&
                        (this.state.finalPrescriptionDateInput !== null && this.state.finalPrescriptionDateInput.length > 0) && (
                         this.state.initialPrescriptionDateInput !== this.state.initialPrescriptionDate ||
                         this.state.finalPrescriptionDateInput !== this.state.finalPrescriptionDate ||
                         this.state.visibleToStudentInput !== this.state.visibleToStudent ||
                         this.state.graphVisibleToStudentInput !== this.state.graphVisibleToStudent ||
                         this.state.indicatorsVisibleToStudentInput !== this.state.indicatorsVisibleToStudent
                        ) &&
                        (this.state.initialPrescriptionDateInput !== this.state.initialPrescriptionDate || this.state.initialPrescriptionDateInput <= this.state.finalPrescriptionDateInput);

    if (valueChecks) {
      if ((this.state.contract_expiration_date !== null && this.state.finalPrescriptionDateInput > this.state.contract_expiration_date) || (this.state.prescriptionDuration !== null && this.state.prescriptionDuration.length > 0 && parseInt(this.state.prescriptionDuration) > 168)) {
        return false;
      }
    }

    return valueChecks;
  }

  async updateActivePrescription() {
    if(!this.state.activePrescription || !this.state.initialPrescriptionDateInput || !this.state.finalPrescriptionDateInput) {
      return;
    }

    this.setState({
      loadingData: true
    });

    const data = {
        food_prescription_id: this.state.activePrescription.id,
        initial_date: this.state.initialPrescriptionDateInput,
        final_date: this.state.finalPrescriptionDateInput,
        visible_to_student: this.state.visibleToStudentInput,
        graph_visible_to_student: this.state.graphVisibleToStudentInput,
        indicators_visible_to_student: this.state.indicatorsVisibleToStudentInput,
        is_replacement: true
    };

    if(await postModel(routes.STUDENT_FOOD_PRESCRIPTION_POST_API.replace('{id}', this.props.student.id), data)) {
      this.reloadActivePrescription();
    }

    this.setState({
      loadingData: false,
    });
  }

  async updatePhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const data = {physical_evaluation: this.state.physicalEvaluationData};

    if(await postModel(routes.PHYSICAL_EVALUATION_DATA_POST_API.replace('{student_id}', this.props.student.id), data)) {
      await this.reloadPhysicalEvaluationData();

      if(this.state.suggestedPrescriptionsOnlyFilter) {
        this.reloadAvailableFoodPrescriptions();
      }
    }

    this.setState({
      loadingData: false,
    });
  }

  getPhysicalEvaluationDataCopy() {
    return this.state.physicalEvaluationData.map((entry) => {
      return {...entry};
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    if(name === 'initial_date') {
      update.initialPrescriptionDateInput = value;

      if (value.length > 0 && this.state.prescriptionDuration > 0) {
        const initialDate = getAsLocalDate(value);
        const daysCount = parseInt(this.state.prescriptionDuration);

        initialDate.setDate(initialDate.getDate() + (daysCount));

        update.finalPrescriptionDateInput = getLocalDateIsoString(initialDate);
      }
    }
    else if(name === 'prescriptionDuration') {
      update.prescriptionDuration = value;

      if (value && parseInt(value) > 0) {
        const initialDate = getAsLocalDate(this.state.initialPrescriptionDateInput);
        const daysCount = parseInt(value);

        initialDate.setDate(initialDate.getDate() + (daysCount));

        update.finalPrescriptionDateInput = getLocalDateIsoString(initialDate);
      }
      else {
        update.finalPrescriptionDateInput = '';
      }
    }
    else if(name.startsWith('physical_evaluation_question:')) {
      const selection = name.split(':');
      const question_id = parseInt(selection[1]);

      const valueCopy = this.getPhysicalEvaluationDataCopy();

      if (question_id === 9) {
        if (value.length > 0) {
          update.weeklyExerciseFrequency = parseInt(value);
        }
        else {
          update.weeklyExerciseFrequency = null;
        }
      }

      for(const entry of valueCopy) {
        if(entry.question_id === question_id) {
          entry.value = value;
          break;
        }
      }

      update.physicalEvaluationData = valueCopy;
    }
    else {
      update[name] = value;
    }

    this.setState(update);
  }

  getTrainingObjectiveOptions() {
    const objectives = [...TRAINING_PERIOD_OBJECTIVES];

    // objectives.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  mayRefreshList() {
    if(this.state.genderInput !== this.state.genderFilter ||
        this.state.mainObjectiveInput !== this.state.mainObjectiveFilter ||
        this.state.minWeightInput !== this.state.minWeightFilter ||
        this.state.maxWeightInput !== this.state.maxWeightFilter ||
        this.state.suggestedPrescriptionsOnlyInput !== this.state.suggestedPrescriptionsOnlyFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      const queryParameters = {};

      if(this.state.suggestedPrescriptionsOnlyInput) {
        queryParameters.suggested_prescriptions_only = this.state.suggestedPrescriptionsOnlyInput;
      }
      else {
        queryParameters.gender = this.state.genderInput;
        queryParameters.main_objective = this.state.mainObjectiveInput;
      }

      queryParameters.min_weight = this.state.minWeightInput;
      queryParameters.max_weight = this.state.maxWeightInput;

      this.props.history.replace(setUrlParameters(`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.FOOD_PRESCRIPTION_PATH}`, queryParameters));

      this.setState({
        genderFilter: this.state.genderInput,
        mainObjectiveFilter: this.state.mainObjectiveInput,
        minWeightFilter: this.state.minWeightInput,
        maxWeightFilter: this.state.maxWeightInput,
        suggestedPrescriptionsOnlyFilter: this.state.suggestedPrescriptionsOnlyInput,
      });
    }
  }

  maySavePhysicalEvaluation() {
    if(!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return false;
    }

    for(const currentEntry of this.state.physicalEvaluationData) {
      for(const originalEntry of this.state.originalPhysicalEvaluationData) {
        if(currentEntry.question_id === originalEntry.question_id) {
          if(currentEntry.value !== originalEntry.value) {
            return true;
          }

          break;
        }
      }
    }

    return false;
  }

  getQuestionOptions(question_id, currentValue=null) {
    let options;

    switch (question_id) {
      case 1:
        const objectives = [...TRAINING_PERIOD_OBJECTIVES];

        options = [
          SelectOption('', 'Não especificado'),
          ...objectives.map((objective) => SelectOption(objective, objective))
        ];
        break;
      case 2:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 3:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 6 meses', '0 à 6 meses'),
          SelectOption('6 meses à 1 ano', '6 meses à 1 ano'),
          SelectOption('1 à 2 anos', '1 à 2 anos'),
          SelectOption('2 à 3 anos', '2 à 3 anos'),
          SelectOption('3 anos ou mais', '3 anos ou mais'),
        ];
        break;
      case 4:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Menos de 5 horas', 'Menos de 5 horas'),
          SelectOption('Entre 5 e 8 horas', 'Entre 5 e 8 horas'),
          SelectOption('Mais de 8 horas', 'Mais de 8 horas'),
        ];
        break;
      case 5:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 6:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 7:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 1 ano', '0 à 1 ano'),
          SelectOption('1 à 3 anos', '1 à 3 anos'),
          SelectOption('3 à 5 anos', '3 à 5 anos'),
          SelectOption('5 anos ou mais', '5 anos ou mais'),
        ];
        break;
      case 8:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim, frequentemente (fins de semana)', 'Sim, frequentemente (fins de semana)'),
          SelectOption('Sim, ocasionalmente', 'Sim, ocasionalmente'),
          SelectOption('Sim, raramente', 'Sim, raramente'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 9:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('1', '1'),
          SelectOption('2', '2'),
          SelectOption('3', '3'),
          SelectOption('4', '4'),
          SelectOption('5', '5'),
          SelectOption('6', '6'),
          SelectOption('7', '7'),
        ];
        break;
      case 10:
        const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

        options = [
          SelectOption('', 'Não especificado'),
          ...levels.map((level) => SelectOption(level, level))
        ];
        break;
      default:
        options = [SelectOption('', 'Não especificado')];
    }

    if(currentValue !== null && !options.some((entry) => entry.value === currentValue)) {
      options.push(SelectOption(currentValue, currentValue));
    }

    return options;
  }

  getNutritionistsResponsibleData() {
    if (!this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) {
      return null;
    }

    let initialAppointmentAt = null;
    let dietActivatedAt = null;
    let finalAppointmentAt = null;

    if (this.state.user_service_status.initial_appointment_at !== null) {
      initialAppointmentAt = getAsLocalDatetime(this.state.user_service_status.initial_appointment_at, false);
      initialAppointmentAt = `Atualizado em ${initialAppointmentAt.toLocaleDateString()} ${getLocalTimeIsoString(initialAppointmentAt)}`;
    }
    if (this.state.user_service_status.diet_activated_at !== null) {
      dietActivatedAt = getAsLocalDatetime(this.state.user_service_status.diet_activated_at, false);
      dietActivatedAt = `Atualizado em ${dietActivatedAt.toLocaleDateString()} ${getLocalTimeIsoString(dietActivatedAt)}`;
    }
    if (this.state.user_service_status.final_appointment_at !== null) {
      finalAppointmentAt = getAsLocalDatetime(this.state.user_service_status.final_appointment_at, false);
      finalAppointmentAt = `Atualizado em ${finalAppointmentAt.toLocaleDateString()} ${getLocalTimeIsoString(finalAppointmentAt)}`;
    }

    return (
      <React.Fragment>

        {this.state.user_service_status.initial_appointment_nutritionist_name &&
          <DefaultInput
            name="initial_appointment_nutritionist_name"
            label="Avaliação inicial realizada por:"
            labelMessage={initialAppointmentAt}
            labelClassName="food-prescription__question__label"
            type="text"
            // handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.user_service_status.initial_appointment_nutritionist_name || ''}
            disabled={true}
          />
        }

        {this.state.user_service_status.diet_responsible_name &&
          <DefaultInput
            name="diet_responsible_name"
            label="Dieta ativada/atualizada por:"
            labelMessage={dietActivatedAt}
            labelClassName="food-prescription__question__label"
            type="text"
            // handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.user_service_status.diet_responsible_name || ''}
            disabled={true}
          />
        }

        {this.state.user_service_status.final_appointment_nutritionist_name &&
          <DefaultInput
            name="final_appointment_nutritionist_name"
            label="Reavaliação realizada por:"
            labelMessage={finalAppointmentAt}
            labelClassName="food-prescription__question__label"
            type="text"
            // handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.user_service_status.final_appointment_nutritionist_name || ''}
            disabled={true}
          />
        }

      </React.Fragment>
    );
  }

  getPhysicalEvaluationQuestions() {
    return this.state.physicalEvaluationData.map((question) => {
      let labelMessage = null;

      if(question.question_id === 1 && !TRAINING_PERIOD_OBJECTIVES.includes(question.value)) {
        labelMessage = 'Atualização de valor necessária.';
      }
      if(question.question_id === 10 && question.value) {
        switch (question.value) {
          case TRAINING_PERIOD_PHYSICAL_LEVELS[0]:
            labelMessage = 'Frequência ótima de 3.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[1]:
            labelMessage = 'Frequência ótima de 4 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[2]:
            labelMessage = 'Frequência ótima de 4.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[3]:
            labelMessage = 'Frequência ótima de 5 vezes/semana.';
            break;
          default:
        }
      }

      return (
        <DefaultInput
          key={`physical_evaluation_question_input_${question.question_id}`}
          name={`physical_evaluation_question:${question.question_id}`}
          label={question.question_text}
          labelMessage={labelMessage}
          labelClassName="food-prescription__question__label"
          type="select"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={question.value || ''}
          options={this.getQuestionOptions(question.question_id, question.value)}
          disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)}
        />
      );
    });
  }

  getDiscClassification(asText=false) {
    let result = ['D'];

    let currentScore = this.props.student.disc.total_d;

    if(currentScore < this.props.student.disc.total_i) {
      result = ['I'];
      currentScore = this.props.student.disc.total_i;
    }
    else if(currentScore === this.props.student.disc.total_i) {
      result.push('I');
    }

    if(currentScore < this.props.student.disc.total_s) {
      result = ['S'];
      currentScore = this.props.student.disc.total_s;
    }
    else if(currentScore === this.props.student.disc.total_s) {
      result.push('S');
    }

    if(currentScore < this.props.student.disc.total_c) {
      result = ['C'];
      currentScore = this.props.student.disc.total_c;
    }
    else if(currentScore === this.props.student.disc.total_c) {
      result.push('C');
    }

    if(asText) {
      return result.join('');
    }

    const elementD = (
      <p
        className="food-prescription__profile__disc__text--d"
        key="disc_classification_d"
      >
        {this.state.screenWidth > 420 ? 'Dominância' : 'D'}
      </p>
    );
    const elementI = (
      <p
        className="food-prescription__profile__disc__text--i"
        key="disc_classification_i"
      >
        {this.state.screenWidth > 420 ? 'Influência' : 'I'}
      </p>
    );
    const elementS = (
      <p
        className="food-prescription__profile__disc__text--s"
        key="disc_classification_s"
      >
        {this.state.screenWidth > 420 ? 'Estabilidade' : 'S'}
      </p>
    );
    const elementC = (
      <p
        className="food-prescription__profile__disc__text--c"
        key="disc_classification_c"
      >
        {this.state.screenWidth > 420 ? 'Complacência' : 'C'}
      </p>
    );

    let classification = [];

    if(result.includes('D')) {
      classification.push(elementD);
    }
    if(result.includes('I')) {
      classification.push(elementI);
    }
    if(result.includes('S')) {
      classification.push(elementS);
    }
    if(result.includes('C')) {
      classification.push(elementC);
    }

    return classification;
  }

  getFoodPrescriptionHistoryProperties() {
    let properties = [
      Property('food_prescription', 'Dieta', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => entry.food_prescription.name,
        getFilterText: (entry) => entry.food_prescription.name,
        getSortCallback: (a, b) => {
          return a.food_prescription.name.localeCompare(b.food_prescription.name);
        }
      }),
      Property('start_date', 'Início', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => getDefaultLocalDateStringFromIsoString(entry.start_date),
        getFilterText: (entry) => getDefaultLocalDateStringFromIsoString(entry.start_date)
      }),
      Property('end_date', 'Fim', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => getDefaultLocalDateStringFromIsoString(entry.end_date),
        getFilterText: (entry) => getDefaultLocalDateStringFromIsoString(entry.end_date)
      }),
    ];

    return properties;
  }

  getFoodPrescriptionHistoryActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {(this.props.userPermissionIds.includes(permissions.DELETE_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteFoodPrescriptionHistory(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getRecipeAssociations(mealPeriod) {
    const filteredAssociations = this.state.diet_sample.food_recipe_associations.filter((association) => association.meal_period_id === mealPeriod.id);

    if (filteredAssociations.length <= 0) {
      return (
        <p className="food-prescription__suttle-alert-text">

          <i className="fas fa-exclamation food-prescription__suttle-alert-text__icon"></i>
          Nada cadastrado

        </p>
      );
    }

    const weekdays = [
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
      'domingo',
    ];

    return filteredAssociations.map((association) => {
      const weekdayInputs = weekdays.map((weekday, index) => {
        const checked = association.weekdays & Math.pow(2, index);

        return (
          <div
            key={`${association.key}:weekday_enabled:${index}`}
            className={`food-prescription__weekday${!checked ? '--disabled': ''}`}
          >

            <p className="food-prescription__weekday__text">

              {weekday}

            </p>

            <button
              className="food-prescription__weekday__select-button"
              disabled={true}
            >

              {(checked > 0) &&
                <i className="fas fa-check"></i>
              }

            </button>

          </div>
        );
      });

      return (
        <div
          className="food-prescription__recipe-association"
          key={`food_recipe_associations:${association.id}`}
        >

          <p
            className="food-prescription__recipe-association__detail"
          >

            <i className="fas fa-clipboard-list"></i>

          </p>

          <div className="food-prescription__recipe-association__content-wrapper">

            <p className="food-prescription__recipe-association__content">{`Refeição ${association.priority}`}</p>

            <ul className="food-prescription__recipe-association__ingredient-list">
              {association.food_recipe.basic_ingredient_associations.map((entry) => {
                let ingredientText = entry.food_ingredient_source_association.food_ingredient.name;

                if (entry.food_ingredient_source_association.processing_method_id !== null) {
                  ingredientText += `| ${entry.food_ingredient_source_association.processing_method.name}`;
                }

                let weightValue = Math.round(10 * entry.quantity*entry.food_ingredient_measurement_association.weight_reference) / 10;

                ingredientText += `| ${entry.quantity} ${entry.food_ingredient_measurement_association.food_measurement.name} | ${weightValue}${entry.food_ingredient_measurement_association.weight_reference_unit.shortname}`;

                return (
                  <li
                    className="food-prescription__recipe-association__ingredient-list__item"
                    key={`basic_ingredient_associations:${entry.id}`}
                  >

                    {ingredientText}

                  </li>
                );
              })}
            </ul>

            <p className="food-prescription__recipe-association__sub-title">Consome geralmente nos dias:</p>

            <div className="food-prescription__recipe-association__weekdays">

              {weekdayInputs}

            </div>

          </div>

        </div>
      );
    });
  }

  getMealPeriods() {
    return this.state.meal_periods.map((period) => {
      let timePeriod = '';

      if (period.start_time !== null && period.end_time !== null) {
        timePeriod = ` (${period.start_time} - ${period.end_time})`;
      }
      else if (period.start_time !== null && period.end_time === null) {
        timePeriod = ` (a partir de ${period.start_time})`;
      }
      else if (period.start_time === null && period.end_time !== null) {
        timePeriod = ` (até ${period.end_time})`;
      }

      return (
        <React.Fragment key={`meal_period:${period.id}`}>

          <DefaultSubSectionTitle
            icon={<i className="fas fa-clock"></i>}
            text={`${period.name}${timePeriod}`}
          />

          <div className="food-prescription__meal-period-container">

            {this.getRecipeAssociations(period)}

          </div>

        </React.Fragment>
      );
    });
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getDietSampleGraphs() {
    const dietSampleData = this.state.dietSampleData;
    const activePrescriptionData = this.state.activePrescriptionData;

    let studentWeight = null;

    if (this.state.last_exam !== null && this.state.last_exam.data.weight && this.state.last_exam.data.weight.length > 0) {
      studentWeight = parseFloat(this.state.last_exam.data.weight);
    }

    if (dietSampleData && activePrescriptionData !== null) {
      if (dietSampleData.nutrientUnitMap.has(NUTRIENT_PROTEIN_ID) && activePrescriptionData.nutrientUnitMap.has(NUTRIENT_PROTEIN_ID) && dietSampleData.nutrientUnitMap.get(NUTRIENT_PROTEIN_ID).id !== activePrescriptionData.nutrientUnitMap.get(NUTRIENT_PROTEIN_ID).id) {
        window.alert('ERRO NOS CÁLCULOS: Unidades de peso de proteína são diferentes!. LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');
      }
      if (dietSampleData.nutrientUnitMap.has(NUTRIENT_FAT_ID) && activePrescriptionData.nutrientUnitMap.has(NUTRIENT_FAT_ID) && dietSampleData.nutrientUnitMap.get(NUTRIENT_FAT_ID).id !== activePrescriptionData.nutrientUnitMap.get(NUTRIENT_FAT_ID).id) {
        window.alert('ERRO NOS CÁLCULOS: Unidades de peso de gordura são diferentes!. LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');
      }
      if (dietSampleData.nutrientUnitMap.has(NUTRIENT_CARBOHYDRATE_ID) && activePrescriptionData.nutrientUnitMap.has(NUTRIENT_CARBOHYDRATE_ID) && dietSampleData.nutrientUnitMap.get(NUTRIENT_CARBOHYDRATE_ID).id !== activePrescriptionData.nutrientUnitMap.get(NUTRIENT_CARBOHYDRATE_ID).id) {
        window.alert('ERRO NOS CÁLCULOS: Unidades de peso de carboidrato são diferentes!. LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');
      }
    }

    const dietSampleCarbohydrateOverview = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalCarbohydrate.mean;
    const dietSampleProteinOverview = PROTEIN_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalProtein.mean;
    const dietSampleFatOverview = FAT_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalFat.mean;

    const dietSampleTotalMean = dietSampleCarbohydrateOverview + dietSampleProteinOverview + dietSampleFatOverview;

    const dietSampleCarbohydrateOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalCarbohydrate.deviation;
    const dietSampleProteinOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalProtein.deviation;
    const dietSampleFatOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * dietSampleData.totalFat.deviation;

    const overviewData = [
      StackGroup('Histórico', [
        StackPoint(Math.round(100 * 100 * (dietSampleCarbohydrateOverview / dietSampleTotalMean)) / 100, 'Carboidrato', Math.round(100 * (dietSampleCarbohydrateOverview / dietSampleTotalMean) * 10) / 10, null, '#8099b3'),
        StackPoint(Math.round(100 * 100 * (dietSampleProteinOverview / dietSampleTotalMean)) / 100, 'Proteína', Math.round(100 * (dietSampleProteinOverview / dietSampleTotalMean) * 10) / 10, null, '#b38080'),
        StackPoint(Math.round(100 * 100 * (dietSampleFatOverview / dietSampleTotalMean)) / 100, 'Gordura', Math.round(100 * (dietSampleFatOverview / dietSampleTotalMean) * 10) / 10, null, '#b3a180'),
      ], '#5899da')
    ];

    const overviewErrorData = [[
      new ErrorPoint([100 * (dietSampleCarbohydrateOverview - dietSampleCarbohydrateOverviewStandardDeviation) / dietSampleTotalMean, 100 * (dietSampleCarbohydrateOverview + dietSampleCarbohydrateOverviewStandardDeviation) / dietSampleTotalMean], 'Carboidrato'),
      new ErrorPoint([100 * (dietSampleProteinOverview - dietSampleProteinOverviewStandardDeviation) / dietSampleTotalMean, 100 * (dietSampleProteinOverview + dietSampleProteinOverviewStandardDeviation) / dietSampleTotalMean], 'Proteína'),
      new ErrorPoint([100 * (dietSampleFatOverview - dietSampleFatOverviewStandardDeviation) / dietSampleTotalMean, 100 * (dietSampleFatOverview + dietSampleFatOverviewStandardDeviation) / dietSampleTotalMean], 'Gordura'),
    ]];

    if (activePrescriptionData !== null) {
      const activePrescriptionCarbohydrateOverview = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalCarbohydrate.mean;
      const activePrescriptionProteinOverview = PROTEIN_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalProtein.mean;
      const activePrescriptionFatOverview = FAT_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalFat.mean;

      const activePrescriptionTotalMean = activePrescriptionCarbohydrateOverview + activePrescriptionProteinOverview + activePrescriptionFatOverview;

      const activePrescriptionCarbohydrateOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalCarbohydrate.deviation;
      const activePrescriptionProteinOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalProtein.deviation;
      const activePrescriptionFatOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * activePrescriptionData.totalFat.deviation;

      overviewData.push(
        StackGroup('Planejado', [
          StackPoint(Math.round(100 * 100 * (activePrescriptionCarbohydrateOverview / activePrescriptionTotalMean)) / 100, 'Carboidrato', Math.round(100 * (activePrescriptionCarbohydrateOverview / activePrescriptionTotalMean) * 10) / 10),
          StackPoint(Math.round(100 * 100 * (activePrescriptionProteinOverview / activePrescriptionTotalMean)) / 100, 'Proteína', Math.round(100 * (activePrescriptionProteinOverview / activePrescriptionTotalMean) * 10) / 10, null, '#da5858'),
          StackPoint(Math.round(100 * 100 * (activePrescriptionFatOverview / activePrescriptionTotalMean)) / 100, 'Gordura', Math.round(100 * (activePrescriptionFatOverview / activePrescriptionTotalMean) * 10) / 10, null, '#daac58'),
        ], '#5899da')
      );
      overviewData.push(
        StackGroup('Desejado', [
          StackPoint(activePrescriptionData.target_carbohydrate_percentage, 'Carboidrato', activePrescriptionData.target_carbohydrate_percentage),
          StackPoint(activePrescriptionData.target_protein_percentage, 'Proteína', activePrescriptionData.target_protein_percentage),
          StackPoint(activePrescriptionData.target_fat_percentage, 'Gordura', activePrescriptionData.target_fat_percentage),
        ], '#9a9a9a')
      );

      overviewErrorData.push([
        new ErrorPoint([100 * (activePrescriptionCarbohydrateOverview - activePrescriptionCarbohydrateOverviewStandardDeviation) / activePrescriptionTotalMean, 100 * (activePrescriptionCarbohydrateOverview + activePrescriptionCarbohydrateOverviewStandardDeviation) / activePrescriptionTotalMean], 'Carboidrato'),
        new ErrorPoint([100 * (activePrescriptionProteinOverview - activePrescriptionProteinOverviewStandardDeviation) / activePrescriptionTotalMean, 100 * (activePrescriptionProteinOverview + activePrescriptionProteinOverviewStandardDeviation) / activePrescriptionTotalMean], 'Proteína'),
        new ErrorPoint([100 * (activePrescriptionFatOverview - activePrescriptionFatOverviewStandardDeviation) / activePrescriptionTotalMean, 100 * (activePrescriptionFatOverview + activePrescriptionFatOverviewStandardDeviation) / activePrescriptionTotalMean], 'Gordura'),
      ]);
    }

    const dietSampleEnergyDailyPoints = [];
    const dietSampleEnergyDailyErrors = [];
    const activePrescriptionEnergyDailyPoints = [];
    const activePrescriptionEnergyDailyErrors = [];

    const dietSampleCarbohydrateDailyPoints = [];
    const dietSampleCarbohydrateDailyErrors = [];
    const activePrescriptionCarbohydrateDailyPoints = [];
    const activePrescriptionCarbohydrateDailyErrors = [];

    const dietSampleProteinDailyPoints = [];
    const dietSampleProteinDailyErrors = [];
    const activePrescriptionProteinDailyPoints = [];
    const activePrescriptionProteinDailyErrors = [];

    const dietSampleFatDailyPoints = [];
    const dietSampleFatDailyErrors = [];
    const activePrescriptionFatDailyPoints = [];
    const activePrescriptionFatDailyErrors = [];

    const dietSampleFiberDailyPoints = [];
    const dietSampleFiberDailyErrors = [];
    const activePrescriptionFiberDailyPoints = [];
    const activePrescriptionFiberDailyErrors = [];

    for (let i=0; i < 7; ++i) {
      dietSampleEnergyDailyPoints.push(StackPoint(Math.round(100 * dietSampleData.energyStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(dietSampleData.energyStatistic.dailyMeans[i] * 10) / 10));
      dietSampleEnergyDailyErrors.push(new ErrorPoint([dietSampleData.energyStatistic.dailyMeans[i] - dietSampleData.energyStatistic.dailyStandardDeviations[i], dietSampleData.energyStatistic.dailyMeans[i] + dietSampleData.energyStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));

      if (studentWeight !== null) {
        const dietSampleDailyCarbohydrateMean = dietSampleData.carbohydrateStatistic.dailyMeans[i] / studentWeight;
        const dietSampleDailyProteinMean = dietSampleData.proteinStatistic.dailyMeans[i] / studentWeight;
        const dietSampleDailyFatMean = dietSampleData.fatStatistic.dailyMeans[i] / studentWeight;

        const dietSampleDailyCarbohydrateStandardDeviation = dietSampleData.carbohydrateStatistic.dailyStandardDeviations[i] / studentWeight;
        const dietSampleDailyProteinStandardDeviation = dietSampleData.proteinStatistic.dailyStandardDeviations[i] / studentWeight;
        const dietSampleDailyFatStandardDeviation = dietSampleData.fatStatistic.dailyStandardDeviations[i] / studentWeight;

        dietSampleCarbohydrateDailyPoints.push(StackPoint(Math.round(100 * dietSampleDailyCarbohydrateMean) / 100, WEEKDAY_NAMES[i], Math.round(dietSampleDailyCarbohydrateMean * 10) / 10));
        dietSampleCarbohydrateDailyErrors.push(new ErrorPoint([(dietSampleDailyCarbohydrateMean - dietSampleDailyCarbohydrateStandardDeviation), (dietSampleDailyCarbohydrateMean + dietSampleDailyCarbohydrateStandardDeviation)], WEEKDAY_NAMES[i]));

        dietSampleProteinDailyPoints.push(StackPoint(Math.round(100 * dietSampleDailyProteinMean) / 100, WEEKDAY_NAMES[i], Math.round(dietSampleDailyProteinMean * 10) / 10));
        dietSampleProteinDailyErrors.push(new ErrorPoint([(dietSampleDailyProteinMean - dietSampleDailyProteinStandardDeviation), (dietSampleDailyProteinMean + dietSampleDailyProteinStandardDeviation)], WEEKDAY_NAMES[i]));

        dietSampleFatDailyPoints.push(StackPoint(Math.round(100 * dietSampleDailyFatMean) / 100, WEEKDAY_NAMES[i], Math.round(dietSampleDailyFatMean * 10) / 10));
        dietSampleFatDailyErrors.push(new ErrorPoint([(dietSampleDailyFatMean - dietSampleDailyFatStandardDeviation), (dietSampleDailyFatMean + dietSampleDailyFatStandardDeviation)], WEEKDAY_NAMES[i]));
      }

      if (activePrescriptionData !== null) {
        activePrescriptionEnergyDailyPoints.push(StackPoint(Math.round(100 * activePrescriptionData.energyStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(activePrescriptionData.energyStatistic.dailyMeans[i] * 10) / 10));
        activePrescriptionEnergyDailyErrors.push(new ErrorPoint([activePrescriptionData.energyStatistic.dailyMeans[i] - activePrescriptionData.energyStatistic.dailyStandardDeviations[i], activePrescriptionData.energyStatistic.dailyMeans[i] + activePrescriptionData.energyStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));

        if (studentWeight !== null) {
          const activePrescriptionDailyCarbohydrateMean = activePrescriptionData.carbohydrateStatistic.dailyMeans[i] / studentWeight;
          const activePrescriptionDailyProteinMean = activePrescriptionData.proteinStatistic.dailyMeans[i] / studentWeight;
          const activePrescriptionDailyFatMean = activePrescriptionData.fatStatistic.dailyMeans[i] / studentWeight;

          const activePrescriptionDailyCarbohydrateStandardDeviation = activePrescriptionData.carbohydrateStatistic.dailyStandardDeviations[i] / studentWeight;
          const activePrescriptionDailyProteinStandardDeviation = activePrescriptionData.proteinStatistic.dailyStandardDeviations[i] / studentWeight;
          const activePrescriptionDailyFatStandardDeviation = activePrescriptionData.fatStatistic.dailyStandardDeviations[i] / studentWeight;

          activePrescriptionCarbohydrateDailyPoints.push(StackPoint(Math.round(100 * activePrescriptionDailyCarbohydrateMean) / 100, WEEKDAY_NAMES[i], Math.round(activePrescriptionDailyCarbohydrateMean * 10) / 10));
          activePrescriptionCarbohydrateDailyErrors.push(new ErrorPoint([(activePrescriptionDailyCarbohydrateMean - activePrescriptionDailyCarbohydrateStandardDeviation), (activePrescriptionDailyCarbohydrateMean + activePrescriptionDailyCarbohydrateStandardDeviation)], WEEKDAY_NAMES[i]));

          activePrescriptionProteinDailyPoints.push(StackPoint(Math.round(100 * activePrescriptionDailyProteinMean) / 100, WEEKDAY_NAMES[i], Math.round(activePrescriptionDailyProteinMean * 10) / 10));
          activePrescriptionProteinDailyErrors.push(new ErrorPoint([(activePrescriptionDailyProteinMean - activePrescriptionDailyProteinStandardDeviation), (activePrescriptionDailyProteinMean + activePrescriptionDailyProteinStandardDeviation)], WEEKDAY_NAMES[i]));

          activePrescriptionFatDailyPoints.push(StackPoint(Math.round(100 * activePrescriptionDailyFatMean) / 100, WEEKDAY_NAMES[i], Math.round(activePrescriptionDailyFatMean * 10) / 10));
          activePrescriptionFatDailyErrors.push(new ErrorPoint([(activePrescriptionDailyFatMean - activePrescriptionDailyFatStandardDeviation), (activePrescriptionDailyFatMean + activePrescriptionDailyFatStandardDeviation)], WEEKDAY_NAMES[i]));
        }
      }

      const dietSampleFiberStatistic = dietSampleData.nutrients.find((nutrientEntry) => nutrientEntry.nutrient.id === NUTRIENT_FIBER_ID);
      const activePrescriptionFiberStatistic = activePrescriptionData !== null ? activePrescriptionData.nutrients.find((nutrientEntry) => nutrientEntry.nutrient.id === NUTRIENT_FIBER_ID) : false;

      if (dietSampleFiberStatistic && activePrescriptionFiberStatistic && dietSampleData.nutrientUnitMap.get(NUTRIENT_FIBER_ID).id !== activePrescriptionData.nutrientUnitMap.get(NUTRIENT_FIBER_ID).id) {
        window.alert('ERRO NOS CÁLCULOS: Unidades de peso de fibra são diferentes!. LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');
      }

      if (dietSampleFiberStatistic) {
        dietSampleFiberDailyPoints.push(StackPoint(Math.round(100 * dietSampleFiberStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(dietSampleFiberStatistic.dailyMeans[i] * 10) / 10));
        dietSampleFiberDailyErrors.push(new ErrorPoint([dietSampleFiberStatistic.dailyMeans[i] - dietSampleFiberStatistic.dailyStandardDeviations[i], dietSampleFiberStatistic.dailyMeans[i] + dietSampleFiberStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
      }
      else if (activePrescriptionFiberStatistic) {
        dietSampleFiberDailyPoints.push(StackPoint(0, WEEKDAY_NAMES[i], 0));
        dietSampleFiberDailyErrors.push(new ErrorPoint([0, 0], WEEKDAY_NAMES[i]));
      }

      if (activePrescriptionData !== null) {
        if (activePrescriptionFiberStatistic) {
          activePrescriptionFiberDailyPoints.push(StackPoint(Math.round(100 * activePrescriptionFiberStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(activePrescriptionFiberStatistic.dailyMeans[i] * 10) / 10));
          activePrescriptionFiberDailyErrors.push(new ErrorPoint([activePrescriptionFiberStatistic.dailyMeans[i] - activePrescriptionFiberStatistic.dailyStandardDeviations[i], activePrescriptionFiberStatistic.dailyMeans[i] + activePrescriptionFiberStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
        }
        else if (dietSampleFiberStatistic) {
          activePrescriptionFiberDailyPoints.push(StackPoint(0, WEEKDAY_NAMES[i], 0));
          activePrescriptionFiberDailyErrors.push(new ErrorPoint([0, 0], WEEKDAY_NAMES[i]));
        }
      }
    }

    const energyDailyData = [
      StackGroup('Histórico', dietSampleEnergyDailyPoints, '#80b388'),
    ];
    const carbohydrateDailyData = [
      StackGroup('Histórico', dietSampleCarbohydrateDailyPoints, '#8099b3'),
    ];
    const proteinDailyData = [
      StackGroup('Histórico', dietSampleProteinDailyPoints, '#b38080'),
    ];
    const fatDailyData = [
      StackGroup('Histórico', dietSampleFatDailyPoints, '#b3a180'),
    ];
    const fiberDailyData = [];

    const energyDailyError = [dietSampleEnergyDailyErrors];
    const carbohydrateDailyError = [dietSampleCarbohydrateDailyErrors];
    const proteinDailyError = [dietSampleProteinDailyErrors];
    const fatDailyError = [dietSampleFatDailyErrors];
    const fiberDailyError = [];

    if (activePrescriptionData !== null) {
      energyDailyData.push(StackGroup('Planejado', activePrescriptionEnergyDailyPoints, '#58da6e'));
      carbohydrateDailyData.push(StackGroup('Planejado', activePrescriptionCarbohydrateDailyPoints, '#5899da'));
      proteinDailyData.push(StackGroup('Planejado', activePrescriptionProteinDailyPoints, '#da5858'));
      fatDailyData.push(StackGroup('Planejado', activePrescriptionFatDailyPoints, '#daac58'));

      energyDailyError.push(activePrescriptionEnergyDailyErrors);
      carbohydrateDailyError.push(activePrescriptionCarbohydrateDailyErrors);
      proteinDailyError.push(activePrescriptionProteinDailyErrors);
      fatDailyError.push(activePrescriptionFatDailyErrors);
    }

    if (dietSampleFiberDailyPoints.length > 0) {
      fiberDailyData.push(StackGroup('Histórico', dietSampleFiberDailyPoints, '#9c80b3'));
      fiberDailyError.push(dietSampleFiberDailyErrors);
    }

    if (activePrescriptionFiberDailyPoints.length > 0) {
      fiberDailyData.push(StackGroup('Planejado', activePrescriptionFiberDailyPoints, '#a258da'));
      fiberDailyError.push(activePrescriptionFiberDailyErrors);
    }

    let dietSampleEnergyPeriodData = [];
    let activePrescriptionEnergyPeriodData = [];

    let dietSampleEnergyPeriodErrorData = [];
    let activePrescriptionEnergyPeriodErrorData = [];

    const periodGraphsContent = [];

    const activeDietPeriodDistributionData = [
      StackGroup('Carboidrato', [], '#5899da'),
      StackGroup('Proteína', [], '#da5858'),
      StackGroup('Gordura', [], '#daac58')
    ];
    const dietSamplePeriodDistributionData = [
      StackGroup('Carboidrato', [], '#5899da'),
      StackGroup('Proteína', [], '#da5858'),
      StackGroup('Gordura', [], '#daac58')
    ];

    for (const period of this.state.meal_periods) {
      const overviewPeriodData = [];
      const overviewPeriodErrorData = [];

      if (dietSampleData.mealPeriodMap.has(period.id)) {
        const dietSampleMealPeriodEntry = dietSampleData.mealPeriodMap.get(period.id);

        dietSampleEnergyPeriodData.push(StackPoint(Math.round(100 * dietSampleMealPeriodEntry.energy.mean) / 100, period.name, Math.round(dietSampleMealPeriodEntry.energy.mean * 10) / 10));
        dietSampleEnergyPeriodErrorData.push(new ErrorPoint([(dietSampleMealPeriodEntry.energy.mean - dietSampleMealPeriodEntry.energy.deviation), (dietSampleMealPeriodEntry.energy.mean + dietSampleMealPeriodEntry.energy.deviation)], period.name));

        const dietSampleCarbohydratePeriodOverview = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.carbohydrate.mean;
        const dietSampleProteinPeriodOverview = PROTEIN_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.protein.mean;
        const dietSampleFatPeriodOverview = FAT_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.fat.mean;

        const dietSamplePeriodTotalMean = dietSampleCarbohydratePeriodOverview + dietSampleProteinPeriodOverview + dietSampleFatPeriodOverview;

        const dietSampleCarbohydratePeriodOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.carbohydrate.deviation;
        const dietSampleProteinPeriodOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.protein.deviation;
        const dietSampleFatPeriodOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * dietSampleMealPeriodEntry.fat.deviation;

        overviewPeriodData.push(
          StackGroup('Histórico', [
            StackPoint(Math.round(100 * 100 * (dietSampleCarbohydratePeriodOverview / dietSamplePeriodTotalMean)) / 100, 'Carboidrato', Math.round(100 * (dietSampleCarbohydratePeriodOverview / dietSamplePeriodTotalMean) * 10) / 10, null, '#8099b3'),
            StackPoint(Math.round(100 * 100 * (dietSampleProteinPeriodOverview / dietSamplePeriodTotalMean)) / 100, 'Proteína', Math.round(100 * (dietSampleProteinPeriodOverview / dietSamplePeriodTotalMean) * 10) / 10, null, '#b38080'),
            StackPoint(Math.round(100 * 100 * (dietSampleFatPeriodOverview / dietSamplePeriodTotalMean)) / 100, 'Gordura', Math.round(100 * (dietSampleFatPeriodOverview / dietSamplePeriodTotalMean) * 10) / 10, null, '#b3a180'),
          ], '#5899da')
        );
        overviewPeriodErrorData.push([
          new ErrorPoint([100 * (dietSampleCarbohydratePeriodOverview - dietSampleCarbohydratePeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean, 100 * (dietSampleCarbohydratePeriodOverview + dietSampleCarbohydratePeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean], 'Carboidrato'),
          new ErrorPoint([100 * (dietSampleProteinPeriodOverview - dietSampleProteinPeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean, 100 * (dietSampleProteinPeriodOverview + dietSampleProteinPeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean], 'Proteína'),
          new ErrorPoint([100 * (dietSampleFatPeriodOverview - dietSampleFatPeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean, 100 * (dietSampleFatPeriodOverview + dietSampleFatPeriodOverviewStandardDeviation) / dietSamplePeriodTotalMean], 'Gordura'),
        ]);

        dietSamplePeriodDistributionData[0].points.push(StackPoint(Math.round(dietSampleCarbohydratePeriodOverview * 100) / 100, period.name, Math.round(dietSampleCarbohydratePeriodOverview * 10) / 10));
        dietSamplePeriodDistributionData[1].points.push(StackPoint(Math.round(dietSampleProteinPeriodOverview * 100) / 100, period.name, Math.round(dietSampleProteinPeriodOverview * 10) / 10));
        dietSamplePeriodDistributionData[2].points.push(StackPoint(Math.round(dietSampleFatPeriodOverview * 100) / 100, period.name, Math.round(dietSampleFatPeriodOverview * 10) / 10));
      }
      else if (activePrescriptionData !== null && activePrescriptionData.mealPeriodMap.has(period.id)) {
        dietSampleEnergyPeriodData.push(StackPoint(0, period.name, 0));
        dietSampleEnergyPeriodErrorData.push(new ErrorPoint([0, 0], period.name));

        overviewPeriodData.push(
          StackGroup('Histórico', [
            StackPoint(0, 'Carboidrato', 0, null, '#8099b3'),
            StackPoint(0, 'Proteína', 0, null, '#b38080'),
            StackPoint(0, 'Gordura', 0, null, '#b3a180'),
          ], '#5899da')
        );
        overviewPeriodErrorData.push([
          new ErrorPoint([0, 0], 'Carboidrato'),
          new ErrorPoint([0, 0], 'Proteína'),
          new ErrorPoint([0, 0], 'Gordura'),
        ]);
      }

      if (activePrescriptionData !== null) {
        if (activePrescriptionData.mealPeriodMap.has(period.id)) {
          const activePrescriptionMealPeriodEntry = activePrescriptionData.mealPeriodMap.get(period.id);

          activePrescriptionEnergyPeriodData.push(StackPoint(Math.round(100 * activePrescriptionMealPeriodEntry.energy.mean) / 100, period.name, Math.round(activePrescriptionMealPeriodEntry.energy.mean * 10) / 10));
          activePrescriptionEnergyPeriodErrorData.push(new ErrorPoint([(activePrescriptionMealPeriodEntry.energy.mean - activePrescriptionMealPeriodEntry.energy.deviation), (activePrescriptionMealPeriodEntry.energy.mean + activePrescriptionMealPeriodEntry.energy.deviation)], period.name));

          const activePrescriptionCarbohydratePeriodOverview = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.carbohydrate.mean;
          const activePrescriptionProteinPeriodOverview = PROTEIN_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.protein.mean;
          const activePrescriptionFatPeriodOverview = FAT_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.fat.mean;

          const activePrescriptionPeriodTotalMean = activePrescriptionCarbohydratePeriodOverview + activePrescriptionProteinPeriodOverview + activePrescriptionFatPeriodOverview;

          const activePrescriptionCarbohydratePeriodOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.carbohydrate.deviation;
          const activePrescriptionProteinPeriodOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.protein.deviation;
          const activePrescriptionFatPeriodOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * activePrescriptionMealPeriodEntry.fat.deviation;

          overviewPeriodData.push(
            StackGroup('Planejado', [
              StackPoint(Math.round(100 * 100 * (activePrescriptionCarbohydratePeriodOverview / activePrescriptionPeriodTotalMean)) / 100, 'Carboidrato', Math.round(100 * (activePrescriptionCarbohydratePeriodOverview / activePrescriptionPeriodTotalMean) * 10) / 10),
              StackPoint(Math.round(100 * 100 * (activePrescriptionProteinPeriodOverview / activePrescriptionPeriodTotalMean)) / 100, 'Proteína', Math.round(100 * (activePrescriptionProteinPeriodOverview / activePrescriptionPeriodTotalMean) * 10) / 10, null, '#da5858'),
              StackPoint(Math.round(100 * 100 * (activePrescriptionFatPeriodOverview / activePrescriptionPeriodTotalMean)) / 100, 'Gordura', Math.round(100 * (activePrescriptionFatPeriodOverview / activePrescriptionPeriodTotalMean) * 10) / 10, null, '#daac58'),
            ], '#5899da')
          );
          overviewPeriodData.push(
            StackGroup('Desejado', [
              StackPoint(activePrescriptionData.target_carbohydrate_percentage, 'Carboidrato', activePrescriptionData.target_carbohydrate_percentage),
              StackPoint(activePrescriptionData.target_protein_percentage, 'Proteína', activePrescriptionData.target_protein_percentage),
              StackPoint(activePrescriptionData.target_fat_percentage, 'Gordura', activePrescriptionData.target_fat_percentage),
            ], '#9a9a9a')
          );
          overviewPeriodErrorData.push([
            new ErrorPoint([100 * (activePrescriptionCarbohydratePeriodOverview - activePrescriptionCarbohydratePeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean, 100 * (activePrescriptionCarbohydratePeriodOverview + activePrescriptionCarbohydratePeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean], 'Carboidrato'),
            new ErrorPoint([100 * (activePrescriptionProteinPeriodOverview - activePrescriptionProteinPeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean, 100 * (activePrescriptionProteinPeriodOverview + activePrescriptionProteinPeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean], 'Proteína'),
            new ErrorPoint([100 * (activePrescriptionFatPeriodOverview - activePrescriptionFatPeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean, 100 * (activePrescriptionFatPeriodOverview + activePrescriptionFatPeriodOverviewStandardDeviation) / activePrescriptionPeriodTotalMean], 'Gordura'),
          ]);

          activeDietPeriodDistributionData[0].points.push(StackPoint(Math.round(activePrescriptionCarbohydratePeriodOverview * 100) / 100, period.name, Math.round(activePrescriptionCarbohydratePeriodOverview * 10) / 10));
          activeDietPeriodDistributionData[1].points.push(StackPoint(Math.round(activePrescriptionProteinPeriodOverview * 100) / 100, period.name, Math.round(activePrescriptionProteinPeriodOverview * 10) / 10));
          activeDietPeriodDistributionData[2].points.push(StackPoint(Math.round(activePrescriptionFatPeriodOverview * 100) / 100, period.name, Math.round(activePrescriptionFatPeriodOverview * 10) / 10));
        }
        else if (dietSampleData.mealPeriodMap.has(period.id)) {
          activePrescriptionEnergyPeriodData.push(StackPoint(0, period.name, 0));
          activePrescriptionEnergyPeriodErrorData.push(new ErrorPoint([0, 0], period.name));

          overviewPeriodData.push(
            StackGroup('Planejado', [
              StackPoint(0, 'Carboidrato', 0),
              StackPoint(0, 'Proteína', 0, null, '#da5858'),
              StackPoint(0, 'Gordura', 0, null, '#daac58'),
            ], '#5899da')
          );
          overviewPeriodData.push(
            StackGroup('Desejado', [
              StackPoint(activePrescriptionData.target_carbohydrate_percentage, 'Carboidrato', activePrescriptionData.target_carbohydrate_percentage),
              StackPoint(activePrescriptionData.target_protein_percentage, 'Proteína', activePrescriptionData.target_protein_percentage),
              StackPoint(activePrescriptionData.target_fat_percentage, 'Gordura', activePrescriptionData.target_fat_percentage),
            ], '#9a9a9a')
          );
          overviewPeriodErrorData.push([
            new ErrorPoint([0, 0], 'Carboidrato'),
            new ErrorPoint([0, 0], 'Proteína'),
            new ErrorPoint([0, 0], 'Gordura'),
          ]);
        }
      }

      if (overviewPeriodData.length > 0) {
        periodGraphsContent.push(
          <React.Fragment key={`meal_period:${period.id}:graphs`}>

            <DefaultSubSectionTitle
              className="food-prescription__sub-section-title"
              icon={<i className="fas fa-chart-bar"></i>}
              text={`Média geral diária - ${period.name}`}
            />

            <StackedBarGraph
              data={overviewPeriodData}
              improvedErrorData={overviewPeriodErrorData}
              lineYAxisType="secondary"
              doNotStack={true}
              height={this.getDefaultGraphHeight()}
              ToolTipValueCallback={(value) => `${value}%`}
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
              normalLegendOrder={true}
              normalXLabel={true}
            />

          </React.Fragment>
        );
      }
    }

    const periodData = [
      StackGroup('Histórico', dietSampleEnergyPeriodData, '#80b388')
    ];
    const periodErrorData = [
      dietSampleEnergyPeriodErrorData
    ];

    if (activePrescriptionData !== null) {
      periodData.push(StackGroup('Planejado', activePrescriptionEnergyPeriodData, '#58da6e'));
      periodErrorData.push(activePrescriptionEnergyPeriodErrorData);
    }

    return (
      <React.Fragment>

        <DefaultSubSectionTitle
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média geral diária"
        />

        <StackedBarGraph
          data={overviewData}
          improvedErrorData={overviewErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}%`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <DefaultSubSectionTitle
          className="food-prescription__sub-section-title"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Distribuições diárias"
        />

        <StackedBarGraph
          title="Energia"
          data={energyDailyData}
          improvedErrorData={energyDailyError}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={activePrescriptionData !== null ? {
            value: activePrescriptionData.target_energy,
            label: 'Consumo desejado',
            showOnTop: true,
            // color: "#9a9a9a",
            // labelFontColor: "#9a9a9a",
          } : null}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Carboidrato"
          data={carbohydrateDailyData}
          improvedErrorData={carbohydrateDailyError}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g/kg corp`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={activePrescriptionData !== null ? {
            value: activePrescriptionData.target_carbohydrate_intake,
            label: 'Consumo desejado',
            showOnTop: true,
            // color: "#9a9a9a",
            // labelFontColor: "#9a9a9a",
          } : null}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Proteína"
          data={proteinDailyData}
          improvedErrorData={proteinDailyError}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g/kg corp`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={activePrescriptionData !== null ? {
            value: activePrescriptionData.target_protein_intake,
            label: 'Consumo desejado',
            showOnTop: true,
            // color: "#9a9a9a",
            // labelFontColor: "#9a9a9a",
          } : null}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Gordura"
          data={fatDailyData}
          improvedErrorData={fatDailyError}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g/kg corp`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={activePrescriptionData !== null ? {
            value: activePrescriptionData.target_fat_intake,
            label: 'Consumo desejada',
            showOnTop: true,
            // color: "#9a9a9a",
            // labelFontColor: "#9a9a9a",
          } : null}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Fibra"
          data={fiberDailyData}
          improvedErrorData={fiberDailyError}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}${dietSampleData.nutrientUnitMap.get(NUTRIENT_FIBER_ID).shortname}`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <DefaultSubSectionTitle
          className="food-prescription__sub-section-title"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média diária por períodos"
        />

        <StackedBarGraph
          data={periodData}
          improvedErrorData={periodErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Histórico"
          data={dietSamplePeriodDistributionData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        {activePrescriptionData !== null &&
          <StackedBarGraph
            title="Planejado"
            data={activeDietPeriodDistributionData}
            lineYAxisType="secondary"
            doNotStack={false}
            height={this.getDefaultGraphHeight()}
            ToolTipValueCallback={(value) => `${value} kcal`}
            legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
            legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            normalLegendOrder={true}
            normalXLabel={true}
          />
        }

        {periodGraphsContent}

      </React.Fragment>
    );
  }

  async copyUserFormLink(textToCopy) {
    try {
      await navigator.clipboard.writeText(textToCopy);

      const linkCopiedSet = new Set(this.state.linkCopiedSet);
      linkCopiedSet.add(textToCopy);

      this.setState({linkCopiedSet});

      window.setTimeout(() => {
        const linkCopiedSet = new Set(this.state.linkCopiedSet);
        linkCopiedSet.delete(textToCopy);
        this.setState({linkCopiedSet});
      }, 1000);
    }
    catch(error) {
      window.alert(error);
    }
  }

  getFoodHistoryLinkButton() {
    if (this.state.food_history_auth_hash === null) {
      return null;
    }

    const foodHistoryLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.STUDENT_FOOD_HISTORY_PATH}${this.state.food_history_auth_hash}`;

    if(this.state.clipboardWritePermission) {
      return (
        <button
          className="model-table__default-button"
          disabled={this.state.linkCopiedSet.has(foodHistoryLink)}
          onClick={(event) => this.copyUserFormLink(foodHistoryLink)}
        >

          <i className="fas fa-link"></i> {this.state.linkCopiedSet.has(foodHistoryLink) ? 'Link copiado' : 'Copiar link do histórico'}

        </button>
      );
    }

    return (
      <a
        className="model-table__default-button"
        href={foodHistoryLink}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i> Página do histórico de alimentação

      </a>
    );
  }

  getAppointmentLinkButton() {
    if (this.props.student.status !== STUDENT_ACTIVE_STATUS || !this.props.student.auth_hash) {
      return null;
    }

    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.SCHEDULE_NUTRITIONAL_EVALUATION_PATH}${this.props.student.auth_hash}`;

    if(this.state.clipboardWritePermission) {
      return (
        <button
          className="model-table__default-button"
          disabled={this.state.linkCopiedSet.has(userFormLink)}
          onClick={(event) => this.copyUserFormLink(userFormLink)}
        >

          <i className="fas fa-link"></i> {this.state.linkCopiedSet.has(userFormLink) ? 'Link do agendamento copiado' : 'Copiar link do agendamento'}

        </button>
      );
    }

    return (
      <a
        className="model-table__default-button"
        href={userFormLink}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i> Página de agendamento

      </a>
    );
  }

  getFoodPrescriptionPDFButton() {
    if (this.state.activePrescription === null || !this.props.userPermissionIds.includes(permissions.DOWNLOAD_FOOD_PRESCRIPTION_PDF_PERMISSION_ID)) {
      return null;
    }

    if(this.state.downloadPdfClicked) {
      return (
        <button
          className="model-table__default-button"
          disabled={true}
        >

          <i className="fas fa-download"></i> Download iniciado

        </button>
      );
    }

    const pdfDownloadLink = `${window.location.protocol}//${window.location.host.replace(':3000', ':5000')}${routes.FOOD_PRESCRIPTION_PDF_API.replace('{food_prescription_id}', this.state.activePrescription.id)}`;

    return (
      <a
        className="model-table__default-button"
        href={pdfDownloadLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          this.setState({downloadPdfClicked: true})
        }}
        download
      >

        <i className="fas fa-download"></i> Baixar PDF da dieta

      </a>
    );
  }

  getPrescriptionDetails() {
    if (this.state.last_exam === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          O aluno ainda não realizou uma avaliação física

        </p>
      );
    }
    else if (!this.state.last_exam.data.basal_metabolic_rate || this.state.last_exam.data.basal_metabolic_rate.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Taxa metabólica basal não especificada

        </p>
      );
    }
    else if (!this.state.last_exam.data.basal_metabolic_multiplier || this.state.last_exam.data.basal_metabolic_multiplier.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Nível de atividade não especificado

        </p>
      );
    }
    else if (!this.state.last_exam.data.weight || this.state.last_exam.data.weight.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Peso não especificado

        </p>
      );
    }
    else if (!this.state.last_exam.data.height || this.state.last_exam.data.height.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Altura não especificada

        </p>
      );
    }
    else if (!this.state.finalPrescriptionDate) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Previsão para fim de dieta não especificado

        </p>
      );
    }
    else if (this.state.weeklyExerciseEnergyExpense <= 0 && this.state.weeklyExerciseFrequency === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Frequência de treino semanal não definido

        </p>
      );
    }

    const weight = parseFloat(this.state.last_exam.data.weight);

    const basal_metabolic_rate = Math.round(10 * parseFloat(this.state.last_exam.data.basal_metabolic_rate)) / 10;
    const basal_metabolic_multiplier = parseFloat(this.state.last_exam.data.basal_metabolic_multiplier);

    const totalDailyEnergyExpense = basal_metabolic_rate * basal_metabolic_multiplier;

    const required_water_per_day = Math.round(10 * 0.035 * weight) / 10;

    const height = parseFloat(this.state.last_exam.data.height);

    const weeklyDietMeanEnergyInput = this.state.activePrescriptionData.energyStatistic.dailyMeans.reduce((sum, value) => sum + value, 0);

    let weeklyHistoryMeanEnergyInput = null;

    if (this.state.dietSampleData !== null) {
      weeklyHistoryMeanEnergyInput = this.state.dietSampleData.energyStatistic.dailyMeans.reduce((sum, value) => sum + value, 0);
    }

    const energyPerClassList = [];

    const energyMeans = [];

    for (const [key, value] of Object.entries(this.state.service_energy_expense_map)) {
      energyMeans.push(value * weight);

      energyPerClassList.push(
        <span
          key={`service:${key}:mean_energy`}
          className="food-prescription__class-energy-mean"
        >

          <span className="food-prescription__class-energy-mean__label">{key}:</span>
          <span className="food-prescription__class-energy-mean__value">{Math.round(value * weight)}</span> kcal/aula

        </span>
      );
    }

    const meanEnergyExpensePerClass = energyMeans.reduce((sum, value) => sum + value, 0) / energyMeans.length;

    let weeklyExerciseEnergyExpense;

    if (this.state.weeklyExerciseEnergyExpense > 0) {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseEnergyExpense;
    }
    else {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseFrequency * meanEnergyExpensePerClass;
    }

    const weeklyBasicActivityEnergyExpense = (7 * basal_metabolic_rate * (basal_metabolic_multiplier - 1)) - weeklyExerciseEnergyExpense;

    const startDate = getAsLocalDate(this.state.initialPrescriptionDate);
    const endDate = getAsLocalDate(this.state.finalPrescriptionDate);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let currentWeek = 'Não iniciado';

    let weekInitialDate = null;
    let weekFinalDate = null;

    let remainingWeekDays = 0;

    let mainStatusIndicator = {
      status: '--disabled',
      text: 'Aguardando início da dieta',
      description: null
    };
    let weightMeasureIndicator = {
      status: '--disabled',
      text: 'Aguardando início da dieta'
    };
    let energyExpenseIndicator = {
      status: '--disabled',
      text: (
        <p className="food-prescription__active-prescription__details__status-field__value__text--disabled">
          Aguardando início da dieta
        </p>
      ),
      additionalText: ''
    };

    let currentWeekWeightMeasurement = false;

    let weekIndex;

    if (today >= endDate) {
      currentWeek = 'Finalizado';

      mainStatusIndicator.text = 'Acompanhamento finalizado';
      mainStatusIndicator.description = 'Esperamos que tenha aproveitado ao máximo este acompanhamento. Como próximo passo, não deixe de realizar o agendamento de finalização com nosso nutricionista e, caso tenha interesse, estamos à sua disposição para iniciarmos um novo período de acompanhamento.';

      let lastWeightMeasurement = null;

      for (const entry of this.state.weight_measurements) {
        if (lastWeightMeasurement === null || lastWeightMeasurement.date < entry.date) {
          lastWeightMeasurement = entry;
        }
      }

      weightMeasureIndicator.text = (
        <React.Fragment>

          Último peso registrado: {lastWeightMeasurement !== null ? (
            <React.Fragment>
              <span className="food-prescription__active-prescription__details__status-field__number">{lastWeightMeasurement.weight}</span> kg ({getAsLocalDateString(lastWeightMeasurement.date)})
            </React.Fragment>
          ) : 'Não registrado'}

        </React.Fragment>
      );

      let totalEnergyExpended = 0;

      for(const trainingData of this.state.training_data) {
        const executionDate = getAsLocalDate(trainingData.date);

        if(executionDate >= startDate && executionDate <= endDate) {
          let entryEnergyExpense = 0;

          if (trainingData.energy_expended) {
            entryEnergyExpense = trainingData.energy_expended;
          }
          else {
            if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
              entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
            }
            else if (trainingData.training_day) {
              entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
                if (association.activity_reference) {
                  return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
                }

                return accumulator;
              }, 0);
            }
          }

          totalEnergyExpended += entryEnergyExpense;
        }
      }

      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const totalWeeks = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7;

      const totalExpectedEnergyExpense = totalWeeks * weeklyExerciseEnergyExpense;
      const percentageAccomplished = 100 * totalEnergyExpended / totalExpectedEnergyExpense;

      energyExpenseIndicator.text = (
        <p className={`food-prescription__active-prescription__details__status-field__value__text${energyExpenseIndicator.status}`}>
          Total de calorias gasta: <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(totalEnergyExpended)}</span> / <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(totalExpectedEnergyExpense)}</span> kcal (<span className="food-prescription__active-prescription__details__status-field__number">{Math.round(percentageAccomplished)}%</span>)
        </p>
      );
    }
    else if (today >= startDate) {
      const timeDiff = Math.abs(today.getTime() - startDate.getTime());

      weekIndex = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7) + 1;
      currentWeek = `Semana ${weekIndex}`;

      weekInitialDate = new Date(startDate);
      weekFinalDate = new Date(startDate);

      weekInitialDate.setDate(weekInitialDate.getDate() + ((weekIndex - 1) * 7));
      weekFinalDate.setDate(weekFinalDate.getDate() + ((weekIndex) * 7) - 1);

      const weekTimeDiff = Math.abs(weekFinalDate.getTime() - today.getTime());

      remainingWeekDays = Math.ceil(weekTimeDiff / (1000 * 3600 * 24)) + 1;

      let weekFinalDayName = (new Intl.DateTimeFormat('pt-BR', {
        weekday: 'long'
      })).format(weekFinalDate);

      let currentWeekEnergyExpended = 0;
      let totalEnergyExpended = 0;

      for(const trainingData of this.state.training_data) {
        const executionDate = getAsLocalDate(trainingData.date);

        if(executionDate >= startDate && executionDate <= weekFinalDate) {
          let entryEnergyExpense = 0;

          if (trainingData.energy_expended) {
            entryEnergyExpense = trainingData.energy_expended;
          }
          else {
            if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
              entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
            }
            else if (trainingData.training_day) {
              entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
                if (association.activity_reference) {
                  return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
                }

                return accumulator;
              }, 0);
            }
          }

          totalEnergyExpended += entryEnergyExpense;

          if (executionDate >= weekInitialDate) {
            currentWeekEnergyExpended += entryEnergyExpense;
          }
        }
      }

      const currentWeekRemainingExpense = weeklyExerciseEnergyExpense - currentWeekEnergyExpended;

      currentWeekWeightMeasurement = this.state.weight_measurements.find((entry) => {
        const measurementDate = getAsLocalDate(entry.date);

        return measurementDate >= weekInitialDate && measurementDate <= weekFinalDate;
      });

      if (!currentWeekWeightMeasurement) {
        mainStatusIndicator.status = '--danger';
        mainStatusIndicator.text = 'Pesagem não realizada';
        mainStatusIndicator.description = `Não deixe de cadastrar seu peso até o dia ${weekFinalDate.toLocaleDateString()} (${weekFinalDayName}).`;

        weightMeasureIndicator.status = '--danger';
        weightMeasureIndicator.text = 'Não realizada';
      }
      else {
        weightMeasureIndicator.status = '--ok';
        weightMeasureIndicator.text = (
          <React.Fragment>

            <span className="food-prescription__active-prescription__details__status-field__number">{currentWeekWeightMeasurement.weight}</span> kg ({getAsLocalDateString(currentWeekWeightMeasurement.date)})

          </React.Fragment>
        );

        if (currentWeekEnergyExpended < weeklyExerciseEnergyExpense) {
          if ((remainingWeekDays * meanEnergyExpensePerClass) <= currentWeekRemainingExpense) {
            mainStatusIndicator.text = 'Não desista do treino';
            mainStatusIndicator.status = '--danger';
            mainStatusIndicator.description = `Mesmo que não seja possível cumprir sua meta semanal de treino, um pouco de treino ainda é melhor do que nenhum treino. Então deixa o desânimo de lado e bora treinar mais um pouco!`;
          }
          else {
            mainStatusIndicator.text = 'Não deixe de realizar seus exercícios semanais';
            mainStatusIndicator.status = '--alert';
            mainStatusIndicator.description = `Ainda faltam ${Math.round(currentWeekRemainingExpense)} kcal, o que, em média, te deixa em dívida de pelo menos mais ${Math.ceil(currentWeekRemainingExpense / meanEnergyExpensePerClass)} treinos. Bora treinar!`;
          }
        }
        else if (totalEnergyExpended < (weekIndex * weeklyExerciseEnergyExpense)) {
          mainStatusIndicator.text = 'Se possível, bora treinar para tirar o atraso';
          mainStatusIndicator.status = '--alert';
          mainStatusIndicator.description = `Parabéns por ter comprido a meta desta semana! Agora, se você quiser caprichar um pouco mais, que tal compensar o que vc deixou de treinar nas semanas anteriores? Sua dívida atual: ${Math.round((weekIndex * weeklyExerciseEnergyExpense) - totalEnergyExpended)} kcal`;
        }
        else {
          mainStatusIndicator.text = 'Parabéns, continue assim!';
          mainStatusIndicator.status = '--ok';
          mainStatusIndicator.description = `Você está no caminho certo. Agora é só manter esse ritmo e não desanimar! Quanto mais você conseguir se adaptar para incorporar hábitos saudáveis no seu dia a dia, mais duradouros serão os resultados que você irá colher no final deste acompanhamento.`;
        }
      }

      energyExpenseIndicator.additionalText = (
        <React.Fragment>
          (faltam <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(currentWeekRemainingExpense)}</span>)
        </React.Fragment>
      );

      if (currentWeekEnergyExpended < (weeklyExerciseEnergyExpense / 3)) {
        energyExpenseIndicator.status = '--danger';
      }
      else if (currentWeekEnergyExpended < weeklyExerciseEnergyExpense) {
        energyExpenseIndicator.status = '--alert';
      }
      else {
        energyExpenseIndicator.status = '--ok';
        energyExpenseIndicator.additionalText = '';
      }

      energyExpenseIndicator.text = (
        <p className={`food-prescription__active-prescription__details__status-field__value__text${energyExpenseIndicator.status}`}>
          <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(currentWeekEnergyExpended)} / {Math.round(weeklyExerciseEnergyExpense)}</span> kcal {energyExpenseIndicator.additionalText}
        </p>
      );
    }

    let weeklyEnergyResult = (7 * basal_metabolic_rate * basal_metabolic_multiplier) - weeklyDietMeanEnergyInput;

    if (this.state.ignoreExerciseEnergyExpense) {
      weeklyEnergyResult -= weeklyExerciseEnergyExpense;
    }

    const weeklyWeightDiffResult = weeklyEnergyResult / BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT;

    return (
      <div className="food-prescription__active-prescription__details">

        <DefaultSubSectionTitle
          icon={<i className="fas fa-chart-line"></i>}
          text="Acompanhamento"
        />

        {this.state.followup_form_submissions.length > 0 &&
          <React.Fragment>

            <div className="food-prescription__followup-report-access">

              <DefaultMenuButton
                className="food-prescription__followup-report-access__show-button"
                onClick={() => this.setState({showFollowupReport: true})}
                text={(
                  <React.Fragment>

                    <i className="fa-solid fa-chart-column food-prescription__followup-report-access__show-button__icon"></i>
                    Relatório semanal

                  </React.Fragment>
                )}
                color="blue"
              />

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        <h1 className="food-prescription__active-prescription__details__current-week">{currentWeek}</h1>

        <div className="food-prescription__active-prescription__details__status">

          <h2 className="food-prescription__active-prescription__details__status__label">Situação:</h2>
          <div className="food-prescription__active-prescription__details__status__value">

            <p className={`food-prescription__active-prescription__details__status__main-value${mainStatusIndicator.status}`}>{mainStatusIndicator.text}</p>
            {mainStatusIndicator.description !== null &&
              <p className="food-prescription__active-prescription__details__status__description">

                {mainStatusIndicator.description}

              </p>
            }

          </div>

        </div>

        <div className="food-prescription__active-prescription__details__status-field">

          <h2 className="food-prescription__active-prescription__details__status-field__label">Pesagem:</h2>
          <div className="food-prescription__active-prescription__details__status-field__value">

            <p className={`food-prescription__active-prescription__details__status-field__value__text${weightMeasureIndicator.status}`}>{weightMeasureIndicator.text}</p>

          </div>

        </div>

        <div className="food-prescription__active-prescription__details__status-field">

          <h2 className="food-prescription__active-prescription__details__status-field__label">Atividade física:</h2>
          <div className="food-prescription__active-prescription__details__status-field__value">

            {energyExpenseIndicator.text}
            {this.state.service_energy_expense_map !== null &&
              <p className="food-prescription__active-prescription__details__status-field__description">

                Médias de gasto calório de aulas FYD: {energyPerClassList}

              </p>
            }

          </div>

        </div>

        <HorizontalRule />

        <div className="food-prescription__active-prescription__details__indicators-container">

          <div className="food-prescription__active-prescription__details__indicators-wrapper">

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Ingestão de água</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{required_water_per_day}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">L/dia</span>

              </p>

            </div>

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Gasto energético total</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(totalDailyEnergyExpense)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Taxa basal</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{basal_metabolic_rate}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Gasto energético cotidiano</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(weeklyBasicActivityEnergyExpense / 7)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            <div
              className={`food-prescription__active-prescription__details__indicator${this.state.ignoreExerciseEnergyExpense ? '--disabled' : ''} food-prescription__active-prescription__details__indicator--clickable`}
              onClick={() => this.setState({ignoreExerciseEnergyExpense: !this.state.ignoreExerciseEnergyExpense})}
            >

              <h2 className="food-prescription__active-prescription__details__indicator__label">Meta de atividade física</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{this.state.ignoreExerciseEnergyExpense ? 0 : Math.round(weeklyExerciseEnergyExpense / 7)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Déficit calórico</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(weeklyEnergyResult / 7)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Perda de peso</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(10 * weeklyWeightDiffResult) / 10}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kg/semana</span>

              </p>

            </div>

          </div>

        </div>

        <div className="food-prescription__graphs-container">

          <DietGraph
            // height={this.getDefaultGraphHeight()}
            legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'top'}
            legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            bodyHeight={height}
            startDate={startDate}
            endDate={endDate}
            normalLegendOrder={this.state.screenWidth <= 770}
            basalMetabolicRate={basal_metabolic_rate}
            basalMetabolicMultiplier={basal_metabolic_multiplier}
            weeklyDietMeanEnergyInput={weeklyDietMeanEnergyInput}
            weeklyHistoryMeanEnergyInput={weeklyHistoryMeanEnergyInput}
            weeklyEnergyExpenseOffset={this.state.ignoreExerciseEnergyExpense ? -weeklyExerciseEnergyExpense : 0}
            initialWeight={weight}
            weightPoints={this.state.weight_measurements.map((entry) => WeightPoint(entry.weight, entry.date))}
            // normalLegendOrder={true}
          />

        </div>

      </div>
    );
  }

  getDietSample() {
    if (this.state.studentStatus !== STUDENT_ACTIVE_STATUS) {
      return null;
    }

    if (this.state.diet_sample !== null) {
      if (!this.props.userPermissionIds.includes(permissions.VIEW_MEAL_PERIOD_PERMISSION)) {
        return null;
      }

      return (
        <React.Fragment>

          <section className="food-prescription__diet-sample">

            <header
              className="food-prescription__diet-sample__header"
              onClick={() => this.setState({dietSampleSectionVisible: !this.state.dietSampleSectionVisible})}
            >

              <h3 className="food-prescription__diet-sample__header__text">
                <i className="fas fa-file-alt food-prescription__diet-sample__header__text-icon"></i>
                Histórico de alimentação
              </h3>

              {this.state.dietSampleSectionVisible ?
                <i className="fas fa-chevron-down food-prescription__diet-sample__header__visible-icon"></i>:
                <i className="fas fa-chevron-up food-prescription__diet-sample__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container food-prescription__diet-sample__content"
              pose={this.state.dietSampleSectionVisible ? 'verticalOpen' : 'verticalClosed'}
            >

              <div className="vertical-accordion-container food-prescription__diet-sample__content-wrapper">

                {this.getMealPeriods()}

                <HorizontalRule />

                {this.getDietSampleGraphs()}

              </div>

            </VerticalAccordionContainer>

          </section>

          {(this.state.diet_sample.description && this.state.diet_sample.description.length > 0) &&
            <DefaultInput
              className="food-prescription__diet-sample__student-instructions-input"
              name="description"
              labelClassName="food-prescription__diet-sample__student-instructions-input__label"
              label="Orientações do aluno"
              type="textarea"
              rows="3"
              disabled={true}
              value={this.state.diet_sample.description || ''}
            />
          }

          {(this.state.student_food_allergens.length > 0) &&
            <React.Fragment>

              <DefaultSubSectionTitle
                icon={<i className="fas fa-suitcase-medical"></i>}
                text="Alergias"
              />

              <div className="food-prescription__diet-sample__allergens-container">

                {this.state.student_food_allergens.map((entry) => (
                  <p
                    key={`student_allergen:${entry.id}`}
                    className="food-prescription__diet-sample__allergen"
                  >
                    {entry.name}
                  </p>
                ))}

              </div>

            </React.Fragment>
          }

          <HorizontalRule />

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          O aluno ainda não preencheu seu histórico de alimentação

        </p>

        <HorizontalRule />

      </React.Fragment>
    );
  }

  getTrackingStatus() {
    if(this.trackingIsEnabled()) {
      return (
        <h2 className="food-prescription__tracking-status--green">Acompanhamento habilitado</h2>
      );
    }

    return (
      <h2 className="food-prescription__tracking-status--red">Acompanhamento desabilitado</h2>
    );
  }

  trackingIsEnabled() {
    if (!this.state.user_service_status || this.state.user_service_status.track_food_prescription_service) {
      return true;
    }

    return false;
  }

  getTabContent() {
    if (this.state.reloadingAvailableFoodPrescriptions) {
      return (
        <PoseGroup>
          <FadeContainer className="content-frame__loading-container" key="preloader">
            <PreLoader local={true} />
          </FadeContainer>
        </PoseGroup>
      );
    }

    switch (this.state.selectedTabIndex) {
      case 1:
        return (
          <React.Fragment>

            <div className="food-prescription__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={`${routes.FOOD_PRESCRIPTION_ADD_PATH}?student_id=${this.props.student.id}`}
                >

                  <i className="fas fa-plus"></i> Adicionar nova dieta

                </Link>
              }

            </div>

            <div className="food-prescription__filters">

              <header className="food-prescription__filters__header">

                <h4 className="food-prescription__filters__header__text">Filtros</h4>

              </header>

              <div className="food-prescription__filters__inputs">

                <div className="food-prescription__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="mainObjectiveInput"
                      label="Objetivo do treinamento"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.suggestedPrescriptionsOnlyInput ? this.getPhysicalEvaluationQuestionValue(1) : (this.state.mainObjectiveInput || '')}
                      options={this.getTrainingObjectiveOptions()}
                      disabled={this.state.suggestedPrescriptionsOnlyInput}
                    />

                    <DefaultInput
                      name="genderInput"
                      label="Gênero alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.suggestedPrescriptionsOnlyInput ? this.props.student.gender : (this.state.genderInput || '')}
                      options={[
                        SelectOption('', 'Todos'),
                        SelectOption('Feminino', 'Feminino'),
                        SelectOption('Masculino', 'Masculino'),
                      ]}
                      disabled={this.state.suggestedPrescriptionsOnlyInput}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="minWeightInput"
                      label="Peso mínimo"
                      type="number"
                      placeholder="-"
                      step="0.1"
                      min="0.0"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.minWeightInput || ''}
                      autoComplete="off"
                      suffix="kg"
                      onFocus={(event) => event.target.select()}
                    />

                    <DefaultInput
                      name="maxWeightInput"
                      label="Peso máximo"
                      type="number"
                      placeholder="-"
                      step="0.1"
                      min="0.0"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.maxWeightInput || ''}
                      autoComplete="off"
                      suffix="kg"
                      onFocus={(event) => event.target.select()}
                    />

                  </HalfWrapper>

                  {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
                    <DefaultInput
                      name="suggestedPrescriptionsOnlyInput"
                      isHighlighted={false}
                      label="Recomendadas apenas:"
                      type="toggle"
                      isHorizontal={true}
                      activeText="Sim"
                      inactiveText="Não"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.suggestedPrescriptionsOnlyInput}
                    />
                  }

                </div>

                <button
                  className="food-prescription__filters__refresh-button"
                  onClick={() => this.refreshList()}
                  disabled={!this.mayRefreshList()}
                >

                  <i className="fas fa-sync"></i>

                </button>

              </div>

            </div>

            <ModelTable
              key="available_prescription_list"
              storageKey="available_prescription_list"
              properties={this.getAvailablePrescriptionProperties()}
              getActions={(entry) => this.getAvailablePrescriptionActions(entry)}
              getRowClassName={(entry) => entry.user_id !== null ? 'food-prescription__prescription-row' : ''}
              data={this.state.food_prescriptions}
              initialOrderBy="name"
            >
            </ModelTable>

          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>

            <div className="food-prescription__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={`${routes.FOOD_RECIPE_ADD_PATH}?student_id=${this.props.student.id}`}
                >

                  <i className="fas fa-plus"></i> Adicionar nova receita

                </Link>
              }

            </div>

            <ModelTable
              key="recipe_list"
              storageKey="recipe_list"
              properties={this.getFoodRecipeProperties()}
              getActions={(entry) => this.getFoodRecipeActions(entry)}
              data={this.state.food_recipes}
              initialOrderBy="name"
            >
            </ModelTable>

          </React.Fragment>
        );
      default:
        return null;
    }
  }

  mayShowPhysicalActivity() {
    return this.state.studentStatus === STUDENT_ACTIVE_STATUS &&
           this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
           (this.state.weeklyExerciseEnergyExpense > 0 ||
            this.state.weeklyExerciseFrequency !== null) &&
           this.state.last_exam !== null &&
           this.state.last_exam.data.weight;
  }

  getPhysicalactivityContent() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    let weekdayInputs = null;

    if (this.state.last_exam.data.active_weekdays !== null && this.state.last_exam.data.active_weekdays.length > 0) {
      const active_weekdays = parseInt(this.state.last_exam.data.active_weekdays);

      weekdayInputs = weekdays.map((weekday, index) => {
        const checked = active_weekdays & Math.pow(2, index);

        return (
          <DefaultInput
            key={`active_weekdays:${index}`}
            name={`active_weekdays:${index}`}
            label={weekday}
            isHorizontal={true}
            invertLabel={true}
            isHighlighted={false}
            type="checkbox"
            value={checked > 0}
            disabled
          />
        );
      });
    }

    const weight = parseFloat(this.state.last_exam.data.weight);

    const energyMeans = [];

    for (const value of Object.values(this.state.service_energy_expense_map)) {
      energyMeans.push(value * weight);
    }

    const meanEnergyExpensePerClass = energyMeans.reduce((sum, value) => sum + value, 0) / energyMeans.length;

    let weeklyExerciseEnergyExpense;

    if (this.state.weeklyExerciseEnergyExpense > 0) {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseEnergyExpense;
    }
    else {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseFrequency * meanEnergyExpensePerClass;
    }

    let weeklyBasicActivityEnergyExpense = null;
    let dailyBasicActivityEnergyExpense = null;
    let totalDailyEnergyExpense = null;

    if (this.state.last_exam !== null &&
        this.state.last_exam.data.basal_metabolic_rate &&
        this.state.last_exam.data.basal_metabolic_rate.length > 0 &&
        this.state.last_exam.data.basal_metabolic_multiplier &&
        this.state.last_exam.data.basal_metabolic_multiplier.length > 0) {
      const basal_metabolic_rate = Math.round(10 * parseFloat(this.state.last_exam.data.basal_metabolic_rate)) / 10;
      const basal_metabolic_multiplier = parseFloat(this.state.last_exam.data.basal_metabolic_multiplier);

      totalDailyEnergyExpense = basal_metabolic_rate * basal_metabolic_multiplier;

      weeklyBasicActivityEnergyExpense = (7 * basal_metabolic_rate * (basal_metabolic_multiplier - 1)) - weeklyExerciseEnergyExpense;
      dailyBasicActivityEnergyExpense = weeklyBasicActivityEnergyExpense / 7;
    }

    const dailyExerciseEnergyExpense = weeklyExerciseEnergyExpense / 7;

    return (
      <React.Fragment>

        <div className="food-prescription__active-prescription__details__indicators-container">

          <div className="food-prescription__active-prescription__details__indicators-wrapper">

            {/* <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Atividade física (semanal)</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(weeklyExerciseEnergyExpense)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/semana</span>

              </p>

            </div> */}

            {totalDailyEnergyExpense !== null &&
              <div className="food-prescription__active-prescription__details__indicator">

                <h2 className="food-prescription__active-prescription__details__indicator__label">Gasto energético total</h2>
                <p className="food-prescription__active-prescription__details__indicator__value">

                  <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(totalDailyEnergyExpense)}</span>
                  <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

                </p>

              </div>
            }

            <div className="food-prescription__active-prescription__details__indicator">

              <h2 className="food-prescription__active-prescription__details__indicator__label">Atividade física (diária)</h2>
              <p className="food-prescription__active-prescription__details__indicator__value">

                <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(dailyExerciseEnergyExpense)}</span>
                <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

              </p>

            </div>

            {/* {weeklyBasicActivityEnergyExpense !== null &&
              <div className="food-prescription__active-prescription__details__indicator">

                <h2 className="food-prescription__active-prescription__details__indicator__label">Atividade cotidiana (semanal)</h2>
                <p className="food-prescription__active-prescription__details__indicator__value">

                  <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(weeklyBasicActivityEnergyExpense)}</span>
                  <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/semana</span>

                </p>

              </div>
            } */}

            {dailyBasicActivityEnergyExpense !== null &&
              <div className="food-prescription__active-prescription__details__indicator">

                <h2 className="food-prescription__active-prescription__details__indicator__label">Atividade cotidiana (diária)</h2>
                <p className="food-prescription__active-prescription__details__indicator__value">

                  <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(dailyBasicActivityEnergyExpense)}</span>
                  <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

                </p>

              </div>
            }

            {this.state.weekly_exercise_frequency !== null &&
              <div className="food-prescription__active-prescription__details__indicator">

                <h2 className="food-prescription__active-prescription__details__indicator__label">Frequência de treinos semanal</h2>
                <p className="food-prescription__active-prescription__details__indicator__value">

                  <span className="food-prescription__active-prescription__details__indicator__value__number">{Math.round(10 * this.state.weekly_exercise_frequency) / 10}</span>
                  <span className="food-prescription__active-prescription__details__indicator__value__unit">treinos/semana</span>

                </p>

              </div>
            }

          </div>

        </div>

        <HorizontalRule />

        {(this.state.last_exam.data.main_fyd_activities !== null && this.state.last_exam.data.main_fyd_activities.length > 0) &&
          <FiledSet legend="Modalidades praticadas">

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_FUNCTIONAL_SERVICE}`}
                label={<span className="personal-training__input-label--red">{SERVICE_FUNCTIONAL_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_FUNCTIONAL_SERVICE)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_GYM_SERVICE}`}
                label={<span className="personal-training__input-label--red">{SERVICE_GYM_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_GYM_SERVICE)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BIKE_SERVICE}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_BIKE_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_BIKE_SERVICE)}
                disabled
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CARDIO}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_CARDIO}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_CARDIO)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BOOTCAMP}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_BOOTCAMP}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_BOOTCAMP)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_PILATES_SERVICE}`}
                label={<span className="personal-training__input-label--green">{SERVICE_PILATES_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_PILATES_SERVICE)}
                disabled
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CORE}`}
                label={<span className="personal-training__input-label--green">{SERVICE_CORE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.last_exam.data.main_fyd_activities.includes(SERVICE_CORE)}
                disabled
              />

            </HalfWrapper>

            {(this.state.last_exam.data.other_main_activities !== null && this.state.last_exam.data.other_main_activities.length > 0) &&
              <DefaultInput
                name="other_main_activities"
                label="Outras"
                type="text"
                placeholder="Outras modalidades praticadas"
                value={this.state.last_exam.data.other_main_activities}
                autoComplete="off"
                disabled
              />
            }

          </FiledSet>
        }

        {weekdayInputs !== null &&
          <FiledSet legend="Dias que pretende treinar">

            <HalfWrapper>

              {weekdayInputs}

            </HalfWrapper>

          </FiledSet>
        }

      </React.Fragment>
    );
  }

  getIndicatorProps(currentValue, lastValue) {
    let scoreStyle = '';
    let iconStyle = null;
    let text = '';

    if (currentValue === null) {
      return [
        scoreStyle,
        iconStyle,
        text
      ];
    }

    if (currentValue >= 9) {
      scoreStyle = '--green';
      text = 'Ótimo';

      if (lastValue !== null) {
        if (lastValue < 9 || lastValue < currentValue) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else if (currentValue >= 7) {
      scoreStyle = '--green';
      text = 'Regular';

      if (lastValue !== null) {
        if (lastValue < 7 || lastValue < currentValue) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else if (lastValue >= 9) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else if (currentValue >= 4) {
      scoreStyle = '--yellow';
      text = 'Ruim';

      if (lastValue !== null) {
        if (lastValue < 4) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else if (lastValue >= 7) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else {
      scoreStyle = '--red';
      text = 'Péssimo';

      if (lastValue !== null) {
        if (lastValue >= 4) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }

    return [
      scoreStyle,
      iconStyle,
      text
    ];
  }

  getYLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 11;
    }
    if(this.state.screenWidth <= 420) {
      return 12;
    }
    if(this.state.screenWidth <= 510) {
      return 13;
    }
    if(this.state.screenWidth <= 680) {
      return 14;
    }
    if(this.state.screenWidth <= 1100) {
      return 15;
    }
    if(this.state.screenWidth <= 1300) {
      return 16;
    }

    return 18;
  }

  getXLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 13;
    }
    if(this.state.screenWidth <= 420) {
      return 14;
    }
    if(this.state.screenWidth <= 510) {
      return 15;
    }
    if(this.state.screenWidth <= 680) {
      return 16;
    }
    if(this.state.screenWidth <= 1100) {
      return 17;
    }
    if(this.state.screenWidth <= 1300) {
      return 18;
    }

    return 20;
  }

  getXAxistUnitLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 8;
    }
    if(this.state.screenWidth <= 420) {
      return 9;
    }
    if(this.state.screenWidth <= 510) {
      return 10;
    }
    if(this.state.screenWidth <= 680) {
      return 11;
    }
    if(this.state.screenWidth <= 1100) {
      return 12;
    }
    if(this.state.screenWidth <= 1300) {
      return 13;
    }

    return 15;
  }

  getXAxisUnitLabelHorizontalOffset() {
    if(this.state.screenWidth <= 1100) {
      return 0;
    }

    return 5;
  }

  getFollowupReport() {
    if (!this.state.showFollowupReport) {
      return null;
    }
    else if (this.state.last_exam === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          O aluno ainda não realizou uma avaliação física

        </p>
      );
    }
    else if (!this.state.last_exam.data.weight || this.state.last_exam.data.weight.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Peso não especificado

        </p>
      );
    }
    else if (!this.state.finalPrescriptionDate) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Previsão para fim de dieta não especificado

        </p>
      );
    }
    else if (this.state.weeklyExerciseEnergyExpense <= 0 && this.state.weeklyExerciseFrequency === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Frequência de treino semanal não definido

        </p>
      );
    }

    const weight = parseFloat(this.state.last_exam.data.weight);

    const energyMeans = [];

    for (const value of Object.values(this.state.service_energy_expense_map)) {
      energyMeans.push(value * weight);
    }

    const meanEnergyExpensePerClass = energyMeans.reduce((sum, value) => sum + value, 0) / energyMeans.length;

    let weeklyExerciseEnergyExpense;

    if (this.state.weeklyExerciseEnergyExpense > 0) {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseEnergyExpense;
    }
    else {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseFrequency * meanEnergyExpensePerClass;
    }

    const startDate = getAsLocalDate(this.state.initialPrescriptionDate);
    const endDate = getAsLocalDate(this.state.finalPrescriptionDate);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (today < startDate) {
      return null;
    }

    let referenceDate;

    if (today >= endDate) {
      referenceDate = endDate;
    }
    else if (today >= startDate) {
      referenceDate = today;
    }

    const timeDiff = Math.abs(referenceDate.getTime() - startDate.getTime());

    let lastWeekIndex = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7);

    const lastWeekInitialDate = new Date(startDate);
    const lastWeekFinalDate = new Date(startDate);

    lastWeekInitialDate.setDate(lastWeekInitialDate.getDate() + ((lastWeekIndex - 1) * 7));
    lastWeekFinalDate.setDate(lastWeekFinalDate.getDate() + ((lastWeekIndex) * 7) - 1);

    const totalTimeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const finalWeekIndex = Math.floor(Math.ceil(totalTimeDiff / (1000 * 3600 * 24)) / 7);

    const totalDays = Math.ceil(totalTimeDiff / (1000 * 3600 * 24)) + 1;
    const currentDay = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

    let lastWeekEnergyExpended = 0;

    for(const trainingData of this.state.training_data) {
      const executionDate = getAsLocalDate(trainingData.date);

      if(executionDate >= startDate && executionDate <= lastWeekFinalDate) {
        let entryEnergyExpense = 0;

        if (trainingData.energy_expended) {
          entryEnergyExpense = trainingData.energy_expended;
        }
        else {
          if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
            entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
          }
          else if (trainingData.training_day) {
            entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
              if (association.activity_reference) {
                return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
              }

              return accumulator;
            }, 0);
          }
        }

        if (executionDate >= lastWeekInitialDate) {
          lastWeekEnergyExpended += entryEnergyExpense;
        }
      }
    }

    const lastWeekExerciseAccomplished = lastWeekEnergyExpended >= weeklyExerciseEnergyExpense;

    const lastWeekWeightMeasurementPerformed = this.state.weight_measurements.some((entry) => {
      const measurementDate = getAsLocalDate(entry.date);

      return measurementDate >= lastWeekInitialDate && measurementDate <= lastWeekFinalDate;
    });

    let objectiveResultText = "Baixo";
    let objectiveResultStyle = "--1";

    if (lastWeekExerciseAccomplished && lastWeekWeightMeasurementPerformed) {
      objectiveResultText = "Excelente";
      objectiveResultStyle = "--3";
    }
    else if (lastWeekExerciseAccomplished || lastWeekWeightMeasurementPerformed) {
      objectiveResultText = "Regular";
      objectiveResultStyle = "--2";
    }

    let currentDietScoreText = '?';
    let currentTrainingScoreText = '?';
    let currentDietScoreValue = null;
    let currentTrainingScoreValue = null;
    let currentFinalScoreText = '?';

    let lastWaterValue = null;
    let currentWaterValue = null;
    let lastSleepValue = null;
    let currentSleepValue = null;
    let lastStressValue = null;
    let currentStressValue = null;

    const scoreHistoryList = [];
    const dietGraphData = [];
    const trainingGraphData = [];
    const waterGraphData = [];
    const sleepGraphData = [];
    const stressGraphData = [];

    const difficultiesAnswerList = [];

    for (let i=2; i <= finalWeekIndex + 1; ++i) {
      const initialDateReference = new Date(startDate);
      let finalDateReference = new Date(startDate);

      initialDateReference.setDate(initialDateReference.getDate() + ((i - 1) * 7));
      finalDateReference.setDate(finalDateReference.getDate() + ((i) * 7) - 1);

      if (finalDateReference > endDate) {
        finalDateReference = new Date(endDate);
      }

      const form = this.state.followup_form_submissions.find((entry) => {
        const submissionDate = getAsLocalDate(entry.submitted_at);

        return submissionDate >= initialDateReference && submissionDate <= finalDateReference;
      });


      if (form) {
        const dietScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_DIET_ID).answer);
        const trainingScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID).answer);

        const waterScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_WATER_ID).answer);
        const sleepScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID).answer);
        const stressScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID).answer);

        const difficultiesAnswer = form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_DIFFICULTIES_ID).answer;

        if (difficultiesAnswer !== null) {
          difficultiesAnswerList.push(
            <li
              key={`followup_report:difficulty_answer:${i-1}`}
              className="food-prescription__followup-report__difficulties-reported__item"
            >

              <h4 className="food-prescription__followup-report__difficulties-reported__item__label">{`${i-1}ª ${this.state.screenWidth > 510 ? 'semana' : 'sem'}:`}</h4>
              <p className="food-prescription__followup-report__difficulties-reported__item__value">{difficultiesAnswer}</p>

            </li>
          );
        }

        if (i === lastWeekIndex + 1) {
          currentDietScoreValue = dietScore;
          currentTrainingScoreValue = trainingScore;
          currentDietScoreText = dietScore < 10 ? dietScore.toFixed(1) : dietScore.toFixed(0);
          currentTrainingScoreText = trainingScore < 10 ? trainingScore.toFixed(1) : trainingScore.toFixed(0);

          const currentFinalScore = (dietScore + trainingScore) / 2;

          currentFinalScoreText = currentFinalScore < 10 ? currentFinalScore.toFixed(1) : currentFinalScore.toFixed(0);

          currentWaterValue = waterScore;
          currentSleepValue = sleepScore;
          currentStressValue = stressScore;
        }
        else if (i === lastWeekIndex) {
          lastWaterValue = waterScore;
          lastSleepValue = sleepScore;
          lastStressValue = stressScore;
        }

        let historyScoreText = (dietScore + trainingScore) / 2;
        historyScoreText = historyScoreText < 10 ? historyScoreText.toFixed(1) : historyScoreText.toFixed(0);

        scoreHistoryList.push((
          <li
            key={`followup_report:score_history_entry:${i-1}`}
            className="food-prescription__followup-report__score-history__list__item"
          >

            <p className="food-prescription__followup-report__score-history__list__item__value">{historyScoreText}</p>
            <p className="food-prescription__followup-report__score-history__list__item__label">{`${i-1}ª ${this.state.screenWidth > 510 ? 'semana' : 'sem'}`}</p>

          </li>
        ));

        dietGraphData.push({
          value: dietScore,
          label: `${i-1}ª`,
          fillColor: "#16d8c5"
        });
        trainingGraphData.push({
          value: trainingScore,
          label: `${i-1}ª`,
          fillColor: "#29a8d0"
        });
        waterGraphData.push({
          value: waterScore,
          label: `${i-1}ª`,
          fillColor: "#66def1"
        });
        sleepGraphData.push({
          value: sleepScore,
          label: `${i-1}ª`,
          fillColor: "#89dba6"
        });
        stressGraphData.push({
          value: stressScore,
          label: `${i-1}ª`,
          fillColor: "#ff7a58"
        });
      }
      else {
        scoreHistoryList.push((
          <li
            key={`followup_report:score_history_entry:${i-1}`}
            className="food-prescription__followup-report__score-history__list__item--faded"
          >

            <p className="food-prescription__followup-report__score-history__list__item__value">?</p>
            <p className="food-prescription__followup-report__score-history__list__item__label">{`${i-1}ª ${this.state.screenWidth > 510 ? 'semana' : 'sem'}`}</p>

          </li>
        ));

        let dietValue = 0;
        let trainingValue = 0;
        let waterValue = 0;
        let sleepValue = 0;
        let stressValue = 0;

        if (i > lastWeekIndex) {
          dietValue = 10;
          trainingValue = 10;
          waterValue = 10;
          sleepValue = 10;
          stressValue = 10;

          if (currentDietScoreValue !== null && currentDietScoreValue <= 6 ) {
            const daysToMax = Math.floor((10 - currentDietScoreValue) / 2);
            dietValue = currentDietScoreValue + ((10 - currentDietScoreValue) * Math.min(daysToMax, i - lastWeekIndex - 1) / daysToMax);
          }
          if (currentTrainingScoreValue !== null && currentTrainingScoreValue <= 6 ) {
            const daysToMax = Math.floor((10 - currentTrainingScoreValue) / 2);
            trainingValue = currentTrainingScoreValue + ((10 - currentTrainingScoreValue) * Math.min(daysToMax, i - lastWeekIndex - 1) / daysToMax);
          }
        }

        dietGraphData.push({
          value: dietValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
        trainingGraphData.push({
          value: trainingValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
        waterGraphData.push({
          value: waterValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
        sleepGraphData.push({
          value: sleepValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
        stressGraphData.push({
          value: stressValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
      }
    }

    const [waterScoreStyle, waterIconStyle, waterIndicatorText] = this.getIndicatorProps(currentWaterValue, lastWaterValue);
    const [sleepScoreStyle, sleepIconStyle, sleepIndicatorText] = this.getIndicatorProps(currentSleepValue, lastSleepValue);
    const [stressScoreStyle, stressIconStyle, stressIndicatorText] = this.getIndicatorProps(11 - currentStressValue, 11 - lastStressValue);

    return (
      <article className="food-prescription__followup-report">

        <img
          className="food-prescription__followup-report__background"
          src={reportBackground}
          alt=""
        />

        <header className="food-prescription__followup-report__header">

          <div className="food-prescription__followup-report__header__title__wrapper">

            <h1 className="food-prescription__followup-report__header__title">{getAbbreviatedName(this.props.student.name)}</h1>

          </div>

          <h3 className="food-prescription__followup-report__header__sub-title">

            <span>Relatório</span>
            <span>{`${lastWeekIndex}ª semana`}</span>

          </h3>

        </header>

        <section className="food-prescription__followup-report__objectives">

          <div className="food-prescription__followup-report__objectives__wrapper">

            <div className="food-prescription__followup-report__objectives__checklist">

              <div className="food-prescription__followup-report__objectives__checklist__item">

                <div className="food-prescription__followup-report__objectives__checklist__item__background"></div>

                <div className="food-prescription__followup-report__objectives__checklist__item__mark">

                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-1"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-2"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-3"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-4"></div>

                  <i className={`fa-sharp fa-solid ${lastWeekWeightMeasurementPerformed ? 'fa-check' : 'fa-xmark'} food-prescription__followup-report__objectives__checklist__item__mark__icon${lastWeekWeightMeasurementPerformed ? '--positive' : '--negative'}`}></i>

                </div>

                <p className="food-prescription__followup-report__objectives__checklist__item__text">Pesou</p>

              </div>

              <div className="food-prescription__followup-report__objectives__symbol">

                <i className="fa-sharp fa-solid fa-plus food-prescription__followup-report__objectives__symbol__icon"></i>

              </div>

              <div className="food-prescription__followup-report__objectives__checklist__item">

                <div className="food-prescription__followup-report__objectives__checklist__item__background"></div>

                <div className="food-prescription__followup-report__objectives__checklist__item__mark">

                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-1"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-2"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-3"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-4"></div>

                  <i className={`fa-sharp fa-solid ${lastWeekExerciseAccomplished ? 'fa-check' : 'fa-xmark'} food-prescription__followup-report__objectives__checklist__item__mark__icon${lastWeekExerciseAccomplished ? '--positive' : '--negative'}`}></i>

                </div>

                <p className="food-prescription__followup-report__objectives__checklist__item__text">Treino</p>
                <p className="food-prescription__followup-report__objectives__checklist__item__sub-text">
                  {Math.round(lastWeekEnergyExpended)}/{Math.round(weeklyExerciseEnergyExpense)} kcal
                </p>

              </div>

            </div>

            <div className="food-prescription__followup-report__objectives__symbol--more-spaced">

              <i className="fa-sharp fa-solid fa-equals food-prescription__followup-report__objectives__symbol__icon--white"></i>

            </div>

            <div className={`food-prescription__followup-report__objectives__result${objectiveResultStyle}`}>

              <h3 className="food-prescription__followup-report__objectives__result__text">{objectiveResultText}</h3>
              <p className="food-prescription__followup-report__objectives__result__sub-text">Grau de motivação</p>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__self-evaluation-scores">

          <div className="food-prescription__followup-report__self-evaluation-scores__days-track">

            {this.screenWidth > 420 ? (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-1"
                src={hexOuterBorderImg}
                alt=""
              />
            ) : (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-1"
                src={hexOuterBorderSmallImg}
                alt=""
              />
            )}
            {this.screenWidth > 420 ? (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-2"
                src={hexInnerBorderImg}
                alt=""
              />
            ) : (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-2"
                src={hexInnerBorderSmallImg}
                alt=""
              />
            )}

            <div className="food-prescription__followup-report__self-evaluation-scores__days-track__wrapper">

              <div className="food-prescription__followup-report__self-evaluation-scores__days-track__logo-image__wrapper">

                <img className="food-prescription__followup-report__self-evaluation-scores__days-track__logo-image" src={logo} alt="Logo da FYD" />

              </div>
              <h3 className="food-prescription__followup-report__self-evaluation-scores__days-track__title">FYD Nutri</h3>
              <p className="food-prescription__followup-report__self-evaluation-scores__days-track__text">
                <span className="food-prescription__followup-report__self-evaluation-scores__days-track__text--highlight">{currentDay}</span>/{totalDays} dias
              </p>

            </div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__separator-1">

            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-1"></div>
            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-2"></div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__current-values">

            <div className="food-prescription__followup-report__self-evaluation-scores__current-values__diet">

              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__diet__value">{currentDietScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__diet__label">dieta</p>

            </div>

            <div className="food-prescription__followup-report__self-evaluation-scores__current-values__training">

              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__training__value">{currentTrainingScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__training__label">Treino</p>

            </div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__separator-2">

            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-3"></div>
            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-4"></div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__result">

            <div className="food-prescription__followup-report__self-evaluation-scores__result__wrapper">

              <p className="food-prescription__followup-report__self-evaluation-scores__result__value">{currentFinalScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__result__label">Sua nota</p>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__score-history">

          <ul className="food-prescription__followup-report__score-history__list">

            {scoreHistoryList}

          </ul>

          <div className="food-prescription__followup-report__score-history__graphs-container">

            <div className="food-prescription__followup-report__score-history__graph">

              <h3 className="food-prescription__followup-report__score-history__graph__title">
                Autoavaliação <span className="food-prescription__followup-report__score-history__graph__title__highlight--diet">dieta</span>
              </h3>

              <div className="food-prescription__followup-report__score-history__graph__wrapper">

                <HorizontalBarGraph
                  scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                  yAxisRange={[0, 10]}
                  data={dietGraphData}
                  axesColor="white"
                  yLabelColor="white"
                  yGridColor="#446e95"
                  yAxisRangeOffsetPercentage={0.2}
                  yAxisLabelStep={2}
                  // barBorderRadius={this.getBarBorderRadius()}
                  barLineWidth={1}
                  xLabelTextSize={this.getXLabelTextSize()}
                  yLabelTextSize={this.getYLabelTextSize()}
                  yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xLabelIsBold={true}
                  xAxisUnitLabel="SEMANA"
                  xAxisUnitLabelPosition="start"
                  xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                  xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                  xAxisUnitLabelVerticalOffset={3}
                />

              </div>

            </div>

            <div className="food-prescription__followup-report__score-history__graph">

              <h3 className="food-prescription__followup-report__score-history__graph__title">
                Autoavaliação <span className="food-prescription__followup-report__score-history__graph__title__highlight--training">treino</span>
              </h3>

              <div className="food-prescription__followup-report__score-history__graph__wrapper">

                <HorizontalBarGraph
                  scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                  yAxisRange={[0, 10]}
                  data={trainingGraphData}
                  axesColor="white"
                  yLabelColor="white"
                  yGridColor="#446e95"
                  yAxisRangeOffsetPercentage={0.2}
                  yAxisLabelStep={2}
                  // barBorderRadius={this.getBarBorderRadius()}
                  barLineWidth={1}
                  xLabelTextSize={this.getXLabelTextSize()}
                  yLabelTextSize={this.getYLabelTextSize()}
                  yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xAxisUnitLabel="SEMANA"
                  xAxisUnitLabelPosition="start"
                  xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                  xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                  xAxisUnitLabelVerticalOffset={3}
                />

              </div>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__additional-info">

          {waterGraphData.length > 0 &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--water">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    {currentWaterValue !== null &&
                      <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                        {waterIconStyle !== null &&
                          <i className={`${waterIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                        }
                        <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${waterScoreStyle}`}>
                          {currentWaterValue.toFixed(0)}/10 {waterIndicatorText}
                        </span>

                      </p>
                    }
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Consumo de água</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__graph">

                  <HorizontalBarGraph
                    scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                    yAxisRange={[0, 10]}
                    data={waterGraphData}
                    axesColor="white"
                    yLabelColor="white"
                    yGridColor="#446e95"
                    yAxisRangeOffsetPercentage={0.2}
                    yAxisLabelStep={2}
                    // barBorderRadius={this.getBarBorderRadius()}
                    barLineWidth={1}
                    xLabelTextSize={this.getXLabelTextSize()}
                    yLabelTextSize={this.getYLabelTextSize()}
                    yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                    xLabelIsBold={true}
                    xAxisUnitLabel="SEMANA"
                    xAxisUnitLabelPosition="start"
                    xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                    xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                    xAxisUnitLabelVerticalOffset={3}
                  />

                </div>

              </div>

            </article>
          }

          {sleepGraphData.length > 0 &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--sleep">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    {currentSleepValue !== null &&
                      <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                        {sleepIconStyle !== null &&
                          <i className={`${sleepIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                        }
                        <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${sleepScoreStyle}`}>
                          {currentSleepValue.toFixed(0)}/10 {sleepIndicatorText}
                        </span>

                      </p>
                    }
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Qualidade do sono</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__graph">

                  <HorizontalBarGraph
                    scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                    yAxisRange={[0, 10]}
                    data={sleepGraphData}
                    axesColor="white"
                    yLabelColor="white"
                    yGridColor="#446e95"
                    yAxisRangeOffsetPercentage={0.2}
                    yAxisLabelStep={2}
                    // barBorderRadius={this.getBarBorderRadius()}
                    barLineWidth={1}
                    xLabelTextSize={this.getXLabelTextSize()}
                    yLabelTextSize={this.getYLabelTextSize()}
                    yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                    xLabelIsBold={true}
                    xAxisUnitLabel="SEMANA"
                    xAxisUnitLabelPosition="start"
                    xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                    xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                    xAxisUnitLabelVerticalOffset={3}
                  />

                  </div>

              </div>

            </article>
          }

          {stressGraphData.length > 0 &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--stress">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    {currentStressValue !== null &&
                      <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                        {stressIconStyle !== null &&
                          <i className={`${stressIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                        }
                        <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${stressScoreStyle}`}>
                          {currentStressValue.toFixed(0)}/10 {stressIndicatorText}
                        </span>

                      </p>
                    }
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Nível de estresse e ansiedade</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__graph">

                  <HorizontalBarGraph
                    scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                    yAxisRange={[0, 10]}
                    data={stressGraphData}
                    axesColor="white"
                    yLabelColor="white"
                    yGridColor="#446e95"
                    yAxisRangeOffsetPercentage={0.2}
                    yAxisLabelStep={2}
                    // barBorderRadius={this.getBarBorderRadius()}
                    barLineWidth={1}
                    xLabelTextSize={this.getXLabelTextSize()}
                    yLabelTextSize={this.getYLabelTextSize()}
                    yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                    xLabelIsBold={true}
                    xAxisUnitLabel="SEMANA"
                    xAxisUnitLabelPosition="start"
                    xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                    xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                    xAxisUnitLabelVerticalOffset={3}
                  />

                  </div>

              </div>

            </article>
          }

        </section>

        {difficultiesAnswerList.length > 0 &&
          <section className="food-prescription__followup-report__difficulties-reported">

            <header className="food-prescription__followup-report__difficulties-reported__header">

                <h3 className="food-prescription__followup-report__difficulties-reported__header__title">Dificuldades mencionadas:</h3>

            </header>

            <ul className="food-prescription__followup-report__difficulties-reported__list">

              {difficultiesAnswerList}

            </ul>

          </section>
        }

      </article>
    );
  }

  getOverlayTitle() {
    return "Relatório semanal";
  }

  getOverlayContent() {
    return this.getFollowupReport();
  }

  getOverlayActionButtons() {
    return (
      <DefaultMenuButton
        className="food-prescription__overlay__action-button"
        onClick={() => this.setState({showFollowupReport: false})}
        text="Fechar"
        color="black"
      />
    );
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ): (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.idToActivate !== null ||
                   this.state.deactivatePrescription ||
                   this.state.foodPrescriptionHistoryIdToDelete !== null ||
                   this.state.foodPrescriptioIdToDelete !== null ||
                   this.state.foodRecipeIdToDelete !== null ||
                   this.state.foodPrescriptioIdToDuplicate !== null ||
                   this.state.foodRecipeIdToDuplicate !== null ||
                   this.state.enableTracking !== null ||
                   this.state.finishRevision}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <OverlayWindow
          className="food-prescription__overlay"
          visible={this.state.showFollowupReport}
          actions={(
            <div className="food-prescription__overlay__action-container">

              {this.getOverlayActionButtons()}

            </div>
          )}
        >

          <header className="food-prescription__overlay__header">

            <h3 className="food-prescription__overlay__header__title">
              {this.getOverlayTitle()}
            </h3>

          </header>

          <hr className="food-prescription__horizontal-rule" />

          <div
            className="food-prescription__overlay__content-container"
          >

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        {this.props.userPermissionIds.includes(permissions.MANAGE_USER_STATUS_PERMISSION_ID) &&
          <React.Fragment>

            <div className="food-prescription__tracking-status-wrapper">

              {this.getTrackingStatus()}

              <button
                className="model-table__default-button food-prescription__tracking-status__toggle-button"
                onClick={(event) => this.onChangeTrackingStatus(!this.trackingIsEnabled())}
              >

                <i className="fas fa-toggle-on"></i> {this.trackingIsEnabled() ? 'Desabilitar acompanhamento' : 'Habilitar acompanhamento'}

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.state.studentStatus !== STUDENT_ACTIVE_STATUS &&
          <p className="food-prescription__alert-text">

            <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
            O aluno não possui um contrato ativo que lhe permita acessar este serviço

          </p>
        }

        {(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) &&
          <React.Fragment>

            <div className="food-prescription__actions-container">

              {this.getFoodPrescriptionPDFButton()}

              {this.getFoodHistoryLinkButton()}

              {this.getAppointmentLinkButton()}

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION) &&
          this.state.user_service_status &&
          (this.state.user_service_status.initial_appointment_nutritionist_name ||
           this.state.user_service_status.diet_responsible_name ||
           this.state.user_service_status.final_appointment_nutritionist_name)) &&
          <React.Fragment>

            <section className="food-prescription__nutritionists-responsible">

              <header
                className="food-prescription__nutritionists-responsible__header"
                onClick={() => this.setState({responsibleSectionVisible: !this.state.responsibleSectionVisible})}
              >

                <h3 className="food-prescription__nutritionists-responsible__header__text">
                  <i className="far fa-chart-bar food-prescription__nutritionists-responsible__header__text-icon"></i>
                  Nutricionistas responsáveis pelo aluno
                </h3>

                {this.state.responsibleSectionVisible ?
                  <i className="fas fa-chevron-down food-prescription__nutritionists-responsible__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up food-prescription__nutritionists-responsible__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container food-prescription__nutritionists-responsible__content"
                pose={this.state.responsibleSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container food-prescription__nutritionists-responsible__content-wrapper">

                  {this.getNutritionistsResponsibleData()}

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
          <React.Fragment>

            <section className="food-prescription__profile">

              <header
                className="food-prescription__profile__header"
                onClick={() => this.setState({profileSectionVisible: !this.state.profileSectionVisible})}
              >

                <h3 className="food-prescription__profile__header__text">
                  <i className="far fa-chart-bar food-prescription__profile__header__text-icon"></i>
                  Perfil
                </h3>

                {this.state.profileSectionVisible ?
                  <i className="fas fa-chevron-down food-prescription__profile__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up food-prescription__profile__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container food-prescription__profile__content"
                pose={this.state.profileSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container food-prescription__profile__content-wrapper">

                  {(this.props.student.disc && this.props.student.disc !== null) ?
                    <div className="food-prescription__profile__disc">

                      <h2 className="food-prescription__profile__disc__label">DISC:</h2>
                      {this.getDiscClassification()}

                    </div>:
                    null
                  }

                  {(this.props.student.note !== null && this.props.student.note.length > 0) &&
                    <DefaultInput
                      name="note"
                      label="Observação"
                      labelClassName="food-prescription__question__label"
                      labelMessage={this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID) ? 'Atualização deve ser realizada na aba "Dados gerais"' : ''}
                      type="text"
                      value={this.props.student.note || ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  }

                  {this.getPhysicalEvaluationQuestions()}

                  {this.maySavePhysicalEvaluation() &&
                    <div className="food-prescription__profile__buttons-container">

                      <button
                        className="food-prescription__profile__save-button"
                        onClick={() => this.updatePhysicalEvaluationData()}
                      >

                        Salvar

                      </button>

                    </div>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.mayShowPhysicalActivity() &&
          <React.Fragment>

            <section className="food-prescription__physicalActivity">

              <header
                className="food-prescription__physicalActivity__header"
                onClick={() => this.setState({physicalActivitySectionVisible: !this.state.physicalActivitySectionVisible})}
              >

                <h3 className="food-prescription__physicalActivity__header__text">
                  <i className="far fa-chart-bar food-prescription__physicalActivity__header__text-icon"></i>
                  Atividade física
                </h3>

                {this.state.physicalActivitySectionVisible ?
                  <i className="fas fa-chevron-down food-prescription__physicalActivity__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up food-prescription__physicalActivity__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container food-prescription__physicalActivity__content"
                pose={this.state.physicalActivitySectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container food-prescription__physicalActivity__content-wrapper">

                  {this.getPhysicalactivityContent()}

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.getDietSample()}

        {(this.state.studentStatus === STUDENT_ACTIVE_STATUS &&
          this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION) &&
          this.state.user_service_status &&
          this.state.user_service_status.food_prescription_service_status === F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS) &&
          <React.Fragment>

            <div className="food-prescription__alert-text-container">

              <p className="food-prescription__alert-text">

                <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
                Revisão de acompanhamento pendente

              </p>

              <button
                className="model-table__default-button food-prescription__alert-text__button"
                disabled={this.state.finishRevision}
                onClick={(event) => this.onFinishRevision()}
              >

                <i className="fa-solid fa-check"></i> Concluir revisão

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.state.activePrescription !== null &&
          <React.Fragment>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-utensils"></i>}
              text="Dieta ativa"
            />

            <section className="food-prescription__active-prescription-container">

              <header className="food-prescription__active-prescription__header">

                <div className="food-prescription__active-prescription__header-wrapper">

                  <div className="food-prescription__active-prescription__header__content-wrapper">

                    <h2 className="food-prescription__active-prescription__header__title">

                      {this.state.activePrescription.name}

                      {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) &&
                        <Link
                          className="food-prescription__active-prescription__header__title__link"
                          to={`${routes.FOOD_PRESCRIPTION_EDIT_PATH}${this.state.activePrescription.id}`}
                        >

                            <i className="fa-solid fa-pen food-prescription__active-prescription__header__title__link__icon"></i>

                        </Link>
                      }

                    </h2>

                    <div className="food-prescription__active-prescription__header__input-container">

                      <DefaultInput
                        className="food-prescription__active-prescription__header__initial-date-input"
                        name="initial_date"
                        type="date"
                        label="Data de início:"
                        placeholder="Data de início"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.initialPrescriptionDateInput || ''}
                        autoComplete="off"
                        isHorizontal={this.state.screenWidth > 600}
                        disabled={this.state.studentStatus !== STUDENT_ACTIVE_STATUS || !this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)}
                      />

                      <DefaultInput
                        className="food-prescription__active-prescription__header__week-duration-input"
                        name="prescriptionDuration"
                        label="Duração:"
                        type="number"
                        placeholder="-"
                        step="1"
                        min="1"
                        max="168"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.prescriptionDuration || ''}
                        autoComplete="off"
                        suffix="Dia(s)"
                        isHorizontal={this.state.screenWidth > 600}
                        disabled={this.state.studentStatus !== STUDENT_ACTIVE_STATUS || !this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)}
                      />

                      <DefaultInput
                        className="food-prescription__active-prescription__header__end-date-input"
                        name="final_date"
                        type="date"
                        label="Previsão de fim:"
                        placeholder="Previsão de fim"
                        min={this.state.initialPrescriptionDateInput}
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.finalPrescriptionDateInput || ''}
                        autoComplete="off"
                        isHorizontal={this.state.screenWidth > 600}
                        disabled={true}
                      />

                      {(this.state.contract_expiration_date !== null && this.state.finalPrescriptionDateInput > this.state.contract_expiration_date) ? (
                          <p className="food-prescription__active-prescription__header__alert">
                            Data final ultrapassa a data de vencimento do contrato ({getAsLocalDateString(this.state.contract_expiration_date)})
                          </p>
                        ) : (this.state.prescriptionDuration !== null && this.state.prescriptionDuration.length > 0 && parseInt(this.state.prescriptionDuration) > 168) ? (
                          <p className="food-prescription__active-prescription__header__alert">
                            Duração não pode ser maior que 24 semanas (168 dias)
                          </p>
                        ) : null
                      }

                      <DefaultInput
                        className="food-prescription__active-prescription__header__visible-to-student-input"
                        name="visibleToStudentInput"
                        label="Visível ao aluno:"
                        type="toggle"
                        isHorizontal={this.state.screenWidth > 600}
                        activeText="Sim"
                        inactiveText="Não"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.visibleToStudentInput}
                      />

                      {this.state.visibleToStudentInput &&
                        <div className="food-prescription__active-prescription__header__visible-to-student-subcontainer">

                          <DefaultInput
                            className="food-prescription__active-prescription__header__visible-to-student__sub-input"
                            name="indicatorsVisibleToStudentInput"
                            label="Indicadores:"
                            type="toggle"
                            isHorizontal={this.state.screenWidth > 600}
                            activeText="Sim"
                            inactiveText="Não"
                            handleInputChange={(event) => this.handleInputChange(event)}
                            value={this.state.indicatorsVisibleToStudentInput}
                          />

                          <DefaultInput
                            className="food-prescription__active-prescription__header__visible-to-student__sub-input"
                            name="graphVisibleToStudentInput"
                            label="Gráfico:"
                            type="toggle"
                            isHorizontal={this.state.screenWidth > 600}
                            activeText="Sim"
                            inactiveText="Não"
                            handleInputChange={(event) => this.handleInputChange(event)}
                            value={this.state.graphVisibleToStudentInput}
                          />

                        </div>
                      }

                    </div>

                  </div>

                  <div className="food-prescription__active-prescription__header__controls-wrapper">

                    {(this.state.studentStatus === STUDENT_ACTIVE_STATUS && this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) &&
                      <React.Fragment>

                        {this.mayUpdateActivePrescription() &&
                          <button
                            className="food-prescription__active-prescription__header__save-button"
                            onClick={() => this.updateActivePrescription()}
                          >

                            Salvar

                          </button>
                        }

                        <button
                          onClick={() => this.deactivatePrescription()}
                          className="food-prescription__active-prescription__header__deactivate-button"
                        >

                          {this.state.screenWidth > 510 ? 'Desativar' : <i className="fas fa-times"></i>}

                        </button>

                      </React.Fragment>
                    }

                  </div>

                </div>

              </header>

              {this.getPrescriptionDetails()}

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {(this.state.studentStatus === STUDENT_ACTIVE_STATUS &&
          this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION) &&
          (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) || this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION))) &&
          <section className="food-prescription__activate-prescription">

            <header
              className="food-prescription__activate-prescription__header"
              onClick={() => this.setState({activatePrescriptionSectionVisible: !this.state.activatePrescriptionSectionVisible})}
            >

              <h3 className="food-prescription__activate-prescription__header__text">
                <i className="fas fa-check-square food-prescription__activate-prescription__header__text-icon"></i>
                Ativar dieta
              </h3>

              {this.state.activatePrescriptionSectionVisible ?
                <i className="fas fa-chevron-down food-prescription__activate-prescription__header__visible-icon"></i>:
                <i className="fas fa-chevron-up food-prescription__activate-prescription__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container food-prescription__activate-prescription__content"
              pose={this.state.activatePrescriptionSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container food-prescription__activate-prescription__content-wrapper">

                <section className="food-prescription__tab-container">

                  <ul className="food-prescription__tab-container__tab-selector">

                    {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) &&
                      <li className="food-prescription__tab-container__tab-item">
                        <button
                          className="food-prescription__tab-container__tab-item__button"
                          onClick={() => this.setState({selectedTabIndex: 1})}
                          disabled={this.state.selectedTabIndex === 1}
                        >
                          Dietas
                        </button>
                      </li>
                    }

                    {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION) &&
                      <li className="food-prescription__tab-container__tab-item">
                        <button
                          className="food-prescription__tab-container__tab-item__button"
                          onClick={() => this.setState({selectedTabIndex: 2})}
                          disabled={this.state.selectedTabIndex === 2}
                        >
                          Receitas
                        </button>
                      </li>
                    }


                  </ul>

                  <article className="food-prescription__tab-container__tab-content">

                    {this.getTabContent()}

                  </article>

                </section>

              </div>

            </VerticalAccordionContainer>

          </section>
        }

        {this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION) &&
          <React.Fragment>

            <HorizontalRule />

            <section className="food-prescription__activate-prescription">

              <header
                className="food-prescription__activate-prescription__header"
                onClick={() => this.setState({foodPrescriptionHistorySectionVisible: !this.state.foodPrescriptionHistorySectionVisible})}
              >

                <h3 className="food-prescription__activate-prescription__header__text">
                  <i className="fas fa-history food-prescription__activate-prescription__header__text-icon"></i>
                  Histórico de dietas
                </h3>

                {this.state.foodPrescriptionHistorySectionVisible ?
                  <i className="fas fa-chevron-down food-prescription__activate-prescription__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up food-prescription__activate-prescription__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container food-prescription__activate-prescription__content"
                pose={this.state.foodPrescriptionHistorySectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container food-prescription__activate-prescription__content-wrapper">

                  {this.state.loadingData ?
                    <PoseGroup>
                      <FadeContainer className="content-frame__loading-container" key="preloader">
                        <PreLoader local={true} />
                      </FadeContainer>
                    </PoseGroup>:
                    <ModelTable
                      key="prescription_history_list"
                      storageKey="prescription_history_list"
                      properties={this.getFoodPrescriptionHistoryProperties()}
                      getActions={this.props.userPermissionIds.includes(permissions.DELETE_STUDENT_FOOD_PRESCRIPTION_HISTORY_PERMISSION) ? (entry) => this.getFoodPrescriptionHistoryActions(entry) : null}
                      data={this.state.food_prescription_history}
                      initialOrderBy="end_date"
                      initialOrderIsDecrescent={true}
                    >
                    </ModelTable>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default FoodPrescription;
