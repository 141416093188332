import React from 'react';
import { Link } from 'react-router-dom';
import './food_recipe_list.scss';
import ContentFrame from '../../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection from '../../../utils/default_section';
import {getModels, deleteModel, setUrlParameters} from '../../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';
import * as permissions from '../../../permissions';

class FoodRecipeList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let classificationId = queryParameters.get('classification_id');
    let isActiveInput = queryParameters.get('is_active');

    if(!classificationId) {
      classificationId = '';
    }
    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }

    this.state = {
      loadingData: true,
      classificationIdInput: classificationId,
      classificationIdFilter: classificationId,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      meal_classifications: [],
      food_recipes: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getMealClassifications() {
    if (this.props.userPermissionIds.includes(permissions.VIEW_MEAL_CLASSIFICATION_PERMISSION)) {
      return await getModels(routes.MEAL_CLASSIFICATIONS_GET_API);
    }

    return [];
  }

  async getFoodRecipes() {
    const parameters = {};

    if(this.state.classificationIdFilter.length > 0) {
      parameters.classification_id = this.state.classificationIdFilter;
    }
    if(this.state.isActiveFilter.length > 0) {
      parameters.is_active = this.state.isActiveFilter;
    }

    return await getModels(setUrlParameters(routes.FOOD_RECIPES_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.classificationIdFilter !== this.state.classificationIdFilter ||
        prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        food_recipes: await this.getFoodRecipes(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    let food_recipes = this.getFoodRecipes();
    let meal_classifications = this.getMealClassifications();

    food_recipes = await food_recipes;
    meal_classifications = await meal_classifications;

    this.setState({
      loadingData: false,
      meal_classifications: meal_classifications,
      food_recipes: food_recipes,
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.FOOD_RECIPE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'food_prescription_associations':
                    descriptions.push('Receita vinculada à dietas alimentares');

                    break;
                  case 'advanced_ingredient_associations':
                    descriptions.push('Receita vinculada à outras receitas');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar a receita`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_RECIPE_PERMISSION) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.FOOD_RECIPE_EDIT_PATH}${entry.id}`}
          >

          {(this.props.userPermissionIds.includes(permissions.EDIT_FOOD_RECIPE_PERMISSION)) ?
            <i className="fas fa-edit"></i>:
            <i className="fas fa-eye"></i>
          }

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_FOOD_RECIPE_PERMISSION) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getClassificationText(entry) {
    return entry.meal_classification !== null ? entry.meal_classification.name : '';
  }

  getEnergyText(entry) {
    if (entry.total_energy === null || !entry.total_energy_unit) {
      return '-';
    }

    let total_energy = Math.round(entry.total_energy * 10) / 10;

    return `${total_energy} ${entry.total_energy_unit.shortname}`;
  }

  getDistributionText(entry) {
    if (entry.total_protein_percentage === null || entry.total_fat_percentage === null || entry.total_carbohydrate_percentage === null) {
      return '-';
    }

    const total_carbohydrate_percentage = Math.round(100 * entry.total_carbohydrate_percentage);
    const total_protein_percentage = Math.round(100 * entry.total_protein_percentage);
    const total_fat_percentage = Math.round(100 * entry.total_fat_percentage);

    return (
      <div className="food-recipe-list__macronutrient-distribution">

        <div className="food-recipe-list__macronutrient-distribution__nutrient--carb">

          <h4 className="food-recipe-list__macronutrient-distribution__nutrient__label">C</h4>
          <div
            className="food-recipe-list__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_carbohydrate_percentage}em`}}
          >
          </div>
          <p className="food-recipe-list__macronutrient-distribution__nutrient__value">{total_carbohydrate_percentage}%</p>

        </div>

        <div className="food-recipe-list__macronutrient-distribution__nutrient--protein">

          <h4 className="food-recipe-list__macronutrient-distribution__nutrient__label">P</h4>
          <div
            className="food-recipe-list__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_protein_percentage}em`}}
          >
          </div>
          <p className="food-recipe-list__macronutrient-distribution__nutrient__value">{total_protein_percentage}%</p>

        </div>

        <div className="food-recipe-list__macronutrient-distribution__nutrient--fat">

          <h4 className="food-recipe-list__macronutrient-distribution__nutrient__label">G</h4>
          <div
            className="food-recipe-list__macronutrient-distribution__nutrient__bar"
            style={{width: `${4*entry.total_fat_percentage}em`}}
          >
          </div>
          <p className="food-recipe-list__macronutrient-distribution__nutrient__value">{total_fat_percentage}%</p>

        </div>

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`food-recipe-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Receita', <i className="fas fa-utensils"></i>)
    );
    properties.push(
      Property(
        'total_energy',
        'Energia (1 pessoa)',
        <i className="fas fa-bolt"></i>,
        {getDataText: this.getEnergyText, getFilterText: this.getEnergyText})
    );
    properties.push(
      Property(
        'people_served',
        'Rendimento',
        <i className="fa-solid fa-bowl-food"></i>)
    );
    properties.push(
      Property(
        'total_protein_percentage',
        'Macronutrientes',
        <i className="fas fa-chart-pie"></i>,
        {getDataText: this.getDistributionText, applyFilter: false, sortable: false})
    );
    properties.push(
      Property(
        'meal_classification_id',
        'Classificação',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getClassificationText, getFilterText: this.getClassificationText})
    );

    properties = [
      ...properties,
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getMealClassificationOptions() {
    return [
      SelectOption('', 'Todas'),
      ...this.state.meal_classifications.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  mayRefreshList() {
    if(this.state.classificationIdInput !== this.state.classificationIdFilter ||
       this.state.isActiveInput !== this.state.isActiveFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.FOOD_RECIPE_LIST_PATH, {
        classification_id: this.state.classificationIdInput,
        is_active: this.state.isActiveInput || 'all',
      }));

      this.setState({
        classificationIdFilter: this.state.classificationIdInput,
        isActiveFilter: this.state.isActiveInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando receita';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar receita';
    }

    return 'Deletar receita';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados à receita serão removidos'}
          confirmText="Deletar receita"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FOOD_RECIPE_LIST_PATH,
              text: "Listar receitas"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar receitas de refeições"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="food-recipe-list"
            title="Lista de receitas de refeições"
          >

            <div className="food-recipe-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={routes.FOOD_RECIPE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova receita

                </Link>
              }

            </div>

            {this.props.userPermissionIds.includes(permissions.VIEW_MEAL_CLASSIFICATION_PERMISSION) &&
              <div className="food-recipe-list__filters">

                <header className="food-recipe-list__filters__header">

                  <h4 className="food-recipe-list__filters__header__text">Filtros</h4>

                </header>

                <div className="food-recipe-list__filters__inputs">

                  <div className="food-recipe-list__filters__inputs-wrapper">

                    <HalfWrapper>

                      <DefaultInput
                        name="classificationIdInput"
                        label="Classificação"
                        type="select"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.classificationIdInput || ''}
                        options={this.getMealClassificationOptions()}
                      />

                      <DefaultInput
                        name="isActiveInput"
                        label="Situação do cadastro"
                        type="select"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.isActiveInput || ''}
                        options={this.getActiveOptions()}
                      />

                    </HalfWrapper>

                  </div>

                  <button
                    className="food-recipe-list__filters__refresh-button"
                    onClick={() => this.refreshList()}
                    disabled={!this.mayRefreshList()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>

                </div>

              </div>
            }

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.state.food_recipes}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FoodRecipeList;
