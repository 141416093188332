import React from 'react';
import { Link } from 'react-router-dom';
import './receptionist_list.scss';
import ContentFrame from '../content_frame';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel, getAsLocalDatetime, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';


class ReceptionistList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActiveInput = queryParameters.get('is_active');

    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }

    this.state = {
      loadingData: true,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      receptionists: [],
      deleteEntry: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getReceptionists() {
    const parameters = {};

    if(this.state.isActiveFilter.length > 0) {
      parameters.is_active = this.state.isActiveFilter;
    }

    return await getModels(setUrlParameters(routes.RECEPTIONISTS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const receptionists = await this.getReceptionists();

    this.setState({
      loadingData: false,
      receptionists
    });
  }

  getRoleText(entry) {
    return entry.role_names.map((roleName) => {
      return (
        <span
          className="receptionist-list__role-name"
          key={`receptionist_rolename_${roleName}_${entry.id}`}
        >
          {roleName}
        </span>
      );
    });
  }

  getCreatedAtText(entry) {
    return (getAsLocalDatetime(entry.created_at, false).toLocaleDateString())
  }

  onDeleteEntry(entry) {
    this.setState({
      deleteEntry: entry,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteEntry: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    const deletePath = this.state.deleteEntry.has_full_access ? routes.ADMIN_RECEPTIONIST_DELETE_API : routes.RECEPTIONIST_DELETE_API;

    try{
      if(await deleteModel(`${deletePath}${this.state.deleteEntry.id}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = null;

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 203) {
            if(error.message.includes('Requires higher access level')) {
              errorDescription = 'Você não possui permissão para remover esta recepcionista. Por favor, entre em contato com o responsável pela unidade FYD para realizar a operação.';
            }
          }
          else if(error.code === 209) {
            errorDescription = 'Sessão do usuário expirada.';
          }
        }
      }

      if(!errorDescription) {
        errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteEntry: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_RECEPTIONIST_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.RECEPTIONIST_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_RECEPTIONIST_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    let activeText;

    if(entry.is_active) {
      if(entry.email_is_validated === null) {
        activeText = 'Email não validado';
      }
      else if(entry.email_is_validated === false) {
        activeText = 'Email inválido';
      }
      else {
        activeText = 'Ativo';
      }
    }
    else {
      activeText = 'Inativo';
    }

    return activeText;
  }

  getIsActiveText(entry) {
    let activeText;
    let classModifier;

    if(entry.is_active) {
      if(entry.email_is_validated === null) {
        activeText = 'Email não validado';
        classModifier = 'pending';
      }
      else if(entry.email_is_validated === false) {
        activeText = 'Email inválido';
        classModifier = 'inativo';
      }
      else {
        activeText = 'Ativo';
        classModifier = 'ativo';
      }
    }
    else {
      activeText = 'Inativo';
      classModifier = 'inativo';
    }

    return (
      <p className={`receptionist-list__is-active-text__${classModifier}`}>{activeText}</p>
    );
  }

  getRoleExperienceLevelText(entry) {
    if (entry.role_experience_level === null) {
      return '';
    }

    return entry.role_experience_level.name;
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-id-card"></i>),
    ];

    if(this.state.screenWidth > 440) {
      properties.push(
        Property('email', 'E-mail', <i className="fas fa-envelope"></i>)
      );
    }

    if(this.state.screenWidth > 550) {
      properties.push(
        Property('role_names', 'Funções', <i className="fas fa-tag"></i>, {getDataText: this.getRoleText})
      );
    }

    properties.push(
      Property('role_experience_level', 'Experiência', <i className="fa-solid fa-ranking-star"></i>, {getDataText: this.getRoleExperienceLevelText, getFilterText: this.getRoleExperienceLevelText})
    );

    if(this.state.screenWidth > 760) {
      properties.push(
        Property('created_at', 'Cadastro', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getCreatedAtText, getFilterText: this.getCreatedAtText})
      );
    }

    properties = [
      ...properties,
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando recepcionista';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar recepcionista';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  mayResetFilterInputs() {
    if(this.state.isActiveInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      isActiveInput: '',
    });
  }

  mayRefreshList() {
    if(this.state.isActiveInput !== this.state.isActiveFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.RECEPTIONIST_LIST_PATH, {
        is_active: this.state.isActiveInput || 'all'
      }));

      this.setState({
        isActiveFilter: this.state.isActiveInput
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados serão removidos'}
          confirmText="Deletar recepcionista"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteEntry !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.RECEPTIONIST_LIST_PATH,
              text: "Listar recepcionistas"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar recepcionistas"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="receptionist-list"
            title="Lista de recepcionistas com acesso ao sistema"
          >

            <div className="coach-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_RECEPTIONIST_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.RECEPTIONIST_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar recepcionista

                </Link>
              }

            </div>

            <div className="coach-list__filters">

              <header className="coach-list__filters__header">

                <h4 className="coach-list__filters__header__text">Filtros</h4>

              </header>

              <div className="coach-list__filters__inputs">

                <div className="coach-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveInput || ''}
                    options={this.getActiveOptions()}
                  />

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="coach-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayRefreshList() &&
                  <button
                    className="coach-list__filters__refresh-button"
                    onClick={() => this.refreshList()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.receptionists}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ReceptionistList;
