import React from 'react';
import { Link } from 'react-router-dom';
import './food_ingredient_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../../utils/default_section';
import {VerticalAccordionContainer} from '../../../utils/pose_containers';
import DefaultInput, {SelectOption, HalfWrapper} from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import ModelTable, {Property} from '../../../utils/model_table';
import * as permissions from '../../../permissions';
import {DEFAULT_FOOD_INFO_SOURCE} from '../../../constants';

class FoodIngredientData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSourceInfoTab: 1,
      foodAllergensVisible: false,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description' && event.target.name !== 'source_association:description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.food_ingredient.id && this.props.food_ingredient.id > 0;
  }

  getInfoSourceSelectorsForNutrientData() {
    const sourceIdsSet = new Set(this.props.food_ingredient.source_associations.map((entry) => entry.food_info_source_id));

    const filteredInfoSources = this.props.info_sources.filter((info_source) => sourceIdsSet.has(info_source.id));

    return filteredInfoSources.map((info_source) => {
      const infoSourceSelected = info_source.id === this.props.activeNutrientInfoSourceId;

      let style = {};

      if (infoSourceSelected) {
        style.color = info_source.color;
        style.borderColor = info_source.color;
      }

      return (
        <button
          key={`food-ingredient:info-source-selector:${info_source.id}`}
          className={`food-ingredient-data__info-source-button`}
          disabled={infoSourceSelected}
          onClick={() => this.props.onSelectNutrientInfoSource(info_source)}
          style={style}
        >

          {info_source.shortname}

        </button>
      );
    });
  }

  getInfoSourceSelectorsForMeasurementData() {
    const filteredMeasurementAssociations = this.props.food_ingredient.food_measurement_associations.filter((entry) => entry.processing_method_id === this.props.selectedSourceAssociation.processing_method_id);

    const sourceIdsSet = new Set(filteredMeasurementAssociations.map((entry) => entry.food_info_source_id));

    const filteredInfoSources = this.props.info_sources.filter((info_source) => sourceIdsSet.has(info_source.id));

    return filteredInfoSources.map((info_source) => {
      const infoSourceSelected = info_source.id === this.props.activeMeasurementInfoSourceId;

      let style = {};

      if (infoSourceSelected) {
        style.color = info_source.color;
        style.borderColor = info_source.color;
      }

      return (
        <button
          key={`food-ingredient:info-source-selector:${info_source.id}`}
          className={`food-ingredient-data__info-source-button`}
          disabled={infoSourceSelected}
          onClick={() => this.props.onSelectMeasurementInfoSource(info_source)}
          style={style}
        >

          {info_source.shortname}

        </button>
      );
    });
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!filterCallback || filterCallback(node)) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
    }

    node.children.sort((a, b) => a.name.localeCompare(b.name));

    for(let child of node.children) {
      if(!filterCallback || filterCallback(child)) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getFoodClassificationOptions() {
    let food_classifications = this.props.food_classifications.filter((entry) => entry.depth === 0);

    food_classifications.sort((a, b) => a.name.localeCompare(b.name));

    food_classifications = food_classifications.flatMap((node) => this.getNodeOptions(node));

    if(this.props.food_ingredient.food_classification_id) {
      return [
        ...food_classifications
      ];
    }

    return [
      SelectOption('', 'Selecione um grupo'),
      ...food_classifications
    ];
  }

  getFoodInfoSourceOptionsForNutrientData() {
    let sourceIdsSet;

    if (this.isEditMode()) {
      sourceIdsSet = new Set(this.props.food_ingredient.source_associations.map((entry) => entry.food_info_source_id));
    }
    else {
      sourceIdsSet = new Set();
    }

    sourceIdsSet.add(DEFAULT_FOOD_INFO_SOURCE.id);

    const filteredInfoSources = this.props.info_sources.filter((info_source) => sourceIdsSet.has(info_source.id));

    return [
      SelectOption('', 'Não definido'),
      ...filteredInfoSources.map((info_source) => SelectOption(info_source.id, info_source.shortname))
    ];
  }

  getFoodInfoSourceOptionsForMeasurementData() {
    let sourceIdsSet;

    if (this.isEditMode()) {
      sourceIdsSet = new Set(this.props.food_ingredient.food_measurement_associations.map((entry) => entry.food_info_source_id));
    }
    else {
      sourceIdsSet = new Set();
    }

    sourceIdsSet.add(DEFAULT_FOOD_INFO_SOURCE.id);

    const filteredInfoSources = this.props.info_sources.filter((info_source) => sourceIdsSet.has(info_source.id));

    return [
      SelectOption('', 'Não definido'),
      ...filteredInfoSources.map((info_source) => SelectOption(info_source.id, info_source.shortname))
    ];
  }

  getProcessingMethodOptions() {
    const filteredAssociations = this.props.food_ingredient.source_associations.filter((association) => association.food_info_source_id === this.props.activeNutrientInfoSourceId);

    if (this.props.selectedSourceAssociation !== null) {
      return [
        ...filteredAssociations.map((source_association) => SelectOption(source_association.processing_method_id || '0', source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
    else {
      return [
        SelectOption('', 'Selecione um método de processamento'),
        ...filteredAssociations.map((source_association) => SelectOption(source_association.processing_method_id || '0', source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
  }

  getMeasurementOptions() {
    const filteredMeasurementAssociations = this.props.food_ingredient.food_measurement_associations.filter((association) => association.food_info_source_id === this.props.activeMeasurementInfoSourceId && association.processing_method_id === this.props.selectedSourceAssociation.processing_method_id);

    if (this.props.selectedSourceAssociation.default_measurement_id !== null) {
      const options = [];

      if (!filteredMeasurementAssociations.some((entry) => entry.food_measurement_id === this.props.selectedSourceAssociation.default_measurement_id)) {
        const missingAssociation = this.props.food_ingredient.food_measurement_associations.find((association) => association.food_measurement_id === this.props.selectedSourceAssociation.default_measurement_id);

        options.push(SelectOption(missingAssociation.food_measurement_id, missingAssociation.food_measurement.name));
      }

      return [
        ...options,
        ...filteredMeasurementAssociations.map((association) => SelectOption(association.food_measurement_id, association.food_measurement.name))
      ];
    }
    else {
      return [
        SelectOption('', 'Indefinido'),
        ...filteredMeasurementAssociations.map((association) => SelectOption(association.food_measurement_id, association.food_measurement.name))
      ];
    }
  }

  getSourceInfoContent() {
    const nutrientFieldsAreEditable = this.props.activeNutrientInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs();

    switch (this.state.selectedSourceInfoTab) {
      case 1:
        const nutrientMap = new Map();

        for (const association of this.props.selectedSourceAssociation.nutrient_associations) {
          let associationList;

          if (!nutrientMap.has(association.nutrient.nutrient_classification_id)) {
            associationList = [];

            nutrientMap.set(association.nutrient.nutrient_classification_id, associationList);
          }
          else {
            associationList = nutrientMap.get(association.nutrient.nutrient_classification_id);
          }

          associationList.push(association);
        }

        const content = [];

        const associationKey = `${this.props.selectedSourceAssociation.food_ingredient_id}:${this.props.selectedSourceAssociation.processing_method_id}:${this.props.selectedSourceAssociation.food_info_source_id}`;

        if (this.props.activeNutrientInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id) {
          content.push(
            <React.Fragment
              key={`source_association:${associationKey}:nutrient_info_header`}
            >

              <DefaultInput
                name="source_association:description"
                isHighlighted={this.isHighlighted("source_association:description")}
                label="Descrição da fonte de informações"
                type="textarea"
                placeholder="Descrição da fonte de informações"
                rows="3"
                handleInputChange={this.props.handleInputChange}
                value={this.props.selectedSourceAssociation.description || ''}
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={!nutrientFieldsAreEditable}
              />

              <HorizontalRule />

            </React.Fragment>
          );
        }

        content.push(
          <h3
            key={`source_association:${associationKey}:weight_reference`}
            className="food-ingredient-data__source-association__instructions"
          >
            {`Valores calculados para cada ${this.props.selectedSourceAssociation.weight_reference}${this.props.selectedSourceAssociation.weight_reference_unit.shortname} do alimento`}
          </h3>
        );

        content.push(
          <DefaultInput
            key={`source_association:${associationKey}:energy`}
            labelClassName="food-ingredient-data__source-association__nutrient-label"
            name="source_association:energy"
            isHighlighted={this.isHighlighted('source_association:energy')}
            label="Energia:"
            type="number"
            placeholder="-"
            step="0.01"
            min="0.00"
            handleInputChange={this.props.handleInputChange}
            value={this.props.selectedSourceAssociation.energy !== null ? this.props.selectedSourceAssociation.energy : ''}
            autoComplete="off"
            suffix={this.props.selectedSourceAssociation.energy_unit.shortname}
            isHorizontal={this.state.screenWidth > 540}
            disabled={!nutrientFieldsAreEditable}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />
        );

        for(const classification of this.props.nutrient_classifications) {
          if (nutrientMap.has(classification.id)) {
            content.push(
              <DefaultSubSectionTitle
                key={`source_association:${associationKey}:nutriet_classification:${classification.id}:title`}
                className="food-ingredient-data__source-association__nutrient-classification"
                icon={<i className="fas fa-circle"></i>}
                text={classification.name}
              />
            );

            const associations = nutrientMap.get(classification.id);
            // associations.sort((a, b) => a.id - b.id);
            associations.sort((a, b) => a.nutrient.fullname.localeCompare(b.nutrient.fullname));

            for (const association of associations) {
              const nutrientKey = `nutrient:${association.nutrient.id}`;

              content.push(
                <div
                  key={`source_association:${associationKey}:${nutrientKey}`}
                  className="food-ingredient-data__field-wrapper"
                >

                  <DefaultInput
                    className="food-ingredient-data__source-association__field-input"
                    labelClassName="food-ingredient-data__source-association__nutrient-label"
                    name={`source_association:${nutrientKey}`}
                    isHighlighted={this.isHighlighted(`source_association:${nutrientKey}`)}
                    label={`${association.nutrient.fullname}:`}
                    type="number"
                    placeholder="-"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={association.weight_value !== null ? association.weight_value : ''}
                    autoComplete="off"
                    suffix={association.weight_unit.shortname}
                    isHorizontal={this.state.screenWidth > 540}
                    disabled={!nutrientFieldsAreEditable}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />

                  {nutrientFieldsAreEditable &&
                    <button
                      className="food-ingredient-data__default-delete-button"
                      style={this.state.screenWidth > 540 ? {margin: '0 0 0 1em'} : {margin: '0 0 0 0.5em'}}
                      onClick={() => this.props.onRemoveNutrientAssociation(association)}
                    >

                      <i className="fas fa-trash-alt"></i>

                    </button>
                  }

                </div>
              );
            }
          }
        }

        if (this.props.activeNutrientInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs()) {
          content.push(
            <React.Fragment
              key={`source_association:${associationKey}:nutrient_info_footer`}
            >

              <HorizontalRule />

              <button
                className="food-ingredient-data__default-add-button--wide"
                onClick={() => this.props.onAddNutrientAssociation()}
              >

                <i className="fas fa-plus food-ingredient-data__default-add-button__icon--locked"></i>
                Adicionar informação nutricional

              </button>

            </React.Fragment>
          );
        }

        return content;
      case 2:
        let filteredMeasurementAssociations = this.props.food_ingredient.food_measurement_associations.filter((association) => association.food_info_source_id === this.props.activeMeasurementInfoSourceId && association.processing_method_id === this.props.selectedSourceAssociation.processing_method_id);

        filteredMeasurementAssociations = filteredMeasurementAssociations.map((association) => {
          return {
            id: association.id,
            name: association.food_measurement.name,
            weight: `${association.weight_reference}${association.weight_reference_unit.shortname}`,
            volume: association.food_measurement.volume_reference !== null ? `${association.food_measurement.volume_reference}${association.food_measurement.volume_reference_unit.shortname}` : '-',
            description: association.description,
            food_measurement_id: association.food_measurement_id,
          };
        });

        let measurementProperties = [
          Property('name', 'Medida', <i className="fas fa-tag"></i>, {
            getCellClassName: (data) => {
              return this.props.selectedSourceAssociation.default_measurement_id === data.food_measurement_id ? 'food-ingredient-data__source-association__default-measurement-cell' : ''
            }
          }),
          Property('weight', 'Peso', <i className="fas fa-weight-hanging"></i>),
          Property('volume', 'Volume', <i className="fas fa-tint"></i>),
          Property('description', 'Descrição', <i className="fas fa-info-circle"></i>),
        ];

        let getActions = null;

        if (this.props.activeMeasurementInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs()) {
          getActions = (entry) => (
            <div className="model-table__model-actions-container">

              <button
                className="model-table__default-edit-button"
                onClick={() => this.props.onEditMeasurementSource(entry)}
              >

                <i className="fas fa-edit"></i>

              </button>

              <button
                className="model-table__default-delete-button"
                onClick={() => this.props.onRemoveMeasurementSource(entry)}
              >

                <i className="far fa-trash-alt"></i>

              </button>

            </div>
          )
        }

        return (
          <React.Fragment>

            <DefaultInput
              name="defaultMeasurementId"
              label="Medida padrão:"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.selectedSourceAssociation.default_measurement_id || ''}
              options={this.getMeasurementOptions()}
              isHorizontal={this.state.screenWidth > 520}
              disabled={!this.mayChangeInputs()}
            />

            <HorizontalRule />

            <p className="food-ingredient-data__default-label">Fonte das medidas referidas:</p>
            <div className="food-ingredient-data__info-source-selector">

              {this.getInfoSourceSelectorsForMeasurementData()}

              {(this.props.userPermissionIds.includes(permissions.EDIT_FOOD_INGREDIENT_PERMISSION) && !this.props.food_ingredient.food_measurement_associations.some((entry) => entry.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id && entry.processing_method_id === this.props.selectedSourceAssociation.processing_method_id)) &&
                <button
                  className="food-ingredient-data__default-add-button"
                  onClick={() => this.props.onAddMeasurementSource()}
                >

                  <i className="fas fa-plus food-ingredient-data__default-add-button__icon"></i>
                  {(this.state.screenWidth > 680) && 'Adicionar fonte'}

                </button>
              }

            </div>

            <HorizontalRule />

            {(this.props.activeMeasurementInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs()) &&
              <React.Fragment>

                <div className="food-ingredient-data__action-wrapper">

                  <button
                    className="food-ingredient-data__default-add-button"
                    onClick={() => this.props.onAddMeasurementSource()}
                  >

                    <i className="fas fa-plus food-ingredient-data__default-add-button__icon--locked"></i>
                    Adicionar medida de referência

                  </button>

                </div>

                <HorizontalRule />

              </React.Fragment>
            }

            <ModelTable
              key={`source_association:${this.props.selectedSourceAssociation.id}:measurement_source:${this.props.activeMeasurementInfoSourceId}`}
              properties={measurementProperties}
              getActions={getActions}
              getIdCallback={(entry) => entry.food_measurement_id}
              data={filteredMeasurementAssociations}
              initialOrderBy="name"
            >

            </ModelTable>

          </React.Fragment>
        );
      default:
        return null;
    }
  }

  getFoodAllergens() {
    return this.props.food_allergens.map((allergen) => {
      return (
        <DefaultInput
          key={`food_allergen:${allergen.id}`}
          className="food-ingredient-data__food-allergens__input"
          name={`food_allergen:${allergen.id}`}
          label={allergen.name}
          type="toggle"
          activeText="Sim"
          inactiveText="Não"
          neutralInactiveColor={true}
          handleInputChange={(event) => this.props.onSetFoodAllergen(allergen, event.target.checked)}
          value={this.props.food_ingredient.food_allergens.some((entry) => entry.id === allergen.id)}
          disabled={!this.mayChangeInputs()}
        />
      );
    });
  }

  mayChangeInputs() {
    if (this.isEditMode() && !this.props.userPermissionIds.includes(permissions.EDIT_FOOD_INGREDIENT_PERMISSION)) {
      return false
    }

    return true;
  }

  render() {
    return (
      <DefaultSection
        className="food-ingredient-data"
        title="Dados do ingrediente"
      >

        <div className="food-ingredient-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="food-ingredient-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do ingrediente"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_ingredient.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.mayChangeInputs()}
            />

            <DefaultInput
              name="food_classification_id"
              isHighlighted={this.isHighlighted("food_classification_id")}
              label="Grupo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_ingredient.food_classification_id || ''}
              options={this.getFoodClassificationOptions()}
              disabled={!this.mayChangeInputs()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="default_nutrient_source_id"
              isHighlighted={this.isHighlighted("default_nutrient_source_id")}
              label="Fonte de informações nutricionais padrão"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_ingredient.default_nutrient_source_id || ''}
              options={this.getFoodInfoSourceOptionsForNutrientData()}
              disabled={!this.mayChangeInputs()}
            />

            <DefaultInput
              name="default_measurement_source_id"
              isHighlighted={this.isHighlighted("default_measurement_source_id")}
              label="Fonte de medidas padrão"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_ingredient.default_measurement_source_id || ''}
              options={this.getFoodInfoSourceOptionsForMeasurementData()}
              disabled={!this.mayChangeInputs()}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do ingrediente"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_ingredient.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

        </div>

        <HorizontalRule />

        <section className="food-ingredient-data__food-allergens">

          <header
            className="food-ingredient-data__food-allergens__header"
            onClick={() => this.setState({foodAllergensVisible: !this.state.foodAllergensVisible})}
          >

            <h3 className="food-ingredient-data__food-allergens__header__text">
              <i className="fas fa-notes-medical food-ingredient-data__food-allergens__header__text-icon"></i>
              Alimentos alérgenos
            </h3>

            {this.state.foodAllergensVisible ?
              <i className="fas fa-chevron-down food-ingredient-data__food-allergens__header__visible-icon"></i>:
              <i className="fas fa-chevron-up food-ingredient-data__food-allergens__header__visible-icon"></i>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container food-ingredient-data__food-allergens__content"
            pose={this.state.foodAllergensVisible ? 'verticalOpen' : 'verticalClosed'}>

            <div className="vertical-accordion-container food-ingredient-data__food-allergens__content-wrapper">

              <h3 className="food-ingredient-data__food-allergens__instructions">
                Selecione os alérgenos abaixo contidos neste ingrediente:
              </h3>

              <div className="food-ingredient-data__food-allergens__list">

                {this.getFoodAllergens()}

              </div>

            </div>

          </VerticalAccordionContainer>

        </section>

        {this.isEditMode() &&
          <React.Fragment>

            <HorizontalRule />

            <DefaultSubSectionTitle
              icon={<i className="fas fa-info"></i>}
              text="Informações nutricionais e medidas referidas"
            />

            <p className="food-ingredient-data__default-label">Fonte das informações nutricionais:</p>
            <div className="food-ingredient-data__info-source-selector">

              {this.getInfoSourceSelectorsForNutrientData()}

              {(this.props.userPermissionIds.includes(permissions.EDIT_FOOD_INGREDIENT_PERMISSION) && !this.props.food_ingredient.source_associations.some((entry) => entry.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id)) &&
                <button
                  className="food-ingredient-data__default-add-button"
                  onClick={() => this.props.onAddNutrientSource()}
                >

                  <i className="fas fa-plus food-ingredient-data__default-add-button__icon"></i>
                  {(this.state.screenWidth > 680) && 'Adicionar fonte'}

                </button>
              }

            </div>

            {this.props.activeNutrientInfoSourceId !== null &&
              <React.Fragment>

                <HorizontalRule />

                {(this.props.activeNutrientInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs()) &&
                  <div className="food-ingredient-data__action-wrapper">

                    <button
                      className="food-ingredient-data__default-add-button"
                      onClick={() => this.props.onAddNutrientSource()}
                    >

                      <i className="fas fa-plus food-ingredient-data__default-add-button__icon--locked"></i>
                      Adicionar método de processamento

                    </button>

                  </div>
                }

                <div className="food-ingredient-data__processing-method-wrapper">

                  <DefaultInput
                    className="food-ingredient-data__processing-method-input"
                    name="selectedSourceAssociation"
                    label="Método de processamento:"
                    type="select"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.selectedSourceAssociation ? this.props.selectedSourceAssociation.processing_method_id || '0' : ''}
                    options={this.getProcessingMethodOptions()}
                    isHorizontal={this.state.screenWidth > 520}
                  />

                  {(this.props.selectedSourceAssociation !== null && this.props.activeNutrientInfoSourceId === DEFAULT_FOOD_INFO_SOURCE.id && this.mayChangeInputs()) &&
                    <button
                      className="food-ingredient-data__default-delete-button--chained"
                      onClick={() => this.props.onRemoveNutrientSource()}
                    >

                      <i className="fas fa-trash-alt food-ingredient-data__default-delete-button__icon"></i>
                      {(this.state.screenWidth > 680) && 'Remover'}

                    </button>
                  }

                </div>

                {this.props.selectedSourceAssociation !== null &&
                  <section className="food-ingredient-data__source-association">

                    <ul className="food-ingredient-data__source-association__tab-selector">

                      <li className="food-ingredient-data__source-association__tab-item">
                        <button
                          className="food-ingredient-data__source-association__tab-item__button"
                          onClick={() => this.setState({selectedSourceInfoTab: 1})}
                          disabled={this.state.selectedSourceInfoTab === 1}
                        >
                          Informações nutricionais
                        </button>
                      </li>

                      <li className="food-ingredient-data__source-association__tab-item">
                        <button
                          className="food-ingredient-data__source-association__tab-item__button"
                          onClick={() => this.setState({selectedSourceInfoTab: 2})}
                          disabled={this.state.selectedSourceInfoTab === 2}
                        >
                          Medidas referidas
                        </button>
                      </li>

                    </ul>

                    <article className="food-ingredient-data__source-association__tab-content">

                      {this.getSourceInfoContent()}

                    </article>

                  </section>
                }

              </React.Fragment>
            }

          </React.Fragment>
        }

        <HorizontalRule />

        <div className="food-ingredient-data__buttons-container">

          {this.mayChangeInputs() &&
            <button
              className="food-ingredient-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <Link
            className="food-ingredient-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {!this.mayChangeInputs() ? 'Voltar': 'Cancelar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default FoodIngredientData;
