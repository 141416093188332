import React from 'react';
import { Link } from 'react-router-dom';
import './exercise_category_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {SERVICE_HOME_TRAINING} from '../../constants';
import WarningMessage from '../warning_message';

class ExerciseCategoryData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.exercise_category.id && this.props.exercise_category.id > 0;
  }

  getServiceOptions() {
    const services = [
      ...this.props.services,
      SERVICE_HOME_TRAINING
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Não especificado'),
      ...services.map((service) => SelectOption(service, service)),
    ];
  }

  render() {
    return (
      <DefaultSection
        className="exercise-category-data"
        title="Dados do pilar de exercício"
      >

        <div className="exercise-category-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="exercise-category-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do pilar"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_category.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="target_service"
              isHighlighted={this.isHighlighted("target_service")}
              label="Treinamento alvo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_category.target_service || ''}
              options={this.getServiceOptions()}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do pilar"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exercise_category.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="exercise-category-data__buttons-container">

          <button
            className="exercise-category-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="exercise-category-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ExerciseCategoryData;
