import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import { Link } from 'react-router-dom';
import './password_recovery.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from './warning_message';
import * as routes from '../constants';

class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      password: "",
      passwordConfirmation: "",
      changePassword: false,
      passwordUpdated: false,
      account_validated: false,
      units_related: [],
      authenticating: false,
      messageIsGood: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  async componentDidMount() {
    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      try {
        let response = await fetch(`${routes.USER_VALIDATE_EMAIL_POST_API}${this.props.match.params.authHash}`,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        let data = await response.json();

        this.setState({
          loadingData: false,
          account_validated: true,
          units_related: data
        });

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      loadingData: false,
      messageIsGood: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  inputsAreValid() {
    return this.state.password.length > 0 &&
           this.state.passwordConfirmation.length > 0;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.updatePasssword();
    }
  }

  async handleRequestError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          loadingData: false,
          authenticating: false,
          messageIsGood: false,
          showWarningMessage: true,
          warningMessage: "Link de autenticação inválido.",
          password: "",
          passwordConfirmation: "",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        this.setState({
          loadingData: false,
          authenticating: false,
          messageIsGood: false,
          showWarningMessage: true,
          warningMessage: "Link de autenticação inválido.",
          password: "",
          passwordConfirmation: "",
        });

        return null;
      }
    }
    else if(response.status === 422) {
      const data = await response.json();

      if(data.code === 208) {
        if(data.message.includes('Email not validated')) {
          this.setState({
            loadingData: false,
            authenticating: false,
            messageIsGood: false,
            showWarningMessage: true,
            warningMessage: "Email não validado. Em caso de qualquer dúvida, por favor, entre em contato com o responsável pela sua unidade.",
            password: "",
            passwordConfirmation: "",
          });
        }
        else {
          this.setState({
            loadingData: false,
            authenticating: false,
            messageIsGood: false,
            showWarningMessage: true,
            warningMessage: "Foram detectados problemas com seu email. Por favor, entre em contato com o resposável pela unidade.",
            password: "",
            passwordConfirmation: "",
          });
        }

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async updatePasssword() {
    const password = this.state.password.trim();
    const passwordConfirmation = this.state.passwordConfirmation.trim();

    if(password !== passwordConfirmation) {
      this.setState({
        messageIsGood: false,
        showWarningMessage: true,
        warningMessage: "Confirmação de senha inválida. Por favor, digite novamente sua nova senha.",
        password: "",
        passwordConfirmation: "",
      });

      return null;
    }

    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const body = JSON.stringify({
        password
      });

      try {
        let response = await fetch(`${routes.USER_UPDATE_PASSWORD_POST_API}${this.props.match.params.authHash}`,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        let data = await response.json();

        this.setState({
          passwordUpdated: true,
          messageIsGood: true,
          showWarningMessage: true,
          warningMessage: "Senha atualizada com sucesso.",
        });

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      messageIsGood: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  getRelatedUnits() {
    if(this.state.units_related.length <= 0) {
      return null;
    }

    const output = [];

    for(const unitname of this.state.units_related) {
      if(output.length > 0) {
        if(output.length === this.state.units_related.length-1) {
          output.push(<span> e </span>);
        }
        else {
          output.push(<span>, </span>);
        }
      }

      output.push(
        <strong key={`validate-account:unit_name:${output.length}`}>{unitname}</strong>
      );
    }

    return output;
  }

  getContent() {
    if(this.state.account_validated && this.state.units_related.length > 0) {
      let text = '';
      if(this.state.units_related.length === 1) {

        text = (
          <span>
            Para definir uma nova senha de acesso à unidade
            {' '}{this.getRelatedUnits()}
            , clique no botão abaixo:
          </span>
        );
      }
      else {
        text = (
          <span>
            Para definir uma nova senha de acesso às unidades
            {' '}{this.getRelatedUnits()}
            , clique no botão abaixo:
          </span>
        );
      }

      if(!this.state.changePassword) {
        return (
          <React.Fragment>

            <div className="validate-account__title">

              <p className="validate-account__title__text">
                {text}
              </p>

            </div>

            <div className="validate-account__request-wrapper">

              <button
                className="validate-account__request-button"
                onClick={() => this.setState({
                  changePassword: true,
                  showWarningMessage: false
                })}
              >

                Alterar senha

              </button>

            </div>

          </React.Fragment>
        );
      }
      else if(!this.state.passwordUpdated) {
        return (
          <React.Fragment>

            <div className="validate-account__title">

              <p className="validate-account__title__text">
                Digite sua nova senha nos campos abaixo:
              </p>

            </div>

            <div className="validate-account__input-wrapper">

              <input
                className="login__input"
                name="password"
                type="password"
                placeholder="Nova senha"
                onChange={(event) => this.handleInputChange(event)}
                value={this.state.password}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

              <input
                className="login__input"
                name="passwordConfirmation"
                type="password"
                placeholder="Confirmar nova senha"
                onChange={(event) => this.handleInputChange(event)}
                value={this.state.passwordConfirmation}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

            </div>

            <div className="validate-account__request-wrapper">

              <button
                className="validate-account__request-button"
                disabled={!this.inputsAreValid() || this.state.authenticating}
                onClick={() => this.updatePasssword()}
              >

                <i className="fas fa-key"></i>
                {this.state.authenticating ? ' Redefinindo...' : ' Redefinir senha'}

              </button>

            </div>

          </React.Fragment>
        );
      }
    }
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="validate-account__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <section className="validate-account">

        <div className="validate-account__container">

          <Link
            className="validate-account__main-link"
            to="/"
            >

              <header className="validate-account__header">

                <h1 className="validate-account__header__text">FYD ADMINISTRATIVO</h1>

              </header>

          </Link>

          <div className="validate-account__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
              background={this.state.messageIsGood ? '#e1fbd8' : null}
              color={this.state.messageIsGood ? '#379000' : null}
            />

          </div>

          {this.getContent()}

          <footer className="validate-account__footer">

            <p className="validate-account__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default PasswordRecovery;
