import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import {
  DEFAULT_UNIT_TYPE,
  SERVICE_FUNCTIONAL_SERVICE,
  SERVICE_PILATES_SERVICE,
  SERVICE_BIKE_SERVICE,
  SERVICE_GYM_SERVICE,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  COST_CENTER_MARKETING_ID} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getLocalDateIsoString, setUrlParameters, getCurrencyText, getAsLocalDate} from '../../utils/functions';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import StackedBarGraph, {StackGroup, StackPoint} from '../../graphs/stacked_bar_graph';
import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import FunnelChart, {FunnelLayer} from '../../graphs/funnel_chart';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import ModelTable, {Property} from '../../utils/model_table';
import * as permissions from '../../permissions';
import './sales_report.scss';

const LIGHT_SERVICE_PALLET = [
  '#78bb67',
  '#5ec2d2',
  '#d2915a',
  '#edf570',
  '#967de2',
  '#ea6767',
  '#cd59b1',
  '#9c9c9c',
];

const DARK_SERVICE_PALLET = [
  '#74985e',
  '#53868e',
  '#826248',
  '#949853',
  '#646796',
  '#9a5c5c',
  '#8e6484',
  '#797979',
];

const SERVICE_COLOR_MAP = {
  [SERVICE_FUNCTIONAL_SERVICE]: '#78bb67',
  [SERVICE_PILATES_SERVICE]: '#5ec2d2',
  [SERVICE_BIKE_SERVICE]: '#edf570',
  [SERVICE_GYM_SERVICE]: '#967de2',
  [SERVICE_CARDIO]: '#ea6767',
  [SERVICE_BOOTCAMP]: '#9469cd',
  [SERVICE_CORE]: '#cd59b1'
};

class SalesReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setDate(1);
      initialDate.setMonth(initialDate.getMonth() - 1);
      initialDate = getLocalDateIsoString(initialDate);
    }
    if(!finalDate) {
      finalDate = new Date();
      finalDate.setDate(0);
      finalDate = getLocalDateIsoString(finalDate);
    }

    this.state = {
      initialDateInput: initialDate,
      finalDateInput: finalDate,
      initialDate: initialDate,
      finalDate: finalDate,
      financialEntries: [],
      contracts: [],
      additionalContracs: [],
      experimentalClasses: [],
      feedbacks: [],
      websiteUserCount: 0,
      marketingTotalExpense: 0,
      experimentalClassSectionVisible: false,
      servicePlanSectionVisible: false,
      coachSectionVisible: false,
      potentialClientsSectionVisible: false,
      salesData: null,
      experimentalContractsOnly: false,
      loadingData: true,
      conversionGroupSize: 5,
      screenWidth: window.innerWidth,
    };
  }

  async getExperimentalClasses() {
    let classes = await getModels(`${routes.EXPERIMENTAL_CLASSES_GET_API}?initial_date=${this.state.initialDate}&final_date=${this.state.finalDate}&load_coaches=true`);

    return classes;
  }

  async getAnalyticsWebsiteUserCount() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_BASIC_WEBSITE_ANALYTICS_DATA_PERMISSION_ID)) {
      return 0;
    }

    return await getModels(`${routes.ANALYTICS_WEBSITE_USER_COUNT_GET_API}?initial_date=${this.state.initialDate}&final_date=${this.state.finalDate}`);
  }

  async getFeedbacks() {
    return await getModels(`${routes.FEEDBACKS_GET_API}?initial_reference_date=${this.state.initialDate}&final_reference_date=${this.state.finalDate}`);
  }

  async getContracs() {
    return await getModels(`${routes.CONTRACTS_GET_API_V2}?initial_created_date_filter=${this.state.initialDate}&final_created_date_filter=${this.state.finalDate}&calculate_target_service=true&load_user=true`);
  }

  async getAdditionalContracs() {
    const dateReference = getAsLocalDate(this.state.finalDate);
    dateReference.setDate(dateReference.getDate() + 1);

    return await getModels(`${routes.CONTRACTS_GET_API_V2}?initial_created_date_filter=${getLocalDateIsoString(dateReference)}&load_user=true`);
  }

  async getFinancialEntries() {
    return await getModels(`${routes.FINANCIAL_MOVEMENTS_GET_API}?initial_date=${this.state.initialDate}&final_date=${this.state.finalDate}&is_expense=false&load_user=true&load_creator=true`);
  }

  async getMarketingFinancialEntries() {
    return await getModels(`${routes.FINANCIAL_MOVEMENTS_GET_API}?initial_month_reference=${this.state.initialDate.slice(0, 7)}&final_month_reference=${this.state.finalDate.slice(0, 7)}&is_expense=true&cost_center_id_filter=${COST_CENTER_MARKETING_ID}`);
  }

  async refreshData(setLoading=true) {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    let websiteUserCount = this.getAnalyticsWebsiteUserCount();
    let financialEntries = this.getFinancialEntries();
    let contracts = this.getContracs();
    let additionalContracs = this.getAdditionalContracs();
    let experimentalClasses = this.getExperimentalClasses();
    let feedbacks = this.getFeedbacks();
    let marketingFinancialEntries = this.getMarketingFinancialEntries();

    const update = {salesData: null}

    if(setLoading) {
      update.loadingData = false;
    }

    websiteUserCount = await websiteUserCount;

    if(websiteUserCount) {
      update.websiteUserCount = websiteUserCount;
    }
    else {
      update.websiteUserCount = 0;
    }

    financialEntries = await financialEntries;

    if(financialEntries) {
      update.financialEntries = financialEntries;
    }

    contracts = await contracts;

    if(contracts) {
      update.contracts = contracts;
    }

    additionalContracs = await additionalContracs;

    if(additionalContracs) {
      update.additionalContracs = additionalContracs;
    }

    experimentalClasses = await experimentalClasses;

    if(experimentalClasses) {
      update.experimentalClasses = experimentalClasses;
    }

    feedbacks = await feedbacks;

    if(feedbacks) {
      update.feedbacks = feedbacks;
    }

    marketingFinancialEntries = await marketingFinancialEntries;

    if(marketingFinancialEntries) {
      update.marketingTotalExpense = marketingFinancialEntries.reduce((sum, entry) => sum + entry.total_value, 0);
    }

    this.setState(update);
  }

  async componentDidMount() {
    await this.refreshData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.refreshData();
    }
    else if(!this.state.loadingData && (this.state.salesData === null || (prevState.experimentalContractsOnly !== this.state.experimentalContractsOnly || prevState.conversionGroupSize !== this.state.conversionGroupSize))) {
      this.loadSalesData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.SALES_REPORT_PATH, {
        initial_date: this.state.initialDateInput,
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  getDateText(entry) {
    const todayIsoDate = (new Date()).toISOString().slice(0, 10);
    const today = getAsLocalDate(todayIsoDate);
    const date = getAsLocalDate(entry.date, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    const dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    const timeDiff = Math.abs(today.getTime() - date.getTime());
    const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return `${dateText} (${daysPassed} dia${daysPassed > 1 ? 's' : ''})` ;
  }

  getCreatedAtText(entry) {
    const date = getAsLocalDate(entry.created_at, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    const dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return dateText;
  }

  getUserText(entry) {
    if(entry.user_id === null) {
      return '';
    }

    return entry.user.name;
  }

  getCreatorText(entry) {
    if(entry.creator_id === null) {
      return '';
    }

    return entry.creator.name;
  }

  getFinancialValueText(entry) {
    const totalValueText = getCurrencyText(entry.total_value);
    const paymentPercentage = 100 * (entry.total_value_completed / entry.total_value);


    return `${totalValueText} (${paymentPercentage.toFixed(1)}%)`;
  }

  getFinancialDescriptionText(entry) {
    if(entry.description === null) {
      return '';
    }

    if(entry.contract_id === null) {
      return entry.description;
    }
    else if(!this.state.salesData.contractMap.has(entry.contract_id)) {
      return 'Erro ao carregar descrição. (contrato não vinculado)';
    }
    else {
      const contract = this.state.salesData.contractMap.get(entry.contract_id);
      const lines = [];

      for(const line of entry.description.split('\n')) {
        const lowerCasedLine = line.toLocaleLowerCase();

        if(lowerCasedLine.startsWith('pagamento de contrato')) {
          lines.push(`Contrato: ${contract.service_plan.name} (desc: ${contract.discount_percentage_applied !== null ? (100 * contract.discount_percentage_applied).toFixed(0) : 0}%)`);
        }
        else if(!lowerCasedLine.startsWith('aluno:')) {
          lines.push(line);
        }
      }

      return lines.join('\n');
    }
  }

  getPotentialClientProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('email', 'Email', <i className="fas fa-envelope"></i>),
      Property('phone', 'Telefone', <i className="fas fa-phone"></i>),
      Property('date', 'Última data', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getDateText(entry),
        getFilterText: (entry) => this.getDateText(entry)
      }),
      Property('services', 'Serviços', <i className="fas fa-concierge-bell"></i>, {
        getDataText: (entry) => entry.services.join(', '),
        getFilterText: (entry) => entry.services.join(', ')
      })
    ];

    return properties;
  }

  getSalesProperties() {
    let properties = [
      Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {
        cellClassName: "sales-report__description-cell",
        getDataText: (entry) => this.getFinancialDescriptionText(entry),
        getFilterText: (entry) => this.getFinancialDescriptionText(entry)
      }),
      Property('user', 'Aluno', <i className="fas fa-user"></i>, {
        getDataText: (entry) => this.getUserText(entry),
        getFilterText: (entry) => this.getUserText(entry),
        getSortCallback: (a, b) => {
          return this.getUserText(a).localeCompare(this.getUserText(b));
        }
      }),
      Property('creator', 'Responsável', <i className="fas fa-user"></i>, {
        getDataText: (entry) => this.getCreatorText(entry),
        getFilterText: (entry) => this.getCreatorText(entry),
        getSortCallback: (a, b) => {
          return this.getCreatorText(a).localeCompare(this.getCreatorText(b));
        }
      }),
      Property('total_value', 'Valor', <i className="fas fa-money-bill-alt"></i>, {
        getDataText: (entry) => this.getFinancialValueText(entry),
        getFilterText: (entry) => this.getFinancialValueText(entry),
        getSortCallback: (a, b) => {
          return a.total_value - b.total_value;
        }
      }),
      Property('created_at', 'Cadastro', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getCreatedAtText(entry),
        getFilterText: (entry) => this.getCreatedAtText(entry)
      })
    ];

    return properties;
  }

  getSalesActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-link-button"
          to={`${routes.INCOME_EDIT_PATH}${entry.id}`}
        >

            <i className="fas fa-link"></i>

        </Link>

      </div>
    );
  }

  loadSalesData() {
    if(this.state.initialDate > this.state.finalDate) {
      return [];
    }

    const experimentalStudentMap = new Map();
    const serviceMap = new Map();
    const coachMap = new Map();
    let totalCheckin = 0;

    for(const entry of this.state.experimentalClasses) {
      if(entry.checked_in) {
        totalCheckin += 1;
      }

      const entryEmail = entry.email.toLocaleLowerCase().trim();

      let coaches = entry.coaches ? entry.coaches.map((coach) => coach.name) : ['Indefinido'];

      for (const coachName of coaches) {
        if(entry.checked_in) {
          if(!coachMap.has(coachName)) {
            coachMap.set(coachName, {
              coachName,
              experimentalCount: 1,
              emails: new Set([entryEmail]),
              feedbackCount: 0
            });
          }
          else {
            const coachMapEntry = coachMap.get(coachName);
            coachMapEntry.experimentalCount += 1;
            coachMapEntry.emails.add(entryEmail);
          }
        }
      }

      if(!experimentalStudentMap.has(entryEmail)) {
        experimentalStudentMap.set(entryEmail, {
          id: experimentalStudentMap.size,
          email: entryEmail,
          date: entry.date,
          firstDate: entry.date,
          name: entry.name,
          phone: entry.phone,
          // physical_activity: entry.physical_activity,
          // physical_condition: entry.physical_condition,
          services: [entry.target_service],
          entries_count: 1,
          checkin_count: entry.checked_in ? 1 : 0,
          hasContract: false,
          contracts: [],
          contractDate: null,
          rescheduled: entry.checked_in === null,
          coaches: entry.checked_in ? new Set(coaches) : new Set(),
        });
      }
      else {
        const studentMapEntry = experimentalStudentMap.get(entryEmail);
        studentMapEntry.entries_count += 1;
        if(studentMapEntry.date < entry.date) {
          studentMapEntry.date = entry.date;
          studentMapEntry.rescheduled = entry.checked_in === null;
        }
        if(studentMapEntry.firstDate > entry.date) {
          studentMapEntry.firstDate = entry.firstDate;
        }
        // if(studentMapEntry.physical_activity === null || (entry.physical_activity !== null && entry.physical_activity.length > studentMapEntry.physical_activity)) {
        //   studentMapEntry.physical_activity = entry.physical_activity;
        // }
        // if(studentMapEntry.physical_condition === null || (entry.physical_condition !== null && entry.physical_condition.length > studentMapEntry.physical_condition)) {
        //   studentMapEntry.physical_condition = entry.physical_condition;
        // }
        if(!studentMapEntry.services.includes(entry.target_service)) {
          studentMapEntry.services.push(entry.target_service);
        }
        if(entry.checked_in) {
          studentMapEntry.checkin_count += 1;
        }

        if(entry.checked_in) {
          studentMapEntry.coaches = new Set([ ...studentMapEntry.coaches, ...coaches ])
        }
      }

      let target_service = entry.target_service || 'Outros';

      if(!serviceMap.has(target_service)) {
        serviceMap.set(target_service, {
          service: target_service,
          experimental_count: 1,
          checkin_count: entry.checked_in ? 1 : 0,
          feedback_count: 0,
          contracts: [],
          totalValue: 0
        });
      }
      else {
        const serviceMapEntry = serviceMap.get(target_service);
        serviceMapEntry.experimental_count += 1;
        if(entry.checked_in) {
          serviceMapEntry.checkin_count += 1;
        }
      }
    }

    const experimentalCheckinCount = [...experimentalStudentMap.values()].reduce((sum, entry) => sum + (entry.checkin_count > 0 ? 1 : 0), 0);

    const feedbackStudentMap = new Map();

    for(const entry of this.state.feedbacks) {
      const entryEmail = entry.email.toLocaleLowerCase().trim();

      if(!feedbackStudentMap.has(entryEmail)) {
        feedbackStudentMap.set(entryEmail, 1);
      }
      else {
        const count = feedbackStudentMap.get(entryEmail);
        feedbackStudentMap.set(entryEmail, count + 1);
      }

      let target_service = entry.target_service || 'Outros';

      if(!serviceMap.has(target_service)) {
        serviceMap.set(target_service, {
          service: target_service,
          experimental_count: 0,
          checkin_count: 0,
          feedback_count: 1,
          contracts: [],
          totalValue: 0
        });
      }
      else {
        const serviceMapEntry = serviceMap.get(target_service);
        serviceMapEntry.feedback_count += 1;
      }

      if(!coachMap.has(entry.instructor)) {
        coachMap.set(entry.instructor, {
          coachName: entry.instructor,
          experimentalCount: 0,
          emails: new Set(),
          feedbackCount: 1
        });
      }
      else {
        const coachMapEntry = coachMap.get(entry.instructor);
        coachMapEntry.feedbackCount += 1;
      }
    }

    // const studentMap = new Map();
    //
    // const financialStudentMap = new Map();
    let totalSales = 0;
    let totalSalesReceived = 0;
    // let totalIncome = 0;

    for(const entry of this.state.financialEntries) {
      // if(entry.user_id !== null && !studentMap.has(entry.user_id)) {
      //   studentMap.set(entry.user_id, {...entry.user});
      // }

      if(!entry.is_expense) {
        totalSales += entry.total_value;
        totalSalesReceived += entry.total_value_completed;

        // if(!entry.is_canceled) {
        //   totalIncome += entry.total_value;
        // }
        // else {
        //   totalIncome += entry.transactions.reduce((sum, transaction) => sum + (transaction.completed ? transaction.value : 0), 0);
        // }

        // if(entry.user_id !== null) {
        //   if(!financialStudentMap.has(entry.user_id)) {
        //     financialStudentMap.set(entry.user_id, {
        //       user_id: entry.user_id,
        //       name: entry.user.name,
        //       email: entry.user.email,
        //       financial_entries: [entry]
        //     });
        //   }
        //   else {
        //     const studentMap = financialStudentMap.get(entry.user_id);
        //     studentMap.financial_entries.push(entry);
        //   }
        // }
      }
    }

    const contractMap = new Map();
    const contractStudentMap = new Map();
    let contractTotalCount = 0;
    const totalContractPerServiceMap = new Map();
    let totalContractPerServiceSum = 0;
    const contractPerServiceDistributionMap = new Map();
    const servicePlanMap = new Map();

    let contractTotalValue = 0;

    const contractEmailMap = new Map();

    for(const entry of this.state.contracts) {
      const contractEmail = entry.user.email.toLocaleLowerCase().trim();
      contractMap.set(entry.id, entry);

      contractTotalValue += entry.total_value;

      if(experimentalStudentMap.has(contractEmail)) {
        const experimentalStudentMapEntry = experimentalStudentMap.get(contractEmail);

        experimentalStudentMapEntry.hasContract = true;
        experimentalStudentMapEntry.contracts.push(entry);

        if (experimentalStudentMapEntry.firstDate <= entry.created_at) {
          if(experimentalStudentMapEntry.contractDate === null) {
            experimentalStudentMapEntry.contractDate = entry.created_at
          }
          else if(experimentalStudentMapEntry.contractDate > entry.created_at) {
            experimentalStudentMapEntry.contractDate = entry.created_at;
          }
        }
      }
      else if(this.state.experimentalContractsOnly) {
        continue;
      }

      contractTotalCount += 1;

      if(!contractEmailMap.has(contractEmail)) {
        contractEmailMap.set(contractEmail, {
          user_id: entry.user_id,
          user_email: contractEmail,
          contracts: [entry]
        });
      }
      else {
        const mapEntry = contractEmailMap.get(contractEmail);
        mapEntry.contracts.push(entry);
      }

      if(!contractStudentMap.has(entry.user_id)) {
        contractStudentMap.set(entry.user_id, {
          user_id: entry.user_id,
          contracts: [entry]
        });
      }
      else {
        const studentMap = contractStudentMap.get(entry.user_id);
        studentMap.contracts.push(entry);
      }

      let target_service = entry.target_service || 'Outros';

      if(!serviceMap.has(target_service)) {
        serviceMap.set(target_service, {
          service: target_service,
          experimental_count: 0,
          checkin_count: 0,
          feedback_count: 1,
          contracts: [entry],
          totalValue: entry.total_value
        });
      }
      else {
        const serviceMapEntry = serviceMap.get(target_service);
        serviceMapEntry.totalValue += entry.total_value;
        serviceMapEntry.contracts.push(entry);
      }

      if (entry.services_list) {
        const serviceCount = entry.services_list.length;

        totalContractPerServiceSum += serviceCount;

        for(const serviceEntry of entry.services_list) {
          if(!serviceMap.has(serviceEntry)) {
            serviceMap.set(serviceEntry, {
              service: serviceEntry,
              experimental_count: 0,
              checkin_count: 0,
              feedback_count: 0,
              contracts: [],
              totalValue: 0
            });
          }

          if (!contractPerServiceDistributionMap.has(serviceEntry)) {
            contractPerServiceDistributionMap.set(serviceEntry, {
              count: 1 / serviceCount,
              value: entry.total_value / serviceCount,
              contracts: [entry],
              totalValue: entry.total_value
            });
          }
          else {
            const contractPerServiceDistributionMapEntry = contractPerServiceDistributionMap.get(serviceEntry);
            contractPerServiceDistributionMapEntry.count += 1 / serviceCount;
            contractPerServiceDistributionMapEntry.value += entry.total_value / serviceCount;
            contractPerServiceDistributionMapEntry.totalValue += entry.total_value;
            contractPerServiceDistributionMapEntry.contracts.push(entry);
          }

          if (!totalContractPerServiceMap.has(serviceEntry)) {
            totalContractPerServiceMap.set(serviceEntry, 1);
          }
          else {
            totalContractPerServiceMap.set(serviceEntry, totalContractPerServiceMap.get(serviceEntry) + 1);
          }
        }
      }
      else {
        if (!contractPerServiceDistributionMap.has(target_service)) {
          contractPerServiceDistributionMap.set(target_service, {
            count: 1,
            value: entry.total_value,
            contracts: [entry],
            totalValue: entry.total_value
          });
        }
        else {
          const contractPerServiceDistributionMapEntry = contractPerServiceDistributionMap.get(target_service);
          contractPerServiceDistributionMapEntry.count += 1;
          contractPerServiceDistributionMapEntry.value += entry.total_value;
          contractPerServiceDistributionMapEntry.totalValue += entry.total_value;
          contractPerServiceDistributionMapEntry.contracts.push(entry);
        }

        if (!totalContractPerServiceMap.has(target_service)) {
          totalContractPerServiceMap.set(target_service, 1);
        }
        else {
          totalContractPerServiceMap.set(target_service, totalContractPerServiceMap.get(target_service) + 1);
        }
      }

      const servicePlanName = entry.service_plan.name;

      if(!servicePlanMap.has(servicePlanName)) {
        servicePlanMap.set(servicePlanName, {
          servicePlanName: servicePlanName,
          count: 1,
          totalValue: entry.total_value
        });
      }
      else {
        const servicePlanEntrie = servicePlanMap.get(servicePlanName);
        servicePlanEntrie.count += 1;
        servicePlanEntrie.totalValue += entry.total_value;
      }
    }

    const servicePlansByCount = [];
    const servicePlansByValue = [];

    for(const value of [...servicePlanMap.values()]) {
      servicePlansByCount.push(PiePoint(value.count, contractTotalCount, value.servicePlanName, servicePlansByCount.length < DEFAULT_SEQUENTIAL_COLOR_PALLET.length ? DEFAULT_SEQUENTIAL_COLOR_PALLET[servicePlansByCount.length] : 'black'));

      if (contractTotalValue > 0) {
        servicePlansByValue.push(PiePoint(value.totalValue, contractTotalValue, value.servicePlanName, servicePlansByValue.length < DEFAULT_SEQUENTIAL_COLOR_PALLET.length ? DEFAULT_SEQUENTIAL_COLOR_PALLET[servicePlansByValue.length] : 'black'));
      }
    }

    for(const entry of this.state.additionalContracs) {
      const contractEmail = entry.user.email.toLocaleLowerCase().trim();

      if(experimentalStudentMap.has(contractEmail)) {
        const experimentalStudentMapEntry = experimentalStudentMap.get(contractEmail);

        experimentalStudentMapEntry.hasContract = true;
        experimentalStudentMapEntry.contracts.push(entry);

        if (experimentalStudentMapEntry.firstDate <= entry.created_at) {
          if(experimentalStudentMapEntry.contractDate === null) {
            experimentalStudentMapEntry.contractDate = entry.created_at
          }
          else if(experimentalStudentMapEntry.contractDate > entry.created_at) {
            experimentalStudentMapEntry.contractDate = entry.created_at;
          }
        }
      }
    }

    const overviewData = [
      StackGroup('Total', [
        StackPoint(contractTotalCount, 'Contratos fechados', contractTotalCount),
        StackPoint(this.state.feedbacks.length, 'Pesquisa de satisfação', this.state.feedbacks.length),
        StackPoint(totalCheckin, 'Comparecimento', totalCheckin),
        StackPoint(this.state.experimentalClasses.length, 'Aulas experimentais', this.state.experimentalClasses.length),
      ], '#93bfeb'),
      StackGroup('Por aluno', [
        StackPoint(contractStudentMap.size, 'Contratos fechados', contractStudentMap.size),
        StackPoint(feedbackStudentMap.size, 'Pesquisa de satisfação', feedbackStudentMap.size),
        StackPoint(experimentalCheckinCount, 'Comparecimento', experimentalCheckinCount),
        StackPoint(experimentalStudentMap.size, 'Aulas experimentais', experimentalStudentMap.size),
      ], '#1866b4')
    ];

    const experimentalDataByService = [
      StackGroup('Contratos fechados', [], '#ea6767'),
      StackGroup('Pesquisas de satisfação', [], '#78bb67', null, false),
      StackGroup('Confirmadas', [], '#1866b4'),
      StackGroup('Agendadas', [], '#93bfeb', null, false),
    ];

    const experimentalEntriesByService = [];
    const contractEntriesByService = [];
    const contractEntriesValuesByService = [];
    const servicePlansByServices = [];

    const serviceKeys = [...serviceMap.keys()];
    serviceKeys.sort();

    let colorCount = 0;

    for(const key of serviceKeys) {
      const serviceMapEntry = serviceMap.get(key);

      if (totalContractPerServiceMap.has(key)) {
        experimentalDataByService[0].points.push(StackPoint(Math.round(10 * totalContractPerServiceMap.get(key)) / 10, key, Math.round(100 * totalContractPerServiceMap.get(key)) / 100));
        experimentalDataByService[3].points.push(StackPoint(serviceMapEntry.experimental_count, key, serviceMapEntry.experimental_count));
        experimentalDataByService[2].points.push(StackPoint(serviceMapEntry.checkin_count, key, serviceMapEntry.checkin_count));
        experimentalDataByService[1].points.push(StackPoint(serviceMapEntry.feedback_count, key, serviceMapEntry.feedback_count));
      }

      if(serviceMapEntry.checkin_count > 0) {
        experimentalEntriesByService.push(PiePoint(serviceMapEntry.checkin_count, totalCheckin, key, colorCount < LIGHT_SERVICE_PALLET.length ? LIGHT_SERVICE_PALLET[colorCount] : 'black'));
      }

      if (totalContractPerServiceMap.has(key)) {
        contractEntriesByService.push(PiePoint(Math.round(10 * totalContractPerServiceMap.get(key)) / 10, totalContractPerServiceSum, key, colorCount < DARK_SERVICE_PALLET.length ? DARK_SERVICE_PALLET[colorCount] : 'black'));
      }
      if (contractPerServiceDistributionMap.has(key)) {
        contractEntriesValuesByService.push(PiePoint(Math.round(100 * contractPerServiceDistributionMap.get(key).value) / 100, contractTotalValue, key, colorCount < DARK_SERVICE_PALLET.length ? DARK_SERVICE_PALLET[colorCount] : 'black'));
      }

      // contractPerServiceDistributionMap
      // totalValue
      // contracts
      const isCombo = !contractPerServiceDistributionMap.has(key);

      const contractsAnalysed = isCombo ? serviceMapEntry.contracts : contractPerServiceDistributionMap.get(key).contracts;

      if(contractsAnalysed.length > 0) {
        const contractServicePlanMap = new Map();
        let serviceTotalValue = 0;

        const serviceDistributionMap = new Map();
        let serviceDistributionTotalCount = 0;

        for(const contract of contractsAnalysed) {
          const servicePlanName = contract.service_plan.name;

          serviceTotalValue += contract.total_value;

          if (isCombo) {
            if (contract.services_list) {
              for(const serviceEntry of contract.services_list) {
                if (!serviceDistributionMap.has(serviceEntry)) {
                  serviceDistributionMap.set(serviceEntry, 1);
                }
                else {
                  serviceDistributionMap.set(serviceEntry, serviceDistributionMap.get(serviceEntry) + 1);
                }
              }

              serviceDistributionTotalCount += contract.services_list.length;
            }
          }

          if(!contractServicePlanMap.has(servicePlanName)) {
            contractServicePlanMap.set(servicePlanName, {
              servicePlanName: servicePlanName,
              count: 1,
              totalValue: contract.total_value
            });
          }
          else {
            const servicePlanEntrie = contractServicePlanMap.get(servicePlanName);
            servicePlanEntrie.count += 1;
            servicePlanEntrie.totalValue += contract.total_value;
          }
        }

        const servicePlansByServiceData = {
          service: key,
          byCount: [],
          byValue: [],
          serviceDistribution: null
        };

        if (serviceDistributionMap.size > 1) {
          const serviceDistribution = [];

          for (let [key, value] of serviceDistributionMap) {
            serviceDistribution.push(PiePoint(value, serviceDistributionTotalCount, key, SERVICE_COLOR_MAP[key] || 'black'));
          }

          servicePlansByServiceData.serviceDistribution = serviceDistribution;
        }

        for(const value of [...contractServicePlanMap.values()]) {
          servicePlansByServiceData.byCount.push(PiePoint(value.count, contractsAnalysed.length, value.servicePlanName, servicePlansByServiceData.byCount.length < DEFAULT_SEQUENTIAL_COLOR_PALLET.length ? DEFAULT_SEQUENTIAL_COLOR_PALLET[servicePlansByServiceData.byCount.length] : 'black'));

          if (serviceTotalValue > 0) {
            servicePlansByServiceData.byValue.push(PiePoint(value.totalValue, serviceTotalValue, value.servicePlanName, servicePlansByServiceData.byValue.length < DEFAULT_SEQUENTIAL_COLOR_PALLET.length ? DEFAULT_SEQUENTIAL_COLOR_PALLET[servicePlansByServiceData.byValue.length] : 'black'));
          }
        }

        servicePlansByServices.push(servicePlansByServiceData);
      }

      colorCount += 1;
    }

    const coachData = [
      StackGroup('Alunos experimentais únicos', [], '#1866b4'),
      StackGroup('Pesquisas de satisfação', [], '#78bb67'),
      StackGroup('Contratos experimentais únicos', [], '#ea6767'),
    ];

    const coachContractValueData = [
      StackGroup('Contratos experimentais únicos', [], '#ea6767')
    ];

    const experimentalCoachKeys = [...coachMap.keys()];
    experimentalCoachKeys.sort();

    for(const key of experimentalCoachKeys) {
      const coachMapEntry = coachMap.get(key);

      const experimentalEmails = [...coachMapEntry.emails];

      const contractCount = experimentalEmails.reduce((sum, email) => sum + (contractEmailMap.has(email) ? (1 / experimentalStudentMap.get(email).coaches.size) : 0), 0);
      const experimentalCount = experimentalEmails.reduce((sum, email) => sum + (1 / experimentalStudentMap.get(email).coaches.size), 0);

      coachData[0].points.push(StackPoint(Math.round(100 * experimentalCount) / 100, key, Math.round(10 * experimentalCount) / 10));
      coachData[1].points.push(StackPoint(coachMapEntry.feedbackCount, key, coachMapEntry.feedbackCount));
      coachData[2].points.push(StackPoint(Math.round(100 * contractCount) / 100, key, Math.round(10 * contractCount) / 10));

      const contractTotalValue = experimentalEmails.reduce((sum, email) => {
        if(!contractEmailMap.has(email)) {
          return sum;
        }

        const contractValueSum = contractEmailMap.get(email).contracts.reduce((sum, contract) => sum + contract.total_value, 0);

        return sum + (contractValueSum / experimentalStudentMap.get(email).coaches.size);
      }, 0);

      coachContractValueData[0].points.push(StackPoint(contractTotalValue, key, getCurrencyText(contractTotalValue)));
    }

    const potentialStudentsA = [];
    const potentialStudentsB = [];

    let experimentalCheckedInConverted = 0;

    const allExperimentalStudents = [...experimentalStudentMap.values()];
    const allConvertedExperimentalStudents = allExperimentalStudents.filter((entry) => entry.contractDate !== null && entry.checkin_count > 0);

    let maxDaysForConversion = 0;
    const conversionGroups = [];

    const meanConversionPeriod = (allConvertedExperimentalStudents.reduce((acc, current) => {
      const experimentalClassDate = getAsLocalDate(current.firstDate);
      const contractDate = getAsLocalDate(current.contractDate);

      const timeDiff = contractDate.getTime() - experimentalClassDate.getTime();
      const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (maxDaysForConversion <= daysPassed) {
        maxDaysForConversion = daysPassed;

        for(let i=conversionGroups.length; i <= Math.floor(maxDaysForConversion / this.state.conversionGroupSize); ++i) {
          conversionGroups.push(0);
        }
      }

      conversionGroups[Math.floor(daysPassed / this.state.conversionGroupSize)] += 1

      return acc + daysPassed;
    }, 0) / allConvertedExperimentalStudents.length) || 0;

    let conversionPeriodDeviation;
    if(allConvertedExperimentalStudents.length > 1) {
      conversionPeriodDeviation = Math.sqrt(allConvertedExperimentalStudents.reduce((acc, current) => {
        const experimentalClassDate = getAsLocalDate(current.firstDate);
        const contractDate = getAsLocalDate(current.contractDate);

        const timeDiff = contractDate.getTime() - experimentalClassDate.getTime();
        const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return acc + Math.pow(daysPassed - meanConversionPeriod, 2);
      }, 0) / allConvertedExperimentalStudents.length); // uncorrected standard deviation
    }
    else {
      conversionPeriodDeviation = 0;
    }

    const conversionDistribution = [
      StackGroup('Convertidos', [
        ...conversionGroups.map((entry, index) => StackPoint(entry, `${index * this.state.conversionGroupSize}-${((index + 1) * this.state.conversionGroupSize) - 1}`, entry))
      ], '#93bfeb')
    ];

    const roundedMeanConversionPeriod = Math.round(meanConversionPeriod*10) / 10;
    const roundedConversionPeriodDeviation = Math.round(conversionPeriodDeviation*10) / 10;

    let experimentalConverted = 0;
    let experimentalConvertedTotalValue = 0;

    for(const entry of allExperimentalStudents) {
      if(!entry.hasContract && !entry.rescheduled) {
        if(entry.checkin_count > 0) {
          potentialStudentsA.push(entry);
        }
        else {
          potentialStudentsB.push(entry);
        }
      }

      if (entry.hasContract) {
        experimentalConverted += 1;
        experimentalConvertedTotalValue += entry.contracts.reduce((sum, contract) => sum + contract.total_value, 0);

        if(entry.checkin_count > 0) {
          experimentalCheckedInConverted += 1;
        }
      }
    }

    this.setState({salesData: {
      overviewData,
      experimentalDataByService,
      experimentalEntriesByService,
      contractEntriesByService,
      contractEntriesValuesByService,
      servicePlansByCount,
      servicePlansByValue,
      servicePlansByServices,
      coachData,
      coachContractValueData,
      experimentalPresence: experimentalStudentMap.size > 0 ? (experimentalCheckinCount / experimentalStudentMap.size) : null,
      conversionPercentage: experimentalCheckinCount > 0 ? [experimentalCheckedInConverted, experimentalCheckinCount] : null,
      conversionPeriod: [roundedMeanConversionPeriod, roundedConversionPeriodDeviation],
      conversionDistribution,
      totalSales,
      totalSalesReceived,
      potentialStudentsA,
      potentialStudentsB,
      contractMap,
      experimentalStudentCount: experimentalStudentMap.size,
      experimentalCheckinCount,
      experimentalConverted,
      experimentalConvertedTotalValue,
    }});
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPerClassificationGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.SALES_REPORT_PATH,
            text: "Relatório de vendas"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Relatório de vendas"
        loading={this.state.loadingData}
      >

        <div className="sales-report__wrapper">

          <div className="sales-report__period-control">

            <h3 className="sales-report__period-control__title">Período de avaliação</h3>

            <div className="sales-report__period-control__inputs-container">
              <HalfWrapper className="sales-report__period-control__inputs">

                <DefaultInput
                  name="initialDateInput"
                  isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                  label="Data inicial"
                  type="date"
                  placeholder="Data inicial"
                  max={this.state.finalDateInput}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.initialDateInput}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

                <DefaultInput
                  name="finalDateInput"
                  isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                  label="Data final"
                  type="date"
                  placeholder="Data final"
                  min={this.state.initialDateInput}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.finalDateInput}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

              </HalfWrapper>

              <button
                className="sales-report__period-control__refresh-button"
                onClick={() => this.applyDateInputChanges()}
                disabled={!this.mayUpdateDateInputs()}
              >

                <i className="fas fa-sync"></i>

              </button>
            </div>

          </div>

          <HorizontalRule />

          <DefaultInput
            className="sales-report__filter-toggle"
            name="experimentalContractsOnly"
            label="Somente contratos de aulas experimentais:"
            type="toggle"
            isHorizontal={this.state.screenWidth > 360}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.experimentalContractsOnly}
            horizontalAlign="right"
          />

          {this.state.salesData !== null ?
            <React.Fragment>

              <DefaultSubSectionTitle
                icon={<i className="far fa-chart-bar"></i>}
                text="Resumo do período"
              />

              <div className="sales-report__indicator-container--spaced">

                <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Matrículas de aulas experimentais:</h2>
                  <p className="sales-report__indicator__value">{getCurrencyText(this.state.salesData.experimentalConvertedTotalValue)}</p>

                </div>

                <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Gasto com marketing:</h2>
                  <p className="sales-report__indicator__value">{getCurrencyText(this.state.marketingTotalExpense)}</p>

                </div>

                {/* <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Conversão de comparecimento:</h2>
                  <p className="sales-report__indicator__value">{this.state.salesData.conversionPercentage !== null ? `${this.state.salesData.conversionPercentage[0]}/${this.state.salesData.conversionPercentage[1]} (${(100 * this.state.salesData.conversionPercentage[0] / this.state.salesData.conversionPercentage[1]).toFixed(1)}%)` : '-'}</p>

                </div> */}

                {(this.state.salesData.experimentalConvertedTotalValue > 0 && this.state.marketingTotalExpense > 0) &&
                  <div className="sales-report__indicator">

                    <h2 className="sales-report__indicator__label">Retorno do marketing:</h2>
                    <p className="sales-report__indicator__value">{getCurrencyText(this.state.salesData.experimentalConvertedTotalValue / this.state.marketingTotalExpense)}</p>

                  </div>
                }

                {(this.state.salesData.experimentalConverted > 0 && this.state.marketingTotalExpense > 0) &&
                  <div className="sales-report__indicator">

                    <h2 className="sales-report__indicator__label">CAC:</h2>
                    <p className="sales-report__indicator__value">{getCurrencyText(this.state.marketingTotalExpense / this.state.salesData.experimentalConverted)}</p>

                  </div>
                }

              </div>

              <FunnelChart
                className="sales-report__graph"
                layers={[
                  FunnelLayer('Acesso ao site', this.state.websiteUserCount, '#1c4587'),
                  FunnelLayer('Aula experimental', this.state.salesData.experimentalStudentCount, '#1155cc'),
                  FunnelLayer('Compareceu', this.state.salesData.experimentalCheckinCount, '#3d85c6'),
                  FunnelLayer('Matriculou-se', this.state.salesData.experimentalConverted, '#6d9eeb'),
                ]}
              />

              <HorizontalRule />

              <StackedBarGraph
                className="sales-report__graph"
                data={this.state.salesData.overviewData}
                lineYAxisType="secondary"
                doNotStack={true}
                isHorizontal={true}
                normalXLabel={true}
                normalLegendOrder={true}
                ToolTipValueCallback={(value) => `${value}`}
                height={this.getDefaultGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
              />

              <HorizontalRule />

              <DefaultSubSectionTitle
                icon={<i className="far fa-chart-bar"></i>}
                text="Distribuição de conversão de comparecimento"
              />

              <div className="sales-report__indicator-container--spaced">

                <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Tempo médio de conversão:</h2>
                  <p className="sales-report__indicator__value">{this.state.salesData.conversionPeriod[0] !== 0 ? `${this.state.salesData.conversionPeriod[0]} ± ${this.state.salesData.conversionPeriod[1]}` : '-'}</p>

                </div>

              </div>

              <div className="sales-report__controls-container">

                <p className="sales-report__control-label">Tamanho do grupo:</p>

                <div className="sales-report__button-group">

                  <button
                    className={`sales-report__control-button--${this.state.conversionGroupSize === 2 ? 'active' : 'inactive'}`}
                    onClick={() => this.setState({conversionGroupSize : 2})}
                    disabled={this.state.conversionGroupSize === 2}
                  >
                    2 dias
                  </button>

                  <button
                    className={`sales-report__control-button--${this.state.conversionGroupSize === 5 ? 'active' : 'inactive'}`}
                    onClick={() => this.setState({conversionGroupSize : 5})}
                    disabled={this.state.conversionGroupSize === 5}
                  >
                    5 dias
                  </button>

                </div>

              </div>

              <StackedBarGraph
                className="sales-report__graph"
                data={this.state.salesData.conversionDistribution}
                lineYAxisType="secondary"
                doNotStack={true}
                normalXLabel={true}
                normalLegendOrder={true}
                ToolTipValueCallback={(value) => `${value}`}
                height={this.getDefaultGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
              />

              <HorizontalRule />

              <section className="sales-report__report-section">

                <header
                  className="sales-report__report-section__header"
                  onClick={() => this.setState({experimentalClassSectionVisible: !this.state.experimentalClassSectionVisible})}
                >

                  <h3 className="sales-report__report-section__header__text">
                    <i className="far fa-chart-bar sales-report__report-section__header__text-icon"></i>
                    Por serviço
                  </h3>

                  {this.state.experimentalClassSectionVisible ?
                    <i className="fas fa-chevron-down sales-report__report-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up sales-report__report-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container sales-report__report-section__content"
                  pose={this.state.experimentalClassSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="sales-report__report-section__wrapper">

                    <StackedBarGraph
                      className="sales-report__graph"
                      data={this.state.salesData.experimentalDataByService}
                      lineYAxisType="secondary"
                      doNotStack={true}
                      isHorizontal={true}
                      normalXLabel={true}
                      reversedXAxis={true}
                      ToolTipValueCallback={(value) => `${value}`}
                      height={this.getDefaultGraphHeight()}
                      legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                      hidden={!this.state.experimentalClassSectionVisible}
                    />

                    <HorizontalRule />

                    <div className="sales-report__per-classification-container">

                      <div className="sales-report__per-classification">

                        <h3 className="sales-report__per-classification__title">Aulas experimentais</h3>

                        <div className="sales-report__per-classification__graph">

                          <PieGraph
                            className="sales-report__graph"
                            data={this.state.salesData.experimentalEntriesByService}
                            height={this.getPerClassificationGraphHeight()}
                            totalToolTipLabel="Total de comparecimentos"
                            valueTextCallback={(value) => value}
                            indexLabel="{value}"
                            hidden={!this.state.experimentalClassSectionVisible}
                          />

                        </div>

                      </div>

                      <div className="sales-report__per-classification">

                        <h3 className="sales-report__per-classification__title">Contratos fechados</h3>

                        <div className="sales-report__per-classification__graph">

                          <PieGraph
                            className="sales-report__graph"
                            data={this.state.salesData.contractEntriesByService}
                            height={this.getPerClassificationGraphHeight()}
                            totalToolTipLabel="Total"
                            valueTextCallback={(value) => value}
                            indexLabel="{value}"
                            hidden={!this.state.experimentalClassSectionVisible}
                          />

                        </div>

                      </div>

                    </div>

                    <HorizontalRule />

                    <DefaultSubSectionTitle
                      icon={<i className="far fa-chart-bar"></i>}
                      text="Por valor do contrato"
                    />

                    <PieGraph
                      className="sales-report__graph"
                      data={this.state.salesData.contractEntriesValuesByService}
                      height={this.getPerClassificationGraphHeight()}
                      totalToolTipLabel="Valor total"
                      hidden={!this.state.experimentalClassSectionVisible}
                    />

                  </div>

                </VerticalAccordionContainer>

              </section>

              <HorizontalRule />

              <section className="sales-report__report-section">

                <header
                  className="sales-report__report-section__header"
                  onClick={() => this.setState({servicePlanSectionVisible: !this.state.servicePlanSectionVisible})}
                >

                  <h3 className="sales-report__report-section__header__text">
                    <i className="fas fa-chart-pie sales-report__report-section__header__text-icon"></i>
                    Venda de planos
                  </h3>

                  {this.state.servicePlanSectionVisible ?
                    <i className="fas fa-chevron-down sales-report__report-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up sales-report__report-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container sales-report__report-section__content"
                  pose={this.state.servicePlanSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="sales-report__report-section__wrapper">

                    {(this.state.salesData.servicePlansByCount.length > 0 || this.state.salesData.servicePlansByValue.length > 0) &&
                      <React.Fragment>

                        <DefaultSubSectionTitle
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Geral"
                        />

                        <div className="sales-report__per-classification-container">

                          <div className="sales-report__per-classification">

                            <h3 className="sales-report__per-classification__title">Por quantidade</h3>

                            <div className="sales-report__per-classification__graph">

                              <PieGraph
                                className="sales-report__graph"
                                data={this.state.salesData.servicePlansByCount}
                                height={this.getPerClassificationGraphHeight()}
                                totalToolTipLabel="Número total"
                                valueTextCallback={(value) => value}
                                indexLabel="{value}"
                                hidden={!this.state.servicePlanSectionVisible}
                              />

                            </div>

                          </div>

                          {this.state.salesData.servicePlansByValue.length > 0 &&
                            <div className="sales-report__per-classification">

                              <h3 className="sales-report__per-classification__title">Por valor</h3>

                              <div className="sales-report__per-classification__graph">

                                <PieGraph
                                  className="sales-report__graph"
                                  data={this.state.salesData.servicePlansByValue}
                                  height={this.getPerClassificationGraphHeight()}
                                  totalToolTipLabel="Valor total"
                                  hidden={!this.state.servicePlanSectionVisible}
                                />

                              </div>

                            </div>
                          }

                        </div>

                        <HorizontalRule />

                      </React.Fragment>
                    }

                    {this.state.salesData.servicePlansByServices.map((entry, i) => (
                      <React.Fragment
                        key={`sales_report:service:${entry.service}:service_plan:graph`}
                      >

                        <DefaultSubSectionTitle
                          icon={<i className="fas fa-concierge-bell"></i>}
                          text={entry.service}
                        />

                        <div className="sales-report__per-classification-container">

                          <div className="sales-report__per-classification">

                            <h3 className="sales-report__per-classification__title">Por quantidade</h3>

                            <div className="sales-report__per-classification__graph">

                              <PieGraph
                                className="sales-report__graph"
                                data={entry.byCount}
                                height={this.getPerClassificationGraphHeight()}
                                totalToolTipLabel="Número total"
                                valueTextCallback={(value) => value}
                                indexLabel="{value}"
                                hidden={!this.state.servicePlanSectionVisible}
                              />

                            </div>

                          </div>

                          {entry.byValue.length > 0 &&
                            <div className="sales-report__per-classification">

                              <h3 className="sales-report__per-classification__title">Por valor</h3>

                              <div className="sales-report__per-classification__graph">

                                <PieGraph
                                  className="sales-report__graph"
                                  data={entry.byValue}
                                  height={this.getPerClassificationGraphHeight()}
                                  totalToolTipLabel="Valor total"
                                  hidden={!this.state.servicePlanSectionVisible}
                                />

                              </div>

                            </div>
                          }

                        </div>

                        {entry.serviceDistribution !== null &&
                          <div className="sales-report__per-classification-container">

                            <div className="sales-report__per-classification">

                              <h3 className="sales-report__per-classification__title">Distribuição de serviços</h3>

                              <div className="sales-report__per-classification__graph">

                                <PieGraph
                                  className="sales-report__graph"
                                  data={entry.serviceDistribution}
                                  height={this.getPerClassificationGraphHeight()}
                                  totalToolTipLabel="Total"
                                  valueTextCallback={(value) => value}
                                  indexLabel="{value}"
                                  hidden={!this.state.servicePlanSectionVisible}
                                />

                              </div>

                            </div>

                          </div>
                        }

                        {(i < this.state.salesData.servicePlansByServices.length - 1) &&
                          <HorizontalRule />
                        }

                      </React.Fragment>
                    ))}

                  </div>

                </VerticalAccordionContainer>

              </section>

              <HorizontalRule />

              <section className="sales-report__report-section">

                <header
                  className="sales-report__report-section__header"
                  onClick={() => this.setState({coachSectionVisible: !this.state.coachSectionVisible})}
                >

                  <h3 className="sales-report__report-section__header__text">
                    <i className="fas fa-user sales-report__report-section__header__text-icon"></i>
                    Por treinador
                  </h3>

                  {this.state.coachSectionVisible ?
                    <i className="fas fa-chevron-down sales-report__report-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up sales-report__report-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container sales-report__report-section__content"
                  pose={this.state.coachSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="sales-report__report-section__wrapper">

                    <DefaultSubSectionTitle
                      icon={<i className="far fa-chart-bar"></i>}
                      text="Por quantidade"
                    />

                    <StackedBarGraph
                      className="sales-report__graph"
                      data={this.state.salesData.coachData}
                      lineYAxisType="secondary"
                      doNotStack={true}
                      isHorizontal={true}
                      normalXLabel={true}
                      normalLegendOrder={true}
                      ToolTipValueCallback={(value) => `${value}`}
                      height={this.getDefaultGraphHeight()}
                      legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                      hidden={!this.state.coachSectionVisible}
                    />

                    <DefaultSubSectionTitle
                      icon={<i className="far fa-chart-bar"></i>}
                      text="Por valor de plano fechado"
                    />

                    <StackedBarGraph
                      className="sales-report__graph"
                      data={this.state.salesData.coachContractValueData}
                      lineYAxisType="secondary"
                      doNotStack={true}
                      isHorizontal={true}
                      normalXLabel={true}
                      normalLegendOrder={true}
                      height={this.getDefaultGraphHeight()}
                      legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                      hidden={!this.state.coachSectionVisible}
                    />

                  </div>

                </VerticalAccordionContainer>

              </section>

              {(this.state.salesData.potentialStudentsA.length > 0 || this.state.salesData.potentialStudentsB.length > 0) &&
                <React.Fragment>

                  <HorizontalRule />

                  <section className="sales-report__report-section">

                    <header
                      className="sales-report__report-section__header"
                      onClick={() => this.setState({potentialClientsSectionVisible: !this.state.potentialClientsSectionVisible})}
                    >

                      <h3 className="sales-report__report-section__header__text">
                        <i className="fas fa-search sales-report__report-section__header__text-icon"></i>
                        Alunos potenciais
                      </h3>

                      {this.state.potentialClientsSectionVisible ?
                        <i className="fas fa-chevron-down sales-report__report-section__header__visible-icon"></i>:
                        <i className="fas fa-chevron-up sales-report__report-section__header__visible-icon"></i>
                      }

                    </header>

                    <VerticalAccordionContainer
                      className="vertical-accordion-container sales-report__report-section__content"
                      pose={this.state.potentialClientsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                    >

                      <div className="sales-report__report-section__wrapper--stretched">

                        {this.state.salesData.potentialStudentsA.length > 0 &&
                          <React.Fragment>

                            <DefaultSubSectionTitle
                              icon={<i className="far fa-list-alt"></i>}
                              text="Comparecidos e não convertidos"
                            />

                            <ModelTable
                              storageKey="hot_potential_clients"
                              properties={this.getPotentialClientProperties()}
                              data={this.state.salesData.potentialStudentsA}
                              initialOrderBy="date"
                            >

                            </ModelTable>

                          </React.Fragment>
                        }

                        {(this.state.salesData.potentialStudentsA.length > 0 && this.state.salesData.potentialStudentsB.length > 0) &&
                          <HorizontalRule />
                        }

                        {this.state.salesData.potentialStudentsB.length > 0 &&
                          <React.Fragment>

                            <DefaultSubSectionTitle
                              icon={<i className="far fa-list-alt"></i>}
                              text="Não comparecidos e não convertidos"
                            />

                            <ModelTable
                              storageKey="cold_potential_clients"
                              properties={this.getPotentialClientProperties()}
                              data={this.state.salesData.potentialStudentsB}
                              initialOrderBy="date"
                            >

                            </ModelTable>

                          </React.Fragment>
                        }

                      </div>

                    </VerticalAccordionContainer>

                  </section>

                </React.Fragment>
              }

              <HorizontalRule />

              <DefaultSubSectionTitle
                icon={<i className="fas fa-clipboard-list"></i>}
                text="Listagem de vendas"
              />

              <div className="sales-report__indicator-container--spaced">

                <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Total de vendas:</h2>
                  <p className="sales-report__indicator__value">{getCurrencyText(this.state.salesData.totalSales)}</p>

                </div>

                <div className="sales-report__indicator">

                  <h2 className="sales-report__indicator__label">Total recebido:</h2>
                  <p className="sales-report__indicator__value">{`${getCurrencyText(this.state.salesData.totalSalesReceived)} (${(100 * (this.state.salesData.totalSalesReceived / this.state.salesData.totalSales)).toFixed(1)}%)`}</p>

                </div>

              </div>

              <ModelTable
                storageKey="sales_list"
                properties={this.getSalesProperties()}
                getActions={(entry) => this.getSalesActions(entry)}
                data={this.state.financialEntries}
                initialOrderBy="created_at"
              >

              </ModelTable>

            </React.Fragment>:
            null
          }

        </div>

      </ContentFrame>
    );
  }
}

export default SalesReport;
