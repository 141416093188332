import React from 'react';
import ContentFrame from '../content_frame';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import {getModels, patchModel, deleteModel, setUrlParameters, getAsLocalDatetime, getLocalTimeIsoString} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './client_lead_list.scss';

class ClientLeadList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let resolved = queryParameters.get('resolved');

    if(!resolved) {
      resolved = 'false';
    }

    this.state = {
      resolved,
      resolvedInput: resolved,
      loadingData: true,
      client_leads: [],
      entryToToggleResolved: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getClientLeads() {
    const parameters = {
      resolved: this.state.resolved,
    };

    if(parameters.resolved === 'all') {
      parameters.resolved = null;
    }

    return await getModels(setUrlParameters(routes.CRM_CLIENT_LEADS_GET_API, parameters));
  }

  async componentDidMount() {
    await this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.resolved !== this.state.resolved) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateLoadingStatus=true) {
    const update = {};

    if (updateLoadingStatus) {
      this.setState({
        loadingData: true
      });

      update.loadingData = false;
    }

    update.client_leads = await this.getClientLeads();
    this.props.onReloadList(update.client_leads);

    this.setState(update);
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      entryToToggleResolved: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onToggleResolvedEntry(entry) {
    this.setState({
      entryToToggleResolved: entry,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      entryToToggleResolved: null,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteId !== null) {
      try{
        if(await deleteModel(`${routes.CRM_CLIENT_LEAD_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.entryToToggleResolved !== null) {
      const data = {resolved: !this.state.entryToToggleResolved.resolved};

      try{
        if(await patchModel(`${routes.CRM_CLIENT_LEAD_PATCH_API}${this.state.entryToToggleResolved.id}`, data)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      entryToToggleResolved: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_CLIENT_LEAD_PERMISSION_ID) &&
          <button
            className="model-table__default-edit-button client-lead-list__resolved-toggle-button"
            onClick={() => this.onToggleResolvedEntry(entry)}
          >

            {entry.resolved ? (
              <i className="fa-solid fa-box-open"></i>
            ) : (
              <i className="fa-solid fa-box-archive"></i>
            )}

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_CLIENT_LEAD_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getCreatedAtText(entry) {
    const date = getAsLocalDatetime(entry.created_at, false);

    return (
      <div className="client-lead-list__cell-wrapper">

        <p className="client-lead-list__date-text">

          {`${date.toLocaleDateString()} ${getLocalTimeIsoString(date, false)}`}

        </p>

      </div>
    );
  }

  getCreatedAtFilter(entry) {
    const date = getAsLocalDatetime(entry.created_at, false);

    return `${date.toLocaleDateString()} ${getLocalTimeIsoString(date, false)}`;
  }

  getResolvedText(entry) {
    const text = entry.resolved ? 'Arquivado' : 'Não resolvido';;

    return (
      <div className="client-lead-list__cell-wrapper">

        <p className={`client-lead-list__resolved-text${entry.resolved ? '' : '--alert'}`}>

          {text}

        </p>

      </div>
    );
  }

  getResolvedFilter(entry) {
    return entry.resolved ? 'Arquivado' : 'Não resolvido';
  }

  getTargetServicesText(entry) {
    if (entry.target_services === null) {
      return '';
    }

    return entry.target_services.split(',').join(', ');
  }

  getProperties() {
    let properties = [
      Property('created_at', 'Criado em', <i className="fas fa-calendar-day"></i>, {
        getDataText: this.getCreatedAtText,
        getFilterText: this.getCreatedAtFilter,
      }),
      Property('name', 'Nome', <i className="fa-solid fa-tag"></i>),
      Property('email', 'E-mail', <i className="fa-regular fa-envelope"></i>),
      Property('phone', 'Telefone', <i className="fa-solid fa-phone"></i>),
      Property('target_services', 'Interesses', <i className="fa-solid fa-dumbbell"></i>, {
        getDataText: this.getTargetServicesText,
        getFilterText: this.getTargetServicesText,
      }),
      Property('resolved', 'Status', <i className="fa-solid fa-circle-info"></i>, {
        getDataText: this.getResolvedText,
        getFilterText: this.getResolvedFilter,
      })
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando lead';
      }
      else if(this.state.entryToToggleResolved !== null) {
        return `${this.state.entryToToggleResolved.resolved ? 'Desarquivando' : 'Arquivando'} lead`;
      }

      return 'INDEFINIDO';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar';
      }
      else if(this.state.entryToToggleResolved !== null) {
        return `Falha ao ${this.state.entryToToggleResolved.resolved ? 'desarquivar' : 'arquivar'}`;
      }

      return 'INDEFINIDO';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar lead';
    }
    else if(this.state.entryToToggleResolved !== null) {
      return `${this.state.entryToToggleResolved.resolved ? 'Desarquivar' : 'Arquivar'} lead`;
    }

    return 'INDEFINIDO';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId !== null) {
      return 'Todos os dados relacionados ao lead serão removidos permanentemente.';
    }
    else if(this.state.entryToToggleResolved !== null) {
      return `O cadastro de lead será ${this.state.entryToToggleResolved.resolved ? 'desarquivado' : 'arquivado'}.`;
    }

    return 'INDEFINIDO';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteId !== null) {
      return 'Deletar lead';
    }
    else if(this.state.entryToToggleResolved !== null) {
      return this.state.entryToToggleResolved.resolved ? 'Desarquivar' : 'Arquivar';
    }

    return 'INDEFINIDO';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(this.state.resolvedInput !== this.state.resolved) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.resolvedInput !== 'false') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      resolvedInput: 'false',
    });
  }

  applyFilterChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.CRM_CLIENT_LEAD_LIST_PATH, {
        resolved: this.state.resolvedInput
      }));

      this.setState({
        resolved: this.state.resolvedInput,
      });
    }
  }

  getResolvedOptions() {
    return [
      SelectOption('true', 'Arquivado'),
      SelectOption('false', 'Não arquivado'),
      SelectOption('all', 'Todos'),
    ];
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.entryToToggleResolved !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.CRM_CLIENT_LEAD_LIST_PATH,
              text: 'Listar requisições de contato'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title={'Contato de clientes pelo site'}
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="client-lead-list"
            title={'Lista de leads'}
          >

            <div className="client-lead-list__filters">

              <header className="client-lead-list__filters__header">

                <h4 className="client-lead-list__filters__header__text">Filtros</h4>

              </header>

              <div className="client-lead-list__filters__inputs">

                <div className="client-lead-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="resolvedInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.resolvedInput || ''}
                    options={this.getResolvedOptions()}
                  />

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="client-lead-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="client-lead-list__filters__refresh-button"
                    onClick={() => this.applyFilterChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.state.client_leads}
              initialOrderBy="created_at"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ClientLeadList;
