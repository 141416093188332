import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import './appointment_room_data.scss';


class AppointmentRoomData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.appointment_room.id && this.props.appointment_room.id > 0;
  }

  render() {
    return (
      <DefaultSection
        className="appointment-room-data"
        title="Dados da sala de atendimento"
      >

        <div className="appointment-room-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="appointment-room-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment_room.is_active}
            horizontalAlign="right"
          />

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome da sala"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment_room.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da sala"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment_room.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="appointment-room-data__buttons-container">

          <button
            className="appointment-room-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="appointment-room-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default AppointmentRoomData;
