import React from 'react';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import HorizontalBarGraph, {BarDataPoint} from '../../graphs/horizontal_bar_graph';
import './disc.scss';

function getDiscColor(type) {
  let color = 'black';

  switch (type.toLocaleLowerCase()) {
    case 'd':
      color = '#f37778';
      break;
    case 'i':
      color = '#fcd602';
      break;
    case 's':
      color = '#50bc84';
      break;
    case 'c':
      color = '#35ade3';
      break;
    default:
  }

  return color;
}

function DiscListColumn(props) {
  return (
    <ul
      className="disc__data-column"
    >

      <li className="disc__data-column__header-item-wrapper">

        <h3 className={`disc__data-column__header-item--${props.type}`}>{props.title}</h3>

      </li>

      {props.data}
      {props.footer}

    </ul>
  );
}

function DiscItem(props) {
  return (
    <li className="disc__data-column__item-wrapper">

      <p
        className="disc__data-column__item"
      >

        {props.data.name}
        <span className={`disc__data-column__item__value--${props.data.type.toLocaleLowerCase()}`}>{props.data.value}</span>

      </p>

    </li>
  );
}

function DiscItemFooter(props) {
  return (
    <li>

      <p
        className={`disc__data-column__footer-item--${props.type}`}
      >

        Total:
        <span className={`disc__data-column__footer-item__value--${props.type}`}>{props.value}</span>

      </p>

    </li>
  );
}

class Disc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getDiscData() {
    const discData = [];

    const dataD = [];
    const dataI = [];
    const dataS = [];
    const dataC = [];

    let totalD = 0;
    let totalI = 0;
    let totalS = 0;
    let totalC = 0;

    for(let data of this.props.student.disc.answers) {
      switch (data.type.toLocaleLowerCase()) {
        case 'd':
          dataD.push(
            <DiscItem
              key={`disc_data_item_${data.id}`}
              data={data}
            />
          );
          totalD += data.value;
          break;
        case 'i':
          dataI.push(
            <DiscItem
              key={`disc_data_item_${data.id}`}
              data={data}
            />
          );
          totalI += data.value;
          break;
        case 's':
          dataS.push(
            <DiscItem
              key={`disc_data_item_${data.id}`}
              data={data}
            />
          );
          totalS += data.value;
          break;
        case 'c':
          dataC.push(
            <DiscItem
              key={`disc_data_item_${data.id}`}
              data={data}
            />
          );
          totalC += data.value;
          break;
        default:

      }
    }

    discData.push(
      <DiscListColumn
        key="disc_data_column_d"
        title="Dominância"
        type="d"
        data={dataD}
        footer={
          <DiscItemFooter
            type="d"
            value={totalD}
            key="disc_data_item_footer_d"
          />
        }
      />
    );

    discData.push(
      <DiscListColumn
        key="disc_data_column_i"
        title="Influência"
        type="i"
        data={dataI}
        footer={
          <DiscItemFooter
            type="i"
            value={totalI}
            key="disc_data_item_footer_i"
          />
        }
      />
    );

    discData.push(
      <DiscListColumn
        key="disc_data_column_s"
        title="Estabilidade"
        type="s"
        data={dataS}
        footer={
          <DiscItemFooter
            type="s"
            value={totalS}
            key="disc_data_item_footer_s"
          />
        }
      />
    );

    discData.push(
      <DiscListColumn
        key="disc_data_column_c"
        title="Complacência"
        type="c"
        data={dataC}
        footer={
          <DiscItemFooter
            type="c"
            value={totalC}
            key="disc_data_item_footer_c"
          />
        }
      />
    );

    return discData;
  }

  getGraphData() {
    return this.props.student.disc.total_scores.map((score) => BarDataPoint(score.value, score.type.toLocaleUpperCase(), getDiscColor(score.type)));
  }

  getGraphHeight() {
    if(this.state.screenWidth <= 380) {
      return 170;
    }

    if(this.state.screenWidth <= 600) {
      return 200;
    }

    return 250;
  }

  getDiscClassification() {
    const elementD = (
      <p
        className="disc__classification__text--d"
        key="disc_classification_d"
      >
        {this.state.screenWidth > 420 ? 'Dominância' : 'D'}
      </p>
    );
    const elementI = (
      <p
        className="disc__classification__text--i"
        key="disc_classification_i"
      >
        {this.state.screenWidth > 420 ? 'Influência' : 'I'}
      </p>);
    const elementS = (
      <p
        className="disc__classification__text--s"
        key="disc_classification_s"
      >
        {this.state.screenWidth > 420 ? 'Estabilidade' : 'S'}

      </p>);
    const elementC = (
      <p
        className="disc__classification__text--c"
        key="disc_classification_c"
      >
        {this.state.screenWidth > 420 ? 'Complacência' : 'C'}

      </p>);

    let classification = [elementD];

    let currentScore = this.props.student.disc.total_d;

    if(currentScore < this.props.student.disc.total_i) {
      classification = [elementI];
      currentScore = this.props.student.disc.total_i;
    }
    else if(currentScore === this.props.student.disc.total_i) {
      classification.push(elementI);
    }

    if(currentScore < this.props.student.disc.total_s) {
      classification = [elementS];
      currentScore = this.props.student.disc.total_s;
    }
    else if(currentScore === this.props.student.disc.total_s) {
      classification.push(elementS);
    }

    if(currentScore < this.props.student.disc.total_c) {
      classification = [elementC];
      currentScore = this.props.student.disc.total_c;
    }
    else if(currentScore === this.props.student.disc.total_c) {
      classification.push(elementC);
    }

    return classification;
  }

  render() {
    return (
      <React.Fragment>

        <div className="disc__classification">

          <h2 className="disc__classification__label">Classificação:</h2>
          {this.getDiscClassification()}

        </div>

        <HorizontalBarGraph
          className="disc__graph"
          title="DISC"
          xLabel="Classificação"
          yLabel="Pontuação"
          data={this.getGraphData()}
          height={this.getGraphHeight()}
        />

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fas fa-database"></i>}
          text="Dados"
        />

        <div className="disc__data-container">

          {this.getDiscData()}

        </div>

      </React.Fragment>
    );
  }
}

export default Disc;
