import React from 'react';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import './expandable_section.scss';

class ExpandableSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  render() {
    return (
      <section className="expandable-section">

        <header
          className="expandable-section__header"
          onClick={() => this.setState({visible: !this.state.visible})}
        >

          <h3 className="expandable-section__header__text">
            <i className={`${this.props.iconClass} expandable-section__header__text-icon`}></i>
            {this.props.title}
          </h3>

          {this.state.visible ?
            <i className="fas fa-chevron-down expandable-section__header__visible-icon"></i>:
            <i className="fas fa-chevron-up expandable-section__header__visible-icon"></i>
          }

        </header>

        <VerticalAccordionContainer
          className="vertical-accordion-container expandable-section__content"
          pose={this.state.visible ? 'verticalOpen' : 'verticalClosed'}>

          <div className="vertical-accordion-container expandable-section__content-wrapper">

            {this.props.children}

          </div>

        </VerticalAccordionContainer>

      </section>
    );
  }
}

export default ExpandableSection;
