import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel} from '../../utils/functions';
import FeedbackData from './feedback_data';

class FeedbackEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: {
        name: "",
        email: "",
        phone: "",
        gender: "",
        answers: []
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const feedback = await getModel(`${routes.FEEDBACK_GET_API}${this.props.match.params.feedbackId}`);

    if(feedback) {
      this.setState({
        feedback: {...this.state.feedback, ...feedback},
        loading: false
      });
    }
    else {
      this.props.history.replace(routes.FEEDBACK_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.feedback, [name]: value};

    this.setState({
      feedback: newData
    });
  }

  inputsAreValid() {
    return true;
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FEEDBACK_LIST_PATH,
            text: "Listar pesquisas"
          },
          {
            path: `${routes.FEEDBACK_EDIT_PATH}${this.props.match.params.feedbackId}`,
            text: "Visualizar entrada"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Visualizar entrada"
        loading={this.state.loading}
      >

        <FeedbackData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          feedback={this.state.feedback}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FEEDBACK_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default FeedbackEdit;
