import React from 'react';
import './performance_report.scss';
import * as routes from '../../constants';
import ContentFrame from '../content_frame';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getAsLocalDate} from '../../utils/functions';
import {PERSONAL_TRAINING_PATH} from '../student/constants';
import SimpleBarGraph, {BarPoint} from '../../graphs/simple_bar_graph';

class PersonalTrainingPerformanceReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      training_data: [],
      loadingData: true,
      screenWidth: window.innerWidth,
      graphContainerWidth: null
    };
  }

  async getTrainingData() {
    return await getModels(`${routes.PERSONAL_TRAINING_DATA_GET_API.replace('{student_id}', this.props.match.params.studentId)}?training_day_id=${this.props.match.params.trainingDayId}`);
  }

  async componentDidMount() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let training_data = this.getTrainingData();

    training_data = await training_data;

    if(training_data && training_data.length > 0) {
      update.training_data = training_data;

      update.training_data.sort((a, b) => a.date.localeCompare(b.date));

      this.setState(update);

      this.resizeListener = () => this.updateSize();

      window.addEventListener("resize", this.resizeListener);
    }
    else {
      this.props.history.replace(routes.DESKTOP_PATH);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getReportData() {
    const timeData = [];
    const workoutTimeData = [];
    const repsData = [];
    const pseData = [];
    const weightData = [];

    for(let entry of this.state.training_data) {
      const total_seconds = entry.total_seconds || 0;
      const workout_time = entry.workout_time || 0;
      const total_repetitions = entry.total_repetitions || 0;
      const total_weight = entry.total_weight || 0;
      const pse = entry.pse || 0;
      const difficulty = entry.difficulty || 'Não especificado';

      const index = difficulty;

      timeData.push(new BarPoint(total_seconds, getAsLocalDate(entry.date).toLocaleDateString(), `${this.getTime(total_seconds)} - ${index}`));
      workoutTimeData.push(new BarPoint(workout_time, getAsLocalDate(entry.date).toLocaleDateString(), `${this.getTime(workout_time)} - ${index}`));
      repsData.push(new BarPoint(total_repetitions, getAsLocalDate(entry.date).toLocaleDateString(), `${total_repetitions} - ${index}`));
      weightData.push(new BarPoint(total_weight, getAsLocalDate(entry.date).toLocaleDateString(), `${total_weight} - ${index}`));
      pseData.push(new BarPoint(pse, getAsLocalDate(entry.date).toLocaleDateString(), `${pse} - ${index}`));
    }

    return {
      timeData,
      workoutTimeData,
      repsData,
      weightData,
      pseData
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getTime(value) {
    const formater = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 });

    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formater.format(minutes)}:${formater.format(seconds)}`;
  }

  render() {
    const reportData = this.getReportData();

    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: `${routes.STUDENT_EDIT_PATH}${this.props.match.params.studentId}${PERSONAL_TRAINING_PATH}`,
            text: "Personal training"
          },
          {
            path: `${routes.PERSONAL_TRAINING_PERFORMANCE_GRAPH_PATH.replace('{student_id}', this.props.match.params.studentId)}${this.props.match.params.trainingDayId}`,
            text: "Comparativo de treino"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Comparativo de treino"
        loading={this.state.loadingData}
      >

        <div className="personal-training-performance-report__wrapper">

          <HorizontalRule />

          <DefaultSubSectionTitle
            icon={<i className="fas fa-dumbbell"></i>}
            text="Kilagem total média"
          />

          <SimpleBarGraph
            className="personal-training-performance-report__graph"
            name="Kilagem total média"
            data={reportData.weightData}
            height={this.getDefaultGraphHeight()}
            barColor="#62b94d"
            labelFormatter={(value) => value}
          />

          <HorizontalRule />

          <DefaultSubSectionTitle
            icon={<i className="fas fa-stopwatch"></i>}
            text="Tempo total"
          />

          <SimpleBarGraph
            className="personal-training-performance-report__graph"
            name="Tempo total"
            data={reportData.timeData}
            height={this.getDefaultGraphHeight()}
            barColor="#e65a5a"
            labelFormatter={(value) => this.getTime(value)}
          />

          <HorizontalRule />

          <DefaultSubSectionTitle
            icon={<i className="fas fa-stopwatch"></i>}
            text="Tempo do workout"
          />

          <SimpleBarGraph
            className="personal-training-performance-report__graph"
            name="Tempo do workout"
            data={reportData.workoutTimeData}
            height={this.getDefaultGraphHeight()}
            barColor="#efe078"
            labelFormatter={(value) => this.getTime(value)}
          />

          <HorizontalRule />

          <DefaultSubSectionTitle
            icon={<i className="fas fa-dumbbell"></i>}
            text="Repetições"
          />

          <SimpleBarGraph
            className="personal-training-performance-report__graph"
            name="Repetições"
            data={reportData.repsData}
            height={this.getDefaultGraphHeight()}
            barColor="#4d86ce"
            labelFormatter={(value) => value}
          />

          <HorizontalRule />

          <DefaultSubSectionTitle
            icon={<i className="fas fa-chart-line"></i>}
            text="Percepção Subjetiva de Esforço (PSE)"
          />

          <SimpleBarGraph
            className="personal-training-performance-report__graph"
            name="PSE"
            data={reportData.pseData}
            height={this.getDefaultGraphHeight()}
            barColor="#b678ef"
            labelFormatter={(value) => value}
          />

        </div>

      </ContentFrame>
    );
  }
}

export default PersonalTrainingPerformanceReport;
