import React from 'react';
import { Link } from 'react-router-dom';
import './operational_task_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {OPERATIONAL_TASK_STATUS_PENDING,
        OPERATIONAL_TASK_STATUS_INITIATED,
        OPERATIONAL_TASK_STATUS_FINISHED,
        OPERATIONAL_TASK_ROLE_MANAGEMENT,
        OPERATIONAL_TASK_ROLE_COACHES,
        OPERATIONAL_TASK_ROLE_RECEPTIONISTS} from '../../constants';

class OperationalTaskData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isEditMode() {
    return this.props.operational_task.id && this.props.operational_task.id > 0;
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getPriorityOptions() {
    return [
      SelectOption(1, 'Normal'),
      SelectOption(2, 'Alta'),
      SelectOption(3, 'Urgente')
    ];
  }

  getStatusOptions() {
    const options = [
      OPERATIONAL_TASK_STATUS_PENDING,
      OPERATIONAL_TASK_STATUS_INITIATED,
      OPERATIONAL_TASK_STATUS_FINISHED
    ];

    return [
      ...options.map((service) => SelectOption(service, service))
    ];
  }

  getRoleOptions() {
    const options = [
      OPERATIONAL_TASK_ROLE_MANAGEMENT,
      OPERATIONAL_TASK_ROLE_COACHES,
      OPERATIONAL_TASK_ROLE_RECEPTIONISTS
    ];

    return [
      SelectOption('', 'Não vinculado'),
      ...options.map((service) => SelectOption(service, service))
    ];
  }

  getResponsibleOptions() {
    let options = this.props.users.map((entry) => SelectOption(entry.id, entry.name));

    return [
      SelectOption('', 'Não vinculado'),
      ...options
    ];
  }

  getServiceOptions() {
    return [
      SelectOption('', 'Não especificado'),
      ...this.props.services.map((service) => SelectOption(service, service))
    ];
  }

  getTargetTypeOptions() {
    const options = [
      SelectOption(1, 'Colaborador'),
      SelectOption(2, 'Serviço'),
      SelectOption(3, 'Função'),
    ];

    if (this.props.target_type === null) {
      return [
        SelectOption('', 'Selecione uma opção'),
        ...options
      ];
    }

    return options;
  }

  getTargetInput(showAll=false) {
    if (showAll || this.props.target_type === 4) {
      return (
        <React.Fragment>

          <DefaultInput
            name="responsible_id"
            isHighlighted={this.isHighlighted("responsible_id")}
            label="Responsável"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.operational_task.responsible_id || ''}
            options={this.getResponsibleOptions()}
          />

          <HalfWrapper>

            <DefaultInput
              name="target_service"
              isHighlighted={this.isHighlighted("target_service")}
              label="Serviço vinculado"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.operational_task.target_service || ''}
              options={this.getServiceOptions()}
            />

            <DefaultInput
              name="target_role"
              isHighlighted={this.isHighlighted("target_role")}
              label="Setor"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.operational_task.target_role || ''}
              options={this.getRoleOptions()}
            />

          </HalfWrapper>

        </React.Fragment>
      );
    }

    if (this.props.target_type === 1) {
      return (
        <DefaultInput
          name="responsible_id"
          isHighlighted={this.isHighlighted("responsible_id")}
          label="Responsável"
          type="select"
          handleInputChange={this.props.handleInputChange}
          value={this.props.operational_task.responsible_id || ''}
          options={this.getResponsibleOptions()}
        />
      );
    }
    else if (this.props.target_type === 2) {
      return (
        <DefaultInput
          name="target_service"
          isHighlighted={this.isHighlighted("target_service")}
          label="Serviço vinculado"
          type="select"
          handleInputChange={this.props.handleInputChange}
          value={this.props.operational_task.target_service || ''}
          options={this.getServiceOptions()}
        />
      );
    }
    else if (this.props.target_type === 3) {
      return (
        <DefaultInput
          name="target_role"
          isHighlighted={this.isHighlighted("target_role")}
          label="Setor"
          type="select"
          handleInputChange={this.props.handleInputChange}
          value={this.props.operational_task.target_role || ''}
          options={this.getRoleOptions()}
        />
      );
    }

    return null;
  }

  render() {
    let variablesSet = 0;

    if (this.props.operational_task.responsible_id !== null) {
      variablesSet += 1;
    }
    if (this.props.operational_task.target_service !== null) {
      variablesSet += 1;
    }
    if (this.props.operational_task.target_role !== null) {
      variablesSet += 1;
    }

    return (
      <DefaultSection
        className="operation-task-data"
        title="Dados da pendência"
      >

        <div className="operation-task-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="operation-task-data__input-container">

          {(variablesSet <= 1 && this.props.target_type !== 4) &&
            <DefaultInput
              name="target_type"
              isHighlighted={this.isHighlighted("target_type")}
              label="Selecione o tipo do responsável pela pendência"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.target_type || ''}
              options={this.getTargetTypeOptions()}
            />
          }

          {this.getTargetInput(variablesSet > 1)}

          <HalfWrapper>

            <DefaultInput
              name="task"
              isHighlighted={this.isHighlighted("task")}
              label="Pendência"
              type="text"
              placeholder="Breve descrição"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.operational_task.task}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="priority"
              isHighlighted={this.isHighlighted("priority")}
              label="Prioridade"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.operational_task.priority || ''}
              options={this.getPriorityOptions()}
            />

          </HalfWrapper>

          <DefaultInput
            name="status"
            isHighlighted={this.isHighlighted("status")}
            label="Situação"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.operational_task.status || ''}
            options={this.getStatusOptions()}
          />

          <DefaultInput
            name="commentary"
            isHighlighted={this.isHighlighted("commentary")}
            label="Comentário/Observação"
            type="textarea"
            placeholder="Comentário / Observação"
            rows="6"
            handleInputChange={this.props.handleInputChange}
            value={this.props.operational_task.commentary || ''}
          />

        </div>

        <HorizontalRule />

        <div className="payment-device-data__buttons-container">

          <button
            className="payment-device-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="payment-device-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default OperationalTaskData;
