import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import ModelTable, {Property} from '../../utils/model_table';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR,
        TRAINING_EXERCISE_PARAMETER_TYPE_RANGE,
        TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        SERVICE_HOME_TRAINING,
        TARGET_SERVICE_COLOR_MAP} from '../../constants';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import ContextPopup from '../../components/context_popup';
import * as permissions from '../../permissions';
import './training_group_exercise_association_data.scss';


class TrainingGroupExerciseAssociationData extends React.Component {
  constructor(props) {
    super(props);

    const targetServiceFilter = this.props.training_period.target_service ? this.props.training_period.target_service.toString() : '';
    let exerciseCategoryFilter = this.props.training_exercise.category_id ? this.props.training_exercise.category_id.toString() : '';

    if (targetServiceFilter.length > 0 && exerciseCategoryFilter.length > 0) {
      const exercise_category_id = parseInt(exerciseCategoryFilter);

      if(!this.props.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === targetServiceFilter);
      })) {
        exerciseCategoryFilter = '';
      }
    }

    this.state = {
      targetServiceFilter,
      exerciseCategoryFilter,
      exerciseFunctionFilter: this.props.training_exercise.function_id ? this.props.training_exercise.function_id.toString() : '',
      muscleGroupFilter: this.props.training_exercise.muscle_group_id ? this.props.training_exercise.muscle_group_id.toString() : '',
      replaceExerciseSectionVisible: false,
      popupContent: null,
      popupTarget: null,
    };
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'note') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.association.id && this.props.association.id > 0;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    if (name === 'targetServiceFilter' && value.length > 0 && this.state.exerciseCategoryFilter.length > 0) {
      const exercise_category_id = parseInt(this.state.exerciseCategoryFilter);

      if(!this.props.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === value);
      })) {
        update.exerciseCategoryFilter = '';
      }
    }

    this.setState(update);
  }

  getIntensityOptions() {
    return [
      SelectOption('', 'Valor aberto'),
      ...this.props.intensity_parameters.map((parameter) => SelectOption(parameter.name, parameter.name))
    ];
  }

  getDifficultyOptions() {
    return [
      SelectOption('', 'Valor aberto'),
      ...this.props.difficulty_parameters.map((parameter) => SelectOption(parameter.name, parameter.name))
    ];
  }

  getParameterTypeOptions() {
    const types = [TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR, TRAINING_EXERCISE_PARAMETER_TYPE_RANGE];

    return [
      ...types.map((type) => SelectOption(type, type))
    ];
  }

  getExerciseOptions() {
    return [
      ...this.props.exercises.map((exercise) => SelectOption(exercise.id, exercise.name))
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.props.services,
      SERVICE_HOME_TRAINING
    ];

    return [
      SelectOption('', 'Todos os serviços'),
      ...services.map((service) => SelectOption(service, service)),
    ];
  }

  getExerciseCategoryOptions() {
    let filteredExerciseCategories = this.props.exerciseCategories;

    if (this.state.targetServiceFilter.length > 0) {
      filteredExerciseCategories = filteredExerciseCategories.filter((entry) => entry.target_service === null || entry.target_service === this.state.targetServiceFilter);
    }

    return [
      SelectOption('', 'Todos os pilares'),
      ...filteredExerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
    ];
  }

  getExerciseFunctionOptions() {
    return [
      SelectOption('', 'Todas as funções'),
      ...this.props.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
    ];
  }

  getMuscleGroupOptions() {
    return [
      SelectOption('', 'Todos os agrupamentos'),
      ...this.props.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.id, muscleGroup.name))
    ];
  }

  getReferenceLinkText(entry) {
    if(!entry.reference_url) {
      return null;
    }

    return (
      <a
        className="model-table__default-link-button"
        href={entry.reference_url}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i>

      </a>
    );
  }

  getExerciseProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => (
          <p className="training-group-exercise-association-data__exercise-name">

              {entry.name}

              {entry.description && (
                <i
                  className="fa-solid fa-circle-info training-group-exercise-association-data__exercise-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="training-group-exercise-association-data__exercise-info">{entry.description}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.name,
        getCellStyle: (entry) => {
          if (entry.target_service in TARGET_SERVICE_COLOR_MAP) {
            return {background: TARGET_SERVICE_COLOR_MAP[entry.target_service]};
          }

          return null;
        }
      }),
    ];

    properties.push(Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>));
    properties.push(Property('category_name', 'Pilar', <i className="fas fa-tag"></i>));
    properties.push(Property('function_name', 'Função', <i className="fas fa-box"></i>));
    properties.push(Property('reference_url', 'URL', <i className="fas fa-link"></i>, {
      getDataText: (entry) => this.getReferenceLinkText(entry),
      getFilterText: (entry) => ''
    }));

    return properties;
  }

  getExerciseActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="training-group-exercise-association-data__activate-button"
          onClick={() => this.props.onUpdateExercise(entry)}
          disabled={entry.id === this.props.association.exercise_id}
        >

          {entry.id === this.props.association.exercise_id ? (
            <React.Fragment>

              <i className="far fa-check-square training-group-exercise-association-data__activate-button__icon"></i>
              Selecionado

            </React.Fragment>
          ) : (
            <React.Fragment>

              <i className="fas fa-exchange-alt training-group-exercise-association-data__activate-button__icon"></i>
              Alterar

            </React.Fragment>
          )}


        </button>

      </div>
    );
  }

  getExerciseList() {
    return this.props.exercises.filter((entry) => {
      let include = true;

      if (entry.target_service !== null && this.state.targetServiceFilter.length > 0 && entry.target_service !== this.state.targetServiceFilter) {
        include = false;
      }
      if (this.state.exerciseCategoryFilter.length > 0 && entry.category_id !== parseInt(this.state.exerciseCategoryFilter)) {
        include = false;
      }
      if (this.state.exerciseFunctionFilter.length > 0 && entry.function_id !== parseInt(this.state.exerciseFunctionFilter)) {
        include = false;
      }
      if (this.state.muscleGroupFilter.length > 0 && entry.muscle_group_id !== parseInt(this.state.muscleGroupFilter)) {
        include = false;
      }

      return include;
    });
  }

  getIntensityInput(repetitionNumber, cycleNumber=null) {
    let inputName = `intensity_value:${repetitionNumber-1}`;
    let inputValue = this.props.association.intensity_value[repetitionNumber-1];

    if(cycleNumber !== null) {
      inputName += `:${cycleNumber-1}`;
      inputValue = inputValue[cycleNumber-1];
    }

    if(!this.props.association.intensity_name) {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label="Repetições"
          type="text"
          placeholder="Valor de repetições"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.intensity_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else if(this.props.association.intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR) {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label="Repetições"
          type="number"
          placeholder="Valor de repetições"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.intensity_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else if(this.props.association.intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
      return (
        <HalfWrapper>

          <DefaultInput
            name={`${inputName}:0`}
            isHighlighted={this.isHighlighted(`${inputName}:0`)}
            label="Repetições mínima"
            type="number"
            placeholder="Repetições mínima"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={inputValue[0]}
            autoComplete="off"
            suffix={this.props.association.intensity_unit}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
          />

          <DefaultInput
            name={`${inputName}:1`}
            isHighlighted={this.isHighlighted(`${inputName}:1`)}
            label="Repetições máxima"
            type="number"
            placeholder="Repetições máxima"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={inputValue[1]}
            autoComplete="off"
            suffix={this.props.association.intensity_unit}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
          />

        </HalfWrapper>
      );
    }

    return null;
  }

  getDifficultInput(repetitionNumber) {
    let inputName = `difficult_value:${repetitionNumber-1}`;
    let inputValue = this.props.association.difficult_value[repetitionNumber-1];

    if(!this.props.association.difficult_name) {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label={`Dificuldade${this.props.training_day_group_association.group.has_additional_difficulties ? ' (normal)' : ''}`}
          type="text"
          placeholder="Valor de dificuldade"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label={`${this.props.association.difficult_name}${this.props.training_day_group_association.group.has_additional_difficulties ? ' (normal)' : ''}`}
          type="number"
          placeholder="Entre com o valor"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getIntermediateDifficultInput(repetitionNumber) {
    let inputName = `difficult_intermediate_value:${repetitionNumber-1}`;
    let inputValue = this.props.association.difficult_intermediate_value[repetitionNumber-1];

    if(!this.props.association.difficult_name) {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label="Dificuldade (*)"
          type="text"
          placeholder="Valor de dificuldade"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label={`${this.props.association.difficult_name} (*)`}
          type="number"
          placeholder="Entre com o valor"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getAdvancedDifficultInput(repetitionNumber) {
    let inputName = `difficult_advanced_value:${repetitionNumber-1}`;
    let inputValue = this.props.association.difficult_advanced_value[repetitionNumber-1];

    if(!this.props.association.difficult_name) {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label="Dificuldade (avançado)"
          type="text"
          placeholder="Valor de dificuldade"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
    else {
      return (
        <DefaultInput
          name={inputName}
          isHighlighted={this.isHighlighted(inputName)}
          label={`${this.props.association.difficult_name} (avançado)`}
          type="number"
          placeholder="Entre com o valor"
          min="0"
          step="1"
          handleInputChange={this.props.handleInputChange}
          value={inputValue}
          autoComplete="off"
          suffix={this.props.association.difficult_unit}
          onKeyDown={(event) => this.handleKeyDown(event)}
          onFocus={(event) => event.target.select()}
          disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
        />
      );
    }
  }

  getRepetitionConfiguration(repetitionNumber) {
    if(this.props.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
      return (
        <React.Fragment>

          {this.getIntensityInput(repetitionNumber)}

          {this.getDifficultInput(repetitionNumber)}

          {this.props.training_day_group_association.group.has_additional_difficulties &&
            <React.Fragment>

              {this.getIntermediateDifficultInput(repetitionNumber)}

              {this.getAdvancedDifficultInput(repetitionNumber)}

            </React.Fragment>
          }

        </React.Fragment>
      );
    }
    else if(this.props.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
      const configurations = [];

      for(let i=1; i <= this.props.training_day_group_association.cycle_number; ++i) {
        configurations.push(
          <div
            className="training-group-exercise-association-data__cycle-configuration"
            key={`training_group_exercise_association_repetition_configuration:${repetitionNumber}:cycle:${i}`}
          >

            <p className="training-group-exercise-association-data__cycle-configuration__detail">{`Série ${i}`}</p>

            <div className="training-group-exercise-association-data__cycle-configuration__input-wrapper">

              {this.getIntensityInput(repetitionNumber, i)}

            </div>

          </div>
        );
      }

      return (
        <React.Fragment>

          {this.getDifficultInput(repetitionNumber)}

          {this.props.training_day_group_association.group.has_additional_difficulties &&
            <React.Fragment>

              {this.getIntermediateDifficultInput(repetitionNumber)}

              {this.getAdvancedDifficultInput(repetitionNumber)}

            </React.Fragment>
          }

          <HorizontalRule />

          {configurations}

        </React.Fragment>
      );
    }
    else if(this.props.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
      return (
        <React.Fragment>

          <DefaultInput
            name={`intensity_value_step:${repetitionNumber-1}`}
            isHighlighted={this.isHighlighted(`intensity_value_step:${repetitionNumber-1}`)}
            label="Incremento de repetições"
            type="number"
            placeholder="Incremento de repetições"
            min="0"
            step="1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.association.intensity_value_step[repetitionNumber-1]}
            autoComplete="off"
            suffix={this.props.association.intensity_unit}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
          />

          <HalfWrapper>

            {this.getIntensityInput(repetitionNumber)}

            <DefaultInput
              id={`default_input_intensity_unit:${repetitionNumber-1}`}
              name="intensity_unit"
              isHighlighted={this.isHighlighted("intensity_unit")}
              label="Unidade de repetições"
              type="text"
              placeholder="Entre com o nome"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.intensity_unit || ''}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
            />

          </HalfWrapper>

          {this.getDifficultInput(repetitionNumber)}

          {this.props.training_day_group_association.group.has_additional_difficulties &&
            <React.Fragment>

              {this.getIntermediateDifficultInput(repetitionNumber)}

              {this.getAdvancedDifficultInput(repetitionNumber)}

            </React.Fragment>
          }

        </React.Fragment>
      );
    }

    return null;
  }

  getConfigurations() {
    if(!this.props.training_period.repetition_count) {
      return null;
    }

    if(this.props.training_day_group_association.group.is_phase_constant || this.props.applyPhaseConstant) {
      return this.getRepetitionConfiguration(1);
    }

    const configurations = [];

    for(let i=1; i<=this.props.training_period.repetition_count; ++i) {
      let repetitionColor = '#ffffff';

      if(i < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
        repetitionColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[i-1];
      }
      configurations.push(
        <div
          className="training-group-exercise-association-data__configuration"
          key={`training_group_exercise_association_repetition_configuration:${i}`}
        >

          <p className="training-group-exercise-association-data__configuration__detail" style={{background: repetitionColor}}>{`Fase ${i}`}</p>

          <div className="training-group-exercise-association-data__configuration__input-wrapper">

            {this.getRepetitionConfiguration(i)}

          </div>

        </div>
      );
    }

    return configurations;
  }

  mayResetFilterInputs() {
    if(this.state.exerciseCategoryFilter.length > 0 ||
       this.state.exerciseFunctionFilter.length > 0 ||
       this.state.muscleGroupFilter.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      exerciseCategoryFilter: '',
      exerciseFunctionFilter: '',
      muscleGroupFilter: ''
    });
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <DefaultSection
          className="training-group-exercise-association-data"
          title="Dados do exercício"
        >

          <div className="training-group-exercise-association-data__warning-container">

            <WarningMessage
              message={this.props.warningMessage}
              onClose={this.props.onCloseWarning}
              visible={this.props.showWarningMessage}
            />

          </div>

          <div className="training-group-exercise-association-data__input-container">

            {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) &&
              <section className="training-group-exercise-association-data__replace-exercise">

                <header
                  className="training-group-exercise-association-data__replace-exercise__header"
                  onClick={() => this.setState({replaceExerciseSectionVisible: !this.state.replaceExerciseSectionVisible})}
                >

                  <h3 className="training-group-exercise-association-data__replace-exercise__header__text">
                    <i className="fas fa-exchange-alt training-group-exercise-association-data__replace-exercise__header__text-icon"></i>
                    Alterar exercício
                  </h3>

                  {this.state.replaceExerciseSectionVisible ?
                    <i className="fas fa-chevron-down training-group-exercise-association-data__replace-exercise__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up training-group-exercise-association-data__replace-exercise__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container training-group-exercise-association-data__replace-exercise__content"
                  pose={this.state.replaceExerciseSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                  <div className="vertical-accordion-container training-group-exercise-association-data__replace-exercise__content-wrapper">

                    <div className="training-group-exercise-association-data__exercise-filters">

                      <header className="training-group-exercise-association-data__exercise-filters__header">

                        <h4 className="training-group-exercise-association-data__exercise-filters__header__text">Filtros</h4>

                      </header>

                      <div className="training-group-exercise-association-data__exercise-filters__inputs">

                        <div className="training-group-exercise-association-data__exercise-filters__inputs-wrapper">

                          <DefaultInput
                            name="targetServiceFilter"
                            label="Serviço alvo"
                            type="select"
                            handleInputChange={(event) => this.handleInputChange(event)}
                            value={this.state.targetServiceFilter || ''}
                            options={this.getServiceOptions()}
                          />

                          <HalfWrapper>

                            <DefaultInput
                              name="exerciseCategoryFilter"
                              label="Pilar"
                              labelMessage={this.state.targetServiceFilter.length > 0 ? 'Filtrado pelo serviço' : null}
                              type="select"
                              value={this.state.exerciseCategoryFilter || ''}
                              handleInputChange={(event) => this.handleInputChange(event)}
                              options={this.getExerciseCategoryOptions()}
                            />

                            <DefaultInput
                              name="exerciseFunctionFilter"
                              label="Função"
                              type="select"
                              value={this.state.exerciseFunctionFilter || ''}
                              handleInputChange={(event) => this.handleInputChange(event)}
                              options={this.getExerciseFunctionOptions()}
                            />

                          </HalfWrapper>

                          <DefaultInput
                            name="muscleGroupFilter"
                            label="Agrupamento muscular"
                            type="select"
                            value={this.state.muscleGroupFilter || ''}
                            handleInputChange={(event) => this.handleInputChange(event)}
                            options={this.getMuscleGroupOptions()}
                          />

                        </div>

                        <button
                          className="training-group-exercise-association-data__exercise-filters__reset-button"
                          onClick={() => this.resetFilterInputs()}
                          disabled={!this.mayResetFilterInputs()}
                        >

                          <i className="fas fa-times"></i>

                        </button>

                      </div>

                    </div>

                    <ModelTable
                      properties={this.getExerciseProperties()}
                      getActions={(entry) => this.getExerciseActions(entry)}
                      data={this.getExerciseList()}
                      initialOrderBy="name"
                    >
                    </ModelTable>

                  </div>

                </VerticalAccordionContainer>

              </section>
            }

            <HorizontalRule />

            <DefaultInput
              name="exercise_id"
              isHighlighted={this.isHighlighted("exercise_id")}
              label="Nome do exercício"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.exercise_id}
              options={this.getExerciseOptions()}
              disabled={true}
            />

            {/* <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome do exercício"
              type="text"
              placeholder="Nome do exercício"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={true}
            /> */}

            <HalfWrapper>

              <DefaultInput
                name="category_name"
                isHighlighted={this.isHighlighted("category_name")}
                label="Pilar"
                type="text"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.training_exercise.category_name}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={true}
              />

              <DefaultInput
                name="function_name"
                isHighlighted={this.isHighlighted("function_name")}
                label="Função"
                type="text"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.training_exercise.function_name}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={true}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="execution_method"
                isHighlighted={this.isHighlighted("execution_method")}
                label="Método de execução"
                type="text"
                placeholder="Método de execução"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.training_day_group_association.execution_method}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={true}
              />

              {this.props.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL &&
                <DefaultInput
                  name="cycle_number"
                  isHighlighted={this.isHighlighted("cycle_number")}
                  label="Número de séries"
                  type="text"
                  placeholder="Número de séries"
                  maxLength="128"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.training_day_group_association.cycle_number}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  disabled={true}
                />
              }

            </HalfWrapper>

            <DefaultInput
              name="reference_url"
              isHighlighted={this.isHighlighted("reference_url")}
              label="URL de referência"
              type="text"
              placeholder="URL"
              maxLength="512"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.reference_url || ''}
              autoComplete="off"
              disabled={true}
            />

            <HorizontalRule />

            {/* <HalfWrapper>

              <DefaultInput
                name="intensity_name"
                isHighlighted={this.isHighlighted("intensity_name")}
                label="Parâmetro de intensidade"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.association.intensity_name || ''}
                options={this.getIntensityOptions()}
              />

              {this.props.association.intensity_name &&
                <DefaultInput
                  name="intensity_value_type"
                  isHighlighted={this.isHighlighted("intensity_value_type")}
                  label="Tipo do parâmetro de intensidade"
                  type="select"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.association.intensity_value_type}
                  options={this.getParameterTypeOptions()}
                />
              }

            </HalfWrapper>

            <DefaultInput
              name="difficult_name"
              isHighlighted={this.isHighlighted("difficult_name")}
              label="Parâmetro de dificuldade"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.difficult_name || ''}
              options={this.getDifficultyOptions()}
            /> */}

            <DefaultInput
              name="attachMaxReference"
              isHighlighted={this.isHighlighted('attachMaxReference')}
              label="Vincular com PR:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.attachMaxReference}
              neutralInactiveColor={true}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
            />

            <DefaultInput
              name="easier_option"
              isHighlighted={this.isHighlighted("easier_option")}
              label="Adaptação"
              type="text"
              placeholder="Nome do exercício de adaptação"
              maxLength="256"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.easier_option || ''}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
            />

            <DefaultInput
              name="note"
              isHighlighted={this.isHighlighted("note")}
              label="Observações"
              type="textarea"
              placeholder="Observações do exercício"
              rows="3"
              handleInputChange={this.props.handleInputChange}
              value={this.props.association.note || ''}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
            />

            <DefaultSubSectionTitle
              className="training-group-exercise-association-data__sub-section"
              icon={<i className="fas fa-cog"></i>}
              text="Configuração de execução"
            />

            {(!this.props.training_day_group_association.group.is_phase_constant && this.props.isDefaultUnit) &&
              <DefaultInput
                name="applyPhaseConstant"
                label="Constante entre fases:"
                type="toggle"
                isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.applyPhaseConstant}
                neutralInactiveColor={true}
                disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)}
              />
            }

            <div className="training-group-exercise-association-data__configurations-container">

              {this.getConfigurations()}

            </div>

          </div>

          <HorizontalRule />

          <div className="training-group-exercise-association-data__buttons-container">

            {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) &&
              <button
                className="training-group-exercise-association-data__save-button"
                disabled={!this.props.enableSave}
                onClick={this.props.onSave}
              >

                Salvar

              </button>
            }

            <Link
              className="training-group-exercise-association-data__cancel-button"
              to={this.props.onCancelPath}
            >

              {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_GROUP_EXERCISE_ASSOCIATION_PERMISSION_ID)) ?
                'Cancelar':
                'Voltar'
              }

            </Link>

          </div>

        </DefaultSection>

      </React.Fragment>
    );
  }
}

export default TrainingGroupExerciseAssociationData;
