import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection from '../../../utils/default_section';
import {getModels, deleteModel} from '../../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';
import * as permissions from '../../../permissions';
import './customer_feedback_target_list.scss';

class CustomerFeedbackTargetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: true,
      customer_feedback_targets: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getCustomerFeedbackTargets() {
    return await getModels(routes.CUSTOMER_FEEDBACK_TARGETS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateLoadingStatus=true) {
    const update = {};

    if (updateLoadingStatus) {
      this.setState({
        loadingData: true
      });

      update.loadingData = false;
    }

    update.customer_feedback_targets = await this.getCustomerFeedbackTargets();

    this.setState(update);
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.CUSTOMER_FEEDBACK_TARGET_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.CUSTOMER_FEEDBACK_TARGET_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "customer-feedback-target-list__description-cell"}),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando assunto de feedback';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar assunto';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId !== null) {
      return 'Todos os dados relacionados ao assunto de feedback serão removidos permanentemente.';
    }

    return 'INDEFINIDO';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteId !== null) {
      return 'Deletar assunto';
    }

    return 'INDEFINIDO';
  }

  resetFilterInputs() {
    this.setState({
      typeIdInput: '',
      targetIdInput: '',
      archivedInput: 'false',
    });
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH,
              text: 'Listar assuntos'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title={'Listar assuntos de feedbacks'}
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="customer-feedback-target-list"
            title={'Lista de assuntos'}
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.state.customer_feedback_targets}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_CUSTOMER_FEEDBACK_TARGET_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.CUSTOMER_FEEDBACK_TARGET_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo assunto de feedback

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default CustomerFeedbackTargetList;
