import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import './training_period_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import VerticalBarGraph, {BarDataPoint} from '../../graphs/vertical_bar_graph';
import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import Stacked100BarGraph, {Stack100Group, Stack100Point} from '../../graphs/stacked_100_bar_graph';
import {getGroupAssociationExecutionMinutes} from '../training_day/training_day_data';
import * as permissions from '../../permissions';
import {SERVICE_PERSONAL_SERVICE, TRAINING_PERIOD_OBJECTIVES, TRAINING_PERIOD_PHYSICAL_LEVELS} from '../../constants';


class TrainingPeriodData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      statsSectionVisible: false,
      scheduleConfigurationVisible: false,
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.todayWeekDay = today.getDay();

    this.entryBeingDragged = null;
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description' && event.target.name !== 'note') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.training_period.id && this.props.training_period.id > 0;
  }

  getServiceOptions() {
    const services = [
      ...this.props.services,
      SERVICE_PERSONAL_SERVICE
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Não especificado'),
      ...services.map((service) => SelectOption(service, service))
    ];
  }

  getTrainingObjectiveOptions() {
    const objectives = [...TRAINING_PERIOD_OBJECTIVES];

    objectives.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Não especificado'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  getGenderOptions() {
    return [
      SelectOption('', 'Indefinido'),
      SelectOption('Feminino', 'Feminino'),
      SelectOption('Masculino', 'Masculino'),
    ];
  }

  getSuggestedDiscProfileOptions() {
    return [
      SelectOption('', 'Não especificado'),
      SelectOption('D', 'D'),
      SelectOption('I', 'I'),
      SelectOption('S', 'S'),
      SelectOption('C', 'C')
    ];
  }

  getSuggestedPhysicalLevelOptions() {
    const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

    return [
      SelectOption('', 'Não especificado'),
      ...levels.map((level) => SelectOption(level, level))
    ];
  }

  getTrainingDays() {
    if(!this.props.training_period.training_days) {
      return null;
    }

    const entriesCount = this.props.training_period.training_days.length;
    const maySwitchOrder = this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID) && entriesCount > 1;

    const isEditMode = this.isEditMode();

    return this.props.training_period.training_days.map((training_day, position) => {
      const orderStyle = {};

      if(isEditMode) {
        let backgroundCount = 0;
        let background = 'linear-gradient(to top';
        const totalDayMinutes = training_day.group_associations.reduce((total, association) => total + getGroupAssociationExecutionMinutes(association), 0);
        let previousPercentage = 0;


        for(const association of training_day.group_associations) {
          if(association.activity_reference_id !== null) {
            const currentPercentage = previousPercentage + (100 * association.time_required / totalDayMinutes);

            if(backgroundCount <= 0) {
              background += `, ${association.capacity_color}, ${association.capacity_color}, ${(currentPercentage).toFixed(2)}%`;
            }
            else {
              background += `, ${association.capacity_color}, ${(previousPercentage).toFixed(2)}%, ${association.capacity_color}, ${(currentPercentage).toFixed(2)}%`;
            }

            previousPercentage = currentPercentage;
            backgroundCount += 1;
          }
        }

        if(backgroundCount > 0) {
          background += `, #2c3e50, ${(previousPercentage).toFixed(2)}% , #2c3e50)`;
          orderStyle.background = background;
        }
      }

      return (
        <div
          className="training-period-data__training"
          key={`training_days:${training_day.order}`}
        >

          <p
            className="training-period-data__training__detail"
            style={orderStyle}
          >

            {training_day.order}

          </p>

          <div className="training-period-data__training__content-wrapper">

            <p className="training-period-data__training__content"> {training_day.name} </p>
            <p className="training-period-data__training__id-text">
              <strong>ID:</strong> {training_day.id}
            </p>

          </div>

          <button
            className={`training-period-data__switch-order-button${(maySwitchOrder && training_day.order > 1) ? '' : '--hidden'}`}
            onClick={() => this.props.onSwitchOrder(training_day, -1)}
          >

            <i className="fas fa-chevron-up"></i>

          </button>

          <button
            className={`training-period-data__switch-order-button${(maySwitchOrder && training_day.order < entriesCount) ? '' : '--hidden'}`}
            onClick={() => this.props.onSwitchOrder(training_day, 1)}
          >

            <i className="fas fa-chevron-down"></i>

          </button>

          {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID) &&
            <button
              className="training-period-data__edit-training-button"
              onClick={() => this.props.onEditTrainingDay(training_day)}
            >

              {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)) ?
                <i className="far fa-edit"></i>:
                <i className="fas fa-eye"></i>
              }

            </button>
          }

          {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_DAY_PERMISSION_ID)) &&
            <button
              className="training-period-data__delete-training-button"
              onClick={() => this.props.onDeleteTrainingDay(training_day)}
            >

              <i className="far fa-trash-alt"></i>

            </button>
          }

        </div>
      );
    });
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPieHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getStatsData() {
    if(!this.isEditMode()) {
      return null;
    }

    const exercises = this.props.training_period.training_days.flatMap((day) => day.group_associations.filter((entry) => entry.group.show_in_resume).flatMap((entry) => entry.exercise_associations.map((association) => association.exercise)));
    const exercisesCount = exercises.length;

    const functionMap = new Map();
    const muscleGroupMap = new Map();
    const categoryMap = new Map();

    for(let exercise of exercises) {
      let functionPoint;
      let muscleGroupPoint;
      let categoryPoint;

      if(!functionMap.has(exercise.function_name)) {
        let barColor = '#000000';

        if(functionMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[functionMap.size];
        }

        functionPoint = BarDataPoint(0, exercise.function_name, barColor);
        functionMap.set(exercise.function_name, functionPoint);
      }
      else {
        functionPoint = functionMap.get(exercise.function_name);
      }

      functionPoint.value += 1;

      let muscle_group_name;

      if(exercise.muscle_group_name && exercise.muscle_group_name.length > 0) {
        muscle_group_name = exercise.muscle_group_name;
      }
      else {
        muscle_group_name = 'Indefinido';
      }

      if(!muscleGroupMap.has(muscle_group_name)) {
        let barColor = '#000000';

        if(muscleGroupMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[muscleGroupMap.size];
        }

        muscleGroupPoint = BarDataPoint(0, muscle_group_name, barColor);
        muscleGroupMap.set(muscle_group_name, muscleGroupPoint);
      }
      else {
        muscleGroupPoint = muscleGroupMap.get(muscle_group_name);
      }

      muscleGroupPoint.value += 1;

      if(!categoryMap.has(exercise.category_name)) {
        let barColor = '#000000';

        if(categoryMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[categoryMap.size];
        }

        categoryPoint = PiePoint(0, exercisesCount, exercise.category_name, barColor);
        categoryMap.set(exercise.category_name, categoryPoint);
      }
      else {
        categoryPoint = categoryMap.get(exercise.category_name);
      }

      categoryPoint.value += 1;
    }

    const functionData = [...functionMap.values()];
    const muscleGroupData = [...muscleGroupMap.values()];

    functionData.sort((a, b) => b.value - a.value);
    muscleGroupData.sort((a, b) => b.value - a.value);

    const functionDayMap = new Map();
    const muscleGroupDayMap = new Map();
    const categoryDayMap = new Map();
    const timePerDayData = [];
    const capacityDayMap = new Map();

    const dayNames = this.props.training_period.training_days.map((entry) => entry.name);

    for(let day of this.props.training_period.training_days) {
      const exercises = day.group_associations.filter((entry) => entry.group.show_in_resume).flatMap((entry) => entry.exercise_associations.map((association) => association.exercise));

      const totalDayMinutes = day.group_associations.reduce((total, association) => total + getGroupAssociationExecutionMinutes(association), 0);

      timePerDayData.push(BarDataPoint(Math.round(totalDayMinutes*10)/10, day.name, '#367dc4'));

      for(let exercise of exercises) {
        let functionDayGroup;

        if(!functionDayMap.has(exercise.function_name)) {
          functionDayGroup = Stack100Group(exercise.function_name, new Map(dayNames.map((dayName) => [dayName, Stack100Point(0, dayName, 0)])), true);
          functionDayMap.set(exercise.function_name, functionDayGroup);
        }
        else {
          functionDayGroup = functionDayMap.get(exercise.function_name);
        }

        const functionDayPoint = functionDayGroup.points.get(day.name);
        functionDayPoint.value += 1;
        functionDayPoint.index += 1;

        let muscle_group_name;

        if(exercise.muscle_group_name && exercise.muscle_group_name.length > 0) {
          muscle_group_name = exercise.muscle_group_name;
        }
        else {
          muscle_group_name = 'Indefinido';
        }

        let muscleGroupDayGroup;

        if(!muscleGroupDayMap.has(muscle_group_name)) {
          muscleGroupDayGroup = Stack100Group(muscle_group_name, new Map(dayNames.map((dayName) => [dayName, Stack100Point(0, dayName, 0)])), true);
          muscleGroupDayMap.set(muscle_group_name, muscleGroupDayGroup);
        }
        else {
          muscleGroupDayGroup = muscleGroupDayMap.get(muscle_group_name);
        }

        const muscleGroupDayPoint = muscleGroupDayGroup.points.get(day.name);
        muscleGroupDayPoint.value += 1;
        muscleGroupDayPoint.index += 1;

        let categoryDayGroup;

        if(!categoryDayMap.has(exercise.category_name)) {
          categoryDayGroup = Stack100Group(exercise.category_name, new Map(dayNames.map((dayName) => [dayName, Stack100Point(0, dayName, 0)])), true);
          categoryDayMap.set(exercise.category_name, categoryDayGroup);
        }
        else {
          categoryDayGroup = categoryDayMap.get(exercise.category_name);
        }

        const categoryDayPoint = categoryDayGroup.points.get(day.name);
        categoryDayPoint.value += 1;
        categoryDayPoint.index += 1;
      }

      for(let association of day.group_associations) {
        let capacityDayGroup;

        let entryTag = 'Indefinido';

        if(association.activity_reference_id !== null) {
          entryTag = association.capacity_abbreviation;
        }

        if(!capacityDayMap.has(entryTag)) {
          capacityDayGroup = Stack100Group(entryTag, new Map(dayNames.map((dayName) => [dayName, Stack100Point(0, dayName, 0)])), true, association.capacity_color);
          capacityDayMap.set(entryTag, capacityDayGroup);
        }
        else {
          capacityDayGroup = capacityDayMap.get(entryTag);
        }

        const capacityDayPoint = capacityDayGroup.points.get(day.name);
        capacityDayPoint.value += association.time_required;
        capacityDayPoint.index += (association.time_required / totalDayMinutes);
      }
    }

    const functionsPerDayData = [...functionDayMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const muscleGroupPerDayData = [...muscleGroupDayMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const categoriesPerDayData = [...categoryDayMap.values()].map((group) => {
      group.points = [...group.points.values()];

      return group;
    });

    const capacitiesPerDayData = [...capacityDayMap.values()].map((group) => {
      group.points = [...group.points.values()];

      for(const point of group.points) {
        if(point.value > 0) {
          point.index = `${point.value} (${(100 * point.index).toFixed(1)}%)`;
        }
      }

      return group;
    });

    return {
      exercisesCount: exercisesCount,
      functionData: functionData,
      muscleGroupData: muscleGroupData,
      categoryData: [...categoryMap.values()],
      functionsPerDayData: functionsPerDayData,
      muscleGroupPerDayData: muscleGroupPerDayData,
      categoriesPerDayData: categoriesPerDayData,
      capacitiesPerDayData: capacitiesPerDayData,
      timePerDayData: timePerDayData
    };
  }

  getScheduleConfiguration() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    let convertedTodayWeekDay = this.todayWeekDay - 1;
    if (convertedTodayWeekDay < 0) {
      convertedTodayWeekDay = 6;
    }

    const weekdayList = weekdays.map((weekday, index) => {
      return (
        <div
          key={`schedule_configuration:header:${index}`}
          className={`training-period-data__schedule-configuration__header__weekday${index === convertedTodayWeekDay ? '--highlighted' : ''}`}
        >

          <h3 className="training-period-data__schedule-configuration__header__weekday__text">

            {weekday}

          </h3>

        </div>
      );
    });

    const weeks = this.props.training_period.schedule_configuration.map((weekList, weekIndex) => {
      return (
        <div
          key={`schedule_configuration:week:${weekIndex}`}
          className="training-period-data__schedule-configuration__week"
        >

          <div className="training-period-data__schedule-configuration__week__header">

            <h3 className="training-period-data__schedule-configuration__week__header__text">

              {`Semana ${weekIndex + 1}`}

            </h3>

            <button
              className="training-period-data__schedule-configuration__week__header__action-button"
              onClick={() => this.props.onRemoveScheduleConfigurationWeek(weekIndex)}
            >

              <i className="far fa-trash-alt"></i>

            </button>

          </div>

          <div className="training-period-data__schedule-configuration__week__content">

            {weekList.map((entry, dayIndex) => {
              let content;

              if (entry !== null) {
                content = (
                  <div
                    className="training-period-data__schedule-configuration__week__weekday__wrapper"
                    style={{background: entry.color}}
                    draggable={true}
                    onDragStart={(event) => {
                      this.entryBeingDragged = {weekIndex, dayIndex};
                      // event.dataTransfer.setData("text/plain", JSON.stringify(this.entryBeingDragged));
                      event.dataTransfer.dropEffect = "move";
                    }}
                  >

                    <div className="training-period-data__schedule-configuration__week__weekday__controls">

                      <button
                        className="training-period-data__schedule-configuration__week__weekday__action-button--blue"
                        onClick={() => this.props.onChangeScheduleConfigurationDay(weekIndex, dayIndex)}
                      >

                        <i className="fas fa-edit"></i>

                      </button>

                      <button
                        className="training-period-data__schedule-configuration__week__weekday__action-button--red"
                        onClick={() => this.props.onRemoveScheduleConfigurationDay(weekIndex, dayIndex)}
                      >

                        <i className="far fa-trash-alt"></i>

                      </button>

                    </div>

                    <p className="training-period-data__schedule-configuration__week__weekday__text">

                      {entry.text}

                    </p>

                    <button
                      className="training-period-data__schedule-configuration__week__weekday__grab-button"
                    >

                      <i className="fa-solid fa-grip-vertical"></i>

                    </button>

                  </div>
                );
              }
              else {
                content = (
                  <button
                    className="training-period-data__schedule-configuration__add-weekday-button"
                    onClick={() => this.props.onChangeScheduleConfigurationDay(weekIndex, dayIndex)}
                  >

                    <i className="fas fa-plus training-period-data__schedule-configuration__add-weekday-button__icon"></i>
                    Adicionar dia

                  </button>
                );
              }

              return (
                <div
                  key={`schedule_configuration:week:${weekIndex}:weekday:${dayIndex}`}
                  className="training-period-data__schedule-configuration__week__weekday"
                  onDrop={(event) => {
                    event.preventDefault();
                    event.target.classList.remove('training-period-data__dropping-effect');

                    if (this.entryBeingDragged !== null && (this.entryBeingDragged.weekIndex !== weekIndex || this.entryBeingDragged.dayIndex !== dayIndex)) {
                      this.props.onSwapScheduleConfigurationDays(this.entryBeingDragged, {weekIndex, dayIndex});
                    }
                  }}
                  onDragOver={(event) => {
                    event.preventDefault();
                    event.dataTransfer.dropEffect = "move";
                  }}
                  onDragEnter={(event) => {
                    event.preventDefault();
                    // const draggedEntry = JSON.parse(event.dataTransfer.getData("text/plain"));

                    if (this.entryBeingDragged !== null && (this.entryBeingDragged.weekIndex !== weekIndex || this.entryBeingDragged.dayIndex !== dayIndex)) {
                      event.target.classList.add('training-period-data__dropping-effect');
                    }
                  }}
                  onDragLeave={(event) => {
                    event.preventDefault();
                    event.target.classList.remove('training-period-data__dropping-effect');
                  }}
                >

                  {content}

                </div>
              );
            })}

          </div>

        </div>
      );
    });

    return (
      <div className="training-period-data__schedule-configuration__wrapper">

        <div className="training-period-data__schedule-configuration">

          <div className="training-period-data__schedule-configuration__header">

            {weekdayList}

          </div>

          <div
            className="training-period-data__schedule-configuration__week-list"
          >

            {weeks}

            <div
              className="training-period-data__schedule-configuration__week"
            >

              <h3 className="training-period-data__schedule-configuration__week__header">

                <span className="training-period-data__schedule-configuration__week__header__text">

                  {`Semana ${this.props.training_period.schedule_configuration.length + 1}`}

                </span>

              </h3>

              <div className="training-period-data__schedule-configuration__week__content">

                <div className="training-period-data__schedule-configuration__add-week-button__wrapper">

                  <button
                    className="training-period-data__schedule-configuration__add-week-button"
                    onClick={() => this.props.onAddScheduleConfigurationWeek()}
                  >

                    <i className="fas fa-plus training-period-data__schedule-configuration__add-week-button__icon"></i>
                    Adicionar semana

                  </button>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
    );
  }

  render() {
    const statsData = this.getStatsData();

    return (
      <DefaultSection
        className="training-period-data"
        title="Dados da periodização"
      >

        <div className="training-period-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
            background={this.props.warningMessageBackground}
            color={this.props.warningMessageColor}
          />

        </div>

        <div className="training-period-data__input-container">

          {this.props.student &&
            <DefaultInput
              className="training-period-data__student-name"
              name="student"
              type="text"
              value={this.props.student.name}
              autoComplete="off"
              prefix="Aluno"
              isHorizontal={true}
              disabled={true}
            />
          }

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome da periodização"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

            <DefaultInput
              name="code"
              isHighlighted={this.isHighlighted("code")}
              label="Código de identificação"
              type="text"
              placeholder="Código de identificação"
              maxLength="32"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.code || ''}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="repetition_count"
              isHighlighted={this.isHighlighted("repetition_count")}
              label="Número de fases"
              type="number"
              placeholder="Número de repetições"
              min="1"
              max="20"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.repetition_count}
              autoComplete="off"
              suffix="fases"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

            <DefaultInput
              name="period_reference"
              isHighlighted={this.isHighlighted("period_reference")}
              label="Período de referência"
              labelMessage="Padrão: 7 dias"
              type="number"
              placeholder="Período de referência"
              min="1"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.period_reference || ''}
              autoComplete="off"
              suffix="dias"
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

          </HalfWrapper>

          <DefaultInput
            name="target_service"
            isHighlighted={this.isHighlighted("target_service")}
            label="Treinamento alvo"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_period.target_service || ''}
            options={this.getServiceOptions()}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
          />

          <HalfWrapper>

            <DefaultInput
              name="main_objective"
              isHighlighted={this.isHighlighted("main_objective")}
              label="Objetivo do treinamento"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.main_objective || ''}
              options={this.getTrainingObjectiveOptions()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

            <DefaultInput
              name="target_gender"
              isHighlighted={this.isHighlighted("target_gender")}
              label="Gênero alvo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.target_gender || ''}
              options={this.getGenderOptions()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="suggested_disc_profile"
              isHighlighted={this.isHighlighted("suggested_disc_profile")}
              label="Perfil DISC sugerido"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.suggested_disc_profile || ''}
              options={this.getSuggestedDiscProfileOptions()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

            <DefaultInput
              name="suggested_physical_level"
              isHighlighted={this.isHighlighted("suggested_physical_level")}
              label="Nível físico sugerido"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.suggested_physical_level || ''}
              options={this.getSuggestedPhysicalLevelOptions()}
              disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da periodização"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_period.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
          />

          <DefaultInput
            name="note"
            isHighlighted={this.isHighlighted("note")}
            label="Nota aos alunos"
            type="textarea"
            placeholder="Nota aos alunos"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_period.note || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.training_period.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
          />

          {(this.props.userPermissionIds.includes(permissions.SHARE_TRAINING_PERIOD_PERMISSION) && this.props.training_period.editable) &&
            <DefaultInput
              name="shared"
              isHighlighted={this.isHighlighted('shared')}
              label="Compartilhar cadastro:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.shared}
            />
          }

          {this.props.training_period.editable &&
            <DefaultInput
              name="is_active"
              isHighlighted={this.isHighlighted('is_active')}
              label="Cadastro ativo:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_period.is_active}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)}
            />
          }

          {(this.isEditMode() && ((this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) || this.props.training_period.schedule_configuration.length > 0)) &&
            <React.Fragment>

              <HorizontalRule />

              <section className="training-period-data__expandable-session">

                <header
                  className="training-period-data__expandable-session__header"
                  onClick={() => this.setState({scheduleConfigurationVisible: !this.state.scheduleConfigurationVisible})}
                >

                  <h3 className="training-period-data__expandable-session__header__text">
                    <i className="fa-solid fa-calendar-days training-period-data__expandable-session__header__text-icon"></i>
                    Cronograma
                  </h3>

                  {this.state.scheduleConfigurationVisible ?
                    <i className="fas fa-chevron-down training-period-data__expandable-session__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up training-period-data__expandable-session__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container training-period-data__expandable-session__content"
                  pose={this.state.scheduleConfigurationVisible ? 'verticalOpen' : 'verticalClosed'}>

                  <div className="vertical-accordion-container training-period-data__expandable-session__content-wrapper">

                    {this.getScheduleConfiguration()}

                  </div>

                </VerticalAccordionContainer>

              </section>

            </React.Fragment>
          }

          {this.isEditMode() &&
            <React.Fragment>

              <DefaultSubSectionTitle
                className="training-period-data__sub-section"
                icon={<i className="fas fa-calendar-day"></i>}
                text="Treinos"
              />

              {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.ADD_TRAINING_DAY_PERMISSION_ID)) &&
                <button
                  className="training-period-data__import-training-button"
                  onClick={() => this.props.onImportTraining()}
                >

                  <i className="fas fa-clone training-period-data__add-training-button__icon"></i> Importar treino

                </button>
              }

              <div className="training-period-data__trainings-container">

                {this.getTrainingDays()}

                {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.ADD_TRAINING_DAY_PERMISSION_ID)) &&
                  <button
                    className="training-period-data__add-training-button"
                    onClick={() => this.props.onAddTrainingDay()}
                  >

                    <i className="fas fa-plus training-period-data__add-training-button__icon"></i>
                    Adicionar treino

                  </button>
                }

              </div>

              {statsData.exercisesCount > 0 &&
                <React.Fragment>

                  <HorizontalRule />

                  <section className="training-period-data__stats">

                    <header
                      className="training-period-data__stats__header"
                      onClick={() => this.setState({statsSectionVisible: !this.state.statsSectionVisible})}
                    >

                      <h3 className="training-period-data__stats__header__text">
                        <i className="far fa-chart-bar training-period-data__stats__header__text-icon"></i>
                        Resumo
                      </h3>

                      {this.state.statsSectionVisible ?
                        <i className="fas fa-chevron-down training-period-data__stats__header__visible-icon"></i>:
                        <i className="fas fa-chevron-up training-period-data__stats__header__visible-icon"></i>
                      }

                    </header>

                    <VerticalAccordionContainer
                      className="vertical-accordion-container training-period-data__stats__content"
                      pose={this.state.statsSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                      <div className="vertical-accordion-container training-period-data__stats__content-wrapper">

                        <div className="training-period-data__indicator">

                          <h2 className="training-period-data__indicator__label">Total de dias:</h2>
                          <p className="training-period-data__indicator__value">{this.props.training_period.repetition_count * this.props.training_period.training_days.length}</p>

                        </div>

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Duração por treino"
                        />

                        <VerticalBarGraph
                          xLabel="Treino"
                          yLabel="Minutos"
                          data={statsData.timePerDayData}
                          height={this.getDefaultGraphHeight()}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Funções por treino"
                        />

                        <Stacked100BarGraph
                          data={statsData.functionsPerDayData}
                          height={this.getDefaultGraphHeight()}
                          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                          totalToolTipLabel="Total de exercícios"
                          ToolTipValueCallback={(value) => `${value}`}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Pilares por treino"
                        />

                        <Stacked100BarGraph
                          data={statsData.categoriesPerDayData}
                          height={this.getDefaultGraphHeight()}
                          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                          totalToolTipLabel="Total de exercícios"
                          ToolTipValueCallback={(value) => `${value}`}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Agrupamentos por treino"
                        />

                        <Stacked100BarGraph
                          data={statsData.muscleGroupPerDayData}
                          height={this.getDefaultGraphHeight()}
                          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                          totalToolTipLabel="Total de exercícios"
                          ToolTipValueCallback={(value) => `${value}`}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Capacidades por treino (por tempo)"
                        />

                        <Stacked100BarGraph
                          data={statsData.capacitiesPerDayData}
                          height={this.getDefaultGraphHeight()}
                          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                          totalToolTipLabel="Tempo total"
                          ToolTipValueCallback={(value) => `${value}`}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Funções"
                        />

                        <VerticalBarGraph
                          xLabel="Função"
                          yLabel="Quantidade"
                          data={statsData.functionData}
                          height={this.getDefaultGraphHeight()}
                          yInterval={1}
                        />

                        <DefaultSubSectionTitle
                          className="training-period-data__sub-section"
                          icon={<i className="fas fa-chart-pie"></i>}
                          text="Pilares"
                        />

                        <PieGraph
                          data={statsData.categoryData}
                          height={this.getPieHeight()}
                          legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                          legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                          valueTextCallback={(value) => value}
                          indexLabel="{value}"
                          // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                          // defaultMultiplier={1}
                        />

                        <DefaultSubSectionTitle
                          className="training-day-group-association-data__sub-section"
                          icon={<i className="far fa-chart-bar"></i>}
                          text="Agrupamentos musculares"
                        />

                        <VerticalBarGraph
                          xLabel="Músculo alvo"
                          yLabel="Quantidade"
                          data={statsData.muscleGroupData}
                          height={this.getDefaultGraphHeight()}
                          yInterval={1}
                        />

                      </div>

                    </VerticalAccordionContainer>

                  </section>

                </React.Fragment>
              }

            </React.Fragment>
          }

        </div>

        <HorizontalRule />

        <div className="training-period-data__buttons-container">

          {(this.props.training_period.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) &&
            <button
              className="training-period-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <Link
            className="training-period-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {this.isEditMode() ? 'Voltar': 'Cancelar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default TrainingPeriodData;
