import React from 'react';
import ContentFrame from '../content_frame';
import ConfirmationWindow from '../confirmation_window';
import * as routes from '../../constants';
import * as paths from './constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, TRAINING_EXECUTION_METHOD_QRP, CLOCK_METHOD_STOPWATCH, CLOCK_METHOD_CUSTOM, DEFAULT_UNIT_TYPE, CLOCK_COLOR_MAP} from '../../constants';
import {TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH} from '../training_day/constants';
import {TRAINING_DAY_EDIT_PATH} from '../training_period/constants';
import {getModels, getModel, postModel, patchModel, deleteModel, putFileRequest} from '../../utils/functions';
import TrainingDayGroupAssociationData from './training_day_group_association_data';

class TrainingDayGroupAssociationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      association: {
        name: "",
        order: null,
        execution_method: "",
        cycle_number: 1,
        note: "",
        time_required: 0,
        clock_time_limit: 0,
        has_clock: false,
        has_support_table: false,
        support_table: [],
        clock_method: CLOCK_METHOD_STOPWATCH,
        clock_preparation_period: 10,
        clock_round_count: 0,
        clock_round_period: 0,
        clock_rest_period: 0,
        pause_on_round_end: false,
        active_color: CLOCK_COLOR_MAP['Verde'],
        clock_custom_array: null,
        hr_range: null,
        activity_reference_id: null,
        exercise_associations: [],
        merge_with_previous_group: false
      },
      training_period: {},
      exercises: [],
      exerciseGroups: [],
      exerciseCategories: [],
      exerciseFunctions: [],
      muscleGroups: [],
      exerciseActivities: [],
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true,
      deleteExerciseAssociationId: null,
      clockAudioFileToUpload: null,
      mayRemoveClockAudioFile: false,
      savingExerciseAssociation: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  getSupportTableCopy() {
    if(!this.state.association.support_table) {
      return [];
    }

    return this.state.association.support_table.map((row) => [...row]);
  }

  getClockCustomArrayCopy() {
    if(!this.state.association.clock_custom_array) {
      return [];
    }

    return this.state.association.clock_custom_array.map((entry) => {
      return {...entry};
    });
  }

  addSupportTableRow() {
    const support_table = this.getSupportTableCopy();

    support_table.push(['', '']);

    this.setState({
      association:{
        ...this.state.association,
        support_table
      }
    });
  }

  removeSupportTableRow(rowIndex) {
    const support_table = this.getSupportTableCopy();

    support_table.splice(rowIndex, 1);

    this.setState({
      association:{
        ...this.state.association,
        support_table
      }
    });
  }

  async reloadData() {
    let association = getModel(`${routes.TRAINING_DAY_GROUP_ASSOCIATION_GET_API}${this.props.match.params.trainingDayGroupAssociationId}`);
    let exercises = getModels(`${routes.TRAINING_EXERCISES_GET_API}?active_only=true`);
    let exerciseGroups = getModels(routes.EXERCISE_GROUPS_GET_API);
    let training_period = getModel(`${routes.TRAINING_PERIOD_GET_API}${this.props.match.params.trainingPeriodId}`);
    let exerciseCategories = getModels(routes.EXERCISE_CATEOGRIES_GET_API);
    let exerciseFunctions = getModels(routes.EXERCISE_FUNCTIONS_GET_API);
    let muscleGroups = getModels(routes.MUSCLE_GROUPS_GET_API);
    let exerciseActivities = getModels(`${routes.EXERCISE_ACTIVITIES_GET_API}?used_in_trainings=true`);
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    const update = {
      loading: false
    }

    association = await association;

    if(association) {
      update.association = {...this.state.association, ...association};
    }

    exercises = await exercises;

    if(exercises) {
      update.exercises = exercises;
    }

    exerciseGroups = await exerciseGroups;

    if(exerciseGroups) {
      update.exerciseGroups = exerciseGroups;
    }

    training_period = await training_period;

    if(training_period) {
      update.training_period = training_period;
    }

    exerciseCategories = await exerciseCategories;

    if(exerciseCategories) {
      update.exerciseCategories = exerciseCategories;
      update.exerciseCategories.sort((a, b) => a.name.localeCompare(b.name));
    }

    exerciseFunctions = await exerciseFunctions;

    if(exerciseFunctions) {
      update.exerciseFunctions = exerciseFunctions;
      update.exerciseFunctions.sort((a, b) => a.name.localeCompare(b.name));
    }

    muscleGroups = await muscleGroups;

    if(muscleGroups) {
      update.muscleGroups = muscleGroups;
      update.muscleGroups.sort((a, b) => a.name.localeCompare(b.name));
    }

    exerciseActivities = await exerciseActivities;

    if(exerciseActivities) {
      update.exerciseActivities = exerciseActivities;
      update.exerciseActivities.sort((a, b) => a.name.localeCompare(b.name));
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.reloadData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getArrayCopy(target, convertCallback=null) {
    if(target instanceof Array) {
      const copy = [];

      for(let entry of target) {
        copy.push(this.getArrayCopy(entry, convertCallback));
      }

      return copy;
    }

    if(convertCallback) {
      return convertCallback(target);
    }

    return target;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    let newData;

    if(name.startsWith('support_table:')) {
      const selection = name.split(':');
      const rowIndex = parseInt(selection[1]);

      name = selection[0];

      const valueCopy = this.getSupportTableCopy();

      valueCopy[rowIndex][selection[2] === 'cycle' ? 0 : 1] = value;

      value = valueCopy;

      newData = {...this.state.association, [name]: value};
    }
    else if(name.startsWith('clock_custom_array:')) {
      const selection = name.split(':');
      const rowIndex = parseInt(selection[1]);

      name = selection[0];

      const valueCopy = this.getClockCustomArrayCopy();

      valueCopy[rowIndex][selection[2]] = value;

      value = valueCopy;

      newData = {...this.state.association, [name]: value};
    }
    else if(name.startsWith('hr_range:')) {
      const selection = name.split(':');
      const rowIndex = parseInt(selection[1]);

      name = selection[0];

      let valueCopy = ['', ''];

      if(this.state.association.hr_range !== null) {
        valueCopy = [...this.state.association.hr_range];
      }

      valueCopy[rowIndex] = value;

      if(valueCopy[0] === '' && valueCopy[1] === '') {
        value = null;
      }
      else {
        value = valueCopy;
      }

      newData = {...this.state.association, [name]: value};
    }
    else if(name.startsWith('group_exercise_associations:')) {
      const selection = name.split(':');
      const exerciseAssociationId = parseInt(selection[1]);

      const listCopy = this.state.association.exercise_associations.map((exerciseAssociation) => {
        const exerciseAssociationCopy = {
          ...exerciseAssociation
        };

        if (exerciseAssociationId === exerciseAssociation.id) {
          if (typeof exerciseAssociationCopy.backup === 'undefined') {
            exerciseAssociationCopy.backup = {};
          }

          if (typeof exerciseAssociationCopy.backup[[selection[2]]] === 'undefined') {
            exerciseAssociationCopy.backup[[selection[2]]] = this.getArrayCopy(exerciseAssociationCopy[selection[2]]);
          }

          const repetitionIndex = parseInt(selection[3]);

          if (selection.length === 4) {
            if (exerciseAssociationCopy[selection[2]][repetitionIndex] !== value) {
              exerciseAssociationCopy[selection[2]][repetitionIndex] = value;
              exerciseAssociationCopy.mustUpdate = true;
            }
          }
          else {
            const cycleIndex = parseInt(selection[4]);

            if (exerciseAssociationCopy[selection[2]][repetitionIndex][cycleIndex] !== value) {
              exerciseAssociationCopy[selection[2]][repetitionIndex][cycleIndex] = value;
              exerciseAssociationCopy.mustUpdate = true;
            }
          }
        }

        return exerciseAssociationCopy;
      });

      newData = {
        ...this.state.association,
        exercise_associations: listCopy
      };
    }
    else {
      newData = {...this.state.association, [name]: value};

      if(name === 'group_id') {
        const newGroup = this.state.exerciseGroups.find((group) => group.id === parseInt(value));

        newData.name = newGroup.name;
      }

      if(name === 'execution_method' && value === TRAINING_EXECUTION_METHOD_QRP) {
        newData.cycle_number = 1;
      }

      if(name === 'clock_method' && value === CLOCK_METHOD_CUSTOM) {
        newData.clock_custom_array = [];

        for(let i = 0; i < newData.clock_round_count; ++i) {
          newData.clock_custom_array.push({
            color: CLOCK_COLOR_MAP['Verde'],
            period: newData.clock_round_period,
            pause_on_end: false
          });
        };
      }

      if(name === 'clock_round_count' && newData.clock_method === CLOCK_METHOD_CUSTOM && value !== '') {
        newData.clock_custom_array = this.getClockCustomArrayCopy();

        const currentSize = newData.clock_custom_array.length;

        if(value < currentSize) {
          newData.clock_custom_array = newData.clock_custom_array.slice(0, value);
        }
        else {
          let color = CLOCK_COLOR_MAP['Verde'];
          let period = newData.clock_round_period;
          let pause_on_end = false;

          if(currentSize > 0) {
            color = newData.clock_custom_array[currentSize-1].color;
            period = newData.clock_custom_array[currentSize-1].period;
            pause_on_end = newData.clock_custom_array[currentSize-1].pause_on_end;
          }

          for(let i = 0; i < value - currentSize; ++i) {
            newData.clock_custom_array.push({
              color,
              period,
              pause_on_end
            });
          };
        }
      }
    }

    this.setState({
      association: newData
    });
  }

  onReplaceExercise(targetAssociation, exercise) {
    const listCopy = this.state.association.exercise_associations.map((exerciseAssociation) => {
      const exerciseAssociationCopy = {
        ...exerciseAssociation
      };

      if (targetAssociation.id === exerciseAssociation.id) {
        if (typeof exerciseAssociationCopy.backup === 'undefined') {
          exerciseAssociationCopy.backup = {};
        }

        if (typeof exerciseAssociationCopy.backup.exercise_id === 'undefined') {
          exerciseAssociationCopy.backup.exercise_id = exerciseAssociationCopy.exercise_id;
          exerciseAssociationCopy.backup.exercise = exerciseAssociationCopy.exercise;
          exerciseAssociationCopy.backup.exercise_name = exerciseAssociationCopy.exercise_name;
        }

        if (exercise) {
          exerciseAssociationCopy.exercise_id = exercise.id;
          exerciseAssociationCopy.exercise = exercise;
          exerciseAssociationCopy.exercise_name = exercise.name;
          exerciseAssociationCopy.mustUpdate = true;
        }
      }

      return exerciseAssociationCopy;
    });

    this.setState({
      association: {
        ...this.state.association,
        exercise_associations: listCopy
      }
    });
  }

  onRevertChanges() {
    const listCopy = this.state.association.exercise_associations.map((exerciseAssociation) => {
      if (exerciseAssociation.mustUpdate) {
        return {
          ...exerciseAssociation,
          ...exerciseAssociation.backup,
          backup: {},
          mustUpdate: false
        };
      }

      return {
        ...exerciseAssociation
      };
    });

    this.setState({
      association: {
        ...this.state.association,
        exercise_associations: listCopy
      }
    });
  }

  inputsAreValid() {
    let clockIsValid = true;
    let hrDataIsValid = true;

    if(this.state.association.clock_method === CLOCK_METHOD_CUSTOM &&
          (this.state.association.clock_custom_array === null ||
           this.state.association.clock_custom_array.some((entry) => entry.color.length <= 0 || entry.period === null || entry.period === ''))) {
      clockIsValid = false;
    }

    if(this.state.association.hr_range !== null && (this.state.association.hr_range.some((value) => value === '') || this.state.association.hr_range[0] > this.state.association.hr_range[1])) {
      hrDataIsValid = false;
    }

    return this.state.association.name.length > 0 &&
           this.state.association.execution_method.length > 0 &&
           (this.state.association.time_required !== null && this.state.association.time_required !== '') &&
           (this.state.association.clock_time_limit !== null && this.state.association.clock_time_limit !== '') &&
           (this.state.association.execution_method === TRAINING_EXECUTION_METHOD_QRP || this.state.association.cycle_number > 0) &&
           clockIsValid &&
           hrDataIsValid;
  }

  onDeleteExerciseAssociation(association) {
    this.setState({
      deleteExerciseAssociationId: association.id,
      clockAudioFileToUpload: null,
      mayRemoveClockAudioFile: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onSelectClockAudioFile(file) {
    this.setState({
      deleteExerciseAssociationId: null,
      clockAudioFileToUpload: file,
      mayRemoveClockAudioFile: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onRemoveClockAudioFile() {
    this.setState({
      deleteExerciseAssociationId: null,
      clockAudioFileToUpload: null,
      mayRemoveClockAudioFile: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onEditExerciseAssociation(association) {
    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}${paths.TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH}${association.id}`);
  }

  onCancelConfirmation() {
    this.setState({
      deleteExerciseAssociationId: null,
      clockAudioFileToUpload: null,
      mayRemoveClockAudioFile: false,
      savingExerciseAssociation: false,
      confirmFailed: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteExerciseAssociationId != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_GROUP_EXERCISE_ASSOCIATION_DELETE_API}${this.state.deleteExerciseAssociationId}`)) {
          this.reloadData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.clockAudioFileToUpload != null) {
      const file_extension = this.state.clockAudioFileToUpload.name.slice((this.state.clockAudioFileToUpload.name.lastIndexOf(".") - 1 >>> 0) + 2);
      const mime_type = this.state.clockAudioFileToUpload.type;

      const data = {file_extension, mime_type};

      try{
        const response = await postModel(routes.TRAINING_DAY_GROUP_ASSOCIATION_CREATE_AUDIO_UPLOAD_URL_POST_API.replace('{id}', this.props.match.params.trainingDayGroupAssociationId), data, true);

        if(response) {
          if (await putFileRequest(response.upload_url, this.state.clockAudioFileToUpload)) {
            const confirmationResponse = await postModel(routes.TRAINING_DAY_GROUP_ASSOCIATION_CONFIRM_AUDIO_UPLOAD_POST_API.replace('{id}', this.props.match.params.trainingDayGroupAssociationId), {}, true);

            if (confirmationResponse && confirmationResponse.file_confimed) {
              this.setState({
                association: {
                  ...this.state.association,
                  music_file_url: confirmationResponse.file_url
                }
              });         
            }
            else {
              throw new Error("Failed to confirm file upload");
            }
          }
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.mayRemoveClockAudioFile) {
      try{
        if(await deleteModel(routes.TRAINING_DAY_GROUP_ASSOCIATION_DELETE_AUDIO_FILE_API.replace('{id}', this.props.match.params.trainingDayGroupAssociationId))) {
          this.setState({
            association: {
              ...this.state.association,
              music_file_url: null
            }
          }); 
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteExerciseAssociationId: null,
      clockAudioFileToUpload: null,
      mayRemoveClockAudioFile: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteExerciseAssociationId != null) {
        return 'Removendo exercício do agrupamento';
      }
      else if(this.state.clockAudioFileToUpload != null) {
        return 'Enviando arquivo audio';
      }
      else if(this.state.mayRemoveClockAudioFile) {
        return 'Excluindo arquivo de audio';
      }
      else if(this.state.savingExerciseAssociation) {
        return 'Salvando dados de exercício';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteExerciseAssociationId != null) {
        return 'Falha ao remover exercício do agrupamento';
      }
      else if(this.state.clockAudioFileToUpload != null) {
        return 'Falha ao enviar arquivo de audio';
      }
      else if(this.state.mayRemoveClockAudioFile) {
        return 'Falha ao excluir arquivo de audio';
      }
      else if(this.state.savingExerciseAssociation) {
        return 'Falha ao salvar dados de exercício';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteExerciseAssociationId != null) {
      return 'Remover exercício do agrupamento';
    }
    else if(this.state.clockAudioFileToUpload != null) {
      return 'Enviar arquivo de audio';
    }
    else if(this.state.mayRemoveClockAudioFile) {
      return 'Remover arquivo de audio';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteExerciseAssociationId != null) {
      return 'Todos os dados relacionados ao exercício serão removidos do agrupamento';
    }
    else if(this.state.clockAudioFileToUpload != null) {
      return 'Tem certeza de que deseja subir o arquivo de audio selecionado para ser tocado junto com o início do cronômetro?';
    }
    else if(this.state.mayRemoveClockAudioFile) {
      return 'O arquivo de audio será desvinculado e apagado permanentemente';
    }
    else if(this.state.savingExerciseAssociation) {
      return 'Aguarde enquanto os dados são salvos';
    }

    return 'Unknown';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteExerciseAssociationId != null) {
      return 'Remover exercício';
    }
    else if(this.state.clockAudioFileToUpload != null) {
      return 'Enviar';
    }
    else if(this.state.mayRemoveClockAudioFile) {
      return 'Remover';
    }

    return 'Unknown';
  }

  addExercise(exercise_id) {
    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}${paths.TRAINING_GROUP_EXERCISE_ASSOCIATION_ADD_PATH}${exercise_id}`);
  }

  async saveData() {
    if (this.state.association.exercise_associations.some((entry) => entry.mustUpdate)) {
      if (!window.confirm("Existem alterações em exercícios que ainda não foram salvas e serão perdidas caso continue. Deseja mesmo continuar?")) {
        return;
      }
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    const data = {...this.state.association};
    data.cycle_number = parseInt(data.cycle_number);
    data.time_required = parseFloat(data.time_required);
    data.exercise_group_id = parseInt(data.group_id);
    data.clock_time_limit = parseFloat(data.clock_time_limit);
    data.clock_preparation_period = parseFloat(data.clock_preparation_period);
    data.clock_round_count = parseFloat(data.clock_round_count);
    data.clock_round_period = parseFloat(data.clock_round_period);
    data.clock_rest_period = parseFloat(data.clock_rest_period);
    if(!data.pause_on_round_end) {
      data.pause_on_round_end = false;
    }
    if(!data.active_color) {
      data.active_color = CLOCK_COLOR_MAP['Verde'];
    }
    if(!data.activity_reference_id) {
      data.activity_reference_id = null;
    }
    if(this.state.association.clock_method === CLOCK_METHOD_CUSTOM) {
      const clock_custom_array = [];

      for(const entry of this.state.association.clock_custom_array) {
        clock_custom_array.push({
          count_label: entry.count_label || null,
          color: entry.color,
          period: parseFloat(entry.period),
          pause_on_end: entry.pause_on_end
        });
      }

      data.clock_custom_array = clock_custom_array;
    }
    if(this.state.association.hr_range !== null) {
      data.hr_range = [
        parseFloat(this.state.association.hr_range[0]),
        parseFloat(this.state.association.hr_range[1])
      ];
    }

    try {
      await patchModel(`${routes.TRAINING_DAY_GROUP_ASSOCIATION_PATCH_API}${this.props.match.params.trainingDayGroupAssociationId}`, data);

      this.reloadData();
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'cycle_number':
                    if (parameter.value > 100) {
                      warningMessages.push('Número de séries não pode ser do maior que 100');
                    }
                    else {
                      warningMessages.push('Número de séries inválido');
                    }

                    highlights.push('cycle_number');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      if (warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessageBackground: null,
        warningMessageColor: null,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // this.props.history.replace(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`);
    this.setState({
      warningMessage: "Dados salvos.",
      warningMessageBackground: "#dbf3c1",
      warningMessageColor: "#456d19",
      showWarningMessage: true,
      loading: false
    });
  }

  async onSaveExerciseAssociation(association) {
    this.setState({
      confirmInProgress: true,
      savingExerciseAssociation: true
    });

    const data = {...association};
    if(data.intensity_name) {
      data.intensity_value = this.getArrayCopy(data.intensity_value, (value) => parseInt(value));
    }
    if(data.difficult_name) {
      data.difficult_value = this.getArrayCopy(data.difficult_value, (value) => parseInt(value));
      data.difficult_intermediate_value = this.getArrayCopy(data.difficult_intermediate_value, (value) => parseInt(value) || 0);
      data.difficult_advanced_value = this.getArrayCopy(data.difficult_advanced_value, (value) => parseInt(value) || 0);
    }
    data.intensity_value_step = this.getArrayCopy(data.intensity_value_step, (value) => parseInt(value));
    data.training_exercise_id = parseInt(data.exercise_id);

    try {
      await patchModel(`${routes.TRAINING_GROUP_EXERCISE_ASSOCIATION_PATCH_API}${association.id}`, data);

      this.setState({
        association: {
          ...this.state.association,
          exercise_associations: this.state.association.exercise_associations.map((exerciseAssociation) => {
            if (exerciseAssociation.id === association.id) {
              return {
                ...exerciseAssociation,
                mustUpdate: false,
                backup: {}
              };
            }

            return {
              ...exerciseAssociation
            };
          })
        }
      });
    }
    catch(errors) {
      let warningMessages = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      if (warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        confirmFailDescription: `${warningMessages.join('; ')}.`,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    // this.props.history.replace(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`);
    this.setState({
      confirmInProgress: false,
      savingExerciseAssociation: false
    });
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  onSwitchOrder(origem, offset) {
    const entriesCount = this.state.association.exercise_associations.length;
    const origiemOrder = origem.order;
    const newOrder = origem.order + offset;

    if(newOrder <= 0 || newOrder > entriesCount) {
      return;
    }

    const listCopy = [];

    for(const association of this.state.association.exercise_associations) {
      if(association.id === origem.id) {
        listCopy.push({
          ...association,
          order: newOrder
        });
      }
      else if(association.order === newOrder) {
        listCopy.push({
          ...association,
          order: origiemOrder
        });
      }
      else {
        listCopy.push(association);
      }
    }

    listCopy.sort((a, b) => a.order - b.order);

    this.setState({association: {...this.state.association, exercise_associations: listCopy}});
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteExerciseAssociationId !== null || 
                   this.state.clockAudioFileToUpload !== null || 
                   this.state.mayRemoveClockAudioFile || 
                   this.state.savingExerciseAssociation}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}`,
              text: "Editar periodização"
            },
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`,
              text: "Editar treino"
            },
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}`,
              text: "Editar agrupamento"
            },
          ]}
          titleIcon={<i className="fas fa-edit"></i>}
          title="Editar agrupamento"
          loading={this.state.loading}
        >

          <TrainingDayGroupAssociationData
            warningMessage={this.state.warningMessage}
            showWarningMessage={this.state.showWarningMessage}
            warningMessageBackground={this.state.warningMessageBackground}
            warningMessageColor={this.state.warningMessageColor}
            association={this.state.association}
            onSave={() => this.saveData()}
            onSaveExerciseAssociation={(association) => this.onSaveExerciseAssociation(association)}
            onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
            enableSave={this.inputsAreValid()}
            handleInputChange={(event) => this.handleInputChange(event)}
            highlights={this.state.highlights}
            onCancelPath={`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`}
            training_period={this.state.training_period}
            exercises={this.state.exercises}
            exerciseGroups={this.state.exerciseGroups}
            exerciseCategories={this.state.exerciseCategories}
            exerciseFunctions={this.state.exerciseFunctions}
            muscleGroups={this.state.muscleGroups}
            exerciseActivities={this.state.exerciseActivities}
            services={this.state.services}
            addExercise={(exercise_id) => this.addExercise(exercise_id)}
            addSupportTableRow={() => this.addSupportTableRow()}
            removeSupportTableRow={(rowIndex) => this.removeSupportTableRow(rowIndex)}
            onDeleteExerciseAssociation={(association) => this.onDeleteExerciseAssociation(association)}
            onEditExerciseAssociation={(association) => this.onEditExerciseAssociation(association)}
            userPermissionIds={this.props.userPermissionIds}
            userAccessLevel={this.props.userAccessLevel}
            isDefaultUnit={this.isDefaultUnit()}
            onSwitchOrder={(training_day, offset) => this.onSwitchOrder(training_day, offset)}
            onRevertChanges={() => this.onRevertChanges()}
            onReplaceExercise={(targetAssociation, exercise) => this.onReplaceExercise(targetAssociation, exercise)}
            onSelectClockAudioFile={(file) => this.onSelectClockAudioFile(file)}
            onRemoveClockAudioFile={() => this.onRemoveClockAudioFile()}
          />

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingDayGroupAssociationEdit;
