import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {FOOD_PRESCRIPTION_PATH} from '../../student/constants';
import {patchModel, getModels, getModel, setUrlParameters} from '../../../utils/functions';
import {getFoodRecipeNutricionalData,
        getFoodRecipeFinalWeight,
        CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
        PROTEIN_TO_KCAL_CONVERSION_CONTANT,
        FAT_TO_KCAL_CONVERSION_CONTANT} from '../../../utils/fyd_food';
import FoodRecipeData from './food_recipe_data';

class FoodRecipeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_recipe: {
        name: "",
        people_served: 1,
        output_weight: null,
        output_volume: null,
        default_measurement_quantity: null,
        description: "",
        consumption_description: "",
        preparation_description: "",
        meal_classification_id: null,
        recipe_difficulty_id: null,
        output_weight_unit_id: null,
        output_volume_unit_id: null,
        default_measurement_id: null,
        total_energy: null,
        total_energy_unit_id: null,
        total_carbohydrate_percentage: null,
        total_protein_percentage: null,
        total_fat_percentage: null,
        preparation_steps: [],
        is_active: true,
      },
      student: null,
      food_recipes: [],
      meal_classifications: [],
      recipe_difficulties: [],
      weight_units: [],
      volume_units: [],
      food_measurements: [],
      food_ingredients: [],
      food_classifications: [],
      ingredientList: [],
      selectedWeightUnit: null,
      selectedVolumeUnit: null,
      selectedDefaultMeasurement: null,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };

    this.basicIngredientDataMap = new Map();
    this.advancedIngredientDataMap = new Map();
  }

  async componentDidMount() {
    const update = {loading: false};

    let food_recipe = getModel(setUrlParameters(`${routes.FOOD_RECIPE_GET_API}${this.props.match.params.foodRecipeId}`, {
      load_full_ingredient: true,
      load_preparation_steps: true
    }));
    let meal_classifications = getModels(routes.MEAL_CLASSIFICATIONS_GET_API);
    let recipe_difficulties = getModels(routes.RECIPE_DIFFICULTIES_GET_API);
    let weight_units = getModels(routes.WEIGHT_UNITS_GET_API);
    let volume_units = getModels(routes.VOLUME_UNITS_GET_API);
    let food_measurements = getModels(routes.FOOD_MEASUREMENTS_GET_API);
    let food_ingredients = getModels(routes.FOOD_INGREDIENTS_GET_API);
    let food_classifications = getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));

    food_recipe = await food_recipe;

    if(food_recipe) {
      update.food_recipe = {...this.state.food_recipe, ...food_recipe};

      let listSize = 0;

      update.ingredientList = [
        ...update.food_recipe.advanced_ingredient_associations.map((association) => {
          this.advancedIngredientDataMap.set(association.advanced_ingredient.id, association.advanced_ingredient);

          const newEntry = {
            id: association.id,
            key: `${listSize}_advanced`, //`${listSize}_advanced_${association.advanced_ingredient.id}`
            recipe_preparation_step: null,
            name: association.name,
            order: association.order,
            quantity: association.quantity,
            isBasic: false,
            loaded: true,
            data: association.advanced_ingredient
          };

          listSize += 1;

          return newEntry;
        }),
        ...update.food_recipe.basic_ingredient_associations.map((association) => {
          const food_ingredient = association.food_ingredient_source_association.food_ingredient;

          this.basicIngredientDataMap.set(food_ingredient.id, food_ingredient);

          const newEntry = {
            id: association.id,
            key: `${listSize}_basic`,
            food_ingredient_source_association: food_ingredient.source_associations.find((entry) => {
              return entry.id === association.food_ingredient_source_association.id;
            }),
            food_ingredient_measurement_association: food_ingredient.food_measurement_associations.find((entry) => {
              return entry.id === association.food_ingredient_measurement_association_id;
            }),
            recipe_preparation_step: null,
            name: association.name,
            order: association.order,
            quantity: association.quantity,
            isBasic: true,
            loaded: true,
            has_prescription_recipe_adjustments: association.has_prescription_recipe_adjustments,
            data: food_ingredient
          };

          listSize += 1;

          return newEntry;
        })
      ];

      update.ingredientList.sort((a, b) => a.order - b.order);
      update.food_recipe.preparation_steps.sort((a, b) => a.order - b.order);

      if(update.food_recipe.user_id !== null) {
        let student = await getModel(`${routes.STUDENT_GET_API}${update.food_recipe.user_id}`);

        if(student) {
          update.student = student;
        }
      }
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    let food_recipes = getModels(setUrlParameters(routes.FOOD_RECIPES_GET_API, {is_active: true, user_id: update.food_recipe.user_id !== null ? update.food_recipe.user_id : null}));

    meal_classifications = await meal_classifications;

    if(meal_classifications) {
      update.meal_classifications = meal_classifications;
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    recipe_difficulties = await recipe_difficulties;

    if(recipe_difficulties) {
      update.recipe_difficulties = recipe_difficulties;
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    weight_units = await weight_units;

    if(weight_units) {
      update.weight_units = weight_units;

      if (update.food_recipe.output_weight_unit_id !== null) {
        update.selectedWeightUnit = update.weight_units.find((entry) => entry.id === update.food_recipe.output_weight_unit_id);
      }
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    volume_units = await volume_units;

    if(volume_units) {
      update.volume_units = volume_units;

      if (update.food_recipe.output_volume_unit_id !== null) {
        update.selectedVolumeUnit = update.volume_units.find((entry) => entry.id === update.food_recipe.output_volume_unit_id);
      }
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    food_measurements = await food_measurements;

    if(food_measurements) {
      update.food_measurements = food_measurements;
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    food_ingredients = await food_ingredients;

    if(food_ingredients) {
      update.food_ingredients = food_ingredients;
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    food_classifications = await food_classifications;

    if(food_classifications) {
      update.food_classifications = food_classifications;
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    food_recipes = await food_recipes;

    if(food_recipes) {
      update.food_recipes = food_recipes.filter((entry) => entry.id !== update.food_recipe.id);
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  getRecipeNutritionalData() {
    if (!this.ingredientsAreValid()) {
      return null;
    }

    let people_served;

    if (typeof this.state.food_recipe.people_served === 'string') {
      if (this.state.food_recipe.people_served.length > 0) {
        people_served = parseFloat(this.state.food_recipe.people_served);
      }
      else {
        people_served = 1;
      }
    }
    else {
      people_served = this.state.food_recipe.people_served;
    }

    const recipeCopy = {
      basic_ingredient_associations: [],
      advanced_ingredient_associations: [],
      people_served
    };

    for (const entry of this.state.ingredientList) {
      if (entry.isBasic) {
        recipeCopy.basic_ingredient_associations.push({
          food_ingredient_source_association: entry.food_ingredient_source_association,
          food_ingredient_measurement_association: entry.food_ingredient_measurement_association,
          quantity: entry.quantity,
        });
      }
      else {
        recipeCopy.advanced_ingredient_associations.push({
          advanced_ingredient: entry.data,
          quantity: entry.quantity,
        });
      }
    }

    return getFoodRecipeNutricionalData(recipeCopy);
  }

  autoSetMeasurementAssociation(ingredient) {
    let filteredAssociations = ingredient.data.food_measurement_associations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);

    if (ingredient.data.default_measurement_source_id !== null) {
      filteredAssociations = filteredAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
    }

    if (filteredAssociations.length === 1) {
      ingredient.food_ingredient_measurement_association = filteredAssociations[0];
    }
    else if (filteredAssociations.length > 1 && ingredient.food_ingredient_source_association.default_measurement_id) {
      let measurement_association = filteredAssociations.find((association) => {
        return association.food_measurement_id === ingredient.food_ingredient_source_association.default_measurement_id;
      });

      if (measurement_association) {
        ingredient.food_ingredient_measurement_association = measurement_association;
      }
    }
  }

  async loadBasicIngredient(ingredient) {
    let food_ingredient;

    if (this.basicIngredientDataMap.has(ingredient.data.id)) {
      food_ingredient = this.basicIngredientDataMap.get(ingredient.data.id);
    }
    else {
      food_ingredient = await getModel(setUrlParameters(`${routes.FOOD_INGREDIENT_GET_API}${ingredient.data.id}`, {load_nutrient_classification: true}));

      if(food_ingredient) {
        this.basicIngredientDataMap.set(ingredient.data.id, food_ingredient);
      }
    }

    if(food_ingredient) {
      const ingredientList = [...this.state.ingredientList];
      ingredient.data = food_ingredient;
      ingredient.loaded = true;

      let filteredAssociations = ingredient.data.source_associations;

      if (ingredient.data.default_nutrient_source_id !== null) {
        filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
      }

      if (filteredAssociations.length === 1) {
        ingredient.food_ingredient_source_association = filteredAssociations[0];

        this.autoSetMeasurementAssociation(ingredient);
      }

      this.setState({ingredientList});
    }
  }

  async loadAdvancedIngredient(ingredient) {
    let food_recipe;

    if (this.advancedIngredientDataMap.has(ingredient.data.id)) {
      food_recipe = this.advancedIngredientDataMap.get(ingredient.data.id);
    }
    else {
      food_recipe = await getModel(setUrlParameters(`${routes.FOOD_RECIPE_GET_API}${ingredient.data.id}`, {load_basic_ingredients: true}));

      if(food_recipe) {
        this.advancedIngredientDataMap.set(ingredient.data.id, food_recipe);
      }
    }

    if(food_recipe) {
      const ingredientList = [...this.state.ingredientList];
      ingredient.data = food_recipe;
      ingredient.loaded = true;

      this.setState({ingredientList});
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const additionalUpdate = {};

    if (name === 'output_weight_unit_id') {
      if (value.length > 0) {
        const output_weight_unit_id = parseInt(value);

        additionalUpdate.selectedWeightUnit = this.state.weight_units.find((entry) => entry.id === output_weight_unit_id);
      }
      else {
        additionalUpdate.selectedWeightUnit = null;
      }
    }
    else if (name === 'output_volume_unit_id') {
      if (value.length > 0) {
        const output_volume_unit_id = parseInt(value);

        additionalUpdate.selectedVolumeUnit = this.state.volume_units.find((entry) => entry.id === output_volume_unit_id);
      }
      else {
        additionalUpdate.selectedVolumeUnit = null;
      }
    }
    else if (name === 'default_measurement_id') {
      if (value.length > 0) {
        const default_measurement_id = parseInt(value);

        additionalUpdate.selectedDefaultMeasurement = this.state.food_measurements.find((entry) => entry.id === default_measurement_id);
      }
      else {
        additionalUpdate.selectedDefaultMeasurement = null;
      }
    }

    const newData = {...this.state.food_recipe, [name]: value};

    this.setState({
      food_recipe: newData,
      ...additionalUpdate
    });
  }

  inputsAreValid() {
    return this.state.food_recipe.name.length > 0 &&
           this.ingredientsAreValid();
  }

  ingredientsAreValid() {
    return this.state.food_recipe.preparation_steps.every((entry) => entry.order > 0  && entry.name.length > 0) &&
           this.state.ingredientList.every((entry) => {
             let entryIsValid = entry.quantity !== null &&
                                entry.quantity > 0 &&
                                entry.order > 0 &&
                                (entry.name === null || entry.name.length > 0);

             if (entry.isBasic) {
               entryIsValid = entryIsValid &&
                              entry.food_ingredient_source_association != null &&
                              entry.food_ingredient_measurement_association != null;
             }

             return entryIsValid;
           });
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.food_recipe};

    if (typeof this.state.food_recipe.meal_classification_id === 'string') {
      if (this.state.food_recipe.meal_classification_id.length > 0) {
        data.meal_classification_id = parseInt(this.state.food_recipe.meal_classification_id);
      }
      else {
        data.meal_classification_id = null;
      }
    }

    if (typeof this.state.food_recipe.recipe_difficulty_id === 'string') {
      if (this.state.food_recipe.recipe_difficulty_id.length > 0) {
        data.recipe_difficulty_id = parseInt(this.state.food_recipe.recipe_difficulty_id);
      }
      else {
        data.recipe_difficulty_id = null;
      }
    }

    if (typeof this.state.food_recipe.output_weight_unit_id === 'string') {
      if (this.state.food_recipe.output_weight_unit_id.length > 0) {
        data.output_weight_unit_id = parseInt(this.state.food_recipe.output_weight_unit_id);
      }
      else {
        data.output_weight_unit_id = null;
      }
    }

    if (typeof this.state.food_recipe.output_volume_unit_id === 'string') {
      if (this.state.food_recipe.output_volume_unit_id.length > 0) {
        data.output_volume_unit_id = parseInt(this.state.food_recipe.output_volume_unit_id);
      }
      else {
        data.output_volume_unit_id = null;
      }
    }

    if (typeof this.state.food_recipe.default_measurement_id === 'string') {
      if (this.state.food_recipe.default_measurement_id.length > 0) {
        data.default_measurement_id = parseInt(this.state.food_recipe.default_measurement_id);
      }
      else {
        data.default_measurement_id = null;
      }
    }

    if (typeof this.state.food_recipe.people_served === 'string') {
      if (this.state.food_recipe.people_served.length > 0) {
        data.people_served = parseInt(this.state.food_recipe.people_served);
      }
      else {
        data.people_served = null;
      }
    }

    if (typeof this.state.food_recipe.output_weight === 'string') {
      if (this.state.food_recipe.output_weight.length > 0) {
        data.output_weight = parseFloat(this.state.food_recipe.output_weight);
      }
      else {
        data.output_weight = null;
      }
    }

    if (typeof this.state.food_recipe.output_volume === 'string') {
      if (this.state.food_recipe.output_volume.length > 0) {
        data.output_volume = parseFloat(this.state.food_recipe.output_volume);
      }
      else {
        data.output_volume = null;
      }
    }

    if (typeof this.state.food_recipe.default_measurement_quantity === 'string') {
      if (this.state.food_recipe.default_measurement_quantity.length > 0) {
        data.default_measurement_quantity = parseFloat(this.state.food_recipe.default_measurement_quantity);
      }
      else {
        data.default_measurement_quantity = null;
      }
    }

    data.basic_ingredient_associations = [];
    data.advanced_ingredient_associations = [];

    for (const entry of this.state.ingredientList) {
      if (entry.isBasic) {
        const output = {
          name: entry.name,
          order: entry.order,
          quantity: entry.quantity,
          food_ingredient_source_association_id: entry.food_ingredient_source_association.id,
          food_ingredient_measurement_association_id: entry.food_ingredient_measurement_association.id,
          recipe_preparation_step_id: entry.recipe_preparation_step ? entry.quantity.recipe_preparation_step.id : null
        };

        if (entry.id !== null) {
          output.id = entry.id;
        }

        data.basic_ingredient_associations.push(output);
      }
      else {
        const output = {
          name: entry.name,
          order: entry.order,
          quantity: entry.quantity,
          advanced_ingredient_id: entry.data.id,
          recipe_preparation_step_id: entry.recipe_preparation_step ? entry.quantity.recipe_preparation_step.id : null
        };

        if (entry.id !== null) {
          output.id = entry.id;
        }

        data.advanced_ingredient_associations.push(output);
      }
    }

    data.preparation_steps = data.preparation_steps.map((entry) => {
      const output = {
        order:entry.order,
        name:entry.name,
        step_instruction:entry.step_instruction,
        step_duration:entry.step_duration
      };

      if (entry.id !== null) {
        output.id = entry.id;
      }

      return output;
    });

    const recipeNutritionalData = this.getRecipeNutritionalData();

    const carbohydrateWeight = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.carbohydrateWeight;
    const proteinWeight = PROTEIN_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.proteinWeight;
    const fatWeight = FAT_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.fatWeight;

    const totalMean = carbohydrateWeight + proteinWeight + fatWeight;

    data.total_energy = recipeNutritionalData.totalEnergy;
    data.total_energy_unit_id = recipeNutritionalData.energyUnit.id;
    data.total_carbohydrate_percentage = carbohydrateWeight / totalMean;
    data.total_protein_percentage = proteinWeight / totalMean;
    data.total_fat_percentage = fatWeight / totalMean;

    try {
      await patchModel(`${routes.FOOD_RECIPE_PATCH_API}${this.props.match.params.foodRecipeId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'people_served':
                    warningMessages.push('Rendimento deve ser no mínimo 1');
                    highlights.push('people_served');

                    break;
                  default:
                }
              }

              break;
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            // case 209:
            //   warningMessages.push('Sessão do usuário expirada');
            //
            //   break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    if (this.state.student !== null) {
      this.props.history.replace(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}`);
    }
    else {
      this.props.history.replace(routes.FOOD_RECIPE_LIST_PATH);
    }
  }

  addBasicIngredient(basicIngredient) {
    const ingredientList = [...this.state.ingredientList];

    const newIngredient = {
      id: null,
      key: `${ingredientList.length}_basic`, //`${ingredientList.length}_basic_${basicIngredient.id}`
      food_ingredient_source_association: null,
      food_ingredient_measurement_association: null,
      recipe_preparation_step: null,
      name: null,
      order: ingredientList.length + 1,
      quantity: null,
      isBasic: true,
      loaded: false,
      has_prescription_recipe_adjustments: false,
      data: basicIngredient
    };

    this.loadBasicIngredient(newIngredient);

    ingredientList.push(newIngredient);

    ingredientList.sort((a, b) => a.order - b.order);

    this.setState({ingredientList});
  }

  addAdvancedIngredient(advancedIngredient) {
    const ingredientList = [...this.state.ingredientList];

    const newIngredient = {
      id: null,
      key: `${ingredientList.length}_advanced`, //`${ingredientList.length}_advanced_${advancedIngredient.id}`
      recipe_preparation_step: null,
      name: null,
      order: ingredientList.length + 1,
      quantity: 1,
      isBasic: false,
      loaded: false,
      data: advancedIngredient
    };

    this.loadAdvancedIngredient(newIngredient);

    ingredientList.push(newIngredient);

    ingredientList.sort((a, b) => a.order - b.order);

    this.setState({ingredientList});
  }

  onSelectIngredientSourceAssociation(ingredient, associationId) {
    const ingredientList = [...this.state.ingredientList];

    ingredient.food_ingredient_source_association = ingredient.data.source_associations.find((association) => association.id === associationId);

    ingredient.food_ingredient_measurement_association = null;
    this.autoSetMeasurementAssociation(ingredient);

    this.setState({ingredientList});
  }

  onUpdateIngredientQuantity(ingredient, quantity) {
    const ingredientList = [...this.state.ingredientList];

    ingredient.quantity = quantity;

    this.setState({ingredientList});
  }

  onUpdateIngredientName(ingredient, name) {
    const ingredientList = [...this.state.ingredientList];

    ingredient.name = name;

    this.setState({ingredientList});
  }

  onSelectIngredientMeasurementAssociation(ingredient, associationId) {
    const ingredientList = [...this.state.ingredientList];

    ingredient.food_ingredient_measurement_association = ingredient.data.food_measurement_associations.find((association) => association.id === associationId);

    this.setState({ingredientList});
  }

  onRemoveIngredient(ingredient) {
    const ingredientList = [...this.state.ingredientList.filter((entry) => entry.key !== ingredient.key)];

    ingredientList.forEach((entry, index) => {
      entry.order = index + 1;
    });

    this.setState({ingredientList});
  }

  onAddPreparationStep() {
    const food_recipe = {...this.state.food_recipe};

    food_recipe.preparation_steps = [...food_recipe.preparation_steps];

    food_recipe.preparation_steps.push({
      id: null,
      order: food_recipe.preparation_steps.length + 1,
      name: "",
      step_instruction: "",
      step_duration: null,
    });

    this.setState({food_recipe});
  }

  onSwitchStepOrder(step, offset) {
    const entriesCount = this.state.food_recipe.preparation_steps.length;
    const originalOrder = step.order;
    const newOrder = step.order + offset;

    if(newOrder <= 0 || newOrder > entriesCount) {
      return;
    }

    const listCopy = [...this.state.food_recipe.preparation_steps];

    let switchedStep = this.state.food_recipe.preparation_steps.find((entry) => entry.order === newOrder);

    step.order = newOrder;
    switchedStep.order = originalOrder;

    listCopy.sort((a, b) => a.order - b.order);

    this.setState({
      food_recipe: {
        ...this.state.food_recipe,
        preparation_steps: listCopy
      }
    });
  }

  onSwitchItemOrder(item, offset) {
    const entriesCount = this.state.ingredientList.length;
    const originalOrder = item.order;
    const newOrder = item.order + offset;

    if(newOrder <= 0 || newOrder > entriesCount) {
      return;
    }

    const listCopy = [...this.state.ingredientList];

    let switchedItem = this.state.ingredientList.find((entry) => entry.order === newOrder);

    item.order = newOrder;
    switchedItem.order = originalOrder;

    listCopy.sort((a, b) => a.order - b.order);

    this.setState({
      ingredientList: listCopy
    });
  }

  onRemovePreparationStep(step) {
    const listCopy = [...this.state.food_recipe.preparation_steps.filter((entry) => entry.order !== step.order)];

    listCopy.forEach((entry, index) => {
      entry.order = index + 1;
    });

    this.setState({
      food_recipe: {
        ...this.state.food_recipe,
        preparation_steps: listCopy
      }
    });
  }

  onUpdateStep(step, field, value) {
    step[field] = value;

    const listCopy = [...this.state.food_recipe.preparation_steps];

    this.setState({
      food_recipe: {
        ...this.state.food_recipe,
        preparation_steps: listCopy
      }
    });
  }

  onCalculateFinalWeight() {
    if (!this.inputsAreValid() || this.state.selectedWeightUnit === null) {
      return;
    }

    const recipeCopy = {
      basic_ingredient_associations: [],
      advanced_ingredient_associations: [],
    };

    for (const entry of this.state.ingredientList) {
      if (entry.isBasic) {
        recipeCopy.basic_ingredient_associations.push({
          food_ingredient_source_association: entry.food_ingredient_source_association,
          food_ingredient_measurement_association: entry.food_ingredient_measurement_association,
          quantity: entry.quantity,
        });
      }
      else {
        recipeCopy.advanced_ingredient_associations.push({
          advanced_ingredient: entry.data,
          quantity: entry.quantity,
        });
      }
    }

    const totalWeight = getFoodRecipeFinalWeight(recipeCopy) / this.state.selectedWeightUnit.conversion_constant_to_gram;

    const newData = {...this.state.food_recipe, output_weight: totalWeight};

    this.setState({food_recipe: newData});
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}` : routes.FOOD_RECIPE_LIST_PATH,
            text: this.state.student !== null ? 'Gerenciar aluno' : 'Listar receitas'
          },
          {
            path: `${routes.FOOD_RECIPE_EDIT_PATH}${this.props.match.params.foodRecipeId}`,
            text: "Editar receita"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar receita"
        loading={this.state.loading}
      >

        <FoodRecipeData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          food_recipe={this.state.food_recipe}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          ingredientsAreValid={this.ingredientsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}` : routes.FOOD_RECIPE_LIST_PATH}
          student={this.state.student}
          food_recipes={this.state.food_recipes}
          meal_classifications={this.state.meal_classifications}
          recipe_difficulties={this.state.recipe_difficulties}
          weight_units={this.state.weight_units}
          volume_units={this.state.volume_units}
          food_measurements={this.state.food_measurements}
          food_ingredients={this.state.food_ingredients}
          food_classifications={this.state.food_classifications}
          selectedWeightUnit={this.state.selectedWeightUnit}
          selectedVolumeUnit={this.state.selectedVolumeUnit}
          selectedDefaultMeasurement={this.state.selectedDefaultMeasurement}
          ingredientList={this.state.ingredientList}
          getRecipeNutritionalData={() => this.getRecipeNutritionalData()}
          userPermissionIds={this.props.userPermissionIds}
          onAddPreparationStep={() => this.onAddPreparationStep()}
          onSwitchStepOrder={(step, offset) => this.onSwitchStepOrder(step, offset)}
          onSwitchItemOrder={(item, offset) => this.onSwitchItemOrder(item, offset)}
          onRemovePreparationStep={(step) => this.onRemovePreparationStep(step)}
          onUpdateStep={(step, field, value) => this.onUpdateStep(step, field, value)}
          addBasicIngredient={(ingredient) => this.addBasicIngredient(ingredient)}
          addAdvancedIngredient={(ingredient) => this.addAdvancedIngredient(ingredient)}
          onSelectIngredientSourceAssociation={(ingredient, associationId) => this.onSelectIngredientSourceAssociation(ingredient, associationId)}
          onSelectIngredientMeasurementAssociation={(ingredient, associationId) => this.onSelectIngredientMeasurementAssociation(ingredient, associationId)}
          onUpdateIngredientQuantity={(ingredient, quantity) => this.onUpdateIngredientQuantity(ingredient, quantity)}
          onUpdateIngredientName={(ingredient, name) => this.onUpdateIngredientName(ingredient, name)}
          onRemoveIngredient={(ingredient) => this.onRemoveIngredient(ingredient)}
          onCalculateFinalWeight={() => this.onCalculateFinalWeight()}
        />

      </ContentFrame>
    );
  }
}

export default FoodRecipeEdit;
