import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';
import {getAsLocalDate, getLocalDateIsoString} from '../utils/functions';
import {BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT} from '../utils/fyd_food';

const WEIGHT_CLASSIFICATION_TEXT_MAP = [
  'Abaixo do peso',
  'Normal',
  'Sobrepeso',
  'Obesidade 1 (leve)',
  'Obesidade 2 (moderada)',
  'Obesidade 3 (mórbida)'
];

const WEIGHT_CLASSIFICATION_COLOR_MAP = [
  '#ffbdbd',
  '#bbdaba',
  '#feffce',
  '#ffdede',
  '#ffbdbd',
  '#ff9999'
];

function WeightPoint(weight, date) {
  return {
    y: weight,
    x: getAsLocalDate(date)
  }
}

export {WeightPoint};


class DietGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getProcessedData() {
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;

    const dateMarkers = [];

    let weekIndex = 1;
    let currentWeekDate = new Date(startDate);

    while (currentWeekDate <= endDate && weekIndex < 100) {
      dateMarkers.push({
        value: new Date(currentWeekDate),
        showOnTop: true,
        label: getLocalDateIsoString(currentWeekDate) !== getLocalDateIsoString(endDate) ? `Semana ${weekIndex} ${currentWeekDate.toLocaleDateString()}` : 'Fim',
        labelPlacement: 'outside',
        labelBackgroundColor: 'transparent'
      });

      currentWeekDate.setDate(currentWeekDate.getDate() + 7);
      weekIndex += 1;
    }

    const minDate = new Date(startDate);
    minDate.setDate(minDate.getDate() - 1);
    const maxDate = new Date(endDate);
    maxDate.setDate(maxDate.getDate() + 1);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (today >= startDate && today <= endDate ) {
      dateMarkers.push({
        value: today,
        showOnTop: true,
        label: "Hoje",
        color: '#919191',
        labelFontColor: '#919191',
        labelPlacement: 'inside',
        labelBackgroundColor: 'transparent'
      });
    }

    const weeklyEnergyResult = this.props.weeklyDietMeanEnergyInput - (7 * this.props.basalMetabolicRate * this.props.basalMetabolicMultiplier) - this.props.weeklyEnergyExpenseOffset;
    const weeklyWeightDiffResult = weeklyEnergyResult / BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT;

    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const weeksPassed = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7;

    const finalTargetWeight = this.props.initialWeight + (weeklyWeightDiffResult * weeksPassed);

    const squaredHeight = Math.pow(this.props.bodyHeight, 2);

    const weightClassification = [
      18.5 * squaredHeight,
      25 * squaredHeight,
      30 * squaredHeight,
      35 * squaredHeight,
      40 * squaredHeight,
      40 * squaredHeight + 10,
    ];

    let minWeight = Math.min(this.props.initialWeight, finalTargetWeight);
    let maxWeight = Math.max(this.props.initialWeight, finalTargetWeight);

    const weightPoints = [];

    if (this.props.weightPoints) {
      for (const point of this.props.weightPoints) {
        weightPoints.push(point);

        minWeight = Math.min(minWeight, point.y);
        maxWeight = Math.max(maxWeight, point.y);
      }
    }

    let finalHistoryTargetWeight = null;

    if (this.props.weeklyHistoryMeanEnergyInput) {
      const weeklyHistoryEnergyResult = this.props.weeklyHistoryMeanEnergyInput - (7 * this.props.basalMetabolicRate * this.props.basalMetabolicMultiplier) - this.props.weeklyEnergyExpenseOffset;
      const weeklyHistoryWeightDiffResult = weeklyHistoryEnergyResult / BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT;
      finalHistoryTargetWeight = this.props.initialWeight + (weeklyHistoryWeightDiffResult * weeksPassed);

      // minWeight = Math.min(minWeight, finalHistoryTargetWeight);
      // maxWeight = Math.max(maxWeight, finalHistoryTargetWeight);
    }

    minWeight -= 3;
    maxWeight += 3;

    minWeight = Math.max(minWeight, weightClassification[0]);
    maxWeight = Math.min(maxWeight, weightClassification[5]);

    const graphData = weightClassification.map((entry, index) => {
      let rangeValue = entry;

      if (index > 0) {
        rangeValue -= weightClassification[index - 1];
      }

      const upperValue = entry;
      let lowerValue = index > 0 ? weightClassification[index - 1] : 0;

      return {
        type: "stackedArea",
        showInLegend: lowerValue <= maxWeight && upperValue >= minWeight,
        name: WEIGHT_CLASSIFICATION_TEXT_MAP[index],
        color: WEIGHT_CLASSIFICATION_COLOR_MAP[index],
        lineThickness: 0,
        fillOpacity: 0.8,
        markerType: "none",
        legendMarkerType: "square",
        dataPoints: [
          { x: minDate, y: rangeValue },
          { x: maxDate, y: rangeValue },
        ]
      };
    });

    if (finalHistoryTargetWeight !== null) {
      graphData.push({
        type: "line",
        showInLegend: "true",
        name: "Histórico",
        color: "#7faded",
        visible: false,
        lineDashType: "dash",
        markerType: "none",
        legendMarkerType: "triangle",
  		  legendMarkerColor :"white",
        dataPoints: [
          { x: startDate, y: this.props.initialWeight },
          { x: endDate, y: finalHistoryTargetWeight },
        ]
      });
    }

    graphData.push({
      type: "line",
      showInLegend: "true",
      name: "Planejamento",
      color: "#878787",
      lineDashType: "dash",
      markerType: "none",
      legendMarkerType: "triangle",
		  legendMarkerColor :"white",
      dataPoints: [
        { x: startDate, y: this.props.initialWeight },
        { x: endDate, y: finalTargetWeight },
      ]
    });

    if (weightPoints.length > 0) {
      graphData.push({
        type: "line",
        showInLegend: "true",
        name: "Peso",
        color: "#696969",
        dataPoints: weightPoints
      });
    }

    return {
      graphData,
      minWeight,
      maxWeight,
      minDate,
      maxDate,
      dateMarkers
    };
  }

  getOptions() {
    const processedData = this.getProcessedData();

    const options = {
			animationEnabled: true,
      culture: 'pt-BR',
			toolTip: {
				// shared: true,
        contentFormatter: (e) => {
  				let content = "";

  				for (let i = 0; i < e.entries.length; i++) {
            const value = Math.round(10 * e.entries[i].dataPoint.y) / 10;
            content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataPoint.x.toLocaleDateString()}:</span> ${value} kg<br/>`;
  				}

  				return content;
  			}
			},
      axisX: {
        valueFormatString: "DD/MM/YYYY",
        minimum: processedData.minDate,
		    maximum: processedData.maxDate,
        interval: 1,
        intervalType: "week",
        tickColor: 'none',
        labelFormatter: (value) => '',
        labelTextAlign: 'center',
        stripLines: processedData.dateMarkers,
        labelMaxWidth: 75
			},
      axisY: {
        minimum: processedData.minWeight,
		    maximum: processedData.maxWeight,
        suffix: ' kg'
			},
      legend: {
				verticalAlign: this.props.legendVerticalAlign || "center",
				horizontalAlign: this.props.legendHorizontalAlign || "right",
				reversed: this.props.normalLegendOrder ? false : true,
        itemclick: (e) => this.toggleDataSeries(e)
			},
			data: processedData.graphData
		};

    // if(this.props.isHorizontal) {
    //   options.axisX.reversed = true;
    // }

    if(this.props.title) {
      options.title = {
				text: this.props.title,
        fontFamily: "Verdana",
        fontWeight: "normal",
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.stripLine) {
      options.axisY.stripLines = [this.props.stripLine];
    }

    if(this.props.width && this.props.width !== null) {
      options.width = this.props.width;
    }

    return options;
  }

  toggleDataSeries(e){
    if (e.dataSeries.name !== "Histórico") {
      return;
    }

		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
    if (!this.props.bodyHeight) {
      return <p>Altura não especificada</p>
    }
    else if (!this.props.startDate) {
      return <p>Data inicial não especificada</p>
    }
    else if (!this.props.endDate) {
      return <p>Data final não especificada</p>
    }
    else if (!this.props.initialWeight) {
      return <p>Peso inicial não especificado</p>
    }
    else if (!this.props.basalMetabolicRate) {
      return <p>Taxa metabólica basal não especificada</p>
    }
    else if (!this.props.weeklyDietMeanEnergyInput) {
      return <p>Consumo energético semanal não especificado</p>
    }
    else if (!this.props.basalMetabolicMultiplier && this.props.basalMetabolicMultiplier !== 0) {
      return <p>Nível de atividade física não especificado</p>
    }

    const timeDiff = Math.abs(this.props.endDate.getTime() - this.props.startDate.getTime());

    const weeksCount = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7);

		return (
  		<div
        className={this.props.className}
        style={{minWidth: `${7.5 * weeksCount}em`}}
      >
        {!this.props.hidden &&
          <CanvasJSReact.CanvasJSChart
            options={this.getOptions()}
    				onRef={ref => this.chart = ref}
    			/>
        }
  		</div>
		);
	}
}

export default DietGraph;
