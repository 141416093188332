import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel} from '../../utils/functions';
import ExerciseActivityTypeData from './exercise_activity_type_data';

class ExerciseActivityTypeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_type: {
        name: "",
        short_name: "",
        description: "",
        color: '#000000'
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    let activity_type = getModel(`${routes.EXERCISE_ACTIVITY_TYPE_GET_API}${this.props.match.params.exerciseActivityTypeId}`);

    activity_type = await activity_type;

    let update = {
      loading: false
    }

    if(activity_type) {
      update.activity_type = activity_type;
    }
    else {
      this.props.history.replace(routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH);
      return;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const newData = {...this.state.activity_type, [name]: value};

    this.setState({
      activity_type: newData
    });
  }

  inputsAreValid() {
    return this.state.activity_type.name.length > 0 &&
           this.state.activity_type.short_name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.activity_type}

    try {
      await patchModel(`${routes.EXERCISE_ACTIVITY_TYPE_PATCH_API}${this.props.match.params.exerciseActivityTypeId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  case 'short_name':
                    warningMessages.push('Abreviação já cadastrada');
                    highlights.push('short_name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH,
            text: this.state.screenWidth > 480 ? 'Listar capacidades de exercícios' : 'Capacidades de exercícios'
          },
          {
            path: `${routes.EXERCISE_ACTIVITY_TYPE_EDIT_PATH}${this.props.match.params.exerciseActivityTypeId}`,
            text: "Editar capacidade"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar capacidade de exercício"
        loading={this.state.loading}
      >

        <ExerciseActivityTypeData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          activity_type={this.state.activity_type}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.EXERCISE_ACTIVITY_TYPE_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default ExerciseActivityTypeEdit;
