import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {DEFAULT_FOOD_INFO_SOURCE} from '../../../constants';
import {postModel, getModels, setUrlParameters} from '../../../utils/functions';
import FoodIngredientData from './food_ingredient_data';

class FoodIngredientAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_ingredient: {
        name: "",
        food_classification_id: null,
        default_nutrient_source_id: DEFAULT_FOOD_INFO_SOURCE.id,
        default_measurement_source_id: DEFAULT_FOOD_INFO_SOURCE.id,
        description: "",
        food_allergens: [],
      },
      info_sources: [],
      food_classifications: [],
      nutrient_classifications: [],
      food_allergens: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let food_classifications = getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));
    let info_sources = getModels(routes.FOOD_INFO_SOURCES_GET_API);
    let nutrient_classifications = getModels(routes.NUTRIENT_CLASSIFICATIONS_GET_API);
    let food_allergens = getModels(routes.FOOD_ALLERGENS_GET_API);

    food_classifications = await food_classifications;

    if(food_classifications) {
      update.food_classifications = food_classifications;
    }

    info_sources = await info_sources;

    if(info_sources) {
      update.info_sources = info_sources;
    }

    nutrient_classifications = await nutrient_classifications;

    if(nutrient_classifications) {
      update.nutrient_classifications = nutrient_classifications;

      update.nutrient_classifications.sort((a, b) => a.id - b.id)
    }

    food_allergens = await food_allergens;

    if(food_allergens) {
      update.food_allergens = food_allergens;
      update.food_allergens.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.food_ingredient, [name]: value};

    this.setState({
      food_ingredient: newData
    });
  }

  onSetFoodAllergen(allergen, include) {
    let foodAllergensCopy = this.state.food_ingredient.food_allergens.map((entry) => ({...entry}));

    foodAllergensCopy = foodAllergensCopy.filter((entry) => entry.id !== allergen.id);

    if (include) {
      foodAllergensCopy.push({...allergen});
    }

    const food_ingredient = {
      ...this.state.food_ingredient,
      food_allergens: foodAllergensCopy
    };

    this.setState({food_ingredient});
  }

  inputsAreValid() {
    return this.state.food_ingredient.name.length > 0 &&
           (this.state.food_ingredient.food_classification_id !== null && this.state.food_ingredient.food_classification_id > 0);
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      name: this.state.food_ingredient.name,
      description: this.state.food_ingredient.description,
      food_classification_id: parseInt(this.state.food_ingredient.food_classification_id),
      default_nutrient_source_id: this.state.food_ingredient.default_nutrient_source_id,
      default_measurement_source_id: this.state.food_ingredient.default_measurement_source_id,
      food_allergen_ids: [...this.state.food_ingredient.food_allergens.map((entry) => entry.id)]
    }

    if (typeof this.state.food_ingredient.default_nutrient_source_id === 'string') {
      if (this.state.food_ingredient.default_nutrient_source_id.length > 0) {
        data.default_nutrient_source_id = parseInt(this.state.food_ingredient.default_nutrient_source_id);
      }
      else {
        data.default_nutrient_source_id = null;
      }
    }

    if (typeof this.state.food_ingredient.default_measurement_source_id === 'string') {
      if (this.state.food_ingredient.default_measurement_source_id.length > 0) {
        data.default_measurement_source_id = parseInt(this.state.food_ingredient.default_measurement_source_id);
      }
      else {
        data.default_measurement_source_id = null;
      }
    }

    let response;

    try {
      response = await postModel(routes.FOOD_INGREDIENT_POST_API, data, true);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('value');
            //
            //         break;
            //       case 'penalty_value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('penalty_value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(`${routes.FOOD_INGREDIENT_EDIT_PATH}${response.model_saved.id}`);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FOOD_INGREDIENT_LIST_PATH,
            text: "Listar ingredientes"
          },
          {
            path: routes.FOOD_INGREDIENT_ADD_PATH,
            text: "Adicionar ingrediente"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Adicionar ingrediente"
        loading={this.state.loading}
      >

        <FoodIngredientData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          food_ingredient={this.state.food_ingredient}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          onSetFoodAllergen={(allergen, include) => this.onSetFoodAllergen(allergen, include)}
          highlights={this.state.highlights}
          onCancelPath={routes.FOOD_INGREDIENT_LIST_PATH}
          food_classifications={this.state.food_classifications}
          info_sources={this.state.info_sources}
          nutrient_classifications={this.state.nutrient_classifications}
          food_allergens={this.state.food_allergens}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default FoodIngredientAdd;
