import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        LOCAL_CACHE_SELECTED_PAYMENT_DEVICE_ID_KEY,
        STONE_PAYMENT_REQUEST_PAID_STATUS,
        PAYMENT_METHOD_CREDIT_CARD_ID,
        PAYMENT_METHOD_CREDIT_CARD_DEBT_ID,
        PAYMENT_METHOD_RECURRENT_ID} from '../../constants';
import {postModel, patchModel, getModel, getModels, getAsLocalDate, getLocalDateIsoString} from '../../utils/functions';
import FinancialMovementData from './financial_movement_data';

class FinancialMovementEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      financial_movement: {
        cost_center_id: '',
        financial_category_id: '',
        month_reference: '',
        description: '',
        payment_method_id: '',
        user_id: null,
        transactions: [],
        search_tag_ids: [],
        payment_device_requests: []
      },
      costCenters: [],
      financialCategories: [],
      paymentMethods: [],
      financial_search_tags: [],
      availablePaymentDevices: [],
      selectedPaymentDevice: null,
      savePaymentDeviceSelected: true,
      sendToPaymentDevice: false,
      showPaymentDeviceOverlay: false,
      paymentReceived: null,
      customerName: '',
      customerEmail: '',
      totalValue: '',
      transactionCount: "",
      firstPaymentDate: "",
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      confirmationWindowTitle: "",
      confirmationWindowDescription: "",
      confirmationWindowVisible: false
    };
  }

  createTransaction(effective_date='', value='') {
    return {
      effective_date: effective_date,
      value: value,
      completed: false,
      nf_coupon_id: null
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async componentDidMount() {
    let financial_movement = this.updateFinancialMovement(false);
    let costCenters = getModels(routes.COST_CENTERS_GET_API);
    let financialCategories = getModels(routes.FINANCIAL_CATEGORIES_GET_API);
    let paymentMethods = getModels(routes.PAYMENT_METHODS_GET_API);
    let availablePaymentDevices = getModels(routes.PAYMENT_DEVICES_AVAILABLE_GET_API);
    let financial_search_tags = this.getFinancialSearchTags();

    let update = {
      loading: false,
      showPaymentDeviceOverlay: false
    }

    financial_movement = await financial_movement;

    if(financial_movement !== null) {
      update = {...update, ...financial_movement};
    }
    else {
      // this.props.history.replace(this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);
      this.props.history.goBack();
    }

    costCenters = await costCenters;

    if(costCenters) {
      update.costCenters = costCenters;
    }

    financialCategories = await financialCategories;

    if(financialCategories) {
      update.financialCategories = financialCategories;
    }

    paymentMethods = await paymentMethods;

    if(paymentMethods) {
      update.paymentMethods = paymentMethods;
      update.paymentMethods.sort((a, b) => a.name.localeCompare(b.name));
    }

    financial_search_tags = await financial_search_tags;

    if(financial_search_tags) {
      update.financial_search_tags = financial_search_tags;
      update.financial_search_tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    availablePaymentDevices = await availablePaymentDevices;

    if(availablePaymentDevices) {
      update.availablePaymentDevices = availablePaymentDevices;
      update.availablePaymentDevices.sort((a, b) => a.name.localeCompare(b.name));

      let queryParameters = (new URLSearchParams(this.props.location.search));

      if (!update.showPaymentDeviceOverlay) {
        update.showPaymentDeviceOverlay = queryParameters.get('initiate_payment_process');

        if (!update.showPaymentDeviceOverlay) {
          update.showPaymentDeviceOverlay = false;
        }
      }

      if (update.availablePaymentDevices.length === 1) {
        update.selectedPaymentDevice = update.availablePaymentDevices[0].id;
      }
      else {
        let selectedPaymentDevice = localStorage.getItem(LOCAL_CACHE_SELECTED_PAYMENT_DEVICE_ID_KEY);

        if(selectedPaymentDevice) {
          update.selectedPaymentDevice = parseInt(selectedPaymentDevice);
        }
      }
    }

    this.setState(update);
  }

  async updateFinancialMovement(updateState = true) {
    let financial_movement = await getModel(`${routes.FINANCIAL_MOVEMENT_GET_API}${this.props.match.params.financialMovementId}`);

    const update = {};

    if(financial_movement) {
      update.financial_movement = {...this.state.financial_movement, ...financial_movement};

      update.transactionCount = financial_movement.transactions.length;
      update.firstPaymentDate = financial_movement.transactions[0].effective_date;
      update.totalValue = financial_movement.transactions.reduce((sum, transaction) => {
        const value = transaction.value;
        transaction.value = transaction.value.toFixed(2);

        return sum + value;
      }, 0).toFixed(2);

      update.paymentReceived = financial_movement.payment_device_requests.find((entry) => entry.closed && entry.status === STONE_PAYMENT_REQUEST_PAID_STATUS) || null;
      update.showPaymentDeviceOverlay = financial_movement.payment_device_requests.some((entry) => !entry.closed);
    }
    else if (!updateState) {
      return null;
    }

    if (updateState) {
      this.setState(update);
    }
    else {
      return update;
    }
  }

  getTransactionsCopy(convertValues = false) {
    return this.state.financial_movement.transactions.map((transaction) => ({
      effective_date: transaction.effective_date,
      completed: transaction.completed,
      value: convertValues ? parseFloat(transaction.value) : transaction.value,
      nf_coupon_id: transaction.nf_coupon_id || null,
    }));
  }

  handleInputChange(event) {
    const target = event.target;
    let name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'sendToPaymentDevice' ||
        name === 'savePaymentDeviceSelected' ||
        name === 'customerName' ||
        name === 'customerEmail') {
      this.setState({
        [name]: value
      });

      return;
    }
    else if (name === 'selectedPaymentDevice') {
      this.setState({
        selectedPaymentDevice: parseInt(value)
      });

      return;
    }

    let ignoreFinancialMovementUpdate = false;

    const stateUpdate = {};
    const additionalUpdate = {};

    let transactionCount = this.state.transactionCount;
    let firstPaymentDate = this.state.firstPaymentDate;
    let totalValue = this.state.totalValue;

    if(name === 'cost_center_id') {
      additionalUpdate.financial_category_id = '';
    }

    if(name === 'payment_method_id' && value.length >= 0 && this.state.firstPaymentDate.length <= 0) {
      additionalUpdate.payment_method_id = value;

      name = 'firstPaymentDate';

      let firstPaymentDate = new Date();

      if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_ID || parseInt(value) === PAYMENT_METHOD_RECURRENT_ID) {
        firstPaymentDate.setDate(firstPaymentDate.getDate() + 30);
      }
      else if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_DEBT_ID) {
        firstPaymentDate.setDate(firstPaymentDate.getDate() + 1);
      }

      value = getLocalDateIsoString(firstPaymentDate);
    }

    if(name.startsWith('transactions:')) {
      const selection = name.split(':');
      const transactionPosition = parseInt(selection[1]);

      name = 'transactions';

      const transactionsCopy = this.getTransactionsCopy();

      transactionsCopy[transactionPosition][selection[2]] = value;

      if(selection[2] === 'value') {
        stateUpdate.totalValue = transactionsCopy.reduce((sum, transaction) => sum + parseFloat(transaction.value), 0).toFixed(2);
      }

      value = transactionsCopy;
    }

    if(name === 'transactionCount' || name === 'firstPaymentDate' || name === 'totalValue') {
      ignoreFinancialMovementUpdate = true;
      stateUpdate[name] = value;

      if(value) {
        if(name === 'transactionCount' && this.state.transactionCount !== value) {
          transactionCount = value;
        }
        else if(name === 'firstPaymentDate' && this.state.firstPaymentDate !== value) {
          firstPaymentDate = value;
        }
        else if(name === 'totalValue' && this.state.totalValue !== value) {
          totalValue = value;
        }
      }

      if(transactionCount && firstPaymentDate && totalValue && totalValue > 0) {
        ignoreFinancialMovementUpdate = false;
        name = 'transactions';

        totalValue = parseFloat(totalValue);
        transactionCount = parseFloat(transactionCount);

        const transactions = [];

        const initialDate = getAsLocalDate(firstPaymentDate);

        let transactionValue = (Math.floor(totalValue/transactionCount * 100) / 100).toFixed(2);

        for(let i=0; i<transactionCount; i++) {
          transactions.push(this.createTransaction(getLocalDateIsoString(initialDate), transactionValue));
          initialDate.setMonth(initialDate.getMonth()+1);
        }

        value = transactions;
      }
    }

    if(ignoreFinancialMovementUpdate) {
      this.setState({
        ...stateUpdate,
        financial_movement: {...this.state.financial_movement, ...additionalUpdate}
      });
    }
    else {
      const newData = {...this.state.financial_movement, ...additionalUpdate, [name]: value};

      this.setState({
        ...stateUpdate,
        financial_movement: newData
      });
    }
  }

  inputsAreValid() {
    return (this.state.financial_movement.payment_method_id && this.state.financial_movement.payment_method_id > 0) &&
           (this.state.financial_movement.cost_center_id && this.state.financial_movement.cost_center_id > 0) &&
           (this.state.financial_movement.financial_category_id && this.state.financial_movement.financial_category_id > 0) &&
           this.state.financial_movement.transactions.length > 0 &&
           this.state.financial_movement.transactions.every((transaction) => (
             transaction.effective_date.length > 0 &&
             transaction.value > 0
           ));
  }

  maySendToPaymentDevice() {
    if (this.isExpense() ||
        this.state.paymentReceived !== null ||
        this.state.availablePaymentDevices.length <= 0 ||
        this.state.financial_movement.payment_method_id.length <= 0) {
      return false;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.financial_movement.payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return false;
    }

    return paymentMethod.has_payment_device_integration &&
          (paymentMethod.installments_may_apply ||
            (!paymentMethod.installments_may_apply && this.state.financial_movement.transactions.length === 1));
  }

  getPaymentMethodAlertMessage() {
    if (this.isExpense() ||
        this.state.financial_movement.payment_method_id.length <= 0) {
      return null;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.financial_movement.payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return null;
    }

    if (paymentMethod.has_payment_device_integration && !paymentMethod.installments_may_apply) {
      return 'Envio para maquininha: somente para pagamentos à vista!';
    }

    return null;
  }

  onCancelPaymentProcess() {
    this.setState({showPaymentDeviceOverlay: false});
  }

  async onSendPaymentToDevice() {
    if (this.state.savePaymentDeviceSelected) {
      localStorage.setItem(LOCAL_CACHE_SELECTED_PAYMENT_DEVICE_ID_KEY, this.state.selectedPaymentDevice);
    }

    const data = {
      payment_device_id: this.state.selectedPaymentDevice,
      financial_entry_id: parseInt(this.props.match.params.financialMovementId),
      customer_name: null,
      customer_email: null
    };

    if (this.state.financial_movement.user_id === null) {
      data.customer_name = this.state.customerName;
      data.customer_email = this.state.customerEmail;
    }

    try{
      const response = await postModel(routes.SEND_PAYMENT_TO_DEVICE_POST_API, data, true);

      if(response) {
        const financial_movement = {...this.state.financial_movement};
        financial_movement.payment_device_requests.push(response.model_saved);

        this.setState({
          financial_movement
        });

        return true;
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmationWindowTitle: "Falha ao enviar pagamento",
        confirmationWindowDescription: errorDescription,
        confirmationWindowVisible: true
      });

      return false;
    }
  }

  async saveData() {
    if(parseInt(this.state.transactionCount) > 72) {
      this.setState({
        highlights: ['transactionCount'],
        showWarningMessage: true,
        warningMessage: "Número de parcelas maior que 72."
      });

      window.scrollTo(0, 0);

      return;
    }

    if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
      let lastValue = null;

      let valueHighlights = []

      for (const transaction of this.state.financial_movement.transactions) {
        valueHighlights.push(`transactions:${valueHighlights.length}:value`);

        if (lastValue !== null && Math.abs(lastValue - transaction.value) > 0.01) {
          this.setState({
            highlights: valueHighlights,
            showWarningMessage: true,
            warningMessage: "Valor de parcelas devem ser iguais para envio de cobrança na maquininha."
          });

          window.scrollTo(0, 0);

          return;
        }

        lastValue = transaction.value;
      }
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      ...this.state.financial_movement,
      transactions: this.getTransactionsCopy(true)
    }
    data.cost_center_id = parseInt(data.cost_center_id);
    data.financial_category_id = parseInt(data.financial_category_id);
    data.payment_method_id = parseInt(data.payment_method_id);

    let showPaymentDeviceOverlay = false;

    try {
      await patchModel(`${routes.FINANCIAL_MOVEMENT_PATCH_API}${this.props.match.params.financialMovementId}`, data);

      if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
        showPaymentDeviceOverlay = true;
      }
      else {
        if (this.props.history.length > 1) {
          this.props.history.goBack();
        }
        else {
          this.props.history.push(this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);
        }

        return;
      }

      this.setState({
        showPaymentDeviceOverlay,
        loading: false
      });
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser maior que 0');
            //         highlights.push('value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'search_tag.type':
                    warningMessages.push(`Palavra-chave "${parameter.value}" não pode ser vinculada com um cadastro de ${this.isExpense() ? 'despesa' : 'receita'}`);

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      if (warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return false;
    }

    // this.props.history.replace(this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);

    // if (this.props.history.length > 1) {
    //   this.props.history.goBack();
    // }
    // else {
    //   this.props.history.push(this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH);
    // }

    return true;
  }

  isExpense() {
    return this.props.location.pathname.startsWith(routes.EXPENSE_EDIT_PATH);
  }

  onToggleSearchTag(tag_id) {
    const tag_ids = [...this.state.financial_movement.search_tag_ids];

    if(tag_ids.includes(tag_id)) {
      tag_ids.splice(tag_ids.indexOf(tag_id), 1);
    }
    else {
      tag_ids.push(tag_id);
    }

    const financial_movement = {...this.state.financial_movement, search_tag_ids: tag_ids};

    this.setState({financial_movement});
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH,
            text: this.isExpense() ? 'Listar despesas' : 'Listar receitas'
          },
          {
            path: `${this.isExpense() ? routes.EXPENSE_EDIT_PATH : routes.INCOME_EDIT_PATH}${this.props.match.params.financialMovementId}`,
            text: this.isExpense() ? 'Editar despesa' : 'Editar receita'
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title={this.isExpense() ? 'Editar despesa' : 'Editar receita'}
        loading={this.state.loading}
      >

        <ConfirmationWindow
          title={this.state.confirmationWindowTitle}
          description={this.state.confirmationWindowDescription}
          cancelText="Ok"
          visible={this.state.confirmationWindowVisible}
          onCancel={() => this.setState({
            confirmationWindowVisible: false
          })}
          loading={false}
          useErrorIcon={true}
          hideConfirmButton={true}
        />

        <FinancialMovementData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_movement={this.state.financial_movement}
          totalValue={this.state.totalValue}
          costCenters={this.state.costCenters}
          financialCategories={this.state.financialCategories}
          paymentMethods={this.state.paymentMethods}
          transactionCount={this.state.transactionCount}
          firstPaymentDate={this.state.firstPaymentDate}
          isExpense={this.isExpense()}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          // onCancelPath={this.isExpense() ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH}
          history={this.props.history}
          userPermissionIds={this.props.userPermissionIds}
          userAccessLevel={this.props.userAccessLevel}
          maySendToPaymentDevice={() => this.maySendToPaymentDevice()}
          getPaymentMethodAlertMessage={() => this.getPaymentMethodAlertMessage()}
          sendToPaymentDevice={this.state.sendToPaymentDevice}
          financial_search_tags={this.state.financial_search_tags}
          availablePaymentDevices={this.state.availablePaymentDevices}
          selectedPaymentDevice={this.state.selectedPaymentDevice}
          savePaymentDeviceSelected={this.state.savePaymentDeviceSelected}
          showPaymentDeviceOverlay={this.state.showPaymentDeviceOverlay}
          customerName={this.state.customerName}
          customerEmail={this.state.customerEmail}
          onCancelPaymentProcess={() => this.onCancelPaymentProcess()}
          onSendPaymentToDevice={(payment_device_id) => this.onSendPaymentToDevice(payment_device_id)}
          onToggleSearchTag={(tag_id) => this.onToggleSearchTag(tag_id)}
          updateFinancialMovement={() => this.updateFinancialMovement()}
          paymentReceived={this.state.paymentReceived}
          nf_coupon_generated={this.state.financial_movement.transactions.some((transaction) => transaction.nf_coupon_id != null)}
        />

      </ContentFrame>
    );
  }
}

export default FinancialMovementEdit;
