import React from 'react';
import { Link } from 'react-router-dom';
import './menu_items.scss';
import {VerticalAccordionContainer, VerticalAccordionSubContainer} from '../utils/pose_containers';

class MenuItem extends React.Component {
  isSelected() {
    return this.props.location.pathname === this.props.linkTo;
  }

  render() {
    return (
      <li className={`menu-items__default-item${this.isSelected() ? '--selected': ''}`}>

        <Link
          className="menu-items__default-item__link"
          to={this.props.linkTo}
        >

          {this.props.icon}
          <span className="menu-items__default-item__text">

            {this.props.text}

          </span>

        </Link>

      </li>
    );
  }
}

class OrganizerMenuItem extends React.Component {
  render() {
    return (
      <li className="menu-items__organizer-item">

        <div className="menu-items__organizer-item__wrapper">

          <span className="menu-items__organizer-item__text">

            {this.props.text}

          </span>

        </div>

      </li>
    );
  }
}

class ExpandableOrganizerMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <li className="menu-items__expandable-organizer-item">

        <div
          className={`menu-items__expandable-organizer-item__wrapper ${this.state.open ? 'menu-items__expandable-organizer-item--opened' : ''}`}
          onClick={() => this.onClick()}
        >

          <div className="menu-items__expandable-organizer-item__text-wrapper">

            {this.props.icon}

            <span className="menu-items__expandable-organizer-item__text">

              {this.props.text}

            </span>

          </div>

          {this.state.open ?
              <i className="fas fa-minus-square menu-items__expandable-organizer-item__status-icon"></i> :
              <i className="fas fa-plus-square menu-items__expandable-organizer-item__status-icon"></i>
          }

        </div>

        <VerticalAccordionContainer
          className="vertical-accordion-container"
          pose={this.state.open ? 'verticalOpen' : 'verticalClosed'}>

          <ul className="menu-items__expandable-organizer-item__sub-menu">

            {this.props.children}

          </ul>

        </VerticalAccordionContainer>

      </li>
    );
  }
}

class AccordionMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <li className="menu-items__accordion-item">

        <div
          className={`menu-items__accordion-item__wrapper ${this.state.open ? 'menu-items__accordion-item--opened' : ''}`}
          onClick={() => this.onClick()}
        >

          <div className="menu-items__accordion-item__text-wrapper">

            {this.props.icon}

            <span className="menu-items__accordion-item__text">

              {this.props.text}

            </span>

          </div>

          {this.state.open ?
              <i className="fas fa-chevron-down menu-items__accordion-item__status-icon"></i> :
              <i className="fas fa-chevron-left menu-items__accordion-item__status-icon"></i>
          }

        </div>

        <VerticalAccordionSubContainer
          className="vertical-accordion-sub-container"
          pose={this.state.open ? 'verticalSubOpen' : 'verticalSubClosed'}>

          <ul className="menu-items__accordion-item__sub-menu">

            {this.props.children}

          </ul>

        </VerticalAccordionSubContainer>

      </li>
    );
  }
}

class SubMenuItem extends React.Component {
  isSelected() {
    return this.props.location.pathname === this.props.linkTo;
  }

  render() {
    return (
      <li className={`menu-items__sub-item${this.isSelected() ? '--selected': ''}`}>

        <Link
          className="menu-items__sub-item__link"
          to={this.props.linkTo}
        >

          <div className="menu-items__accordion-item__text-wrapper">

            <span className="menu-items__accordion-item__text">{this.props.text}</span>

          </div>

          {this.props.statusIcon}

        </Link>

      </li>
    );
  }
}

export {
  MenuItem,
  OrganizerMenuItem,
  ExpandableOrganizerMenuItem,
  AccordionMenuItem,
  SubMenuItem
};
