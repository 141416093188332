import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import * as userPaths from '../student/constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import {getModels, deleteModel, patchModel, setUrlParameters, getLocalDateIsoString, getAsLocalDatetime} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
        APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID} from '../../constants';
import * as permissions from '../../permissions';
import './appointment_list.scss';


class AppointmentList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setDate(initialDate.getDate()); // - 7
      initialDate = getLocalDateIsoString(initialDate);
    }
    else if(initialDate === 'all') {
      initialDate = '';
    }

    if(!finalDate) {
      finalDate = new Date();
      finalDate.setDate(finalDate.getDate()); // - 7
      finalDate = getLocalDateIsoString(finalDate);
    }
    else if(finalDate === 'all') {
      finalDate = '';
    }

    this.state = {
      loadingData: true,
      appointments: [],
      deleteEntry: null,
      attendedId: null,
      absentId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      initialDate,
      initialDateInput: initialDate,
      finalDate,
      finalDateInput: finalDate,
      screenWidth: window.innerWidth
    };
  }

  async getAppointments() {
    const parameters = {};

    if (this.state.initialDate) {
      parameters.initial_date = `${this.state.initialDate}T00:00`;
    }
    if (this.state.finalDate) {
      parameters.final_date = `${this.state.finalDate}T23:59`;
    }

    return await getModels(setUrlParameters(routes.APPOINTMENTS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate ||
        prevState.finalDate !== this.state.finalDate) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const appointments = await this.getAppointments();

    this.setState({
      loadingData: false,
      appointments
    });
  }

  onDeleteEntry(entry) {
    this.setState({
      deleteEntry: entry,
      attendedId: null,
      absentId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onMarkAttendedEntry(entryId) {
    this.setState({
      deleteEntry: null,
      attendedId: entryId,
      absentId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onMarkAbsentEntry(entryId) {
    this.setState({
      deleteEntry: null,
      attendedId: null,
      absentId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteEntry: null,
      attendedId: null,
      absentId: null,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.attendedId != null || this.state.absentId !== null) {
      let entryId;
      const data = {};

      if (this.state.attendedId != null) {
        entryId = this.state.attendedId;
        data.attended = true;
      }
      else {
        entryId = this.state.absentId;
        data.attended = false;
      }

      try{
        if(await patchModel(`${routes.APPOINTMENT_PATCH_API}${entryId}`, data)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   const descriptions = [];
              //
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'recipe_associations':
              //         descriptions.push('Ingrediente vinculado a um cadastro de receita');
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   errorDescription = `${descriptions.join('. ')}. Todos os vínculos devem ser removidos manualmente antes de prosseguir com a remoção deste cadastro.`;
              //
              //   break;
              // case 208:
              //   if (error.message.includes('This entry cannot be deleted due to its essencial associacions')) {
              //     errorDescription = 'Esta entrada não pode ser removida por conter informações nutricionais importantes.';
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deleteEntry != null) {
      try{
        if(await deleteModel(`${routes.APPOINTMENT_DELETE_API}${this.state.deleteEntry.id}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if (error.message.includes('User selected have an invalid email')) {
                  errorDescription = 'Email de aluno inválido: Não foi possível enviar o email notificando ' +
                                     'o aluno sobre o cancelamento da avaliação. Entre em contato manualmente ' +
                                     'com o aluno para notificar sobre o cancelamento e para corrigir seu email inválido.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteEntry: null,
      attendedId: null,
      absentId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.MANAGE_OWN_APPOINTMENT_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.MANAGE_APPOINTMENT_PERMISSION_ID);
  }

  getUserLink(entry) {
    switch (entry.appointment_type.id) {
      case APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID:
        return `${routes.STUDENT_EDIT_PATH}${entry.user_id}${userPaths.PHYSICAL_AVALIATION_PATH}`;
      case APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID:
        return `${routes.STUDENT_EDIT_PATH}${entry.user_id}${userPaths.FOOD_PRESCRIPTION_PATH}`;
      default:
        return `${routes.STUDENT_MANAGE_PATH}${entry.user_id}`;
    }
  }

  getActions(entry) {
    if (entry.attended === null) {
      return (
        <div className="model-table__model-actions-container">

          <button
            className="appointment-list__action-button--green"
            onClick={() => this.onMarkAttendedEntry(entry.id)}
          >

            Presente

          </button>

          <button
            className="appointment-list__action-button--yellow"
            onClick={() => this.onMarkAbsentEntry(entry.id)}
          >

            Faltou

          </button>

          <button
            className="appointment-list__action-button--red"
            onClick={() => this.onDeleteEntry(entry)}
          >

            Cancelar

          </button>

        </div>
      );
    }

    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-link-button"
          to={this.getUserLink(entry)}
        >

            <i className="fas fa-link"></i>

        </Link>

        <Link
          className="model-table__default-edit-button"
          to={`${routes.APPOINTMENT_EDIT_PATH}${entry.id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>

        <button
          className="appointment-list__action-button--red"
          onClick={() => this.onDeleteEntry(entry)}
        >

          <i className="fa-solid fa-trash-can"></i>

        </button>

      </div>
    );
  }

  getScheduleText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDatetime(entry.scheduled_at, false);
    const dateString = entry.scheduled_at.slice(0, 10);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = entry.scheduled_at.slice(11, 16);

    if(this.state.screenWidth > 515) {
      dateFormat.weekday = 'short';
    }

    if(this.state.screenWidth > 580) {
      dateFormat.weekday = 'long';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return (
      <div className="appointment-list__cell-wrapper">

        <p className={`appointment-list__date-text${dateString > todayString ? '' : dateString < todayString ? '--past' : '--today'}`}>

          {dateText}
          {' '}
          <span className="appointment-list__time-text">{hourText}</span>

        </p>

      </div>
    );
  }

  getScheduleFilter(entry) {
    const date = getAsLocalDatetime(entry.scheduled_at, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = entry.scheduled_at.slice(11, 16);

    if(this.state.screenWidth > 515) {
      dateFormat.weekday = 'short';
    }

    if(this.state.screenWidth > 580) {
      dateFormat.weekday = 'long';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return `${dateText} ${hourText}`;
  }

  getAttendedText(entry, textOnly=false) {
    let status;
    let icon;
    let text;

    if (entry.attended === null) {
      status = '';
      icon = null;
      text = 'Agendado';
    }
    else if (entry.attended) {
      status = '--attended';
      icon = (<i className="fa-solid fa-check appointment-list__status-text__icon"></i>);
      text = 'Atendido';
    }
    else {
      status = '--missed';
      icon = (<i className="fa-solid fa-xmark appointment-list__status-text__icon"></i>);
      text = 'Faltou';
    }

    if (textOnly) {
      return text;
    }

    return (
      <div className="appointment-list__cell-wrapper--centered">

        <p className={`appointment-list__status-text${status}`}>

          {icon}
          {text}

        </p>

      </div>
    );
  }

  getTypeText(entry) {
    return (
      <div className="appointment-list__cell-wrapper--centered">

        <p
          className="appointment-list__type-text"
          style={{background: entry.appointment_type.color}}
        >

          {entry.appointment_type.name}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('user', 'Nome', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => entry.user.name,
        getFilterText: (entry) => entry.user.name,
        getSortCallback: (a, b) => a.user.name.localeCompare(b.user.name),
      }),
      Property('appointment_type', 'Tipo', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => this.getTypeText(entry),
        getFilterText: (entry) => entry.appointment_type.name,
        getSortCallback: (a, b) => a.appointment_type.name.localeCompare(b.appointment_type.name),
      }),
      Property('scheduled_at', 'Data agendada', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getScheduleText(entry),
        getFilterText: (entry) => this.getScheduleFilter(entry),
      }),
      Property('attended', 'Situação', <i className="fas fa-tasks"></i>, {
        getDataText: (entry) => this.getAttendedText(entry),
        getFilterText: (entry) => this.getAttendedText(entry, true),
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if (this.state.attendedId !== null) {
        return 'Marcando presença';
      }
      else if (this.state.absentId !== null) {
        return 'Marcando falta';
      }
      else if (this.state.deleteEntry !== null && this.state.deleteEntry.attended === null) {
        return 'Cancelando atendimento';
      }

       return 'Removendo atendimento';
    }
    else if(this.state.confirmFailed) {
      if (this.state.attendedId !== null) {
        return 'Falha ao marcar presença';
      }
      else if (this.state.absentId !== null) {
        return 'Falha ao marcar falta';
      }
      else if (this.state.deleteEntry !== null && this.state.deleteEntry.attended === null) {
        return 'Falha ao cancelar';
      }

      return 'Falha ao remover atendimento';
    }

    if (this.state.attendedId !== null) {
      return 'Marcar PRESENÇA';
    }
    else if (this.state.absentId !== null) {
      return 'Marcar FALTA';
    }
    else if (this.state.deleteEntry !== null && this.state.deleteEntry.attended === null) {
      return 'Cancelar atendimento';
    }

    return 'Remover atendimento';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.attendedId !== null) {
      return 'Tem certeza de que gostaria de confirmar a PRESENÇA para este atendimento?';
    }
    else if(this.state.absentId !== null) {
      return 'Tem certeza de que gostaria de confirmar a FALTA para este atendimento?';
    }
    else if(this.state.deleteEntry !== null && this.state.deleteEntry.attended === null) {
      return 'Todos os dados relacionados ao atendimento serão removidos. Tentaremos enviar um email notificando o aluno sobre o cancelamento.';
    }

    return 'Todos os dados relacionados ao atendimento serão removidos.';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.attendedId !== null) {
      return 'Marcar PRESENÇA';
    }
    else if(this.state.absentId !== null) {
      return 'Marcar FALTA';
    }
    else if(this.state.deleteEntry !== null && this.state.deleteEntry.attended === null) {
      return 'Confirmar cancelamento';
    }

    return 'Remover';
  }

  mayUpdateDateInputs() {
    if(this.state.initialDateInput !== this.state.initialDate ||
       this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.initialDateInput.length > 0 ||
       this.state.finalDateInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      initialDateInput: '',
      finalDateInput: '',
    });
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.APPOINTMENT_LIST_PATH, {
        initial_date: this.state.initialDateInput || 'all',
        final_date: this.state.finalDateInput || 'all',
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteEntry !== null || this.state.attendedId !== null || this.state.absentId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.APPOINTMENT_LIST_PATH,
              text: "Listar atendimentos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar atendimentos agendados"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="appointment-list"
            title="Lista de atendimentos agendados"
          >

            <div className="appointment-list__filters">

              <header className="appointment-list__filters__header">

                <h4 className="appointment-list__filters__header__text">Filtros</h4>

              </header>

              <div className="appointment-list__filters__inputs">

                <div className="appointment-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="initialDateInput"
                      label="Data inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.finalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.initialDateInput}
                    />

                    <DefaultInput
                      name="finalDateInput"
                      label="Data final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.initialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.finalDateInput}
                    />

                  </HalfWrapper>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="appointment-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="appointment-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.appointments}
              initialOrderBy="scheduled_at"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default AppointmentList;
