const CONTRACT_PATH = '/contratos';
const CONTRACT_ADD_PATH = '/adicionar-contrato';
const CONTRACT_EDIT_PATH = '/contrato/';
const FINANCIAL_PATH = '/pagamentos';
const PHYSICAL_AVALIATION_PATH = '/avaliacao-fisica';
const DISC_PATH = '/disc';
const PERSONAL_TRAINING_PATH = '/personal-training';
const FOOD_PRESCRIPTION_PATH = '/food-prescription';
const ADDRESS_PATH = '/endereco';
const PHYSICAL_EVALUATION_ADD_PATH = '/adicionar-avaliacao-fisica';
const SUPPORT_PATH = '/suporte';
const ADD_SUPPORT_TICKET_PATH = '/suporte/iniciar-conversa';
const ACCESS_CONTROL_PAGE = '/credenciais-de-acesso';

export {
  CONTRACT_PATH,
  CONTRACT_ADD_PATH,
  CONTRACT_EDIT_PATH,
  FINANCIAL_PATH,
  PHYSICAL_AVALIATION_PATH,
  DISC_PATH,
  PERSONAL_TRAINING_PATH,
  FOOD_PRESCRIPTION_PATH,
  ADDRESS_PATH,
  PHYSICAL_EVALUATION_ADD_PATH,
  SUPPORT_PATH,
  ADD_SUPPORT_TICKET_PATH,
  ACCESS_CONTROL_PAGE
};
