import React from 'react';
import { Link } from 'react-router-dom';
import './cost_center_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class CostCenterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      cost_centers: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getCostCenters() {
    return await getModels(routes.COST_CENTERS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const cost_centers = await this.getCostCenters();

    this.setState({
      loadingData: false,
      cost_centers: cost_centers
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.COST_CENTER_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'financial_categories':
                    descriptions.push('Centro vinculado à uma categoria financeira');

                    break;
                  case 'financial_entries':
                    descriptions.push('Centro vinculado à uma entrada financeira');

                    break;
                  case 'children':
                    descriptions.push('Centro de custo agrupa outros centros de custo.');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar o centro de custo`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_COST_CENTER_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_COST_CENTER_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">
        {this.props.userPermissionIds.includes(permissions.EDIT_COST_CENTER_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.COST_CENTER_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {(!entry.is_permanent && this.props.userPermissionIds.includes(permissions.DELETE_COST_CENTER_PERMISSION_ID)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getParentName(entry) {
    if(!entry.parent) {
      return (<p className="cost-center-list__hierarchy-text--empty">-</p>);
    }

    return (<p className="cost-center-list__hierarchy-text--empty">{entry.parent.name}</p>);
  }

  getParentNameFilter(entry) {
    if(!entry.parent) {
      return '-';
    }

    return entry.parent.name;
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
    ];

    if(this.state.screenWidth > 430) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "cost-center-list__description-cell"}),
      );
    }

    properties.push(
      Property('parent_id', 'Pertence a', <i className="fas fa-sitemap"></i>, {getDataText: this.getParentName, getFilterText: this.getParentNameFilter}),
    );

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando centro';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar centro';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao centro de custo serão removidos'}
          confirmText="Deletar centro"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.COST_CENTER_LIST_PATH,
              text: "Listar centros"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar centros de custo"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="cost-center-list"
            title="Lista de centros de custo"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.cost_centers}
              initialOrderBy="name"
            >
              {this.props.userPermissionIds.includes(permissions.ADD_COST_CENTER_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.COST_CENTER_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo centro de custo

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default CostCenterList;
