import React from 'react';
// import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, DummyInput, SelectOption, FiledSet} from '../../utils/default_input';
import {getModels, postModel} from '../../utils/functions';
import SimpleLineGraph from '../../graphs/simple_line_graph';
import WarningMessage from '../warning_message';
import * as metrics from './metric_functions';
import {BODY_FAT_PROTOCOLS,
        BASAL_METABOLIC_RATE_PROTOCOLS,
        TRAINING_PERIOD_OBJECTIVES,
        TRAINING_PERIOD_PHYSICAL_LEVELS,
        SERVICE_FUNCTIONAL_SERVICE,
        SERVICE_PILATES_SERVICE,
        SERVICE_BIKE_SERVICE,
        SERVICE_GYM_SERVICE,
        SERVICE_CARDIO,
        SERVICE_CORE,
        SERVICE_BOOTCAMP} from '../../constants';
import * as routes from '../../constants';
import * as permissions from '../../permissions';
import './physical_evaluation_data.scss';

const LAST_VALUE_TEXT_COLOR = '#854490';

class PhysicalEvaluationData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      profileSectionVisible: false,
      originalPhysicalEvaluationData: [],
      physicalEvaluationData: [],
      screenWidth: window.innerWidth
    };
  }

  async getPhysicalEvaluationData() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return await getModels(routes.PHYSICAL_EVALUATION_DATA_GET_API.replace('{student_id}', this.props.student.id));
    }

    return [];
  }

  async reloadPhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let physicalEvaluationData = this.getPhysicalEvaluationData();

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          if (entry.value.length > 0) {
            update.weeklyExerciseFrequency = parseInt(entry.value);
          }
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPhysicalEvaluationData = physicalEvaluationData;
    }

    this.setState(update);
  }

  async componentDidMount() {
    let physicalEvaluationData = this.getPhysicalEvaluationData();

    const update = {
      loadingData: false
    };

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          if (entry.value.length > 0) {
            update.weeklyExerciseFrequency = parseInt(entry.value);
          }
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPhysicalEvaluationData = physicalEvaluationData;
    }
    else {
      physicalEvaluationData = [];
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async updatePhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const data = {physical_evaluation: this.state.physicalEvaluationData};

    if(await postModel(routes.PHYSICAL_EVALUATION_DATA_POST_API.replace('{student_id}', this.props.student.id), data)) {
      // await this.reloadPhysicalEvaluationData();
    }

    this.setState({
      loadingData: false,
    });
  }

  getPhysicalEvaluationDataCopy() {
    return this.state.physicalEvaluationData.map((entry) => {
      return {...entry};
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    if(name.startsWith('physical_evaluation_question:')) {
      const selection = name.split(':');
      const question_id = parseInt(selection[1]);

      const valueCopy = this.getPhysicalEvaluationDataCopy();

      if (question_id === 9) {
        if (value.length > 0) {
          update.weeklyExerciseFrequency = parseInt(value);
        }
        else {
          update.weeklyExerciseFrequency = null;
        }
      }

      for(const entry of valueCopy) {
        if(entry.question_id === question_id) {
          entry.value = value;
          break;
        }
      }

      update.physicalEvaluationData = valueCopy;
    }
    else {
      update[name] = value;
    }

    this.setState(update);
  }

  maySavePhysicalEvaluation() {
    if(!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return false;
    }

    for(const currentEntry of this.state.physicalEvaluationData) {
      for(const originalEntry of this.state.originalPhysicalEvaluationData) {
        if(currentEntry.question_id === originalEntry.question_id) {
          if(currentEntry.value !== originalEntry.value) {
            return true;
          }

          break;
        }
      }
    }

    return false;
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.onSaveClicked();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getLastValue(name) {
    if(!this.props.exam.last_exam_data || !this.props.exam.last_exam_data[name]) {
      return '';
    }

    if (name === 'basal_metabolic_multiplier') {
      const lastSelection = this.getLifeActivityLevelOptions().find((entry) => entry.value.toString() === this.props.exam.last_exam_data[name]);

      if (lastSelection) {
        return `Último valor: ${lastSelection.text}`;
      }
    }

    return `Último valor: ${this.props.exam.last_exam_data[name]}`;
  }

  getMainFydActivityLabelMessage(service) {
    const lastMainFydActivities = this.getLastValue('main_fyd_activities');

    if (lastMainFydActivities.length <= 0) {
      return '';
    }

    return lastMainFydActivities.includes(service) ? '(Sim)' : '(Não)';
  }

  getMainFydActivityLabelMessageColor(service) {
    const lastMainFydActivities = this.getLastValue('main_fyd_activities');

    if (lastMainFydActivities.length <= 0) {
      return '';
    }

    return lastMainFydActivities.includes(service) ? 'green' : '';
  }

  getActiveWeekdayLabelMessage(dayIndex) {
    if(!this.props.exam.last_exam_data || !this.props.exam.last_exam_data['active_weekdays']) {
      return '';
    }

    const checked = this.props.exam.last_exam_data['active_weekdays'] & Math.pow(2, dayIndex);

    return checked > 0 ? '(Sim)' : '(Não)';
  }

  getActiveWeekdayLabelMessageColor(dayIndex) {
    if(!this.props.exam.last_exam_data || !this.props.exam.last_exam_data['active_weekdays']) {
      return '';
    }

    const checked = this.props.exam.last_exam_data['active_weekdays'] & Math.pow(2, dayIndex);

    return checked > 0? 'green' : '';
  }

  getBodyFatProtocolOptions() {
    // BODY_FAT_PROTOCOLS.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Selecione o protocolo utilizado'),
      ...BODY_FAT_PROTOCOLS.map((protocol) => SelectOption(protocol, protocol)),
    ];
  }

  getBasalMetabolicRateProtocolOptions() {
    // BASAL_METABOLIC_RATE_PROTOCOLS.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Selecione o protocolo utilizado'),
      ...BASAL_METABOLIC_RATE_PROTOCOLS.map((protocol) => SelectOption(protocol, protocol)),
    ];
  }

  getLifeActivityLevelOptions() {
    // return [
    //   SelectOption('', 'Selecione nível do aluno'),
    //   SelectOption(1.2, 'Sedentário'),
    //   SelectOption(1.375, 'Leve'),
    //   SelectOption(1.55, 'Moderada'),
    //   SelectOption(1.725, 'Alta'),
    //   SelectOption(1.9, 'Muito Alta'),
    // ];
    return [
      SelectOption('', 'Selecione nível do aluno'),
      SelectOption(1.35, '1.35 - Muito sedentário'),
      SelectOption(1.5, '1.5 - Sedentário pouco ativo'),
      SelectOption(1.6, '1.6 - Sedentário mais ativo (mais comum)'),
      SelectOption(1.7, '1.7 - Moderadamente ativo'),
      SelectOption(1.85, '1.85 - Muito ativo'),
      SelectOption(2.0, '2.0 - Atividade intensa'),
    ];
  }

  getBoneMassInputs() {
    return (
      <React.Fragment>

        <div className="physical-evaluation-data__generic-note">

          <p className="physical-evaluation-data__generic-note__text">

            Caso os dados de <strong>diâmetro biestilóide radio ulnar</strong>, <strong>diâmetro biepicondiliano do fêmur</strong> e <strong>altura</strong> não
            sejam fornecidos, o sistema assumirá um valor de <strong>14%</strong> para
            a porcentagem de massa óssea.

          </p>

        </div>

        <HalfWrapper>

          <DefaultInput
            name="diameter_wrist"
            isHighlighted={this.isHighlighted("diameter_wrist")}
            label="Diâmetro biestilóide radio ulnar"
            type="number"
            placeholder="Biestilóide radio ulnar"
            step="0.01"
            min="0.00"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.diameter_wrist}
            autoComplete="off"
            suffix="mm"
            labelMessage={this.getLastValue('diameter_wrist')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultInput
            name="diameter_knee"
            isHighlighted={this.isHighlighted("diameter_knee")}
            label="Diâmetro biepicondiliano do fêmur"
            type="number"
            placeholder="Biepicondiliano do fêmur"
            step="0.01"
            min="0.00"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.diameter_knee}
            autoComplete="off"
            suffix="mm"
            labelMessage={this.getLastValue('diameter_knee')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultInput
            name="height"
            isHighlighted={this.isHighlighted("height") || (this.props.exam_data.height && this.props.exam_data.height > 4)}
            label="Altura"
            type="number"
            placeholder="Altura"
            step="0.01"
            min="0.00"
            max="4.00"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.height}
            autoComplete="off"
            suffix="metros"
            labelMessage={this.getLastValue('height')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

        </HalfWrapper>

      </React.Fragment>
    );
  }

  getBodyCompositionInputs() {
    switch(this.props.exam_data.body_fat_protocol) {
      case BODY_FAT_PROTOCOLS[0]:
        return (
          <HalfWrapper>

            <DefaultInput
              name="skeletal_muscle_percentage"
              isHighlighted={this.isHighlighted("skeletal_muscle_percentage")}
              label="Músculo esquelético"
              type="number"
              placeholder="Porcentagem do músculo esquelético"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.skeletal_muscle_percentage}
              autoComplete="off"
              suffix="%"
              labelMessage={this.getLastValue('skeletal_muscle_percentage')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="body_fat_percentage"
              isHighlighted={this.isHighlighted("body_fat_percentage")}
              label="Gordura corporal"
              type="number"
              placeholder="Porcentagem de gordura corporal"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.body_fat_percentage}
              autoComplete="off"
              suffix="%"
              labelMessage={this.getLastValue('body_fat_percentage')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>
        );
      case BODY_FAT_PROTOCOLS[1]:
        return (
          <React.Fragment>

            {this.getBoneMassInputs()}

            <DefaultSubSectionTitle
              className="physical-evaluation-data__subsection"
              icon={<i className="fas fa-ruler-combined"></i>}
              text="Dobras cutâneas"
            />

            <HalfWrapper>

              <DefaultInput
                name="skinfold_triceps"
                isHighlighted={this.isHighlighted("skinfold_triceps")}
                label="Tríceps"
                type="number"
                placeholder="Tríceps"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_triceps}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_triceps')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_subscapular"
                isHighlighted={this.isHighlighted("skinfold_subscapular")}
                label="Subescapular"
                type="number"
                placeholder="Subescapular"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_subscapular}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_subscapular')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="skinfold_suprailiac"
                isHighlighted={this.isHighlighted("skinfold_suprailiac")}
                label="Supra-ilíaca"
                type="number"
                placeholder="Supra-ilíaca"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_suprailiac}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_suprailiac')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_abdomen"
                isHighlighted={this.isHighlighted("skinfold_abdomen")}
                label="Abdominal"
                type="number"
                placeholder="Abdominal"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_abdomen}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_abdomen')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

          </React.Fragment>
        );
      case BODY_FAT_PROTOCOLS[2]:
        if(this.props.student.gender === 'Masculino') {
          return (
            <React.Fragment>

              {this.getBoneMassInputs()}

              <DefaultSubSectionTitle
                className="physical-evaluation-data__subsection"
                icon={<i className="fas fa-ruler-combined"></i>}
                text="Dobras cutâneas"
              />

              <HalfWrapper>

                <DefaultInput
                  name="skinfold_triceps"
                  isHighlighted={this.isHighlighted("skinfold_triceps")}
                  label="Tríceps"
                  type="number"
                  placeholder="Tríceps"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_triceps}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_triceps')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_suprailiac"
                  isHighlighted={this.isHighlighted("skinfold_suprailiac")}
                  label="Supra-ilíaca"
                  type="number"
                  placeholder="Supra-ilíaca"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_suprailiac}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_suprailiac')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_abdomen"
                  isHighlighted={this.isHighlighted("skinfold_abdomen")}
                  label="Abdominal"
                  type="number"
                  placeholder="Abdominal"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_abdomen}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_abdomen')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

              </HalfWrapper>

            </React.Fragment>
          );
        }
        else {
          return (
            <React.Fragment>

              {this.getBoneMassInputs()}

              <DefaultSubSectionTitle
                className="physical-evaluation-data__subsection"
                icon={<i className="fas fa-ruler-combined"></i>}
                text="Dobras cutâneas"
              />

              <HalfWrapper>

                <DefaultInput
                  name="skinfold_thigh"
                  isHighlighted={this.isHighlighted("skinfold_thigh")}
                  label="Coxa"
                  type="number"
                  placeholder="Coxa"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_thigh}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_thigh')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_suprailiac"
                  isHighlighted={this.isHighlighted("skinfold_suprailiac")}
                  label="Supra-ilíaca"
                  type="number"
                  placeholder="Supra-ilíaca"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_suprailiac}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_suprailiac')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_subscapular"
                  isHighlighted={this.isHighlighted("skinfold_subscapular")}
                  label="Subescapular"
                  type="number"
                  placeholder="Subescapular"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_subscapular}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_subscapular')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

              </HalfWrapper>

            </React.Fragment>
          );
        }
      case BODY_FAT_PROTOCOLS[3]:
        if(this.props.student.gender === 'Masculino') {
          return (
            <React.Fragment>

              {this.getBoneMassInputs()}

              <DefaultSubSectionTitle
                className="physical-evaluation-data__subsection"
                icon={<i className="fas fa-ruler-combined"></i>}
                text="Dobras cutâneas"
              />

              <HalfWrapper>

                <DefaultInput
                  name="skinfold_thorax"
                  isHighlighted={this.isHighlighted("skinfold_thorax")}
                  label="Tórax"
                  type="number"
                  placeholder="Tórax"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_thorax}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_thorax')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_abdomen"
                  isHighlighted={this.isHighlighted("skinfold_abdomen")}
                  label="Abdominal"
                  type="number"
                  placeholder="Abdominal"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_abdomen}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_abdomen')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_thigh"
                  isHighlighted={this.isHighlighted("skinfold_thigh")}
                  label="Coxa"
                  type="number"
                  placeholder="Coxa"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_thigh}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_thigh')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

              </HalfWrapper>

            </React.Fragment>
          );
        }
        else {
          return (
            <React.Fragment>

              {this.getBoneMassInputs()}

              <DefaultSubSectionTitle
                className="physical-evaluation-data__subsection"
                icon={<i className="fas fa-ruler-combined"></i>}
                text="Dobras cutâneas"
              />

              <HalfWrapper>

                <DefaultInput
                  name="skinfold_triceps"
                  isHighlighted={this.isHighlighted("skinfold_triceps")}
                  label="Tríceps"
                  type="number"
                  placeholder="Tríceps"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_triceps}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_triceps')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_suprailiac"
                  isHighlighted={this.isHighlighted("skinfold_suprailiac")}
                  label="Supra-ilíaca"
                  type="number"
                  placeholder="Supra-ilíaca"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_suprailiac}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_suprailiac')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  name="skinfold_thigh"
                  isHighlighted={this.isHighlighted("skinfold_thigh")}
                  label="Coxa"
                  type="number"
                  placeholder="Coxa"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.exam_data.skinfold_thigh}
                  autoComplete="off"
                  suffix="mm"
                  labelMessage={this.getLastValue('skinfold_thigh')}
                  labelColor={LAST_VALUE_TEXT_COLOR}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />

              </HalfWrapper>

            </React.Fragment>
          );
        }
      case BODY_FAT_PROTOCOLS[4]:
        return (
          <React.Fragment>

            {this.getBoneMassInputs()}

            <DefaultSubSectionTitle
              className="physical-evaluation-data__subsection"
              icon={<i className="fas fa-ruler-combined"></i>}
              text="Dobras cutâneas"
            />

            <HalfWrapper>

              <DefaultInput
                name="skinfold_thorax"
                isHighlighted={this.isHighlighted("skinfold_thorax")}
                label="Tórax"
                type="number"
                placeholder="Tórax"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_thorax}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_thorax')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_midaxilla"
                isHighlighted={this.isHighlighted("skinfold_midaxilla")}
                label="Axilar média"
                type="number"
                placeholder="Axilar média"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_midaxilla}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_midaxilla')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_triceps"
                isHighlighted={this.isHighlighted("skinfold_triceps")}
                label="Tríceps"
                type="number"
                placeholder="Tríceps"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_triceps}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_triceps')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="skinfold_subscapular"
                isHighlighted={this.isHighlighted("skinfold_subscapular")}
                label="Subescapular"
                type="number"
                placeholder="Subescapular"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_subscapular}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_subscapular')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_abdomen"
                isHighlighted={this.isHighlighted("skinfold_abdomen")}
                label="Abdominal"
                type="number"
                placeholder="Abdominal"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_abdomen}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_abdomen')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="skinfold_suprailiac"
                isHighlighted={this.isHighlighted("skinfold_suprailiac")}
                label="Supra-ilíaca"
                type="number"
                placeholder="Supra-ilíaca"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_suprailiac}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_suprailiac')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="skinfold_thigh"
                isHighlighted={this.isHighlighted("skinfold_thigh")}
                label="Coxa"
                type="number"
                placeholder="Coxa"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.skinfold_thigh}
                autoComplete="off"
                suffix="mm"
                labelMessage={this.getLastValue('skinfold_thigh')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DummyInput />
              <DummyInput />

            </HalfWrapper>

          </React.Fragment>
        );
        case BODY_FAT_PROTOCOLS[5]:
          if(this.props.student.gender === 'Masculino') {
            return (
              <React.Fragment>

                {this.getBoneMassInputs()}

                <HalfWrapper>

                  <DefaultInput
                    name="circumference_neck"
                    isHighlighted={this.isHighlighted("circumference_neck")}
                    label="Circunferência do pescoço"
                    type="number"
                    placeholder="Pescoço"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_neck}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_neck')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    name="circumference_abdomen"
                    isHighlighted={this.isHighlighted("circumference_abdomen")}
                    label="Circunferência do abdômen"
                    type="number"
                    placeholder="Abdômen"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_abdomen}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_abdomen')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />
                  {/* <DefaultInput
                    name="circumference_waist"
                    isHighlighted={this.isHighlighted("circumference_waist")}
                    label="Circunferência da cintura"
                    type="number"
                    placeholder="Cintura"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_waist}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_waist')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  /> */}

                </HalfWrapper>

              </React.Fragment>
            );
          }
          else {
            return (
              <React.Fragment>

                {this.getBoneMassInputs()}

                <HalfWrapper>

                  <DefaultInput
                    name="circumference_neck"
                    isHighlighted={this.isHighlighted("circumference_neck")}
                    label="Circunferência do pescoço"
                    type="number"
                    placeholder="Pescoço"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_neck}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_neck')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    name="circumference_waist"
                    isHighlighted={this.isHighlighted("circumference_waist")}
                    label="Circunferência da cintura"
                    type="number"
                    placeholder="Cintura"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_waist}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_waist')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    name="circumference_hip"
                    isHighlighted={this.isHighlighted("circumference_hip")}
                    label="Circunferência do quadril"
                    type="number"
                    placeholder="Quadril"
                    step="0.01"
                    min="0.00"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.exam_data.circumference_hip}
                    autoComplete="off"
                    suffix="cm"
                    labelMessage={this.getLastValue('circumference_hip')}
                    labelColor={LAST_VALUE_TEXT_COLOR}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    onFocus={(event) => event.target.select()}
                  />

                </HalfWrapper>

              </React.Fragment>
            );
          }
      default:
        break;
    }

    return null;
  }

  getQuestionOptions(question_id, currentValue=null) {
    let options;

    switch (question_id) {
      case 1:
        const objectives = [...TRAINING_PERIOD_OBJECTIVES];

        options = [
          SelectOption('', 'Não especificado'),
          ...objectives.map((objective) => SelectOption(objective, objective))
        ];
        break;
      case 2:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 3:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 6 meses', '0 à 6 meses'),
          SelectOption('6 meses à 1 ano', '6 meses à 1 ano'),
          SelectOption('1 à 2 anos', '1 à 2 anos'),
          SelectOption('2 à 3 anos', '2 à 3 anos'),
          SelectOption('3 anos ou mais', '3 anos ou mais'),
        ];
        break;
      case 4:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Menos de 5 horas', 'Menos de 5 horas'),
          SelectOption('Entre 5 e 8 horas', 'Entre 5 e 8 horas'),
          SelectOption('Mais de 8 horas', 'Mais de 8 horas'),
        ];
        break;
      case 5:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 6:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 7:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 1 ano', '0 à 1 ano'),
          SelectOption('1 à 3 anos', '1 à 3 anos'),
          SelectOption('3 à 5 anos', '3 à 5 anos'),
          SelectOption('5 anos ou mais', '5 anos ou mais'),
        ];
        break;
      case 8:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim, frequentemente (fins de semana)', 'Sim, frequentemente (fins de semana)'),
          SelectOption('Sim, ocasionalmente', 'Sim, ocasionalmente'),
          SelectOption('Sim, raramente', 'Sim, raramente'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 9:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('1', '1'),
          SelectOption('2', '2'),
          SelectOption('3', '3'),
          SelectOption('4', '4'),
          SelectOption('5', '5'),
          SelectOption('6', '6'),
          SelectOption('7', '7'),
        ];
        break;
      case 10:
        const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

        options = [
          SelectOption('', 'Não especificado'),
          ...levels.map((level) => SelectOption(level, level))
        ];
        break;
      default:
        options = [SelectOption('', 'Não especificado')];
    }

    if(currentValue !== null && !options.some((entry) => entry.value === currentValue)) {
      options.push(SelectOption(currentValue, currentValue));
    }

    return options;
  }

  getPhysicalEvaluationQuestions(getMostRelevantOnly=false) {
    const questions = [];

    for (const question of this.state.physicalEvaluationData) {
      if (getMostRelevantOnly && question.question_id !== 1 && question.question_id !== 10) {
        continue;
      }

      let labelMessage = null;

      if(question.question_id === 1 && !TRAINING_PERIOD_OBJECTIVES.includes(question.value)) {
        labelMessage = 'Atualização de valor necessária.';
      }
      if(question.question_id === 10 && question.value) {
        switch (question.value) {
          case TRAINING_PERIOD_PHYSICAL_LEVELS[0]:
            labelMessage = 'Frequência ótima de 3.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[1]:
            labelMessage = 'Frequência ótima de 4 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[2]:
            labelMessage = 'Frequência ótima de 4.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[3]:
            labelMessage = 'Frequência ótima de 5 vezes/semana.';
            break;
          default:
        }
      }

      questions.push((
        <DefaultInput
          id={getMostRelevantOnly ? `physical_evaluation_question:${question.question_id}` : null}
          key={`physical_evaluation_question_input_${question.question_id}`}
          name={`physical_evaluation_question:${question.question_id}`}
          label={question.question_text}
          labelMessage={labelMessage}
          labelClassName="physical-evaluation-data__question__label"
          type="select"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={question.value || ''}
          options={this.getQuestionOptions(question.question_id, question.value)}
          disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)}
        />
      ));
    }

    return questions;
  }

  getDiscClassification(asText=false) {
    let result = ['D'];

    let currentScore = this.props.student.disc.total_d;

    if(currentScore < this.props.student.disc.total_i) {
      result = ['I'];
      currentScore = this.props.student.disc.total_i;
    }
    else if(currentScore === this.props.student.disc.total_i) {
      result.push('I');
    }

    if(currentScore < this.props.student.disc.total_s) {
      result = ['S'];
      currentScore = this.props.student.disc.total_s;
    }
    else if(currentScore === this.props.student.disc.total_s) {
      result.push('S');
    }

    if(currentScore < this.props.student.disc.total_c) {
      result = ['C'];
      currentScore = this.props.student.disc.total_c;
    }
    else if(currentScore === this.props.student.disc.total_c) {
      result.push('C');
    }

    if(asText) {
      return result.join('');
    }

    const elementD = (
      <p
        className="physical-evaluation-data__profile__disc__text--d"
        key="disc_classification_d"
      >
        {this.state.screenWidth > 420 ? 'Dominância' : 'D'}
      </p>
    );
    const elementI = (
      <p
        className="physical-evaluation-data__profile__disc__text--i"
        key="disc_classification_i"
      >
        {this.state.screenWidth > 420 ? 'Influência' : 'I'}
      </p>
    );
    const elementS = (
      <p
        className="physical-evaluation-data__profile__disc__text--s"
        key="disc_classification_s"
      >
        {this.state.screenWidth > 420 ? 'Estabilidade' : 'S'}
      </p>
    );
    const elementC = (
      <p
        className="physical-evaluation-data__profile__disc__text--c"
        key="disc_classification_c"
      >
        {this.state.screenWidth > 420 ? 'Complacência' : 'C'}
      </p>
    );

    let classification = [];

    if(result.includes('D')) {
      classification.push(elementD);
    }
    if(result.includes('I')) {
      classification.push(elementI);
    }
    if(result.includes('S')) {
      classification.push(elementS);
    }
    if(result.includes('C')) {
      classification.push(elementC);
    }

    return classification;
  }

  getActiveWeekdaysInput() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    return weekdays.map((weekday, index) => {
      const checked = this.props.exam_data.active_weekdays & Math.pow(2, index);

      return (
        <DefaultInput
          key={`active_weekdays:${index}`}
          name={`active_weekdays:${index}`}
          label={weekday}
          isHorizontal={true}
          invertLabel={true}
          isHighlighted={false}
          type="checkbox"
          handleInputChange={this.props.handleInputChange}
          labelMessage={this.getActiveWeekdayLabelMessage(index)}
          labelColor={this.getActiveWeekdayLabelMessageColor(index)}
          placeLabelMessageBellow={true}
          value={checked > 0}
        />
      );
    });
  }

  async onSaveClicked() {
    if (this.maySavePhysicalEvaluation()) {
      await this.updatePhysicalEvaluationData();
    }

    this.props.onSave();
  }

  getFormContent() {
    const systolicMetric = metrics.getSystolicBloodPressureMetric(this.props.exam_data.blood_pressure_systolic);
    const diastolicMetric = metrics.getDiastolicBloodPressureMetric(this.props.exam_data.blood_pressure_diastolic);

    return (
      <React.Fragment>

        <div className="physical-evaluation-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
          <React.Fragment>

            <section className="physical-evaluation-data__profile">

              <header
                className="physical-evaluation-data__profile__header"
                onClick={() => this.setState({profileSectionVisible: !this.state.profileSectionVisible})}
              >

                <h3 className="physical-evaluation-data__profile__header__text">
                  <i className="far fa-chart-bar physical-evaluation-data__profile__header__text-icon"></i>
                  Perfil
                </h3>

                {this.state.profileSectionVisible ?
                  <i className="fas fa-chevron-down physical-evaluation-data__profile__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up physical-evaluation-data__profile__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container physical-evaluation-data__profile__content"
                pose={this.state.profileSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container physical-evaluation-data__profile__content-wrapper">

                  {(this.props.student.disc && this.props.student.disc !== null) ?
                    <div className="physical-evaluation-data__profile__disc">

                      <h2 className="physical-evaluation-data__profile__disc__label">DISC:</h2>
                      {this.getDiscClassification()}

                    </div>:
                    null
                  }

                  {(this.props.student.note !== null && this.props.student.note.length > 0) &&
                    <DefaultInput
                      name="note"
                      label="Observação"
                      labelClassName="physical-evaluation-data__question__label"
                      labelMessage={this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID) ? 'Atualização deve ser realizada na aba "Dados gerais"' : ''}
                      type="text"
                      value={this.props.student.note || ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  }

                  {this.getPhysicalEvaluationQuestions()}

                  {/* {this.maySavePhysicalEvaluation() &&
                    <div className="physical-evaluation-data__profile__buttons-container">

                      <button
                        className="physical-evaluation-data__profile__save-button"
                        onClick={() => this.updatePhysicalEvaluationData()}
                      >

                        Salvar

                      </button>

                    </div>
                  } */}

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        <div className="physical-evaluation-data__input-container">

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome do aluno"
            maxLength="128"
            value={this.props.exam.student_name}
            autoComplete="off"
            disabled
          />

          <HalfWrapper>

            <DefaultInput
              name="date"
              isHighlighted={this.isHighlighted("date")}
              label="Data do exame"
              type="date"
              placeholder="Data do exame"
              value={this.props.exam.scheduled_date}
              readOnly
              disabled
            />

            <DefaultInput
              name="time"
              isHighlighted={this.isHighlighted("time")}
              label="Horário do exame"
              type="time"
              placeholder="Data do exame"
              value={this.props.exam.scheduled_time}
              readOnly
              disabled
            />

          </HalfWrapper>

          {this.props.exam.evaluator_name !== null &&
            <DefaultInput
              name="evaluator_name"
              isHighlighted={this.isHighlighted("evaluator_name")}
              label="Avaliador"
              type="text"
              placeholder="Nome do avaliador"
              maxLength="128"
              value={this.props.exam.evaluator_name}
              autoComplete="off"
              disabled
            />
          }

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fa-solid fa-person-walking"></i>}
            text="Atividade física"
          />

          {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
            this.getPhysicalEvaluationQuestions(true)
          }

          {this.props.weekly_exercise_frequency > 0 &&
            <div className="physical-evaluation-data__indicators-container">

              <div className="physical-evaluation-data__indicators-wrapper">

                <div className="physical-evaluation-data__indicator">

                  <h2 className="physical-evaluation-data__indicator__label">Frequência de treinos semanal</h2>
                  <p className="physical-evaluation-data__indicator__value">

                    <span className="physical-evaluation-data__indicator__value__number">{Math.round(10 * this.props.weekly_exercise_frequency) / 10}</span>
                    <span className="physical-evaluation-data__indicator__value__unit">treinos/semana</span>

                  </p>

                </div>

                {(this.props.requiresPersonalPeriod && this.props.current_training_period_progress !== null) &&
                  <div className="physical-evaluation-data__indicator">

                    <h2 className="physical-evaluation-data__indicator__label">Progresso da periodização</h2>
                    <p className="physical-evaluation-data__indicator__value">

                      <span className="physical-evaluation-data__indicator__value__number">{Math.round(100 * this.props.current_training_period_progress)}%</span>

                    </p>

                  </div>
                }

              </div>

            </div>
          }

          <DefaultInput
            name="requiresPersonalPeriod"
            isHighlighted={false}
            label="Requer periodização: "
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.requiresPersonalPeriod}
          />

          <FiledSet legend="Modalidades praticadas">

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_FUNCTIONAL_SERVICE}`}
                label={<span className="physical-evaluation-data__input-label--red">{SERVICE_FUNCTIONAL_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_FUNCTIONAL_SERVICE)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_FUNCTIONAL_SERVICE)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_FUNCTIONAL_SERVICE)}
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_GYM_SERVICE}`}
                label={<span className="physical-evaluation-data__input-label--red">{SERVICE_GYM_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_GYM_SERVICE)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_GYM_SERVICE)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_GYM_SERVICE)}
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BIKE_SERVICE}`}
                label={<span className="physical-evaluation-data__input-label--yellow">{SERVICE_BIKE_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_BIKE_SERVICE)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_BIKE_SERVICE)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_BIKE_SERVICE)}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CARDIO}`}
                label={<span className="physical-evaluation-data__input-label--yellow">{SERVICE_CARDIO}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_CARDIO)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_CARDIO)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_CARDIO)}
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BOOTCAMP}`}
                label={<span className="physical-evaluation-data__input-label--yellow">{SERVICE_BOOTCAMP}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_BOOTCAMP)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_BOOTCAMP)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_BOOTCAMP)}
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_PILATES_SERVICE}`}
                label={<span className="physical-evaluation-data__input-label--green">{SERVICE_PILATES_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_PILATES_SERVICE)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_PILATES_SERVICE)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_PILATES_SERVICE)}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CORE}`}
                label={<span className="physical-evaluation-data__input-label--green">{SERVICE_CORE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                handleInputChange={this.props.handleInputChange}
                labelMessage={this.getMainFydActivityLabelMessage(SERVICE_CORE)}
                labelColor={this.getMainFydActivityLabelMessageColor(SERVICE_CORE)}
                placeLabelMessageBellow={true}
                value={this.props.exam_data.main_fyd_activities.includes(SERVICE_CORE)}
              />

            </HalfWrapper>

            <DefaultInput
              name="other_main_activities"
              label="Outras"
              type="text"
              placeholder="Outras modalidades praticadas"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.other_main_activities}
              autoComplete="off"
              labelMessage={this.getLastValue('other_main_activities')}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </FiledSet>

          <FiledSet legend="Dias que pretende treinar">

            <HalfWrapper>

              {this.getActiveWeekdaysInput()}

            </HalfWrapper>

          </FiledSet>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-heartbeat"></i>}
            text="Pressão arterial"
          />

          <HalfWrapper>

            <DefaultInput
              name="blood_pressure_systolic"
              isHighlighted={this.isHighlighted("blood_pressure_systolic")}
              label="Sistólica"
              type="number"
              placeholder="Pressão arterial sistólica"
              step="1"
              min="0"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.blood_pressure_systolic}
              autoComplete="off"
              suffix="mmHg"
              labelMessage={systolicMetric.value}
              labelColor={systolicMetric.color}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="blood_pressure_diastolic"
              isHighlighted={this.isHighlighted("blood_pressure_diastolic")}
              label="Diastólica"
              type="number"
              placeholder="Pressão arterial diastólica"
              step="1"
              min="0"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.blood_pressure_diastolic}
              autoComplete="off"
              suffix="mmHg"
              labelMessage={diastolicMetric.value}
              labelColor={diastolicMetric.color}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-heartbeat"></i>}
            text="Frequência cardíaca"
          />

          {this.props.isbluetoothAvailable &&
            <div className="physical-evaluation-data__action-container">

              <button
                className="physical-evaluation-data__sync-button"
                onClick={() => {
                  if(this.props.restingHrDeviceName === null) {
                    this.props.onSyncRestingHr();
                  }
                  else {
                    this.props.onDesconnectDevices();
                  }
                }}
              >

                {this.props.syncingToRestingHrDevice ?
                  <i className="fas fa-spinner physical-evaluation-data__loading-icon"></i>:
                  this.props.restingHrDeviceName === null ?
                  <React.Fragment>

                    <i className="fab fa-bluetooth-b physical-evaluation-data__sync-button__icon"></i> Sincronizar
                  </React.Fragment>:
                  <React.Fragment>

                    <i className="fas fa-stop physical-evaluation-data__sync-button__icon"></i> Parar
                  </React.Fragment>
                }

              </button>

            </div>
          }

          {this.props.showRestingHrGraph &&
            <SimpleLineGraph
              data={this.props.restingHrData}
              height={this.getDefaultGraphHeight()}
              labelFormatter={(value) => `${value} bpm`}
              lineColor="#e04848"
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            />
          }

          <DefaultInput
            name="resting_heart_rate"
            isHighlighted={this.isHighlighted("resting_heart_rate")}
            label="Frequência cardíaca de repouso"
            type="number"
            placeholder="Frequência cardíaca de repouso"
            step="1"
            min="0"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.resting_heart_rate}
            autoComplete="off"
            suffix="bpm"
            labelMessage={this.getLastValue('resting_heart_rate')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-weight"></i>}
            text="Peso, altura e IMC"
          />

          <HalfWrapper>

            <DefaultInput
              name="weight"
              isHighlighted={this.isHighlighted("weight")}
              label="Peso"
              type="number"
              placeholder="Peso atual"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.weight}
              autoComplete="off"
              suffix="kg"
              labelMessage={this.getLastValue('weight')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="height"
              isHighlighted={this.isHighlighted("height") || (this.props.exam_data.height && this.props.exam_data.height > 4)}
              label="Altura"
              type="number"
              placeholder="Altura"
              step="0.01"
              min="0.00"
              max="4.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.height}
              autoComplete="off"
              suffix="metros"
              labelMessage={this.getLastValue('height')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="desired_imc"
              isHighlighted={this.isHighlighted("desired_imc")}
              label="IMC ideal"
              type="number"
              placeholder="IMC ideal"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.desired_imc}
              autoComplete="off"
              labelMessage={this.getLastValue('desired_imc')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="desired_weight"
              isHighlighted={this.isHighlighted("desired_weight")}
              label="Meta do peso atual"
              type="number"
              placeholder="Meta do peso atual"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.desired_weight}
              autoComplete="off"
              suffix="kg"
              labelMessage={this.getLastValue('desired_weight')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-child"></i>}
            text="Composição corporal"
          />

          <DefaultInput
            name="body_fat_protocol"
            isHighlighted={this.isHighlighted("body_fat_protocol")}
            label="Protocolo de medida"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.body_fat_protocol || ''}
            labelMessage={this.getLastValue('body_fat_protocol')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            options={this.getBodyFatProtocolOptions()}
          />

          {this.getBodyCompositionInputs()}

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-weight"></i>}
            text="Taxa metabólica basal" // e gordura visceral
          />

          <DefaultInput
            name="basal_metabolic_rate_protocol"
            isHighlighted={this.isHighlighted("basal_metabolic_rate_protocol")}
            label="Protocolo do cálculo"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exam_data.basal_metabolic_rate_protocol || ''}
            labelMessage={this.getLastValue('basal_metabolic_rate_protocol')}
            labelColor={LAST_VALUE_TEXT_COLOR}
            options={this.getBasalMetabolicRateProtocolOptions()}
          />

          <HalfWrapper>

            {this.props.exam_data.basal_metabolic_rate_protocol === BASAL_METABOLIC_RATE_PROTOCOLS[0] &&
              <DefaultInput
                name="basal_metabolic_rate"
                isHighlighted={this.isHighlighted("basal_metabolic_rate")}
                label="Taxa metabólica basal"
                type="number"
                placeholder="Taxa metabólica basal"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.exam_data.basal_metabolic_rate}
                autoComplete="off"
                suffix="kcal / dia"
                labelMessage={this.getLastValue('basal_metabolic_rate')}
                labelColor={LAST_VALUE_TEXT_COLOR}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />
            }

            <DefaultInput
              name="basal_metabolic_multiplier"
              isHighlighted={this.isHighlighted("basal_metabolic_multiplier")}
              // labelMessage="No momento, este campo possui carater apenas informativo e não influencia na taxa metabólica basal"
              labelMessage={this.getLastValue('basal_metabolic_multiplier')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              label="Nível de atividade"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.basal_metabolic_multiplier || ''}
              options={this.getLifeActivityLevelOptions()}
            />

            {/* <DefaultInput
              name="visceral_fat_level"
              isHighlighted={this.isHighlighted("visceral_fat_level")}
              label="Nível de gordura visceral"
              type="number"
              placeholder="Nível de gordura visceral"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.visceral_fat_level}
              autoComplete="off"
              labelMessage={this.getLastValue('visceral_fat_level')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            /> */}
            {/* <DummyInput /> */}

          </HalfWrapper>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-ruler-vertical"></i>}
            text="Circunferências"
          />

          <HalfWrapper>

            <DefaultInput
              name="circumference_thorax"
              isHighlighted={this.isHighlighted("circumference_thorax")}
              label="Tórax"
              type="number"
              placeholder="Tórax"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_thorax}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_thorax')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_hip"
              isHighlighted={this.isHighlighted("circumference_hip")}
              label="Quadril"
              type="number"
              placeholder="Quadril"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_hip}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_hip')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_waist"
              isHighlighted={this.isHighlighted("circumference_waist")}
              label="Cintura"
              type="number"
              placeholder="Cintura"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_waist}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_waist')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="circumference_abdomen"
              isHighlighted={this.isHighlighted("circumference_abdomen")}
              label="Abdômen"
              type="number"
              placeholder="Abdômen"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_abdomen}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_abdomen')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_contracted_arm_d"
              isHighlighted={this.isHighlighted("circumference_contracted_arm_d")}
              label="Braço contraído (D)"
              type="number"
              placeholder="Braço contraído (D)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_contracted_arm_d}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_contracted_arm_d')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_contracted_arm_e"
              isHighlighted={this.isHighlighted("circumference_contracted_arm_e")}
              label="Braço contraído (E)"
              type="number"
              placeholder="Braço contraído (E)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_contracted_arm_e}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_contracted_arm_e')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="circumference_relaxed_arm_d"
              isHighlighted={this.isHighlighted("circumference_relaxed_arm_d")}
              label="Braço relaxado (D)"
              type="number"
              placeholder="Braço relaxado (D)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_relaxed_arm_d}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_relaxed_arm_d')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_relaxed_arm_e"
              isHighlighted={this.isHighlighted("circumference_relaxed_arm_e")}
              label="Braço relaxado (E)"
              type="number"
              placeholder="Braço relaxado (E)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_relaxed_arm_e}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_relaxed_arm_e')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_forearm_d"
              isHighlighted={this.isHighlighted("circumference_forearm_d")}
              label="Antebraço (D)"
              type="number"
              placeholder="Antebraço (D)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_forearm_d}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_forearm_d')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="circumference_forearm_e"
              isHighlighted={this.isHighlighted("circumference_forearm_e")}
              label="Antebraço (E)"
              type="number"
              placeholder="Antebraço (E)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_forearm_e}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_forearm_e')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_thigh_d"
              isHighlighted={this.isHighlighted("circumference_thigh_d")}
              label="Coxa (D)"
              type="number"
              placeholder="Coxa (D)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_thigh_d}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_thigh_d')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_thigh_e"
              isHighlighted={this.isHighlighted("circumference_thigh_e")}
              label="Coxa (E)"
              type="number"
              placeholder="Coxa (E)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_thigh_e}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_thigh_e')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="circumference_calf_d"
              isHighlighted={this.isHighlighted("circumference_calf_d")}
              label="Panturrilha (D)"
              type="number"
              placeholder="Panturrilha (D)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_calf_d}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_calf_d')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_calf_e"
              isHighlighted={this.isHighlighted("circumference_calf_e")}
              label="Panturrilha (E)"
              type="number"
              placeholder="Panturrilha (E)"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_calf_e}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_calf_e')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="circumference_scapular"
              isHighlighted={this.isHighlighted("circumference_scapular")}
              label="Escapular"
              type="number"
              placeholder="Escapular"
              step="0.01"
              min="0.00"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.circumference_scapular}
              autoComplete="off"
              suffix="cm"
              labelMessage={this.getLastValue('circumference_scapular')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-smile"></i>}
            text="Bem estar"
          />

          <div className="physical-evaluation-data__generic-note">

            <p className="physical-evaluation-data__generic-note__text">

              Todos os itens abaixo devem ser atribuídos levando-se em consiração que quanto maior a nota melhor
              a percepção do aluno sobre o determinado ponto. Ex: <strong>Nível de stress:</strong> quanto maior
              a nota menos estressado o aluno se sentiu nos últimos 2-3 meses.

            </p>

          </div>

          <div className="physical-evaluation-data__reference-container">

            <ul className="physical-evaluation-data__scale-reference">

              <li className="physical-evaluation-data__scale-item--1">

                <h4 className="physical-evaluation-data__scale-item__label"> Fraco </h4>
                <p className="physical-evaluation-data__scale-item__value"> 1 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--5">

                <h4 className="physical-evaluation-data__scale-item__label"> Moderado </h4>
                <p className="physical-evaluation-data__scale-item__value"> 5 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--7">

                <h4 className="physical-evaluation-data__scale-item__label"> Bem </h4>
                <p className="physical-evaluation-data__scale-item__value"> 7 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--10">

                <h4 className="physical-evaluation-data__scale-item__label"> Excelente </h4>
                <p className="physical-evaluation-data__scale-item__value"> 10 </p>

              </li>

            </ul>

          </div>

          <HalfWrapper>

            <DefaultInput
              name="welfare_energy_level"
              isHighlighted={this.isHighlighted("welfare_energy_level")}
              label="Nível de energia"
              type="number"
              placeholder="Nível de energia"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.welfare_energy_level}
              autoComplete="off"
              labelMessage={this.getLastValue('welfare_energy_level')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="welfare_sleep_quality"
              isHighlighted={this.isHighlighted("welfare_sleep_quality")}
              label="Qualidade do sono"
              type="number"
              placeholder="Qualidade do sono"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.welfare_sleep_quality}
              autoComplete="off"
              labelMessage={this.getLastValue('welfare_sleep_quality')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="welfare_stress_level"
              isHighlighted={this.isHighlighted("welfare_stress_level")}
              label="Nível de stress"
              type="number"
              placeholder="Nível de stress"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.welfare_stress_level}
              autoComplete="off"
              labelMessage={this.getLastValue('welfare_stress_level')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="welfare_self_image"
              isHighlighted={this.isHighlighted("welfare_self_image")}
              label="Autoimagem"
              type="number"
              placeholder="Autoimagem"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.welfare_self_image}
              autoComplete="off"
              labelMessage={this.getLastValue('welfare_self_image')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="welfare_self_esteem"
              isHighlighted={this.isHighlighted("welfare_self_esteem")}
              label="Autoestima"
              type="number"
              placeholder="Autoestima"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.welfare_self_esteem}
              autoComplete="off"
              labelMessage={this.getLastValue('welfare_self_esteem')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DummyInput />

          </HalfWrapper>

          <DefaultSubSectionTitle
            className="physical-evaluation-data__subsection"
            icon={<i className="fas fa-bullseye"></i>}
            text="F.O.M.D.A."
          />

          <div className="physical-evaluation-data__reference-container">

            <ul className="physical-evaluation-data__scale-reference">

              <li className="physical-evaluation-data__scale-item--1">

                <h4 className="physical-evaluation-data__scale-item__label"> Fraco </h4>
                <p className="physical-evaluation-data__scale-item__value"> 1 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--5">

                <h4 className="physical-evaluation-data__scale-item__label"> Moderado </h4>
                <p className="physical-evaluation-data__scale-item__value"> 5 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--7">

                <h4 className="physical-evaluation-data__scale-item__label"> Bem </h4>
                <p className="physical-evaluation-data__scale-item__value"> 7 </p>

              </li>

              <li className="physical-evaluation-data__scale-item--10">

                <h4 className="physical-evaluation-data__scale-item__label"> Excelente </h4>
                <p className="physical-evaluation-data__scale-item__value"> 10 </p>

              </li>

            </ul>

          </div>

          <HalfWrapper>

            <DefaultInput
              name="fomda_frequency"
              isHighlighted={this.isHighlighted("fomda_frequency")}
              label="Frequência"
              type="number"
              placeholder="Nota de frequência"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.fomda_frequency}
              autoComplete="off"
              labelMessage={this.getLastValue('fomda_frequency')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="fomda_objective"
              isHighlighted={this.isHighlighted("fomda_objective")}
              label="Objetivo (foco)"
              type="number"
              placeholder="Nota de objectivo"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.fomda_objective}
              autoComplete="off"
              labelMessage={this.getLastValue('fomda_objective')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="fomda_motivation"
              isHighlighted={this.isHighlighted("fomda_motivation")}
              label="Motivação"
              type="number"
              placeholder="Nota de motivação"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.fomda_motivation}
              autoComplete="off"
              labelMessage={this.getLastValue('fomda_motivation')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="fomda_dedication"
              isHighlighted={this.isHighlighted("fomda_dedication")}
              label="Dedicação"
              type="number"
              placeholder="Nota de dedicação"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.fomda_dedication}
              autoComplete="off"
              labelMessage={this.getLastValue('fomda_dedication')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name="fomda_feeding"
              isHighlighted={this.isHighlighted("fomda_feeding")}
              label="Alimentação"
              type="number"
              placeholder="Nota de alimentação"
              step="1"
              min="1"
              max="10"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exam_data.fomda_feeding}
              autoComplete="off"
              labelMessage={this.getLastValue('fomda_feeding')}
              labelColor={LAST_VALUE_TEXT_COLOR}
              onKeyDown={(event) => this.handleKeyDown(event)}
              onFocus={(event) => event.target.select()}
            />

            <DummyInput />

          </HalfWrapper>

          {this.props.sendReportEmail === false || this.props.sendReportEmail === true ?
            <React.Fragment>

              <HorizontalRule />

              <DefaultInput
                name="sendReportEmail"
                isHighlighted={false}
                label="Enviar email ao salvar: "
                type="toggle"
                isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.sendReportEmail}
              />

            </React.Fragment>:
            null
          }

        </div>

        <HorizontalRule />

        <div className="physical-evaluation-data__buttons-container">

          <button
            className="physical-evaluation-data__save-button"
            disabled={!this.props.enableSave}
            onClick={() => this.onSaveClicked()}
          >

            Salvar

          </button>

          <button
            className="physical-evaluation-data__cancel-button"
            onClick={() => this.props.history.goBack()}
          >

            Cancelar

          </button>

          {/* <Link
            className="physical-evaluation-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link> */}

        </div>

      </React.Fragment>
    );
  }

  render() {
    if(this.props.removeFrame) {
      return this.getFormContent();
    }

    return (
      <DefaultSection
        className="physical-evaluation-data"
        title="Dados da avaliação"
      >

        {this.getFormContent()}

      </DefaultSection>
    );
  }
}

export default PhysicalEvaluationData;
