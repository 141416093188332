import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, getModel, patchModel} from '../../utils/functions';
import ExerciseActivityData from './exercise_activity_data';

class ExerciseActivityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercise_activity: {
        name: "",
        met: 0,
        description: "",
        activity_type_id: "",
        show_in_student_app: false,
        used_in_trainings: true
      },
      exerciseActivityTypes: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    let exercise_activity = getModel(`${routes.EXERCISE_ACTIVITY_GET_API}${this.props.match.params.exerciseActivityId}`);
    let exerciseActivityTypes = getModels(routes.EXERCISE_ACTIVITY_TYPES_GET_API);

    const update = {
      loading: false
    }

    exercise_activity = await exercise_activity;

    if(exercise_activity) {
      update.exercise_activity = {...this.state.exercise_activity, ...exercise_activity};
    }

    exerciseActivityTypes = await exerciseActivityTypes;

    if(exerciseActivityTypes) {
      update.exerciseActivityTypes = exerciseActivityTypes;
      update.exerciseActivityTypes.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.exercise_activity, [name]: value};

    this.setState({
      exercise_activity: newData
    });
  }

  inputsAreValid() {
    return this.state.exercise_activity.name.length > 0 &&
           this.state.exercise_activity.met > 0 &&
           (this.state.exercise_activity.activity_type_id && this.state.exercise_activity.activity_type_id > 0);
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.exercise_activity}
    data.met = parseFloat(data.met);

    try {
      await patchModel(`${routes.EXERCISE_ACTIVITY_PATCH_API}${this.props.match.params.exerciseActivityId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.EXERCISE_ACTIVITY_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.EXERCISE_ACTIVITY_LIST_PATH,
            text: "Listar atividades"
          },
          {
            path: `${routes.EXERCISE_ACTIVITY_EDIT_PATH}${this.props.match.params.exerciseActivityId}`,
            text: "Editar atividade"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar atividade"
        loading={this.state.loading}
      >

        <ExerciseActivityData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          exercise_activity={this.state.exercise_activity}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.EXERCISE_ACTIVITY_LIST_PATH}
          exerciseActivityTypes={this.state.exerciseActivityTypes}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default ExerciseActivityEdit;
