import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {PHYSICAL_AVALIATION_PATH} from '../student/constants';
import {getModel, patchModel, postModel, getAsLocalDate} from '../../utils/functions';
import PhysicalEvaluationData from './physical_evaluation_data';
import {DateLinePoint} from '../../graphs/simple_line_graph';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, BODY_FAT_PROTOCOLS, BASAL_METABOLIC_RATE_PROTOCOLS} from '../../constants';
import * as permissions from '../../permissions';

class PhysicalEvaluationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exam: {},
      exam_data: {
        fomda_frequency: "",
        fomda_objective: "",
        fomda_motivation: "",
        fomda_dedication: "",
        fomda_feeding: "",
        welfare_energy_level: "",
        welfare_sleep_quality: "",
        welfare_stress_level: "",
        welfare_self_image: "",
        welfare_self_esteem: "",
        circumference_thorax: "",
        circumference_hip: "",
        circumference_waist: "",
        circumference_abdomen: "",
        circumference_contracted_arm_d: "",
        circumference_contracted_arm_e: "",
        circumference_relaxed_arm_d: "",
        circumference_relaxed_arm_e: "",
        circumference_forearm_d: "",
        circumference_forearm_e: "",
        circumference_thigh_d: "",
        circumference_thigh_e: "",
        circumference_calf_d: "",
        circumference_calf_e: "",
        circumference_scapular: "",
        circumference_neck: "",
        blood_pressure_systolic: "",
        blood_pressure_diastolic: "",
        resting_heart_rate: "",
        weight: "",
        height: "",
        desired_imc: "",
        desired_weight: "",
        basal_metabolic_rate_protocol: "",
        basal_metabolic_rate: "",
        basal_metabolic_multiplier: "",
        visceral_fat_level: "",
        skeletal_muscle_percentage: "",
        body_fat_percentage: "",
        body_fat_protocol: "",
        skinfold_triceps: "",
        skinfold_subscapular: "",
        skinfold_suprailiac: "",
        skinfold_abdomen: "",
        skinfold_thigh: "",
        skinfold_thorax: "",
        skinfold_midaxilla: "",
        diameter_wrist: "",
        diameter_knee: "",
        main_fyd_activities: "",
        other_main_activities: "",
        active_weekdays: 0,
      },
      student: null,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      sendReportEmail: false,
      requiresPersonalPeriod: true,
      weekly_exercise_frequency: 0,
      current_training_period_progress: null,
      loading: true,
      isbluetoothAvailable: false,
      syncingToRestingHrDevice: false,
      restingHrDeviceName: null,
      restingHrData: [],
      showRestingHrGraph: false
    };
  }

  async componentDidMount() {
    try {
      const exam = await getModel(`${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_GET}${this.props.match.params.physicalEvaluationId}?include_last_exam=true&load_student=true`);

      const exam_data = {...this.state.exam_data, ...exam.data};
      delete exam.data;

      const student = {...exam.student};
      delete exam.student;

      const status = await getModel(routes.STUDENT_PERSONAL_TRAINING_SERVICE_STATUS_GET_API.replace('{student_id}', student.id.toString()));

      if(exam && status) {
        const isbluetoothAvailable = await this.props.bluetoothDevices.isBluetoothAvailable();

        this.setState({
          exam: {...this.state.exam, ...exam},
          exam_data,
          student,
          loading: false,
          requiresPersonalPeriod: status.track_personal_training_service,
          weekly_exercise_frequency: status.weekly_exercise_frequency,
          current_training_period_progress: status.current_training_period_progress,
          isbluetoothAvailable: isbluetoothAvailable
        });
      }
      else {
        // this.props.history.replace(routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH);
        this.props.history.goBack();
      }
    }
    catch(errors) {
      this.props.history.goBack();
    }
  }

  componentWillUnmount() {
    this.props.bluetoothDevices.disconnectAllDevices();
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    if(name === 'sendReportEmail' || name === 'requiresPersonalPeriod') {
      value = target.type === 'checkbox' ? target.checked : target.value;

      this.setState({
        [name]: value
      });

      return;
    }

    if(name === 'blood_pressure_systolic' ||
       name === 'blood_pressure_diastolic' ||
       name === 'welfare_energy_level' ||
       name === 'welfare_sleep_quality' ||
       name === 'welfare_stress_level' ||
       name === 'welfare_self_image' ||
       name === 'welfare_self_esteem' ||
       name === 'fomda_frequency' ||
       name === 'fomda_objective' ||
       name === 'fomda_motivation' ||
       name === 'fomda_dedication' ||
       name === 'fomda_feeding') {
      if(value) {
        value = parseInt(value);
      }
    }
    else if(name === 'resting_heart_rate') {
      if(value) {
        value = parseFloat(value);
      }
    }
    else if(name.startsWith('main_fyd_activities')) {
      value = target.type === 'checkbox' ? target.checked : target.value;

      const selection = name.split(':');
      const service = selection[1];

      let exam_data = {
        ...this.state.exam_data,
      };

      name = selection[0];

      const currentServices = exam_data.main_fyd_activities.split(';');

      const serviceIndex = currentServices.indexOf(service);

      if (value && serviceIndex < 0) {
        currentServices.push(service);
      }
      else if (serviceIndex >= 0) {
        currentServices.splice(serviceIndex, 1);
      }

      value = currentServices.join(';');
    }
    else if(name.startsWith('active_weekdays')) {
      value = target.type === 'checkbox' ? target.checked : target.value;

      const selection = name.split(':');
      const weekdayIndex = parseInt(selection[1]);

      let exam_data = {
        ...this.state.exam_data,
      };

      name = selection[0];

      const weekdayValue = Math.pow(2, weekdayIndex);

      if (!value) {
        value = exam_data.active_weekdays ^ weekdayValue;
      }
      else {
        value = exam_data.active_weekdays | weekdayValue;
      }
    }

    const newData = {...this.state.exam_data, [name]: value};

    this.setState({
      exam_data: newData
    });
  }

  inputsAreValid() {
    return true;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.exam_data};

    for(let [key, value] of Object.entries(data)) {
      // if(value === '' || value === null) {
      //   delete data[key];
      // }
      // else {
      //   data[key] = parseFloat(value);
      // }

      if(key !== 'body_fat_protocol' &&
         key !== 'basal_metabolic_rate_protocol' &&
         key !== 'main_fyd_activities' &&
         key !== 'other_main_activities') {
        data[key] = parseFloat(value);
      }
    }

    if(data.height && data.height > 4) {
      this.setState({
        highlights: ['height'],
        showWarningMessage: true,
        warningMessage: "Altura não pode ser maior que 4 metros.",
        loading: false
      });

      return;
    }

    const examDate = getAsLocalDate(this.state.exam.scheduled_date);
    const birthdate = getAsLocalDate(this.state.student.birthdate);

    const timeDiff = Math.abs(examDate.getTime() - birthdate.getTime());
    const age = Math.ceil(timeDiff / (1000 * 3600 * 24 * 365));

    switch(this.state.exam_data.body_fat_protocol) {
      case BODY_FAT_PROTOCOLS[1]:
        if(data.skinfold_triceps && data.skinfold_subscapular && data.skinfold_suprailiac && data.skinfold_abdomen) {
          const sum = data.skinfold_triceps + data.skinfold_subscapular + data.skinfold_suprailiac + data.skinfold_abdomen;

          data.body_fat_percentage = (0.153*sum) + 5.783;
        }
        break;
      case BODY_FAT_PROTOCOLS[2]:
        if(this.state.student.gender === 'Masculino') {
          if(data.skinfold_triceps && data.skinfold_suprailiac && data.skinfold_abdomen) {
            const sum = data.skinfold_triceps + data.skinfold_suprailiac + data.skinfold_abdomen;

            const bodyDensity = 1.17136 - (0.06706 * (Math.log(sum) / Math.log(10)));

            data.body_fat_percentage = ((4.95 / bodyDensity) - 4.5) * 100;
          }
        }
        else if(data.skinfold_thigh && data.skinfold_suprailiac && data.skinfold_subscapular) {
          const sum = data.skinfold_thigh + data.skinfold_suprailiac + data.skinfold_subscapular;

          const bodyDensity = 1.16650 - (0.07063 * (Math.log(sum) / Math.log(10)));

          data.body_fat_percentage = ((4.95 / bodyDensity) - 4.5) * 100;
        }
        break;
      case BODY_FAT_PROTOCOLS[3]:
        if(this.state.student.gender === 'Masculino') {
          if(data.skinfold_thorax && data.skinfold_abdomen && data.skinfold_thigh) {
            const sum = data.skinfold_thorax + data.skinfold_abdomen + data.skinfold_thigh;

            const bodyDensity = 1.10938 - (0.0008267 * sum) + (0.0000016 * Math.pow(sum, 2)) - (0.0002574 * age);

            data.body_fat_percentage = ((4.95 / bodyDensity) - 4.5) * 100;
          }
        }
        else if(data.skinfold_triceps && data.skinfold_suprailiac && data.skinfold_thigh) {
          const sum = data.skinfold_triceps + data.skinfold_suprailiac + data.skinfold_thigh;

          const bodyDensity = 1.0994921 - (0.0009929 * sum) + (0.0000023 * Math.pow(sum, 2)) - (0.0001392 * age);

          data.body_fat_percentage = ((4.95 / bodyDensity) - 4.5) * 100;
        }
        break;
      case BODY_FAT_PROTOCOLS[4]:
        if(data.skinfold_thorax && data.skinfold_midaxilla && data.skinfold_triceps && data.skinfold_subscapular && data.skinfold_abdomen && data.skinfold_suprailiac && data.skinfold_thigh) {
          const sum = data.skinfold_thorax + data.skinfold_midaxilla + data.skinfold_triceps + data.skinfold_subscapular + data.skinfold_abdomen + data.skinfold_suprailiac + data.skinfold_thigh;

          const bodyDensity = 1.11200000 - (0.00043499 * sum) + (0.00000055 * Math.pow(sum, 2)) - (0.0002882 * age);

          data.body_fat_percentage = ((4.95 / bodyDensity) - 4.5) * 100;
        }
        break;
        case BODY_FAT_PROTOCOLS[5]:
          if(this.state.student.gender === 'Masculino') {
            if(data.circumference_neck && data.circumference_abdomen && data.height) {
              data.body_fat_percentage = (495 / (1.033 - (0.191 * (Math.log(data.circumference_abdomen - data.circumference_neck) / Math.log(10))) + (0.155 * (Math.log(data.height * 100) / Math.log(10))))) - 450;
            }
            // if(data.circumference_neck && data.circumference_waist && data.height) {
            //   data.body_fat_percentage = (495 / (1.033 - (0.191 * (Math.log(data.circumference_waist - data.circumference_neck) / Math.log(10))) + (0.155 * (Math.log(data.height * 100) / Math.log(10))))) - 450;
            // }
          }
          else if(data.circumference_neck && data.circumference_waist && data.circumference_hip && data.height) {
            data.body_fat_percentage = (495 / (1.296 - (0.35 * (Math.log(data.circumference_hip + data.circumference_waist - data.circumference_neck) / Math.log(10))) + (0.221 * (Math.log(data.height * 100) / Math.log(10))))) - 450;
          }
          break;
      default:
    }

    switch(this.state.exam_data.basal_metabolic_rate_protocol) {
      case BASAL_METABOLIC_RATE_PROTOCOLS[1]:
        if(data.weight && data.height) {
          if(this.state.student.gender === 'Masculino') {
            data.basal_metabolic_rate = 88.362 + (13.397 * data.weight) + (4.799 * 100 * data.height) - (5.677 * age);
          }
          else {
            data.basal_metabolic_rate = 447.593 + (9.247 * data.weight) + (3.098 * 100 * data.height) - (4.330 * age);
          }

          // if(data.basal_metabolic_multiplier) {
          //   data.basal_metabolic_rate *= data.basal_metabolic_multiplier;
          // }
        }
        break;
      case BASAL_METABOLIC_RATE_PROTOCOLS[2]:
        if(data.weight && data.height) {
          if(this.state.student.gender === 'Masculino') {
            data.basal_metabolic_rate = (10.0 * data.weight) + (6.25 * 100 * data.height) - (5.0 * age) + 5;
          }
          else {
            data.basal_metabolic_rate = (10.0 * data.weight) + (6.25 * 100 * data.height) - (5.0 * age) - 161;
          }
        }
        break;
      case BASAL_METABOLIC_RATE_PROTOCOLS[3]:
        if(data.weight && data.body_fat_percentage) {
          data.basal_metabolic_rate = 500 + (22 * data.weight * (1 - (data.body_fat_percentage / 100)));
        }
        break;
      default:
    }

    if(this.state.exam_data.body_fat_protocol !== BODY_FAT_PROTOCOLS[0]) {
      if(data.body_fat_percentage) {
        let residualPercentage = this.state.student.gender === 'Masculino' ? 24.1 : 20.9;
        let bonePercentage = 14;

        if(data.diameter_wrist && data.diameter_knee && data.height && data.weight) {
          const boneMass = 3.02 * Math.pow((Math.pow(data.height, 2) * data.diameter_wrist * data.diameter_knee * 0.0004), 0.712);
          bonePercentage = 100 * boneMass / data.weight;
        }

        data.skeletal_muscle_percentage = 100 - (data.body_fat_percentage + residualPercentage + bonePercentage);
      }
    }

    data.completed = true;

    let dataSaved = false;

    try {
      const mainDataProcess = patchModel(`${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_PATCH}${this.props.match.params.physicalEvaluationId}`, data);
      const statusProcess = patchModel(routes.STUDENT_PERSONAL_TRAINING_SERVICE_TRACKING_PATCH_API.replace('{student_id}', this.state.student.id.toString()), {
        track_personal_training_service: this.state.requiresPersonalPeriod
      });

      await mainDataProcess;
      await statusProcess;

      dataSaved = true;

      if(this.state.sendReportEmail) {
        await postModel(routes.NOTIFICATION_PHYSICAL_EVALUATION_REPORT_LINK_POST, {user_ids: [this.state.exam.user_id]})
      }
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              let message = 'Valor(es) inválido(s)';

              for(let parameter of error.parameters) {
                if(parameter.name === 'body_fat_percentage') {
                  message = 'Valores inválidos para o cálculo de gordura corporal';

                  switch(this.state.exam_data.body_fat_protocol) {
                    case BODY_FAT_PROTOCOLS[1]:
                      highlights.push('skinfold_triceps');
                      highlights.push('skinfold_subscapular');
                      highlights.push('skinfold_suprailiac');
                      highlights.push('skinfold_abdomen');
                      break;
                    case BODY_FAT_PROTOCOLS[2]:
                      if(this.state.student.gender === 'Masculino') {
                        highlights.push('skinfold_abdomen');
                        highlights.push('skinfold_suprailiac');
                        highlights.push('skinfold_abdomen');
                      }
                      else {
                        highlights.push('skinfold_thigh');
                        highlights.push('skinfold_suprailiac');
                        highlights.push('skinfold_subscapular');
                      }
                      break;
                    case BODY_FAT_PROTOCOLS[3]:
                      if(this.state.student.gender === 'Masculino') {
                        highlights.push('skinfold_thorax');
                        highlights.push('skinfold_abdomen');
                        highlights.push('skinfold_thigh');
                      }
                      else {
                        highlights.push('skinfold_triceps');
                        highlights.push('skinfold_suprailiac');
                        highlights.push('skinfold_thigh');
                      }
                      break;
                    case BODY_FAT_PROTOCOLS[4]:
                      highlights.push('skinfold_thorax');
                      highlights.push('skinfold_midaxilla');
                      highlights.push('skinfold_triceps');
                      highlights.push('skinfold_subscapular');
                      highlights.push('skinfold_abdomen');
                      highlights.push('skinfold_suprailiac');
                      highlights.push('skinfold_thigh');
                      break;
                    case BODY_FAT_PROTOCOLS[5]:
                      if(this.state.student.gender === 'Masculino') {
                        highlights.push('circumference_neck');
                        highlights.push('circumference_abdomen');
                        highlights.push('height');
                      }
                      else {
                        highlights.push('circumference_neck');
                        highlights.push('circumference_waist');
                        highlights.push('circumference_hip');
                        highlights.push('height');
                      }
                      break;
                    default:
                  }
                }
                else {
                  highlights.push(parameter.name);
                }
              }

              warningMessages.push(message);

              break;
            case 208:
              if (error.message.includes('Inactive student')) {
                warningMessages.push('Usuário não possui um contrato ativo');
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      if(warningMessages.length <= 0) {
        if(dataSaved) {
          warningMessages.push(`Dados salvos, mas houve um erro ao enviar email: ${DEFAULT_UNKNOWN_ERROR_MESSAGE}`);
        }
        else {
          warningMessages.push(`Erro ao salvar dados: ${DEFAULT_UNKNOWN_ERROR_MESSAGE}`);
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // this.props.history.replace(routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH);
    this.props.history.goBack();
  }

  parseHeartRate(value) {
    value = value.buffer ? value : new DataView(value);

    let flags = value.getUint8(0);
    let rate16Bits = flags & 0x1;
    let index = 1;

    let heartRate = null;

    if (rate16Bits) {
      heartRate = value.getUint16(index, /*littleEndian=*/true);
      index += 2;
    } else {
      heartRate = value.getUint8(index);
      index += 1;
    }

    return heartRate;
  }

  async onDesconnectDevices() {
    await this.props.bluetoothDevices.disconnectAllDevices();

    this.setState({restingHrDeviceName: null});
  }

  async onSyncRestingHr() {
    this.setState({syncingToRestingHrDevice: true});

    if(this.state.restingHrDeviceName !== null) {
      await this.props.bluetoothDevices.disconnectAllDevices();
      this.setState({restingHrDeviceName: null});
    }

    const deviceResponse = await this.props.bluetoothDevices.requestDevice(null, ['heart_rate']);

    if(deviceResponse.error !== null) {
      this.setState({syncingToRestingHrDevice: false});
    }
    else {
      const true_device_identifier = deviceResponse.device.name;

      this.setState({restingHrDeviceName: true_device_identifier});

      const response = await this.props.bluetoothDevices.subscribeToCharacteristic(
        true_device_identifier,
        'heart_rate',
        'heart_rate_measurement',
        (value) => {
          const now = new Date();
          const timeFilter = new Date(now);
          const meanTimeFilter = new Date(now);
          timeFilter.setMinutes(now.getMinutes() - 15);
          meanTimeFilter.setMinutes(now.getMinutes() - 3);

          const hr = this.parseHeartRate(value);

          const meanValues = [];

          const data = [];
          let lowerstEntry = null;

          for(const entry of this.state.restingHrData) {
            if(entry.date < timeFilter) {
              continue;
            }

            entry.index = null;

            data.push(entry);

            if(lowerstEntry === null || entry.value < lowerstEntry.value) {
              lowerstEntry = entry;
            }

            if(entry.date >= meanTimeFilter) {
              meanValues.push(entry.value);
            }
          }

          const newEntry = new DateLinePoint(hr, now);
          meanValues.push(newEntry.value);

          if(lowerstEntry === null || newEntry.value < lowerstEntry.value) {
            lowerstEntry = newEntry;
          }

          lowerstEntry.index = '\u2193';

          data.push(newEntry);

          let meanHr = Math.round(10 * meanValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / meanValues.length) / 10;

          const newData = {...this.state.exam_data, resting_heart_rate: meanHr};

          this.setState({
            exam_data: newData,
            syncingToRestingHrDevice: false,
            restingHrData: data,
            showRestingHrGraph: true
          });
        },
        () => {
          window.alert("Dispositivo temporariamente desconectado.");
        }
      );

      if(response.error !== null) {
        window.alert(`Falha ao coletar dados do sensor (${true_device_identifier}). Erro: ${response.error}`);
      }
    }
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${PHYSICAL_AVALIATION_PATH}` : routes.APPOINTMENT_LIST_PATH,
            text: "Gerenciar aluno"
          },
          {
            path: `${routes.PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH}${this.props.match.params.physicalEvaluationId}`,
            text: "Preencher avaliação física"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Preencher avaliação física"
        loading={this.state.loading}
      >

        <PhysicalEvaluationData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          exam={this.state.exam}
          exam_data={this.state.exam_data}
          sendReportEmail={this.props.userPermissionIds.includes(permissions.SEND_PHYSICAL_EVALUATION_REPORT_NOTIFICATION_PERMISSION_ID) ? this.state.sendReportEmail : null}
          requiresPersonalPeriod={this.state.requiresPersonalPeriod}
          weekly_exercise_frequency={this.state.weekly_exercise_frequency}
          current_training_period_progress={this.state.current_training_period_progress}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          // onCancelPath={routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH}
          history={this.props.history}
          student={this.state.student}
          onSyncRestingHr={() => this.onSyncRestingHr()}
          onDesconnectDevices={() => this.onDesconnectDevices()}
          isbluetoothAvailable={this.state.isbluetoothAvailable}
          syncingToRestingHrDevice={this.state.syncingToRestingHrDevice}
          restingHrDeviceName={this.state.restingHrDeviceName}
          showRestingHrGraph={this.state.showRestingHrGraph}
          restingHrData={this.state.restingHrData}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default PhysicalEvaluationEdit;
