import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import WarningMessage from '../warning_message';
import './promotional_coupon_data.scss';


class PromotionalCouponData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceFilter: "",
      servicesSectionVisible: false
    };
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.promotional_coupon.id && this.props.promotional_coupon.id > 0;
  }

  getOwnerOptions() {
    let options = this.props.users.map((entry) => SelectOption(entry.id, entry.name));

    return [
      SelectOption('', 'Não vinculado'),
      ...options
    ];
  }

  getServiceOptions() {
    if(!this.props.services) {
      return null;
    }

    const filteredServices = this.props.services.filter((service) => service.name.toLocaleLowerCase().includes(this.state.serviceFilter.toLocaleLowerCase()));

    if(filteredServices.length <= 0) {
      return null;
    }

    return filteredServices.map((service) => {
      const selected = this.props.promotional_coupon.service_ids.includes(service.id);

      return (
        <div
          key={`promotional_coupon_data:service:${service.id}`}
          className={`promotional-coupon-data__service-option${!selected ? '--disabled': ''}`}
        >

          <button
            className="promotional-coupon-data__service-option__check-button"
            onClick={() => this.props.onToggleService(service.id)}
          >

            {selected &&
              <i className="fas fa-check"></i>
            }

          </button>

          <p className="promotional-coupon-data__service-option__text">

            {service.name}

          </p>

        </div>
      );
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <DefaultSection
        className="promotional-coupon-data"
        title="Dados do cupom de desconto"
      >

        <div className="promotional-coupon-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="promotional-coupon-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.promotional_coupon.is_active}
            horizontalAlign="right"
          />

          <HalfWrapper>

            <DefaultInput
              name="code"
              isHighlighted={this.isHighlighted("code")}
              label="Código do cupom"
              type="text"
              placeholder="Código do cupom"
              maxLength="32"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotional_coupon.code}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="owner_id"
              isHighlighted={this.isHighlighted("owner_id")}
              label="Funcionário vinculado"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotional_coupon.owner_id || ''}
              options={this.getOwnerOptions()}
              disabled={this.isEditMode()}
            />

          </HalfWrapper>

          <DefaultInput
            name="discount_percentage"
            isHighlighted={this.isHighlighted("discount_percentage")}
            label="Desconto"
            type="range"
            placeholder="Porcentagem"
            min="0"
            max={100}
            step="0.5"
            handleInputChange={this.props.handleInputChange}
            value={this.props.promotional_coupon.discount_percentage !== null ? Math.round(this.props.promotional_coupon.discount_percentage * 1000) / 10 : 0}
            autoComplete="off"
            suffix="%"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <HalfWrapper>

            <DefaultInput
              name="valid_since"
              isHighlighted={this.isHighlighted("valid_since")}
              label="Válido a partir de"
              type="date"
              placeholder="Válido a partir de"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotional_coupon.valid_since}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="valid_until"
              isHighlighted={this.isHighlighted("valid_until")}
              label="Válido até"
              labelMessage="O não preenchimento implica que o cupom não possui data de expiração"
              type="date"
              placeholder="Válido até"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotional_coupon.valid_until || ''}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <DefaultInput
            name="limited_use"
            label="Uso limitado:"
            type="toggle"
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.promotional_coupon.limited_use}
          />

          {this.props.promotional_coupon.limited_use &&
            <DefaultInput
              name="remaining_use_count"
              isHighlighted={this.isHighlighted("remaining_use_count")}
              label="Utilizações restantes"
              type="number"
              placeholder="Quantidade de uso disponível"
              min="0"
              step="1"
              handleInputChange={this.props.handleInputChange}
              value={this.props.promotional_coupon.remaining_use_count}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              labelMessage="Este número será atualizado cada vez que o cupom for utilizado"
            />
          }

          <DefaultInput
            name="website_enabled"
            label="Pode ser utilizado em site:"
            type="toggle"
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.promotional_coupon.website_enabled}
          />

          <HorizontalRule />

          <section className="promotional-coupon-data__default-section">

            <header
              className="promotional-coupon-data__default-section__header"
              onClick={() => this.setState({servicesSectionVisible: !this.state.servicesSectionVisible})}
            >

              <h3 className="promotional-coupon-data__default-section__header__text">
                <i className="fa-solid fa-bell-concierge promotional-coupon-data__default-section__header__text-icon"></i>
                Serviços vinculados
              </h3>

              {this.state.servicesSectionVisible ?
                <i className="fas fa-chevron-down promotional-coupon-data__default-section__header__visible-icon"></i>:
                <i className="fas fa-chevron-up promotional-coupon-data__default-section__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container promotional-coupon-data__default-section__content"
              pose={this.state.servicesSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container promotional-coupon-data__default-section__content-wrapper">

                <div className="promotional-coupon-data__default-section-container">

                <DefaultInput
                  name="serviceFilter"
                  label="Busca rápida:"
                  type="text"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.serviceFilter}
                  autoComplete="off"
                  disabled={this.state.confirmInProgress}
                />

                <div className="promotional-coupon-data__service-options-container">

                  {this.getServiceOptions()}

                </div>

                </div>

              </div>

            </VerticalAccordionContainer>

          </section>

        </div>

        <HorizontalRule />

        <div className="promotional-coupon-data__buttons-container">

          <button
            className="promotional-coupon-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="promotional-coupon-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default PromotionalCouponData;
