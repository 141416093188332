import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, getModel, patchModel} from '../../utils/functions';
import FinancialCategoryData from './financial_category_data';

class FinancialCategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_category: {
        name: "",
        type: "Ambos",
        cost_center_id: "",
        description: "",
        remove_cost_center: false,
        parent_id: "",
        color: '#000000'
      },
      financialCategories: [],
      costCenters: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let update = {
      loading: false
    };

    let financialCategories = getModels(routes.FINANCIAL_CATEGORIES_GET_API);
    let financial_category = getModel(`${routes.FINANCIAL_CATEGORY_GET_API}${this.props.match.params.financialCategoryId}`);

    financialCategories = await financialCategories;
    if(financialCategories) {
      update.financialCategories = financialCategories;
    }

    financial_category = await financial_category;

    if(financial_category) {
      update.financial_category = {...this.state.financial_category, ...financial_category}
    }
    else {
      this.props.history.replace(routes.FINANCIAL_CATEGORY_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    if(name === 'cost_center_id') {
      name = 'remove_cost_center';
      value = !(value && value > 0);
    }

    const newData = {...this.state.financial_category, [name]: value};

    this.setState({
      financial_category: newData
    });
  }

  inputsAreValid() {
    return this.state.financial_category.name.length > 0 &&
           (this.state.financial_category.type === 'Receita' ||
            this.state.financial_category.type === 'Gasto' ||
            this.state.financial_category.type === 'Ambos');
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.financial_category}
    delete data.cost_center_id;
    if(!data.parent_id) {
      data.parent_id = null;
    }
    else {
      data.parent_id = parseInt(data.parent_id);
    }

    try {
      await patchModel(`${routes.FINANCIAL_CATEGORY_PATCH_API}${this.props.match.params.financialCategoryId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FINANCIAL_CATEGORY_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FINANCIAL_CATEGORY_LIST_PATH,
            text: "Listar categorias"
          },
          {
            path: `${routes.FINANCIAL_CATEGORY_EDIT_PATH}${this.props.match.params.financialCategoryId}`,
            text: "Editar categoria"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar categoria financeira"
        loading={this.state.loading}
      >

        <FinancialCategoryData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_category={this.state.financial_category}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FINANCIAL_CATEGORY_LIST_PATH}
          financialCategories={this.state.financialCategories}
        />

      </ContentFrame>
    );
  }
}

export default FinancialCategoryEdit;
