import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from './color_pallet';
import {getCurrencyText} from '../utils/functions';

function PiePoint(value, total, name, color=null) {
  return {
    value,
    total,
    name,
    color
  }
}

export {PiePoint};

function getPointData(percentage, value, name, color=null, total=null) {
  const point = { y: percentage, label: name, value: value };

  if(color) {
    point.color = color;
  }

  if(total) {
    point.total = total;
  }

  return point;
}

class PieGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getData() {
    const multiplier = this.props.defaultMultiplier || 100;
    const getValueText = this.props.valueTextCallback || getCurrencyText;
    const data = this.props.data.map((point) => getPointData(((point.value/point.total)*multiplier).toFixed(2), getValueText(point.value), point.name, point.color, getValueText(point.total)));

    data.sort((a, b) => {
      return b.y - a.y;
    });

    let toolTipContent = this.props.toolTipContent || `<span style='"'color: {color};'"'>{label}:</span> {y}% ({value})<br/>`;

    if(this.props.totalToolTipLabel) {
      toolTipContent += `<hr/><strong>${this.props.totalToolTipLabel}:</strong> {total}`;
    }

    return [{
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: toolTipContent,
				indexLabel: this.props.indexLabel || "{y}%",
				indexLabelPlacement: "inside",
				dataPoints: data
			}];
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			colorSet: DEFAULT_SEQUENTIAL_COLOR_PALLET,
      culture: 'pt-BR',
			toolTip: {
				shared: true
			},
			legend: {
        verticalAlign: this.props.legendVerticalAlign || "bottom",
				horizontalAlign: this.props.legendHorizontalAlign || "center",
			},
			data: this.getData()
		};

    if(this.props.title) {
      options.title = {
				text: this.props.title
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    return options;
  }

	render() {
		return (
		<div className={this.props.className}>

      {!this.props.hidden &&
        <CanvasJSReact.CanvasJSChart
          options={this.getOptions()}
          onRef={ref => this.chart = ref}
        />
      }
		</div>
		);
	}
}

export default PieGraph;
