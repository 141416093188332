import React from 'react';
import './context_popup.scss';

const SIDE_MARGIN = 4;

class ContextPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.container = null;
    this.arrowElement = null;

    this.containerRef = (element) => {
      if (element == null) {
        this.container = null;
        this.arrowElement = null;
      }
      else {
        this.container = element;

        this.arrowElement = element.getElementsByClassName('context-popup__arrow')[0];
      }
    };
  }

  async componentDidMount() {
    this.mayUpdatePosition = true;
    requestAnimationFrame(this.updateContainerPosition.bind(this));
  }

  componentWillUnmount() {
    this.mayUpdatePosition = false;
  }

  updateContainerPosition() {
    if (this.container !== null && this.props.targetElement !== null) {
      const containerRect = this.container.getBoundingClientRect();
      const rect = this.props.targetElement.getBoundingClientRect();
      const parentRect = this.container.parentElement.getBoundingClientRect();
      const arrowRect = this.arrowElement.getBoundingClientRect();

      let arrowBoxShadow;

      let relativeXPosition = rect.x - parentRect.x + (rect.width / 2);
      let arrowXPosition = relativeXPosition - (containerRect.x - parentRect.x);
      let maxWidth = Math.min(relativeXPosition, parentRect.width - relativeXPosition) * 2;

      let relativeYPosition = rect.y - parentRect.y + (rect.height / 2);
      let arrowYPosition;

      let directionXMultiplier; // 1 -> to right | -1 -> to left
      // let directionYMultiplier; // 1 -> to bottom | -1 -> to top

      if (relativeXPosition >= parentRect.width / 2) {
        directionXMultiplier = -1;
        maxWidth = (parentRect.width - relativeXPosition - SIDE_MARGIN) * 2;
      }
      else {
        directionXMultiplier = 1;
        maxWidth = (relativeXPosition - SIDE_MARGIN) * 2;
      }

      if (rect.y >= (containerRect.height + 60 + SIDE_MARGIN)) { // 60 aproximates header size
        relativeYPosition -= (rect.height / 2) + containerRect.height + (0.6 * arrowRect.height);
        arrowYPosition = containerRect.height;
        arrowBoxShadow = '1px 1px #6a6a6a';
      }
      else {
        relativeYPosition += (rect.height / 2) + (0.6 * arrowRect.height);
        arrowYPosition = 0;
        arrowBoxShadow = '-1px -1px #6a6a6a';
      }

      if (maxWidth < 280 && containerRect.width > maxWidth) {
        const newMaxWidth = Math.min(parentRect.width - (2 * SIDE_MARGIN), 280);

        // relativeXPosition = relativeXPosition + (directionXMultiplier * (newMaxWidth - maxWidth) / 2);
        relativeXPosition = relativeXPosition + (directionXMultiplier * (containerRect.width - maxWidth) / 2);
        maxWidth = newMaxWidth;

        if (directionXMultiplier > 0) {
          arrowXPosition = Math.max(arrowXPosition, 1.1 * arrowRect.width / 2);
        }
        else {
          arrowXPosition = Math.min(arrowXPosition, containerRect.width - (1.1 * arrowRect.width / 2));
        }
      }

      this.container.style.maxWidth = `${maxWidth}px`;
      this.container.style.left = `${relativeXPosition - (containerRect.width / 2)}px`;
      this.container.style.top = `${relativeYPosition}px`;

      this.arrowElement.style.top = `${arrowYPosition}px`;
      this.arrowElement.style.boxShadow = arrowBoxShadow;
      this.arrowElement.style.left = `${arrowXPosition}px`;
    }

    if (this.mayUpdatePosition) {
      requestAnimationFrame(this.updateContainerPosition.bind(this));
    }
  }

  render() {
    if (this.props.targetElement === null || this.props.content === null) {
      return null;
    }

    return (
      <div
        className={`context-popup ${this.props.className || ''}`}
        ref={this.containerRef}
      >
        <div className="context-popup__arrow"></div>

        {this.props.content}
      </div>
    );
  }
}

export default ContextPopup;
