import React from 'react';
import './zone_gauge.scss';

const ANIMATION_PERIOD = 2000; //ms

class ZoneGauge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };

    this.current_index = 0;
    this.timeReference = 0;
    this.indexReference = 0;
    this.graphIsSet = false;
    this.animating = false;
    this.textOpacity = 1;
    this.stopDrawing = false;

    this.canvasSizeSet = false;

    this.canvasRef = React.createRef();
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    this.setGraphAnimation();
  }

  componentWillUnmount() {
    this.animating = false;
    this.stopDrawing = true;

    window.removeEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if(!this.animating && prevState.screenWidth !== this.state.screenWidth) {
      const canvas = this.canvasRef.current;
      canvas.width = 0;
      canvas.height = 0;

      this.canvasSizeSet = false;

      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  setGraphAnimation() {
    this.graphIsSet = true;
    this.timeReference = Date.now();
    this.indexReference = this.current_index;
    this.animating = true;

    const canvas = this.canvasRef.current;
    canvas.width = 0;
    canvas.height = 0;

    requestAnimationFrame(this.drawZoneGraph.bind(this));
  }

  getCurrentZoneIndex() {
    const increment = this.props.zone_index - this.indexReference;
    const timeRatio = (Date.now() - this.timeReference) / ANIMATION_PERIOD;

    // let result = this.indexReference + (Date.now() - this.timeReference) * increment / ANIMATION_PERIOD;
    // let result = this.indexReference + increment*Math.sin(timeRatio * 0.5*Math.PI );
    let result = this.indexReference + increment*((timeRatio - 1)*(timeRatio - 1)*(timeRatio - 1) + 1);

    if(timeRatio > 0.25) {
      const opacityRatio = (timeRatio - 0.25)/0.75;
      this.textOpacity = (opacityRatio - 1)*(opacityRatio - 1)*(opacityRatio - 1) + 1;
    }
    else {
      this.textOpacity = 0;
    }

    if(timeRatio >= 1) {
      result = this.props.zone_index;
      this.animating = false;
      this.current_index = this.props.zone_index;
    }

    return result;
  }

  drawZoneGraph() {
    if(this.stopDrawing) {
      return;
    }

    const canvas = this.canvasRef.current;

    if(!canvas) {
      return;
    }

    const margin = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 15 : 5;

    const maxZoneValue = 2.4;
    let zoneIndex = this.getCurrentZoneIndex();
    const zoneIndexText = zoneIndex.toFixed(2);
    zoneIndex = Math.min(zoneIndex, maxZoneValue);

    if(!this.canvasSizeSet) {
      canvas.width = canvas.parentNode.offsetWidth;
      canvas.height = canvas.parentNode.offsetHeight;

      this.canvasSizeSet = true;
    }

    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    const textMargin = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 12 : 8;
    const textSize = Math.min(Math.max(this.state.screenWidth*0.023, 14), 30);
    const textSidePadding = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 10 : 5;

    context.globalAlpha = 1;

    context.font = `${textSize}px Orbitron, sans-serif`;
    context.textBaseline  = 'bottom';
    context.textAlign   = 'left';
    const zoneTextMetric = context.measureText(zoneIndexText);
    const maxTextWidth = textSize*3;

    const radius = Math.min((canvas.width - 2*margin - 2*textMargin - 2*maxTextWidth - 2*textSidePadding)*0.5, canvas.height - 2*margin - textMargin - textSize);
    const segmentThickness = radius*0.25;

    const centerX = canvas.width*0.5;
    const centerY = canvas.height-margin;

    const angleGap = 0.025*Math.PI;

    let initialAngle = Math.PI;
    let finalAngle = initialAngle + (0.8 / maxZoneValue)*Math.PI;
    context.lineJoin = "round";
    context.lineWidth = radius*0.03;

    context.strokeStyle = '#fdcd01';
    context.fillStyle = '#fdcd01';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = initialAngle + ((1.3 - 0.8) / maxZoneValue)*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#029642';
    context.fillStyle = '#029642';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = initialAngle + ((1.5 - 1.3) / maxZoneValue)*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#e82310';
    context.fillStyle = '#e82310';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = 2*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#bc0100';
    context.fillStyle = '#bc0100';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    const baseWidth = radius*0.08;
    const tipWidth = radius*0.02;
    const indicatorLength = radius;

    let zoneAngle = (zoneIndex/maxZoneValue) * Math.PI;

    context.strokeStyle = '#3a3839';
    context.fillStyle = '#3a3839';
    context.lineWidth = 2;

    context.beginPath();
    context.moveTo(centerX - Math.sin(zoneAngle)*baseWidth*0.5, centerY + Math.cos(zoneAngle)*baseWidth*0.5);
    context.lineTo(centerX - Math.cos(zoneAngle)*indicatorLength - Math.sin(zoneAngle)*tipWidth*0.5, centerY - Math.sin(zoneAngle)*indicatorLength + Math.cos(zoneAngle)*tipWidth*0.5);
    context.arc(centerX - Math.cos(zoneAngle)*indicatorLength, centerY - Math.sin(zoneAngle)*indicatorLength, tipWidth*0.5, -1.5*Math.PI+zoneAngle, -2.5*Math.PI + zoneAngle);
    context.lineTo(centerX + Math.sin(zoneAngle)*baseWidth*0.5, centerY - Math.cos(zoneAngle)*baseWidth*0.5);
    context.arc(centerX, centerY, baseWidth*0.5, -0.5*Math.PI+zoneAngle, -1.5*Math.PI + zoneAngle);
    context.fill();
    context.stroke();

    const initialTextPoistion = [centerX - Math.cos(zoneAngle)*(indicatorLength + textMargin), centerY - Math.sin(zoneAngle)*(indicatorLength + textMargin)];
    let textOffset = textSidePadding*0.5;

    if(zoneIndex < maxZoneValue*0.5) {
      initialTextPoistion[0] -= (zoneTextMetric.width + textSidePadding);
    }

    context.globalAlpha = this.textOpacity;

    context.lineJoin = "round";
    context.lineWidth = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 7 : 4;
    context.strokeStyle = '#3a3839';
    context.fillStyle = '#3a3839';

    context.fillRect(initialTextPoistion[0], initialTextPoistion[1] - (textSize + context.lineWidth*0.5), zoneTextMetric.width + textSidePadding, textSize);
    context.strokeRect(initialTextPoistion[0], initialTextPoistion[1] - (textSize + context.lineWidth*0.5), zoneTextMetric.width + textSidePadding, textSize);

    context.fillStyle = 'white';
    context.fillText(zoneIndexText, initialTextPoistion[0] + textOffset, initialTextPoistion[1]);

    if(this.animating) {
      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  render() {
    return (
      <canvas ref={this.canvasRef} />
    );
  }
}

export default ZoneGauge;
