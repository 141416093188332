import React from 'react';
import { Link } from 'react-router-dom';
import './cost_center_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class CostCenterData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.cost_center.id && this.props.cost_center.id > 0;
  }

  getNodeOptions(node) {
    const options = [];

    if(!this.isEditMode() || node.id !== this.props.cost_center.id) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));

      node.children.sort((a, b) => a.name.localeCompare(b.name));

      for(let child of node.children) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getParentOptions() {
    const roots = this.props.costCenters.filter((entry) => entry.depth === 0);

    roots.sort((a, b) => a.name.localeCompare(b.name));

    return [
      SelectOption('', '-'),
      ...roots.flatMap((node) => this.getNodeOptions(node))
    ];
  }

  render() {
    return (
      <DefaultSection
        className="cost-center-data"
        title="Dados do centro de custo"
      >

        <div className="cost-center-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="cost-center-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do centro de custo"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.cost_center.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="parent_id"
              isHighlighted={this.isHighlighted("parent_id")}
              label="Pertence a"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.cost_center.parent_id || ''}
              options={this.getParentOptions()}
            />

          </HalfWrapper>

          <DefaultInput
            className="cost-center-data__color-input"
            name="color"
            isHighlighted={this.isHighlighted("color")}
            label="Cor:"
            type="color"
            isHorizontal={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.cost_center.color}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do centro de custo"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.cost_center.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="cost-center-data__buttons-container">

          <button
            className="cost-center-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="cost-center-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default CostCenterData;
