import React from 'react';
import { Link } from 'react-router-dom';
import './food_prescription_list.scss';
import ContentFrame from '../../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection from '../../../utils/default_section';
import {getModels, deleteModel, postModel} from '../../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, FOOD_PRESCRIPTION_OBJECTIVES} from '../../../constants';
import * as permissions from '../../../permissions';

class FoodPrescriptionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: true,
      isActiveFilter: "true",
      objectiveFilter: "",
      genderFilter: "",
      minWeightFilter: "",
      maxWeightFilter: "",
      food_prescriptions: [],
      deleteId: null,
      entryIdToDuplicate: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getFoodPrescriptions() {
    return await getModels(routes.FOOD_PRESCRIPTIONS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    let food_prescriptions = this.getFoodPrescriptions();

    food_prescriptions = await food_prescriptions;

    this.setState({
      loadingData: false,
      food_prescriptions: food_prescriptions,
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      entryIdToDuplicate: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDuplicateEntry(entryId) {
    this.setState({
      entryIdToDuplicate: entryId,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      entryIdToDuplicate: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteId != null) {
      try{
        if(await deleteModel(`${routes.FOOD_PRESCRIPTION_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   const descriptions = [];
              //
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'financial_entries':
              //         descriptions.push('Categoria vinculada à uma entrada financeira');
              //
              //         break;
              //       case 'children':
              //         descriptions.push('Categoria agrupa outras categorias.');
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
              //                      `desfeitos antes de deletar a categoria financeira`;
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.entryIdToDuplicate != null) {
      try{
        if(await postModel(`${routes.FOOD_PRESCRIPTION_DUPLICATE_POST_API}${this.state.entryIdToDuplicate}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   const descriptions = [];
              //
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'financial_entries':
              //         descriptions.push('Categoria vinculada à uma entrada financeira');
              //
              //         break;
              //       case 'children':
              //         descriptions.push('Categoria agrupa outras categorias.');
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
              //                      `desfeitos antes de deletar a categoria financeira`;
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      entryIdToDuplicate: null,
      confirmInProgress: false,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_PRESCRIPTION_PERMISSION) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.FOOD_PRESCRIPTION_EDIT_PATH}${entry.id}`}
          >

          {(this.props.userPermissionIds.includes(permissions.EDIT_FOOD_PRESCRIPTION_PERMISSION)) ?
            <i className="fas fa-edit"></i>:
            <i className="fas fa-eye"></i>
          }

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION) &&
          <button
            className="model-table__default-link-button"
            onClick={() => this.onDuplicateEntry(entry.id)}
          >

            <i className="fas fa-clone"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_FOOD_PRESCRIPTION_PERMISSION) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`food-prescription-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Dieta', <i className="fas fa-clipboard-list"></i>),
      Property('main_objective', 'Objetivo', <i className="fas fa-bullseye"></i>),
      Property('target_gender', 'Gênero', <i className="fas fa-venus-mars"></i>),
      Property('target_weight', 'Peso (kg)', <i className="fas fa-weight"></i>),
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando dieta';
      }
      else if(this.state.entryIdToDuplicate !== null) {
        return 'Duplicando dieta';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar dieta';
      }
      else if(this.state.entryIdToDuplicate !== null) {
        return 'Falha ao duplicar dieta';
      }

      return 'Unknown';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar dieta';
    }
    else if(this.state.entryIdToDuplicate !== null) {
      return 'Duplicar dieta';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'Todos os dados relacionados à dieta serão removidos';
    }
    else if(this.state.entryIdToDuplicate !== null) {
      return 'Uma cópia da dieta selecionada será criada';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Deletar';
    }
    else if(this.state.entryIdToDuplicate !== null) {
      return 'Duplicar';
    }

    return 'Unknown';
  }

  getFoodPrescriptionData() {
    let food_prescriptions = this.state.food_prescriptions;

    if(this.state.isActiveFilter.length > 0) {
      const isActiveFilter = this.state.isActiveFilter === 'true';
      food_prescriptions = food_prescriptions.filter((entry) => entry.is_active === isActiveFilter);
    }

    if(this.state.objectiveFilter.length > 0) {
      food_prescriptions = food_prescriptions.filter((entry) => entry.main_objective !== null && entry.main_objective.toLocaleLowerCase().includes(this.state.objectiveFilter.toLocaleLowerCase()));
    }

    if(this.state.genderFilter.length > 0) {
      food_prescriptions = food_prescriptions.filter((entry) => {
        if (entry.target_gender === null || entry.target_gender.length <= 0) {
          return true;
        }

        return entry.target_gender !== null && entry.target_gender.toLocaleLowerCase().includes(this.state.genderFilter.toLocaleLowerCase())
      });
    }

    if(this.state.minWeightFilter.length > 0) {
      food_prescriptions = food_prescriptions.filter((entry) => entry.target_weight !== null && entry.target_weight >= parseFloat(this.state.minWeightFilter));
    }

    if(this.state.maxWeightFilter.length > 0) {
      food_prescriptions = food_prescriptions.filter((entry) => entry.target_weight !== null && entry.target_weight <= parseFloat(this.state.maxWeightFilter));
    }

    return food_prescriptions;
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  getPrescriptionObjectiveOptions() {
    const objectives = [...FOOD_PRESCRIPTION_OBJECTIVES];

    return [
      SelectOption('', 'Todos'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.entryIdToDuplicate !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FOOD_PRESCRIPTION_LIST_PATH,
              text: "Listar dietas"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar dietas"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="food-prescription-list"
            title="Lista de dietas alimentares"
          >

            <div className="food-prescription-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={routes.FOOD_PRESCRIPTION_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova dieta

                </Link>
              }

            </div>

            <div className="food-prescription-list__filters">

              <header className="food-prescription-list__filters__header">

                <h4 className="food-prescription-list__filters__header__text">Filtros</h4>

              </header>

              <div className="food-prescription-list__filters__inputs">

                <div className="food-prescription-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveFilter"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveFilter || ''}
                    options={this.getActiveOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="objectiveFilter"
                      label="Objetivo principal"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.objectiveFilter || ''}
                      options={this.getPrescriptionObjectiveOptions()}
                    />

                    <DefaultInput
                      name="genderFilter"
                      label="Gênero alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.genderFilter || ''}
                      options={[
                        SelectOption('', 'Todos'),
                        SelectOption('Feminino', 'Feminino'),
                        SelectOption('Masculino', 'Masculino'),
                      ]}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="minWeightFilter"
                      label="Peso mínimo"
                      type="number"
                      placeholder="-"
                      step="0.1"
                      min="0.0"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.minWeightFilter || ''}
                      autoComplete="off"
                      suffix="kg"
                      onFocus={(event) => event.target.select()}
                    />

                    <DefaultInput
                      name="maxWeightFilter"
                      label="Peso máximo"
                      type="number"
                      placeholder="-"
                      step="0.1"
                      min="0.0"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.maxWeightFilter || ''}
                      autoComplete="off"
                      suffix="kg"
                      onFocus={(event) => event.target.select()}
                    />

                  </HalfWrapper>

                </div>

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.getFoodPrescriptionData()}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FoodPrescriptionList;
