import React from 'react';
import { Link } from 'react-router-dom';
import './meal_classification_data.scss';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput from '../../../utils/default_input';
import WarningMessage from '../../warning_message';

class MealClassificationData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.meal_classification.id && this.props.meal_classification.id > 0;
  }

  render() {
    return (
      <DefaultSection
        className="meal-classification-data"
        title="Dados da classificação de refeição"
      >

        <div className="meal-classification-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="meal-classification-data__input-container">

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome da classificação"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.meal_classification.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da classificação"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.meal_classification.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="meal-classification-data__buttons-container">

          <button
            className="meal-classification-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="meal-classification-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default MealClassificationData;
