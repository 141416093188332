import React from 'react';
import { Link } from 'react-router-dom';
import './service_group_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class ServiceGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      service_groups: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getServiceGroups() {
    return await getModels(routes.SERVICE_GROUPS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const service_groups = await this.getServiceGroups();

    this.setState({
      service_groups,
      loadingData: false
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.SERVICE_GROUP_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'services':
                    errorDescription = 'Agrupamento vinculado à um serviço/plano. Estes vínculos devem ser removidos antes de excluir este agrupamento.';

                    break;
                  default:
                }
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_SERVICE_PLAN_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_SERVICE_PLAN_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.SERVICE_GROUP_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_SERVICE_PLAN_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  getIsActiveText(entry) {
    const activeText = entry.is_active ? 'Ativo' : 'Inativo';

    return (
      <p className={`service-group-list__is-active-text__${activeText.toLowerCase()}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [];

    if(this.state.screenWidth > 430) {
      properties.push(
        Property('order', 'Prioridade', <i className="fas fa-list-ol"></i>)
      );
    }

    properties.push(
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {getDataText: (entry) => (<p style={{color: entry.color}}>{entry.name}</p>)})
    );

    if(this.state.screenWidth > 700) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "service-group-list__description-cell"})
      );
    }

    properties.push(
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    );

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando agrupamento';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao agrupamento';
    }

    return 'Deletar agrupamento';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao agrupamento serão removidos'}
          confirmText="Deletar agrupamento"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.SERVICE_GROUP_LIST_PATH,
              text: "Listar agrupamentos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar grupos de serviços"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="service-group-list"
            title="Lista de grupos de serviços"
          >

            <div className="service-group-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_SERVICE_PLAN_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.SERVICE_GROUP_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo grupo

                </Link>
              }

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.service_groups}
              initialOrderBy="order"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ServiceGroupList;
