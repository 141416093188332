import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import ModelTable, {Property} from '../../../utils/model_table';
import {getModels,
        postModel,
        getAsLocalDatetime,
        getLocalTimeIsoString,
        getAbbreviatedName} from '../../../utils/functions';
import * as routes from '../../../constants';
import * as paths from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        SUPPORT_STATUS_OPEN_ID,
        SUPPORT_STATUS_CLOSED_ID,
        SUPPORT_STATUS_AWAITING_RESPONSE_ID,
        SUPPORT_CATEGORY_FP_DIET_ID,
        SUPPORT_CATEGORY_FP_GENERAL_ID,
        SUPPORT_CATEGORY_PT_PERIOD_ID,
        SUPPORT_CATEGORY_PT_GENERAL_ID} from '../../../constants';
import ConfirmationWindow from '../../confirmation_window';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';
import DefaultMenuButton from '../../../components/default_menu_button';
import OverlayWindow from '../../../components/overlay_window';
import DefaultInput from '../../../utils/default_input';
import './support_ticket_list.scss';

import chatArrowLeft from '../../../images/chat_arrow_left.svg';
import chatArrowRight from '../../../images/chat_arrow_right.svg';


class SupportTicketList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      support_tickets: [],
      selectedTicket: null,
      messageToSend: '',
      onSendMessage: false,
      onCloseTicket: false,
      loadingData: true,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getSupportTickets() {
    return await getModels(`${routes.SUPPORT_TICKETS_GET_API}?student_id=${this.props.student.id}`);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const support_tickets = await this.getSupportTickets();
    support_tickets.forEach((ticket) => ticket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b)));

    let queryParameters = (new URLSearchParams(this.props.location.search));

    let support_ticket_id = queryParameters.get('support_ticket_id') || null;
    let selectedTicket = null;

    if (support_ticket_id) {
      support_ticket_id = parseInt(support_ticket_id);
      selectedTicket = support_tickets.find((ticket) => ticket.id === support_ticket_id) || null;
    }

    this.setState({
      loadingData: false,
      support_tickets,
      selectedTicket
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  onCancelConfirmation() {
    this.setState({
      onSendMessage: false,
      onCloseTicket: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.onCloseTicket) {
      try{
        const response = await postModel(routes.CLOSE_SUPPORT_TICKET_POST_API.replace('{support_ticket_id}', this.state.selectedTicket.id), {}, true);

        if(response) {
          const updatedTicket = {...this.state.selectedTicket, ...response.support_ticket};
          updatedTicket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b))

          const support_tickets = this.state.support_tickets.filter((ticket) => ticket.id !== this.state.selectedTicket.id);
          support_tickets.push(updatedTicket);

          this.setState({
            support_tickets,
            selectedTicket: null,
            messageToSend: '',
            onCloseTicket: false,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  getStatusText(entry) {
    let statusStyle = '';

    switch (entry.status_id) {
      case SUPPORT_STATUS_OPEN_ID:
        statusStyle = '--red';
        break;
      case SUPPORT_STATUS_CLOSED_ID:
        statusStyle = '--gray';
        break;
      case SUPPORT_STATUS_AWAITING_RESPONSE_ID:
        statusStyle = '--yellow';
        break;
      default:
        break;
    }

    return (
      <p className={`support-ticket-list__status-text${statusStyle}`}>{entry.status.name}</p>
    );
  }

  getSubjectStyle(entry) {
    let cellStyle = 'support-ticket-list__subject';

    switch (entry.category_id) {
      case SUPPORT_CATEGORY_FP_DIET_ID:
        cellStyle += '--green';
        break;
      case SUPPORT_CATEGORY_FP_GENERAL_ID:
        cellStyle += '--gray';
        break;
      case SUPPORT_CATEGORY_PT_PERIOD_ID:
        cellStyle += '--red';
        break;
      case SUPPORT_CATEGORY_PT_GENERAL_ID:
        cellStyle += '--red';
        break;
      default:
        break;
    }

    return cellStyle;
  }

  getLastMessageAtText(entry) {
    let lastMessageAt = entry.updated_at;

    entry.messages.forEach((message) => {
      if (lastMessageAt < message.sent_at) {
        lastMessageAt = message.sent_at;
      }
    });

    lastMessageAt = getAsLocalDatetime(lastMessageAt, false);

    return `${lastMessageAt.toLocaleDateString()} ${getLocalTimeIsoString(lastMessageAt, false)}`;
  }

  getTicketWeight(entry) {
    let weight;

    switch (entry.status_id) {
      case SUPPORT_STATUS_OPEN_ID:
        weight = 3;
        break;
      case SUPPORT_STATUS_CLOSED_ID:
        weight = 1;
        break;
      case SUPPORT_STATUS_AWAITING_RESPONSE_ID:
        weight = 2;
        break;
      default:
        weight = 10;
        break;
    }

    return weight;
  }

  sortTicketMessageCallback(a, b) {
    return a.sent_at.localeCompare(b.sent_at);
  }

  statusSortCallback(a, b) {
    const aWeight = this.getTicketWeight(a);
    const bWeight = this.getTicketWeight(b);

    if (aWeight === bWeight) {
      let aLastMessageAt = a.updated_at;
      let bLastMessageAt = b.updated_at;

      a.messages.forEach((message) => {
        if (aLastMessageAt < message.sent_at) {
          aLastMessageAt = message.sent_at;
        }
      });
      b.messages.forEach((message) => {
        if (bLastMessageAt < message.sent_at) {
          bLastMessageAt = message.sent_at;
        }
      });

      return aLastMessageAt.localeCompare(bLastMessageAt);
    }

    return aWeight - bWeight;
  }

  getProperties() {
    let properties = [
      Property('subject', 'Assunto', <i className="fa-solid fa-circle-info"></i>, {getCellClassName: this.getSubjectStyle}),
    ];

    properties.push(
      Property('last_message_at', 'Última entrada em', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getLastMessageAtText, getFilterText: this.getLastMessageAtText}),
    );

    properties = [
      ...properties,
      Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: this.getStatusText,
        getSortCallback: (a, b) => this.statusSortCallback(a, b),
        getFilterText: (entry) => entry.status.name}),
    ];

    return properties;
  }

  onOpenTicket(selectedTicket) {
    this.setState({selectedTicket});
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container support-ticket-list__action-container">

        <button
          className="model-table__default-edit-button"
          onClick={() => this.onOpenTicket(entry)}
        >

          <i className="fa-regular fa-eye support-ticket-list__action-icon"></i>
          Abrir

        </button>

      </div>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.onSendMessage) {
        return 'Enviando mensagem';
      }
      if(this.state.onCloseTicket) {
        return 'Finalizando atendimento';
      }
    }
    else if(this.state.confirmFailed) {
      if(this.state.onSendMessage) {
        return 'Falha ao enviar mensagem';
      }
      if(this.state.onCloseTicket) {
        return 'Falha ao finalizar atendimento';
      }
    }
    else if(this.state.onSendMessage) {
      return 'Enviar mensagem';
    }
    else if(this.state.onCloseTicket) {
      return 'Finalizar atendimento';
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onSendMessage) {
      return 'Aguarde enquanto enviamos sua mensagem. Obrigado!';
    }
    else if(this.state.onCloseTicket) {
      if (this.state.confirmInProgress) {
        return `Aguarde enquanto finalizamos o atendimento. Obrigado!`;
      }

      return `Ao finalizar o atendimento, considera-se que a dúvida levantada no ticket já foi sanada ou deixou de ser relevante e não necessita de mais interações.`;
    }

    return 'Não implementado';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.onSendMessage) {
      return 'Enviar';
    }
    if(this.state.onCloseTicket) {
      return 'Finalizar';
    }

    return 'Deletar contrato';
  }

  async sendMessageOrCloseTicket() {
    if(this.state.messageToSend.length > 0) {
      this.setState({
        onSendMessage: true,
        confirmInProgress: true
      });

      const data = {message: this.state.messageToSend};

      try{
        const response = await postModel(routes.SUPPORT_MESSAGE_POST_API.replace('{support_ticket_id}', this.state.selectedTicket.id), data, true);

        if(response) {
          const updatedTicket = {...this.state.selectedTicket, ...response.support_ticket};
          updatedTicket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b))

          const support_tickets = this.state.support_tickets.filter((ticket) => ticket.id !== this.state.selectedTicket.id);
          support_tickets.push(updatedTicket);

          this.setState({
            support_tickets,
            selectedTicket: updatedTicket,
            messageToSend: '',
            onSendMessage: false,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        console.log(errors);
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      this.setState({
        onCloseTicket: true
      });
    }

    return;
  }

  getOverlayActionContent() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    let buttonText = null;
    let buttonColor = 'green';
    let buttonStyle = '';

    if (this.state.messageToSend.length > 0) {
      if (this.state.onSendMessage) {
        buttonText = 'Enviando';
      }
      else {
        buttonText = 'Enviar';
        buttonStyle = '--send';
      }
    }
    else if (this.state.selectedTicket.status_id !== SUPPORT_STATUS_CLOSED_ID) {
      if (this.state.onCloseTicket) {
        buttonText = 'Finalizando';
      }
      else {
        buttonText = 'Finalizar atendimento';
      }

      buttonColor = 'blue';
    }

    return (
      <div className="support-ticket-list__send-message">

        <DefaultInput
          className="support-ticket-list__send-message__input"
          name="messageToSend"
          type="textarea"
          placeholder="Digite aqui sua mensagem"
          isHorizontal={true}
          rows="4"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={this.state.messageToSend || ''}
          disabled={this.state.onSendMessage || this.state.onCloseTicket}
        />

        <div className="support-ticket-list__send-message__controls">

          {buttonText !== null &&
            <DefaultMenuButton
              className={`support-ticket-list__send-message__button${buttonStyle}`}
              onClick={() => this.sendMessageOrCloseTicket()}
              text={buttonText}
              color={buttonColor}
              disabled={this.state.onSendMessage || this.state.onCloseTicket}
            />
          }

        </div>

      </div>
    );
  }

  getOverlayTitle() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    return (
      <React.Fragment>

        <span className="support-ticket-list__overlay__header__title--label">Assunto:</span>
        {this.state.selectedTicket.subject}

      </React.Fragment>
    );
  }

  getOverlayContent() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    const messages = this.state.selectedTicket.messages.map((message) => {
      const sentAt = getAsLocalDatetime(message.sent_at, false);
      const sentByStaff = message.staff !== null;
      const sentText = `Enviado em ${sentAt.toLocaleDateString()} ${getLocalTimeIsoString(sentAt, false)}`;
      let sentElement;

      if (sentByStaff) {
        sentElement = (
          <React.Fragment>
            {getAbbreviatedName(message.staff.name)}<br/>{sentText}
          </React.Fragment>
        );
      }
      else {
        sentElement = sentText;
      }

      return (
        <div
          key={`support_message:${message.id}`}
          className={`support-ticket-list__message-wrapper${sentByStaff ? '--right' : '--left'}`}
        >

          <div className="support-ticket-list__message">

            <div className="support-ticket-list__message__arrow">
              <img
                className="support-ticket-list__message__arrow__image"
                src={sentByStaff ? chatArrowRight : chatArrowLeft}
                alt=''
              />
            </div>

            <div className="support-ticket-list__message__balloon">

              <p className="support-ticket-list__message__sent-text">{sentElement}</p>

              <p className="support-ticket-list__message__text">{message.message}</p>

            </div>

          </div>

        </div>
      );
    });

    return (
      <div className="support-ticket-list__messages-container">

        {messages}

      </div>
    );
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <OverlayWindow
          className="support-ticket-list__overlay"
          visible={this.state.selectedTicket !== null}
          actions={(
            <div className="support-ticket-list__overlay__action-container">

              {this.getOverlayActionContent()}

            </div>
          )}
        >

          <header className="support-ticket-list__overlay__header">

            <h3 className="support-ticket-list__overlay__header__title">
              {this.getOverlayTitle()}
            </h3>

            {(!this.state.onSendMessage && !this.state.onCloseTicket) &&
              <DefaultMenuButton
                className="support-ticket-list__overlay__header__close-button"
                onClick={() => this.setState({selectedTicket: null})}
                text="X"
                color="red"
              />
            }

          </header>

          <div
            className="support-ticket-list__overlay__content-container"
            ref={(element) => {
              if (element !== null) {
                  element.scrollTo(0, element.scrollHeight);
                }
            }}
          >

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.onSendMessage || this.state.onCloseTicket}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ModelTable
          properties={this.getProperties()}
          data={this.state.support_tickets}
          initialOrderBy="status"
          getActions={(entry) => this.getActions(entry)}
          initialOrderIsDecrescent={true}
        >

          <Link
            className="model-table__default-button"
            to={{
              pathname: `${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.ADD_SUPPORT_TICKET_PATH}`,
              state: { lastPath: this.props.location.pathname }
            }}
          >

            <i className="fas fa-plus"></i> Iniciar nova conversa

          </Link>

        </ModelTable>

      </React.Fragment>

    );
  }
}

export default SupportTicketList;
