import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import {getModel, getModels, postModel, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './access_control_device_list.scss';

class AccessControlDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      access_control_devices: [],
      deviceToConfigure: null,
      deviceConfigurationSuccessful: false,
      overlayIsLoading: false,
      deleteId: null,
      deviceIdToOpenDoor: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getAccessControlDevices() {
    return await getModels(routes.ACCESS_CONTROL_DEVICES_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const access_control_devices = await this.getAccessControlDevices();

    this.setState({
      access_control_devices,
      loadingData: false
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deviceIdToOpenDoor: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onOpenDeviceDoor(entryId) {
    this.setState({
      deleteId: null,
      deviceIdToOpenDoor: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmationWindow() {
    const update = {
      deleteId: null,
      deviceIdToOpenDoor: null,
      confirmInProgress: false,
      confirmFailed: false,
      deviceConfigurationSuccessful: false
    };

    if (this.state.deviceConfigurationSuccessful) {
      update.deviceToConfigure = null;
    }

    this.setState(update);
  }

  async onConfirmConfirmationWindow() {
    this.setState({
      confirmInProgress: true
    });

    if (this.state.deleteId !== null) {
      try{
        if(await deleteModel(`${routes.ACCESS_CONTROL_DEVICE_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'classes':
              //         errorDescription = 'Controlador já possui dados de transações de pagamento. Por favor, ' +
              //                            'desabilite este cadastro para bloquear seu uso.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    if (this.state.deviceIdToOpenDoor !== null) {
      try{
        if(await postModel(routes.ACCESS_CONTROL_DEVICE_OPEN_DOOR_POST_API.replace('{id}', this.state.deviceIdToOpenDoor))) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'classes':
              //         errorDescription = 'Controlador já possui dados de transações de pagamento. Por favor, ' +
              //                            'desabilite este cadastro para bloquear seu uso.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      deviceIdToOpenDoor: null,
    });
  }

  async onConfigureDevice(deviceId) {
    this.setState({
      loadingData: true
    });

    try{
      const access_control_device = await getModel(routes.ACCESS_CONTROL_DEVICE_CONFIGURATION_GET_API.replace('{id}', deviceId));

      if (access_control_device) {
        this.setState({
          deviceToConfigure: access_control_device,
          loadingData: false
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 207:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'Timeout':
            //         errorDescription = 'Não foi possível se conectar com o dispositivo. Verifique se sua configuração de rede e de permissões ' +
            //                            'estão corretas antes de tentar novamente.';

            //         break;
            //       default:
            //     }
            //   }

            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false,
        loadingData: false
      });

      return;
    }
  }

  async onApplyDeviceConfiguration() {
    if (this.state.deviceToConfigure === null) {
      return null;
    }

    this.setState({
      overlayIsLoading: true
    });

    try{
      if(await postModel(routes.ACCESS_CONTROL_DEVICE_CONFIGURATION_POST_API.replace('{id}', this.state.deviceToConfigure.id))) {
        this.setState({
          deviceConfigurationSuccessful: true,
          overlayIsLoading: false
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 207:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Timeout':
                    errorDescription = 'Não foi possível se conectar com o dispositivo. Verifique se sua configuração de rede e de permissões ' +
                                       'estão corretas antes de tentar novamente.';

                    break;
                  default:
                }
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        overlayIsLoading: false
      });

      return false;
    }
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_ACCESS_CONTROL_DEVICE_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DELETE_ACCESS_CONTROL_DEVICE_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID) && entry.is_active) &&
          <button
            className="model-table__default-edit-button access-control-device-list__open-door-button"
            onClick={() => this.onOpenDeviceDoor(entry.id)}
          >

            <i className="fa-solid fa-door-open"></i>

          </button>
        }

        {(this.props.userPermissionIds.includes(permissions.MANAGE_ACCESS_CONTROL_DEVICE_CONFIGURATION_PERMISSION_ID) && entry.is_active) &&
          <button
            className="model-table__default-edit-button access-control-device-list__configure-button"
            onClick={() => this.onConfigureDevice(entry.id)}
          >

            <i className="fa-solid fa-gear"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.EDIT_ACCESS_CONTROL_DEVICE_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.ACCESS_CONTROL_DEVICE_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_ACCESS_CONTROL_DEVICE_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getStatusText(entry) {
    return (
      <div className="access-control-device-list__cell-wrapper--centered">

        <p className={`access-control-device-list__status-text${entry.is_active ? '--enabled' : '--disabled'}`}>

          {entry.is_active ?
            <i className="fas fa-check access-control-device-list__status-text__icon"></i>:
            <i className="fas fa-times access-control-device-list__status-text__icon"></i>}
          {entry.is_active ? 'Habilitado' : 'Desabilitado'}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: (entry) => entry.is_active ? 'Ativo' : 'Inativo',
      })
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if (this.state.deleteId !== null) {
        return 'Deletando';
      }
      else if (this.state.deviceIdToOpenDoor !== null) {
        return 'Liberando passagem';
      }

      return 'INDEFINIDO';
    }
    else if(this.state.confirmFailed) {
      if (this.state.deviceToConfigure !== null) {
        return 'Falha ao configurar';
      }
      else if (this.state.deleteId !== null) {
        return 'Falha ao deletar';
      }
      else if (this.state.deviceIdToOpenDoor !== null) {
        return 'Falha ao liberar passagem';
      }

      return 'INDEFINIDO';
    }

    if (this.state.deviceConfigurationSuccessful) {
      return 'Dispositivo configurado';
    }
    else if (this.state.deleteId !== null) {
      return 'Deletar dispositivo';
    }
    else if (this.state.deviceIdToOpenDoor !== null) {
      return 'Liberar passagem';
    }

    return 'INDEFINIDO';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if (this.state.deviceConfigurationSuccessful) {
      return 'A configuração do dispositivo foi realizada com sucesso.';
    }

    if (this.state.deleteId !== null) {
      return 'Todos os dados relacionados ao dispositivo serão removidos.';
    }
    if (this.state.deviceIdToOpenDoor !== null) {
      return 'Um comando será envido ao dispositivo para liberar a passagem';
    }

    return 'INDEFINIDO';
  }

  getConfirmationWindowConfirmText() {
    if (this.state.deleteId !== null) {
      return 'Deletar dispositivo';
    }
    if (this.state.deviceIdToOpenDoor !== null) {
      return 'Liberar';
    }

    return 'INDEFINIDO';
  }

  getOverlayActions() {
    if (this.state.deviceToConfigure === null) {
      return null;
    }

    return (
      <React.Fragment>

        {!this.state.overlayIsLoading &&
          <DefaultMenuButton
            className="access-control-device-list__overlay__action-button"
            onClick={() => this.setState({deviceToConfigure: null})}
            text="Cancelar"
          />
        }

        <DefaultMenuButton
          className="access-control-device-list__overlay__action-button"
          onClick={() => this.onApplyDeviceConfiguration()}
          color="purple"
          text={this.state.overlayIsLoading ? 'Configurando...' : 'Iniciar configuração'}
          disabled={this.state.overlayIsLoading}
        />

      </React.Fragment>
    );
  }

  getOverlayHeader() {
    if (this.state.deviceToConfigure === null) {
      return null;
    }

    return 'Configurar dispositivo';
  }

  getOverlayContent() {
    if (this.state.deviceToConfigure === null) {
      return null;
    }

    return (
      <React.Fragment>

        <p className='access-control-device-list__overlay__message'>

          As informações abaixo são sigilosas e não devem ser compartilhadas. Antes de iniciar o processo de configuração, certifique-se de que o
          dispositivo possa ser acessado pelo IP abaixo e de que a senha administrativa esteja devidamente configurada no controlador.

        </p>

        <HalfWrapper>

          <DefaultInput
            name="name"
            label="Nome do dispositivo"
            type="text"
            placeholder="Nome"
            maxLength="128"
            // handleInputChange={this.props.handleInputChange}
            value={this.state.deviceToConfigure.name}
            autoComplete="off"
            disabled={true}
          />

          <DefaultInput
            name="address_ipv4"
            label="Endereço IP"
            type="text"
            placeholder="IPv4"
            maxLength="128"
            // handleInputChange={this.props.handleInputChange}
            value={this.state.deviceToConfigure.address_ipv4}
            autoComplete="off"
            disabled={true}
          />

        </HalfWrapper>

        <DefaultInput
          name="api_key"
          label="Senha administrativa"
          labelMessage="Mantenha esta senha em segredo!"
          type="text"
          maxLength="128"
          // handleInputChange={this.props.handleInputChange}
          value={this.state.deviceToConfigure.api_key}
          autoComplete="off"
          disabled={true}
        />

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="access-control-device-list__overlay"
          visible={this.state.deviceToConfigure !== null}
          loading={this.state.overlayIsLoading}
          actions={(
            <div className="access-control-device-list__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="access-control-device-list__overlay__header">

            <h3 className="access-control-device-list__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="access-control-device-list__horizontal-rule" />

          <div className="access-control-device-list__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={(this.state.confirmFailed || this.state.deviceConfigurationSuccessful) ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.deviceIdToOpenDoor !== null || this.state.confirmFailed || this.state.deviceConfigurationSuccessful}
          onCancel={() => this.onCancelConfirmationWindow()}
          onConfirm={() => this.onConfirmConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          useSuccessIcon={this.state.deviceConfigurationSuccessful}
          hideConfirmButton={this.state.confirmFailed || this.state.deviceConfigurationSuccessful}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.ACCESS_CONTROL_DEVICE_LIST_PATH,
              text: "Listar controladores de acesso"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar controladores de acesso"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="access-control-device-list"
            title="Lista de dipositivos"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.access_control_devices}
              initialOrderBy="name"
              initialOrderIsDecrescent={true}
            >

              {this.props.userPermissionIds.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.ACCESS_CONTROL_DEVICE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar dispositivo

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default AccessControlDeviceList;
