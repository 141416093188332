import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import ModelTable, {Property} from '../../utils/model_table';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption, FiledSet} from '../../utils/default_input';
import {deleteModel, postModel, patchModel, getModel, getModels, getAsLocalDate, setUrlParameters, getDefaultLocalDateStringFromIsoString, getAsLocalDatetime, getLocalTimeIsoString} from '../../utils/functions';
import * as routes from '../../constants';
import * as paths from './constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        STUDENT_ACTIVE_STATUS,
        SERVICE_PERSONAL_SERVICE,
        TRAINING_PERIOD_OBJECTIVES,
        TRAINING_PERIOD_PHYSICAL_LEVELS,
        SERVICE_FUNCTIONAL_SERVICE,
        SERVICE_PILATES_SERVICE,
        SERVICE_BIKE_SERVICE,
        SERVICE_GYM_SERVICE,
        SERVICE_CARDIO,
        SERVICE_CORE,
        SERVICE_BOOTCAMP,
        TRAINING_CALENDAR_COLOR_MAP,
        P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS,
        DEFAULT_UNIT_TYPE} from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import HorizontalBarGraph, {BarDataPoint} from '../../graphs/horizontal_bar_graph';
import StackedBarGraph, {StackGroup, StackPoint} from '../../graphs/stacked_bar_graph';
import * as permissions from '../../permissions';
import './personal_training.scss';

function roundValue(value, decimals) {
  const multiplier = Math.pow(10, decimals);

  return Math.round(value * multiplier) / multiplier;
}

class PersonalTraining extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let targetServiceInput = queryParameters.get('target_service');
    let mainObjectiveInput = queryParameters.get('main_objective');
    let physicalLevelInput = queryParameters.get('suggested_physical_level');
    let discProfileInput = queryParameters.get('suggested_disc_profile');
    let codeInput = queryParameters.get('code');
    let targetGenderInput = queryParameters.get('target_gender');
    let suggestedPeriodsOnlyInput = queryParameters.get('suggested_periods_only');

    if(!targetServiceInput) {
      targetServiceInput = '';
    }
    if(!mainObjectiveInput) {
      mainObjectiveInput = '';
    }
    if(!physicalLevelInput) {
      physicalLevelInput = '';
    }
    if(!discProfileInput) {
      discProfileInput = '';
    }
    if(!codeInput) {
      codeInput = '';
    }
    if(!targetGenderInput) {
      targetGenderInput = '';
    }
    if(suggestedPeriodsOnlyInput && suggestedPeriodsOnlyInput === 'true') {
      suggestedPeriodsOnlyInput = true;
    }
    else {
      suggestedPeriodsOnlyInput = false;
    }

    this.state = {
      reloadingAvailableTrainingPeriods: false,
      loadingData: true,
      studentWeight: null,
      studentStatus: null,
      activePeriod: null,
      student_schedule_configuration: null,
      original_student_schedule_configuration: null,
      persontal_training_history: [],
      initialTrainingDate: null,
      initialTrainingDateInput: null,
      finalTrainingDate: null,
      finalTrainingDateInput: null,
      boundToServicesInput: false,
      boundToServices: false,
      targetServiceInput: targetServiceInput,
      targetServiceFilter: targetServiceInput,
      mainObjectiveInput: mainObjectiveInput,
      mainObjectiveFilter: mainObjectiveInput,
      physicalLevelInput: physicalLevelInput,
      physicalLevelFilter: physicalLevelInput,
      discProfileInput: discProfileInput,
      discProfileFilter: discProfileInput,
      codeInput: codeInput,
      codeFilter: codeInput,
      targetGenderInput: targetGenderInput,
      targetGenderFilter: targetGenderInput,
      suggestedPeriodsOnlyInput: suggestedPeriodsOnlyInput,
      suggestedPeriodsOnlyFilter: suggestedPeriodsOnlyInput,
      user_service_status: null,
      weekly_exercise_frequency: null,
      main_fyd_activities: null,
      other_main_activities: null,
      active_weekdays: null,
      services: [],
      trainingPeriods: [],
      personalTrainingData: [],
      originalPersonalTrainingData: [],
      physicalEvaluationData: [],
      serviceFilter: "",
      activatePeriodSectionVisible: false,
      responsibleSectionVisible: false,
      physicalActivitySectionVisible: false,
      personalTrainingHistorySectionVisible: false,
      profileSectionVisible: false,
      studentNoteVisible: false,
      idToActivate: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      deactivatePeriod: false,
      sendNotificationEmail: false,
      enableTracking: null,
      finishTrainingPeriodRevision: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      notificationEmailSent: false,
      onChangeScheduleConfigurationDay: null,
      scheduleConfigurationDayText: "",
      scheduleConfigurationDayColor: TRAINING_CALENDAR_COLOR_MAP['Descanso'],
      screenWidth: window.innerWidth,
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.todayWeekDay = today.getDay();
  }

  async getPersonalTraining() {
    return await getModel(routes.PERSONAL_TRAINING_PERIOD_GET_API.replace('{id}', this.props.student.id));
  }

  async getPersonalTrainingData() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION)) {
      return await getModels(routes.PERSONAL_TRAINING_DATA_GET_API.replace('{student_id}', this.props.student.id));
    }

    return [];
  }

  async getPhysicalEvaluationData() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return await getModels(routes.PHYSICAL_EVALUATION_DATA_GET_API.replace('{student_id}', this.props.student.id));
    }

    return [];
  }

  async reloadActivePeriod() {
    this.setState({
      loadingData: true
    });

    const personalTraining = await this.getPersonalTraining();

    const update = {loadingData: false};

    if(personalTraining) {
      if(personalTraining.training_period) {
        update.activePeriod = personalTraining.training_period;
        update.initialTrainingDate = personalTraining.initial_date;
        update.initialTrainingDateInput = personalTraining.initial_date;
        update.finalTrainingDate = personalTraining.final_date;
        update.finalTrainingDateInput = personalTraining.final_date;
        update.boundToServicesInput = personalTraining.bound_to_services;
        update.boundToServices = personalTraining.bound_to_services;
      }
      else {
        update.activePeriod = null;
        update.initialTrainingDate = null;
        update.initialTrainingDateInput = null;
        update.finalTrainingDate = null;
        update.finalTrainingDateInput = null;
        update.studentWeight = null;
        update.studentStatus = null;
      }

      if(personalTraining.student_weight) {
        update.studentWeight = personalTraining.student_weight;
      }

      if(personalTraining.student_status) {
        update.studentStatus = personalTraining.student_status;
      }

      if(personalTraining.persontal_training_history) {
        update.persontal_training_history = personalTraining.persontal_training_history;
      }

      if(personalTraining.user_service_status) {
        update.user_service_status = personalTraining.user_service_status;
      }

      if(Object.hasOwn(personalTraining, 'weekly_exercise_frequency')) {
        update.weekly_exercise_frequency = personalTraining.weekly_exercise_frequency;
      }

      if(personalTraining.main_fyd_activities) {
        update.main_fyd_activities = personalTraining.main_fyd_activities;
      }

      if(personalTraining.other_main_activities) {
        update.other_main_activities = personalTraining.other_main_activities;
      }

      if(personalTraining.active_weekdays) {
        update.active_weekdays = personalTraining.active_weekdays;
      }

      if(personalTraining.student_schedule_configuration) {
        update.original_student_schedule_configuration = personalTraining.student_schedule_configuration;
        update.student_schedule_configuration = personalTraining.student_schedule_configuration;
      }
      else {
        update.original_student_schedule_configuration = null;
        update.student_schedule_configuration = null;
      }
    }
    else {
      update.activePeriod = null;
      update.initialTrainingDate = null;
      update.initialTrainingDateInput = null;
      update.finalTrainingDate = null;
      update.finalTrainingDateInput = null;
      update.studentWeight = null;
      update.studentStatus = null;
      update.user_service_status = null;
      update.weekly_exercise_frequency = null;
      update.main_fyd_activities = null;
      update.other_main_activities = null;
      update.active_weekdays = null;
      update.original_student_schedule_configuration = null;
      update.student_schedule_configuration = null;
      update.persontal_training_history = [];
    }

    this.setState(update);
  }

  async reloadPersonalTrainingData() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let personalTrainingData = this.getPersonalTrainingData();

    personalTrainingData = await personalTrainingData;

    if(personalTrainingData) {
      update.personalTrainingData = personalTrainingData;
    }

    this.setState(update);
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getPhysicalEvaluationQuestionValue(question_id) {
    const question = this.state.originalPersonalTrainingData.find((entry) => entry.question_id === question_id);

    if(question) {
      return question.value;
    }
    else if(question_id === 10) {
      return 'Iniciante';
    }
    else {
      return '';
    }
  }

  async reloadAvailableTrainingPeriods() {
    if (!this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
      return;
    }

    this.setState({
      reloadingAvailableTrainingPeriods: true
    });

    const update = {reloadingAvailableTrainingPeriods: false};

    const parameters = {
      user_id: this.props.student.id,
      active_only: true
    };

    if(this.state.targetServiceFilter) {
      parameters.target_service = this.state.targetServiceFilter;
    }
    if(this.state.codeFilter) {
      parameters.code_filter = this.state.codeFilter;
    }

    if(this.state.suggestedPeriodsOnlyFilter) {
      parameters.main_objective = this.getPhysicalEvaluationQuestionValue(1);
      parameters.suggested_physical_level = this.getPhysicalEvaluationQuestionValue(10);
      parameters.target_gender = this.props.student.gender;

      const suggested_disc_profile = this.getDiscClassification(true);

      if (suggested_disc_profile.length > 1) {
        if(this.state.discProfileFilter) {
          parameters.suggested_disc_profile = this.state.discProfileFilter;
        }
      }
      else {
        parameters.suggested_disc_profile = suggested_disc_profile;
      }
    }
    else {
      if(this.state.mainObjectiveFilter) {
        parameters.main_objective = this.state.mainObjectiveFilter;
      }
      if(this.state.physicalLevelFilter) {
        parameters.suggested_physical_level = this.state.physicalLevelFilter;
      }
      if(this.state.discProfileFilter) {
        parameters.suggested_disc_profile = this.state.discProfileFilter;
      }
      if(this.state.targetGenderFilter) {
        parameters.target_gender = this.state.targetGenderFilter;
      }
    }

    let trainingPeriods = await getModels(setUrlParameters(routes.TRAINING_PERIODS_GET_API, parameters));

    trainingPeriods = await trainingPeriods;

    if(trainingPeriods) {
      update.trainingPeriods = trainingPeriods;
    }

    this.setState(update);
  }

  async reloadPhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let physicalEvaluationData = this.getPhysicalEvaluationData();

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPersonalTrainingData = physicalEvaluationData;
    }

    this.setState(update);
  }

  async componentDidMount() {
    let personalTraining = this.getPersonalTraining();
    let personalTrainingData = this.getPersonalTrainingData();
    let physicalEvaluationData = this.getPhysicalEvaluationData();
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    const update = {
      loadingData: false
    };

    personalTraining = await personalTraining;

    if(personalTraining) {
      if(personalTraining.training_period) {
        update.activePeriod = personalTraining.training_period;
        update.initialTrainingDate = personalTraining.initial_date;
        update.initialTrainingDateInput = personalTraining.initial_date;
        update.finalTrainingDate = personalTraining.final_date;
        update.finalTrainingDateInput = personalTraining.final_date;
        update.boundToServicesInput = personalTraining.bound_to_services;
        update.boundToServices = personalTraining.bound_to_services;
      }

      if(personalTraining.student_weight) {
        update.studentWeight = personalTraining.student_weight;
      }

      if(personalTraining.student_status) {
        update.studentStatus = personalTraining.student_status;
      }

      if(personalTraining.persontal_training_history) {
        update.persontal_training_history = personalTraining.persontal_training_history;
      }

      if(personalTraining.user_service_status) {
        update.user_service_status = personalTraining.user_service_status;
      }

      if(Object.hasOwn(personalTraining, 'weekly_exercise_frequency')) {
        update.weekly_exercise_frequency = personalTraining.weekly_exercise_frequency;
      }

      if(personalTraining.main_fyd_activities) {
        update.main_fyd_activities = personalTraining.main_fyd_activities;
      }

      if(personalTraining.other_main_activities) {
        update.other_main_activities = personalTraining.other_main_activities;
      }

      if(personalTraining.active_weekdays) {
        update.active_weekdays = personalTraining.active_weekdays;
      }

      if(personalTraining.student_schedule_configuration) {
        update.original_student_schedule_configuration = personalTraining.student_schedule_configuration;
        update.student_schedule_configuration = personalTraining.student_schedule_configuration;
      }
      else {
        update.original_student_schedule_configuration = null;
        update.student_schedule_configuration = null;
      }
    }

    personalTrainingData = await personalTrainingData;

    if(personalTrainingData) {
      update.personalTrainingData = personalTrainingData;
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    physicalEvaluationData = await physicalEvaluationData;

    if(physicalEvaluationData) {
      let includeWeekFrequency = true;
      let includePhysicalLevel = true;

      for(const entry of physicalEvaluationData) {
        if(entry.question_id === 9) {
          includeWeekFrequency = false;
        }
        else if(entry.question_id === 10) {
          includePhysicalLevel = false;
        }
      }

      if(includeWeekFrequency) {
        physicalEvaluationData.push({
          question_id: 9,
          question_text: "Quantos dias por semana pretende se exercitar?",
          value: ""
        });
      }
      if(includePhysicalLevel) {
        physicalEvaluationData.push({
          question_id: 10,
          question_text: "Nível físico",
          value: "Iniciante"
        });
      }

      update.physicalEvaluationData = physicalEvaluationData;
      update.originalPersonalTrainingData = physicalEvaluationData;
    }
    else {
      physicalEvaluationData = [];
    }

    if (this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
      const parameters = {
        user_id: this.props.student.id,
        active_only: true
      };

      if(this.state.targetServiceFilter) {
        parameters.target_service = this.state.targetServiceFilter;
      }

      if(this.state.suggestedPeriodsOnlyFilter) {
        const main_objective = physicalEvaluationData.find((entry) => entry.question_id === 1);
        const suggested_physical_level = physicalEvaluationData.find((entry) => entry.question_id === 10);
        const suggested_disc_profile = this.getDiscClassification(true);
        const target_gender = this.props.student.gender;

        if(main_objective) {
          parameters.main_objective = main_objective.value;
        }
        else {
          parameters.main_objective = '';
        }
        if(suggested_physical_level) {
          parameters.suggested_physical_level = suggested_physical_level.value;
        }
        else {
          parameters.suggested_physical_level = '';
        }
        if(suggested_disc_profile) {
          if (suggested_disc_profile.length > 1) {
            if(this.state.discProfileFilter) {
              parameters.suggested_disc_profile = this.state.discProfileFilter;
            }
          }
          else {
            parameters.suggested_disc_profile = suggested_disc_profile;
          }
        }
        else {
          parameters.suggested_disc_profile = '';
        }
        if(target_gender) {
          parameters.target_gender = target_gender;
        }
        else {
          parameters.target_gender = '';
        }
      }
      else {
        if(this.state.mainObjectiveFilter) {
          parameters.main_objective = this.state.mainObjectiveFilter;
        }
        if(this.state.physicalLevelFilter) {
          parameters.suggested_physical_level = this.state.physicalLevelFilter;
        }
        if(this.state.discProfileFilter) {
          parameters.suggested_disc_profile = this.state.discProfileFilter;
        }
        if(this.state.codeFilter) {
          parameters.code_filter = this.state.codeFilter;
        }
        if(this.state.targetGenderFilter) {
          parameters.target_gender = this.state.targetGenderFilter;
        }
      }

      let trainingPeriods = getModels(setUrlParameters(routes.TRAINING_PERIODS_GET_API, parameters));

      trainingPeriods = await trainingPeriods;

      if(trainingPeriods) {
        update.trainingPeriods = trainingPeriods;
      }
      else {
        this.props.history.replace(routes.DESKTOP_PATH);
        return;
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.targetServiceFilter !== this.state.targetServiceFilter ||
        prevState.mainObjectiveFilter !== this.state.mainObjectiveFilter ||
        prevState.physicalLevelFilter !== this.state.physicalLevelFilter ||
        prevState.suggestedPeriodsOnlyFilter !== this.state.suggestedPeriodsOnlyFilter ||
        prevState.discProfileFilter !== this.state.discProfileFilter ||
        prevState.codeFilter !== this.state.codeFilter ||
        prevState.targetGenderFilter !== this.state.targetGenderFilter) {
      this.reloadAvailableTrainingPeriods();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  activatePeriod(period_id) {
    this.setState({
      idToActivate: period_id,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  deactivatePeriod() {
    this.setState({
      deactivatePeriod: true,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  onDeletePersonalTrainingData(entryId) {
    this.setState({
      personalEntryIdToDelete: entryId,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  onDeleteTrainingPeriodData(entryId) {
    this.setState({
      trainingPeriodIdToDelete: entryId,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  onDeletePersonalTrainingHistory(entryId) {
    this.setState({
      personalTrainingHistoryIdToDelete: entryId,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  onDuplicateTrainingPeriod(entryId) {
    this.setState({
      trainingPeriodIdToDuplicate: entryId,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
    });
  }

  onOverwriteScheduleConfiguration() {
    this.setState({
      onOverwriteScheduleConfiguration: true,
      onCancelScheduleConfigurationOverwrite: false,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onCancelScheduleConfigurationOverwrite() {
    this.setState({
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: true,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onRemoveScheduleConfigurationDay(weekIndex, dayIndex) {
    this.setState({
      onRemoveScheduleConfigurationDay: {weekIndex, dayIndex},
      onRemoveScheduleConfigurationWeek: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onRemoveScheduleConfigurationWeek(weekIndex) {
    this.setState({
      onRemoveScheduleConfigurationWeek: weekIndex,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  getAvailablePeriodProperties() {
    let properties = [];

    properties.push(
      Property('code', 'Código', <i className="fas fa-tag"></i>),
    );

    properties.push(
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getCellClassName: (entry) => entry.suggested_disc_profile !== null ? `personal-training__name-cell--${entry.suggested_disc_profile.toLocaleLowerCase()}` : 'personal-training__training-periods__name-cell'
      })
    );
    properties.push(Property('target_gender', 'Gênero', <i className="fas fa-venus-mars"></i>));
    properties.push(Property('main_objective', 'Objetivo', <i className="fas fa-bullseye"></i>));
    properties.push(Property('suggested_physical_level', 'Nível físico', <i className="fas fa-fist-raised"></i>));

    if(this.state.screenWidth > 710) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "personal-training__description-cell"}),
      );
    }

    properties.push(
      Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>),
    );

    return properties;
  }

  getPersonalTrainingHistoryProperties() {
    let properties = [];

    properties.push(
      Property('code', 'Código', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => entry.training_period.code,
        getFilterText: (entry) => entry.training_period.code,
        getSortCallback: (a, b) => {
          return a.training_period.code.localeCompare(b.training_period.code);
        }
      }),
    );

    properties.push(
      Property('training_period', 'Periodização', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => entry.training_period.name,
        getFilterText: (entry) => entry.training_period.name,
        getSortCallback: (a, b) => {
          return a.training_period.name.localeCompare(b.training_period.name);
        }
      })
    );
    properties.push(Property('start_date', 'Início', <i className="fas fa-calendar-day"></i>, {
      getDataText: (entry) => getDefaultLocalDateStringFromIsoString(entry.start_date),
      getFilterText: (entry) => getDefaultLocalDateStringFromIsoString(entry.start_date)
    }));
    properties.push(Property('end_date', 'Fim', <i className="fas fa-calendar-day"></i>, {
      getDataText: (entry) => getDefaultLocalDateStringFromIsoString(entry.end_date),
      getFilterText: (entry) => getDefaultLocalDateStringFromIsoString(entry.end_date)
    }));

    return properties;
  }

  // getCapacityText(entry) {
  //   let text;
  //   let color;
  //
  //   if(entry.capacity_abbreviation) {
  //     text = entry.capacity_abbreviation;
  //     color = entry.capacity_color;
  //   }
  //   else {
  //     text = 'Não vinculado';
  //     color = '#dcdcdc';
  //   }
  //
  //
  //   return (
  //     <div className="personal-training__cell-wrapper--centered">
  //
  //       <p className={`personal-training__capacity-text${!entry.capacity_abbreviation ? '--gray' : ''}`} style={{background: color}}>
  //
  //         {text}
  //
  //       </p>
  //
  //     </div>
  //   );
  // }

  getDateText(entry) {
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'}).format(getAsLocalDate(entry.date));
  }

  getTrainingDayName(entry) {
    if (entry.is_custom_training) {
      if (entry.activity_reference_id === null) {
        return `${entry.training_day_name} (Home Training)`;
      }

      return `${entry.exercise} (complementar)`
    }

    return entry.training_day_name;
  }

  getPersonalTrainingDataProperties() {
    let properties = [
      Property('training_day_name', 'Treino', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => this.getTrainingDayName(entry),
        getFilterText: (entry) => this.getTrainingDayName(entry)
      }),
      // Property('capacity_abbreviation', 'Capacidade', <i className="fas fa-tag"></i>, {
      //   getDataText: (entry) => this.getCapacityText(entry),
      // }),
      Property('date', 'Data', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getDateText(entry),
        getFilterText: (entry) => this.getDateText(entry)
      }),
    ];

    return properties;
  }

  getAvailablePeriodActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="personal-training__activate-button"
          onClick={() => this.activatePeriod(entry.id)}
        >

          <i className="far fa-check-square personal-training__activate-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Ativar' : ''}

        </button>

        {entry.editable &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.TRAINING_PERIOD_EDIT_PATH}${entry.id}`}
          >

            {this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID) ?
              <i className="fas fa-edit"></i>:
              <i className="fas fa-eye"></i>
            }

          </Link>
        }

        {(entry.editable && entry.user_id !== null && this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_PERIOD_PERMISSION_ID)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteTrainingPeriodData(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) &&
          <button
            className="model-table__default-link-button"
            onClick={() => this.onDuplicateTrainingPeriod(entry.id)}
          >

            <i className="fas fa-clone"></i>

          </button>
        }

      </div>
    );
  }

  getPersonalTrainingHistoryActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {(this.props.userPermissionIds.includes(permissions.DELETE_PERSONAL_TRAINING_HISTORY_PERMISSION)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeletePersonalTrainingHistory(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getPersonalTrainingDataActions(entry) {
    if(entry.is_personal_training) {
      return (
        <div className="model-table__model-actions-container">

          <Link
            className="model-table__default-link-button"
            to={`${routes.PERSONAL_TRAINING_PERFORMANCE_GRAPH_PATH.replace('{student_id}', this.props.student.id.toString())}${entry.training_day_id}`}
          >

              <i className="fas fa-chart-bar"></i>

          </Link>

          <Link
            className="model-table__default-edit-button"
            to={`${routes.PERSONAL_TRAINING_EDIT_PATH}${entry.id}`}
          >

            {this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION) ?
              <i className="fas fa-edit"></i>:
              <i className="fas fa-eye"></i>
            }

          </Link>

          {this.props.userPermissionIds.includes(permissions.DELETE_PERSONAL_TRAINING_DATA_PERMISSION) &&
            <button
              className="model-table__default-delete-button"
              onClick={() => this.onDeletePersonalTrainingData(entry.id)}
            >

              <i className="far fa-trash-alt"></i>

            </button>
          }

        </div>
      );
    }

    return null;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.idToActivate !== null) {
        return 'Ativando periodização';
      }
      else if(this.state.onOverwriteScheduleConfiguration) {
        return 'Sobrescrevendo cronograma';
      }
      else if(this.state.onCancelScheduleConfigurationOverwrite) {
        return 'Restaurando cronograma';
      }
      else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
        return 'Removendo semana de cronograma';
      }
      else if(this.state.onRemoveScheduleConfigurationDay !== null) {
        return 'Removendo dia de cronograma';
      }
      else if(this.state.deactivatePeriod) {
        return 'Desativando periodização';
      }
      else if(this.state.personalEntryIdToDelete !== null) {
        return 'Deletando treino';
      }
      else if(this.state.trainingPeriodIdToDelete !== null) {
        return 'Deletando periodização';
      }
      else if(this.state.personalTrainingHistoryIdToDelete !== null) {
        return 'Deletando entrada do histórico';
      }
      else if(this.state.trainingPeriodIdToDuplicate !== null) {
        return 'Duplicando periodização';
      }
      else if(this.state.sendNotificationEmail) {
        return 'Enviando email';
      }
      else if(this.state.finishTrainingPeriodRevision) {
        return 'Finalizando revisão de periodização';
      }
      else if(this.state.enableTracking !== null) {
        if (this.state.enableTracking) {
          return 'Habilitando periodização'
        }

        return 'Desabilitando periodização';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.idToActivate !== null) {
        return 'Falha ao ativar periodização';
      }
      else if(this.state.onOverwriteScheduleConfiguration) {
        return 'Falha ao sobrescrever cronograma';
      }
      else if(this.state.onCancelScheduleConfigurationOverwrite) {
        return 'Falha ao restaurar cronograma';
      }
      else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
        return 'Falha ao remover semana de cronograma';
      }
      else if(this.state.onRemoveScheduleConfigurationDay !== null) {
        return 'Falha ao remover dia de cronograma';
      }
      else if(this.state.deactivatePeriod) {
        return 'Falha ao desativar periodização';
      }
      else if(this.state.personalEntryIdToDelete !== null) {
        return 'Falha ao deletar treino';
      }
      else if(this.state.trainingPeriodIdToDelete !== null) {
        return 'Falha ao deletar periodização';
      }
      else if(this.state.personalTrainingHistoryIdToDelete !== null) {
        return 'Falha ao deletar entrada de histórico';
      }
      else if(this.state.trainingPeriodIdToDuplicate !== null) {
        return 'Falha ao duplicar periodização';
      }
      else if(this.state.sendNotificationEmail) {
        return 'Falha ao enviar email';
      }
      else if(this.state.finishTrainingPeriodRevision) {
        return 'Falha ao finalizar revisão';
      }
      else if(this.state.enableTracking !== null) {
        return 'Falha ao habilitar/desabilitar periodização';
      }

      return 'Unknown fail';
    }

    if(this.state.idToActivate !== null) {
      return 'Ativar periodização';
    }
    else if(this.state.onOverwriteScheduleConfiguration) {
      return 'Sobrescrever cronograma';
    }
    else if(this.state.onCancelScheduleConfigurationOverwrite) {
      return 'Restaurar cronograma';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
      return 'Remover semana de cronograma';
    }
    else if(this.state.onRemoveScheduleConfigurationDay !== null) {
      return 'Remover dia de cronograma';
    }
    else if(this.state.deactivatePeriod) {
      return 'Desativar periodização';
    }
    else if(this.state.personalEntryIdToDelete !== null) {
      return 'Deletar treino';
    }
    else if(this.state.trainingPeriodIdToDelete !== null) {
      return 'Deletar periodização';
    }
    else if(this.state.personalTrainingHistoryIdToDelete !== null) {
      return 'Deletar entrada de histórico';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Duplicar periodização';
    }
    else if(this.state.sendNotificationEmail) {
      return 'Enviar email';
    }
    else if(this.state.finishTrainingPeriodRevision) {
      return 'Finalizar revisão de periodização';
    }
    else if(this.state.enableTracking !== null) {
      if (this.state.enableTracking) {
        return 'Habilitar periodização';
      }

      return 'Desabilitar periodização';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.idToActivate != null) {
      return 'A periodização selecionada será ativada para o aluno. Caso haja uma periodização já ativa ela será substituída.';
    }
    else if(this.state.onOverwriteScheduleConfiguration) {
      return 'Após sobresvrever o cronograma de treino você poderá editá-lo de acordo com as neceddidades do aluno.';
    }
    else if(this.state.onCancelScheduleConfigurationOverwrite) {
      return 'Restaurar cronograma original cadastrado para a periodização de treino ativa.';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek != null) {
      return 'A semana será removida do cronograma';
    }
    else if(this.state.onRemoveScheduleConfigurationDay != null) {
      return 'A entrada será removida do cronograma';
    }
    else if(this.state.deactivatePeriod) {
      return 'A periodização atual do aluno será desativada.';
    }
    else if(this.state.personalEntryIdToDelete !== null) {
      return 'O treino de personal selecionado será removido.';
    }
    else if(this.state.trainingPeriodIdToDelete !== null) {
      return 'A periodização cadastrada para o aluno será removida.';
    }
    else if(this.state.personalTrainingHistoryIdToDelete !== null) {
      return 'A entrada de histórico de periodização será apagada permanentemente.';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Uma cópia da periodização selecionada será criada para o aluno.';
    }
    else if(this.state.sendNotificationEmail) {
      return 'Um email será enviada ao aluno para notificar a ativação/alteração de periodização de personal training.';
    }
    else if(this.state.finishTrainingPeriodRevision) {
      return 'A revisão da periodização será marcada como finalizada.';
    }
    else if(this.state.enableTracking !== null) {
      return `O acompanhamento de periodização de personal do aluno será ${this.state.enableTracking ? 'habilitado' : 'desabilitado'}.`;
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.idToActivate != null) {
      return 'Ativar';
    }
    else if(this.state.onOverwriteScheduleConfiguration) {
      return 'Sobrescrever';
    }
    else if(this.state.onCancelScheduleConfigurationOverwrite) {
      return 'Restaurar';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek != null) {
      return 'Remover';
    }
    else if(this.state.onRemoveScheduleConfigurationDay != null) {
      return 'Remover';
    }
    else if(this.state.deactivatePeriod) {
      return 'Desativar';
    }
    else if(this.state.personalEntryIdToDelete !== null) {
      return 'Remover';
    }
    else if(this.state.trainingPeriodIdToDelete !== null) {
      return 'Remover';
    }
    else if(this.state.personalTrainingHistoryIdToDelete !== null) {
      return 'Deletar';
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {
      return 'Duplicar';
    }
    else if(this.state.sendNotificationEmail) {
      return 'Enviar';
    }
    else if(this.state.finishTrainingPeriodRevision) {
      return 'Finalizar';
    }
    else if(this.state.enableTracking !== null) {
      if (this.state.enableTracking) {
        return 'Habilitar';
      }

      return 'Desabilitar';
    }

    return 'Unknown';
  }

  onSendNotificationEmail() {
    this.setState({
      sendNotificationEmail: true,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onFinishTrainingPeriodRevision() {
    this.setState({
      finishTrainingPeriodRevision: true,
      sendNotificationEmail: false,
      idToActivate: null,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onChangeTrackingStatus(enableTracking) {
    this.setState({
      enableTracking: enableTracking,
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onCancelConfirmation() {
    this.setState({
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmFailed: false,
      confirmInProgress: false,
      onChangeScheduleConfigurationDay: null
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.idToActivate != null) {
      const data = {training_period_id: this.state.idToActivate};

      try{
        if(await postModel(routes.PERSONAL_TRAINING_PERIOD_POST_API.replace('{id}', this.props.student.id.toString()), data)) {
          this.reloadActivePeriod();
          this.reloadPersonalTrainingData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if(error.message.includes('Inactive student')) {
                  errorDescription = 'Aluno inativo.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deactivatePeriod) {

      try{
        if(await deleteModel(routes.PERSONAL_TRAINING_PERIOD_DELETE_API.replace('{id}', this.props.student.id.toString()))) {
          this.reloadActivePeriod();
          this.reloadPersonalTrainingData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if(error.message.includes('Inactive student')) {
                  errorDescription = 'Aluno inativo.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.personalEntryIdToDelete !== null) {

      try{
        if(await deleteModel(`${routes.PERSONAL_TRAINING_DATA_DELETE_API}${this.state.personalEntryIdToDelete}`)) {
          this.reloadPersonalTrainingData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 208:
                if(error.message.includes('Inactive student')) {
                  errorDescription = 'Aluno inativo.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.trainingPeriodIdToDelete !== null) {
      try{
        if(await deleteModel(`${routes.TRAINING_PERIOD_DELETE_API}${this.state.trainingPeriodIdToDelete}`)) {
          this.reloadAvailableTrainingPeriods();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'schedules':
                      descriptions.push('Periodização já foi ativada. Os cadastros de ativação ' +
                                        'devem ser deletados antes de deletar a periodização');

                      break;
                    case 'classes':
                    case 'personal_data_entries':
                    case 'training_data_entries':
                      descriptions.push('Periodização possui dados de alunos vinculados');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Nesta situação, recomenda-se que o cadastro da periodização seja simplesmente desativado.`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.personalTrainingHistoryIdToDelete !== null) {
      try{
        if(await deleteModel(`${routes.PERSONAL_TRAINING_HISTORY_DELETE_API}${this.state.personalTrainingHistoryIdToDelete}`)) {
          this.reloadActivePeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.trainingPeriodIdToDuplicate !== null) {

      try{
        if(await postModel(`${routes.PERSONAL_TRAINING_DUPLICATE_PERIOD_POST_API.replace('{student_id}', this.props.student.id.toString())}${this.state.trainingPeriodIdToDuplicate}`)) {
          this.reloadAvailableTrainingPeriods();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 102:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'intensity_value':
                      const errorValue = parameter.value.split(';');
                      errorDescription = `Periodização possui um erro de configuração. Verifique o treino ${errorValue[0]}, agrupamento "${errorValue[1]}" da periodização desejada`;

                      break;
                    default:
                  }
                }

                break;
              case 103:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'name':
                      errorDescription = 'Periodização já duplicada. Para duplicar novamente esta periodização, altere o nome dá última cópia feita.'

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.sendNotificationEmail) {
      try {
        if(await postModel(`${routes.NOTIFICATION_PERSONAL_TRAINING_ACTIVE_POST}`, {user_id: this.props.student.id})) {
          this.setState({
            notificationEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          notificationEmailSent: false
        });

        return;
      }
    }
    else if(this.state.finishTrainingPeriodRevision) {
      try {
        if(await postModel(routes.STUDENT_PERSONAL_TRAINING_SERVICE_FINISH_REVISION_POST_API.replace('{student_id}', this.props.student.id.toString()))) {
          this.reloadActivePeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          notificationEmailSent: false
        });

        return;
      }
    }
    else if(this.state.enableTracking !== null) {
      const data = {track_personal_training_service: this.state.enableTracking};

      try{
        if(await patchModel(routes.STUDENT_PERSONAL_TRAINING_SERVICE_TRACKING_PATCH_API.replace('{student_id}', this.props.student.id.toString()), data)) {
          this.reloadActivePeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
      const student_schedule_configuration = this.state.student_schedule_configuration.map((week) => {
        return week.map((entry) => {
          if (entry === null) {
            return null;
          }

          return {...entry};
        });
      });

      student_schedule_configuration.splice(this.state.onRemoveScheduleConfigurationWeek, 1);

      this.setState({
        student_schedule_configuration
      });
    }
    else if(this.state.onRemoveScheduleConfigurationDay !== null) {
      const student_schedule_configuration = this.state.student_schedule_configuration.map((week, weekIndex) => {
        const weekCopy = week.map((entry) => {
          if (entry === null) {
            return null;
          }

          return {...entry};
        });

        if (weekIndex === this.state.onRemoveScheduleConfigurationDay.weekIndex) {
          weekCopy[this.state.onRemoveScheduleConfigurationDay.dayIndex] = null;
        }

        return weekCopy;
      });

      this.setState({
        student_schedule_configuration
      });
    }
    else if(this.state.onOverwriteScheduleConfiguration) {
      const data = {schedule_configuration: this.state.activePeriod.schedule_configuration};

      try{
        if(await postModel(routes.PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_POST_API.replace('{student_id}', this.props.student.id.toString()), data)) {
          this.reloadActivePeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onCancelScheduleConfigurationOverwrite) {
      try{
        if(await deleteModel(routes.PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_DELETE_API.replace('{student_id}', this.props.student.id.toString()))) {
          this.reloadActivePeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      sendNotificationEmail: false,
      finishTrainingPeriodRevision: false,
      idToActivate: null,
      onRemoveScheduleConfigurationWeek: null,
      onRemoveScheduleConfigurationDay: null,
      onOverwriteScheduleConfiguration: false,
      onCancelScheduleConfigurationOverwrite: false,
      deactivatePeriod: false,
      personalEntryIdToDelete: null,
      trainingPeriodIdToDelete: null,
      personalTrainingHistoryIdToDelete: null,
      trainingPeriodIdToDuplicate: null,
      enableTracking: null,
      confirmInProgress: false,
    });
  }

  mayUpdateActivePeriod() {
    return this.state.initialTrainingDateInput.length > 0 &&
           this.state.finalTrainingDateInput.length > 0 && (
             this.state.initialTrainingDateInput !== this.state.initialTrainingDate ||
             this.state.finalTrainingDateInput !== this.state.finalTrainingDate ||
             this.state.boundToServices !== this.state.boundToServicesInput
           ) &&
           (this.state.initialTrainingDateInput !== this.state.initialTrainingDate || this.state.initialTrainingDateInput <= this.state.finalTrainingDateInput);
  }

  async updateActivePeriod() {
    if(!this.state.activePeriod || !this.state.initialTrainingDateInput || !this.state.finalTrainingDateInput) {
      return;
    }

    this.setState({
      loadingData: true
    });

    const data = {
        training_period_id: this.state.activePeriod.id,
        initial_date: this.state.initialTrainingDateInput,
        bound_to_services: this.state.boundToServicesInput
    };

    if(this.state.initialTrainingDateInput === this.state.initialTrainingDate) {
      data.final_date = this.state.finalTrainingDateInput;
    }

    if(await postModel(routes.PERSONAL_TRAINING_PERIOD_POST_API.replace('{id}', this.props.student.id.toString()), data)) {
      this.reloadActivePeriod();
      this.reloadPersonalTrainingData();
    }

    this.setState({
      loadingData: false,
    });
  }

  async updatePhysicalEvaluationData() {
    this.setState({
      loadingData: true
    });

    const data = {physical_evaluation: this.state.physicalEvaluationData};

    if(await postModel(routes.PHYSICAL_EVALUATION_DATA_POST_API.replace('{student_id}', this.props.student.id.toString()), data)) {
      await this.reloadPhysicalEvaluationData();

      if(this.state.suggestedPeriodsOnlyFilter) {
        this.reloadAvailableTrainingPeriods();
      }
    }

    this.setState({
      loadingData: false,
    });
  }

  getActiveTrainingDays() {
    if(this.state.activePeriod === null) {
      return null;
    }

    return this.state.activePeriod.training_days.map((training) => (
      <React.Fragment key={`active_period:training:${training.id}`}>

        <li className="personal-training__active-period__training">

          <div className="personal-training__active-period__training__text-wrapper">

            <h3 className="personal-training__active-period__training__name">{training.name}</h3>
            <p className="personal-training__active-period__training__note">{training.note}</p>

          </div>

          {(!training.is_placeholder && this.state.studentStatus === STUDENT_ACTIVE_STATUS && this.props.userPermissionIds.includes(permissions.ADD_PERSONAL_TRAINING_DATA_PERMISSION)) &&
            <div className="personal-training__active-period__training__start-button-wrapper">

              <Link
                className="personal-training__active-period__training__start-button"
                to={`${routes.PERSONAL_TRAINING_CLASSBOARD_PATH.replace('{student_id}', this.props.student.id)}${training.id}`}
              >

                  <i className="fas fa-play"></i>

              </Link>

            </div>
          }

        </li>

        <div className="personal-training__active-period__ruler"></div>

      </React.Fragment>
    ));
  }

  getPhysicalEvaluationDataCopy() {
    return this.state.physicalEvaluationData.map((entry) => {
      return {...entry};
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    if(name === 'initial_date') {
      update.initialTrainingDateInput = value;
    }
    else if(name === 'final_date') {
      update.finalTrainingDateInput = value;
    }
    else if(name === 'bound_to_services') {
      update.boundToServicesInput = value;
    }
    else if(name.startsWith('physical_evaluation_question:')) {
      const selection = name.split(':');
      const question_id = parseInt(selection[1]);

      const valueCopy = this.getPhysicalEvaluationDataCopy();

      for(const entry of valueCopy) {
        if(entry.question_id === question_id) {
          entry.value = value;
          break;
        }
      }

      update.physicalEvaluationData = valueCopy;
    }
    else {
      update[name] = value;
    }

    this.setState(update);
  }

  getActivePeriodIndicators() {
    if(this.state.activePeriod === null) {
      return null;
    }

    const periodReference = this.state.activePeriod.period_reference || 7;

    const cycleCount = this.state.activePeriod.repetition_count;

    const initialDate = getAsLocalDate(this.state.initialTrainingDate);
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    // let finalDate = getAsLocalDate(this.state.initialTrainingDate);
    // finalDate.setDate(finalDate.getDate() + (periodReference * cycleCount) - 1);
    // finalDate = finalDate.toISOString().slice(0, 10);

    const timeDiff = Math.abs(today.getTime() - initialDate.getTime());
    let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const currentCycle = initialDate <= today ? Math.floor(daysCount / periodReference) + 1 : 0;
    const cycleReferenceMultiplier = Math.min(cycleCount, currentCycle);

    let expectedEnergyConsunption = 0;
    let energyExpended = 0;

    const trainingsCountExpected = this.state.activePeriod.training_days.length * cycleReferenceMultiplier;
    let trainingsExecuted = 0;

    const capacitiesMap = new Map();
    const capacityColorMap = new Map();

    const energyExpenseMap = new Map();
    const energyExpectedExpenseMap = new Map();

    const personalTrainingDayIds = [];

    for(const trainingDay of this.state.activePeriod.training_days) {
      personalTrainingDayIds.push(trainingDay.id);

      for(const association of trainingDay.group_associations) {
        if(association.activity_reference) {
          if(this.state.studentWeight !== null) {
            expectedEnergyConsunption += this.state.studentWeight * (association.time_required / 60) * association.activity_reference.met;
          }

          const capacityAbbreviation = association.capacity_abbreviation;

          if(!capacitiesMap.has(capacityAbbreviation)) {
            capacitiesMap.set(capacityAbbreviation, {
              expectedCount: 1 * cycleReferenceMultiplier,
              executed: 0
            });
          }
          else {
            const mapEntry = capacitiesMap.get(capacityAbbreviation);
            mapEntry.expectedCount += 1 * cycleReferenceMultiplier;
          }

          if(!capacityColorMap.has(capacityAbbreviation)) {
            capacityColorMap.set(capacityAbbreviation, association.capacity_color);
          }
        }
      }

      // if(trainingDay.activity_reference) {
      //   if(this.state.studentWeight !== null) {
      //     const totalTime = trainingDay.group_associations.reduce((accumulator, currentValue) => accumulator + currentValue.time_required, 0) / 60;
      //
      //     expectedEnergyConsunption += this.state.studentWeight * totalTime * trainingDay.activity_reference.met;
      //   }
      //
      //   const capacityAbbreviation = trainingDay.capacity_abbreviation;
      //
      //   if(!capacitiesMap.has(capacityAbbreviation)) {
      //     capacitiesMap.set(capacityAbbreviation, {
      //       expectedCount: 1 * cycleReferenceMultiplier,
      //       executed: 0
      //     });
      //   }
      //   else {
      //     const mapEntry = capacitiesMap.get(capacityAbbreviation);
      //     mapEntry.expectedCount += 1 * cycleReferenceMultiplier;
      //   }
      //
      //   if(!capacityColorMap.has(capacityAbbreviation)) {
      //     capacityColorMap.set(capacityAbbreviation, trainingDay.capacity_color);
      //   }
      // }
    }

    for(const trainingData of this.state.personalTrainingData) {
      if(!this.state.boundToServices && (!trainingData.training_day || !personalTrainingDayIds.includes(trainingData.training_day.id))) {
        continue;
      }

      const executionDate = getAsLocalDate(trainingData.date);

      if(executionDate >= initialDate) {
        trainingsExecuted += 1;

        let entryEnergyExpense = 0;

        if (trainingData.energy_expended) {
          entryEnergyExpense = trainingData.energy_expended;
        }
        else if (this.state.studentWeight !== null) {
          if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
            entryEnergyExpense = this.state.studentWeight * (trainingData.total_time / 60) * trainingData.activity_reference.met;
          }
          else if (trainingData.training_day) {
            entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
              if (association.activity_reference) {
                return accumulator + (this.state.studentWeight * (association.time_required / 60) * association.activity_reference.met);
              }

              return accumulator;
            }, 0);
          }
        }

        if(entryEnergyExpense > 0) {
          energyExpended += entryEnergyExpense;

          const entryDiff = Math.abs(executionDate.getTime() - initialDate.getTime());
          let days = Math.ceil(entryDiff / (1000 * 3600 * 24));

          const cycleIndex = Math.floor(days / periodReference) + 1;
          const cycleLabel = `Ciclo ${cycleIndex}`;

          if(!energyExpenseMap.has(cycleIndex)) {
            energyExpenseMap.set(cycleIndex, StackPoint(entryEnergyExpense, cycleLabel, entryEnergyExpense));
          }
          else {
            const stackPoint = energyExpenseMap.get(cycleIndex);
            stackPoint.value += entryEnergyExpense;
            stackPoint.index += entryEnergyExpense;
          }
        }

        if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
          const capacityAbbreviation = trainingData.activity_reference.type_abbreviation;

          if(!capacitiesMap.has(capacityAbbreviation)) {
            capacitiesMap.set(capacityAbbreviation, {
              expectedCount: 0,
              executed: 1
            });
          }
          else {
            const mapEntry = capacitiesMap.get(capacityAbbreviation);
            mapEntry.executed += 1;
          }

          if(!capacityColorMap.has(capacityAbbreviation)) {
            capacityColorMap.set(capacityAbbreviation, trainingData.activity_reference.type_color);
          }
        }
        else if (trainingData.training_day) {
          for(const association of trainingData.training_day.group_associations) {
            if(association.capacity_abbreviation) {
              const capacityAbbreviation = association.capacity_abbreviation;

              if(!capacitiesMap.has(capacityAbbreviation)) {
                capacitiesMap.set(capacityAbbreviation, {
                  expectedCount: 0,
                  executed: 1
                });
              }
              else {
                const mapEntry = capacitiesMap.get(capacityAbbreviation);
                mapEntry.executed += 1;
              }

              if(!capacityColorMap.has(capacityAbbreviation)) {
                capacityColorMap.set(capacityAbbreviation, association.capacity_color);
              }
            }
          }
        }

        // if(trainingData.capacity_abbreviation) {
        //   const capacityAbbreviation = trainingData.capacity_abbreviation;
        //
        //   if(!capacitiesMap.has(capacityAbbreviation)) {
        //     capacitiesMap.set(capacityAbbreviation, {
        //       expectedCount: 0,
        //       executed: 1
        //     });
        //   }
        //   else {
        //     const mapEntry = capacitiesMap.get(capacityAbbreviation);
        //     mapEntry.executed += 1;
        //   }
        //
        //   if(!capacityColorMap.has(capacityAbbreviation)) {
        //     capacityColorMap.set(capacityAbbreviation, trainingData.capacity_color);
        //   }
        // }
      }
    }

    for(const entry of energyExpenseMap.values()) {
      entry.index = roundValue(entry.index, 2);
    }

    const energyExpectedExpensePoints = []
    const energyExpensePoints = []

    const cycleLabels = [];

    if(cycleReferenceMultiplier > 0) {
      for(let i=1; i<=cycleReferenceMultiplier; ++i) {
        const cycleLabel = `Ciclo ${i}`;

        cycleLabels.push(i);

        energyExpectedExpenseMap.set(i, StackPoint(expectedEnergyConsunption, cycleLabel, roundValue(expectedEnergyConsunption, 2)));
      }
    }

    for(const key of energyExpenseMap.keys()) {
      if(!cycleLabels.includes(key)) {
        cycleLabels.push(key);
      }
    }

    cycleLabels.sort((a, b) => a - b);

    for(const labelIndex of cycleLabels) {
      const label = `Ciclo ${labelIndex}`;

      if(energyExpenseMap.has(labelIndex)) {
        energyExpensePoints.push(energyExpenseMap.get(labelIndex));
      }
      else {
        energyExpensePoints.push(StackPoint(0, label, 0));
      }

      if(energyExpectedExpenseMap.has(labelIndex)) {
        energyExpectedExpensePoints.push(energyExpectedExpenseMap.get(labelIndex));
      }
      else {
        energyExpectedExpensePoints.push(StackPoint(0, label, 0));
      }
    }

    const energyConsumptionData = [
      StackGroup('Gasto', energyExpensePoints, '#f95b5b', null),
      StackGroup('Estimado', energyExpectedExpensePoints, '#d08989', null),
    ];

    const capacityExpected = [];
    const capacityExecuted = [];

    for(let [key, value] of capacitiesMap) {
      capacityExecuted.push(BarDataPoint(value.executed, key, capacityColorMap.get(key)));
      capacityExpected.push(BarDataPoint(value.expectedCount, key, `${capacityColorMap.get(key)}5c`));
    }

    const capacityGraphData = [
      {
        name: "Esperada",
        data: capacityExpected
      },
      {
        name: "Executada",
        data: capacityExecuted
      },
    ];

     energyExpended = roundValue(energyExpended, 0);
     expectedEnergyConsunption = roundValue(cycleReferenceMultiplier * expectedEnergyConsunption, 0);

     const totalProgress = 100 * trainingsExecuted / (this.state.activePeriod.training_days.length * cycleCount);

    return {
      periodReference,
      cycleCount,
      currentCycle,
      expectedEnergyConsunption,
      energyExpended,
      trainingsCountExpected,
      trainingsExecuted,
      capacityGraphData,
      energyConsumptionData,
      totalProgress
    };
  }

  getGraphHeight() {
    if(this.state.screenWidth <= 380) {
      return 300;
    }

    if(this.state.screenWidth <= 600) {
      return 350;
    }

    return 400;
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getGenderOptions() {
    return [
      SelectOption('', 'Todos'),
      SelectOption('Feminino', 'Feminino'),
      SelectOption('Masculino', 'Masculino'),
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.state.services,
      SERVICE_PERSONAL_SERVICE
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...services.map((service) => SelectOption(service, service))
    ];
  }

  getTrainingObjectiveOptions() {
    const objectives = [...TRAINING_PERIOD_OBJECTIVES];

    // objectives.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  mayRefreshList() {
    if(this.state.targetServiceInput !== this.state.targetServiceFilter ||
        this.state.mainObjectiveInput !== this.state.mainObjectiveFilter ||
        this.state.physicalLevelInput !== this.state.physicalLevelFilter ||
        this.state.discProfileInput !== this.state.discProfileFilter ||
        this.state.codeInput !== this.state.codeFilter ||
        this.state.targetGenderInput !== this.state.targetGenderFilter ||
        this.state.suggestedPeriodsOnlyInput !== this.state.suggestedPeriodsOnlyFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      const queryParameters = {};

      queryParameters.code = this.state.codeInput;
      queryParameters.target_gender = this.state.targetGenderInput;

      if(this.state.suggestedPeriodsOnlyInput) {
        const suggested_disc_profile = this.getDiscClassification(true);

        if (suggested_disc_profile.length > 1) {
          queryParameters.suggested_disc_profile = this.state.discProfileInput;
        }

        queryParameters.suggested_periods_only = this.state.suggestedPeriodsOnlyInput;
      }
      else {
        queryParameters.target_service = this.state.targetServiceInput;
        queryParameters.main_objective = this.state.mainObjectiveInput;
        queryParameters.suggested_physical_level = this.state.physicalLevelInput;
        queryParameters.suggested_disc_profile = this.state.discProfileInput;
      }

      this.props.history.replace(setUrlParameters(`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.PERSONAL_TRAINING_PATH}`, queryParameters));

      this.setState({
        targetServiceFilter: this.state.targetServiceInput,
        mainObjectiveFilter: this.state.mainObjectiveInput,
        physicalLevelFilter: this.state.physicalLevelInput,
        discProfileFilter: this.state.discProfileInput,
        codeFilter: this.state.codeInput,
        targetGenderFilter: this.state.targetGenderInput,
        suggestedPeriodsOnlyFilter: this.state.suggestedPeriodsOnlyInput,
      });
    }
  }

  maySavePhysicalEvaluation() {
    if(!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return false;
    }

    for(const currentEntry of this.state.physicalEvaluationData) {
      for(const originalEntry of this.state.originalPersonalTrainingData) {
        if(currentEntry.question_id === originalEntry.question_id) {
          if(currentEntry.value !== originalEntry.value) {
            return true;
          }

          break;
        }
      }
    }

    return false;
  }

  getSuggestedPhysicalLevelOptions() {
    const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

    return [
      SelectOption('', 'Todos'),
      ...levels.map((level) => SelectOption(level, level))
    ];
  }

  getQuestionOptions(question_id, currentValue=null) {
    let options;

    switch (question_id) {
      case 1:
        const objectives = [...TRAINING_PERIOD_OBJECTIVES];

        options = [
          SelectOption('', 'Não especificado'),
          ...objectives.map((objective) => SelectOption(objective, objective))
        ];
        break;
      case 2:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 3:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 6 meses', '0 à 6 meses'),
          SelectOption('6 meses à 1 ano', '6 meses à 1 ano'),
          SelectOption('1 à 2 anos', '1 à 2 anos'),
          SelectOption('2 à 3 anos', '2 à 3 anos'),
          SelectOption('3 anos ou mais', '3 anos ou mais'),
        ];
        break;
      case 4:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Menos de 5 horas', 'Menos de 5 horas'),
          SelectOption('Entre 5 e 8 horas', 'Entre 5 e 8 horas'),
          SelectOption('Mais de 8 horas', 'Mais de 8 horas'),
        ];
        break;
      case 5:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 6:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim', 'Sim'),
          SelectOption('Não', 'Não'),
          SelectOption('Às vezes', 'Às vezes'),
        ];
        break;
      case 7:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('0 à 1 ano', '0 à 1 ano'),
          SelectOption('1 à 3 anos', '1 à 3 anos'),
          SelectOption('3 à 5 anos', '3 à 5 anos'),
          SelectOption('5 anos ou mais', '5 anos ou mais'),
        ];
        break;
      case 8:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('Sim, frequentemente (fins de semana)', 'Sim, frequentemente (fins de semana)'),
          SelectOption('Sim, ocasionalmente', 'Sim, ocasionalmente'),
          SelectOption('Sim, raramente', 'Sim, raramente'),
          SelectOption('Não', 'Não'),
        ];
        break;
      case 9:
        options = [
          SelectOption('', 'Não especificado'),
          SelectOption('1', '1'),
          SelectOption('2', '2'),
          SelectOption('3', '3'),
          SelectOption('4', '4'),
          SelectOption('5', '5'),
          SelectOption('6', '6'),
          SelectOption('7', '7'),
        ];
        break;
      case 10:
        const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

        options = [
          SelectOption('', 'Não especificado'),
          ...levels.map((level) => SelectOption(level, level))
        ];
        break;
      default:
        options = [SelectOption('', 'Não especificado')];
    }

    if(currentValue !== null && !options.some((entry) => entry.value === currentValue)) {
      options.push(SelectOption(currentValue, currentValue));
    }

    return options;
  }

  getPhysicalEvaluationQuestions() {
    return this.state.physicalEvaluationData.map((question) => {
      let labelMessage = null;

      if(question.question_id === 1 && !TRAINING_PERIOD_OBJECTIVES.includes(question.value)) {
        labelMessage = 'Atualização de valor necessária.';
      }
      if(question.question_id === 10 && question.value) {
        switch (question.value) {
          case TRAINING_PERIOD_PHYSICAL_LEVELS[0]:
            labelMessage = 'Frequência ótima de 3.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[1]:
            labelMessage = 'Frequência ótima de 4 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[2]:
            labelMessage = 'Frequência ótima de 4.5 vezes/semana.';
            break;
          case TRAINING_PERIOD_PHYSICAL_LEVELS[3]:
            labelMessage = 'Frequência ótima de 5 vezes/semana.';
            break;
          default:
        }
      }

      return (
        <DefaultInput
          key={`physical_evaluation_question_input_${question.question_id}`}
          name={`physical_evaluation_question:${question.question_id}`}
          label={question.question_text}
          labelMessage={labelMessage}
          labelClassName="personal-training__question__label"
          type="select"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={question.value || ''}
          options={this.getQuestionOptions(question.question_id, question.value)}
          disabled={!this.props.userPermissionIds.includes(permissions.EDIT_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)}
        />
      );
    });
  }

  getSuggestedDiscProfileOptions() {
    const options = [
      SelectOption('', 'Não especificado'),
      SelectOption('D', 'D'),
      SelectOption('I', 'I'),
      SelectOption('S', 'S'),
      SelectOption('C', 'C')
    ];

    // if(this.state.suggestedPeriodsOnlyInput) {
    //   const currentValue = this.getDiscClassification(true);
    //
    //   if(!options.some((entry) => entry.value === currentValue)) {
    //     options.push(SelectOption(currentValue, currentValue));
    //   }
    // }

    return options;
  }

  getDiscClassification(asText=false) {
    let result = ['D'];

    let currentScore = this.props.student.disc.total_d;

    if(currentScore < this.props.student.disc.total_i) {
      result = ['I'];
      currentScore = this.props.student.disc.total_i;
    }
    else if(currentScore === this.props.student.disc.total_i) {
      result.push('I');
    }

    if(currentScore < this.props.student.disc.total_s) {
      result = ['S'];
      currentScore = this.props.student.disc.total_s;
    }
    else if(currentScore === this.props.student.disc.total_s) {
      result.push('S');
    }

    if(currentScore < this.props.student.disc.total_c) {
      result = ['C'];
      currentScore = this.props.student.disc.total_c;
    }
    else if(currentScore === this.props.student.disc.total_c) {
      result.push('C');
    }

    if(asText) {
      return result.join('');
    }

    const elementD = (
      <p
        className="personal-training__profile__disc__text--d"
        key="disc_classification_d"
      >
        {this.state.screenWidth > 420 ? 'Dominância' : 'D'}
      </p>
    );
    const elementI = (
      <p
        className="personal-training__profile__disc__text--i"
        key="disc_classification_i"
      >
        {this.state.screenWidth > 420 ? 'Influência' : 'I'}
      </p>
    );
    const elementS = (
      <p
        className="personal-training__profile__disc__text--s"
        key="disc_classification_s"
      >
        {this.state.screenWidth > 420 ? 'Estabilidade' : 'S'}
      </p>
    );
    const elementC = (
      <p
        className="personal-training__profile__disc__text--c"
        key="disc_classification_c"
      >
        {this.state.screenWidth > 420 ? 'Complacência' : 'C'}
      </p>
    );

    let classification = [];

    if(result.includes('D')) {
      classification.push(elementD);
    }
    if(result.includes('I')) {
      classification.push(elementI);
    }
    if(result.includes('S')) {
      classification.push(elementS);
    }
    if(result.includes('C')) {
      classification.push(elementC);
    }

    return classification;
  }

  getCoachResponsibleData() {
    let trainingPeriodActivatedAt = null;

    if (this.state.user_service_status.training_period_activated_at !== null) {
      trainingPeriodActivatedAt = getAsLocalDatetime(this.state.user_service_status.training_period_activated_at, false);
      trainingPeriodActivatedAt = `Atualizado em ${trainingPeriodActivatedAt.toLocaleDateString()} ${getLocalTimeIsoString(trainingPeriodActivatedAt)}`;
    }

    return (
      <React.Fragment>

        {this.state.user_service_status.training_period_responsible_name &&
          <DefaultInput
            name="training_period_responsible_name"
            label="Periodização ativada por:"
            labelMessage={trainingPeriodActivatedAt}
            labelClassName="personal-training__question__label"
            type="text"
            // handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.user_service_status.training_period_responsible_name || ''}
            disabled={true}
          />
        }

      </React.Fragment>
    );
  }

  trackingIsEnabled() {
    if (!this.state.user_service_status || this.state.user_service_status.track_personal_training_service) {
      return true;
    }

    return false;
  }

  getTrackingStatus() {
    if(this.trackingIsEnabled()) {
      return (
        <h2 className="personal-training__tracking-status--green">Requer periodização</h2>
      );
    }

    return (
      <h2 className="personal-training__tracking-status--red">Não requer periodização</h2>
    );
  }

  mayShowPhysicalActivity() {
    return this.state.studentStatus === STUDENT_ACTIVE_STATUS &&
           this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
           (this.state.weekly_exercise_frequency !== null ||
            this.state.main_fyd_activities !== null ||
            this.state.other_main_activities !== null ||
            this.state.active_weekdays !== null);
  }

  getPhysicalactivityContent() {
    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    let weekdayInputs = null;

    if (this.state.active_weekdays !== null) {
      weekdayInputs = weekdays.map((weekday, index) => {
        const checked = this.state.active_weekdays & Math.pow(2, index);

        return (
          <DefaultInput
            key={`active_weekdays:${index}`}
            name={`active_weekdays:${index}`}
            label={weekday}
            isHorizontal={true}
            invertLabel={true}
            isHighlighted={false}
            type="checkbox"
            value={checked > 0}
            disabled
          />
        );
      });
    }

    return (
      <React.Fragment>

        {this.state.weekly_exercise_frequency !== null &&
          <div className="personal-training__indicators-container">

            <div className="personal-training__indicators-wrapper">

              {this.state.weekly_exercise_frequency !== null &&
                <div className="personal-training__indicator">

                  <h2 className="personal-training__indicator__label">Frequência de treinos semanal</h2>
                  <p className="personal-training__indicator__value">

                    <span className="personal-training__indicator__value__number">{Math.round(10 * this.state.weekly_exercise_frequency) / 10}</span>
                    <span className="personal-training__indicator__value__unit">treinos/semana</span>

                  </p>

                </div>
              }

            </div>

          </div>
        }

        {this.state.main_fyd_activities !== null &&
          <FiledSet legend="Modalidades praticadas">

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_FUNCTIONAL_SERVICE}`}
                label={<span className="personal-training__input-label--red">{SERVICE_FUNCTIONAL_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_FUNCTIONAL_SERVICE)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_GYM_SERVICE}`}
                label={<span className="personal-training__input-label--red">{SERVICE_GYM_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_GYM_SERVICE)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BIKE_SERVICE}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_BIKE_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_BIKE_SERVICE)}
                disabled
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CARDIO}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_CARDIO}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_CARDIO)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_BOOTCAMP}`}
                label={<span className="personal-training__input-label--yellow">{SERVICE_BOOTCAMP}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_BOOTCAMP)}
                disabled
              />

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_PILATES_SERVICE}`}
                label={<span className="personal-training__input-label--green">{SERVICE_PILATES_SERVICE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_PILATES_SERVICE)}
                disabled
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name={`main_fyd_activities:${SERVICE_CORE}`}
                label={<span className="personal-training__input-label--green">{SERVICE_CORE}</span>}
                isHorizontal={true}
                invertLabel={true}
                isHighlighted={false}
                type="checkbox"
                value={this.state.main_fyd_activities.includes(SERVICE_CORE)}
                disabled
              />

            </HalfWrapper>

            {this.state.other_main_activities !== null &&
              <DefaultInput
                name="other_main_activities"
                label="Outras"
                type="text"
                placeholder="Outras modalidades praticadas"
                value={this.state.other_main_activities}
                autoComplete="off"
                disabled
              />
            }

          </FiledSet>
        }

        {weekdayInputs !== null &&
          <FiledSet legend="Dias que pretende treinar">

            <HalfWrapper>

              {weekdayInputs}

            </HalfWrapper>

          </FiledSet>
        }

      </React.Fragment>
    );
  }

  maySaveStudentScheduleConfiguration() {
    if (this.state.student_schedule_configuration === null || this.state.original_student_schedule_configuration === null) {
      return false;
    }

    if (this.state.student_schedule_configuration.length !== this.state.original_student_schedule_configuration.length) {
      return true;
    }

    for (let i=0; i < this.state.student_schedule_configuration.length; ++i) {
      const originalWeek = this.state.original_student_schedule_configuration[i];
      const week = this.state.student_schedule_configuration[i];

      for (let j=0; j < week.length; ++j) {
        const originalDay = originalWeek[j];
        const day = week[j];

        if ((originalDay === null && day !== null) ||
            (originalDay !== null && day === null) ||
            (originalDay !== null && (originalDay.text !== day.text || originalDay.color !== day.color))) {
          return true;
        }
      }
    }

    return false;
  }

  async saveStudentScheduleConfiguration() {
    const data = {schedule_configuration: this.state.student_schedule_configuration};

    try{
      if(await postModel(routes.PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_POST_API.replace('{student_id}', this.props.student.id.toString()), data)) {
        this.reloadActivePeriod();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  onAddScheduleConfigurationWeek() {
    const student_schedule_configuration = this.state.student_schedule_configuration.map((week) => {
      return week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });
    });

    student_schedule_configuration.push([null, null, null, null, null, null, null]);

    this.setState({student_schedule_configuration});
  }

  changeScheduleConfigurationDay() {
    const student_schedule_configuration = this.state.student_schedule_configuration.map((week, weekIndex) => {
      const weekCopy = week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });

      if (weekIndex === this.state.onChangeScheduleConfigurationDay.weekIndex) {
        weekCopy[this.state.onChangeScheduleConfigurationDay.dayIndex] = {
          text: this.state.scheduleConfigurationDayText,
          color: this.state.scheduleConfigurationDayColor,
        };
      }

      return weekCopy;
    });

    this.setState({
      student_schedule_configuration,
      onChangeScheduleConfigurationDay: null
    });
  }

  onChangeScheduleConfigurationDay(weekIndex, dayIndex) {
    let scheduleConfigurationDayText = "";
    let scheduleConfigurationDayColor = TRAINING_CALENDAR_COLOR_MAP['Descanso'];

    if (this.state.student_schedule_configuration.length - 1 >= weekIndex) {
      const dayEntry = this.state.student_schedule_configuration[weekIndex][dayIndex];

      if (dayEntry !== null) {
        scheduleConfigurationDayText = dayEntry.text;
        scheduleConfigurationDayColor = dayEntry.color;
      }
    }

    this.setState({
      onChangeScheduleConfigurationDay: {weekIndex, dayIndex},
      scheduleConfigurationDayText,
      scheduleConfigurationDayColor
    });
  }

  onSwapScheduleConfigurationDays(initialPosition, finalPosition) {
    const student_schedule_configuration = this.state.student_schedule_configuration.map((week, weekIndex) => {
      return week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });
    });

    let finalEntry = student_schedule_configuration[finalPosition.weekIndex][finalPosition.dayIndex];

    student_schedule_configuration[finalPosition.weekIndex][finalPosition.dayIndex] = student_schedule_configuration[initialPosition.weekIndex][initialPosition.dayIndex];
    student_schedule_configuration[initialPosition.weekIndex][initialPosition.dayIndex] = finalEntry;

    this.setState({
      student_schedule_configuration
    });
  }

  getScheduleConfigurationColorOptions() {
    const options = [
      ...Object.entries(TRAINING_CALENDAR_COLOR_MAP).map(([key, value]) => SelectOption(value, key))
    ];

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  }

  getScheduleConfiguration() {
    let schedule_configuration;
    let editable = false;

    if (this.state.student_schedule_configuration !== null) {
      schedule_configuration = this.state.student_schedule_configuration;
      editable = true;
    }
    else if (this.state.activePeriod.schedule_configuration.length > 0) {
      schedule_configuration = this.state.activePeriod.schedule_configuration;
    }
    else {
      return null;
    }

    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    let convertedTodayWeekDay = this.todayWeekDay - 1;
    if (convertedTodayWeekDay < 0) {
      convertedTodayWeekDay = 6;
    }

    let currentWeekIndex = 0;

    if (schedule_configuration.length > 0) {
      const initialDate = getAsLocalDate(this.state.initialTrainingDate);
      const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

      const timeDiff = Math.abs(today.getTime() - initialDate.getTime());
      const daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24)) + convertedTodayWeekDay;

      currentWeekIndex = (Math.floor(daysCount / 7)) % schedule_configuration.length;
    }

    const weekdayList = weekdays.map((weekday, index) => {
      return (
        <div
          key={`schedule_configuration:header:${index}`}
          className={`personal-training__schedule-configuration__header__weekday${index === convertedTodayWeekDay ? '--highlighted' : ''}`}
        >

          <h3 className="personal-training__schedule-configuration__header__weekday__text">

            {weekday}

          </h3>

        </div>
      );
    });

    const weeks = schedule_configuration.map((weekList, weekIndex) => {
      return (
        <div
          key={`schedule_configuration:week:${weekIndex}`}
          className="personal-training__schedule-configuration__week"
        >

          <div
            className={`personal-training__schedule-configuration__week__header${weekIndex === currentWeekIndex ? '--highlighted' : ''}`}
          >

            <h3 className="personal-training__schedule-configuration__week__header__text">

              {`Semana ${weekIndex + 1}`}

            </h3>

            {editable &&
              <button
                className="personal-training__schedule-configuration__week__header__action-button"
                onClick={() => this.onRemoveScheduleConfigurationWeek(weekIndex)}
              >

                <i className="far fa-trash-alt"></i>

              </button>
            }

          </div>

          <div className="personal-training__schedule-configuration__week__content">

            {weekList.map((entry, dayIndex) => {
              let content = null;

              if (entry !== null) {
                content = (
                  <div
                    className="personal-training__schedule-configuration__week__weekday__wrapper"
                    style={{background: entry.color}}
                    draggable={true}
                    onDragStart={(event) => {
                      this.entryBeingDragged = {weekIndex, dayIndex};
                      // event.dataTransfer.setData("text/plain", JSON.stringify(this.entryBeingDragged));
                      event.dataTransfer.dropEffect = "move";
                    }}
                  >

                    {editable &&
                      <div className="personal-training__schedule-configuration__week__weekday__controls">

                        <button
                          className="personal-training__schedule-configuration__week__weekday__action-button--blue"
                          onClick={() => this.onChangeScheduleConfigurationDay(weekIndex, dayIndex)}
                        >

                          <i className="fas fa-edit"></i>

                        </button>

                        <button
                          className="personal-training__schedule-configuration__week__weekday__action-button--red"
                          onClick={() => this.onRemoveScheduleConfigurationDay(weekIndex, dayIndex)}
                        >

                          <i className="far fa-trash-alt"></i>

                        </button>

                      </div>
                    }

                    <p className="personal-training__schedule-configuration__week__weekday__text">

                      {entry.text}

                    </p>

                    {editable &&
                      <button
                        className="personal-training__schedule-configuration__week__weekday__grab-button"
                      >

                        <i className="fa-solid fa-grip-vertical"></i>

                      </button>
                    }

                  </div>
                );
              }
              else if (editable) {
                content = (
                  <button
                    className="personal-training__schedule-configuration__add-weekday-button"
                    onClick={() => this.onChangeScheduleConfigurationDay(weekIndex, dayIndex)}
                  >

                    <i className="fas fa-plus personal-training__schedule-configuration__add-weekday-button__icon"></i>
                    Adicionar dia

                  </button>
                );
              }

              return (
                <div
                  key={`schedule_configuration:week:${weekIndex}:weekday:${dayIndex}`}
                  className="personal-training__schedule-configuration__week__weekday"
                  onDrop={(event) => {
                    event.preventDefault();
                    event.target.classList.remove('personal-training__dropping-effect');

                    if (this.entryBeingDragged !== null && (this.entryBeingDragged.weekIndex !== weekIndex || this.entryBeingDragged.dayIndex !== dayIndex)) {
                      this.onSwapScheduleConfigurationDays(this.entryBeingDragged, {weekIndex, dayIndex});
                    }
                  }}
                  onDragOver={(event) => {
                    event.preventDefault();
                    event.dataTransfer.dropEffect = "move";
                  }}
                  onDragEnter={(event) => {
                    event.preventDefault();
                    // const draggedEntry = JSON.parse(event.dataTransfer.getData("text/plain"));

                    if (this.entryBeingDragged !== null && (this.entryBeingDragged.weekIndex !== weekIndex || this.entryBeingDragged.dayIndex !== dayIndex)) {
                      event.target.classList.add('personal-training__dropping-effect');
                    }
                  }}
                  onDragLeave={(event) => {
                    event.preventDefault();
                    event.target.classList.remove('personal-training__dropping-effect');
                  }}
                >

                  {content}

                </div>
              );
            })}

          </div>

        </div>
      );
    });

    return (
      <div className="personal-training__schedule-configuration__wrapper">

        <div className="personal-training__schedule-configuration">

          <div className="personal-training__schedule-configuration__header">

            {weekdayList}

          </div>

          <div
            className="personal-training__schedule-configuration__week-list"
          >

            {weeks}

            {editable &&
              <div
                className="personal-training__schedule-configuration__week"
              >

                <h3 className="personal-training__schedule-configuration__week__header--highlighted">

                  <span
                    className="personal-training__schedule-configuration__week__header__text"
                  >

                    {`Semana ${schedule_configuration.length + 1}`}

                  </span>

                </h3>

                <div className="personal-training__schedule-configuration__week__content">

                  <div className="personal-training__schedule-configuration__add-week-button__wrapper">

                    <button
                      className="personal-training__schedule-configuration__add-week-button"
                      onClick={() => this.onAddScheduleConfigurationWeek()}
                    >

                      <i className="fas fa-plus personal-training__schedule-configuration__add-week-button__icon"></i>
                      Adicionar semana

                    </button>

                  </div>

                </div>

              </div>
            }

          </div>

        </div>

      </div>
    );
  }

  render() {
    const indicatorsData = this.getActivePeriodIndicators();

    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ): (
      <React.Fragment>

        <OverlayWindow
          className="personal-training__overlay"
          visible={this.state.onChangeScheduleConfigurationDay != null}
          actions={(
            <div className="personal-training__overlay__action-container">

              <DefaultMenuButton
                className="personal-training__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="personal-training__overlay__action-button"
                onClick={() => {
                  this.changeScheduleConfigurationDay();
                }}
                text="Confirmar"
                disabled={this.state.scheduleConfigurationDayText.length <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="personal-training__overlay__header">

            <h3 className="personal-training__overlay__header__title">
              Adicionar/Editar dia
            </h3>

          </header>

          <hr className="personal-training__horizontal-rule" />

          <div className="personal-training__overlay__content">

            <DefaultInput
              name="scheduleConfigurationDayText"
              label="Texto"
              type="textarea"
              placeholder="Texto do dia"
              rows="3"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.scheduleConfigurationDayText}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
            />

            <div className="personal-training__color-input-container">

              <div
                className="personal-training__color-preview"
                style={{background: this.state.scheduleConfigurationDayColor || TRAINING_CALENDAR_COLOR_MAP['Descanso']}}
              >
              </div>

              <DefaultInput
                name="scheduleConfigurationDayColor"
                label="Cor"
                type="select"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.scheduleConfigurationDayColor}
                options={this.getScheduleConfigurationColorOptions()}
              />

            </div>

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.idToActivate !== null ||
                   this.state.onRemoveScheduleConfigurationWeek !== null ||
                   this.state.onRemoveScheduleConfigurationDay !== null ||
                   this.state.onOverwriteScheduleConfiguration ||
                   this.state.onCancelScheduleConfigurationOverwrite ||
                   this.state.deactivatePeriod ||
                   this.state.personalEntryIdToDelete !== null ||
                   this.state.trainingPeriodIdToDelete !== null ||
                   this.state.personalTrainingHistoryIdToDelete !== null ||
                   this.state.trainingPeriodIdToDuplicate !== null ||
                   this.state.sendNotificationEmail ||
                   this.state.finishTrainingPeriodRevision ||
                   this.state.enableTracking !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        {(this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION) &&
          this.props.userPermissionIds.includes(permissions.ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) &&
          <React.Fragment>

            <div className="personal-training__tracking-status-wrapper">

              {this.getTrackingStatus()}

              <button
                className="model-table__default-button personal-training__tracking-status__toggle-button"
                onClick={(event) => this.onChangeTrackingStatus(!this.trackingIsEnabled())}
              >

                <i className="fas fa-toggle-on"></i> {this.trackingIsEnabled() ? 'Desabilitar periodização' : 'Habilitar periodização'}

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.state.studentStatus !== STUDENT_ACTIVE_STATUS &&
          <p className="personal-training__alert-text">

            <i className="fas fa-exclamation personal-training__alert-text__icon"></i>
            O aluno precisa estar ativo para liberar o acesso completo

          </p>
        }

        {(this.state.activePeriod !== null && this.props.student.email_is_validated !== false && !this.props.student.block_email_notification && this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)) &&
          <React.Fragment>

            <div className="personal-training__actions-container">

              <button
                className="model-table__default-button"
                disabled={this.state.notificationEmailSent}
                onClick={(event) => this.onSendNotificationEmail()}
              >

                <i className="fas fa-envelope"></i> {this.state.notificationEmailSent ? 'Email enviado' : 'Enviar email de notificação'}

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {(this.state.studentStatus === STUDENT_ACTIVE_STATUS &&
          this.props.userPermissionIds.includes(permissions.ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
          this.state.user_service_status &&
          this.state.user_service_status.personal_training_service_status === P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS) &&
          <React.Fragment>

            <div className="personal-training__alert-text-container">

              <p className="personal-training__alert-text">

                <i className="fas fa-exclamation personal-training__alert-text__icon"></i>
                Revisão de periodização em andamento (Nutrição)

              </p>

              <button
                className="model-table__default-button personal-training__alert-text__button"
                disabled={this.state.finishTrainingPeriodRevision}
                onClick={(event) => this.onFinishTrainingPeriodRevision()}
              >

                <i className="fa-solid fa-check"></i> Finalizar revisão

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        {(this.state.user_service_status &&
          this.state.user_service_status.training_period_responsible_name) &&
          <React.Fragment>

            <section className="personal-training__coach-responsible">

              <header
                className="personal-training__coach-responsible__header"
                onClick={() => this.setState({responsibleSectionVisible: !this.state.responsibleSectionVisible})}
              >

                <h3 className="personal-training__coach-responsible__header__text">
                  <i className="far fa-chart-bar personal-training__coach-responsible__header__text-icon"></i>
                  Responsáveis pelo aluno
                </h3>

                {this.state.responsibleSectionVisible ?
                  <i className="fas fa-chevron-down personal-training__coach-responsible__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training__coach-responsible__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training__coach-responsible__content"
                pose={this.state.responsibleSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container personal-training__coach-responsible__content-wrapper">

                  {this.getCoachResponsibleData()}

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
          <React.Fragment>

            <section className="personal-training__profile">

              <header
                className="personal-training__profile__header"
                onClick={() => this.setState({profileSectionVisible: !this.state.profileSectionVisible})}
              >

                <h3 className="personal-training__profile__header__text">
                  <i className="far fa-chart-bar personal-training__profile__header__text-icon"></i>
                  Perfil
                </h3>

                {this.state.profileSectionVisible ?
                  <i className="fas fa-chevron-down personal-training__profile__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training__profile__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training__profile__content"
                pose={this.state.profileSectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container personal-training__profile__content-wrapper">

                  {(this.props.student.disc && this.props.student.disc !== null) ?
                    <div className="personal-training__profile__disc">

                      <h2 className="personal-training__profile__disc__label">DISC:</h2>
                      {this.getDiscClassification()}

                    </div>:
                    null
                  }

                  {(this.props.student.note !== null && this.props.student.note.length > 0) &&
                    <DefaultInput
                      name="note"
                      label="Observação"
                      labelClassName="personal-training__question__label"
                      labelMessage={this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID) ? 'Atualização deve ser realizada na aba "Dados gerais"' : ''}
                      type="text"
                      value={this.props.student.note || ''}
                      autoComplete="off"
                      disabled={true}
                    />
                  }

                  {this.getPhysicalEvaluationQuestions()}

                  {this.maySavePhysicalEvaluation() &&
                    <div className="personal-training__profile__buttons-container">

                      <button
                        className="personal-training__profile__save-button"
                        onClick={() => this.updatePhysicalEvaluationData()}
                      >

                        Salvar

                      </button>

                    </div>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.mayShowPhysicalActivity() &&
          <React.Fragment>

            <section className="personal-training__physicalActivity">

              <header
                className="personal-training__physicalActivity__header"
                onClick={() => this.setState({physicalActivitySectionVisible: !this.state.physicalActivitySectionVisible})}
              >

                <h3 className="personal-training__physicalActivity__header__text">
                  <i className="far fa-chart-bar personal-training__physicalActivity__header__text-icon"></i>
                  Atividade física
                </h3>

                {this.state.physicalActivitySectionVisible ?
                  <i className="fas fa-chevron-down personal-training__physicalActivity__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training__physicalActivity__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training__physicalActivity__content"
                pose={this.state.physicalActivitySectionVisible ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="vertical-accordion-container personal-training__physicalActivity__content-wrapper">

                  {this.getPhysicalactivityContent()}

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.state.activePeriod !== null &&
          <React.Fragment>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-dumbbell"></i>}
              text="Periodização ativa"
            />

            <section className="personal-training__active-period-container">

              <header className="personal-training__active-period__header">

                <div className="personal-training__active-period__header-wrapper">

                  <div className="personal-training__active-period__header__content-wrapper">

                    <h2 className="personal-training__active-period__header__title">

                      {`${(this.state.activePeriod.code !== null && this.state.activePeriod.code.length > 0) ? `${this.state.activePeriod.code} - ` : ''}${this.state.activePeriod.name}`}

                      {(this.state.activePeriod.editable && this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID)) &&
                        <Link
                          className="personal-training__active-period__header__title__link"
                          to={`${routes.TRAINING_PERIOD_EDIT_PATH}${this.state.activePeriod.id}`}
                        >

                            <i className="fa-solid fa-pen personal-training__active-period__header__title__link__icon"></i>

                        </Link>
                      }

                    </h2>

                    <div className="personal-training__active-period__header__input-container">

                      <DefaultInput
                        className="personal-training__active-period__header__initial-date-input"
                        name="initial_date"
                        type="date"
                        label="Data de início:"
                        placeholder="Data de início"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.initialTrainingDateInput || ''}
                        autoComplete="off"
                        isHorizontal={this.state.screenWidth > 600}
                        disabled={this.state.studentStatus !== STUDENT_ACTIVE_STATUS || !this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)}
                      />

                      <DefaultInput
                        className="personal-training__active-period__header__end-date-input"
                        name="final_date"
                        type="date"
                        label="Previsão de fim:"
                        labelMessage={this.state.initialTrainingDateInput !== this.state.initialTrainingDate ? 'Data final será recalculada' : ''}
                        hideInput={this.state.initialTrainingDateInput !== this.state.initialTrainingDate}
                        placeholder="Previsão de fim"
                        min={this.state.initialTrainingDateInput}
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.initialTrainingDateInput === this.state.initialTrainingDate ? (this.state.finalTrainingDateInput || '') : ''}
                        autoComplete="off"
                        isHorizontal={this.state.screenWidth > 600}
                        disabled={this.state.initialTrainingDateInput !== this.state.initialTrainingDate || !this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)}
                      />

                      {this.isDefaultUnit() &&
                        <DefaultInput
                          name="bound_to_services"
                          isHighlighted={false}
                          label="Vinculada às aulas e treinos complementares: "
                          type="toggle"
                          isHorizontal={false}
                          activeText="Sim"
                          inactiveText="Não"
                          handleInputChange={(event) => this.handleInputChange(event)}
                          value={this.state.boundToServicesInput}
                          disabled={this.state.studentStatus !== STUDENT_ACTIVE_STATUS || !this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)}
                        />
                      }

                    </div>

                  </div>

                  <div className="personal-training__active-period__header__controls-wrapper">

                    {this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION) &&
                      <React.Fragment>

                        {this.mayUpdateActivePeriod() &&
                          <button
                            className="personal-training__active-period__header__save-button"
                            onClick={() => this.updateActivePeriod()}
                          >

                            Salvar

                          </button>
                        }

                        <button
                          onClick={() => this.deactivatePeriod()}
                          className="personal-training__active-period__header__deactivate-button"
                        >

                          {this.state.screenWidth > 510 ? 'Desativar' : <i className="fas fa-times"></i>}

                        </button>

                      </React.Fragment>
                    }

                  </div>

                </div>

                {(this.state.activePeriod.schedule_configuration.length > 0 || this.state.activePeriod.note !== null) &&
                  <section className="personal-training__note-to-students">

                    <header
                      className="personal-training__note-to-students__header"
                      onClick={() => this.setState({studentNoteVisible: !this.state.studentNoteVisible})}
                    >

                      <h3 className="personal-training__note-to-students__header__text">
                        <i className="fa-solid fa-calendar-days personal-training__note-to-students__header__text-icon"></i>
                        Cronograma
                      </h3>

                      {this.state.studentNoteVisible ?
                        <i className="fas fa-chevron-down personal-training__note-to-students__header__visible-icon"></i>:
                        <i className="fas fa-chevron-up personal-training__note-to-students__header__visible-icon"></i>
                      }

                    </header>

                    <VerticalAccordionContainer
                      className="vertical-accordion-container personal-training__note-to-students__content"
                      pose={this.state.studentNoteVisible ? 'verticalOpen' : 'verticalClosed'}>

                      <div className="vertical-accordion-container personal-training__note-to-students__content-wrapper">

                        <div className="personal-training__active-period__header__schedule-configuration">

                          <div className="personal-training__active-period__header__schedule-configuration__actions">

                            {this.state.student_schedule_configuration === null &&
                              <button
                                className="personal-training__active-period__header__save-button"
                                onClick={() => this.onOverwriteScheduleConfiguration()}
                              >

                                Sobrescrever cronograma

                              </button>
                            }

                            {this.maySaveStudentScheduleConfiguration() &&
                              <button
                                className="personal-training__active-period__header__save-button"
                                onClick={() => this.saveStudentScheduleConfiguration()}
                              >

                                Salvar

                              </button>
                            }

                            {this.state.student_schedule_configuration !== null &&
                              <button
                                onClick={() => this.onCancelScheduleConfigurationOverwrite()}
                                className="personal-training__active-period__header__deactivate-button"
                              >

                                Restaurar cronograma

                              </button>
                            }

                          </div>

                          {this.getScheduleConfiguration()}

                          {this.state.activePeriod.note !== null ? (
                            <p className="personal-training__active-period__header__note">

                              {this.state.activePeriod.note}

                            </p>
                          ) : null}


                        </div>

                      </div>

                    </VerticalAccordionContainer>

                  </section>
                }

              </header>

              <ul className="personal-training__active-period__trainings-container">

                {this.getActiveTrainingDays()}

              </ul>

              {this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) &&
                <div className="personal-training__active-period__details">

                  <DefaultSubSectionTitle
                    icon={<i className="fas fa-chart-line"></i>}
                    text="Acompanhamento"
                  />

                  <div className="personal-training__active-period__details__indicators-container">

                    <div className="personal-training__active-period__details__indicators-wrapper">

                      <div className="personal-training__active-period__details__indicator">

                        <h2 className="personal-training__active-period__details__indicator__label">Duração do ciclo:</h2>
                        <p className="personal-training__active-period__details__indicator__value">{`${indicatorsData.periodReference} dias`}</p>

                      </div>

                      <div className="personal-training__active-period__details__indicator">

                        <h2 className="personal-training__active-period__details__indicator__label">Ciclo atual:</h2>
                        <p className="personal-training__active-period__details__indicator__value">{`${indicatorsData.currentCycle} / ${indicatorsData.cycleCount}`}</p>

                      </div>

                      <div className="personal-training__active-period__details__indicator">

                        <h2 className="personal-training__active-period__details__indicator__label">Treinos executados:</h2>
                        <p className="personal-training__active-period__details__indicator__value">{`${indicatorsData.trainingsExecuted} / ${indicatorsData.trainingsCountExpected}`}</p>

                      </div>

                      <div className="personal-training__active-period__details__indicator">

                        <h2 className="personal-training__active-period__details__indicator__label">Gasto calórico:</h2>
                        <p className="personal-training__active-period__details__indicator__value">{`${indicatorsData.energyExpended} / ${indicatorsData.expectedEnergyConsunption} kcal`}</p>

                      </div>

                    </div>

                    <div className="personal-training__active-period__details__progress-indicator">

                      <p className="personal-training__active-period__details__progress-indicator__text">

                        {indicatorsData.totalProgress.toFixed(0)}%

                      </p>

                    </div>

                  </div>

                  {(indicatorsData.currentCycle > 0 && indicatorsData.capacityGraphData.some((entry) => entry.data.length > 0)) &&
                    <HorizontalBarGraph
                      className="personal-training__active-period__details__graph"
                      title="Capacidades exercidas"
                      data={indicatorsData.capacityGraphData}
                      multipleGroups={true}
                      height={this.getGraphHeight()}
                      toolTipValueCallback={(value) => `${roundValue(value, 2)} grupo(s)`}
                    />
                  }

                  {indicatorsData.energyExpended > 0 &&
                    <StackedBarGraph
                      className="personal-training__active-period__details__graph"
                      title="Gasto calórico"
                      data={indicatorsData.energyConsumptionData}
                      height={this.getDefaultGraphHeight()}
                      legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                      doNotStack={true}
                      ToolTipValueCallback={(value) => `${roundValue(value, 2)} kcal`}
                      normalXLabel={true}
                      normalLegendOrder={true}
                    />
                  }

                </div>
              }

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {(this.state.studentStatus === STUDENT_ACTIVE_STATUS && this.props.userPermissionIds.includes(permissions.MANAGE_PERSONAL_TRAINING_PERIOD_PERMISSION)) &&
          <React.Fragment>

            <section className="personal-training__activate-period">

              <header
                className="personal-training__activate-period__header"
                onClick={() => this.setState({activatePeriodSectionVisible: !this.state.activatePeriodSectionVisible})}
              >

                <h3 className="personal-training__activate-period__header__text">
                  <i className="fas fa-check-square personal-training__activate-period__header__text-icon"></i>
                  Ativar periodização
                </h3>

                {this.state.activatePeriodSectionVisible ?
                  <i className="fas fa-chevron-down personal-training__activate-period__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training__activate-period__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training__activate-period__content"
                pose={this.state.activatePeriodSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container personal-training__activate-period__content-wrapper">

                  {this.state.reloadingAvailableTrainingPeriods ?
                    <PoseGroup>
                      <FadeContainer className="content-frame__loading-container" key="preloader">
                        <PreLoader local={true} />
                      </FadeContainer>
                    </PoseGroup>:
                    <React.Fragment>

                      <div className="personal-training__list-actions">

                        {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_PERMISSION_ID) &&
                          <Link
                            className="model-table__default-button"
                            to={`${routes.TRAINING_PERIOD_ADD_PATH}?student_id=${this.props.student.id}`}
                          >

                            <i className="fas fa-plus"></i> Adicionar nova periodização

                          </Link>
                        }

                      </div>

                      <div className="personal-training__filters">

                        <header className="personal-training__filters__header">

                          <h4 className="personal-training__filters__header__text">Filtros</h4>

                        </header>

                        <div className="personal-training__filters__inputs">

                          <div className="personal-training__filters__inputs-wrapper">

                            <HalfWrapper>

                              <DefaultInput
                                name="codeInput"
                                label="Código do serviço"
                                type="text"
                                placeholder="Código do serviço"
                                maxLength="32"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.codeInput || ''}
                                autoComplete="off"
                              />

                              <DefaultInput
                                name="targetServiceInput"
                                label="Serviço alvo"
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.targetServiceInput || ''}
                                options={this.getServiceOptions()}
                              />

                            </HalfWrapper>

                            <HalfWrapper>

                              <DefaultInput
                                name="targetGenderInput"
                                label="Gênero alvo"
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.suggestedPeriodsOnlyInput ? this.props.student.gender : (this.state.targetGenderInput || '')}
                                options={this.getGenderOptions()}
                                disabled={this.state.suggestedPeriodsOnlyInput}
                              />

                              <DefaultInput
                                name="mainObjectiveInput"
                                label="Objetivo do treinamento"
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.suggestedPeriodsOnlyInput ? this.getPhysicalEvaluationQuestionValue(1) : (this.state.mainObjectiveInput || '')}
                                options={this.getTrainingObjectiveOptions()}
                                disabled={this.state.suggestedPeriodsOnlyInput}
                              />

                            </HalfWrapper>

                            <HalfWrapper>

                              <DefaultInput
                                name="physicalLevelInput"
                                label="Nível físico sugerido"
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.suggestedPeriodsOnlyInput ? this.getPhysicalEvaluationQuestionValue(10) : (this.state.physicalLevelInput || '')}
                                options={this.getSuggestedPhysicalLevelOptions()}
                                disabled={this.state.suggestedPeriodsOnlyInput}
                              />

                              <DefaultInput
                                name="discProfileInput"
                                label="Perfil DISC sugerido"
                                labelMessage={this.state.suggestedPeriodsOnlyInput && this.getDiscClassification(true).length > 1 ? `DISC do aluno: ${this.getDiscClassification(true)}` : null}
                                type="select"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={(this.state.suggestedPeriodsOnlyInput && this.getDiscClassification(true).length === 1) ?  this.getDiscClassification(true) : (this.state.discProfileInput || '')}
                                options={this.getSuggestedDiscProfileOptions()}
                                disabled={this.state.suggestedPeriodsOnlyInput && this.getDiscClassification(true).length === 1}
                              />

                            </HalfWrapper>

                            {this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) &&
                              <DefaultInput
                                name="suggestedPeriodsOnlyInput"
                                isHighlighted={false}
                                label="Recomendadas apenas:"
                                type="toggle"
                                isHorizontal={true}
                                activeText="Sim"
                                inactiveText="Não"
                                handleInputChange={(event) => this.handleInputChange(event)}
                                value={this.state.suggestedPeriodsOnlyInput}
                              />
                            }

                          </div>

                          <button
                            className="personal-training__filters__refresh-button"
                            onClick={() => this.refreshList()}
                            disabled={!this.mayRefreshList()}
                          >

                            <i className="fas fa-sync"></i>

                          </button>

                        </div>

                      </div>

                      <ModelTable
                        storageKey="available_periods"
                        properties={this.getAvailablePeriodProperties()}
                        getActions={(entry) => this.getAvailablePeriodActions(entry)}
                        getRowClassName={(entry) => (entry.editable && entry.user_id !== null) ? 'personal-training__training-perdiod-row' : ''}
                        data={this.state.trainingPeriods}
                        initialOrderBy="code"
                      >
                      </ModelTable>

                    </React.Fragment>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_HISTORY_PERMISSION) &&
          <React.Fragment>

            <section className="personal-training__activate-period">

              <header
                className="personal-training__activate-period__header"
                onClick={() => this.setState({personalTrainingHistorySectionVisible: !this.state.personalTrainingHistorySectionVisible})}
              >

                <h3 className="personal-training__activate-period__header__text">
                  <i className="fas fa-history personal-training__activate-period__header__text-icon"></i>
                  Periodizações anteriores
                </h3>

                {this.state.personalTrainingHistorySectionVisible ?
                  <i className="fas fa-chevron-down personal-training__activate-period__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up personal-training__activate-period__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container personal-training__activate-period__content"
                pose={this.state.personalTrainingHistorySectionVisible ? 'verticalOpen' : 'verticalClosed'}>

                <div className="vertical-accordion-container personal-training__activate-period__content-wrapper">

                  {this.state.loadingData ?
                    <PoseGroup>
                      <FadeContainer className="content-frame__loading-container" key="preloader">
                        <PreLoader local={true} />
                      </FadeContainer>
                    </PoseGroup>:
                    <ModelTable
                      storageKey="training_history"
                      properties={this.getPersonalTrainingHistoryProperties()}
                      getActions={this.props.userPermissionIds.includes(permissions.DELETE_PERSONAL_TRAINING_HISTORY_PERMISSION) ? (entry) => this.getPersonalTrainingHistoryActions(entry) : null}
                      data={this.state.persontal_training_history}
                      initialOrderBy="end_date"
                      initialOrderIsDecrescent={true}
                    >
                    </ModelTable>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

            <HorizontalRule />

          </React.Fragment>
        }

        {this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) &&
          <React.Fragment>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-history"></i>}
              text={this.state.activePeriod === null ? 'Histórico' : 'Histórico recente'}
            />

            <ModelTable
              storageKey="training_data"
              properties={this.getPersonalTrainingDataProperties()}
              getActions={(entry) => this.getPersonalTrainingDataActions(entry)}
              data={this.state.personalTrainingData}
              getIdCallback={(row) => {
                if (row.is_custom_training) {
                  return `custom_training:${row.id}`;
                }
                else if (row.is_personal_training) {
                  return `personal_training:${row.id}`;
                }

                return `training_class:${row.id}`
              }}
              initialOrderBy="date"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>


          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default PersonalTraining;
