import React from 'react';
import './loading_icon.scss';

import loadingCircleOuter from '../images/loading_circle_outer.svg';
import loadingCircleInner from '../images/loading_circle_inner.svg';

function LoadingIcon(props) {
  return (
    <div className="loading-icon">

      <p className="loading-icon__text">{props.text || 'Carregando'}</p>

      <img
        className="loading-icon__icon-2"
        src={loadingCircleInner}
        alt=""
      />
      <img
        className="loading-icon__icon-1"
        src={loadingCircleOuter}
        alt=""
      />

    </div>
  );
}

export default LoadingIcon;
