import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel} from '../../utils/functions';
import ServiceGroupData from './service_group_data';

class ServiceGroupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service_group: {
        name: "",
        color: "",
        description: "",
        order: 1,
        is_active: true
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let service_group = getModel(`${routes.SERVICE_GROUP_GET_API}${this.props.match.params.serviceGroupId}`);

    service_group = await service_group;

    if(service_group) {
      update.service_group = {...this.state.service_group, ...service_group};

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.SERVICE_GROUP_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.service_group, [name]: value};

    this.setState({
      service_group: newData
    });
  }

  inputsAreValid() {
    return this.state.service_group.name.length > 0 &&
           this.state.service_group.color.length > 0 &&
           this.state.service_group.order > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.service_group};
    data.order = parseInt(data.order);

    try {
      await patchModel(`${routes.SERVICE_GROUP_PATCH_API}${this.props.match.params.serviceGroupId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('value');
            //
            //         break;
            //       case 'penalty_value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('penalty_value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.SERVICE_GROUP_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.SERVICE_GROUP_LIST_PATH,
            text: "Listar agrupamentos"
          },
          {
            path: `${routes.SERVICE_GROUP_EDIT_PATH}${this.props.match.params.serviceGroupId}`,
            text: "Editar agrupamento"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar grupo de serviço"
        loading={this.state.loading}
      >

        <ServiceGroupData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          service_group={this.state.service_group}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.SERVICE_GROUP_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default ServiceGroupEdit;
