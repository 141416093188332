import React from 'react';
import { Link } from 'react-router-dom';
import './financial_category_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {BOTH_TYPE_NAME} from '../../constants';

class FinancialCategoryData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getCostCenterOptions() {
    if(this.isEditMode()) {
      if(!this.props.financial_category.cost_center_id) {
        return [
          SelectOption('', 'Nenhum centro de custo vinculado')
        ];
      }

      return [
        SelectOption('', 'Desvincular centro de custo'),
        SelectOption(this.props.financial_category.cost_center_id, this.props.financial_category.cost_center_name),
      ];
    }

    const roots = this.props.costCenters.filter((entry) => entry.depth === 0);

    roots.sort((a, b) => a.name.localeCompare(b.name));

    return [
      SelectOption('', 'Não vinculado'),
      ...roots.flatMap((node) => this.getNodeOptions(node))
    ];
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!this.isEditMode() || node.id !== this.props.financial_category.id) {
      if(!filterCallback || filterCallback(node)) {
        options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
      }

      node.children.sort((a, b) => a.name.localeCompare(b.name));

      for(let child of node.children) {
        if(!filterCallback || filterCallback(child)) {
          options.push(...this.getNodeOptions(child));
        }
      }
    }

    return options;
  }

  getParentOptions() {
    const roots = this.props.financialCategories.filter((entry) => entry.depth === 0);

    roots.sort((a, b) => a.name.localeCompare(b.name));

    return [
      SelectOption('', '-'),
      ...roots.flatMap((node) => this.getNodeOptions(node, (category) => (
        this.props.financial_category.type === BOTH_TYPE_NAME || (this.props.financial_category.type === category.type)
      )))
    ];
  }

  isEditMode() {
    return this.props.financial_category.id && this.props.financial_category.id > 0;
  }

  getCostCenterValue() {
    if(this.isEditMode()) {
      if(this.props.financial_category.remove_cost_center) {
        return '';
      }
    }

    return this.props.financial_category.cost_center_id || '';
  }

  render() {
    return (
      <DefaultSection
        className="financial-category-data"
        title="Dados da categoria financeira"
      >

        <div className="financial-category-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="financial-category-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome da categoria"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_category.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="parent_id"
              isHighlighted={this.isHighlighted("parent_id")}
              label="Pertence a"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_category.parent_id || ''}
              options={this.getParentOptions()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="cost_center_id"
              isHighlighted={this.isHighlighted("cost_center_id")}
              label="Centro de custo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.getCostCenterValue()}
              options={this.getCostCenterOptions()}
              disabled={this.isEditMode() && !this.props.financial_category.cost_center_id}
            />

            <DefaultInput
              name="type"
              isHighlighted={this.isHighlighted("type")}
              label="Tipo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.financial_category.type}
              options={[
                SelectOption('Ambos'),
                SelectOption('Gasto'),
                SelectOption('Receita'),
              ]}
              disabled={this.isEditMode()}
            />

          </HalfWrapper>

          <DefaultInput
            className="financial-category-data__color-input"
            name="color"
            isHighlighted={this.isHighlighted("color")}
            label="Cor:"
            type="color"
            isHorizontal={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.financial_category.color}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da categoria"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.financial_category.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="financial-category-data__buttons-container">

          <button
            className="financial-category-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="financial-category-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default FinancialCategoryData;
