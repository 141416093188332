import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {getModel, patchModel} from '../../../utils/functions';
import CustomerFeedbackTargetData from './customer_feedback_target_data';

class CustomerFeedbackTargetEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer_feedback_target: {
        name: "",
        description: "",
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let customer_feedback_target = getModel(`${routes.CUSTOMER_FEEDBACK_TARGET_GET_API}${this.props.match.params.customerFeedbackTargetId}`);

    customer_feedback_target = await customer_feedback_target;

    if(customer_feedback_target) {
      update.customer_feedback_target = {...this.state.customer_feedback_target, ...customer_feedback_target};

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.customer_feedback_target, [name]: value};

    this.setState({
      customer_feedback_target: newData
    });
  }

  inputsAreValid() {
    return this.state.customer_feedback_target.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.customer_feedback_target}

    try {
      await patchModel(`${routes.CUSTOMER_FEEDBACK_TARGET_PATCH_API}${this.props.match.params.customerFeedbackTargetId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH,
            text: "Listar assuntos"
          },
          {
            path: `${routes.CUSTOMER_FEEDBACK_TARGET_EDIT_PATH}${this.props.match.params.customerFeedbackTargetId}`,
            text: "Editar assunto"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar assunto de feedback"
        loading={this.state.loading}
      >

        <CustomerFeedbackTargetData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          customer_feedback_target={this.state.customer_feedback_target}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.CUSTOMER_FEEDBACK_TARGET_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default CustomerFeedbackTargetEdit;
