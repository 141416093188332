import React from 'react';
import './scale_picker.scss';

class ScalePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  render() {
    return (
      <React.Fragment>

        <ul className="scale-picker">

          <li className="scale-picker__group">

            <div className="scale-picker__group__image--3">

              <i className="fas fa-dizzy scale-picker__group__image-icon"></i>

              <p className="scale-picker__group__image__text">
                Expectativa de diminuição no desempenho
              </p>

            </div>

            <div className="scale-picker__group__options">

              <button
                className="scale-picker__group__option--1"
                onClick={() => this.props.onSetValue(1)}
                disabled={this.props.value === 1}
              >

                <p className="scale-picker__group__option-number">
                  1
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Extremo cansaço
                </p>

              </button>

              <button
                className="scale-picker__group__option--2"
                onClick={() => this.props.onSetValue(2)}
                disabled={this.props.value === 2}
              >

                <p className="scale-picker__group__option-number">
                  2
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Cansado
                </p>

              </button>

              <button
                className="scale-picker__group__option--3"
                onClick={() => this.props.onSetValue(3)}
                disabled={this.props.value === 3}
              >

                <p className="scale-picker__group__option-number">
                  3
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Um pouco cansado
                </p>

              </button>

            </div>

          </li>

          <li className="scale-picker__group">

            <div className="scale-picker__group__image--7">

              <i className="fas fa-meh scale-picker__group__image-icon"></i>

              <p className="scale-picker__group__image__text">
                Expectativa de similaridade no desempenho
              </p>

            </div>

            <div className="scale-picker__group__options">

              <button
                className="scale-picker__group__option--4"
                onClick={() => this.props.onSetValue(4)}
                disabled={this.props.value === 4}
              >

                <p className="scale-picker__group__option-number">
                  4
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Pouco recuperado
                </p>

              </button>

              <button
                className="scale-picker__group__option--5"
                onClick={() => this.props.onSetValue(5)}
                disabled={this.props.value === 5}
              >

                <p className="scale-picker__group__option-number">
                  5
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Em recuperação
                </p>

              </button>

              <button
                className="scale-picker__group__option--6"
                onClick={() => this.props.onSetValue(6)}
                disabled={this.props.value === 6}
              >

                <p className="scale-picker__group__option-number">
                  6
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Quase recuperado
                </p>

              </button>

              <button
                className="scale-picker__group__option--7"
                onClick={() => this.props.onSetValue(7)}
                disabled={this.props.value === 7}
              >

                <p className="scale-picker__group__option-number">
                  7
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Recuperado
                </p>

              </button>

            </div>

          </li>

          <li className="scale-picker__group">

            <div className="scale-picker__group__image--10">

              <i className="fas fa-laugh-beam scale-picker__group__image-icon"></i>

              <p className="scale-picker__group__image__text">
                Expectativa de um bom desempenho
              </p>

            </div>

            <div className="scale-picker__group__options">

              <button
                className="scale-picker__group__option--8"
                onClick={() => this.props.onSetValue(8)}
                disabled={this.props.value === 8}
              >

                <p className="scale-picker__group__option-number">
                  8
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Bem recuperado
                </p>

              </button>

              <button
                className="scale-picker__group__option--9"
                onClick={() => this.props.onSetValue(9)}
                disabled={this.props.value === 9}
              >

                <p className="scale-picker__group__option-number">
                  9
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Muito bem recuperado
                </p>

              </button>

              <button
                className="scale-picker__group__option--10"
                onClick={() => this.props.onSetValue(10)}
                disabled={this.props.value === 10}
              >

                <p className="scale-picker__group__option-number">
                  10
                </p>

                <p className="scale-picker__group__option-text--flex">
                  Altamente energético
                </p>

              </button>

            </div>

          </li>

        </ul>

        {this.props.note &&
          <p className="scale-picker__small-note">

            {this.props.note}

          </p>
        }



      </React.Fragment>
    );
  }
}

export default ScalePicker;
