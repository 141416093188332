import React from 'react';
import './financial_general_report.scss';
import * as routes from '../../constants';
import {DEFAULT_UNIT_TYPE} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getAsLocalDate, getCurrencyText, getLocalDateIsoString, setUrlParameters} from '../../utils/functions';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import FinancialNetGraph, {FinancialNetPoint} from '../../graphs/financial_net_graph';
import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import ContextPopup from '../../components/context_popup';
import Stacked100BarGraph, {Stack100Group, Stack100Point} from '../../graphs/stacked_100_bar_graph';
import StackedBarGraph, {StackGroup, LineGroup, StackPoint} from '../../graphs/stacked_bar_graph';

const TICKET_BAR_PALLET = [
  '#9a9a9a',
  '#d2915a',
  '#3fb68e',
  '#6973f6',
  '#cd59b1',
  '#ea6767',
  '#5ec2d2',
  '#edf570',
  '#78bb67',
];

const TICKET_LINE_PALLET = [
  '#ca5353c4',
  '#0e8c62',
  '#8746ce',
  '#a92689',
  '#c52f2f',
  '#1e92a5',
  '#bbc517',
  '#499e33',
];


class FinancialGeneralReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let initialDate = queryParameters.get('initial_date');
    let finalDate = queryParameters.get('final_date');

    if(!initialDate) {
      initialDate = new Date();
      initialDate.setDate(1);
      initialDate.setMonth(initialDate.getMonth() - 6);
      initialDate = getLocalDateIsoString(initialDate);
    }
    if(!finalDate) {
      finalDate = new Date();
      finalDate.setMonth(finalDate.getMonth() + 7);
      finalDate.setDate(0);
      finalDate = getLocalDateIsoString(finalDate);
    }

    this.state = {
      initialDateInput: initialDate,
      finalDateInput: finalDate,
      initialDate: initialDate,
      finalDate: finalDate,
      financialCategories: [],
      costCenters: [],
      transactions: [],
      contracts: [],
      services: [],
      uniqueStudentFlux: [],
      loadingData: true,
      screenWidth: window.innerWidth,
      graphContainerWidth: null,
      includeProjection: false,
      costCenterFilterVisible: false,
      financialCategoryFilterVisible: false,
      costCenterBranchMap: new Map(),
      costCenterBranchActiveDepthMap: new Map(),
      financialCategoryBranchMap: new Map(),
      financialCategoryBranchActiveDepthMap: new Map(),
      popupContent: null,
      popupTarget: null,
    };
  }

  async getContracs() {
    return await getModels(`${routes.CONTRACTS_GET_API_V2}?initial_reference_date=${this.state.initialDate}&final_reference_date=${this.state.finalDate}&load_additional_data=true&load_payments=true&calculate_target_service=true`);
  }

  async getTransactions() {
    return await getModels(`${routes.FINANCIAL_TRANSACTIONS_GET_API}?initial_date=${this.state.initialDate}&final_date=${this.state.finalDate}&load_additional_info=true`);
  }

  async getServices() {
    return await getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
  }

  async getUniqueStudentFlux() {
    return await getModels(`${routes.UNIQUE_STUDENT_FLUX_GET_API}?initial_date_reference=${this.state.initialDate.slice(0, 7)}&final_date_reference=${this.state.finalDate.slice(0, 7)}`);
  }

  async refreshData(setLoading=true) {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    let transactions = this.getTransactions();
    let contracts = this.getContracs();
    let services = this.getServices();
    let uniqueStudentFlux = this.getUniqueStudentFlux();

    const update = {}

    if(setLoading) {
      update.loadingData = false;
    }

    transactions = await transactions;

    if(transactions) {
      update.transactions = transactions;
    }

    contracts = await contracts;

    if(contracts) {
      update.contracts = contracts;
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    uniqueStudentFlux = await uniqueStudentFlux;

    if(uniqueStudentFlux) {
      update.uniqueStudentFlux = uniqueStudentFlux;
    }

    this.setState(update);
  }

  parseBranch(node, nodeBranchMap, nodeBranchActiveDepthMap) {
    let branchId;

    if(node.parent_id === null) {
      branchId = node.id;
    }
    else {
      branchId = nodeBranchMap.get(node.parent_id).branchId;
    }

    nodeBranchMap.set(node.id, {
      node,
      branchId,
      visible: true
    });

    if(node.depth === 0) {
      nodeBranchActiveDepthMap.set(branchId, {activeDepth: 0, maxDepth: 0});
    }
    else {
      const depthData = nodeBranchActiveDepthMap.get(branchId);
      depthData.maxDepth = Math.max(depthData.maxDepth, node.depth);
    }

    node.children.forEach((child) => this.parseBranch(child, nodeBranchMap, nodeBranchActiveDepthMap));
  }

  async componentDidMount() {
    const update = {loadingData: false};

    let financialCategories = getModels(routes.FINANCIAL_CATEGORIES_GET_API);
    let costCenters = getModels(routes.COST_CENTERS_GET_API);

    let dataRefresh = this.refreshData(false);

    financialCategories = await financialCategories;

    if(financialCategories) {
      update.financialCategories = financialCategories;

      financialCategories.filter((entry) => entry.depth === 0).forEach((node) => this.parseBranch(node, this.state.financialCategoryBranchMap, this.state.financialCategoryBranchActiveDepthMap));
    }

    costCenters = await costCenters;

    if(costCenters) {
      update.costCenters = costCenters;

      costCenters.filter((entry) => entry.depth === 0).forEach((node) => this.parseBranch(node, this.state.costCenterBranchMap, this.state.costCenterBranchActiveDepthMap));
    }

    await dataRefresh;

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.FINANCIAL_REPORT_PATH, {
        initial_date: this.state.initialDateInput,
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  getTransactionClassification(transaction) {
    const costCenterNodeData = this.state.costCenterBranchMap.get(transaction.cost_center_id);

    if(!costCenterNodeData) {
      return null;
    }

    const costCenterDepthData = this.state.costCenterBranchActiveDepthMap.get(costCenterNodeData.branchId);

    let currentCostCenterNode = costCenterNodeData.node;
    let costCenterName = null;
    let costCenterColor = null;

    while(currentCostCenterNode.parent_id !== null) {
      if(!this.state.costCenterBranchMap.get(currentCostCenterNode.id).visible) {
        return null;
      }

      if(currentCostCenterNode.depth === costCenterDepthData.activeDepth) {
        costCenterName = currentCostCenterNode.name;
        costCenterColor = currentCostCenterNode.color;
      }

      currentCostCenterNode = this.state.costCenterBranchMap.get(currentCostCenterNode.parent_id).node;
    }

    if(!this.state.costCenterBranchMap.get(currentCostCenterNode.id).visible) {
      return null;
    }
    if(costCenterName === null) {
      if(currentCostCenterNode.depth === costCenterDepthData.activeDepth) {
        costCenterName = currentCostCenterNode.name;
        costCenterColor = currentCostCenterNode.color;
      }
      else {
        if(costCenterNodeData.node.children.length > 0) {
          costCenterName = `${costCenterNodeData.node.name} - Outros`;
        }
        else {
          costCenterName = costCenterNodeData.node.name;
        }

        costCenterColor = costCenterNodeData.node.color;
      }
    }

    const categoryNodeData = this.state.financialCategoryBranchMap.get(transaction.financial_category_id);

    if(!categoryNodeData) {
      return null;
    }

    const categoryDepthData = this.state.financialCategoryBranchActiveDepthMap.get(categoryNodeData.branchId);

    let currentCategoryNode = categoryNodeData.node;
    let categoryName = null;
    let categoryColor = null;

    while(currentCategoryNode.parent_id !== null) {
      if(!this.state.financialCategoryBranchMap.get(currentCategoryNode.id).visible) {
        return null;
      }

      if(currentCategoryNode.depth === categoryDepthData.activeDepth) {
        categoryName = currentCategoryNode.name;
        categoryColor = currentCategoryNode.color;
      }

      currentCategoryNode = this.state.financialCategoryBranchMap.get(currentCategoryNode.parent_id).node;
    }

    if(!this.state.financialCategoryBranchMap.get(currentCategoryNode.id).visible) {
      return null;
    }
    if(categoryName === null) {
      if(currentCategoryNode.depth === categoryDepthData.activeDepth) {
        categoryName = currentCategoryNode.name;
        categoryColor = currentCategoryNode.color;
      }
      else {
        if(categoryNodeData.node.children.length > 0) {
          categoryName = `${categoryNodeData.node.name} - Outros`;
        }
        else {
          categoryName = categoryNodeData.node.name;
        }
        categoryColor = categoryNodeData.node.color;
      }
    }

    return {
      costCenterName,
      costCenterColor,
      categoryName,
      categoryColor,
    };
  }

  getFinancialData() {
    if(this.state.initialDate > this.state.finalDate) {
      return [];
    }

    const filteredTransactions = [];

    for(const transaction of this.state.transactions) {
      const classification = this.getTransactionClassification(transaction);

      if(classification !== null) {
        filteredTransactions.push({
          ...transaction,
          cost_center_name: classification.costCenterName,
          cost_center_color: classification.costCenterColor,
          financial_category_name: classification.categoryName,
          financial_category_color: classification.categoryColor,
        });
      }
    }

    const totalIncome = filteredTransactions.reduce((sum, transaction) => sum + ((!transaction.is_expense && (transaction.completed || (!transaction.is_canceled && this.state.includeProjection))) ? transaction.value : 0), 0);

    const initialMonthMap = new Map();
    const monthMap = new Map ();
    let periodResult = 0;

    const monthList = [];

    const date = getAsLocalDate(this.state.initialDate);
    date.setDate(1);
    const finalDate = getAsLocalDate(this.state.finalDate);

    const finalYear = finalDate.getFullYear();
    const finalMonth = finalDate.getMonth();

    let year = date.getFullYear();
    let month = date.getMonth();

    const monthFormat = new Intl.DateTimeFormat('pt-BR', {month: 'long'});

    while(year < finalYear || (year === finalYear && month <= finalMonth)) {
      const isoDate = getLocalDateIsoString(date).slice(0, 7);
      monthMap.set(isoDate, FinancialNetPoint(0, 0, 0, month, year));
      initialMonthMap.set(isoDate, `${monthFormat.format(date)} ${year}`);
      monthList.push(isoDate);

      date.setMonth(date.getMonth() + 1);
      month = date.getMonth();
      year = date.getFullYear();
    }

    const totalCostCenterMap = new Map();
    const totalCategoryMap = new Map();

    const costCenterMap = new Map();
    const categoryMap = new Map();

    for(let transaction of filteredTransactions) {
      if(transaction.effective_date < this.state.initialDate || transaction.effective_date > this.state.finalDate) {
        continue;
      }

      if(transaction.is_canceled && !transaction.completed) {
        continue;
      }

      const monthData = monthMap.get(transaction.effective_date.slice(0, 7));

      let costCenterPoint;
      let categoryPoint;

      let costCenterGroup;
      let categoryGroup;

      if(transaction.is_expense) {
        monthData.projection -= transaction.value;
      }
      else {
        monthData.projection += transaction.value;
      }

      if(transaction.completed || this.state.includeProjection) {
        if(transaction.is_expense) {
          monthData.expense += transaction.value;
          periodResult -= transaction.value;
        }
        else {
          monthData.income += transaction.value;
          periodResult += transaction.value;
        }

        if(!transaction.is_expense) {
          if(!totalCostCenterMap.has(transaction.cost_center_name)) {
            costCenterPoint = PiePoint(0, totalIncome, transaction.cost_center_name, transaction.cost_center_color);
            totalCostCenterMap.set(transaction.cost_center_name , costCenterPoint);
          }
          else {
            costCenterPoint = totalCostCenterMap.get(transaction.cost_center_name);
          }

          if(!totalCategoryMap.has(transaction.financial_category_name)) {
            categoryPoint = PiePoint(0, totalIncome, transaction.financial_category_name, transaction.financial_category_color);
            totalCategoryMap.set(transaction.financial_category_name , categoryPoint);
          }
          else {
            categoryPoint = totalCategoryMap.get(transaction.financial_category_name);
          }

          costCenterPoint.value += transaction.value;
          categoryPoint.value += transaction.value;
        }
        else {
          if(!costCenterMap.has(transaction.cost_center_name)) {
            costCenterGroup = Stack100Group(transaction.cost_center_name, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, Stack100Point(0, value)])), true, transaction.cost_center_color);
            costCenterMap.set(transaction.cost_center_name , costCenterGroup);
          }
          else {
            costCenterGroup = costCenterMap.get(transaction.cost_center_name);
          }

          if(!categoryMap.has(transaction.financial_category_name)) {
            categoryGroup = Stack100Group(transaction.financial_category_name, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, Stack100Point(0, value)])), true, transaction.financial_category_color);
            categoryMap.set(transaction.financial_category_name , categoryGroup);
          }
          else {
            categoryGroup = categoryMap.get(transaction.financial_category_name);
          }

          costCenterGroup.points.get(transaction.effective_date.slice(0, 7)).value += transaction.value;
          categoryGroup.points.get(transaction.effective_date.slice(0, 7)).value += transaction.value;
        }
      }
    }

    const perCostCenterData = [...costCenterMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });
    const perCategoryData = [...categoryMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const incomeContractMap = new Map([
      ['Renovações', StackGroup('Renovações', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#48a6e6', 'Venda total')],
      ['Novos contratos', StackGroup('Novos contratos', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#58a74d', 'Venda total')],
      ['Não aceitos', StackGroup('Não aceitos', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#9a9a9a', null, false)],
    ]);

    const outcomeContractMap = new Map([
      [true, LineGroup('Cancelamentos', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#612c9cc4', 'Vencimentos e cancelamentos', 'dot', false)],
      [false, LineGroup('Vencimentos', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#ca5353c4', 'Vencimentos e cancelamentos')],
    ]);

    const adjustedContractResult = LineGroup('Total corrigido', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#adadadc4', 'Total corrigido', 'solid', false, false)

    const serviceMap = new Map();

    const sellTicketMap = new Map([
      ['Total real', StackGroup('Receita média real', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(monthMap.get(key).income, value, 0)])), TICKET_BAR_PALLET[0], null, false)],
      ['Total', StackGroup('Receita média total', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), TICKET_BAR_PALLET[1])],
      ['Despesa total real', StackGroup('Despesa média real', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(monthMap.get(key).expense, value, 0)])), '#e36f95')]
    ]);
    const activeUsers = new Map([
      ['Total', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, new Set()]))]
    ]);
    const activeUsersMap = new Map([
      ['Total', LineGroup('Alunos totais', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), TICKET_LINE_PALLET[0], null, 'solid', true, false)]
    ]);

    for(let contract of this.state.contracts) {
      const effectiveDate = contract.effective_date.slice(0, 7);

      const formattedEffectiveDate = contract.effective_date.slice(0, 10);
      const formattedExpiresAt = contract.expires_at.slice(0, 10);
      const formattedCanceledAt = contract.canceled_at ? contract.canceled_at.slice(0, 10) : '';

      const calculatedTargetService = contract.target_service || 'Outros';

      if(!contract.accepted) {
        if(formattedEffectiveDate >= this.state.initialDate && formattedEffectiveDate <= this.state.finalDate) {
          const incomeContractGroup = incomeContractMap.get('Não aceitos');
          const point = incomeContractGroup.points.get(effectiveDate);
          point.value += contract.total_value;
          point.index += 1;
        }

        continue;
      }

      if(formattedEffectiveDate >= this.state.initialDate && formattedEffectiveDate <= this.state.finalDate) {
        const incomeContractGroup = incomeContractMap.get(contract.is_renew ? 'Renovações' : 'Novos contratos');
        const point = incomeContractGroup.points.get(effectiveDate);
        point.value += contract.total_value;
        point.index += 1;

        adjustedContractResult.points.get(effectiveDate).value += contract.total_value;

        let serviceGroup;

        if(this.isDefaultUnit()) {
          if(!serviceMap.has(calculatedTargetService)) {
            serviceGroup = Stack100Group(calculatedTargetService, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, Stack100Point(0, value, 0)])), true);
            serviceMap.set(calculatedTargetService, serviceGroup);
          }
          else {
            serviceGroup = serviceMap.get(calculatedTargetService);
          }
        }
        else {
          if(!serviceMap.has(contract.service_plan.name)) {
            serviceGroup = Stack100Group(contract.service_plan.name, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, Stack100Point(0, value, 0)])), true);
            serviceMap.set(contract.service_plan.name, serviceGroup);
          }
          else {
            serviceGroup = serviceMap.get(contract.service_plan.name);
          }
        }

        const servicePoint = serviceGroup.points.get(effectiveDate);
        servicePoint.value += contract.total_value;
        servicePoint.index += 1;
      }

      const contractTicket = contract.total_value / contract.period; // 30 *

      let services = ['Total'];
      // let targetService = 'Cross FYD';

      if(contract.restriction_map) {
        for(const service of this.state.services) {
          if(contract.restriction_map[service] && (contract.restriction_map[service].enable_all || contract.restriction_map[service].time_ids.length)) {
            services.push(service);
          }
        }

        if(this.isDefaultUnit() && services.length <= 1) {
          services.push('Outros');
          // services.push('Indefinido');
        }

        // if(services.length === 1) {
        //   targetService = services[0];
        // }
        // else if(services.length > 1) {
        //   targetService = `Combo (${services.join(' + ')})`;
        // }
        // else {
        //   targetService = 'Indefinido';
        // }
      }
      else {
        services.push('Cross FYD');
      }

      for(const isoDate of monthList) {
        const expiresAtDate = getAsLocalDate(contract.canceled ? formattedCanceledAt : formattedExpiresAt);
        const contracEffectiveDate = getAsLocalDate(formattedEffectiveDate);

        const currentYear = parseInt(isoDate.slice(0, 4));
        const currentMonth = parseInt(isoDate.slice(5, 7)) - 1;

        const firstMonthDate = new Date(currentYear, currentMonth, 1);
        const lastMonthDate = new Date(currentYear, currentMonth + 1, 0);

        const initialInputDate = getAsLocalDate(this.state.initialDate);
        const finalInputDate = getAsLocalDate(this.state.finalDate);

        if(isoDate >= effectiveDate && isoDate <= contract.expires_at.slice(0, 7)) {
          if(contract.canceled && isoDate > contract.canceled_at.slice(0, 7)) {
            continue;
          }

          let serviceTicketGroup;

          for(const targetService of services) {
            if(!sellTicketMap.has(targetService)) {
              serviceTicketGroup = StackGroup(targetService, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), TICKET_BAR_PALLET[sellTicketMap.size], null, false);
              sellTicketMap.set(targetService, serviceTicketGroup);
            }
            else {
              serviceTicketGroup = sellTicketMap.get(targetService);
            }

            let initialReferenceDate = contracEffectiveDate > firstMonthDate ? contracEffectiveDate : firstMonthDate;
            initialReferenceDate = initialInputDate > initialReferenceDate ? initialInputDate : initialReferenceDate;

            let lastReferenceDate = expiresAtDate > lastMonthDate ? lastMonthDate : expiresAtDate;
            lastReferenceDate = finalInputDate > lastReferenceDate ? lastReferenceDate : finalInputDate;

            const timeDiff = Math.abs(lastReferenceDate.getTime() - initialReferenceDate.getTime());
            let activeDaysCount = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

            let serviceTicketPoint = serviceTicketGroup.points.get(isoDate);
            serviceTicketPoint.value += activeDaysCount * contractTicket;
          }

          let activeUsersGroup;

          for(const targetService of services) {
            if(!activeUsers.has(targetService)) {
              activeUsersMap.set(targetService, LineGroup(targetService, new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), TICKET_LINE_PALLET[activeUsers.size], null, 'dot', false, false));

              activeUsersGroup = new Map([...initialMonthMap.entries()].map(([key, value]) => [key, new Set()]));
              activeUsers.set(targetService, activeUsersGroup);
            }
            else {
              activeUsersGroup = activeUsers.get(targetService);
            }

            let monthlyActiveUsers = activeUsersGroup.get(isoDate);
            monthlyActiveUsers.add(contract.user_id);
          }
        }
      }

      if(formattedCanceledAt >= this.state.initialDate && formattedCanceledAt <= this.state.finalDate) {
        const outcomeContractGroup = outcomeContractMap.get(true);
        const point = outcomeContractGroup.points.get(contract.canceled_at.slice(0, 7));
        const remainingValue = contract.payments.reduce((sum, entry) => sum + entry.transactions.reduce((sum, transaction) => sum + (!transaction.completed ? transaction.value : 0), 0), 0);
        point.value += remainingValue;
        point.index += 1;

        adjustedContractResult.points.get(contract.canceled_at.slice(0, 7)).value -= remainingValue;

        if(!point.canceled) {
          point.canceled = 0;
        }

        point.canceled += remainingValue;
      }
      else if(!contract.canceled && formattedExpiresAt >= this.state.initialDate && formattedExpiresAt <= this.state.finalDate) {
        const outcomeContractGroup = outcomeContractMap.get(false);
        const point = outcomeContractGroup.points.get(contract.expires_at.slice(0, 7));
        point.value += contract.total_value;
        point.index += 1;

        if(!point.expired) {
          point.expired = 0;
        }

        point.expired += contract.total_value
      }
    }

    const uniqueStudentFluxMap = [
      StackGroup('Entrada', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#58a74d'),
      StackGroup('Saída', new Map([...initialMonthMap.entries()].map(([key, value]) => [key, StackPoint(0, value, 0)])), '#ca5353')
    ];

    for(const entry of this.state.uniqueStudentFlux) {
      if(!uniqueStudentFluxMap[0].points.has(entry.date)) {
        continue;
      }

      let studentIncomePoint = uniqueStudentFluxMap[0].points.get(entry.date);
      studentIncomePoint.value += entry.income;
      studentIncomePoint.index += entry.income;
      let studentOutcomePoint = uniqueStudentFluxMap[1].points.get(entry.date);
      studentOutcomePoint.value += entry.outcome;
      studentOutcomePoint.index += entry.outcome;
    }

    const incomeContractData = [...incomeContractMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const outcomeContractData = [
      adjustedContractResult,
      ...outcomeContractMap.values()
    ].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const perServiceData = [...serviceMap.values()].map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    activeUsers.set('Total real', activeUsers.get('Total'));
    activeUsers.set('Despesa total real', activeUsers.get('Total'));

    const ticketData = [...sellTicketMap.entries()].map(([groupKey, group]) => {
      const activeUsersGroup = activeUsers.get(groupKey);

      group.points = [...group.points.entries()].map(([key, value]) => {
        const activeCount = activeUsersGroup.get(key).size;

        if(activeCount > 0) {
          value.value = value.value / activeCount;
        }

        return value;
      });

      return group;
    });

    const uniqueStudentFluxData = uniqueStudentFluxMap.map((group) => {
      group.points = [...group.points.values()];
      return group;
    });

    const activeUsersData = [...activeUsersMap.entries()].map(([groupKey, group]) => {
      const activeUsersGroup = activeUsers.get(groupKey);

      group.points = [...group.points.entries()].map(([key, value]) => {
        value.value = activeUsersGroup.get(key).size;
        value.index = value.value;

        return value;
      });

      return group;
    });

    return {
      netData: [...monthMap.values()],
      periodResult,
      totalPerCostCenterData: [...totalCostCenterMap.values()],
      totalPerCategoryData: [...totalCategoryMap.values()],
      perCostCenterData: perCostCenterData,
      perCategoryData: perCategoryData,
      incomeContractData: incomeContractData,
      outcomeContractData: outcomeContractData,
      perServiceData: perServiceData,
      ticketData: ticketData,
      activeUsersData: activeUsersData,
      uniqueStudentFluxData
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPerClassificationGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getNoteVisibleState(node, nodeBranchMap) {
    let currentNode = node;

    while(currentNode.parent_id !== null) {
      if(!nodeBranchMap.get(currentNode.id).visible) {
        return false;
      }

      currentNode = nodeBranchMap.get(currentNode.parent_id).node;
    }

    return nodeBranchMap.get(currentNode.id).visible;
  }

  toggleNodeActive(node, nodeBranchMap) {
    const nodeEntry = nodeBranchMap.get(node.id);

    nodeEntry.visible = !nodeEntry.visible;

    this.setState({});
  }

  setActiveDepth(branchId, depth, nodeBranchActiveDepthMap) {
    const depthData = nodeBranchActiveDepthMap.get(branchId);

    depthData.activeDepth = depth;

    this.setState({});
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getFilterNodeBranch(node, specifier, nodeBranchMap, nodeBranchActiveDepthMap) {
    const children = [];

    for(const child of node.children) {
      children.push(this.getFilterNodeBranch(child, specifier, nodeBranchMap, nodeBranchActiveDepthMap));
    }

    const depthOptions = [];
    const depthData = nodeBranchActiveDepthMap.get(node.id);

    if(node.depth === 0 && depthData.maxDepth > 0) {
      for(let i=0; i <= depthData.maxDepth; ++i) {
        const depth = i;

        depthOptions.push(
          <button
            key={`${specifier}:branch:${node.id}:depth_option:${depth}`}
            className={`financial-general-report__filters-container__tree-selector__depth-option${depthData.activeDepth === depth ? '--active' : ''}`}
            onClick={() => this.setActiveDepth(node.id, depth, nodeBranchActiveDepthMap)}
          >

            {depth}

          </button>
        );
      }
    }

    const buttonStyle = {};
    const nodeIsActive = this.getNoteVisibleState(node, nodeBranchMap);

    if(nodeIsActive) {
      buttonStyle.background = node.color;
    }

    const nodeKey = `${specifier}:${node.id}`;

    return (
      <React.Fragment
        key={`${nodeKey}:branch`}
      >

        {(node.depth === 0 && depthData.maxDepth > 0) &&
          <div className="financial-general-report__filters-container__tree-selector__depth-selector">

            {depthOptions}

          </div>
        }

        <div
          className={`financial-general-report__filters-container__tree-selector__node-branch${(node.depth === 0 && depthData.maxDepth > 0) ? '--wrapped' : ''}`}
        >

          <div className="financial-general-report__filters-container__tree-selector__node-group">

            <button
              className={`financial-general-report__filters-container__tree-selector__node-toggle${nodeIsActive ? '--active' : ''}`}
              onClick={() => this.toggleNodeActive(node, nodeBranchMap)}
              style={buttonStyle}
            >

              <p className="financial-general-report__filters-container__tree-selector__node-toggle__text">
                {node.name}
              </p>

              {(node.description !== null && node.description.length > 0) &&
                <i
                  className="fa-solid fa-circle-info financial-general-report__filters-container__tree-selector__node-toggle__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, node.description)}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              }

            </button>

          </div>

          {children.length > 0 &&
            <div className="financial-general-report__filters-container__tree-selector__node-group">

              {children}

            </div>
          }

        </div>

      </React.Fragment>
    );
  }

  getCostCenterOptions() {
    if(this.state.costCenters.length <= 0) {
      return (<p className="financial-general-report__filters-container__filter-default-text">Nenhum centro de custo cadastrado</p>);
    }

    const roots = this.state.costCenters.filter((entry) => entry.depth === 0);

    return roots.map((node) => this.getFilterNodeBranch(node, 'cost_center', this.state.costCenterBranchMap, this.state.costCenterBranchActiveDepthMap));
  }

  getFinancialCategoriesOptions() {
    if(this.state.financialCategories.length <= 0) {
      return (<p className="financial-general-report__filters-container__filter-default-text">Nenhum centro de custo cadastrado</p>);
    }

    const roots = this.state.financialCategories.filter((entry) => entry.depth === 0);

    return roots.map((node) => this.getFilterNodeBranch(node, 'financial_categories', this.state.financialCategoryBranchMap, this.state.financialCategoryBranchActiveDepthMap));
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  render() {
    const financialData = this.getFinancialData();

    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FINANCIAL_REPORT_PATH,
              text: "Relatório financeiro"
            },
          ]}
          titleIcon={<i className="fas fa-chart-line"></i>}
          title="Relatório financeiro"
          loading={this.state.loadingData}
        >

          <div className="financial-general-report__wrapper">

            <div className="financial-general-report__period-control">

              <h3 className="financial-general-report__period-control__title">Período de avaliação</h3>

              <div className="financial-general-report__period-control__inputs-container">
                <HalfWrapper className="financial-general-report__period-control__inputs">

                  <DefaultInput
                    name="initialDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data inicial"
                    type="date"
                    placeholder="Data inicial"
                    max={this.state.finalDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.initialDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                  <DefaultInput
                    name="finalDateInput"
                    isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                    label="Data final"
                    type="date"
                    placeholder="Data final"
                    min={this.state.initialDateInput}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.finalDateInput}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                </HalfWrapper>

                <button
                  className="financial-general-report__period-control__refresh-button"
                  onClick={() => this.applyDateInputChanges()}
                  disabled={!this.mayUpdateDateInputs()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              </div>

            </div>

            <HorizontalRule />

            <div className="financial-general-report__filters-container">

              <header className="financial-general-report__filters-container__header">

                <h3 className="financial-general-report__per-classification__title">Filtros</h3>

              </header>

              <section className="financial-general-report__filters-container__tree-selector">

                <header
                  className="financial-general-report__filters-container__tree-selector__header"
                  onClick={() => this.setState({costCenterFilterVisible: !this.state.costCenterFilterVisible})}
                >

                  <h3 className="financial-general-report__filters-container__tree-selector__header__text">
                    <i className="fas fa-filter financial-general-report__filters-container__tree-selector__header__text-icon"></i>
                    Centros de custo
                  </h3>

                  {this.state.costCenterFilterVisible ?
                    <i className="fas fa-chevron-down financial-general-report__filters-container__tree-selector__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up financial-general-report__filters-container__tree-selector__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container financial-general-report__filters-container__tree-selector__content"
                  pose={this.state.costCenterFilterVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="financial-general-report__filters-container__tree-selector__wrapper">

                    {this.getCostCenterOptions()}

                  </div>

                </VerticalAccordionContainer>

              </section>

              <section className="financial-general-report__filters-container__tree-selector">

                <header
                  className="financial-general-report__filters-container__tree-selector__header"
                  onClick={() => this.setState({financialCategoryFilterVisible: !this.state.financialCategoryFilterVisible})}
                >

                  <h3 className="financial-general-report__filters-container__tree-selector__header__text">
                    <i className="fas fa-filter financial-general-report__filters-container__tree-selector__header__text-icon"></i>
                    Categorias
                  </h3>

                  {this.state.financialCategoryFilterVisible ?
                    <i className="fas fa-chevron-down financial-general-report__filters-container__tree-selector__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up financial-general-report__filters-container__tree-selector__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container financial-general-report__filters-container__tree-selector__content"
                  pose={this.state.financialCategoryFilterVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="financial-general-report__filters-container__tree-selector__wrapper">

                    {this.getFinancialCategoriesOptions()}

                  </div>

                </VerticalAccordionContainer>

              </section>

            </div>

            <DefaultInput
              id="toggle_projection_1"
              name="includeProjection"
              label="Projeção:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.includeProjection}
              horizontalAlign="right"
            />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Balanço mensal"
            />

            <div className="financial-general-report__indicator-container--spaced">

              <div className="financial-general-report__indicator">

                <h2 className="financial-general-report__indicator__label">Resultado do período:</h2>
                <p className="financial-general-report__indicator__value">{getCurrencyText(financialData.periodResult)}</p>

              </div>

            </div>

            <FinancialNetGraph
              data={financialData.netData}
              height={this.getDefaultGraphHeight()}
            />

            <HorizontalRule />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Venda de contratos"
            />

            <StackedBarGraph
              data={financialData.incomeContractData}
              lineData={financialData.outcomeContractData}
              height={this.getDefaultGraphHeight()}
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            />

            <HorizontalRule />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Fluxo de alunos únicos"
            />

            <StackedBarGraph
              data={financialData.uniqueStudentFluxData}
              lineYAxisType="secondary"
              doNotStack={true}
              height={this.getDefaultGraphHeight()}
              ToolTipValueCallback={(value) => `${value}`}
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            />

            <HorizontalRule />

            <DefaultInput
              id="toggle_projection_2"
              name="includeProjection"
              label="Projeção:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.includeProjection}
              horizontalAlign="right"
            />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Receita média por alunos ativos"
            />

            <StackedBarGraph
              data={financialData.ticketData}
              lineData={financialData.activeUsersData}
              lineYAxisType="secondary"
              doNotStack={true}
              height={this.getDefaultGraphHeight()}
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            />

            <HorizontalRule />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Venda por serviços"
            />

            <Stacked100BarGraph
              data={financialData.perServiceData}
              height={this.getDefaultGraphHeight()}
              legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
              legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
              totalToolTipLabel="Total"
            />

            <HorizontalRule />

            <DefaultInput
              id="toggle_projection_3"
              name="includeProjection"
              label="Projeção:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.includeProjection}
              horizontalAlign="right"
            />

            <DefaultSubSectionTitle
              icon={<i className="far fa-chart-bar"></i>}
              text="Distribuição por classificações"
            />

            <div className="financial-general-report__per-classification-container">

              <div className="financial-general-report__per-classification">

                <h3 className="financial-general-report__per-classification__title">Receita total por categoria</h3>

                <div className="financial-general-report__per-classification__graph">

                  <PieGraph
                    data={financialData.totalPerCategoryData}
                    height={this.getPerClassificationGraphHeight()}
                    totalToolTipLabel="Receita total"
                  />

                </div>

              </div>

              <div className="financial-general-report__per-classification">

                <h3 className="financial-general-report__per-classification__title">Receita total por centro de custo</h3>

                <div className="financial-general-report__per-classification__graph">

                  <PieGraph
                    data={financialData.totalPerCostCenterData}
                    height={this.getPerClassificationGraphHeight()}
                    totalToolTipLabel="Receita total"
                  />

                </div>

              </div>

            </div>

            <div className="financial-general-report__per-classification--spaced">

              <h3 className="financial-general-report__per-classification__title">Gasto mensal por categoria</h3>

              <div className="financial-general-report__per-classification__graph">

                <Stacked100BarGraph
                  data={financialData.perCategoryData}
                  height={this.getDefaultGraphHeight()}
                  legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                  legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                  totalToolTipLabel="Total"
                />

              </div>

            </div>

            <div className="financial-general-report__per-classification--spaced">

              <h3 className="financial-general-report__per-classification__title">Gasto mensal por centro de custo</h3>

              <div className="financial-general-report__per-classification__graph">

                <Stacked100BarGraph
                  data={financialData.perCostCenterData}
                  height={this.getDefaultGraphHeight()}
                  legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                  legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                  totalToolTipLabel="Total"
                />

              </div>

            </div>

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FinancialGeneralReport;
