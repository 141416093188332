import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../../constants';
import * as paths from '../constants';
import {COACH_ACCESS_LEVEL, DEFAULT_UNIT_TYPE} from '../../../constants';
import ContentFrame from '../../content_frame';
import {VerticalAccordionContainer} from '../../../utils/pose_containers';
import ModelTable, {Property} from '../../../utils/model_table';
import {DefaultSubSectionTitle, HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
import {getModels, getAsLocalDate, getCurrencyText, setUrlParameters} from '../../../utils/functions';
import PieGraph, {PiePoint} from '../../../graphs/pie_graph';
import VerticalBarGraph, {BarDataPoint} from '../../../graphs/vertical_bar_graph';
import SimpleBarGraph, {DateBarPoint, BarPoint, ErrorPoint} from '../../../graphs/simple_bar_graph';
import * as permissions from '../../../permissions';
import './student_general_report.scss';

const UNDEFINED_CLASS_TIME_TAG = 'Outros';

class StudentGeneralReport extends React.Component {
  constructor(props) {
    super(props);

    const initialDate = new Date();
    initialDate.setDate(1);
    const finalDate = new Date();

    this.state = {
      frequencyServiceInput: '',
      frequencyService: '',
      frequencyInitialDateInput: initialDate.toISOString().slice(0, 10),
      frequencyFinalDateInput: finalDate.toISOString().slice(0, 10),
      frequencyInitialDate: initialDate.toISOString().slice(0, 10),
      frequencyFinalDate: finalDate.toISOString().slice(0, 10),
      students: [],
      personalTraining: [],
      contracts: [],
      training_classes: [],
      services: [],
      loadingData: true,
      filterSectionVisible: false,
      serviceFilterMap: new Map(),
      screenWidth: window.innerWidth,
      graphContainerWidth: null
    };
  }

  async getContracs() {
    const parameters = {
      active_only: true,
      calculate_target_service: true,
    };

    return await getModels(setUrlParameters(routes.CONTRACTS_GET_API, parameters));
  }

  async getStudents() {
    const parameters = {
      active_only: true,
      load_disc: true,
      load_birthdate: true,
      load_gender: true,
      load_next_physical_evaluation_date: true,
    };

    // if(this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) && this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
    //   parameters.load_personal_training = true;
    // }

    return await getModels(setUrlParameters(routes.STUDENTS_GET_API, parameters));
  }

  async getTrainingClasses() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_CLASS_DATA_PERMISSION_ID)) {
      const parameters = {
        initial_date: this.state.frequencyInitialDate,
        final_date: this.state.frequencyFinalDate,
      };

      if(this.state.frequencyService) {
        parameters.target_service = this.state.frequencyService;
      }

      return await getModels(setUrlParameters(routes.TRAINING_CLASSES_GET_API, parameters));
    }

    return [];
  }

  async getServices() {
    let services = await getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    services.sort((a, b) => a.localeCompare(b));

    return services;
  }

  async refreshData() {
    this.setState({loadingData: true});

    let students = this.getStudents();
    let contracts = this.getContracs();
    let training_classes = this.getTrainingClasses();
    let services = this.getServices();

    const update = {
      loadingData: false
    }

    // let requiresPersonalSet = new Set();

    contracts = await contracts;

    if(contracts) {
      update.contracts = contracts;

      update.serviceFilterMap = new Map(this.state.serviceFilterMap);

      for (const contract of contracts) {
        if (!update.serviceFilterMap.has(contract.service_plan_id)) {
          update.serviceFilterMap.set(contract.service_plan_id, {
            id: contract.service_plan.id,
            name: contract.service_plan.name,
            description: contract.service_plan.description,
            included: true,
          })
        }
      }

      // requiresPersonalSet = new Set(contracts.filter((contract) => contract.service_plan.requires_personal_period).map((contract) => contract.user_id));
    }

    students = await students;

    if(students) {
      update.students = students;
      update.personalTraining = [];

      // const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

      // for(const student of students) {
      //   if(student.personal_training && student.personal_training !== null) {
      //     const initialDate = getAsLocalDate(student.personal_training.initial_date);

      //     let lastTraining = null;
      //     let trainingsExecuted = 0;

      //     for(const trainingData of student.personal_training.training_data) {
      //       const executionDate = getAsLocalDate(trainingData.date);

      //       if(executionDate >= initialDate) {
      //         trainingsExecuted += 1;

      //         if(lastTraining === null || lastTraining.date < trainingData.date) {
      //           lastTraining = trainingData;
      //         }
      //       }
      //     }

      //     // let days_since_last_training = 'Não iniciado';
      //     let days_since_last_training = -1;

      //     if(lastTraining !== null) {
      //       const timeDiff = Math.abs(today.getTime() - getAsLocalDate(lastTraining.date).getTime());
      //       let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

      //       // days_since_last_training = `${daysCount} dia(s)`;
      //       days_since_last_training = daysCount;
      //     }

      //     const totalProgress = 100 * trainingsExecuted / (student.personal_training.trainings_count * student.personal_training.repetition_count);

      //     update.personalTraining.push({
      //       id: student.id,
      //       student_name: student.name,
      //       progress: totalProgress,
      //       days_since_last_training: days_since_last_training,
      //       final_date: student.personal_training.final_date,
      //       physical_evaluation_status: student.physical_evaluation_status
      //     });
      //   }
      //   else if(requiresPersonalSet.has(student.id)) {
      //     update.personalTraining.push({
      //       id: student.id,
      //       student_name: student.name,
      //       progress: null,
      //       days_since_last_training: null,
      //       final_date: null,
      //       physical_evaluation_status: student.physical_evaluation_status
      //     });
      //   }
      // }
    }

    training_classes = await training_classes;

    if(training_classes) {
      update.training_classes = training_classes;
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);
  }

  getServiceOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.services.map((service) => SelectOption(service, service))
    ];
  }

  async componentDidMount() {
    this.refreshData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    if(name.startsWith('service_filter:')) {
      const selection = name.split(':');
      const service_id = parseInt(selection[1]);

      const serviceMapEntry = this.state.serviceFilterMap.get(service_id);

      update.serviceFilterMap = new Map(this.state.serviceFilterMap);

      update.serviceFilterMap.set(service_id, {
        ...serviceMapEntry,
        included: value
      })
    }
    else if(name === 'includeAllServices') {
      update.serviceFilterMap = new Map(this.state.serviceFilterMap);

      for (const entry of update.serviceFilterMap.values()) {
        entry.included = value;
      }
    }
    else {
      update[name] = value;
    }

    this.setState(update);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.frequencyInitialDate !== this.state.frequencyInitialDate || prevState.frequencyFinalDate !== this.state.frequencyFinalDate || prevState.frequencyService !== this.state.frequencyService) {
      this.setState({training_classes: await this.getTrainingClasses()});
    }
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getDiscGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getStudentData() {
    const filteredContracts = this.state.contracts.filter((entry) => this.state.serviceFilterMap.get(entry.service_plan_id).included);
    const studentIdSet = new Set(filteredContracts.map((contract) => contract.user_id));
    const filteredStudents = this.state.students.filter((entry) => studentIdSet.has(entry.id))

    const studentCount = filteredStudents.length;

    const discMap = new Map([
      ['d', PiePoint(0, studentCount, 'Dominância', '#f37778')],
      ['i', PiePoint(0, studentCount, 'Influência', '#fcd602')],
      ['s', PiePoint(0, studentCount, 'Estabilidade', '#50bc84')],
      ['c', PiePoint(0, studentCount, 'Complacência', '#35ade3')],
    ]);

    const ageMap = new Map([
      [1, BarDataPoint(0, '10 a 20', '#1866b4')],
      [2, BarDataPoint(0, '20 a 30', '#367dc4')],
      [3, BarDataPoint(0, '30 a 40', '#5899da')],
      [4, BarDataPoint(0, '40 a 50', '#74abe2')],
      [5, BarDataPoint(0, '50 a 60', '#93bfeb')],
      [6, BarDataPoint(0, '60+', '#b2d4f5')],
    ]);

    const genderMap = new Map([
      ['Masculino', PiePoint(0, studentCount, 'Masculino', '#35ade3')],
      ['Feminino', PiePoint(0, studentCount, 'Feminino', '#ff76c8')],
    ]);

    const serviceMap = new Map();

    for(let student of filteredStudents) {
      let score = -1;

      for(let classification of student.disc.total_scores) {
        if(classification.value < score) {
          break;
        }

        score = classification.value;
        discMap.get(classification.type).value += 1;
      }

      let ageGroup = 6;
      const today = new Date();
      const birthdate = getAsLocalDate(student.birthdate);
      const timeDiff = Math.abs(today.getTime() - birthdate.getTime());
      const age = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));

      if(today > birthdate && age >= 10) {
        if(age >= 10 && age < 20) {
          ageGroup = 1;
        }
        else if(age >= 20 && age < 30) {
          ageGroup = 2;
        }
        else if(age >= 30 && age < 40) {
          ageGroup = 3;
        }
        else if(age >= 40 && age < 50) {
          ageGroup = 4;
        }
        else if(age >= 50 && age < 60) {
          ageGroup = 5;
        }
        else if(age >= 60) {
          ageGroup = 6;
        }

        ageMap.get(ageGroup).value += 1;
      }

      if(!genderMap.has(student.gender)) {
        genderMap.set(student.gender, PiePoint(0, studentCount, student.gender, '#000000'));
      }

      genderMap.get(student.gender).value += 1;
    }

    const contractCount = filteredContracts.length;
    const ticketMap = new Map();
    const userMap = new Map();
    let totalMeanTicket = 0;

    for(let contract of filteredContracts) {
      let servicePoint;

      if(!serviceMap.has(contract.service_plan.name)) {
        servicePoint = PiePoint(0, contractCount, contract.service_plan.name);
        serviceMap.set(contract.service_plan.name, servicePoint);
      }
      else {
        servicePoint = serviceMap.get(contract.service_plan.name);
      }

      servicePoint.value += 1;

      if(contract.target_service) {
        if(!ticketMap.has(contract.target_service)) {
          ticketMap.set(contract.target_service, 0);
        }

        if(!userMap.has(contract.target_service)) {
          userMap.set(contract.target_service, new Set());
        }

        ticketMap.set(contract.target_service, ticketMap.get(contract.target_service) + (30 * (contract.total_value / contract.period)));
        userMap.get(contract.target_service).add(contract.user_id);
      }

      totalMeanTicket += 30 * (contract.total_value / contract.period);
    }

    if(studentCount > 0) {
      totalMeanTicket = totalMeanTicket / studentCount;
    }

    const serviceTickets = [];

    for(const [key, value] of ticketMap.entries()) {
      const active_students = userMap.get(key).size;

      serviceTickets.push({
        target_service: key,
        ticket: value / active_students,
        active_students
      });
    }

    return {
      discData: [...discMap.values()].filter((classification) => classification.value > 0),
      genderData: [...genderMap.values()].filter((classification) => classification.value > 0),
      ageData: [...ageMap.values()],
      serviceData: [...serviceMap.values()],
      serviceTickets,
      totalMeanTicket,
      filteredContracts,
      filteredStudents
    };
  }

  mayUpdateFrequencyDateInputs() {
    if(!this.state.frequencyInitialDateInput || !this.state.frequencyFinalDateInput) {
      return false;
    }

    if(this.state.frequencyInitialDateInput !== this.state.frequencyInitialDate || this.state.frequencyFinalDateInput !== this.state.frequencyFinalDate || this.state.frequencyServiceInput !== this.state.frequencyService) {
      return true;
    }

    return false;
  }

  applyFrequencyDateInputChanges() {
    if(this.mayUpdateFrequencyDateInputs()) {
      this.setState({
        frequencyInitialDate: this.state.frequencyInitialDateInput,
        frequencyFinalDate: this.state.frequencyFinalDateInput,
        frequencyService: this.state.frequencyServiceInput,
      });
    }
  }

  getFrequencyData() {
    let totalStudents = 0;
    const frequencyByDate = new Map();
    const frequencyByHour = new Map();
    const uniqueStudents = new Set();

    for(const entry of this.state.training_classes) {
      totalStudents += entry.student_count;

      const date = entry.started_at.slice(0, 10);

      if(!frequencyByDate.has(date)) {
        frequencyByDate.set(date, 0);
      }

      frequencyByDate.set(date, frequencyByDate.get(date) + entry.student_count);

      if(entry.class_time) {
        if(!frequencyByHour.has(entry.class_time)) {
          frequencyByHour.set(entry.class_time, []);
        }

        frequencyByHour.get(entry.class_time).push(entry.student_count);
      }
      else {
        if(!frequencyByHour.has(UNDEFINED_CLASS_TIME_TAG)) {
          frequencyByHour.set(UNDEFINED_CLASS_TIME_TAG, []);
        }

        frequencyByHour.get(UNDEFINED_CLASS_TIME_TAG).push(entry.student_count);
      }

      if(entry.data_entries) {
        for(const student_data of entry.data_entries) {
          uniqueStudents.add(student_data.user_id);
        }
      }
    }

    const byDate = [];
    const byHour = [];
    const byHourError = [];

    const hourKeys = [...frequencyByHour.keys()];
    hourKeys.sort()

    for(const [key, value] of frequencyByDate.entries()) {
      byDate.push(new DateBarPoint(value, getAsLocalDate(key), value));
    }

    for(const key of hourKeys) {
      const value = frequencyByHour.get(key);

      const mean = value.reduce((acc, current) => Number(acc) + Number(current)) / value.length;

      let deviation;
      if(value.length > 1) {
        deviation = Math.sqrt(value.reduce((acc, current) => acc + Math.pow(current - mean, 2), 0) / (value.length)); //uncorrected standard deviation
        // deviation = value.reduce((acc, current) => acc + Math.abs(current - mean), 0) / value.length; // mean deviation
      }
      else {
        deviation = 0;
      }

      const roundedMean = Math.round(mean*100) / 100;

      byHour.push(new BarPoint(roundedMean, key, roundedMean));
      byHourError.push(new ErrorPoint([mean-deviation, mean+deviation], key));
    }

    let weeklyFrequency;

    if(uniqueStudents.size > 0) {
      const initialData = getAsLocalDate(this.state.frequencyInitialDate);
      const finalData = getAsLocalDate(this.state.frequencyFinalDate);

      const timeDiff = Math.abs(finalData.getTime() - initialData.getTime());
      const searchDaysRange = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

      weeklyFrequency = (7 * totalStudents) / (uniqueStudents.size * searchDaysRange);
    }
    else {
      weeklyFrequency = 0;
    }

    return {
      totalclasses: this.state.training_classes.length,
      totalStudents: totalStudents,
      weeklyFrequency,
      byDate,
      byHour,
      byHourError,
    };
  }

  getServiceTicketsIndicators(serviceTickets) {
    return serviceTickets.map((entry) => (
      <div
        key={`service:${entry.target_service}:ticket`}
        className="student-general-report__indicator"
      >

        <h2 className="student-general-report__indicator__label">Ticket médio - {entry.target_service} ({entry.active_students}):</h2>
        <p className="student-general-report__indicator__value">{getCurrencyText(entry.ticket)}</p>

      </div>
    ));
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getDateText(isoDate) {
    const todayIsoDate = (new Date()).toISOString().slice(0, 10);
    const today = getAsLocalDate(todayIsoDate);
    const date = getAsLocalDate(isoDate);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    const dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    const timeDiff = date.getTime() - today.getTime();
    const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const daysPassedText = daysPassed === 0 ? 'hoje' : `${daysPassed} dia${Math.abs(daysPassed) > 1 ? 's' : ''}`;

    return `${dateText} (${daysPassedText})` ;
  }

  getPhysicalEvaluationDateText(entry) {
    if(entry.physical_evaluation_status === null) {
      return 'Liberado';
    }
    else if(!entry.physical_evaluation_status.enabled) {
      return 'Desabilitado';
    }
    else if(entry.physical_evaluation_status.next_date === null) {
      return 'Liberado';
    }

    return this.getDateText(entry.physical_evaluation_status.next_date);
  }

  getPersonalTrainingProperties() {
    let properties = [
      Property('student_name', 'Nome', <i className="fas fa-tag"></i>),
      Property('progress', 'Progresso', <i className="fas fa-medal"></i>, {
        getDataText: (entry) => (entry.progress !== null ? `${entry.progress.toFixed(0)}%` : '-'),
        getFilterText: (entry) => (entry.progress !== null ? `${entry.progress.toFixed(0)}%` : '-'),
        getSortCallback: (a, b) => {
          if(a.progress === null && b.progress !== null) {
            return 1;
          }
          else if(a.progress !== null && b.progress === null) {
            return -1;
          }
          else if(a.progress === null && b.progress === null) {
            return 0;
          }

          return a.progress - b.progress;
        }
      }),
      Property('days_since_last_training', 'Último treino há', <i className="fas fa-calendar-day"></i>, {
        getSortCallback: (a, b) => {
          if(a.days_since_last_training === null && b.days_since_last_training !== null) {
            return 1;
          }
          else if(a.days_since_last_training !== null && b.days_since_last_training === null) {
            return -1;
          }
          else if(a.days_since_last_training === null && b.days_since_last_training === null) {
            return 0;
          }

          if(a.days_since_last_training < 0 && b.days_since_last_training >= 0) {
            return 1;
          }
          else if(a.days_since_last_training >= 0 && b.days_since_last_training < 0) {
            return -1;
          }
          else if(a.days_since_last_training === b.days_since_last_training) {
            if(Math.round(a.progress) !== Math.round(b.progress)) {
              return a.progress - b.progress;
            }

            return a.student_name.localeCompare(b.student_name);
          }

          return a.days_since_last_training - b.days_since_last_training;
        },
        getDataText: (entry) => {
          if(entry.days_since_last_training === null) {
            return 'Não configurado';
          }
          else if(entry.days_since_last_training < 0) {
            return 'Não iniciado';
          }

          return `${entry.days_since_last_training} dia(s)`
        }
      }),
      Property('final_date', 'Término', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => (entry.final_date !== null ? this.getDateText(entry.final_date) : '-'),
        getFilterText: (entry) => (entry.final_date !== null ? this.getDateText(entry.final_date) : '-')
      }),
      Property('physical_evaluation_status', 'Reavaliação física', <i className="fas fa-calendar-day"></i>, {
        getSortCallback: (a, b) => {
          const aDisabled = a.physical_evaluation_status !== null && !a.physical_evaluation_status.enabled;
          const bDisabled = b.physical_evaluation_status !== null && !b.physical_evaluation_status.enabled;

          if (aDisabled && !bDisabled) {
            return 1;
          }
          else if(!aDisabled && bDisabled) {
            return -1;
          }
          else if(aDisabled && bDisabled) {
            return 0;
          }

          const aNextDateNotSet = a.physical_evaluation_status === null || a.physical_evaluation_status.next_date === null;
          const bNextDateNotSet = b.physical_evaluation_status === null || b.physical_evaluation_status.next_date === null;

          if (aNextDateNotSet && !bNextDateNotSet) {
            return -1;
          }
          else if(!aNextDateNotSet && bNextDateNotSet) {
            return 1;
          }
          else if(aNextDateNotSet && bNextDateNotSet) {
            return 0;
          }

          return a.physical_evaluation_status.next_date.slice(0, 10).localeCompare(b.physical_evaluation_status.next_date.slice(0, 10));
        },
        getDataText: (entry) => this.getPhysicalEvaluationDateText(entry),
        getFilterText: (entry) => this.getPhysicalEvaluationDateText(entry)
      }),
    ];

    return properties;
  }

  getPersonalTrainingActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-link-button"
          to={`${routes.STUDENT_EDIT_PATH}${entry.id}${paths.PERSONAL_TRAINING_PATH}`}
        >

            <i className="fas fa-link"></i>

        </Link>

      </div>
    );
  }

  getServiceFilterProperties() {
    let properties = [];

    if(this.state.screenWidth > 430) {
      properties.push(
        Property('code', 'Código', <i className="fas fa-tag"></i>),
      );
    }

    properties.push(
      Property('name', 'Serviço', <i className="fas fa-tag"></i>)
    );

    if(this.state.screenWidth > 700) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "service-list__description-cell"}),
      );
    }

    // if(this.state.screenWidth > 430) {
    //   properties.push(
    //     Property('period', 'Período', <i className="fas fa-calendar-week"></i>, {getDataText: this.getPeriodText}),
    //   );
    // }

    properties = [
      ...properties,
      Property('included', 'Incluído', <i className="fa-solid fa-filter"></i>, {
        getDataText: (entry) => {
          return (
            <DefaultInput
              name={`service_filter:${entry.id}`}
              isHighlighted={false}
              type="checkbox"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={entry.included}
            />
          );
        },
        applyFilter: false,
        sortable: false,
      })
    ];

    return properties;
  }

  render() {
    const studentData = this.getStudentData();
    const frequencyData = this.getFrequencyData();

    const serviceFilterList = [...this.state.serviceFilterMap.values()];

    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.STUDENT_REPORT_PATH,
            text: "Relatório geral"
          },
        ]}
        titleIcon={<i className="fas fa-chart-line"></i>}
        title="Relatório geral"
        loading={this.state.loadingData}
      >

        <div className="student-general-report__wrapper">

          {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
            <React.Fragment>

              <section className="student-general-report__filter-section">

                <header
                  className="student-general-report__filter-section__header"
                  onClick={() => this.setState({filterSectionVisible: !this.state.filterSectionVisible})}
                >

                  <h3 className="student-general-report__filter-section__header__text">
                    <i className="fa-solid fa-filter student-general-report__filter-section__header__text-icon"></i>
                    Filtrar serviços
                  </h3>

                  {this.state.filterSectionVisible ?
                    <i className="fas fa-chevron-down student-general-report__filter-section__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up student-general-report__filter-section__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container student-general-report__filter-section__content"
                  pose={this.state.filterSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="student-general-report__filter-section__wrapper">

                    <DefaultInput
                      name="includeAllServices"
                      label="Selecionar/desselecionar todos os serviços:"
                      type="toggle"
                      isHorizontal={true}
                      activeText="Sim"
                      inactiveText="Não"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={!serviceFilterList.some((entry) => !entry.included)}
                      horizontalAlign="right"
                    />

                    <HorizontalRule />

                    <ModelTable
                      properties={this.getServiceFilterProperties()}
                      data={serviceFilterList}
                      initialOrderBy="name"
                      initialOrderIsDecrescent={true}
                    >
                    </ModelTable>

                  </div>

                </VerticalAccordionContainer>

              </section>

              <HorizontalRule />

            </React.Fragment>
          }

          <div className="student-general-report__indicator-container">

            <div className="student-general-report__indicator">

              <h2 className="student-general-report__indicator__label">Alunos ativos:</h2>
              <p className="student-general-report__indicator__value">{studentData.filteredStudents.length}</p>

            </div>

            {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID)) &&
              <React.Fragment>

                <div className="student-general-report__indicator">

                  <h2 className="student-general-report__indicator__label">Contratos/Serviços ativos:</h2>
                  <p className="student-general-report__indicator__value">{studentData.filteredContracts.length}</p>

                </div>

                <div className="student-general-report__indicator">

                  <h2 className="student-general-report__indicator__label">Ticket médio total:</h2>
                  <p className="student-general-report__indicator__value">{getCurrencyText(studentData.totalMeanTicket)}</p>

                </div>

                {this.getServiceTicketsIndicators(studentData.serviceTickets)}

              </React.Fragment>
            }

          </div>

          <HorizontalRule />

          {studentData.filteredStudents.length > 0 &&
            <React.Fragment>

              <DefaultSubSectionTitle
                icon={<i className="fas fa-chart-pie"></i>}
                text="Distribuição DISC"
              />

              <PieGraph
                data={studentData.discData}
                height={this.getDiscGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                valueTextCallback={(value) => value}
                indexLabel="{value}"
                // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                // defaultMultiplier={1}
              />

              <HorizontalRule />

              <DefaultSubSectionTitle
                icon={<i className="fas fa-chart-pie"></i>}
                text="Distribuição de gênero"
              />

              <PieGraph
                data={studentData.genderData}
                height={this.getDiscGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                valueTextCallback={(value) => value}
                indexLabel="{value}"
                // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                // defaultMultiplier={1}
              />

              <HorizontalRule />

              <DefaultSubSectionTitle
                icon={<i className="far fa-chart-bar"></i>}
                text="Distribuição de faixa etária"
              />

              <VerticalBarGraph
                xLabel="Faixa etária"
                yLabel="Número de alunos"
                data={studentData.ageData}
                height={this.getDefaultGraphHeight()}
              />

              <HorizontalRule />

            </React.Fragment>
          }

          {(this.props.userAccessLevel < COACH_ACCESS_LEVEL && this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_ENTRIES_PERMISSION_ID) && studentData.filteredContracts.length > 0) &&
            <React.Fragment>

              <DefaultSubSectionTitle
                icon={<i className="fas fa-chart-pie"></i>}
                text="Distribuição de serviços ativos"
              />

              <PieGraph
                data={studentData.serviceData}
                height={this.getDiscGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                valueTextCallback={(value) => value}
              />

              <HorizontalRule />

            </React.Fragment>
          }

          {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_CLASS_DATA_PERMISSION_ID)) &&
            <React.Fragment>

              <DefaultSubSectionTitle
                className="student-general-report__no-margin-subsection"
                icon={<i className="far fa-chart-bar"></i>}
                text="Presença em aulas"
              />

              <div className="student-general-report__inputs-container">
                <div className="student-general-report__inputs-wrapper">

                  <DefaultInput
                    name="frequencyServiceInput"
                    label="Serviço"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.frequencyServiceInput || ''}
                    options={this.getServiceOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="frequencyInitialDateInput"
                      isHighlighted={this.state.frequencyInitialDateInput > this.state.frequencyFinalDateInput}
                      label="Data inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.frequencyFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.frequencyInitialDateInput}
                    />

                    <DefaultInput
                      name="frequencyFinalDateInput"
                      isHighlighted={this.state.frequencyInitialDateInput > this.state.frequencyFinalDateInput}
                      label="Data final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.frequencyInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.frequencyFinalDateInput}
                    />

                  </HalfWrapper>

                </div>

                <button
                  className="student-general-report__refresh-button"
                  onClick={() => this.applyFrequencyDateInputChanges()}
                  disabled={!this.mayUpdateFrequencyDateInputs()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              </div>

              <div className="student-general-report__indicator-container--spaced">

                <div className="student-general-report__indicator">

                  <h2 className="student-general-report__indicator__label">Total de aulas:</h2>
                  <p className="student-general-report__indicator__value">{frequencyData.totalclasses}</p>

                </div>

                <div className="student-general-report__indicator">

                  <h2 className="student-general-report__indicator__label">Total de check-ins:</h2>
                  <p className="student-general-report__indicator__value">{frequencyData.totalStudents}</p>

                </div>

                {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DATA_PERMISSION_ID) &&
                  <div className="student-general-report__indicator">

                    <h2 className="student-general-report__indicator__label">Frequência média semanal:</h2>
                    <p className="student-general-report__indicator__value">{frequencyData.weeklyFrequency.toFixed(2)}</p>

                  </div>
                }

              </div>

              <SimpleBarGraph
                title="Por dia"
                name="Frequência"
                data={frequencyData.byDate}
                height={this.getDefaultGraphHeight()}
                barColor="#4d86ce"
                labelFormatter={(value) => value}
                dateBased={true}
              />

              <SimpleBarGraph
                title="Por hora"
                name="Frequência média"
                data={frequencyData.byHour}
                errorData={frequencyData.byHourError}
                height={this.getDefaultGraphHeight()}
                barColor="#4d86ce"
                labelFormatter={(value) => value}
                dateBased={false}
              />

              <HorizontalRule />

            </React.Fragment>
          }

          {/* {(this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_DATA_PERMISSION) && this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) &&
            <React.Fragment>

              <DefaultSubSectionTitle
                icon={<i className="fas fa-dumbbell"></i>}
                text="Personal training"
              />

              <ModelTable
                properties={this.getPersonalTrainingProperties()}
                getActions={(entry) => this.getPersonalTrainingActions(entry)}
                data={this.state.personalTraining}
                initialOrderBy="days_since_last_training"
                initialOrderIsDecrescent={true}
              >

              </ModelTable>

              <HorizontalRule />

            </React.Fragment>
          } */}

        </div>

      </ContentFrame>
    );
  }
}

export default StudentGeneralReport;
