import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {PHYSICAL_AVALIATION_PATH} from '../student/constants';
import DefaultSection, {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModel, postModel, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import ImagePicker from '../../components/image_picker';
import ConfirmationWindow from '../confirmation_window';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import './physical_evaluation_photo_edit.scss';


class PhysicalEvaluationPhotoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exam: {},
      errorTitle: null,
      errorMessage: null,
      loading: true
    };
  }

  async componentDidMount() {
    try {
      const exam = await getModel(setUrlParameters(
        `${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_GET}${this.props.match.params.physicalEvaluationId}`, 
        {load_photos: true, include_last_exam: true, load_student: true}));

      if(exam) {
        this.setState({
          exam: {...this.state.exam, ...exam},
          loading: false
        });
      }
      else {
        this.props.history.replace(routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH);
      }
    }
    catch(errors) {
      this.props.history.replace(routes.PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH);
    }
  }

  async onUpdateFrontImage(dataURL) {
    const front_photo_url = await this.onUpdateImage(dataURL, routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_FRONT_PHOTO_POST.replace('{id}', this.props.match.params.physicalEvaluationId));
    
    if (front_photo_url !== null) {
      this.setState({
        exam: {...this.state.exam, front_photo_url}
      });  

      return true
    }

    return false;
  }

  async onUpdateSideImage(dataURL) {
    const side_photo_url = await this.onUpdateImage(dataURL, routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_SIDE_PHOTO_POST.replace('{id}', this.props.match.params.physicalEvaluationId));
    
    if (side_photo_url !== null) {
      this.setState({
        exam: {...this.state.exam, side_photo_url}
      });  

      return true
    }

    return false;
  }

  async onUpdateBackImage(dataURL) {
    const back_photo_url = await this.onUpdateImage(dataURL, routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_BACK_PHOTO_POST.replace('{id}', this.props.match.params.physicalEvaluationId));
    
    if (back_photo_url !== null) {
      this.setState({
        exam: {...this.state.exam, back_photo_url}
      });  

      return true
    }

    return false;
  }

  async onUpdateImage(dataURL, url) {
    let data = {file: dataURL};

    try {
      const response = await postModel(url, data, true);
      
      if(response) {
        return response.image_url;
      }
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            // for(let parameter of error.parameters) {
            //   if(parameter.name === 'email') {
            //   }
            // }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        errorTitle: 'Falha ao atualizar imagem',
        errorMessage: `${warningMessages.join('; ')}.`,
      });
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: `${routes.STUDENT_EDIT_PATH}${this.state.exam.user_id}${PHYSICAL_AVALIATION_PATH}`,
              text: "Gerenciar aluno"
            },
            {
              path: `${routes.PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH}${this.props.match.params.physicalEvaluationId}`,
              text: "Avaliação física: Fotos"
            },
          ]}
          titleIcon={<i className="fas fa-edit"></i>}
          title="Avaliação física: Fotos"
          loading={this.state.loading}
        >

          <DefaultSection
            className="physical-evaluation-photo-edit"
            title="Fotos para comparativo"
          >

            <DefaultInput
              name="name"
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              maxLength="128"
              value={this.state.exam.student_name}
              autoComplete="off"
              disabled
            />

            <HalfWrapper>

              <DefaultInput
                name="date"
                label="Data do exame"
                type="date"
                placeholder="Data do exame"
                value={this.state.exam.scheduled_date}
                readOnly
                disabled
              />

              <DefaultInput
                name="time"
                label="Horário do exame"
                type="time"
                placeholder="Data do exame"
                value={this.state.exam.scheduled_time}
                readOnly
                disabled
              />

            </HalfWrapper>

            {this.state.exam.evaluator_name !== null &&
              <DefaultInput
                name="evaluator_name"
                label="Avaliador"
                type="text"
                placeholder="Nome do avaliador"
                maxLength="128"
                value={this.state.exam.evaluator_name}
                autoComplete="off"
                disabled
              />
            }
          
            <DefaultSubSectionTitle
              className="physical-evaluation-photo-edit__subsection"
              icon={<i className="fa-solid fa-camera"></i>}
              text="Frente"
            />

            <div className="physical-evaluation-photo-edit__comparison-container">

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Anterior</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.last_exam ? this.state.exam.last_exam.front_photo_url : null}
                  maxWidth={1500}
                  maxHeight={1500}
                />

              </div>

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Atual</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.front_photo_url}
                  maxWidth={1500}
                  maxHeight={1500}
                  onPictureSelected={(dataURL) => this.onUpdateFrontImage(dataURL)}
                />

              </div>

            </div>
          
            <DefaultSubSectionTitle
              className="physical-evaluation-photo-edit__subsection"
              icon={<i className="fa-solid fa-camera"></i>}
              text="Perfil"
            />

            <div className="physical-evaluation-photo-edit__comparison-container">

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Anterior</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.last_exam ? this.state.exam.last_exam.side_photo_url : null}
                  maxWidth={1500}
                  maxHeight={1500}
                />

              </div>

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Atual</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.side_photo_url}
                  maxWidth={1500}
                  maxHeight={1500}
                  onPictureSelected={(dataURL) => this.onUpdateSideImage(dataURL)}
                />

              </div>

            </div>
          
            <DefaultSubSectionTitle
              className="physical-evaluation-photo-edit__subsection"
              icon={<i className="fa-solid fa-camera"></i>}
              text="Costas"
            />

            <div className="physical-evaluation-photo-edit__comparison-container">

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Anterior</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.last_exam ? this.state.exam.last_exam.back_photo_url : null}
                  maxWidth={1500}
                  maxHeight={1500}
                />

              </div>

              <div className="physical-evaluation-photo-edit__photo">

                <h4 className="physical-evaluation-photo-edit__photo__title">Atual</h4>

                <ImagePicker 
                  className="physical-evaluation-photo-edit__image-picker"
                  currentImage={this.state.exam.back_photo_url}
                  maxWidth={1500}
                  maxHeight={1500}
                  onPictureSelected={(dataURL) => this.onUpdateBackImage(dataURL)}
                />

              </div>

            </div>

            <HorizontalRule />

            <div className="physical-evaluation-photo-edit__buttons-container">

              <button
                className="physical-evaluation-photo-edit__cancel-button"
                onClick={() => this.props.history.goBack()}
              >

                Voltar

              </button>

            </div>

          </DefaultSection>

        </ContentFrame>

        <ConfirmationWindow
          className="physical-evaluation-photo-edit__message-window"
          title={this.state.errorTitle}
          description={this.state.errorMessage}
          confirmText="ok"
          cancelText="ok"
          visible={this.state.errorTitle !== null && this.state.errorMessage !== null}
          onCancel={() => this.setState({
            errorTitle: null,
            errorMessage: null,
          })}
          loading={false}
          useErrorIcon={true}
          hideConfirmButton={true}
        />

      </React.Fragment>
    );
  }
}

export default PhysicalEvaluationPhotoEdit;
