import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, PHYSICAL_REVALUATION_THRESHOLD, STUDENT_ACTIVE_STATUS, DEFAULT_UNIT_TYPE} from '../../constants';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import ConfirmationWindow from '../confirmation_window';
import './physical_evaluation.scss';
import * as routes from '../../constants';
import {patchModel, getModels, deleteModel, postModel, setUrlParameters, getLocalDateIsoString, getAsLocalDatetime, getAsLocalDate} from '../../utils/functions';
import {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import ModelTable, {Property} from '../../utils/model_table';
import * as paths from './constants';
import * as permissions from '../../permissions';


class PhysicalEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exams: [],
      loadingData: true,
      willEnable: null,
      deleteId: null,
      wasCanceled: false,
      wasAbsent: false,
      sendScheduleEmail: false,
      sendReportEmail: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      clipboardWritePermission: false,
      reportDownloadLinkCopied: false,
      userFromLinkCopied: false,
      scheduleEmailSent: false,
      reportEmailSent: false,
      downloadPdfClicked: false,
      entryIdDownloadStartedSet: new Set(),
    };
  }

  async getExams() {
    return await getModels(`${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAMS_GET}?student_id=${this.props.student.id}&include_all=true`);
  }

  async componentDidMount() {
    this.reloadData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    const permissionStatus = await navigator.permissions.query({name: 'clipboard-write'});

    if(permissionStatus.state === 'granted') {
      this.setState({
        clipboardWritePermission: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  async reloadData() {
    this.setState({
      loadingData: true
    });

    const exams = await this.getExams();

    this.setState({
      loadingData: false,
      exams: exams
    });
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getScheduleText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDatetime(entry.scheduled_iso_date, false);
    const dateString = entry.scheduled_date;

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = entry.scheduled_time;

    if(this.state.screenWidth > 420) {
      dateFormat.weekday = 'short';
    }

    if(this.state.screenWidth > 510) {
      dateFormat.weekday = 'long';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return (
      <div className="physical-evaluation__cell-wrapper">

        <p className={`physical-evaluation__date-text${dateString > todayString ? '' : dateString < todayString ? '--past' : '--today'}`}>

          {dateText}
          {' '}
          <span className="physical-evaluation__time-text">{hourText}</span>

        </p>

      </div>
    );
  }

  getCompletedText(entry) {
    return (
      <div className="physical-evaluation__cell-wrapper--centered">

        <p className={`physical-evaluation__status-text${entry.completed ? '--completed' : '--incompleted'}`}>

          {entry.completed ?
            <i className="fas fa-check physical-evaluation__status-text__icon"></i>:
            <i className="fas fa-times physical-evaluation__status-text__icon"></i>}
          {entry.completed ? 'Preenchido' : 'Não preenchido'}

        </p>

      </div>
    );
  }

  getScheduleFilter(entry) {
    const date = getAsLocalDatetime(entry.scheduled_iso_date, false);

    const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'};

    let dateText = '';
    let hourText = entry.scheduled_time;

    if(this.state.screenWidth > 420) {
      dateFormat.weekday = 'short';
    }

    if(this.state.screenWidth > 510) {
      dateFormat.weekday = 'long';
    }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return `${dateText} ${hourText}`;
  }

  getProperties() {
    let properties = [
      Property('scheduled_iso_date', 'Data agendada', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getScheduleText(entry),
        getFilterText: (entry) => this.getScheduleFilter(entry),
      }),
      Property('evaluator_name', 'Avaliador', <i className="fa-solid fa-user-doctor"></i>),
      Property('completed', 'Situação', <i className="fas fa-tasks"></i>, {
        getDataText: (entry) => this.getCompletedText(entry),
        getFilterText: (entry) => entry.completed ? 'Preenchido' : 'Não preenchido',
      }),
    ];

    return properties;
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {entry.completed ?
          this.state.entryIdDownloadStartedSet.has(entry.id) ? (
            <button
              className="model-table__default-link-button"
              disabled={true}
            >

              <i className="fa-solid fa-check"></i>

            </button>
          ) : (
            <a
              className="model-table__default-link-button"
              href={`${window.location.protocol}//${window.location.host.replace(':3000', ':5000')}${routes.STUDENT_SINGLE_PHYSICAL_EVALUATION_PDF_GET.replace('{student_id}', entry.user_id).replace('{exam_id}', entry.id)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                const entryIdDownloadStartedSet = new Set(this.state.entryIdDownloadStartedSet);
                entryIdDownloadStartedSet.add(entry.id);

                this.setState({entryIdDownloadStartedSet});
              }}
              download
            >

              <i className="fas fa-download"></i>

            </a>
          ) :
          null
        }

        <Link
          className="model-table__default-edit-button"
          to={`${routes.PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH}${entry.id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>
        
        {this.isDefaultUnit() &&
          <Link
            className="model-table__default-edit-button physical-evaluation__photo-button"
            to={routes.PHYSICAL_EVALUATION_SCHEDULED_PHOTO_EDIT_PATH.replace('{id}', entry.id)}
          >

              <i className="fa-solid fa-camera"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) ? (entry.completed ? (
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id, false, false)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        ) : (
          <React.Fragment>

            <button
              className="model-table__default-delete-button"
              onClick={() => this.onDeleteEntry(entry.id, true, false)}
            >

              Cancelar

            </button>

            <button
              className="model-table__default-delete-button physical-evaluation__absent-button"
              onClick={() => this.onDeleteEntry(entry.id, false, true)}
            >

              Faltou

            </button>
          </React.Fragment>
        )) : null}

      </div>
    );
  }

  onSendScheduleEmail() {
    this.setState({
      sendScheduleEmail: true,
      sendReportEmail: false,
      willEnable: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onSendReportEmail() {
    this.setState({
      sendReportEmail: true,
      sendScheduleEmail: false,
      willEnable: null,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onChangeStatus(willEnable) {
    this.setState({
      willEnable: willEnable,
      deleteId: null,
      sendScheduleEmail: false,
      sendReportEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteEntry(entryId, wasCanceled=true, wasAbsent=false) {
    this.setState({
      deleteId: entryId,
      wasCanceled,
      wasAbsent,
      willEnable: null,
      sendScheduleEmail: false,
      sendReportEmail: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      willEnable: null,
      deleteId: null,
      sendScheduleEmail: false,
      sendReportEmail: false,
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.willEnable != null) {
      try {
        const status = await patchModel(routes.STUDENT_PHYSICAL_EVALUATION_PATCH.replace('{id}', this.props.student.id.toString()), {enabled: this.state.willEnable}, true);

        this.props.onUpdateStatus(status);

        return;
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.deleteId != null) {
      const parameters = {};

      if (!this.state.wasCanceled) {
        parameters.student_absent = true;
      }

      try{
        if(await deleteModel(setUrlParameters(`${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAM_DELETE}${this.state.deleteId}`, parameters))) {
          // this.reloadData();
          this.props.onUpdateStatus();

          return;
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.sendScheduleEmail) {
      try {
        if(await postModel(routes.NOTIFICATION_PHYSICAL_EVALUATION_SCHEDULE_LINK_POST, {user_ids: [this.props.student.id]})) {
          this.setState({
            scheduleEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          scheduleEmailSent: false
        });

        return;
      }
    }
    else if(this.state.sendReportEmail) {
      try {
        if(await postModel(routes.NOTIFICATION_PHYSICAL_EVALUATION_REPORT_LINK_POST, {user_ids: [this.props.student.id]})) {
          this.setState({
            reportEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          reportEmailSent: false
        });

        return;
      }
    }

    this.setState({
      willEnable: null,
      deleteId: null,
      sendScheduleEmail: false,
      sendReportEmail: false,
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId) {
        if (this.state.wasCanceled) {
          return 'Cancelando';
        }
        else if (this.state.wasAbsent) {
          return 'Marcando falta';
        }

         return 'Deletando agendamento';
      }
      else if(this.state.sendScheduleEmail) {
        return 'Enviando email para agendamento';
      }
      else if(this.state.sendReportEmail) {
        return `Enviando email com ${this.getReportTypeText()}`;
      }
      else if(this.state.willEnable) {
        return 'Habilitando avaliação física';
      }

      return 'Desabilitando avaliação física';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId) {
        if (this.state.wasCanceled) {
          return 'Falha ao cancelar';
        }
        else if (this.state.wasAbsent) {
          return 'Falha ao marcar falta';
        }

        return 'Falha ao remover agendamento';
      }
      else if(this.state.sendScheduleEmail || this.state.sendReportEmail) {
        return 'Falha ao enviar email';
      }

      return 'Falha ao atualizar cadastro';
    }

    if(this.state.deleteId) {
      if (this.state.wasCanceled) {
        return 'Cancelar agendamento';
      }
      else if (this.state.wasAbsent) {
        return 'Marcar falta de aluno';
      }

      return 'Deletar exame agendado';
    }
    else if(this.state.sendScheduleEmail) {
      return 'Enviar email de agendamento';
    }
    else if(this.state.sendReportEmail) {
      return `Enviar email com ${this.getReportTypeText()}`;
    }
    else if(this.state.willEnable) {
      return 'Habilitar avaliação física';
    }

    return 'Desabilitar avaliação física';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      if(this.state.wasCanceled) {
        return 'Todos os dados relacionados ao agendamento serão removidos. Tentaremos enviar um email notificando o aluno sobre o cancelamento.';
      }
      else if(this.state.wasAbsent) {
        return 'Todos os dados relacionados ao agendamento serão removidos. Tentaremos enviar um email notificando o aluno sobre sua ausência.';
      }

      return 'Todos os dados relacionados ao agendamento serão removidos.';
    }
    else if(this.state.sendScheduleEmail) {
      return 'Enviaremos um email ao aluno contendo o link para realizar o agendamento de exame de avaliação física';
    }
    else if(this.state.sendReportEmail) {
      return 'Enviaremos um email ao aluno contendo o link para acessar o relatório de suas avaliações físicas';
    }

    return 'O cadastro do usuário será atualizado';
  }

  async copyUserFormLink() {
    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.SCHEDULE_PHYSICAL_EVALUATION_PATH}${this.props.student.auth_hash}`;

    try {
      await navigator.clipboard.writeText(userFormLink);
      this.setState({userFromLinkCopied: true});

      window.setTimeout(() => {
        this.setState({userFromLinkCopied: false});
      }, 1000);
    }
    catch(error) {
      window.alert(error);
    }
  }

  async copyReportDownloadLink() {
    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.PHYSICAL_EVALUATION_REPORT_PDF_DOWNLOAD_PATH}${this.props.student.auth_hash}`;

    try {
      await navigator.clipboard.writeText(userFormLink);
      this.setState({reportDownloadLinkCopied: true});

      window.setTimeout(() => {
        this.setState({reportDownloadLinkCopied: false});
      }, 1000);
    }
    catch(error) {
      window.alert(error);
    }
  }

  isEnabled() {
    const status = this.props.student.physical_evaluation_status;

    return status != null && status.enabled;
  }

  getStatus() {
    if(this.isEnabled()) {
      return (
        <h2 className="physical-evaluation__status--green">Habilitado</h2>
      );
    }

    return (
      <h2 className="physical-evaluation__status--red">Desabilitado</h2>
    );
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteId != null) {
      if(this.state.wasCanceled) {
        return 'Cancelar agendamento';
      }
      else if(this.state.wasAbsent) {
        return 'Marcar falta';
      }

      return 'Deletar agendamento';
    }
    else if(this.state.sendScheduleEmail || this.state.sendReportEmail) {
      return 'Enviar email';
    }

    return 'Alterar';
  }

  getCompletedExamsCount() {
    return this.state.exams.filter((entry) => entry.completed).length;
  }

  scheduleIsAvailable() {
    if(!this.isEnabled()) {
      return false;
    }

    if(this.getCompletedExamsCount() <= 0 || !this.props.student.physical_evaluation_status.last_exam_completed_at) {
      return true;
    }

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
    const date = getAsLocalDate(this.props.student.physical_evaluation_status.last_exam_completed_at);
    const timeDiff = Math.abs(today.getTime() - date.getTime());
    let daysPassed = (date < today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysPassed > PHYSICAL_REVALUATION_THRESHOLD;
  }

  getReportTypeText() {
    if(this.getCompletedExamsCount() <= 1) {
      return '1ª avaliação';
    }

    return 'comparativo';
  }

  render() {
    const userFormLink = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.SCHEDULE_PHYSICAL_EVALUATION_PATH}${this.props.student.auth_hash}`;
    const hasReport = this.state.exams.some((exam) => exam.completed);

    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    this.props.student.disc != null ?
    (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.willEnable !== null || this.state.deleteId !== null || this.state.sendScheduleEmail || this.state.sendReportEmail}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        {this.props.student.status !== STUDENT_ACTIVE_STATUS &&
          <p className="physical-evaluation__alert-text">

            <i className="fas fa-exclamation physical-evaluation__alert-text__icon"></i>
            <span className="physical-evaluation__alert-text__text">
              O aluno precisa estar ativo para liberar o acesso completo
            </span>

          </p>
        }
        {(this.props.student.status === STUDENT_ACTIVE_STATUS && !this.props.student.birthdate) &&
          <p className="physical-evaluation__alert-text">

            <i className="fas fa-exclamation physical-evaluation__alert-text__icon"></i>
            <span className="physical-evaluation__alert-text__text">
              Data de nascimento não preenchido
            </span>

          </p>
        }

        {(this.props.student.status === STUDENT_ACTIVE_STATUS && this.props.userPermissionIds.includes(permissions.UPDATE_PHYSICAL_EVALUATION_STATUS_PERMISSION_ID)) &&
          <React.Fragment>

            <div className="physical-evaluation__status-wrapper">

              {this.getStatus()}

              <button
                className="model-table__default-button physical-evaluation__status__toggle-button"
                onClick={(event) => this.onChangeStatus(!this.isEnabled())}
              >

                <i className="fas fa-toggle-on"></i> {this.isEnabled() ? 'Desabilitar' : 'Habilitar'}

              </button>

            </div>

            <HorizontalRule />

          </React.Fragment>
        }

        <div className="model-table__buttons physical-evaluation__action-container">

          {(this.props.student.status === STUDENT_ACTIVE_STATUS && this.props.student.email_is_validated !== false && !this.props.student.block_email_notification) ?
            <React.Fragment>

              {(this.props.userPermissionIds.includes(permissions.SEND_PHYSICAL_EXAM_SCHEDULE_NOTIFICATION_PERMISSION_ID) && this.scheduleIsAvailable()) ?
                <button
                  className="model-table__default-button"
                  disabled={this.state.scheduleEmailSent}
                  onClick={(event) => this.onSendScheduleEmail()}
                >

                  <i className="fas fa-envelope"></i> {this.state.scheduleEmailSent ? 'Email enviado' : 'Enviar email de agendamento'}

                </button>:
                null
              }

              {(this.props.userPermissionIds.includes(permissions.SEND_PHYSICAL_EVALUATION_REPORT_NOTIFICATION_PERMISSION_ID) && hasReport) ?
                <button
                  className="model-table__default-button"
                  disabled={this.state.reportEmailSent}
                  onClick={(event) => this.onSendReportEmail()}
                >

                  <i className="fas fa-envelope"></i> {this.state.reportEmailSent ? 'Email enviado' : `Enviar email com ${this.getReportTypeText()}`}

                </button>:
                null
              }

            </React.Fragment>:
            null
          }

          {(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) && hasReport) ?
            <React.Fragment>
              {this.state.downloadPdfClicked ? (
                <button
                  className="model-table__default-button"
                  disabled={true}
                >

                  <i className="fas fa-download"></i> Download iniciado

                </button>
              ) : (
                <a
                  className="model-table__default-button"
                  href={`${window.location.protocol}//${window.location.host.replace(':3000', ':5000')}${routes.STUDENT_PHYSICAL_EVALUATION_PDF_GET.replace('{student_id}', this.props.student.id)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    this.setState({downloadPdfClicked: true})
                  }}
                  download
                >

                  <i className="fas fa-download"></i> Baixar {this.getReportTypeText()}

                </a>
              )}

              {(this.props.student.auth_hash && this.state.clipboardWritePermission) ?
                <button
                  className="model-table__default-button"
                  disabled={this.state.reportDownloadLinkCopied}
                  onClick={(event) => this.copyReportDownloadLink()}
                >

                  <i className="fas fa-link"></i> {this.state.reportDownloadLinkCopied ? 'Link de relatório copiado' : `Copiar link ${this.getReportTypeText()}`}

                </button>:
                null
              }
            </React.Fragment>:
            null
          }

          {(this.props.student.status === STUDENT_ACTIVE_STATUS && this.props.student.auth_hash && this.isEnabled()) ?
            this.state.clipboardWritePermission ?

            <button
              className="model-table__default-button"
              disabled={this.state.userFromLinkCopied}
              onClick={(event) => this.copyUserFormLink()}
            >

              <i className="fas fa-link"></i> {this.state.userFromLinkCopied ? 'Link do agendamento copiado' : 'Copiar link do agendamento'}

            </button>:
            <a
              className="model-table__default-button"
              href={userFormLink}
              target="_blank"
              rel="noopener noreferrer"
            >

              <i className="fas fa-link"></i> Página de agendamento

            </a>:
            null
          }

        </div>

        <HorizontalRule />

        {this.props.student.physical_evaluation_status != null ?
          <React.Fragment>
            <div className="physical-evaluation__indicator-container">

              <div className="physical-evaluation__indicator">

                <h2 className="physical-evaluation__indicator__label">Vezes em que o relatório foi baixado:</h2>
                <p className="physical-evaluation__indicator__value">{this.props.student.physical_evaluation_status.report_download_counter}</p>

              </div>

            </div>

            <HorizontalRule />
          </React.Fragment>:
          null
        }

        <DefaultSubSectionTitle
          icon={<i className="fas fa-clipboard-list"></i>}
          text="Exames agendados"
        />

        <ModelTable
          properties={this.getProperties()}
          data={this.state.exams}
          getActions={this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) ? (entry) => this.getActions(entry) : null}
          initialOrderBy="scheduled_iso_date"
          initialOrderIsDecrescent={true}
        >

          {(this.props.student.status === STUDENT_ACTIVE_STATUS && this.props.student.birthdate && this.props.userPermissionIds.includes(permissions.ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) &&
            <Link
              className="model-table__default-button"
              to={{
                pathname: `${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.PHYSICAL_EVALUATION_ADD_PATH}`,
                state: { lastPath: this.props.location.pathname }
              }}
            >

              <i className="fas fa-plus"></i> Adicionar avaliação física

            </Link>
          }

        </ModelTable>

      </React.Fragment>

    ):
    (
      <p className="physical-evaluation__alert-text">

        <i className="fas fa-exclamation physical-evaluation__alert-text__icon"></i>
        <span className="physical-evaluation__alert-text__text">
          O cadastro inicial deve ser preenchido para liberar a avaliação física
        </span>

      </p>
    );
  }
}

export default PhysicalEvaluation;
