import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {postModel, getModel, setInputCursor, getPhoneText} from '../../utils/functions';
import StudentBasicData from './student_basic_data';

class StudentAdd extends React.Component {
  constructor(props) {
    super(props);

    let feedback_id = (new URLSearchParams(props.location.search)).get('feedback_id');

    if(feedback_id) {
      feedback_id = parseInt(feedback_id);
    }

    this.state = {
      student: {
        name: "",
        email: "",
        phone: "",
        gender: "",
        feedback_id: feedback_id
      },
      addToMarketingList: false,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: feedback_id !== null,
    };
  }

  async componentDidMount() {
    const update = {
      loading: false
    };

    if(this.state.student.feedback_id) {
      const feedback = await getModel(`${routes.FEEDBACK_GET_API}${this.state.student.feedback_id}`);

      if(feedback) {
        update.student = {
          name: feedback.name,
          email: feedback.email,
          phone: feedback.phone,
          gender: feedback.gender,
          feedback_id: feedback.id,
        }
      }
      else {
        this.props.history.replace(this.getLastPath().path);
        return;
      }
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'addToMarketingList') {
      this.setState({
        addToMarketingList: value
      });

      return;
    }

    if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }

    const newData = {...this.state.student, [name]: value};

    this.setState({
      student: newData
    });
  }

  inputsAreValid() {
    return this.state.student.name.length > 0 &&
           this.state.student.email.match(/.+@.+\..+/) != null &&
           this.state.student.phone.length > 0 &&
           this.state.student.gender.length;
  }

  async saveData() {
    if(!this.state.student.gender) {
      this.setState({
        highlights: ['gender'],
        showWarningMessage: true,
        warningMessage: "Gênero não selecionado"
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      ...this.state.student,
      add_to_marketing_list: this.state.addToMarketingList
    };

    if(!data.feedback_id) {
      delete data.feedback_id;
    }

    let response;

    try {
      response = await postModel(routes.STUDENT_POST_API, data, true);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 203) {
            if(error.message.includes('User from another unit')) {
              warningMessages.push('Email já cadastrado em outra unidade. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
            else if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para cadastrar este email. Por favor, entre em contato com o responsável pela unidade FYD para realizar o cadastro');
              highlights.push('email');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada')
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(`${routes.STUDENT_MANAGE_PATH}${response.model_saved.id}`);
    // this.props.history.replace(this.getLastPath().path);
  }

  getLastPath() {
    if(this.props.location.state && this.props.location.state.lastPath !== routes.STUDENT_LIST_PATH) {
      return {
        path: routes.FEEDBACK_LIST_PATH,
        name: 'Listar pesquisas'
      }
    }

    return {
      path: routes.STUDENT_LIST_PATH,
      name: 'Listar alunos'
    }
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.getLastPath().path,
            text: this.getLastPath().name
          },
          {
            path: routes.STUDENT_ADD_PATH,
            text: "Adicionar aluno"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar aluno"
        loading={this.state.loading}
      >

        <StudentBasicData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          student={this.state.student}
          addToMarketingList={this.state.addToMarketingList}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.getLastPath().path}
        />

      </ContentFrame>
    );
  }
}

export default StudentAdd;
