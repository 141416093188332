import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {PERSONAL_TRAINING_PATH} from '../student/constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, TRAINING_CALENDAR_COLOR_MAP} from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import {getModel, getModels, patchModel, postModel, deleteModel} from '../../utils/functions';
import TrainingPeriodData from './training_period_data';
import './training_period_edit.scss';
import * as paths from './constants';

const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

class TrainingPeriodEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training_period: {
        name: "",
        code: "",
        target_service: "",
        main_objective: "",
        suggested_disc_profile: "",
        suggested_physical_level: "",
        target_gender: "",
        description: "",
        note: "",
        repetition_count: 3,
        period_reference: null,
        training_days: [],
        schedule_configuration: [],
        is_active: true
      },
      student: null,
      services: [],
      highlights: [],
      warningMessage: "",
      warningMessageBackground: null,
      warningMessageColor: null,
      showWarningMessage: false,
      loading: true,
      deleteDayId: null,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      willImportTraining: null,
      trainingIdToImport: 0,
      onChangeScheduleConfigurationDay: null,
      scheduleConfigurationDayText: "",
      scheduleConfigurationDayColor: TRAINING_CALENDAR_COLOR_MAP['Descanso'],
      screenWidth: window.innerWidth
    };
  }

  async reloadPeriod() {
    let training_period = getModel(`${routes.TRAINING_PERIOD_GET_API}${this.props.match.params.trainingPeriodId}?load_activity_reference=true`);
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    const update = {
      loading: false
    }

    training_period = await training_period;

    if(training_period) {
      update.training_period = {...this.state.training_period, ...training_period};

      if(training_period.student) {
        update.student = training_period.student;
      }
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.reloadPeriod();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;


    if(name === 'trainingIdToImport' || name === 'scheduleConfigurationDayText' || name === 'scheduleConfigurationDayColor') {
      const update = {[name]: value};

      this.setState(update);
    }
    else {
      const newData = {...this.state.training_period, [name]: value};

      this.setState({
        training_period: newData
      });
    }
  }

  onDeleteTrainingDay(training_day) {
    this.setState({
      deleteDayId: training_day.id,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onRemoveScheduleConfigurationDay(weekIndex, dayIndex) {
    this.setState({
      onRemoveScheduleConfigurationDay: {weekIndex, dayIndex},
      onRemoveScheduleConfigurationWeek: null,
      deleteDayId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onRemoveScheduleConfigurationWeek(weekIndex) {
    this.setState({
      onRemoveScheduleConfigurationWeek: weekIndex,
      onRemoveScheduleConfigurationDay: null,
      deleteDayId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onEditTrainingDay(training_day) {
    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${paths.TRAINING_DAY_EDIT_PATH}${training_day.id}`);
  }

  onCancelConfirmation() {
    this.setState({
      deleteDayId: null,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
      willImportTraining: null,
      trainingIdToImport: 0,
      onChangeScheduleConfigurationDay: null
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteDayId != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_DAY_DELETE_API}${this.state.deleteDayId}`)) {
          this.reloadPeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'classes':
                      errorDescription = 'Treinamento possui dados de alunos vinculados.';

                      break;
                    case 'personal_data_entries':
                      errorDescription = 'Treinamento possui dados de alunos vinculados.';

                      break;
                    case 'training_data_entries':
                      errorDescription = 'Treinamento possui dados de alunos vinculados.';

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onRemoveScheduleConfigurationDay !== null) {
      const schedule_configuration = this.state.training_period.schedule_configuration.map((week, weekIndex) => {
        const weekCopy = week.map((entry) => {
          if (entry === null) {
            return null;
          }

          return {...entry};
        });

        if (weekIndex === this.state.onRemoveScheduleConfigurationDay.weekIndex) {
          weekCopy[this.state.onRemoveScheduleConfigurationDay.dayIndex] = null;
        }

        return weekCopy;
      });

      const training_period = {
        ...this.state.training_period,
        schedule_configuration
      };

      this.setState({
        training_period
      });
    }
    else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
      const schedule_configuration = this.state.training_period.schedule_configuration.map((week) => {
        return week.map((entry) => {
          if (entry === null) {
            return null;
          }

          return {...entry};
        });
      });

      schedule_configuration.splice(this.state.onRemoveScheduleConfigurationWeek, 1);

      const training_period = {
        ...this.state.training_period,
        schedule_configuration
      };

      this.setState({
        training_period
      });
    }

    this.setState({
      deleteDayId: null,
      onRemoveScheduleConfigurationDay: null,
      onRemoveScheduleConfigurationWeek: null,
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteDayId !== null) {
        return 'Removendo treino';
      }
      else if(this.state.onRemoveScheduleConfigurationDay !== null) {
        return 'Removendo dia do cronograma';
      }
      else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
        return 'Removendo semana do cronograma';
      }
      else if(this.state.willImportTraining !== null && this.state.trainingIdToImport > 0) {
        return 'Importando treino';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteDayId !== null) {
        return 'Falha ao remover treino';
      }
      else if(this.state.onRemoveScheduleConfigurationDay != null) {
        return 'Falha ao remover dia';
      }
      else if(this.state.onRemoveScheduleConfigurationWeek != null) {
        return 'Falha ao remover semana';
      }
      else if(this.state.willImportTraining != null) {
        return 'Falha ao importar treino';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteDayId !== null) {
      return 'Remover treino';
    }
    else if(this.state.onRemoveScheduleConfigurationDay !== null) {
      return 'Remover dia de cronograma';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
      return 'Remover semana de cronograma';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteDayId != null) {
      return 'Todos os dados relacionados ao treino serão removidos';
    }
    else if(this.state.onRemoveScheduleConfigurationDay !== null) {
      return 'A entrada será removida do cronograma';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek !== null) {
      return 'A semana será removida do cronograma';
    }
    else if(this.state.willImportTraining != null && this.state.trainingIdToImport > 0) {
      return `Uma cópia do treino ${this.state.trainingIdToImport} está sendo criada.`;
    }

    return 'Unknown';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteDayId != null) {
      return 'Remover treino';
    }
    else if(this.state.onRemoveScheduleConfigurationDay != null) {
      return 'Remover';
    }
    else if(this.state.onRemoveScheduleConfigurationWeek != null) {
      return 'Remover';
    }

    return 'Unknown';
  }

  async onAddTrainingDay() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    const data = {
      name: '',
      note: '',
      description_url: null
    };

    const days_registered = this.state.training_period.training_days.length;

    if(days_registered < ALPHABET.length) {
      data.name = ALPHABET[days_registered];
    }

    let response;

    try {
      response = await postModel(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${paths.TRAINING_DAY_POST_PATH}`, data, true);
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessageBackground: null,
        warningMessageColor: null,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.setState({
      highlights: [],
      warningMessageBackground: null,
      warningMessageColor: null,
      showWarningMessage: false,
      loading: false
    });

    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${paths.TRAINING_DAY_EDIT_PATH}${response.model_saved.id}`);
  }

  inputsAreValid() {
    return this.state.training_period.name.length > 0 &&
           this.state.training_period.repetition_count > 0;
  }

  async saveData() {
    const repetition_count = parseInt(this.state.training_period.repetition_count);

    if(repetition_count > 20) {
      this.setState({
        highlights: ['repetition_count'],
        warningMessageBackground: null,
        warningMessageColor: null,
        showWarningMessage: true,
        warningMessage: "Número de repetições não pode ser maior que 20."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    const data = {...this.state.training_period, repetition_count}

    if(data.period_reference) {
      data.period_reference = parseInt(data.period_reference);
    }
    if(data.suggested_disc_profile !== null && data.suggested_disc_profile.length <= 0) {
      data.suggested_disc_profile = null;
    }

    try {
      await patchModel(`${routes.TRAINING_PERIOD_PATCH_API}${this.props.match.params.trainingPeriodId}`, data);

      this.reloadPeriod();
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'intensity_value':
                    warningMessages.push(`Existem erros de configuração no cadastro da periodização. Estes erros precisam ser corrigidos antes de atualizar o "Número de fases" da periodização`);

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessageBackground: null,
        warningMessageColor: null,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // this.props.history.replace(routes.TRAINING_PERIOD_LIST_PATH);
    this.setState({
      warningMessage: "Dados salvos.",
      warningMessageBackground: "#dbf3c1",
      warningMessageColor: "#456d19",
      showWarningMessage: true,
      loading: false
    });
  }

  async proceedTrainingImport() {
    if(this.state.trainingIdToImport <= 0) {
      return;
    }

    this.setState({
      confirmInProgress: true
    });

    if(this.state.willImportTraining != null && this.state.trainingIdToImport > 0) {
      const data = {};

      try{
        if(await postModel(`${routes.TRAINING_PERIOD_PATCH_API}${this.props.match.params.trainingPeriodId}${paths.IMPORT_TRAINING_DAY_POST_API}${this.state.trainingIdToImport}`, data)) {
          this.reloadPeriod();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 102:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'intensity_value':
                      errorDescription = `Treino importado possui um erro de configuração. Verifique o agrupamento "${parameter.value}" do treino desejado`;

                      break;
                    default:
                  }
                }

                break;
              case 203:
              case 208:
                errorDescription = `ID (${this.state.trainingIdToImport}) inválido.`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      willImportTraining: null,
      trainingIdToImport: 0,
      confirmInProgress: false
    });
  }

  onImportTraining() {
    this.setState({
      willImportTraining: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onSwitchOrder(origem, offset) {
    const entriesCount = this.state.training_period.training_days.length;
    const origiemOrder = origem.order;
    const newOrder = origem.order + offset;

    if(newOrder <= 0 || newOrder > entriesCount) {
      return;
    }

    const listCopy = [];

    for(const training_day of this.state.training_period.training_days) {
      if(training_day.id === origem.id) {
        listCopy.push({
          ...training_day,
          order: newOrder
        });
      }
      else if(training_day.order === newOrder) {
        listCopy.push({
          ...training_day,
          order: origiemOrder
        });
      }
      else {
        listCopy.push(training_day);
      }
    }

    listCopy.sort((a, b) => a.order - b.order);

    this.setState({training_period: {...this.state.training_period, training_days: listCopy}});
  }

  onAddScheduleConfigurationWeek() {
    const schedule_configuration = this.state.training_period.schedule_configuration.map((week) => {
      return week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });
    });

    schedule_configuration.push([null, null, null, null, null, null, null]);

    const training_period = {
      ...this.state.training_period,
      schedule_configuration
    };

    this.setState({training_period});
  }

  changeScheduleConfigurationDay() {
    const schedule_configuration = this.state.training_period.schedule_configuration.map((week, weekIndex) => {
      const weekCopy = week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });

      if (weekIndex === this.state.onChangeScheduleConfigurationDay.weekIndex) {
        weekCopy[this.state.onChangeScheduleConfigurationDay.dayIndex] = {
          text: this.state.scheduleConfigurationDayText,
          color: this.state.scheduleConfigurationDayColor,
        };
      }

      return weekCopy;
    });

    const training_period = {
      ...this.state.training_period,
      schedule_configuration
    };

    this.setState({
      training_period,
      onChangeScheduleConfigurationDay: null
    });
  }

  onChangeScheduleConfigurationDay(weekIndex, dayIndex) {
    let scheduleConfigurationDayText = "";
    let scheduleConfigurationDayColor = TRAINING_CALENDAR_COLOR_MAP['Descanso'];

    if (this.state.training_period.schedule_configuration.length - 1 >= weekIndex) {
      const dayEntry = this.state.training_period.schedule_configuration[weekIndex][dayIndex];

      if (dayEntry !== null) {
        scheduleConfigurationDayText = dayEntry.text;
        scheduleConfigurationDayColor = dayEntry.color;
      }
    }

    this.setState({
      onChangeScheduleConfigurationDay: {weekIndex, dayIndex},
      scheduleConfigurationDayText,
      scheduleConfigurationDayColor
    });
  }

  onSwapScheduleConfigurationDays(initialPosition, finalPosition) {
    const schedule_configuration = this.state.training_period.schedule_configuration.map((week, weekIndex) => {
      return week.map((entry) => {
        if (entry === null) {
          return null;
        }

        return {...entry};
      });
    });

    let finalEntry = schedule_configuration[finalPosition.weekIndex][finalPosition.dayIndex];

    schedule_configuration[finalPosition.weekIndex][finalPosition.dayIndex] = schedule_configuration[initialPosition.weekIndex][initialPosition.dayIndex];
    schedule_configuration[initialPosition.weekIndex][initialPosition.dayIndex] = finalEntry;

    const training_period = {
      ...this.state.training_period,
      schedule_configuration
    };

    this.setState({
      training_period
    });
  }

  getScheduleConfigurationColorOptions() {
    const options = [
      ...Object.entries(TRAINING_CALENDAR_COLOR_MAP).map(([key, value]) => SelectOption(value, key))
    ];

    options.sort((a, b) => a.text.localeCompare(b.text));

    return options;
  }

  render() {
    return (
      <React.Fragment>
        <OverlayWindow
          className="training-period-edit__overlay"
          visible={this.state.willImportTraining != null && this.state.loading !== true && this.state.confirmInProgress !== true && this.state.confirmFailed !== true}
          actions={(
            <div className="training-period-edit__overlay__action-container">

              <DefaultMenuButton
                className="training-period-edit__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-period-edit__overlay__action-button"
                onClick={() => {
                  this.proceedTrainingImport();
                }}
                text="Confirmar"
                disabled={this.state.trainingIdToImport <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="training-period-edit__overlay__header">

            <h3 className="training-period-edit__overlay__header__title">
              Importar treino
            </h3>

          </header>

          <hr className="training-period-edit__horizontal-rule" />

          <div className="training-period-edit__overlay__content">

            <DefaultInput
              name="trainingIdToImport"
              label="ID do treino"
              type="number"
              placeholder="ID do treino"
              min="0"
              step="1"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.trainingIdToImport}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={true}
            />

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="training-period-edit__overlay"
          visible={this.state.onChangeScheduleConfigurationDay != null}
          actions={(
            <div className="training-period-edit__overlay__action-container">

              <DefaultMenuButton
                className="training-period-edit__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-period-edit__overlay__action-button"
                onClick={() => {
                  this.changeScheduleConfigurationDay();
                }}
                text="Confirmar"
                disabled={this.state.scheduleConfigurationDayText.length <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="training-period-edit__overlay__header">

            <h3 className="training-period-edit__overlay__header__title">
              Adicionar/Editar dia
            </h3>

          </header>

          <hr className="training-period-edit__horizontal-rule" />

          <div className="training-period-edit__overlay__content">

            <DefaultInput
              name="scheduleConfigurationDayText"
              label="Texto"
              type="textarea"
              placeholder="Texto do dia"
              rows="3"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.scheduleConfigurationDayText}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
            />

            <div className="training-period-edit__color-input-container">

              <div
                className="training-period-edit__color-preview"
                style={{background: this.state.scheduleConfigurationDayColor || TRAINING_CALENDAR_COLOR_MAP['Descanso']}}
              >
              </div>

              <DefaultInput
                name="scheduleConfigurationDayColor"
                label="Cor"
                type="select"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.scheduleConfigurationDayColor}
                options={this.getScheduleConfigurationColorOptions()}
              />

            </div>

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={
            this.state.deleteDayId !== null ||
            this.state.onRemoveScheduleConfigurationDay !== null ||
            this.state.onRemoveScheduleConfigurationWeek !== null ||
            (this.state.confirmFailed === true && this.state.willImportTraining != null) ||
            (this.state.willImportTraining != null && this.state.trainingIdToImport > 0 && this.state.confirmInProgress)
          }
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${PERSONAL_TRAINING_PATH}` : routes.TRAINING_PERIOD_LIST_PATH,
              text: this.state.student !== null ? 'Personal training' : 'Listar periodizações'
            },
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}`,
              text: "Editar periodização"
            },
          ]}
          titleIcon={<i className="fas fa-edit"></i>}
          title="Editar periodização"
          loading={this.state.loading}
        >

          <TrainingPeriodData
            key={`training_period_${this.state.training_period.id}_edit`}
            warningMessage={this.state.warningMessage}
            showWarningMessage={this.state.showWarningMessage}
            warningMessageBackground={this.state.warningMessageBackground}
            warningMessageColor={this.state.warningMessageColor}
            training_period={this.state.training_period}
            services={this.state.services}
            student={this.state.student}
            onAddScheduleConfigurationWeek={() => this.onAddScheduleConfigurationWeek()}
            onChangeScheduleConfigurationDay={(weekIndex, dayIndex) => this.onChangeScheduleConfigurationDay(weekIndex, dayIndex)}
            onRemoveScheduleConfigurationDay={(weekIndex, dayIndex) => this.onRemoveScheduleConfigurationDay(weekIndex, dayIndex)}
            onRemoveScheduleConfigurationWeek={(weekIndex) => this.onRemoveScheduleConfigurationWeek(weekIndex)}
            onSwapScheduleConfigurationDays={(initialPosition, finalPosition) => this.onSwapScheduleConfigurationDays(initialPosition, finalPosition)}
            onSave={() => this.saveData()}
            onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
            enableSave={this.inputsAreValid()}
            handleInputChange={(event) => this.handleInputChange(event)}
            highlights={this.state.highlights}
            onCancelPath={this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${PERSONAL_TRAINING_PATH}` : routes.TRAINING_PERIOD_LIST_PATH}
            onAddTrainingDay={() => this.onAddTrainingDay()}
            onDeleteTrainingDay={(training_day) => this.onDeleteTrainingDay(training_day)}
            onEditTrainingDay={(training_day) => this.onEditTrainingDay(training_day)}
            onImportTraining={() => this.onImportTraining()}
            onSwitchOrder={(training_day, offset) => this.onSwitchOrder(training_day, offset)}
            userPermissionIds={this.props.userPermissionIds}
            userAccessLevel={this.props.userAccessLevel}
          />

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingPeriodEdit;
