import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
// import {RECEPTIONIST_ROLE} from '../../constants';
import {postModel, getModels, setUrlParameters} from '../../utils/functions';
import ReceptionistData from './receptionist_data';

class ReceptionistAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receptionist: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_experience_level_id: null,
        is_active: true,
        has_full_access: false
      },
      role_experience_levels: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getRoleExperienceLevels() {
    const parameters = {
      is_active: true,
      // role_id: RECEPTIONIST_ROLE.id
    };

    return await getModels(setUrlParameters(routes.ROLE_EXPERIENCE_LEVELS_GET_API, parameters));
  }

  async componentDidMount() {
    const role_experience_levels = await this.getRoleExperienceLevels();

    this.setState({
      loading: false,
      role_experience_levels
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.receptionist, [name]: value};

    if (name === 'has_full_access' && value !== this.state.receptionist.has_full_access) {
      newData.role_experience_level_id = null;
    }

    this.setState({
      receptionist: newData
    });
  }

  inputsAreValid() {
    return this.state.receptionist.name.length > 0 &&
           this.state.receptionist.email.match(/.+@.+\..+/) != null &&
           this.state.receptionist.password.length > 0 &&
           this.state.receptionist.password_confirmation.length > 0;
  }

  async saveData() {
    if(this.state.receptionist.password !== this.state.receptionist.password_confirmation) {
      this.setState({
        highlights: ['password', 'password_confirmation'],
        showWarningMessage: true,
        warningMessage: "Confirmarção de senha não bate."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let receptionistData = {...this.state.receptionist};

    if (receptionistData.role_experience_level_id && receptionistData.role_experience_level_id > 0) {
      receptionistData.role_experience_level_id = parseInt(receptionistData.role_experience_level_id);
    }
    else {
      receptionistData.role_experience_level_id = null;
    }

    try {
      await postModel(routes.RECEPTIONIST_POST_API, receptionistData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 203) {
            if(error.message.includes('User from another unit')) {
              warningMessages.push('Email já cadastrado em outra unidade. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
            else if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para cadastrar este email. Por favor, entre em contato com o responsável pela unidade FYD para realizar o cadastro');
              highlights.push('email');
            }
          }
          else if(error.code === 208) {
            if(error.message.includes('User can not inactivate himself')) {
              warningMessages.push('Usuário não pode se desativar');
              highlights.push('is_active');
            }
            else if(error.message.includes('Invalid email')) {
              warningMessages.push('Emáil inválido. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.RECEPTIONIST_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.RECEPTIONIST_LIST_PATH,
            text: "Listar recepcionistas"
          },
          {
            path: routes.RECEPTIONIST_ADD_PATH,
            text: "Adicionar recepcionista"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar recepcionista"
        loading={this.state.loading}
      >

        <ReceptionistData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          receptionist={this.state.receptionist}
          role_experience_levels={this.state.role_experience_levels}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.RECEPTIONIST_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default ReceptionistAdd;
