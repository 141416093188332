import React from 'react';
import { Link } from 'react-router-dom';
import './financial_category_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class FinancialCategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      financial_category: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getFinancialCategories() {
    return await getModels(routes.FINANCIAL_CATEGORIES_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const financial_category = await this.getFinancialCategories();

    this.setState({
      loadingData: false,
      financial_category: financial_category
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.FINANCIAL_CATEGORY_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'financial_entries':
                    descriptions.push('Categoria vinculada à uma entrada financeira');

                    break;
                  case 'children':
                    descriptions.push('Categoria agrupa outras categorias.');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar a categoria financeira`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_CATEGORY_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_FINANCIAL_CATEGORY_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_CATEGORY_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.FINANCIAL_CATEGORY_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {(!entry.is_permanent && this.props.userPermissionIds.includes(permissions.DELETE_FINANCIAL_CATEGORY_PERMISSION_ID)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getCostCenterText(entry) {
    if(entry.cost_center_name) {
      return (
        <div className="financial-category-list__cell-wrapper">

          <p className="financial-category-list__cost-center-text">{entry.cost_center_name}</p>

        </div>
      );
    }

    return (
      <div className="financial-category-list__cell-wrapper">

        <p className="financial-category-list__cost-center-text--empty">

          <i className="fas fa-ban financial-category-list__no-cost-center-icon"></i>{' '}Não definido

        </p>

      </div>
    );
  }

  getTypeText(entry) {
    return (
      <p className={`financial-category-list__type-text__${entry.type.toLowerCase()}`}>{entry.type}</p>
    );
  }

  getParentName(entry) {
    if(!entry.parent) {
      return (<p className="financial-category-list__hierarchy-text--empty">-</p>);
    }

    return (<p className="financial-category-list__hierarchy-text--empty">{entry.parent.name}</p>);
  }

  getParentNameFilter(entry) {
    if(!entry.parent) {
      return '-';
    }

    return entry.parent.name;
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),

    ];

    if(this.state.screenWidth > 710) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "financial-category-list__description-cell"}),
      );
    }

    if(this.state.screenWidth > 450) {
      properties.push(
        Property('cost_center_name', 'Centro de custo', <i className="fas fa-box"></i>, {getDataText: this.getCostCenterText}),
      );
    }

    properties = [
      ...properties,
      Property('parent_id', 'Pertence a', <i className="fas fa-sitemap"></i>, {getDataText: this.getParentName, getFilterText: this.getParentNameFilter}),
      Property('type', 'Tipo', <i className="fas fa-dollar-sign"></i>, {getDataText: this.getTypeText}),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando categoria';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar categoria';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados à categoria financeira serão removidos'}
          confirmText="Deletar categoria"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FINANCIAL_CATEGORY_LIST_PATH,
              text: "Listar categorias"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar categorias"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="financial-category-list"
            title="Lista de categorias financeiras"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.financial_category}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_CATEGORY_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.FINANCIAL_CATEGORY_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova categoria financeira

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FinancialCategoryList;
