import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {FOOD_PRESCRIPTION_PATH} from '../../student/constants';
import {postModel, getModels, getModel, setUrlParameters} from '../../../utils/functions';
import {CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT, PROTEIN_TO_KCAL_CONVERSION_CONTANT, FAT_TO_KCAL_CONVERSION_CONTANT} from '../../../utils/fyd_food';
import FoodPrescriptionData from './food_prescription_data';

class FoodPrescriptionAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_prescription: {
        name: "",
        description: "",
        target_gender: "",
        target_weight: "",
        main_objective: "",
        target_energy: "",
        target_carbohydrate_percentage: null,
        target_protein_percentage: null,
        target_fat_percentage: null,
        target_carbohydrate_intake: null,
        target_protein_intake: null,
        target_fat_intake: null,
        is_active: true,
        food_recipe_associations: [],
        food_ingredient_associations: [],
        meal_period_note_map: {}
      },
      student: null,
      food_recipes: [],
      meal_classifications: [],
      meal_periods: [],
      includedMealPeriodIds: new Set(),
      addMealPeriodSelect: '',
      food_ingredients: [],
      food_classifications: [],
      prescriptionItemList: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };

    this.recipeDataMap = new Map();
    this.ingredientDataMap = new Map();

    this.itemCounter = 1;
  }

  async componentDidMount() {
    const update = {loading: false};

    let student = null;

    let user_id = (new URLSearchParams(this.props.location.search)).get('student_id');

    if(user_id) {
      user_id = parseInt(user_id);
    }

    if(user_id) {
      student = await getModel(`${routes.STUDENT_GET_API}${user_id}`);

      if(student) {
        update.student = student;
      }
    }

    let food_recipes = getModels(setUrlParameters(routes.FOOD_RECIPES_GET_API, {is_active: true, user_id: student !== null ? student.id : null}));
    let meal_classifications = getModels(routes.MEAL_CLASSIFICATIONS_GET_API);
    let meal_periods = getModels(routes.MEAL_PERIODS_GET_API);
    let food_ingredients = getModels(routes.FOOD_INGREDIENTS_GET_API);
    let food_classifications = getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));

    food_recipes = await food_recipes;

    if(food_recipes) {
      update.food_recipes = food_recipes;
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
      return;
    }

    meal_classifications = await meal_classifications;

    if(meal_classifications) {
      update.meal_classifications = meal_classifications;
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
      return;
    }

    meal_periods = await meal_periods;

    if(meal_periods) {
      update.meal_periods = meal_periods;
      update.meal_periods.sort((a, b) => {
        if (a.start_time !== null && b.start_time !== null) {
          const result = a.start_time.localeCompare(b.start_time);

          if (result !== 0) {
            return result;
          }

          return a.name.localeCompare(b.name);
        }
        else if (a.start_time !== null && b.start_time === null) {
          return -1;
        }
        else if (a.start_time === null && b.start_time !== null) {
          return 1;
        }
        else {
          return a.name.localeCompare(b.name);
        }
      });
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
      return;
    }

    food_ingredients = await food_ingredients;

    if(food_ingredients) {
      update.food_ingredients = food_ingredients;
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
      return;
    }

    food_classifications = await food_classifications;

    if(food_classifications) {
      update.food_classifications = food_classifications;
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  getRecipeIgredientList(food_recipe) {
    const ingredientList = [
      ...food_recipe.advanced_ingredient_associations.map((advanced_ingredient_association) => {
        return {
          id: advanced_ingredient_association.id,
          key: `recipe:advanced_ingredient_association:${advanced_ingredient_association.id}`,
          name: advanced_ingredient_association.name,
          order: advanced_ingredient_association.order,
          quantity: advanced_ingredient_association.quantity,
          isBasic: false,
          data: advanced_ingredient_association.advanced_ingredient
        };
      }),
      ...food_recipe.basic_ingredient_associations.map((basic_ingredient_association) => {
        const food_ingredient = basic_ingredient_association.food_ingredient_source_association.food_ingredient;

        const newEntry = {
          id: basic_ingredient_association.id,
          key: `recipe:basic_ingredient_association:${basic_ingredient_association.id}`,
          food_ingredient_source_association: basic_ingredient_association.food_ingredient_source_association,
          food_ingredient_measurement_association: food_ingredient.food_measurement_associations.find((entry) => {
            return entry.id === basic_ingredient_association.food_ingredient_measurement_association_id;
          }),
          food_ingredient_measurement_association_id: basic_ingredient_association.food_ingredient_measurement_association_id,
          name: basic_ingredient_association.name,
          order: basic_ingredient_association.order,
          quantity: basic_ingredient_association.quantity,
          isBasic: true,
          data: food_ingredient,
          food_ingredient: food_ingredient,
        };

        return newEntry;
      })
    ];

    ingredientList.sort((a, b) => a.order - b.order);

    return ingredientList;
  }

  autoSetMeasurementAssociation(ingredient_association) {
    let filteredAssociations = ingredient_association.food_ingredient.food_measurement_associations.filter((association) => association.processing_method_id === ingredient_association.food_ingredient_source_association.processing_method_id);

    if (ingredient_association.food_ingredient.default_measurement_source_id !== null) {
      filteredAssociations = filteredAssociations.filter((association) => association.food_info_source_id === ingredient_association.food_ingredient.default_measurement_source_id);
    }

    if (filteredAssociations.length === 1) {
      ingredient_association.food_ingredient_measurement_association = filteredAssociations[0];
    }
    else if (filteredAssociations.length > 1 && ingredient_association.food_ingredient_source_association.default_measurement_id) {
      let measurement_association = filteredAssociations.find((association) => {
        return association.food_measurement_id === ingredient_association.food_ingredient_source_association.default_measurement_id;
      });

      if (measurement_association) {
        ingredient_association.food_ingredient_measurement_association = measurement_association;
      }
    }
  }

  async loadFoodRecipe(recipe_association) {
    let food_recipe;

    if (this.recipeDataMap.has(recipe_association.food_recipe.id)) {
      food_recipe = this.recipeDataMap.get(recipe_association.food_recipe.id);
    }
    else {
      food_recipe = await getModel(setUrlParameters(`${routes.FOOD_RECIPE_GET_API}${recipe_association.food_recipe.id}`, {load_basic_ingredients: true, load_preparation_steps: true}));

      if(food_recipe) {
        this.recipeDataMap.set(recipe_association.food_recipe.id, food_recipe);
      }
    }

    if(food_recipe) {
      recipe_association.ingredientList = this.getRecipeIgredientList(food_recipe);

      recipe_association.food_recipe = food_recipe;
      recipe_association.loaded = true;

      this.setState({prescriptionItemList: [...this.state.prescriptionItemList]});
    }
  }

  async loadFoodIngredient(ingredient_association) {
    let food_ingredient;

    if (this.ingredientDataMap.has(ingredient_association.food_ingredient.id)) {
      food_ingredient = this.ingredientDataMap.get(ingredient_association.food_ingredient.id);
    }
    else {
      food_ingredient = await getModel(setUrlParameters(`${routes.FOOD_INGREDIENT_GET_API}${ingredient_association.food_ingredient.id}`, {load_nutrient_classification: true}));

      if(food_ingredient) {
        this.ingredientDataMap.set(ingredient_association.food_ingredient.id, food_ingredient);
      }
    }

    if(food_ingredient) {
      ingredient_association.food_ingredient = food_ingredient;
      ingredient_association.loaded = true;

      let filteredAssociations = food_ingredient.source_associations;

      if (food_ingredient.default_nutrient_source_id !== null) {
        filteredAssociations = food_ingredient.source_associations.filter((association) => association.food_info_source_id === food_ingredient.default_nutrient_source_id);
      }

      if (filteredAssociations.length === 1) {
        ingredient_association.food_ingredient_source_association = filteredAssociations[0];

        this.autoSetMeasurementAssociation(ingredient_association);
      }

      this.setState({prescriptionItemList: [...this.state.prescriptionItemList]});
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name.startsWith('meal_period_note')) {
      const selection = name.split(':');

      const meal_period_id = parseInt(selection[1]);

      const food_prescription = {...this.state.food_prescription};

      food_prescription.meal_period_note_map = {...food_prescription.meal_period_note_map};

      if (value.length > 0) {
        food_prescription.meal_period_note_map[meal_period_id] = value;
      }
      else {
        delete food_prescription.meal_period_note_map[meal_period_id];
      }

      this.setState({food_prescription});
    }
    else if (name === 'target_carbohydrate_intake' || name === 'target_protein_intake' || name === 'target_fat_intake') {
      const newData = {...this.state.food_prescription};

      if (value.length > 0) {
        newData[name] = parseFloat(value);
      }
      else {
        newData[name] = null;
      }

      if (newData.target_carbohydrate_intake === null || newData.target_protein_intake === null || newData.target_fat_intake === null) {
        newData.target_carbohydrate_percentage = null;
        newData.target_protein_percentage = null;
        newData.target_fat_percentage = null;
      }
      else {
        const carbohydrateEnergy = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * newData.target_carbohydrate_intake;
        const proteinEnergy = PROTEIN_TO_KCAL_CONVERSION_CONTANT * newData.target_protein_intake;
        const fatEnergy = FAT_TO_KCAL_CONVERSION_CONTANT * newData.target_fat_intake;

        const totalEnergy = carbohydrateEnergy + proteinEnergy + fatEnergy;

        newData.target_carbohydrate_percentage = Math.round(10000 * carbohydrateEnergy / totalEnergy) / 100;
        newData.target_protein_percentage = Math.round(10000 * proteinEnergy / totalEnergy) / 100;
        newData.target_fat_percentage = Math.round(10000 * fatEnergy / totalEnergy) / 100;
      }

      this.setState({food_prescription: newData});
    }
    else if (name === 'addMealPeriodSelect') {
      this.setState({addMealPeriodSelect: value});
    }
    else {
      const newData = {...this.state.food_prescription, [name]: value};

      this.setState({food_prescription: newData});
    }
  }

  targetPercentagesAreValid() {
    if (this.state.food_prescription.target_carbohydrate_intake !== null || this.state.food_prescription.target_protein_intake !== null || this.state.food_prescription.target_fat_intake !== null) {
      if (this.state.food_prescription.target_carbohydrate_percentage === null || this.state.food_prescription.target_protein_percentage === null || this.state.food_prescription.target_fat_percentage === null) {
        return false;
      }
    }

    return true;
  }

  itemsAreValid() {
    return this.state.prescriptionItemList.length > 0 &&
           this.state.prescriptionItemList.every((entry) => {
             if (!entry.isRecipe) {
               return (entry.quantity !== null && entry.quantity > 0) &&
                      entry.food_ingredient_source_association != null &&
                      entry.food_ingredient_measurement_association != null &&
                      entry.weekdays > 0 &&
                      (entry.name === null || entry.name.length > 0);
             }

             return entry.weekdays > 0 && (entry.name === null || entry.name.length > 0);
           });
  }

  inputsAreValid() {
    return this.state.food_prescription.name.length > 0 &&
           this.itemsAreValid() &&
           this.targetPercentagesAreValid();
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.food_prescription};

    if (typeof this.state.food_prescription.target_weight === 'string') {
      if (this.state.food_prescription.target_weight.length > 0) {
        data.target_weight = parseFloat(this.state.food_prescription.target_weight);
      }
      else {
        data.target_weight = null;
      }
    }

    if (typeof this.state.food_prescription.target_energy === 'string') {
      if (this.state.food_prescription.target_energy.length > 0) {
        data.target_energy = parseFloat(this.state.food_prescription.target_energy);
      }
      else {
        data.target_energy = null;
      }
    }

    data.food_recipe_associations = [];
    data.food_ingredient_associations = [];

    if(this.state.student !== null) {
      data.user_id = this.state.student.id;
    }

    for (const entry of this.state.prescriptionItemList) {
      if (entry.isRecipe) {
        const recipe_adjustments = entry.recipe_adjustments.filter((adjustment) => {
          if (adjustment.food_recipe_advanced_ingredient_association_id !== null) {
            return !entry.ingredientList.some((item) => !item.isBasic && adjustment.food_recipe_advanced_ingredient_association_id === item.id && adjustment.quantity === item.quantity);
          }
          else {
            return !entry.ingredientList.some((item) => item.isBasic && adjustment.food_recipe_basic_ingredient_association_id === item.id && adjustment.food_ingredient_measurement_association_id === item.food_ingredient_measurement_association_id && adjustment.quantity === item.quantity);
          }
        });

        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_recipe_id: entry.food_recipe.id,
          name: entry.name,
          priority: entry.priority,
          weekdays: entry.weekdays,
          recipe_adjustments
        };

        if (entry.id !== null) {
          associationData.id = entry.id;
        }

        data.food_recipe_associations.push(associationData);
      }
      else {
        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_ingredient_source_association_id: entry.food_ingredient_source_association.id,
          food_ingredient_measurement_association_id: entry.food_ingredient_measurement_association.id,
          name: entry.name,
          quantity: entry.quantity,
          priority: entry.priority,
          weekdays: entry.weekdays
        };

        if (entry.id !== null) {
          associationData.id = entry.id;
        }

        data.food_ingredient_associations.push(associationData);
      }
    }

    try {
      await postModel(routes.FOOD_PRESCRIPTION_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('value');
            //
            //         break;
            //       case 'penalty_value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('penalty_value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    if (this.state.student !== null) {
      this.props.history.replace(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}`);
    }
    else {
      this.props.history.replace(routes.FOOD_PRESCRIPTION_LIST_PATH);
    }
  }

  addRecipeAssociation(recipe, meal_period) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    const filteredAssociations = prescriptionItemList.filter((association) => association.meal_period.id === meal_period.id);

    const newAssociation = {
      id: null,
      key: `meal_period:${meal_period.id}:recipe_association:${this.itemCounter}`,
      meal_period: meal_period,
      meal_period_id: meal_period.id,
      isRecipe: true,
      food_recipe: recipe,
      recipe_adjustments: [],
      name: null,
      priority: filteredAssociations.length + 1,
      weekdays: 127, // binary math: mon=1, tue=2, wed=4, thu=8, fri=16, sat=32, sun=64
      loaded: false
    };

    this.itemCounter += 1;

    this.loadFoodRecipe(newAssociation);

    prescriptionItemList.push(newAssociation);
    prescriptionItemList.sort((a, b) => a.priority - b.priority);

    this.setState({prescriptionItemList});
  }

  addIngredientAssociation(food_ingredient, meal_period) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    const filteredAssociations = prescriptionItemList.filter((association) => association.meal_period.id === meal_period.id);

    const newAssociation = {
      id: null,
      key: `meal_period:${meal_period.id}:ingredient_association:${this.itemCounter}`,
      meal_period: meal_period,
      meal_period_id: meal_period.id,
      isRecipe: false,
      food_ingredient,
      food_ingredient_source_association: null,
      food_ingredient_measurement_association: null,
      name: null,
      quantity: null,
      priority: filteredAssociations.length + 1,
      weekdays: 127, // binary math: mon=1, tue=2, wed=4, thu=8, fri=16, sat=32, sun=64
      loaded: false
    };

    this.itemCounter += 1;

    this.loadFoodIngredient(newAssociation);

    prescriptionItemList.push(newAssociation);
    prescriptionItemList.sort((a, b) => a.priority - b.priority);

    this.setState({prescriptionItemList});
  }

  onRemovePrescriptionItem(association) {
    const prescriptionItemList = [...this.state.prescriptionItemList.filter((entry) => entry.key !== association.key)];

    prescriptionItemList.filter((entry) => entry.meal_period.id === association.meal_period.id).forEach((entry, index) => {
      entry.priority = index + 1;
    });

    this.setState({prescriptionItemList});
  }

  onUpdateItemAssociationWeekday(association, weekdayIndex) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    const weekdayValue = Math.pow(2, weekdayIndex);

    if ((association.weekdays & weekdayValue) > 0) {
      association.weekdays = association.weekdays ^ weekdayValue;
    }
    else {
      association.weekdays = association.weekdays | weekdayValue;
    }

    this.setState({prescriptionItemList});
  }

  onSelectIngredientSourceAssociation(ingredient_association, associationId) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    ingredient_association.food_ingredient_source_association = ingredient_association.food_ingredient.source_associations.find((association) => association.id === associationId);

    ingredient_association.food_ingredient_measurement_association = null;
    this.autoSetMeasurementAssociation(ingredient_association);

    this.setState({prescriptionItemList});
  }

  onUpdateIngredientQuantity(ingredient_association, quantity) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    ingredient_association.quantity = quantity;

    this.setState({prescriptionItemList});
  }

  onUpdateIngredientName(ingredient_association, name) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    ingredient_association.name = name;

    this.setState({prescriptionItemList});
  }

  onSelectIngredientMeasurementAssociation(ingredient_association, associationId) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    ingredient_association.food_ingredient_measurement_association = ingredient_association.food_ingredient.food_measurement_associations.find((association) => association.id === associationId);

    this.setState({prescriptionItemList});
  }

  onUpdateRecipeQuantity(prescriptionAssociation, itemAssociation, quantity) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    let recipe_adjustment = prescriptionAssociation.recipe_adjustments.find((entry) => itemAssociation.isBasic ? entry.food_recipe_basic_ingredient_association_id === itemAssociation.id : entry.food_recipe_advanced_ingredient_association_id === itemAssociation.id);

    if (!recipe_adjustment) {
      recipe_adjustment = {
        food_recipe_basic_ingredient_association_id: null,
        food_recipe_advanced_ingredient_association_id: null,
        food_ingredient_measurement_association_id: null,
        quantity: 0,
      };

      if (itemAssociation.isBasic) {
        recipe_adjustment.food_recipe_basic_ingredient_association_id = itemAssociation.id;
        recipe_adjustment.food_ingredient_measurement_association = itemAssociation.food_ingredient.food_measurement_associations.find((association) => association.id === itemAssociation.food_ingredient_measurement_association_id);
        recipe_adjustment.food_ingredient_measurement_association_id = recipe_adjustment.food_ingredient_measurement_association.id;
      }
      else {
        recipe_adjustment.food_recipe_advanced_ingredient_association_id = itemAssociation.id;
      }

      prescriptionAssociation.recipe_adjustments.push(recipe_adjustment);
    }

    recipe_adjustment.quantity = quantity;

    this.setState({prescriptionItemList});
  }

  onSelectRecipeMeasurementAssociation(prescriptionAssociation, itemAssociation, associationId) {
    const prescriptionItemList = [...this.state.prescriptionItemList];

    let recipe_adjustment = prescriptionAssociation.recipe_adjustments.find((entry) => itemAssociation.isBasic ? entry.food_recipe_basic_ingredient_association_id === itemAssociation.id : entry.food_recipe_advanced_ingredient_association_id === itemAssociation.id);

    if (!recipe_adjustment) {
      recipe_adjustment = {
        food_recipe_basic_ingredient_association_id: null,
        food_recipe_advanced_ingredient_association_id: null,
        food_ingredient_measurement_association_id: null,
        quantity: 0,
      };

      if (itemAssociation.isBasic) {
        recipe_adjustment.food_recipe_basic_ingredient_association_id = itemAssociation.id;
      }
      else {
        recipe_adjustment.food_recipe_advanced_ingredient_association_id = itemAssociation.id;
      }

      prescriptionAssociation.recipe_adjustments.push(recipe_adjustment);
    }

    recipe_adjustment.food_ingredient_measurement_association = itemAssociation.food_ingredient.food_measurement_associations.find((association) => association.id === associationId);
    recipe_adjustment.food_ingredient_measurement_association_id = recipe_adjustment.food_ingredient_measurement_association.id;

    this.setState({prescriptionItemList});
  }

  onSwitchPriority(association, offset) {
    const filteredAssociations = this.state.prescriptionItemList.filter((entry) => entry.meal_period.id === association.meal_period.id);
    const entriesCount = filteredAssociations.length;
    const originalPriority = association.priority;
    const newPriority = association.priority + offset;

    if(newPriority <= 0 || newPriority > entriesCount) {
      return;
    }

    const listCopy = [...this.state.prescriptionItemList];

    let switchedAssociation = filteredAssociations.find((entry) => entry.priority === newPriority);

    association.priority = newPriority;
    switchedAssociation.priority = originalPriority;

    listCopy.sort((a, b) => a.priority - b.priority);

    this.setState({
      prescriptionItemList: listCopy
    });
  }

  addMealPeriod() {
    if(!this.state.addMealPeriodSelect) {
      return;
    }

    const mealPeriodId = parseInt(this.state.addMealPeriodSelect);

    const includedMealPeriodIds = new Set(this.state.includedMealPeriodIds);

    includedMealPeriodIds.add(mealPeriodId);

    this.setState({
      includedMealPeriodIds,
      addMealPeriodSelect: ''
    });
  }

  onUpdateFoodPrescriptionParameter(parameter, value) {
    const newData = {...this.state.food_prescription, [parameter]: value};

    this.setState({food_prescription: newData});
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}` : routes.FOOD_PRESCRIPTION_LIST_PATH,
            text: this.state.student !== null ? 'Gerenciar aluno' : 'Listar dietas'
          },
          {
            path: routes.FOOD_PRESCRIPTION_ADD_PATH,
            text: "Adicionar dieta"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar dieta alimentar"
        loading={this.state.loading}
      >

        <FoodPrescriptionData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          food_prescription={this.state.food_prescription}
          prescriptionItemList={this.state.prescriptionItemList}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          itemsAreValid={this.itemsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${FOOD_PRESCRIPTION_PATH}` : routes.FOOD_PRESCRIPTION_LIST_PATH}
          food_recipes={this.state.food_recipes}
          meal_classifications={this.state.meal_classifications}
          meal_periods={this.state.meal_periods}
          includedMealPeriodIds={this.state.includedMealPeriodIds}
          food_ingredients={this.state.food_ingredients}
          food_classifications={this.state.food_classifications}
          student={this.state.student}
          userPermissionIds={this.props.userPermissionIds}
          addRecipeAssociation={(recipe, meal_period) => this.addRecipeAssociation(recipe, meal_period)}
          addIngredientAssociation={(ingredient, meal_period) => this.addIngredientAssociation(ingredient, meal_period)}
          onRemovePrescriptionItem={(association) => this.onRemovePrescriptionItem(association)}
          onSwitchPriority={(association, offset) => this.onSwitchPriority(association, offset)}
          onUpdateItemAssociationWeekday={(association, index) => this.onUpdateItemAssociationWeekday(association, index)}
          onSelectIngredientSourceAssociation={(ingredient_association, associationId) => this.onSelectIngredientSourceAssociation(ingredient_association, associationId)}
          onSelectIngredientMeasurementAssociation={(ingredient_association, associationId) => this.onSelectIngredientMeasurementAssociation(ingredient_association, associationId)}
          onUpdateIngredientQuantity={(ingredient_association, quantity) => this.onUpdateIngredientQuantity(ingredient_association, quantity)}
          onUpdateIngredientName={(ingredient_association, name) => this.onUpdateIngredientName(ingredient_association, name)}
          onUpdateRecipeQuantity={(prescriptionAssociation, itemAssociation, quantity) => this.onUpdateRecipeQuantity(prescriptionAssociation, itemAssociation, quantity)}
          onSelectRecipeMeasurementAssociation={(prescriptionAssociation, itemAssociation, associationId) => this.onSelectRecipeMeasurementAssociation(prescriptionAssociation, itemAssociation, associationId)}
          addMealPeriod={() => this.addMealPeriod()}
          addMealPeriodSelect={this.state.addMealPeriodSelect}
          onUpdateFoodPrescriptionParameter={(parameter, value) => this.onUpdateFoodPrescriptionParameter(parameter, value)}
        />

      </ContentFrame>
    );
  }
}

export default FoodPrescriptionAdd;
