import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {COACH_ROLE, HEAD_COACH_ROLE} from '../../constants';
import {getModel, patchModel, getModels} from '../../utils/functions';
import RoleExperienceLevelData from './role_experience_level_data';

class RoleExperienceLevelEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role_experience_level: {
        name: "",
        description: "",
        salary_reference: "",
        role_id: "",
        is_active: true,
      },
      salary_map: {},
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let role_experience_level = getModel(`${routes.ROLE_EXPERIENCE_LEVEL_GET_API}${this.props.match.params.roleExperienceLevelId}`);
    let services = getModels(routes.EXPERIMENTAL_CLASS_SERVICES_GET_API);

    role_experience_level = await role_experience_level;

    if(role_experience_level) {
      update.role_experience_level = {...this.state.role_experience_level, ...role_experience_level};

      if(role_experience_level.salary_map) {
        update.salary_map = role_experience_level.salary_map;
      }

      services = await services;

      if(services) {
        update.services = services;
        update.services.sort((a, b) => b.localeCompare(a));

        for (const service of update.services) {
          if (!update.salary_map[service]) {
            update.salary_map[service] = [0, 0, 0, 0];
          }
        }
      }

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name.startsWith('salary_map:')) {
      const selection = name.split(':');
      const service = selection[1];
      const salaryIndex = parseInt(selection[2]);

      const salary_map = {};

      for (const [key, value] of Object.entries(this.state.salary_map)) {
        salary_map[key] = [...value];
      }

      salary_map[service][salaryIndex] = parseFloat(value);

      this.setState({salary_map});
    }
    else {
      const newData = {...this.state.role_experience_level, [name]: value};

      this.setState({
        role_experience_level: newData
      });
    }
  }

  inputsAreValid() {
    let salaryMapIsValid = true;

    if (this.state.role_experience_level.role_id && (parseInt(this.state.role_experience_level.role_id) === COACH_ROLE.id || parseInt(this.state.role_experience_level.role_id) === HEAD_COACH_ROLE.id)) {
      for (const service of this.state.services) {
        salaryMapIsValid = this.state.salary_map[service].every((value) => value && value > 0);

        if (!salaryMapIsValid) {
          break;
        }
      }
    }

    return salaryMapIsValid &&
           this.state.role_experience_level.name.length > 0 &&
           (this.state.role_experience_level.salary_reference && this.state.role_experience_level.salary_reference > 0) &&
           (this.state.role_experience_level.role_id && this.state.role_experience_level.role_id > 0);
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.role_experience_level};
    delete data.role_id
    data.salary_reference = parseFloat(data.salary_reference);

    if (this.state.role_experience_level.role_id === COACH_ROLE.id || this.state.role_experience_level.role_id === HEAD_COACH_ROLE.id) {
      data.salary_map = {};

      for (const [key, value] of Object.entries(this.state.salary_map)) {
        data.salary_map[key] = value.map((entry) => parseFloat(entry));
      }
    }
    else {
      data.salary_map = null;
    }

    try {
      await patchModel(`${routes.ROLE_EXPERIENCE_LEVEL_PATCH_API}${this.props.match.params.roleExperienceLevelId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado para este tipo de usuário');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH,
            text: "Níveis de experiência"
          },
          {
            path: `${routes.ROLE_EXPERIENCE_LEVEL_EDIT_PATH}${this.props.match.params.roleExperienceLevelId}`,
            text: "Editar nível"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar nível de experiência"
        loading={this.state.loading}
      >

        <RoleExperienceLevelData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          role_experience_level={this.state.role_experience_level}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.ROLE_EXPERIENCE_LEVEL_LIST_PATH}
          salary_map={this.state.salary_map}
          services={this.state.services}
        />

      </ContentFrame>
    );
  }
}

export default RoleExperienceLevelEdit;
