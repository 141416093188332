import React from 'react';
import { Link } from 'react-router-dom';
import {P_T_SERVICE_AWAITING_PHYSICAL_EVALUATION_STATUS,
        P_T_SERVICE_TRAINING_PERIOD_ENABLED_STATUS,
        P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS,
        P_T_SERVICE_PENDING_PERIOD_SUPPORT_RESPONSE_STATUS,
        P_T_SERVICE_PENDING_GENERAL_SUPPORT_RESPONSE_STATUS,
        SUPPORT_CATEGORY_PT_PERIOD_ID} from '../../constants';
import * as routes from '../../constants';
import * as userPaths from '../student/constants';
import ContentFrame from '../content_frame';
import {DefaultSubSectionTitle} from '../../utils/default_section';
import {getModels,
        getLocalDateIsoString,
        setUrlParameters,
        getDefaultLocalDateStringFromIsoString,
        getAsLocalDate} from '../../utils/functions';
import ModelTable, {Property} from '../../utils/model_table';
import ContextPopup from '../../components/context_popup';
import './personal_training_service_report.scss';


class PersonalTrainingServiceReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      loadingData: true,
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth,
    };
  }

  async getStudents() {
    const parameters = {};

    return await getModels(setUrlParameters(routes.PERSONAL_TRAINING_SERVICE_STUDENTS_GET_API, parameters));
  }

  async reloadStudents(setLoading=true) {
    if (setLoading) {
      this.setState({loadingData: true});
    }

    let students = this.getStudents();

    const update = {};

    if(setLoading) {
      update.loadingData = false;
    }

    students = await students;

    if (students) {
      const today = new Date();
      const todayIsodate = getLocalDateIsoString(today);

      update.students = students.map((student) => {
        const entry = {...student};

        let date;
        let timeDiff;
        let daysPassed;

        let appointmentMessage = null;
        let physicalEvaluationUpToDate = false;

        if (Object.hasOwn(entry, 'next_appointment') && entry.next_appointment !== null) {
          appointmentMessage = `${entry.next_appointment.appointment_type_name} agendada para o dia ${getDefaultLocalDateStringFromIsoString(entry.next_appointment.scheduled_at)}`;
        }
        else if (entry.physical_evaluation_status !== null && entry.physical_evaluation_status.next_date !== null) {
          if (entry.physical_evaluation_status.next_date.slice(0, 10) < todayIsodate) {
            date = getAsLocalDate(entry.physical_evaluation_status.next_date);
            timeDiff = Math.abs(today.getTime() - date.getTime());
            daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

            appointmentMessage = `Avaliação física liberada há ${daysPassed} dias`;
          }
          else {
            physicalEvaluationUpToDate = true;
            appointmentMessage = `Próxima avaliação física liberada a partir do dia ${getDefaultLocalDateStringFromIsoString(entry.physical_evaluation_status.next_date)}`;
          }
        }
        else {
          appointmentMessage = `Ainda não realizou nenhuma avaliação física`;
        }

        if (entry.open_support_tickets.length > 0) {
          let statusMessage = P_T_SERVICE_PENDING_GENERAL_SUPPORT_RESPONSE_STATUS;
          entry.personal_training_service_status_weight = 8;
          entry.style_modifier = '--purple';

          let lastMessageAt = null;

          for (const ticket of entry.open_support_tickets) {
            if (lastMessageAt === null || lastMessageAt < ticket.updated_at) {
              lastMessageAt = ticket.updated_at;
            }

            if (ticket.category_id === SUPPORT_CATEGORY_PT_PERIOD_ID) {
              lastMessageAt = ticket.updated_at;
              statusMessage = P_T_SERVICE_PENDING_PERIOD_SUPPORT_RESPONSE_STATUS;
              break;
            }
          }

          let infoMessage = "O aluno enviou uma mensagem pelo canal de suporte que precisa ser respondida ou finalizada.";

          date = getAsLocalDate(lastMessageAt);
          timeDiff = Math.abs(today.getTime() - date.getTime());
          daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

          entry.personal_training_service_status_days_passed = daysPassed;
          statusMessage += ` (${daysPassed})`;

          entry.personal_training_service_status_text = (
            <div className="personal-training-service-report__status">

              <div className="personal-training-service-report__status__info-container">

                <div className="personal-training-service-report__status__text-wrapper">

                  <p className="personal-training-service-report__status__text">

                    {statusMessage}

                  </p>

                  {appointmentMessage !== null &&
                    <p className="personal-training-service-report__status__sub-text">

                      {appointmentMessage}

                    </p>
                  }

                </div>

                <i
                  className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, infoMessage)}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>

              </div>

            </div>
          );
        }
        else if (entry.personal_training_service_status.status === P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS) {
          let statusMessage = 'Revisar periodização (Nutrição';
          entry.personal_training_service_status_weight = 6;
          entry.style_modifier = '--red-2';

          let infoMessage = "O aluno passou por uma avaliação nutricional e precisa ter sua prescrição de treino revisada para estar em sincronia com o acompanhamento de sua dieta.";

          date = getAsLocalDate(entry.personal_training_service_status.status_updated_at);
          timeDiff = Math.abs(today.getTime() - date.getTime());
          daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

          entry.personal_training_service_status_days_passed = daysPassed;
          statusMessage += ` | ${daysPassed})`;

          entry.personal_training_service_status_text = (
            <div className="personal-training-service-report__status">

              <div className="personal-training-service-report__status__info-container">

                <div className="personal-training-service-report__status__text-wrapper">

                  <p className="personal-training-service-report__status__text">

                    {statusMessage}

                  </p>

                  {appointmentMessage !== null &&
                    <p className="personal-training-service-report__status__sub-text">

                      {appointmentMessage}

                    </p>
                  }

                </div>

                <i
                  className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, infoMessage)}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>

              </div>

            </div>
          );
        }
        else if ((entry.personal_training_service_status.status === P_T_SERVICE_AWAITING_PHYSICAL_EVALUATION_STATUS && entry.personal_training_service_status.final_date !== null && entry.personal_training_service_status.final_date < entry.personal_training_service_status.status_updated_at.slice(0, 10)) ||
            (entry.personal_training_service_status.initial_date === null && (entry.physical_evaluation_status === null || entry.physical_evaluation_status.next_date === null))) {
          let statusMessage = P_T_SERVICE_AWAITING_PHYSICAL_EVALUATION_STATUS;
          entry.personal_training_service_status_weight = 2;
          entry.personal_training_service_status_days_passed = 0;
          entry.style_modifier = '--gray';

          let infoMessage = "O aluno ainda não realizou sua primeira avaliação física.";

          if (entry.personal_training_service_status.status_updated_at !== null) {
            date = getAsLocalDate(entry.personal_training_service_status.status_updated_at);
            timeDiff = Math.abs(today.getTime() - date.getTime());
            daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

            if (daysPassed >= 18) {
              entry.personal_training_service_status_weight = 5;
              entry.style_modifier = '--red-2';

              infoMessage = `Faz pelo menos 18 dias que o aluno se matriculou na academia e ainda não realizou sua primeira avaliação física. Neste caso, ative uma periodização assim que possível mesmo sem realizar a avaliação.`;
            }
            else if (daysPassed >= 11) {
              entry.style_modifier = '--yellow';

              infoMessage = `Faz ${daysPassed} dias que o aluno se matriculou na academia e ainda não realizou sua primeira avaliação física. Procure incentivar ele a realizar a avaliação.`;
            }

            entry.personal_training_service_status_days_passed = daysPassed;

            statusMessage += ` (${daysPassed})`;
          }

          entry.personal_training_service_status_text = (
            <div className="personal-training-service-report__status">

              <div className="personal-training-service-report__status__info-container">

                <div className="personal-training-service-report__status__text-wrapper">

                  <p className="personal-training-service-report__status__text">

                    {statusMessage}

                  </p>

                  {appointmentMessage !== null &&
                    <p className="personal-training-service-report__status__sub-text">

                      {appointmentMessage}

                    </p>
                  }

                </div>

                <i
                  className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, infoMessage)}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>

              </div>

            </div>
          );
        }
        else {
          if (entry.personal_training_service_status.initial_date === null) {
            let statusMessage = P_T_SERVICE_TRAINING_PERIOD_ENABLED_STATUS;
            entry.personal_training_service_status_weight = 7;
            entry.personal_training_service_status_days_passed = 0;
            entry.style_modifier = '--red-3';

            let infoMessage = "O aluno já realizou sua avaliação física e aguarda a ativação de sua periodização de treino.";

            if (entry.physical_evaluation_status && entry.physical_evaluation_status.last_exam_completed_at) {
              date = getAsLocalDate(entry.physical_evaluation_status.last_exam_completed_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.personal_training_service_status_days_passed = daysPassed;

              statusMessage += ` (${daysPassed})`;

              infoMessage = `O aluno já realizou sua avaliação física há ${daysPassed} dias e aguarda a ativação de sua periodização de treino.`;
            }

            entry.personal_training_service_status_text = (
              <div className="personal-training-service-report__status">

                <div className="personal-training-service-report__status__info-container">

                  <div className="personal-training-service-report__status__text-wrapper">

                    <p className="personal-training-service-report__status__text">

                    {statusMessage}

                    </p>

                    {appointmentMessage !== null &&
                      <p className="personal-training-service-report__status__sub-text">

                        {appointmentMessage}

                      </p>
                    }

                  </div>

                  <i
                    className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                    onMouseEnter={(event) => this.onShowHoverdata(event.target, infoMessage)}
                    onMouseLeave={(event) => this.onHideHoverdata()}
                  >
                  </i>

                </div>

              </div>
            );
          }
          else if(entry.personal_training_service_status.progress > 1 && entry.personal_training_service_status.final_date < todayIsodate) {
            date = getAsLocalDate(entry.personal_training_service_status.final_date);
            timeDiff = Math.abs(today.getTime() - date.getTime());
            daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

            entry.personal_training_service_status_text = (
              <div className="personal-training-service-report__status">

                <div  className="personal-training-service-report__status__info-container">

                  <div className="personal-training-service-report__status__text-wrapper">

                    <p className="personal-training-service-report__status__text">

                      Ativar nova periodização ({daysPassed})

                    </p>

                    {appointmentMessage !== null &&
                      <p className="personal-training-service-report__status__sub-text">

                        {appointmentMessage}

                      </p>
                    }

                  </div>

                  <i
                    className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                    onMouseEnter={(event) => this.onShowHoverdata(event.target, `O aluno já ultrapassou 100% da periodização ativa e passaram-se ${daysPassed} dias da data final da periodização vigente.`)}
                    onMouseLeave={(event) => this.onHideHoverdata()}
                  >
                  </i>

                </div>

              </div>
            );
            entry.personal_training_service_status_weight = physicalEvaluationUpToDate ? 4 : 3;
            entry.personal_training_service_status_days_passed = daysPassed;
            entry.style_modifier = '--red-1';
          }
          else {
            date = getAsLocalDate(entry.personal_training_service_status.final_date);
            timeDiff = Math.abs(today.getTime() - date.getTime());
            daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

            entry.style_modifier = '';

            let infoMessage = "O aluno se encontra dentro do período vigente da periodização.";

            if (today > date) {
              daysPassed -= 1;
              entry.style_modifier = '--yellow';

              infoMessage = "O aluno já passou da data final da periodização vigente mas ainda não atingiu 100% dela.";
            }

            const valueStyle = {};
            const backgroundCoverStyle = {width: `${100 * (1 - entry.personal_training_service_status.progress)}%`};

            if (entry.personal_training_service_status.progress > 0.45) {
              valueStyle.right = `${Math.max(100 * (1 - entry.personal_training_service_status.progress), 0)}%`;
            }
            else {
              valueStyle.left = `${100 * entry.personal_training_service_status.progress}%`;
            }

            entry.personal_training_service_status_text = (
              <div className="personal-training-service-report__status">

                <div className="personal-training-service-report__status__info-container">

                  <div className="personal-training-service-report__status__text-wrapper">

                    <p className="personal-training-service-report__status__text">

                      Periodização ativa ({getDefaultLocalDateStringFromIsoString(entry.personal_training_service_status.initial_date)} - {getDefaultLocalDateStringFromIsoString(entry.personal_training_service_status.final_date)})

                    </p>

                    {appointmentMessage !== null &&
                      <p className="personal-training-service-report__status__sub-text">

                        {appointmentMessage}

                      </p>
                    }

                  </div>

                  <i
                    className="fa-solid fa-circle-info personal-training-service-report__status__info-icon"
                    onMouseEnter={(event) => this.onShowHoverdata(event.target, infoMessage)}
                    onMouseLeave={(event) => this.onHideHoverdata()}
                  >
                  </i>

                </div>

                <div className="personal-training-service-report__status__progress">

                  <p className="personal-training-service-report__status__progress__value" style={valueStyle}>{(100 * entry.personal_training_service_status.progress).toFixed(1)}%</p>

                  <div className="personal-training-service-report__status__progress__background"></div>
                  <div className="personal-training-service-report__status__progress__background-cover" style={backgroundCoverStyle}></div>

                </div>

              </div>
            );
            entry.personal_training_service_status_weight = 1;
            entry.personal_training_service_status_days_passed = daysPassed;
          }
        }

        return entry;
      });
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.setState({loadingData: true});

    let students_process = this.reloadStudents(false);

    await students_process;

    this.setState({loadingData: false});

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: (<p className="personal-training-service-report__status__info-text">{text}</p>),
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getStudentProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('personal_training_service_status_text', 'Situação', <i className="fa-solid fa-temperature-half"></i>, {
        getCellClassName: (entry) => entry.style_modifier ? `personal-training-service-report__status-cell${entry.style_modifier}` : 'personal-training-service-report__status-cell',
        getSortCallback: (a, b) => {
          let sortResult = 0;

          if (a.personal_training_service_status_weight === b.personal_training_service_status_weight) {
            sortResult = a.personal_training_service_status_days_passed - b.personal_training_service_status_days_passed;
          }
          else {
            sortResult = a.personal_training_service_status_weight - b.personal_training_service_status_weight;
          }

          if (sortResult === 0) {
            return b.name.localeCompare(a.name);
          }

          return sortResult;
        },
      }),
    ];

    return properties;
  }

  getStudentActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-link-button"
          to={`${routes.STUDENT_EDIT_PATH}${entry.id}${userPaths.PERSONAL_TRAINING_PATH}`}
        >

            <i className="fas fa-link"></i>

        </Link>

      </div>
    );
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.PERSONAL_TRAINING_SERVICE_REPORT_PATH,
              text: "Periodização - Acompanhamento"
            },
          ]}
          titleIcon={<i className="fa-solid fa-image-portrait"></i>}
          title="Periodização - Acompanhamento de alunos"
          loading={this.state.loadingData}
        >

          <div className="personal-training-service-report__wrapper">

            <DefaultSubSectionTitle
              icon={<i className="fa-solid fa-list"></i>}
              text="Acompanhamento"
            />

            <ModelTable
              properties={this.getStudentProperties()}
              getActions={(entry) => this.getStudentActions(entry)}
              data={this.state.students}
              initialOrderBy="personal_training_service_status_text"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default PersonalTrainingServiceReport;
