import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {NUTRITIONIST_ROLE} from '../../constants';
import {postModel, getModels, setUrlParameters} from '../../utils/functions';
import NutritionistData from './nutritionist_data';

class NutritionistAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nutritionist: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_experience_level_id: null,
        is_active: true,
        has_full_access: false
      },
      role_experience_levels: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getRoleExperienceLevels() {
    const parameters = {
      is_active: true,
      role_id: NUTRITIONIST_ROLE.id
    };

    return await getModels(setUrlParameters(routes.ROLE_EXPERIENCE_LEVELS_GET_API, parameters));
  }

  async componentDidMount() {
    const role_experience_levels = await this.getRoleExperienceLevels();

    this.setState({
      loading: false,
      role_experience_levels
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.nutritionist, [name]: value};

    this.setState({
      nutritionist: newData
    });
  }

  inputsAreValid() {
    return this.state.nutritionist.name.length > 0 &&
           this.state.nutritionist.email.match(/.+@.+\..+/) != null &&
           this.state.nutritionist.password.length > 0 &&
           this.state.nutritionist.password_confirmation.length > 0;
  }

  async saveData() {
    if(this.state.nutritionist.password !== this.state.nutritionist.password_confirmation) {
      this.setState({
        highlights: ['password', 'password_confirmation'],
        showWarningMessage: true,
        warningMessage: "Confirmarção de senha não bate."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let nutritionistData = {...this.state.nutritionist};

    if (!nutritionistData.has_full_access && nutritionistData.role_experience_level_id && nutritionistData.role_experience_level_id > 0) {
      nutritionistData.role_experience_level_id = parseInt(nutritionistData.role_experience_level_id);
    }
    else {
      nutritionistData.role_experience_level_id = null;
    }

    try {
      await postModel(routes.NUTRITIONIST_POST_API, nutritionistData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 203) {
            if(error.message.includes('User from another unit')) {
              warningMessages.push('Email já cadastrado em outra unidade. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
            else if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para cadastrar este email. Por favor, entre em contato com o responsável pela unidade FYD para realizar o cadastro');
              highlights.push('email');
            }
          }
          else if(error.code === 208) {
            if(error.message.includes('User can not inactivate himself')) {
              warningMessages.push('Usuário não pode se desativar');
              highlights.push('is_active');
            }
            else if(error.message.includes('Invalid email')) {
              warningMessages.push('Emáil inválido. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.NUTRITIONIST_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.NUTRITIONIST_LIST_PATH,
            text: "Listar nutricionistas"
          },
          {
            path: routes.NUTRITIONIST_ADD_PATH,
            text: "Adicionar nutricionista"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar nutricionista"
        loading={this.state.loading}
      >

        <NutritionistData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          nutritionist={this.state.nutritionist}
          role_experience_levels={this.state.role_experience_levels}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.NUTRITIONIST_LIST_PATH}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default NutritionistAdd;
