import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {getModel, patchModel} from '../../../utils/functions';
import MealPeriodData from './meal_period_data';

class MealPeriodEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meal_period: {
        name: "",
        start_time: null,
        end_time: null,
        description: "",
        visible_at_history_form: false
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let update = {
      loading: false
    };

    let meal_period = getModel(`${routes.MEAL_PERIOD_GET_API}${this.props.match.params.mealPeriodId}`);

    meal_period = await meal_period;

    if(meal_period) {
      update.meal_period = {...this.state.meal_period, ...meal_period}
    }
    else {
      this.props.history.replace(routes.MEAL_PERIOD_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'cost_center_id') {
      name = 'remove_cost_center';
      value = !(value && value > 0);
    }

    const newData = {...this.state.meal_period, [name]: value};

    this.setState({
      meal_period: newData
    });
  }

  inputsAreValid() {
    return this.state.meal_period.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.meal_period};

    try {
      await patchModel(`${routes.MEAL_PERIOD_PATCH_API}${this.props.match.params.mealPeriodId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.MEAL_PERIOD_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.MEAL_PERIOD_LIST_PATH,
            text: "Listar períodos"
          },
          {
            path: `${routes.MEAL_PERIOD_EDIT_PATH}${this.props.match.params.mealPeriodId}`,
            text: "Editar período"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar período de refeição"
        loading={this.state.loading}
      >

        <MealPeriodData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          meal_period={this.state.meal_period}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.MEAL_PERIOD_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default MealPeriodEdit;
