import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import './content_frame.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';

class ContentFrame extends React.Component {
  getHistoryLinks() {
    return this.props.headerHistory.map(({path, text}, index) => {
      return (
        <Link
          key={index.toString()}
          className="content-frame__header__history-link"
          to={path}
          disabled={this.props.location.pathname === path}
        >
          <p className="content-frame__header__history-link__text">{text}</p>

        </Link>
      );
    });
  }

  render() {
    return this.props.loading ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <header className="content-frame__header">

          <div className="content-frame__header__history-container">

            {this.getHistoryLinks()}

          </div>

          <div className="content-frame__header__title-container">

            {this.props.titleIcon ?
              (
                <div className="content-frame__header__title-icon">

                  {this.props.titleIcon}

                </div>
              ):
              null
            }

            <div className="content-frame__header__title-text-wrapper">

              <h2 className="content-frame__header__title">

                {this.props.title}

              </h2>

              {this.props.titleDescription ?
                (
                  <p className="content-frame__header__title-description">

                    ({this.props.titleDescription})

                  </p>
                ):
                null
              }

            </div>

          </div>

        </header>

        {this.props.children}

      </React.Fragment>
    );
  }
}

export default ContentFrame;
