import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import * as userPaths from '../student/constants';
import {
  DEFAULT_UNKNOWN_ERROR_MESSAGE,
  F_P_SERVICE_AWAITING_FIRST_EVALUATION_STATUS,
  F_P_SERVICE_DIET_ENABLED_STATUS,
  F_P_SERVICE_LAST_EVALUATION_PERFORMED_STATUS,
  F_P_SERVICE_CANCELED_STATUS,
  F_P_SERVICE_FINISHED_STATUS,
  F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS,
  F_P_SERVICE_PENDING_NUTRITIONIST_RESPONSE_STATUS,
  F_P_SERVICE_PENDING_TECHINICAL_RESPONSE_STATUS,
  SUPPORT_CATEGORY_FP_DIET_ID} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels,
        patchModel,
        deleteModel,
        getLocalDateIsoString,
        setUrlParameters,
        getDefaultLocalDateStringFromIsoString,
        getAsLocalDate,
        getPhoneText,
        getAsLocalDatetime} from '../../utils/functions';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import * as permissions from '../../permissions';
import './food_prescription_report.scss';


class FoodPrescriptionReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let leadResolved = queryParameters.get('lead_resolved');

    if(!leadResolved) {
      leadResolved = 'false';
    }

    this.state = {
      leadResolved,
      leadResolvedInput: leadResolved,
      students: [],
      leads: [],
      leadSectionVisible: false,
      loadingData: true,
      entryToToggleResolve: null,
      trackingStatusToDelete: null,
      trackingStatusToFinish: null,
      leadToDeleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getFoodPrescriptionStudents() {
    const parameters = {};

    return await getModels(setUrlParameters(routes.FOOD_PRESCRIPTION_SERVICE_STUDENTS_GET_API, parameters));
  }

  async getFoodPrescriptionLeads() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_SERVICE_LEAD_PERMISSION_ID)) {
      const parameters = {
        resolved: this.state.leadResolved
      };

      if(parameters.resolved === 'all') {
        parameters.resolved = null;
      }

      return await getModels(setUrlParameters(routes.NUTRI_SERVICE_LEADS_GET_API, parameters));
    }

    return [];
  }

  async refreshData(setLoading=true) {
    if (setLoading) {
      this.setState({loadingData: true});
    }

    let leads = this.getFoodPrescriptionLeads();

    const update = {};

    if(setLoading) {
      update.loadingData = false;
    }

    leads = await leads;

    if(leads) {
      update.leads = leads;
    }

    this.setState(update);
  }

  async reloadFoodPrescriptionStudents(setLoading=true) {
    if (setLoading) {
      this.setState({loadingData: true});
    }

    let students = this.getFoodPrescriptionStudents();

    const update = {};

    if(setLoading) {
      update.loadingData = false;
    }

    students = await students;

    if (students) {
      const today = new Date();
      const todayIsodate = getLocalDateIsoString(today);

      update.students = students.map((student) => {
        const entry = {
          ...student,
          mayFinishService: false
        };

        let date;
        let timeDiff;
        let daysPassed;

        if (student.open_support_tickets.length > 0) {
          let lastMessageAt = null;
          let mainStatus = F_P_SERVICE_PENDING_TECHINICAL_RESPONSE_STATUS;

          for (const ticket of student.open_support_tickets) {
            if (lastMessageAt === null || lastMessageAt < ticket.updated_at) {
              lastMessageAt = ticket.updated_at;
            }

            if (ticket.category_id === SUPPORT_CATEGORY_FP_DIET_ID) {
              lastMessageAt = ticket.updated_at;
              mainStatus = F_P_SERVICE_PENDING_NUTRITIONIST_RESPONSE_STATUS;
              break;
            }
          }

          date = getAsLocalDate(lastMessageAt);
          timeDiff = Math.abs(today.getTime() - date.getTime());
          daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

          entry.nutri_service_status = `${mainStatus} (${daysPassed})`;
          entry.nutri_service_status_weight = 7;
          entry.nutri_service_status_days_passed = daysPassed;
          entry.style_modifier = '--red';
        }
        else {
          switch (student.food_prescription_service_status) {
            case F_P_SERVICE_AWAITING_FIRST_EVALUATION_STATUS:
              date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.nutri_service_status = `${F_P_SERVICE_AWAITING_FIRST_EVALUATION_STATUS} (${daysPassed})`;
              entry.nutri_service_status_weight = 3;
              entry.nutri_service_status_days_passed = daysPassed;
              entry.style_modifier = '--yellow';

              break;
            case F_P_SERVICE_DIET_ENABLED_STATUS:
              if (entry.food_prescription && entry.food_prescription.final_date && entry.food_prescription_service_status_updated_at.slice(0, 10) < entry.food_prescription.final_date) {
                date = getAsLocalDate(entry.food_prescription.final_date);
                timeDiff = Math.abs(today.getTime() - date.getTime());
                daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

                entry.nutri_service_status_days_passed = daysPassed;

                if (todayIsodate >= entry.food_prescription.final_date) {
                  entry.nutri_service_status = `Aguardando reavaliação (${daysPassed})`;
                  entry.nutri_service_status_weight = 4;
                  entry.style_modifier = '--yellow';

                  if (daysPassed > 14) {
                    entry.mayFinishService = true;
                  }
                }
                else {
                  entry.nutri_service_status = `Dieta ativa (${getDefaultLocalDateStringFromIsoString(entry.food_prescription.initial_date)} - ${getDefaultLocalDateStringFromIsoString(entry.food_prescription.final_date)}) (${-1 * (daysPassed + 1)})`;
                  entry.nutri_service_status_weight = 2;
                  entry.style_modifier = '--green';
                }
              }
              else {
                date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
                timeDiff = Math.abs(today.getTime() - date.getTime());
                daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

                entry.nutri_service_status = `Dieta liberada (${daysPassed})`;
                entry.nutri_service_status_weight = 5;
                entry.nutri_service_status_days_passed = daysPassed;
                entry.style_modifier = '--red';
              }

              break;
            case F_P_SERVICE_LAST_EVALUATION_PERFORMED_STATUS:
              date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.nutri_service_status = `Serviço finalizado (${daysPassed})`;
              entry.nutri_service_status_weight = 1;
              entry.nutri_service_status_days_passed = daysPassed;
              entry.style_modifier = '--gray';

              break;
            case F_P_SERVICE_CANCELED_STATUS:
              date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.nutri_service_status = `Serviço cancelado (${daysPassed})`;
              entry.nutri_service_status_weight = 1;
              entry.nutri_service_status_days_passed = daysPassed;
              entry.style_modifier = '--gray';

              break;
            case F_P_SERVICE_FINISHED_STATUS:
              date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.nutri_service_status = `Serviço finalizado (${daysPassed})`;
              entry.nutri_service_status_weight = 1;
              entry.nutri_service_status_days_passed = daysPassed;
              entry.style_modifier = '--gray';

              break;
            case F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS:
              date = getAsLocalDate(entry.food_prescription_service_status_updated_at);
              timeDiff = Math.abs(today.getTime() - date.getTime());
              daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;

              entry.nutri_service_status = `${F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS} (${daysPassed})`;
              entry.nutri_service_status_weight = 6;
              entry.nutri_service_status_days_passed = daysPassed;
              entry.style_modifier = '--red';

              break;
            default:
              entry.nutri_service_status = 'INDEFINIDO';
              entry.nutri_service_status_weight = 10;
              entry.nutri_service_status_days_passed = 0;
              entry.style_modifier = '--red';
          }
        }

        return entry;
      });
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.setState({loadingData: true});

    let students_process = this.reloadFoodPrescriptionStudents(false);
    let leads_process = this.refreshData(false);

    await students_process;
    await leads_process;

    this.setState({loadingData: false});

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.leadResolved !== this.state.leadResolved) {
      this.refreshData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getStudentProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('nutri_service_status', 'Situação', <i className="fa-solid fa-temperature-half"></i>, {
        getCellClassName: (entry) => entry.style_modifier ? `food-prescription-report__status-cell${entry.style_modifier}` : 'food-prescription-report__status-cell',
        getSortCallback: (a, b) => {
          let sortResult = 0;

          if (a.nutri_service_status_weight === b.nutri_service_status_weight) {
            sortResult = a.nutri_service_status_days_passed - b.nutri_service_status_days_passed;
          }
          else {
            sortResult = a.nutri_service_status_weight - b.nutri_service_status_weight;
          }

          if (sortResult === 0) {
            return b.name.localeCompare(a.name);
          }

          return sortResult;
        },
      }),
    ];

    return properties;
  }

  getStudentActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-link-button"
          to={`${routes.STUDENT_EDIT_PATH}${entry.id}${userPaths.FOOD_PRESCRIPTION_PATH}`}
        >

            <i className="fas fa-link"></i>

        </Link>

        {(this.props.userPermissionIds.includes(permissions.MANAGE_USER_STATUS_PERMISSION_ID) && entry.mayFinishService) &&
          <button
            className="model-table__default-edit-button food-prescription-report__finish-button"
            onClick={() => this.onFinishTrackingStatus(entry.id)}
          >

            <i className="fa-solid fa-xmark"></i>

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.MANAGE_USER_STATUS_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteTrackingStatus(entry.id)}
          >

            <i className="far fa-trash-alt"></i>


          </button>
        }

      </div>
    );
  }

  getCreatedAtText(entry) {
    const date = getAsLocalDatetime(entry.created_at, false);

    return (
      <div className="food-prescription-report__cell-wrapper">

        <p className="food-prescription-report__date-text">

          {`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}

        </p>

      </div>
    );
  }

  getCreatedAtFilter(entry) {
    const date = getAsLocalDatetime(entry.created_at, false);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  getResolvedText(entry) {
    const text = entry.resolved ? 'Resolvido' : 'Não resolvido';;

    return (
      <div className="food-prescription-report__cell-wrapper">

        <p className={`food-prescription-report__resolved-text${entry.resolved ? '' : '--alert'}`}>

          {text}

        </p>

      </div>
    );
  }

  getResolvedFilter(entry) {
    return entry.resolved ? 'Resolvido' : 'Não resolvido';
  }

  getIdentityText(entry) {
    return (
      <div className="food-prescription-report__identity-container">

        <p className="food-prescription-report__identity-entry">

          <i className="fa-solid fa-user food-prescription-report__identity-entry__icon"></i>{' '}{entry.name}

        </p>

        <p className="food-prescription-report__identity-entry">

          <i className="fa-solid fa-envelope food-prescription-report__identity-entry__icon"></i>{' '}{entry.email}

        </p>

        <p className="food-prescription-report__identity-entry">

          <i className="fa-solid fa-phone food-prescription-report__identity-entry__icon"></i>{' '}{getPhoneText(entry.phone)}

        </p>

      </div>
    );
  }

  getIdentityFilter(entry) {
    return `${entry.name} ${entry.email} ${getPhoneText(entry.phone)}`;
  }

  getLeadProperties() {
    let properties = [
      Property('created_at', 'Criado em', <i className="fas fa-calendar-day"></i>, {
        getDataText: this.getCreatedAtText,
        getFilterText: this.getCreatedAtFilter,
      }),
      Property('name', 'Identidade', <i className="fa-solid fa-tag"></i>, {
        getDataText: this.getIdentityText,
        getFilterText: this.getIdentityFilter,
        sortable: false
      }),
      Property('promotional_coupon_code', 'Cupom', <i className="fa-solid fa-ticket"></i>),
      Property('main_objective', 'Objetivo', <i className="fa-solid fa-bullseye"></i>),
      Property('exercise_frequency', 'Frequência de treino', <i className="fa-solid fa-calendar-days"></i>),
      Property('exercise_activity', 'Atividade', <i className="fa-solid fa-dumbbell"></i>),
      Property('resolved', 'Status', <i className="fa-solid fa-circle-info"></i>, {
        getDataText: this.getResolvedText,
        getFilterText: this.getResolvedFilter,
      })
    ];

    return properties;
  }

  getLeadActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {entry.is_from_same_unit &&
          <Link
            className="model-table__default-link-button"
            to={`${routes.STUDENT_MANAGE_PATH}${entry.user_id}`}
          >

              <i className="fas fa-link"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.EDIT_SERVICE_LEAD_PERMISSION_ID) &&
          <button
            className="model-table__default-edit-button food-prescription-report__resolve-toggle-button"
            onClick={() => this.onToggleResolveEntry(entry)}
          >

            {entry.resolved ? (
              <i className="fa-solid fa-box-open"></i>
            ) : (
              <i className="fa-solid fa-box-archive"></i>
            )}

          </button>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_SERVICE_LEAD_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteLead(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  onToggleResolveEntry(entry) {
    this.setState({
      entryToToggleResolve: entry,
      leadToDeleteId: null,
      trackingStatusToDelete: null,
      trackingStatusToFinish: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteLead(entryId) {
    this.setState({
      leadToDeleteId: entryId,
      trackingStatusToDelete: null,
      trackingStatusToFinish: null,
      entryToToggleResolve: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onDeleteTrackingStatus(entryId) {
    this.setState({
      trackingStatusToDelete: entryId,
      trackingStatusToFinish: null,
      leadToDeleteId: null,
      entryToToggleResolve: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onFinishTrackingStatus(entryId) {
    this.setState({
      trackingStatusToDelete: null,
      trackingStatusToFinish: entryId,
      leadToDeleteId: null,
      entryToToggleResolve: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      trackingStatusToDelete: null,
      trackingStatusToFinish: null,
      leadToDeleteId: null,
      entryToToggleResolve: null,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.leadToDeleteId !== null) {
      try{
        if(await deleteModel(`${routes.NUTRI_SERVICE_LEAD_DELETE_API}${this.state.leadToDeleteId}`)) {
          this.refreshData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.entryToToggleResolve !== null) {
      const data = {resolved: !this.state.entryToToggleResolve.resolved};

      try{
        if(await patchModel(`${routes.NUTRI_SERVICE_LEAD_PATCH_API}${this.state.entryToToggleResolve.id}`, data)) {
          this.refreshData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.trackingStatusToDelete !== null) {
      try{
        if(await deleteModel(routes.STUDENT_PRESCRIPTION_SERVICE_STATUS_DELETE_API.replace('{student_id}', this.state.trackingStatusToDelete.toString()))) {
          this.reloadFoodPrescriptionStudents();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.trackingStatusToFinish !== null) {
      const data = {service_status: F_P_SERVICE_FINISHED_STATUS};

      try{
        if(await patchModel(routes.STUDENT_PRESCRIPTION_SERVICE_STATUS_PATCH_API.replace('{student_id}', this.state.trackingStatusToFinish.toString()), data)) {
          this.reloadFoodPrescriptionStudents();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      leadToDeleteId: null,
      trackingStatusToDelete: null,
      trackingStatusToFinish: null,
      entryToToggleResolve: null,
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.leadToDeleteId !== null) {
        return 'Deletando lead';
      }
      else if(this.state.entryToToggleResolve !== null) {
        return `${this.state.entryToToggleResolve.resolved ? 'Desarquivando' : 'Arquivando'} lead`;
      }
      else if(this.state.trackingStatusToDelete !== null) {
        return 'Resetando status de acompanhamento';
      }
      else if(this.state.trackingStatusToFinish !== null) {
        return 'Finalizando status de acompanhamento';
      }

      return 'INDEFINIDO';
    }
    else if(this.state.confirmFailed) {
      if(this.state.leadToDeleteId !== null) {
        return 'Falha ao deletar';
      }
      else if(this.state.entryToToggleResolve !== null) {
        return `Falha ao ${this.state.entryToToggleResolve.resolved ? 'desarquivar' : 'resolver'}`;
      }
      else if(this.state.trackingStatusToDelete !== null) {
        return 'Falha ao resetar status de acompanhamento';
      }
      else if(this.state.trackingStatusToFinish !== null) {
        return 'Falha finalizar status de acompanhamento';
      }

      return 'INDEFINIDO';
    }

    if(this.state.leadToDeleteId !== null) {
      return 'Deletar lead';
    }
    else if(this.state.entryToToggleResolve !== null) {
      return `${this.state.entryToToggleResolve.resolved ? 'Desarquivar' : 'Resolver'} lead`;
    }
    else if(this.state.trackingStatusToDelete !== null) {
      return 'Resetar status de acompanhamento';
    }
    else if(this.state.trackingStatusToFinish !== null) {
      return 'Finalizar status de acompanhamento';
    }

    return 'INDEFINIDO';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.leadToDeleteId !== null) {
      return 'Todos os dados relacionados ao lead serão removidos permanentemente.';
    }
    else if(this.state.entryToToggleResolve !== null) {
      return `O cadastro de lead será ${this.state.entryToToggleResolve.resolved ? 'desarquivado' : 'arquivado'}.`;
    }
    else if(this.state.trackingStatusToDelete !== null) {
      return 'O status de acompanhamento do aluno será resetado.';
    }
    else if(this.state.trackingStatusToFinish !== null) {
      return 'O status de acompanhamento do aluno será considerado como finalizado.';
    }

    return 'INDEFINIDO';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.leadToDeleteId !== null) {
      return 'Deletar lead';
    }
    else if(this.state.entryToToggleResolve !== null) {
      return this.state.entryToToggleResolve.resolved ? 'Desarquivar' : 'Resolver';
    }
    else if(this.state.trackingStatusToDelete !== null) {
      return 'Resetar status';
    }
    else if(this.state.trackingStatusToFinish !== null) {
      return 'Finalizar status';
    }

    return 'INDEFINIDO';
  }

  mayResetLeadFilterInputs() {
    if(this.state.leadResolvedInput !== 'false') {
      return true;
    }

    return false;
  }

  mayUpdateLeadInputs() {
    if(this.state.leadResolvedInput !== this.state.leadResolved) {
      return true;
    }

    return false;
  }

  resetLeadFilterInputs() {
    this.setState({
      leadResolvedInput: 'false',
    });
  }

  applyLeadFilterChanges() {
    if(this.mayUpdateLeadInputs()) {
      this.props.history.replace(setUrlParameters(routes.FOOD_PRESCRIPTION_SERVICE_REPORT_PATH, {
        lead_resolved: this.state.leadResolvedInput
      }));

      this.setState({
        leadResolved: this.state.leadResolvedInput,
      });
    }
  }

  getLeadResolvedOptions() {
    return [
      SelectOption('true', 'Resolvido'),
      SelectOption('false', 'Não resolvido'),
      SelectOption('all', 'Todos'),
    ];
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.leadToDeleteId !== null ||
                   this.state.entryToToggleResolve !== null ||
                   this.state.trackingStatusToDelete !== null ||
                   this.state.trackingStatusToFinish !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FOOD_PRESCRIPTION_SERVICE_REPORT_PATH,
              text: "Nutrição - Acompanhamento"
            },
          ]}
          titleIcon={<i className="fa-solid fa-image-portrait"></i>}
          title="Nutrição - Acompanhamento de alunos"
          loading={this.state.loadingData}
        >

          <div className="food-prescription-report__wrapper">

            {this.props.userPermissionIds.includes(permissions.VIEW_SERVICE_LEAD_PERMISSION_ID) &&
              <React.Fragment>

                <section className="food-prescription-report__lead-section">

                  <header
                    className="food-prescription-report__lead-section__header"
                    onClick={() => {
                      this.setState({leadSectionVisible: !this.state.leadSectionVisible});
                    }}
                  >

                    <h3 className="food-prescription-report__lead-section__header__text">
                      <i className="fa-solid fa-triangle-exclamation food-prescription-report__lead-section__header__text-icon"></i>
                      Leads cadastrados
                    </h3>

                    {this.state.leadSectionVisible ?
                      <i className="fas fa-chevron-down food-prescription-report__lead-section__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up food-prescription-report__lead-section__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container food-prescription-report__lead-section__content"
                    pose={this.state.leadSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                  >

                    <div className="food-prescription-report__lead-section__wrapper--stretched">

                      <div className="food-prescription-report__filters">

                        <header className="food-prescription-report__filters__header">

                          <h4 className="food-prescription-report__filters__header__text">Filtros</h4>

                        </header>

                        <div className="food-prescription-report__filters__inputs">

                          <div className="food-prescription-report__filters__inputs-wrapper">

                            <DefaultInput
                              name="leadResolvedInput"
                              label="Situação do cadastro"
                              type="select"
                              handleInputChange={(event) => this.handleInputChange(event)}
                              value={this.state.leadResolvedInput || ''}
                              options={this.getLeadResolvedOptions()}
                            />

                          </div>

                          {this.mayResetLeadFilterInputs() &&
                            <button
                              className="food-prescription-report__filters__reset-button"
                              onClick={() => this.resetLeadFilterInputs()}
                            >

                              <i className="fas fa-times"></i>

                            </button>
                          }

                          {this.mayUpdateLeadInputs() &&
                            <button
                              className="food-prescription-report__filters__refresh-button"
                              onClick={() => this.applyLeadFilterChanges()}
                            >

                              <i className="fas fa-sync"></i>

                            </button>
                          }

                        </div>

                      </div>

                      <HorizontalRule />

                      <ModelTable
                        storageKey="leads_list"
                        properties={this.getLeadProperties()}
                        getActions={(entry) => this.getLeadActions(entry)}
                        data={this.state.leads}
                        initialOrderBy="created_at"
                        initialOrderIsDecrescent={true}
                      >

                      </ModelTable>

                    </div>

                  </VerticalAccordionContainer>

                </section>

                <HorizontalRule />

              </React.Fragment>
            }

            <DefaultSubSectionTitle
              icon={<i className="fa-solid fa-list"></i>}
              text="Acompanhamento"
            />

            <ModelTable
              storageKey="clients_list"
              properties={this.getStudentProperties()}
              getActions={(entry) => this.getStudentActions(entry)}
              data={this.state.students}
              initialOrderBy="nutri_service_status"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FoodPrescriptionReport;
