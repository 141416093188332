import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {BOTH_TYPE_NAME} from '../../constants';
import {postModel} from '../../utils/functions';
import FinancialSearchTagData from './financial_search_tag_data';


class FinancialSearchTagAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_search_tag: {
        name: "",
        description: "",
        type: BOTH_TYPE_NAME
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.financial_search_tag, [name]: value};

    this.setState({
      financial_search_tag: newData
    });
  }

  inputsAreValid() {
    return this.state.financial_search_tag.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.financial_search_tag};

    try {
      await postModel(routes.FINANCIAL_SEARCH_TAG_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Palavra-chave já cadastrada');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FINANCIAL_SEARCH_TAG_LIST_PAGE);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FINANCIAL_SEARCH_TAG_LIST_PAGE,
            text: "Listar palavras-chave"
          },
          {
            path: routes.FINANCIAL_SEARCH_TAG_ADD_PAGE,
            text: "Adicionar palavra-chave"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar palavra-chave (financeiro)"
        loading={this.state.loading}
      >

        <FinancialSearchTagData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_search_tag={this.state.financial_search_tag}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FINANCIAL_SEARCH_TAG_LIST_PAGE}
        />

      </ContentFrame>
    );
  }
}

export default FinancialSearchTagAdd;
