import React, { useState } from 'react';
import {VerticalAccordionContainer, VerticalAccordionSubContainer} from '../../utils/pose_containers';
import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import {CONTRACT_EXPIRATION_ALERT_RANGE,
        PAYMENT_ALERT_RANGE,
        PHYSICAL_REVALUATION_THRESHOLD,
        PHYSICAL_REVALUATION_ALERT_RANGE,
        NOTIFICATION_UNREAD_THRESHOLD,
        NOTIFICATION_UNREAD_ALERT_RANGE,
        STUDENT_LAST_TRAINING_ALERT_RANGE,
        STUDENT_LAST_TRAINING_RANGE,
        STUDENT_PERSONAL_TRAINING_ALERT_RANGE,
        OPERATIONAL_TASK_STATUS_PENDING,
        OPERATIONAL_TASK_STATUS_INITIATED,
        OPERATIONAL_TASK_STATUS_FINISHED,
        DEFAULT_UNKNOWN_ERROR_MESSAGE,
        COACH_ACCESS_LEVEL,
        DEFAULT_UNIT_TYPE} from '../../constants';
import * as studentPaths from '../student/constants';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import ConfirmationWindow from '../confirmation_window';
import ContentFrame from '../content_frame';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, patchModel, postModel, getAsLocalDate, setUrlParameters, getCurrencyText} from '../../utils/functions';
import * as paths from '../student/constants';
import * as permissions from '../../permissions';
import './desktop.scss';

function AlertList(props) {
  const [alertsVisible, setAlertsVisible] = useState(true);

  return (
    <section className="desktop__alert-list">

      <header
        className="desktop__alert-list__header"
        onClick={() => setAlertsVisible(!alertsVisible)}
      >

        <h3 className="desktop__alert-list__header-text">{props.title}</h3>

        {alertsVisible ?
          <i className="fas fa-chevron-down desktop__alert-list__header__visible-icon"></i>:
          <i className="fas fa-chevron-up desktop__alert-list__header__visible-icon"></i>
        }

      </header>

      <VerticalAccordionContainer
        className="vertical-accordion-container desktop__alert-list__list-wrapper"
        pose={alertsVisible ? 'verticalOpen' : 'verticalClosed'}>

        <ul className="desktop__alert-list__list">

          {props.children}

        </ul>

      </VerticalAccordionContainer>

    </section>
  );
}

function AlertSubList(props) {
  const [subListVisible, setSubListVisible] = useState(false);

  return (
    <section className="desktop__alert-sub-list">

      <header
        className={`desktop__alert-sub-list__header${props.headerSpecifier}`}
        onClick={() => setSubListVisible(!subListVisible)}
      >

        <div className="desktop__alert-sub-list__header__wrapper">

          {subListVisible ?
            <i className="fas fa-circle desktop__alert-sub-list__header__bullet-icon"></i>:
            <i className="far fa-circle desktop__alert-sub-list__header__bullet-icon"></i>
          }
          <h3 className="desktop__alert-sub-list__header-text">{props.title}</h3>

        </div>

        {subListVisible ?
          <i className="fas fa-chevron-down desktop__alert-sub-list__header__visible-icon"></i>:
          <i className="fas fa-chevron-up desktop__alert-sub-list__header__visible-icon"></i>
        }

      </header>

      <VerticalAccordionSubContainer
        className="vertical-accordion-container desktop__alert-sub-list__list-wrapper"
        pose={subListVisible ? 'verticalSubOpen' : 'verticalSubClosed'}>

        <ul className="desktop__alert-sub-list__list">

          {props.children}

        </ul>

      </VerticalAccordionSubContainer>

    </section>
  );
}

function AlertItem(props) {
  let descriptionText = null;

  if(props.descriptionText) {
    descriptionText = props.descriptionText;
  }
  return (
    <li className="desktop__list-item">

      <div className="desktop__list-item__description-wrapper">
        <span className="desktop__list-item__id-text">{props.id}</span>

        {descriptionText !== null &&
          <span className="desktop__list-item__description-text">
            {descriptionText}
          </span>
        }

        {props.status}
      </div>

      <Link
        className="desktop__default-link-button"
        to={props.linkTo}
      >

          <i className="fas fa-link desktop__default-link-button__icon"></i> {props.entityName}

      </Link>

    </li>
  );
}

class Desktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      transactions: [],
      active_students: [],
      exams: [],
      operational_tasks: [],
      taskCommentaryToUpdate: '',
      taskToInitiate: null,
      taskToFinish: null,
      onRegisterTask: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      taskVisibilityMap: new Map(),
      loadingData: true,
      screenWidth: window.innerWidth,
    };

    this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER = [
      OPERATIONAL_TASK_STATUS_INITIATED,
      OPERATIONAL_TASK_STATUS_PENDING,
      OPERATIONAL_TASK_STATUS_FINISHED
    ];
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async getContracs() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_CONTRACT_PERMISSION_ID)) {
      let active_contracts = getModels(`${routes.CONTRACTS_GET_API}?active_only=true`);
      let unaccepted_contracts = getModels(`${routes.CONTRACTS_GET_API}?unaccepted_only=true`);

      return [
        ...(await active_contracts),
        ...(await unaccepted_contracts),
      ];
    }

    return [];
  }

  async getTransactions() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      const finalDate = new Date();
      finalDate.setMonth(finalDate.getMonth()+1);

      const finalDateString = finalDate.toISOString().slice(0, 10);

      return await getModels(`${routes.FINANCIAL_TRANSACTIONS_GET_API}?final_date=${finalDateString}&pending=true&load_description=true`);
    }

    return [];
  }

  async getPhysicalAvaliationExams() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
      return await getModels(`${routes.PHYSICAL_EVALUATION_SCHEDULED_EXAMS_GET}?users_active_only=true&completed=true`);
    }
  }

  async getActiveUsers() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      return await getModels(`${routes.STUDENTS_GET_API}?active_candidate_only=true`); //&load_personal_training=true
    }

    return [];
  }

  async getOperationalTasks() {
    if(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.VIEW_OPERATIONAL_TASK_BASIC_PERMISSION_ID)) {
      const parameters = {
        status_filter: 'open_and_recently_closed',
        my_tasks_only: true
      };

      return await getModels(setUrlParameters(routes.OPERATIONAL_TASKS_GET_API, parameters));
    }

    return [];
  }

  async reloadOperationalTasks(updateState=true) {
    const update = {};

    if (updateState) {
      this.setState({loadingData: true});

      update.loadingData = false;
    }

    let operational_tasks = this.getOperationalTasks();

    operational_tasks = await operational_tasks;

    if(operational_tasks) {
      update.operational_tasks = operational_tasks;

      update.operational_tasks.sort((a, b) => {
        if (a.author_id === this.props.userId && b.author_id !== this.props.userId) {
          return 1;
        }
        else if (b.author_id === this.props.userId && a.author_id !== this.props.userId) {
          return -1;
        }

        const aStatusWeight = this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER.indexOf(a.status);
        const bStatusWeight = this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER.indexOf(b.status);

        if (aStatusWeight !== bStatusWeight) {
          return aStatusWeight - bStatusWeight;
        }
        else if (a.priority !== b.priority) {
          return b.priority - a.priority;
        }

        return b.created_at.localeCompare(a.created_at);
      });
    }

    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  async refreshData() {
    this.setState({loadingData: true});

    let contracts = this.getContracs();
    let transactions = this.getTransactions();
    // let exams = this.getPhysicalAvaliationExams();
    let active_students = this.getActiveUsers();
    let operationalTasksUpdate = this.reloadOperationalTasks(false);

    let update = {
      loadingData: false
    }

    contracts = await contracts;

    if(contracts) {
      update.contracts = contracts;
    }

    transactions = await transactions;

    if(transactions) {
      update.transactions = transactions;
    }

    // exams = await exams;
    //
    // if(exams) {
    //   update.exams = exams;
    // }

    active_students = await active_students;

    if(active_students) {
      update.active_students = active_students;
    }

    operationalTasksUpdate = await operationalTasksUpdate;

    update = {...update, ...operationalTasksUpdate};

    this.setState(update);
  }

  async componentDidMount() {
    this.refreshData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getTodayDate() {
    let dateString = (new Intl.DateTimeFormat('pt-BR', {
      weekday: this.state.screenWidth > 510 ? 'long' : 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })).format(new Date());

    dateString = dateString[0].toLocaleUpperCase() + dateString.slice(1);

    return (
      <h4 className="desktop__today-text">

        {dateString}

      </h4>
    );
  }

  getContractAlerts() {
    const alerts = [];
    const userContract = new Map();

    const todayIsoDate = (new Date()).toISOString().slice(0, 10);
    const today = getAsLocalDate(todayIsoDate);

    const smallStatus = this.state.screenWidth > 480;

    for(let contract of this.state.contracts) {
      const expired = contract.expires_at.slice(0, 10) < todayIsoDate;

      if(!contract.accepted) {
        if(expired) {
          alerts.push(
            {
              sortWeight: 1,
              statusText: `${smallStatus ? 'Contrato i' : 'I'}nválido`,
              linkTo: `${routes.STUDENT_EDIT_PATH}${contract.user_id}${studentPaths.CONTRACT_PATH}`,
              entityName: "Contratos",
              alertType: "",
              contract: contract
            }
          );
        }
        else {
          const date = getAsLocalDate(contract.effective_date);
          const timeDiff = Math.abs(today.getTime() - date.getTime());
          let daysPassed = (today > date ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

          let statusText = `${smallStatus ? 'Contrato n' : 'N'}ão aceito`;

          if(daysPassed > 0) {
            statusText += ` (${daysPassed}`;

            if(smallStatus) {
              statusText += ` dia${daysPassed > 1 ? 's' : ''}`;
            }

            statusText += ')';
          }

          alerts.push(
            {
              sortWeight: 2,
              statusText: statusText,
              linkTo: `${routes.STUDENT_EDIT_PATH}${contract.user_id}${studentPaths.CONTRACT_PATH}`,
              entityName: 'Contratos',
              alertType: "",
              contract: contract
            }
          );
        }
      }
      else if(!contract.canceled) {
        if(userContract.has(contract.user_id) && userContract.get(contract.user_id).days_to_expire < contract.days_to_expire) {
          continue;
        }
        else {
          userContract.set(contract.user_id, contract);
        }
      }
    }

    for(let contract of userContract.values()) {
      if(contract.days_to_expire >= 0 && contract.days_to_expire < CONTRACT_EXPIRATION_ALERT_RANGE) {
        alerts.push(
          {
            sortWeight: Math.max(3 + contract.days_to_expire),
            statusText: contract.days_to_expire === 0 ? `${smallStatus ? 'Contrato e' : 'E'}xpira HOJE` : `${smallStatus ? 'Contrato e' : 'E'}xpira em ${contract.days_to_expire} ${contract.days_to_expire > 1 ? 'dias' : 'dia'}`,
            linkTo: `${routes.STUDENT_MANAGE_PATH}${contract.user_id}`,
            entityName: 'Aluno',
            alertType: "--alert",
            contract: contract
          }
        );
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    return alerts.map((entry) => (
      <AlertItem
        key={`contract_alert_item_${entry.contract.id}`}
        id={entry.contract.username}
        status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
        linkTo={entry.linkTo}
        entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
      />
    ));
  }

  getFinancialAlerts() {
    const alerts = [];

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const smallStatus = this.state.screenWidth > 760;

    for(let transaction of this.state.transactions) {
      const date = getAsLocalDate(transaction.effective_date);
      const timeDiff = Math.abs(date.getTime() - today.getTime());
      let daysToExpire = (date > today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

      if(daysToExpire < PAYMENT_ALERT_RANGE) {
        const type = transaction.is_expense ? 'Dívida' : 'Pagamento';

        // statusText: daysToExpire === 0 ? `${smallStatus ? `${type} v` : 'V'}ence HOJE` : daysToExpire > 0 ? `${smallStatus ? `${type} v` : 'V'}ence em ${daysToExpire} ${daysToExpire > 1 ? 'dias' : 'dia'}` : `${smallStatus ? `${type} v` : 'V'}enceu há ${daysToExpire} ${daysToExpire < -1 ? 'dias' : 'dia'}`,
        alerts.push(
          {
            sortWeight: (transaction.is_expense ? 2 : 1) + daysToExpire,
            statusText: daysToExpire === 0 ? `${smallStatus ? `${type} v` : 'V'}ence HOJE` : daysToExpire > 0 ? `${smallStatus ? `${type} v` : 'V'}ence em ${daysToExpire} ${daysToExpire > 1 ? 'dias' : 'dia'}` : `${smallStatus ? `${type} v` : 'V'}enceu há ${daysToExpire} ${daysToExpire < -1 ? 'dias' : 'dia'}`,
            linkTo: `${transaction.is_expense ? routes.EXPENSE_EDIT_PATH : routes.INCOME_EDIT_PATH}${transaction.financial_entry_id}`,
            entityName: transaction.is_expense ? 'Despesa' : 'Receita',
            alertType: daysToExpire < 0 ? '' : '--alert',
            transaction: transaction,
            daysToExpire: daysToExpire,
            effective_date: date.toLocaleDateString()
          }
        );
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    const subKeys = [];
    const subLists = new Map([['expired', []]]);

    for(const entry of alerts) {
      if(entry.daysToExpire < 0) {
        subLists.get('expired').push(
          <AlertItem
            key={`financial_transaction_alert_item_${entry.transaction.id}`}
            id={`${entry.transaction.is_expense ? 'Dívida' : 'Pagamento'} (${getCurrencyText(entry.transaction.value)})`}
            descriptionText={this.state.screenWidth > 0 ? entry.transaction.entry_description : null}
            // status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
            linkTo={entry.linkTo}
            entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
          />
        );
      }
      else {
        const key = `${entry.effective_date} (${entry.daysToExpire === 0 ? 'HOJE' : `${entry.daysToExpire} dia${entry.daysToExpire > 1 ? 's' : ''}`})`;
        let subList;

        if(!subLists.has(key)) {
          subList = [];

          subKeys.push(key);
          subLists.set(key, subList);
        }
        else {
          subList = subLists.get(key);
        }

        subList.push(<AlertItem
          key={`financial_transaction_alert_item_${entry.transaction.id}`}
          id={`${entry.transaction.is_expense ? 'Dívida' : 'Pagamento'} (${getCurrencyText(entry.transaction.value)})`}
          descriptionText={this.state.screenWidth > 0 ? entry.transaction.entry_description : null}
          // status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
          linkTo={entry.linkTo}
          entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
        />);
      }
    }

    const output = [];

    output.push(
      <AlertSubList
        title="Expirados"
        key="desktop:financial_sub_list:expired"
        headerSpecifier="--red"
      >

        {subLists.get('expired')}

      </AlertSubList>
    );

    for(const key of subKeys) {
      output.push(
        <AlertSubList
          title={key}
          key={`desktop:financial_sub_list:${key.slice(0, 10)}`}
          headerSpecifier="--yellow"
        >

          {subLists.get(key)}

        </AlertSubList>
      );
    }

    return output;

    // return alerts.map((entry) => (
    //   <AlertItem
    //     key={`financial_transaction_alert_item_${entry.transaction.id}`}
    //     id={`${entry.transaction.is_expense ? 'Dívida' : 'Pagamento'} (${getCurrencyText(entry.transaction.value)})`}
    //     descriptionText={this.state.screenWidth > 0 ? entry.transaction.entry_description : null}
    //     status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
    //     linkTo={entry.linkTo}
    //     entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
    //   />
    // ));
  }

  getPhysicalRevaluationAlerts() {
    const alerts = [];

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const smallStatus = this.state.screenWidth > 480;

    const studentMap = new Map();

    for(let exam of this.state.exams) {
      if(studentMap.has(exam.user_id)) {
        if(studentMap.get(exam.user_id).scheduled_date < exam.scheduled_date) {
          studentMap.set(exam.user_id, exam);
        }
      }
      else {
        studentMap.set(exam.user_id, exam);
      }
    }

    for(let exam of studentMap.values()) {
      const date = getAsLocalDate(exam.scheduled_date);
      const timeDiff = Math.abs(today.getTime() - date.getTime());
      let daysPassed = (date < today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

      if(daysPassed > PHYSICAL_REVALUATION_THRESHOLD) {
        const daysAfterEnabled = daysPassed - PHYSICAL_REVALUATION_THRESHOLD;

        alerts.push(
          {
            sortWeight: daysAfterEnabled,
            statusText: smallStatus ? `Liberada (${daysAfterEnabled} dia${daysAfterEnabled > 1 ? 's' : ''})` : `Reavaliação liberada há ${daysAfterEnabled} dia${daysAfterEnabled > 1 ? 's' : ''}`,
            linkTo: `${routes.STUDENT_EDIT_PATH}${exam.user_id}${paths.PHYSICAL_AVALIATION_PATH}`,
            entityName: 'Aluno',
            alertType: daysAfterEnabled > PHYSICAL_REVALUATION_ALERT_RANGE ? '' : '--alert',
            exam: exam
          }
        );
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    return alerts.map((entry) => (
      <AlertItem
        key={`physical_revaluation_alert_item_${entry.exam.id}`}
        id={entry.exam.student_name}
        status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
        linkTo={entry.linkTo}
        entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
      />
    ));
  }

  getNotificationAlerts() {
    const alerts = [];

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    for(let student of this.state.active_students) {
      if(student.email_is_validated === false) {
        alerts.push(
          {
            sortWeight: 10000,
            statusText: 'Email inválido',
            linkTo: `${routes.STUDENT_MANAGE_PATH}${student.id}`,
            entityName: 'Aluno',
            alertType: '',
            student: student
          }
        );
      }
      else if(student.block_email_notification === true) {
        alerts.push(
          {
            sortWeight: 10000,
            statusText: 'Aluno bloqueou email',
            linkTo: `${routes.STUDENT_MANAGE_PATH}${student.id}`,
            entityName: 'Aluno',
            alertType: '',
            student: student
          }
        );
      }
      else if(student.last_notification_received_at && !student.last_notification_visualization_at) {
        const date = getAsLocalDate(student.last_notification_received_at);
        const timeDiff = Math.abs(today.getTime() - date.getTime());
        let daysPassed = (date < today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

        if(daysPassed > NOTIFICATION_UNREAD_THRESHOLD) {
          const daysAfterEnabled = daysPassed - NOTIFICATION_UNREAD_THRESHOLD;

          alerts.push(
            {
              sortWeight: daysAfterEnabled,
              statusText: 'Notificações não estão sendo visualizadas',
              linkTo: `${routes.STUDENT_MANAGE_PATH}${student.id}`,
              entityName: 'Aluno',
              alertType: daysAfterEnabled > NOTIFICATION_UNREAD_ALERT_RANGE ? '' : '--alert',
              student: student
            }
          );
        }
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    return alerts.map((entry) => (
      <AlertItem
        key={`user_notification_alert_item_${entry.student.id}`}
        id={entry.student.name}
        status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
        linkTo={entry.linkTo}
        entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
      />
    ));
  }

  getTrainingAlerts() {
    const alerts = [];

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    for(let student of this.state.active_students) {
      if(student.last_training_data) {
        const startedAt = getAsLocalDate(student.last_training_data.training_class.started_at);
        const timeDiff = Math.abs(today.getTime() - startedAt.getTime());

        const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if(daysPassed > STUDENT_LAST_TRAINING_ALERT_RANGE) {
          alerts.push(
            {
              sortWeight: daysPassed,
              statusText: `Há ${daysPassed} desde última aula`,
              linkTo: `${routes.STUDENT_MANAGE_PATH}${student.id}`,
              entityName: 'Aluno',
              alertType: daysPassed > STUDENT_LAST_TRAINING_RANGE ? '' : '--alert',
              student: student
            }
          );
        }
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    return alerts.map((entry) => (
      <AlertItem
        key={`user_training_alert_item_${entry.student.id}`}
        id={entry.student.name}
        status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
        linkTo={entry.linkTo}
        entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
      />
    ));
  }

  getPersonalTrainingAlerts() {
    const alerts = [];

    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    for(let student of this.state.active_students) {
      if(student.personal_training && student.personal_training !== null) {
        const endDate = getAsLocalDate(student.personal_training.final_date);
        const timeDiff = Math.abs(endDate.getTime() - today.getTime());
        const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if(endDate >= today) {
          if(daysPassed <= STUDENT_PERSONAL_TRAINING_ALERT_RANGE) {
            alerts.push(
              {
                sortWeight: daysPassed - STUDENT_PERSONAL_TRAINING_ALERT_RANGE,
                statusText: daysPassed > 0 ? `Periodização termina em ${daysPassed} dia${daysPassed > 1 ? 's' : ''}` : 'Periodização termina HOJE',
                linkTo: `${routes.STUDENT_EDIT_PATH}${student.id}${paths.PERSONAL_TRAINING_PATH}`,
                entityName: 'Aluno',
                alertType: '--alert',
                student: student
              }
            );
          }
        }
        else {
          alerts.push(
            {
              sortWeight: daysPassed,
              statusText: `Periodização terminou há ${daysPassed} dia${daysPassed > 1 ? 's' : ''}`,
              linkTo: `${routes.STUDENT_EDIT_PATH}${student.id}${paths.PERSONAL_TRAINING_PATH}`,
              entityName: 'Aluno',
              alertType: '',
              student: student
            }
          );
        }
      }
    }

    alerts.sort((a, b) => a.sortWeight - b.sortWeight);

    return alerts.map((entry) => (
      <AlertItem
        key={`user_training_alert_item_${entry.student.id}`}
        id={entry.student.name}
        status={<p className={`desktop__status-text${entry.alertType}`}>{entry.statusText}</p>}
        linkTo={entry.linkTo}
        entityName={this.state.screenWidth > 420 ? entry.entityName : ''}
      />
    ));
  }

  getAlerts() {
    const alerLists = [];
    let rulerCount = 1;

    const contractAlerts = this.getContractAlerts();

    if(contractAlerts.length > 0) {
      alerLists.push(
        <AlertList
          title="Contratos"
          key="desktop_contract-alert-list"
        >

          {contractAlerts}

        </AlertList>
      );
      alerLists.push(
        <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
      );

      rulerCount++;
    }

    if(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      const financialAlerts = this.getFinancialAlerts();

      if(financialAlerts.length > 0) {
        alerLists.push(
          <AlertList
            title="Pagamentos e dívidas"
            key="desktop_financial-alert-list"
          >

            {financialAlerts}

          </AlertList>
        );
        alerLists.push(
          <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
        );

        rulerCount++;
      }
    }

    // const physicalRevaluationAlerts = this.getPhysicalRevaluationAlerts();
    //
    // if(physicalRevaluationAlerts.length > 0) {
    //   alerLists.push(
    //     <AlertList
    //       title="Reavaliações físicas"
    //       key="desktop_physical_revaluation-alert-list"
    //     >
    //
    //       {physicalRevaluationAlerts}
    //
    //     </AlertList>
    //   );
    //   alerLists.push(
    //     <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
    //   );
    //
    //   rulerCount++;
    // }

    const userNotificationAlerts = this.getNotificationAlerts();

    if(userNotificationAlerts.length > 0) {
      alerLists.push(
        <AlertList
          title="Notificações de alunos"
          key="desktop_user_notification-alert-list"
        >

          {userNotificationAlerts}

        </AlertList>
      );
      alerLists.push(
        <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
      );

      rulerCount++;
    }

    // REMOVIDO TEMPORARIAMENTE PARA LIMPAR ÁREA DE TRABALHO
    // const userTrainingAlerts = this.getTrainingAlerts();

    // if(userTrainingAlerts.length > 0) {
    //   alerLists.push(
    //     <AlertList
    //       title="Presença de alunos"
    //       key="desktop_user_training-alert-list"
    //     >

    //       {userTrainingAlerts}

    //     </AlertList>
    //   );
    //   alerLists.push(
    //     <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
    //   );

    //   rulerCount++;
    // }

    // const userPersonalTrainingAlerts = this.getPersonalTrainingAlerts();
    //
    // if(userPersonalTrainingAlerts.length > 0) {
    //   alerLists.push(
    //     <AlertList
    //       title="Personal Training"
    //       key="desktop_user__personal_training-alert-list"
    //     >
    //
    //       {userPersonalTrainingAlerts}
    //
    //     </AlertList>
    //   );
    //   alerLists.push(
    //     <HorizontalRule key={`desktop_ruler_${rulerCount}`} />
    //   );
    //
    //   rulerCount++;
    // }

    if(alerLists.length > 0) {
      return alerLists;
    }

    return null;

    // return (
    //   <p className="desktop__not-found-text">

    //     <i className="fas fa-check desktop__not-found-text__icon"></i> Nenhuma pendência encontrada

    //   </p>
    // );
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  onRegisterTask() {
    this.setState({
      onRegisterTask: true,
      newTaskText: '',
      newTaskPriority: 1,
      newTaskCommentary: ''
    });
  }

  onMarkTaskInitiated(entry) {
    this.setState({
      taskToInitiate: entry,
      taskCommentaryToUpdate: entry.commentary
    });
  }

  onMarkTaskFinished(entry) {
    this.setState({
      taskToFinish: entry,
      taskCommentaryToUpdate: entry.commentary
    });
  }

  onToggleTaskContent(taskId) {
    const taskVisibilityMap = new Map(this.state.taskVisibilityMap);

    let visible = false;

    if (taskVisibilityMap.has(taskId)) {
      visible = taskVisibilityMap.get(taskId);
    }

    taskVisibilityMap.set(taskId, !visible);

    this.setState({taskVisibilityMap});
  }

  getOperationalTaskContent() {
    const taskElements = this.state.operational_tasks.map((entry) => {
      const contentVisible = this.state.taskVisibilityMap.get(entry.id);

      return (
        <li
          key={`operational-task:${entry.id}`}
          className="desktop__operational-tasks__task__wrapper"
        >

          <div className={`desktop__operational-tasks__task__priority-indicator--${entry.priority}`}></div>

          <section className="desktop__operational-tasks__task__expandable-container">

            <header className="desktop__operational-tasks__task__expandable-container__header">

              <div className="desktop__operational-tasks__task">

                <div
                  className={`desktop__operational-tasks__task__main${entry.commentary !== null ? '' : '--disabled'}`}
                  onClick={() => {
                    if (entry.commentary !== null) {
                      this.onToggleTaskContent(entry.id);
                    }
                  }}
                >

                  {contentVisible ?
                    <i className={`fas fa-chevron-down desktop__operational-tasks__task__expandable-container__header__visible-icon${entry.commentary !== null ? '' : '--hidden'}`}></i>:
                    <i className={`fa-solid fa-chevron-right desktop__operational-tasks__task__expandable-container__header__visible-icon${entry.commentary !== null ? '' : '--hidden'}`}></i>
                  }

                  <h3 className="desktop__operational-tasks__task__expandable-container__header-text">{entry.task}</h3>

                </div>

                {entry.finished_at === null &&
                  <div className="desktop__operational-tasks__task__input-wrapper">

                    <button
                      className="desktop__operational-tasks__task__input--blue"
                      onClick={() => {
                        if (entry.initiated_at === null) {
                          this.onMarkTaskInitiated(entry);
                        }
                      }}
                      disabled={entry.initiated_at !== null}
                    >

                      {entry.initiated_at !== null &&
                        <i className="fas fa-check desktop__operational-tasks__task__input__icon"></i>
                      }

                    </button>

                  </div>
                }

                <div className="desktop__operational-tasks__task__input-wrapper">

                  <button
                    className="desktop__operational-tasks__task__input--green"
                    onClick={() => {
                      if (entry.finished_at === null) {
                        this.onMarkTaskFinished(entry);
                      }
                    }}
                    disabled={entry.finished_at !== null}
                  >

                    {entry.finished_at !== null &&
                      <i className="fas fa-check desktop__operational-tasks__task__input__icon"></i>
                    }

                  </button>

                </div>

              </div>

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container desktop__operational-tasks__task__expandable-container__content-wrapper"
              pose={contentVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="desktop__operational-tasks__task__expandable-container__content">

                <p className="desktop__operational-tasks__task__commentary">{entry.commentary}</p>

              </div>

            </VerticalAccordionContainer>

          </section>

        </li>
      );
    });

    return (
      <ul className="desktop__operational-tasks">

        <li className="desktop__operational-tasks__header">

          <p className="desktop__operational-tasks__header__name">Tarefa</p>
          <p className="desktop__operational-tasks__header__initiated">Iniciada</p>
          <p className="desktop__operational-tasks__header__finished">Finalizada</p>

        </li>

        {taskElements.length <= 0 &&
          <li className="desktop__operational-tasks__no-data-found">
            <p className="desktop__operational-tasks__no-data-found__text">Nenhuma tarefa pendente</p>
          </li>
        }

        {taskElements}

      </ul>
    );
  }

  getConfirmationWindowTitle() {
    if(this.state.taskToInitiate !== null) {
      return 'Iniciar tarefa';
    }
    else if(this.state.taskToFinish !== null) {
      return 'Finalizar tarefa';
    }
    else if(this.state.onRegisterTask) {
      return 'Registrar pendência';
    }

    return '';
  }

  getConfirmationWindowDescription() {
    if(this.state.onRegisterTask) {
      return 'Uma nova pendência será registrada e encaminhada para a equipe de gestão da academia.';
    }
    else if(this.state.taskToInitiate !== null) {
      return 'A tarefa será marcada como iniciada.';
    }
    else if(this.state.taskToFinish !== null) {
      return 'A tarefa será marcada como finalizada e não aparecerá novamente nesta listagem.';
    }
  }

  getPriorityOptions() {
    return [
      SelectOption(1, 'Normal'),
      SelectOption(2, 'Alta'),
      SelectOption(3, 'Urgente')
    ];
  }

  getConfirmationWindowContent() {
    if (this.state.taskToInitiate !== null || this.state.taskToFinish !== null) {
      return (
        <React.Fragment>

          <DefaultInput
            name="taskCommentaryToUpdate"
            label="Comentário/Observação"
            type="textarea"
            placeholder="Comentário / Observação"
            rows="6"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.taskCommentaryToUpdate || ''}
          />

        </React.Fragment>
      );
    }
    else if (this.state.onRegisterTask) {
      return (
        <React.Fragment>

          <DefaultInput
            name="newTaskText"
            label="Pendência"
            type="text"
            placeholder="Breve descrição"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskText}
            autoComplete="off"
          />

          <DefaultInput
            name="newTaskPriority"
            label="Prioridade"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskPriority || ''}
            options={this.getPriorityOptions()}
          />

          <DefaultInput
            name="newTaskCommentary"
            label="Comentário/Observação"
            type="textarea"
            placeholder="Comentário / Observação"
            rows="6"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskCommentary || ''}
          />

        </React.Fragment>
      );
    }
  }

  onCancelConfirmationWindow() {
    this.setState({
      taskToInitiate: null,
      taskToFinish: null,
      onRegisterTask: false,
      confirmInProgress: false
    });
  }

  async onConfirmConfirmationWindow() {
    if (this.state.onRegisterTask) {
      const data = {
        priority: parseInt(this.state.newTaskPriority),
        task: this.state.newTaskText,
        commentary: this.state.newTaskCommentary
      };

      this.setState({
        confirmInProgress: true
      });

      try{
        await postModel(routes.OPERATIONAL_TASK_MANAGEMENT_POST_API, data);

        this.reloadOperationalTasks();

        // this.setState({
        //   showSuccessWindow: true,
        //   confirmDescription: 'Tarefa cadastrada com sucesso',
        //   taskToInitiate: null,
        //   taskToFinish: null,
        //   onRegisterTask: false,
        //   confirmInProgress: false
        // });

        // return;
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      const data = {commentary: this.state.taskCommentaryToUpdate};

      let taskId;

      if(this.state.taskToInitiate !== null) {
        data.status = OPERATIONAL_TASK_STATUS_INITIATED;
        taskId = this.state.taskToInitiate.id;
      }
      else if(this.state.taskToFinish !== null) {
        data.status = OPERATIONAL_TASK_STATUS_FINISHED;
        taskId = this.state.taskToFinish.id;
      }
      else {
        return;
      }

      this.setState({
        confirmInProgress: true
      });

      try{
        const response = await patchModel(routes.OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API.replace('{id}', taskId), data, true);

        if(response) {
          const operational_tasks = this.state.operational_tasks.map((entry) => {
            let task;

            if (entry.id === taskId) {
              task = {...response.model_saved};
            }
            else {
              task = {...entry}
            }

            return task;
          });

          this.setState({
            operational_tasks,
            confirmInProgress: false
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.onCancelConfirmationWindow();
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="desktop__operational-tasks__confirmation-window"
          visible={this.state.taskToInitiate !== null || this.state.taskToFinish !== null || this.state.onRegisterTask}
          loading={this.state.confirmInProgress}
          actions={(
            <div className="desktop__operational-tasks__confirmation-window__action-container">

              <DefaultMenuButton
                className="desktop__operational-tasks__confirmation-window__action-button"
                onClick={() => {
                  this.onCancelConfirmationWindow();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="desktop__operational-tasks__confirmation-window__action-button"
                onClick={() => {
                  this.onConfirmConfirmationWindow();
                }}
                text="Confirmar"
                color="green"
              />

            </div>
          )}
        >

          <header className="desktop__operational-tasks__confirmation-window__header">

            <h3 className="desktop__operational-tasks__confirmation-window__header__title">
              {this.getConfirmationWindowTitle()}
            </h3>

          </header>

          <hr className="desktop__operational-tasks__confirmation-window__horizontal-rule" />

          <div className="desktop__operational-tasks__confirmation-window__content">

            <p className="desktop__operational-tasks__confirmation-window__description">{this.getConfirmationWindowDescription()}</p>

            {this.getConfirmationWindowContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailDescription}
          confirmText="Confirmar"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.confirmFailed}
          onCancel={() => this.setState({confirmFailed: false})}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
          ]}
          titleIcon={<i className="fas fa-home"></i>}
          title="Área de trabalho"
          loading={this.state.loadingData}
        >
          <div className="desktop__wrapper">

            {this.getTodayDate()}

            {this.isDefaultUnit() &&
              <React.Fragment>

                <DefaultSubSectionTitle
                  icon={<i className="fas fa-exclamation-triangle"></i>}
                  text="Pendências"
                />

                <div className="desktop__actions-container">

                  {this.props.userAccessLevel < COACH_ACCESS_LEVEL ? (
                    <Link
                      className="desktop__action-button"
                      to={routes.OPERATIONAL_TASK_ADD_PAGE}
                    >

                      <i className="fas fa-plus"></i> Cadastrar pendência

                    </Link>
                  ) : (
                    <button
                      className="desktop__action-button"
                      onClick={() => {
                        this.onRegisterTask();
                      }}
                      disabled={this.state.confirmInProgress}
                    >

                      <i className="fas fa-plus"></i> Cadastrar pendência

                    </button>
                  )}

                </div>

                {this.getOperationalTaskContent()}

              </React.Fragment>
            }

            {this.getAlerts()}

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default Desktop;
