import React from 'react';
import { Link } from 'react-router-dom';
import './student_basic_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {getPhoneText} from '../../utils/functions';


class StudentBasicData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  render() {
    return (
      <DefaultSection
        className="student-basic-data"
        title="Dados do usuário"
      >

        <div className="student-basic-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="student-basic-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do usuário"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.student.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="E-mail do usuário"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.student.email}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="phone"
              isHighlighted={this.isHighlighted("phone")}
              label="Telefone"
              type="tel"
              placeholder="Telefone do aluno"
              maxLength="16"
              handleInputChange={this.props.handleInputChange}
              value={getPhoneText(this.props.student.phone)}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="gender"
              isHighlighted={this.isHighlighted("gender")}
              label="Gênero"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.student.gender}
              options={[
                SelectOption('', 'Selecione o gênero'),
                SelectOption('Feminino', 'Feminino'),
                SelectOption('Masculino', 'Masculino'),
              ]}
            />

          </HalfWrapper>

          <HorizontalRule />

          <DefaultInput
            name="addToMarketingList"
            isHighlighted={false}
            label="Aceita receber anúncions e promoções: "
            labelMessage="O aluno ainda receberá notificações essenciais por email, como vencimento de planos, agendamentos, eventos, etc."
            placeLabelMessageBellow={true}
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.addToMarketingList}
          />

        </div>

        <HorizontalRule />

        <div className="student-basic-data__buttons-container">

          <button
            className="student-basic-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="student-basic-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default StudentBasicData;
