import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import ModelTable, {Property} from '../../../utils/model_table';
import './financial_list.scss';
import {getModels, postModel, deleteModel, getAsLocalDate, getCurrencyText, getLocalDateIsoString, getAsLocalDateString} from '../../../utils/functions';
import * as routes from '../../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, PAYMENT_ALERT_RANGE} from '../../../constants';
import ConfirmationWindow from '../../confirmation_window';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';


class FinancialList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_movements: [],
      loadingData: true,
      deleteId: null,
      cancelId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      clipboardWritePermission: false,
      userFromLinkCopied: false
    };
  }

  async getFinancialMovements() {
    return await getModels(`${routes.FINANCIAL_MOVEMENTS_GET_API}?user_id=${this.props.student.id}`);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const financial_movements = await this.getFinancialMovements();

    this.setState({
      loadingData: false,
      financial_movements: financial_movements
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelEntry(entryId) {
    this.setState({
      cancelId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      cancelId: null,
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.cancelId) {
      try {
        if(await postModel(routes.FINANCIAL_MOVEMENT_CANCEL_API.replace('{id}', this.state.cancelId.toString()))) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      try{
        if(await deleteModel(`${routes.FINANCIAL_MOVEMENT_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      cancelId: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-edit-button"
          to={`${entry.is_expense ? routes.EXPENSE_EDIT_PATH : routes.INCOME_EDIT_PATH}${entry.id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>

        {!entry.contract_id ?
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>:
          null
        }

      </div>
    );
  }

  getProgressText(entry) {
    return `${getCurrencyText(entry.total_value_completed)} de ${getCurrencyText(entry.total_value)}`;
  }

  getNextPaymentText(entry) {
    if(!entry.next_payment_date) {
      return (
        <div className="financial-list__cell-wrapper">

          <p className="financial-list__date-text--completed">

            Completa

          </p>

        </div>
      );
    }
    else if (entry.is_canceled) {
      return (
        <div className="financial-list__cell-wrapper">

          <p className="financial-list__date-text--alert">

            Cancelada

          </p>

        </div>
      );
    }

    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDate(entry.next_payment_date);
    const dateString = getLocalDateIsoString(date);

    const timeDiff = Math.abs(date.getTime() - today.getTime());
    const daysToExpire = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return (
      <div className="financial-list__cell-wrapper">

        <p className={`financial-list__date-text${dateString < todayString ? '--past' : daysToExpire < PAYMENT_ALERT_RANGE ? '--alert' : ''}`}>

          {date.toLocaleDateString()}

        </p>

      </div>
    );
  }

  getNextPaymentFilter(entry) {
    if(!entry.next_payment_date) {
      return 'completa';
    }
    else if (entry.is_canceled) {
      return 'cancelada';
    }

    return getAsLocalDateString(entry.next_payment_date) || '';
  }

  getTypeText(entry) {
    return (
      <div className="financial-list__cell-wrapper">

        <p className={`financial-list__type-text${entry.is_expense ? '--expense' : ''}`}>

          {entry.is_expense ? 'Dívida' : 'Pagamento'}

        </p>

      </div>
    );
  }

  getTypeFilter(entry) {
    return entry.is_expense ? 'Dívida' : 'Pagamento';
  }

  getProperties() {
    let properties = [
      Property('is_expense', 'Tipo', <i className="fas fa-cash-register"></i>, {
        getDataText: this.getTypeText,
        getFilterText: this.getTypeFilter,
        sortable: false
      }),
      Property('next_payment_date', 'Próximo pagamento', <i className="fas fa-calendar-day"></i>, {
        getDataText: this.getNextPaymentText,
        getFilterText: this.getNextPaymentFilter,
      }),
      Property('progress', 'Progresso', <i className="fas fa-money-bill-alt"></i>, {
        getDataText: this.getProgressText,
        getFilterText: this.getProgressText
      }),
    ];

    if(this.state.screenWidth > 1020) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {
          cellClassName: "financial-list__description-cell"
        }),
      );
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.cancelId) {
        return 'Cancelando entrada';
      }

      return 'Deletando entrada';
    }
    else if(this.state.confirmFailed) {
      if(this.state.cancelId) {
        return 'Falha ao cancelar entrada';
      }

      return 'Falha ao deletar';
    }
    else if(this.state.cancelId) {
      return 'Cancelar entrada';
    }

    return 'Deletar entrada';
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados à entrada serão removidos'}
          confirmText="Deletar entrada"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ModelTable
          properties={this.getProperties()}
          getActions={(entry) => this.getActions(entry)}
          data={this.state.financial_movements}
          initialOrderBy="next_payment_date"
          defaultSortValue='zzzzzzzzz'
        >

          <Link
            className="model-table__default-button"
            to={{
              pathname: routes.INCOME_ADD_PATH,
              search: `?student_id=${this.props.student.id}`,
            }}
          >

            <i className="fas fa-plus"></i> Adicionar novo pagamento

          </Link>

          <Link
            className="model-table__default-button"
            to={{
              pathname: routes.EXPENSE_ADD_PATH,
              search: `?student_id=${this.props.student.id}`,
            }}
          >

            <i className="fas fa-plus"></i> Adicionar nova dívida

          </Link>

        </ModelTable>

      </React.Fragment>
    );
  }
}

export default FinancialList;
