import React from 'react';
import {DEFAULT_UNIT_TYPE} from '../../../constants';
import {HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {SelectOption} from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import './support_ticket_data.scss';


class SupportTicketData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth
    };
  }

  componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  // handleKeyDown(event) {
  //   if(event.keyCode === 13 && this.props.enableSave) {
  //     this.props.onSave();
  //   }
  // }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.support_ticket.id && this.props.support_ticket.id > 0;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getServiceOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.support_categories.map((category) => SelectOption(category.id, category.name))
      ];
    }

    return [
      SelectOption('', 'Selecione uma classificação'),
      ...this.props.support_categories.map((category) => SelectOption(category.id, category.name))
    ];
  }

  render() {
    return (
      <React.Fragment>

        <div className="support-ticket-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="support-ticket-data__input-container">

          <DefaultInput
            name="category_id"
            isHighlighted={this.isHighlighted("category_id")}
            label="Classificação da conversa"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.support_ticket.category_id || ''}
            options={this.getServiceOptions()}
          />

          <DefaultInput
            name="subject"
            isHighlighted={this.isHighlighted("subject")}
            label="Assunto"
            type="text"
            placeholder="Assunto da conversa"
            maxLength="256"
            handleInputChange={this.props.handleInputChange}
            value={this.props.support_ticket.subject}
            autoComplete="off"
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Mensagem"
            type="textarea"
            placeholder="Mensagem inicial"
            rows="4"
            handleInputChange={this.props.handleInputChange}
            value={this.props.support_ticket.description || ''}
          />

        </div>

        <HorizontalRule />

        <div className="support-ticket-data__buttons-container">

          <button
            className="support-ticket-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <button
            className="support-ticket-data__cancel-button"
            onClick={() => this.props.history.goBack()}
          >

            Cancelar

          </button>

        </div>

      </React.Fragment>
    );
  }
}

export default SupportTicketData;
