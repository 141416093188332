import React from 'react';
import { Link } from 'react-router-dom';
import './exercise_activity_type_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class ExerciseActivityTypeData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.activity_type.id && this.props.activity_type.id > 0;
  }

  render() {
    return (
      <DefaultSection
        className="exercise-activity-type-data"
        title="Dados da capacidade de exercício"
      >

        <div className="exercise-activity-type-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="exercise-activity-type-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome da capacidade"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.activity_type.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="short_name"
              isHighlighted={this.isHighlighted("short_name")}
              label="Abreviação"
              type="text"
              placeholder="Abreviação da capacidade"
              maxLength="32"
              handleInputChange={this.props.handleInputChange}
              value={this.props.activity_type.short_name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <DefaultInput
            className="exercise-activity-type-data__color-input"
            name="color"
            isHighlighted={this.isHighlighted("color")}
            label="Cor:"
            type="color"
            isHorizontal={true}
            handleInputChange={this.props.handleInputChange}
            value={this.props.activity_type.color}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da capacidade"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.activity_type.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="exercise-activity-type-data__buttons-container">

          <button
            className="exercise-activity-type-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="exercise-activity-type-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ExerciseActivityTypeData;
