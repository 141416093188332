const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const DEFAULT_UNKNOWN_ERROR_MESSAGE = `Erro não documentado. Por favor, entre em contato com ` +
                                      `o suporte para que isto seja corrigido`;

export {DEFAULT_ERROR_MESSAGE, DEFAULT_UNKNOWN_ERROR_MESSAGE};

const COACH_ACCESS_LEVEL = 3;

const CONTRACT_EXPIRATION_ALERT_RANGE = 10;
const PAYMENT_ALERT_RANGE = 7;
const PHYSICAL_REVALUATION_ALERT_RANGE = 10;

const PHYSICAL_REVALUATION_THRESHOLD = 80;

const NOTIFICATION_UNREAD_THRESHOLD = 8;
const NOTIFICATION_UNREAD_ALERT_RANGE = 6;

const EXPENSE_TYPE_NAME = 'Gasto';
const INCOME_TYPE_NAME = 'Receita';
const BOTH_TYPE_NAME = 'Ambos';

const NOTIFICATION_OPENED = "OPENED";
const NOTIFICATION_CLICKED = "CLICKED";

const STUDENT_ACTIVE_STATUS = 'Ativo';

// Always add at the bottom of this list
const BODY_FAT_PROTOCOLS = [
  'Balança de bioimpedância',
  'Faulkner (4 dobras)',
  'Guedes (3 dobras)',
  'Jackson & Pollock (3 dobras)',
  'Jackson & Pollock (7 dobras)',
  'Perimetria',
];

// Always add at the bottom of this list
const BASAL_METABOLIC_RATE_PROTOCOLS = [
  'Campo aberto',
  'Harris–Benedict revisado',
  'Mifflin St Jeor',
  'Cunningham',
];

const NOTIFICATION_TEXT_MAP = {
  "PROCESSING": "Processando",
  "ACCEPTED": "Aceito",
  "DELIVERED": "Entregue",
  "PERMANENT_FAILURE": "Falha permanente",
  "TEMPORARY_FAILURE": "Falha temporária",
  [NOTIFICATION_OPENED]: "Visualizado",
  [NOTIFICATION_CLICKED]: "Abriu link",
  "UNSUBSCRIBED": "Unsubscribed",
  "COMPLAINED": "Marcou como spam",
  "STORED": "Armazenou",
  "REJECTED": "Rejeitado",
  "UNKNOWN": "UNKNOWN",
};

const TRAINING_EXECUTION_METHOD_FIXED_REPS = 'Repetições fechadas';
const TRAINING_EXECUTION_METHOD_PIRAMIDAL = 'Piramidal';
const TRAINING_EXECUTION_METHOD_QRP = 'QRP';

const TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR = 'Escalar'
const TRAINING_EXERCISE_PARAMETER_TYPE_RANGE = 'Intervalo'

const TRAINING_EXERCISE_PARAMETER_PR_NAME = 'PR'
const TRAINING_EXERCISE_PARAMETER_PR_UNIT = '%'

const TRAINING_EXERCISE_PARAMETER_DEFAULT_NAME = 'Repetições'

const STUDENT_REGISTRATION_COST = 29.90;

const CLOCK_METHOD_STOPWATCH = 'Cronômetro';
const CLOCK_METHOD_TIMER = 'Contagem regressiva';
const CLOCK_METHOD_TABATA = 'Tabata';
const CLOCK_METHOD_SERIES = 'Séries';
const CLOCK_METHOD_CUSTOM = 'Personalizado';

const CLOCK_COLOR_MAP = {
  "Verde": "#89f155",
  "Vermelho": "#d62323",
  "Amarelo": "#dad61e",
  "Azul": "#1a9fc7",
  "Branco": "#ffffff",
  "Roxo": "#b94df1",
};

const SERVICE_COLOR_MAP = {
  "Vermelho": "#ff3d53",
  "Verde": "#6fb54b",
  "Amarelo": "#d8bd39",
  "Azul": "#00b4d3",
  "Roxo": "#b958d2",
  "Rosa": "#e24b83",
  "Cinza": "#868686",
  "Laranja": "#d07a3d",
};

const TRAINING_CALENDAR_COLOR_MAP = {
  "Musculação": "#ff3d53",
  "Combinado": "#6fb54b",
  "Atividade Complementar": "#d8bd39",
  "Aulas": "#00b4d3",
  "Descanso": "#ffffff",
};

const SERVICE_FUNCTIONAL_SERVICE = 'Cross FYD';
const SERVICE_PILATES_SERVICE = 'Fyd Pilates';
const SERVICE_BIKE_SERVICE = 'Fyd Bike';
const SERVICE_GYM_SERVICE = 'Musculação';
const SERVICE_CARDIO = 'Cardio';
const SERVICE_CORE = 'Core';
const SERVICE_BOOTCAMP = 'FYD Run Bootcamp';

const SERVICE_PERSONAL_SERVICE = 'Personal training';
const SERVICE_HOME_TRAINING = 'Home training';

const TARGET_SERVICE_COLOR_MAP = {
  [SERVICE_FUNCTIONAL_SERVICE]: "#78bb6738",
  [SERVICE_PILATES_SERVICE]: "#5ec2d238",
  [SERVICE_BIKE_SERVICE]: "#edf57038",
  [SERVICE_GYM_SERVICE]: "#967de238",
  [SERVICE_CARDIO]: "#ea676738",
  [SERVICE_CORE]: "#cd59b138",
  [SERVICE_BOOTCAMP]: "#9469cd38",
  [SERVICE_HOME_TRAINING]: "#a3a3a338",
};

const STUDENT_RECENT_TRAINING_PERIOD = 20;
const STUDENT_LAST_TRAINING_ALERT_RANGE = 6;
const STUDENT_LAST_TRAINING_RANGE = 9;

const STUDENT_PERSONAL_TRAINING_ALERT_RANGE = 4;

const DEFAULT_UNIT_TYPE = 1;

const TRAINING_PERIOD_OBJECTIVES = [
  'Emagrecimento',
  'Ganho de massa muscular (hipertrofia)',
  'Performance',
  'Qualidade de vida'
];

const FOOD_PRESCRIPTION_OBJECTIVES = [
  'Emagrecimento',
  'Ganho de massa muscular (hipertrofia)'
];
// const TRAINING_PERIOD_OBJECTIVES = [
//   'Emagrecimento',
//   'Hipertrofia',
//   'Condicionamento Físico & Performance',
//   'Manutenção'
// ];

const TRAINING_PERIOD_PHYSICAL_LEVELS = [
  'Iniciante',
  'Intermediário',
  'Avançado',
  'Atleta'
];

const NUTRIENT_PROTEIN_ID = 1;
const NUTRIENT_FAT_ID = 2;
const NUTRIENT_CARBOHYDRATE_ID = 3;
const NUTRIENT_FIBER_ID = 4;

const FOOD_MEASUREMENT_GRAM_ID = 68;

const DEFAULT_FOOD_INFO_SOURCE = {
  id: 3,
  shortname: 'Manual',
};

const DEFAULT_ENERGY_UNIT = {
  id: 1,
  shortname: 'kcal',
};

const DEFAULT_WEIGHT_UNIT = {
  id: 1,
  shortname: 'g',
};

const DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE = 1.0;

const MANAGER_ROLE = {
  id: 2,
  name: 'Gerente',
};
const COACH_ROLE = {
  id: 4,
  name: 'Treinador',
};
const RECEPTIONIST_ROLE = {
  id: 6,
  name: 'Recepcionista',
};
const NUTRITIONIST_ROLE = {
  id: 7,
  name: 'Nutricionista',
};
const ADMIN_NUTRITIONIST_ROLE = {
  id: 8,
  name: 'Nutricionista gestor',
};
const HEAD_COACH_ROLE = {
  id: 9,
  name: 'Coordenador técnico',
};
const ADMIN_RECEPTIONIST_ROLE = {
  id: 10,
  name: 'Líder de recepção',
};

const COST_CENTER_MARKETING_ID = 9;
const COST_CENTER_PILATES_ID = 2;
const COST_CENTER_FUNCTIONAL_ID = 6;
const COST_CENTER_BIKE_ID = 14;
const COST_CENTER_GYM_ID = 13;
const COST_CENTER_CARDIO_ID = 16;
const COST_CENTER_CORE_ID = 15;
const COST_CENTER_BOOTCAMP_ID = 17;

const APPOINTMENT_TYPE_PHYSICAL_EVALUATION = {
  id: 1,
  default_appointment_duration: 15
};
const APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION = {
  id: 2,
  default_appointment_duration: 40
};

const APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID = 1;
const APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID = 2;

const F_P_SERVICE_AWAITING_FIRST_EVALUATION_STATUS = 'Aguardando primeira avaliação';
const F_P_SERVICE_DIET_ENABLED_STATUS = 'Dieta liberada';
const F_P_SERVICE_LAST_EVALUATION_PERFORMED_STATUS = 'Reavaliação concluída';
const F_P_SERVICE_CANCELED_STATUS = 'Serviço cancelado';
const F_P_SERVICE_FINISHED_STATUS = 'Serviço finalizado';
const F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS = 'Requer revisão (formulário de acompanhamento)';
const F_P_SERVICE_PENDING_NUTRITIONIST_RESPONSE_STATUS = 'Cliente enviou pergunta ao nutricionista';
const F_P_SERVICE_PENDING_TECHINICAL_RESPONSE_STATUS = 'Cliente enviou mensagem de dúvida';

const P_T_SERVICE_AWAITING_PHYSICAL_EVALUATION_STATUS = 'Aguardando avaliação física';
const P_T_SERVICE_TRAINING_PERIOD_ENABLED_STATUS = 'Periodização liberada';
const P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS = 'Requer revisão (Nutrição)';
const P_T_SERVICE_PENDING_PERIOD_SUPPORT_RESPONSE_STATUS = 'Cliente enviou pergunta sobre periodização';
const P_T_SERVICE_PENDING_GENERAL_SUPPORT_RESPONSE_STATUS = 'Cliente enviou mensagem de dúvida';

const FYD_FORM_FOLLOWUP_QUESTION_DIET_ID = 4;
const FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID = 5;
const FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID = 1;
const FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID = 2;
const FYD_FORM_FOLLOWUP_QUESTION_WATER_ID = 3;
const FYD_FORM_FOLLOWUP_QUESTION_DIFFICULTIES_ID = 6;

const FYD_FORM_SATISFACTION_QUESTION_ROOM_ID = 7;
const FYD_FORM_SATISFACTION_QUESTION_COACH_ID = 8;
const FYD_FORM_SATISFACTION_QUESTION_TRAINING_ID = 9;
const FYD_FORM_SATISFACTION_QUESTION_GENERAL_ID = 10;

const SUPPORT_CATEGORY_FP_DIET_ID = 1;
const SUPPORT_CATEGORY_FP_GENERAL_ID = 2;
const SUPPORT_CATEGORY_PT_PERIOD_ID = 3;
const SUPPORT_CATEGORY_PT_GENERAL_ID = 4;

const SUPPORT_STATUS_OPEN_ID = 1;
const SUPPORT_STATUS_CLOSED_ID = 2;
const SUPPORT_STATUS_AWAITING_RESPONSE_ID = 3;

const LOCAL_CACHE_SELECTED_PAYMENT_DEVICE_ID_KEY = 'fyd:admin:selected_payment_device';

const OPERATIONAL_TASK_STATUS_PENDING = 'Pendente';
const OPERATIONAL_TASK_STATUS_INITIATED = 'Em progresso';
const OPERATIONAL_TASK_STATUS_FINISHED = 'Finalizada';

const OPERATIONAL_TASK_ROLE_MANAGEMENT = 'Gestão';
const OPERATIONAL_TASK_ROLE_COACHES = 'Professores';
const OPERATIONAL_TASK_ROLE_RECEPTIONISTS = 'Recepção';

const STONE_PAYMENT_REQUEST_PAID_STATUS = 'paid';

const ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID = 1;
const ACCESS_CONTROL_METHOD_FINGER_PRINT_ID = 2;

const PAYMENT_METHOD_CREDIT_CARD_ID = 3;
const PAYMENT_METHOD_CREDIT_CARD_DEBT_ID = 4;
const PAYMENT_METHOD_RECURRENT_ID = 9;

const ACCESS_CONTROL_RULE_ALWAYS = 'Always';
const ACCESS_CONTROL_RULE_CHECKIN = 'Checkin';

export {
  COACH_ACCESS_LEVEL,
  CONTRACT_EXPIRATION_ALERT_RANGE,
  PAYMENT_ALERT_RANGE,
  EXPENSE_TYPE_NAME,
  INCOME_TYPE_NAME,
  BOTH_TYPE_NAME,
  PHYSICAL_REVALUATION_ALERT_RANGE,
  PHYSICAL_REVALUATION_THRESHOLD,
  NOTIFICATION_TEXT_MAP,
  NOTIFICATION_UNREAD_THRESHOLD,
  NOTIFICATION_UNREAD_ALERT_RANGE,
  NOTIFICATION_OPENED,
  NOTIFICATION_CLICKED,
  TRAINING_EXECUTION_METHOD_FIXED_REPS,
  TRAINING_EXECUTION_METHOD_PIRAMIDAL,
  TRAINING_EXECUTION_METHOD_QRP,
  TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR,
  TRAINING_EXERCISE_PARAMETER_TYPE_RANGE,
  TRAINING_EXERCISE_PARAMETER_PR_NAME,
  TRAINING_EXERCISE_PARAMETER_PR_UNIT,
  TRAINING_EXERCISE_PARAMETER_DEFAULT_NAME,
  STUDENT_REGISTRATION_COST,
  CLOCK_METHOD_STOPWATCH,
  CLOCK_METHOD_TIMER,
  CLOCK_METHOD_TABATA,
  CLOCK_METHOD_SERIES,
  CLOCK_METHOD_CUSTOM,
  CLOCK_COLOR_MAP,
  SERVICE_COLOR_MAP,
  TRAINING_CALENDAR_COLOR_MAP,
  TARGET_SERVICE_COLOR_MAP,
  SERVICE_FUNCTIONAL_SERVICE,
  SERVICE_PILATES_SERVICE,
  SERVICE_BIKE_SERVICE,
  STUDENT_RECENT_TRAINING_PERIOD,
  STUDENT_LAST_TRAINING_ALERT_RANGE,
  STUDENT_LAST_TRAINING_RANGE,
  STUDENT_PERSONAL_TRAINING_ALERT_RANGE,
  SERVICE_PERSONAL_SERVICE,
  SERVICE_GYM_SERVICE,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  SERVICE_HOME_TRAINING,
  STUDENT_ACTIVE_STATUS,
  DEFAULT_UNIT_TYPE,
  BODY_FAT_PROTOCOLS,
  BASAL_METABOLIC_RATE_PROTOCOLS,
  TRAINING_PERIOD_OBJECTIVES,
  TRAINING_PERIOD_PHYSICAL_LEVELS,
  FOOD_PRESCRIPTION_OBJECTIVES,
  NUTRIENT_PROTEIN_ID,
  NUTRIENT_FAT_ID,
  NUTRIENT_CARBOHYDRATE_ID,
  NUTRIENT_FIBER_ID,
  FOOD_MEASUREMENT_GRAM_ID,
  DEFAULT_FOOD_INFO_SOURCE,
  DEFAULT_ENERGY_UNIT,
  DEFAULT_WEIGHT_UNIT,
  DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE,
  MANAGER_ROLE,
  COACH_ROLE,
  RECEPTIONIST_ROLE,
  NUTRITIONIST_ROLE,
  ADMIN_NUTRITIONIST_ROLE,
  HEAD_COACH_ROLE,
  ADMIN_RECEPTIONIST_ROLE,
  COST_CENTER_MARKETING_ID,
  COST_CENTER_PILATES_ID,
  COST_CENTER_FUNCTIONAL_ID,
  COST_CENTER_BIKE_ID,
  COST_CENTER_GYM_ID,
  COST_CENTER_CARDIO_ID,
  COST_CENTER_CORE_ID,
  COST_CENTER_BOOTCAMP_ID,
  APPOINTMENT_TYPE_PHYSICAL_EVALUATION,
  APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION,
  APPOINTMENT_TYPE_PHYSICAL_EVALUATION_ID,
  APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION_ID,
  F_P_SERVICE_AWAITING_FIRST_EVALUATION_STATUS,
  F_P_SERVICE_DIET_ENABLED_STATUS,
  F_P_SERVICE_LAST_EVALUATION_PERFORMED_STATUS,
  F_P_SERVICE_CANCELED_STATUS,
  F_P_SERVICE_FINISHED_STATUS,
  F_P_SERVICE_PENDING_FOLLOWUP_REVIEW_STATUS,
  P_T_SERVICE_AWAITING_PHYSICAL_EVALUATION_STATUS,
  P_T_SERVICE_TRAINING_PERIOD_ENABLED_STATUS,
  P_T_SERVICE_PENDING_NUTRITIONAL_REVIEW_STATUS,
  FYD_FORM_FOLLOWUP_QUESTION_DIET_ID,
  FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID,
  FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID,
  FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID,
  FYD_FORM_FOLLOWUP_QUESTION_WATER_ID,
  FYD_FORM_FOLLOWUP_QUESTION_DIFFICULTIES_ID,
  FYD_FORM_SATISFACTION_QUESTION_ROOM_ID,
  FYD_FORM_SATISFACTION_QUESTION_COACH_ID,
  FYD_FORM_SATISFACTION_QUESTION_TRAINING_ID,
  FYD_FORM_SATISFACTION_QUESTION_GENERAL_ID,
  SUPPORT_CATEGORY_FP_DIET_ID,
  SUPPORT_CATEGORY_FP_GENERAL_ID,
  SUPPORT_STATUS_OPEN_ID,
  SUPPORT_STATUS_CLOSED_ID,
  SUPPORT_STATUS_AWAITING_RESPONSE_ID,
  F_P_SERVICE_PENDING_NUTRITIONIST_RESPONSE_STATUS,
  F_P_SERVICE_PENDING_TECHINICAL_RESPONSE_STATUS,
  P_T_SERVICE_PENDING_PERIOD_SUPPORT_RESPONSE_STATUS,
  P_T_SERVICE_PENDING_GENERAL_SUPPORT_RESPONSE_STATUS,
  SUPPORT_CATEGORY_PT_PERIOD_ID,
  SUPPORT_CATEGORY_PT_GENERAL_ID,
  LOCAL_CACHE_SELECTED_PAYMENT_DEVICE_ID_KEY,
  OPERATIONAL_TASK_STATUS_PENDING,
  OPERATIONAL_TASK_STATUS_INITIATED,
  OPERATIONAL_TASK_STATUS_FINISHED,
  OPERATIONAL_TASK_ROLE_MANAGEMENT,
  OPERATIONAL_TASK_ROLE_COACHES,
  OPERATIONAL_TASK_ROLE_RECEPTIONISTS,
  STONE_PAYMENT_REQUEST_PAID_STATUS,
  ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID,
  ACCESS_CONTROL_METHOD_FINGER_PRINT_ID,
  PAYMENT_METHOD_CREDIT_CARD_ID,
  PAYMENT_METHOD_CREDIT_CARD_DEBT_ID,
  PAYMENT_METHOD_RECURRENT_ID,
  ACCESS_CONTROL_RULE_ALWAYS,
  ACCESS_CONTROL_RULE_CHECKIN
};

// APIS
const STATE_API = '/request_state';

const AUTHENTICATE_API = '/autenticar';
const IS_AUTHENTICATED_API = '/autenticado';
const SIGN_OUT_API = '/deslogar';

const EXPERIMENTAL_CLASSES_GET_API = '/aulas-experimentais';
const EXPERIMENTAL_CLASS_GET_API = '/experimental-class/';
const EXPERIMENTAL_CLASS_DELETE_API = '/aula-experimental/';
const EXPERIMENTAL_CLASS_PATCH_API = '/aula-experimental/';
const EXPERIMENTAL_CLASS_CHECK_IN_API = '/aula-experimental/agendados/';
const EXPERIMENTAL_CLASS_SCHEDULE_GET_API = '/dias-e-horarios';
const EXPERIMENTAL_CLASS_SCHEDULE_POST_API = '/gerenciar-dias-e-horarios';
const EXPERIMENTAL_CLASS_SERVICES_GET_API = '/aulas-experimentais/servicos';

const USERS_GET_API = '/usuarios';
const USER_GET_API = '/usuario/';
const USER_PATCH_API = '/usuario/';
const USER_POST_API = '/usuario';
const USER_DELETE_API = '/usuario/';

const USER_IMAGE_POST_API = '/user/{id}/image';

const STUDENTS_GET_API = '/alunos';
const STUDENT_GET_API = '/aluno/';
const STUDENT_PATCH_API = '/aluno/';
const STUDENT_POST_API = '/aluno';
const STUDENT_DELETE_API = '/aluno/';
const STUDENT_BASIC_DATA_PATCH_API = '/aluno/{id}/basic-data';

const SERVICES_GET_API = '/planos';
const SERVICE_GET_API = '/plano/';
const SERVICE_PATCH_API = '/plano/';
const SERVICE_POST_API = '/plano';
const SERVICE_DELETE_API = '/plano/';

const SERVICE_GROUPS_GET_API = '/agrupamento-de-planos';
const SERVICE_GROUP_GET_API = '/agrupamento-de-plano/';
const SERVICE_GROUP_PATCH_API = '/agrupamento-de-plano/';
const SERVICE_GROUP_POST_API = '/agrupamento-de-plano';
const SERVICE_GROUP_DELETE_API = '/agrupamento-de-plano/';

const COST_CENTERS_GET_API = '/centros-de-custo';
const COST_CENTER_GET_API = '/centro-de-custo/';
const COST_CENTER_PATCH_API = '/centro-de-custo/';
const COST_CENTER_POST_API = '/centro-de-custo';
const COST_CENTER_DELETE_API = '/centro-de-custo/';

const FINANCIAL_CATEGORIES_GET_API = '/categoria-financeiras';
const FINANCIAL_CATEGORY_GET_API = '/categoria-financeiras/';
const FINANCIAL_CATEGORY_PATCH_API = '/categoria-financeiras/';
const FINANCIAL_CATEGORY_POST_API = '/categoria-financeiras';
const FINANCIAL_CATEGORY_DELETE_API = '/categoria-financeiras/';

const FEEDBACKS_GET_API = '/feedbacks';
const FEEDBACK_GET_API = '/feedback/';
const FEEDBACK_DELETE_API = '/pesquisa-de-satisfacao/';
const FEEDBACK_PATH_GET_API = '/feedback-path';

const CONTRACTS_GET_API = '/contratos';
const CONTRACTS_GET_API_V2 = '/v2/contratos';
const CONTRACT_GET_API = '/contrato/';
const CONTRACT_POST_API = '/contrato';
const CONTRACT_PATCH_API = '/contrato/';
const CONTRACT_DELETE_API = '/contrato/';
const CONTRACT_CANCEL_API = '/contrato/{id}/cancelar';

const CONTRACT_CHECK_DISCOUNT_COUPON_POST_API = '/contrato/validate-discount-coupon';

const PAYMENT_METHODS_GET_API = '/metodos-de-pagamento';

const FINANCIAL_MOVEMENTS_GET_API = '/movimentacoes-financeiras';
const FINANCIAL_TRANSACTIONS_GET_API = '/financial-transactions';
const FINANCIAL_MOVEMENT_GET_API = '/movimentacao-financeira/';
const EXPENSE_POST_API = '/adicionar-despesa';
const INCOME_POST_API = '/adicionar-receita';
const FINANCIAL_MOVEMENT_PATCH_API = '/movimentacao-financeira/';
const FINANCIAL_MOVEMENT_DELETE_API = '/movimentacao-financeira/';
const FINANCIAL_MOVEMENT_CANCEL_API = '/movimentacao-financeira/{id}/cancelar';

const FINANCIAL_TRANSACTIONS_CONFIRM_POST_API = '/financial-transactions/confirm-payment';
const FINANCIAL_TRANSACTIONS_GENERATE_NF_COUPONS_POST_API = '/financial-transactions/generate-nf-coupons';

const FINANCIAL_COACH_CLASS_SALARY_POST_API = '/coach/{coach_id}/register-class-salary';
const FINANCIAL_SALARY_MAP_GET_API = '/financial/salary-map/'

const PHYSICAL_EVALUATION_EXAMS_GET = '/avaliacao-fisica/datas-disponiveis';
const PHYSICAL_EVALUATION_EXAM_GET = '/avaliacao-fisica/data-disponivel/';
const PHYSICAL_EVALUATION_EXAM_POST = '/avaliacao-fisica/data-disponivel/';
const PHYSICAL_EVALUATION_EXAM_PATCH = '/avaliacao-fisica/data-disponivel/';
const PHYSICAL_EVALUATION_EXAM_DELETE = '/avaliacao-fisica/data-disponivel/';

const STUDENT_PHYSICAL_EVALUATION_PATCH = '/aluno/{id}/avaliacao-fisica';

const PHYSICAL_EVALUATION_SCHEDULED_EXAMS_GET = '/avaliacao-fisica/datas-agendadas';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_GET = '/avaliacao-fisica/data-agendada/';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_PATCH = '/avaliacao-fisica/data-agendada/';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_POST = '/avaliacao-fisica/adicionar-avaliacao';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_DELETE = '/avaliacao-fisica/data-agendada/';

const PHYSICAL_EVALUATION_SCHEDULED_EXAM_FRONT_PHOTO_POST = '/avaliacao-fisica/data-agendada/{id}/photos/front';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_SIDE_PHOTO_POST = '/avaliacao-fisica/data-agendada/{id}/photos/side';
const PHYSICAL_EVALUATION_SCHEDULED_EXAM_BACK_PHOTO_POST = '/avaliacao-fisica/data-agendada/{id}/photos/back';

const STUDENT_PHYSICAL_EVALUATION_PDF_GET = '/aluno/{student_id}/avaliacao-fisica/pdf';
const STUDENT_SINGLE_PHYSICAL_EVALUATION_PDF_GET = '/aluno/{student_id}/avaliacao-fisica/{exam_id}/pdf';

const NOTIFICATION_REGISTRATION_LINK_POST = '/notifications/registration-link';
const NOTIFICATIONS_GET_API = '/notifications';
const NOTIFICATION_GET_LAST_STATUS_UPDATE_API = '/notification/{notification_id}/last-status-update';
const NOTIFICATION_PHYSICAL_EVALUATION_SCHEDULE_LINK_POST = '/notifications/physical-evaluation-schedule-link';
const NOTIFICATION_PHYSICAL_EVALUATION_REPORT_LINK_POST = '/notifications/physical-evaluation-report-link';
const NOTIFICATION_APPOINTMENT_DELIVER_SCHEDULE_EMAILS_POST = '/notifications/appointment-deliver-schedule-emails';

const NOTIFICATION_PERSONAL_TRAINING_ACTIVE_POST = '/notifications/personal-training-active';

const NOTIFICATION_COACH_CLASS_SALARY_REPORT_POST_API = '/notifications/coach-class-salary-report';

const EXERCISE_FUNCTIONS_GET_API = '/training-period/exercise-functions';
const EXERCISE_FUNCTION_GET_API = '/training-period/exercise-functions/';
const EXERCISE_FUNCTION_PATCH_API = '/training-period/exercise-functions/';
const EXERCISE_FUNCTION_POST_API = '/training-period/exercise-functions';
const EXERCISE_FUNCTION_DELETE_API = '/training-period/exercise-functions/';

const MUSCLE_GROUPS_GET_API = '/training-period/muscle-groups'
const MUSCLE_GROUP_GET_API = '/training-period/muscle-groups/'
const MUSCLE_GROUP_PATCH_API = '/training-period/muscle-groups/'
const MUSCLE_GROUP_POST_API = '/training-period/muscle-groups'
const MUSCLE_GROUP_DELETE_API = '/training-period/muscle-groups/'

const EXERCISE_CATEOGRIES_GET_API = '/training-period/exercise-categories';
const EXERCISE_CATEGORY_GET_API = '/training-period/exercise-categories/';
const EXERCISE_CATEGORY_PATCH_API = '/training-period/exercise-categories/';
const EXERCISE_CATEGORY_POST_API = '/training-period/exercise-categories';
const EXERCISE_CATEGORY_DELETE_API = '/training-period/exercise-categories/';

const TRAINING_EXERCISES_GET_API = '/training-period/training-exercises';
const TRAINING_EXERCISE_GET_API = '/training-period/training-exercises/';
const TRAINING_EXERCISE_PATCH_API = '/training-period/training-exercises/';
const TRAINING_EXERCISE_POST_API = '/training-period/training-exercises';
const TRAINING_EXERCISE_DELETE_API = '/training-period/training-exercises/';

const TRAINING_PERIODS_GET_API = '/training-periods';
const TRAINING_PERIOD_GET_API = '/training-periods/';
const TRAINING_PERIOD_PATCH_API = '/training-periods/';
const TRAINING_PERIOD_POST_API = '/training-periods';
const TRAINING_PERIOD_DELETE_API = '/training-periods/';

const TRAINING_PERIOD_DUPLICATE_POST_API = '/duplicate-training-period/';

const TRAINING_DAYS_GET_API = '/training-days';
const TRAINING_DAY_GET_API = '/training-days/';
const TRAINING_DAY_PATCH_API = '/training-days/';
const TRAINING_DAY_DELETE_API = '/training-days/';

const TRAINING_DAY_CLASSROOM_PATH_API = '/classroom-path';

const EXERCISE_GROUPS_GET_API = '/exercise-groups';

const TRAINING_DAY_GROUP_ASSOCIATION_GET_API = '/training-day-group-associations/';
const TRAINING_DAY_GROUP_ASSOCIATION_PATCH_API = '/training-day-group-associations/';
const TRAINING_DAY_GROUP_ASSOCIATION_DELETE_API = '/training-day-group-associations/';
const TRAINING_DAY_GROUP_ASSOCIATION_UPDATE_LINK_POST_API = '/training-day-group-associations/{id}/update-link-with-previous-group';
const TRAINING_DAY_GROUP_ASSOCIATION_CREATE_AUDIO_UPLOAD_URL_POST_API = '/training-day-group-associations/{id}/generate-audio-upload-url';
const TRAINING_DAY_GROUP_ASSOCIATION_CONFIRM_AUDIO_UPLOAD_POST_API = '/training-day-group-associations/{id}/confirm-audio-upload';
const TRAINING_DAY_GROUP_ASSOCIATION_DELETE_AUDIO_FILE_API = '/training-day-group-associations/{id}/delete-audio-file';

const TRAINING_GROUP_EXERCISE_ASSOCIATION_GET_API = '/training-group-exercise-associations/';
const TRAINING_GROUP_EXERCISE_ASSOCIATION_PATCH_API = '/training-group-exercise-associations/';
const TRAINING_GROUP_EXERCISE_ASSOCIATION_DELETE_API = '/training-group-exercise-associations/';

const TRAINING_PERIOD_SCHEDULES_GET_API = '/training-period-schedules';
const TRAINING_PERIOD_SCHEDULE_GET_API = '/training-period-schedules/';
const TRAINING_PERIOD_SCHEDULE_PATCH_API = '/training-period-schedules/';
const TRAINING_PERIOD_SCHEDULE_POST_API = '/training-period-schedules';
const TRAINING_PERIOD_SCHEDULE_DELETE_API = '/training-period-schedules/';

const TRAINING_CALENDAR_GET_API = '/training-calendar';
const TRAINING_CALENDAR_MONTH_GET_API = '/training-calendar/';
const TRAINING_CALENDAR_DATE_POST_API = '/training-calendar/';

const TRAINING_CALENDAR_OVERWRITE_POST_API = '/training-calendar/overwrite-training/';
const TRAINING_CALENDAR_OVERWRITE_DELETE_API = '/training-calendar/overwrite-training/';

const STUDENT_CLASS_CHECKIN_GET_API = '/student-class-checkin/{service}/{date_id}';

const TRAINING_PERIOD_SERVICES_GET_API = '/training-period-services';

const COACHES_GET_API = '/coaches';
const COACH_GET_API = '/coach/';
const COACH_PATCH_API = '/coach/';
const COACH_POST_API = '/coach';
const COACH_DELETE_API = '/coach/';

const HEAD_COACH_DELETE_API = '/head-coach/'

const USER_DEVICES_GET_API = '/user-devices';
const USER_DEVICE_GET_API = '/user-device/';
const USER_DEVICE_PATCH_API = '/user-device/';
const USER_DEVICE_DELETE_API = '/user-device/';

const TRAINING_TIMES_GET_API = '/training-times';
const TRAINING_TIMES_POST_API = '/training-times';

const TRAINING_CLASSES_GET_API = '/training-classes';
const TRAINING_DATA_ENTRIES_GET_API = '/training-data';

const USER_SEND_VALIDATION_EMAIL_POST_API = '/send-validation-email';
const USER_VALIDATE_EMAIL_POST_API = '/validate-email/';
const USER_UPDATE_PASSWORD_POST_API = '/update-password/';

const STUDENT_SEND_VALIDATION_EMAIL_POST_API = '/send-validation-email-to-student';

const USER_INITIATE_EMAIL_CHANGE_POST_API = '/initiate-user-email-change';
const INITIATE_PASSWORD_RECOVERY_POST_API = '/initiate-password-recovery';

const HOME_TRAININGS_GET_API = '/home-trainings';
const HOME_TRAINING_POST_API = '/home-training';
const HOME_TRAINING_DELETE_API = '/home-training/';

const PERSONAL_TRAINING_PERIOD_GET_API = '/aluno/{id}/personal-training/active-period';
const PERSONAL_TRAINING_PERIOD_POST_API = '/aluno/{id}/personal-training/active-period';
const PERSONAL_TRAINING_PERIOD_DELETE_API = '/aluno/{id}/personal-training/active-period';

const PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_POST_API = '/aluno/{student_id}/personal-training/schedule-configuration';
const PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_DELETE_API = '/aluno/{student_id}/personal-training/schedule-configuration';

const PERSONAL_TRAINING_CLASSBOARD_GET_API = '/aluno/{student_id}/personal-training/training/';
const PERSONAL_TRAINING_EXERCISE_REFERENCE_POST_API = '/aluno/{student_id}/exercise-references';

const PERSONAL_TRAINING_DATA_GET_API = '/aluno/{student_id}/personal-training-data';
const PERSONAL_TRAINING_DATA_ENTRY_GET_API = '/personal-training-data/';
const PERSONAL_TRAINING_DATA_PATCH_API = '/personal-training-data/';
const PERSONAL_TRAINING_DATA_POST_API = '/aluno/{student_id}/personal-training-data/';
const PERSONAL_TRAINING_DATA_DELETE_API = '/personal-training-data/';

const PERSONAL_TRAINING_DUPLICATE_PERIOD_POST_API = '/aluno/{student_id}/personal-training/duplicate-training-period/';

const EXERCISE_ACTIVITY_TYPES_GET_API = '/training-period/exercise-activity-types';
const EXERCISE_ACTIVITY_TYPE_GET_API = '/training-period/exercise-activity-types/';
const EXERCISE_ACTIVITY_TYPE_POST_API = '/training-period/exercise-activity-types';
const EXERCISE_ACTIVITY_TYPE_DELETE_API = '/training-period/exercise-activity-types/';
const EXERCISE_ACTIVITY_TYPE_PATCH_API = '/training-period/exercise-activity-types/';

const EXERCISE_ACTIVITIES_GET_API = '/training-period/exercise-activities';
const EXERCISE_ACTIVITY_GET_API = '/training-period/exercise-activities/';
const EXERCISE_ACTIVITY_POST_API = '/training-period/exercise-activities';
const EXERCISE_ACTIVITY_DELETE_API = '/training-period/exercise-activities/';
const EXERCISE_ACTIVITY_PATCH_API = '/training-period/exercise-activities/';

const UNIQUE_STUDENT_FLUX_GET_API = '/unique_student_flux';

const HR_DEVICES_GET_API = '/hr-devices';
const HR_DEVICE_GET_API = '/hr-device/';
const HR_DEVICE_PATCH_API = '/hr-device/';
const HR_DEVICE_DELETE_API = '/hr-device/';
const HR_DEVICE_POST_API = '/hr-devices';

const RECEPTIONISTS_GET_API = '/receptionists';
const RECEPTIONIST_GET_API = '/receptionist/';
const RECEPTIONIST_PATCH_API = '/receptionist/';
const RECEPTIONIST_POST_API = '/receptionist';
const RECEPTIONIST_DELETE_API = '/receptionist/';

const ADMIN_RECEPTIONIST_DELETE_API = '/admin-receptionist/';

const NUTRITIONISTS_GET_API = '/nutritionists';
const NUTRITIONIST_GET_API = '/nutritionist/';
const NUTRITIONIST_DELETE_API = '/nutritionist/';
const NUTRITIONIST_PATCH_API = '/nutritionist/';
const NUTRITIONIST_POST_API = '/nutritionist';

const ADMIN_NUTRITIONIST_DELETE_API = '/admin-nutritionist/';

const PHYSICAL_EVALUATION_DATA_GET_API = '/aluno/{student_id}/avaliacao-fisica/dados-complementares';
const PHYSICAL_EVALUATION_DATA_POST_API = '/aluno/{student_id}/avaliacao-fisica/dados-complementares';

const PERSONAL_TRAINING_HISTORY_DELETE_API = '/personal-training-history/';

const FOOD_INGREDIENTS_GET_API = '/food-ingredients';
const FOOD_INGREDIENT_GET_API = '/food-ingredient/';
const FOOD_INGREDIENT_PATCH_API = '/food-ingredient/';
const FOOD_INGREDIENT_POST_API = '/food-ingredient';
const FOOD_INGREDIENT_DELETE_API = '/food-ingredient/';

const INGREDIENT_SOURCE_ASSOCIATION_DELETE_API = '/food-ingredient-source-association/';

const INGREDIENT_PROCESSING_METHODS_GET_API = '/ingredient-processing-methods';

const FOOD_NUTRIENTS_GET_API = '/food-nutrients';

const FOOD_CLASSIFICATIONS_GET_API = '/food-classifications';
const FOOD_CLASSIFICATION_GET_API = '/food-classification/';
const FOOD_CLASSIFICATION_POST_API = '/food-classification';
const FOOD_CLASSIFICATION_PATCH_API = '/food-classification/';
const FOOD_CLASSIFICATION_DELETE_API = '/food-classification/';

const FOOD_INFO_SOURCES_GET_API = '/food-info-sources';

const NUTRIENT_CLASSIFICATIONS_GET_API = '/nutrient-classifications';

const FOOD_ALLERGENS_GET_API = '/food-allergens';

const MEAL_CLASSIFICATIONS_GET_API = '/meal-classifications';
const MEAL_CLASSIFICATION_GET_API = '/meal-classification/';
const MEAL_CLASSIFICATION_DELETE_API = '/meal-classification/';
const MEAL_CLASSIFICATION_PATCH_API = '/meal-classification/';
const MEAL_CLASSIFICATION_POST_API = '/meal-classification';

const RECIPE_DIFFICULTIES_GET_API = '/recipe-difficulties';

const FOOD_RECIPES_GET_API = '/food-recipes';
const FOOD_RECIPE_GET_API = '/food-recipe/';
const FOOD_RECIPE_DELETE_API = '/food-recipe/';
const FOOD_RECIPE_PATCH_API = '/food-recipe/';
const FOOD_RECIPE_POST_API = '/food-recipe';

const STUDENT_FOOD_RECIPE_DUPLICATE_POST_API = '/student/{student_id}/duplicate-food-recipe/';
const FOOD_RECIPE_DUPLICATE_POST_API = '/duplicate-food-recipe/';

const WEIGHT_UNITS_GET_API = '/weight-units';
const VOLUME_UNITS_GET_API = '/volume-units';

const FOOD_MEASUREMENTS_GET_API = '/food-measurements';

const MEAL_PERIODS_GET_API = '/meal-periods';
const MEAL_PERIOD_GET_API = '/meal-period/';
const MEAL_PERIOD_DELETE_API = '/meal-period/';
const MEAL_PERIOD_PATCH_API = '/meal-period/';
const MEAL_PERIOD_POST_API = '/meal-period';

const FOOD_PRESCRIPTIONS_GET_API = '/food-prescriptions';
const FOOD_PRESCRIPTION_GET_API = '/food-prescription/';
const FOOD_PRESCRIPTION_DELETE_API = '/food-prescription/';
const FOOD_PRESCRIPTION_PATCH_API = '/food-prescription/';
const FOOD_PRESCRIPTION_POST_API = '/food-prescription';

const STUDENT_FOOD_PRESCRIPTION_DUPLICATE_POST_API = '/student/{student_id}/duplicate-food-prescription/';
const FOOD_PRESCRIPTION_DUPLICATE_POST_API = '/duplicate-food-prescription/';

const FOOD_PRESCRIPTION_PDF_API = '/food-prescription/{food_prescription_id}/pdf';

const STUDENT_FOOD_PRESCRIPTION_GET_API = '/student/{id}/food-prescription';
const STUDENT_FOOD_PRESCRIPTION_POST_API = '/student/{id}/food-prescription';
const STUDENT_FOOD_PRESCRIPTION_DELETE_API = '/student/{id}/food-prescription';

const STUDENT_PRESCRIPTION_SERVICE_TRACKING_PATCH_API = '/student/{student_id}/food-prescription-service/update-tracking-status';
const STUDENT_PRESCRIPTION_SERVICE_STATUS_PATCH_API = '/student/{student_id}/food-prescription-service/update-service-status';
const STUDENT_PRESCRIPTION_SERVICE_STATUS_DELETE_API = '/student/{student_id}/food-prescription-service/delete-service-status';

const STUDENT_FOOD_PRESCRIPTION_HISTORY_DELETE_API = '/student-food-prescription-history/';

const FOOD_PRESCRIPTION_SERVICE_STUDENTS_GET_API = '/food-prescription-service-students';

const NUTRI_SERVICE_LEADS_GET_API = '/nutri-service-leads';
const NUTRI_SERVICE_LEAD_DELETE_API = '/nutri-service-lead/';
const NUTRI_SERVICE_LEAD_PATCH_API = '/nutri-service-lead/';

const CRM_CLIENT_LEADS_GET_API = '/client-leads';
const CRM_CLIENT_LEAD_PATCH_API = '/client-lead/';
const CRM_CLIENT_LEAD_DELETE_API = '/client-lead/';

const ROLE_EXPERIENCE_LEVELS_GET_API = '/role-experience-levels';
const ROLE_EXPERIENCE_LEVEL_GET_API = '/role-experience-level/';
const ROLE_EXPERIENCE_LEVEL_POST_API = '/role-experience-level';
const ROLE_EXPERIENCE_LEVEL_PATCH_API = '/role-experience-level/';
const ROLE_EXPERIENCE_LEVEL_DELETE_API = '/role-experience-level/';

const ANALYTICS_WEBSITE_USER_COUNT_GET_API = '/analytics/website/total_users'

const CUSTOMER_FEEDBACK_TARGETS_GET_API = '/customer-feedback-targets';
const CUSTOMER_FEEDBACK_TARGET_GET_API = '/customer-feedback-target/';
const CUSTOMER_FEEDBACK_TARGET_PATCH_API = '/customer-feedback-target/';
const CUSTOMER_FEEDBACK_TARGET_DELETE_API = '/customer-feedback-target/';
const CUSTOMER_FEEDBACK_TARGET_POST_API = '/customer-feedback-target';

const CUSTOMER_FEEDBACK_TYPES_GET_API = '/customer-feedback-types';

const CUSTOMER_FEEDBACKS_GET_API = '/customer-feedbacks';
const CUSTOMER_FEEDBACK_GET_API = '/customer-feedback/';
const CUSTOMER_FEEDBACK_PATCH_API = '/customer-feedback/';
const CUSTOMER_FEEDBACK_DELETE_API = '/customer-feedback/';

const APPOINTMENT_TYPES_GET_API = '/appointment-types';

const APPOINTMENT_ROOMS_GET_API = '/appointment-rooms';
const APPOINTMENT_ROOM_GET_API = '/appointment-room/';
const APPOINTMENT_ROOM_POST_API = '/appointment-room';
const APPOINTMENT_ROOM_PATCH_API = '/appointment-room/';
const APPOINTMENT_ROOM_DELETE_API = '/appointment-room/';

const APPOINTMENT_RESPONSIBLE_OPTIONS_GET_API = '/available-appointment-responsibles';

const APPOINTMENT_SCHEDULES_GET_API = '/appointment-schedules';
const APPOINTMENT_SCHEDULE_GET_API = '/appointment-schedule/';
const APPOINTMENT_SCHEDULE_POST_API = '/appointment-schedule';
const APPOINTMENT_SCHEDULE_PATCH_API = '/appointment-schedule/';
const APPOINTMENT_SCHEDULE_DELETE_API = '/appointment-schedule/';

const APPOINTMENTS_GET_API = '/appointments';
const APPOINTMENT_GET_API = '/appointment/';
const APPOINTMENT_POST_API = '/appointment';
const APPOINTMENT_PATCH_API = '/appointment/';
const APPOINTMENT_DELETE_API = '/appointment/';

const PERSONAL_TRAINING_SERVICE_STUDENTS_GET_API = '/personal-training-service-students';

const STUDENT_PERSONAL_TRAINING_SERVICE_TRACKING_PATCH_API = '/student/{student_id}/personal-training-service/update-tracking-status';

const STUDENT_PERSONAL_TRAINING_SERVICE_STATUS_GET_API = '/student/{student_id}/personal-training-service/status';

const STUDENT_PERSONAL_TRAINING_SERVICE_FINISH_REVISION_POST_API = '/student/{student_id}/personal-training-service/finish-revision';
const STUDENT_NUTRI_SERVICE_FINISH_REVISION_POST_API = '/student/{student_id}/nutritional-service/finish-revision';

const SUPPORT_TICKETS_GET_API = '/support-tickets';
const SUPPORT_TICKET_POST_API = '/support-ticket';
const CLOSE_SUPPORT_TICKET_POST_API = '/support-ticket/{support_ticket_id}/close-ticket';
const SUPPORT_MESSAGE_POST_API = '/support-ticket/{support_ticket_id}/message';

const SUPPORT_CATEGORIES_GET_API = '/support-categories';

const SATISFACTION_REPORT_DATA_GET_API = '/monthly-satisfaction-survey/data';

const PROMOTIONAL_COUPONS_GET_API = '/promotional-coupons';
const PROMOTIONAL_COUPON_GET_API = '/promotional-coupon/';
const PROMOTIONAL_COUPON_POST_API = '/promotional-coupon';
const PROMOTIONAL_COUPON_PATCH_API = '/promotional-coupon/';
const PROMOTIONAL_COUPON_DELETE_API = '/promotional-coupon/';

const PAYMENT_DEVICES_AVAILABLE_GET_API = '/available-payment-devices';

const PAYMENT_DEVICES_GET_API = '/payment-devices';
const PAYMENT_DEVICE_GET_API = '/payment-device/';
const PAYMENT_DEVICE_PATCH_API = '/payment-device/';
const PAYMENT_DEVICE_DELETE_API = '/payment-device/';
const PAYMENT_DEVICE_POST_API = '/payment-devices';

const SEND_PAYMENT_TO_DEVICE_POST_API = '/send-payment-to-device';
const CANCEL_PAYMENT_DEVICE_REQUEST_PATCH_API = '/payment-device-request/{id}/cancel-request';

const OPERATIONAL_TASKS_GET_API = '/operational-tasks';
const OPERATIONAL_TASK_GET_API = '/operational-task/';
const OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API = '/operational-task/{id}/update-status';
const OPERATIONAL_TASK_PATCH_API = '/operational-task/';
const OPERATIONAL_TASK_DELETE_API = '/operational-task/';
const OPERATIONAL_TASK_POST_API = '/operational-tasks';
const OPERATIONAL_TASK_MANAGEMENT_POST_API = '/operational-tasks/management-task';

const FINANCIAL_SEARCH_TAGS_GET_API = '/financial-search-tags';
const FINANCIAL_SEARCH_TAG_GET_API = '/financial-search-tag/';
const FINANCIAL_SEARCH_TAG_PATCH_API = '/financial-search-tag/';
const FINANCIAL_SEARCH_TAG_DELETE_API = '/financial-search-tag/';
const FINANCIAL_SEARCH_TAG_POST_API = '/financial-search-tags';

const ACCESS_CONTROL_DEVICES_GET_API = '/access-control-devices';
const ACCESS_CONTROL_DEVICE_GET_API = '/access-control-device/';
const ACCESS_CONTROL_DEVICE_PATCH_API = '/access-control-device/';
const ACCESS_CONTROL_DEVICE_DELETE_API = '/access-control-device/';
const ACCESS_CONTROL_DEVICE_POST_API = '/access-control-devices';

const ACCESS_CONTROL_DEVICE_CONFIGURATION_GET_API = '/access-control-device/{id}/configuration';
const ACCESS_CONTROL_DEVICE_CONFIGURATION_POST_API = '/access-control-device/{id}/configuration';

const ACCESS_CONTROL_DEVICE_OPEN_DOOR_POST_API = '/access-control-device/{id}/open-door';

const ACCESS_CONTROL_DEVICE_REQUEST_USER_PICTURE_GET_API = '/access-control-device/{id}/request-user-picture';

const USER_ACCESS_CONTROL_DEVICE_ASSOCIATIONS_GET_API = '/user/{id}/access-control/device-associations';
const USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API = '/user/{id}/access-control/device-association';
const USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_DELETE_API = '/user/{id}/access-control/device-association';

const USER_ACCESS_CONTROL_ACCESS_RULE_GET_API = '/user/{id}/access-control/access-rule';
const USER_ACCESS_CONTROL_ACCESS_RULE_POST_API = '/user/{id}/access-control/access-rule';

const STAFF_GET_API = '/colaboradores';

export {
  STATE_API,
  AUTHENTICATE_API,
  IS_AUTHENTICATED_API,
  SIGN_OUT_API,
  EXPERIMENTAL_CLASSES_GET_API,
  EXPERIMENTAL_CLASS_GET_API,
  EXPERIMENTAL_CLASS_DELETE_API,
  EXPERIMENTAL_CLASS_PATCH_API,
  EXPERIMENTAL_CLASS_CHECK_IN_API,
  EXPERIMENTAL_CLASS_SCHEDULE_GET_API,
  EXPERIMENTAL_CLASS_SCHEDULE_POST_API,
  EXPERIMENTAL_CLASS_SERVICES_GET_API,
  USERS_GET_API,
  USER_GET_API,
  USER_PATCH_API,
  USER_POST_API,
  USER_DELETE_API,
  USER_IMAGE_POST_API,
  STUDENTS_GET_API,
  STUDENT_GET_API,
  STUDENT_PATCH_API,
  STUDENT_POST_API,
  STUDENT_DELETE_API,
  STUDENT_BASIC_DATA_PATCH_API,
  SERVICES_GET_API,
  SERVICE_GET_API,
  SERVICE_PATCH_API,
  SERVICE_POST_API,
  SERVICE_DELETE_API,
  SERVICE_GROUPS_GET_API,
  SERVICE_GROUP_GET_API,
  SERVICE_GROUP_PATCH_API,
  SERVICE_GROUP_POST_API,
  SERVICE_GROUP_DELETE_API,
  COST_CENTERS_GET_API,
  COST_CENTER_GET_API,
  COST_CENTER_PATCH_API,
  COST_CENTER_POST_API,
  COST_CENTER_DELETE_API,
  FINANCIAL_CATEGORIES_GET_API,
  FINANCIAL_CATEGORY_GET_API,
  FINANCIAL_CATEGORY_PATCH_API,
  FINANCIAL_CATEGORY_POST_API,
  FINANCIAL_CATEGORY_DELETE_API,
  FEEDBACKS_GET_API,
  FEEDBACK_GET_API,
  FEEDBACK_DELETE_API,
  CONTRACTS_GET_API,
  CONTRACTS_GET_API_V2,
  CONTRACT_GET_API,
  CONTRACT_POST_API,
  CONTRACT_PATCH_API,
  CONTRACT_DELETE_API,
  CONTRACT_CANCEL_API,
  CONTRACT_CHECK_DISCOUNT_COUPON_POST_API,
  PAYMENT_METHODS_GET_API,
  FINANCIAL_MOVEMENTS_GET_API,
  FINANCIAL_TRANSACTIONS_GET_API,
  FINANCIAL_MOVEMENT_GET_API,
  EXPENSE_POST_API,
  INCOME_POST_API,
  FINANCIAL_MOVEMENT_PATCH_API,
  FINANCIAL_MOVEMENT_DELETE_API,
  FINANCIAL_MOVEMENT_CANCEL_API,
  FINANCIAL_TRANSACTIONS_CONFIRM_POST_API,
  FINANCIAL_COACH_CLASS_SALARY_POST_API,
  FINANCIAL_SALARY_MAP_GET_API,
  PHYSICAL_EVALUATION_EXAMS_GET,
  PHYSICAL_EVALUATION_EXAM_GET,
  PHYSICAL_EVALUATION_EXAM_POST,
  PHYSICAL_EVALUATION_EXAM_PATCH,
  PHYSICAL_EVALUATION_EXAM_DELETE,
  STUDENT_PHYSICAL_EVALUATION_PATCH,
  PHYSICAL_EVALUATION_SCHEDULED_EXAMS_GET,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_GET,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_PATCH,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_POST,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_DELETE,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_FRONT_PHOTO_POST,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_SIDE_PHOTO_POST,
  PHYSICAL_EVALUATION_SCHEDULED_EXAM_BACK_PHOTO_POST,
  STUDENT_PHYSICAL_EVALUATION_PDF_GET,
  STUDENT_SINGLE_PHYSICAL_EVALUATION_PDF_GET,
  NOTIFICATION_REGISTRATION_LINK_POST,
  NOTIFICATIONS_GET_API,
  NOTIFICATION_GET_LAST_STATUS_UPDATE_API,
  NOTIFICATION_PHYSICAL_EVALUATION_SCHEDULE_LINK_POST,
  NOTIFICATION_PHYSICAL_EVALUATION_REPORT_LINK_POST,
  NOTIFICATION_APPOINTMENT_DELIVER_SCHEDULE_EMAILS_POST,
  NOTIFICATION_PERSONAL_TRAINING_ACTIVE_POST,
  NOTIFICATION_COACH_CLASS_SALARY_REPORT_POST_API,
  EXERCISE_FUNCTIONS_GET_API,
  EXERCISE_FUNCTION_GET_API,
  EXERCISE_FUNCTION_PATCH_API,
  EXERCISE_FUNCTION_POST_API,
  EXERCISE_FUNCTION_DELETE_API,
  EXERCISE_CATEOGRIES_GET_API,
  EXERCISE_CATEGORY_GET_API,
  EXERCISE_CATEGORY_PATCH_API,
  EXERCISE_CATEGORY_POST_API,
  EXERCISE_CATEGORY_DELETE_API,
  TRAINING_EXERCISES_GET_API,
  TRAINING_EXERCISE_GET_API,
  TRAINING_EXERCISE_PATCH_API,
  TRAINING_EXERCISE_POST_API,
  TRAINING_EXERCISE_DELETE_API,
  TRAINING_PERIODS_GET_API,
  TRAINING_PERIOD_GET_API,
  TRAINING_PERIOD_PATCH_API,
  TRAINING_PERIOD_POST_API,
  TRAINING_PERIOD_DELETE_API,
  TRAINING_PERIOD_DUPLICATE_POST_API,
  TRAINING_DAYS_GET_API,
  TRAINING_DAY_GET_API,
  TRAINING_DAY_PATCH_API,
  TRAINING_DAY_DELETE_API,
  TRAINING_DAY_CLASSROOM_PATH_API,
  EXERCISE_GROUPS_GET_API,
  TRAINING_DAY_GROUP_ASSOCIATION_GET_API,
  TRAINING_DAY_GROUP_ASSOCIATION_PATCH_API,
  TRAINING_DAY_GROUP_ASSOCIATION_DELETE_API,
  TRAINING_DAY_GROUP_ASSOCIATION_UPDATE_LINK_POST_API,
  TRAINING_DAY_GROUP_ASSOCIATION_CREATE_AUDIO_UPLOAD_URL_POST_API,
  TRAINING_DAY_GROUP_ASSOCIATION_CONFIRM_AUDIO_UPLOAD_POST_API,
  TRAINING_DAY_GROUP_ASSOCIATION_DELETE_AUDIO_FILE_API,
  TRAINING_GROUP_EXERCISE_ASSOCIATION_GET_API,
  TRAINING_GROUP_EXERCISE_ASSOCIATION_PATCH_API,
  TRAINING_GROUP_EXERCISE_ASSOCIATION_DELETE_API,
  TRAINING_PERIOD_SCHEDULES_GET_API,
  TRAINING_PERIOD_SCHEDULE_GET_API,
  TRAINING_PERIOD_SCHEDULE_PATCH_API,
  TRAINING_PERIOD_SCHEDULE_POST_API,
  TRAINING_PERIOD_SCHEDULE_DELETE_API,
  TRAINING_CALENDAR_GET_API,
  TRAINING_CALENDAR_MONTH_GET_API,
  TRAINING_CALENDAR_DATE_POST_API,
  TRAINING_PERIOD_SERVICES_GET_API,
  COACHES_GET_API,
  COACH_GET_API,
  COACH_PATCH_API,
  COACH_POST_API,
  COACH_DELETE_API,
  HEAD_COACH_DELETE_API,
  USER_DEVICES_GET_API,
  USER_DEVICE_GET_API,
  USER_DEVICE_PATCH_API,
  USER_DEVICE_DELETE_API,
  TRAINING_TIMES_GET_API,
  TRAINING_TIMES_POST_API,
  TRAINING_CLASSES_GET_API,
  TRAINING_DATA_ENTRIES_GET_API,
  USER_SEND_VALIDATION_EMAIL_POST_API,
  STUDENT_SEND_VALIDATION_EMAIL_POST_API,
  USER_VALIDATE_EMAIL_POST_API,
  USER_UPDATE_PASSWORD_POST_API,
  USER_INITIATE_EMAIL_CHANGE_POST_API,
  INITIATE_PASSWORD_RECOVERY_POST_API,
  TRAINING_CALENDAR_OVERWRITE_POST_API,
  TRAINING_CALENDAR_OVERWRITE_DELETE_API,
  STUDENT_CLASS_CHECKIN_GET_API,
  HOME_TRAININGS_GET_API,
  HOME_TRAINING_POST_API,
  HOME_TRAINING_DELETE_API,
  PERSONAL_TRAINING_PERIOD_GET_API,
  PERSONAL_TRAINING_PERIOD_POST_API,
  PERSONAL_TRAINING_PERIOD_DELETE_API,
  PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_POST_API,
  PERSONAL_TRAINING_SCHEDULE_CONFIGURATION_DELETE_API,
  EXERCISE_ACTIVITY_TYPES_GET_API,
  EXERCISE_ACTIVITY_TYPE_GET_API,
  EXERCISE_ACTIVITY_TYPE_POST_API,
  EXERCISE_ACTIVITY_TYPE_DELETE_API,
  EXERCISE_ACTIVITY_TYPE_PATCH_API,
  EXERCISE_ACTIVITIES_GET_API,
  EXERCISE_ACTIVITY_GET_API,
  EXERCISE_ACTIVITY_POST_API,
  EXERCISE_ACTIVITY_DELETE_API,
  EXERCISE_ACTIVITY_PATCH_API,
  PERSONAL_TRAINING_CLASSBOARD_GET_API,
  PERSONAL_TRAINING_EXERCISE_REFERENCE_POST_API,
  PERSONAL_TRAINING_DATA_GET_API,
  PERSONAL_TRAINING_DATA_ENTRY_GET_API,
  PERSONAL_TRAINING_DATA_PATCH_API,
  PERSONAL_TRAINING_DATA_POST_API,
  PERSONAL_TRAINING_DATA_DELETE_API,
  PERSONAL_TRAINING_DUPLICATE_PERIOD_POST_API,
  MUSCLE_GROUPS_GET_API,
  MUSCLE_GROUP_GET_API,
  MUSCLE_GROUP_PATCH_API,
  MUSCLE_GROUP_POST_API,
  MUSCLE_GROUP_DELETE_API,
  UNIQUE_STUDENT_FLUX_GET_API,
  HR_DEVICES_GET_API,
  HR_DEVICE_GET_API,
  HR_DEVICE_PATCH_API,
  HR_DEVICE_DELETE_API,
  HR_DEVICE_POST_API,
  RECEPTIONISTS_GET_API,
  RECEPTIONIST_GET_API,
  RECEPTIONIST_PATCH_API,
  RECEPTIONIST_POST_API,
  RECEPTIONIST_DELETE_API,
  ADMIN_RECEPTIONIST_DELETE_API,
  NUTRITIONISTS_GET_API,
  NUTRITIONIST_GET_API,
  NUTRITIONIST_DELETE_API,
  NUTRITIONIST_PATCH_API,
  NUTRITIONIST_POST_API,
  ADMIN_NUTRITIONIST_DELETE_API,
  PHYSICAL_EVALUATION_DATA_GET_API,
  PHYSICAL_EVALUATION_DATA_POST_API,
  PERSONAL_TRAINING_HISTORY_DELETE_API,
  FOOD_INGREDIENTS_GET_API,
  FOOD_INGREDIENT_GET_API,
  FOOD_INGREDIENT_PATCH_API,
  FOOD_INGREDIENT_POST_API,
  FOOD_INGREDIENT_DELETE_API,
  INGREDIENT_SOURCE_ASSOCIATION_DELETE_API,
  INGREDIENT_PROCESSING_METHODS_GET_API,
  FOOD_NUTRIENTS_GET_API,
  FOOD_CLASSIFICATIONS_GET_API,
  FOOD_CLASSIFICATION_GET_API,
  FOOD_CLASSIFICATION_POST_API,
  FOOD_CLASSIFICATION_PATCH_API,
  FOOD_CLASSIFICATION_DELETE_API,
  FOOD_INFO_SOURCES_GET_API,
  FOOD_ALLERGENS_GET_API,
  MEAL_CLASSIFICATIONS_GET_API,
  MEAL_CLASSIFICATION_GET_API,
  MEAL_CLASSIFICATION_DELETE_API,
  MEAL_CLASSIFICATION_PATCH_API,
  MEAL_CLASSIFICATION_POST_API,
  RECIPE_DIFFICULTIES_GET_API,
  FOOD_RECIPES_GET_API,
  FOOD_RECIPE_GET_API,
  FOOD_RECIPE_DELETE_API,
  FOOD_RECIPE_PATCH_API,
  FOOD_RECIPE_POST_API,
  STUDENT_FOOD_RECIPE_DUPLICATE_POST_API,
  FOOD_RECIPE_DUPLICATE_POST_API,
  WEIGHT_UNITS_GET_API,
  VOLUME_UNITS_GET_API,
  FOOD_MEASUREMENTS_GET_API,
  MEAL_PERIODS_GET_API,
  MEAL_PERIOD_GET_API,
  MEAL_PERIOD_DELETE_API,
  MEAL_PERIOD_PATCH_API,
  MEAL_PERIOD_POST_API,
  FOOD_PRESCRIPTIONS_GET_API,
  FOOD_PRESCRIPTION_GET_API,
  FOOD_PRESCRIPTION_DELETE_API,
  FOOD_PRESCRIPTION_PATCH_API,
  FOOD_PRESCRIPTION_POST_API,
  STUDENT_FOOD_PRESCRIPTION_DUPLICATE_POST_API,
  FOOD_PRESCRIPTION_DUPLICATE_POST_API,
  FOOD_PRESCRIPTION_PDF_API,
  STUDENT_FOOD_PRESCRIPTION_GET_API,
  STUDENT_FOOD_PRESCRIPTION_POST_API,
  STUDENT_FOOD_PRESCRIPTION_DELETE_API,
  STUDENT_PRESCRIPTION_SERVICE_TRACKING_PATCH_API,
  STUDENT_PRESCRIPTION_SERVICE_STATUS_PATCH_API,
  STUDENT_PRESCRIPTION_SERVICE_STATUS_DELETE_API,
  STUDENT_FOOD_PRESCRIPTION_HISTORY_DELETE_API,
  FOOD_PRESCRIPTION_SERVICE_STUDENTS_GET_API,
  NUTRI_SERVICE_LEADS_GET_API,
  NUTRI_SERVICE_LEAD_DELETE_API,
  NUTRI_SERVICE_LEAD_PATCH_API,
  CRM_CLIENT_LEADS_GET_API,
  CRM_CLIENT_LEAD_PATCH_API,
  CRM_CLIENT_LEAD_DELETE_API,
  ROLE_EXPERIENCE_LEVELS_GET_API,
  ROLE_EXPERIENCE_LEVEL_GET_API,
  ROLE_EXPERIENCE_LEVEL_POST_API,
  ROLE_EXPERIENCE_LEVEL_PATCH_API,
  ROLE_EXPERIENCE_LEVEL_DELETE_API,
  ANALYTICS_WEBSITE_USER_COUNT_GET_API,
  CUSTOMER_FEEDBACK_TARGETS_GET_API,
  CUSTOMER_FEEDBACK_TARGET_GET_API,
  CUSTOMER_FEEDBACK_TARGET_PATCH_API,
  CUSTOMER_FEEDBACK_TARGET_DELETE_API,
  CUSTOMER_FEEDBACK_TARGET_POST_API,
  CUSTOMER_FEEDBACK_TYPES_GET_API,
  CUSTOMER_FEEDBACKS_GET_API,
  CUSTOMER_FEEDBACK_GET_API,
  CUSTOMER_FEEDBACK_PATCH_API,
  CUSTOMER_FEEDBACK_DELETE_API,
  APPOINTMENT_TYPES_GET_API,
  APPOINTMENT_ROOMS_GET_API,
  APPOINTMENT_ROOM_GET_API,
  APPOINTMENT_ROOM_POST_API,
  APPOINTMENT_ROOM_PATCH_API,
  APPOINTMENT_ROOM_DELETE_API,
  APPOINTMENT_RESPONSIBLE_OPTIONS_GET_API,
  APPOINTMENT_SCHEDULES_GET_API,
  APPOINTMENT_SCHEDULE_GET_API,
  APPOINTMENT_SCHEDULE_POST_API,
  APPOINTMENT_SCHEDULE_PATCH_API,
  APPOINTMENT_SCHEDULE_DELETE_API,
  APPOINTMENTS_GET_API,
  APPOINTMENT_GET_API,
  APPOINTMENT_POST_API,
  APPOINTMENT_PATCH_API,
  APPOINTMENT_DELETE_API,
  PERSONAL_TRAINING_SERVICE_STUDENTS_GET_API,
  STUDENT_PERSONAL_TRAINING_SERVICE_TRACKING_PATCH_API,
  STUDENT_PERSONAL_TRAINING_SERVICE_STATUS_GET_API,
  STUDENT_PERSONAL_TRAINING_SERVICE_FINISH_REVISION_POST_API,
  STUDENT_NUTRI_SERVICE_FINISH_REVISION_POST_API,
  SUPPORT_TICKETS_GET_API,
  SUPPORT_TICKET_POST_API,
  CLOSE_SUPPORT_TICKET_POST_API,
  SUPPORT_MESSAGE_POST_API,
  SUPPORT_CATEGORIES_GET_API,
  SATISFACTION_REPORT_DATA_GET_API,
  PROMOTIONAL_COUPONS_GET_API,
  PROMOTIONAL_COUPON_GET_API,
  PROMOTIONAL_COUPON_POST_API,
  PROMOTIONAL_COUPON_PATCH_API,
  PROMOTIONAL_COUPON_DELETE_API,
  PAYMENT_DEVICES_GET_API,
  PAYMENT_DEVICE_GET_API,
  PAYMENT_DEVICE_PATCH_API,
  PAYMENT_DEVICE_DELETE_API,
  PAYMENT_DEVICE_POST_API,
  PAYMENT_DEVICES_AVAILABLE_GET_API,
  SEND_PAYMENT_TO_DEVICE_POST_API,
  CANCEL_PAYMENT_DEVICE_REQUEST_PATCH_API,
  OPERATIONAL_TASKS_GET_API,
  OPERATIONAL_TASK_GET_API,
  OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API,
  OPERATIONAL_TASK_PATCH_API,
  OPERATIONAL_TASK_DELETE_API,
  OPERATIONAL_TASK_POST_API,
  OPERATIONAL_TASK_MANAGEMENT_POST_API,
  FINANCIAL_SEARCH_TAGS_GET_API,
  FINANCIAL_SEARCH_TAG_GET_API,
  FINANCIAL_SEARCH_TAG_PATCH_API,
  FINANCIAL_SEARCH_TAG_DELETE_API,
  FINANCIAL_SEARCH_TAG_POST_API,
  ACCESS_CONTROL_DEVICES_GET_API,
  ACCESS_CONTROL_DEVICE_GET_API,
  ACCESS_CONTROL_DEVICE_PATCH_API,
  ACCESS_CONTROL_DEVICE_DELETE_API,
  ACCESS_CONTROL_DEVICE_POST_API,
  ACCESS_CONTROL_DEVICE_CONFIGURATION_GET_API,
  ACCESS_CONTROL_DEVICE_CONFIGURATION_POST_API,
  ACCESS_CONTROL_DEVICE_OPEN_DOOR_POST_API,
  USER_ACCESS_CONTROL_DEVICE_ASSOCIATIONS_GET_API,
  ACCESS_CONTROL_DEVICE_REQUEST_USER_PICTURE_GET_API,
  USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API,
  USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_DELETE_API,
  STAFF_GET_API,
  FINANCIAL_TRANSACTIONS_GENERATE_NF_COUPONS_POST_API,
  USER_ACCESS_CONTROL_ACCESS_RULE_GET_API,
  USER_ACCESS_CONTROL_ACCESS_RULE_POST_API,
};

// PATHS
const DESKTOP_PATH = '/area-de-trabalho';

const EXPERIMENTAL_CLASS_SCHEDULED_PATH = '/aula-experimental/agendados';
const EXPERIMENTAL_CLASS_COMPLETED_PATH = '/aula-experimental/completos';
const EXPERIMENTAL_CLASS_AVAILABLE_DAYS_PATH = '/gerenciar-dias-e-horarios';
const EXPERIMENTAL_CLASS_SITE_PATH = '/aula-experimental';
const EXPERIMENTAL_CLASS_EDIT_PATH = '/aula-experimental/';

const STUDENT_LIST_PATH = '/listar-alunos';
const STUDENT_ADD_PATH = '/adicionar-aluno';
const STUDENT_MANAGE_PATH = '/gerenciar-aluno/';
const STUDENT_EDIT_PATH = '/editar-aluno/';
const STUDENT_FORM_PATH = '/cadastro-de-aluno/';

const STUDENT_FOOD_HISTORY_PATH = '/historico-de-alimentacao/';

const STUDENT_REPORT_PATH = '/alunos/relatorio-geral';

const USER_LIST_PATH = '/lista-de-usuarios';
const USER_ADD_PATH = '/adicionar-usuario';
const USER_EDIT_PATH = '/editar-usuario/';

const SERVICE_LIST_PATH = '/listar-planos';
const SERVICE_ADD_PATH = '/adicionar-plano';
const SERVICE_EDIT_PATH = '/editar-plano/';

const SERVICE_GROUP_LIST_PATH = '/listar-agrupamento-de-planos';
const SERVICE_GROUP_ADD_PATH = '/adicionar-agrupamento-de-plano';
const SERVICE_GROUP_EDIT_PATH = '/editar-agrupamento-de-plano/';

const COST_CENTER_LIST_PATH = '/listar-centros-de-custo';
const COST_CENTER_ADD_PATH = '/adicionar-centro-de-custo';
const COST_CENTER_EDIT_PATH = '/editar-centro-de-custo/';

const FINANCIAL_CATEGORY_LIST_PATH = '/listar-categorias-financeiras';
const FINANCIAL_CATEGORY_ADD_PATH = '/adicionar-categoria-financeira';
const FINANCIAL_CATEGORY_EDIT_PATH = '/editar-categoria-financeira/';

const INCOME_LIST_PATH = '/listar-receita';
const INCOME_ADD_PATH = '/adicionar-receita';
const INCOME_EDIT_PATH = '/editar-receita/';

const EXPENSE_LIST_PATH = '/listar-despesa';
const EXPENSE_ADD_PATH = '/adicionar-despesa';
const EXPENSE_EDIT_PATH = '/editar-despesa/';

const FINANCIAL_REPORT_PATH = '/financeiro/relatorio-geral';

const FEEDBACK_LIST_PATH = '/lista-de-pesquisa-de-satisfacao';
const FEEDBACK_EDIT_PATH = '/pesquisa-de-satisfacao/';
const FEEDBACK_REPORT_PATH = '/pesquisa-de-satisfacao/relatorio-geral';

const PHYSICAL_EVALUATION_EXAM_LIST_PATH = '/avaliacao-fisica/listar-datas-disponiveis';
const PHYSICAL_EVALUATION_EXAM_EDIT_PATH = '/avaliacao-fisica/data-disponivel/';
const PHYSICAL_EVALUATION_EXAM_ADD_PATH = '/avaliacao-fisica/adicionar-dia';

const SCHEDULE_PHYSICAL_EVALUATION_PATH = '/agendar-avaliacao-fisica/';
const SCHEDULE_NUTRITIONAL_EVALUATION_PATH = '/agendar-avaliacao-nutricional/';

const PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH = '/avaliacao-fisica/agendados';
const PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH = '/avaliacao-fisica/agendado/';
const PHYSICAL_EVALUATION_SCHEDULED_PHOTO_EDIT_PATH = '/avaliacao-fisica/agendado/{id}/fotos';
const PHYSICAL_EVALUATION_REPORT_PDF_DOWNLOAD_PATH = '/avaliacao-fisica/pdf/';

const EXERCISE_FUNCTION_LIST_PATH = '/periodizacao/listar-funcoes';
const EXERCISE_FUNCTION_ADD_PATH = '/periodizacao/adicionar-funcao';
const EXERCISE_FUNCTION_EDIT_PATH = '/periodizacao/editar-funcao/';

const MUSCLE_GROUP_LIST_PATH = '/periodizacao/listar-agrupamentos-musculares';
const MUSCLE_GROUP_ADD_PATH = '/periodizacao/adicionar-agrupamento-muscular';
const MUSCLE_GROUP_EDIT_PATH = '/periodizacao/editar-agrupamento-muscular/';

const EXERCISE_CATEGORY_LIST_PATH = '/periodizacao/listar-pilares';
const EXERCISE_CATEGORY_ADD_PATH = '/periodizacao/adicionar-pilar';
const EXERCISE_CATEGORY_EDIT_PATH = '/periodizacao/editar-pilar/';

const TRAINING_EXERCISE_LIST_PATH = '/periodizacao/listar-exercicios';
const TRAINING_EXERCISE_ADD_PATH = '/periodizacao/adicionar-exercicio';
const TRAINING_EXERCISE_EDIT_PATH = '/periodizacao/editar-exercicio/';

const TRAINING_PERIOD_LIST_PATH = '/periodizacao/listar-periodizacoes';
const TRAINING_PERIOD_ADD_PATH = '/periodizacao/adicionar-periodizacao';
const TRAINING_PERIOD_EDIT_PATH = '/periodizacao/editar-periodizacao/';

const TRAINING_DAY_LIST_PATH = '/periodizacao/pesquisa-de-treinos'

const TRAINING_SCHEDULE_MANAGE_PATH = '/gerenciar-calendario-de-treino';
const TRAINING_SCHEDULE_ACTIVATE_PERIOD_PATH = '/gerenciar-calendario-de-treino/ativar-periodizacao';
const TRAINING_SCHEDULE_EDIT_ACTIVE_PERIOD_PATH = '/gerenciar-calendario-de-treino/editar-periodizacao-ativa/';

const TRAINING_CALENDAR_VIEW_PATH = '/periodizacao/';

const COACH_LIST_PATH = '/lista-de-treinadores';
const COACH_ADD_PATH = '/adicionar-treinador';
const COACH_EDIT_PATH = '/editar-treinador/';

const USER_DEVICE_LIST_PATH = '/listar-dispositivos';
const USER_DEVICE_EDIT_PATH = '/editar-dispositivo/';

const MANAGE_TRAINING_TIMES_PATH = '/gerenciar-horarios-de-treinamento';

const USER_VALIDATE_EMAIL_PATH = '/validar-conta/';
const PASSWORD_RECOVERY_PATH = '/recuperacao-de-senha/';

const HOME_TRAINING_LIST_PATH = '/lista-de-treinos-para-casa';

const PERSONAL_TRAINING_CLASSBOARD_PATH = '/aluno/{student_id}/personal-training/lousa/';
const PERSONAL_TRAINING_EDIT_PATH = '/editar-treino-de-personal-training/';
const PERSONAL_TRAINING_PERFORMANCE_GRAPH_PATH = '/aluno/{student_id}/personal-training/comparativo-de-treino/';

const EXERCISE_ACTIVITY_TYPE_LIST_PATH = '/periodizacao/listar-capacidades';
const EXERCISE_ACTIVITY_TYPE_ADD_PATH = '/periodizacao/adicionar-capacidade';
const EXERCISE_ACTIVITY_TYPE_EDIT_PATH = '/periodizacao/editar-capacidade/';

const EXERCISE_ACTIVITY_LIST_PATH = '/periodizacao/listar-atividades';
const EXERCISE_ACTIVITY_ADD_PATH = '/periodizacao/adicionar-atividade';
const EXERCISE_ACTIVITY_EDIT_PATH = '/periodizacao/editar-atividade/';

const HR_DEVICE_LIST_PATH = '/listar-frequencimetros';
const HR_DEVICE_ADD_PATH = '/adicionar-frequencimetro';
const HR_DEVICE_EDIT_PATH = '/editar-frequencimetro/';

const RECEPTIONIST_LIST_PATH = '/lista-de-recepcionistas';
const RECEPTIONIST_ADD_PATH = '/adicionar-recepcionista';
const RECEPTIONIST_EDIT_PATH = '/editar-recepcionista/';

const NUTRITIONIST_LIST_PATH = '/lista-de-nutricionistas';
const NUTRITIONIST_ADD_PATH = '/adicionar-nutricionista';
const NUTRITIONIST_EDIT_PATH = '/editar-nutricionista/';

const SALES_REPORT_PATH = '/financeiro/relatorio-de-vendas';
const CLASS_REPORT_PATH = '/relatorios/relatorio-de-aulas';

const FOOD_CLASSIFICATION_LIST_PATH = '/nutricao/lista-de-classificacao-de-ingredientes';
const FOOD_CLASSIFICATION_ADD_PATH = '/nutricao/adicionar-classificacao-de-ingrediente';
const FOOD_CLASSIFICATION_EDIT_PATH = '/nutricao/editar-classificacao-de-ingrediente/';

const FOOD_INGREDIENT_LIST_PATH = '/nutricao/listar-ingredientes';
const FOOD_INGREDIENT_EDIT_PATH = '/nutricao/editar-ingrediente/';
const FOOD_INGREDIENT_ADD_PATH = '/nutricao/adicionar-ingrediente';

const MEAL_CLASSIFICATION_LIST_PATH = '/nutricao/lista-de-classificacao-de-refeicoes';
const MEAL_CLASSIFICATION_ADD_PATH = '/nutricao/adicionar-classificacao-de-refeicao';
const MEAL_CLASSIFICATION_EDIT_PATH = '/nutricao/editar-classificacao-de-refeicao/';

const FOOD_RECIPE_LIST_PATH = '/nutricao/lista-de-receitas';
const FOOD_RECIPE_ADD_PATH = '/nutricao/adicionar-receita';
const FOOD_RECIPE_EDIT_PATH = '/nutricao/editar-receita/';

const MEAL_PERIOD_LIST_PATH = '/nutricao/lista-de-periodos-de-refeicoes';
const MEAL_PERIOD_ADD_PATH = '/nutricao/adicionar-periodo-de-refeicao';
const MEAL_PERIOD_EDIT_PATH = '/nutricao/editar-periodo-de-refeicao/';

const FOOD_PRESCRIPTION_LIST_PATH = '/nutricao/lista-de-dietas';
const FOOD_PRESCRIPTION_ADD_PATH = '/nutricao/adicionar-dieta';
const FOOD_PRESCRIPTION_EDIT_PATH = '/nutricao/editar-dieta/';

const FOOD_PRESCRIPTION_SERVICE_REPORT_PATH = '/nutricao/acompanhamento-de-alunos';

const ROLE_EXPERIENCE_LEVEL_LIST_PATH = '/listar-niveis-de-experiencia';
const ROLE_EXPERIENCE_LEVEL_ADD_PATH = '/adicionar-nivel-de-experiencia';
const ROLE_EXPERIENCE_LEVEL_EDIT_PATH = '/editar-nivel-de-experiencia/';

const CUSTOMER_FEEDBACK_TARGET_LIST_PATH = '/listar-assuntos-de-feedbacks-de-clientes';
const CUSTOMER_FEEDBACK_TARGET_ADD_PATH = '/adicionar-assunto-de-feedback-de-cliente';
const CUSTOMER_FEEDBACK_TARGET_EDIT_PATH = '/editar-assunto-de-feedback-de-cliente/';

const CUSTOMER_FEEDBACK_LIST_PATH = '/listar-feedbacks-de-clientes';

const APPOINTMENT_ROOM_LIST_PATH = '/atendimento/lista-de-salas';
const APPOINTMENT_ROOM_ADD_PATH = '/atendimento/adicionar-sala';
const APPOINTMENT_ROOM_EDIT_PATH = '/atendimento/editar-sala/';

const APPOINTMENT_SCHEDULE_LIST_PATH = '/atendimento/calendario';
const APPOINTMENT_SCHEDULE_ADD_PATH = '/atendimento/criar-período-de-atendimento';
const APPOINTMENT_SCHEDULE_EDIT_PATH = '/atendimento/editar-período-de-atendimento/';

const APPOINTMENT_LIST_PATH = '/atendimento/lista';
const APPOINTMENT_EDIT_PATH = '/atendimento/editar-atendimento/';

const PERSONAL_TRAINING_SERVICE_REPORT_PATH = '/periodizacao/acompanhamento-de-alunos';

const SATISFACTION_REPORT_PATH = '/relatorios/relatorio-de-satisfacao';

const PROMOTIONAL_COUPON_LIST_PATH = '/lista-de-cupons-de-desconto';
const PROMOTIONAL_COUPON_ADD_PATH = '/adicionar-cupom-de-desconto';
const PROMOTIONAL_COUPON_EDIT_PATH = '/editar-cupom-de-desconto/';

const PAYMENT_DEVICE_LIST_PATH = '/listar-maquininhas-de-cartao';
const PAYMENT_DEVICE_ADD_PATH = '/adicionar-maquininha-de-cartao';
const PAYMENT_DEVICE_EDIT_PATH = '/editar-maquininha-de-cartao/';

const OPERATIONAL_TASK_LIST_PAGE = '/listar-pendencias-e-tarefas';
const OPERATIONAL_TASK_ADD_PAGE = '/adicionar-pendencia';
const OPERATIONAL_TASK_EDIT_PAGE = '/editar-pendencia/';

const FINANCIAL_SEARCH_TAG_LIST_PAGE = '/financeiro/listar-palavras-chave';
const FINANCIAL_SEARCH_TAG_ADD_PAGE = '/financeiro/adicionar-palavra-chave';
const FINANCIAL_SEARCH_TAG_EDIT_PAGE = '/financeiro/editar-palavra-chave/';

const PAYMENT_REPORT_PAGE = '/financeiro/relatorio-para-conferencia-de-pagamentos';

const ACCESS_CONTROL_DEVICE_LIST_PATH = '/listar-controladores-de-acesso';
const ACCESS_CONTROL_DEVICE_ADD_PATH = '/adicionar-controlador-de-acesso';
const ACCESS_CONTROL_DEVICE_EDIT_PATH = '/editar-controlador-de-acesso/';

const STAFF_ACCESS_CONTROL_LIST_PATH = '/controle-de-acesso/colaboradores';

const NF_COUPON_REPORT_PATH = '/financeiro/emissao-de-nota-fiscal';

const CRM_CLIENT_LEAD_LIST_PATH = '/crm/requisicoes-de-contatos-de-clientes';

export {
  DESKTOP_PATH,
  EXPERIMENTAL_CLASS_SCHEDULED_PATH,
  EXPERIMENTAL_CLASS_COMPLETED_PATH,
  EXPERIMENTAL_CLASS_AVAILABLE_DAYS_PATH,
  EXPERIMENTAL_CLASS_SITE_PATH,
  EXPERIMENTAL_CLASS_EDIT_PATH,
  STUDENT_LIST_PATH,
  STUDENT_ADD_PATH,
  STUDENT_MANAGE_PATH,
  STUDENT_EDIT_PATH,
  STUDENT_FORM_PATH,
  STUDENT_FOOD_HISTORY_PATH,
  STUDENT_REPORT_PATH,
  USER_LIST_PATH,
  USER_ADD_PATH,
  USER_EDIT_PATH,
  SERVICE_LIST_PATH,
  SERVICE_ADD_PATH,
  SERVICE_EDIT_PATH,
  SERVICE_GROUP_LIST_PATH,
  SERVICE_GROUP_ADD_PATH,
  SERVICE_GROUP_EDIT_PATH,
  COST_CENTER_LIST_PATH,
  COST_CENTER_ADD_PATH,
  COST_CENTER_EDIT_PATH,
  FINANCIAL_CATEGORY_LIST_PATH,
  FINANCIAL_CATEGORY_ADD_PATH,
  FINANCIAL_CATEGORY_EDIT_PATH,
  INCOME_LIST_PATH,
  INCOME_ADD_PATH,
  INCOME_EDIT_PATH,
  EXPENSE_LIST_PATH,
  EXPENSE_ADD_PATH,
  EXPENSE_EDIT_PATH,
  FINANCIAL_REPORT_PATH,
  FEEDBACK_LIST_PATH,
  FEEDBACK_EDIT_PATH,
  FEEDBACK_REPORT_PATH,
  FEEDBACK_PATH_GET_API,
  PHYSICAL_EVALUATION_EXAM_LIST_PATH,
  PHYSICAL_EVALUATION_EXAM_EDIT_PATH,
  PHYSICAL_EVALUATION_EXAM_ADD_PATH,
  SCHEDULE_PHYSICAL_EVALUATION_PATH,
  SCHEDULE_NUTRITIONAL_EVALUATION_PATH,
  PHYSICAL_EVALUATION_SCHEDULED_LIST_PATH,
  PHYSICAL_EVALUATION_SCHEDULED_EDIT_PATH,
  PHYSICAL_EVALUATION_SCHEDULED_PHOTO_EDIT_PATH,
  PHYSICAL_EVALUATION_REPORT_PDF_DOWNLOAD_PATH,
  EXERCISE_FUNCTION_LIST_PATH,
  EXERCISE_FUNCTION_ADD_PATH,
  EXERCISE_FUNCTION_EDIT_PATH,
  EXERCISE_CATEGORY_LIST_PATH,
  EXERCISE_CATEGORY_ADD_PATH,
  EXERCISE_CATEGORY_EDIT_PATH,
  TRAINING_EXERCISE_LIST_PATH,
  TRAINING_EXERCISE_ADD_PATH,
  TRAINING_EXERCISE_EDIT_PATH,
  TRAINING_PERIOD_LIST_PATH,
  TRAINING_PERIOD_ADD_PATH,
  TRAINING_PERIOD_EDIT_PATH,
  TRAINING_DAY_LIST_PATH,
  TRAINING_SCHEDULE_MANAGE_PATH,
  TRAINING_SCHEDULE_ACTIVATE_PERIOD_PATH,
  TRAINING_SCHEDULE_EDIT_ACTIVE_PERIOD_PATH,
  TRAINING_CALENDAR_VIEW_PATH,
  COACH_LIST_PATH,
  COACH_ADD_PATH,
  COACH_EDIT_PATH,
  USER_DEVICE_LIST_PATH,
  USER_DEVICE_EDIT_PATH,
  MANAGE_TRAINING_TIMES_PATH,
  USER_VALIDATE_EMAIL_PATH,
  PASSWORD_RECOVERY_PATH,
  HOME_TRAINING_LIST_PATH,
  PERSONAL_TRAINING_CLASSBOARD_PATH,
  PERSONAL_TRAINING_EDIT_PATH,
  PERSONAL_TRAINING_PERFORMANCE_GRAPH_PATH,
  EXERCISE_ACTIVITY_TYPE_LIST_PATH,
  EXERCISE_ACTIVITY_TYPE_ADD_PATH,
  EXERCISE_ACTIVITY_TYPE_EDIT_PATH,
  EXERCISE_ACTIVITY_LIST_PATH,
  EXERCISE_ACTIVITY_ADD_PATH,
  EXERCISE_ACTIVITY_EDIT_PATH,
  MUSCLE_GROUP_LIST_PATH,
  MUSCLE_GROUP_ADD_PATH,
  MUSCLE_GROUP_EDIT_PATH,
  HR_DEVICE_LIST_PATH,
  HR_DEVICE_ADD_PATH,
  HR_DEVICE_EDIT_PATH,
  RECEPTIONIST_LIST_PATH,
  RECEPTIONIST_ADD_PATH,
  RECEPTIONIST_EDIT_PATH,
  NUTRITIONIST_LIST_PATH,
  NUTRITIONIST_ADD_PATH,
  NUTRITIONIST_EDIT_PATH,
  SALES_REPORT_PATH,
  CLASS_REPORT_PATH,
  FOOD_CLASSIFICATION_LIST_PATH,
  FOOD_CLASSIFICATION_ADD_PATH,
  FOOD_CLASSIFICATION_EDIT_PATH,
  FOOD_INGREDIENT_LIST_PATH,
  FOOD_INGREDIENT_EDIT_PATH,
  FOOD_INGREDIENT_ADD_PATH,
  NUTRIENT_CLASSIFICATIONS_GET_API,
  MEAL_CLASSIFICATION_LIST_PATH,
  MEAL_CLASSIFICATION_ADD_PATH,
  MEAL_CLASSIFICATION_EDIT_PATH,
  FOOD_RECIPE_LIST_PATH,
  FOOD_RECIPE_ADD_PATH,
  FOOD_RECIPE_EDIT_PATH,
  MEAL_PERIOD_LIST_PATH,
  MEAL_PERIOD_ADD_PATH,
  MEAL_PERIOD_EDIT_PATH,
  FOOD_PRESCRIPTION_LIST_PATH,
  FOOD_PRESCRIPTION_ADD_PATH,
  FOOD_PRESCRIPTION_EDIT_PATH,
  FOOD_PRESCRIPTION_SERVICE_REPORT_PATH,
  ROLE_EXPERIENCE_LEVEL_LIST_PATH,
  ROLE_EXPERIENCE_LEVEL_ADD_PATH,
  ROLE_EXPERIENCE_LEVEL_EDIT_PATH,
  CUSTOMER_FEEDBACK_TARGET_LIST_PATH,
  CUSTOMER_FEEDBACK_TARGET_ADD_PATH,
  CUSTOMER_FEEDBACK_TARGET_EDIT_PATH,
  CUSTOMER_FEEDBACK_LIST_PATH,
  APPOINTMENT_ROOM_LIST_PATH,
  APPOINTMENT_ROOM_ADD_PATH,
  APPOINTMENT_ROOM_EDIT_PATH,
  APPOINTMENT_SCHEDULE_LIST_PATH,
  APPOINTMENT_SCHEDULE_ADD_PATH,
  APPOINTMENT_SCHEDULE_EDIT_PATH,
  APPOINTMENT_LIST_PATH,
  APPOINTMENT_EDIT_PATH,
  PERSONAL_TRAINING_SERVICE_REPORT_PATH,
  SATISFACTION_REPORT_PATH,
  PROMOTIONAL_COUPON_LIST_PATH,
  PROMOTIONAL_COUPON_ADD_PATH,
  PROMOTIONAL_COUPON_EDIT_PATH,
  PAYMENT_DEVICE_LIST_PATH,
  PAYMENT_DEVICE_ADD_PATH,
  PAYMENT_DEVICE_EDIT_PATH,
  OPERATIONAL_TASK_LIST_PAGE,
  OPERATIONAL_TASK_ADD_PAGE,
  OPERATIONAL_TASK_EDIT_PAGE,
  FINANCIAL_SEARCH_TAG_LIST_PAGE,
  FINANCIAL_SEARCH_TAG_ADD_PAGE,
  FINANCIAL_SEARCH_TAG_EDIT_PAGE,
  PAYMENT_REPORT_PAGE,
  ACCESS_CONTROL_DEVICE_LIST_PATH,
  ACCESS_CONTROL_DEVICE_ADD_PATH,
  ACCESS_CONTROL_DEVICE_EDIT_PATH,
  STAFF_ACCESS_CONTROL_LIST_PATH,
  NF_COUPON_REPORT_PATH,
  CRM_CLIENT_LEAD_LIST_PATH
};
