const DEFAULT_SEQUENTIAL_COLOR_PALLET = [
  '#367dc4',
  '#da5a1b',
  '#0e8c62',
  '#e32b62',
  '#8746ce',
  '#0e8f9d',
  '#3945e4',
  '#a92689',
  '#547582',
  '#e24c4c',
  '#245a8e',
  '#74abe2',
  '#ef8d5d',
  '#3fb68e',
  '#f06a93',
  '#a97dd8',
  '#3ab5c2',
  '#6973f6',
  '#cd59b1',
  '#8ca2ab',
  '#f38787',
  '#4d78a2',
  '#edf570',
  '#78bb67',
  '#845f31',
  '#84314c',
  '#d01919',
  '#212a92',
  '#3a7713',
];

export default DEFAULT_SEQUENTIAL_COLOR_PALLET;
