import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';
import './financial_search_tag_list.scss';


class FinancialSearchTagList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: true,
      financial_search_tags: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const financial_search_tags = await this.getFinancialSearchTags();

    this.setState({
      loadingData: false,
      financial_search_tags
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.FINANCIAL_SEARCH_TAG_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_SEARCH_TAG_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_FINANCIAL_SEARCH_TAG_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_FINANCIAL_SEARCH_TAG_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.FINANCIAL_SEARCH_TAG_EDIT_PAGE}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_FINANCIAL_SEARCH_TAG_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getTypeText(entry) {
    return (
      <p className={`financial-search-tag-list__type-text__${entry.type.toLowerCase()}`}>{entry.type}</p>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Palavra-chave', <i className="fas fa-tag"></i>),
      Property('type', 'Tipo', <i className="fas fa-dollar-sign"></i>, {getDataText: this.getTypeText}),
      Property('description', 'Descrição', <i className="fas fa-tag"></i>)
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando palavra-chave';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar palavra-chave';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados à palavra-chave serão removidos'}
          confirmText="Deletar palavra-chave"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FINANCIAL_SEARCH_TAG_LIST_PAGE,
              text: "Listar palavras-chave (financeiro)"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar palavras-chave (financeiro)"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="financial-search-tag-list"
            title="Lista de palavras-chave (financeiro)"
          >

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.financial_search_tags}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_SEARCH_TAG_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.FINANCIAL_SEARCH_TAG_ADD_PAGE}
                >

                  <i className="fas fa-plus"></i> Adicionar palavra-chave

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FinancialSearchTagList;
