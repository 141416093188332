import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, postModel, getLocalDateIsoString, setUrlParameters} from '../../utils/functions';
import PromotionalCouponData from './promotional_coupon_data';
import * as permissions from '../../permissions';


class PromotionalCouponAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotional_coupon: {
        owner_id: null,
        code: "",
        discount_percentage: 0,
        limited_use: false,
        valid_since: getLocalDateIsoString(new Date()),
        valid_until: "",
        service_ids: [],
        website_enabled: false,
        is_active: true,
        remaining_use_count: 0
      },
      users: [],
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getCoaches() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_COACH_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.COACHES_GET_API, parameters));
  }

  async getReceptionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.RECEPTIONISTS_GET_API, parameters));
  }

  async getManagers() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_MANAGER_USER_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.USERS_GET_API, parameters));
  }

  async getNutritionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_NUTRITIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.NUTRITIONISTS_GET_API, parameters));
  }

  async getServices() {
    return await getModels(routes.SERVICES_GET_API);
  }

  async componentDidMount() {
    const update = {
      loading: false,
    };

    const userIdSet = new Set();
    update.users = [];

    let coaches = this.getCoaches();
    let receptionists = this.getReceptionists();
    let managers = this.getManagers();
    let nutritionists = this.getNutritionists();
    let services = this.getServices();

    coaches = await coaches;

    if (coaches) {
      for (const entry of coaches) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    receptionists = await receptionists;

    if (receptionists) {
      for (const entry of receptionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    managers = await managers;

    if (managers) {
      for (const entry of managers) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    nutritionists = await nutritionists;

    if (nutritionists) {
      for (const entry of nutritionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    services = await services;

    if (services) {
      update.services = services;
      update.services.sort((a, b) => a.name.localeCompare(b.name));
    }
    
    update.users.sort((a, b) => a.name.localeCompare(b.name));
    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'owner_id' && value.length > 0) {
      value = parseInt(value);
    }
    else if(name === 'discount_percentage' && value.length > 0) {
      value = parseFloat(value) / 100;
    }
    else if(name === 'remaining_use_count' && value.length > 0) {
      value = parseInt(value);
    }
    else if(name === 'code') {
      value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleUpperCase().replace(' ', '_');
    }

    const newData = {...this.state.promotional_coupon, [name]: value};

    this.setState({
      promotional_coupon: newData
    });
  }

  onToggleService(service_id) {
    const service_ids = [...this.state.promotional_coupon.service_ids];

    if(service_ids.includes(service_id)) {
      service_ids.splice(service_ids.indexOf(service_id), 1);
    }
    else {
      service_ids.push(service_id);
    }

    const promotional_coupon = {...this.state.promotional_coupon, service_ids};

    this.setState({promotional_coupon});
  }

  inputsAreValid() {
    return this.state.promotional_coupon.code.length > 0 &&
           this.state.promotional_coupon.valid_since.length > 0 &&
           (this.state.promotional_coupon.discount_percentage >= 0 && this.state.promotional_coupon.discount_percentage <= 1) &&
           this.state.promotional_coupon.service_ids.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.promotional_coupon};

    try {
      await postModel(routes.PROMOTIONAL_COUPON_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'code':
                    warningMessages.push('Código já cadastrado');
                    highlights.push('code');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.PROMOTIONAL_COUPON_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.PROMOTIONAL_COUPON_LIST_PATH,
            text: "Listar cupons"
          },
          {
            path: routes.PROMOTIONAL_COUPON_ADD_PATH,
            text: "Adicionar cupom"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar cupom de desconto"
        loading={this.state.loading}
      >

        <PromotionalCouponData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          promotional_coupon={this.state.promotional_coupon}
          users={this.state.users}
          services={this.state.services}
          onSave={() => this.saveData()}
          onToggleService={(service_id) => this.onToggleService(service_id)}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.PROMOTIONAL_COUPON_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default PromotionalCouponAdd;
