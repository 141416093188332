import React from 'react';
import { Link } from 'react-router-dom';
import './experimental_class_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {getPhoneText} from '../../utils/functions';

class ExperimentalClassData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'physical_condition') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  render() {
    return (
      <DefaultSection
        className="experimental-class-data"
        title="Dados da aula experimental"
      >

        <div className="experimental-class-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="experimental-class-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.experimental_class.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="E-mail do aluno"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.experimental_class.email}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="phone"
              isHighlighted={this.isHighlighted("phone")}
              label="Telefone"
              type="tel"
              placeholder="Telefone do aluno"
              maxLength="16"
              handleInputChange={this.props.handleInputChange}
              value={getPhoneText(this.props.experimental_class.phone)}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <HalfWrapper>

              <DefaultInput
                name="date"
                isHighlighted={this.isHighlighted("date")}
                label="Dia"
                type="date"
                placeholder="Data da aula"
                handleInputChange={this.props.handleInputChange}
                value={this.props.experimental_class.date}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

              <DefaultInput
                name="hour"
                isHighlighted={this.isHighlighted("hour")}
                label="Horário"
                type="time"
                placeholder="Horário da aula"
                handleInputChange={this.props.handleInputChange}
                value={this.props.experimental_class.hour}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

            </HalfWrapper>

          </HalfWrapper>

          <DefaultInput
            name="physical_activity"
            isHighlighted={this.isHighlighted("physical_activity")}
            label="Atividade física"
            type="textarea"
            placeholder="Descreva as atividades físicas que pratica ou praticou recentemente"
            maxLength="512"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.experimental_class.physical_activity || ''}
          />

          <DefaultInput
            name="physical_condition"
            isHighlighted={this.isHighlighted("physical_condition")}
            label="Restrição"
            type="textarea"
            placeholder="Descreva a restrição"
            maxLength="512"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.experimental_class.physical_condition || ''}
          />

        </div>

        <HorizontalRule />

        <div className="experimental-class-data__buttons-container">

          <button
            className="experimental-class-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="experimental-class-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ExperimentalClassData;
