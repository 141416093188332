import React from 'react';
import { PoseGroup } from 'react-pose';
import * as routes from '../../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        SUPPORT_CATEGORY_FP_DIET_ID} from '../../../constants'; //STUDENT_REGISTRATION_COST
import {getModels, postModel, setUrlParameters} from '../../../utils/functions';
import SupportTicketData from './support_ticket_data';
import * as paths from '../constants';
import {DefaultSubSectionTitle} from '../../../utils/default_section';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';


class SupportTicketAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      support_ticket: {
        category_id: SUPPORT_CATEGORY_FP_DIET_ID,
        subject: "",
        description: ""
      },
      support_categories: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let support_categories = getModels(routes.SUPPORT_CATEGORIES_GET_API);

    support_categories = await support_categories;

    const update = {
      loading: false
    }

    if(support_categories) {
      update.support_categories = support_categories;

      update.support_categories.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const support_ticket = {...this.state.support_ticket, [name]: value};

    this.setState({
      support_ticket
    });
  }

  inputsAreValid() {
    return (this.state.support_ticket.category_id && this.state.support_ticket.category_id > 0) &&
           this.state.support_ticket.subject.length > 0 &&
           this.state.support_ticket.description.length > 0;
  }

  async saveData() {
    if(!this.state.support_ticket.category_id || this.state.support_ticket.category_id <= 0) {
      this.setState({
        highlights: ['category_id'],
        showWarningMessage: true,
        warningMessage: "Classificação da conversa não selecionada"
      });

      return;
    }

    if(this.state.support_ticket.subject.length <= 0) {
      this.setState({
        highlights: ['subject'],
        showWarningMessage: true,
        warningMessage: "Assunto não preenchido"
      });

      return;
    }

    if(this.state.support_ticket.description.length <= 0) {
      this.setState({
        highlights: ['description'],
        showWarningMessage: true,
        warningMessage: "Mensagem inicial não preenchida."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      ...this.state.support_ticket
    };
    data.category_id = parseInt(data.category_id);
    data.customer_id = this.props.student.id;

    try {
      const response = await postModel(routes.SUPPORT_TICKET_POST_API, data, true);

      const parameters = {support_ticket_id: response.support_ticket.id};

      this.props.history.replace(setUrlParameters(`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.SUPPORT_PATH}`, parameters));
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultSubSectionTitle
          icon={<i className="fas fa-plus"></i>}
          text="Iniciar nova conversa/suporte"
        />

        <SupportTicketData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          support_ticket={this.state.support_ticket}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_PATH}`}
          support_categories={this.state.support_categories}
          history={this.props.history}
          unit_type_id={this.props.unit_type_id}
        />

      </React.Fragment>
    );
  }
}

export default SupportTicketAdd;
