import React from 'react';
import './feedback_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {getPhoneText} from '../../utils/functions';

class FeedbackData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getFeedbackQuestions() {
    return this.props.feedback.answers.map((question) => (
      <DefaultInput
        key={`feedback_question_input_${question.question_id}`}
        name={`feedback_question_input_${question.question_id}`}
        label={`${question.question_id}) ${question.question_text}`}
        labelClassName="feedback-data__question__label"
        type="text"
        placeholder="Sem resposta"
        value={question.value.join('; ')}
        disabled={true}
      />
    ));
  }

  render() {
    return (
      <DefaultSection
        className="feedback-data"
        title="Dados da entrada de pesquisa de satisfação"
      >

        <div className="feedback-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="feedback-data__input-container">

          <DefaultInput
            name="target_service"
            isHighlighted={this.isHighlighted("target_service")}
            label="Serviço avaliado"
            type="text"
            placeholder="Serviço avaliado"
            value={this.props.feedback.target_service || 'Indefinido'}
            autoComplete="off"
            disabled={true}
          />

          <HorizontalRule />

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              value={this.props.feedback.name}
              autoComplete="off"
              disabled={true}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="E-mail do aluno"
              value={this.props.feedback.email}
              disabled={true}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="gender"
              isHighlighted={this.isHighlighted("gender")}
              label="Gênero"
              type="text"
              placeholder="Gênero do aluno"
              value={this.props.feedback.gender}
              disabled={true}
            />

            <DefaultInput
              name="phone"
              isHighlighted={this.isHighlighted("phone")}
              label="Telefone"
              type="text"
              placeholder="Telefone do aluno"
              value={getPhoneText(this.props.feedback.phone)}
              disabled={true}
            />

          </HalfWrapper>

        </div>

        <HorizontalRule />

        {this.getFeedbackQuestions()}

      </DefaultSection>
    );
  }
}

export default FeedbackData;
