import React from 'react';
import { Link } from 'react-router-dom';
import StackedBarGraph, {StackGroup, StackPoint} from '../../../graphs/stacked_bar_graph';
import './food_recipe_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../../utils/default_section';
import {VerticalAccordionContainer} from '../../../utils/pose_containers';
import LoadingIcon from '../../../components/loading_icon'
import DefaultInput, {SelectOption, HalfWrapper, DummyInput} from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import ModelTable, {Property} from '../../../utils/model_table';
import * as permissions from '../../../permissions';
import {CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
        PROTEIN_TO_KCAL_CONVERSION_CONTANT,
        FAT_TO_KCAL_CONVERSION_CONTANT} from '../../../utils/fyd_food';

class FoodRecipeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIngredientTab: 1,
      foodClassificationFilter: "",
      mealClassificationFilter: "",
      addIngredientVisible: false,
      nutritionInfoVisible: false,
      preparationSectionVisible: false,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.food_recipe.id && this.props.food_recipe.id > 0;
  }

  getMealClassificationOptions() {
    return [
      SelectOption('', 'Indefinido'),
      ...this.props.meal_classifications.map((meal_classification) => SelectOption(meal_classification.id, meal_classification.name))
    ];
  }

  getRecipeDifficultyOptions() {
    return [
      SelectOption('', 'Indefinido'),
      ...this.props.recipe_difficulties.map((recipe_difficulty) => SelectOption(recipe_difficulty.id, recipe_difficulty.name))
    ];
  }

  getWeightUnitOptions() {
    const filteredOptions = this.props.weight_units.filter((entry) => entry.conversion_constant_to_gram !== null);

    return [
      SelectOption('', 'Não especificado'),
      ...filteredOptions.map((weight_unit) => SelectOption(weight_unit.id, weight_unit.fullname))
    ];
  }

  getVolumeUnitOptions() {
    const filteredOptions = this.props.volume_units.filter((entry) => entry.conversion_constant_to_milliliter !== null);

    return [
      SelectOption('', 'Não especificado'),
      ...filteredOptions.map((volume_unit) => SelectOption(volume_unit.id, volume_unit.fullname))
    ];
  }

  getDefaultMeasurementOptions() {
    return [
      SelectOption('', 'Não especificado'),
      ...this.props.food_measurements.map((food_measurement) => SelectOption(food_measurement.id, food_measurement.name))
    ];
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!filterCallback || filterCallback(node)) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
    }

    node.children.sort((a, b) => a.name.localeCompare(b.name));

    for(let child of node.children) {
      if(!filterCallback || filterCallback(child)) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getFoodClassificationOptions() {
    let food_classifications = this.props.food_classifications.filter((entry) => entry.depth === 0);

    food_classifications.sort((a, b) => a.name.localeCompare(b.name));

    food_classifications = food_classifications.flatMap((node) => this.getNodeOptions(node));

    return [
      SelectOption('', 'Todos'),
      ...food_classifications
    ];
  }

  getMealClassificationFilterOptions() {
    return [
      SelectOption('', 'Todas'),
      ...this.props.meal_classifications.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  getProcessingMethodOptions(ingredient) {
    let filteredAssociations = ingredient.data.source_associations;

    if (ingredient.data.default_nutrient_source_id !== null) {
      filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
    }

    if (ingredient.food_ingredient_source_association !== null) {
      return [
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
    else {
      return [
        SelectOption('', 'Selecione um método de processamento'),
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
  }

  getMeasurementOptions(ingredient) {
    let filteredMeasurementAssociations = ingredient.data.food_measurement_associations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);

    if (ingredient.data.default_measurement_source_id !== null) {
      filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
    }

    if (ingredient.food_ingredient_measurement_association === null) {
      return [
        SelectOption('', 'Selecione uma medida'),
        ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
      ];
    }

    return [
      ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
    ];
  }

  mayChangeInputs() {
    if (this.isEditMode() && !this.props.userPermissionIds.includes(permissions.EDIT_FOOD_RECIPE_PERMISSION)) {
      return false
    }

    return this.props.userPermissionIds.includes(permissions.ADD_FOOD_RECIPE_PERMISSION);
  }

  hasOutputWeight() {
    return this.props.selectedWeightUnit !== null;
  }

  hasOutputVolume() {
    return this.props.selectedVolumeUnit !== null;
  }

  hasDefaultMeasurement() {
    return this.props.selectedDefaultMeasurement !== null;
  }

  getBasicIngredients() {
    let filteredBasicIngredients = this.props.food_ingredients;

    if(this.state.foodClassificationFilter) {
      filteredBasicIngredients = filteredBasicIngredients.filter((entry) => entry.food_classification_id === parseInt(this.state.foodClassificationFilter));
    }

    return filteredBasicIngredients;
  }

  getAdvancedIngredients() {
    let filteredAdvancedIngredients = this.props.food_recipes;

    if(this.state.mealClassificationFilter) {
      filteredAdvancedIngredients = filteredAdvancedIngredients.filter((entry) => entry.meal_classification_id === parseInt(this.state.mealClassificationFilter));
    }

    return filteredAdvancedIngredients;
  }

  getBasicIngredientActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="food-recipe-data__add-ingredient-button"
          onClick={() => this.props.addBasicIngredient(entry)}
        >

          <i className="fas fa-plus food-recipe-data__add-ingredient-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Adicionar' : ''}

        </button>

      </div>
    );
  }

  getAdvancedIngredientActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="food-recipe-data__add-ingredient-button"
          onClick={() => this.props.addAdvancedIngredient(entry)}
        >

          <i className="fas fa-plus food-recipe-data__add-ingredient-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Adicionar' : ''}

        </button>

      </div>
    );
  }

  getFoodClassificationText(entry) {
    return entry.food_classification.name;
  }

  getMealClassificationText(entry) {
    return entry.meal_classification !== null ? entry.meal_classification.name : '';
  }

  getBasicIngredientProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Ingrediente', <i className="fas fa-carrot"></i>)
    );
    properties.push(
      Property(
        'food_classification_id',
        'Grupo',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getFoodClassificationText, applyFilter: false})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  getAdvancedIngredientProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Receita', <i className="fas fa-utensils"></i>)
    );
    properties.push(
      Property(
        'meal_classification_id',
        'Classificação',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getMealClassificationText, getFilterText: this.getMealClassificationText})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  getIngredientTabContent() {
    switch (this.state.selectedIngredientTab) {
      case 1:
        return (
          <React.Fragment>

            <DefaultInput
              name="foodClassificationFilter"
              label="Filtrar grupo"
              type="select"
              value={this.state.foodClassificationFilter}
              handleInputChange={(event) => this.setState({foodClassificationFilter: event.target.value})}
              options={this.getFoodClassificationOptions()}
            />

            <HorizontalRule />

            <ModelTable
              key="basic_ingredient_list"
              storageKey="basic_ingredient_list"
              properties={this.getBasicIngredientProperties()}
              getActions={(entry) => this.getBasicIngredientActions(entry)}
              data={this.getBasicIngredients()}
              initialOrderBy="name"
              restrictiveFilter={true}
            >

            </ModelTable>

          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>

            <DefaultInput
              key="advanced_ingredient_list"
              storageKey="advanced_ingredient_list"
              name="mealClassificationFilter"
              label="Filtrar classificação"
              type="select"
              value={this.state.mealClassificationFilter}
              handleInputChange={(event) => this.setState({mealClassificationFilter: event.target.value})}
              options={this.getMealClassificationFilterOptions()}
            />

            <HorizontalRule />

            <ModelTable
              properties={this.getAdvancedIngredientProperties()}
              getActions={(entry) => this.getAdvancedIngredientActions(entry)}
              data={this.getAdvancedIngredients()}
              initialOrderBy="name"
            >

            </ModelTable>

          </React.Fragment>
        );
      default:
        return null;
    }
  }

  getIngredientInputs(ingredient) {
    if (!ingredient.loaded) {
      return (
        <LoadingIcon />
      );
    }

    if (ingredient.isBasic) {
      let filteredAssociations = ingredient.data.source_associations;

      if (ingredient.data.default_nutrient_source_id !== null) {
        filteredAssociations = ingredient.data.source_associations.filter((association) => association.food_info_source_id === ingredient.data.default_nutrient_source_id);
      }

      let filteredMeasurementAssociations = ingredient.data.food_measurement_associations;

      if (ingredient.food_ingredient_source_association !== null) {
        filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);
      }

      if (ingredient.data.default_measurement_source_id !== null) {
        filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
      }

      if (filteredAssociations.length <= 0 || filteredMeasurementAssociations.length <= 0) {
        return (
          <p className="food-recipe-data__alert-text">

            <i className="fas fa-exclamation food-recipe-data__alert-text__icon"></i>
            Cadastro de ingrediente incompleto

          </p>
        );
      }

      return (
        <HalfWrapper className="food-recipe-data__ingredient__inputs-container">

          {filteredAssociations.length > 1 ? (
            <DefaultInput
              name={`ingredient:${ingredient.key}:food_ingredient_source_association_id`}
              isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:food_ingredient_source_association_id`)}
              label="Processamento:"
              type="select"
              handleInputChange={(event) => this.props.onSelectIngredientSourceAssociation(ingredient, parseInt(event.target.value))}
              value={ingredient.food_ingredient_source_association ? ingredient.food_ingredient_source_association.id : ''}
              options={this.getProcessingMethodOptions(ingredient)}
              disabled={!this.mayChangeInputs() || ingredient.has_prescription_recipe_adjustments}
            />
          ) : this.state.screenWidth >= 680 ? (<DummyInput />) : null}

          {ingredient.food_ingredient_source_association !== null &&
            <React.Fragment>

              <DefaultInput
                name={`ingredient:${ingredient.key}:quantity`}
                isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:quantity`)}
                label="Quantidade"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.props.onUpdateIngredientQuantity(ingredient, parseFloat(event.target.value) || null)}
                value={ingredient.quantity || ''}
                autoComplete="off"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name={`ingredient:${ingredient.key}:food_ingredient_measurement_association_id`}
                isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:food_ingredient_measurement_association_id`)}
                label="Medida padrão:"
                type="select"
                handleInputChange={(event) => this.props.onSelectIngredientMeasurementAssociation(ingredient, parseInt(event.target.value))}
                value={ingredient.food_ingredient_measurement_association !== null ? ingredient.food_ingredient_measurement_association.id : ''}
                options={this.getMeasurementOptions(ingredient)}
                disabled={!this.mayChangeInputs()}
              />

            </React.Fragment>
          }

        </HalfWrapper>
      );
    }
    else {
      let recipeQuantityText = '';

      if (ingredient.data.default_measurement !== null && ingredient.data.default_measurement_quantity !== null) {
        recipeQuantityText = ` (${ingredient.data.default_measurement_quantity} ${ingredient.data.default_measurement.name}(s))`;
      }
      else if (ingredient.data.output_volume_unit !== null && ingredient.data.output_volume !== null) {
        recipeQuantityText = ` (${ingredient.data.output_volume}${ingredient.data.output_volume_unit.shortname})`;
      }
      else if (ingredient.data.output_weight_unit !== null && ingredient.data.output_weight !== null) {
        recipeQuantityText = ` (${ingredient.data.output_weight}${ingredient.data.output_weight_unit.shortname})`;
      }

      return (
        <HalfWrapper className="food-recipe-data__ingredient__inputs-container">

          {this.state.screenWidth >= 680 ? (<DummyInput />) : null}

          <DefaultInput
            name={`ingredient:${ingredient.key}:quantity`}
            isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:quantity`)}
            label="Quantidade"
            type="number"
            placeholder="-"
            step="0.01"
            min="0.00"
            handleInputChange={(event) => this.props.onUpdateIngredientQuantity(ingredient, parseFloat(event.target.value) || null)}
            value={ingredient.quantity || ''}
            autoComplete="off"
            suffix={`receita(s)${recipeQuantityText}`}
            disabled={!this.mayChangeInputs()}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          {/* <DefaultInput
            name={`ingredient:${ingredient.key}:quantity`}
            isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:quantity`)}
            label="Medida padrão:"
            type="select"
            handleInputChange={(event) => this.props.onSelectIngredientMeasurementAssociation(ingredient, parseInt(event.target.value))}
            value={ingredient.food_ingredient_measurement_association.id}
            options={this.getMeasurementOptions(ingredient)}
            disabled={true}
          /> */}

        </HalfWrapper>
      );
    }
  }

  getPreparationStepInputs(step) {
    return (
      <React.Fragment>

        <HalfWrapper>

          <DefaultInput
            name={`step:${step.order}:name`}
            isHighlighted={this.isHighlighted(`step:${step.order}:name`)}
            label="Nome"
            type="text"
            placeholder="Nome do passo"
            maxLength="128"
            handleInputChange={(event) => this.props.onUpdateStep(step, 'name', event.target.value)}
            value={step.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

          <DefaultInput
            name={`step:${step.order}:step_duration`}
            isHighlighted={this.isHighlighted(`step:${step.order}:step_duration`)}
            label="Duração"
            type="number"
            placeholder="-"
            step="0.5"
            min="0.0"
            handleInputChange={(event) => this.props.onUpdateStep(step, 'step_duration', parseFloat(event.target.value) || null)}
            value={step.step_duration || ''}
            autoComplete="off"
            suffix="minuto(s)"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={!this.mayChangeInputs()}
          />

        </HalfWrapper>

        <DefaultInput
          name={`step:${step.order}:step_instruction`}
          isHighlighted={this.isHighlighted(`step:${step.order}:step_instruction`)}
          label="Descrição"
          type="textarea"
          placeholder="Descrição da receita"
          rows="3"
          handleInputChange={(event) => this.props.onUpdateStep(step, 'step_instruction', event.target.value)}
          value={step.step_instruction || ''}
          disabled={!this.mayChangeInputs()}
        />

      </React.Fragment>
    );
  }

  getIngredients() {
    return this.props.ingredientList.map((ingredient) => {
      return (
        <div
          className="food-recipe-data__ingredient"
          key={ingredient.key}
        >

          <p
            className="food-recipe-data__ingredient__detail"
          >

            {ingredient.isBasic ? (
              <i className="fas fa-carrot"></i>
            ) : (
              <i className="fas fa-utensils"></i>
            )}

          </p>

          <div className="food-recipe-data__ingredient__content-wrapper">

            <DefaultInput
              className="food-recipe-data__ingredient__content"
              labelClassName="food-recipe-data__ingredient__name-label"
              name={`ingredient:${ingredient.key}:name`}
              isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:name`)}
              type="text"
              placeholder="Nome vísivel ao aluno"
              maxLength="128"
              handleInputChange={(event) => this.props.onUpdateIngredientName(ingredient, event.target.value)}
              labelMessage={(ingredient.name !== null && ingredient.name !== ingredient.data.name) ? ingredient.data.name : null}
              value={ingredient.name !== null ? ingredient.name : ingredient.data.name}
              autoComplete="off"
              disabled={!this.mayChangeInputs()}
            />

            {this.getIngredientInputs(ingredient)}

          </div>

          {this.mayChangeInputs() &&
            <React.Fragment>

              {this.props.ingredientList.length > 1 &&
                <div className="food-recipe-data__switch-order-button-container">

                  <button
                    className={`food-recipe-data__switch-order-button${(ingredient.order > 1) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchItemOrder(ingredient, -1)}
                  >

                    <i className="fas fa-chevron-up"></i>

                  </button>

                  <button
                    className={`food-recipe-data__switch-order-button${(ingredient.order < this.props.ingredientList.length) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchItemOrder(ingredient, 1)}
                  >

                    <i className="fas fa-chevron-down"></i>

                  </button>

                </div>
              }

              <button
                className="food-recipe-data__delete-ingredient-button"
                onClick={() => this.props.onRemoveIngredient(ingredient)}
              >

                <i className="far fa-trash-alt"></i>

              </button>

            </React.Fragment>
          }

        </div>
      );
    });
  }

  getPreparationSteps() {
    return this.props.food_recipe.preparation_steps.map((step) => {
      return (
        <div
          className="food-recipe-data__preparation-step"
          key={`preparation_step:${step.order}`}
        >

          <p
            className="food-recipe-data__preparation-step__detail"
          >

            {step.order}

          </p>

          <div className="food-recipe-data__preparation-step__content-wrapper">

            {this.getPreparationStepInputs(step)}

          </div>

          {this.mayChangeInputs() &&
            <React.Fragment>

              {this.props.food_recipe.preparation_steps.length > 1 &&
                <div className="food-recipe-data__switch-order-button-container">

                  <button
                    className={`food-recipe-data__switch-order-button${(step.order > 1) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchStepOrder(step, -1)}
                  >

                    <i className="fas fa-chevron-up"></i>

                  </button>

                  <button
                    className={`food-recipe-data__switch-order-button${(step.order < this.props.food_recipe.preparation_steps.length) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchStepOrder(step, 1)}
                  >

                    <i className="fas fa-chevron-down"></i>

                  </button>

                </div>
              }

              <button
                className="food-recipe-data__delete-preparation-step-button"
                onClick={() => this.props.onRemovePreparationStep(step)}
              >

                <i className="far fa-trash-alt"></i>

              </button>

            </React.Fragment>
          }

        </div>
      );
    });
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getNutritionalData() {
    if (!this.state.nutritionInfoVisible) {
      return null;
    }

    if (this.props.ingredientList.some((entry) => !entry.loaded)) {
      return (<LoadingIcon />);
    }

    const recipeNutritionalData = this.props.getRecipeNutritionalData();

    const content = [];

    const carbohydrateWeight = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.carbohydrateWeight;
    const proteinWeight = PROTEIN_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.proteinWeight;
    const fatWeight = FAT_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.fatWeight;

    const totalMean = carbohydrateWeight + proteinWeight + fatWeight;

    const overviewData = [
      StackGroup('Distribuição', [
        StackPoint(Math.round(100 * (carbohydrateWeight / totalMean)) / 100, 'Carboidrato', Math.round(100 * (carbohydrateWeight / totalMean) * 10) / 10, null, '#5899da'),
        StackPoint(Math.round(100 * (proteinWeight / totalMean)) / 100, 'Proteína', Math.round(100 * (proteinWeight / totalMean) * 10) / 10, null, '#da5858'),
        StackPoint(Math.round(100 * (fatWeight / totalMean)) / 100, 'Gordura', Math.round(100 * (fatWeight / totalMean) * 10) / 10, null, '#daac58')
      ], 'none', null, true, false),
    ];

    content.push(
      <React.Fragment key='macronutrient_distribution'>

        <StackedBarGraph
          data={overviewData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}%`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
        />

        <HorizontalRule />

      </React.Fragment>
    );

    const energyValue = Math.round(recipeNutritionalData.totalEnergy * 100) / 100;

    content.push(
      <DefaultInput
        key={`nutrition_info:energy`}
        labelClassName="food-recipe-data__nutrition-info__nutrient-label"
        name="energy"
        label="Energia:"
        type="number"
        placeholder="-"
        step="0.01"
        min="0.00"
        value={energyValue}
        autoComplete="off"
        suffix={recipeNutritionalData.energyUnit.shortname}
        isHorizontal={this.state.screenWidth > 360}
        disabled={true}
      />
    );

    for(const entry of recipeNutritionalData.nutrientClassifications) {
      content.push(
        <DefaultSubSectionTitle
          key={`nutrition_info:nutriet_classification:${entry.classification.id}:title`}
          className="food-recipe-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-circle"></i>}
          text={entry.classification.name}
        />
      );

      const associations = [...entry.nutrientMap.values()].sort((a, b) => a.nutrient.fullname.localeCompare(b.nutrient.fullname));

      for (const association of associations) {
        const nutrientKey = `nutrition_info:nutrient:${association.nutrient.id}`;

        const nutrientValue = Math.round(association.entries.reduce((sum, value) => sum + value, 0) * 100) / 100;

        content.push(
          <DefaultInput
            key={nutrientKey}
            labelClassName="food-recipe-data__nutrition-info__nutrient-label"
            name={nutrientKey}
            label={`${association.nutrient.fullname}:`}
            type="number"
            placeholder="-"
            step="0.01"
            min="0.00"
            value={nutrientValue}
            autoComplete="off"
            suffix={recipeNutritionalData.nutrientUnitMap.get(association.nutrient.id).shortname}
            isHorizontal={this.state.screenWidth > 360}
            disabled={true}
          />
        );
      }
    }

    return content;
  }

  getActionButtons() {
    if(!this.hasOutputWeight() || !this.props.enableSave || this.props.selectedWeightUnit.conversion_constant_to_gram === null) {
      return  null;
    }

    return (
      <div className="default-section__links-wrapper">

        <button
          className="default-section__default-link-button"
          onClick={() => this.props.onCalculateFinalWeight()}
        >

            <i className="fa-solid fa-weight-scale default-section__default-link-button__icon"></i> Calcular peso final

        </button>

      </div>
    );
  }

  render() {
    return (
      <DefaultSection
        className="food-recipe-data"
        title="Dados da receita"
      >

        <div className="food-recipe-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        {this.getActionButtons()}

        <div className="food-recipe-data__input-container">

          {this.props.student &&
            <DefaultInput
              className="food-recipe-data__student-name"
              name="student"
              type="text"
              value={this.props.student.name}
              autoComplete="off"
              prefix="Aluno"
              isHorizontal={true}
              disabled={true}
            />
          }

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome da receita"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

          <HalfWrapper>

            <DefaultInput
              name="meal_classification_id"
              isHighlighted={this.isHighlighted("meal_classification_id")}
              label="Classificação"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_recipe.meal_classification_id || ''}
              options={this.getMealClassificationOptions()}
              disabled={!this.mayChangeInputs()}
            />

            <DefaultInput
              name="recipe_difficulty_id"
              isHighlighted={this.isHighlighted("recipe_difficulty_id")}
              label="Dificuldade"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_recipe.recipe_difficulty_id || ''}
              options={this.getRecipeDifficultyOptions()}
              disabled={!this.mayChangeInputs()}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="output_weight_unit_id"
              isHighlighted={this.isHighlighted("output_weight_unit_id")}
              label="Unidade de peso de referência"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_recipe.output_weight_unit_id || ''}
              options={this.getWeightUnitOptions()}
              disabled={!this.mayChangeInputs()}
            />

            <DefaultInput
              name="output_volume_unit_id"
              isHighlighted={this.isHighlighted("output_volume_unit_id")}
              label="Unidade de volume de referência"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_recipe.output_volume_unit_id || ''}
              options={this.getVolumeUnitOptions()}
              disabled={!this.mayChangeInputs()}
            />

          </HalfWrapper>

          {(this.hasOutputWeight() || this.hasOutputVolume()) &&
            <HalfWrapper>

              {this.hasOutputWeight() &&
                <DefaultInput
                  name="output_weight"
                  isHighlighted={this.isHighlighted("output_weight")}
                  label="Peso final"
                  type="number"
                  placeholder="-"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.food_recipe.output_weight || ''}
                  autoComplete="off"
                  suffix={this.props.selectedWeightUnit.shortname}
                  disabled={!this.mayChangeInputs()}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />
              }

              {this.hasOutputVolume() &&
                <DefaultInput
                  name="output_volume"
                  isHighlighted={this.isHighlighted("output_volume")}
                  label="Volume final"
                  type="number"
                  placeholder="-"
                  step="0.01"
                  min="0.00"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.food_recipe.output_volume || ''}
                  autoComplete="off"
                  suffix={this.props.selectedVolumeUnit.shortname}
                  disabled={!this.mayChangeInputs()}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  onFocus={(event) => event.target.select()}
                />
              }

            </HalfWrapper>
          }

          <HalfWrapper>

            <DefaultInput
              name="default_measurement_id"
              isHighlighted={this.isHighlighted("default_measurement_id")}
              label="Medida de referência"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_recipe.default_measurement_id || ''}
              options={this.getDefaultMeasurementOptions()}
              disabled={!this.mayChangeInputs()}
            />

            {this.hasDefaultMeasurement() &&
              <DefaultInput
                name="default_measurement_quantity"
                isHighlighted={this.isHighlighted("default_measurement_quantity")}
                label="Quantidade de referência"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_recipe.default_measurement_quantity || ''}
                autoComplete="off"
                suffix={`${this.props.selectedDefaultMeasurement.name}(s)`}
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />
            }

          </HalfWrapper>

          <DefaultInput
            name="people_served"
            labelMessage="Informações nutricionais da receita são sempre calculadas para porção equivalente para 1 pessoa"
            isHighlighted={this.isHighlighted("people_served")}
            label="Rendimento"
            type="number"
            placeholder="-"
            step="1"
            min="1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.people_served || ''}
            autoComplete="off"
            suffix="pessoa(s)"
            disabled={!this.mayChangeInputs()}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.is_active}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da receita"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

          <DefaultInput
            name="consumption_description"
            isHighlighted={this.isHighlighted("consumption_description")}
            label="Descrição sobre consumo"
            type="textarea"
            placeholder="Descrição sobre consumo"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.consumption_description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

          <DefaultInput
            name="preparation_description"
            isHighlighted={this.isHighlighted("preparation_description")}
            label="Descrição sobre preparação"
            type="textarea"
            placeholder="Descrição sobre preparação"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_recipe.preparation_description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

        </div>

        <HorizontalRule />

        <section className="food-recipe-data__preparation">

          <header
            className="food-recipe-data__preparation__header"
            onClick={() => this.setState({preparationSectionVisible: !this.state.preparationSectionVisible})}
          >

            <h3 className="food-recipe-data__preparation__header__text">
              <i className="fas fa-list-alt food-recipe-data__preparation__header__text-icon"></i>
              Passos para preparação
            </h3>

            {this.state.preparationSectionVisible ?
              <i className="fas fa-chevron-down food-recipe-data__preparation__header__visible-icon"></i>:
              <i className="fas fa-chevron-up food-recipe-data__preparation__header__visible-icon"></i>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container food-recipe-data__preparation__content"
            pose={this.state.preparationSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

            <div className="vertical-accordion-container food-recipe-data__preparation__content-wrapper">

              <div className="food-recipe-data__preparation-container">

                {this.getPreparationSteps()}

                {this.mayChangeInputs() &&
                  <button
                    className="food-recipe-data__add-preparation-step-button"
                    onClick={() => this.props.onAddPreparationStep()}
                  >

                    <i className="fas fa-plus food-recipe-data__add-preparation-step-button__icon"></i>
                    Adicionar passo

                  </button>
                }

              </div>

            </div>

          </VerticalAccordionContainer>

        </section>

        <HorizontalRule />

        <section className="food-recipe-data__add-ingredient">

          <header
            className="food-recipe-data__add-ingredient__header"
            onClick={() => this.setState({addIngredientVisible: !this.state.addIngredientVisible})}
          >

            <h3 className="food-recipe-data__add-ingredient__header__text">
              <i className="fas fa-plus food-recipe-data__add-ingredient__header__text-icon"></i>
              Adicionar ingrediente
            </h3>

            {this.state.addIngredientVisible ?
              <i className="fas fa-chevron-down food-recipe-data__add-ingredient__header__visible-icon"></i>:
              <i className="fas fa-chevron-up food-recipe-data__add-ingredient__header__visible-icon"></i>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container food-recipe-data__add-ingredient__content"
            pose={this.state.addIngredientVisible ? 'verticalOpen' : 'verticalClosed'}>

            <div className="vertical-accordion-container food-recipe-data__add-ingredient__content-wrapper">

              <section className="food-recipe-data__tab-container">

                <ul className="food-recipe-data__tab-container__tab-selector">

                  <li className="food-recipe-data__tab-container__tab-item">
                    <button
                      className="food-recipe-data__tab-container__tab-item__button"
                      onClick={() => this.setState({selectedIngredientTab: 1})}
                      disabled={this.state.selectedIngredientTab === 1}
                    >
                      Ingredientes básicos
                    </button>
                  </li>

                  <li className="food-recipe-data__tab-container__tab-item">
                    <button
                      className="food-recipe-data__tab-container__tab-item__button"
                      onClick={() => this.setState({selectedIngredientTab: 2})}
                      disabled={this.state.selectedIngredientTab === 2}
                    >
                      Receitas
                    </button>
                  </li>

                </ul>

                <article className="food-recipe-data__tab-container__tab-content">

                  {this.getIngredientTabContent()}

                </article>

              </section>

            </div>

          </VerticalAccordionContainer>

        </section>

        <HorizontalRule />

        {this.props.ingredientList.length > 0 &&
          <React.Fragment>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-carrot"></i>}
              text="Ingredientes"
            />

            <div className="food-recipe-data__ingredients-container">

              {this.getIngredients()}

            </div>

            <HorizontalRule />

            {this.props.ingredientsAreValid &&
              <React.Fragment>

                <section className="food-recipe-data__nutrition-info">

                  <header
                    className="food-recipe-data__nutrition-info__header"
                    onClick={() => this.setState({nutritionInfoVisible: !this.state.nutritionInfoVisible})}
                  >

                    <h3 className="food-recipe-data__nutrition-info__header__text">
                      <i className="fas fa-info food-recipe-data__nutrition-info__header__text-icon"></i>
                      Informação nutricional
                    </h3>

                    {this.state.nutritionInfoVisible ?
                      <i className="fas fa-chevron-down food-recipe-data__nutrition-info__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up food-recipe-data__nutrition-info__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container food-recipe-data__nutrition-info__content"
                    pose={this.state.nutritionInfoVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container food-recipe-data__nutrition-info__content-wrapper">

                      {this.getNutritionalData()}

                    </div>

                  </VerticalAccordionContainer>

                </section>

                <HorizontalRule />

              </React.Fragment>
            }

          </React.Fragment>
        }

        <div className="food-recipe-data__buttons-container">

          {this.mayChangeInputs() &&
            <button
              className="food-recipe-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <Link
            className="food-recipe-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {!this.mayChangeInputs() ? 'Voltar': 'Cancelar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default FoodRecipeData;
