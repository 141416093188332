import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, postModel} from '../../utils/functions';
import CostCenterData from './cost_center_data';

class CostCenterAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cost_center: {
        name: "",
        description: "",
        parent_id: "",
        color: '#000000'
      },
      costCenters: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const cost_centers = await getModels(routes.COST_CENTERS_GET_API);

    if(cost_centers) {
      this.setState({
        costCenters: cost_centers,
        loading: false
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.cost_center, [name]: value};

    this.setState({
      cost_center: newData
    });
  }

  inputsAreValid() {
    return this.state.cost_center.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.cost_center}
    if(!data.parent_id) {
      data.parent_id = null;
    }
    else {
      data.parent_id = parseInt(data.parent_id);
    }

    try {
      await postModel(routes.COST_CENTER_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.COST_CENTER_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.COST_CENTER_LIST_PATH,
            text: "Listar centros"
          },
          {
            path: routes.COST_CENTER_ADD_PATH,
            text: "Adicionar centro"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar centro de custo"
        loading={this.state.loading}
      >

        <CostCenterData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          cost_center={this.state.cost_center}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.COST_CENTER_LIST_PATH}
          costCenters={this.state.costCenters}
        />

      </ContentFrame>
    );
  }
}

export default CostCenterAdd;
