import React from 'react';
import { PoseGroup } from 'react-pose';
import './experimental_class_schedule.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import {getModels, postModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import {HorizontalContainer, FadeContainer} from '../../utils/pose_containers';

function getDayId(date) {
  let dayId = date.getDay() - 1;

  if(dayId < 0) {
    dayId = 6;
  }

  return dayId;
}

class ExperimentalClassSchedule extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();

    this.state = {
      loadingData: true,
      activeService: '',
      service_map: {},
      training_times: [],
      services: [],
      screenWidth: window.innerWidth,
      selectedDay: getDayId(today),
      monthName: new Intl.DateTimeFormat('pt-BR', {month: 'long'}).format(today),
      showDataSaved: false,
      lastServiceSaved: '',
    };
  }

  async componentDidMount() {
    let available_calendar = await getModels(routes.EXPERIMENTAL_CLASS_SCHEDULE_GET_API);
    const services = [];
    let activeService = '';

    if(available_calendar) {
      for(let service of available_calendar.services) {
        services.push(service);

        if(!activeService) {
          activeService = service;
        }
      }

      services.sort((a, b) => a.localeCompare(b));

      this.setState({
        service_map: available_calendar.service_map,
        training_times: available_calendar.training_times,
        activeService,
        services,
        loadingData: false,
      });
    }
    else {
      this.props.history.replace(routes.DESKTOP_PATH);
      return;
    }

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async onSave() {
    if(!this.state.activeService) {
      return;
    }

    this.setState({
      loadingData: true
    });

    const data = {
      day_ids: this.state.service_map[this.state.activeService],
      service: this.state.activeService,
    };

    try {
      await postModel(routes.EXPERIMENTAL_CLASS_SCHEDULE_POST_API, data);
    }
    catch(errors) {
      window.alert(DEFAULT_UNKNOWN_ERROR_MESSAGE);

      this.setState({
        loadingData: false,
      });

      return;
    }

    this.setState({
      loadingData: false,
      showDataSaved: true,
      lastServiceSaved: this.state.activeService,
    });

    setTimeout(() => this.setState({showDataSaved: false}), 1500);
  }

  getDays() {
    if(!this.state.activeService) {
      return;
    }

    const dayList = [];

    const currentDate = new Date();
    const dateFormat = new Intl.DateTimeFormat('pt-BR', {weekday: this.state.screenWidth > 10 ? 'long' : 'short', day: 'numeric'});

    for(let i=0; i<=6; ++i) {
      const day = currentDate.getDate();
      const day_id = getDayId(currentDate);

      dayList.push({
        day_id,
        dayName: dateFormat.format(currentDate)
      });

      currentDate.setDate(day + 1);
    }

    return dayList.map((entry) => (
      <React.Fragment key={`experimental_class_schedule_day_${entry.day_id}`}>

        <input
          type="radio"
          id={`experimental_class_schedule_${entry.day_id}`}
          name="day"
          value={entry.day_id}
          onChange={() => this.setState({selectedDay: entry.day_id})}
          className="experimental-class-schedule__days__input"
          checked={entry.day_id === this.state.selectedDay}
        />

        <label
          htmlFor={`experimental_class_schedule_${entry.day_id}`}
          className="experimental-class-schedule__days__label"
          dayid={entry.day_id}
        >

          <span>{entry.dayName}</span>

        </label>

      </React.Fragment>
    ));
  }

  getHours() {
    if(!this.state.activeService) {
      return;
    }

    const selectedDayIds = this.state.service_map[this.state.activeService];

    const hours = this.state.training_times.filter((entry) => entry.day_id === this.state.selectedDay && entry.target_service === this.state.activeService);

    if(hours.length <= 0) {
      return null;
    }

    const now = new Date();
    const timeFormat = new Intl.DateTimeFormat('pt-BR', {hour: '2-digit', minute: '2-digit'});

    return hours.map((entry) => {
      const past = this.state.selectedDay === getDayId(now) && entry.time < timeFormat.format(now);

      return (
        <HorizontalContainer
          key={`experimental_class_schedule_hour_${entry.id}`}
          className={`experimental-class-schedule__hour${past ? '--past': ''}`}
        >

          <p className="experimental-class-schedule__hour__text">

            {entry.time}

          </p>

          <button
            className="experimental-class-schedule__hour__select-time-button"
            onClick={() => this.onClickTime(entry.id)}
          >

            {selectedDayIds.includes(entry.id) &&
              <i className="fas fa-check"></i>
            }

          </button>

        </HorizontalContainer>
      );
    });
  }

  onClickTime(training_time_id) {
    if(!this.state.activeService) {
      return;
    }

    const activeService = this.state.activeService;

    const service_map = {...this.state.service_map};

    service_map[activeService] = [...service_map[activeService]];

    if(service_map[activeService].includes(training_time_id)) {
      service_map[activeService].splice(service_map[activeService].indexOf(training_time_id), 1);
    }
    else {
      service_map[activeService].push(training_time_id);
    }

    this.setState({
      service_map
    });
  }

  getServiceSelectors() {
    return this.state.services.map((service) => {
      const serviceSelected = service === this.state.activeService;

      return (
        <button
          key={`experimental_class_service_selector_${service.toLowerCase().replace(/ /g, '_')}`}
          className={`experimental-class-schedule__service-button`}
          disabled={serviceSelected}
          onClick={() => this.setState({
            activeService: service
          })}
        >

          {service}

        </button>
      );
    });
  }

  render() {
    return (
      <React.Fragment>

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.EXPERIMENTAL_CLASS_AVAILABLE_DAYS_PATH,
              text: "Dias e horários"
            },
          ]}
          titleIcon={<i className="far fa-clock"></i>}
          title="Dias e horários"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="experimental-class-schedule"
            title="Gerenciar dias e horários das aulas experimentais"
          >

            <div className="experimental-class-schedule__links-wrapper">

              <a
                className="experimental-class-schedule__default-button"
                href={`${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.EXPERIMENTAL_CLASS_SITE_PATH}`}
                target="_blank"
                rel="noopener noreferrer"
              >

                <i className="fas fa-link"></i> Cadastrar aula experimental

              </a>

            </div>

            <HorizontalRule />

            <DefaultSubSectionTitle
              className="experimental-class-schedule__service-selector-header"
              icon={<i className="fas fa-list"></i>}
              text="Selecione o serviço que deseja configurar"
            />

            <div className="experimental-class-schedule__service-selector">

              {this.getServiceSelectors()}

            </div>

            <HorizontalRule />

            <div className="experimental-class-schedule__content-wrapper">

              <div className="experimental-class-schedule__days">

                <h3 className="experimental-class-schedule__days-title">{this.state.monthName}</h3>

                <div className="experimental-class-schedule__days__wrapper">

                  {this.getDays()}

                </div>


              </div>

              <div className="experimental-class-schedule__hour-manager">

                <h3 className="experimental-class-schedule__hour-manager-title">Horários</h3>

                <div className="experimental-class-schedule__hours">

                  <PoseGroup flipMove={false}>

                    {this.getHours()}

                  </PoseGroup>

                </div>

              </div>

            </div>

            <HorizontalRule />

            <div className="experimental-class-schedule__buttons-container">

              <button
                className="experimental-class-schedule__save-button"
                onClick={() => {this.onSave()}}
              >

                Salvar

              </button>

              <PoseGroup>

                {this.state.showDataSaved &&
                  <FadeContainer key="experimental-class-schedule__data-saved-text">

                    <p className="experimental-class-schedule__data-saved-text">{`${this.state.lastServiceSaved} - Dados de salvos!`}</p>

                  </FadeContainer>
                }

              </PoseGroup>

            </div>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ExperimentalClassSchedule;
