import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput from '../../../utils/default_input';
import WarningMessage from '../../warning_message';
import './customer_feedback_target_data.scss';


class CustomerFeedbackTargetData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  render() {
    return (
      <DefaultSection
        className="customer-feedback-target-data"
        title="Dados do assunto"
      >

        <div className="customer-feedback-target-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="customer-feedback-target-data__input-container">

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome do assunto"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.customer_feedback_target.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do assunto"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.customer_feedback_target.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <HorizontalRule />

        <div className="customer-feedback-target-data__buttons-container">

          <button
            className="customer-feedback-target-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="customer-feedback-target-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default CustomerFeedbackTargetData;
