import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';


const DAY_FORMATER = new Intl.DateTimeFormat('pt-BR', {weekday: 'long', day: 'numeric', month: 'long'});

class DateBarPoint {
  constructor(value, date, index=null, color=null) {
    this.value = value;
    this.date = date;
    this.index = index;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, x: this.date};

    if(this.index) {
      point.indexContent = this.index;
    }

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

class BarPoint {
  constructor(value, label, index=null, color=null) {
    this.value = value;
    this.label = label;
    this.index = index;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, label: this.label};

    if(this.index) {
      point.indexContent = this.index;
    }

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

class ErrorPoint {
  constructor(value, label, color=null) {
    this.value = value;
    this.label = label;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, label: this.label};

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

export {DateBarPoint, BarPoint, ErrorPoint};


class SimpleBarGraph extends React.Component {
  getData() {
    const data = [
      {
        type: "column",
        color: this.props.barColor,
        name: this.props.name,
        showInLegend: false,
        indexLabel: "{indexContent}",
        indexLabelFontWeight: "bold",
        indexLabelFontColor: "#333",
        dataPoints: this.props.data.map((point) => point.graphPoint)
      }
    ];

    if(this.props.errorData) {
      data.push({
        type: "error",
  			name: "Desvio padrão",
        color: "#C0504E",
        stemThickness: 1,
        whiskerThickness: 1,
        stemDashType: "dash",
        dataPoints: this.props.errorData.map((point) => point.graphPoint)
      });
    }

    return data;
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			// colorSet: "colorSet2",
      culture: 'pt-BR',
			toolTip: {
				shared: true,
        contentFormatter: (e) => {
  				let content = "";

  				for (let i = 0; i < e.entries.length; i++) {
            if(i === 0) {
              if(this.props.dateBased) {
                content += `${DAY_FORMATER.format(e.entries[i].dataPoint.x)}<br/>`;
              }
              else {
                content += `${e.entries[i].dataPoint.label}<br/>`;
              }
            }

            if(e.entries[i].dataSeries.type === 'error') {
              const sd = (e.entries[i].dataPoint.y[1] - e.entries[i].dataPoint.y[0]) / 2;

              content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataSeries.name}:</span> ${sd.toFixed(2)}<br/>`;
            }
            else {
              content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataSeries.name}:</span> ${this.props.labelFormatter(e.entries[i].dataPoint.y)}<br/>`;
            }
  				}

  				return content;
  			}
			},
      axisY: {
				labelFormatter: (entry) => this.props.labelFormatter(entry.value)
			},
			data: this.getData()
		};

    if(this.props.dateBased) {
      options.axisX = {
        valueFormatString: "D",
        interval: 1,
        intervalType: "day"
      };
    }
    else {
      options.axisX = {labelFormatter: (entry) => entry.label ? entry.label.split(' ')[0] : entry.label};
    }

    if(this.props.title) {
      options.title = {
				text: this.props.title,
        fontFamily: "Open Sans, sans-serif",
        fontColor: "#333"
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.dataPointWidth ) {
      options.dataPointWidth  = this.props.dataPointWidth ;
    }

    if(this.props.stripLine) {
      options.axisY.stripLines = [this.props.stripLine];
    }

    if(this.props.xLabelAngle) {
      options.axisX.labelAngle = this.props.xLabelAngle;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
		<div className={this.props.className}>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
				onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default SimpleBarGraph;
