import React from 'react';
import { Link } from 'react-router-dom';
import './meal_period_data.scss';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../../utils/default_input';
import WarningMessage from '../../warning_message';

class MealPeriodData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.meal_period.id && this.props.meal_period.id > 0;
  }

  render() {
    return (
      <DefaultSection
        className="meal-period-data"
        title="Dados do período de refeição"
      >

        <div className="meal-period-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="meal-period-data__input-container">

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome do período"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.meal_period.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <HalfWrapper>

            <DefaultInput
              name="start_time"
              isHighlighted={this.isHighlighted("start_time")}
              label="Hora inicial"
              type="time"
              placeholder="Horário de início"
              handleInputChange={this.props.handleInputChange}
              value={this.props.meal_period.start_time || ''}
              max={this.props.meal_period.end_time}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="end_time"
              isHighlighted={this.isHighlighted("end_time")}
              label="Hora final"
              type="time"
              placeholder="Horário de término"
              handleInputChange={this.props.handleInputChange}
              value={this.props.meal_period.end_time || ''}
              min={this.props.meal_period.start_time}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do período"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.meal_period.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            name="visible_at_history_form"
            label="Presento no histórico de alimentação:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.meal_period.visible_at_history_form}
          />

        </div>

        <HorizontalRule />

        <div className="meal-period-data__buttons-container">

          <button
            className="meal-period-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="meal-period-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default MealPeriodData;
