import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, patchModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import {PERSONAL_TRAINING_PATH} from '../student/constants';
import PersonalTrainingData from './personal_training_data';
import * as permissions from '../../permissions';

class PersonalTrainingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personal_training_data: null,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let personal_training_data = getModel(`${routes.PERSONAL_TRAINING_DATA_ENTRY_GET_API}${this.props.match.params.personalTrainingDataId}`);

    personal_training_data = await personal_training_data;

    if(personal_training_data) {
      update.personal_training_data = personal_training_data;

      this.setState(update);
    }
    else {
      this.props.history.goBack();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'minutes') {
      const totalSeconds = this.state.personal_training_data.total_seconds || 0;

      if(value !== null) {
        value = (parseInt(value)*60) + (totalSeconds % 60);
      }

      name = 'total_seconds';
    }
    else if(name === 'seconds') {
      const totalSeconds = this.state.personal_training_data.total_seconds || 0;

      if(value !== null) {
        value = parseInt(value) + (Math.floor(totalSeconds / 60)*60);
      }

      name = 'total_seconds';
    }
    else if(name === 'workoutMinutes') {
      const totalSeconds = this.state.personal_training_data.workout_time || 0;

      if(value !== null) {
        value = (parseInt(value)*60) + (totalSeconds % 60);
      }

      name = 'workout_time';
    }
    else if(name === 'workoutSeconds') {
      const totalSeconds = this.state.personal_training_data.workout_time || 0;

      if(value !== null) {
        value = parseInt(value) + (Math.floor(totalSeconds / 60)*60);
      }

      name = 'workout_time';
    }

    const newData = {...this.state.personal_training_data, [name]: value};

    this.setState({
      personal_training_data: newData
    });
  }

  inputsAreValid() {
    return this.state.personal_training_data && this.state.personal_training_data.date.length > 0;
  }

  async saveData() {
    if(!this.state.personal_training_data.difficulty) {
      this.setState({
        highlights: ['difficulty'],
        showWarningMessage: true,
        warningMessage: "Dificuldade não selecionada"
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.personal_training_data}
    data.difficulty = data.difficulty || null;
    data.pse = parseInt(data.pse);
    data.total_repetitions = parseInt(data.total_repetitions);
    data.total_seconds = parseFloat(data.total_seconds);
    data.workout_time = parseFloat(data.workout_time);
    data.total_weight = parseFloat(data.total_weight);

    try {
      await patchModel(`${routes.PERSONAL_TRAINING_DATA_PATCH_API}${this.props.match.params.personalTrainingDataId}`, data);
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if(error.message.includes('Inactive student')) {
                warningMessages = ['Aluno inativo'];
              }
              // for(let parameter of error.parameters) {
              //   switch (parameter.name) {
              //     case 'period':
              //       warningMessages.push('Período deve ser maior que 0');
              //       highlights.push('period');
              //
              //       break;
              //     default:
              //   }
              // }

              break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(`${routes.STUDENT_EDIT_PATH}${this.state.personal_training_data.user_id}${PERSONAL_TRAINING_PATH}`);
  }

  onSetPse(value) {
    if(!this.props.userPermissionIds.includes(permissions.EDIT_PERSONAL_TRAINING_DATA_PERMISSION)) {
      return;
    }

    const newData = {...this.state.personal_training_data, pse: value};

    this.setState({
      personal_training_data: newData
    });
  }

  render() {
    let pathHistory;
    let cancelPath;

    if(this.state.personal_training_data) {
      cancelPath = `${routes.STUDENT_EDIT_PATH}${this.state.personal_training_data.user_id}${PERSONAL_TRAINING_PATH}`;

      pathHistory = [
        {
          path: routes.DESKTOP_PATH,
          text: "Área de trabalho"
        },
        {
          path: cancelPath,
          text: "Personal training"
        },
        {
          path: `${routes.PERSONAL_TRAINING_EDIT_PATH}${this.props.match.params.personalTrainingDataId}`,
          text: "Editar treino"
        },
      ];
    }
    else {
      cancelPath = routes.DESKTOP_PATH;

      pathHistory = [
        {
          path: routes.DESKTOP_PATH,
          text: "Área de trabalho"
        },
        {
          path: `${routes.PERSONAL_TRAINING_EDIT_PATH}${this.props.match.params.personalTrainingDataId}`,
          text: "Editar treino"
        },
      ];
    }

    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={pathHistory}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar treino"
        loading={this.state.loading}
      >

        <PersonalTrainingData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          personal_training_data={this.state.personal_training_data}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={cancelPath}
          onSetPse={(value) => this.onSetPse(value)}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default PersonalTrainingEdit;
