import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {PERSONAL_TRAINING_PATH} from '../student/constants';
import {postModel, getModels, getModel} from '../../utils/functions';
import TrainingPeriodData from './training_period_data';

class TrainingPeriodAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      training_period: {
        name: "",
        code: "",
        target_service: "",
        main_objective: "",
        suggested_disc_profile: "",
        suggested_physical_level: "",
        target_gender: "",
        description: "",
        note: "",
        repetition_count: 3,
        period_reference: null,
        editable: true,
        shared: false,
        is_active: true
      },
      student: null,
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    let user_id = (new URLSearchParams(this.props.location.search)).get('student_id');

    if(user_id) {
      user_id = parseInt(user_id);
    }

    const update = {
      loading: false
    }

    if(user_id) {
      let student = await getModel(`${routes.STUDENT_GET_API}${user_id}`);

      if(student) {
        update.student = student;
      }
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.training_period, [name]: value};

    this.setState({
      training_period: newData
    });
  }

  inputsAreValid() {
    return this.state.training_period.name.length > 0 &&
           this.state.training_period.repetition_count > 0;
  }

  async saveData() {
    const repetition_count = parseInt(this.state.training_period.repetition_count);

    if(repetition_count > 20) {
      this.setState({
        highlights: ['repetition_count'],
        showWarningMessage: true,
        warningMessage: "Número de repetições não pode ser maior que 20."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.training_period, repetition_count};

    if(data.period_reference) {
      data.period_reference = parseInt(data.period_reference);
    }
    if(data.suggested_disc_profile !== null && data.suggested_disc_profile.length <= 0) {
      data.suggested_disc_profile = null;
    }
    if(this.state.student !== null) {
      data.user_id = this.state.student.id;
    }

    let response;

    try {
      response = await postModel(routes.TRAINING_PERIOD_POST_API, data, true);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // TODO: replace by edit path
    this.props.history.replace(`${routes.TRAINING_PERIOD_EDIT_PATH}${response.model_saved.id}`);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${PERSONAL_TRAINING_PATH}` : routes.TRAINING_PERIOD_LIST_PATH,
            text: this.state.student !== null ? 'Personal training' : 'Listar periodizações'
          },
          {
            path: routes.TRAINING_PERIOD_ADD_PATH,
            text: "Adicionar periodização"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar periodização"
        loading={this.state.loading}
      >

        <TrainingPeriodData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          training_period={this.state.training_period}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={this.state.student !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${PERSONAL_TRAINING_PATH}` : routes.TRAINING_PERIOD_LIST_PATH}
          services={this.state.services}
          student={this.state.student}
          userPermissionIds={this.props.userPermissionIds}
          userAccessLevel={this.props.userAccessLevel}
        />

      </ContentFrame>
    );
  }
}

export default TrainingPeriodAdd;
