import React from 'react';
import './model_table.scss';

function Property(name, text, icon, configurations={}) {
  const defaultConfigurations = {
    getDataText: (data) => data[name],
    getSortCallback: null,
    getFilterText: (data) => data[name] || '',
    getCellClassName: (data) => '',
    getCellStyle: (data) => null,
    applyFilter: true,
    sortable: true,
    isFlexible: true,
    cellClassName: "",
    headerClassName: "",
  };

  if(configurations.cellClassName && configurations.cellClassName.length > 0) {
    defaultConfigurations.getCellClassName = (data) => configurations.cellClassName;
  }
  // else {
  //   defaultConfigurations.getCellClassName = (data) => '';
  // }

  const configurationsToApply = {...defaultConfigurations, ...configurations};

  return {
    name: name,
    text: text,
    icon: icon,
    ...configurationsToApply
  };
}

export {Property};

class ModelTable extends React.Component {
  constructor(props) {
    super(props);

    let initialParams = {
      linesPerPage: props.initialLinesPerPage || 5,
      currentPage: 1,
      orderProperty: props.initialOrderBy,
      orderCrescent: props.initialOrderIsDecrescent || false,
      filterString: "",
    };

    this.entriesNumber = props.data.length;

    this.accentRegex = new RegExp(/\p{Diacritic}/, 'gu');

    let storedParams = sessionStorage.getItem(this.getStorageKey());

    if(storedParams) {
      initialParams = {...initialParams, ...JSON.parse(storedParams)};

      const pageCount = Math.ceil(this.entriesNumber / initialParams.linesPerPage);

      if(initialParams.currentPage > pageCount) {
        initialParams.currentPage = pageCount;
      }

      if(initialParams.currentPage < 1) {
        initialParams.currentPage = 1;
      }
    }

    this.state = initialParams;
  }

  getStorageKey() {
    if (this.props.storageKey) {
      return `${window.location.pathname}:${this.props.storageKey}`;
    }

    return window.location.pathname
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.currentPage !== this.state.currentPage ||
       prevState.linesPerPage !== this.state.linesPerPage ||
       prevState.orderProperty !== this.state.orderProperty ||
       prevState.orderCrescent !== this.state.orderCrescent ||
       prevState.filterString !== this.state.filterString) {
      sessionStorage.setItem(this.getStorageKey(), JSON.stringify({
        currentPage: this.state.currentPage,
        linesPerPage: this.state.linesPerPage,
        orderProperty: this.state.orderProperty,
        orderCrescent: this.state.orderCrescent,
        filterString: this.state.filterString,
      }));
    }
  }

  getSortIconClass(propertyName) {
    if(this.state.orderProperty === propertyName) {
      return this.state.orderCrescent ? 'fas fa-sort-down model-table__table__header-sort-icon--selected' : 'fas fa-sort-up model-table__table__header-sort-icon--selected';
    }

    return 'fas fa-sort model-table__table__header-sort-icon';
  }

  onClickHeader(property) {
    if(!property.sortable) {
      return;
    }

    if(this.state.orderProperty === property.name) {
      this.setState({
        orderCrescent: !this.state.orderCrescent
      });
    }
    else {
      this.setState({
        orderProperty: property.name,
        orderCrescent: false
      });
    }
  }

  onChangeFilter(event) {
    this.setState({
      filterString: event.target.value,
      currentPage: 1
    });
  }

  getHeaders() {
    return this.props.properties.map((property) => {
      return (
        <th
          className={`model-table__table__header-cell${!property.sortable ? '--not-sortable': ''} ${property.headerClassName}`}
          key={`model_table_header_${property.name}`}
          onClick={() => this.onClickHeader(property)}
        >

          <div className={`model-table__table__header-wrapper${property.isFlexible ? '--flex' : ''}`}>

            <div className="model-table__table__header-label">

              {property.icon ?
                (
                  <div className="model-table__table__header-icon">

                    {property.icon}

                  </div>
                ):
                null
              }

              {property.text}

            </div>

            {property.sortable &&
              <i className={this.getSortIconClass(property.name)}></i>
            }

          </div>

        </th>
      );
    });
  }

  getRowCells(row) {
    const cells = this.props.properties.map((property) => {
      return (
        <td
          className={`model-table__table__row-cell${this.state.orderProperty === property.name ? '--highlighted': ''} ${property.getCellClassName(row)}`}
          style={property.getCellStyle(row)}
          key={`model_table_property_${property.name}`}
        >
          {property.getDataText(row)}
        </td>
      );
    });

    if(this.props.getActions) {
      cells.push(
        <td
          className="model-table__table__row-cell"
          key={`model_table_property_controls`}
        >

          {this.props.getActions(row)}

        </td>
      );
    }

    return cells;
  }

  getRows() {
    const propertyName = this.state.orderProperty;

    let sortCallback = null;

    let property = this.props.properties.find((property) => property.name === propertyName);

    if(property) {
      sortCallback = property.getSortCallback;
    }

    if(sortCallback === null) {
      sortCallback = (a, b) => {
        const aValue = a[propertyName] ? a[propertyName].toString() : this.props.defaultSortValue || '';
        const bValue = b[propertyName] ? b[propertyName].toString() : this.props.defaultSortValue || '';

        return aValue.localeCompare(bValue);
      }
    }

    const propertyCallbacks = this.props.properties.filter((property) => property.applyFilter).map((property) => property.getFilterText);

    let filteredData = [];

    if(this.state.filterString) {
      const normilizedFilterString = this.state.filterString.normalize("NFD").replace(this.accentRegex, "").toLowerCase().trim();

      filteredData = this.props.data.filter((entry) => {
        return propertyCallbacks.some((callback) => {
          if (this.props.restrictiveFilter) {
            return callback(entry).toString().normalize("NFD").replace(this.accentRegex, "").toLowerCase().startsWith(normilizedFilterString);
          }

          return callback(entry).toString().normalize("NFD").replace(this.accentRegex, "").toLowerCase().includes(normilizedFilterString);
        });
      });
    }
    else {
      filteredData = this.props.data;
    }

    if(propertyName) {
      filteredData.sort(sortCallback);

      if(this.state.orderCrescent) {
        filteredData.reverse();
      }
    }

    this.entriesNumber = filteredData.length;

    this.pageCount = Math.ceil(this.entriesNumber / this.state.linesPerPage);

    // TODO: Create an update functions called at the beginning of the render method.
    const initialIndex = (this.state.currentPage - 1) * this.state.linesPerPage;
    const finalIndex = this.state.currentPage * this.state.linesPerPage;

    const visibleData = filteredData.slice(initialIndex, finalIndex);

    return visibleData.map((row) => {
      return (
        <tr
          className={`model-table__table__row${this.props.getRowClassName ? ` ${this.props.getRowClassName(row)}` : ''}`}
          key={`model_table_${this.props.getIdCallback ? this.props.getIdCallback(row) : row.id}`}
        >

          {this.getRowCells(row)}

        </tr>
      );
    });
  }

  onChangeLineCount(event) {
    this.setState({
      linesPerPage: parseInt(event.target.value),
      currentPage: 1
    });
  }

  setCurrentPage(page) {
    if(page !== this.state.currentPage && page >= 1 && page <= this.pageCount) {
      this.setState({
        currentPage: page
      });
    }
  }

  getPageButtons() {
    const buttons = [];

    let initialIndex = this.state.currentPage - 1;

    if(initialIndex < 1) {
      initialIndex = 1;
    }

    let finalIndex = this.state.currentPage + 1;

    if(finalIndex > this.pageCount) {
      finalIndex = this.pageCount;
    }

    for(let i = initialIndex; i <= finalIndex; i++) {
      buttons.push(
        <button
          className={`model-table__page-button${this.state.currentPage === i ? '--selected' : ''}`}
          disabled={this.state.currentPage === i}
          key={`page_button_${i}`}
          onClick={() => this.setCurrentPage(i)}
        >

          {i}

        </button>
      );
    }

    return buttons;
  }

  render() {
    const tableRows = this.getRows();

    let lineCountInputId = 'table_show_lines_number';
    let filterInputId = 'table_filter_string';

    if (this.props.storageKey) {
      lineCountInputId = `${this.props.storageKey}:${lineCountInputId}`;
      filterInputId = `${this.props.storageKey}:${filterInputId}`;
    }

    return (
      <React.Fragment>

        {this.props.children &&
          <div className="model-table__buttons">

            {this.props.children}

          </div>
        }

        <div className="model-table__show-controls">

          {!this.props.hideLinesPerPageControl &&
            <div className="model-table__line-count-container">

              <label
                htmlFor={lineCountInputId}
                className="model-table__line-count-label"
              >

                Linhas por página:

              </label>

              <select
                id={lineCountInputId}
                value={this.state.linesPerPage}
                className="model-table__line-count-input"
                onChange={(event) => this.onChangeLineCount(event)}
              >
                <option value={5}>5</option>
                <option value={15}>15</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>

            </div>
          }

          {!this.props.hideFilter &&
            <div className="model-table__string-filter-container">

              <label
                htmlFor={filterInputId}
                className="model-table__string-filter-label"
              >

                Filtrar:

              </label>

              <input
                type="search"
                id={filterInputId}
                aria-label="Filtro de registros apresentados na listagem"
                className="model-table__string-filter-input"
                onChange={(event) => this.onChangeFilter(event)}
                value={this.state.filterString}
                autoComplete="off"
              />

            </div>
          }

        </div>

        <div
          className="model-table__table-wrapper"
          style={{minHeight: this.props.enableMinRowHeight ? `${2.5 + (tableRows.length * 3.5)}em` : null}}
        >

          <table className="model-table__table">

            <thead>

              <tr className="model-table__table__row">

                {this.getHeaders()}

                {this.props.getActions &&
                  <th className="model-table__table__header-cell--not-sortable">

                    <div className="model-table__table__header-wrapper">

                      <div className="model-table__table__header-label">

                        <div className="model-table__table__header-icon">

                          <i className="fas fa-cogs"></i>

                        </div>

                        Ações

                      </div>

                    </div>

                  </th>
                }

              </tr>

            </thead>

            <tbody>

              {tableRows}

            </tbody>

          </table>

        </div>

        {!this.props.hideNavigationControls &&
          <div className="model-table__page-controls">

            <div className="model-table__entries-text-container">

              <p  className="model-table__entries-text">

                Entradas de{' '}

                <span className="model-table__entries-text-span">

                  {(((this.state.currentPage - 1) * this.state.linesPerPage) + 1) <= this.entriesNumber ? (((this.state.currentPage - 1) * this.state.linesPerPage) + 1) : this.entriesNumber}

                </span>

                {' '}à{' '}

                <span className="model-table__entries-text-span">

                  {(this.state.currentPage * this.state.linesPerPage) <= this.entriesNumber ? (this.state.currentPage * this.state.linesPerPage) : this.entriesNumber}

                </span>

                {' '}de{' '}

                <span className="model-table__entries-text-span">

                  {this.entriesNumber}

                </span>

              </p>

            </div>

            <div className="model-table__page-buttons-container">

              <button
                className="model-table__page-button"
                disabled={this.state.currentPage <= 1}
                onClick={() => this.setCurrentPage(1)}
              >

                <i className="fas fa-angle-double-left"></i>

              </button>

              <button
                className="model-table__page-button"
                disabled={this.state.currentPage <= 1}
                onClick={() => this.setCurrentPage(this.state.currentPage - 1)}
              >

                <i className="fas fa-angle-left"></i>

              </button>

              {this.getPageButtons()}

              <button
                className="model-table__page-button"
                disabled={this.state.currentPage >= this.pageCount}
                onClick={() => this.setCurrentPage(this.state.currentPage + 1)}
              >

                <i className="fas fa-angle-right"></i>

              </button>

              <button
                className="model-table__page-button"
                disabled={this.state.currentPage >= this.pageCount}
                onClick={() => this.setCurrentPage(this.pageCount)}
              >

                <i className="fas fa-angle-double-right"></i>

              </button>

            </div>

          </div>
        }

      </React.Fragment>
    );
  }
}

export default ModelTable;
