import React from 'react';
import { Link } from 'react-router-dom';
import './payment_device_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class PaymentDeviceData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isEditMode() {
    return this.props.payment_device.id && this.props.payment_device.id > 0;
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  render() {
    return (
      <DefaultSection
        className="payment-device-data"
        title="Dados da maquininha"
      >

        <div className="payment-device-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="payment-device-data__input-container">

          <DefaultInput
            name="is_active"
            label="Ativo:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.payment_device.is_active}
            horizontalAlign="right"
          />

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome do frequencímetro"
              type="text"
              placeholder="Nome do frequencímetro"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.payment_device.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="serial_number"
              isHighlighted={this.isHighlighted("serial_number")}
              label="Número de série"
              type="text"
              placeholder="Número de série"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.payment_device.serial_number}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={this.isEditMode()}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição"
            rows="6"
            handleInputChange={this.props.handleInputChange}
            value={this.props.payment_device.description || ''}
          />

        </div>

        <HorizontalRule />

        <div className="payment-device-data__buttons-container">

          <button
            className="payment-device-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="payment-device-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default PaymentDeviceData;
