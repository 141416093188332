import React from 'react';
import { Link } from 'react-router-dom';
import './food_ingredient_list.scss';
import ContentFrame from '../../content_frame';
import DefaultInput, {HalfWrapper, SelectOption} from '../../../utils/default_input';
import * as routes from '../../../constants';
import ModelTable, {Property} from '../../../utils/model_table';
import ConfirmationWindow from '../../confirmation_window';
import DefaultSection from '../../../utils/default_section';
import {getModels, deleteModel, setUrlParameters} from '../../../utils/functions';
import * as permissions from '../../../permissions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';

class FoodIngredientList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let classificationId = queryParameters.get('classification_id');

    if(!classificationId) {
      classificationId = '';
    }

    this.state = {
      loadingData: true,
      classificationIdInput: classificationId,
      classificationIdFilter: classificationId,
      food_classifications: [],
      food_ingredients: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getFoodClassifications() {
    if (this.props.userPermissionIds.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION)) {
      return await getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));
    }

    return [];
  }

  async getFoodIngredients() {
    const parameters = {};

    if(this.state.classificationIdFilter.length > 0) {
      parameters.classification_id = this.state.classificationIdFilter;
    }

    return await getModels(setUrlParameters(routes.FOOD_INGREDIENTS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.classificationIdFilter !== this.state.classificationIdFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        food_ingredients: await this.getFoodIngredients(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    let food_ingredients = this.getFoodIngredients();
    let food_classifications = this.getFoodClassifications();

    food_classifications = await food_classifications;
    food_ingredients = await food_ingredients;

    this.setState({
      loadingData: false,
      food_classifications: food_classifications,
      food_ingredients: food_ingredients,
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteId != null) {
      try{
        if(await deleteModel(`${routes.FOOD_INGREDIENT_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'recipe_associations':
                      descriptions.push('Ingrediente vinculado a um cadastro de receita');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Todos os vínculos devem ser removidos manualmente antes de prosseguir com a remoção deste cadastro.`;

                break;
              case 208:
                if (error.message.includes('This entry cannot be deleted due to its essencial associacions')) {
                  errorDescription = 'Esta entrada não pode ser removida por conter informações nutricionais importantes.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      confirmInProgress: false,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_INGREDIENT_PERMISSION) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.FOOD_INGREDIENT_EDIT_PATH}${entry.id}`}
          >

          {(this.props.userPermissionIds.includes(permissions.EDIT_FOOD_INGREDIENT_PERMISSION)) ?
            <i className="fas fa-edit"></i>:
            <i className="fas fa-eye"></i>
          }

          </Link>
        }

        {(!entry.is_permanent && this.props.userPermissionIds.includes(permissions.DELETE_FOOD_INGREDIENT_PERMISSION)) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getPeriodText(entry) {
    return (
      <div className="food-ingredient-list__cell-wrapper">

        <p className="food-ingredient-list__period-text">{`${entry.period} dia${entry.period > 1 ? 's' : ''}`}</p>

      </div>
    );
  }

  getClassificationText(entry) {
    return entry.food_classification.name;
  }

  getProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Ingrediente', <i className="fas fa-carrot"></i>)
    );
    properties.push(
      Property(
        'food_classification_id',
        'Grupo',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getClassificationText, getFilterText: this.getClassificationText})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!filterCallback || filterCallback(node)) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
    }

    node.children.sort((a, b) => a.name.localeCompare(b.name));

    for(let child of node.children) {
      if(!filterCallback || filterCallback(child)) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getFoodClassificationOptions() {
    let food_classifications = this.state.food_classifications.filter((entry) => entry.depth === 0);

    food_classifications.sort((a, b) => a.name.localeCompare(b.name));

    food_classifications = food_classifications.flatMap((node) => this.getNodeOptions(node));

    return [
      SelectOption('', 'Todos'),
      ...food_classifications
    ];
  }

  mayRefreshList() {
    if(this.state.classificationIdInput !== this.state.classificationIdFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.FOOD_INGREDIENT_LIST_PATH, {
        classification_id: this.state.classificationIdInput,
      }));

      this.setState({
        classificationIdFilter: this.state.classificationIdInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteId !== null) {
        return 'Deletando ingrediente';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteId !== null) {
        return 'Falha ao deletar';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteId !== null) {
      return 'Deletar ingrediente';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteId != null) {
      return 'Todos os dados relacionados ao ingrediente serão removidos';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteId != null) {
      return 'Deletar ingrediente';
    }

    return 'Unknown';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FOOD_INGREDIENT_LIST_PATH,
              text: "Listar ingredientes"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar ingredientes"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="food-ingredient-list"
            title="Lista de ingredientes alimentícios"
          >

            <div className="food-ingredient-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_FOOD_INGREDIENT_PERMISSION) &&
                <Link
                  className="model-table__default-button"
                  to={routes.FOOD_INGREDIENT_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo ingrediente

                </Link>
              }

            </div>

            {this.props.userPermissionIds.includes(permissions.VIEW_FOOD_CLASSIFICATION_PERMISSION) &&
              <div className="food-ingredient-list__filters">

                <header className="food-ingredient-list__filters__header">

                  <h4 className="food-ingredient-list__filters__header__text">Filtros</h4>

                </header>

                <div className="food-ingredient-list__filters__inputs">

                  <div className="food-ingredient-list__filters__inputs-wrapper">

                    <HalfWrapper>

                      <DefaultInput
                        name="classificationIdInput"
                        label="Grupo"
                        type="select"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.classificationIdInput || ''}
                        options={this.getFoodClassificationOptions()}
                      />

                    </HalfWrapper>

                  </div>

                  <button
                    className="food-ingredient-list__filters__refresh-button"
                    onClick={() => this.refreshList()}
                    disabled={!this.mayRefreshList()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>

                </div>

              </div>
            }

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.state.food_ingredients}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FoodIngredientList;
