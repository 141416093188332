import React from 'react';
import {checkElementInViewport} from '../../utils/functions';
import './horizontal_bar_graph.scss';

class BarGraphDataPoint {
  #value = null;
  #label = null;
  #fillColor = null;
  #borderColor = null;
  #borderLineDash = [];
  #borderRadius = null;
  #borderWidth = 1;
  #animationDuration = null;
  #animationDelay = null;

  #xPosition = null;
  #yPosition = null;
  #width = null;
  #height = null;

  #timeReference = null;

  #animationPosition = 0;

  constructor(initialValue, initialLabel) {
    this.value = initialValue;
    this.label = initialLabel;
  }

  /**
   * @param {number} newValue
   */
  set value(newValue) {
    if (typeof newValue !== "number") {
      console.error(`"value" passed for BarDataPoint is not a number! (value = ${newValue})`);
      this.#value = 0;
    }
    else {
      this.#value = newValue;
    }
  }
  get value() {
    return this.#value;
  }

  /**
   * @param {number} newBorderWidth
   */
  set borderWidth(newBorderWidth) {
    if (typeof newBorderWidth !== "number") {
      console.error(`"borderWidth" passed for BarDataPoint is not a number! (borderWidth = ${newBorderWidth})`);
      this.#borderWidth = 1;
    }
    else {
      this.#borderWidth = newBorderWidth;
    }
  }
  get borderWidth() {
    return this.#borderWidth;
  }

  /**
   * @param {string} newLabel
   */
  set label(newLabel) {
    if (typeof newLabel !== "string") {
      console.error(`"label" passed for BarDataPoint is not a string! (label = ${newLabel})`);
      this.#label = 'INVALID';
    }
    else {
      this.#label = newLabel;
    }
  }
  get label() {
    return this.#label;
  }

  /**
   * @param {string} newFillColor
   */
  set fillColor(newFillColor) {
    if (typeof newFillColor !== "string") {
      console.error(`"fillColor" passed for BarDataPoint is not a string! (fillColor = ${newFillColor})`);
    }
    else if (newFillColor === 'none') {
      this.#fillColor = null;
    }
    else {
      this.#fillColor = newFillColor;
    }
  }
  get fillColor() {
    return this.#fillColor;
  }

  /**
   * @param {string} newBorderColor
   */
  set borderColor(newBorderColor) {
    if (typeof newBorderColor !== "string") {
      console.error(`"borderColor" passed for BarDataPoint is not a string! (borderColor = ${newBorderColor})`);
    }
    else if (newBorderColor === 'none') {
      this.#borderColor = null;
    }
    else {
      this.#borderColor = newBorderColor;
    }
  }
  get borderColor() {
    return this.#borderColor;
  }

  /**
   * @param {int|int[]} newBorderLineDash
   */
  set borderLineDash(newBorderLineDash) {
    if (!Array.isArray(newBorderLineDash) || newBorderLineDash.length !== 2 || newBorderLineDash.some((entry) => typeof entry !== "number")) {
      console.error('Invalid "borderLineDash" passed for BarDataPoint! It should follow the example: [5, 10]');
    }
    else {
      this.#borderLineDash = [...newBorderLineDash];
    }
  }
  get borderLineDash() {
    return this.#borderLineDash;
  }

  /**
   * @param {int|int[]} newBorderRadius
   */
  set borderRadius(newBorderRadius) {
    if (!Array.isArray(newBorderRadius) || newBorderRadius.length > 4 || newBorderRadius.some((entry) => typeof entry !== "number")) {
      console.error('Invalid "borderRadius" passed for BarDataPoint! It should follow the example: 5, [5, 5, 0, 0]');
    }
    else {
      this.#borderRadius = [...newBorderRadius];
    }
  }
  get borderRadius() {
    return this.#borderRadius;
  }

  /**
   * @param {number} newAnimationDuration
   */
  set animationDuration(newAnimationDuration) {
    if (typeof newAnimationDuration !== "number") {
      console.error(`"animationDuration" passed for BarDataPoint is not a number! (animationDuration = ${newAnimationDuration})`);
      this.#animationDuration = null;
    }
    else {
      this.#animationDuration = newAnimationDuration;
    }
  }
  get animationDuration() {
    return this.#animationDuration;
  }

  /**
   * @param {number} newAnimationDelay
   */
  set animationDelay(newAnimationDelay) {
    if (typeof newAnimationDelay !== "number") {
      console.error(`"animationDelay" passed for BarDataPoint is not a number! (animationDelay = ${newAnimationDelay})`);
      this.#animationDelay = null;
    }
    else {
      this.#animationDelay = newAnimationDelay;
    }
  }
  get animationDelay() {
    return this.#animationDelay;
  }

  get xPosition() {
    return this.#xPosition;
  }
  get yPosition() {
    return this.#yPosition;
  }
  get width() {
    return this.#width;
  }
  get height() {
    return this.#height;
  }

  setDrawProperties(xPosition, yPosition, width, height) {
    this.#xPosition = xPosition;
    this.#yPosition = yPosition;
    this.#width = width;
    this.#height = height;
  }

  drawBar(context, defaultRadius, defaultBorderWidth) {
    let positionMultiplier = 1;

    if (this.#animationDelay !== null && this.#animationDuration !== null) {
      if (this.#timeReference !== null) {
        let elapsedTime = ((Date.now() - this.#timeReference)/1000) - this.#animationDelay;

        this.#animationPosition = elapsedTime / this.#animationDuration;

        if (elapsedTime <= 0) {
          positionMultiplier = 0;
        }
        else if (elapsedTime < this.#animationDuration) {
          positionMultiplier = Math.pow(this.#animationPosition - 1, 3) + 1;
        }
      }
      else {
        positionMultiplier = 0;
      }
    }

    if (this.#fillColor !== null) {
      context.fillStyle  = this.#fillColor;
    }
    if (this.#borderColor !== null) {
      context.strokeStyle  = this.#borderColor;
    }
    context.lineWidth  = this.#borderWidth !== null ? this.#borderWidth : defaultBorderWidth;
    context.setLineDash(this.#borderLineDash);

    context.beginPath();

    context.roundRect(this.#xPosition, this.#yPosition + ((1 - positionMultiplier) * this.#height), this.#width, positionMultiplier * this.#height, this.#borderRadius !== null ? this.#borderRadius : defaultRadius);

    if (this.#fillColor !== null) {
      context.fill();
    }
    if (this.#borderColor !== null) {
      context.stroke();
    }
  }

  setAnimation(animationDuration, animationDelay) {
    if (this.#animationDuration === null) {
      this.#animationDuration = animationDuration;
    }
    if (this.#animationDelay === null) {
      this.#animationDelay = animationDelay;
    }
  }

  playAnimation(timeReference) {
    this.#timeReference = timeReference;
  }

  animationIsRunning() {
    if (this.#timeReference === null) {
      return false;
    }

    return this.#animationPosition < 1;
  }
}

class HorizontalBarGraph extends React.Component {
  #canvas = null;
  #container = null;

  #stopDrawing = false;
  #animationHasTriggered = false;
  #data = null;

  #margin = 'auto';

  #axesColor = '#3a3839';
  #hideHorizontalOffsetGrid = true
  #barBorderRadius = 'auto';
  #barLineWidth = 1;

  #yLabelColor = '#3a3839';
  #yGridColor = '#919191';
  #yLabelTextSize = 18;
  #yLabelWidthMultiplier = 1.1;
  #yLabelHorizontalOffset = 5;
  #yLabelVerticalOffset = 0;
  #yLabelTextBaseline = 'alphabetic';
  #yLabelDigitPrecision = 0;
  #yLabelHideZeroLabel = true;
  #yAxisRange = null;
  #yAxisLabelStep = 'auto';
  #yAxisRangeOffsetPercentage = 0.1;
  #minimumYLabelDistanceMultiplier = 1.1;
  #yLabelIsBold = false;

  #xLabelTextSize = 'auto';
  #xLabelHeightMultiplier = 1.1;
  #xLabelVerticalOffset = 5;
  #xAxisSideOffsetPercentage = 0.05;
  #barSpacingPercentage = 0.2;
  #xLabelTextBaseline = 'hanging';
  #xLabelIsBold = false;
  #xAxistUnitLabelTextSize = 18;
  #xAxisUnitLabel = null;
  #xAxisUnitLabelPosition = 'end';
  #xAxisUnitLabelHorizontalOffset = 0;
  #xAxisUnitLabelVerticalOffset = 0;

  #xlabelHorizontalMargin = 5;

  #playAnimation = true;
  #barAnimationDelay = 0.1;
  #barAnimationDuration = 0.8;

  #yLabelMinValue = null;
  #yLabelMaxValue = null;
  #xLabelMinPosition = null;
  #xLabelMaxPosition = null;

  #xAxisLabelStep = 0;

  #yLabelHorizontalPosition = 0
  #xLabelVerticalPosition = 0
  // Y positions are considered positive from bottom to top
  #yAxisMinPosition = 0;
  #yAxisMaxPosition = 0;
  #xAxisMinPosition = 0;
  #xAxisMaxPosition = 0;

  #barWidth = 0;

  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };

    this.canvasRef = React.createRef();
    this.graphContainerRef = React.createRef();
  }

  get margin() {
    if (this.#margin === 'auto') {
      return 3;
    }

    return this.#margin;
  }

  get xLabelTextSize() {
    if (this.#xLabelTextSize === 'auto') {
      if(this.state.screenWidth <= 1300) {
        return 15;
      }

      return 18;
    }

    return this.#xLabelTextSize;
  }

  processGeneralProps() {
    if ('margin' in this.props) {
      if ((typeof this.props.margin !== 'number' || this.props.margin <= 0) && this.props.margin !== 'auto') {
        console.error('Invalid "margin" passed for bar graph! It should be a positive number or "auto"!');
      }
      else {
        this.#margin = this.props.margin;
      }
    }
    else {
      this.#margin = 'auto';
    }

    if ('totalAnimationDuration' in this.props) {
      if (typeof this.props.totalAnimationDuration !== 'number') {
        console.error('"totalAnimationDuration" passed for bar graph is not a number!');
      }
      else {
        this.#barAnimationDelay = this.props.totalAnimationDuration;
      }
    }
    else {
      this.#barAnimationDelay = 0.1;
    }

    if ('barAnimationDuration' in this.props) {
      if (typeof this.props.barAnimationDuration !== 'number') {
        console.error('"barAnimationDuration" passed for bar graph is not a number!');
      }
      else {
        this.#barAnimationDuration = this.props.barAnimationDuration;
      }
    }
    else {
      this.#barAnimationDuration = 0.8;
    }
  }

  processBarProps() {
    if ('barBorderRadius' in this.props) {
      if (typeof this.props.barBorderRadius !== "number" && (!Array.isArray(this.props.barBorderRadius) || this.props.barBorderRadius.length > 4 || this.props.barBorderRadius.some((entry) => typeof entry !== "number")) && this.props.barBorderRadius !== 'auto') {
        console.error('Invalid "barBorderRadius" passed for graph! It should follow the example: 5, [5, 5, 0, 0]');
      }
      else if (Array.isArray(this.props.barBorderRadius)) {
        this.#barBorderRadius = [...this.props.barBorderRadius];
      }
      else {
        this.#barBorderRadius = this.props.barBorderRadius;
      }
    }
    else {
      this.#barBorderRadius = 'auto';
    }

    if ('barLineWidth' in this.props) {
      if (typeof this.props.barLineWidth !== "number") {
        console.error('"barLineWidth" passed for graph is not a number!');
      }
      else {
        this.#barLineWidth = this.props.barLineWidth;
      }
    }
    else {
      this.#barLineWidth = 1;
    }
  }

  processAxesProps() {
    if ('yAxisRange' in this.props) {
      if (!Array.isArray(this.props.yAxisRange) || this.props.yAxisRange.length !== 2 || this.props.yAxisRange.some((entry) => typeof entry !== "number" && entry !== "auto")) {
        console.error('Invalid "yAxisRange" passed for bar graph! It should follow the examples: [0, 100], [0, "auto"], ["auto", 100]');
      }
      else {
        this.#yAxisRange = [...this.props.yAxisRange];
      }
    }
    else {
      this.#yAxisRange = ['auto', 'auto'];
    }

    if ('yAxisLabelStep' in this.props) {
      if ((typeof this.props.yAxisLabelStep !== 'number' || this.props.yAxisLabelStep <= 0) && this.props.yAxisLabelStep !== 'auto') {
        console.error('Invalid "yAxisLabelStep" passed for bar graph! It should be a positive number or "auto"!');
      }
      else {
        this.#yAxisLabelStep = this.props.yAxisLabelStep;
      }
    }
    else {
      this.#yAxisLabelStep = 'auto';
    }

    if ('axesColor' in this.props) {
      if (typeof this.props.axesColor !== 'string') {
        console.error('"axesColor" passed for bar graph is not a string!');
      }
      else {
        this.#axesColor = this.props.axesColor;
      }
    }
    else {
      this.#axesColor = '#3a3839';
    }

    if ('yLabelColor' in this.props) {
      if (typeof this.props.yLabelColor !== 'string') {
        console.error('"yLabelColor" passed for bar graph is not a string!');
      }
      else {
        this.#yLabelColor = this.props.yLabelColor;
      }
    }
    else {
      this.#yLabelColor = '#3a3839';
    }

    if ('yGridColor' in this.props) {
      if (typeof this.props.yGridColor !== 'string') {
        console.error('"yGridColor" passed for bar graph is not a string!');
      }
      else {
        this.#yGridColor = this.props.yGridColor;
      }
    }
    else {
      this.#yGridColor = '#919191';
    }

    if ('yLabelHideZeroLabel' in this.props) {
      if (typeof this.props.yLabelHideZeroLabel !== 'boolean') {
        console.error('"yLabelHideZeroLabel" passed for bar graph is not a boolean!');
      }
      else {
        this.#yLabelHideZeroLabel = this.props.yLabelHideZeroLabel;
      }
    }
    else {
      this.#yLabelHideZeroLabel = true;
    }

    if ('yLabelIsBold' in this.props) {
      if (typeof this.props.yLabelIsBold !== 'boolean') {
        console.error('"yLabelIsBold" passed for bar graph is not a boolean!');
      }
      else {
        this.#yLabelIsBold = this.props.yLabelIsBold;
      }
    }
    else {
      this.#yLabelIsBold = false;
    }

    if ('xLabelIsBold' in this.props) {
      if (typeof this.props.xLabelIsBold !== 'boolean') {
        console.error('"xLabelIsBold" passed for bar graph is not a boolean!');
      }
      else {
        this.#xLabelIsBold = this.props.xLabelIsBold;
      }
    }
    else {
      this.#xLabelIsBold = false;
    }

    if ('yAxisRangeOffsetPercentage' in this.props) {
      if (typeof this.props.yAxisRangeOffsetPercentage !== 'number') {
        console.error('"yAxisRangeOffsetPercentage" passed for bar graph is not a number!');
      }
      else {
        this.#yAxisRangeOffsetPercentage = this.props.yAxisRangeOffsetPercentage;
      }
    }
    else {
      this.#yAxisRangeOffsetPercentage = 0.1;
    }

    if ('yLabelDigitPrecision' in this.props) {
      if (typeof this.props.yLabelDigitPrecision !== 'number') {
        console.error('"yLabelDigitPrecision" passed for bar graph is not a number!');
      }
      else {
        this.#yLabelDigitPrecision = parseInt(this.props.yLabelDigitPrecision);
      }
    }
    else {
      this.#yLabelDigitPrecision = 0;
    }

    if ('yLabelHorizontalOffset' in this.props) {
      if (typeof this.props.yLabelHorizontalOffset !== 'number') {
        console.error('"yLabelHorizontalOffset" passed for bar graph is not a number!');
      }
      else {
        this.#yLabelHorizontalOffset = this.props.yLabelHorizontalOffset;
      }
    }
    else {
      this.#yLabelHorizontalOffset = 5;
    }

    if ('xLabelVerticalOffset' in this.props) {
      if (typeof this.props.xLabelVerticalOffset !== 'number') {
        console.error('"xLabelVerticalOffset" passed for bar graph is not a number!');
      }
      else {
        this.#xLabelVerticalOffset = this.props.xLabelVerticalOffset;
      }
    }
    else {
      this.#xLabelVerticalOffset = 5;
    }

    if ('xAxisUnitLabelHorizontalOffset' in this.props) {
      if (typeof this.props.xAxisUnitLabelHorizontalOffset !== 'number') {
        console.error('"xAxisUnitLabelHorizontalOffset" passed for bar graph is not a number!');
      }
      else {
        this.#xAxisUnitLabelHorizontalOffset = this.props.xAxisUnitLabelHorizontalOffset;
      }
    }
    else {
      this.#xAxisUnitLabelHorizontalOffset = 0;
    }

    if ('xAxisUnitLabelVerticalOffset' in this.props) {
      if (typeof this.props.xAxisUnitLabelVerticalOffset !== 'number') {
        console.error('"xAxisUnitLabelVerticalOffset" passed for bar graph is not a number!');
      }
      else {
        this.#xAxisUnitLabelVerticalOffset = this.props.xAxisUnitLabelVerticalOffset;
      }
    }
    else {
      this.#xAxisUnitLabelVerticalOffset = 0;
    }

    if ('yLabelVerticalOffset' in this.props) {
      if (typeof this.props.yLabelVerticalOffset !== 'number') {
        console.error('"yLabelVerticalOffset" passed for bar graph is not a number!');
      }
      else {
        this.#yLabelVerticalOffset = this.props.yLabelVerticalOffset;
      }
    }
    else {
      this.#yLabelVerticalOffset = 0;
    }

    if ('yLabelTextSize' in this.props) {
      if (typeof this.props.yLabelTextSize !== 'number') {
        console.error('"yLabelTextSize" passed for bar graph is not a number!');
      }
      else {
        this.#yLabelTextSize = this.props.yLabelTextSize;
      }
    }
    else {
      this.#yLabelTextSize = 18;
    }

    if ('xLabelTextSize' in this.props) {
      if ((typeof this.props.xLabelTextSize !== 'number' || this.props.xLabelTextSize <= 0) && this.props.xLabelTextSize !== 'auto') {
        console.error('Invalid "xLabelTextSize" passed for bar graph! It should be a positive number or "auto"!');
      }
      else {
        this.#xLabelTextSize = this.props.xLabelTextSize;
      }
    }
    else {
      this.#xLabelTextSize = 'auto';
    }

    if ('xAxistUnitLabelTextSize' in this.props) {
      if (typeof this.props.xAxistUnitLabelTextSize !== 'number') {
        console.error('"xAxistUnitLabelTextSize" passed for bar graph is not a number!');
      }
      else {
        this.#xAxistUnitLabelTextSize = this.props.xAxistUnitLabelTextSize;
      }
    }
    else {
      this.#xAxistUnitLabelTextSize = 18;
    }

    if ('yLabelWidthMultiplier' in this.props) {
      if (typeof this.props.yLabelWidthMultiplier !== 'number') {
        console.error('"yLabelWidthMultiplier" passed for bar graph is not a number!');
      }
      else {
        this.#yLabelWidthMultiplier = this.props.yLabelWidthMultiplier;
      }
    }
    else {
      this.#yLabelWidthMultiplier = 1.1;
    }

    if ('xLabelHeightMultiplier' in this.props) {
      if (typeof this.props.xLabelHeightMultiplier !== 'number') {
        console.error('"xLabelHeightMultiplier" passed for bar graph is not a number!');
      }
      else {
        this.#xLabelHeightMultiplier = this.props.xLabelHeightMultiplier;
      }
    }
    else {
      this.#xLabelHeightMultiplier = 1.1;
    }

    if ('minimumLabelDistanceMultiplier' in this.props) {
      if (typeof this.props.minimumLabelDistanceMultiplier !== 'number') {
        console.error('"minimumLabelDistanceMultiplier" passed for bar graph is not a number!');
      }
      else {
        this.#minimumYLabelDistanceMultiplier = this.props.minimumLabelDistanceMultiplier;
      }
    }
    else {
      this.#minimumYLabelDistanceMultiplier = 1.1;
    }

    if ('yLabelTextBaseline' in this.props) {
      if (typeof this.props.yLabelTextBaseline !== 'string') {
        console.error('"yLabelTextBaseline" passed for bar graph is not a string!');
      }
      else {
        this.#yLabelTextBaseline = this.props.yLabelTextBaseline;
      }
    }
    else {
      this.#yLabelTextBaseline = 'alphabetic';
    }

    if ('xLabelTextBaseline' in this.props) {
      if (typeof this.props.xLabelTextBaseline !== 'string') {
        console.error('"xLabelTextBaseline" passed for bar graph is not a string!');
      }
      else {
        this.#xLabelTextBaseline = this.props.xLabelTextBaseline;
      }
    }
    else {
      this.#xLabelTextBaseline = 'hanging';
    }

    if ('xAxisUnitLabel' in this.props) {
      if (typeof this.props.xAxisUnitLabel !== 'string') {
        console.error('"xAxisUnitLabel" passed for bar graph is not a string!');
      }
      else {
        this.#xAxisUnitLabel = this.props.xAxisUnitLabel;
      }
    }
    else {
      this.#xAxisUnitLabel = null;
    }

    if ('xAxisUnitLabelPosition' in this.props) {
      if (typeof this.props.xAxisUnitLabelPosition !== 'string') {
        console.error('"xAxisUnitLabelPosition" passed for bar graph is not a string!');
      }
      else {
        this.#xAxisUnitLabelPosition = this.props.xAxisUnitLabelPosition;
      }
    }
    else {
      this.#xAxisUnitLabelPosition = 'end';
    }
  }

  processGraphData() {
    this.#data = [];

    if (!Array.isArray(this.props.data)) {
      return;
    }

    for (const entry of this.props.data) {
      if (typeof entry !== "object") {
        console.error(`Invalid bar graph data point format! Entry: ${entry}`);
        continue;
      }
      else if (!('value' in entry)) {
        console.error(`Missing "value" property in bar graph data point! Entry: ${entry}`);
        continue;
      }
      else if (!('label' in entry)) {
        console.error(`Missing "label" property in bar graph data point! Entry: ${entry}`);
        continue;
      }

      const newBar = new BarGraphDataPoint(entry.value, entry.label);

      if ('fillColor' in entry) {
        newBar.fillColor = entry.fillColor;
      }
      if ('borderColor' in entry) {
        newBar.borderColor = entry.borderColor;
      }
      if ('borderLineDash' in entry) {
        newBar.borderLineDash = entry.borderLineDash;
      }
      if ('borderRadius' in entry) {
        newBar.borderRadius = entry.borderRadius;
      }
      if ('borderWidth' in entry) {
        newBar.borderWidth = entry.borderWidth;
      }

      this.#data.push(newBar);
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  checkForScroll() {
    if (!this.#playAnimation) {
      return false;
    }

    if(!this.#animationHasTriggered) {
      if(checkElementInViewport(this.#canvas, 0.8)) {
        this.playGraphAnimation();

        this.#animationHasTriggered = true;

        return true;
      }
    }

    requestAnimationFrame(this.checkForScroll.bind(this));

    return false;
  }

  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => this.chart.render(), 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    this.#canvas = this.canvasRef.current;
    this.#container = this.graphContainerRef.current;

    this.processGeneralProps();
    this.processBarProps();
    this.processAxesProps();
    this.processGraphData();
    this.updateCanvasSize();
    this.calculateAxes();

    if (this.#playAnimation) {
      this.setGraphAnimation();
    }

    if (!this.checkForScroll()) {
      requestAnimationFrame(this.drawGraph.bind(this));
    }
  }

  animationIsRunning() {
    if (!Array.isArray(this.#data)) {
      return false;
    }

    return this.#data.some((entry) => entry.animationIsRunning());
  }

  async componentDidUpdate(prevProps, prevState) {
    let redrawGraph = false;

    if (prevProps.data !== this.props.data) {
      this.processGraphData();
      this.calculateAxes();
      redrawGraph = true;
    }
    if (prevProps.axesColor !== this.props.axesColor ||
        prevProps.yLabelColor !== this.props.yLabelColor ||
        prevProps.yLabelDigitPrecision !== this.props.yLabelDigitPrecision ||
        prevProps.xAxistUnitLabelTextSize !== this.props.xAxistUnitLabelTextSize ||
        prevProps.xAxisUnitLabel !== this.props.xAxisUnitLabel ||
        prevProps.xAxisUnitLabelPosition !== this.props.xAxisUnitLabelPosition ||
        prevProps.xAxisUnitLabelHorizontalOffset !== this.props.xAxisUnitLabelHorizontalOffset ||
        prevProps.xAxisUnitLabelVerticalOffset !== this.props.xAxisUnitLabelVerticalOffset ||
        prevProps.yLabelTextBaseline !== this.props.yLabelTextBaseline ||
        prevProps.xLabelTextBaseline !== this.props.xLabelTextBaseline ||
        prevProps.yLabelHideZeroLabel !== this.props.yLabelHideZeroLabel ||
        prevProps.yLabelIsBold !== this.props.yLabelIsBold ||
        prevProps.xLabelIsBold !== this.props.xLabelIsBold ||
        prevProps.yGridColor !== this.props.yGridColor) {
      this.processAxesProps();
      redrawGraph = true;
    }
    if (prevProps.yAxisRange !== this.props.yAxisRange ||
        prevProps.yLabelVerticalOffset !== this.props.yLabelVerticalOffset ||
        prevProps.yLabelTextSize !== this.props.yLabelTextSize ||
        prevProps.xLabelTextSize !== this.props.xLabelTextSize ||
        prevProps.yAxisRangeOffsetPercentage !== this.props.yAxisRangeOffsetPercentage ||
        prevProps.minimumLabelDistanceMultiplier !== this.props.minimumLabelDistanceMultiplier ||
        prevProps.xLabelHeightMultiplier !== this.props.xLabelHeightMultiplier ||
        prevProps.yLabelHorizontalOffset !== this.props.yLabelHorizontalOffset ||
        prevProps.xLabelVerticalOffset !== this.props.xLabelVerticalOffset ||
        prevProps.yLabelWidthMultiplier !== this.props.yLabelWidthMultiplier ||
        prevProps.yAxisLabelStep !== this.props.yAxisLabelStep) {
      this.processAxesProps();
      this.calculateAxes();
      redrawGraph = true;
    }
    if (prevProps.margin !== this.props.margin) {
      this.processGeneralProps();
      this.calculateAxes();
      redrawGraph = true;
    }
    if (prevProps.barBorderRadius !== this.props.barBorderRadius ||
        prevProps.barLineWidth !== this.props.barLineWidth) {
      this.processBarProps();
      redrawGraph = true;
    }

    if(prevState.screenWidth !== this.state.screenWidth) {
      this.processAxesProps();
      this.updateCanvasSize();
      this.calculateAxes();

      if (!this.animationIsRunning()) {
        redrawGraph = true;
      }
    }

    if (redrawGraph) {
      requestAnimationFrame(this.drawGraph.bind(this));
    }
  }

  componentWillUnmount() {
    this.#stopDrawing = true;

    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }

    window.removeEventListener("resize", this.resizeListener);
  }

  setGraphAnimation() {
    if (Array.isArray(this.#data)) {
      this.#data.forEach((entry, index) => entry.setAnimation(this.#barAnimationDuration, index * this.#barAnimationDelay));
    }
  }

  playGraphAnimation() {
    const timeReference = Date.now();

    if (Array.isArray(this.#data)) {
      this.#data.forEach((entry, index) => entry.playAnimation(timeReference));
    }

    requestAnimationFrame(this.drawGraph.bind(this));
  }

  getYCanvasPosition(value) {
    return this.#yAxisMinPosition + ((this.#yAxisMaxPosition - this.#yAxisMinPosition) * (value - this.#yAxisRange[0]) / (this.#yAxisRange[1] - this.#yAxisRange[0]));
  }

  calculateAxes() {
    // Y axis
    if (this.#yAxisRange[0] === 'auto') {
      this.#yAxisRange[0] = Math.min(0, ...this.#data.map((entry) => entry.value));
    }
    if (this.#yAxisRange[1] === 'auto') {
      this.#yAxisRange[1] = Math.max(0, ...this.#data.map((entry) => entry.value));
    }

    if (this.#yAxisLabelStep === 'auto') {
      const totalGraphHeight = this.#yAxisMinPosition - this.#yAxisMaxPosition;
      const yAxisRangeOffsetPercentage = ((this.#yAxisRange[0] < 0 && this.#yAxisRange[1] > 0) ? 2 : 1) * this.#yAxisRangeOffsetPercentage;

      let yLabelStepCapacity = Math.floor(((1 - yAxisRangeOffsetPercentage) * totalGraphHeight) / (this.#yLabelTextSize * this.#minimumYLabelDistanceMultiplier));

      let currentStepProposal = null;

      for (let i=yLabelStepCapacity; (i >= 3 || i === yLabelStepCapacity); i--) {
        const yAxisLabelStep = ((this.#yAxisRange[1] - this.#yAxisRange[0]) / i);

        let orderOfMagnitude;

        if (yAxisLabelStep >= 1) {
          orderOfMagnitude = 1;

          while (Math.floor(yAxisLabelStep / Math.pow(10, orderOfMagnitude)) > 0) {
            orderOfMagnitude += 1;
          }

          orderOfMagnitude = Math.pow(10, orderOfMagnitude - 1) / 2;
        }
        else {
          orderOfMagnitude = -1;

          while (Math.floor(yAxisLabelStep / Math.pow(10, orderOfMagnitude)) <= 0) {
            orderOfMagnitude -= 1;
          }

          orderOfMagnitude = Math.pow(10, orderOfMagnitude) / 2;
        }

        const proposal = {
          perfectionDistance: yAxisLabelStep % orderOfMagnitude,
          step: orderOfMagnitude * Math.ceil(yAxisLabelStep / orderOfMagnitude)
        };

        if (currentStepProposal === null || currentStepProposal.perfectionDistance > proposal.perfectionDistance) {
          currentStepProposal = proposal;
        }
      }

      this.#yAxisLabelStep = currentStepProposal.step;
    }

    if (Math.abs(this.#yAxisRange[0]) % this.#yAxisLabelStep < Math.abs(this.#yAxisRange[1]) % this.#yAxisLabelStep) {
      this.#yLabelMinValue = Math.sign(this.#yAxisRange[0]) * this.#yAxisLabelStep * Math.ceil(Math.abs(this.#yAxisRange[0]) / this.#yAxisLabelStep);
      this.#yLabelMaxValue = this.#yLabelMinValue + this.#yAxisLabelStep * Math.floor((this.#yAxisRange[1] - this.#yLabelMinValue) / this.#yAxisLabelStep);
    }
    else {
      this.#yLabelMaxValue = Math.sign(this.#yAxisRange[1]) * this.#yAxisLabelStep * Math.ceil(Math.abs(this.#yAxisRange[1]) / this.#yAxisLabelStep);
      this.#yLabelMinValue = this.#yLabelMaxValue - this.#yAxisLabelStep * Math.floor((this.#yLabelMaxValue - this.#yAxisRange[0]) / this.#yAxisLabelStep);
    }

    this.#yAxisRange[0] = this.#yAxisRange[0] * (1 + this.#yAxisRangeOffsetPercentage);
    this.#yAxisRange[1] = this.#yAxisRange[1] * (1 + this.#yAxisRangeOffsetPercentage);

    // Positions
    const context = this.#canvas.getContext('2d');

    const margin = this.margin;

    // Label positions
    context.font = `${this.#yLabelIsBold ? 'bold ' : ''}${this.#yLabelTextSize}px 'Orbitron', sans-serif`;
    const yLabelMetric = context.measureText(Math.max(Math.abs(this.#yAxisRange[0]), Math.abs(this.#yAxisRange[1]).toFixed(this.#yLabelDigitPrecision)));

    this.#yLabelHorizontalPosition = margin + (yLabelMetric.width * this.#yLabelWidthMultiplier);
    this.#xLabelVerticalPosition = this.#canvas.height - margin - (this.xLabelTextSize * this.#xLabelHeightMultiplier)

    // Axes positions
    this.#yAxisMinPosition = this.#xLabelVerticalPosition - this.#xLabelVerticalOffset;
    this.#yAxisMaxPosition = margin;

    this.#xAxisMinPosition = this.#yLabelHorizontalPosition + this.#yLabelHorizontalOffset;
    this.#xAxisMaxPosition = this.#canvas.width - margin;

    // X axis
    const labelCount = new Set(this.#data.map((entry) => entry.label)).size;

    const xAxisSideOffset = (this.#xAxisMaxPosition - this.#xAxisMinPosition) * this.#xAxisSideOffsetPercentage;
    this.#xLabelMinPosition = this.#xAxisMinPosition + xAxisSideOffset;
    this.#xLabelMaxPosition = this.#xAxisMaxPosition - xAxisSideOffset;

    this.#xAxisLabelStep = (this.#xLabelMaxPosition - this.#xLabelMinPosition) / labelCount;

    this.#barWidth = this.#xAxisLabelStep * (1 - (2*this.#barSpacingPercentage));

    let barPosition = this.#xLabelMinPosition + (this.#barSpacingPercentage * this.#xAxisLabelStep);
    for (const data of this.#data) {
      const barHeight = this.#yAxisMinPosition - this.getYCanvasPosition(data.value);
      data.setDrawProperties(barPosition, this.#yAxisMinPosition - barHeight, this.#barWidth, barHeight);

      barPosition += this.#xAxisLabelStep;
    }
  }

  drawAxes() {
    const context = this.#canvas.getContext('2d');

    const yGridPositions = [];
    const yLabelPositions = [];

    for (let labelValue = this.#yLabelMinValue; labelValue <= this.#yAxisRange[1]; labelValue += this.#yAxisLabelStep) {
      const position = {
        value: labelValue,
        position: this.getYCanvasPosition(labelValue)
      };

      if (labelValue >= this.#yAxisRange[0] && labelValue <= this.#yAxisRange[1]) {
        yGridPositions.push(position);
      }

      if (labelValue >= this.#yLabelMinValue && labelValue <= this.#yLabelMaxValue && (!this.#yLabelHideZeroLabel || labelValue !== 0)) {
        yLabelPositions.push(position);
      }
    }

    const xGridPositions = [];

    for (let currentPosition = this.#xLabelMinPosition; currentPosition <= this.#xLabelMaxPosition; currentPosition += this.#xAxisLabelStep) {
      xGridPositions.push({
        position: currentPosition
      });

      const middlePosistion = currentPosition + (0.5*this.#xAxisLabelStep);

      if (middlePosistion <= this.#xLabelMaxPosition) {
        xGridPositions.push({
          position: middlePosistion
        });
      }
    }

    // Graph grid
    context.strokeStyle = this.#yGridColor;
    context.lineWidth = 1;

    context.beginPath();

    const horizontalGridMinPosition = this.#hideHorizontalOffsetGrid ? this.#xLabelMinPosition : this.#xAxisMinPosition;
    const horizontalGridMaxPosition = this.#hideHorizontalOffsetGrid ? this.#xLabelMaxPosition : this.#xAxisMaxPosition;

    for (const entry of yGridPositions) {
      context.moveTo(horizontalGridMinPosition, entry.position);
      context.lineTo(horizontalGridMaxPosition, entry.position);
    }

    for (const entry of xGridPositions) {
      context.moveTo(entry.position, this.#yAxisMinPosition);
      context.lineTo(entry.position, this.#yAxisMaxPosition);
    }

    context.stroke();

    // Graph bars
    const barBorderRadius = this.#barBorderRadius === 'auto' ? [this.#barWidth * 0.25, this.#barWidth * 0.25, 0, 0] : this.#barBorderRadius;

    for (const data of this.#data) {
      data.drawBar(context, barBorderRadius, this.#barLineWidth);
    }

    // Axes
    context.strokeStyle = this.#axesColor;
    context.setLineDash([]);
    context.lineJoin = "round";
    context.lineCap = "round";
    context.lineWidth = 3;

    context.beginPath();

    context.moveTo(this.#xAxisMinPosition, this.#yAxisMaxPosition);
    context.lineTo(this.#xAxisMinPosition, this.#yAxisMinPosition);
    context.lineTo(this.#xAxisMaxPosition, this.#yAxisMinPosition);

    context.stroke();

    // Labels
    context.font = `${this.#yLabelIsBold ? 'bold ' : ''}${this.#yLabelTextSize}px 'Orbitron', sans-serif`;
    context.fillStyle = this.#yLabelColor;
    context.textBaseline = this.#yLabelTextBaseline;
    context.textAlign = 'right';

    context.beginPath();

    for (const entry of yLabelPositions) {
      context.fillText(entry.value.toFixed(this.#yLabelDigitPrecision), this.#yLabelHorizontalPosition, entry.position + this.#yLabelVerticalOffset);
    }

    context.font = `${this.#xLabelIsBold ? 'bold ' : ''}${this.xLabelTextSize}px 'Montserrat', sans-serif`;
    context.textBaseline = this.#xLabelTextBaseline;
    context.textAlign = 'center';

    context.beginPath();

    for (const data of this.#data) {
      context.fillText(data.label, data.xPosition + (0.5*data.width), this.#xLabelVerticalPosition);
    }

    // Axis unit label
    if (this.#xAxisUnitLabel !== null) {
      context.font = `${this.#xAxistUnitLabelTextSize}px 'Montserrat', sans-serif`;
      context.textBaseline = this.#xLabelTextBaseline;

      switch (this.#xAxisUnitLabelPosition) {
        case 'start':
          context.textAlign = 'center';

          context.beginPath();

          context.fillText(this.#xAxisUnitLabel, this.#xAxisMinPosition + this.#xAxisUnitLabelHorizontalOffset, this.#xLabelVerticalPosition + this.#xAxisUnitLabelVerticalOffset);

          break;
        case 'end':
        default:
          context.textAlign = 'right';

          context.beginPath();

          context.fillText(this.#xAxisUnitLabel, this.#xAxisMaxPosition + this.#xAxisUnitLabelHorizontalOffset, this.#xLabelVerticalPosition + this.#xAxisUnitLabelVerticalOffset);

          break;
      }
    }
  }

  updateCanvasSize() {
    const parentRect = this.#container.getBoundingClientRect();
    const containerWidth = parentRect.width; //offsetWidth
    const containerHeight = parentRect.height; //offsetHeight
    let canvasWidth = containerWidth;
    let canvasHeight = containerHeight;

    const yAxisRange = [...this.#yAxisRange];

    if (yAxisRange[0] === 'auto') {
      yAxisRange[0] = Math.min(0, ...this.#data.map((entry) => entry.value));
    }
    if (yAxisRange[1] === 'auto') {
      yAxisRange[1] = Math.max(0, ...this.#data.map((entry) => entry.value));
    }

    const margin = this.margin;

    const context = this.#canvas.getContext('2d');

    context.font = `${this.#yLabelIsBold ? 'bold ' : ''}${this.#yLabelTextSize}px 'Orbitron', sans-serif`;
    const yLabelMetric = context.measureText(Math.max(Math.abs(yAxisRange[0]), Math.abs(yAxisRange[1]).toFixed(this.#yLabelDigitPrecision)));

    const yLabelHorizontalPosition = margin + (yLabelMetric.width * this.#yLabelWidthMultiplier);

    const xAxisMinPosition = yLabelHorizontalPosition + this.#yLabelHorizontalOffset;
    const xAxisMaxPosition = containerWidth - this.margin;

    const labelCount = new Set(this.#data.map((entry) => entry.label)).size;

    const xAxisSideOffset = (xAxisMaxPosition - xAxisMinPosition) * this.#xAxisSideOffsetPercentage;
    const xLabelMinPosition = xAxisMinPosition + xAxisSideOffset;
    const xLabelMaxPosition = xAxisMaxPosition - xAxisSideOffset;

    const xAxisLabelStep = (xLabelMaxPosition - xLabelMinPosition) / labelCount;

    context.font = `${this.#xLabelIsBold ? 'bold ' : ''}${this.xLabelTextSize}px 'Montserrat', sans-serif`;

    let widthDifference = 0;

    for (const data of this.#data) {
      widthDifference += (context.measureText(data.label).width + (2*this.#xlabelHorizontalMargin)) - xAxisLabelStep;
    }

    if (widthDifference > 0) {
      this.#container.style.overflow = 'auto';
      this.#container.style.overflowY = 'hidden';
      canvasWidth += widthDifference;
      canvasHeight -= this.state.screenWidth <= 850 ? 4 : 10;
    }
    else {
      this.#container.style.overflow = 'hidden';
    }

    this.#canvas.width = canvasWidth;
    this.#canvas.height = canvasHeight;
  }

  drawGraph() {
    if(this.#stopDrawing) {
      return;
    }

    if(!this.#canvas) {
      return;
    }

    const context = this.#canvas.getContext('2d');

    context.clearRect(0, 0, this.#canvas.width, this.#canvas.height);

    this.drawAxes();

    if(this.animationIsRunning()) {
      requestAnimationFrame(this.drawGraph.bind(this));
    }
  }

	render() {
		return (
      <div className="horizontal-bar-graph__wrapper">

        <div className={`horizontal-bar-graph__scroll-container${this.props.scrollContainerClassName ? (' ' + this.props.scrollContainerClassName) : ''}`} ref={this.graphContainerRef}>

          <canvas className="horizontal-bar-graph" ref={this.canvasRef} />

        </div>

      </div>
		);
	}
}

export default HorizontalBarGraph;
