import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {NUTRITIONIST_ROLE} from '../../constants';
import {getModel, getModels, patchModel, setUrlParameters} from '../../utils/functions';
import NutritionistData from './nutritionist_data';
import * as permissions from '../../permissions';

class NutritionistEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nutritionist: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_experience_level_id: null,
        is_active: true,
        has_full_access: false
      },
      role_experience_levels: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getRoleExperienceLevels() {
    const parameters = {
      is_active: true,
      role_id: NUTRITIONIST_ROLE.id
    };

    return await getModels(setUrlParameters(routes.ROLE_EXPERIENCE_LEVELS_GET_API, parameters));
  }

  async componentDidMount() {
    try {
      let role_experience_levels = this.getRoleExperienceLevels();
      const nutritionist = await getModel(`${routes.NUTRITIONIST_GET_API}${this.props.match.params.userId}`);

      const update = {loading: false};

      if(nutritionist) {
        update.nutritionist = {...this.state.nutritionist, ...nutritionist};

        if (nutritionist.role_experience_level !== null) {
          update.nutritionist.role_experience_level_id = nutritionist.role_experience_level.id
        }

        role_experience_levels = await role_experience_levels;

        if (role_experience_levels) {
          update.role_experience_levels = role_experience_levels;
        }

        this.setState(update);
      }
      else {
        this.props.history.replace(routes.NUTRITIONIST_LIST_PATH);
      }
    }
    catch(errors) {
      this.props.history.replace(routes.NUTRITIONIST_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.nutritionist, [name]: value};

    this.setState({
      nutritionist: newData
    });
  }

  inputsAreValid() {
    return this.state.nutritionist.name.length > 0 &&
           this.state.nutritionist.email.match(/.+@.+\..+/) != null;
  }

  async saveData() {
    if(this.state.nutritionist.password !== this.state.nutritionist.password_confirmation) {
      this.setState({
        highlights: ['password', 'password_confirmation'],
        showWarningMessage: true,
        warningMessage: "Confirmarção de senha não bate."
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let nutritionistData = {...this.state.nutritionist};

    if (!nutritionistData.has_full_access && nutritionistData.role_experience_level_id && nutritionistData.role_experience_level_id > 0) {
      nutritionistData.role_experience_level_id = parseInt(nutritionistData.role_experience_level_id);
    }
    else {
      nutritionistData.role_experience_level_id = null;
    }

    if(!nutritionistData.password) {
      delete nutritionistData.password;
      delete nutritionistData.password_confirmation;
    }

    try {
      await patchModel(`${routes.NUTRITIONIST_PATCH_API}${this.props.match.params.userId}`, nutritionistData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 203) {
            if(error.message.includes('User from another unit')) {
              warningMessages.push('Email já cadastrado em outra unidade. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
            else if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para editar este cadastro. Por favor, entre em contato com o responsável pela unidade FYD para realizar a edição');
              highlights.push('email');
            }
          }
          else if(error.code === 208) {
            if(error.message.includes('User can not inactivate himself')) {
              warningMessages.push('Usuário não pode se desativar');
              highlights.push('is_active');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.NUTRITIONIST_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.NUTRITIONIST_LIST_PATH,
            text: "Listar nutricionistas"
          },
          {
            path: `${routes.NUTRITIONIST_EDIT_PATH}${this.props.match.params.userId}`,
            text: "Editar nutricionista"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar nutricionista"
        loading={this.state.loading}
      >

        <NutritionistData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          nutritionist={this.state.nutritionist}
          role_experience_levels={this.state.role_experience_levels}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.NUTRITIONIST_LIST_PATH}
          mayTriggerEmailChange={this.props.userPermissionIds.includes(permissions.TRIGGER_USER_EMAIL_CHANGE_PERMISSION_ID)}
          mayChangeEmail={this.props.userPermissionIds.includes(permissions.CHANGE_USER_EMAIL_PERMISSION_ID)}
          userPermissionIds={this.props.userPermissionIds}
        />

      </ContentFrame>
    );
  }
}

export default NutritionistEdit;
