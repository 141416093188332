import React from 'react';
import { Link } from 'react-router-dom';
import './user_device_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class UserDeviceData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  getServiceOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.props.services.map((service) => SelectOption(service, service))
    ];
  }

  render() {
    return (
      <DefaultSection
        className="user-device-data"
        title="Dados do dispositivo"
      >

        <div className="user-device-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="user-device-data__input-container">

          <DefaultInput
            name="user_name"
            isHighlighted={this.isHighlighted("user_name")}
            label="Nome do responsável"
            type="text"
            placeholder="Nome do responsável"
            maxLength="128"
            value={this.props.device.user_name}
            autoComplete="off"
            disabled={true}
          />

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome do dispositivo"
              type="text"
              placeholder="Nome do dispositivo"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.device.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="target_service"
              isHighlighted={this.isHighlighted("target_service")}
              label="Serviço permitido"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.device.target_service || ''}
              options={this.getServiceOptions()}
            />

          </HalfWrapper>

          <DefaultInput
            name="enabled"
            isHighlighted={this.isHighlighted('enabled')}
            label="Habilitado:"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.device.enabled}
          />

        </div>

        <HorizontalRule />

        <div className="user-device-data__buttons-container">

          <button
            className="user-device-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="user-device-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default UserDeviceData;
