import React from 'react';
import { Link } from 'react-router-dom';
import './food_prescription_data.scss';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../../utils/default_section';
import {VerticalAccordionContainer, VerticalAccordionSubContainer, VerticalAccordionSub2Container} from '../../../utils/pose_containers';
import LoadingIcon from '../../../components/loading_icon'
import DefaultInput, {SelectOption, HalfWrapper, DummyInput} from '../../../utils/default_input';
import OverlayWindow from '../../../components/overlay_window';
import DefaultMenuButton from '../../../components/default_menu_button';
import WarningMessage from '../../warning_message';
import ModelTable, {Property} from '../../../utils/model_table';
import {getFoodRecipeNutricionalData,
        WEEKDAY_NAMES,
        CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
        PROTEIN_TO_KCAL_CONVERSION_CONTANT,
        FAT_TO_KCAL_CONVERSION_CONTANT,
        BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT,
        getFoodPrescriptionNutritionalData} from '../../../utils/fyd_food';
import StackedBarGraph, {StackGroup, StackPoint, ErrorPoint} from '../../../graphs/stacked_bar_graph';
import {FOOD_PRESCRIPTION_OBJECTIVES, NUTRIENT_PROTEIN_ID, NUTRIENT_FAT_ID, NUTRIENT_CARBOHYDRATE_ID, NUTRIENT_FIBER_ID, FOOD_MEASUREMENT_GRAM_ID} from '../../../constants';
import * as permissions from '../../../permissions';

class FoodPrescriptionData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMealPeriod: null,
      nutritionInfoVisible: false,
      periodNutritionInfoVisibilityMap: new Set(),
      mealClassificationFilter: "",
      foodClassificationFilter: "",
      selectedItemTab: 1,
      previewIsVisible: false,
      caloryCalculatorVisible: false,
      prescriptionDuration: '',
      finalWeight: '',
      totalEnergyExpense: '',
      mealAssociationPreviewVisibilityMap: {},
      mealPreparationPreviewVisibilityMap: {},
      screenWidth: window.innerWidth
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.todayWeekDay = today.getDay();
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.food_prescription.id && this.props.food_prescription.id > 0;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {};

    update[name] = value;

    this.setState(update);
  }

  getGenderOptions() {
    return [
      SelectOption('', 'Indefinido'),
      SelectOption('Feminino', 'Feminino'),
      SelectOption('Masculino', 'Masculino'),
    ];
  }

  getMainObjectiveOptions() {
    const objectives = [...FOOD_PRESCRIPTION_OBJECTIVES];

    return [
      SelectOption('', 'Indefinido'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  mayChangeInputs() {
    if (this.isEditMode() && !this.props.userPermissionIds.includes(permissions.EDIT_FOOD_PRESCRIPTION_PERMISSION)) {
      return false
    }

    return this.props.userPermissionIds.includes(permissions.ADD_FOOD_PRESCRIPTION_PERMISSION);
  }

  getProcessingMethodOptions(ingredient_association) {
    let filteredAssociations = ingredient_association.food_ingredient.source_associations;

    if (ingredient_association.food_ingredient.default_nutrient_source_id !== null) {
      filteredAssociations = ingredient_association.food_ingredient.source_associations.filter((association) => association.food_info_source_id === ingredient_association.food_ingredient.default_nutrient_source_id);
    }

    if (ingredient_association.food_ingredient_source_association !== null) {
      return [
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
    else {
      return [
        SelectOption('', 'Selecione um método de processamento'),
        ...filteredAssociations.map((source_association) => SelectOption(source_association.id, source_association.processing_method ? source_association.processing_method.name : 'Não se aplica'))
      ];
    }
  }

  getMeasurementOptions(ingredient_association) {
    let filteredMeasurementAssociations = ingredient_association.food_ingredient.food_measurement_associations.filter((association) => association.processing_method_id === ingredient_association.food_ingredient_source_association.processing_method_id);

    if (ingredient_association.food_ingredient.default_measurement_source_id !== null) {
      filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient_association.food_ingredient.default_measurement_source_id);
    }

    if (ingredient_association.food_ingredient_measurement_association === null) {
      return [
        SelectOption('', 'Selecione uma medida'),
        ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
      ];
    }

    return [
      ...filteredMeasurementAssociations.map((association) => SelectOption(association.id, `${association.food_measurement.name} (${association.weight_reference}${association.weight_reference_unit.shortname})`))
    ];
  }

  getMeasurementName(ingredient_association) {
    let measurementAssociation = ingredient_association.food_ingredient.food_measurement_associations.find((association) => association.id === ingredient_association.food_ingredient_measurement_association.id);

    return `${measurementAssociation.food_measurement.name} (${measurementAssociation.weight_reference}${measurementAssociation.weight_reference_unit.shortname})`;
  }

  getPrescriptionAssociationContent(prescriptionAssociation) {
    let inputs;

    if (prescriptionAssociation.isRecipe) {
      inputs = (
        <div className="food-prescription-data__item-association__inputs-container">

          {prescriptionAssociation.ingredientList.map((ingredient) => {
            return (
              <div
                className="food-prescription-data__item-association__recipe-ingredient"
                key={ingredient.key}
              >

                <p className="food-prescription-data__item-association__recipe-ingredient__name">
                  {ingredient.isRecipe ? (
                    <i className="fas fa-utensils food-prescription-data__item-association__recipe-ingredient__name__icon"></i>
                  ) : (
                    <i className="fas fa-carrot food-prescription-data__item-association__recipe-ingredient__name__icon"></i>
                  )}
                  {ingredient.name !== null ? ingredient.name : ingredient.data.name}
                </p>

                {this.getRecipeIngredientInputs(ingredient, prescriptionAssociation)}

              </div>
            );
          })}

        </div>
      );
    }
    else {
      inputs = this.getPrescriptionIngredientInputs(prescriptionAssociation)
    }

    return (
      <React.Fragment>


        {inputs}

        <HorizontalRule />

      </React.Fragment>
    );
  }

  getRecipeIngredientInputs(ingredient, prescriptionAssociation) {
    const recipe_adjustment = prescriptionAssociation.recipe_adjustments.find((entry) => ingredient.isBasic ? entry.food_recipe_basic_ingredient_association_id === ingredient.id : entry.food_recipe_advanced_ingredient_association_id === ingredient.id);

    const adjustment = recipe_adjustment || ingredient;

    if (ingredient.isBasic) {
      let filteredMeasurementAssociations = ingredient.data.food_measurement_associations;

      if (ingredient.food_ingredient_source_association !== null) {
        filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.processing_method_id === ingredient.food_ingredient_source_association.processing_method_id);
      }

      if (ingredient.data.default_measurement_source_id !== null) {
        filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient.data.default_measurement_source_id);
      }

      if (filteredMeasurementAssociations.length <= 0) {
        return (
          <p className="food-recipe-data__alert-text">

            <i className="fas fa-exclamation food-recipe-data__alert-text__icon"></i>
            Cadastro de ingrediente incompleto

          </p>
        );
      }

      return (
        <HalfWrapper className="food-prescription-data__item-association__recipe-ingredient__inputs-container">

          {ingredient.food_ingredient_source_association.processing_method ? (
            <DefaultInput
              label="Processamento:"
              id={`${prescriptionAssociation.key}:ingredient:${ingredient.key}:processing_method`}
              type="text"
              value={ingredient.food_ingredient_source_association.processing_method.name}
              disabled={true}
            />
          ) : this.state.screenWidth >= 680 ? (<DummyInput />) : null}

          <DefaultInput
            id={`${prescriptionAssociation.key}:ingredient:${ingredient.key}:quantity`}
            name={`ingredient:${ingredient.key}:quantity`}
            isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:quantity`)}
            label="Quantidade"
            labelMessage={(adjustment.quantity !== ingredient.quantity) ? `(${ingredient.quantity})` : null}
            type="number"
            placeholder="-"
            step="0.01"
            min="0.00"
            handleInputChange={(event) => this.props.onUpdateRecipeQuantity(prescriptionAssociation, ingredient, parseFloat(event.target.value) || null)}
            value={adjustment.quantity || ''}
            autoComplete="off"
            disabled={!this.mayChangeInputs()}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultInput
            id={`${prescriptionAssociation.key}:ingredient:${ingredient.key}:food_ingredient_measurement_association_id`}
            name={`ingredient:${ingredient.key}:food_ingredient_measurement_association_id`}
            isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:food_ingredient_measurement_association_id`)}
            label="Medida padrão:"
            labelMessage={(adjustment.food_ingredient_measurement_association_id !== ingredient.food_ingredient_measurement_association_id) ? `(${this.getMeasurementName(ingredient)})` : null}
            type="select"
            handleInputChange={(event) => this.props.onSelectRecipeMeasurementAssociation(prescriptionAssociation, ingredient, parseInt(event.target.value))}
            value={adjustment.food_ingredient_measurement_association_id}
            options={this.getMeasurementOptions(ingredient)}
            disabled={!this.mayChangeInputs()}
          />

        </HalfWrapper>
      );
    }
    else {
      let recipeQuantityText = '';

      if (ingredient.data.default_measurement !== null && ingredient.data.default_measurement_quantity !== null) {
        recipeQuantityText = ` (${ingredient.data.default_measurement_quantity} ${ingredient.data.default_measurement.name}(s))`;
      }
      else if (ingredient.data.output_volume_unit !== null && ingredient.data.output_volume !== null) {
        recipeQuantityText = ` (${ingredient.data.output_volume}${ingredient.data.output_volume_unit.shortname})`;
      }
      else if (ingredient.data.output_weight_unit !== null && ingredient.data.output_weight !== null) {
        recipeQuantityText = ` (${ingredient.data.output_weight}${ingredient.data.output_weight_unit.shortname})`;
      }

      return (
        <HalfWrapper className="food-prescription-data__item-association__recipe-ingredient__inputs-container">

          {this.state.screenWidth >= 680 ? (<DummyInput />) : null}

          <DefaultInput
            id={`${prescriptionAssociation.key}:recipe:${ingredient.key}:quantity`}
            name={`ingredient:${ingredient.key}:quantity`}
            isHighlighted={this.isHighlighted(`ingredient:${ingredient.key}:quantity`)}
            label="Quantidade"
            labelMessage={(adjustment.quantity !== ingredient.quantity) ? `(${ingredient.quantity})` : null}
            type="number"
            placeholder="-"
            step="0.01"
            min="0.00"
            handleInputChange={(event) => this.props.onUpdateRecipeQuantity(prescriptionAssociation, ingredient, parseFloat(event.target.value) || null)}
            value={adjustment.quantity || ''}
            autoComplete="off"
            suffix={`receita(s)${recipeQuantityText}`}
            disabled={!this.mayChangeInputs()}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

        </HalfWrapper>
      );
    }
  }

  getPrescriptionIngredientInputs(ingredient_association) {
    let filteredAssociations = ingredient_association.food_ingredient.source_associations;

    if (ingredient_association.food_ingredient.default_nutrient_source_id !== null) {
      filteredAssociations = ingredient_association.food_ingredient.source_associations.filter((association) => association.food_info_source_id === ingredient_association.food_ingredient.default_nutrient_source_id);
    }

    let filteredMeasurementAssociations = ingredient_association.food_ingredient.food_measurement_associations;

    if (ingredient_association.food_ingredient_source_association !== null) {
      filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.processing_method_id === ingredient_association.food_ingredient_source_association.processing_method_id);
    }

    if (ingredient_association.food_ingredient.default_measurement_source_id !== null) {
      filteredMeasurementAssociations = filteredMeasurementAssociations.filter((association) => association.food_info_source_id === ingredient_association.food_ingredient.default_measurement_source_id);
    }

    if (filteredAssociations.length <= 0 || filteredMeasurementAssociations.length <= 0) {
      return (
        <p className="food-prescription-data__alert-text">

          <i className="fas fa-exclamation food-prescription-data__alert-text__icon"></i>
          Cadastro de ingrediente incompleto

        </p>
      );
    }

    return (
      <HalfWrapper className="food-prescription-data__item-association__inputs-container">

        {filteredAssociations.length > 1 ? (
          <DefaultInput
            name={`${ingredient_association.key}:food_ingredient_source_association_id`}
            isHighlighted={!ingredient_association.food_ingredient_source_association}
            label="Processamento:"
            type="select"
            handleInputChange={(event) => this.props.onSelectIngredientSourceAssociation(ingredient_association, parseInt(event.target.value))}
            value={ingredient_association.food_ingredient_source_association ? ingredient_association.food_ingredient_source_association.id : ''}
            options={this.getProcessingMethodOptions(ingredient_association)}
            disabled={!this.mayChangeInputs()}
          />
        ) : this.state.screenWidth >= 680 ? (<DummyInput />) : null}

        {ingredient_association.food_ingredient_source_association !== null &&
          <React.Fragment>

            <DefaultInput
              name={`${ingredient_association.key}:quantity`}
              isHighlighted={!ingredient_association.quantity}
              label="Quantidade"
              type="number"
              placeholder="-"
              step="0.01"
              min="0.00"
              handleInputChange={(event) => this.props.onUpdateIngredientQuantity(ingredient_association, parseFloat(event.target.value) || null)}
              value={ingredient_association.quantity || ''}
              autoComplete="off"
              disabled={!this.mayChangeInputs()}
              onFocus={(event) => event.target.select()}
            />

            <DefaultInput
              name={`${ingredient_association.key}:food_ingredient_measurement_association_id`}
              isHighlighted={!ingredient_association.food_ingredient_measurement_association}
              label="Medida padrão:"
              type="select"
              handleInputChange={(event) => this.props.onSelectIngredientMeasurementAssociation(ingredient_association, parseInt(event.target.value))}
              value={ingredient_association.food_ingredient_measurement_association !== null ? ingredient_association.food_ingredient_measurement_association.id : ''}
              options={this.getMeasurementOptions(ingredient_association)}
              disabled={!this.mayChangeInputs()}
            />

          </React.Fragment>
        }

      </HalfWrapper>
    );
  }

  getPrescriptionItems(mealPeriod) {
    const filteredAssociations = this.props.prescriptionItemList.filter((association) => association.meal_period.id === mealPeriod.id);

    const weekdays = [
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
      'domingo',
    ];

    return filteredAssociations.map((association) => {
      const weekdayInputs = weekdays.map((weekday, index) => {
        const checked = association.weekdays & Math.pow(2, index);

        return (
          <div
            key={`${association.key}:weekday_enabled:${index}`}
            className={`food-prescription-data__weekday${!checked ? '--disabled': ''}`}
          >

            <p className="food-prescription-data__weekday__text">

              {weekday}

            </p>

            <button
              className="food-prescription-data__weekday__select-button"
              onClick={() => this.props.onUpdateItemAssociationWeekday(association, index)}
              disabled={!this.mayChangeInputs()}
            >

              {(checked > 0) &&
                <i className="fas fa-check"></i>
              }

            </button>

          </div>
        );
      });

      const itemName = association.isRecipe ? association.food_recipe.name : association.food_ingredient.name;

      return (
        <div
          className="food-prescription-data__item-association"
          key={association.key}
        >

          <p
            className="food-prescription-data__item-association__detail"
          >

            {association.isRecipe ? (
              <i className="fas fa-utensils"></i>
            ) : (
              <i className="fas fa-carrot"></i>
            )}
            {/* <i className="fas fa-clipboard-list"></i> */}

          </p>

          <div className="food-prescription-data__item-association__content-wrapper">

            <DefaultInput
              className="food-prescription-data__item-association__content"
              labelClassName="food-prescription-data__item-association__name-label"
              name={`${association.key}:name`}
              isHighlighted={association.name !== null && association.name.length <= 0}
              type="text"
              placeholder="Nome vísivel ao aluno"
              maxLength="128"
              handleInputChange={(event) => this.props.onUpdateIngredientName(association, event.target.value)}
              labelMessage={(association.name !== null && association.name !== itemName) ? itemName : null}
              value={association.name !== null ? association.name : itemName}
              autoComplete="off"
              disabled={!this.mayChangeInputs()}
            />

            <p className="food-prescription-data__item-association__priority-text">
              <strong>{`Opção ${association.priority}`}</strong>
            </p>

            {association.loaded && this.getPrescriptionAssociationContent(association)}

            {association.loaded &&
              <div className="food-prescription-data__item-association__weekdays">

                {weekdayInputs}

              </div>
            }

          </div>

          {!association.loaded && (
            <LoadingIcon />
          )}

          {(association.loaded && this.mayChangeInputs()) &&
            <React.Fragment>

              {filteredAssociations.length > 1 &&
                <div className="food-recipe-data__switch-order-button-container">

                  <button
                    className={`food-prescription-data__switch-order-button${(association.priority > 1) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchPriority(association, -1)}
                  >

                    <i className="fas fa-chevron-up"></i>

                  </button>

                  <button
                    className={`food-prescription-data__switch-order-button${(association.priority < filteredAssociations.length) ? '' : '--hidden'}`}
                    onClick={() => this.props.onSwitchPriority(association, 1)}
                  >

                    <i className="fas fa-chevron-down"></i>

                  </button>

                </div>
              }

              <button
                className="food-prescription-data__delete-recipe-association-button"
                onClick={() => this.props.onRemovePrescriptionItem(association)}
              >

                <i className="far fa-trash-alt"></i>

              </button>

            </React.Fragment>
          }

        </div>
      );
    });
  }

  getPeriodNutritionalDataSection(mealPeriod) {
    const filteredAssociations = this.props.prescriptionItemList.filter((association) => association.meal_period.id === mealPeriod.id);

    if (filteredAssociations.length <= 0 || 
        filteredAssociations.some((entry) => {
      if (!entry.isRecipe) {
        return (entry.quantity === null || entry.quantity <= 0) ||
               entry.food_ingredient_source_association === null ||
               entry.food_ingredient_measurement_association === null ||
               entry.weekdays <= 0 ||
               (entry.name !== null && entry.name.length <= 0);
      }

      return entry.weekdays <= 0 || (entry.name !== null && entry.name.length <= 0);
    })) {
      return null;
    }

    return (
      <section className="food-prescription-data__nutrition-info">

        <header
          className="food-prescription-data__nutrition-info__header"
          onClick={() => {
            const periodNutritionInfoVisibilityMap = new Set(this.state.periodNutritionInfoVisibilityMap);

            if (periodNutritionInfoVisibilityMap.has(mealPeriod.id)) {
              periodNutritionInfoVisibilityMap.delete(mealPeriod.id);
            }
            else {
              periodNutritionInfoVisibilityMap.add(mealPeriod.id);
            }

            this.setState({periodNutritionInfoVisibilityMap})
          }}
        >

          <h3 className="food-prescription-data__nutrition-info__header__text">
            <i className="fas fa-info food-prescription-data__nutrition-info__header__text-icon"></i>
            Resumo: {mealPeriod.name}
          </h3>

          {this.state.periodNutritionInfoVisibilityMap.has(mealPeriod.id) ?
            <i className="fas fa-chevron-down food-prescription-data__nutrition-info__header__visible-icon"></i>:
            <i className="fas fa-chevron-up food-prescription-data__nutrition-info__header__visible-icon"></i>
          }

        </header>

        <VerticalAccordionContainer
          className="vertical-accordion-container food-prescription-data__nutrition-info__content"
          pose={this.state.periodNutritionInfoVisibilityMap.has(mealPeriod.id) ? 'verticalOpen' : 'verticalClosed'}>

          <div className="vertical-accordion-container food-prescription-data__nutrition-info__content-wrapper">

            {this.getPeriodNutritionalData(mealPeriod)}

          </div>

        </VerticalAccordionContainer>

      </section>
    );
  }

  getMealPeriods() {
    const filteredMealPeriods = this.props.meal_periods.filter((entry) => this.props.includedMealPeriodIds.has(entry.id));

    return filteredMealPeriods.map((period) => {
      let timePeriod = '';

      if (period.start_time !== null && period.end_time !== null) {
        timePeriod = ` (${period.start_time} - ${period.end_time})`;
      }
      else if (period.start_time !== null && period.end_time === null) {
        timePeriod = ` (a partir de ${period.start_time})`;
      }
      else if (period.start_time === null && period.end_time !== null) {
        timePeriod = ` (até ${period.end_time})`;
      }

      return (
        <React.Fragment key={`meal_period:${period.id}`}>

          <DefaultSubSectionTitle
            icon={<i className="fas fa-clock"></i>}
            text={`${period.name}${timePeriod}`}
          />

          <DefaultInput
            key={`meal_period:${period.id}:note`}
            name={`meal_period_note:${period.id}`}
            label="Observação do período"
            type="textarea"
            placeholder="Nota destinada ao aluno sobre o período de alimentação"
            rows="5"
            handleInputChange={this.props.handleInputChange}
            value={this.props.food_prescription.meal_period_note_map[period.id] || ''}
            disabled={!this.mayChangeInputs()}
          />

          <div className="food-prescription-data__meal-period-container">

            {this.getPrescriptionItems(period)}

            {this.mayChangeInputs() &&
              <button
                className="food-prescription-data__add-food-recipe-button"
                onClick={() => this.setState({selectedMealPeriod: period})}
              >

                <i className="fas fa-plus food-prescription-data__add-food-recipe-button__icon"></i>
                Adicionar item

              </button>
            }

          </div>

          {this.getPeriodNutritionalDataSection(period)}

          <HorizontalRule />

        </React.Fragment>
      );
    });
  }

  getMealClassificationText(entry) {
    return entry.meal_classification !== null ? entry.meal_classification.name : '';
  }

  getFoodClassificationText(entry) {
    return entry.food_classification.name;
  }

  getRecipeProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Receita', <i className="fas fa-utensils"></i>)
    );
    properties.push(
      Property(
        'meal_classification_id',
        'Classificação',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getMealClassificationText, getFilterText: this.getMealClassificationText})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  getIngredientProperties() {
    let properties = [];

    properties.push(
      Property('name', 'Ingrediente', <i className="fas fa-carrot"></i>)
    );
    properties.push(
      Property(
        'food_classification_id',
        'Grupo',
        <i className="fas fa-tag"></i>,
        {getDataText: this.getFoodClassificationText, applyFilter: false})
    );

    properties = [
      ...properties
    ];

    return properties;
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getBasicIngredientsFromRecipe(recipe) {
    return [
      ...recipe.basic_ingredient_associations.map((entry) => {
        return {
          ...entry
        };
      }),
      ...recipe.advanced_ingredient_associations.map((entry) => this.getBasicIngredientsFromRecipe(entry)).flat()
    ];
  }

  getPeriodNutritionalData(mealPeriod) {
    if (!this.state.periodNutritionInfoVisibilityMap.has(mealPeriod.id)) {
      return null;
    }

    const filteredAssociations = this.props.prescriptionItemList.filter((association) => association.meal_period.id === mealPeriod.id);

    if (filteredAssociations.some((entry) => !entry.loaded)) {
      return (<LoadingIcon />);
    }

    const prescriptionCopy = {...this.props.food_prescription};
    prescriptionCopy.food_recipe_associations = [];
    prescriptionCopy.food_ingredient_associations = [];

    const energyPeriodData = [];
    const carbohydratePeriodData = [];
    const proteinPeriodData = [];
    const fatPeriodData = [];

    // const carbohydrateWeightPeriodData = [];
    // const proteinWeightPeriodData = [];
    // const fatWeightPeriodData = [];

    for (const entry of filteredAssociations) {
      if (entry.isRecipe) {
        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_recipe: entry.food_recipe,
          recipe_adjustments: entry.recipe_adjustments.map((recipe_adjustment) => {
            return {
              ...recipe_adjustment
            };
          }),
          weekdays: entry.weekdays
        };

        prescriptionCopy.food_recipe_associations.push(associationData);
      }
      else {
        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_ingredient_source_association: entry.food_ingredient_source_association,
          food_ingredient_measurement_association: entry.food_ingredient_measurement_association,
          quantity: entry.quantity,
          weekdays: entry.weekdays
        };

        prescriptionCopy.food_ingredient_associations.push(associationData);
      }

      let entryNutritionalData;

      if (entry.isRecipe) {
        entryNutritionalData = getFoodRecipeNutricionalData(entry.food_recipe, entry.recipe_adjustments);
      }
      else {
        entryNutritionalData = getFoodRecipeNutricionalData({
          basic_ingredient_associations: [entry],
          advanced_ingredient_associations: [],
          people_served: 1
        });
      }
      
      const itemName = entry.isRecipe ? entry.food_recipe.name : entry.food_ingredient.name;
      let optionName = entry.name !== null ? entry.name : itemName; // = `Opção ${entry.priority}`;

      energyPeriodData.push(StackPoint(Math.round(100 * entryNutritionalData.totalEnergy) / 100, optionName, Math.round(entryNutritionalData.totalEnergy * 10) / 10));

      carbohydratePeriodData.push(StackPoint(Math.round(100 * CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.carbohydrateWeight) / 100, optionName, Math.round(CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.carbohydrateWeight * 10) / 10));
      proteinPeriodData.push(StackPoint(Math.round(100 * PROTEIN_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.proteinWeight) / 100, optionName, Math.round(PROTEIN_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.proteinWeight * 10) / 10));
      fatPeriodData.push(StackPoint(Math.round(100 * FAT_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.fatWeight) / 100, optionName, Math.round(FAT_TO_KCAL_CONVERSION_CONTANT * entryNutritionalData.fatWeight * 10) / 10));

      // carbohydrateWeightPeriodData.push(StackPoint(Math.round(10 * entryNutritionalData.carbohydrateWeight) / 10, optionName, Math.round(entryNutritionalData.carbohydrateWeight * 10) / 10));
      // proteinWeightPeriodData.push(StackPoint(Math.round(10 * entryNutritionalData.proteinWeight) / 10, optionName, Math.round(entryNutritionalData.proteinWeight * 10) / 10));
      // fatWeightPeriodData.push(StackPoint(Math.round(10 * entryNutritionalData.fatWeight) / 10, optionName, Math.round(entryNutritionalData.fatWeight * 10) / 10));
    }

    const nutritionalData = getFoodPrescriptionNutritionalData(prescriptionCopy);

    if (nutritionalData === null) {
      return 'Incompatibilidade de unidades';
    }

    const carbohydrateOverviewValue = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalCarbohydrate.mean;
    const proteinOverviewValue = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalProtein.mean;
    const fatOverviewValue = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalFat.mean;

    const totalMean = carbohydrateOverviewValue + proteinOverviewValue + fatOverviewValue;

    const carbohydrateOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalCarbohydrate.deviation;
    const proteinOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalProtein.deviation;
    const fatOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalFat.deviation;

    const overviewData = [
      StackGroup('Planejado', [
        StackPoint(Math.round(100 * 100 * (carbohydrateOverviewValue / totalMean)) / 100, 'Carboidrato', Math.round(100 * (carbohydrateOverviewValue / totalMean) * 10) / 10),
        StackPoint(Math.round(100 * 100 * (proteinOverviewValue / totalMean)) / 100, 'Proteína', Math.round(100 * (proteinOverviewValue / totalMean) * 10) / 10, null, '#da5858'),
        StackPoint(Math.round(100 * 100 * (fatOverviewValue / totalMean)) / 100, 'Gordura', Math.round(100 * (fatOverviewValue / totalMean) * 10) / 10, null, '#daac58'),
      ], '#5899da'),
      StackGroup('Desejado', [
        StackPoint(nutritionalData.target_carbohydrate_percentage, 'Carboidrato', nutritionalData.target_carbohydrate_percentage),
        StackPoint(nutritionalData.target_protein_percentage, 'Proteína', nutritionalData.target_protein_percentage),
        StackPoint(nutritionalData.target_fat_percentage, 'Gordura', nutritionalData.target_fat_percentage),
      ], '#9a9a9a')
    ];

    const overviewErrorData = [
      new ErrorPoint([100 * (carbohydrateOverviewValue - carbohydrateOverviewStandardDeviation) / totalMean, 100 * (carbohydrateOverviewValue + carbohydrateOverviewStandardDeviation) / totalMean], 'Carboidrato'),
      new ErrorPoint([100 * (proteinOverviewValue - proteinOverviewStandardDeviation) / totalMean, 100 * (proteinOverviewValue + proteinOverviewStandardDeviation) / totalMean], 'Proteína'),
      new ErrorPoint([100 * (fatOverviewValue - fatOverviewStandardDeviation) / totalMean, 100 * (fatOverviewValue + fatOverviewStandardDeviation) / totalMean], 'Gordura'),
    ];

    const stackedOptionsData = [
      StackGroup('Carboidrato', carbohydratePeriodData, '#5899da'),
      StackGroup('Proteína', proteinPeriodData, '#da5858'),
      StackGroup('Gordura', fatPeriodData, '#daac58'),
    ];

    const stackedEnergyOptionsData = [
      StackGroup('Energia', energyPeriodData, '#58da6e')
    ];

    // const stackedWeightOptionsData = [
    //   StackGroup('Carboidrato', carbohydrateWeightPeriodData, '#5899da'),
    //   StackGroup('Proteína', proteinWeightPeriodData, '#da5858'),
    //   StackGroup('Gordura', fatWeightPeriodData, '#daac58'),
    // ];
    
    const content = [];

    content.push(
      <React.Fragment key={`nutrition_info:period:${mealPeriod.id}`}>

        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média geral diária"
        />

        <StackedBarGraph
          data={overviewData}
          errorData={overviewErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}%`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <HorizontalRule />

        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Comparativo de refeições"
        />

        <StackedBarGraph
          data={stackedEnergyOptionsData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <StackedBarGraph
          data={stackedOptionsData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        {/* <StackedBarGraph
          data={stackedWeightOptionsData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        /> */}

      </React.Fragment>
    );

    return content;
  }

  getNutritionalData() {
    if (!this.state.nutritionInfoVisible) {
      return null;
    }

    if (this.props.prescriptionItemList.some((entry) => !entry.loaded)) {
      return (<LoadingIcon />);
    }

    const nutritionalData = this.getPresctiptionNutritionalData();

    if (nutritionalData === null) {
      return 'Incompatibilidade de unidades';
    }

    const carbohydrateOverviewValue = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalCarbohydrate.mean;
    const proteinOverviewValue = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalProtein.mean;
    const fatOverviewValue = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalFat.mean;

    const totalMean = carbohydrateOverviewValue + proteinOverviewValue + fatOverviewValue;

    const carbohydrateOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalCarbohydrate.deviation;
    const proteinOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalProtein.deviation;
    const fatOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.totalFat.deviation;

    const weightOverviewData = [
      StackGroup('Planejado', [
        StackPoint(Math.round(100 * nutritionalData.totalCarbohydrate.mean) / 100, 'Carboidrato', Math.round(nutritionalData.totalCarbohydrate.mean * 10) / 10),
        StackPoint(Math.round(100 * nutritionalData.totalProtein.mean) / 100, 'Proteína', Math.round(nutritionalData.totalProtein.mean * 10) / 10, null, '#da5858'),
        StackPoint(Math.round(100 * nutritionalData.totalFat.mean) / 100, 'Gordura', Math.round(nutritionalData.totalFat.mean * 10) / 10, null, '#daac58'),
      ], '#5899da')
    ];

    if (((typeof this.props.food_prescription.target_weight === 'string' && this.props.food_prescription.target_weight.length > 0) || this.props.food_prescription.target_weight)) {
      const targetWeight = parseFloat(this.props.food_prescription.target_weight);
      
      weightOverviewData.push(
        StackGroup('Desejado', [
          StackPoint(Math.round(100 * targetWeight * nutritionalData.target_carbohydrate_intake) / 100, 'Carboidrato', Math.round(100 * targetWeight * nutritionalData.target_carbohydrate_intake) / 100),
          StackPoint(Math.round(100 * targetWeight * nutritionalData.target_protein_intake) / 100, 'Proteína', Math.round(100 * targetWeight * nutritionalData.target_protein_intake) / 100),
          StackPoint(Math.round(100 * targetWeight * nutritionalData.target_fat_intake) / 100, 'Gordura', Math.round(100 * targetWeight * nutritionalData.target_fat_intake) / 100),
        ], '#9a9a9a')
      );
    }

    const weigthOverviewErrorData = [
      new ErrorPoint([nutritionalData.totalCarbohydrate.mean - nutritionalData.totalCarbohydrate.deviation, nutritionalData.totalCarbohydrate.mean + nutritionalData.totalCarbohydrate.deviation], 'Carboidrato'),
      new ErrorPoint([nutritionalData.totalProtein.mean - nutritionalData.totalProtein.deviation, nutritionalData.totalProtein.mean + nutritionalData.totalProtein.deviation], 'Proteína'),
      new ErrorPoint([nutritionalData.totalFat.mean - nutritionalData.totalFat.deviation, nutritionalData.totalFat.mean + nutritionalData.totalFat.deviation], 'Gordura'),
    ];

    const overviewData = [
      StackGroup('Planejado', [
        StackPoint(Math.round(100 * 100 * (carbohydrateOverviewValue / totalMean)) / 100, 'Carboidrato', Math.round(100 * (carbohydrateOverviewValue / totalMean) * 10) / 10),
        StackPoint(Math.round(100 * 100 * (proteinOverviewValue / totalMean)) / 100, 'Proteína', Math.round(100 * (proteinOverviewValue / totalMean) * 10) / 10, null, '#da5858'),
        StackPoint(Math.round(100 * 100 * (fatOverviewValue / totalMean)) / 100, 'Gordura', Math.round(100 * (fatOverviewValue / totalMean) * 10) / 10, null, '#daac58'),
      ], '#5899da'),
      StackGroup('Desejado', [
        StackPoint(nutritionalData.target_carbohydrate_percentage, 'Carboidrato', nutritionalData.target_carbohydrate_percentage),
        StackPoint(nutritionalData.target_protein_percentage, 'Proteína', nutritionalData.target_protein_percentage),
        StackPoint(nutritionalData.target_fat_percentage, 'Gordura', nutritionalData.target_fat_percentage),
      ], '#9a9a9a')
    ];

    const overviewErrorData = [
      new ErrorPoint([100 * (carbohydrateOverviewValue - carbohydrateOverviewStandardDeviation) / totalMean, 100 * (carbohydrateOverviewValue + carbohydrateOverviewStandardDeviation) / totalMean], 'Carboidrato'),
      new ErrorPoint([100 * (proteinOverviewValue - proteinOverviewStandardDeviation) / totalMean, 100 * (proteinOverviewValue + proteinOverviewStandardDeviation) / totalMean], 'Proteína'),
      new ErrorPoint([100 * (fatOverviewValue - fatOverviewStandardDeviation) / totalMean, 100 * (fatOverviewValue + fatOverviewStandardDeviation) / totalMean], 'Gordura'),
    ];

    const energyDailyPoints = [];
    const energyDailyErrors = [];

    const carbohydrateDailyPoints = [];
    const carbohydrateDailyErrors = [];

    const proteinDailyPoints = [];
    const proteinDailyErrors = [];

    const fatDailyPoints = [];
    const fatDailyErrors = [];

    const fiberDailyPoints = [];
    const fiberDailyErrors = [];

    for (let i=0; i < 7; ++i) {
      energyDailyPoints.push(StackPoint(Math.round(100 * nutritionalData.energyStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(nutritionalData.energyStatistic.dailyMeans[i] * 10) / 10));
      energyDailyErrors.push(new ErrorPoint([nutritionalData.energyStatistic.dailyMeans[i] - nutritionalData.energyStatistic.dailyStandardDeviations[i], nutritionalData.energyStatistic.dailyMeans[i] + nutritionalData.energyStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));

      if (((typeof this.props.food_prescription.target_weight === 'string' && this.props.food_prescription.target_weight.length > 0) || this.props.food_prescription.target_weight)) {
        carbohydrateDailyPoints.push(StackPoint(Math.round(100 * nutritionalData.carbohydrateStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(nutritionalData.carbohydrateStatistic.dailyMeans[i] * 10) / 10));
        carbohydrateDailyErrors.push(new ErrorPoint([nutritionalData.carbohydrateStatistic.dailyMeans[i] - nutritionalData.carbohydrateStatistic.dailyStandardDeviations[i], nutritionalData.carbohydrateStatistic.dailyMeans[i] + nutritionalData.carbohydrateStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
  
        proteinDailyPoints.push(StackPoint(Math.round(100 * nutritionalData.proteinStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(nutritionalData.proteinStatistic.dailyMeans[i] * 10) / 10));
        proteinDailyErrors.push(new ErrorPoint([nutritionalData.proteinStatistic.dailyMeans[i] - nutritionalData.proteinStatistic.dailyStandardDeviations[i], nutritionalData.proteinStatistic.dailyMeans[i] + nutritionalData.proteinStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
  
        fatDailyPoints.push(StackPoint(Math.round(100 * nutritionalData.fatStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(nutritionalData.fatStatistic.dailyMeans[i] * 10) / 10));
        fatDailyErrors.push(new ErrorPoint([nutritionalData.fatStatistic.dailyMeans[i] - nutritionalData.fatStatistic.dailyStandardDeviations[i], nutritionalData.fatStatistic.dailyMeans[i] + nutritionalData.fatStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
      }
      else {
        const dailyCarbohydrateMean = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.carbohydrateStatistic.dailyMeans[i];
        const dailyProteinMean = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.proteinStatistic.dailyMeans[i];
        const dailyFatMean = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.fatStatistic.dailyMeans[i];

        const dailyCarbohydrateStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * nutritionalData.carbohydrateStatistic.dailyStandardDeviations[i];
        const dailyProteinStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * nutritionalData.proteinStatistic.dailyStandardDeviations[i];
        const dailyFatStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * nutritionalData.fatStatistic.dailyStandardDeviations[i];

        const totalDailyMean = dailyCarbohydrateMean + dailyProteinMean + dailyFatMean;

        carbohydrateDailyPoints.push(StackPoint(Math.round(100 * 100 * (dailyCarbohydrateMean / totalDailyMean)) / 100, WEEKDAY_NAMES[i], Math.round(100 * (dailyCarbohydrateMean / totalDailyMean) * 10) / 10));
        carbohydrateDailyErrors.push(new ErrorPoint([100 * (dailyCarbohydrateMean - dailyCarbohydrateStandardDeviation) / totalDailyMean, 100 * (dailyCarbohydrateMean + dailyCarbohydrateStandardDeviation) / totalDailyMean], WEEKDAY_NAMES[i]));

        proteinDailyPoints.push(StackPoint(Math.round(100 * 100 * (dailyProteinMean / totalDailyMean)) / 100, WEEKDAY_NAMES[i], Math.round(100 * (dailyProteinMean / totalDailyMean) * 10) / 10));
        proteinDailyErrors.push(new ErrorPoint([100 * (dailyProteinMean - dailyProteinStandardDeviation) / totalDailyMean, 100 * (dailyProteinMean + dailyProteinStandardDeviation) / totalDailyMean], WEEKDAY_NAMES[i]));

        fatDailyPoints.push(StackPoint(Math.round(100 * 100 * (dailyFatMean / totalDailyMean)) / 100, WEEKDAY_NAMES[i], Math.round(100 * (dailyFatMean / totalDailyMean) * 10) / 10));
        fatDailyErrors.push(new ErrorPoint([100 * (dailyFatMean - dailyFatStandardDeviation) / totalDailyMean, 100 * (dailyFatMean + dailyFatStandardDeviation) / totalDailyMean], WEEKDAY_NAMES[i]));
      }

      const fiberStatistic = nutritionalData.nutrients.find((nutrientEntry) => nutrientEntry.nutrient.id === NUTRIENT_FIBER_ID);

      if (fiberStatistic) {
        fiberDailyPoints.push(StackPoint(Math.round(100 * fiberStatistic.dailyMeans[i]) / 100, WEEKDAY_NAMES[i], Math.round(fiberStatistic.dailyMeans[i] * 10) / 10));
        fiberDailyErrors.push(new ErrorPoint([fiberStatistic.dailyMeans[i] - fiberStatistic.dailyStandardDeviations[i], fiberStatistic.dailyMeans[i] + fiberStatistic.dailyStandardDeviations[i]], WEEKDAY_NAMES[i]));
      }
    }

    const energyDailyData = [
      StackGroup('Planejado', energyDailyPoints, '#58da6e'),
    ];
    const carbohydrateDailyData = [
      StackGroup('Planejado', carbohydrateDailyPoints, '#5899da'),
    ];
    const proteinDailyData = [
      StackGroup('Planejado', proteinDailyPoints, '#da5858'),
    ];
    const fatDailyData = [
      StackGroup('Planejado', fatDailyPoints, '#daac58'),
    ];

    const carbohydrateStripLine = {
      value: nutritionalData.target_carbohydrate_percentage,
      label: 'Porcentagem desejada',
      showOnTop: true,
      // color: "#9a9a9a",
      // labelFontColor: "#9a9a9a",
    };
    const proteinStripLine = {
      value: nutritionalData.target_protein_percentage,
      label: 'Porcentagem desejada',
      showOnTop: true,
      // color: "#9a9a9a",
      // labelFontColor: "#9a9a9a",
    };
    const fatStripLine = {
      value: nutritionalData.target_fat_percentage,
      label: 'Porcentagem desejada',
      showOnTop: true,
      // color: "#9a9a9a",
      // labelFontColor: "#9a9a9a",
    };

    let carbohydrateToolTipValueCallback = (value) => `${value}%`;
    let proteinToolTipValueCallback = (value) => `${value}%`;
    let fatToolTipValueCallback = (value) => `${value}%`;

    if (((typeof this.props.food_prescription.target_weight === 'string' && this.props.food_prescription.target_weight.length > 0) || this.props.food_prescription.target_weight)) {
      const targetWeight = parseFloat(this.props.food_prescription.target_weight);
      
      carbohydrateStripLine.value = targetWeight * nutritionalData.target_carbohydrate_intake;
      carbohydrateStripLine.label = "Consumo desejado";
      proteinStripLine.value = targetWeight * nutritionalData.target_protein_intake;
      proteinStripLine.label = "Consumo desejado";
      fatStripLine.value = targetWeight * nutritionalData.target_fat_intake;
      fatStripLine.label = "Consumo desejado";

      carbohydrateToolTipValueCallback = (value) => `${value}${nutritionalData.nutrientUnitMap.get(NUTRIENT_CARBOHYDRATE_ID).shortname}`;
      proteinToolTipValueCallback = (value) => `${value}${nutritionalData.nutrientUnitMap.get(NUTRIENT_PROTEIN_ID).shortname}`;
      fatToolTipValueCallback = (value) => `${value}${nutritionalData.nutrientUnitMap.get(NUTRIENT_FAT_ID).shortname}`;
    }

    const energyPeriodData = [];
    const carbohydratePeriodData = [];
    const proteinPeriodData = [];
    const fatPeriodData = [];

    const carbohydrateWeightPeriodData = [];
    const proteinWeightPeriodData = [];
    const fatWeightPeriodData = [];

    const energyPeriodErrorData = [];
    const carbohydratePeriodErrorData = [];
    const proteinPeriodErrorData = [];
    const fatPeriodErrorData = [];

    // const periodGraphsContent = [];

    for (const period of this.props.meal_periods) {
      if (nutritionalData.mealPeriodMap.has(period.id)) {
        const mealPeriodEntry = nutritionalData.mealPeriodMap.get(period.id);

        energyPeriodData.push(StackPoint(Math.round(100 * mealPeriodEntry.energy.mean) / 100, period.name, Math.round(mealPeriodEntry.energy.mean * 10) / 10));
        carbohydratePeriodData.push(StackPoint(Math.round(100 * CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.carbohydrate.mean) / 100, period.name, Math.round(CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.carbohydrate.mean * 10) / 10));
        proteinPeriodData.push(StackPoint(Math.round(100 * PROTEIN_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.protein.mean) / 100, period.name, Math.round(PROTEIN_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.protein.mean * 10) / 10));
        fatPeriodData.push(StackPoint(Math.round(100 * FAT_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.fat.mean) / 100, period.name, Math.round(FAT_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.fat.mean * 10) / 10));

        carbohydrateWeightPeriodData.push(StackPoint(Math.round(10 * mealPeriodEntry.carbohydrate.mean) / 10, period.name, Math.round(mealPeriodEntry.carbohydrate.mean * 10) / 10));
        proteinWeightPeriodData.push(StackPoint(Math.round(10 * mealPeriodEntry.protein.mean) / 10, period.name, Math.round(mealPeriodEntry.protein.mean * 10) / 10));
        fatWeightPeriodData.push(StackPoint(Math.round(10 * mealPeriodEntry.fat.mean) / 10, period.name, Math.round(mealPeriodEntry.fat.mean * 10) / 10));

        energyPeriodErrorData.push(new ErrorPoint([(mealPeriodEntry.energy.mean - mealPeriodEntry.energy.deviation), (mealPeriodEntry.energy.mean + mealPeriodEntry.energy.deviation)], period.name));
        carbohydratePeriodErrorData.push(new ErrorPoint([CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.carbohydrate.mean - mealPeriodEntry.carbohydrate.deviation), CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.carbohydrate.mean + mealPeriodEntry.carbohydrate.deviation)], period.name));
        proteinPeriodErrorData.push(new ErrorPoint([PROTEIN_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.protein.mean - mealPeriodEntry.protein.deviation), PROTEIN_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.protein.mean + mealPeriodEntry.protein.deviation)], period.name));
        fatPeriodErrorData.push(new ErrorPoint([FAT_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.fat.mean - mealPeriodEntry.fat.deviation), FAT_TO_KCAL_CONVERSION_CONTANT * (mealPeriodEntry.fat.mean + mealPeriodEntry.fat.deviation)], period.name));

        // const carbohydratePeriodOverview = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.carbohydrate.mean;
        // const proteinPeriodOverview = PROTEIN_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.protein.mean;
        // const fatPeriodOverview = FAT_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.fat.mean;

        // const periodTotalMean = carbohydratePeriodOverview + proteinPeriodOverview + fatPeriodOverview;

        // const carbohydratePeriodOverviewStandardDeviation = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.carbohydrate.deviation;
        // const proteinPeriodOverviewStandardDeviation = PROTEIN_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.protein.deviation;
        // const fatPeriodOverviewStandardDeviation = FAT_TO_KCAL_CONVERSION_CONTANT * mealPeriodEntry.fat.deviation;

        // const overviewPeriodData = [
        //   StackGroup('Planejado', [
        //     StackPoint(Math.round(100 * 100 * (carbohydratePeriodOverview / periodTotalMean)) / 100, 'Carboidrato', Math.round(100 * (carbohydratePeriodOverview / periodTotalMean) * 10) / 10),
        //     StackPoint(Math.round(100 * 100 * (proteinPeriodOverview / periodTotalMean)) / 100, 'Proteína', Math.round(100 * (proteinPeriodOverview / periodTotalMean) * 10) / 10, null, '#da5858'),
        //     StackPoint(Math.round(100 * 100 * (fatPeriodOverview / periodTotalMean)) / 100, 'Gordura', Math.round(100 * (fatPeriodOverview / periodTotalMean) * 10) / 10, null, '#daac58'),
        //   ], '#5899da'),
        //   StackGroup('Desejado', [
        //     StackPoint(nutritionalData.target_carbohydrate_percentage, 'Carboidrato', nutritionalData.target_carbohydrate_percentage),
        //     StackPoint(nutritionalData.target_protein_percentage, 'Proteína', nutritionalData.target_protein_percentage),
        //     StackPoint(nutritionalData.target_fat_percentage, 'Gordura', nutritionalData.target_fat_percentage),
        //   ], '#9a9a9a')
        // ];

        // const overviewPeriodErrorData = [
        //   new ErrorPoint([100 * (carbohydratePeriodOverview - carbohydratePeriodOverviewStandardDeviation) / periodTotalMean, 100 * (carbohydratePeriodOverview + carbohydratePeriodOverviewStandardDeviation) / periodTotalMean], 'Carboidrato'),
        //   new ErrorPoint([100 * (proteinPeriodOverview - proteinPeriodOverviewStandardDeviation) / periodTotalMean, 100 * (proteinPeriodOverview + proteinPeriodOverviewStandardDeviation) / periodTotalMean], 'Proteína'),
        //   new ErrorPoint([100 * (fatPeriodOverview - fatPeriodOverviewStandardDeviation) / periodTotalMean, 100 * (fatPeriodOverview + fatPeriodOverviewStandardDeviation) / periodTotalMean], 'Gordura'),
        // ]

        // periodGraphsContent.push(
        //   <React.Fragment key={`meal_period:${period.id}:graphs`}>

        //     <DefaultSubSectionTitle
        //       className="food-prescription-data__sub-section-title"
        //       icon={<i className="fas fa-chart-bar"></i>}
        //       text={`Média geral diária - ${period.name}`}
        //     />

        //     <StackedBarGraph
        //       data={overviewPeriodData}
        //       errorData={overviewPeriodErrorData}
        //       lineYAxisType="secondary"
        //       doNotStack={true}
        //       height={this.getDefaultGraphHeight()}
        //       ToolTipValueCallback={(value) => `${value}%`}
        //       legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
        //       legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
        //       normalLegendOrder={true}
        //       normalXLabel={true}
        //     />

        //   </React.Fragment>
        // );
      }
    }

    // const periodData = [
    //   StackGroup('Energia', energyPeriodData, '#58da6e')
    // ];
    // const periodErrorData = [
    //   energyPeriodErrorData,
    //   // carbohydratePeriodErrorData,
    //   // proteinPeriodErrorData,
    //   // fatPeriodErrorData
    // ];

    const stackedPeriodData = [
      StackGroup('Carboidrato', carbohydratePeriodData, '#5899da'),
      StackGroup('Proteína', proteinPeriodData, '#da5858'),
      StackGroup('Gordura', fatPeriodData, '#daac58'),
    ];

    const stackedWeightPeriodData = [
      StackGroup('Carboidrato', carbohydrateWeightPeriodData, '#5899da'),
      StackGroup('Proteína', proteinWeightPeriodData, '#da5858'),
      StackGroup('Gordura', fatWeightPeriodData, '#daac58'),
    ];

    const energyValue = Math.round(nutritionalData.totalEnergy.mean * 100) / 100;
    const energyStandardDeviation = Math.round(nutritionalData.totalEnergy.deviation * 100) / 100;

    const content = [];

    content.push(
      <React.Fragment key={`nutrition_info:overview`}>

        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média geral diária"
        />

        <StackedBarGraph
          data={weightOverviewData}
          errorData={weigthOverviewErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <StackedBarGraph
          data={overviewData}
          errorData={overviewErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}%`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <HorizontalRule />

        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Distribuições diárias"
        />

        <StackedBarGraph
          title="Energia"
          data={energyDailyData}
          errorData={energyDailyErrors}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={{
            value: nutritionalData.target_energy,
            label: 'Consumo desejada',
            showOnTop: true,
            // color: "#9a9a9a",
            // labelFontColor: "#9a9a9a",
          }}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Carboidrato"
          data={carbohydrateDailyData}
          errorData={carbohydrateDailyErrors}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={carbohydrateToolTipValueCallback}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={carbohydrateStripLine}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Proteína"
          data={proteinDailyData}
          errorData={proteinDailyErrors}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={proteinToolTipValueCallback}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={proteinStripLine}
          normalXLabel={true}
        />

        <StackedBarGraph
          title="Gordura"
          data={fatDailyData}
          errorData={fatDailyErrors}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={fatToolTipValueCallback}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          stripLine={fatStripLine}
          normalXLabel={true}
        />

        {fiberDailyPoints.length > 0 &&
          <StackedBarGraph
            title="Fibra"
            data={[StackGroup('Planejado', fiberDailyPoints, '#a258da')]}
            errorData={fiberDailyErrors}
            lineYAxisType="secondary"
            doNotStack={true}
            height={this.getDefaultGraphHeight()}
            ToolTipValueCallback={(value) => `${value}${nutritionalData.nutrientUnitMap.get(NUTRIENT_FIBER_ID).shortname}`}
            legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
            legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
            normalLegendOrder={true}
            normalXLabel={true}
          />
        }


        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média diária por períodos"
        />

        {/* <StackedBarGraph
          data={periodData}
          improvedErrorData={periodErrorData}
          lineYAxisType="secondary"
          doNotStack={true}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        /> */}

        <StackedBarGraph
          data={stackedPeriodData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value} kcal`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        <StackedBarGraph
          data={stackedWeightPeriodData}
          lineYAxisType="secondary"
          doNotStack={false}
          height={this.getDefaultGraphHeight()}
          ToolTipValueCallback={(value) => `${value}g`}
          legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
          legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
          normalLegendOrder={true}
          normalXLabel={true}
        />

        {/* {periodGraphsContent} */}

        <HorizontalRule />

        <DefaultSubSectionTitle
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-chart-bar"></i>}
          text="Média diária na semana"
        />

      </React.Fragment>
    );

    content.push(
      <DefaultInput
        key={`nutrition_info:energy`}
        labelClassName="food-prescription-data__nutrition-info__nutrient-label"
        name="energy"
        label="Energia:"
        type="text"
        placeholder="-"
        // step="0.01"
        // min="0.00"
        value={`${energyValue} ± ${energyStandardDeviation}`}
        autoComplete="off"
        suffix={`${nutritionalData.energyUnit.shortname} / dia`}
        isHorizontal={this.state.screenWidth > 360}
        disabled={true}
      />
    );

    for(const entry of nutritionalData.nutrientClassifications) {
      content.push(
        <DefaultSubSectionTitle
          key={`nutrition_info:nutriet_classification:${entry.classification.id}:title`}
          className="food-prescription-data__nutrition-info__nutrient-classification"
          icon={<i className="fas fa-circle"></i>}
          text={entry.classification.name}
        />
      );

      for (const statistic of entry.nutrientStatistics) {
        const nutrientKey = `nutrition_info:nutrient:${statistic.nutrient.id}`;

        const nutrientValue = Math.round(statistic.totalMean * 100) / 100;
        const nutrientStandardDeviation = Math.round(statistic.totalMeanDeviation * 100) / 100;

        content.push(
          <DefaultInput
            key={nutrientKey}
            labelClassName="food-prescription-data__nutrition-info__nutrient-label"
            name={nutrientKey}
            label={`${statistic.nutrient.fullname}:`}
            type="text"
            placeholder="-"
            // step="0.01"
            // min="0.00"
            value={`${nutrientValue} ± ${nutrientStandardDeviation}`}
            autoComplete="off"
            suffix={`${nutritionalData.nutrientUnitMap.get(statistic.nutrient.id).shortname} / dia`}
            isHorizontal={this.state.screenWidth > 360}
            disabled={true}
          />
        );
      }
    }

    return content;
  }

  getMealClassificationFilterOptions() {
    return [
      SelectOption('', 'Todas'),
      ...this.props.meal_classifications.map((entry) => SelectOption(entry.id, entry.name))
    ];
  }

  getNodeOptions(node, filterCallback=null) {
    const options = [];

    if(!filterCallback || filterCallback(node)) {
      options.push(SelectOption(node.id, `${'•'.repeat(node.depth)} ${node.name}`));
    }

    node.children.sort((a, b) => a.name.localeCompare(b.name));

    for(let child of node.children) {
      if(!filterCallback || filterCallback(child)) {
        options.push(...this.getNodeOptions(child));
      }
    }

    return options;
  }

  getFoodClassificationOptions() {
    let food_classifications = this.props.food_classifications.filter((entry) => entry.depth === 0);

    food_classifications.sort((a, b) => a.name.localeCompare(b.name));

    food_classifications = food_classifications.flatMap((node) => this.getNodeOptions(node));

    return [
      SelectOption('', 'Todos'),
      ...food_classifications
    ];
  }

  getRecipeActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="food-prescription-data__add-item-button"
          onClick={() => this.props.addRecipeAssociation(entry, this.state.selectedMealPeriod)}
        >

          <i className="fas fa-plus food-prescription-data__add-item-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Adicionar' : ''}

        </button>

      </div>
    );
  }

  getIngredientActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="food-prescription-data__add-item-button"
          onClick={() => this.props.addIngredientAssociation(entry, this.state.selectedMealPeriod)}
        >

          <i className="fas fa-plus food-prescription-data__add-item-button__icon"></i>
          {this.state.screenWidth > 510 ? 'Adicionar' : ''}

        </button>

      </div>
    );
  }

  getRecipes() {
    let filteredRecipes = this.props.food_recipes;

    if(this.state.mealClassificationFilter.length > 0) {
      filteredRecipes = filteredRecipes.filter((entry) => entry.meal_classification_id === parseInt(this.state.mealClassificationFilter));
    }

    return filteredRecipes;
  }

  getIngredients() {
    let filteredIngredients = this.props.food_ingredients;

    if(this.state.foodClassificationFilter) {
      filteredIngredients = filteredIngredients.filter((entry) => entry.food_classification_id === parseInt(this.state.foodClassificationFilter));
    }

    return filteredIngredients;
  }

  getItemTabContent() {
    switch (this.state.selectedItemTab) {
      case 1:
        return (
          <React.Fragment>

            <DefaultInput
              name="mealClassificationFilter"
              label="Filtrar classificação"
              type="select"
              value={this.state.mealClassificationFilter}
              handleInputChange={(event) => this.setState({mealClassificationFilter: event.target.value})}
              options={this.getMealClassificationFilterOptions()}
            />

            <HorizontalRule />

            <ModelTable
              key="recipe_list"
              storageKey="recipe_list"
              properties={this.getRecipeProperties()}
              getActions={(entry) => this.getRecipeActions(entry)}
              data={this.getRecipes()}
              initialOrderBy="name"
            >
            </ModelTable>

          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>

            <DefaultInput
              name="foodClassificationFilter"
              label="Filtrar grupo"
              type="select"
              value={this.state.foodClassificationFilter}
              handleInputChange={(event) => this.setState({foodClassificationFilter: event.target.value})}
              options={this.getFoodClassificationOptions()}
            />

            <HorizontalRule />

            <ModelTable
              key="ingredient_list"
              storageKey="ingredient_list"
              properties={this.getIngredientProperties()}
              getActions={(entry) => this.getIngredientActions(entry)}
              data={this.getIngredients()}
              initialOrderBy="name"
              restrictiveFilter={true}
            >

            </ModelTable>

          </React.Fragment>
        );
      default:
        return null;
    }
  }

  getMealPeriodOptions() {
    const filteredMealPeriods = this.props.meal_periods.filter((entry) => !this.props.includedMealPeriodIds.has(entry.id));

    return [
      SelectOption('', 'Selecione um período de refeição'),
      ...filteredMealPeriods.map((meal_period) => {
        let timePeriod = '';

        if (meal_period.start_time !== null && meal_period.end_time !== null) {
          timePeriod = ` (${meal_period.start_time} - ${meal_period.end_time})`;
        }
        else if (meal_period.start_time !== null && meal_period.end_time === null) {
          timePeriod = ` (a partir de ${meal_period.start_time})`;
        }
        else if (meal_period.start_time === null && meal_period.end_time !== null) {
          timePeriod = ` (até ${meal_period.end_time})`;
        }

        return SelectOption(meal_period.id, `${meal_period.name}${timePeriod}`);
      })
    ];
  }

  getMacronutrientDistribution(association) {
    let carbohydrateWeight = 0;
    let proteinWeight = 0;
    let fatWeight = 0;
    let totalMean = 0;

    let recipeNutritionalData;

    if (association.isRecipe) {
      recipeNutritionalData = getFoodRecipeNutricionalData(association.food_recipe, association.recipe_adjustments);
    }
    else {
      recipeNutritionalData = getFoodRecipeNutricionalData({
        basic_ingredient_associations: [association],
        advanced_ingredient_associations: [],
        people_served: 1
      });
    }

    carbohydrateWeight = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.carbohydrateWeight;
    proteinWeight = PROTEIN_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.proteinWeight;
    fatWeight = FAT_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.fatWeight;

    totalMean = carbohydrateWeight + proteinWeight + fatWeight;

    if (totalMean <= 0) {
      return null;
    }

    const total_carbohydrate_percentage = carbohydrateWeight / totalMean;
    const total_protein_percentage = proteinWeight / totalMean;
    const total_fat_percentage = fatWeight / totalMean;

    return (
      <div className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__container">

        <div className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution">

          <div className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient--carb">

            <h4 className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__label">C</h4>
            <div
              className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_carbohydrate_percentage}em`}}
            >
            </div>
            <p className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_carbohydrate_percentage)}%</p>

          </div>

          <div className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient--protein">

            <h4 className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__label">P</h4>
            <div
              className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_protein_percentage}em`}}
            >
            </div>
            <p className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_protein_percentage)}%</p>

          </div>

          <div className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient--fat">

            <h4 className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__label">G</h4>
            <div
              className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_fat_percentage}em`}}
            >
            </div>
            <p className="food-prescription-data__diet-preview__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_fat_percentage)}%</p>

          </div>

        </div>

      </div>
    );
  }

  getActionButtons() {
    return (
      <div className="default-section__links-wrapper">

        <button
          className="default-section__default-link-button food-prescription-data__default-button"
          onClick={() => this.setState({previewIsVisible: true})}
        >

            <i className="fa-solid fa-eye default-section__default-link-button__icon"></i> Pré-visualização

        </button>

        <button
          className="default-section__default-link-button food-prescription-data__default-button"
          onClick={() => this.setState({caloryCalculatorVisible: true})}
        >

            <i className="fa-solid fa-calculator default-section__default-link-button__icon"></i> Calcular meta calórica

        </button>

      </div>
    );
  }

  getDietMealAssociationContent(association) {
    const mealName = association.isRecipe ? association.food_recipe.name : association.food_ingredient.name;
    const associationKey = `diet_preview:${association.key}`;

    // const weekdays = [
    //   'segunda',
    //   'terça',
    //   'quarta',
    //   'quinta',
    //   'sexta',
    //   'sábado',
    //   'domingo',
    // ];
    const weekdays = [
      'S',
      'T',
      'Q',
      'Q',
      'S',
      'S',
      'D',
    ];

    let convertedTodayWeekDay = this.todayWeekDay - 1;
    if (convertedTodayWeekDay < 0) {
      convertedTodayWeekDay = 6;
    }

    let mayEatToday = false;

    const weekdayInputs = weekdays.map((weekday, index) => {
      const checked = association.weekdays & Math.pow(2, index);

      if (index === convertedTodayWeekDay && checked) {
        mayEatToday = true;
      }

      return (
        <div
          key={`${associationKey}:weekday_enabled:${index}`}
          className={`food-prescription-data__diet-preview__meal-option__weekday${!checked ? '--disabled': index === convertedTodayWeekDay ? '--highlighted' : ''}`}
        >

          <p className="food-prescription-data__diet-preview__meal-option__weekday__text">

            {weekday}

          </p>

          <button
            className="food-prescription-data__diet-preview__meal-option__weekday__select-button"
            disabled={true}
          >

            {(checked > 0) &&
              <i className="fas fa-check"></i>
            }

          </button>

        </div>
      );
    });

    const ingredientList = [];

    if (association.isRecipe) {
      const ingredients = [
        ...association.food_recipe.advanced_ingredient_associations.map((entry) => {
          let adjustment = association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_advanced_ingredient_association_id === entry.id);

          if (!adjustment) {
            adjustment = entry;
          }

          const newEntry = {
            key: `${associationKey}:advanced_ingredient:${entry.id}`,
            order: entry.order,
            quantity: adjustment.quantity,
            isBasic: false,
            name: entry.name !== null ? entry.name : entry.advanced_ingredient.name
          };

          return newEntry;
        }),
        ...association.food_recipe.basic_ingredient_associations.map((entry) => {
          let adjustment = association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_basic_ingredient_association_id === entry.id);

          if (!adjustment) {
            adjustment = entry;
          }

          const newEntry = {
            key: `${associationKey}:basic_ingredient:${entry.id}`,
            food_ingredient_source_association: entry.food_ingredient_source_association,
            food_ingredient_measurement_association: adjustment.food_ingredient_measurement_association,
            order: entry.order,
            quantity: adjustment.quantity,
            isBasic: true,
            name: entry.name !== null ? entry.name : entry.food_ingredient_source_association.food_ingredient.name
          };

          return newEntry;
        })
      ];

      ingredients.sort((a, b) => a.order - b.order);

      for (const ingredient of ingredients) {
        let ingredientText = ingredient.name;
        let categoryText = 'Receita';

        let weightText;
        let weightValue;

        if (ingredient.isBasic) {
          categoryText = ingredient.food_ingredient_source_association.food_ingredient.food_classification.name;

          if (ingredient.food_ingredient_source_association.processing_method_id !== null) {
            ingredientText += ` - ${ingredient.food_ingredient_source_association.processing_method.name}`;
          }

          weightValue = Math.round(10 * ingredient.quantity * ingredient.food_ingredient_measurement_association.weight_reference) / 10;

          if (ingredient.food_ingredient_measurement_association.food_measurement_id !== FOOD_MEASUREMENT_GRAM_ID) {
            weightText = (
              <p className="food-prescription-data__diet-preview__meal-option__ingredient__quantity">
                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__label">Quantidade:</span>

                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                    {ingredient.quantity}
                  </span>
                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.food_measurement.name}
                  </span>

                </span>

                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__separator">
                  ou
                </span>

                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                    {weightValue}
                  </span>
                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.weight_reference_unit.shortname}
                  </span>

                </span>
              </p>
            );
          }
          else {
            weightText = (
              <p className="food-prescription-data__diet-preview__meal-option__ingredient__quantity">
                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__label">Quantidade:</span>

                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                    {weightValue}
                  </span>
                  <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.weight_reference_unit.shortname}
                  </span>

                </span>
              </p>
            );
          }
        }
        else {
          weightText = (
            <p className="food-prescription-data__diet-preview__meal-option__ingredient__quantity">
              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__label">Quantidade:</span>

              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                  {ingredient.quantity}
                </span>
                <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                  Receita
                </span>

              </span>
            </p>
          );
        }

        ingredientList.push(
          <li
            key={ingredient.key}
            className="food-prescription-data__diet-preview__meal-option__ingredient"
          >
            <div className="food-prescription-data__diet-preview__meal-option__ingredient__title-wrapper">

              <i className="fa-solid fa-circle food-prescription-data__diet-preview__meal-option__ingredient__bullet"></i>

              <div className="food-prescription-data__diet-preview__meal-option__ingredient__name-wrapper">

                <h4 className="food-prescription-data__diet-preview__meal-option__ingredient__name">{ingredientText}:</h4>
                <p className="food-prescription-data__diet-preview__meal-option__ingredient__category">{categoryText}</p>

              </div>

            </div>

            {weightText}

          </li>
        );
      }
    }
    else {
      let ingredientText = association.food_ingredient.name;

      if (association.food_ingredient_source_association.processing_method_id !== null) {
        ingredientText += ` - ${association.food_ingredient_source_association.processing_method.name}`;
      }

      let weightValue = Math.round(10 * association.quantity * association.food_ingredient_measurement_association.weight_reference) / 10;

      let weightText;

      if (association.food_ingredient_measurement_association.food_measurement_id !== FOOD_MEASUREMENT_GRAM_ID) {
        weightText = (
          <p className="food-prescription-data__diet-preview__meal-option__ingredient__quantity">
            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__label">Quantidade:</span>

            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                {association.quantity}
              </span>
              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.food_measurement.name}
              </span>

            </span>

            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__separator">
              ou
            </span>

            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                {weightValue}
              </span>
              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.weight_reference_unit.shortname}
              </span>

            </span>
          </p>
        );
      }
      else {
        weightText = (
          <p className="food-prescription-data__diet-preview__meal-option__ingredient__quantity">
            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__label">Quantidade:</span>

            <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__number">
                {weightValue}
              </span>
              <span className="food-prescription-data__diet-preview__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.weight_reference_unit.shortname}
              </span>

            </span>
          </p>
        );
      }

      ingredientList.push(
        <li
          key={`${associationKey}:ingredient_description`}
          className="food-prescription-data__diet-preview__meal-option__ingredient"
        >
          <div className="food-prescription-data__diet-preview__meal-option__ingredient__title-wrapper">

            <i className="fa-solid fa-circle food-prescription-data__diet-preview__meal-option__ingredient__bullet"></i>

            <div className="food-prescription-data__diet-preview__meal-option__ingredient__name-wrapper">

              <h4 className="food-prescription-data__diet-preview__meal-option__ingredient__name">{ingredientText}:</h4>
              <p className="food-prescription-data__diet-preview__meal-option__ingredient__category">{association.food_ingredient.food_classification.name}</p>

            </div>

          </div>

          {weightText}

        </li>
      );
    }

    return (
      <section
        className="food-prescription-data__diet-preview__meal-option"
        key={associationKey}
      >

        <header
          className={`food-prescription-data__diet-preview__meal-option__header${mayEatToday ? '--highlighted' : ''}`}
          onClick={() => {
            let mealAssociationPreviewVisibilityMap = {...this.state.mealAssociationPreviewVisibilityMap};

            if (this.state.mealAssociationPreviewVisibilityMap[associationKey] !== true) {
              mealAssociationPreviewVisibilityMap[associationKey] = true;
            }
            else {
              mealAssociationPreviewVisibilityMap[associationKey] = false;
            }

            this.setState({mealAssociationPreviewVisibilityMap});
          }}
        >

          <div className="food-prescription-data__diet-preview__meal-option__header__wrapper">

            <p className="food-prescription-data__diet-preview__meal-option__header__text-icon">{association.priority}</p>

            <div className="food-prescription-data__diet-preview__meal-option__header__content-wrapper">

              <h3 className="food-prescription-data__diet-preview__meal-option__header__text">
                {association.name !== null ? association.name : mealName}
              </h3>

              <p className="food-prescription-data__diet-preview__meal-option__sub-title">Consumir nos dias:</p>

              <div className="food-prescription-data__diet-preview__meal-option__weekdays">

                {weekdayInputs}

              </div>

            </div>

          </div>

          {this.state.mealAssociationPreviewVisibilityMap[associationKey] === true ?
            <i className="fas fa-chevron-down food-prescription-data__diet-preview__meal-option__header__visible-icon"></i>:
            <i className="fas fa-chevron-up food-prescription-data__diet-preview__meal-option__header__visible-icon"></i>
          }

        </header>

        <VerticalAccordionSubContainer
          className="vertical-accordion-container food-prescription-data__diet-preview__meal-option__content"
          pose={this.state.mealAssociationPreviewVisibilityMap[associationKey] === true ? 'verticalSubOpen' : 'verticalSubClosed'}>

          <div className="vertical-accordion-container food-prescription-data__diet-preview__meal-option__content-wrapper">

            {this.state.mealAssociationPreviewVisibilityMap[associationKey] === true ? this.getMacronutrientDistribution(association) : null}

            <p className="food-prescription-data__diet-preview__meal-option__ingredient__sub-title">

              Ingredientes:

            </p>

            {(association.isRecipe && association.food_recipe.people_served !== null && association.food_recipe.people_served > 1) &&
              <p className="food-prescription-data__diet-preview__meal-option__people-served">
                <span className="food-prescription-data__diet-preview__meal-option__people-served__label">
                  Rendimento:
                </span>
                <span className="food-prescription-data__diet-preview__meal-option__people-served__value">
                  {association.food_recipe.people_served} Pessoas
                </span>
              </p>
            }

            <ul className="food-prescription-data__diet-preview__meal-option__ingredient-list">

              {ingredientList}

            </ul>

            {(association.isRecipe && (association.food_recipe.preparation_description !== null || association.food_recipe.preparation_steps.length > 0)) &&
              <React.Fragment>

                <HorizontalRule
                  color="black"
                />

                <section
                  className="food-prescription-data__diet-preview__meal-option__preparation"
                >

                  <header
                    className="food-prescription-data__diet-preview__meal-option__preparation__header"
                    onClick={() => {
                      let mealPreparationPreviewVisibilityMap = {...this.state.mealPreparationPreviewVisibilityMap};

                      if (this.state.mealPreparationPreviewVisibilityMap[associationKey] !== true) {
                        mealPreparationPreviewVisibilityMap[associationKey] = true;
                      }
                      else {
                        mealPreparationPreviewVisibilityMap[associationKey] = false;
                      }

                      this.setState({mealPreparationPreviewVisibilityMap});
                    }}
                  >

                    <h4 className="food-prescription-data__diet-preview__meal-option__preparation__header__text">
                      Instruções de preparação
                    </h4>

                    {this.state.mealPreparationPreviewVisibilityMap[associationKey] === true ?
                      <i className="fa-solid fa-chevron-down food-prescription-data__diet-preview__meal-option__preparation__header__visible-icon"></i>:
                      <i className="fa-solid fa-chevron-up food-prescription-data__diet-preview__meal-option__preparation__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionSub2Container
                    className="vertical-accordion-container food-prescription-data__diet-preview__meal-option__preparation__content"
                    pose={this.state.mealPreparationPreviewVisibilityMap[associationKey] === true ? 'verticalSub2Open' : 'verticalSub2Closed'}>

                    <div className="vertical-accordion-container food-prescription-data__diet-preview__meal-option__preparation__content-wrapper">

                      {association.food_recipe.preparation_description !== null &&
                        <p className="food-prescription-data__diet-preview__meal-option__preparation__description">

                          {association.food_recipe.preparation_description}

                        </p>
                      }

                      {association.food_recipe.preparation_steps.length > 0 &&
                        <ul className="food-prescription-data__diet-preview__meal-option__preparation__step-list">

                          {association.food_recipe.preparation_steps.map((step) => {
                            return (
                              <li
                                key={`${associationKey}:preparation_step:${step.id}`}
                                className="food-prescription-data__diet-preview__meal-option__preparation__step"
                              >
                                <div className="food-prescription-data__diet-preview__meal-option__preparation__step__name-wrapper">

                                  {/* <i className="fa-solid fa-circle food-prescription-data__diet-preview__meal-option__preparation__step__bullet"></i> */}

                                  <h4 className="food-prescription-data__diet-preview__meal-option__preparation__step__name">{`${step.order}) `}{step.name}{step.step_duration !== null ? ` (${step.step_duration} min)` : ''}</h4>

                                </div>

                                <p className="food-prescription-data__diet-preview__meal-option__preparation__step__instruction">

                                  {step.step_instruction}

                                </p>

                              </li>
                            );
                          })}

                        </ul>
                      }

                    </div>

                  </VerticalAccordionSub2Container>

                </section>

              </React.Fragment>
            }

          </div>

        </VerticalAccordionSubContainer>

      </section>
    );
  }

  getDietContent() {
    const mealPeriodMap = new Map();
    const mealPeriodAssociationMap = new Map();

    const dietAssociations = this.props.prescriptionItemList.filter((entry) => {
      if (!entry.loaded) {
        return false;
      }

      if (!entry.isRecipe) {
        return (entry.quantity !== null && entry.quantity > 0) &&
               entry.food_ingredient_source_association != null &&
               entry.food_ingredient_measurement_association != null &&
               entry.weekdays > 0 &&
               (entry.name === null || entry.name.length > 0);
      }

      return entry.weekdays > 0 && (entry.name === null || entry.name.length > 0);
    });

    for (const association of dietAssociations) {
      let mealPeriodAssociations;

      if (!mealPeriodMap.has(association.meal_period_id)) {
        mealPeriodMap.set(association.meal_period_id, association.meal_period);

        mealPeriodAssociations = [];
      }
      else {
        mealPeriodAssociations = mealPeriodAssociationMap.get(association.meal_period_id);
      }

      mealPeriodAssociations.push(association);

      mealPeriodAssociationMap.set(association.meal_period_id, mealPeriodAssociations);
    }

    const mealPeriods = [...mealPeriodMap.values()];
    mealPeriods.sort((a, b) => {
      if (a.start_time !== null && b.start_time !== null) {
        const result = a.start_time.localeCompare(b.start_time);

        if (result !== 0) {
          return result;
        }

        return a.name.localeCompare(b.name);
      }
      else if (a.start_time !== null && b.start_time === null) {
        return -1;
      }
      else if (a.start_time === null && b.start_time !== null) {
        return 1;
      }
      else {
        return a.name.localeCompare(b.name);
      }
    });

    return mealPeriods.map((mealPeriod) => {
      let timePeriod = '';

      if (mealPeriod.start_time !== null && mealPeriod.end_time !== null) {
        timePeriod = ` (${mealPeriod.start_time} - ${mealPeriod.end_time})`;
      }
      else if (mealPeriod.start_time !== null && mealPeriod.end_time === null) {
        timePeriod = ` (a partir de ${mealPeriod.start_time})`;
      }
      else if (mealPeriod.start_time === null && mealPeriod.end_time !== null) {
        timePeriod = ` (até ${mealPeriod.end_time})`;
      }

      const associations = mealPeriodAssociationMap.get(mealPeriod.id);
      associations.sort((a, b) => a.priority - b.priority);

      let mealList;

      mealList = associations.map((association) => this.getDietMealAssociationContent(association));

      return (
        <React.Fragment key={`diet:meal_period:${mealPeriod.id}`}>

          <DefaultSubSectionTitle
            className="food-prescription-data__diet-preview__meal-period__title"
            icon={<i className="fas fa-clock"></i>}
            text={`${mealPeriod.name}${timePeriod}`}
          />

          {this.props.food_prescription.meal_period_note_map[mealPeriod.id] &&
            <p className="food-prescription-data__diet-preview__meal-period__note">

              {this.props.food_prescription.meal_period_note_map[mealPeriod.id]}

            </p>
          }

          <ul className={`food-prescription-data__diet-preview__meal-period-list`}>

            {mealList}

          </ul>

        </React.Fragment>
      );
    });
  }

  getPresctiptionNutritionalData() {
    const prescriptionCopy = {...this.props.food_prescription};
    prescriptionCopy.food_recipe_associations = [];
    prescriptionCopy.food_ingredient_associations = [];

    for (const entry of this.props.prescriptionItemList) {
      if (entry.isRecipe) {
        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_recipe: entry.food_recipe,
          recipe_adjustments: entry.recipe_adjustments.map((recipe_adjustment) => {
            return {
              ...recipe_adjustment
            };
          }),
          weekdays: entry.weekdays
        };

        prescriptionCopy.food_recipe_associations.push(associationData);
      }
      else {
        const associationData = {
          meal_period_id: entry.meal_period.id,
          food_ingredient_source_association: entry.food_ingredient_source_association,
          food_ingredient_measurement_association: entry.food_ingredient_measurement_association,
          quantity: entry.quantity,
          weekdays: entry.weekdays
        };

        prescriptionCopy.food_ingredient_associations.push(associationData);
      }
    }

    return getFoodPrescriptionNutritionalData(prescriptionCopy);
  }

  mayCalculateTargetEnergyIntake() {
    return this.state.prescriptionDuration.length > 0 && 
           this.state.finalWeight.length > 0 &&  
           this.state.totalEnergyExpense.length > 0 && 
           ((typeof this.props.food_prescription.target_weight === 'string' && this.props.food_prescription.target_weight.length > 0) || this.props.food_prescription.target_weight);
  }

  getCaloryCalculations() {
    if (!this.mayCalculateTargetEnergyIntake()) {
      return null
    }

    const initialWeight = parseFloat(this.props.food_prescription.target_weight);
    const prescriptionDuration = parseFloat(this.state.prescriptionDuration);
    const finalWeight = parseFloat(this.state.finalWeight);
    const totalEnergyExpense = parseFloat(this.state.totalEnergyExpense);

    const totalEnergyLoss = ((finalWeight - initialWeight) * BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT) / prescriptionDuration;
    const targetDailyEnergy = totalEnergyExpense + totalEnergyLoss;

    const weeklyWeightLoss = -7 * (finalWeight - initialWeight) / prescriptionDuration;

    return (
      <React.Fragment>

        <HorizontalRule />

        <div className="food-prescription-data__indicators-container">

          <div className="food-prescription-data__indicators-wrapper">
            
            <div className="food-prescription-data__indicator">

              <h2 className="food-prescription-data__indicator__label">Meta calórica diária</h2>
              <p className="food-prescription-data__indicator__value">

                <span className="food-prescription-data__indicator__value__number">{Math.round(10 * targetDailyEnergy) / 10}</span>
                <span className="food-prescription-data__indicator__value__unit">kcal/dia</span>

              </p>

            </div>
            
            <div className="food-prescription-data__indicator">

              <h2 className="food-prescription-data__indicator__label">Perda de peso semanal</h2>
              <p className="food-prescription-data__indicator__value">

                <span className="food-prescription-data__indicator__value__number">{Math.round(100 * weeklyWeightLoss) / 100}</span>
                <span className="food-prescription-data__indicator__value__unit">kg/semana</span>

              </p>

            </div>

          </div>

        </div>

      </React.Fragment>
    );
  }

  applyCaloryCalculation() {
    if (!this.mayCalculateTargetEnergyIntake()) {
      return null
    }

    const initialWeight = parseFloat(this.props.food_prescription.target_weight);
    const prescriptionDuration = parseFloat(this.state.prescriptionDuration);
    const finalWeight = parseFloat(this.state.finalWeight);
    const totalEnergyExpense = parseFloat(this.state.totalEnergyExpense);

    const totalEnergyLoss = ((finalWeight - initialWeight) * BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT) / prescriptionDuration;
    const targetDailyEnergy = totalEnergyExpense + totalEnergyLoss;

    this.props.onUpdateFoodPrescriptionParameter("target_energy", Math.round(10 * targetDailyEnergy) / 10);
    
    this.setState({
      caloryCalculatorVisible: false,
    });
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="food-prescription-data__overlay"
          visible={this.state.caloryCalculatorVisible}
          actions={(
            <div className="food-prescription-data__overlay__action-container">

              <DefaultMenuButton
                className="food-prescription-data__overlay__action-button"
                onClick={() => this.setState({
                  caloryCalculatorVisible: false,
                })}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="food-prescription-data__overlay__action-button"
                onClick={() => this.applyCaloryCalculation()}
                color="green"
                text="Confirmar"
                disabled={!this.mayCalculateTargetEnergyIntake()}
              />

            </div>
          )}
        >

          <header className="food-prescription-data__overlay__header">

            <h3 className="food-prescription-data__overlay__header__title">
              Calculadora de meta calórica
            </h3>

          </header>

          <hr className="training-time__horizontal-rule" />

          <div className="food-prescription-data__overlay__content">

            {this.state.caloryCalculatorVisible &&
              <React.Fragment>

                <HalfWrapper>

                  <DefaultInput
                    id="overlay_target_weight"
                    name="target_weight"
                    isHighlighted={this.isHighlighted("target_weight")}
                    label="Peso inicial"
                    type="number"
                    placeholder="-"
                    step="0.1"
                    min="0.0"
                    handleInputChange={this.props.handleInputChange}
                    value={this.props.food_prescription.target_weight || ''}
                    autoComplete="off"
                    suffix="kg"
                    disabled={!this.mayChangeInputs()}
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    name="finalWeight"
                    isHighlighted={this.isHighlighted("finalWeight")}
                    label="Peso no final da dieta"
                    type="number"
                    placeholder="Peso no final da dieta"
                    step="0.01"
                    min="0.00"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.finalWeight}
                    autoComplete="off"
                    suffix="kg"
                    onFocus={(event) => event.target.select()}
                  />

                </HalfWrapper>

                <HalfWrapper>

                  <DefaultInput
                    name="prescriptionDuration"
                    label="Duração da dieta:"
                    type="number"
                    placeholder="-"
                    step="1"
                    min="1"
                    max="168"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.prescriptionDuration}
                    autoComplete="off"
                    suffix="Dias"
                  />

                  <DefaultInput
                    name="totalEnergyExpense"
                    isHighlighted={this.isHighlighted("totalEnergyExpense")}
                    label="Gasto energético total (diário)"
                    type="number"
                    placeholder="-"
                    step="1"
                    min="0"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.totalEnergyExpense}
                    autoComplete="off"
                    suffix="kcal"
                    onFocus={(event) => event.target.select()}
                  />

                </HalfWrapper>

                {this.getCaloryCalculations()}       

              </React.Fragment>
            }

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="food-prescription-data__overlay"
          visible={this.state.previewIsVisible}
          actions={(
            <div className="food-prescription-data__overlay__action-container">

              <DefaultMenuButton
                className="food-prescription-data__overlay__action-button"
                onClick={() => this.setState({previewIsVisible: false})}
                text="Fechar"
              />

            </div>
          )}
        >

          <header className="food-prescription-data__overlay__header">

            <h3 className="food-prescription-data__overlay__header__title">
              <i className="fa-solid fa-eye"></i> Pré-visualização
            </h3>

          </header>

          <hr className="food-prescription-data__horizontal-rule" />

          <div className="food-prescription-data__overlay__content--with-border">

            <section className="food-prescription-data__diet-preview">

              {this.getDietContent()}

            </section>

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="food-prescription-data__overlay"
          visible={this.state.selectedMealPeriod != null}
          actions={(
            <div className="food-prescription-data__overlay__action-container">

              <DefaultMenuButton
                className="food-prescription-data__overlay__action-button"
                onClick={() => this.setState({
                  selectedMealPeriod: null,
                  mealClassificationFilter: "",
                  foodClassificationFilter: "",
                })}
                text="Fechar"
              />

            </div>
          )}
        >

          <header className="food-prescription-data__overlay__header">

            <h3 className="food-prescription-data__overlay__header__title">
              {`${this.state.selectedMealPeriod !== null ? this.state.selectedMealPeriod.name : ''}: adicionar itens`}
            </h3>

          </header>

          <hr className="food-prescription-data__horizontal-rule" />

          <div className="food-prescription-data__overlay__content">

            <section className="food-prescription-data__tab-container">

              <ul className="food-prescription-data__tab-container__tab-selector">

                <li className="food-prescription-data__tab-container__tab-item">
                  <button
                    className="food-prescription-data__tab-container__tab-item__button"
                    onClick={() => this.setState({selectedItemTab: 1})}
                    disabled={this.state.selectedItemTab === 1}
                  >
                    Receitas
                  </button>
                </li>

                <li className="food-prescription-data__tab-container__tab-item">
                  <button
                    className="food-prescription-data__tab-container__tab-item__button"
                    onClick={() => this.setState({selectedItemTab: 2})}
                    disabled={this.state.selectedItemTab === 2}
                  >
                    Ingredientes básicos
                  </button>
                </li>

              </ul>

              <article className="food-prescription-data__tab-container__tab-content">

                {this.getItemTabContent()}

              </article>

            </section>

          </div>

        </OverlayWindow>

        <DefaultSection
          className="food-prescription-data"
          title="Dados da dieta"
        >

          {this.getActionButtons()}

          <div className="food-prescription-data__warning-container">

            <WarningMessage
              message={this.props.warningMessage}
              onClose={this.props.onCloseWarning}
              visible={this.props.showWarningMessage}
            />

          </div>

          <div className="food-prescription-data__input-container">

            {this.props.student &&
              <DefaultInput
                className="food-prescription-data__student-name"
                name="student"
                type="text"
                value={this.props.student.name}
                autoComplete="off"
                prefix="Aluno"
                isHorizontal={true}
                disabled={true}
              />
            }

            <HalfWrapper>

              <DefaultInput
                name="name"
                isHighlighted={this.isHighlighted("name")}
                label="Nome"
                type="text"
                placeholder="Nome da dieta"
                maxLength="128"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.name}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={!this.mayChangeInputs()}
              />

              <DefaultInput
                name="target_weight"
                isHighlighted={this.isHighlighted("target_weight")}
                label="Peso inicial"
                type="number"
                placeholder="-"
                step="0.1"
                min="0.0"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_weight || ''}
                autoComplete="off"
                suffix="kg"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="main_objective"
                isHighlighted={this.isHighlighted("main_objective")}
                label="Objetivo principal"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.main_objective || ''}
                options={this.getMainObjectiveOptions()}
                disabled={!this.mayChangeInputs()}
              />

              <DefaultInput
                name="target_gender"
                isHighlighted={this.isHighlighted("target_gender")}
                label="Gênero alvo"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_gender || ''}
                options={this.getGenderOptions()}
                disabled={!this.mayChangeInputs()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="target_energy"
                isHighlighted={this.isHighlighted("target_energy")}
                label="Meta calórica diária"
                type="number"
                placeholder="-"
                step="1"
                min="0"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_energy || ''}
                autoComplete="off"
                suffix="kcal"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="target_carbohydrate_intake"
                labelMessage={this.props.food_prescription.target_carbohydrate_percentage ? `Meta em porcentagem: ${this.props.food_prescription.target_carbohydrate_percentage}%` : null}
                isHighlighted={this.isHighlighted("target_carbohydrate_intake")}
                label="Meta diária de carboidrato"
                type="number"
                placeholder="-"
                step="0.1"
                min="0.0"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_carbohydrate_intake || ''}
                autoComplete="off"
                suffix="g / kg corporal"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="target_protein_intake"
                labelMessage={this.props.food_prescription.target_protein_percentage ? `Meta em porcentagem: ${this.props.food_prescription.target_protein_percentage}%` : null}
                isHighlighted={this.isHighlighted("target_protein_intake")}
                label="Meta diária de proteína"
                type="number"
                placeholder="-"
                step="0.1"
                min="0.0"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_protein_intake || ''}
                autoComplete="off"
                suffix="g / kg corporal"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                name="target_fat_intake"
                labelMessage={this.props.food_prescription.target_fat_percentage ? `Meta em porcentagem: ${this.props.food_prescription.target_fat_percentage}%` : null}
                isHighlighted={this.isHighlighted("target_fat_intake")}
                label="Meta diária de gordura"
                type="number"
                placeholder="-"
                step="0.1"
                min="0.0"
                handleInputChange={this.props.handleInputChange}
                value={this.props.food_prescription.target_fat_intake || ''}
                autoComplete="off"
                suffix="g / kg corporal"
                disabled={!this.mayChangeInputs()}
                onKeyDown={(event) => this.handleKeyDown(event)}
                onFocus={(event) => event.target.select()}
              />

            </HalfWrapper>

            <DefaultInput
              name="is_active"
              label="Ativo:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_prescription.is_active}
            />

            <DefaultInput
              name="description"
              isHighlighted={this.isHighlighted("description")}
              label="Descrição"
              type="textarea"
              placeholder="Descrição da dieta"
              rows="3"
              handleInputChange={this.props.handleInputChange}
              value={this.props.food_prescription.description || ''}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.mayChangeInputs()}
            />

          </div>

          <HorizontalRule />

          <div className="food-prescription-data__add-meal-period-wrapper">

            <DefaultInput
              className="food-prescription-data__add-meal-period-input"
              name="addMealPeriodSelect"
              label="Incluir período"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.addMealPeriodSelect || ''}
              options={this.getMealPeriodOptions()}
              isHorizontal={this.state.screenWidth > 580}
            />

            <button
              className="food-prescription-data__add-meal-period-button"
              disabled={!this.props.addMealPeriodSelect}
              onClick={() => this.props.addMealPeriod()}
            >

              Incluir

            </button>

          </div>

          <HorizontalRule />

          {this.getMealPeriods()}

          <HorizontalRule />

          {this.props.itemsAreValid &&
            <React.Fragment>

              <section className="food-prescription-data__nutrition-info">

                <header
                  className="food-prescription-data__nutrition-info__header"
                  onClick={() => this.setState({nutritionInfoVisible: !this.state.nutritionInfoVisible})}
                >

                  <h3 className="food-prescription-data__nutrition-info__header__text">
                    <i className="fas fa-info food-prescription-data__nutrition-info__header__text-icon"></i>
                    Informações nutricionais
                  </h3>

                  {this.state.nutritionInfoVisible ?
                    <i className="fas fa-chevron-down food-prescription-data__nutrition-info__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up food-prescription-data__nutrition-info__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container food-prescription-data__nutrition-info__content"
                  pose={this.state.nutritionInfoVisible ? 'verticalOpen' : 'verticalClosed'}>

                  <div className="vertical-accordion-container food-prescription-data__nutrition-info__content-wrapper">

                    {this.getNutritionalData()}

                  </div>

                </VerticalAccordionContainer>

              </section>

              <HorizontalRule />

            </React.Fragment>
          }

          <div className="food-prescription-data__buttons-container">

            {this.mayChangeInputs() &&
              <button
                className="food-prescription-data__save-button"
                disabled={!this.props.enableSave}
                onClick={this.props.onSave}
              >

                Salvar

              </button>
            }

            <Link
              className="food-prescription-data__cancel-button"
              to={this.props.onCancelPath}
            >

              {!this.mayChangeInputs() ? 'Voltar': 'Cancelar'}

            </Link>

          </div>

        </DefaultSection>

      </React.Fragment>
    );
  }
}

export default FoodPrescriptionData;
