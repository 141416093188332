import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {getModel, patchModel} from '../../../utils/functions';
import AppointmentRoomData from './appointment_room_data';


class AppointmentRoomEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment_room: {
        name: "",
        description: "",
        is_active: true
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let update = {
      loading: false
    };

    let appointment_room = getModel(`${routes.APPOINTMENT_ROOM_GET_API}${this.props.match.params.appointmentRoomId}`);

    appointment_room = await appointment_room;

    if(appointment_room) {
      update.appointment_room = {...this.state.appointment_room, ...appointment_room}
    }
    else {
      this.props.history.replace(routes.APPOINTMENT_ROOM_LIST_PATH);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.appointment_room, [name]: value};

    this.setState({
      appointment_room: newData
    });
  }

  inputsAreValid() {
    return this.state.appointment_room.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.appointment_room};

    try {
      await patchModel(`${routes.APPOINTMENT_ROOM_PATCH_API}${this.props.match.params.appointmentRoomId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.APPOINTMENT_ROOM_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.APPOINTMENT_ROOM_LIST_PATH,
            text: "Listar salas"
          },
          {
            path: `${routes.APPOINTMENT_ROOM_EDIT_PATH}${this.props.match.params.appointmentRoomId}`,
            text: "Editar sala"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar sala de atendimento"
        loading={this.state.loading}
      >

        <AppointmentRoomData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          appointment_room={this.state.appointment_room}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.APPOINTMENT_ROOM_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default AppointmentRoomEdit;
