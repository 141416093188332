import {NUTRIENT_PROTEIN_ID,
        NUTRIENT_FAT_ID,
        NUTRIENT_CARBOHYDRATE_ID,
        DEFAULT_WEIGHT_UNIT} from '../constants';

const WEEKDAY_CODES = [
  1,
  2,
  4,
  8,
  16,
  32,
  64,
];

const WEEKDAY_NAMES = [
  'segunda',
  'terça',
  'quarta',
  'quinta',
  'sexta',
  'sábado',
  'domingo',
];

const CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT = 4;
const PROTEIN_TO_KCAL_CONVERSION_CONTANT = 4;
const FAT_TO_KCAL_CONVERSION_CONTANT = 9;

const BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT = 7700;

function getBasicIngredientsFromRecipe(recipe, quantityMultiplier=1, recipe_adjustments=[]) {
  return [
    ...recipe.basic_ingredient_associations.map((entry) => {
      let adjustment = recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_basic_ingredient_association_id === entry.id);

      if (!adjustment) {
        adjustment = entry;
      }

      return {
        ...entry,
        food_ingredient_measurement_association: adjustment.food_ingredient_measurement_association,
        quantity: quantityMultiplier * adjustment.quantity
      };
    }),
    ...recipe.advanced_ingredient_associations.map((entry) => {
      let adjustment = recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_advanced_ingredient_association_id === entry.id);

      if (!adjustment) {
        adjustment = entry;
      }

      return getBasicIngredientsFromRecipe(entry.advanced_ingredient, adjustment.quantity * quantityMultiplier);
    }).flat()
  ];
}

function getFoodRecipeFinalWeight(foodRecipe) {
  let totalWeight = 0;

  for (const entry of foodRecipe.basic_ingredient_associations) {
    totalWeight += entry.quantity * entry.food_ingredient_measurement_association.weight_reference * entry.food_ingredient_measurement_association.weight_reference_unit.conversion_constant_to_gram;
  }

  for (const entry of foodRecipe.advanced_ingredient_associations) {
    if (entry.advanced_ingredient.output_weight_unit !== null && entry.advanced_ingredient.output_weight !== null) {
      totalWeight += entry.quantity * entry.advanced_ingredient.output_weight * entry.advanced_ingredient.output_weight_unit.conversion_constant_to_gram;
    }
    else {
      totalWeight += entry.quantity * getFoodRecipeFinalWeight(entry.advanced_ingredient);
    }
  }

  return totalWeight;
}

function getFoodRecipeNutricionalData(foodRecipe, recipe_adjustments=[]) {
  const nutritionalData = {};

  // organizes nutrients entries per its classification to later store statistic data
  const classificationMap = new Map();

  // Auxiliar map for checking nutrient weight units
  const nutrientUnitMap = new Map();

  // Stores energy amount for each ingredient
  const energyEntries = [];

  // Auxiliar variable for checking energy units
  let energyUnit = null;

  const people_served = foodRecipe.people_served || 1;

  const basicIngredientList = getBasicIngredientsFromRecipe(foodRecipe, 1 / people_served, recipe_adjustments).flat();

  let recipeNutrientWeightMap = new Map();

  for (const entry of basicIngredientList) {
    let proportionMultiplier;

    if (entry.food_ingredient_measurement_association.weight_reference_unit.id !== entry.food_ingredient_source_association.weight_reference_unit.id) {
      if (entry.food_ingredient_measurement_association.weight_reference_unit.conversion_constant_to_gram === null || entry.food_ingredient_source_association.weight_reference_unit.conversion_constant_to_gram === null) {
        window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (peso de referência). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

        return null;
      }

      proportionMultiplier = entry.quantity * ((entry.food_ingredient_measurement_association.weight_reference_unit.conversion_constant_to_gram * entry.food_ingredient_measurement_association.weight_reference) / (entry.food_ingredient_source_association.weight_reference_unit.conversion_constant_to_gram * entry.food_ingredient_source_association.weight_reference));
    }
    else {
      proportionMultiplier = entry.quantity * (entry.food_ingredient_measurement_association.weight_reference / entry.food_ingredient_source_association.weight_reference);
    }

    if (energyUnit === null) {
      energyUnit = entry.food_ingredient_source_association.energy_unit;
    }
    else if (energyUnit.id !== entry.food_ingredient_source_association.energy_unit.id) {
      window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (energia). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

      return null;
    }

    energyEntries.push(proportionMultiplier * entry.food_ingredient_source_association.energy);

    for (const association of entry.food_ingredient_source_association.nutrient_associations) {
      let classificationMapEntry;

      if (!classificationMap.has(association.nutrient.nutrient_classification_id)) {
        classificationMapEntry = {
          classification: association.nutrient.nutrient_classification,
          nutrientMap: new Map()
        };

        classificationMap.set(association.nutrient.nutrient_classification_id, classificationMapEntry);
      }
      else {
        classificationMapEntry = classificationMap.get(association.nutrient.nutrient_classification_id);
      }

      let nutrientMapEntry;

      if (!classificationMapEntry.nutrientMap.has(association.nutrient.id)) {
        nutrientMapEntry = {
          nutrient: association.nutrient,
          entries: []
        };

        classificationMapEntry.nutrientMap.set(association.nutrient.id, nutrientMapEntry);
      }
      else {
        nutrientMapEntry = classificationMapEntry.nutrientMap.get(association.nutrient.id);
      }

      let associationWeightValue = association.weight_value;

      if (!nutrientUnitMap.has(association.nutrient.id)) {
        nutrientUnitMap.set(association.nutrient.id, association.weight_unit);
      }
      else if (nutrientUnitMap.get(association.nutrient.id).id !== association.weight_unit.id) {
        if (nutrientUnitMap.get(association.nutrient.id).id !== DEFAULT_WEIGHT_UNIT.id || association.weight_unit.conversion_constant_to_gram === null) {
          window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (peso de nutrientes). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

          return null;
        }

        associationWeightValue *= association.weight_unit.conversion_constant_to_gram;
      }

      nutrientMapEntry.entries.push(proportionMultiplier * associationWeightValue);

      if (!recipeNutrientWeightMap.has(association.nutrient.id)) {
        recipeNutrientWeightMap.set(association.nutrient.id, proportionMultiplier * associationWeightValue);
      }
      else {
        recipeNutrientWeightMap.set(association.nutrient.id, recipeNutrientWeightMap.get(association.nutrient.id) + (proportionMultiplier * associationWeightValue));
      }
    }
  }

  nutritionalData.carbohydrateWeight = recipeNutrientWeightMap.has(NUTRIENT_CARBOHYDRATE_ID) ? recipeNutrientWeightMap.get(NUTRIENT_CARBOHYDRATE_ID) : 0;
  nutritionalData.proteinWeight = recipeNutrientWeightMap.has(NUTRIENT_PROTEIN_ID) ? recipeNutrientWeightMap.get(NUTRIENT_PROTEIN_ID) : 0;
  nutritionalData.fatWeight = recipeNutrientWeightMap.has(NUTRIENT_FAT_ID) ? recipeNutrientWeightMap.get(NUTRIENT_FAT_ID) : 0;

  nutritionalData.recipeNutrientWeightMap = recipeNutrientWeightMap;

  nutritionalData.energyEntries = energyEntries;
  nutritionalData.totalEnergy = energyEntries.reduce((sum, value) => sum + value, 0);
  nutritionalData.nutrientClassifications = [...classificationMap.values()].sort((a, b) => a.classification.id - b.classification.id);
  nutritionalData.nutrientUnitMap = nutrientUnitMap;
  nutritionalData.energyUnit = energyUnit;

  return nutritionalData;
}

function getFoodPrescriptionNutritionalData(foodPrescription) {
  const nutritionalData = {};

  // organizes nutrients entries per its classification to later store statistic data
  const classificationMap = new Map();

  // Auxiliar map for checking nutrient weight units
  const nutrientUnitMap = new Map();

  // Maps nutrients data per meal period
  const periodIngredientsMap = new Map();

  // Auxiliar variable for checking energy units
  let energyUnit = null;

  const itemAssociations = [
    ...foodPrescription.food_recipe_associations.map((association) => {
      return {
        ...association,
        isRecipe: true,
      };
    }),
    ...foodPrescription.food_ingredient_associations.map((association) => {
      return {
        ...association,
        isRecipe: false,
      };
    })
  ];

  for (const itemAssociation of itemAssociations) {
    let periodIngredientsMapEntry;

    if (!periodIngredientsMap.has(itemAssociation.meal_period_id)) {
      // These arrays represent each day in a week
      periodIngredientsMapEntry = {
        meal_period_id: itemAssociation.meal_period_id,
        energyValues: [[], [], [], [], [], [], []],
        carbohydrateWeights: [[], [], [], [], [], [], []],
        proteinWeights: [[], [], [], [], [], [], []],
        fatWeights: [[], [], [], [], [], [], []],
        nutrientMap: new Map(), // This will store nutrient weights the same way as the above variables for protein, fat, etc
        maxNutrientCount: [0, 0, 0, 0, 0, 0, 0] // This will simply count how many recipes were computed for each day of the week for given meal period
      };

      periodIngredientsMap.set(itemAssociation.meal_period_id, periodIngredientsMapEntry);
    }
    else {
      periodIngredientsMapEntry = periodIngredientsMap.get(itemAssociation.meal_period_id);
    }

    let people_served = 1;

    if (itemAssociation.isRecipe && itemAssociation.food_recipe.people_served && itemAssociation.food_recipe.people_served > 0) {
      people_served = itemAssociation.food_recipe.people_served;
    }

    const basicIngredientList = itemAssociation.isRecipe ? getBasicIngredientsFromRecipe(itemAssociation.food_recipe, 1 / people_served, itemAssociation.recipe_adjustments).flat() : [itemAssociation];

    let recipeEnergy = 0;

    // Basically, it is an auxiliary map for storing total weights for each nutrient of given recipe
    let recipeNutrientWeightMap = new Map();

    for (const entry of basicIngredientList) {
      let proportionMultiplier;

      if (entry.food_ingredient_measurement_association.weight_reference_unit_id !== entry.food_ingredient_source_association.weight_reference_unit_id) {
        if (entry.food_ingredient_measurement_association.weight_reference_unit.conversion_constant_to_gram === null || entry.food_ingredient_source_association.weight_reference_unit.conversion_constant_to_gram === null) {
          window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (peso de referência). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

          return null;
        }

        proportionMultiplier = entry.quantity * ((entry.food_ingredient_measurement_association.weight_reference_unit.conversion_constant_to_gram * entry.food_ingredient_measurement_association.weight_reference) / (entry.food_ingredient_source_association.weight_reference_unit.conversion_constant_to_gram * entry.food_ingredient_source_association.weight_reference));
      }
      else {
        proportionMultiplier = entry.quantity * (entry.food_ingredient_measurement_association.weight_reference / entry.food_ingredient_source_association.weight_reference);
      }

      if (energyUnit === null) {
        energyUnit = entry.food_ingredient_source_association.energy_unit;
      }
      else if (energyUnit.id !== entry.food_ingredient_source_association.energy_unit.id) {
        window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (energia). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

        return null;
      }

      recipeEnergy += proportionMultiplier * entry.food_ingredient_source_association.energy;

      for (const association of entry.food_ingredient_source_association.nutrient_associations) {
        let classificationMapEntry;

        if (!classificationMap.has(association.nutrient.nutrient_classification_id)) {
          classificationMapEntry = {
            classification: association.nutrient.nutrient_classification,
            nutrientMap: new Map() // This will later store statistic data
          };

          classificationMap.set(association.nutrient.nutrient_classification_id, classificationMapEntry);
        }
        else {
          classificationMapEntry = classificationMap.get(association.nutrient.nutrient_classification_id);
        }

        if (!classificationMapEntry.nutrientMap.has(association.nutrient.id)) {
          classificationMapEntry.nutrientMap.set(association.nutrient.id, {
            nutrient: association.nutrient,
            dailyMeans: [0, 0, 0, 0, 0, 0, 0],
            dailyStandardDeviations: [0, 0, 0, 0, 0, 0, 0]
          });
        }

        let associationWeightValue = association.weight_value;

        if (!nutrientUnitMap.has(association.nutrient.id)) {
          nutrientUnitMap.set(association.nutrient.id, association.weight_unit);
        }
        else if (nutrientUnitMap.get(association.nutrient.id).id !== association.weight_unit.id) {
          if (nutrientUnitMap.get(association.nutrient.id).id !== DEFAULT_WEIGHT_UNIT.id || association.weight_unit.conversion_constant_to_gram === null) {
            window.alert('ERRO NOS CÁLCULOS: Incompatibilidade de unidades cadastradas (peso de nutrientes). LEVANTE ESTE ERRO PARA O SUPORTE DA FYD!');

            return null;
          }

          associationWeightValue *= association.weight_unit.conversion_constant_to_gram;
        }

        if (!periodIngredientsMapEntry.nutrientMap.has(association.nutrient.id)) {
          periodIngredientsMapEntry.nutrientMap.set(association.nutrient.id, {
            nutrient: association.nutrient,
            weights: [[], [], [], [], [], [], []]
          });
        }

        if (!recipeNutrientWeightMap.has(association.nutrient.id)) {
          recipeNutrientWeightMap.set(association.nutrient.id, proportionMultiplier * associationWeightValue);
        }
        else {
          recipeNutrientWeightMap.set(association.nutrient.id, recipeNutrientWeightMap.get(association.nutrient.id) + (proportionMultiplier * associationWeightValue));
        }
      }
    }

    // Weekday codes included in the recipe
    const associationWeekdayCodes = WEEKDAY_CODES.filter((weekCode) => (weekCode & itemAssociation.weekdays) > 0);

    for (const weekCode of associationWeekdayCodes) {
      periodIngredientsMapEntry.energyValues[WEEKDAY_CODES.indexOf(weekCode)].push(recipeEnergy);

      periodIngredientsMapEntry.maxNutrientCount[WEEKDAY_CODES.indexOf(weekCode)] += 1;
    }

    for (const weekCode of associationWeekdayCodes) {
      const carbohydrateWeight = recipeNutrientWeightMap.has(NUTRIENT_CARBOHYDRATE_ID) ? recipeNutrientWeightMap.get(NUTRIENT_CARBOHYDRATE_ID) : 0;
      const proteinWeight = recipeNutrientWeightMap.has(NUTRIENT_PROTEIN_ID) ? recipeNutrientWeightMap.get(NUTRIENT_PROTEIN_ID) : 0;
      const fatWeight = recipeNutrientWeightMap.has(NUTRIENT_FAT_ID) ? recipeNutrientWeightMap.get(NUTRIENT_FAT_ID) : 0;

      // const total = (4 * carbohydrateWeight) + (4 * proteinWeight) + (9 * fatWeight);

      periodIngredientsMapEntry.carbohydrateWeights[WEEKDAY_CODES.indexOf(weekCode)].push(carbohydrateWeight);
      periodIngredientsMapEntry.proteinWeights[WEEKDAY_CODES.indexOf(weekCode)].push(proteinWeight);
      periodIngredientsMapEntry.fatWeights[WEEKDAY_CODES.indexOf(weekCode)].push(fatWeight);
    }

    for (const [key, value] of recipeNutrientWeightMap) {
      for (const weekCode of associationWeekdayCodes) {
        periodIngredientsMapEntry.nutrientMap.get(key).weights[WEEKDAY_CODES.indexOf(weekCode)].push(value);
      }
    }
  }

  let target_energy = 0;
  let target_carbohydrate_percentage = 0;
  let target_protein_percentage = 0;
  let target_fat_percentage = 0;
  let target_carbohydrate_intake = 0;
  let target_protein_intake = 0;
  let target_fat_intake = 0;

  if (typeof foodPrescription.target_energy === 'string' && foodPrescription.target_energy.length > 0) {
    target_energy = parseFloat(foodPrescription.target_energy);
  }
  else if (foodPrescription.target_energy !== null) {
    target_energy = foodPrescription.target_energy;
  }

  if (typeof foodPrescription.target_carbohydrate_percentage === 'string' && foodPrescription.target_carbohydrate_percentage.length > 0) {
    target_carbohydrate_percentage = parseFloat(foodPrescription.target_carbohydrate_percentage);
  }
  else if (foodPrescription.target_carbohydrate_percentage !== null) {
    target_carbohydrate_percentage = foodPrescription.target_carbohydrate_percentage;
  }

  if (typeof foodPrescription.target_protein_percentage === 'string' && foodPrescription.target_protein_percentage.length > 0) {
    target_protein_percentage = parseFloat(foodPrescription.target_protein_percentage);
  }
  else if (foodPrescription.target_protein_percentage !== null) {
    target_protein_percentage = foodPrescription.target_protein_percentage;
  }

  if (typeof foodPrescription.target_fat_percentage === 'string' && foodPrescription.target_fat_percentage.length > 0) {
    target_fat_percentage = parseFloat(foodPrescription.target_fat_percentage);
  }
  else if (foodPrescription.target_fat_percentage !== null) {
    target_fat_percentage = foodPrescription.target_fat_percentage;
  }

  if (foodPrescription.target_carbohydrate_intake !== null) {
    target_carbohydrate_intake = foodPrescription.target_carbohydrate_intake;
  }
  if (foodPrescription.target_protein_intake !== null) {
    target_protein_intake = foodPrescription.target_protein_intake;
  }
  if (foodPrescription.target_fat_intake !== null) {
    target_fat_intake = foodPrescription.target_fat_intake;
  }

  nutritionalData.energyUnit = energyUnit;
  nutritionalData.nutrientUnitMap = nutrientUnitMap;

  nutritionalData.target_energy = target_energy;
  nutritionalData.target_carbohydrate_percentage = target_carbohydrate_percentage;
  nutritionalData.target_protein_percentage = target_protein_percentage;
  nutritionalData.target_fat_percentage = target_fat_percentage;
  nutritionalData.target_carbohydrate_intake = target_carbohydrate_intake;
  nutritionalData.target_protein_intake = target_protein_intake;
  nutritionalData.target_fat_intake = target_fat_intake;

  let carbohydrateStatistic = {
    dailyMeans: [0, 0, 0, 0, 0, 0, 0],
    dailyStandardDeviations: [0, 0, 0, 0, 0, 0, 0]
  };
  let proteinStatistic = {
    dailyMeans: [0, 0, 0, 0, 0, 0, 0],
    dailyStandardDeviations: [0, 0, 0, 0, 0, 0, 0]
  };
  let fatStatistic = {
    dailyMeans: [0, 0, 0, 0, 0, 0, 0],
    dailyStandardDeviations: [0, 0, 0, 0, 0, 0, 0]
  };
  let energyStatistic = {
    dailyMeans: [0, 0, 0, 0, 0, 0, 0],
    dailyStandardDeviations: [0, 0, 0, 0, 0, 0, 0]
  };

  nutritionalData.mealPeriodMap = new Map();

  for (const periodEntry of [...periodIngredientsMap.values()]) {
    let periodEnergyMean = [0, 0, 0, 0, 0, 0, 0];
    let carbohydrateWeightMean = [0, 0, 0, 0, 0, 0, 0];
    let proteinWeightMean = [0, 0, 0, 0, 0, 0, 0];
    let fatWeightMean = [0, 0, 0, 0, 0, 0, 0];

    let periodEnergyStandardDeviation = [0, 0, 0, 0, 0, 0, 0];
    let carbohydrateWeightStandardDeviation = [0, 0, 0, 0, 0, 0, 0];
    let proteinWeightStandardDeviation = [0, 0, 0, 0, 0, 0, 0];
    let fatWeightStandardDeviation = [0, 0, 0, 0, 0, 0, 0];

    for (let i = 0; i < 7; ++i) {
      if (periodEntry.energyValues[i].length > 0) {
        periodEnergyMean[i] = periodEntry.energyValues[i].reduce((sum, value) => sum + value, 0) / periodEntry.energyValues[i].length;
        energyStatistic.dailyMeans[i] += periodEnergyMean[i];
      }

      // At this point we are calculating the means of weights for each macronutrient and meal period
      if (periodEntry.carbohydrateWeights[i].length > 0) {
        carbohydrateWeightMean[i] = periodEntry.carbohydrateWeights[i].reduce((sum, value) => sum + value, 0) / periodEntry.carbohydrateWeights[i].length;
        carbohydrateStatistic.dailyMeans[i] += carbohydrateWeightMean[i];
      }
      if (periodEntry.proteinWeights[i].length > 0) {
        proteinWeightMean[i] = periodEntry.proteinWeights[i].reduce((sum, value) => sum + value, 0) / periodEntry.proteinWeights[i].length;
        proteinStatistic.dailyMeans[i] += proteinWeightMean[i];
      }
      if (periodEntry.fatWeights[i].length > 0) {
        fatWeightMean[i] = periodEntry.fatWeights[i].reduce((sum, value) => sum + value, 0) / periodEntry.fatWeights[i].length;
        fatStatistic.dailyMeans[i] += fatWeightMean[i];
      }
    }

    for (let i = 0; i < 7; ++i) {
      if (periodEntry.energyValues[i].length > 0) {
        periodEnergyStandardDeviation[i] = Math.sqrt(periodEntry.energyValues[i].reduce((sum, value) => sum + Math.pow(periodEnergyMean[i] - value, 2), 0) / periodEntry.energyValues[i].length);
        energyStatistic.dailyStandardDeviations[i] += periodEnergyStandardDeviation[i];
      }
      if (periodEntry.carbohydrateWeights[i].length > 0) {
        carbohydrateWeightStandardDeviation[i] = Math.sqrt(periodEntry.carbohydrateWeights[i].reduce((sum, value) => sum + Math.pow(carbohydrateWeightMean[i] - value, 2), 0) / periodEntry.carbohydrateWeights[i].length);
        carbohydrateStatistic.dailyStandardDeviations[i] += carbohydrateWeightStandardDeviation[i];
      }
      if (periodEntry.proteinWeights[i].length > 0) {
        proteinWeightStandardDeviation[i] = Math.sqrt(periodEntry.proteinWeights[i].reduce((sum, value) => sum + Math.pow(proteinWeightMean[i] - value, 2), 0) / periodEntry.proteinWeights[i].length);
        proteinStatistic.dailyStandardDeviations[i] += proteinWeightStandardDeviation[i];
      }
      if (periodEntry.fatWeights[i].length > 0) {
        fatWeightStandardDeviation[i] = Math.sqrt(periodEntry.fatWeights[i].reduce((sum, value) => sum + Math.pow(fatWeightMean[i] - value, 2), 0) / periodEntry.fatWeights[i].length);
        fatStatistic.dailyStandardDeviations[i] += fatWeightStandardDeviation[i];
      }
    }

    // Calculates the statistics for the period
    nutritionalData.mealPeriodMap.set(periodEntry.meal_period_id, {
      energy: {
        dailyMeans: periodEnergyMean,
        dailyStandardDeviations: periodEnergyStandardDeviation,
        mean: periodEnergyMean.reduce((sum, value) => sum + value, 0) / 7,
        deviation: periodEnergyStandardDeviation.reduce((sum, value) => sum + value, 0) / 7
      },
      carbohydrate: {
        dailyMeans: carbohydrateWeightMean,
        dailyStandardDeviations: carbohydrateWeightStandardDeviation,
        mean: carbohydrateWeightMean.reduce((sum, value) => sum + value, 0) / 7,
        deviation: carbohydrateWeightStandardDeviation.reduce((sum, value) => sum + value, 0) / 7
      },
      protein: {
        dailyMeans: proteinWeightMean,
        dailyStandardDeviations: proteinWeightStandardDeviation,
        mean: proteinWeightMean.reduce((sum, value) => sum + value, 0) / 7,
        deviation: proteinWeightStandardDeviation.reduce((sum, value) => sum + value, 0) / 7
      },
      fat: {
        dailyMeans: fatWeightMean,
        dailyStandardDeviations: fatWeightStandardDeviation,
        mean: fatWeightMean.reduce((sum, value) => sum + value, 0) / 7,
        deviation: fatWeightStandardDeviation.reduce((sum, value) => sum + value, 0) / 7
      },
    });

    // Essencially the same procedure for the above nutrients fat, protein, etc
    for (const nutrientEntry of [...periodEntry.nutrientMap.values()]) {
      const statisticEntry = classificationMap.get(nutrientEntry.nutrient.nutrient_classification_id).nutrientMap.get(nutrientEntry.nutrient.id);

      let periodNutrientMean = [0, 0, 0, 0, 0, 0, 0];

      for (let i = 0; i < 7; ++i) {
        if (nutrientEntry.weights[i].length > 0) {
          // If some ingredients does not contain some ingredients, they must be considered as a 0 for its weight for a correct mean calculation
          while (nutrientEntry.weights[i].length < periodEntry.maxNutrientCount[i]) {
            nutrientEntry.weights[i].push(0);
          }

          periodNutrientMean[i] = nutrientEntry.weights[i].reduce((sum, value) => sum + value, 0) / nutrientEntry.weights[i].length;
          statisticEntry.dailyMeans[i] += periodNutrientMean[i];
        }
      }

      for (let i = 0; i < 7; ++i) {
        if (nutrientEntry.weights[i].length > 0) {
          statisticEntry.dailyStandardDeviations[i] += Math.sqrt(nutrientEntry.weights[i].reduce((sum, value) => sum + Math.pow(periodNutrientMean[i] - value, 2), 0) / nutrientEntry.weights[i].length);
        }
      }
    }
  }

  nutritionalData.carbohydrateStatistic = carbohydrateStatistic;
  nutritionalData.proteinStatistic = proteinStatistic;
  nutritionalData.fatStatistic = fatStatistic;
  nutritionalData.energyStatistic = energyStatistic;

  // Simple mean of daily means
  const energyOverviewValue = energyStatistic.dailyMeans.reduce((sum, value) => sum + value, 0) / 7;
  const carbohydrateOverviewValue = carbohydrateStatistic.dailyMeans.reduce((sum, value) => sum + value, 0) / 7;
  const proteinOverviewValue = proteinStatistic.dailyMeans.reduce((sum, value) => sum + value, 0) / 7;
  const fatOverviewValue = fatStatistic.dailyMeans.reduce((sum, value) => sum + value, 0) / 7;

  const energyOverviewStandardDeviation = energyStatistic.dailyStandardDeviations.reduce((sum, value) => sum + value, 0) / 7;
  const carbohydrateOverviewStandardDeviation = carbohydrateStatistic.dailyStandardDeviations.reduce((sum, value) => sum + value, 0) / 7;
  const proteinOverviewStandardDeviation = proteinStatistic.dailyStandardDeviations.reduce((sum, value) => sum + value, 0) / 7;
  const fatOverviewStandardDeviation = fatStatistic.dailyStandardDeviations.reduce((sum, value) => sum + value, 0) / 7;

  nutritionalData.totalEnergy = {
    mean: energyOverviewValue,
    deviation: energyOverviewStandardDeviation
  };
  nutritionalData.totalCarbohydrate = {
    mean: carbohydrateOverviewValue,
    deviation: carbohydrateOverviewStandardDeviation
  };
  nutritionalData.totalProtein = {
    mean: proteinOverviewValue,
    deviation: proteinOverviewStandardDeviation
  };
  nutritionalData.totalFat = {
    mean: fatOverviewValue,
    deviation: fatOverviewStandardDeviation
  };

  const nutrients = []
  const nutrientClassifications = [...classificationMap.values()].sort((a, b) => a.classification.id - b.classification.id).map((classificationEntry) => {
    const nutrientStatistics = [...classificationEntry.nutrientMap.values()].sort((a, b) => a.nutrient.fullname.localeCompare(b.nutrient.fullname)).map((entry) => {
      return {
        ...entry,
        totalMean: entry.dailyMeans.reduce((sum, value) => sum + value, 0) / 7,
        totalMeanDeviation: entry.dailyStandardDeviations.reduce((sum, value) => sum + value, 0) / 7
      };
    });

    nutrients.push(nutrientStatistics);

    return {
      classification: classificationEntry.classification,
      nutrientStatistics
    };
  });

  nutritionalData.nutrientClassifications = nutrientClassifications;
  nutritionalData.nutrients = nutrients.flat();

  return nutritionalData;
}

export {
  WEEKDAY_CODES,
  WEEKDAY_NAMES,
  CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
  PROTEIN_TO_KCAL_CONVERSION_CONTANT,
  FAT_TO_KCAL_CONVERSION_CONTANT,
  BODY_WEIGHT_TO_ENERGY_CONVERSION_CONSTANT,
  getBasicIngredientsFromRecipe,
  getFoodRecipeFinalWeight,
  getFoodRecipeNutricionalData,
  getFoodPrescriptionNutritionalData
};
