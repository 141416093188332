import React from 'react';
// import { Link } from 'react-router-dom';
import * as routes from '../../constants';
import {
  DEFAULT_UNIT_TYPE,
  SERVICE_FUNCTIONAL_SERVICE,
  SERVICE_PILATES_SERVICE,
  SERVICE_BIKE_SERVICE,
  SERVICE_GYM_SERVICE,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE,
  DEFAULT_UNKNOWN_ERROR_MESSAGE,
  COST_CENTER_PILATES_ID,
  COST_CENTER_FUNCTIONAL_ID,
  COST_CENTER_BIKE_ID,
  COST_CENTER_GYM_ID,
  COST_CENTER_CARDIO_ID,
  COST_CENTER_BOOTCAMP_ID,
  COST_CENTER_CORE_ID} from '../../constants';
import ContentFrame from '../content_frame';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getModel, postModel, getLocalDateIsoString, setUrlParameters, getCurrencyText, getAsLocalDate} from '../../utils/functions';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import StackedBarGraph, {StackGroup, StackPoint} from '../../graphs/stacked_bar_graph';
// import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import HorizontalBarGraph, {BarDataPoint} from '../../graphs/horizontal_bar_graph';
// import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import * as permissions from '../../permissions';
import './class_report.scss';

// const LIGHT_SERVICE_PALLET = [
//   '#78bb67',
//   '#5ec2d2',
//   '#d2915a',
//   '#edf570',
//   '#967de2',
//   '#ea6767',
//   '#cd59b1',
//   '#9c9c9c',
// ];
//
// const DARK_SERVICE_PALLET = [
//   '#74985e',
//   '#53868e',
//   '#826248',
//   '#949853',
//   '#646796',
//   '#9a5c5c',
//   '#8e6484',
//   '#797979',
// ];

const SERVICE_COLOR_MAP = {
  [SERVICE_FUNCTIONAL_SERVICE]: '#78bb67',
  [SERVICE_PILATES_SERVICE]: '#5ec2d2',
  [SERVICE_BIKE_SERVICE]: '#edf570',
  [SERVICE_GYM_SERVICE]: '#967de2',
  [SERVICE_CARDIO]: '#ea6767',
  [SERVICE_CORE]: '#cd59b1',
  [SERVICE_BOOTCAMP]: '#9469cd'
};

const SERVICE_COST_CENTER_MAP = {
  [SERVICE_PILATES_SERVICE]: COST_CENTER_PILATES_ID,
  [SERVICE_FUNCTIONAL_SERVICE]: COST_CENTER_FUNCTIONAL_ID,
  [SERVICE_BIKE_SERVICE]: COST_CENTER_BIKE_ID,
  [SERVICE_GYM_SERVICE]: COST_CENTER_GYM_ID,
  [SERVICE_CARDIO]: COST_CENTER_CARDIO_ID,
  [SERVICE_BOOTCAMP]: COST_CENTER_BOOTCAMP_ID,
  [SERVICE_CORE]: COST_CENTER_CORE_ID,
}

class ClassReport extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let month_reference = queryParameters.get('month_reference');

    let initialDate;
    let finalDate;

    if(!month_reference) {
      initialDate = new Date();
      initialDate.setDate(1);
      initialDate.setMonth(initialDate.getMonth() - 1);

      finalDate = new Date();
      finalDate.setDate(1);
      finalDate.setMonth(finalDate.getMonth() - 1);
    }
    else {
      month_reference = month_reference + '-01';
      initialDate = getAsLocalDate(month_reference);
      finalDate = getAsLocalDate(month_reference);
    }

    initialDate.setDate(1);
    initialDate = getLocalDateIsoString(initialDate);

    finalDate.setMonth(finalDate.getMonth() + 1);
    finalDate.setDate(0);
    finalDate = getLocalDateIsoString(finalDate);

    this.state = {
      month_reference: initialDate.slice(0, 7),
      initialDate: initialDate,
      finalDate: finalDate,
      coaches: [],
      services: [],
      trainingClasses: [],
      experimentalClasses: [],
      training_times: [],
      availableCalendar: [],
      checkin_limit_map: {},
      coachReportVisibilityMap: new Map(),
      coachSalaryMap: new Map(),
      reportEmailSentMap: new Map(),
      analysisData: null,
      coachToRegister: null,
      reportDataToSend: null,
      loadingData: true,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getTrainingClasses() {
    const parameters = {
      initial_date: this.state.initialDate,
      final_date: this.state.finalDate,
      load_coaches: true
    };

    return await getModels(setUrlParameters(routes.TRAINING_CLASSES_GET_API, parameters));
  }

  async getExperimentalClasses() {
    const parameters = {
      initial_date: this.state.initialDate,
      final_date: this.state.finalDate,
      load_coaches: true,
      checked_in: true
    };

    let classes = await getModels(setUrlParameters(routes.EXPERIMENTAL_CLASSES_GET_API, parameters));

    return classes;
  }

  async getSalaryMap() {
    if (!this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      return null
    }

    let salaryMap = await getModel(`${routes.FINANCIAL_SALARY_MAP_GET_API}${this.state.month_reference}`);

    return salaryMap;
  }

  async getCoaches() {
    const parameters = {
      load_salary_map: true
    };

    return await getModels(setUrlParameters(routes.COACHES_GET_API, parameters));
  }

  async getAvailableCalendar() {
    return await getModels(routes.TRAINING_TIMES_GET_API);
  }

  async refreshData(setLoading=true) {
    if(this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({loadingData: true});

    let trainingClasses = this.getTrainingClasses();
    let experimentalClasses = this.getExperimentalClasses();
    let salaryMap = this.getSalaryMap();

    const update = {
      analysisData: null,
      reportEmailSentMap: new Map(),
      coachSalaryMap: new Map()
    }

    if(setLoading) {
      update.loadingData = false;
    }

    trainingClasses = await trainingClasses;

    if(trainingClasses) {
      update.trainingClasses = trainingClasses;
    }

    experimentalClasses = await experimentalClasses;

    if(experimentalClasses) {
      update.experimentalClasses = experimentalClasses;
    }

    salaryMap = await salaryMap;

    if(salaryMap) {
      for (const [key, value] of Object.entries(salaryMap)) {
        if (value) {
          update.coachSalaryMap.set(parseInt(key), value);
        }
      }
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.setState({loadingData: true});

    let coaches = this.getCoaches();
    let availableCalendar = this.getAvailableCalendar();

    await this.refreshData(false);

    const update = {loadingData: false};

    coaches = await coaches;
    availableCalendar = await availableCalendar;

    if (coaches) {
      update.coaches = coaches;
      update.coaches.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (availableCalendar) {
      update.training_times = availableCalendar.training_times;

      update.services = availableCalendar.services
      update.services.sort((a, b) => b.localeCompare(a));

      if(availableCalendar.checkin_limit_map) {
        update.checkin_limit_map = availableCalendar.checkin_limit_map;
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.refreshData();
    }
    else if(!this.state.loadingData && this.state.analysisData === null) {
      this.loadAnalysisData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  mayUpdateDateInputs() {
    if(!this.state.month_reference) {
      return false;
    }

    if(this.state.month_reference !== this.state.initialDate.slice(0, 7)) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(routes.CLASS_REPORT_PATH, {
        month_reference: this.state.month_reference,
      }));

      const month_reference = this.state.month_reference + '-01';

      let initialDate = getAsLocalDate(month_reference);
      let finalDate = getAsLocalDate(month_reference);

      initialDate.setDate(1);
      initialDate = getLocalDateIsoString(initialDate);

      finalDate.setMonth(finalDate.getMonth() + 1);
      finalDate.setDate(0);
      finalDate = getLocalDateIsoString(finalDate);

      this.setState({
        initialDate,
        finalDate
      });
    }
  }

  handleKeyDown(event) {
    if(event.keyCode === 13) {
      this.applyDateInputChanges();
    }
  }

  loadAnalysisData() {
    if(this.state.initialDate > this.state.finalDate) {
      return [];
    }

    const defaultTargetStudentCountMap = new Map(this.state.services.map((service) => [service, Math.ceil(parseFloat(this.state.checkin_limit_map[service]) * DEFAULT_CLASS_TARGET_STUDENT_PERCENTAGE)]));

    let checkinCount = 0;

    const serviceDataMap = new Map();
    const coachMap = new Map();
    const experimentalClassMap = new Map();

    for(const entry of this.state.experimentalClasses) {
      if (!serviceDataMap.has(entry.target_service)) {
        serviceDataMap.set(entry.target_service, {
          classCount: 0,
          checkinCount: 0,
          experimentalCount: 0
        });
      }

      const serviceDataMapEntry = serviceDataMap.get(entry.target_service);
      serviceDataMapEntry.experimentalCount += 1;

      const classDateIdentifier = `${entry.target_service}:${entry.date.slice(0, 16)}`;

      if (!experimentalClassMap.has(classDateIdentifier)) {
        experimentalClassMap.set(classDateIdentifier, []);
      }

      experimentalClassMap.get(classDateIdentifier).push(entry);
    }

    for (const class_entry of this.state.trainingClasses) {
      checkinCount += class_entry.data_entries.length;

      if (!serviceDataMap.has(class_entry.target_service)) {
        serviceDataMap.set(class_entry.target_service, {
          classCount: 0,
          checkinCount: 0,
          experimentalCount: 0
        });
      }

      const serviceDataMapEntry = serviceDataMap.get(class_entry.target_service);
      serviceDataMapEntry.classCount += 1;
      serviceDataMapEntry.checkinCount += class_entry.data_entries.length;

      let classDayId = getAsLocalDate(class_entry.created_at).getDay() - 1;
      if (classDayId < 0) {
        classDayId = 6;
      }

      const classTrainingTime = this.state.training_times.find((entry) => entry.target_service === class_entry.target_service && entry.day_id === classDayId && entry.time === class_entry.class_time);

      let targetStudentCount;

      if (!classTrainingTime || classTrainingTime.target_student_count === null) {
        targetStudentCount = defaultTargetStudentCountMap.get(class_entry.target_service);
      }
      else {
        targetStudentCount = classTrainingTime.target_student_count;
      }

      let totalStudents = class_entry.data_entries.length;

      const classDateIdentifier = `${class_entry.target_service}:${class_entry.created_at.slice(0, 10)}T${class_entry.class_time}`;
      let experimentalCount = 0;

      if (experimentalClassMap.has(classDateIdentifier)) {
        const experimentalClassEntries = experimentalClassMap.get(classDateIdentifier);
        experimentalCount = experimentalClassEntries.length;
        totalStudents += experimentalCount;
      }

      for (const coach of class_entry.coaches) {
        if (!coachMap.has(coach.id)) {
          coachMap.set(coach.id, new Map())
        }

        const coachServiceMap = coachMap.get(coach.id);

        if (!coachServiceMap.has(class_entry.target_service)) {
          coachServiceMap.set(class_entry.target_service, {
            classCount: 0,
            checkinCount: 0,
            experimentalCount: 0,
            performance: [0, 0, 0, 0],
          })
        }

        const coachServiceMapEntry = coachServiceMap.get(class_entry.target_service);

        coachServiceMapEntry.classCount += 1;
        coachServiceMapEntry.checkinCount += class_entry.data_entries.length;
        coachServiceMapEntry.experimentalCount += experimentalCount;

        if (totalStudents >= targetStudentCount * 0.75) {
          coachServiceMapEntry.performance[0] += 1;
        }
        else if (totalStudents >= targetStudentCount * 0.5) {
          coachServiceMapEntry.performance[1] += 1;
        }
        else if (totalStudents > 0) {
          coachServiceMapEntry.performance[2] += 1;
        }
        else {
          coachServiceMapEntry.performance[3] += 1;
        }
      }
    }

    const serviceClassPoints = [];
    const serviceCheckinPoints = [];
    const serviceExperimentalPoints = [];

    const occupancyRateData = [];

    for (const service of this.state.services) {
      if (serviceDataMap.has(service)) {
        const serviceDataMapEntry = serviceDataMap.get(service);

        serviceClassPoints.push(StackPoint(serviceDataMapEntry.classCount, service, serviceDataMapEntry.classCount));
        serviceCheckinPoints.push(StackPoint(serviceDataMapEntry.checkinCount, service, serviceDataMapEntry.checkinCount));
        serviceExperimentalPoints.push(StackPoint(serviceDataMapEntry.experimentalCount, service, serviceDataMapEntry.experimentalCount));

        if (this.state.checkin_limit_map[service]) {
          const ratio = 100 * (serviceDataMapEntry.checkinCount + serviceDataMapEntry.experimentalCount) / (serviceDataMapEntry.classCount * this.state.checkin_limit_map[service]);
          occupancyRateData.push(BarDataPoint(Math.round(10 * ratio) / 10, service, SERVICE_COLOR_MAP[service]));
        }
      }
      else {
        serviceClassPoints.push(StackPoint(0, service, 0));
        serviceCheckinPoints.push(StackPoint(0, service, 0));
        serviceExperimentalPoints.push(StackPoint(0, service, 0));
      }
    }

    const overviewData = [
      StackGroup('Aulas', serviceClassPoints, '#93bfeb'),
      StackGroup('Chekins', serviceCheckinPoints, '#1866b4'),
      StackGroup('Alunos experimentais', serviceExperimentalPoints, '#78bb67'),
    ];

    occupancyRateData.sort((a, b) => b.value - a.value);

    this.setState({
      analysisData: {
        checkinCount,
        overviewData,
        occupancyRateData,
        coachMap
      }
    });
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPerClassificationGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  getServiceTotalText(entry) {
    let text = entry.classCount;

    if (entry.salary !== null) {
      text = `${entry.classCount} (${getCurrencyText(entry.salary)})`;
    }

    if (entry.serviceFinancialEntry) {
      return (
        <p className="class-report__coach-report__total-text">

          {text}

          <a
            className="class-report__coach-report__total-text__link"
            href={`${routes.EXPENSE_EDIT_PATH}${entry.serviceFinancialEntry.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >

            <i className="fas fa-link"></i>

          </a>

        </p>
      );
    }

    return text
  }

  getCoachReportEntryProperties() {
    let properties = [
      Property('service', 'Serviço', <i className="fa-solid fa-bell-concierge"></i>),
      Property('green', '[100%, 75%]', null, {
        cellClassName: "class-report__coach-report__green-cell",
        headerClassName: "class-report__coach-report__green-header"
      }),
      Property('yellow', ']75%, 50%]', null, {
        cellClassName: "class-report__coach-report__yellow-cell",
        headerClassName: "class-report__coach-report__yellow-header"
      }),
      Property('red', ']50%, 0%[', null, {
        cellClassName: "class-report__coach-report__red-cell",
        headerClassName: "class-report__coach-report__red-header"
      }),
      Property('white', '0%', null, {
        cellClassName: "class-report__coach-report__white-cell",
        headerClassName: "class-report__coach-report__white-header"
      }),
      Property('classCount', 'Total', null, {getDataText: this.getServiceTotalText, getFilterText: this.getServiceTotalText}),
    ];

    return properties;
  }

  onRegisterSalary(coach) {
    this.setState({
      coachToRegister: coach,
      reportDataToSend: null
    });
  }

  onSendClassReport(reportData) {
    this.setState({
      coachToRegister: null,
      reportDataToSend: reportData
    });
  }

  getCoachReports() {
    if (this.state.analysisData === null) {
      return null;
    }

    const reportEntries = [];
    let totalMonthSalary = 0;
    let totalRegisteredSalary = 0;

    for (const coach of this.state.coaches) {
      if (this.state.analysisData.coachMap.has(coach.id)) {
        const serviceMap = this.state.analysisData.coachMap.get(coach.id);

        let salaryMapIsValid = false;
        let salaryMap = null;

        const serviceEntries = [];
        let totalSalary = 0;

        if (coach.role_experience_level !== null && typeof coach.role_experience_level.salary_map !== 'undefined') {
          salaryMap = coach.role_experience_level.salary_map;
          salaryMapIsValid = true;
        }

        let financialEntries = [];
        let totalSalaryExpense = 0;

        if (this.state.coachSalaryMap.has(coach.id)) {
          financialEntries = this.state.coachSalaryMap.get(coach.id);
        }

        for (const service of this.state.services) {
          if (serviceMap.has(service)) {
            const serviceData = serviceMap.get(service);

            const costCenterId = SERVICE_COST_CENTER_MAP[service];
            const serviceFinancialEntry = financialEntries.find((entry) => entry.is_expense && entry.cost_center_id === costCenterId);

            if (serviceFinancialEntry) {
              totalSalaryExpense += serviceFinancialEntry.total_value;
            }

            const serviceEntry = {
              id: `coach_report:${coach.id}:service:${service}`,
              service,
              classCount: serviceData.classCount,
              green: serviceData.performance[0],
              yellow: serviceData.performance[1],
              red: serviceData.performance[2],
              white: serviceData.performance[3],
              salary: null,
              serviceFinancialEntry
            };

            if (salaryMap === null || typeof salaryMap[service] === 'undefined' || salaryMap[service].length !== 4) {
              salaryMapIsValid = false;
            }
            else {
              serviceEntry.salary = serviceEntry.green * salaryMap[service][0] +
                                    serviceEntry.yellow * salaryMap[service][1] +
                                    serviceEntry.red * salaryMap[service][2] +
                                    serviceEntry.white * salaryMap[service][3];

              totalSalary += serviceEntry.salary;
            }

            serviceEntries.push(serviceEntry);
          }
        }

        totalMonthSalary += totalSalary;
        totalRegisteredSalary += totalSalaryExpense;

        reportEntries.push(
          <React.Fragment key={`coach_report:${coach.id}`}>

            <section className="class-report__report-section">

              <header
                className="class-report__report-section__header"
                onClick={() => {
                  const coachReportVisibilityMap = new Map(this.state.coachReportVisibilityMap);

                  coachReportVisibilityMap.set(coach.id, !coachReportVisibilityMap.has(coach.id) || !coachReportVisibilityMap.get(coach.id))

                  this.setState({coachReportVisibilityMap});
                }}
              >

                <h3 className="class-report__report-section__header__text">
                  <i className="fa-solid fa-user-ninja class-report__report-section__header__text-icon"></i>
                  {coach.name}
                </h3>

                {this.state.coachReportVisibilityMap.get(coach.id) ?
                  <i className="fas fa-chevron-down class-report__report-section__header__visible-icon"></i>:
                  <i className="fas fa-chevron-up class-report__report-section__header__visible-icon"></i>
                }

              </header>

              <VerticalAccordionContainer
                className="vertical-accordion-container class-report__report-section__content"
                pose={this.state.coachReportVisibilityMap.get(coach.id) ? 'verticalOpen' : 'verticalClosed'}
              >

                <div className="class-report__report-section__wrapper--stretched">

                  {!salaryMapIsValid ? (
                    <p className="class-report__alert-text">

                      <i className="fas fa-exclamation class-report__alert-text__icon"></i>
                      <span className="class-report__alert-text__text">
                        Nível de experiência não configurado
                      </span>

                    </p>
                  ) : (
                    <p className="class-report__coach-report__role-experiente-level">{coach.role_experience_level.name}</p>
                  )}

                  <ModelTable
                    properties={this.getCoachReportEntryProperties()}
                    data={serviceEntries}
                    initialLinesPerPage={serviceEntries.length}
                    initialOrderBy="service"
                    hideFilter={true}
                    hideLinesPerPageControl={true}
                    hideNavigationControls={true}
                  >

                  </ModelTable>

                  {salaryMapIsValid &&
                    <div className="class-report__coach-report__resume">

                      <div className="class-report__coach-report__resume__line">

                        <h4 className="class-report__coach-report__resume__label">{this.state.screenWidth > 510 ? 'Salário do período' : 'Salário'}</h4>
                        <div className="class-report__coach-report__resume__separator"></div>
                        <p className="class-report__coach-report__resume__value">{getCurrencyText(totalSalary)}</p>

                      </div>

                      <div className="class-report__coach-report__resume__line">

                        <h4 className="class-report__coach-report__resume__label">{this.state.screenWidth > 510 ? 'Total cadastrado' : 'Cadastrado'}</h4>
                        <div className="class-report__coach-report__resume__separator"></div>
                        <p className="class-report__coach-report__resume__value">{getCurrencyText(totalSalaryExpense)}</p>

                      </div>

                    </div>
                  }

                  {(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                    this.props.userPermissionIds.includes(permissions.ADD_FINANCIAL_ENTRIES_PERMISSION_ID) &&
                    this.props.userPermissionIds.includes(permissions.EDIT_COACH_PERMISSION_ID) &&
                    salaryMapIsValid &&
                    totalSalary > 0) &&
                    <React.Fragment>

                      <HorizontalRule />

                      <div className="class-report__coach-report__actions-container">

                        <button
                          className="class-report__coach-report__action-button"
                          disabled={financialEntries.length > 0}
                          onClick={(event) => this.onRegisterSalary(coach)}
                        >

                          <i className="fa-solid fa-money-check-dollar"></i> {financialEntries.length > 0 ? 'Salário cadastrado' : 'Cadastrar salário'}

                        </button>

                        {financialEntries.length > 0 &&
                          <button
                            className="class-report__coach-report__action-button"
                            disabled={this.state.reportEmailSentMap.has(coach.id)}
                            onClick={(event) => this.onSendClassReport({
                              coach,
                              serviceEntries
                            })}
                          >

                            <i className="fas fa-envelope"></i> {this.state.reportEmailSentMap.has(coach.id) ? 'Email enviado' : 'Enviar relatório de aulas'}

                          </button>
                        }

                      </div>

                    </React.Fragment>
                  }

                </div>

              </VerticalAccordionContainer>

            </section>

          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>

        <div className="class-report__indicator-container--spaced">

          <div className="class-report__indicator">

            <h2 className="class-report__indicator__label">Salário estimado:</h2>
            <p className="class-report__indicator__value">{getCurrencyText(totalMonthSalary)}</p>

          </div>

          <div className="class-report__indicator">

            <h2 className="class-report__indicator__label">Salário cadastrado:</h2>
            <p className="class-report__indicator__value">{getCurrencyText(totalRegisteredSalary)}</p>

          </div>

        </div>

        {reportEntries}

      </React.Fragment>
    );
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.coachToRegister != null) {
      const salary_entries = [];

      for (const [key, value] of this.state.analysisData.coachMap.get(this.state.coachToRegister.id)) {
        const salary = value.performance[0] * this.state.coachToRegister.role_experience_level.salary_map[key][0] +
                       value.performance[1] * this.state.coachToRegister.role_experience_level.salary_map[key][1] +
                       value.performance[2] * this.state.coachToRegister.role_experience_level.salary_map[key][2] +
                       value.performance[3] * this.state.coachToRegister.role_experience_level.salary_map[key][3];

        salary_entries.push({
          service: key,
          value: salary,
          class_count: value.classCount
        });
      }

      const data = {
        payment_date: getLocalDateIsoString(new Date()),
        month_reference: this.state.initialDate.slice(0, 7),
        salary_entries
      };

      let response;

      try{
        response = await postModel(routes.FINANCIAL_COACH_CLASS_SALARY_POST_API.replace('{coach_id}', this.state.coachToRegister.id), data, true);

        const coachSalaryMap = new Map(this.state.coachSalaryMap);
        coachSalaryMap.set(this.state.coachToRegister.id, response.financial_entries);

        this.setState({coachSalaryMap});
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.reportDataToSend != null) {
      const data = {
        coach_id: this.state.reportDataToSend.coach.id,
        month_reference: this.state.initialDate.slice(0, 7),
        serviceEntries: this.state.reportDataToSend.serviceEntries.map((entry) => {
          return {
            service: entry.service,
            class_count: entry.classCount,
            green: entry.green,
            yellow: entry.yellow,
            red: entry.red,
            white: entry.white,
            salary: entry.salary,
          };
        })
      };

      try{
        if(await postModel(routes.NOTIFICATION_COACH_CLASS_SALARY_REPORT_POST_API, data)) {
          const reportEmailSentMap = new Map(this.state.reportEmailSentMap);
          reportEmailSentMap.set(this.state.reportDataToSend.coach.id, true);

          this.setState({
            reportEmailSentMap
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      coachToRegister: null,
      reportDataToSend: null,
      confirmFailed: false,
      confirmInProgress: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.coachToRegister != null) {
        return 'Cadastrando salário';
      }
      else if(this.state.reportDataToSend != null) {
        return 'Enviando relatório';
      }

      return 'Indeterminado';
    }
    else if(this.state.confirmFailed) {
      if(this.state.coachToRegister != null) {
        return 'Falha ao cadastrar salário';
      }
      else if(this.state.reportDataToSend != null) {
        return 'Falha ao enviar email';
      }

      return 'Indeterminado';
    }

    if(this.state.coachToRegister != null) {
      return 'Cadastrar salário';
    }
    else if(this.state.reportDataToSend != null) {
      return 'Enviar relatório';
    }

    return 'Indeterminado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.coachToRegister != null) {
      return `Tem certeza que gostaria de cadastrar o salário do(a) professor(a) ${this.state.coachToRegister.name} no financeiro?`;
    }
    else if(this.state.reportDataToSend != null) {
      return `Um email contendo um relatório sobre as aulas trabalhas pelo professor(a) ${this.state.reportDataToSend.coach.name} será enviado.`;
    }

    return 'Indeterminado';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.coachToRegister != null) {
      return 'Cadastrar';
    }
    else if(this.state.reportDataToSend != null) {
      return 'Enviar';
    }
  }

  onCancelConfirmation() {
    this.setState({
      coachToRegister: null,
      reportDataToSend: null,
      confirmFailed: false
    });
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.coachToRegister !== null || this.state.reportDataToSend !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.CLASS_REPORT_PATH,
              text: "Relatório de aulas"
            },
          ]}
          titleIcon={<i className="fas fa-chart-line"></i>}
          title="Relatório de aulas"
          loading={this.state.loadingData}
        >

          <div className="class-report__wrapper">

            <div className="class-report__period-control">

              <h3 className="class-report__period-control__title">Período de avaliação</h3>

              <div className="class-report__period-control__inputs-container">
                <HalfWrapper className="class-report__period-control__inputs">

                  <DefaultInput
                    name="month_reference"
                    label="Mês de referência"
                    type="month"
                    placeholder="Mês"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.month_reference}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />

                </HalfWrapper>

                <button
                  className="class-report__period-control__refresh-button"
                  onClick={() => this.applyDateInputChanges()}
                  disabled={!this.mayUpdateDateInputs()}
                >

                  <i className="fas fa-sync"></i>

                </button>
              </div>

            </div>

            <HorizontalRule />

            {this.state.analysisData !== null ?
              <React.Fragment>

                <DefaultSubSectionTitle
                  icon={<i className="far fa-chart-bar"></i>}
                  text="Resumo do período"
                />

                <div className="class-report__indicator-container--spaced">

                  <div className="class-report__indicator">

                    <h2 className="class-report__indicator__label">Aulas executadas:</h2>
                    <p className="class-report__indicator__value">{this.state.trainingClasses.length}</p>

                  </div>

                  <div className="class-report__indicator">

                    <h2 className="class-report__indicator__label">Total de checkins:</h2>
                    <p className="class-report__indicator__value">{this.state.analysisData.checkinCount}</p>

                  </div>

                  <div className="class-report__indicator">

                    <h2 className="class-report__indicator__label">Aulas experimentais:</h2>
                    <p className="class-report__indicator__value">{this.state.experimentalClasses.length}</p>

                  </div>

                </div>

                <StackedBarGraph
                  className="class-report__graph"
                  data={this.state.analysisData.overviewData}
                  lineYAxisType="secondary"
                  doNotStack={true}
                  isHorizontal={true}
                  normalXLabel={true}
                  normalLegendOrder={true}
                  ToolTipValueCallback={(value) => `${value}`}
                  height={this.getDefaultGraphHeight()}
                  legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                  legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                />

                <HorizontalBarGraph
                  className="class-report__graph"
                  title="Taxa de ocupação média"
                  xLabel="Serviço"
                  yLabel="Ocupação"
                  data={this.state.analysisData.occupancyRateData}
                  height={this.getDefaultGraphHeight()}
                  toolTipValueCallback={(value) => `${value}%`}
                />

                <HorizontalRule />

                <DefaultSubSectionTitle
                  icon={<i className="fa-solid fa-money-check-dollar"></i>}
                  text="Salário de professores"
                />

                {this.getCoachReports()}

              </React.Fragment>:
              null
            }

          </div>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ClassReport;
