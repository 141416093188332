import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {postModel} from '../../utils/functions';
import AccessControlDeviceData from './access_control_device_data';

class AccessControlDeviceAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access_control_device: {
        name: "",
        address_ipv4: "",
        address_ipv6: null,
        description: "",
        is_active: true,
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.access_control_device, [name]: value};

    this.setState({
      access_control_device: newData
    });
  }

  inputsAreValid() {
    return this.state.access_control_device.name.length > 0 &&
           (this.state.access_control_device.address_ipv4 !== null && this.state.access_control_device.address_ipv4.length > 0);
  }

  async saveData() {
    const regex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})(:\d+)?$/;

    const ipv4Match = this.state.access_control_device.address_ipv4.match(regex);

    let invalidIpv4Format = true;

    if (ipv4Match !== null) {
      invalidIpv4Format = false;

      for (let i=1; i < ipv4Match.length-1; ++i) {
        if (parseInt(ipv4Match[i]) > 255) {
          invalidIpv4Format = true;

          break;
        }
      }
    }

    if (invalidIpv4Format) {
      this.setState({
        highlights: ['address_ipv4'],
        showWarningMessage: true,
        warningMessage: `Formato de IP inválido.`,
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.access_control_device};

    try {
      await postModel(routes.ACCESS_CONTROL_DEVICE_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastro');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.ACCESS_CONTROL_DEVICE_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.ACCESS_CONTROL_DEVICE_LIST_PATH,
            text: "Listar controladores de acesso"
          },
          {
            path: routes.ACCESS_CONTROL_DEVICE_ADD_PATH,
            text: "Adicionar dispositivo"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar controlador de acesso"
        loading={this.state.loading}
      >

        <AccessControlDeviceData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          access_control_device={this.state.access_control_device}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.ACCESS_CONTROL_DEVICE_LIST_PATH}
        />

      </ContentFrame>
    );
  }
}

export default AccessControlDeviceAdd;
