import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {getModel, patchModel, getModels, deleteModel, setUrlParameters} from '../../../utils/functions';
import FoodIngredientData from './food_ingredient_data';
import OverlayWindow from '../../../components/overlay_window';
import DefaultInput, {SelectOption, HalfWrapper} from '../../../utils/default_input';
import DefaultMenuButton from '../../../components/default_menu_button';
import ConfirmationWindow from '../../confirmation_window';
import './food_ingredient_edit.scss';
import {DEFAULT_FOOD_INFO_SOURCE, DEFAULT_ENERGY_UNIT, DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../../constants';

class FoodIngredientEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_ingredient: {
        name: "",
        food_classification_id: null,
        default_nutrient_source_id: '',
        default_measurement_source_id: '',
        description: "",
        food_allergens: [],
      },
      info_sources: [],
      food_classifications: [],
      nutrient_classifications: [],
      food_allergens: [],
      ingredient_processing_methods: [],
      food_nutrients: [],
      weight_units: [],
      food_measurements: [],
      activeNutrientInfoSourceId: null,
      activeMeasurementInfoSourceId: null,
      selectedSourceAssociation: null,
      newProcessingMethod: false,
      onSelectNewProcessingMethod: false,
      onSelectNewMeasurement: false,
      onSelectNewNutrientAssociation: false,
      measurementAssociationToEdit: null,
      selectedNewProcessingMethodId: '',
      selectedNewMeasurementId: '',
      selectedNewFoodNutrientId: '',
      overlayValueInput: null,
      overlayValueInput2: null,
      overlayWeightUnitId: '',
      overlayTextInput: '',
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      loadingAdditionalData: false,
      sourceAssociationIdTodelete: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
    };
  }

  sortSourceAssociationCallback(a, b) {
    if (a.processing_method === null && b.processing_method !== null) {
      return 1;
    }
    else if (a.processing_method !== null && b.processing_method === null) {
      return -1;
    }
    else if (a.processing_method === null && b.processing_method === null) {
      return 0;
    }

    return a.processing_method.name.localeCompare(b.processing_method.name);
  }

  async componentDidMount() {
    const update = {loading: false};

    let food_ingredient = getModel(`${routes.FOOD_INGREDIENT_GET_API}${this.props.match.params.foodIngredientId}`);
    let info_sources = getModels(routes.FOOD_INFO_SOURCES_GET_API);
    let food_classifications = getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));
    let nutrient_classifications = getModels(routes.NUTRIENT_CLASSIFICATIONS_GET_API);
    let food_allergens = getModels(routes.FOOD_ALLERGENS_GET_API);

    let infoSourceSelected = false;
    let sourceAssociationSelected = false;

    food_ingredient = await food_ingredient;

    if(food_ingredient) {
      update.food_ingredient = {...this.state.food_ingredient, ...food_ingredient};

      update.food_ingredient.source_associations.sort((a, b) => this.sortSourceAssociationCallback(a, b));

      info_sources = await info_sources;

      if(info_sources) {
        update.info_sources = info_sources;

        const sourceIdsSet = new Set(update.food_ingredient.source_associations.map((entry) => entry.food_info_source_id));

        if (update.food_ingredient.source_associations.length > 0) {
          for (const sourceEntry of update.info_sources) {
            if (sourceIdsSet.has(sourceEntry.id)) {
              update.activeNutrientInfoSourceId = sourceEntry.id;
              infoSourceSelected = true;
              break;
            }
          }
        }
      }

      if (update.food_ingredient.source_associations.length === 1) {
        update.selectedSourceAssociation = update.food_ingredient.source_associations[0];
        sourceAssociationSelected = true;
      }
      else if (infoSourceSelected) {
        const filteredSourcesAssociations = update.food_ingredient.source_associations.filter((association) => association.food_info_source_id === update.activeNutrientInfoSourceId);

        if (filteredSourcesAssociations.length === 1) {
          update.selectedSourceAssociation = filteredSourcesAssociations[0];
          sourceAssociationSelected = true;
        }
      }

      if (infoSourceSelected && sourceAssociationSelected) {
        const filteredMeasurementAssociations = update.food_ingredient.food_measurement_associations.filter((entry) => entry.processing_method_id === update.selectedSourceAssociation.processing_method_id);
        const sourceIdsSet = new Set(filteredMeasurementAssociations.map((entry) => entry.food_info_source_id));

        if (sourceIdsSet.has(update.activeNutrientInfoSourceId)) {
          update.activeMeasurementInfoSourceId = update.activeNutrientInfoSourceId;
        }
        else {
          for (const sourceEntry of update.info_sources) {
            if (sourceIdsSet.has(sourceEntry.id)) {
              update.activeMeasurementInfoSourceId = sourceEntry.id;
              break;
            }
          }
        }
      }

      food_classifications = await food_classifications;

      if(food_classifications) {
        update.food_classifications = food_classifications;
      }

      nutrient_classifications = await nutrient_classifications;

      if(nutrient_classifications) {
        update.nutrient_classifications = nutrient_classifications;

        update.nutrient_classifications.sort((a, b) => a.id - b.id)
      }

      food_allergens = await food_allergens;

      if(food_allergens) {
        update.food_allergens = food_allergens;
        update.food_allergens.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.FOOD_INGREDIENT_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'selectedSourceAssociation') {
      const update = {
        selectedSourceAssociation: this.state.food_ingredient.source_associations.find((entry) => {
          if (entry.food_info_source_id !== this.state.activeNutrientInfoSourceId) {
            return false;
          }

          if (value !== '0') {
            return entry.processing_method_id === parseInt(value);
          }
          else {
            return entry.processing_method_id === null;
          }
        })
      }

      const filteredMeasurementAssociations = this.state.food_ingredient.food_measurement_associations.filter((entry) => entry.processing_method_id === update.selectedSourceAssociation.processing_method_id);
      const sourceIdsSet = new Set(filteredMeasurementAssociations.map((entry) => entry.food_info_source_id));

      if (sourceIdsSet.has(this.state.activeNutrientInfoSourceId)) {
        update.activeMeasurementInfoSourceId = this.state.activeNutrientInfoSourceId;
      }
      else {
        for (const sourceEntry of this.state.info_sources) {
          if (sourceIdsSet.has(sourceEntry.id)) {
            update.activeMeasurementInfoSourceId = sourceEntry.id;
            break;
          }
        }
      }

      this.setState(update);
    }
    else if (name === 'selectedNewProcessingMethodId' ||
             name === 'selectedNewMeasurementId' ||
             name === 'selectedNewFoodNutrientId' ||
             name === 'overlayTextInput' ||
             name === 'overlayValueInput' ||
             name === 'overlayValueInput2' ||
             name === 'overlayWeightUnitId') {
      this.setState({[name]: value});
    }
    else if (name === 'defaultMeasurementId') {
      const selectedSourceAssociation = this.state.selectedSourceAssociation;

      selectedSourceAssociation.default_measurement_id = parseInt(value);

      this.setState({selectedSourceAssociation});
    }
    else if(name.startsWith('source_association')) {
      const selection = name.split(':');

      const variable = selection[1];

      const selectedSourceAssociation = this.state.selectedSourceAssociation;

      switch (variable) {
        case 'energy':
          selectedSourceAssociation.energy = value;
          break;
        case 'description':
          selectedSourceAssociation.description = value;
          break;
        case 'nutrient':
          const food_nutrient_id = parseInt(selection[2]);

          selectedSourceAssociation.nutrient_associations.find((entry) => entry.nutrient.id === food_nutrient_id).weight_value = value;
          break;
        default:
      }

      this.setState({selectedSourceAssociation});
    }
    else {
      const newData = {...this.state.food_ingredient, [name]: value};

      this.setState({
        food_ingredient: newData
      });
    }
  }

  onSetFoodAllergen(allergen, include) {
    let foodAllergensCopy = this.state.food_ingredient.food_allergens.map((entry) => ({...entry}));

    foodAllergensCopy = foodAllergensCopy.filter((entry) => entry.id !== allergen.id);

    if (include) {
      foodAllergensCopy.push({...allergen});
    }

    const food_ingredient = {
      ...this.state.food_ingredient,
      food_allergens: foodAllergensCopy
    };

    this.setState({food_ingredient});
  }

  inputsAreValid() {
    return this.state.food_ingredient.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let standard_source_associations = this.state.food_ingredient.source_associations.filter((association) => association.food_info_source_id !== DEFAULT_FOOD_INFO_SOURCE.id);
    standard_source_associations = standard_source_associations.map((association) => {
      return {
        id: association.id,
        default_measurement_id: parseInt(association.default_measurement_id),
      };
    });

    let manual_source_associations = this.state.food_ingredient.source_associations.filter((association) => association.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id);
    manual_source_associations = manual_source_associations.map((association) => {
      return {
        id: association.id || null,
        energy: association.energy ? parseFloat(association.energy) : null,
        energy_unit_id: association.energy_unit_id,
        weight_reference: parseFloat(association.weight_reference),
        weight_reference_unit_id: association.weight_reference_unit_id,
        // food_ingredient_id: association.food_ingredient_id,
        // source_ingredient: association.source_ingredient,
        // food_info_source_id: association.food_info_source_id,
        processing_method_id: association.processing_method_id,
        default_measurement_id: association.default_measurement_id && association.default_measurement_id !== '0' ? parseInt(association.default_measurement_id) : null,
        description: association.description,
        nutrient_associations: association.nutrient_associations.map((entry) => {
          return {
            food_nutrient_id: entry.food_nutrient_id,
            weight_unit_id: entry.weight_unit_id,
            weight_value: entry.weight_value ? parseFloat(entry.weight_value) : null,
          };
        }),
      };
    });

    let manual_measurement_associations = this.state.food_ingredient.food_measurement_associations.filter((association) => association.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id);
    manual_measurement_associations = manual_measurement_associations.map((association) => {
      return {
        id: association.id || null,
        food_measurement_id: association.food_measurement_id,
        weight_reference_unit_id: association.weight_reference_unit_id,
        processing_method_id: association.processing_method_id,
        weight_reference: association.weight_reference ? parseFloat(association.weight_reference) : null,
        description: association.description,
      };
    });

    const data = {
      name: this.state.food_ingredient.name,
      description: this.state.food_ingredient.description,
      food_classification_id: parseInt(this.state.food_ingredient.food_classification_id),
      default_nutrient_source_id: this.state.food_ingredient.default_nutrient_source_id,
      default_measurement_source_id: this.state.food_ingredient.default_measurement_source_id,
      food_allergen_ids: [...this.state.food_ingredient.food_allergens.map((entry) => entry.id)],
      standard_source_associations,
      manual_source_associations,
      manual_measurement_associations
    }

    if (typeof this.state.food_ingredient.default_nutrient_source_id === 'string') {
      if (this.state.food_ingredient.default_nutrient_source_id.length > 0) {
        data.default_nutrient_source_id = parseInt(this.state.food_ingredient.default_nutrient_source_id);
      }
      else {
        data.default_nutrient_source_id = null;
      }
    }

    if (typeof this.state.food_ingredient.default_measurement_source_id === 'string') {
      if (this.state.food_ingredient.default_measurement_source_id.length > 0) {
        data.default_measurement_source_id = parseInt(this.state.food_ingredient.default_measurement_source_id);
      }
      else {
        data.default_measurement_source_id = null;
      }
    }

    try {
      await patchModel(`${routes.FOOD_INGREDIENT_PATCH_API}${this.props.match.params.foodIngredientId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'period':
            //         warningMessages.push('Período deve ser maior que 0');
            //         highlights.push('period');
            //
            //         break;
            //       case 'value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('value');
            //
            //         break;
            //       case 'penalty_value':
            //         warningMessages.push('Valor deve ser positivo');
            //         highlights.push('penalty_value');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FOOD_INGREDIENT_LIST_PATH);
  }

  onSelectNutrientInfoSource(infoSourceId, source_associations) {
    const update = {activeNutrientInfoSourceId: infoSourceId};

    const filteredSourcesAssociations = source_associations.filter((association) => association.food_info_source_id === infoSourceId);

    if (filteredSourcesAssociations.length === 1) {
      update.selectedSourceAssociation = filteredSourcesAssociations[0];

      this.onSelectMeasurementInfoSource(infoSourceId, this.state.food_ingredient.food_measurement_associations, update.selectedSourceAssociation);
    }
    else {
      update.selectedSourceAssociation = null;
      update.activeMeasurementInfoSourceId = null;
    }

    this.setState(update);
  }

  onSelectMeasurementInfoSource(infoSourceId, food_measurement_associations, selectedSourceAssociation) {
    const update = {};

    const filteredMeasurementAssociations = food_measurement_associations.filter((entry) => entry.processing_method_id === selectedSourceAssociation.processing_method_id);
    const sourceIdsSet = new Set(filteredMeasurementAssociations.map((entry) => entry.food_info_source_id));

    if (sourceIdsSet.has(infoSourceId)) {
      update.activeMeasurementInfoSourceId = infoSourceId;
    }
    else {
      for (const sourceEntry of this.state.info_sources) {
        if (sourceIdsSet.has(sourceEntry.id)) {
          update.activeMeasurementInfoSourceId = sourceEntry.id;
          break;
        }
      }
    }

    this.setState(update);
  }

  getIngredientProcessingMethodOptions() {
    const filteredAssociations = this.state.food_ingredient.source_associations.filter((association) => association.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id);
    const methodIdsSet = new Set(filteredAssociations.map((entry) => entry.processing_method_id));

    const filteredProcessingMethods = this.state.ingredient_processing_methods.filter((entry) => !methodIdsSet.has(entry.id));

    const options = [];

    if (this.state.selectedNewProcessingMethodId.length <= 0) {
      options.push(SelectOption('', 'Selecione um método de processamento'));
    }

    if (!methodIdsSet.has(null)) {
      options.push(SelectOption('0', 'Não se aplica'));
    }

    options.push(...filteredProcessingMethods.map((method) => SelectOption(method.id, method.name)));

    return options;
  }

  getFoodMeasurementOptions() {
    const filteredAssociations = this.state.food_ingredient.food_measurement_associations.filter((association) => {
      return association.food_info_source_id === DEFAULT_FOOD_INFO_SOURCE.id &&
             association.processing_method_id === this.state.selectedSourceAssociation.processing_method_id
    });
    const measurementIdsSet = new Set(filteredAssociations.map((entry) => entry.food_measurement_id));

    const filteredMeasurements = this.state.food_measurements.filter((entry) => !measurementIdsSet.has(entry.id));

    const options = [];

    if (this.state.selectedNewMeasurementId.length <= 0) {
      options.push(SelectOption('', 'Selecione uma medida de referência'));
    }

    options.push(...filteredMeasurements.map((measurement) => SelectOption(measurement.id, measurement.name)));

    return options;
  }

  getFoodNutrientOptions() {
    const nutrientIdsSet = new Set(this.state.selectedSourceAssociation.nutrient_associations.map((entry) => entry.food_nutrient_id));

    const filteredFoodNutrients = this.state.food_nutrients.filter((entry) => !nutrientIdsSet.has(entry.id));

    const options = [];

    if (this.state.selectedNewFoodNutrientId.length <= 0) {
      options.push(SelectOption('', 'Selecione um nutriente'));
    }

    const classificationMap = new Map(this.state.nutrient_classifications.map((entry) => [entry.id, entry.name]));

    options.push(...filteredFoodNutrients.map((entry) => SelectOption(entry.id, `${classificationMap.get(entry.nutrient_classification_id)} - ${entry.fullname}`)));

    return options;
  }

  getWeightUnitOptions() {
    const options = [];

    if (this.state.overlayWeightUnitId.length <= 0) {
      options.push(SelectOption('', 'Selecione uma unidade'));
    }

    options.push(...this.state.weight_units.map((entry) => SelectOption(entry.id, entry.shortname)));

    return options;
  }

  async onAddNutrientSource() {
    if (this.state.ingredient_processing_methods.length <= 0 || this.state.weight_units.length <= 0) {
      this.setState({
        onSelectNewProcessingMethod: true,
        loadingAdditionalData: true
      });

      let ingredient_processing_methods = getModels(routes.INGREDIENT_PROCESSING_METHODS_GET_API);
      let weight_units = getModels(routes.WEIGHT_UNITS_GET_API);

      const update = {loadingAdditionalData: false};

      ingredient_processing_methods = await ingredient_processing_methods;

      if(ingredient_processing_methods) {
        ingredient_processing_methods.sort((a, b) => a.name.localeCompare(b.name));

        update.ingredient_processing_methods = ingredient_processing_methods;
      }

      weight_units = await weight_units;

      if(weight_units) {
        update.weight_units = weight_units;
      }

      this.setState(update);
    }
    else {
      this.setState({
        onSelectNewProcessingMethod: true,
      });
    }
  }

  async onAddMeasurementSource() {
    if (this.state.food_measurements.length <= 0 || this.state.weight_units.length <= 0) {
      this.setState({
        onSelectNewMeasurement: true,
        loadingAdditionalData: true
      });

      let food_measurements = getModels(setUrlParameters(routes.FOOD_MEASUREMENTS_GET_API, {load_volume_unit: true}));
      let weight_units = getModels(routes.WEIGHT_UNITS_GET_API);

      const update = {loadingAdditionalData: false};

      food_measurements = await food_measurements;

      if(food_measurements) {
        food_measurements.sort((a, b) => a.name.localeCompare(b.name));

        update.food_measurements = food_measurements;
      }

      weight_units = await weight_units;

      if(weight_units) {
        update.weight_units = weight_units;
      }

      this.setState(update);
    }
    else {
      this.setState({
        onSelectNewMeasurement: true,
      });
    }
  }

  async onEditMeasurementSource(measurementEntry) {
    const measurementAssociation = this.state.food_ingredient.food_measurement_associations.find((entry) => {
      return entry.processing_method_id === this.state.selectedSourceAssociation.processing_method_id &&
             entry.food_info_source_id === this.state.activeMeasurementInfoSourceId &&
             entry.food_measurement_id === measurementEntry.food_measurement_id;
    });

    if (this.state.food_measurements.length <= 0 || this.state.weight_units.length <= 0) {
      this.setState({
        measurementAssociationToEdit: measurementAssociation,
        overlayWeightUnitId: measurementAssociation.weight_reference_unit_id.toString(),
        overlayValueInput: measurementAssociation.weight_reference,
        overlayTextInput: measurementAssociation.description,
        loadingAdditionalData: true
      });

      let food_measurements = getModels(setUrlParameters(routes.FOOD_MEASUREMENTS_GET_API, {load_volume_unit: true}));
      let weight_units = getModels(routes.WEIGHT_UNITS_GET_API);

      const update = {loadingAdditionalData: false};

      food_measurements = await food_measurements;

      if(food_measurements) {
        food_measurements.sort((a, b) => a.name.localeCompare(b.name));

        update.food_measurements = food_measurements;
      }

      weight_units = await weight_units;

      if(weight_units) {
        update.weight_units = weight_units;
      }

      this.setState(update);
    }
    else {
      this.setState({
        measurementAssociationToEdit: measurementAssociation,
        overlayWeightUnitId: measurementAssociation.weight_reference_unit_id.toString(),
        overlayValueInput: measurementAssociation.weight_reference,
        overlayTextInput: measurementAssociation.description,
      });
    }
  }

  onRemoveMeasurementSource(measurementEntry) {
    const food_ingredient = {...this.state.food_ingredient};

    food_ingredient.food_measurement_associations = food_ingredient.food_measurement_associations.filter((entry) => {
      return entry.processing_method_id !== this.state.selectedSourceAssociation.processing_method_id ||
             entry.food_info_source_id !== this.state.activeMeasurementInfoSourceId ||
             entry.food_measurement_id !== measurementEntry.food_measurement_id;
    });

    this.setState({food_ingredient});

    if (!food_ingredient.food_measurement_associations.some((entry) => entry.food_info_source_id === this.state.activeMeasurementInfoSourceId && entry.processing_method_id === this.state.selectedSourceAssociation.processing_method_id)) {
      this.onSelectMeasurementInfoSource(this.state.activeNutrientInfoSourceId, food_ingredient.food_measurement_associations, this.state.selectedSourceAssociation);
    }
  }

  async onAddNutrientAssociation() {
    if (this.state.food_nutrients.length <= 0 || this.state.weight_units.length <= 0) {
      this.setState({
        onSelectNewNutrientAssociation: true,
        loadingAdditionalData: true
      });

      let food_nutrients = getModels(routes.FOOD_NUTRIENTS_GET_API);
      let weight_units = getModels(routes.WEIGHT_UNITS_GET_API);

      const update = {loadingAdditionalData: false};

      food_nutrients = await food_nutrients;

      if(food_nutrients) {
        food_nutrients.sort((a, b) => {
          const classificationComparison = a.nutrient_classification_id - b.nutrient_classification_id;

          if (classificationComparison === 0) {
            return a.fullname.localeCompare(b.fullname);
          }

          return classificationComparison;
        });

        update.food_nutrients = food_nutrients;
      }

      weight_units = await weight_units;

      if(weight_units) {
        update.weight_units = weight_units;
      }

      this.setState(update);
    }
    else {
      this.setState({
        onSelectNewNutrientAssociation: true,
      });
    }
  }

  onRemoveNutrientSource() {
    if (this.state.selectedSourceAssociation.id && this.state.selectedSourceAssociation.id > 0) {
      this.setState({
        sourceAssociationIdTodelete: this.state.selectedSourceAssociation.id,
        confirmInProgress: false,
        confirmFailed: false
      });
    }
    else {
      const food_ingredient = {...this.state.food_ingredient};

      food_ingredient.source_associations = food_ingredient.source_associations.filter((association) => {
        return association.food_info_source_id !== this.state.selectedSourceAssociation.food_info_source_id ||
               association.processing_method_id !== this.state.selectedSourceAssociation.processing_method_id;
      });

      const sourceIdsSet = new Set(food_ingredient.source_associations.map((entry) => entry.food_info_source_id));

      if (sourceIdsSet.has(this.state.activeNutrientInfoSourceId)) {
        this.setState({food_ingredient});

        this.onSelectNutrientInfoSource(this.state.activeNutrientInfoSourceId, food_ingredient.source_associations);
      }
      else if (sourceIdsSet.size > 0) {
        for (const sourceEntry of this.state.info_sources) {
          if (sourceIdsSet.has(sourceEntry.id)) {
            this.setState({food_ingredient});

            this.onSelectNutrientInfoSource(sourceEntry.id, food_ingredient.source_associations);
            break;
          }
        }
      }
      else {
        this.setState({
          food_ingredient,
          activeNutrientInfoSourceId: null,
          selectedSourceAssociation: null,
          activeMeasurementInfoSourceId: null,
        });
      }
    }
  }

  onRemoveNutrientAssociation(association) {
    const selectedSourceAssociation = this.state.selectedSourceAssociation;

    selectedSourceAssociation.nutrient_associations = selectedSourceAssociation.nutrient_associations.filter((entry) => entry.food_nutrient_id !== association.food_nutrient_id);

    this.setState({selectedSourceAssociation});
  }

  onCancelOverlay() {
    this.setState({
      onSelectNewProcessingMethod: false,
      onSelectNewMeasurement: false,
      onSelectNewNutrientAssociation: false,
      measurementAssociationToEdit: null,
      selectedNewProcessingMethodId: '',
      selectedNewMeasurementId: '',
      selectedNewFoodNutrientId: '',
      overlayWeightUnitId: '',
      overlayValueInput: null,
      overlayValueInput2: null
    });
  }

  onConfirmOverlay() {
    if (this.state.onSelectNewProcessingMethod) {
      let processing_method_id = null;
      let processing_method = null;

      if (this.state.selectedNewProcessingMethodId.length > 0) {
        processing_method_id = parseInt(this.state.selectedNewProcessingMethodId);

        if (processing_method_id > 0) {
          processing_method = this.state.ingredient_processing_methods.find((entry) => entry.id === processing_method_id);
        }
        else {
          processing_method_id = null;
        }
      }

      const weight_reference_unit_id = parseInt(this.state.overlayWeightUnitId);
      const weight_reference_unit = this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId));

      const food_ingredient = {...this.state.food_ingredient};

      const newAssociation = {
        processing_method_id,
        processing_method,
        energy: this.state.overlayValueInput,
        energy_unit_id: DEFAULT_ENERGY_UNIT.id,
        energy_unit: DEFAULT_ENERGY_UNIT,
        weight_reference: this.state.overlayValueInput2,
        weight_reference_unit_id,
        weight_reference_unit,
        food_ingredient_id: this.state.food_ingredient.id,
        source_ingredient_id: null,
        food_info_source_id: DEFAULT_FOOD_INFO_SOURCE.id,
        default_measurement_id: null,
        description: '',
        nutrient_associations: []
      };

      food_ingredient.source_associations.push(newAssociation);

      food_ingredient.source_associations.sort((a, b) => this.sortSourceAssociationCallback(a, b));

      this.setState({
        food_ingredient,
        onSelectNewProcessingMethod: false,
        selectedNewProcessingMethodId: '',
        overlayWeightUnitId: '',
        overlayValueInput: null,
        overlayValueInput2: null,
      });

      if (this.state.activeNutrientInfoSourceId !== DEFAULT_FOOD_INFO_SOURCE.id) {
        this.onSelectNutrientInfoSource(DEFAULT_FOOD_INFO_SOURCE.id, food_ingredient.source_associations);
      } else {
        this.setState({
          selectedSourceAssociation: newAssociation
        });
      }
    }
    else if (this.state.onSelectNewMeasurement) {
      const food_measurement_id = parseInt(this.state.selectedNewMeasurementId);
      const food_measurement = this.state.food_measurements.find((entry) => entry.id === food_measurement_id);

      const weight_reference_unit_id = parseInt(this.state.overlayWeightUnitId);
      const weight_reference_unit = this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId));

      const food_ingredient = {...this.state.food_ingredient};

      const newAssociation = {
        food_measurement_id,
        food_measurement,
        weight_reference_unit_id,
        weight_reference_unit,
        processing_method_id: this.state.selectedSourceAssociation.processing_method_id,
        weight_reference: this.state.overlayValueInput,
        food_ingredient_id: this.state.food_ingredient.id,
        food_info_source_id: DEFAULT_FOOD_INFO_SOURCE.id,
        description: this.state.overlayTextInput,
      };

      food_ingredient.food_measurement_associations.push(newAssociation);

      this.setState({
        food_ingredient,
        onSelectNewMeasurement: false,
        selectedNewMeasurementId: '',
        overlayTextInput: '',
        overlayWeightUnitId: '',
        overlayValueInput: null,
        activeMeasurementInfoSourceId: DEFAULT_FOOD_INFO_SOURCE.id
      });
    }
    else if (this.state.measurementAssociationToEdit !== null) {
      const weight_reference_unit_id = parseInt(this.state.overlayWeightUnitId);
      const weight_reference_unit = this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId));

      const food_ingredient = {...this.state.food_ingredient};

      const measurementAssociationToEdit = this.state.measurementAssociationToEdit;

      measurementAssociationToEdit.weight_reference_unit_id = weight_reference_unit_id;
      measurementAssociationToEdit.weight_reference_unit = weight_reference_unit;
      measurementAssociationToEdit.weight_reference = this.state.overlayValueInput;
      measurementAssociationToEdit.description = this.state.overlayTextInput;

      this.setState({
        food_ingredient,
        measurementAssociationToEdit: null,
        overlayTextInput: '',
        overlayWeightUnitId: '',
        overlayValueInput: null
      });
    }
    else if (this.state.onSelectNewNutrientAssociation) {
      const food_nutrient_id = parseInt(this.state.selectedNewFoodNutrientId);
      const nutrient = this.state.food_nutrients.find((entry) => entry.id === food_nutrient_id);

      const weight_unit_id = parseInt(this.state.overlayWeightUnitId);
      const weight_unit = this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId));

      const selectedSourceAssociation = this.state.selectedSourceAssociation;

      const newAssociation = {
        food_nutrient_id,
        nutrient,
        weight_unit_id,
        weight_unit,
        weight_value: this.state.overlayValueInput,
      };

      selectedSourceAssociation.nutrient_associations.push(newAssociation);

      this.setState({
        selectedSourceAssociation,
        onSelectNewNutrientAssociation: false,
        selectedNewFoodNutrientId: '',
        overlayWeightUnitId: '',
        overlayValueInput: null,
      });
    }
  }

  getOverlayHeader() {
    if (this.state.onSelectNewProcessingMethod) {
      return 'Novo método de processamento';
    }
    else if (this.state.onSelectNewMeasurement) {
      return 'Nova medida de referência';
    }
    else if (this.state.measurementAssociationToEdit !== null) {
      return 'Editar medida de referência';
    }
    else if (this.state.onSelectNewNutrientAssociation) {
      return 'Nova informação nutricional';
    }

    return 'Unknown';
  }

  getOverlayContent() {
    if (this.state.loadingAdditionalData) {
      return null;
    }

    if (this.state.onSelectNewProcessingMethod) {
      return (
        <React.Fragment>

          <DefaultInput
            name="selectedNewProcessingMethodId"
            label="Método de processamento"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.selectedNewProcessingMethodId || ''}
            options={this.getIngredientProcessingMethodOptions()}
          />

          <HalfWrapper>

            <DefaultInput
              name="overlayWeightUnitId"
              label="Unidade de peso de referência"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overlayWeightUnitId || ''}
              options={this.getWeightUnitOptions()}
            />

            {this.state.overlayWeightUnitId.length > 0 &&
              <DefaultInput
                name="overlayValueInput2"
                label="Peso de referência"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.overlayValueInput2 || ''}
                autoComplete="off"
                suffix={this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId)).shortname}
              />
            }

          </HalfWrapper>

          {(this.state.overlayValueInput2 && this.state.overlayValueInput2.length > 0) &&
            <DefaultInput
              name="overlayValueInput"
              label={`Energia referente à ${this.state.overlayValueInput2}${this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId)).shortname} do alimento`}
              type="number"
              placeholder="-"
              step="0.01"
              min="0.00"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overlayValueInput || ''}
              autoComplete="off"
              suffix={DEFAULT_ENERGY_UNIT.shortname}
            />
          }

        </React.Fragment>
      );
    }
    else if (this.state.onSelectNewMeasurement) {
      return (
        <React.Fragment>

          <DefaultInput
            name="selectedNewMeasurementId"
            label="Medida de referência"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.selectedNewMeasurementId || ''}
            options={this.getFoodMeasurementOptions()}
          />

          <HalfWrapper>

            <DefaultInput
              name="overlayWeightUnitId"
              label="Unidade de peso"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overlayWeightUnitId || ''}
              options={this.getWeightUnitOptions()}
            />

            {this.state.overlayWeightUnitId.length > 0 &&
              <DefaultInput
                name="overlayValueInput"
                label="Peso"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.overlayValueInput || ''}
                autoComplete="off"
                suffix={this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId)).shortname}
              />
            }

          </HalfWrapper>

          <DefaultInput
            name="overlayTextInput"
            label="Descrição da medida relacionada com o ingrediente"
            type="textarea"
            placeholder="Descrição da medida relacionada com o ingrediente"
            rows="3"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.overlayTextInput || ''}
          />

        </React.Fragment>
      );
    }
    else if (this.state.measurementAssociationToEdit !== null) {
      return (
        <React.Fragment>

          <DefaultInput
            name="selectedNewMeasurementId"
            label="Medida de referência"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.measurementAssociationToEdit.food_measurement_id || ''}
            disabled={true}
            options={[SelectOption(this.state.measurementAssociationToEdit.food_measurement.id, this.state.measurementAssociationToEdit.food_measurement.name)]}
          />

          <HalfWrapper>

            <DefaultInput
              name="overlayWeightUnitId"
              label="Unidade de peso"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overlayWeightUnitId || ''}
              options={this.getWeightUnitOptions()}
            />

            {this.state.overlayWeightUnitId.length > 0 &&
              <DefaultInput
                name="overlayValueInput"
                label="Peso"
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.overlayValueInput || ''}
                autoComplete="off"
                suffix={this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId)).shortname}
              />
            }

          </HalfWrapper>

          <DefaultInput
            name="overlayTextInput"
            label="Descrição da medida relacionada com o ingrediente"
            type="textarea"
            placeholder="Descrição da medida relacionada com o ingrediente"
            rows="3"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.overlayTextInput || ''}
          />

        </React.Fragment>
      );
    }
    else if (this.state.onSelectNewNutrientAssociation) {
      return (
        <React.Fragment>

          <DefaultInput
            name="selectedNewFoodNutrientId"
            label="Nome do nutriente"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.selectedNewFoodNutrientId || ''}
            options={this.getFoodNutrientOptions()}
          />

          <HalfWrapper>

            <DefaultInput
              name="overlayWeightUnitId"
              label="Unidade de peso"
              type="select"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overlayWeightUnitId || ''}
              options={this.getWeightUnitOptions()}
            />

            {this.state.overlayWeightUnitId.length > 0 &&
              <DefaultInput
                name="overlayValueInput"
                label={`Peso referente à ${this.state.selectedSourceAssociation.weight_reference}${this.state.selectedSourceAssociation.weight_reference_unit.shortname} do alimento`}
                type="number"
                placeholder="-"
                step="0.01"
                min="0.00"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.overlayValueInput || ''}
                autoComplete="off"
                suffix={this.state.weight_units.find((entry) => entry.id === parseInt(this.state.overlayWeightUnitId)).shortname}
              />
            }

          </HalfWrapper>

        </React.Fragment>
      );
    }
  }

  onCancelConfirmation() {
    this.setState({
      sourceAssociationIdTodelete: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.sourceAssociationIdTodelete != null) {
      try{
        if(await deleteModel(`${routes.INGREDIENT_SOURCE_ASSOCIATION_DELETE_API}${this.state.sourceAssociationIdTodelete}`)) {
          const food_ingredient = {...this.state.food_ingredient};

          food_ingredient.source_associations = food_ingredient.source_associations.filter((association) => association.id !== this.state.sourceAssociationIdTodelete);

          const sourceIdsSet = new Set(food_ingredient.source_associations.map((entry) => entry.food_info_source_id));

          if (sourceIdsSet.has(this.state.activeNutrientInfoSourceId)) {
            this.setState({food_ingredient});

            this.onSelectNutrientInfoSource(this.state.activeNutrientInfoSourceId, food_ingredient.source_associations);
          }
          else if (sourceIdsSet.size > 0) {
            for (const sourceEntry of this.state.info_sources) {
              if (sourceIdsSet.has(sourceEntry.id)) {
                this.setState({food_ingredient});

                this.onSelectNutrientInfoSource(sourceEntry.id, food_ingredient.source_associations);
                break;
              }
            }
          }
          else {
            this.setState({
              food_ingredient,
              activeNutrientInfoSourceId: null,
              selectedSourceAssociation: null,
              activeMeasurementInfoSourceId: null,
            });
          }
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                const descriptions = [];

                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'recipe_associations':
                      descriptions.push('Método de processamento vinculado a um cadastro de receita');

                      break;
                    default:
                  }
                }

                errorDescription = `${descriptions.join('. ')}. Todos os vínculos devem ser removidos manualmente antes de prosseguir com a remoção deste cadastro.`;

                break;
              case 208:
                if (error.message.includes('This entry cannot be deleted due to its essencial associacions')) {
                  errorDescription = 'Esta entrada não pode ser removida por conter informações nutricionais importantes.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      sourceAssociationIdTodelete: null,
      confirmInProgress: false,
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.sourceAssociationIdTodelete !== null) {
        return 'Deletando associação';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.sourceAssociationIdTodelete !== null) {
        return 'Falha ao deletar';
      }

      return 'Unknown fail';
    }

    if(this.state.sourceAssociationIdTodelete !== null) {
      return 'Deletar associação';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.sourceAssociationIdTodelete != null) {
      return 'Todos os dados relacionados a esta associação serão removidos';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.sourceAssociationIdTodelete != null) {
      return 'Deletar associação';
    }

    return 'Unknown';
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="food-ingredient-edit__overlay"
          visible={this.state.onSelectNewProcessingMethod ||
                   this.state.onSelectNewMeasurement ||
                   this.state.measurementAssociationToEdit !== null ||
                   this.state.onSelectNewNutrientAssociation}
          loading={this.state.loadingAdditionalData}
          actions={(
            <div className="food-ingredient-edit__overlay__action-container">

              <DefaultMenuButton
                className="food-ingredient-edit__overlay__action-button"
                onClick={() => {
                  this.onCancelOverlay();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="food-ingredient-edit__overlay__action-button"
                onClick={() => {
                  this.onConfirmOverlay();
                }}
                text="Confirmar"
                disabled={ this.state.loadingAdditionalData ||
                           this.state.overlayValueInput === null ||
                           (this.state.onSelectNewProcessingMethod && this.state.selectedNewProcessingMethodId.length <= 0 && this.state.overlayValueInput2.length <= 0) ||
                           (this.state.onSelectNewMeasurement && this.state.selectedNewMeasurementId.length <= 0) ||
                           (this.state.onSelectNewNutrientAssociation && this.state.selectedNewFoodNutrientId.length <= 0)}
                color="green"
              />

            </div>
          )}
        >

          <header className="food-ingredient-edit__overlay__header">

            <h3 className="food-ingredient-edit__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="food-ingredient-edit__horizontal-rule" />

          <div className="food-ingredient-edit__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.sourceAssociationIdTodelete !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.FOOD_INGREDIENT_LIST_PATH,
              text: "Listar ingredientes"
            },
            {
              path: `${routes.FOOD_INGREDIENT_EDIT_PATH}${this.props.match.params.foodIngredientId}`,
              text: "Editar ingrediente"
            },
          ]}
          titleIcon={<i className="fas fa-edit"></i>}
          title="Editar ingrediente"
          loading={this.state.loading}
        >

          <FoodIngredientData
            warningMessage={this.state.warningMessage}
            showWarningMessage={this.state.showWarningMessage}
            food_ingredient={this.state.food_ingredient}
            onSave={() => this.saveData()}
            onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
            enableSave={this.inputsAreValid()}
            handleInputChange={(event) => this.handleInputChange(event)}
            onSetFoodAllergen={(allergen, include) => this.onSetFoodAllergen(allergen, include)}
            highlights={this.state.highlights}
            onCancelPath={routes.FOOD_INGREDIENT_LIST_PATH}
            food_classifications={this.state.food_classifications}
            info_sources={this.state.info_sources}
            nutrient_classifications={this.state.nutrient_classifications}
            food_allergens={this.state.food_allergens}
            activeNutrientInfoSourceId={this.state.activeNutrientInfoSourceId}
            activeMeasurementInfoSourceId={this.state.activeMeasurementInfoSourceId}
            selectedSourceAssociation={this.state.selectedSourceAssociation}
            onSelectNutrientInfoSource={(infoSource) => this.onSelectNutrientInfoSource(infoSource.id, this.state.food_ingredient.source_associations)}
            onSelectMeasurementInfoSource={(infoSource) => this.setState({activeMeasurementInfoSourceId: infoSource.id})}
            onAddNutrientSource={() => this.onAddNutrientSource()}
            onAddMeasurementSource={() => this.onAddMeasurementSource()}
            onAddNutrientAssociation={() => this.onAddNutrientAssociation()}
            onEditMeasurementSource={(measurementEntry) => this.onEditMeasurementSource(measurementEntry)}
            onRemoveMeasurementSource={(measurementEntry) => this.onRemoveMeasurementSource(measurementEntry)}
            onRemoveNutrientSource={() => this.onRemoveNutrientSource()}
            onRemoveNutrientAssociation={(association) => this.onRemoveNutrientAssociation(association)}
            userPermissionIds={this.props.userPermissionIds}
          />

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FoodIngredientEdit;
