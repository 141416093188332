import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';

function BarDataPoint(value, label, color) {
  return {
    value,
    label,
    color
  }
}

export {BarDataPoint};

class VerticalBarGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			theme: "light2",
			axisX: {
        labelFontWeight: 'bold',
			},
			axisY: {},
			data: [{
				type: "column",
				dataPoints: this.props.data.map((entry) => ({
          y: entry.value,
          label: entry.label,
          color: entry.color
        })),
			}]
		}

    if(this.props.title) {
      options.title = {
        text: this.props.title
      };
    }

    if(this.props.xLabel) {
      options.axisX.title = this.props.xLabel;
    }

    if(this.props.yLabel) {
      options.axisY.title = this.props.yLabel;
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.yInterval) {
      options.axisY.interval = this.props.yInterval;
    }

    if(this.props.animationEnabled === true || this.props.animationEnabled === false) {
      options.animationEnabled = this.props.animationEnabled;
    }

    return options;
  }

	render() {
		return (
		<div className={this.props.className}>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
        onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default VerticalBarGraph;
