import React from 'react';
import { Link } from 'react-router-dom';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import './appointment_data.scss';


class AppointmentData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.appointment.id && this.props.appointment.id > 0;
  }

  getAttendedOptions() {
    if(this.props.appointment.attended.length <= 0) {
      return [
        SelectOption('', 'Agendado'),
        SelectOption('true', 'Atendido'),
        SelectOption('false', 'Faltou'),
      ];
    }

    return [
      SelectOption('true', 'Atendido'),
      SelectOption('false', 'Faltou'),
    ];
  }

  render() {
    return (
      <DefaultSection
        className="appointment-data"
        title="Dados da sala de atendimento"
      >

        <div className="appointment-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="appointment-data__input-container">

          <DefaultInput
            name="attended"
            isHighlighted={this.isHighlighted("attended")}
            label="Situação"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment.attended || ''}
            options={this.getAttendedOptions()}
          />

          <HorizontalRule />

          <HalfWrapper>

            <DefaultInput
              name="user"
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              maxLength="128"
              value={this.props.appointment.user.name}
              autoComplete="off"
              disabled={true}
            />

            <DefaultInput
              name="appointment_type"
              label="Tipo"
              type="text"
              placeholder="Tipo do atendimento"
              maxLength="128"
              value={this.props.appointment.appointment_type.name}
              autoComplete="off"
              disabled={true}
            />

          </HalfWrapper>

          <DefaultInput
            name="date"
            label="Data do atendimento"
            type="date"
            placeholder="Data do atendimento"
            value={this.props.appointment.scheduled_at.slice(0, 10)}
            disabled={true}
          />

          <HalfWrapper>

            <DefaultInput
              name="scheduled_at"
              label="Início"
              type="time"
              placeholder="Início"
              value={this.props.appointment.scheduled_at.slice(11, 16)}
              autoComplete="off"
              disabled={true}
            />

            <DefaultInput
              name="finish_at"
              label="Fim"
              type="time"
              placeholder="Horário de término"
              value={this.props.appointment.finish_at.slice(11, 16)}
              autoComplete="off"
              disabled={true}
            />

          </HalfWrapper>

        </div>

        <HorizontalRule />

        <div className="appointment-data__buttons-container">

          <button
            className="appointment-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="appointment-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default AppointmentData;
