import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, getModel, patchModel} from '../../utils/functions';
import CostCenterData from './cost_center_data';

class CostCenterEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cost_center: {
        name: "",
        description: "",
        parent_id: "",
        color: '#000000'
      },
      costCenters: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let cost_centers = getModels(routes.COST_CENTERS_GET_API);
    let cost_center = getModel(`${routes.COST_CENTER_GET_API}${this.props.match.params.costCenterId}`);

    cost_centers = await cost_centers;

    if(cost_centers) {
      update.costCenters = cost_centers;
    }

    cost_center = await cost_center;

    if(cost_center) {
      update.cost_center = {...this.state.cost_center, ...cost_center};

      this.setState(update);
    }
    else {
      this.props.history.replace(routes.COST_CENTER_LIST_PATH);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.cost_center, [name]: value};

    this.setState({
      cost_center: newData
    });
  }

  inputsAreValid() {
    return this.state.cost_center.name.length;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.cost_center}
    if(!data.parent_id) {
      data.parent_id = null;
    }
    else {
      data.parent_id = parseInt(data.parent_id);
    }

    try {
      await patchModel(`${routes.COST_CENTER_PATCH_API}${this.props.match.params.costCenterId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.COST_CENTER_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.COST_CENTER_LIST_PATH,
            text: "Listar centros"
          },
          {
            path: `${routes.COST_CENTER_EDIT_PATH}${this.props.match.params.costCenterId}`,
            text: "Editar centro"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar centro de custo"
        loading={this.state.loading}
      >

        <CostCenterData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          cost_center={this.state.cost_center}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.COST_CENTER_LIST_PATH}
          costCenters={this.state.costCenters}
        />

      </ContentFrame>
    );
  }
}

export default CostCenterEdit;
