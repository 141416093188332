import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {BOTH_TYPE_NAME} from '../../constants';
import {getModel, patchModel} from '../../utils/functions';
import FinancialSearchTagData from './financial_search_tag_data';


class FinancialSearchTagEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_search_tag: {
        name: "",
        description: "",
        type: BOTH_TYPE_NAME
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let update = {
      loading: false
    };

    let financial_search_tag = getModel(`${routes.FINANCIAL_SEARCH_TAG_GET_API}${this.props.match.params.financialSeachTagId}`);

    financial_search_tag = await financial_search_tag;

    if(financial_search_tag) {
      update.financial_search_tag = {...this.state.financial_search_tag, ...financial_search_tag};
    }
    else {
      this.props.history.replace(routes.FINANCIAL_SEARCH_TAG_LIST_PAGE);
      return;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.financial_search_tag, [name]: value};

    this.setState({
      financial_search_tag: newData
    });
  }

  inputsAreValid() {
    return this.state.financial_search_tag.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.financial_search_tag};

    try {
      await patchModel(`${routes.FINANCIAL_SEARCH_TAG_PATCH_API}${this.props.match.params.financialSeachTagId}`, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Palavra-chave já cadastrada');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FINANCIAL_SEARCH_TAG_LIST_PAGE);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FINANCIAL_SEARCH_TAG_LIST_PAGE,
            text: "Listar palavras-chave"
          },
          {
            path: `${routes.FINANCIAL_SEARCH_TAG_EDIT_PAGE}${this.props.match.params.financialSeachTagId}`,
            text: "Editar palavra-chave"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar palavra-chave (financeiro)"
        loading={this.state.loading}
      >

        <FinancialSearchTagData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_search_tag={this.state.financial_search_tag}
          onSave={() => this.saveData()}
          onToggleService={(service_id) => this.onToggleService(service_id)}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FINANCIAL_SEARCH_TAG_LIST_PAGE}
        />

      </ContentFrame>
    );
  }
}

export default FinancialSearchTagEdit;
