import React from 'react';
import { Link } from 'react-router-dom';
import './payment_device_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class PaymentDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      payment_devices: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getPaymentDevices() {
    return await getModels(routes.PAYMENT_DEVICES_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const payment_devices = await this.getPaymentDevices();

    this.setState({
      payment_devices,
      loadingData: false
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.PAYMENT_DEVICE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'classes':
            //         errorDescription = 'Máquina já possui dados de transações de pagamento. Por favor, ' +
            //                            'desabilite este cadastro para bloquear seu uso.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_PAYMENT_DEVICE_PERMISSION_ID) ||
           this.props.userPermissionIds.includes(permissions.DELETE_PAYMENT_DEVICE_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_PAYMENT_DEVICE_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.PAYMENT_DEVICE_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_PAYMENT_DEVICE_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getStatusText(entry) {
    return (
      <div className="payment-device-list__cell-wrapper--centered">

        <p className={`payment-device-list__status-text${entry.is_active ? '--enabled' : '--disabled'}`}>

          {entry.is_active ?
            <i className="fas fa-check payment-device-list__status-text__icon"></i>:
            <i className="fas fa-times payment-device-list__status-text__icon"></i>}
          {entry.is_active ? 'Habilitado' : 'Desabilitado'}

        </p>

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
      Property('serial_number', 'Número de série', <i className="fas fa-tag"></i>),
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: (entry) => entry.is_active ? 'Ativo' : 'Inativo',
      })
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar maquininha';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados à maquininha de cartão serão removidos.'}
          confirmText="Deletar maquininha"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.PAYMENT_DEVICE_LIST_PATH,
              text: "Listar maquininhas"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar maquininhas"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="payment-device-list"
            title="Lista de máquinas de cartão"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.payment_devices}
              initialOrderBy="name"
              initialOrderIsDecrescent={true}
            >

              {this.props.userPermissionIds.includes(permissions.ADD_WEARABLE_DEVICE_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.PAYMENT_DEVICE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar nova maquininha

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default PaymentDeviceList;
