import React from 'react';
import { Link } from 'react-router-dom';
import './training_schedule_active_period_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';

class TrainingScheduleActivePeriodData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.schedule.id && this.props.schedule.id > 0;
  }

  getTrainingPeriodOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.trainingPeriods.map((period) => SelectOption(period.id, period.name))
      ];
    }

    const filteredEntries = this.props.trainingPeriods.filter((period) => period.target_service === this.props.selectedService);

    return [
      SelectOption('', 'Selecione uma periodização'),
      ...filteredEntries.map((period) => SelectOption(period.id, period.code !== null ? `${period.code} - ${period.name}` : period.name))
    ];
  }

  getServiceOptions() {
    if(this.isEditMode()) {
      return [];
    }

    return [
      SelectOption('', 'Selecione um serviço'),
      ...this.props.services.map((service) => SelectOption(service, service))
    ];
  }

  render() {
    return (
      <DefaultSection
        className="training-schedule-active-period-data"
        title="Ativamento de periodização"
      >

        <div className="training-schedule-active-period-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
            background={this.props.warningMessageBackground}
            color={this.props.warningMessageColor}
          />

        </div>

        <div className="training-schedule-active-period-data__input-container">

          {!this.isEditMode() &&
            <DefaultInput
              name="selectedService"
              label="Serviço"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.selectedService || ''}
              options={this.getServiceOptions()}
            />
          }

          {(this.isEditMode() || this.props.selectedService) &&
            <HalfWrapper>

              <DefaultInput
                name="training_period_id"
                isHighlighted={this.isHighlighted("training_period_id")}
                label="Periodização"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.schedule.training_period_id || ''}
                options={this.getTrainingPeriodOptions()}
                disabled={this.isEditMode()}
              />

              <DefaultInput
                name="start_date"
                isHighlighted={this.isHighlighted("start_date")}
                label="Data de início"
                type="date"
                placeholder="Data de início"
                handleInputChange={this.props.handleInputChange}
                value={this.props.schedule.start_date}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />

            </HalfWrapper>
          }

        </div>

        <HorizontalRule />

        <div className="training-schedule-active-period-data__buttons-container">

          <button
            className="training-schedule-active-period-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <Link
            className="training-schedule-active-period-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {this.isEditMode() ? 'Voltar': 'Cancelar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default TrainingScheduleActivePeriodData;
