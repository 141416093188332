import React from 'react';
import { Link } from 'react-router-dom';
import './training_exercise_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {getModels, deleteModel, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, SERVICE_HOME_TRAINING, TARGET_SERVICE_COLOR_MAP} from '../../constants';
import ContextPopup from '../../components/context_popup';
import * as permissions from '../../permissions';

class TrainingExerciseList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let targetServiceInput = queryParameters.get('target_service');
    let exerciseCategoryInput = queryParameters.get('exercise_category_id');
    let exerciseFunctionInput = queryParameters.get('exercise_function_id');
    let muscleGroupInput = queryParameters.get('muscle_group_id');

    if(!targetServiceInput) {
      targetServiceInput = '';
    }
    if(!exerciseCategoryInput) {
      exerciseCategoryInput = '';
    }
    if(!exerciseFunctionInput) {
      exerciseFunctionInput = '';
    }
    if(!muscleGroupInput) {
      muscleGroupInput = '';
    }

    this.state = {
      loadingData: true,
      targetServiceInput: targetServiceInput,
      targetServiceFilter: targetServiceInput,
      exerciseCategoryInput: exerciseCategoryInput,
      exerciseCategoryFilter: exerciseCategoryInput,
      exerciseFunctionInput: exerciseFunctionInput,
      exerciseFunctionFilter: exerciseFunctionInput,
      muscleGroupInput: muscleGroupInput,
      muscleGroupFilter: muscleGroupInput,
      training_exercises: [],
      services: [],
      exerciseCategories: [],
      exerciseFunctions: [],
      muscleGroups: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      popupContent: null,
      popupTarget: null,
      screenWidth: window.innerWidth
    };
  }

  async getTrainingExercises() {
    const parameters = {};

    if(this.state.targetServiceFilter) {
      parameters.target_service = this.state.targetServiceFilter;
    }
    if(this.state.exerciseCategoryFilter) {
      parameters.exercise_category_id = this.state.exerciseCategoryFilter;
    }
    if(this.state.exerciseFunctionFilter) {
      parameters.exercise_function_id = this.state.exerciseFunctionFilter;
    }
    if(this.state.muscleGroupFilter) {
      parameters.muscle_group_id = this.state.muscleGroupFilter;
    }

    if(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID)) {
      return await getModels(setUrlParameters(routes.TRAINING_EXERCISES_GET_API, parameters));
    }

    parameters.active_only = true;

    return await getModels(setUrlParameters(routes.TRAINING_EXERCISES_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList(true);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.targetServiceFilter !== this.state.targetServiceFilter || prevState.exerciseCategoryFilter !== this.state.exerciseCategoryFilter || prevState.exerciseFunctionFilter !== this.state.exerciseFunctionFilter || prevState.muscleGroupFilter !== this.state.muscleGroupFilter) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(reloadAll=false) {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    update.training_exercises = await this.getTrainingExercises();

    if(reloadAll) {
      let exerciseCategories = getModels(routes.EXERCISE_CATEOGRIES_GET_API);
      let exerciseFunctions = getModels(routes.EXERCISE_FUNCTIONS_GET_API);
      let muscleGroups = getModels(routes.MUSCLE_GROUPS_GET_API);
      let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

      exerciseCategories = await exerciseCategories;

      if(exerciseCategories) {
        update.exerciseCategories = exerciseCategories;
        update.exerciseCategories.sort((a, b) => a.name.localeCompare(b.name));
      }

      exerciseFunctions = await exerciseFunctions;

      if(exerciseFunctions) {
        update.exerciseFunctions = exerciseFunctions;
        update.exerciseFunctions.sort((a, b) => a.name.localeCompare(b.name));
      }

      muscleGroups = await muscleGroups;

      if(muscleGroups) {
        update.muscleGroups = muscleGroups;
        update.muscleGroups.sort((a, b) => a.name.localeCompare(b.name));
      }

      services = await services;

      if(services) {
        update.services = services;
      }
    }

    this.setState(update);
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.TRAINING_EXERCISE_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'day_group_associations':
                    descriptions.push('Exercício vinculado à uma periodização');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar o exercício.`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_EXERCISE_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_EXERCISE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID)) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.TRAINING_EXERCISE_EDIT_PATH}${entry.id}`}
          >

              {(entry.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID)) ?
                <i className="fas fa-edit"></i>:
                <i className="fas fa-eye"></i>
              }

          </Link>
        }

        {(this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_EXERCISE_PERMISSION_ID) && entry.editable) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

        {entry.reference_url !== null &&
          <a
            className="model-table__default-link-button"
            href={entry.reference_url}
            target="_blank"
            rel="noopener noreferrer"
            key={`training_exercise:${entry.id}:reference`}
            >

              <i className="fas fa-link"></i>

            </a>
        }

      </div>
    );
  }

  getStatusText(entry) {
    const status = this.getStatusFilterText(entry);

    return (
      <p className={`training-exercise-list__status-text--${status.toLowerCase()}`}>{status}</p>
    );
  }

  getStatusFilterText(entry) {
    return entry.is_active ? 'Ativo' : 'Inativo';
  }

  onShowHoverdata(target, text) {
    this.setState({
      popupContent: text,
      popupTarget: target,
    });
  }

  onHideHoverdata() {
    this.setState({
      popupContent: null,
      popupTarget: null,
    });
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => (
          <p className="training-exercise-list__exercise-name">

              {entry.name}

              {entry.description && (
                <i
                  className="fa-solid fa-circle-info training-exercise-list__exercise-name__info-icon"
                  onMouseEnter={(event) => this.onShowHoverdata(event.target, (
                    <p className="training-exercise-list__exercise-info">{entry.description}</p>
                  ))}
                  onMouseLeave={(event) => this.onHideHoverdata()}
                >
                </i>
              )}

            </p>
        ),
        getFilterText: (entry) => entry.name,
        getCellStyle: (entry) => {
          if (entry.target_service in TARGET_SERVICE_COLOR_MAP) {
            return {background: TARGET_SERVICE_COLOR_MAP[entry.target_service]};
          }

          return null;
        }
      }),
    ];

    if(this.state.screenWidth > 710) {
      properties.push(
        Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>),
      );
    }

    properties.push(Property('category_name', 'Pilar', <i className="fas fa-tag"></i>));
    properties.push(Property('function_name', 'Função', <i className="fas fa-box"></i>));

    if(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID)) {
      properties.push(Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.getStatusText(entry),
        getFilterText: this.getStatusFilterText,
        cellClassName: "training-exercise-list__status-cell"
      }));
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando exercício';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar exercício';
  }

  getExerciseCategoryOptions() {
    let filteredExerciseCategories = this.state.exerciseCategories;

    if (this.state.targetServiceInput.length > 0) {
      filteredExerciseCategories = filteredExerciseCategories.filter((entry) => entry.target_service === null || entry.target_service === this.state.targetServiceInput);
    }

    return [
      SelectOption('', 'Todos os pilares'),
      ...filteredExerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
    ];
  }

  getExerciseFunctionOptions() {
    return [
      SelectOption('', 'Todas as funções'),
      ...this.state.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
    ];
  }

  getMuscleGroupOptions() {
    return [
      SelectOption('', 'Todos os agrupamentos'),
      ...this.state.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.id, muscleGroup.name))
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.state.services,
      SERVICE_HOME_TRAINING
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos os serviços'),
      ...services.map((service) => SelectOption(service, service)),
    ];
  }

  mayRefreshFilters() {
    if(this.state.targetServiceInput !== this.state.targetServiceFilter ||
       this.state.exerciseCategoryInput !== this.state.exerciseCategoryFilter ||
       this.state.exerciseFunctionInput !== this.state.exerciseFunctionFilter ||
       this.state.muscleGroupInput !== this.state.muscleGroupFilter) {
      return true;
    }

    return false;
  }

  async refreshFilters() {
    if(this.mayRefreshFilters()) {
      this.props.history.replace(setUrlParameters(routes.TRAINING_EXERCISE_LIST_PATH, {
        target_service: this.state.targetServiceInput,
        exercise_category_id: this.state.exerciseCategoryInput,
        exercise_function_id: this.state.exerciseFunctionInput,
        muscle_group_id: this.state.muscleGroupInput
      }));

      this.setState({
        targetServiceFilter: this.state.targetServiceInput,
        exerciseCategoryFilter: this.state.exerciseCategoryInput,
        exerciseFunctionFilter: this.state.exerciseFunctionInput,
        muscleGroupFilter: this.state.muscleGroupInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    if (name === 'targetServiceInput' && value.length > 0 && this.state.exerciseCategoryInput.length > 0) {
      const exercise_category_id = parseInt(this.state.exerciseCategoryInput);

      if(!this.state.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === this.state.targetServiceInput);
      })) {
        update.exerciseCategoryInput = '';
      }
    }

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ContextPopup
          targetElement={this.state.popupTarget}
          content={this.state.popupContent}
        />

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao exercício serão removidos'}
          confirmText="Deletar exercício"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.TRAINING_EXERCISE_LIST_PATH,
              text: "Listar exercícios"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar exercícios"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-exercise-list"
            title="Lista de exercícios"
          >

            <div className="training-exercise-list__list-actions">

              {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_EXERCISE_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.TRAINING_EXERCISE_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo exercício

                </Link>
              }

            </div>

            <div className="training-exercise-list__filters">

              <header className="training-exercise-list__filters__header">

                <h4 className="training-exercise-list__filters__header__text">Filtros</h4>

              </header>

              <div className="training-exercise-list__filters__inputs">

                <div className="training-exercise-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="targetServiceInput"
                    label="Serviço alvo"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.targetServiceInput || ''}
                    options={this.getServiceOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="exerciseCategoryInput"
                      label="Pilar"
                      labelMessage={this.state.targetServiceInput.length > 0 ? 'Filtrado pelo serviço' : null}
                      type="select"
                      value={this.state.exerciseCategoryInput || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getExerciseCategoryOptions()}
                    />

                    <DefaultInput
                      name="exerciseFunctionInput"
                      label="Função"
                      type="select"
                      value={this.state.exerciseFunctionInput || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getExerciseFunctionOptions()}
                    />

                  </HalfWrapper>

                  <DefaultInput
                    name="muscleGroupInput"
                    label="Agrupamento muscular"
                    type="select"
                    value={this.state.muscleGroupInput || ''}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    options={this.getMuscleGroupOptions()}
                  />

                </div>

                <button
                  className="training-exercise-list__filters__refresh-button"
                  onClick={() => this.refreshFilters()}
                  disabled={!this.mayRefreshFilters()}
                >

                  <i className="fas fa-sync"></i>

                </button>

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.training_exercises}
              initialOrderBy="name"
            >
            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingExerciseList;
