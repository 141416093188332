import React from 'react';

const ANIMATION_PERIOD = 1000; //ms
const ANIMATION_LAYER_DELAY = 300; //ms

function FunnelLayer(name, value, color) {
  return {
    name,
    value,
    color
  }
}

export {FunnelLayer};

class FunnelChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };

    this.timeReference = 0;
    this.animating = false;
    this.stopDrawing = false;
    this.layerMarginMultiplier = 0.3;

    this.canvasSizeSet = false;

    this.canvasRef = React.createRef();
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    this.setGraphAnimation();
  }

  async componentDidUpdate(prevProps, prevState) {
    if(!this.animating && prevState.screenWidth !== this.state.screenWidth) {
      const canvas = this.canvasRef.current;
      canvas.width = 0;
      canvas.height = 0;

      this.canvasSizeSet = false;

      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  componentWillUnmount() {
    this.animating = false;
    this.stopDrawing = true;

    window.removeEventListener("resize", this.resizeListener);
  }

  setGraphAnimation() {
    this.timeReference = Date.now();
    this.animating = true;

    const canvas = this.canvasRef.current;
    canvas.width = 0;
    canvas.height = 0;

    requestAnimationFrame(this.drawZoneGraph.bind(this));
  }

  getGraphMargin() {
    return (this.state.screenWidth > 950 && window.innerHeight > 850) ? 15 : 5;
  }

  getDefaultTextSize() {
    return Math.min(Math.max(this.state.screenWidth*0.023, 14), 30);
  }

  getAnimationLayerStageMultiplier(stage) {
    if (!this.animating || !this.props.layers || this.props.layers.length <= 0 || stage < 0 || stage >= this.props.layers.length) {
      return 1;
    }

    const deltaTime = Date.now() - this.timeReference;

    if (deltaTime >= ((this.props.layers.length * ANIMATION_LAYER_DELAY) + ANIMATION_PERIOD)) {
      this.animating = false;
      return 1;
    }

    const stageStart = stage * ANIMATION_LAYER_DELAY;
    const stageFinish = stageStart + ANIMATION_PERIOD;

    if (deltaTime <= stageStart) {
      return 0;
    }
    else if (deltaTime >= stageFinish) {
      return 1;
    }

    const stageRatio = (deltaTime - stageStart) / ANIMATION_PERIOD;

    return (stageRatio - 1)*(stageRatio - 1)*(stageRatio - 1) + 1;
  }

  drawZoneGraph() {
    if(this.stopDrawing || !this.props.layers || this.props.layers.length <= 0) {
      return;
    }

    const canvas = this.canvasRef.current;

    if(!canvas) {
      return;
    }

    const margin = this.getGraphMargin();

    if(!this.canvasSizeSet) {
      canvas.width = canvas.parentNode.offsetWidth;
      canvas.height = canvas.parentNode.offsetHeight;

      this.canvasSizeSet = true;
    }

    // let maxValue = null;
    //
    // for (const layer of this.props.layers) {
    //   if (maxValue === null || layer.value > maxValue) {
    //     maxValue = layer.value;
    //   }
    // }

    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    const textSize = this.getDefaultTextSize();

    context.globalAlpha = 1;

    const layerMargin = this.layerMarginMultiplier * textSize;
    const layerHeight = 2.5 * textSize;
    // const minWidth = 14 * textSize;

    const initialXPosition = 4 * textSize;
    const diffTextOffset = 0.2 * textSize;

    const maxWidth = canvas.width - initialXPosition - (2 * margin);
    // const layerWidthDiff = 2 * textSize;
    const layerWidthDiff = 0.2 * maxWidth / (this.props.layers.length - 1);

    let lastYMiddlePosition;
    let lastXMiddlePosition;

    context.textBaseline = 'middle';

    let diffLineCompleted = false;

    for (let i=0; i < this.props.layers.length; ++i) {
      const stageLayerMultiplier = this.getAnimationLayerStageMultiplier(i);

      const initialYPosition = margin + (i * (layerHeight + layerMargin));

      // let layerWidth = minWidth + ((maxWidth - minWidth) * (this.props.layers[i].value / maxValue));
      let layerWidth = maxWidth - (i * 2 * layerWidthDiff * (1 + layerMargin / layerHeight));
      layerWidth = stageLayerMultiplier * layerWidth;

      const xOffset = margin + initialXPosition + ((maxWidth - layerWidth) / 2);

      const middleYPosition = initialYPosition + (layerHeight / 2);

      context.globalAlpha = stageLayerMultiplier;
      context.fillStyle = this.props.layers[i].color;
      context.strokeStyle = this.props.layers[i].color;
      context.lineWidth = 1;
      context.lineJoin = "miter";
      context.setLineDash([]);
      context.beginPath();
      context.moveTo(xOffset, initialYPosition);
      context.lineTo(xOffset + layerWidth, initialYPosition);
      context.lineTo(xOffset + layerWidth - layerWidthDiff, layerHeight + initialYPosition);
      context.lineTo(xOffset + layerWidthDiff, layerHeight + initialYPosition);
      context.closePath()
      context.fill();
      context.stroke();

      context.fillStyle = 'white';
      context.textAlign = 'center';

      context.font = `${0.8 * textSize}px Montserrat, sans-serif`;
      context.fillText(this.props.layers[i].name, xOffset + (layerWidth / 2), middleYPosition - (0.5 * textSize));

      context.font = `${textSize}px Orbitron, sans-serif`;
      context.fillText(Math.round(stageLayerMultiplier * this.props.layers[i].value), xOffset + (layerWidth / 2), middleYPosition + (0.5 * textSize));

      if (i > 0 && this.props.layers[i-1].value > 0) {
        context.strokeStyle = '#878787';
        context.lineWidth = 2;
        context.lineJoin = "round";
        context.setLineDash([2, 4]);
        context.beginPath();
        context.moveTo(xOffset + (layerWidthDiff / 2), middleYPosition);
        context.lineTo(margin + initialXPosition - diffTextOffset, middleYPosition);
        context.lineTo(margin + initialXPosition - diffTextOffset, lastYMiddlePosition);

        if (!diffLineCompleted) {
          context.lineTo(lastXMiddlePosition, lastYMiddlePosition);
          diffLineCompleted = true;
        }

        context.stroke();

        const valueDiffPercentage = Math.round(1000 * this.props.layers[i].value / this.props.layers[i-1].value) / 10;

        context.fillStyle = '#878787';
        context.textAlign = 'right';
        context.font = `${0.7 * textSize}px Orbitron, sans-serif`;
        context.fillText(`${valueDiffPercentage}%`, margin + initialXPosition - diffTextOffset - (0.2 * textSize), (lastYMiddlePosition + middleYPosition) / 2);
      }

      lastYMiddlePosition = middleYPosition;
      lastXMiddlePosition = xOffset + (layerWidthDiff / 2);
    }

    if(this.animating) {
      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  render() {
    if (!this.props.layers || this.props.layers.length <= 0) {
      return null;
    }

    const defaultTextSize = this.getDefaultTextSize();

    const height = (2 * this.getGraphMargin()) + (this.props.layers.length * (2.5 * defaultTextSize)) + (this.layerMarginMultiplier * defaultTextSize * (this.props.layers.length - 1));

    return (
      <div
        className={this.props.className}
        style={{height: `${height}px`}}
      >

        <canvas ref={this.canvasRef} />

      </div>
    );
  }
}

export default FunnelChart;
