import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import ModelTable, {Property} from '../../utils/model_table';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import {getModel, getModels, postModel, deleteModel, getLocalDateIsoString, getAsLocalDate} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants'; //SERVICE_GYM_SERVICE
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import {TRAINING_DAY_EDIT_PATH} from '../training_period/constants';
import * as permissions from '../../permissions';
import DefaultInput from '../../utils/default_input';
import DefaultMenuButton from '../../components/default_menu_button';
import './training_period_schedule.scss';

function getDayId(date) {
  let dayId = date.getDay() - 1;

  if(dayId < 0) {
    dayId = 6;
  }

  return dayId;
}


class TrainingPeriodSchedule extends React.Component {
  constructor(props) {
    super(props);

    const currentDate = new Date();
    currentDate.setDate(1);

    this.state = {
      loadingData: true,
      activated_periods: [],
      days_info: {
        days_enabled: [],
        days_disabled: [],
        default_weekdays: [],
        auth_hash: ''
      },
      activeService: null,
      services: [],
      training_day_map: new Map(),
      activatedPeriodsVisible: false,
      updateDateInfo: null,
      may_keep_training_distribution: false,
      deleteScheduleId: null,
      dateToRemoveOverwrite: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
      currentDate: currentDate,
      novoHorario: "",
      dateToOverwrite: null,
      overwriteId: 0,
      loadingCheckinStudents: false,
      selectedCheckinDate: null,
      checkinStudentsVisible: false,
      studentsCheckinData: null
    };
  }

  async reloadData(changeLoadingState=true) {
    if(this.state.activeService === null) {
      return;
    }

    if(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID)) {
      if(changeLoadingState) {
        this.setState({
          loadingData: true,
        });
      }

      let activated_periods = await getModels(`${routes.TRAINING_PERIOD_SCHEDULES_GET_API}?target_service=${this.state.activeService}`);

      if(activated_periods) {
        const update = {
          activated_periods: activated_periods
        };

        if(changeLoadingState) {
          update.loadingData = false;
        }

        this.setState(update);
      }
      else {
        this.props.history.replace(routes.DESKTOP_PATH);
        return;
      }
    }
  }

  async reloadMonthData(currentDate=null, changeLoadingState=true) {
    if(this.state.activeService === null) {
      return;
    }

    const update = {};

    if(changeLoadingState) {
      this.setState({
        loadingData: true,
      });

      update.loadingData = false;
    }

    if(!currentDate) {
      currentDate = this.state.currentDate;
    }

    const min_date = getLocalDateIsoString(currentDate);
    const max_date = getLocalDateIsoString(new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0));
    const month_id = min_date.slice(0, 7);

    let training_day_map = getModels(`${routes.TRAINING_CALENDAR_GET_API}?target_service=${this.state.activeService}&min_date=${min_date}&max_date=${max_date}`);
    let days_info = getModels(`${routes.TRAINING_CALENDAR_MONTH_GET_API}${this.state.activeService}/${month_id}`);

    training_day_map = await training_day_map;

    if(training_day_map) {
      update.training_day_map = new Map();

      for(let entry of training_day_map) {
        if(!update.training_day_map.has(entry.date)) {
          update.training_day_map.set(entry.date, []);
        }

        update.training_day_map.get(entry.date).push(entry);
      }
    }

    days_info = await days_info;

    if(days_info) {
      update.days_info = days_info;
      update.days_info.days_enabled = update.days_info.days_enabled.map((dayId) => parseInt(dayId));
      update.days_info.days_disabled = update.days_info.days_disabled.map((dayId) => parseInt(dayId));
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);

    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    const update = {
      loadingData: false
    }

    // this.reloadData(false);
    // this.reloadMonthData(null, false);

    services = await services;

    if(services) {
      const servicesSet = new Set(services);
      // servicesSet.delete(SERVICE_GYM_SERVICE);
      services = [...servicesSet];

      update.services = services;
      update.services.sort((a, b) => a.localeCompare(b));
    }

    this.setState(update);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeService !== this.state.activeService) {
      this.reloadData();
      this.reloadMonthData();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  onUpdateDateInfo(dateId, enable) {
    this.setState({
      updateDateInfo: { dateId, enable },
      may_keep_training_distribution: false,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onRemoveOverwrite(dateId) {
    this.setState({
      dateToRemoveOverwrite: dateId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  async onCancelConfirmation() {
    if(this.state.may_keep_training_distribution) {
      this.setState({
        confirmInProgress: true
      });

      await this.updateTrainingCalendarDate(true);

      this.setState({
        deleteScheduleId: null,
        updateDateInfo: null,
        may_keep_training_distribution: false,
        dateToRemoveOverwrite: null,
      });
    }
    else {
      this.setState({
        deleteScheduleId: null,
        updateDateInfo: null,
        may_keep_training_distribution: false,
        dateToOverwrite: null,
        dateToRemoveOverwrite: null,
        overwriteId: 0,
        confirmFailed: false,
      });
    }
  }

  async updateTrainingCalendarDate(keep_training_distribution) {
    const payload = {
      enabled: this.state.updateDateInfo.enable,
      keep_training_distribution
    };

    try{
      if(await postModel(`${routes.TRAINING_CALENDAR_DATE_POST_API}${this.state.activeService}/${this.state.updateDateInfo.dateId}`, payload)) {
        this.reloadData();
        this.reloadMonthData();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  async onAcceptConfirmation() {
    if(this.state.activeService === null) {
      return;
    }

    if (this.state.updateDateInfo === null || this.state.updateDateInfo.enable || this.state.may_keep_training_distribution) {
      this.setState({
        confirmInProgress: true
      });
    }

    if(this.state.deleteScheduleId != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_PERIOD_SCHEDULE_DELETE_API}${this.state.deleteScheduleId}`)) {
          this.reloadData();
          this.reloadMonthData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.dateToRemoveOverwrite != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_CALENDAR_OVERWRITE_POST_API}${this.state.activeService}/${this.state.dateToRemoveOverwrite}`)) {
          this.reloadData();
          this.reloadMonthData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.updateDateInfo != null) {
      if (this.state.updateDateInfo.enable || this.state.may_keep_training_distribution) {
        await this.updateTrainingCalendarDate(false);
      }
      else {
        this.setState({
          may_keep_training_distribution: true
        });

        return;
      }
    }

    this.setState({
      deleteScheduleId: null,
      updateDateInfo: null,
      may_keep_training_distribution: false,
      dateToRemoveOverwrite: null,
    });
  }

  async proceedToOverwriteDate() {
    if(this.state.overwriteId <= 0) {
      return;
    }

    this.setState({
      loadingData: true
    });

    if(this.state.dateToOverwrite != null && this.state.overwriteId > 0) {
      const data = {
        training_day_id: parseInt(this.state.overwriteId),
      };

      try{
        if(await postModel(`${routes.TRAINING_CALENDAR_OVERWRITE_POST_API}${this.state.activeService}/${this.state.dateToOverwrite}`, data)) {
          this.reloadData();
          this.reloadMonthData();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 106:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'training.target_service':
                      errorDescription = 'Serviço do treino selecionado não é correspondente.';

                      break;
                    case 'period.is_active':
                      errorDescription = 'O treino desejado encontra-se inativo no momento.';

                      break;
                    default:
                  }
                }

                break;
              case 208:
                errorDescription = 'ID inválido.';

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          loadingData: false
        });

        return;
      }
    }

    this.setState({
      dateToOverwrite: null,
      overwriteId: 0,
      loadingData: false
    });
  }

  onDeletePeriodSchedule(scheduleId) {
    this.setState({
      deleteScheduleId: scheduleId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteScheduleId != null) {
        return 'Removendo cadastro';
      }
      else if(this.state.updateDateInfo != null) {
        return this.state.updateDateInfo.enable ? 'Habilitando dia' : 'Desabilitando dia';
      }
      else if(this.state.dateToRemoveOverwrite != null) {
        return 'Removendo treino sobrescrito';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteScheduleId != null) {
        return 'Falha ao remover cadastro';
      }
      else if(this.state.updateDateInfo != null) {
        return this.state.updateDateInfo.enable ? 'Falha ao habilitar dia' : 'Falha ao desabilitar dia';
      }
      else if(this.state.dateToOverwrite != null) {
        return 'Falha ao sobrescrever treino do dia';
      }
      else if(this.state.dateToRemoveOverwrite != null) {
        return 'Falha ao remover treino sobrescrito';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteScheduleId != null) {
      return 'Remover período ativado';
    }
    else if(this.state.updateDateInfo != null) {
      if (this.state.may_keep_training_distribution) {
        return 'Deseja atualizar a ordem dos treinos?';
      }

      return this.state.updateDateInfo.enable ? 'Habilitar dia' : 'Desabilitar dia';
    }
    else if(this.state.dateToRemoveOverwrite != null) {
      return 'Remover treino sobrescrito';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteScheduleId != null) {
      return 'Todos os dados relacionados ao cadastro serão removidos';
    }
    else if(this.state.updateDateInfo != null) {
      if (this.state.may_keep_training_distribution) {
        return 'Em caso de NÃO atualização, o dia será DESABILITADO, impedindo checkins e agendamento de aulas experimentais, mas não alterará a sequência de treinos atual do calendário.';
      }

      return this.state.updateDateInfo.enable ? 'O dia será HABILITADO para a alocação de treinos' : 'O dia será DESABILITADO para a alocação de treinos';
    }
    else if(this.state.dateToRemoveOverwrite != null) {
      return 'O treino sobrescrito será removido e o calendário com as periodizações ativas será atualizado automaticamente';
    }

    return 'Unknown';
  }

  getConfirmationWindowConfirmText() {
    if(this.state.deleteScheduleId != null) {
      return 'Remover cadastro';
    }
    else if(this.state.updateDateInfo != null) {
      if (this.state.may_keep_training_distribution) {
        return 'Sim';
      }

      return this.state.updateDateInfo.enable ? 'Habilitar dia' : 'Desabilitar dia';
    }
    else if(this.state.dateToRemoveOverwrite != null) {
      return 'Remover';
    }

    return 'Unknown';
  }

  getConfirmationWindowCancelText() {
    if (this.state.updateDateInfo !== null && this.state.may_keep_training_distribution) {
      return 'Não'
    }

    return this.state.confirmFailed ? 'Ok' : 'Cancelar';
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.TRAINING_SCHEDULE_EDIT_ACTIVE_PERIOD_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeletePeriodSchedule(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getScheduleStatusText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const start_date = entry.start_date;
    const end_date = entry.end_date;

    let text;
    let statusClass;

    if(start_date > todayString) {
      text = 'Agendada';
      statusClass = '--scheduled';
    }
    else if(start_date <= todayString && end_date >= todayString) {
      text = 'Em andamento';
      statusClass = '--in-progress';
    }
    else {
      text = 'Finalizada';
      statusClass = '--finished';
    }

    return (
      <div className="training-period-schedule__cell-wrapper--centered">

        <p className={`training-period-schedule__status-text${statusClass}`}>

          {text}

        </p>

      </div>
    );
  }

  getScheduleStatusFilter(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const start_date = entry.start_date;
    const end_date = entry.end_date;

    if(start_date > todayString) {
      return 'Agendada';
    }
    else if(start_date <= todayString && end_date >= todayString) {
      return 'Em andamento';
    }
    else {
      return 'Finalizada';
    }
  }

  getPropertyDateText(isoDate) {
    const date = getAsLocalDate(isoDate, false);

    return (
      <div className="training-period-schedule__cell-wrapper">

        <p className="training-period-schedule__date-text">

          {date.toLocaleDateString()}

        </p>

      </div>
    );
  }

  getPropertyDateFilter(isoDate) {
    const date = getAsLocalDate(isoDate, false);
    return date.toLocaleDateString();
  }

  getProperties() {
    let properties = [
      Property('code', 'Código', <i className="fas fa-tag"></i>),
      Property('period_name', 'Periodização', <i className="fas fa-tag"></i>),
    ];

    // if(this.state.screenWidth > 710) {
    //   properties.push(
    //     Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "training-day-group-association-data__description-cell"}),
    //   );
    // }

    properties.push(Property('start_date', 'Data de início', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getPropertyDateText(entry.start_date),
        getFilterText: (entry) => this.getPropertyDateFilter(entry.start_date),
      }));
    properties.push(Property('end_date', 'Data de término', <i className="fas fa-calendar-day"></i>, {
        getDataText: (entry) => this.getPropertyDateText(entry.end_date),
        getFilterText: (entry) => this.getPropertyDateFilter(entry.end_date),
      }));
    properties.push(Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {
      getDataText: (entry) => this.getScheduleStatusText(entry),
      getFilterText: (entry) => this.getScheduleStatusFilter(entry),
    }),);

    return properties;
  }

  getDateText() {
    const monthFormat = new Intl.DateTimeFormat('pt-BR', {
      month: 'long',
    });
    const yearFormat = new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
    });

    return `${monthFormat.format(this.state.currentDate)}, ${yearFormat.format(this.state.currentDate)}`;
  }

  changeMonth(dif) {
    const currentDate = new Date(this.state.currentDate.getTime());
    currentDate.setMonth(currentDate.getMonth() + dif, 1);

    this.setState({currentDate});

    this.reloadMonthData(currentDate);
  }

  dayMayBeEnabled(date) {
    if(this.state.days_info.days_disabled.includes(date.getDate())) {
      return true;
    }
    else if(this.state.days_info.days_enabled.includes(date.getDate())) {
      return false;
    }
    else {
      const weekDay = getDayId(date);

      if(!this.state.days_info.default_weekdays.includes(weekDay)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  getTrainingInfo(date) {
    const isoDate = getLocalDateIsoString(date);
    let info = [];

    if(this.state.training_day_map.has(isoDate)) {
      let info_counter = 0;

      info = this.state.training_day_map.get(isoDate).filter((entry) => entry.has_training || entry.training_day_id).map((entry) => {
        let repetitionColor = '#000000';

        if (!entry.has_training) {
          repetitionColor = '#8b8b8b';
        }
        else if(entry.repetition_index <= DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            repetitionColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[entry.repetition_index-1];
        }

        info_counter += 1;

        return (
          <p
            style={{background: repetitionColor}}
            className="training-period-schedule__calendar__day-item__training-info"
            key={`training_period_schedule_training_info:${isoDate}:${info_counter}`}
          >

            {`${entry.period_name} - ${entry.training_day_name} (Fase ${entry.repetition_index})`}

            <Link
              className="training-period-schedule__calendar__default-link-button"
              to={`${routes.TRAINING_PERIOD_EDIT_PATH}${entry.period_id}${TRAINING_DAY_EDIT_PATH}${entry.training_day_id}?from_calendar=true`}
            >

                <i className="fas fa-link training-period-schedule__calendar__default-link-button__icon"></i>

            </Link>

          </p>
        );
      });
    }

    const dayMayBeEnabled = this.dayMayBeEnabled(date);

    if(info.length <= 0 || dayMayBeEnabled) {

      info = [
        (
          <p
            className={`training-period-schedule__calendar__day-item__no-info${dayMayBeEnabled ? '--red' : ''}`}
            key={`training_period_schedule_training_info:${isoDate}:disabled_text`}
          >

            {dayMayBeEnabled ? 'Dia indisponível' : 'Não configurado'}

          </p>
        ),
        ...info
      ];
    }

    return info;
  }

  hasOverwrite(date) {
    const isoDate = date.toISOString().slice(0, 10);

    if(this.state.training_day_map.has(isoDate)) {
      const training = this.state.training_day_map.get(isoDate)[0];

      if(training.has_training && training.is_overwrite) {
        return true;
      }
    }

    return false;
  }

  async loadStudentsCheckin(isoDate) {
    this.setState({
      loadingCheckinStudents: true,
      selectedCheckinDate: isoDate,
      studentsCheckinData: null,
      checkinStudentsVisible: true
    });

    let checkinData = getModel(routes.STUDENT_CLASS_CHECKIN_GET_API.replace('{service}', this.state.activeService).replace('{date_id}', isoDate));

    const update = {
      loadingCheckinStudents: false
    }

    checkinData = await checkinData;

    if(checkinData) {
      update.studentsCheckinData = checkinData.classes_checked_in;
      update.studentsCheckinData.sort((a, b) => a.time.localeCompare(b.time));
    }

    this.setState(update);
  }

  getMonthDays() {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);

    const month_id = getLocalDateIsoString(this.state.currentDate).slice(0, 7);
    const currentDate = new Date(this.state.currentDate.getTime());
    let currentMonth = currentDate.getMonth();
    const initialMonth = currentMonth;

    const dayNameFormat = new Intl.DateTimeFormat('pt-BR', {weekday: this.state.screenWidth > 680 ? 'long' : 'short'});

    const dayElements = [];

    while(currentMonth === initialMonth) {
      const dayId = currentDate.getDate();
      const isoDate = getLocalDateIsoString(currentDate);

      dayElements.push(
        <li
          className={`training-period-schedule__calendar__day-item${isoDate === todayString ? '--today' : ''}`}
          key={`training_period_schedule:${month_id}:${dayId}`}
        >

          <p className="training-period-schedule__calendar__day-item__id">

            <span>{dayId}</span>
            <span>-</span>
            <span>{dayNameFormat.format(currentDate)}</span>

          </p>

          <div className="training-period-schedule__calendar__day-item__content">

            <div className="training-period-schedule__calendar__day-item__trainings">

              {this.getTrainingInfo(currentDate)}

            </div>

            {(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_CALENDAR_PERMISSION_ID) ||
              this.props.userPermissionIds.includes(permissions.VIEW_CLASS_CHECKIN_STUDENTS_PERMISSION)) &&
              <div className="training-period-schedule__calendar__day-item__actions">

                {this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_CALENDAR_PERMISSION_ID) &&
                  <button
                    className="training-period-schedule__calendar__day-item__config-button"
                    onClick={() => this.setState({dateToOverwrite: isoDate, confirmInProgress: false})}
                  >

                    <i className="fas fa-cog"></i>

                  </button>
                }

                {this.props.userPermissionIds.includes(permissions.VIEW_CLASS_CHECKIN_STUDENTS_PERMISSION) &&
                  <button
                    className="training-period-schedule__calendar__day-item__config-button"
                    onClick={() => this.loadStudentsCheckin(isoDate)}
                  >

                    <i className="fa-solid fa-chalkboard-user"></i>

                  </button>
                }

                {this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_CALENDAR_PERMISSION_ID) &&
                  <React.Fragment>
                    {this.hasOverwrite(currentDate) ?
                      <button
                        className="training-period-schedule__calendar__day-item__disable-button"
                        onClick={() => {this.onRemoveOverwrite(isoDate)}}
                      >

                        <i className="fas fa-times"></i>

                      </button>:
                      this.dayMayBeEnabled(currentDate) ?
                        <button
                          className="training-period-schedule__calendar__day-item__enable-button"
                          onClick={() => {this.onUpdateDateInfo(isoDate, true)}}
                        >

                          Habilitar

                        </button>:
                        <button
                          className="training-period-schedule__calendar__day-item__disable-button"
                          onClick={() => {this.onUpdateDateInfo(isoDate, false)}}
                        >

                          Desabilitar

                        </button>
                    }
                  </React.Fragment>
                }


              </div>
            }

          </div>

        </li>
      );

      currentDate.setDate(dayId + 1);
      currentMonth = currentDate.getMonth();
    }

    return dayElements;
  }

  getServiceSelectors() {
    return this.state.services.map((service) => {
      const serviceSelected = service === this.state.activeService;

      return (
        <button
          key={`training_period_schedule:service:${service.toLowerCase().replace(/ /g, '_')}`}
          className={`training-period-schedule__service-button`}
          disabled={serviceSelected}
          onClick={() => this.setState({
            activeService: service
          })}
        >

          {service}

        </button>
      );
    });
  }

  schedulesAreInconsistent() {
    if(!this.state.activated_periods) {
      return null;
    }

    const schedules = this.state.activated_periods;

    const today = new Date();

    for(let i=0; i < schedules.length; ++i) {
      if(schedules[i].end_date < today.toISOString().slice(0, 10)) {
        continue;
      }

      for(let j=0; j < schedules.length; ++j) {
        if(i === j) {
          continue;
        }
        else if(schedules[j].end_date < today.toISOString().slice(0, 10)) {
          continue;
        }

        if(schedules[i].start_date <= schedules[j].end_date && schedules[i].end_date >= schedules[j].start_date) {
          return false;
        }
      }
    }

    return true;
  }

  getOverwriteHeader() {
    if(this.state.dateToOverwrite) {
      const date = getAsLocalDate(this.state.dateToOverwrite);

      return (
        <span>
          Sobrescrever treino do dia <span className="training-period-schedule__overlay__header__red-text">{date.toLocaleDateString()}</span>
        </span>
      );
    }

    return 'Não configurado';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getStudentsCheckinHeader() {
    if(this.state.selectedCheckinDate) {
      const date = getAsLocalDate(this.state.selectedCheckinDate);

      return (
        <span>
          {this.state.activeService} - <span className="training-period-schedule__overlay__header__red-text">{date.toLocaleDateString()}</span>
        </span>
      );
    }

    return 'Não configurado';
  }

  getStudentCheckinAchievementText(checkinData) {
    let achievementText = null;

    if (checkinData.class_count === 50 ||
        checkinData.class_count === 100 ||
        checkinData.class_count === 200 ||
        checkinData.class_count === 300) {
      achievementText = `${checkinData.class_count} aulas`;
    }

    const todayIsodate = (new Date()).toISOString().slice(5, 10);

    if (todayIsodate === checkinData.birthdate.slice(5, 10)) {
      if (achievementText !== null) {
        achievementText += ' e aniversário'
      }
      else {
        achievementText = 'Aniversário';
      }
    }

    if (achievementText === null) {
      return null;
    }

    return (
      <span className="training-period-schedule__checkin-list__item__achievement-text">({achievementText})</span>
    )
  }

  getStudentsCheckinContent() {
    if (this.state.studentsCheckinData === null) {
      return null;
    }

    return this.state.studentsCheckinData.map((entry) => {
      return (
        <React.Fragment key={`training_period_schedule:service:${this.state.activeService}:date:${this.state.selectedCheckinDate}:time:${entry.time}`}>

          <DefaultSubSectionTitle
            className="training-period-schedule__checkin-list__class-section"
            icon={<i className="fa-regular fa-clock"></i>}
            text={entry.time}
          />

          <div className="training-period-schedule__checkin-list">

            {entry.checkins.map((data) => (
              <div
                key={`training_period_schedule:service:${this.state.activeService}:date:${this.state.selectedCheckinDate}:time:${entry.time}:checkin_id:${data.id}`}
                className="training-period-schedule__checkin-list__item"
              >

                <h4 className="training-period-schedule__checkin-list__item__name">
                  {data.name}
                  {this.getStudentCheckinAchievementText(data)}
                </h4>

                <Link
                  className="model-table__default-link-button training-period-schedule__checkin-list__item__link-button"
                  to={`${routes.STUDENT_MANAGE_PATH}${data.id}`}
                >

                    <i className="fas fa-link"></i>

                </Link>

              </div>
            ))}

            {entry.experimentals.map((data) => (
              <div
                key={`training_period_schedule:service:${this.state.activeService}:date:${this.state.selectedCheckinDate}:time:${entry.time}:experimental_id:${data.id}`}
                className="training-period-schedule__checkin-list__item"
              >

                <h4 className="training-period-schedule__checkin-list__item__name">{data.name} <span className="training-period-schedule__checkin-list__item__name--red">(EXP)</span></h4>

                <Link
                  className="model-table__default-link-button training-period-schedule__checkin-list__item__link-button"
                  to={`${routes.EXPERIMENTAL_CLASS_EDIT_PATH}${data.id}`}
                >

                    <i className="fas fa-link"></i>

                </Link>

              </div>
            ))}

          </div>

        </React.Fragment>
      );
    })
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="training-period-schedule__overlay"
          visible={this.state.dateToOverwrite != null && this.state.loadingData !== true}
          actions={(
            <div className="training-period-schedule__overlay__action-container">

              <DefaultMenuButton
                className="training-period-schedule__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-period-schedule__overlay__action-button"
                onClick={() => {
                  this.proceedToOverwriteDate();
                }}
                text="Confirmar"
                disabled={this.state.overwriteId <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="training-period-schedule__overlay__header">

            <h3 className="training-period-schedule__overlay__header__title">
              {this.getOverwriteHeader()}
            </h3>

          </header>

          <hr className="training-period-schedule__horizontal-rule" />

          <div className="training-period-schedule__overlay__content">

            <DefaultInput
              name="overwriteId"
              label="ID do treino"
              type="number"
              placeholder="ID do treino"
              min="0"
              step="1"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.overwriteId}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={true}
            />

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="training-period-schedule__overlay"
          visible={this.state.checkinStudentsVisible}
          loading={this.state.loadingCheckinStudents}
          actions={(
            <div className="training-period-schedule__overlay__action-container">

              <DefaultMenuButton
                className="training-period-schedule__overlay__action-button"
                onClick={() => this.setState({checkinStudentsVisible: false})}
                text="Fechar"
              />

            </div>
          )}
        >

          <header className="training-period-schedule__overlay__header">

            <h3 className="training-period-schedule__overlay__header__title">
              {this.getStudentsCheckinHeader()}
            </h3>

          </header>

          <hr className="training-period-schedule__horizontal-rule" />

          <div className="training-period-schedule__overlay__content">

            {this.getStudentsCheckinContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.getConfirmationWindowCancelText()}
          visible={this.state.deleteScheduleId !== null ||
                   this.state.updateDateInfo !== null ||
                   this.state.dateToRemoveOverwrite != null ||
                   (this.state.confirmFailed === true && this.state.dateToOverwrite != null)}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.TRAINING_SCHEDULE_MANAGE_PATH,
              text: "Calendário de treinos"
            },
          ]}
          titleIcon={<i className="fas fa-calendar-alt"></i>}
          title="Calendário de treinos"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-period-schedule"
            title="Gerenciar calendário de treinos"
          >

            <DefaultSubSectionTitle
              className="training-period-schedule__service-selector-header"
              icon={<i className="fas fa-list"></i>}
              text="Selecione o serviço que deseja configurar"
            />

            <div className="training-period-schedule__service-selector">

              {this.getServiceSelectors()}

            </div>

            {this.state.activeService !== null &&
              <React.Fragment>

                {this.schedulesAreInconsistent() === false &&
                  <div className="training-period-schedule__warning-container">

                    <p className="training-period-schedule__warning-container__text">

                      Dias com mais de uma periodização ativa detectados. Para que o sistema funcione corretamente deve haver apenas uma única periodização ativa por dia. Por favor, ajuste a configuração do calendário e das periodizações ativas para corrigir a inconsistência.

                    </p>

                  </div>
                }

                <div className="training-period-schedule__links-wrapper">

                  <a
                    className="training-period-schedule__default-button"
                    href={`${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${routes.TRAINING_CALENDAR_VIEW_PATH}${this.state.days_info.auth_hash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >

                    <i className="fas fa-link"></i> Visualizar periodização

                  </a>

                </div>

                {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID) &&
                  <React.Fragment>

                    <HorizontalRule />

                    <section className="training-period-schedule__activated-periods">

                      <header
                        className="training-period-schedule__activated-periods__header"
                        onClick={() => this.setState({activatedPeriodsVisible: !this.state.activatedPeriodsVisible})}
                      >

                        <h3 className="training-period-schedule__activated-periods__header__text">
                          <i className="fas fa-list-ul training-period-schedule__activated-periods__header__text-icon"></i>
                          Periodizações ativadas
                        </h3>

                        {this.state.activatedPeriodsVisible ?
                          <i className="fas fa-chevron-down training-period-schedule__activated-periods__header__visible-icon"></i>:
                          <i className="fas fa-chevron-up training-period-schedule__activated-periods__header__visible-icon"></i>
                        }

                      </header>

                      <VerticalAccordionContainer
                        className="vertical-accordion-container training-period-schedule__activated-periods__content"
                        pose={this.state.activatedPeriodsVisible ? 'verticalOpen' : 'verticalClosed'}>

                        <div className="vertical-accordion-container training-period-schedule__activated-periods__content-wrapper">

                          <ModelTable
                            properties={this.getProperties()}
                            getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
                            data={this.state.activated_periods}
                            initialOrderBy="end_date"
                            initialOrderIsDecrescent={true}
                          >

                            {this.props.userPermissionIds.includes(permissions.ADD_TRAINING_PERIOD_SCHEDULE_PERMISSION_ID) &&
                              <Link
                                className="model-table__default-button"
                                to={routes.TRAINING_SCHEDULE_ACTIVATE_PERIOD_PATH}
                              >

                                <i className="fas fa-plus"></i> Ativar periodização

                              </Link>
                            }

                          </ModelTable>

                        </div>

                      </VerticalAccordionContainer>

                    </section>

                  </React.Fragment>
                }

                <HorizontalRule />

                <section className="training-period-schedule__calendar">

                  <header className="training-period-schedule__calendar__header">

                    <button
                      className="training-period-schedule__calendar__date-change-button"
                      onClick={() => {this.changeMonth(-1)}}
                    >

                      <i className="fas fa-chevron-left"></i>

                    </button>

                    <h3 className="training-period-schedule__calendar__header-text">

                      {this.getDateText()}

                    </h3>

                    <button
                      className="training-period-schedule__calendar__date-change-button"
                      onClick={() => {this.changeMonth(1)}}
                    >

                      <i className="fas fa-chevron-right"></i>

                    </button>

                  </header>

                  <ul className="training-period-schedule__calendar__days-list">

                    {this.getMonthDays()}

                  </ul>

                </section>

              </React.Fragment>
            }

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingPeriodSchedule;
