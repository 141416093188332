const GREEN_COLOR_PALLET = [
  '#2a6d3c',
  '#358a4d',
  '#3fa45b',
  '#4cba6b',
  '#71c989',
  '#a1dbb1',
];

const RED_COLOR_PALLET = [
  '#930a0a',
  '#b90c0d',
  '#dc0d0e',
  '#f33334',
  '#f66364',
  '#f99494',
];

function Metric(value, color) {
  return {value, color}
}

function getSystolicBloodPressureMetric(value) {
  if(!value) {
    return '';
  }

  if(value < 120) {
    return Metric('Ótima', GREEN_COLOR_PALLET[4]);
  }
  else if(value < 130) {
    return Metric('Normal', GREEN_COLOR_PALLET[2]);
  }
  else if(value <= 138) {
    return Metric('Limítrofe', GREEN_COLOR_PALLET[0]);
  }
  else if(value <= 159) {
    return Metric('Hipertensão - estágio 1 (leve)', RED_COLOR_PALLET[0]);
  }
  else if(value <= 179) {
    return Metric('Hipertensão - estágio 2 (moderada)', RED_COLOR_PALLET[2]);
  }
  else {
    return Metric('Hipertensão - estágio 3 (grave)', RED_COLOR_PALLET[4]);
  }
}

function getDiastolicBloodPressureMetric(value) {
  if(!value) {
    return '';
  }

  if(value < 80) {
    return Metric('Ótima', GREEN_COLOR_PALLET[4]);
  }
  else if(value < 85) {
    return Metric('Normal', GREEN_COLOR_PALLET[2]);
  }
  else if(value <= 89) {
    return Metric('Limítrofe', GREEN_COLOR_PALLET[0]);
  }
  else if(value <= 99) {
    return Metric('Hipertensão - estágio 1 (leve)', RED_COLOR_PALLET[0]);
  }
  else if(value <= 109) {
    return Metric('Hipertensão - estágio 2 (moderada)', RED_COLOR_PALLET[2]);
  }
  else {
    return Metric('Hipertensão - estágio 3 (grave)', RED_COLOR_PALLET[4]);
  }
}

export {getSystolicBloodPressureMetric, getDiastolicBloodPressureMetric};
