import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {APPOINTMENT_TYPE_PHYSICAL_EVALUATION,
        APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION,
        DEFAULT_UNIT_TYPE} from '../../../constants';
import {getModels, postModel, setUrlParameters} from '../../../utils/functions';
import AppointmentScheduleData from './appointment_schedule_data';


class AppointmentScheduleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment_schedule: {
        responsible_id: this.isDefaultUnit() ? '' : this.props.userId,
        appointment_type_id: this.isDefaultUnit() ? '' : APPOINTMENT_TYPE_PHYSICAL_EVALUATION.id,
        appointment_room_id: "",
        date: "",
        start_at: "",
        end_at: "",
        default_entry_duration: this.isDefaultUnit() ? '' : APPOINTMENT_TYPE_PHYSICAL_EVALUATION.default_appointment_duration,
      },
      appointment_types: [],
      appointment_rooms: [],
      responsible_options: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async componentDidMount() {
    const update = {
      loading: false,
      appointment_schedule: {...this.state.appointment_schedule}
    };

    let appointment_types = getModels(routes.APPOINTMENT_TYPES_GET_API);
    let appointment_rooms = getModels(setUrlParameters(routes.APPOINTMENT_ROOMS_GET_API, {is_active: true}));

    if (this.isDefaultUnit()) {
      const responsible_options = await getModels(routes.APPOINTMENT_RESPONSIBLE_OPTIONS_GET_API);

      if(responsible_options) {
        update.responsible_options = responsible_options;
        update.responsible_options.sort((a, b) => a.name.localeCompare(b.name));
      }
    }

    appointment_types = await appointment_types;

    if(appointment_types) {
      update.appointment_types = appointment_types;
      update.appointment_types.sort((a, b) => a.name.localeCompare(b.name));
    }

    appointment_rooms = await appointment_rooms;

    if(appointment_rooms) {
      update.appointment_rooms = appointment_rooms;
      update.appointment_rooms.sort((a, b) => a.name.localeCompare(b.name));

      if (update.appointment_rooms.length === 1) {
        update.appointment_schedule.appointment_room_id = update.appointment_rooms[0].id;
      }
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {};

    if((name === 'appointment_type_id' || name === 'responsible_id' || name === 'appointment_room_id' || name === 'default_entry_duration') && value.length > 0) {
      value = parseInt(value);
    }

    update.appointment_schedule = {...this.state.appointment_schedule, [name]: value};

    if(name === 'appointment_type_id') {
      switch (value) {
        case APPOINTMENT_TYPE_PHYSICAL_EVALUATION.id:
          update.appointment_schedule.default_entry_duration = APPOINTMENT_TYPE_PHYSICAL_EVALUATION.default_appointment_duration;
          break;
        case APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION.id:
          update.appointment_schedule.default_entry_duration = APPOINTMENT_TYPE_NUTRITIONAL_EVALUATION.default_appointment_duration;
          break;
        default:
      }
    }

    this.setState(update);
  }

  inputsAreValid() {
    return this.state.appointment_schedule.date.length > 0 &&
           this.state.appointment_schedule.start_at.length > 0 &&
           this.state.appointment_schedule.end_at.length > 0 &&
           this.state.appointment_schedule.start_at <= this.state.appointment_schedule.end_at &&
           this.state.appointment_schedule.responsible_id > 0 &&
           this.state.appointment_schedule.appointment_type_id > 0 &&
           this.state.appointment_schedule.appointment_room_id > 0 &&
           this.state.appointment_schedule.default_entry_duration > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.appointment_schedule};

    data.start_at = `${data.date}T${data.start_at}`;
    data.end_at = `${data.date}T${data.end_at}`;
    delete data.date

    try {
      await postModel(routes.APPOINTMENT_SCHEDULE_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 106:
              for(let parameter of error.parameters) {
                if (parameter.name.startsWith('Room already scheduled for selected period')) {
                  warningMessages.push('Sala se encontra ocupada no período selecionado');
                  highlights.push('start_at');
                  highlights.push('end_at');
                  highlights.push('appointment_room_id');
                }
                else if (parameter.name.startsWith('Responsible already scheduled for selected period')) {
                  warningMessages.push('Responsável já se encontra ocupada no período selecionado');
                  highlights.push('start_at');
                  highlights.push('end_at');
                  highlights.push('responsible_id');
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // this.props.history.replace(routes.APPOINTMENT_SCHEDULE_LIST_PATH);
    this.props.history.goBack();
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.APPOINTMENT_SCHEDULE_LIST_PATH,
            text: "Calendário de atendimento"
          },
          {
            path: routes.APPOINTMENT_SCHEDULE_ADD_PATH,
            text: "Adicionar período"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar período de atendimento"
        loading={this.state.loading}
      >

        <AppointmentScheduleData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          appointment_schedule={this.state.appointment_schedule}
          appointment_types={this.state.appointment_types}
          appointment_rooms={this.state.appointment_rooms}
          responsible_options={this.state.responsible_options}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onClickCancelPath={() => this.props.history.goBack()}
          unit_type_id={this.props.unit_type_id}
        />

      </ContentFrame>
    );
  }
}

export default AppointmentScheduleAdd;
