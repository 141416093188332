import React from 'react';
import { Link } from 'react-router-dom';
import './exercise_activity_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import * as permissions from '../../permissions';

class ExerciseActivityData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.exercise_activity.id && this.props.exercise_activity.id > 0;
  }

  getExerciseActivityTypeOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.exerciseActivityTypes.map((activityType) => SelectOption(activityType.id, activityType.name))
      ];
    }

    return [
      SelectOption('', 'Selecione uma capacidade'),
      ...this.props.exerciseActivityTypes.map((activityType) => SelectOption(activityType.id, activityType.name))
    ];
  }

  render() {
    const inputsAreDisabled = !this.props.userPermissionIds.includes(permissions.EDIT_EXERCISE_ACTIVITY_PERMISSION_ID);

    return (
      <DefaultSection
        className="exercise-activity-data"
        title="Dados da atividade"
      >

        <div className="exercise-activity-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="exercise-activity-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome da atividade"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_activity.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={inputsAreDisabled}
            />

            <DefaultInput
              name="activity_type_id"
              isHighlighted={this.isHighlighted("activity_type_id")}
              label="Capacidade"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_activity.activity_type_id || ''}
              options={this.getExerciseActivityTypeOptions()}
              disabled={inputsAreDisabled}
            />

          </HalfWrapper>

          <DefaultInput
            name="met"
            isHighlighted={this.isHighlighted("met")}
            label="Equivalente Metabólico da Tarefa (MET)"
            labelMessage={this.props.totalValueMessage}
            type="number"
            placeholder="Valor MET"
            min="0.0"
            step="0.1"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exercise_activity.met}
            autoComplete="off"
            suffix="kcal / (kg.hora)"
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
            disabled={inputsAreDisabled}
          />

          <HalfWrapper>

            <DefaultInput
              name="show_in_student_app"
              label="Listar no app do aluno:"
              type="toggle"
              // isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_activity.show_in_student_app}
            />

            <DefaultInput
              name="used_in_trainings"
              label="Exibir em cadastro de treino:"
              type="toggle"
              // isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.exercise_activity.used_in_trainings}
            />

          </HalfWrapper>

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição da atividade"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.exercise_activity.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={inputsAreDisabled}
          />

        </div>

        <HorizontalRule />

        <div className="exercise-activity-data__buttons-container">

          {!inputsAreDisabled &&
            <button
              className="exercise-activity-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
              >

                Salvar

              </button>
          }

          <Link
            className="exercise-activity-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {!inputsAreDisabled ? 'Cancelar' : 'Voltar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default ExerciseActivityData;
