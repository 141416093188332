import React from 'react';
import { Link } from 'react-router-dom';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import './experimental_class_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import {getModel, getModels, deleteModel, patchModel, getLocalDateIsoString, getAsLocalDatetime, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';

class ExperimentalClassList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      experimental_classes: [],
      services: [],
      selectedService: "",
      loadingData: false,
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      feedbackPath: null,
      screenWidth: window.innerWidth,
      updateStudentId: null,
    };

    if(this.props.completedClasses) {
      let queryParameters = (new URLSearchParams(props.location.search));

      let initialDate = queryParameters.get('initial_date');
      let finalDate = queryParameters.get('final_date');

      if(!initialDate) {
        initialDate = new Date();
        initialDate.setMonth(initialDate.getMonth() - 1);
        initialDate = getLocalDateIsoString(initialDate);
      }
      if(!finalDate) {
        finalDate = new Date();
        finalDate = getLocalDateIsoString(finalDate);
      }

      this.state.initialDateInput = initialDate;
      this.state.finalDateInput = finalDate;
      this.state.initialDate = initialDate;
      this.state.finalDate = finalDate;
    }
  }

  async getExperimentalClasses() {
    let classes;

    if(this.props.completedClasses) {
      classes = await getModels(`${routes.EXPERIMENTAL_CLASSES_GET_API}?marked=true&initial_date=${this.state.initialDate}&final_date=${this.state.finalDate}`)
    }
    else {
      classes = await getModels(`${routes.EXPERIMENTAL_CLASSES_GET_API}?marked=false`)
    }

    return classes;
  }

  async getExperimentalClassesServices() {
    let services = await getModels(routes.EXPERIMENTAL_CLASS_SERVICES_GET_API);

    services.sort((a, b) => a.localeCompare(b));

    return services;
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialDate !== this.state.initialDate || prevState.finalDate !== this.state.finalDate) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    if(this.props.completedClasses && this.state.initialDate > this.state.finalDate) {
      return;
    }

    this.setState({
      loadingData: true
    });

    let experimental_classes = this.getExperimentalClasses();
    let services = [];
    let selectedService = '';

    if(!this.props.completedClasses) {
      services = this.getExperimentalClassesServices();
    }

    experimental_classes = await experimental_classes;

    if(!this.props.completedClasses) {
      this.props.onReloadList(experimental_classes);

      services = await services;

      if(services.length > 0) {
        selectedService = services[0];
      }
    }

    this.setState({
      experimental_classes,
      services,
      selectedService,
      loadingData: false,
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false,
      updateStudentId: null,
    });
  }

  onCancelConfirmation() {
    this.setState({
      deleteId: null,
      updateStudentId: null
    });
  }

  async onConfirm() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.updateStudentId) {
      try {
        if(await patchModel(`${routes.EXPERIMENTAL_CLASS_CHECK_IN_API}${this.state.updateStudentId.id}`, {checked_in: this.state.updateStudentId.checkedIn})) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      try{
        if(await deleteModel(`${routes.EXPERIMENTAL_CLASS_DELETE_API}${this.state.deleteId}`)) {
          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteId: null,
      updateStudentId: null
    });
  }

  // listHasActions() {
  //   return this.props.userPermissionIds.includes(permissions.EDIT_EXPERIMENTAL_CLASS_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_EXPERIMENTAL_CLASS_PERMISSION_ID);
  // }

  async onOpenFeedbackForm(entry) {
    let path;

    if(this.state.feedbackPath === null) {
      this.setState({
        loading: true
      });

      let feedbackPath = await getModel(routes.FEEDBACK_PATH_GET_API);

      path = `${window.location.protocol}//${window.location.host.replace('admin.', '').replace(':3000', ':5000')}${feedbackPath}`;

      this.setState({
        loading: false,
        feedbackPath: path
      });
    }
    else {
      path = this.state.feedbackPath;
    }

    const parameters = {
      name: entry.name,
      email: entry.email,
      phone: entry.phone.replace(/\D/g,""),
      target_service: entry.target_service
    };

    window.open(setUrlParameters(path, parameters), '_blank');
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={{
              pathname: `${routes.EXPERIMENTAL_CLASS_EDIT_PATH}${entry.id}`,
              state: { lastPath: this.props.location.pathname }
            }}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_EXPERIMENTAL_CLASS_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

        <button
          className="model-table__default-link-button"
          onClick={() => this.onOpenFeedbackForm(entry)}
        >

            <i className="fas fa-link"></i>

        </button>

      </div>
    );
  }

  getContactText(entry) {
    return (
      <div className="experimental-class-list__contact">

        <p className="experimental-class-list__contact__email"><i className="fas fa-envelope-square experimental-class-list__contact__icon"></i>{entry.email}</p>
        <p className="experimental-class-list__contact__phone"><i className="fas fa-phone-square experimental-class-list__contact__icon"></i>{entry.phone}</p>

      </div>
    );
  }

  getDateText(entry) {
    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDatetime(entry.date, false);
    const dateString = getLocalDateIsoString(date);

    const dateFormat = {day: '2-digit', month: '2-digit'};

    let dateText = '';
    let hourText = '';

    if(!this.props.completedClasses) {
      if(this.state.screenWidth > 550) {
        if(this.state.screenWidth > 610) {
          dateFormat.weekday = 'short';
          dateFormat.year = 'numeric';
        }

        if(this.state.screenWidth > 1310) {
          dateFormat.weekday = 'long';
        }

        dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);
      }

      hourText = new Intl.DateTimeFormat('pt-BR', {hour: '2-digit', minute: '2-digit'}).format(date);
    }
    else {
      dateFormat.year = 'numeric';

      if(this.state.screenWidth > 550) {
        hourText = new Intl.DateTimeFormat('pt-BR', {hour: '2-digit', minute: '2-digit'}).format(date);
      }

      if(this.state.screenWidth > 610) {
        dateFormat.weekday = 'short';
      }

      if(this.state.screenWidth > 1310) {
        dateFormat.weekday = 'long';
      }

      dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);
    }

    return (
      <div className="experimental-class-list__cell-wrapper">

        <p className={`experimental-class-list__date-text${dateString > todayString ? '' : dateString < todayString ? '--past' : '--today'}`}>

          {dateText}
          {' '}
          <span className="experimental-class-list__time-text">{hourText}</span>

        </p>

      </div>
    );
  }

  getPhysicalConditionText(entry) {
    if(!entry.physical_condition) {
      return (
        <div className="experimental-class-list__cell-wrapper">

          <p className="experimental-class-list__physical-condition-text--empty">

            <i className="fas fa-ban experimental-class-list__no-physical-condition-icon"></i>{' '}Sem restrições

          </p>

        </div>
      );
    }

    return entry.physical_condition;
  }

  getPhysicalActivityText(entry) {
    if(!entry.physical_activity) {
      return (
        <div className="experimental-class-list__cell-wrapper">

          <p className="experimental-class-list__physical-condition-text--empty">

            <i className="fas fa-exclamation-triangle experimental-class-list__no-physical-condition-icon"></i>{' '}Sedentário

          </p>

        </div>
      );
    }

    return entry.physical_activity;
  }

  getCheckedInText(entry) {
    let showText = false;

    if(entry.checked_in === null) {
      if((this.props.completedClasses && this.state.screenWidth > 1260) || (!this.props.completedClasses && this.state.screenWidth > 1470)) {
        showText = true
      }

      return (
        <div className="experimental-class-list__cell-wrapper">

          <button
            className="experimental-class-list__check-in-button"
            onClick={() => this.setState({
              updateStudentId: {id: entry.id, checkedIn: true},
              deleteId: null,
              confirmInProgress: false,
              confirmFailed: false,
            })}
          >

            <i className="fas fa-thumbs-up experimental-class-list__check-icon"></i>
            {showText &&
              'Compareceu'
            }

          </button>

          <button
            className="experimental-class-list__check-out-button"
            onClick={() => this.setState({
              updateStudentId: {id: entry.id, checkedIn: false},
              deleteId: null,
              confirmInProgress: false,
              confirmFailed: false,
            })}
          >

            {showText &&
              'Faltou'
            }
            <i className="fas fa-thumbs-down experimental-class-list__check-icon"></i>

          </button>

        </div>
      );
    }
    else {
      if(this.state.screenWidth > 420) {
        showText = true
      }

      return (
        <div className="experimental-class-list__cell-wrapper">

          {entry.checked_in ?
            <button
              className="experimental-class-list__check-in-button--flex"
              disabled
            >

              <i className="fas fa-thumbs-up experimental-class-list__check-icon"></i>
              {showText &&
                'Compareceu'
              }

            </button>:
            <button
              className="experimental-class-list__check-out-button--flex"
              disabled
            >

              {showText &&
                'Faltou'
              }
              <i className="fas fa-thumbs-down experimental-class-list__check-icon"></i>

            </button>
          }

        </div>
      );
    }
  }

  getProperties() {
    let properties = [];

    if(this.props.completedClasses) {
      properties.push(Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>),);
    }

    properties.push(Property('name', 'Nome', <i className="fas fa-tag"></i>));

    if((this.props.completedClasses && this.state.screenWidth > 1160) || (!this.props.completedClasses && this.state.screenWidth > 1380)) {
      properties.push(
        Property('contact', 'Contato', <i className="fas fa-phone"></i>, {
          sortable: false,
          getDataText: this.getContactText,
          getFilterText: (entry) => `${entry.email} ${entry.phone}`
        })
      );
    }

    if(!this.props.completedClasses) {
      properties.push(
        Property('physical_condition', 'Condição', <i className="fas fa-notes-medical"></i>, {
          getDataText: this.getPhysicalConditionText,
          getFilterText: (entry) => entry.physical_condition || ''
        })
      );
    }

    properties.push(Property('date', this.state.screenWidth > 610 ? 'Data agendada' : this.props.completedClasses ? 'Data': 'Hora', <i className="fas fa-calendar-day"></i>, {
      getDataText: (entry) => this.getDateText(entry),
      getFilterText: (entry) => new Intl.DateTimeFormat('pt-BR', {
        weekday: 'long',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'}).format(getAsLocalDatetime(entry.date, false))
    }))

    if(!this.props.completedClasses) {
      properties.push(
        Property('physical_activity', 'Atividade física', <i className="fas fa-running"></i>, {
          getDataText: this.getPhysicalActivityText,
          getFilterText: (entry) => entry.physical_activity || ''
        })
      );
    }

    properties = [
      ...properties,
      Property('checked_in', 'Presença', <i className="fas fa-tasks"></i>, {
        getDataText: (entry) => this.getCheckedInText(entry),
        sortable: this.props.completedClasses,
        getFilterText: (entry) => entry.checked_in ? 'compareceu' : 'faltou'
      }),
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.updateStudentId) {
        return 'Alterando presença';
      }

      return 'Deletando aula';
    }
    else if(this.state.confirmFailed) {
      if(this.state.updateStudentId) {
        return 'Falha ao alterar presença';
      }

      return 'Falha ao deletar';
    }
    else if(this.state.updateStudentId) {
      return 'Alterar presença';
    }

    return 'Deletar aula';
  }

  getServiceSelectors() {
    return this.state.services.map((service) => {
      const serviceSelected = service === this.state.selectedService;

      return (
        <button
          key={`experimental_class_service_selector_${service.toLowerCase().replace(/ /g, '_')}`}
          className="experimental-class-list__service-button"
          disabled={serviceSelected}
          onClick={() => this.setState({
            selectedService: service
          })}
        >

          {service}

          <span className="experimental-class-list__service-button__counter">

            {this.state.experimental_classes.filter((entry) => entry.target_service === service).length}

          </span>

        </button>
      );
    });
  }

  getClasses() {
    if(!this.props.completedClasses && this.state.selectedService) {
      return this.state.experimental_classes.filter((entry) => entry.target_service === this.state.selectedService);
    }

    return this.state.experimental_classes;
  }

  mayUpdateDateInputs() {
    if(!this.state.initialDateInput || !this.state.finalDateInput) {
      return false;
    }

    if(this.state.initialDateInput !== this.state.initialDate || this.state.finalDateInput !== this.state.finalDate) {
      return true;
    }

    return false;
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(this.props.completedClasses ? routes.EXPERIMENTAL_CLASS_COMPLETED_PATH : routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH, {
        initial_date: this.state.initialDateInput,
        final_date: this.state.finalDateInput,
      }));

      this.setState({
        initialDate: this.state.initialDateInput,
        finalDate: this.state.finalDateInput,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : this.state.updateStudentId ? `Alterar presença para ${this.state.updateStudentId.checkedIn ? '"compareceu"' : '"faltou"'}` : 'Todos os dados relacionados à aula experimental serão removidos'}
          confirmText={this.state.updateStudentId ? `Alterar presença` : 'Deletar aula'}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null || this.state.updateStudentId !== null}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirm()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: this.props.completedClasses ? routes.EXPERIMENTAL_CLASS_COMPLETED_PATH : routes.EXPERIMENTAL_CLASS_SCHEDULED_PATH,
              text: this.props.completedClasses ? 'Aulas experimentais' : 'Aulas agendadas'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title={this.props.completedClasses ? 'Aulas experimentais' : 'Aulas agendadas'}
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="experimental-class-list"
            title={this.props.completedClasses ? 'Lista de aulas experimentais completas' : 'Lista de aulas agendadas'}
          >
            {!this.props.completedClasses ?
              <React.Fragment>

                <div className="experimental-class-list__service-selector">

                  {this.getServiceSelectors()}

                </div>

                <HorizontalRule />

              </React.Fragment>:
              <div className="experimental-class-list__filters">

                <header className="experimental-class-list__filters__header">

                  <h4 className="experimental-class-list__filters__header__text">Filtros</h4>

                </header>

                <div className="experimental-class-list__filters__inputs">

                  <div className="experimental-class-list__filters__inputs-wrapper">

                    <HalfWrapper>

                      <DefaultInput
                        name="initialDateInput"
                        isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                        label="Data inicial"
                        type="date"
                        placeholder="Data inicial"
                        max={this.state.finalDateInput}
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.initialDateInput}
                      />

                      <DefaultInput
                        name="finalDateInput"
                        isHighlighted={this.state.initialDateInput > this.state.finalDateInput}
                        label="Data final"
                        type="date"
                        placeholder="Data final"
                        min={this.state.initialDateInput}
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.finalDateInput}
                      />

                    </HalfWrapper>

                  </div>

                  <button
                    className="experimental-class-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                    disabled={!this.mayUpdateDateInputs()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>

                </div>

              </div>
            }

            <ModelTable
              key={`service:${this.state.selectedService}:experimental_class_list`}
              storageKey={this.state.selectedService}
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.getClasses()}
              initialOrderBy="date"
              initialOrderIsDecrescent={this.props.completedClasses}
            />

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default ExperimentalClassList;
