import React from 'react';
import ContentFrame from '../content_frame';
import ConfirmationWindow from '../confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import * as routes from '../../constants';
import {PERSONAL_TRAINING_PATH} from '../student/constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, TRAINING_EXECUTION_METHOD_FIXED_REPS, DEFAULT_UNIT_TYPE} from '../../constants';
import * as paths from './constants';
import {TRAINING_DAY_EDIT_PATH, TRAINING_DAY_POST_PATH} from '../training_period/constants';
import {getModels, getModel, patchModel, postModel, deleteModel} from '../../utils/functions';
import DefaultMenuButton from '../../components/default_menu_button';
import DefaultInput from '../../utils/default_input';
import TrainingDayData from './training_day_data';
import './training_day_edit.scss';

class TrainingDayEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training_day: {
        name: "",
        note: "",
        expected_pse: null,
        description_url: null,
        order: null,
        group_associations: [],
        is_placeholder: false,
      },
      period_user_id: null,
      addExerciseGroupSelect: '',
      exerciseGroups: [],
      highlights: [],
      warningMessage: "",
      warningMessageBackground: null,
      warningMessageColor: null,
      showWarningMessage: false,
      loading: true,
      associationToTogglePreviousLink: null,
      deleteAssociationId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      willImportExerciseGroup: null,
      exerciseGroupIdToImport: 0,
      screenWidth: window.innerWidth
    };
  }

  async reloadTrainingDay() {
    let training_day = getModel(`${routes.TRAINING_DAY_GET_API}${this.props.match.params.trainingDayId}`);
    let exerciseGroups = getModels(routes.EXERCISE_GROUPS_GET_API);

    const update = {
      loading: false
    }

    training_day = await training_day;

    if(training_day) {
      update.training_day = {...this.state.training_day, ...training_day};

      if(training_day.period.user_id !== null) {
        update.period_user_id = training_day.period.user_id;
      }
    }

    exerciseGroups = await exerciseGroups;

    if(exerciseGroups) {
      update.exerciseGroups = exerciseGroups;
      update.exerciseGroups.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.reloadTrainingDay();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'addExerciseGroupSelect') {
      this.setState({
        addExerciseGroupSelect: value
      });

      return;
    }
    else if(name === 'exerciseGroupIdToImport') {
      const update = {[name]: value};

      this.setState(update);

      return;
    }

    const newData = {...this.state.training_day, [name]: value};

    this.setState({
      training_day: newData
    });
  }

  inputsAreValid() {
    return this.state.training_day.name.length > 0;
  }

  onDeleteAssociation(association) {
    this.setState({
      deleteAssociationId: association.id,
      associationToTogglePreviousLink: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onToggleMergeWithPreviousGroup(association) {
    this.setState({
      associationToTogglePreviousLink: association,
      deleteAssociationId: null,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onEditAssociation(association) {
    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${paths.TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${association.id}`);
  }

  onCancelConfirmation() {
    this.setState({
      associationToTogglePreviousLink: null,
      deleteAssociationId: null,
      willImportExerciseGroup: null,
      exerciseGroupIdToImport: 0,
    });
  }

  onImportExerciseGroup() {
    this.setState({
      willImportExerciseGroup: true,
      confirmInProgress: false,
      confirmFailed: false})
  }

  async onAcceptConfirmation() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.deleteAssociationId != null) {
      try{
        if(await deleteModel(`${routes.TRAINING_DAY_GROUP_ASSOCIATION_DELETE_API}${this.state.deleteAssociationId}`)) {
          this.reloadTrainingDay();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.associationToTogglePreviousLink != null) {
      const data = {merge_with_previous_group: !this.state.associationToTogglePreviousLink.merge_with_previous_group};

      try{
        if(await postModel(routes.TRAINING_DAY_GROUP_ASSOCIATION_UPDATE_LINK_POST_API.replace('{id}', this.state.associationToTogglePreviousLink.id), data)) {
          const listCopy = [];

          for(const association of this.state.training_day.group_associations) {
            if(association.id === this.state.associationToTogglePreviousLink.id) {
              listCopy.push({
                ...association,
                merge_with_previous_group: data.merge_with_previous_group
              });
            }
            else {
              listCopy.push(association);
            }
          }

          listCopy.sort((a, b) => a.order - b.order);

          this.setState({training_day: {...this.state.training_day, group_associations: listCopy}});
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      deleteAssociationId: null,
      associationToTogglePreviousLink: null
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.deleteAssociationId != null) {
        return 'Removendo agrupamento';
      }
      else if(this.state.associationToTogglePreviousLink != null) {
        return this.state.associationToTogglePreviousLink.merge_with_previous_group ? 'Desvinculando agrupamentos' : 'Unificando agrupamentos';
      }
      else if(this.state.willImportExerciseGroup != null && this.state.exerciseGroupIdToImport > 0) {
        return 'Importando agrupamento';
      }

      return 'Unknown';
    }
    else if(this.state.confirmFailed) {
      if(this.state.deleteAssociationId != null) {
        return 'Falha ao remover agrupamento';
      }
      else if(this.state.associationToTogglePreviousLink != null) {
        return this.state.associationToTogglePreviousLink.merge_with_previous_group ? 'Falha ao desvincular agrupamentos' : 'Falha ao unificar agrupamentos';
      }
      else if(this.state.willImportExerciseGroup != null) {
        return 'Falha ao importar agrupamento';
      }

      return 'Unknown fail';
    }

    if(this.state.deleteAssociationId != null) {
      return 'Remover agrupamento';
    }
    else if(this.state.associationToTogglePreviousLink != null) {
      return this.state.associationToTogglePreviousLink.merge_with_previous_group ? 'Desvincular agrupamentos' : 'Unificar agrupamentos';
    }

    return 'Unknown';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.deleteAssociationId != null) {
      return 'Todos os dados relacionados ao agrupamento serão removidos';
    }
    else if(this.state.associationToTogglePreviousLink != null) {
      return 'IMPORTANTE: Na lousa, os agrupamentos unificados precisam ter seus cronômetros configurados para serem exibidos.';
    }
    else if(this.state.willImportExerciseGroup != null && this.state.exerciseGroupIdToImport > 0) {
      return `Uma cópia do agrupamento ${this.state.exerciseGroupIdToImport} está sendo criada.`;
    }

    return 'Unknown';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.deleteAssociationId != null) {
      return 'Remover agrupamento';
    }
    else if(this.state.associationToTogglePreviousLink != null) {
      return this.state.associationToTogglePreviousLink.merge_with_previous_group ? 'Desvincular' : 'Unificar';
    }

    return 'Unknown';
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    const data = {...this.state.training_day}

    if(data.expected_pse) {
      data.expected_pse = parseInt(data.expected_pse);
    }

    try {
      await patchModel(`${routes.TRAINING_DAY_PATCH_API}${this.props.match.params.trainingDayId}`, data);

      this.reloadTrainingDay();
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessageBackground: null,
        warningMessageColor: null,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    // this.props.history.replace(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}`);
    this.setState({
      warningMessage: "Dados salvos.",
      warningMessageBackground: "#dbf3c1",
      warningMessageColor: "#456d19",
      showWarningMessage: true,
      loading: false
    });
  }

  async proceedExerciseGroupImport() {
    if(this.state.exerciseGroupIdToImport <= 0) {
      return;
    }

    this.setState({
      confirmInProgress: true
    });

    if(this.state.willImportExerciseGroup != null && this.state.exerciseGroupIdToImport > 0) {
      const data = {};

      try{
        if(await postModel(`${routes.TRAINING_DAY_PATCH_API}${this.props.match.params.trainingDayId}${paths.IMPORT_EXERCISE_GROUP_POST_API}${this.state.exerciseGroupIdToImport}`, data)) {
          this.reloadTrainingDay();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 102:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'intensity_value':
                      errorDescription = 'Agrupamento importado possui um erro de configuração.';

                      break;
                    default:
                  }
                }

                break;
              case 203:
              case 208:
                errorDescription = `ID (${this.state.exerciseGroupIdToImport}) inválido.`;

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.setState({
      willImportExerciseGroup: null,
      exerciseGroupIdToImport: 0,
      confirmInProgress: false
    });
  }

  async addExerciseGroup() {
    if(!this.state.addExerciseGroupSelect) {
      return;
    }

    const group_id = parseInt(this.state.addExerciseGroupSelect);

    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    const selectedGroup = this.state.exerciseGroups.find((group) => group.id === group_id);

    const data = {
      order: this.state.training_day.group_associations.length + 1,
      exercise_group_id: group_id,
      execution_method: TRAINING_EXECUTION_METHOD_FIXED_REPS,
      time_required: 0
    };

    if(selectedGroup) {
      data.name = selectedGroup.name;
    }
    else {
      data.name = '';
    }

    let response;

    try {
      response = await postModel(`${TRAINING_DAY_POST_PATH}/${this.props.match.params.trainingDayId}${paths.TRAINING_DAY_GROUP_ASSOCIATION_POST_PATH}`, data, true);
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessageBackground: null,
        warningMessageColor: null,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.setState({
      highlights: [],
      warningMessageBackground: null,
      warningMessageColor: null,
      showWarningMessage: false,
      loading: false
    });

    this.props.history.push(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${paths.TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${response.model_saved.id}`);
  }

  getPreviousPath() {
    let from_calendar = (new URLSearchParams(this.props.location.search)).get('from_calendar');

    if(from_calendar === 'true') {
      return routes.TRAINING_SCHEDULE_MANAGE_PATH;
    }

    return `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}`;
  }

  async onOpenClassroom() {
    this.setState({
      loading: true
    });

    let classroomPath = await getModel(`${routes.TRAINING_DAY_GET_API}${this.props.match.params.trainingDayId}${routes.TRAINING_DAY_CLASSROOM_PATH_API}`);

    const path = `${window.location.protocol}//${window.location.host.replace('admin.', 'lousa.').replace(':3000', ':5000')}${classroomPath}`;

    window.open(path, '_blank');

    this.setState({
      loading: false
    });
  }

  onSwitchOrder(origem, offset) {
    const entriesCount = this.state.training_day.group_associations.length;
    const origiemOrder = origem.order;
    const newOrder = origem.order + offset;

    if(newOrder <= 0 || newOrder > entriesCount) {
      return;
    }

    const listCopy = [];

    for(const association of this.state.training_day.group_associations) {
      if(association.id === origem.id) {
        listCopy.push({
          ...association,
          order: newOrder
        });
      }
      else if(association.order === newOrder) {
        listCopy.push({
          ...association,
          order: origiemOrder
        });
      }
      else {
        listCopy.push(association);
      }
    }

    listCopy.sort((a, b) => a.order - b.order);

    this.setState({training_day: {...this.state.training_day, group_associations: listCopy}});
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="training-day-edit__overlay"
          visible={this.state.willImportExerciseGroup != null && this.state.loading !== true && this.state.confirmInProgress !== true && this.state.confirmFailed !== true}
          actions={(
            <div className="training-day-edit__overlay__action-container">

              <DefaultMenuButton
                className="training-day-edit__overlay__action-button"
                onClick={() => {
                  this.onCancelConfirmation();
                }}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="training-day-edit__overlay__action-button"
                onClick={() => {
                  this.proceedExerciseGroupImport();
                }}
                text="Confirmar"
                disabled={this.state.exerciseGroupIdToImport <= 0}
                color="green"
              />

            </div>
          )}
        >

          <header className="training-day-edit__overlay__header">

            <h3 className="training-day-edit__overlay__header__title">
              Importar agrupamento
            </h3>

          </header>

          <hr className="training-day-edit__horizontal-rule" />

          <div className="training-day-edit__overlay__content">

            <DefaultInput
              name="exerciseGroupIdToImport"
              label="ID do treino"
              type="number"
              placeholder="ID do treino"
              min="0"
              step="1"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.exerciseGroupIdToImport}
              autoComplete="off"
              onFocus={(event) => event.target.select()}
              isHorizontal={true}
            />

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={
            this.state.deleteAssociationId !== null ||
            this.state.associationToTogglePreviousLink !== null ||
            (this.state.confirmFailed === true && this.state.willImportExerciseGroup != null) ||
            (this.state.willImportExerciseGroup != null && this.state.exerciseGroupIdToImport > 0 && this.state.confirmInProgress)
          }
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onAcceptConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: this.state.period_user_id !== null ? `${routes.STUDENT_EDIT_PATH}${this.state.period_user_id}${PERSONAL_TRAINING_PATH}` : routes.TRAINING_PERIOD_LIST_PATH,
              text: this.state.period_user_id !== null ? 'Personal training' : 'Listar periodizações'
            },
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}`,
              text: "Editar periodização"
            },
            {
              path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`,
              text: "Editar treino"
            },
          ]}
          titleIcon={<i className="fas fa-edit"></i>}
          title="Editar treino"
          loading={this.state.loading}
        >

          <TrainingDayData
            warningMessage={this.state.warningMessage}
            showWarningMessage={this.state.showWarningMessage}
            warningMessageBackground={this.state.warningMessageBackground}
            warningMessageColor={this.state.warningMessageColor}
            training_day={this.state.training_day}
            onSave={() => this.saveData()}
            onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
            enableSave={this.inputsAreValid()}
            handleInputChange={(event) => this.handleInputChange(event)}
            highlights={this.state.highlights}
            onCancelPath={this.getPreviousPath()}
            exerciseGroups={this.state.exerciseGroups}
            addExerciseGroupSelect={this.state.addExerciseGroupSelect}
            addExerciseGroup={() => this.addExerciseGroup()}
            onDeleteAssociation={(association) => this.onDeleteAssociation(association)}
            onToggleMergeWithPreviousGroup={(association) => this.onToggleMergeWithPreviousGroup(association)}
            onEditAssociation={(association) => this.onEditAssociation(association)}
            userPermissionIds={this.props.userPermissionIds}
            userAccessLevel={this.props.userAccessLevel}
            onOpenClassroom={() => this.onOpenClassroom()}
            isDefaultUnit={this.isDefaultUnit()}
            onSwitchOrder={(training_day, offset) => this.onSwitchOrder(training_day, offset)}
            onImportExerciseGroup={() => this.onImportExerciseGroup()}
          />

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingDayEdit;
