import React from 'react';
import DefaultSection, {HorizontalRule} from '../../../utils/default_section';
import DefaultInput, {SelectOption, HalfWrapper} from '../../../utils/default_input';
import {DEFAULT_UNIT_TYPE} from '../../../constants';
import WarningMessage from '../../warning_message';
import './appointment_schedule_data.scss';


class AppointmentScheduleData extends React.Component {
  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.appointment_schedule.id && this.props.appointment_schedule.id > 0;
  }

  mayChangeInputs() {
    if (this.isEditMode() && this.props.appointment_schedule.appointments.length > 0) {
      return false;
    }

    return true;
  }

  getTypeOptions() {
    let options = this.props.appointment_types.map((type) => SelectOption(type.id, type.name));

    if(this.props.appointment_schedule.appointment_type_id && this.props.appointment_schedule.appointment_type_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um tipo'),
      ...options
    ];
  }

  getRoomOptions() {
    let options = this.props.appointment_rooms.map((room) => SelectOption(room.id, room.name));

    if(this.props.appointment_schedule.appointment_room_id && this.props.appointment_schedule.appointment_room_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione uma sala'),
      ...options
    ];
  }

  getAppointmentResponsibleOptions() {
    let options = this.props.responsible_options.map((entry) => SelectOption(entry.id, entry.name));

    if(this.props.appointment_schedule.responsible_id && this.props.appointment_schedule.responsible_id > 0) {
      return [
        ...options
      ];
    }

    return [
      SelectOption('', 'Selecione um responsável'),
      ...options
    ];
  }

  getPeriodAvailableSlots() {
    if (this.props.appointment_schedule.default_entry_duration >= 5 &&
        this.props.appointment_schedule.start_at.length > 0 &&
        this.props.appointment_schedule.end_at.length > 0 &&
        this.props.appointment_schedule.start_at <= this.props.appointment_schedule.end_at) {
      const start_at = this.props.appointment_schedule.start_at;
      const end_at = this.props.appointment_schedule.end_at;

      const default_entry_duration = this.props.appointment_schedule.default_entry_duration;

      const periodDuration = (60 * parseFloat(end_at.slice(0, 2)) + parseFloat(end_at.slice(3, 5))) - (60 * parseFloat(start_at.slice(0, 2)) + parseFloat(start_at.slice(3, 5)));
      const totalSlots = Math.floor(periodDuration / default_entry_duration);
      const remainingMinutes = periodDuration % default_entry_duration;

      return (
        <React.Fragment>

          <HorizontalRule />

          <div className="appointment-schedule-data__indicator-container--spaced">

            <div className="appointment-schedule-data__indicator">

              <h2 className="appointment-schedule-data__indicator__label">Vagas:</h2>
              <p className="appointment-schedule-data__indicator__value">{totalSlots}</p>

            </div>

            {remainingMinutes > 0 &&
              <div className="appointment-schedule-data__indicator">

                <h2 className="appointment-schedule-data__indicator__label">Tempo sobressalente:</h2>
                <p className="appointment-schedule-data__indicator__value">{remainingMinutes} min</p>

              </div>
            }

          </div>

        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <DefaultSection
        className="appointment-schedule-data"
        title="Dados do período de atendimento"
      >

        <div className="appointment-schedule-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="appointment-schedule-data__input-container">

          <HalfWrapper>

            {this.isDefaultUnit() &&
              <DefaultInput
                name="appointment_type_id"
                isHighlighted={this.isHighlighted("appointment_type_id")}
                label="Tipo de atendimento"
                type="select"
                handleInputChange={this.props.handleInputChange}
                value={this.props.appointment_schedule.appointment_type_id || ''}
                options={this.getTypeOptions()}
                disabled={this.isEditMode()}
              />
            }

            <DefaultInput
              name="appointment_room_id"
              isHighlighted={this.isHighlighted("appointment_room_id")}
              label="Sala"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.appointment_schedule.appointment_room_id || ''}
              options={this.getRoomOptions()}
              disabled={this.isEditMode()}
            />

          </HalfWrapper>

          {this.isDefaultUnit() &&
            <DefaultInput
              name="responsible_id"
              isHighlighted={this.isHighlighted("responsible_id")}
              label="Responsável pelo atendimento"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.appointment_schedule.responsible_id || ''}
              options={this.getAppointmentResponsibleOptions()}
            />
          }

          <DefaultInput
            name="default_entry_duration"
            isHighlighted={this.isHighlighted('default_entry_duration')}
            label="Duração padrão de cada atendimento"
            type="number"
            placeholder="Duração"
            step="1"
            min="5"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment_schedule.default_entry_duration || ''}
            autoComplete="off"
            suffix="minutos"
            disabled={!this.props.appointment_schedule.appointment_type_id || this.isEditMode()}
            onKeyDown={(event) => this.handleKeyDown(event)}
            onFocus={(event) => event.target.select()}
          />

          <DefaultInput
            name="date"
            isHighlighted={this.isHighlighted("date")}
            label="Data do período"
            type="date"
            placeholder="Data do período"
            handleInputChange={this.props.handleInputChange}
            value={this.props.appointment_schedule.date}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.mayChangeInputs()}
          />

          <HalfWrapper>

            <DefaultInput
              name="start_at"
              isHighlighted={this.isHighlighted("start_at")}
              label="Início do período"
              type="time"
              placeholder="Início do período"
              handleInputChange={this.props.handleInputChange}
              value={this.props.appointment_schedule.start_at}
              max={this.props.appointment_schedule.end_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              name="end_at"
              isHighlighted={this.isHighlighted("end_at")}
              label="Término do período"
              type="time"
              placeholder="Horário de término"
              handleInputChange={this.props.handleInputChange}
              value={this.props.appointment_schedule.end_at}
              min={this.props.appointment_schedule.start_at}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

        </div>

        {this.getPeriodAvailableSlots()}

        <HorizontalRule />

        <div className="appointment-schedule-data__buttons-container">

          <button
            className="appointment-schedule-data__save-button"
            disabled={!this.props.enableSave}
            onClick={this.props.onSave}
          >

            Salvar

          </button>

          <button
            className="appointment-schedule-data__cancel-button"
            onClick={this.props.onClickCancelPath}
          >

            Cancelar

          </button>

        </div>

      </DefaultSection>
    );
  }
}

export default AppointmentScheduleData;
