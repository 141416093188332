import React from 'react';
import { Link } from 'react-router-dom';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {SERVICE_PERSONAL_SERVICE, TRAINING_PERIOD_OBJECTIVES, TRAINING_PERIOD_PHYSICAL_LEVELS} from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import DefaultSection from '../../utils/default_section';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import {getModels, setUrlParameters} from '../../utils/functions';
import * as paths from '../training_period/constants';
import * as permissions from '../../permissions';
import './training_day_list.scss';


class TrainingDayList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActiveInput = queryParameters.get('is_active');
    let targetServiceInput = queryParameters.get('target_service');
    let mainObjectiveInput = queryParameters.get('main_objective');
    let physicalLevelInput = queryParameters.get('suggested_physical_level');
    let discProfileInput = queryParameters.get('suggested_disc_profile');
    let targetGenderInput = queryParameters.get('target_gender');
    let exerciseFiltersInput = queryParameters.get('exercise_filters');
    let exerciseGroupIdInput = queryParameters.get('exercise_group_id');

    if(!targetServiceInput) {
      targetServiceInput = '';
    }
    if(!mainObjectiveInput) {
      mainObjectiveInput = '';
    }
    if(!physicalLevelInput) {
      physicalLevelInput = '';
    }
    if(!discProfileInput) {
      discProfileInput = '';
    }
    if(!targetGenderInput) {
      targetGenderInput = '';
    }
    if(!exerciseGroupIdInput) {
      exerciseGroupIdInput = '';
    }
    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }
    if(!exerciseFiltersInput) {
      exerciseFiltersInput = [];
    }
    else {
      exerciseFiltersInput = JSON.parse(exerciseFiltersInput);
    }

    this.state = {
      loadingData: true,
      targetServiceInput: targetServiceInput,
      targetServiceFilter: targetServiceInput,
      mainObjectiveInput: mainObjectiveInput,
      mainObjectiveFilter: mainObjectiveInput,
      physicalLevelInput: physicalLevelInput,
      physicalLevelFilter: physicalLevelInput,
      discProfileInput: discProfileInput,
      discProfileFilter: discProfileInput,
      targetGenderInput: targetGenderInput,
      targetGenderFilter: targetGenderInput,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      exerciseFiltersInput: exerciseFiltersInput,
      exerciseFilters: exerciseFiltersInput,
      exerciseGroupIdInput: exerciseGroupIdInput,
      exerciseGroupIdFilter: exerciseGroupIdInput,
      training_days: [],
      services: [],
      exercises: [],
      exerciseGroups: [],
      exerciseCategories: [],
      exerciseFunctions: [],
      muscleGroups: [],
      onAddServiceFilter: false,
      searchInitialized: false,
      exerciseTargetServiceFilter: "",
      exerciseCategoryFilter: "",
      exerciseFunctionFilter: "",
      exerciseMuscleGroupFilter: "",
      screenWidth: window.innerWidth
    };
  }

  async getTrainingDays() {
    const parameters = {};

    if(this.state.targetServiceFilter) {
      parameters.target_service = this.state.targetServiceFilter;
    }
    if(this.state.mainObjectiveFilter) {
      parameters.main_objective = this.state.mainObjectiveFilter;
    }
    if(this.state.physicalLevelFilter) {
      parameters.suggested_physical_level = this.state.physicalLevelFilter;
    }
    if(this.state.discProfileFilter) {
      parameters.suggested_disc_profile = this.state.discProfileFilter;
    }
    if(this.state.targetGenderFilter) {
      parameters.target_gender = this.state.targetGenderFilter;
    }
    if(this.state.isActiveFilter.length > 0) {
      parameters.active_only = this.state.isActiveFilter;
    }
    if(this.state.exerciseFilters.length > 0) {
      parameters.group_exercise_filters = this.state.exerciseFilters.join(',');
    }
    if(this.state.exerciseGroupIdFilter.length > 0) {
      parameters.exercise_group_id_filter = parseInt(this.state.exerciseGroupIdFilter);
    }

    if(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_PERIOD_PERMISSION_ID)) {
      return await getModels(setUrlParameters(routes.TRAINING_DAYS_GET_API, parameters));
    }

    parameters.active_only = true;

    return await getModels(setUrlParameters(routes.TRAINING_DAYS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }
  
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchInitialized !== this.state.searchInitialized ||
        prevState.targetServiceFilter !== this.state.targetServiceFilter ||
        prevState.mainObjectiveFilter !== this.state.mainObjectiveFilter ||
        prevState.physicalLevelFilter !== this.state.physicalLevelFilter ||
        prevState.discProfileFilter !== this.state.discProfileFilter ||
        prevState.targetGenderFilter !== this.state.targetGenderFilter ||
        prevState.exerciseFilters !== this.state.exerciseFilters ||
        prevState.exerciseGroupIdFilter !== this.state.exerciseGroupIdFilter ||
        prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.setState({
        loadingData: true
      });

      this.setState({
        training_days: await this.getTrainingDays(),
        loadingData: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const update = {
      loadingData: false
    }

    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
    let exercises = getModels(routes.TRAINING_EXERCISES_GET_API);
    let exerciseGroups = getModels(routes.EXERCISE_GROUPS_GET_API);
    let exerciseCategories = getModels(routes.EXERCISE_CATEOGRIES_GET_API);
    let exerciseFunctions = getModels(routes.EXERCISE_FUNCTIONS_GET_API);
    let muscleGroups = getModels(routes.MUSCLE_GROUPS_GET_API);

    services = await services;

    if(services) {
      update.services = services;
    }

    exercises = await exercises;

    if(exercises) {
      update.exercises = exercises;
    }

    exerciseGroups = await exerciseGroups;

    if(exerciseGroups) {
      update.exerciseGroups = exerciseGroups;
    }

    exerciseCategories = await exerciseCategories;

    if(exerciseCategories) {
      update.exerciseCategories = exerciseCategories;
      update.exerciseCategories.sort((a, b) => a.name.localeCompare(b.name));
    }

    exerciseFunctions = await exerciseFunctions;

    if(exerciseFunctions) {
      update.exerciseFunctions = exerciseFunctions;
      update.exerciseFunctions.sort((a, b) => a.name.localeCompare(b.name));
    }

    muscleGroups = await muscleGroups;

    if(muscleGroups) {
      update.muscleGroups = muscleGroups;
      update.muscleGroups.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DAY_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_PERIOD_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.TRAINING_PERIOD_EDIT_PATH}${entry.period_id}${paths.TRAINING_DAY_EDIT_PATH}${entry.id}`}
          >

            <i className="fas fa-eye"></i>

          </Link>
        }

      </div>
    );
  }

  getTrainingPeriodName(entry) {
    return entry.period.name;
  }

  getProperties() {
    let properties = [];

    properties.push(
      Property('id', 'ID', <i className="fas fa-tag"></i>, {
        getSortCallback: (a, b) => a.id - b.id
      }),
    );

    properties.push(
      Property('period_name', 'Periodização', <i className="fas fa-tag"></i>, {
        getDataText: (entry) => this.getTrainingPeriodName(entry),
        getFilterText: (entry) => this.getTrainingPeriodName(entry)
      }),
    );

    properties.push(
      Property('target_service', 'Serv.', <i className="fas fa-concierge-bell"></i>, {cellClassName: "training-day-list__target-service-cell"}),
    );

    return properties;
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  getGenderOptions() {
    return [
      SelectOption('', 'Todos'),
      SelectOption('Feminino', 'Feminino'),
      SelectOption('Masculino', 'Masculino'),
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.state.services,
      SERVICE_PERSONAL_SERVICE
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...services.map((service) => SelectOption(service, service))
    ];
  }

  getTrainingObjectiveOptions() {
    const objectives = [...TRAINING_PERIOD_OBJECTIVES];

    objectives.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Todos'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  getSuggestedPhysicalLevelOptions() {
    const levels = [...TRAINING_PERIOD_PHYSICAL_LEVELS];

    return [
      SelectOption('', 'Todos'),
      ...levels.map((level) => SelectOption(level, level))
    ];
  }

  getSuggestedDiscProfileOptions() {
    const options = [
      SelectOption('', 'Todos'),
      SelectOption('D', 'D'),
      SelectOption('I', 'I'),
      SelectOption('S', 'S'),
      SelectOption('C', 'C')
    ];

    return options;
  }

  getExerciseGroupOptions() {
    return [
      SelectOption('', 'Todos'),
      ...this.state.exerciseGroups.map((exerciseGroup) => SelectOption(exerciseGroup.id, exerciseGroup.name))
    ];
  }

  exerciseFiltersHasChanged() {
    const setA = new Set(this.state.exerciseFiltersInput);
    const setB = new Set(this.state.exerciseFilters);

    return this.state.exerciseFiltersInput.some((entry) => !setB.has(entry)) || this.state.exerciseFilters.some((entry) => !setA.has(entry));
  }

  mayRefreshList() {
    if(!this.state.searchInitialized ||
       this.state.targetServiceInput !== this.state.targetServiceFilter ||
       this.state.mainObjectiveInput !== this.state.mainObjectiveFilter ||
       this.state.physicalLevelInput !== this.state.physicalLevelFilter ||
       this.state.discProfileInput !== this.state.discProfileFilter ||
       this.state.targetGenderInput !== this.state.targetGenderFilter ||
       this.state.isActiveInput !== this.state.isActiveFilter ||
       this.state.exerciseGroupIdInput !== this.state.exerciseGroupIdFilter ||
       this.exerciseFiltersHasChanged()) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.TRAINING_DAY_LIST_PATH, {
        target_service: this.state.targetServiceInput,
        main_objective: this.state.mainObjectiveInput,
        suggested_physical_level: this.state.physicalLevelInput,
        suggested_disc_profile: this.state.discProfileInput,
        target_gender: this.state.targetGenderInput,
        exercise_group_id: this.state.exerciseGroupIdInput,
        is_active: this.state.isActiveInput || 'all',
        exercise_filters: this.state.exerciseFiltersInput.length > 0 ? JSON.stringify(this.state.exerciseFiltersInput) : ''
      }));

      this.setState({
        targetServiceFilter: this.state.targetServiceInput,
        mainObjectiveFilter: this.state.mainObjectiveInput,
        physicalLevelFilter: this.state.physicalLevelInput,
        discProfileFilter: this.state.discProfileInput,
        targetGenderFilter: this.state.targetGenderInput,
        isActiveFilter: this.state.isActiveInput,
        exerciseFilters: this.state.exerciseFiltersInput,
        exerciseGroupIdFilter: this.state.exerciseGroupIdInput,
        searchInitialized: true
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    if (name === 'exerciseTargetServiceFilter' && value.length > 0 && this.state.exerciseCategoryFilter.length > 0) {
      const exercise_category_id = parseInt(this.state.exerciseCategoryFilter);

      if(!this.state.exerciseCategories.some((entry) => {
        return entry.id === exercise_category_id && (entry.target_service === null || entry.target_service === value);
      })) {
        update.exerciseCategoryFilter = '';
      }
    }

    this.setState(update);
  }

  getExerciseFilters() {
    const filters = this.state.exercises.filter((entry) => this.state.exerciseFiltersInput.includes(entry.id))

    return filters.map((entry, index) => {
      return (        
        <div 
          key={`exercise_filter:${entry.id}`}
          className="training-day-list__exercise-filter-list__entry"
        >

          <h3 className="training-day-list__exercise-filter-list__entry__name">{entry.name}</h3>
  
          <button
            className="training-day-list__exercise-filter-list__entry__remove-button"
            onClick={() => this.removeExerciseFilter(index)}
          >
  
            <i className="fas fa-minus"></i>
  
          </button>
  
        </div>
      );      
    });
  }

  onAddExerciseFilter() {
    this.setState({onAddServiceFilter: true});
  }

  addExerciseFilter(training_exercise) {
    const exerciseFiltersInput = [...this.state.exerciseFiltersInput];

    exerciseFiltersInput.push(training_exercise.id);

    this.setState({exerciseFiltersInput});
  }

  removeExerciseFilter(index) {
    const exerciseFiltersInput = [...this.state.exerciseFiltersInput];

    exerciseFiltersInput.splice(index, 1);

    this.setState({exerciseFiltersInput});
  }

  getExerciseCategoryOptions() {
    let filteredExerciseCategories = this.state.exerciseCategories;

    if (this.state.exerciseTargetServiceFilter.length > 0) {
      filteredExerciseCategories = filteredExerciseCategories.filter((entry) => entry.target_service === null || entry.target_service === this.state.exerciseTargetServiceFilter);
    }

    return [
      SelectOption('', 'Todos os pilares'),
      ...filteredExerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
    ];
  }

  getExerciseFunctionOptions() {
    return [
      SelectOption('', 'Todas as funções'),
      ...this.state.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
    ];
  }

  getMuscleGroupOptions() {
    return [
      SelectOption('', 'Todos os agrupamentos'),
      ...this.state.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.id, muscleGroup.name))
    ];
  }

  getExerciseList() {
    return this.state.exercises.filter((entry) => {
      let include = true;

      if (entry.target_service !== null && this.state.exerciseTargetServiceFilter.length > 0 && entry.target_service !== this.state.exerciseTargetServiceFilter) {
        include = false;
      }
      if (this.state.exerciseCategoryFilter.length > 0 && entry.category_id !== parseInt(this.state.exerciseCategoryFilter)) {
        include = false;
      }
      if (this.state.exerciseFunctionFilter.length > 0 && entry.function_id !== parseInt(this.state.exerciseFunctionFilter)) {
        include = false;
      }
      if (this.state.exerciseMuscleGroupFilter.length > 0 && entry.muscle_group_id !== parseInt(this.state.exerciseMuscleGroupFilter)) {
        include = false;
      }

      return include;
    });
  }

  getReferenceLinkText(entry) {
    if(!entry.reference_url) {
      return null;
    }

    return (
      <a
        className="model-table__default-link-button"
        href={entry.reference_url}
        target="_blank"
        rel="noopener noreferrer"
      >

        <i className="fas fa-link"></i>

      </a>
    );
  }

  getExerciseProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),
    ];

    properties.push(Property('target_service', 'Serviço', <i className="fas fa-concierge-bell"></i>));
    properties.push(Property('category_name', 'Pilar', <i className="fas fa-tag"></i>));
    properties.push(Property('function_name', 'Função', <i className="fas fa-box"></i>));
    properties.push(Property('reference_url', 'URL', <i className="fas fa-link"></i>, {
      getDataText: (entry) => this.getReferenceLinkText(entry),
      getFilterText: (entry) => ''
    }));

    return properties;
  }

  getExerciseActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="training-day-list__exercise-filter-list__include-button"
          onClick={() => this.addExerciseFilter(entry)}
        >

          Filtrar

        </button>

      </div>
    );
  }

  mayResetExerciseFilterInputs() {
    if(this.state.exerciseCategoryFilter.length > 0 ||
       this.state.exerciseFunctionFilter.length > 0 ||
       this.state.exerciseMuscleGroupFilter.length > 0) {
      return true;
    }

    return false;
  }

  resetExerciseFilterInputs() {
    this.setState({
      exerciseCategoryFilter: '',
      exerciseFunctionFilter: '',
      exerciseMuscleGroupFilter: ''
    });
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="training-day-list__overlay"
          visible={this.state.onAddServiceFilter}
          actions={(
            <div className="training-day-list__overlay__action-container">

              <DefaultMenuButton
                className="training-day-list__overlay__action-button"
                onClick={() => this.setState({
                  onAddServiceFilter: false
                })}
                text="Fechar"
              />

            </div>
          )}
        >

          <header className="training-day-list__overlay__header">

            <h3 className="training-day-list__overlay__header__title">
              Filtrar por exercício
            </h3>

          </header>

          <hr className="training-day-list__horizontal-rule" />

          <div className="training-day-list__overlay__content">

            <div className="training-day-list__exercise-filters">

              <header className="training-day-list__exercise-filters__header">

                <h4 className="training-day-list__exercise-filters__header__text">Filtros</h4>

              </header>

              <div className="training-day-list__exercise-filters__inputs">

                <div className="training-day-list__exercise-filters__inputs-wrapper">

                  <DefaultInput
                    name="exerciseTargetServiceFilter"
                    label="Serviço alvo"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.exerciseTargetServiceFilter || ''}
                    options={this.getServiceOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="exerciseCategoryFilter"
                      label="Pilar"
                      labelMessage={this.state.exerciseTargetServiceFilter.length > 0 ? 'Filtrado pelo serviço' : null}
                      type="select"
                      value={this.state.exerciseCategoryFilter || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getExerciseCategoryOptions()}
                    />

                    <DefaultInput
                      name="exerciseFunctionFilter"
                      label="Função"
                      type="select"
                      value={this.state.exerciseFunctionFilter || ''}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      options={this.getExerciseFunctionOptions()}
                    />

                  </HalfWrapper>

                  <DefaultInput
                    name="exerciseMuscleGroupFilter"
                    label="Agrupamento muscular"
                    type="select"
                    value={this.state.exerciseMuscleGroupFilter || ''}
                    handleInputChange={(event) => this.handleInputChange(event)}
                    options={this.getMuscleGroupOptions()}
                  />

                </div>

                <button
                  className="training-day-list__exercise-filters__reset-button"
                  onClick={() => this.resetExerciseFilterInputs()}
                  disabled={!this.mayResetExerciseFilterInputs()}
                >

                  <i className="fas fa-times"></i>

                </button>

              </div>

            </div>

            <ModelTable
              storageKey="exercise_filters"
              properties={this.getExerciseProperties()}
              getActions={(entry) => this.getExerciseActions(entry)}
              data={this.getExerciseList()}
              initialOrderBy="name"
              enableMinRowHeight={true}
            >
            </ModelTable>

          </div>

        </OverlayWindow>

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.TRAINING_DAY_LIST_PATH,
              text: "Pesquisar treinos"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Pesquisar treinos"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="training-day-list"
            title="Pesquisar treinos"
          >

            <div className="training-day-list__filters">

              <header className="training-day-list__filters__header">

                <h4 className="training-day-list__filters__header__text">Filtros</h4>

              </header>

              <div className="training-day-list__filters__inputs">

                <div className="training-day-list__filters__inputs-wrapper">

                  <HalfWrapper>

                    <DefaultInput
                      name="isActiveInput"
                      label="Situação do cadastro"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.isActiveInput || ''}
                      options={this.getActiveOptions()}
                    />

                    <DefaultInput
                      name="targetGenderInput"
                      label="Gênero alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.targetGenderInput || ''}
                      options={this.getGenderOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="targetServiceInput"
                      label="Serviço alvo"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.targetServiceInput || ''}
                      options={this.getServiceOptions()}
                    />

                    <DefaultInput
                      name="mainObjectiveInput"
                      label="Objetivo do treinamento"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.mainObjectiveInput || ''}
                      options={this.getTrainingObjectiveOptions()}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="physicalLevelInput"
                      label="Nível físico sugerido"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.physicalLevelInput || ''}
                      options={this.getSuggestedPhysicalLevelOptions()}
                    />

                    <DefaultInput
                      name="discProfileInput"
                      label="Perfil DISC sugerido"
                      type="select"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.discProfileInput || ''}
                      options={this.getSuggestedDiscProfileOptions()}
                    />

                  </HalfWrapper>

                  <div className="training-day-list__exercise-filter-section">

                    <header className="training-day-list__exercise-filter-section__header">

                      <h4 className="training-day-list__exercise-filter-section__header__text">Exercícios dentro do mesmo agrupamento</h4>

                    </header>

                    <div className="training-day-list__exercise-filter-section__inputs">

                      <div className="training-day-list__exercise-filter-list">

                        <DefaultInput
                          className="training-day-list__exercise-filter-list__exercise-group-filter"
                          name="exerciseGroupIdInput"
                          label="Tipo de agrupamento"
                          type="select"
                          handleInputChange={(event) => this.handleInputChange(event)}
                          value={this.state.exerciseGroupIdInput || ''}
                          options={this.getExerciseGroupOptions()}
                        />

                        {this.getExerciseFilters()}

                        <button
                          className="training-day-list__exercise-filter-list__entry__add-button"
                          onClick={() => this.onAddExerciseFilter()}
                        >

                          <i className="fas fa-plus training-day-list__exercise-filter-list__entry__add-button__icon"></i>
                          Adicionar filtro

                        </button>
                        
                      </div>                      

                    </div>

                  </div>

                </div>

                <button
                  className="training-day-list__exercise-filter-section__refresh-button"
                  onClick={() => this.refreshList()}
                  disabled={!this.mayRefreshList()}
                >

                  <i className="fa-solid fa-magnifying-glass"></i>

                </button>

              </div>

            </div>

            {this.state.searchInitialized &&
              <ModelTable
                storageKey="training_list"
                properties={this.getProperties()}
                getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
                data={this.state.training_days}
                initialOrderBy="id"
              >
              </ModelTable>
            }

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default TrainingDayList;
