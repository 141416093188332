import React from 'react';
import { Link } from 'react-router-dom';
import './training_exercise_data.scss';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {SERVICE_HOME_TRAINING} from '../../constants';
import * as permissions from '../../permissions';

class TrainingExerciseData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.training_exercise.id && this.props.training_exercise.id > 0;
  }

  getExerciseCategoryOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.exerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
      ];
    }

    return [
      SelectOption('', 'Selecione um pilar'),
      ...this.props.exerciseCategories.map((exerciseCategory) => SelectOption(exerciseCategory.id, exerciseCategory.name))
    ];
  }

  getExerciseFunctionOptions() {
    if(this.isEditMode()) {
      return [
        ...this.props.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
      ];
    }

    return [
      SelectOption('', 'Selecione uma função'),
      ...this.props.exerciseFunctions.map((exerciseFunction) => SelectOption(exerciseFunction.id, exerciseFunction.name))
    ];
  }

  getMuscleGroupOptions() {
    return [
      SelectOption('', 'Indefinido'),
      ...this.props.muscleGroups.map((muscleGroup) => SelectOption(muscleGroup.id, muscleGroup.name))
    ];
  }

  getServiceOptions() {
    const services = [
      ...this.props.services,
      SERVICE_HOME_TRAINING
    ];

    services.sort((a, b) => a.localeCompare(b));

    return [
      SelectOption('', 'Não especificado'),
      ...services.map((service) => SelectOption(service, service)),
    ];
  }

  getIntensityParameters() {
    if(!this.props.training_exercise.intensity_parameters) {
      return null;
    }

    return this.props.training_exercise.intensity_parameters.map((parameter, position) => (
      <div
        className="training-exercise-data__parameter"
        key={`intensity_parameters:${position}`}
      >

        <div className="training-exercise-data__parameter__detail"> </div>

        <div className="training-exercise-data__parameter__input-wrapper">

          <DefaultInput
            className="training-exercise-data__parameter__name"
            name={`intensity_parameters:${position}:name`}
            isHighlighted={this.isHighlighted(`intensity_parameters:${position}:name`)}
            label="Nome"
            type="text"
            placeholder="Nome do parâmetro"
            maxLength="64"
            handleInputChange={this.props.handleInputChange}
            value={parameter.name || ''}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            className="training-exercise-data__parameter__unit"
            name={`intensity_parameters:${position}:unit`}
            isHighlighted={this.isHighlighted(`intensity_parameters:${position}:unit`)}
            label="Unidade"
            type="text"
            placeholder="Unidade do parâmetro"
            maxLength="64"
            handleInputChange={this.props.handleInputChange}
            value={parameter.unit || ''}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <button
          className="training-exercise-data__delete-parameter-button"
          onClick={() => this.props.onRemoveIntensityParameter(position)}
        >

          <i className="far fa-trash-alt"></i>

        </button>

      </div>
    ));
  }

  getDifficultyParameters() {
    if(!this.props.training_exercise.difficulty_parameters) {
      return null;
    }

    return this.props.training_exercise.difficulty_parameters.map((parameter, position) => (
      <div
        className="training-exercise-data__parameter"
        key={`difficulty_parameters:${position}`}
      >

        <div className="training-exercise-data__parameter__detail"> </div>

        <div className="training-exercise-data__parameter__input-wrapper">

          <DefaultInput
            className="training-exercise-data__parameter__name"
            name={`difficulty_parameters:${position}:name`}
            isHighlighted={this.isHighlighted(`difficulty_parameters:${position}:name`)}
            label="Nome"
            type="text"
            placeholder="Nome do parâmetro"
            maxLength="64"
            handleInputChange={this.props.handleInputChange}
            value={parameter.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <DefaultInput
            className="training-exercise-data__parameter__unit"
            name={`difficulty_parameters:${position}:unit`}
            isHighlighted={this.isHighlighted(`difficulty_parameters:${position}:unit`)}
            label="Unidade"
            type="text"
            placeholder="Unidade do parâmetro"
            maxLength="64"
            handleInputChange={this.props.handleInputChange}
            value={parameter.unit}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

        </div>

        <button
          className="training-exercise-data__delete-parameter-button"
          onClick={() => this.props.onRemoveDifficultyParameter(position)}
        >

          <i className="far fa-trash-alt"></i>

        </button>

      </div>
    ));
  }

  render() {
    return (
      <DefaultSection
        className="training-exercise-data"
        title="Dados do exercício"
      >

        <div className="training-exercise-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="training-exercise-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do exercício"
              maxLength="128"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
            />

            <DefaultInput
              name="target_service"
              isHighlighted={this.isHighlighted("target_service")}
              label="Treinamento alvo"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.target_service || ''}
              options={this.getServiceOptions()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="category_id"
              isHighlighted={this.isHighlighted("category_id")}
              label="Pilar"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.category_id || ''}
              options={this.getExerciseCategoryOptions()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
            />

            <DefaultInput
              name="function_id"
              isHighlighted={this.isHighlighted("function_id")}
              label="Função"
              type="select"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.function_id || ''}
              options={this.getExerciseFunctionOptions()}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
            />

          </HalfWrapper>

          <DefaultInput
            name="muscle_group_id"
            isHighlighted={this.isHighlighted("muscle_group_id")}
            label="Músculo alvo"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_exercise.muscle_group_id || ''}
            options={this.getMuscleGroupOptions()}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
          />

          <DefaultInput
            name="reference_url"
            isHighlighted={this.isHighlighted("reference_url")}
            label="URL de referência"
            type="text"
            placeholder="URL"
            maxLength="512"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_exercise.reference_url || ''}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
          />

          <DefaultInput
            name="easier_option"
            isHighlighted={this.isHighlighted("easier_option")}
            label="Adaptação"
            type="text"
            placeholder="Nome do exercício de adaptação"
            maxLength="256"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_exercise.easier_option || ''}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
          />

          <DefaultInput
            name="description"
            isHighlighted={this.isHighlighted("description")}
            label="Descrição"
            type="textarea"
            placeholder="Descrição do exercício"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_exercise.description || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) || !this.props.training_exercise.editable}
          />

          {(this.props.userPermissionIds.includes(permissions.SHARE_TRAINING_EXERCISE_PERMISSION) && this.props.training_exercise.editable) &&
            <DefaultInput
              name="shared"
              isHighlighted={this.isHighlighted('shared')}
              label="Compartilhar cadastro:"
              type="toggle"
              isHorizontal={true}
              activeText="Sim"
              inactiveText="Não"
              handleInputChange={this.props.handleInputChange}
              value={this.props.training_exercise.shared}
            />
          }

          {/* <DefaultSubSectionTitle
            className="training-exercise-data__sub-section"
            icon={<i className="fas fa-stopwatch"></i>}
            text="Parâmetros de intensidade"
          />

          <div className="training-exercise-data__parameters-container">

            {this.getIntensityParameters()}

            <button
              className="training-exercise-data__add-parameter-button"
              onClick={() => this.props.onAddIntensityParameter()}
            >

              <i className="fas fa-plus training-exercise-data__add-parameter-button__icon"></i>
              Adicionar parâmetro

            </button>

          </div>

          <DefaultSubSectionTitle
            className="training-exercise-data__sub-section"
            icon={<i className="fas fa-weight-hanging"></i>}
            text="Parâmetros de dificuldade"
          />

          <div className="training-exercise-data__parameters-container">

            {this.getDifficultyParameters()}

            <button
              className="training-exercise-data__add-parameter-button"
              onClick={() => this.props.onAddDifficultyParameter()}
            >

              <i className="fas fa-plus training-exercise-data__add-parameter-button__icon"></i>
              Adicionar parâmetro

            </button>

          </div> */}

          {(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) && this.props.training_exercise.editable) &&
            <React.Fragment>

              <HorizontalRule />

              <DefaultInput
                name="is_active"
                isHighlighted={this.isHighlighted('is_active')}
                label="Cadastro ativo:"
                type="toggle"
                isHorizontal={true}
                activeText="Sim"
                inactiveText="Não"
                handleInputChange={this.props.handleInputChange}
                value={this.props.training_exercise.is_active}
              />

            </React.Fragment>
          }

        </div>

        <HorizontalRule />

        <div className="training-exercise-data__buttons-container">

          {(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) && this.props.training_exercise.editable) &&
            <button
              className="training-exercise-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
              >

                Salvar

              </button>
          }

          <Link
            className="training-exercise-data__cancel-button"
            to={this.props.onCancelPath}
          >

            {(this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_EXERCISE_PERMISSION_ID) && this.props.training_exercise.editable) ? 'Cancelar' : 'Voltar'}

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default TrainingExerciseData;
