import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';
import {getCurrencyText} from '../utils/functions';

function FinancialNetPoint(income, expense, projection, month, year) {
  return {
    income,
    expense,
    projection,
    month,
    year,
  }
}

export {FinancialNetPoint};

function getPointData(value, date) {
  return { x: date, y: value };
}

class FinancialNetGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getData() {
    const incomeData = [];
    const expenseData = [];
    const projectionData = [];
    const netData = [];

    for(let point of this.props.data) {
      const date = new Date(point.year, point.month);

      incomeData.push(getPointData(point.income, date));
      expenseData.push(getPointData(point.expense, date));
      projectionData.push(getPointData(point.projection, date));

      const netPoint = getPointData(point.income - point.expense, date);
      // netPoint.color = '#adadadc4';
      //
      // if(point.income > point.expense) {
      //   netPoint.color = '#57904f';
      // }
      // else if(point.income < point.expense) {
      //   netPoint.color = '#bc3f52';
      // }

      netData.push(netPoint);
    }

    return [{
      type: "column",
      name: "Receitas",
      showInLegend: true,
      color: '#58a74d',
      xValueFormatString: "MMMM YYYY",
      yValueFormatString: "R$ #,##0.00",
      dataPoints: incomeData
    }, {
      type: "column",
      name: "Despesas",
      showInLegend: true,
      color: '#ca5353',
      xValueFormatString: "MMMM YYYY",
      yValueFormatString: "R$ #,##0.00",
      dataPoints: expenseData
    }, {
      type: "line",
      name: "Total",
      color: '#adadadc4',
      showInLegend: true,
      yValueFormatString: "R$ #,##0.00",
      dataPoints: netData
    }];

    // {
    //   type: "line",
    //   name: "Projeção",
    //   markerType: "none",
    //   lineThickness: 1,
    //   lineDashType: "dash",
    //   color: '#292929ad',
    //   visible: false,
    //   showInLegend: true,
    //   yValueFormatString: "R$ #,##0.00",
    //   dataPoints: projectionData
    // }
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			// colorSet: "colorSet2",
      culture: 'pt-BR',
			axisX: {
				valueFormatString: "MMMM",
        interval: 1,
        intervalType: "month"
			},
			axisY: {
				labelFormatter: (entry) => getCurrencyText(entry.value)
			},
			toolTip: {
				shared: true
			},
			legend: {
        cursor: "pointer",
				verticalAlign: "bottom",
        itemclick: (e) => this.toggleDataSeries(e)
			},
			data: this.getData()
		};

    if(this.props.title) {
      options.title = {
				text: this.props.title
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
		<div>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
				onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default FinancialNetGraph;
