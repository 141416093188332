import React from 'react';
import './default_input.scss';

function HalfWrapper(props) {
  return (
    <div className={`half-wrapper ${props.className || ''}`}>

      {props.children}

    </div>
  );
}

export {HalfWrapper};

function SelectOption(value, text=null) {
  return {
    value: value,
    text: text || value,
  };
}

export {SelectOption};

function DummyInput(props) {
  return (
    <div className={`dummy-input ${props.className}`}> </div>
  );
}

export {DummyInput};

function FiledSet(props) {
  return (
    <fieldset className={`default-fieldset ${props.className || ''}`}>

      <legend className={`default-fieldset__legend ${props.legendClassName || ''}`}>{props.legend}</legend>

      {props.children}

    </fieldset>
  );
}

export {FiledSet};

function getSelectOptions(options) {
  return options.map((option) => {
    return (
      <option key={`default_input_select_option_${option.value}`} value={option.value}>{option.text}</option>
    );
  });
}

function DefaultInput(props) {
  if(props.type === 'toggle') {
    let horizontalAlignClass = '';

    if(props.horizontalAlign === 'right') {
      horizontalAlignClass = 'align-right'
    }

    return (
      <div className={`default-input${props.isHorizontal ? '--horizontal': ''} ${props.className || ''} ${horizontalAlignClass}`}>

        {/* {props.label ?
          <h4
            className={`default-input__label${props.invertLabel ? '--inverted' : ''} ${props.labelClassName || ''}`}
            htmlFor={props.id || `default_input_${props.name}`}
          >
            {props.label}
          </h4> :
          null
        } */}
        {(props.label || props.labelMessage) ?
          <label
            className={`default-input__label${props.invertLabel ? '--inverted' : ''} ${props.placeLabelMessageBellow ? 'default-input__label--bellow' : ''} ${props.labelClassName || ''}`}
            htmlFor={props.id || `default_input_${props.name}`}
          >
            {props.label}

            {props.labelMessage ?
              <span
                className={`default-input__label__warning-message${props.placeLabelMessageBellow ? '--bellow' : ''}`}
                style={(props.labelColor || props.labelMessageWidth) ? {color: props.labelColor, width: props.labelMessageWidth} : {}}
              >
                {props.labelMessage}
              </span>:
              null
            }
          </label> :
          null
        }

        {!props.hideInput &&
          <div className={`default-input__input-wrapper${props.invertLabel ? '--inverted' : ''}`}>

            <label>

              <input
                className="default-input__toggle-input"
                id={props.id || `default_input_${props.name}`}
                name={props.name}
                aria-hidden="false"
                type="checkbox"
                checked={props.value}
                onChange={props.handleInputChange}
                onBlur={props.handleOnBlur}
                disabled={props.disabled}
              />
              <span
                className={`default-input__toggle-visual${props.neutralInactiveColor ? '--inactive-neutral' : ''}`}
                aria-hidden="true"
                inactive-text={props.inactiveText}
                active-text={props.activeText}
                toggle-width="20em"
                >

                  <span></span>

                </span>

              </label>

            </div>
        }

      </div>
    );
  }

  return (
    <div className={`default-input${props.isHorizontal ? '--horizontal': ''} ${props.className || ''}`}>

      {(props.label || props.labelMessage) ?
        <label
          className={`default-input__label${props.invertLabel ? '--inverted' : ''} ${props.placeLabelMessageBellow ? 'default-input__label--bellow' : ''} ${props.labelClassName || ''}`}
          htmlFor={props.id || `default_input_${props.name}`}
        >
          {props.label}

          {props.labelMessage ?
            <span
              className={`default-input__label__warning-message${props.placeLabelMessageBellow ? '--bellow' : ''}`}
              style={(props.labelColor || props.labelMessageWidth) ? {color: props.labelColor, width: props.labelMessageWidth} : {}}
            >
              {props.labelMessage}
            </span>:
            null
          }
        </label> :
        null
      }

      {!props.hideInput &&
        <div className={`default-input__input-wrapper${props.invertLabel ? '--inverted' : ''}`}>

          {props.type === 'textarea' ?
            <textarea
              className={`default-input__text-area-input${props.isHighlighted ? '--highlighted' : ''}`}
              wrap="soft"
              id={props.id || `default_input_${props.name}`}
              name={props.name}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              onChange={props.handleInputChange}
              onBlur={props.handleOnBlur}
              value={props.value}
              rows={props.rows}
              onKeyDown={props.onKeyDown}
              onFocus={props.onFocus}
              disabled={props.disabled}
            /> :
            props.type === 'select' ?
            <select
              className={`default-input__select-input${props.isHighlighted ? '--highlighted' : ''}`}
              id={props.id || `default_input_${props.name}`}
              name={props.name}
              onChange={props.handleInputChange}
              onBlur={props.handleOnBlur}
              value={props.value}
              disabled={props.disabled}
            >
                {getSelectOptions(props.options)}
            </select> :
            <React.Fragment>

              <input
                className={`default-input__input${props.isHighlighted ? '--highlighted' : ''}`}
                id={props.id || `default_input_${props.name}`}
                name={props.name}
                type={props.type}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                min={props.min}
                max={props.max}
                step={props.step}
                onChange={props.handleInputChange}
                onBlur={props.handleOnBlur}
                value={props.value}
                autoComplete={props.autoComplete}
                onKeyDown={props.onKeyDown}
                onFocus={props.onFocus}
                disabled={props.disabled}
                checked={props.type === 'checkbox' && props.value}
              />

              {props.type === 'range' &&
              <input
                className={`default-input__input${props.isHighlighted ? '--highlighted' : ''}`}
                id={props.id || `default_input_${props.name}`}
                name={props.name}
                type="number"
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                min={props.min}
                max={props.max}
                step={props.step}
                onChange={props.handleInputChange}
                onBlur={props.handleOnBlur}
                value={props.value}
                autoComplete={props.autoComplete}
                onKeyDown={props.onKeyDown}
                onFocus={props.onFocus}
                disabled={props.disabled}
              />
            }

          </React.Fragment>
        }

        {props.prefix ?
          <span className="default-input__input-prefix">{props.prefix}</span> :
          null
        }

        {props.suffix ?
          <span className="default-input__input-suffix">{props.suffix}</span> :
          null
        }

      </div>
      }

    </div>
  );
}

export default DefaultInput;
