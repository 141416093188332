import React from 'react';
import { Link } from 'react-router-dom';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import DefaultSection, {HorizontalRule, DefaultSubSectionTitle} from '../../utils/default_section';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        TRAINING_PERIOD_EDIT_PATH} from '../../constants';
import {TRAINING_DAY_EDIT_PATH} from '../training_period/constants';
import {TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH} from './constants';
import {TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH} from '../training_day_group_association/constants';
import DEFAULT_SEQUENTIAL_COLOR_PALLET from '../../graphs/color_pallet';
import VerticalBarGraph, {BarDataPoint} from '../../graphs/vertical_bar_graph';
import PieGraph, {PiePoint} from '../../graphs/pie_graph';
import * as permissions from '../../permissions';
import {parseTextForIcons} from '../../utils/functions';
import './training_day_data.scss';


function getFormatedTime(minutes) {
  const secondsRemaining = Math.floor((minutes*60) % 60);

  return `${Math.floor(minutes)}'` + (secondsRemaining > 0 ? ` ${secondsRemaining}''` : '');
}

function getGroupAssociationExecutionMinutes(association) {
  if(association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
    return association.time_required;
  }
  else if(association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
    return association.time_required;
  }
  else if(association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
    return association.time_required;
  }

  return 0;
}

export {getFormatedTime, getGroupAssociationExecutionMinutes};

class TrainingDayData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      statsSectionVisible: false
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave && event.target.name !== 'description' && event.target.name !== 'note') {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.training_day.id && this.props.training_day.id > 0;
  }

  getExerciseGroupOptions() {
    return [
      SelectOption('', 'Selecione um agrupamento de exercícios'),
      ...this.props.exerciseGroups.map((exerciseGroup) => SelectOption(exerciseGroup.id, exerciseGroup.name))
    ];
  }

  getPSEOptions() {
    return [
      SelectOption('', 'Não especificado'),
      SelectOption(1, '1 - Muito leve'),
      SelectOption(2, '2 - Leve'),
      SelectOption(3, '3 - Tranquilo'),
      SelectOption(4, '4 - Moderado'),
      SelectOption(5, '5 - Requer esforço'),
      SelectOption(6, '6 - Pouco pesado'),
      SelectOption(7, '7 - Pesado'),
      SelectOption(8, '8 - Muito pesado'),
      SelectOption(9, '9 - Extremamente pesado'),
      SelectOption(10, '10 - Quase impossível'),
    ];
  }

  getGroupAssociationExecutionTime(association) {
    const minutes = getGroupAssociationExecutionMinutes(association);

    return getFormatedTime(minutes);
  }

  getExerciseAssociations(group) {
    return group.exercise_associations.map((association) => (
      <li
        className="training-day-data__association__exercise"
        key={`training_day_group_associations:exercise_association:${association.id}`}
      >

        <Link
          className="training-day-data__association__exercise__link"
          to={`${TRAINING_PERIOD_EDIT_PATH}${this.props.training_day.period_id}${TRAINING_DAY_EDIT_PATH}${this.props.training_day.id}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${group.id}${TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH}${association.id}`}
        >

            <i className="fa-solid fa-link training-day-data__association__exercise__link__icon"></i>

        </Link>

        {association.exercise_name}

      </li>
    ));
  }

  getTrainingDayGroupAssociations() {
    if(!this.props.training_day.group_associations) {
      return null;
    }

    const entriesCount = this.props.training_day.group_associations.length;
    const maySwitchOrder = this.props.training_day.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID) && entriesCount > 1;

    const isEditMode = this.isEditMode();

    return this.props.training_day.group_associations.map((association, position) => {
      const orderStyle = {};

      if(isEditMode && association.capacity_color !== null) {
        orderStyle.background = association.capacity_color;
      }

      return (
        <div
          className="training-day-data__association"
          key={`training_day_group_associations:${association.id}`}
        >

          <p
            className="training-day-data__association__detail"
            style={orderStyle}
          >

            {association.order}

          </p>

          <div className="training-day-data__association__content-wrapper">

            <p className="training-day-data__association__content"> {parseTextForIcons(association.name, `training_day_group_associations:${association.id}:name`, 'training-day-data__parsed-text')} </p>

            {association.exercise_associations.length > 0 &&
              <ul className="training-day-data__association__exercise-list">

                {this.getExerciseAssociations(association)}

              </ul>
            }

            <p className="training-day-data__association__id-text">
              <strong>ID:</strong> {association.id}
            </p>

          </div>

          <div className="training-day-data__association__additional-info-container">

            <p className="training-day-data__association__additional-info">
              <i className="fas fa-tag training-day-data__association__additional-info__icon"></i>
              {this.props.exerciseGroups.find((entry) => entry.id === association.group_id).name}
            </p>

            <p className="training-day-data__association__additional-info">
              <i className="fas fa-stopwatch training-day-data__association__additional-info__icon"></i>
              {this.getGroupAssociationExecutionTime(association)}
            </p>

          </div>

          {this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID) &&
            <React.Fragment>

              <button
                className={`training-day-data__switch-order-button${(maySwitchOrder && association.order > 1) ? '' : '--hidden'}`}
                onClick={() => this.props.onSwitchOrder(association, -1)}
              >

                <i className="fas fa-chevron-up"></i>

              </button>

              <button
                className={`training-day-data__switch-order-button${(maySwitchOrder && association.order < entriesCount) ? '' : '--hidden'}`}
                onClick={() => this.props.onSwitchOrder(association, 1)}
              >

                <i className="fas fa-chevron-down"></i>

              </button>

            </React.Fragment>
          }

          {this.props.userPermissionIds.includes(permissions.VIEW_DAY_GROUP_ASSOCIATION_PERMISSION_ID) &&
            <button
              className="training-day-data__edit-association-button"
              onClick={() => this.props.onEditAssociation(association)}
            >

              {(this.props.training_day.editable && this.props.userPermissionIds.includes(permissions.EDIT_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) ?
                <i className="far fa-edit"></i>:
                <i className="fas fa-eye"></i>
              }

            </button>
          }

          {(this.props.training_day.editable && this.props.userPermissionIds.includes(permissions.DELETE_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) &&
            <button
              className="training-day-data__delete-association-button"
              onClick={() => this.props.onDeleteAssociation(association)}
            >

              <i className="far fa-trash-alt"></i>

            </button>
          }

          {association.order > 1 &&
            <button
              className={`training-day-data__association-merge-button${association.merge_with_previous_group ? '--active' : ''}`}
              onClick={() => this.props.onToggleMergeWithPreviousGroup(association)}
              disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.DELETE_DAY_GROUP_ASSOCIATION_PERMISSION_ID)}
            >
            </button>
          }

        </div>
      );
    });
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPieHeight() {
    if(this.state.screenWidth <= 420) {
      return 230;
    }

    if(this.state.screenWidth <= 600) {
      return 250;
    }

    if(this.state.screenWidth <= 1100) {
      return 290;
    }

    return 330;
  }

  getStatsData() {
    const exercises = this.props.training_day.group_associations.filter((entry) => entry.group.show_in_resume).flatMap((entry) => entry.exercise_associations.map((association) => association.exercise));
    const exercisesCount = exercises.length;

    const totalMinutes = this.props.training_day.group_associations.reduce((total, association) => total + getGroupAssociationExecutionMinutes(association), 0);

    const functionMap = new Map();
    const muscleGroupMap = new Map();
    const categoryMap = new Map();
    const capacityMap = new Map();

    for(let exercise of exercises) {
      let functionPoint;
      let muscleGroupPoint;
      let categoryPoint;

      if(!functionMap.has(exercise.function_name)) {
        let barColor = '#000000';

        if(functionMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[functionMap.size];
        }

        functionPoint = BarDataPoint(0, exercise.function_name, barColor);
        functionMap.set(exercise.function_name, functionPoint);
      }
      else {
        functionPoint = functionMap.get(exercise.function_name);
      }

      functionPoint.value += 1;

      let muscle_group_name;

      if(exercise.muscle_group_name && exercise.muscle_group_name.length > 0) {
        muscle_group_name = exercise.muscle_group_name;
      }
      else {
        muscle_group_name = 'Indefinido';
      }

      if(!muscleGroupMap.has(muscle_group_name)) {
        let barColor = '#000000';

        if(muscleGroupMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[muscleGroupMap.size];
        }

        muscleGroupPoint = BarDataPoint(0, muscle_group_name, barColor);
        muscleGroupMap.set(muscle_group_name, muscleGroupPoint);
      }
      else {
        muscleGroupPoint = muscleGroupMap.get(muscle_group_name);
      }

      muscleGroupPoint.value += 1;

      if(!categoryMap.has(exercise.category_name)) {
        let barColor = '#000000';

        if(categoryMap.size < DEFAULT_SEQUENTIAL_COLOR_PALLET.length) {
            barColor = DEFAULT_SEQUENTIAL_COLOR_PALLET[categoryMap.size];
        }

        categoryPoint = PiePoint(0, exercisesCount, exercise.category_name, barColor);
        categoryMap.set(exercise.category_name, categoryPoint);
      }
      else {
        categoryPoint = categoryMap.get(exercise.category_name);
      }

      categoryPoint.value += 1;
    }

    for(let association of this.props.training_day.group_associations) {
      let capacityPoint;

      let entryTag = 'Indefinido';

      if(association.activity_reference_id !== null) {
        entryTag = association.capacity_abbreviation;
      }

      if(!capacityMap.has(entryTag)) {
        capacityPoint = PiePoint(0, totalMinutes, entryTag, association.capacity_color);
        capacityMap.set(entryTag, capacityPoint);
      }
      else {
        capacityPoint = capacityMap.get(entryTag);
      }

      capacityPoint.value += association.time_required;
    }

    const functionData = [...functionMap.values()];
    const muscleGroupData = [...muscleGroupMap.values()];

    functionData.sort((a, b) => b.value - a.value);
    muscleGroupData.sort((a, b) => b.value - a.value);

    return {
      exercisesCount: exercisesCount,
      functionData: functionData,
      muscleGroupData: muscleGroupData,
      categoryData: [...categoryMap.values()],
      capacityData: [...capacityMap.values()],
      totalTime: getFormatedTime(totalMinutes)
    };
  }

  render() {
    const statsData = this.getStatsData();

    return (
      <DefaultSection
        className="training-day-data"
        title="Dados do treino"
      >

        <div className="default-section__links-wrapper">

          <DefaultInput
            className="training-day-data__id-label"
            name="id"
            type="number"
            placeholder="ID do treino"
            min="0"
            step="1"
            value={this.props.training_day.id}
            prefix="ID"
            isHorizontal={true}
            disabled={true}
          />

          {this.props.isDefaultUnit &&
            <button
              className="default-section__default-link-button"
              onClick={() => this.props.onOpenClassroom()}
            >

                <i className="fas fa-tv default-section__default-link-button__icon"></i> Abrir lousa

            </button>
          }

        </div>

        <div className="training-day-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
            background={this.props.warningMessageBackground}
            color={this.props.warningMessageColor}
          />

        </div>

        <div className="training-day-data__input-container">

          <DefaultInput
            name="name"
            isHighlighted={this.isHighlighted("name")}
            label="Nome"
            type="text"
            placeholder="Nome do treino"
            maxLength="128"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_day.name}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)}
          />

          <DefaultInput
            name="expected_pse"
            isHighlighted={this.isHighlighted("expected_pse")}
            label="PSE esperado"
            type="select"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_day.expected_pse || ''}
            options={this.getPSEOptions()}
            isHorizontal={this.state.screenWidth > 580}
            disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)}
          />

          <DefaultInput
            name="description_url"
            isHighlighted={this.isHighlighted("description_url")}
            label="URL de vídeo explicativo"
            type="text"
            placeholder="URL"
            maxLength="512"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_day.description_url || ''}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)}
          />

          <DefaultInput
            name="note"
            isHighlighted={this.isHighlighted("note")}
            label="Nota aos alunos"
            type="textarea"
            placeholder="Nota aos alunos"
            rows="3"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_day.note || ''}
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)}
          />

          <DefaultInput
            name="is_placeholder"
            isHighlighted={this.isHighlighted('is_placeholder')}
            label="Bloquear lousa:"
            // labelMessage="Se habilidado, o cadastro será utilizado somente para indicar o tipo de atividade ao aluno"
            type="toggle"
            isHorizontal={true}
            activeText="Sim"
            inactiveText="Não"
            handleInputChange={this.props.handleInputChange}
            value={this.props.training_day.is_placeholder}
            disabled={!this.props.training_day.editable || !this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)}
          />

          <HorizontalRule />

          {(this.props.training_day.editable && this.props.userPermissionIds.includes(permissions.ADD_DAY_GROUP_ASSOCIATION_PERMISSION_ID)) &&
            <React.Fragment>
              <div className="training-day-data__add-exercise-group-wrapper">

                <DefaultInput
                  className="training-day-data__add-exercise-group-input"
                  name="addExerciseGroupSelect"
                  isHighlighted={this.isHighlighted("addExerciseGroupSelect")}
                  label="Adicionar agrupamento"
                  type="select"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.addExerciseGroupSelect || ''}
                  options={this.getExerciseGroupOptions()}
                  isHorizontal={this.state.screenWidth > 580}
                />

                <button
                  className="training-day-data__add-exercise-group-button"
                  disabled={!this.props.addExerciseGroupSelect}
                  onClick={() => this.props.addExerciseGroup()}
                >

                  Adicionar

                </button>

              </div>

              <button
                className="training-day-data__import-exercise-group-button"
                onClick={() => this.props.onImportExerciseGroup()}
              >

                <i className="fas fa-clone training-day-data__import-exercise-group-button__icon"></i> Importar agrupamento

              </button>

            </React.Fragment>
          }

          {this.isEditMode() && this.props.training_day.group_associations.length > 0 &&
            <React.Fragment>

              <DefaultSubSectionTitle
                className="training-day-data__sub-section"
                icon={<i className="fas fa-layer-group"></i>}
                text="Agrupamentos"
              />

              <div className="training-day-data__associations-container">

                {this.getTrainingDayGroupAssociations()}

              </div>

            </React.Fragment>
          }

          {statsData.exercisesCount > 0 &&
            <React.Fragment>

              <HorizontalRule />

              <section className="training-day-data__stats">

                <header
                  className="training-day-data__stats__header"
                  onClick={() => this.setState({statsSectionVisible: !this.state.statsSectionVisible})}
                >

                  <h3 className="training-day-data__stats__header__text">
                    <i className="far fa-chart-bar training-day-data__stats__header__text-icon"></i>
                    Resumo
                  </h3>

                  {this.state.statsSectionVisible ?
                    <i className="fas fa-chevron-down training-day-data__stats__header__visible-icon"></i>:
                    <i className="fas fa-chevron-up training-day-data__stats__header__visible-icon"></i>
                  }

                </header>

                <VerticalAccordionContainer
                  className="vertical-accordion-container training-day-data__stats__content"
                  pose={this.state.statsSectionVisible ? 'verticalOpen' : 'verticalClosed'}
                >

                  <div className="vertical-accordion-container training-day-data__stats__content-wrapper">

                    <div className="training-day-data__indicator">

                      <h2 className="training-day-data__indicator__label">Tempo total:</h2>
                      <p className="training-day-data__indicator__value">{statsData.totalTime}</p>

                    </div>

                    <DefaultSubSectionTitle
                      className="training-day-data__sub-section"
                      icon={<i className="far fa-chart-bar"></i>}
                      text="Funções"
                    />

                    <VerticalBarGraph
                      xLabel="Função"
                      yLabel="Quantidade"
                      data={statsData.functionData}
                      height={this.getDefaultGraphHeight()}
                      yInterval={1}
                    />

                    <DefaultSubSectionTitle
                      className="training-day-data__sub-section"
                      icon={<i className="fas fa-chart-pie"></i>}
                      text="Pilares"
                    />

                    <PieGraph
                      data={statsData.categoryData}
                      height={this.getPieHeight()}
                      legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                      valueTextCallback={(value) => value}
                      indexLabel="{value}"
                      // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                      // defaultMultiplier={1}
                    />

                    <DefaultSubSectionTitle
                      className="training-day-group-association-data__sub-section"
                      icon={<i className="far fa-chart-bar"></i>}
                      text="Agrupamentos musculares"
                    />

                    <VerticalBarGraph
                      xLabel="Músculo alvo"
                      yLabel="Quantidade"
                      data={statsData.muscleGroupData}
                      height={this.getDefaultGraphHeight()}
                      yInterval={1}
                    />

                    <DefaultSubSectionTitle
                      className="training-day-data__sub-section"
                      icon={<i className="fas fa-chart-pie"></i>}
                      text="Capacidades (por tempo)"
                    />

                    <PieGraph
                      data={statsData.capacityData}
                      height={this.getPieHeight()}
                      legendVerticalAlign={this.state.screenWidth > 420 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 420 ? 'right' : 'center'}
                      valueTextCallback={(value) => value}
                      indexLabel="{value}"
                      // toolTipContent={`<span style='"'color: {color};'"'>{label}:</span> {y}`}
                      // defaultMultiplier={1}
                    />

                  </div>

                </VerticalAccordionContainer>

              </section>

            </React.Fragment>
          }

        </div>

        <HorizontalRule />

        <div className="training-day-data__buttons-container">

          {(this.props.training_day.editable && this.props.userPermissionIds.includes(permissions.EDIT_TRAINING_DAY_PERMISSION_ID)) &&
            <button
              className="training-day-data__save-button"
              disabled={!this.props.enableSave}
              onClick={this.props.onSave}
            >

              Salvar

            </button>
          }

          <Link
            className="training-day-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Voltar

          </Link>

        </div>

      </DefaultSection>
    );
  }
}

export default TrainingDayData;
