import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModels, postModel} from '../../utils/functions';
import FinancialCategoryData from './financial_category_data';

class FinancialCategoryAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_category: {
        name: "",
        type: "Ambos",
        cost_center_id: "",
        description: "",
        parent_id: "",
        color: '#000000'
      },
      financialCategories: [],
      costCenters: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let financialCategories = getModels(routes.FINANCIAL_CATEGORIES_GET_API);
    let cost_centers = getModels(routes.COST_CENTERS_GET_API);

    financialCategories = await financialCategories;
    if(financialCategories) {
      update.financialCategories = financialCategories;
    }

    cost_centers = await cost_centers;
    if(cost_centers) {
      update.costCenters = cost_centers;
    }

    if(cost_centers) {
      this.setState(update);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.financial_category, [name]: value};

    this.setState({
      financial_category: newData
    });
  }

  inputsAreValid() {
    return this.state.financial_category.name.length > 0 &&
           (this.state.financial_category.type === 'Receita' ||
            this.state.financial_category.type === 'Gasto' ||
            this.state.financial_category.type === 'Ambos');
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.financial_category}
    if(!data.cost_center_id) {
      data.cost_center_id = null;
    }
    else {
      data.cost_center_id = parseInt(data.cost_center_id);
    }
    if(!data.parent_id) {
      data.parent_id = null;
    }
    else {
      data.parent_id = parseInt(data.parent_id);
    }

    try {
      await postModel(routes.FINANCIAL_CATEGORY_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FINANCIAL_CATEGORY_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FINANCIAL_CATEGORY_LIST_PATH,
            text: "Listar categorias"
          },
          {
            path: routes.FINANCIAL_CATEGORY_ADD_PATH,
            text: "Adicionar categoria"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar categoria financeira"
        loading={this.state.loading}
      >

        <FinancialCategoryData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          financial_category={this.state.financial_category}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FINANCIAL_CATEGORY_LIST_PATH}
          costCenters={this.state.costCenters}
          financialCategories={this.state.financialCategories}
        />

      </ContentFrame>
    );
  }
}

export default FinancialCategoryAdd;
