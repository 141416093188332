import React from 'react';
import { PoseGroup } from 'react-pose';
import * as routes from '../../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        PAYMENT_METHOD_CREDIT_CARD_ID,
        PAYMENT_METHOD_CREDIT_CARD_DEBT_ID,
        PAYMENT_METHOD_RECURRENT_ID
} from '../../../constants'; //STUDENT_REGISTRATION_COST
import {getModels,
        postModel,
        getAsLocalDate,
        getLocalDateIsoString,
        setUrlParameters,
        getContractPaymentIndex} from '../../../utils/functions';
import ContractData from './contract_data';
import * as paths from '../constants';
import {DefaultSubSectionTitle} from '../../../utils/default_section';
import {FadeContainer} from '../../../utils/pose_containers';
import PreLoader from '../../../utils/preloader';
import * as permissions from '../../../permissions';


class ContractAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: {
        service_plan_id: "",
        total_value: "",
        discount_percentage_applied: 0,
        period: "",
        effective_date: getLocalDateIsoString(new Date()),
        is_renew: false,
        payments: [this.createPayment(`Pagamento de contrato. \nAluno: ${this.props.student.name}. `)],
      },
      active_search_tag_ids: [],
      financial_search_tags: [],
      availablePaymentDevices: [],
      sendToPaymentDevice: false,
      requires_nf: true,
      promotionalCouponApplied: null,
      contractFixedTotalValue: "",
      activeService: null,
      restriction_map: null,
      max_possible_discount: 0,
      training_times: [],
      calendar_services: [],
      services: [],
      paymentMethods: [],
      additionalDescription: "",
      transactionCount: "",
      firstPaymentDate: "",
      totalValueMessage:"",
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  createPayment(description='') {
    return {
      description: description,
      payment_method_id: "",
      is_canceled: false,
      transactions: [],
      search_tag_ids: [],
      payment_device_requests: []
    };
  }

  createTransaction(effective_date='', value='') {
    return {
      effective_date: effective_date,
      value: value,
      completed: false,
      nf_coupon_id: null
    };
  }

  async getFinancialSearchTags() {
    return await getModels(routes.FINANCIAL_SEARCH_TAGS_GET_API);
  }

  async componentDidMount() {
    let services = getModels(`${routes.SERVICES_GET_API}?load_additional_data=true&is_active=true`);
    let available_calendar = getModels(routes.TRAINING_TIMES_GET_API);
    let paymentMethods = getModels(routes.PAYMENT_METHODS_GET_API);
    let financial_search_tags = this.getFinancialSearchTags();
    let availablePaymentDevices = getModels(routes.PAYMENT_DEVICES_AVAILABLE_GET_API);

    services = await services;
    paymentMethods = await paymentMethods;
    available_calendar = await available_calendar;

    const update = {
      loading: false
    }

    if(services) {
      update.services = services;

      for(const service of update.services) {
        if(service.code !== null) {
          service.name = `${service.code} - ${service.name}`;
        }
      }

      update.services.sort((a, b) => a.name.localeCompare(b.name));
    }

    if(paymentMethods) {
      update.paymentMethods = paymentMethods;
      update.paymentMethods.sort((a, b) => a.name.localeCompare(b.name));
    }

    if(available_calendar) {
      update.calendar_services = available_calendar.services;
      update.training_times = available_calendar.training_times;
    }

    financial_search_tags = await financial_search_tags;

    if(financial_search_tags) {
      update.financial_search_tags = financial_search_tags;
      update.financial_search_tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    availablePaymentDevices = await availablePaymentDevices;

    if(availablePaymentDevices) {
      update.availablePaymentDevices = availablePaymentDevices;
      update.availablePaymentDevices.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  getPaymentsCopy(convertValues = false) {
    return this.state.contract.payments.map((payment) => ({
      ...payment,
      transactions: payment.transactions.map((transaction) => ({
        ...transaction,
        value: convertValues ? parseFloat(transaction.value) : transaction.value
      }))
    }));
  }

  handleInputChange(event) {
    const target = event.target;
    let name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'sendToPaymentDevice') {
      this.setState({
        sendToPaymentDevice: value
      });

      return;
    }
    else if (name === 'requires_nf') {
      this.setState({
        requires_nf: value
      });

      return;
    }

    let updatePayments = false;
    let updateTotalValue = false;

    const update = {};
    const additionalUpdate = {};

    let transactionCount = this.state.transactionCount;
    let firstPaymentDate = this.state.firstPaymentDate;
    let totalValue = parseFloat(this.state.contract.total_value);
    let fixedTotalValue = parseFloat(this.state.contractFixedTotalValue);
    let discountPercentageApplied = this.state.contract.discount_percentage_applied !== null ? parseFloat(this.state.contract.discount_percentage_applied) : 0;
    let is_renew = this.state.contract.is_renew;
    let service_plan_id = parseInt(this.state.contract.service_plan_id);
    let firstPaymentValue = 0;

    if(this.state.contract.payments.length > 1) {
      firstPaymentValue = this.state.contract.payments[getContractPaymentIndex(this.state.contract.payments, false)].transactions[0].value;
    }

    if(name === 'service_plan_id') {
      const service = this.state.services.find((service) => service.id === parseInt(value));

      if(service) {
        update.total_value = service.value.toFixed(2);
        additionalUpdate.contractFixedTotalValue = update.total_value;
        totalValue = update.total_value;
        update.period = service.period;
        additionalUpdate.restriction_map = null;
        additionalUpdate.activeService = null;
        additionalUpdate.max_possible_discount = service.allowed_discount_percentage * 100;
        update.discount_percentage_applied = 0;
        discountPercentageApplied = 0;
        additionalUpdate.promotionalCouponApplied = null;
      }

      updateTotalValue = true;
      updatePayments = true;
      service_plan_id = parseInt(value);
      update[name] = value;
    }
    else if(name === 'is_renew') {
      updateTotalValue = true;
      updatePayments = true;
      is_renew = value;
      update[name] = value;
      update.discount_percentage_applied = 0;
      discountPercentageApplied = 0;
      additionalUpdate.promotionalCouponApplied = null;
      additionalUpdate.max_possible_discount = this.state.services.find((service) => service.id === service_plan_id).allowed_discount_percentage * 100;
    }
    else if(name === 'total_value') {
      updatePayments = true;
      update[name] = value;
      totalValue = parseFloat(value);
      additionalUpdate.contractFixedTotalValue = value;
      update.discount_percentage_applied = 0;
      discountPercentageApplied = 0;
      additionalUpdate.promotionalCouponApplied = null;
      additionalUpdate.max_possible_discount = this.state.services.find((service) => service.id === service_plan_id).allowed_discount_percentage * 100;
    }
    else if(name === 'additionalPayment') {
      if(value) {
        if(this.state.contract.payments.length > 1) {
          return;
        }

        const newPayment = this.createPayment(`Entrada. \nAluno: ${this.props.student.name}. `);
        newPayment.transactions.push(this.createTransaction(getLocalDateIsoString(new Date())));

        // STUDENT_REGISTRATION_COST.toFixed(2)

        update.payments = [newPayment, ...this.getPaymentsCopy()];
      }
      else {
        if(this.state.contract.payments.length <= 1) {
          return;
        }

        const paymentsCopy = this.getPaymentsCopy();

        update.payments = [paymentsCopy[getContractPaymentIndex(paymentsCopy)]];
        firstPaymentValue = 0;
      }

      updatePayments = true;
    }
    else if(name.startsWith('payments:')) {
      const selection = name.split(':');
      const paymentPosition = parseInt(selection[1]);

      const paymentsCopy = this.getPaymentsCopy();

      if(selection[2] === 'transactions') {
        const transactionPosition = parseInt(selection[3]);

        paymentsCopy[paymentPosition].transactions[transactionPosition][selection[4]] = value;

        if(paymentPosition === 0 && selection[4] === 'value' && paymentsCopy.length > 1) {
          updatePayments = true;
          firstPaymentValue = parseFloat(value);
        }
      }
      else {
        if(paymentPosition === getContractPaymentIndex(this.state.contract.payments) && selection[2] === 'payment_method_id' && value.length >= 0) { //firstPaymentDate.length <= 0
          // Apply updates for firstPaymentDate variable
          firstPaymentDate = new Date();

          if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_ID || parseInt(value) === PAYMENT_METHOD_RECURRENT_ID) {
            firstPaymentDate.setDate(firstPaymentDate.getDate() + 30);
          }
          else if (parseInt(value) === PAYMENT_METHOD_CREDIT_CARD_DEBT_ID) {
            firstPaymentDate.setDate(firstPaymentDate.getDate() + 1);
          }

          firstPaymentDate = getLocalDateIsoString(firstPaymentDate);

          additionalUpdate.transactionCount = transactionCount;
          additionalUpdate.firstPaymentDate = firstPaymentDate;

          updatePayments = true;
        }

        paymentsCopy[paymentPosition][selection[2]] = value;
      }

      update.payments = paymentsCopy;
    }
    else if(name === 'transactionCount' || name === 'firstPaymentDate') {
      if(value) {
        if(name === 'transactionCount' && this.state.transactionCount !== value) {
          transactionCount = value;
        }
        else if(name === 'firstPaymentDate' && this.state.firstPaymentDate !== value) {
          firstPaymentDate = value;
        }
      }

      additionalUpdate.transactionCount = transactionCount;
      additionalUpdate.firstPaymentDate = firstPaymentDate;

      updatePayments = true;
    }
    else if(name === 'additionalDescription') {
      additionalUpdate.additionalDescription = value
    }
    else {
      if(name === 'discount_percentage_applied') {
        additionalUpdate.max_possible_discount = this.state.services.find((service) => service.id === service_plan_id).allowed_discount_percentage * 100;

        if(value > additionalUpdate.max_possible_discount) {
          value = additionalUpdate.max_possible_discount;
        }
        else if(value < 0) {
          value = 0;
        }

        value = value / 100;

        updatePayments = true;
        totalValue = Math.round(parseFloat(fixedTotalValue * (1 - value)) * 100) / 100;
        update.total_value = totalValue;
        additionalUpdate.promotionalCouponApplied = null;
      }

      update[name] = value;
    }

    if(updateTotalValue && service_plan_id) {
      let lastContract = null;

      if(is_renew) {
        for(let contract of this.props.student.contracts) {
          if(contract.service_plan_id === service_plan_id) {
            if(lastContract && lastContract.expires_at > contract.expires_at) {
              continue;
            }

            lastContract = contract;
          }
        }
      }

      const service = this.state.services.find((service) => service.id === service_plan_id);

      if(lastContract !== null) {
        if(lastContract.discount_percentage_applied !== null) {
          discountPercentageApplied = lastContract.discount_percentage_applied;
        }
        totalValue = lastContract.total_value / (1 - discountPercentageApplied);

        update.total_value = Math.floor(100 * totalValue) / 100;
        additionalUpdate.totalValueMessage = `Valor atual: R$ ${service.value.toFixed(2)}`
      }
      else if(service) {
        update.total_value = service.value.toFixed(2);
        totalValue = update.total_value
        additionalUpdate.totalValueMessage = '';
      }

      additionalUpdate.contractFixedTotalValue = update.total_value;
    }

    if(transactionCount && firstPaymentDate && updatePayments) {
      const paymentsCopy = update.payments ? update.payments : this.getPaymentsCopy();

      const transactions = [];

      const initialDate = getAsLocalDate(firstPaymentDate);

      totalValue -= firstPaymentValue;

      let transactionValue = (Math.floor(totalValue/transactionCount * 100) / 100).toFixed(2);

      for(let i=0; i<transactionCount; i++) {
        transactions.push(this.createTransaction(getLocalDateIsoString(initialDate), transactionValue));
        initialDate.setMonth(initialDate.getMonth()+1);
      }

      paymentsCopy[getContractPaymentIndex(paymentsCopy)].transactions = transactions;

      update.payments = paymentsCopy;
    }

    const newData = {...this.state.contract, ...update};

    this.setState({
      contract: newData,
      ...additionalUpdate
    });
  }

  inputsAreValid() {
    return (this.state.contract.service_plan_id && this.state.contract.service_plan_id > 0) &&
           this.state.contract.total_value >= 0 &&
           this.state.contract.period > 0 &&
           this.state.contract.effective_date.length > 0 &&
           this.state.contract.payments.length > 0 &&
           this.state.contract.payments.every(payment => (
             (payment.payment_method_id && payment.payment_method_id > 0) &&
             payment.transactions.length > 0 &&
             payment.transactions.every((transaction) => (
               transaction.effective_date.length > 0 &&
               transaction.value >= 0
             ))
           ));
  }

  maySendToPaymentDevice() {
    const paymentIndex = getContractPaymentIndex(this.state.contract.payments);

    if (this.state.availablePaymentDevices.length <= 0 || this.state.contract.payments[paymentIndex].payment_method_id.length <= 0) {
      return false;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.contract.payments[paymentIndex].payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return false;
    }

    return paymentMethod.has_payment_device_integration &&
          (paymentMethod.installments_may_apply ||
            (!paymentMethod.installments_may_apply && this.state.contract.payments[paymentIndex].transactions.length === 1));
  }

  getPaymentMethodAlertMessage() {
    const mainPaymentIndex = getContractPaymentIndex(this.state.contract.payments);

    if (this.state.contract.payments[mainPaymentIndex].payment_method_id.length <= 0) {
      return null;
    }

    const paymentMethod = this.state.paymentMethods.find((entry) => entry.id === parseInt(this.state.contract.payments[mainPaymentIndex].payment_method_id));

    if (typeof paymentMethod === 'undefined') {
      return null;
    }

    if (paymentMethod.has_payment_device_integration && !paymentMethod.installments_may_apply) {
      return 'Envio para maquininha: somente para pagamentos à vista!';
    }

    return null;
  }

  async saveData() {
    const valueHighlights = ['total_value'];

    const realContractValue = this.state.contract.payments.reduce((sum, payment, paymentIndex) => {
      return payment.transactions.reduce((totalValue, transaction, transactionIndex) => {
        valueHighlights.push(`payments:${paymentIndex}:transactions:${transactionIndex}:value`);

        return parseFloat(transaction.value) + totalValue;
      }, 0) + sum;
    }, 0);

    if(Math.abs(realContractValue - parseFloat(this.state.contract.total_value)) > 1) {
      this.setState({
        highlights: valueHighlights,
        showWarningMessage: true,
        warningMessage: "Somatória dos valores maior que o valor total do contrato."
      });

      return;
    }

    if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
      const mainPaymentIndex = getContractPaymentIndex(this.state.contract.payments);

      let lastValue = null;

      for (const transaction of this.state.contract.payments[mainPaymentIndex].transactions) {
        if (lastValue !== null && Math.abs(lastValue - transaction.value) > 0.01) {
          this.setState({
            highlights: valueHighlights,
            showWarningMessage: true,
            warningMessage: "Valor de parcelas devem ser iguais para envio de cobrança na maquininha."
          });

          window.scrollTo(0, 0);

          return;
        }

        lastValue = transaction.value;
      }
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {
      ...this.state.contract,
      payments: this.getPaymentsCopy(true)
    }
    data.service_plan_id = parseInt(data.service_plan_id);
    data.total_value = parseFloat(data.total_value);
    data.period = parseInt(data.period);
    data.user_id = this.props.student.id;

    for(const payment of data.payments) {
      payment.search_tag_ids = [...this.state.active_search_tag_ids];
      payment.requires_nf = this.state.requires_nf;
    }

    if(this.state.additionalDescription) {
      data.payments[getContractPaymentIndex(data.payments)].description += `\n${this.state.additionalDescription}`;
    }

    if(this.state.restriction_map !== null) {
      data.restriction_map = this.state.restriction_map;
    }

    if (this.state.promotionalCouponApplied !== null) {
      data.promotional_coupon_id = this.state.promotionalCouponApplied.id;
    }
    else {
      data.promotional_coupon_id = null;
    }

    let response;

    try {
      response = await postModel(routes.CONTRACT_POST_API, data, true);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 106:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'promotional_coupon.discount_percentage':
                    warningMessages.push('Valor de desconto aplicado por cupom está diferente do valor cadastrado. Reaplique novamente o cupom de desconto, altere o valor do contrato manualmente ou crie um novo contrato');
                    highlights.push('promotional_coupon_code');
                    highlights.push('discount_percentage_applied');

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes("Coupon discount has changed")) {
                warningMessages.push('Valor de desconto aplicado por cupom está diferente do valor cadastrado. Reaplique novamente o cupom de desconto, altere o valor do contrato manualmente ou crie um novo contrato');
                  highlights.push('promotional_coupon_code');
                  highlights.push('discount_percentage_applied');
              }
              else if (error.message.includes("Coupon usage limit reached")) {
                warningMessages.push('Limite de uso do cupom atingido');
                highlights.push('promotional_coupon_code');
              }

              break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      if (warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    if (this.maySendToPaymentDevice() && this.state.sendToPaymentDevice) {
      this.props.history.replace(setUrlParameters(`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_EDIT_PATH}${response.model_saved.id}`, {
        initiate_payment_process: true
      }));
    }
    else {
      this.props.history.replace(`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_PATH}`);
      // this.props.history.goBack();
    }
  }

  async applyDiscountCoupon(code) {
    const returnMessage = {
      message: '',
      isValid: false
    };

    const data = {
      code,
      service_plan_id: parseInt(this.state.contract.service_plan_id)
    };

    try {
      const response = await postModel(routes.CONTRACT_CHECK_DISCOUNT_COUPON_POST_API, data, true);

      if (response) {
        returnMessage.message = `${100*response.discount_percentage}% de desconto aplicado!`;
        returnMessage.isValid = true;

        const contract = {...this.state.contract};
        const additionalUpdate = {promotionalCouponApplied: response};

        const service = this.state.services.find((service) => service.id === parseInt(this.state.contract.service_plan_id));

        if(service) {
          contract.total_value = Math.round(parseFloat(parseFloat(service.value.toFixed(2)) * (1 - response.discount_percentage)) * 100) / 100;
          additionalUpdate.max_possible_discount = 100;
          contract.discount_percentage_applied = response.discount_percentage;
        }

        // Update copied from function handleInputChange
        // TODO: Create a single function to handle both updates (here and at handleInputChange)
        const transactionCount = this.state.transactionCount;
        const firstPaymentDate = this.state.firstPaymentDate;
        let firstPaymentValue = 0;

        if(this.state.contract.payments.length > 1) {
          firstPaymentValue = this.state.contract.payments[getContractPaymentIndex(this.state.contract.payments, false)].transactions[0].value;
        }

        if(transactionCount && firstPaymentDate) {
          const paymentsCopy = contract.payments ? contract.payments : this.getPaymentsCopy();

          const transactions = [];

          const initialDate = getAsLocalDate(firstPaymentDate);

          const remainingValue = contract.total_value - firstPaymentValue;

          let transactionValue = (Math.floor(remainingValue/transactionCount * 100) / 100).toFixed(2);

          for(let i=0; i<transactionCount; i++) {
            transactions.push(this.createTransaction(getLocalDateIsoString(initialDate), transactionValue));
            initialDate.setMonth(initialDate.getMonth()+1);
          }

          paymentsCopy[getContractPaymentIndex(paymentsCopy)].transactions = transactions;

          contract.payments = paymentsCopy;
        }

        this.setState({
          contract,
          ...additionalUpdate
        });
      }
    }
    catch(errors) {
      returnMessage.message = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if (error.message.includes('Invalid coupon code')) {
                returnMessage.message = 'Código de cupom inválido.'
              }
              else if (error.message.includes('Coupon is not valid yet')) {
                returnMessage.message = 'Cupom fora do período válido.'
              }
              else if (error.message.includes('Invalid coupon for given service')) {
                returnMessage.message = 'Cupom inválido para o plano selecionado.'
              }
              else if (error.message.includes('Coupon is not valid anymore')) {
                returnMessage.message = 'Cupom expirado.'
              }
              else if (error.message.includes('Coupon is not active')) {
                returnMessage.message = 'Cupom inativo.'
              }
              else if (error.message.includes('Coupon usage limit reached')) {
                returnMessage.message = 'Limite de uso do cupom atingido.'
              }

              break;
            case 209:
              returnMessage.message = 'Sessão do usuário expirada.'
              break;
            default:
          }
        }
      }
    }

    return returnMessage;
  }

  getRestrictionMapCopy(entry) {
    const copy = {};

    for (let [key, value] of Object.entries(entry)) {
      copy[key] = {
        enable_all: value.enable_all,
        time_ids: [...value.time_ids]
      };
    }

    return copy;
  }

  onChangeRestriction(training_time_id) {
    if(this.state.activeService === null) {
      return;
    }

    let restriction_map = null;

    if(this.state.restriction_map !== null) {
      restriction_map = this.getRestrictionMapCopy(this.state.restriction_map);
    }
    else {
      const serviceSelected = this.state.services.find((service) => service.id === parseInt(this.state.contract.service_plan_id));

      if(serviceSelected && serviceSelected.restriction_map) {
        restriction_map = this.getRestrictionMapCopy(serviceSelected.restriction_map);
      }
    }

    if(restriction_map === null || !restriction_map[this.state.activeService]) {
      return;
    }

    const time_ids = [...restriction_map[this.state.activeService].time_ids];

    if(time_ids.includes(training_time_id)) {
      time_ids.splice(time_ids.indexOf(training_time_id), 1);
    }
    else {
      time_ids.push(training_time_id);
    }

    restriction_map[this.state.activeService] = {
      enable_all: restriction_map[this.state.activeService].enable_all,
      time_ids,
    };

    this.setState({
      restriction_map
    });
  }

  onSelectService(activeService) {
    this.setState({activeService});
  }

  onToggleSearchTag(tag_id) {
    const tag_ids = [...this.state.active_search_tag_ids];

    if(tag_ids.includes(tag_id)) {
      tag_ids.splice(tag_ids.indexOf(tag_id), 1);
    }
    else {
      tag_ids.push(tag_id);
    }

    this.setState({active_search_tag_ids: tag_ids});
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultSubSectionTitle
          icon={<i className="fas fa-plus"></i>}
          text="Adicionar contrato"
        />

        <ContractData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          contract={this.state.contract}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={`${routes.STUDENT_EDIT_PATH}${this.props.student.id}${paths.CONTRACT_PATH}`}
          services={this.state.services}
          calendar_services={this.state.calendar_services}
          training_times={this.state.training_times}
          paymentMethods={this.state.paymentMethods}
          additionalDescription={this.state.additionalDescription}
          transactionCount={this.state.transactionCount}
          firstPaymentDate={this.state.firstPaymentDate}
          history={this.props.history}
          totalValueMessage={this.state.totalValueMessage}
          disableTotalValue={!this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_COMPLETE_PERMISSION_ID)}
          disablePeriod={!this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_COMPLETE_PERMISSION_ID)}
          // blockAdditionalValue={!this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_COMPLETE_PERMISSION_ID)}
          blockTransactionStatus={!this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_COMPLETE_PERMISSION_ID)}
          mayEditRestrictions={this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_COMPLETE_PERMISSION_ID)}
          onChangeRestriction={(training_time_id) => this.onChangeRestriction(training_time_id)}
          onSelectService={(activeService) => this.onSelectService(activeService)}
          restriction_map={this.state.restriction_map}
          activeService={this.state.activeService}
          max_possible_discount={this.state.max_possible_discount}
          unit_type_id={this.props.unit_type_id}
          promotionalCouponApplied={this.state.promotionalCouponApplied}
          applyDiscountCoupon={(code) => this.applyDiscountCoupon(code)}
          financial_search_tags={this.state.financial_search_tags}
          active_search_tag_ids={this.state.active_search_tag_ids}
          onToggleSearchTag={(tag_id) => this.onToggleSearchTag(tag_id)}
          availablePaymentDevices={this.state.availablePaymentDevices}
          maySendToPaymentDevice={() => this.maySendToPaymentDevice()}
          getPaymentMethodAlertMessage={() => this.getPaymentMethodAlertMessage()}
          sendToPaymentDevice={this.state.sendToPaymentDevice}
          requires_nf={this.state.requires_nf}
          showPaymentDeviceOverlay={false}
          userPermissionIds={this.props.userPermissionIds}
          paymentReceived={null}
        />

      </React.Fragment>
    );
  }
}

export default ContractAdd;
