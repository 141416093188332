import React from 'react';
import { Link } from 'react-router-dom';
import './muscle_group_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import {getModels, deleteModel} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import * as permissions from '../../permissions';


class MuscleGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      muscle_groups: [],
      deleteId: null,
      deleteInProgress: false,
      deleteFailed: false,
      deleteFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getMuscleGroups() {
    return await getModels(routes.MUSCLE_GROUPS_GET_API);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const muscle_groups = await this.getMuscleGroups();

    this.setState({
      loadingData: false,
      muscle_groups
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      deleteInProgress: false,
      deleteFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      deleteInProgress: true
    });

    try{
      if(await deleteModel(`${routes.MUSCLE_GROUP_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 104:
              const descriptions = [];

              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'exercises':
                    descriptions.push('Agrupamento muscular vinculado à um exercício');

                    break;
                  default:
                }
              }

              errorDescription = `${descriptions.join('. ')}. Os vínculos devem ser ` +
                                 `desfeitos antes de deletar o agrupamento.`;

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        deleteFailDescription: errorDescription,
        deleteFailed: true,
        deleteInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.EDIT_MUSCLE_GROUP_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.DELETE_MUSCLE_GROUP_PERMISSION_ID);
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        {this.props.userPermissionIds.includes(permissions.EDIT_MUSCLE_GROUP_PERMISSION_ID) &&
          <Link
            className="model-table__default-edit-button"
            to={`${routes.MUSCLE_GROUP_EDIT_PATH}${entry.id}`}
          >

              <i className="fas fa-edit"></i>

          </Link>
        }

        {this.props.userPermissionIds.includes(permissions.DELETE_MUSCLE_GROUP_PERMISSION_ID) &&
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-tag"></i>),

    ];

    if(this.state.screenWidth > 710) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {cellClassName: "muscle-group-list__description-cell"}),
      );
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.deleteInProgress) {
      return 'Deletando agrupamento';
    }
    else if(this.state.deleteFailed) {
      return 'Falha ao agrupamento';
    }

    return 'Deletar agrupamento';
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.deleteFailed ? this.state.deleteFailDescription : 'Todos os dados relacionados ao agrupamento serão removidos'}
          confirmText="Deletar agrupamento"
          cancelText={this.state.deleteFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.deleteInProgress}
          useErrorIcon={this.state.deleteFailed}
          hideConfirmButton={this.state.deleteFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.MUSCLE_GROUP_LIST_PATH,
              text: this.state.screenWidth > 480 ? 'Listar agrupamentos musculares' : 'Agrupamentos musculares'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Listar agrupamentos musculares"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="muscle-group-list"
            title="Lista de agrupamentos"
          >

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.muscle_groups}
              initialOrderBy="name"
            >

              {this.props.userPermissionIds.includes(permissions.ADD_MUSCLE_GROUP_PERMISSION_ID) &&
                <Link
                  className="model-table__default-button"
                  to={routes.MUSCLE_GROUP_ADD_PATH}
                >

                  <i className="fas fa-plus"></i> Adicionar novo agrupamento muscular

                </Link>
              }

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default MuscleGroupList;
