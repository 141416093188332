import React from 'react';
import { Link } from 'react-router-dom';
import './student_address_data.scss';
import {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {patchModel, setInputCursor, getCepText} from '../../utils/functions';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';


class StudentAddressData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.student.address,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let studentData = {
      address: {...this.state.address}
    };

    try {
      await patchModel(`${routes.STUDENT_PATCH_API}${this.props.student.id}`, studentData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 203) {
            if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para atualizar este cadastro. Por favor, entre em contato com o responsável pela unidade FYD para realizar a atualização');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      if(warningMessages.length === 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.onSave();

    // this.props.history.replace(this.props.onCancelPath);
  }

  inputsAreValid() {
    return true;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.saveData();
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if(value && name === 'cep') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getCepText(value);;

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const valueIncreased = this.state.address[name].length < value.length;

      if(value.length > 5 &&
          (newPosition === 6)) {
        newPosition = valueIncreased ? 7 : 5;
      }

      setInputCursor(target, newPosition);
    }

    const newData = {...this.state.address, [name]: value};

    this.setState({
      address: newData
    });
  }

  render() {
    return (
      <React.Fragment>

        <div className="student-address-data__warning-container">

          <WarningMessage
            message={this.state.warningMessage}
            onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
            visible={this.state.showWarningMessage}
          />

        </div>

        <div className="student-address-data__input-container">

          <HalfWrapper>

            <DefaultInput
              name="street"
              isHighlighted={this.isHighlighted("street")}
              label="Endereço"
              type="text"
              placeholder="Logradouro do aluno"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.address.street}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              className="student-address-data__number-input"
              name="number"
              isHighlighted={this.isHighlighted("number")}
              label="Número"
              type="text"
              placeholder="Número do aluno"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.address.number}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <DefaultInput
            name="complement"
            isHighlighted={this.isHighlighted("complement")}
            label="Complemento"
            type="text"
            placeholder="Complemento de endereço"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.address.complement}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
          />

          <HalfWrapper>

            <DefaultInput
              name="neighborhood"
              isHighlighted={this.isHighlighted("neighborhood")}
              label="Bairro"
              type="text"
              placeholder="Bairro do aluno"
              maxLength="64"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.address.neighborhood}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              className="student-address-data__cep-input"
              name="cep"
              isHighlighted={this.isHighlighted("cep")}
              label="CEP"
              type="text"
              placeholder="CEP do aluno"
              maxLength="16"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getCepText(this.state.address.cep)}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <DefaultInput
              name="city"
              isHighlighted={this.isHighlighted("city")}
              label="Cidade"
              type="text"
              placeholder="Cidade do aluno"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.address.city}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <DefaultInput
              className="student-address-data__state-input"
              name="state"
              isHighlighted={this.isHighlighted("state")}
              label="Estado"
              type="text"
              placeholder="Estado do aluno"
              maxLength="32"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.address.state}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </HalfWrapper>

        </div>

        <HorizontalRule />

        <div className="student-address-data__buttons-container">

          <button
            className="student-address-data__save-button"
            disabled={!this.inputsAreValid()}
            onClick={() => this.saveData()}
          >

            Salvar

          </button>

          <Link
            className="student-address-data__cancel-button"
            to={this.props.onCancelPath}
          >

            Cancelar

          </Link>

        </div>

      </React.Fragment>
    );
  }
}

export default StudentAddressData;
