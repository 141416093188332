import React from 'react';
import { Link } from 'react-router-dom';
import './financial_movement_list.scss';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import * as paths from '../student/constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection, {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import {getModels, deleteModel, getCurrencyText, getAsLocalDate, getLocalDateIsoString, getAsLocalDateString, setUrlParameters} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, PAYMENT_ALERT_RANGE} from '../../constants';

class FinancialMovementList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let transactionInitialDate = queryParameters.get('initial_transaction_date');
    let transactionFinalDate = queryParameters.get('final_transaction_date');

    let createdAtInitialDate = queryParameters.get('initial_date');
    let createdAtFinalDate = queryParameters.get('final_date');

    let transactionPending = queryParameters.get('pending');

    if(!transactionInitialDate) {
      transactionInitialDate = new Date();
      transactionInitialDate.setDate(transactionInitialDate.getDate() - 14);
      transactionInitialDate = getLocalDateIsoString(transactionInitialDate);
    }
    else if(transactionInitialDate === 'all') {
      transactionInitialDate = '';
    }
    if(!transactionFinalDate) {
      transactionFinalDate = new Date();
      transactionFinalDate.setDate(transactionFinalDate.getDate() + 14);
      transactionFinalDate = getLocalDateIsoString(transactionFinalDate);
    }
    else if(transactionFinalDate === 'all') {
      transactionFinalDate = '';
    }

    if(!createdAtInitialDate) {
      createdAtInitialDate = '';
    }
    if(!createdAtFinalDate) {
      createdAtFinalDate = '';
    }

    if(!transactionPending) {
      transactionPending = 'true';
    }

    this.state = {
      transactionInitialDate,
      transactionInitialDateInput: transactionInitialDate,
      transactionFinalDate,
      transactionFinalDateInput: transactionFinalDate,
      createdAtInitialDate,
      createdAtInitialDateInput: createdAtInitialDate,
      createdAtFinalDate,
      createdAtFinalDateInput: createdAtFinalDate,
      transactionPending,
      transactionPendingInput: transactionPending,
      loadingData: true,
      financial_movements: [],
      deleteId: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  async getFinancialMovements() {
    const parameters = {
      initial_transaction_date: this.state.transactionInitialDate,
      final_transaction_date: this.state.transactionFinalDate,
      initial_date: this.state.createdAtInitialDate,
      final_date: this.state.createdAtFinalDate,
      pending: this.state.transactionPending,
      is_expense: this.props.isExpense
    };

    if(parameters.pending === 'all') {
      parameters.pending = null;
    }

    return await getModels(setUrlParameters(routes.FINANCIAL_MOVEMENTS_GET_API, parameters));
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.transactionInitialDate !== this.state.transactionInitialDate ||
        prevState.transactionFinalDate !== this.state.transactionFinalDate ||
        prevState.createdAtInitialDate !== this.state.createdAtInitialDate ||
        prevState.createdAtFinalDate !== this.state.createdAtFinalDate ||
        prevState.transactionPending !== this.state.transactionPending) {
      this.reloadList();
    }
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loadingData: true
    });

    const financial_movements = await this.getFinancialMovements();

    this.setState({
      loadingData: false,
      financial_movements: financial_movements
    });
  }

  onDeleteEntry(entryId) {
    this.setState({
      deleteId: entryId,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  onCancelDelete() {
    this.setState({
      deleteId: null
    });
  }

  async onConfirmDelete() {
    this.setState({
      confirmInProgress: true
    });

    try{
      if(await deleteModel(`${routes.FINANCIAL_MOVEMENT_DELETE_API}${this.state.deleteId}`)) {
        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }

    this.setState({
      deleteId: null,
    });
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <Link
          className="model-table__default-edit-button"
          to={`${this.props.isExpense ? routes.EXPENSE_EDIT_PATH : routes.INCOME_EDIT_PATH}${entry.id}`}
        >

            <i className="fas fa-edit"></i>

        </Link>

        {entry.contract_id ?
          <Link
            className="model-table__default-link-button"
            to={`${routes.STUDENT_EDIT_PATH}${entry.user_id}${paths.CONTRACT_EDIT_PATH}${entry.contract_id}`}
          >

              <i className="fas fa-link"></i>

          </Link>:
          <button
            className="model-table__default-delete-button"
            onClick={() => this.onDeleteEntry(entry.id)}
          >

            <i className="far fa-trash-alt"></i>

          </button>
        }

      </div>
    );
  }

  getProgressText(entry) {
    return `${getCurrencyText(entry.total_value_completed)} de ${getCurrencyText(entry.total_value)}`;
  }

  getNextPaymentText(entry) {
    if(!entry.next_payment_date) {
      return (
        <div className="financial-movement-list__cell-wrapper">

          <p className="financial-movement-list__date-text--completed">

            Completa

          </p>

        </div>
      );
    }
    else if (entry.is_canceled) {
      return (
        <div className="financial-movement-list__cell-wrapper">

          <p className="financial-movement-list__date-text--alert">

            Cancelada

          </p>

        </div>
      );
    }

    const today = new Date();
    const todayString = getLocalDateIsoString(today);
    const date = getAsLocalDate(entry.next_payment_date);
    const dateString = getLocalDateIsoString(date);

    const timeDiff = Math.abs(date.getTime() - today.getTime());
    const daysToExpire = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return (
      <div className="financial-movement-list__cell-wrapper">

        <p className={`financial-movement-list__date-text${dateString < todayString ? '--past' : daysToExpire < PAYMENT_ALERT_RANGE ? '--alert' : ''}`}>

          {date.toLocaleDateString()}
          <br />
          ({getCurrencyText(entry.next_payment_value)})

        </p>

      </div>
    );
  }

  getCreatedAtText(entry) {
    const date = getAsLocalDate(entry.created_at);

    return (
      <div className="financial-movement-list__cell-wrapper">

        <p className="financial-movement-list__date-text">

          {date.toLocaleDateString()}

        </p>

      </div>
    );
  }

  getCreatedAtFilter(entry) {
    return getAsLocalDateString(entry.created_at);
  }

  getNextPaymentFilter(entry) {
    if(!entry.next_payment_date) {
      return 'completa';
    }
    else if (entry.is_canceled) {
      return 'cancelada';
    }

    return getAsLocalDateString(entry.next_payment_date) || '';
  }

  getClassificationText(entry) {
    return (
      <div className="financial-movement-list__classification-container">

        <p><i className="fas fa-box"></i>{' '}{entry.cost_center_name}</p>
        <p><i className="fas fa-tags"></i>{' '}{entry.financial_category_name}</p>

      </div>
    );
  }

  getClassificationFilter(entry) {
    return `${entry.cost_center_name} ${entry.financial_category_name}`;
  }

  getProperties() {
    let properties = [
      Property('created_at', 'Criado em', <i className="fas fa-calendar-day"></i>, {
        getDataText: this.getCreatedAtText,
        getFilterText: this.getCreatedAtFilter,
      }),
      Property('next_payment_date', 'Próximo pagamento', <i className="fas fa-calendar-day"></i>, {
        getDataText: this.getNextPaymentText,
        getFilterText: this.getNextPaymentFilter,
        getSortCallback: (a, b) => {
          if(a.next_payment_date === null && b.next_payment_date !== null) {
            return 1;
          }
          else if(a.next_payment_date !== null && b.next_payment_date === null) {
            return -1;
          }
          else if(a.next_payment_date === null && b.next_payment_date === null) {
            return 0;
          }
          else if(a.is_canceled && !b.is_canceled) {
            return 1;
          }
          else if(!a.is_canceled && b.is_canceled) {
            return -1;
          }
          else if(a.is_canceled && b.is_canceled) {
            return 0;
          }
          else {
            return a.next_payment_date.localeCompare(b.next_payment_date);
          }
        }
      }),
      Property('progress', 'Progresso', <i className="fas fa-money-bill-alt"></i>, {
        getDataText: this.getProgressText,
        getFilterText: this.getProgressText
      }),
    ];

    if(this.state.screenWidth > 1020) {
      properties.push(
        Property('description', 'Descrição', <i className="fas fa-info-circle"></i>, {
          cellClassName: "financial-movement-list__description-cell"
        }),
      );
    }

    if(this.state.screenWidth > 560) {
      properties.push(
        Property('tags', 'Centro / Categoria', <i className="fas fa-tag"></i>, {
          getDataText: this.getClassificationText,
          getFilterText: this.getClassificationFilter,
          sortable: false
        }),
      );
    }

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Deletando entrada';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao deletar';
    }

    return 'Deletar entrada';
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  getFinancialEntries() {
    let filteredEntries = this.state.financial_movements;

    return filteredEntries;
  }

  mayUpdateDateInputs() {
    if(this.state.transactionInitialDateInput !== this.state.transactionInitialDate ||
       this.state.transactionFinalDateInput !== this.state.transactionFinalDate ||
       this.state.createdAtInitialDateInput !== this.state.createdAtInitialDate ||
       this.state.createdAtFinalDateInput !== this.state.createdAtFinalDate ||
       this.state.transactionPendingInput !== this.state.transactionPending) {
      return true;
    }

    return false;
  }

  mayResetFilterInputs() {
    if(this.state.transactionInitialDateInput.length > 0 ||
       this.state.transactionFinalDateInput.length > 0 ||
       this.state.createdAtInitialDateInput.length > 0 ||
       this.state.createdAtFinalDateInput.length > 0 ||
       this.state.transactionPendingInput !== 'all') {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      transactionInitialDateInput: '',
      transactionFinalDateInput: '',
      createdAtInitialDateInput: '',
      createdAtFinalDateInput: '',
      transactionPendingInput: 'all',
    });
  }

  applyDateInputChanges() {
    if(this.mayUpdateDateInputs()) {
      this.props.history.replace(setUrlParameters(this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH, {
        initial_transaction_date: this.state.transactionInitialDateInput || 'all',
        final_transaction_date: this.state.transactionFinalDateInput || 'all',
        initial_date: this.state.createdAtInitialDateInput,
        final_date: this.state.createdAtFinalDateInput,
        pending: this.state.transactionPendingInput,
      }));

      this.setState({
        transactionInitialDate: this.state.transactionInitialDateInput,
        transactionFinalDate: this.state.transactionFinalDateInput,
        createdAtInitialDate: this.state.createdAtInitialDateInput,
        createdAtFinalDate: this.state.createdAtFinalDateInput,
        transactionPending: this.state.transactionPendingInput,
      });
    }
  }

  getTransactionPendingOptions() {
    return [
      SelectOption('true', 'Não finalizado'),
      SelectOption('false', 'Finalizado'),
      SelectOption('all', 'Todos'),
    ];
  }

  render() {
    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmFailed ? this.state.confirmFailDescription : 'Todos os dados relacionados à entrada serão removidos'}
          confirmText="Deletar entrada"
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.deleteId !== null}
          onCancel={() => this.onCancelDelete()}
          onConfirm={() => this.onConfirmDelete()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: this.props.isExpense ? routes.EXPENSE_LIST_PATH : routes.INCOME_LIST_PATH,
              text: this.props.isExpense ? 'Listar despesas' : 'Listar receitas'
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title={this.props.isExpense ? 'Listar despesas' : 'Listar receitas'}
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="financial-movement-list"
            title={this.props.isExpense ? 'Lista de despesas' : 'Lista de receitas'}
          >

            <div className="financial-movement-list__filters">

              <header className="financial-movement-list__filters__header">

                <h4 className="financial-movement-list__filters__header__text">Filtros</h4>

              </header>

              <div className="financial-movement-list__filters__inputs">

                <div className="financial-movement-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="transactionPendingInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.transactionPendingInput || ''}
                    options={this.getTransactionPendingOptions()}
                  />

                  <HalfWrapper>

                    <DefaultInput
                      name="transactionInitialDateInput"
                      // isHighlighted={this.state.transactionInitialDateInput > this.state.transactionFinalDateInput}
                      label="Data da transação inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.transactionFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.transactionInitialDateInput}
                    />

                    <DefaultInput
                      name="transactionFinalDateInput"
                      // isHighlighted={this.state.transactionInitialDateInput > this.state.transactionFinalDateInput}
                      label="Data da transação final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.transactionInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.transactionFinalDateInput}
                    />

                  </HalfWrapper>

                  <HalfWrapper>

                    <DefaultInput
                      name="createdAtInitialDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data de criação inicial"
                      type="date"
                      placeholder="Data inicial"
                      max={this.state.createdAtFinalDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtInitialDateInput}
                    />

                    <DefaultInput
                      name="createdAtFinalDateInput"
                      // isHighlighted={this.state.createdAtInitialDateInput > this.state.createdAtFinalDateInput}
                      label="Data de criação final"
                      type="date"
                      placeholder="Data final"
                      min={this.state.createdAtInitialDateInput}
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.createdAtFinalDateInput}
                    />

                  </HalfWrapper>

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="financial-movement-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayUpdateDateInputs() &&
                  <button
                    className="financial-movement-list__filters__refresh-button"
                    onClick={() => this.applyDateInputChanges()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <HorizontalRule />

            <ModelTable
              properties={this.getProperties()}
              getActions={(entry) => this.getActions(entry)}
              data={this.getFinancialEntries()}
              initialOrderBy="next_payment_date"
              defaultSortValue='zzzzzzzzz'
            >

              <Link
                className="model-table__default-button"
                to={this.props.isExpense ? routes.EXPENSE_ADD_PATH : routes.INCOME_ADD_PATH}
              >

                <i className="fas fa-plus"></i> Adicionar nova {this.props.isExpense ? 'despesa' : 'receita'}

              </Link>

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default FinancialMovementList;
