import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {getModel, getModels, patchModel} from '../../utils/functions';
import TrainingGroupExerciseAssociationData from './training_group_exercise_association_data';
import {TRAINING_DAY_EDIT_PATH} from '../training_period/constants';
import {TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH} from '../training_day/constants';
import {TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH} from '../training_day_group_association/constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR,
        TRAINING_EXERCISE_PARAMETER_TYPE_RANGE,
        TRAINING_EXERCISE_PARAMETER_PR_NAME,
        TRAINING_EXERCISE_PARAMETER_PR_UNIT,
        TRAINING_EXERCISE_PARAMETER_DEFAULT_NAME,
        DEFAULT_UNIT_TYPE} from '../../constants';

function compareParametersValues(parameterA, parameterB) {
  const parameterAIsArray = parameterA instanceof Array;
  const parameterBIsArray = parameterB instanceof Array;

  if(parameterAIsArray !== parameterBIsArray) {
    return false;
  }
  else if (!parameterAIsArray) {
    return true;
  }
  else if(parameterA.length !== parameterB.length) {
    return false;
  }

  for(let i=0; i < parameterA.length; ++i) {
    if(!compareParametersValues(parameterA[i], parameterB[i])) {
      return false;
    }
  }

  return true;
}

function getNewArray(arraySize, populateCallback) {
  const newArray = [];

  for(let i=0; i < arraySize; ++i) {
    newArray.push(populateCallback());
  }

  return newArray;
}

function getNewIntensityUpdate(training_day_group_association, repetition_count, intensity_value_type, intensity_name) {
  if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
    if(!intensity_name || intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR) {
      return {intensity_value: getNewArray(repetition_count, () => '')};
    }
    else if(intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
      return {intensity_value: getNewArray(repetition_count, () => getNewArray(2, () => ''))};
    }
  }
  else if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
    if(!intensity_name || intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR) {
      return {intensity_value: getNewArray(repetition_count, () => getNewArray(training_day_group_association.cycle_number, () => ''))};
    }
    else if(intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
      return {intensity_value: getNewArray(repetition_count, () => getNewArray(training_day_group_association.cycle_number, () => getNewArray(2, () => '')))};
    }
  }
  else if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
    if(!intensity_name || intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR) {
      return {
        intensity_name: TRAINING_EXERCISE_PARAMETER_DEFAULT_NAME,
        intensity_value: getNewArray(repetition_count, () => ''),
        intensity_value_step: getNewArray(repetition_count, () => 0),
      };
    }
    else if(intensity_value_type === TRAINING_EXERCISE_PARAMETER_TYPE_RANGE) {
      return {
        intensity_name: TRAINING_EXERCISE_PARAMETER_DEFAULT_NAME,
        intensity_value: getNewArray(repetition_count, () => getNewArray(2, () => '')),
        intensity_value_step: getNewArray(repetition_count, () => 0),
      };
    }
  }

  return null;
}

function getUpdatedDifficultyValue(training_day_group_association, repetition_count) {
  const update = {
    difficult_value: getNewArray(repetition_count, () => ''),
    difficult_intermediate_value: getNewArray(repetition_count, () => ''),
    difficult_advanced_value: getNewArray(repetition_count, () => ''),
  };

  // if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
  //   return {difficult_value: getNewArray(repetition_count, () => '')};
  // }
  // else if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
  //   return {difficult_value: getNewArray(repetition_count, () => '')};
  // }
  // else if(training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
  //   return {difficult_value: getNewArray(repetition_count, () => '')};
  // }

  return update;
}

export {compareParametersValues, getNewIntensityUpdate, getUpdatedDifficultyValue};

class TrainingGroupExerciseAssociationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      association: {
        easier_option: null,
        intensity_value_type: TRAINING_EXERCISE_PARAMETER_TYPE_SCALAR,
        intensity_value: [],
        intensity_value_step: [],
        intensity_name: null,
        intensity_unit: null,
        difficult_name: null,
        difficult_unit: null,
        difficult_value: [],
        difficult_intermediate_value: [],
        difficult_advanced_value: [],
        note: null,
        day_group_association: {}
      },
      training_period: {},
      training_day_group_association: {},
      training_exercise: {},
      attachMaxReference: false,
      applyPhaseConstant: !this.isDefaultUnit(),
      exercises: [],
      services: [],
      exerciseCategories: [],
      exerciseFunctions: [],
      muscleGroups: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async componentDidMount() {
    let exerciseCategories = getModels(routes.EXERCISE_CATEOGRIES_GET_API);
    let exerciseFunctions = getModels(routes.EXERCISE_FUNCTIONS_GET_API);
    let muscleGroups = getModels(routes.MUSCLE_GROUPS_GET_API);
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
    let exercises = getModels(`${routes.TRAINING_EXERCISES_GET_API}?active_only=true`);
    let training_period = getModel(`${routes.TRAINING_PERIOD_GET_API}${this.props.match.params.trainingPeriodId}`);
    let association = getModel(`${routes.TRAINING_GROUP_EXERCISE_ASSOCIATION_GET_API}${this.props.match.params.trainingGroupExerciseAssociationId}`);
    let training_day_group_association = getModel(`${routes.TRAINING_DAY_GROUP_ASSOCIATION_GET_API}${this.props.match.params.trainingDayGroupAssociationId}`);

    const update = {
      loading: false
    }

    let repetition_count = null;

    exerciseCategories = await exerciseCategories;

    if(exerciseCategories) {
      update.exerciseCategories = exerciseCategories;
      update.exerciseCategories.sort((a, b) => a.name.localeCompare(b.name));
    }

    exerciseFunctions = await exerciseFunctions;

    if(exerciseFunctions) {
      update.exerciseFunctions = exerciseFunctions;
      update.exerciseFunctions.sort((a, b) => a.name.localeCompare(b.name));
    }

    muscleGroups = await muscleGroups;

    if(muscleGroups) {
      update.muscleGroups = muscleGroups;
      update.muscleGroups.sort((a, b) => a.name.localeCompare(b.name));
    }

    services = await services;

    if(services) {
      update.services = services;
      update.services.sort((a, b) => a.localeCompare(b));
    }

    exercises = await exercises;

    if(exercises) {
      update.exercises = exercises;
      update.exercises.sort((a, b) => a.name.localeCompare(b.name));
    }

    training_period = await training_period;

    if(training_period) {
      update.training_period = training_period;

      repetition_count = training_period.repetition_count;
    }

    association = await association;

    if(association) {
      update.association = association;
      update.training_exercise = association.exercise;

      update.attachMaxReference = association.difficult_name === TRAINING_EXERCISE_PARAMETER_PR_NAME;
    }

    training_day_group_association = await training_day_group_association;

    if(training_day_group_association) {
      update.training_day_group_association = training_day_group_association;

      if(repetition_count && update.association) {
        const intensityFormat = getNewIntensityUpdate(training_day_group_association, repetition_count, update.association.intensity_value_type, update.association.intensity_name);
        const difficultyFormat = getUpdatedDifficultyValue(training_day_group_association, repetition_count);

        if(intensityFormat.intensity_value_step && intensityFormat.intensity_value_step.length !== update.association.intensity_value_step.length) {
          update.association.intensity_value_step = intensityFormat.intensity_value_step;
        }

        if(!compareParametersValues(intensityFormat.intensity_value, update.association.intensity_value)) {
          update.association.intensity_value = intensityFormat.intensity_value;
        }

        if(intensityFormat.intensity_name) {
          update.association.intensity_name = intensityFormat.intensity_name;
        }
        else {
          update.association.intensity_name = '';
        }

        if(!compareParametersValues(difficultyFormat.difficult_value, update.association.difficult_value)) {
          update.association.difficult_value = difficultyFormat.difficult_value;
        }

        if(training_day_group_association.group.has_additional_difficulties) {
          if(!compareParametersValues(difficultyFormat.difficult_intermediate_value, update.association.difficult_intermediate_value)) {
            update.association.difficult_intermediate_value = difficultyFormat.difficult_intermediate_value;
          }
          if(!compareParametersValues(difficultyFormat.difficult_advanced_value, update.association.difficult_advanced_value)) {
            update.association.difficult_advanced_value = difficultyFormat.difficult_advanced_value;
          }
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getArrayCopy(target, convertCallback=null) {
    if(target instanceof Array) {
      const copy = [];

      for(let entry of target) {
        copy.push(this.getArrayCopy(entry, convertCallback));
      }

      return copy;
    }

    if(convertCallback) {
      return convertCallback(target);
    }

    return target;
  }

  getParameterValueCopy(attributename) {
    const entry = this.state.association[attributename];

    return this.getArrayCopy(entry);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    let update = {};
    let additionalUpdate = {};

    if(name === 'attachMaxReference') {
      additionalUpdate.attachMaxReference = value;

      name = '';

      if(value) {
        update.difficult_name = TRAINING_EXERCISE_PARAMETER_PR_NAME;
        update.difficult_unit = TRAINING_EXERCISE_PARAMETER_PR_UNIT;
      }
      else {
        update.difficult_name = '';
        update.difficult_unit = '';
      }

      update = {...update, ...getUpdatedDifficultyValue(this.state.training_day_group_association, this.state.training_period.repetition_count)};
    }
    else if(name === 'applyPhaseConstant') {
      additionalUpdate.applyPhaseConstant = value;

      if(value) {
        const intensity_value = this.getParameterValueCopy('intensity_value');

        for(let k = 1; k < intensity_value.length; ++k) {
          intensity_value[k] = this.getArrayCopy(intensity_value[0]);
        }

        update.intensity_value = intensity_value;

        const difficult_value = this.getParameterValueCopy('difficult_value');

        for(let k = 1; k < difficult_value.length; ++k) {
          difficult_value[k] = this.getArrayCopy(difficult_value[0]);
        }

        update.difficult_value = difficult_value;

        if(this.state.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
          const intensity_value_step = this.getParameterValueCopy('intensity_value_step');

          for(let k = 1; k < intensity_value_step.length; ++k) {
            intensity_value_step[k] = this.getArrayCopy(intensity_value_step[0]);
          }

          update.intensity_value_step = intensity_value_step;
        }
      }

      name = '';
    }
    else if(name === 'difficult_name') {
      if(value) {
        update.difficult_unit = this.state.training_exercise.difficulty_parameters.find((parameter) => parameter.name === value).unit || '';
      }
      else {
        update.difficult_unit = '';
      }

      update = {...update, ...getUpdatedDifficultyValue(this.state.training_day_group_association, this.state.training_period.repetition_count)};
    }

    if(name === 'intensity_name') {
      if(value) {
        update.intensity_unit = this.state.training_exercise.intensity_parameters.find((parameter) => parameter.name === value).unit || '';
      }
      else {
        update.intensity_unit = '';
      }

      update = {...update, ...getNewIntensityUpdate(this.state.training_day_group_association, this.state.training_period.repetition_count, this.state.association.intensity_value_type, value)};
    }

    if(name === 'intensity_value_type' && this.state.association.intensity_value_type !== value) {
      update = {...update, ...getNewIntensityUpdate(this.state.training_day_group_association, this.state.training_period.repetition_count, value, this.state.association.intensity_name)};
    }

    if(name.startsWith('intensity_value:') || name.startsWith('difficult_value:') || name.startsWith('difficult_intermediate_value:') || name.startsWith('difficult_advanced_value:') || name.startsWith('intensity_value_step:')) {
      const selection = name.split(':');
      const repetitionPosition = parseInt(selection[1]);

      name = selection[0];

      const valueCopy = this.getParameterValueCopy(name);

      if(this.state.training_day_group_association.group.is_phase_constant || this.state.applyPhaseConstant) {
        for(let k = 0; k < valueCopy.length; ++k) {
          if(selection.length === 2) {
            valueCopy[k] = value;
          }
          else if(selection.length === 3) {
            valueCopy[k][parseInt(selection[2])] = value;
          }
          if(selection.length === 4) {
            valueCopy[k][parseInt(selection[2])][parseInt(selection[3])] = value;
          }
        }
      }
      else {
        if(selection.length === 2) {
          valueCopy[repetitionPosition] = value;
        }
        else if(selection.length === 3) {
          valueCopy[repetitionPosition][parseInt(selection[2])] = value;
        }
        if(selection.length === 4) {
          valueCopy[repetitionPosition][parseInt(selection[2])][parseInt(selection[3])] = value;
        }
      }

      value = valueCopy;
    }

    if(name === 'exercise_id') {
      value = parseInt(value);

      additionalUpdate.training_exercise = this.state.exercises.find((exercise) => exercise.id === value);
      update.easier_option = additionalUpdate.training_exercise.easier_option;
    }

    if(name) {
      update[name] = value;
    }

    const newData = {...this.state.association, ...update};

    this.setState({
      association: newData,
      ...additionalUpdate
    });
  }

  updateExercise(exercise) {
    this.setState({
      association: {
        ...this.state.association,
        exercise_id: exercise.id,
        easier_option: exercise.easier_option
      },
      training_exercise: exercise
    });
  }

  checkParameterValid(parameter) {
    if(parameter instanceof Array) {
      return parameter.every((parameter) => this.checkParameterValid(parameter));
    }

    return parameter !== null && parameter !== '';
  }

  inputsAreValid() {
    const intensityAreValid = this.state.association.intensity_value.every((parameter) => this.checkParameterValid(parameter));

    let difficultAreValid = true;

    if(this.state.association.difficult_name) {
      difficultAreValid = this.state.association.difficult_value.every((parameter) => this.checkParameterValid(parameter));

      if(this.state.training_day_group_association.group.has_additional_difficulties) {
        difficultAreValid = difficultAreValid &&
                            this.state.association.difficult_intermediate_value.every((parameter) => this.checkParameterValid(parameter)) &&
                            this.state.association.difficult_advanced_value.every((parameter) => this.checkParameterValid(parameter));;
      }
    }

    if(this.state.training_day_group_association.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
      return intensityAreValid &&
             difficultAreValid &&
             this.state.association.intensity_value_step.every((parameter) => this.checkParameterValid(parameter));
    }

    return intensityAreValid &&
           difficultAreValid;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.association}
    if(data.intensity_name) {
      data.intensity_value = this.getArrayCopy(data.intensity_value, (value) => parseInt(value));
    }
    if(data.difficult_name) {
      data.difficult_value = this.getArrayCopy(data.difficult_value, (value) => parseInt(value));
      data.difficult_intermediate_value = this.getArrayCopy(data.difficult_intermediate_value, (value) => parseInt(value) || 0);
      data.difficult_advanced_value = this.getArrayCopy(data.difficult_advanced_value, (value) => parseInt(value) || 0);
    }
    data.intensity_value_step = this.getArrayCopy(data.intensity_value_step, (value) => parseInt(value));
    data.training_exercise_id = parseInt(data.exercise_id);

    try {
      await patchModel(`${routes.TRAINING_GROUP_EXERCISE_ASSOCIATION_PATCH_API}${this.props.match.params.trainingGroupExerciseAssociationId}`, data);
    }
    catch(errors) {
      let warningMessages = [DEFAULT_UNKNOWN_ERROR_MESSAGE];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 103:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'name':
            //         warningMessages.push('Nome já cadastrado');
            //         highlights.push('name');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages = ['Sessão do usuário expirada'];

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}`);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}`,
            text: "Editar treino"
          },
          {
            path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}`,
            text: "Editar agrupamento"
          },
          {
            path: `${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}${TRAINING_GROUP_EXERCISE_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingGroupExerciseAssociationId}`,
            text: "Editar exercício"
          },
        ]}
        titleIcon={<i className="fas fa-edit"></i>}
        title="Editar exercício do agrupamento"
        loading={this.state.loading}
      >

        <TrainingGroupExerciseAssociationData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          association={this.state.association}
          training_exercise={this.state.training_exercise}
          training_period={this.state.training_period}
          training_day_group_association={this.state.training_day_group_association}
          exercises={this.state.exercises}
          services={this.state.services}
          exerciseCategories={this.state.exerciseCategories}
          exerciseFunctions={this.state.exerciseFunctions}
          muscleGroups={this.state.muscleGroups}
          applyPhaseConstant={this.state.applyPhaseConstant}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          onUpdateExercise={(exercise) => this.updateExercise(exercise)}
          highlights={this.state.highlights}
          onCancelPath={`${routes.TRAINING_PERIOD_EDIT_PATH}${this.props.match.params.trainingPeriodId}${TRAINING_DAY_EDIT_PATH}${this.props.match.params.trainingDayId}${TRAINING_DAY_GROUP_ASSOCIATION_EDIT_PATH}${this.props.match.params.trainingDayGroupAssociationId}`}
          intensity_parameters={this.state.training_exercise.intensity_parameters}
          difficulty_parameters={this.state.training_exercise.difficulty_parameters}
          attachMaxReference={this.state.attachMaxReference}
          userPermissionIds={this.props.userPermissionIds}
          userAccessLevel={this.props.userAccessLevel}
          isDefaultUnit={this.isDefaultUnit()}
        />

      </ContentFrame>
    );
  }
}

export default TrainingGroupExerciseAssociationEdit;
