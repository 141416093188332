import React from 'react';
import ContentFrame from '../content_frame';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import * as routes from '../../constants';
import ModelTable, {Property} from '../../utils/model_table';
import ConfirmationWindow from '../confirmation_window';
import DefaultSection from '../../utils/default_section';
import OverlayWindow from '../../components/overlay_window';
import DefaultMenuButton from '../../components/default_menu_button';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import {getModels, getModel, deleteModel, postModel, setUrlParameters, getAbbreviatedName} from '../../utils/functions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID,
        ACCESS_CONTROL_METHOD_FINGER_PRINT_ID} from '../../constants';
import * as permissions from '../../permissions';
import './staff_access_list.scss';


class StaffAccessList extends React.Component {
  constructor(props) {
    super(props);

    let queryParameters = (new URLSearchParams(props.location.search));

    let isActiveInput = queryParameters.get('is_active');

    if(!isActiveInput) {
      isActiveInput = 'true';
    }
    else if(isActiveInput === 'all') {
      isActiveInput = '';
    }

    this.state = {
      loadingData: true,
      isActiveInput: isActiveInput,
      isActiveFilter: isActiveInput,
      access_control_devices: [],
      staff_list: [],
      selectecUser: null,
      onConfigureFacialRecognition: false,
      onConfigureFingerPrint: false,
      userImage: null,
      overlayIsLoading: false,
      selectedDevice: null,
      mayRemoveFacialRecognition: false,
      mayRemoveFingerPrint: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth
    };
  }

  async getAccessControlDevices() {
    return await getModels(routes.ACCESS_CONTROL_DEVICES_GET_API);
  }

  async getStaff() {
    const parameters = {};

    if(this.state.isActiveFilter.length > 0) {
      parameters.is_active = this.state.isActiveFilter;
    }

    return await getModels(setUrlParameters(routes.STAFF_GET_API, parameters));
  }

  async componentDidMount() {
    let access_control_devices = this.getAccessControlDevices();

    let update = this.reloadList(false);

    access_control_devices = await access_control_devices;

    update = await update;

    if (access_control_devices) {
      update.access_control_devices = access_control_devices;
      update.access_control_devices.sort((a, b) => a.name.localeCompare(b.name));

      if (update.access_control_devices.length > 0) {
        update.selectedDevice = update.access_control_devices[0];
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.isActiveFilter !== this.state.isActiveFilter) {
      this.reloadList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList(updateState=true) {
    if (updateState) {
      this.setState({
        loadingData: true
      });
    }

    const update = {loadingData: false};

    const staff_list = await this.getStaff();

    if (staff_list) {
      update.staff_list = staff_list;

      if (this.state.selectecUser !== null) {
        const selectecUser = update.staff_list.find((entry) => entry.id === this.state.selectecUser.id);

        if (selectecUser) {
          update.selectecUser = selectecUser;
        }
        else {
          update.selectecUser = null;
        }
      }
    }

    if (updateState) {
      this.setState(update);
    }

    return update;
  }

  async onConfirmConfirmationWindow() {
    if (this.state.selectedDevice === null || this.state.selectecUser === null) {
      return null;
    }

    this.setState({
      confirmInProgress: true
    });

    const parameters = {
      access_control_device_id: this.state.selectedDevice.id
    };

    if (this.state.mayRemoveFacialRecognition) {
      parameters.access_control_method_id = ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID;
    }
    else if (this.state.mayRemoveFingerPrint) {
      parameters.access_control_method_id = ACCESS_CONTROL_METHOD_FINGER_PRINT_ID;
    }
    else {
      return null;
    }

    try{
      if (await deleteModel(setUrlParameters(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_DELETE_API.replace('{id}', this.state.selectecUser.id), parameters))) {
        this.setState({
          mayRemoveFacialRecognition: false,
          mayRemoveFingerPrint: false,
          confirmFailed: false,
          confirmInProgress: false,
        });

        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  async onConfigureFacialRecognition() {
    if (this.state.selectedDevice === null || this.state.selectecUser === null) {
      return null;
    }

    this.setState({
      onConfigureFacialRecognition: true,
      overlayIsLoading: true,
      userImage: null
    });

    try{
      const response = await getModel(routes.ACCESS_CONTROL_DEVICE_REQUEST_USER_PICTURE_GET_API.replace('{id}', this.state.selectedDevice.id));

      if(response) {
        this.setState({
          userImage: response.user_image,
          overlayIsLoading: false
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 207:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Timeout':
                    errorDescription = 'Não foi possível coletar foto do cliente pelo dispositivo. Verifique se ele está ligado ' +
                                       'corretamente e tente novamente mais tarde.';

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes('Took to long to capture picture')) {
                errorDescription = 'Tempo limite de espera para captura de ' +
                                   'foto alcançada. Tente novamente.';
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  async onConfirmCredentialRegistration() {
    if (this.state.selectedDevice === null || this.state.selectecUser === null) {
      return null;
    }

    if (this.state.onConfigureFacialRecognition && this.state.userImage !== null) {
      this.setState({
        overlayIsLoading: true
      });

      const data = {
        access_control_device_id: this.state.selectedDevice.id,
        access_control_method_id: ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID,
        user_picture: this.state.userImage
      }

      try{
        if (await postModel(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API.replace('{id}', this.state.selectecUser.id), data)) {
          this.setState({
            overlayIsLoading: false,
            onConfigureFacialRecognition: false
          });

          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 207:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'Timeout':
                      errorDescription = 'Não foi possível se conectar com o dispositivo de reconhecimento facial. Verifique se ele está ligado ' +
                                         'corretamente e tente novamente mais tarde.';

                      break;
                    default:
                  }
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }

    }
  }

  async onConfigureFingerPrint() {
    if (this.state.selectedDevice === null || this.state.selectecUser === null) {
      return null;
    }

    this.setState({
      onConfigureFingerPrint: true,
      confirmInProgress: true
    });

    const data = {
      access_control_device_id: this.state.selectedDevice.id,
      access_control_method_id: ACCESS_CONTROL_METHOD_FINGER_PRINT_ID
    }

    try{
      if (await postModel(routes.USER_ACCESS_CONTROL_DEVICE_ASSOCIATION_POST_API.replace('{id}', this.state.selectecUser.id), data)) {
        this.setState({
          onConfigureFingerPrint: false,
          confirmInProgress: false
        });

        this.reloadList();
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 207:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'Timeout':
                    errorDescription = 'Não foi possível coletar foto do cliente pelo dispositivo. Verifique se ele está ligado ' +
                                       'corretamente e tente novamente mais tarde.';

                    break;
                  default:
                }
              }

              break;
            case 208:
              if (error.message.includes('Took to long to capture finger print')) {
                errorDescription = 'Tempo limite de espera para captura de ' +
                                   'impressão digital alcançada. Tente novamente.';
              }

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  getRoleText(entry) {
    return entry.role_names.map((roleName) => {
      return (
        <span
          className="staff-access-list__role-name"
          key={`staff_rolename_${roleName}_${entry.id}`}
        >
          {roleName}
        </span>
      );
    });
  }

  onCancelConfirmationWindow() {
    this.setState({
      onConfigureFacialRecognition: false,
      onConfigureFingerPrint: false,
      overlayIsLoading: false,
      mayRemoveFacialRecognition: false,
      mayRemoveFingerPrint: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  listHasActions() {
    return this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID);
  }

  onConfigureUser(selectecUser) {
    this.setState({selectecUser});
  }

  getActions(entry) {
    return (
      <div className="model-table__model-actions-container">

        <button
          className="model-table__default-edit-button staff-access-list__configure-button"
          onClick={() => this.onConfigureUser(entry)}
        >

          <i className="fa-solid fa-gear"></i>

        </button>

      </div>
    );
  }

  getIsActiveFilterText(entry) {
    let activeText;

    if(entry.is_active) {
      if(entry.email_is_validated === null) {
        activeText = 'Email não validado';
      }
      else if(entry.email_is_validated === false) {
        activeText = 'Email inválido';
      }
      else {
        activeText = 'Ativo';
      }
    }
    else {
      activeText = 'Inativo';
    }

    return activeText;
  }

  getIsActiveText(entry) {
    let activeText;
    let classModifier;

    if(entry.is_active) {
      if(entry.email_is_validated === null) {
        activeText = 'Email não validado';
        classModifier = 'pending';
      }
      else if(entry.email_is_validated === false) {
        activeText = 'Email inválido';
        classModifier = 'inativo';
      }
      else {
        activeText = 'Ativo';
        classModifier = 'ativo';
      }
    }
    else {
      activeText = 'Inativo';
      classModifier = 'inativo';
    }

    return (
      <p className={`staff-access-list__is-active-text__${classModifier}`}>{activeText}</p>
    );
  }

  getProperties() {
    let properties = [
      Property('name', 'Nome', <i className="fas fa-id-card"></i>),
    ];

    properties.push(
      Property('email', 'E-mail', <i className="fas fa-envelope"></i>)
    );

    properties.push(
      Property('role_names', 'Funções', <i className="fas fa-tag"></i>, {getDataText: this.getRoleText})
    );

    properties = [
      ...properties,
      Property('is_active', 'Situação', <i className="fas fa-thermometer-half"></i>, {getDataText: this.getIsActiveText, getFilterText: this.getIsActiveFilterText})
    ];

    return properties;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if (this.state.mayRemoveFacialRecognition || this.state.mayRemoveFingerPrint) {
        return 'Removendo cadastro';
      }
      else if (this.state.onConfigureFingerPrint) {
        return 'Configurando impressão digital';
      }

      return 'Indefinido';
    }
    else if(this.state.confirmFailed) {
      if (this.state.mayRemoveFacialRecognition) {
        return 'Falha ao remover face';
      }
      else if (this.state.mayRemoveFingerPrint) {
        return 'Falha ao remover impressão digital';
      }
      else if(this.state.onConfigureFacialRecognition || this.state.onConfigureFingerPrint) {
        return 'Falha durante configuração';
      }
    }

    if (this.state.mayRemoveFacialRecognition) {
      return 'Remover cadastro de face';
    }
    else if (this.state.mayRemoveFingerPrint) {
      return 'Remover cadastro de impressão digital';
    }

    return 'Não implementado';
  }

  getActiveOptions() {
    return [
      SelectOption('true', 'Ativo'),
      SelectOption('false', 'Inativo'),
      SelectOption('', 'Todos'),
    ];
  }

  mayResetFilterInputs() {
    if(this.state.isActiveInput.length > 0) {
      return true;
    }

    return false;
  }

  resetFilterInputs() {
    this.setState({
      isActiveInput: '',
    });
  }

  mayRefreshList() {
    if(this.state.isActiveInput !== this.state.isActiveFilter) {
      return true;
    }

    return false;
  }

  async refreshList() {
    if(this.mayRefreshList()) {
      this.props.history.replace(setUrlParameters(routes.STAFF_ACCESS_CONTROL_LIST_PATH, {
        is_active: this.state.isActiveInput || 'all'
      }));

      this.setState({
        isActiveFilter: this.state.isActiveInput
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    let name = target.name;

    const update = {[name]: value};

    this.setState(update);
  }

  onRemoveFacialRecognition() {
    this.setState({
      mayRemoveFacialRecognition: true,
      mayRemoveFingerPrint: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  onRemoveFingerPrint() {
    this.setState({
      mayRemoveFingerPrint: true,
      mayRemoveFacialRecognition: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if (this.state.mayRemoveFacialRecognition) {
      return 'A autenticação por reconhecimento facial será desabilitada para o usuário selecionado. Para habilitar novamente será necessário uma nova coleta de foto.';
    }
    else if (this.state.mayRemoveFingerPrint) {
      return 'A autenticação por impressão digital será desabilitada para o usuário selecionado. Para habilitar novamente será necessário uma nova coleta de impressão digital.';
    }
    else if (this.state.onConfigureFingerPrint) {
      return 'Processo de coleta e cadastro da impressão digital em andamento.';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmText() {
    if (this.state.mayRemoveFacialRecognition || this.state.mayRemoveFingerPrint) {
      return 'Remover';
    }

    return 'Indefinido';
  }

  getOverlayActions() {
    if (this.state.selectecUser !== null) {
      if (this.state.onConfigureFacialRecognition) {
        let confirmText = 'Confirmar';

        if (this.state.overlayIsLoading) {
          if (this.state.userImage !== null) {
            confirmText = 'Cadastrando foto...';
          }
          else {
            confirmText = 'Aguardando captura de foto...';
          }
        }

        return (
          <React.Fragment>

            {!this.state.overlayIsLoading &&
              <DefaultMenuButton
                className="staff-access-list__overlay__action-button"
                onClick={() => this.setState({onConfigureFacialRecognition: false})}
                text="Cancelar"
              />
            }

            <DefaultMenuButton
              className="staff-access-list__overlay__action-button"
              onClick={() => this.onConfirmCredentialRegistration()}
              color="purple"
              text={confirmText}
              disabled={this.state.overlayIsLoading}
            />

          </React.Fragment>
        );
      }

      return (
        <DefaultMenuButton
          className="staff-access-list__overlay__action-button"
          onClick={() => this.setState({selectecUser: null})}
          text="Fechar"
        />
      );
    }

    return null;
  }

  getOverlayHeader() {
    if (this.state.selectecUser !== null) {
      if (this.state.onConfigureFacialRecognition) {
        return `${getAbbreviatedName(this.state.selectecUser.name)}: Reconhecimento facial`;
      }

      return getAbbreviatedName(this.state.selectecUser.name)
    }

    return null;
  }

  getOverlayContent() {
    if (this.state.selectecUser !== null) {
      if (this.state.onConfigureFacialRecognition && this.state.userImage !== null) {
        return (
          <React.Fragment>

              <p className='staff-access-list__overlay__message'>

                Para confirmar, verifique se a imagem não está borrada e está bem enquadrada.

              </p>

            <div
              className="staff-access-list__user-picture"
            >

              <img
                className="staff-access-list__user-picture__img"
                src={`data:image/jpeg;base64,${this.state.userImage}`}
                alt="Foto do usuário"
              />

            </div>

          </React.Fragment>
        );
      }

      return (
        <React.Fragment>

          <DefaultSubSectionTitle
            className="staff-access-list__device-selector-header"
            icon={<i className="fas fa-list"></i>}
            text="Selecione o acesso a ser configurado"
          />

          <div className="staff-access-list__device-selector">

            {this.getDeviceSelectors()}

          </div>

          {this.getSelectedDeviceConfiguration()}

        </React.Fragment>
      );
    }

    return null;
  }

  getDeviceSelectors() {
    if (this.state.access_control_devices.length <= 0) {
      return (
        <p className="staff-access-list__alert-text">

          <i className="fas fa-exclamation staff-access-list__alert-text__icon"></i>
          <span className="staff-access-list__alert-text__text">
            Nenhum controle de acesso encontrado
          </span>

        </p>
      );
    }

    return this.state.access_control_devices.map((device) => {
      const deviceSelected = this.state.selectedDevice !== null && device.id === this.state.selectedDevice.id;

      return (
        <button
          key={`staff_access_list:device:${device.id}`}
          className={`staff-access-list__device-button`}
          disabled={deviceSelected}
          onClick={() => this.setState({
            selectedDevice: device
          })}
        >

          {device.name}

        </button>
      );
    });
  }

  getSelectedDeviceConfiguration() {
    if (this.state.selectedDevice === null || this.state.selectecUser === null) {
      return null;
    }

    const facialRecognitionAssociation = this.state.selectecUser.access_control_device_associations.find(
      (entry) => entry.access_control_method_id === ACCESS_CONTROL_METHOD_FACIAL_RECOGNITION_ID &&
                 entry.access_control_device_id === this.state.selectedDevice.id);

    let facialRecognitionElement;

    if (facialRecognitionAssociation) {
      facialRecognitionElement = (
        <React.Fragment>

          <div className="staff-access-list__action-buttons">

          <button
            className="staff-access-list__reconfig-button"
            onClick={() => this.onConfigureFacialRecognition()}
          >

            <i className="fa-solid fa-gear staff-access-list__add-button__icon"></i>
            Reconfigurar

          </button>

          <button
            className="staff-access-list__remove-button"
            onClick={() => this.onRemoveFacialRecognition()}
          >

            <i className="fa-solid fa-trash-can staff-access-list__add-button__icon"></i>
            Remover

          </button>

          </div>
        </React.Fragment>
      );
    }
    else {
      facialRecognitionElement = (
        <div className="staff-access-list__action-buttons">

          <button
            className="staff-access-list__add-button"
            onClick={() => this.onConfigureFacialRecognition()}
          >

            <i className="fa-solid fa-gear staff-access-list__add-button__icon"></i>
            Iniciar configuração

          </button>

        </div>
      );
    }

    const fingerPrintAssociation = this.state.selectecUser.access_control_device_associations.find(
      (entry) => entry.access_control_method_id === ACCESS_CONTROL_METHOD_FINGER_PRINT_ID &&
                 entry.access_control_device_id === this.state.selectedDevice.id);

    let fingerPrintElement;

    if (fingerPrintAssociation) {
      fingerPrintElement = (
        <React.Fragment>

          <div className="staff-access-list__action-buttons">

          <button
            className="staff-access-list__reconfig-button"
            onClick={() => this.onConfigureFingerPrint()}
          >

            <i className="fa-solid fa-gear staff-access-list__add-button__icon"></i>
            Reconfigurar

          </button>

          <button
            className="staff-access-list__remove-button"
            onClick={() => this.onRemoveFingerPrint()}
          >

            <i className="fa-solid fa-trash-can staff-access-list__add-button__icon"></i>
            Remover

          </button>

          </div>
        </React.Fragment>
      );
    }
    else {
      fingerPrintElement = (
        <div className="staff-access-list__action-buttons">

          <button
            className="staff-access-list__add-button"
            onClick={() => this.onConfigureFingerPrint()}
          >

            <i className="fa-solid fa-gear staff-access-list__add-button__icon"></i>
            Iniciar configuração

          </button>

        </div>
      );
    }

    return (
      <React.Fragment>

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-image-portrait"></i>}
          text="Reconhecimento facial"
        />

        <p className='staff-access-list__overlay__message'>

          Antes de iniciar a configuração com a coleta da foto, posicione a pessoa em frente ao dispositivo de reconhecimento facial e peça para ela olhar
          para a lente do aparelho. Peça também para que a pessoa remova os óculos,
          chapéu, boné, etc, para que ajeite o cabelo de forma que
          não obstrua o rosto (testa inclusa) e mantenha uma expressão neutra.

        </p>

        {facialRecognitionElement}

        <DefaultSubSectionTitle
          icon={<i className="fa-solid fa-fingerprint"></i>}
          text="Impressão digital"
        />

        <p className='staff-access-list__overlay__message'>

          Antes de iniciar a configuração da impressão digital, posicione a pessoa em frente ao dispositivo de acesso.
          A pessoa deve escolher o dedo que deseja utilizar para o cadastro (normalmente o indicador) e ela deverá pressionar o
          mesmo dedo 3 vezes seguindo a orientação do aparelho. Para cada vez que o aparelho requisitar,
          a pessoa deve desencostar o dedo do sensor e colocá-lo novamente como faria ao acessar a academia.

        </p>

        {fingerPrintElement}

      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>

        <OverlayWindow
          className="staff-access-list__overlay"
          visible={this.state.onConfigureFacialRecognition || this.state.selectecUser !== null}
          loading={this.state.overlayIsLoading || this.state.loadingData}
          actions={(
            <div className="staff-access-list__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="staff-access-list__overlay__header">

            <h3 className="staff-access-list__overlay__header__title">
              {this.getOverlayHeader()}
            </h3>

          </header>

          <hr className="staff-access-list__horizontal-rule" />

          <div className="staff-access-list__overlay__content">

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.confirmFailed || this.state.mayRemoveFacialRecognition || this.state.mayRemoveFingerPrint || this.state.onConfigureFingerPrint}
          onCancel={() => this.onCancelConfirmationWindow()}
          onConfirm={() => this.onConfirmConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <ContentFrame
          location={this.props.location}
          headerHistory={[
            {
              path: routes.DESKTOP_PATH,
              text: "Área de trabalho"
            },
            {
              path: routes.STAFF_ACCESS_CONTROL_LIST_PATH,
              text: "Gerenciar acesso de colaboradores"
            },
          ]}
          titleIcon={<i className="fas fa-clipboard-list"></i>}
          title="Gerenciar acesso de colaboradores"
          loading={this.state.loadingData}
        >

          <DefaultSection
            className="staff-access-list"
            title="Lista de colaboradores"
          >

            <div className="staff-access-list__filters">

              <header className="staff-access-list__filters__header">

                <h4 className="staff-access-list__filters__header__text">Filtros</h4>

              </header>

              <div className="staff-access-list__filters__inputs">

                <div className="staff-access-list__filters__inputs-wrapper">

                  <DefaultInput
                    name="isActiveInput"
                    label="Situação do cadastro"
                    type="select"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={this.state.isActiveInput || ''}
                    options={this.getActiveOptions()}
                  />

                </div>

                {this.mayResetFilterInputs() &&
                  <button
                    className="staff-access-list__filters__reset-button"
                    onClick={() => this.resetFilterInputs()}
                  >

                    <i className="fas fa-times"></i>

                  </button>
                }

                {this.mayRefreshList() &&
                  <button
                    className="staff-access-list__filters__refresh-button"
                    onClick={() => this.refreshList()}
                  >

                    <i className="fas fa-sync"></i>

                  </button>
                }

              </div>

            </div>

            <ModelTable
              properties={this.getProperties()}
              getActions={this.listHasActions() ? (entry) => this.getActions(entry) : null}
              data={this.state.staff_list}
              initialOrderBy="name"
            >

            </ModelTable>

          </DefaultSection>

        </ContentFrame>

      </React.Fragment>
    );
  }
}

export default StaffAccessList;
