import React from 'react';
import ContentFrame from '../../content_frame';
import * as routes from '../../../constants';
import {postModel, getModels, setUrlParameters} from '../../../utils/functions';
import FoodClassificationData from './food_classification_data';

class FoodClassificationAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_classification: {
        name: "",
        description: "",
        parent_id: null,
        show_in_student_form: false
      },
      food_classifications: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const update = {loading: false};

    let food_classifications = getModels(setUrlParameters(routes.FOOD_CLASSIFICATIONS_GET_API, {load_children: true}));

    food_classifications = await food_classifications;

    if(food_classifications) {
      update.food_classifications = food_classifications;
    }

    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newData = {...this.state.food_classification, [name]: value};

    this.setState({
      food_classification: newData
    });
  }

  inputsAreValid() {
    return this.state.food_classification.name.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.food_classification};

    if(!data.parent_id) {
      data.parent_id = null;
    }
    else {
      data.parent_id = parseInt(data.parent_id);
    }

    try {
      await postModel(routes.FOOD_CLASSIFICATION_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 103:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'name':
                    warningMessages.push('Nome já cadastrado');
                    highlights.push('name');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.FOOD_CLASSIFICATION_LIST_PATH);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.FOOD_CLASSIFICATION_LIST_PATH,
            text: "Listar grupos"
          },
          {
            path: routes.FOOD_CLASSIFICATION_ADD_PATH,
            text: "Adicionar grupo"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar grupo de alimento/ingrediente"
        loading={this.state.loading}
      >

        <FoodClassificationData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          food_classification={this.state.food_classification}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.FOOD_CLASSIFICATION_LIST_PATH}
          food_classifications={this.state.food_classifications}
        />

      </ContentFrame>
    );
  }
}

export default FoodClassificationAdd;
