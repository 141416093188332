import React from 'react';
import { Link } from 'react-router-dom';
import ConfirmationWindow from '../confirmation_window';
import './student_general_data.scss';
import {HorizontalRule} from '../../utils/default_section';
import DefaultInput, {HalfWrapper, SelectOption} from '../../utils/default_input';
import WarningMessage from '../warning_message';
import {patchModel, postModel, getPhoneText, setInputCursor, getCpfText, getRgText, getAsLocalDate} from '../../utils/functions';
import * as routes from '../../constants';
import * as permissions from '../../permissions';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';


class StudentGeneralData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {
        name: this.props.student.name,
        phone: this.props.student.phone,
        email: this.props.student.email,
        birthdate: this.props.student.birthdate,
        gender: this.props.student.gender,
        cpf: this.props.student.cpf,
        rg: this.props.student.rg,
        profession: this.props.student.profession,
        marital_status: this.props.student.marital_status,
        nationality: this.props.student.nationality,
        at_emergency: this.props.student.at_emergency,
        note: this.props.student.note,
      },
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      triggerEmailChange: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      accountChangeEmailSent: false,
      loading: true,
    };
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      warningMessageBackground: null,
      warningMessageColor: null,
      loading: true
    });

    let api_path;
    let studentData;

    if (this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)) {
      api_path = `${routes.STUDENT_PATCH_API}${this.props.student.id}`;
      studentData = {...this.state.student};
    }
    else {
      api_path = routes.STUDENT_BASIC_DATA_PATCH_API.replace('{id}', this.props.student.id);
      studentData = {
        name: this.state.student.name,
        phone: this.state.student.phone,
        email: this.state.student.email,
        birthdate: this.state.student.birthdate,
        gender: this.state.student.gender,
        at_emergency: this.state.student.at_emergency,
        note: this.state.student.note
      };
    }

    try {
      await patchModel(api_path, studentData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 203) {
            if(error.message.includes('User from another unit')) {
              warningMessages.push('Email já cadastrado em outra unidade. Por favor, entre em contato com o suporte FYD caso necessário');
              highlights.push('email');
            }
            else if(error.message.includes('Requires higher access level')) {
              warningMessages.push('Você não possui permissão para atualizar este cadastro. Por favor, entre em contato com o responsável pela unidade FYD para realizar a atualização');
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      if(warningMessages.length === 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        warningMessageBackground: null,
        warningMessageColor: null,
        loading: false
      });

      return;
    }

    this.props.onSave();

    // this.props.history.replace(this.props.onCancelPath);
  }

  inputsAreValid() {
    return this.state.student.name.length > 0 &&
           this.state.student.email.match(/.+@.+\..+/) != null &&
           this.state.student.phone.length > 0;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.saveData();
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && name === 'cpf') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getCpfText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const valueIncreased = this.state.student[name].length < value.length;

      if(value.length > 3 &&
          (newPosition === 4)) {
        newPosition = valueIncreased ? 5 : 3;
      }
      else if(value.length > 6 &&
          (newPosition === 8)) {
        newPosition = valueIncreased ? 9 : 7;
      }
      else if(value.length > 9 &&
          (newPosition === 12)) {
        newPosition = valueIncreased ? 13 : 11;
      }

      setInputCursor(target, newPosition);
    }
    else if(value && name === 'rg') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"");
      target.value = getRgText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      const valueIncreased = this.state.student[name].length < value.length;

      if(value.length > 2 &&
          (newPosition === 3)) {
        newPosition = valueIncreased ? 4 : 2;
      }
      else if(value.length > 5 &&
          (newPosition === 7)) {
        newPosition = valueIncreased ? 8 : 6;
      }
      else if(value.length > 8 &&
          (newPosition === 11)) {
        newPosition = valueIncreased ? 12 : 10;
      }

      setInputCursor(target, newPosition);
    }

    const newData = {...this.state.student, [name]: value};

    this.setState({
      student: newData
    });
  }

  mayTriggerEmailChange() {
    return this.props.mayTriggerEmailChange &&
           this.props.student.email_is_validated === true;
  }

  onCancelAction() {
    this.setState({
      triggerEmailChange: false,
    });
  }

  onTriggerEmailChange() {
    this.setState({
      triggerEmailChange: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.triggerEmailChange) {
        return 'Enviando email';
      }

      return 'Não definido';
    }
    else if(this.state.confirmFailed) {
      if(this.state.triggerEmailChange) {
        return 'Falha ao enviar email';
      }

      return 'Não definido';
    }

    if(this.state.triggerEmailChange) {
      return 'Enviar notificação para mudança de email';
    }

    return 'Não definido';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.triggerEmailChange) {
      return 'Um email será enviado ao usuário contendo um link para realizar a alteração ' +
             'do email vinculado à conta.';
    }

    return 'Não definido';
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.triggerEmailChange) {
      return 'Enviar email';
    }

    return 'Não definido';
  }

  async onConfirmAction() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.triggerEmailChange) {
      const userData = {user_id: this.props.student.id};

      try {
        if(await postModel(routes.USER_INITIATE_EMAIL_CHANGE_POST_API, userData)) {
          this.setState({
            accountChangeEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            if(error.code === 203) {
              if(error.message.includes('Requires higher access level')) {
                errorDescription = 'Você não possui permissão para realizar esta ação para este cadastro. Por favor, entre em contato com o suporte FYD caso necessário.';
                break;
              }
            }
            else if(error.code === 209) {
              errorDescription = 'Sessão do usuário expirada.';
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          accountChangeEmailSent: false
        });

        return;
      }
    }

    this.setState({
      triggerEmailChange: false,
    });
  }

  getStudentAge() {
    if(this.state.student.birthdate && this.state.student.birthdate.length > 0) {
      const today = new Date();
      const birthdate = getAsLocalDate(this.state.student.birthdate);
      const timeDiff = Math.abs(today.getTime() - birthdate.getTime());
      return Math.floor(timeDiff / (1000 * 3600 * 24 * 365));
    }

    return null;
  }

  render() {
    const age = this.getStudentAge();

    return (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.state.triggerEmailChange}
          onCancel={() => this.onCancelAction()}
          onConfirm={() => this.onConfirmAction()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <div className="student-general-data__warning-container">

          <WarningMessage
            message={this.state.warningMessage}
            onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
            visible={this.state.showWarningMessage}
            background={this.state.warningMessageBackground}
            color={this.state.warningMessageColor}
          />

        </div>

        <div className="student-general-data__input-container">

          {this.mayTriggerEmailChange() &&
            <React.Fragment>

              <div className="user-data__actions-container">

                <button
                  className="student-general-data__action-button"
                  disabled={this.state.accountChangeEmailSent}
                  onClick={(event) => this.onTriggerEmailChange()}
                >

                  <i className="fas fa-envelope"></i> {this.state.accountChangeEmailSent ? 'Email enviado' : 'Iniciar alteração de email'}

                </button>

              </div>

              <HorizontalRule />

            </React.Fragment>

          }

          <HalfWrapper>

            <DefaultInput
              name="name"
              isHighlighted={this.isHighlighted("name")}
              label="Nome"
              type="text"
              placeholder="Nome do aluno"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.student.name}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
            />

            <DefaultInput
              name="email"
              isHighlighted={this.isHighlighted("email")}
              label="E-mail"
              type="email"
              placeholder="E-mail do aluno"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.student.email}
              autoComplete="off"
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={(!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)) || (!this.props.mayChangeEmail && this.props.student.email_is_validated)}
            />

          </HalfWrapper>

          <HalfWrapper>

            <HalfWrapper>

              <DefaultInput
                name="phone"
                isHighlighted={this.isHighlighted("phone")}
                label="Telefone"
                type="tel"
                placeholder="Telefone do aluno"
                maxLength="16"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={getPhoneText(this.state.student.phone)}
                autoComplete="off"
                onKeyDown={(event) => this.handleKeyDown(event)}
                disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
              />

              <DefaultInput
                name="gender"
                isHighlighted={this.isHighlighted("gender")}
                label="Gênero"
                type="select"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.student.gender}
                options={[
                  SelectOption('Feminino', 'Feminino'),
                  SelectOption('Masculino', 'Masculino'),
                ]}
                disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
              />

            </HalfWrapper>

            <DefaultInput
              name="birthdate"
              isHighlighted={this.isHighlighted("birthdate")}
              label="Data de nascimento"
              type="date"
              placeholder="Data de nascimento"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.student.birthdate}
              labelMessage={age !== null ? `(Idade: ${age} anos)` : null}
              labelColor={'#854490'}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
            />

          </HalfWrapper>
          
          {this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID) &&
            <React.Fragment>

              <HalfWrapper>

                <HalfWrapper>

                  <DefaultInput
                    name="cpf"
                    isHighlighted={this.isHighlighted("cpf")}
                    label="CPF"
                    type="text"
                    placeholder="CPF do aluno"
                    maxLength="14"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={getCpfText(this.state.student.cpf)}
                    autoComplete="off"
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
                  />

                  <DefaultInput
                    name="rg"
                    isHighlighted={this.isHighlighted("rg")}
                    label="RG"
                    type="text"
                    placeholder="RG do aluno"
                    maxLength="16"
                    handleInputChange={(event) => this.handleInputChange(event)}
                    value={getRgText(this.state.student.rg)}
                    autoComplete="off"
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
                  />

                </HalfWrapper>

                <DefaultInput
                  name="profession"
                  isHighlighted={this.isHighlighted("profession")}
                  label="Profissão"
                  type="text"
                  placeholder="Profissão do aluno"
                  maxLength="32"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.student.profession}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
                />

              </HalfWrapper>

              <HalfWrapper>

                <DefaultInput
                  name="marital_status"
                  isHighlighted={this.isHighlighted("marital_status")}
                  label="Estado civil"
                  type="text"
                  placeholder="Estado civil do aluno"
                  maxLength="32"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.student.marital_status}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
                />

                <DefaultInput
                  name="nationality"
                  isHighlighted={this.isHighlighted("nationality")}
                  label="Nacionalidade"
                  type="text"
                  placeholder="Nacionalidade do aluno"
                  maxLength="32"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.student.nationality}
                  autoComplete="off"
                  onKeyDown={(event) => this.handleKeyDown(event)}
                  disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
                />

              </HalfWrapper>

            </React.Fragment>
          }

          <DefaultInput
            name="at_emergency"
            isHighlighted={this.isHighlighted("at_emergency")}
            label="Emergência ligar"
            type="text"
            placeholder="Contato de emergência do aluno"
            maxLength="64"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.student.at_emergency}
            autoComplete="off"
            onKeyDown={(event) => this.handleKeyDown(event)}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
          />

          <DefaultInput
            name="note"
            isHighlighted={this.isHighlighted("note")}
            label="Observação"
            maxLength="256"
            type="textarea"
            placeholder="Observações relevantes"
            rows="3"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.student.note}
            disabled={!this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) && !this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)}
          />

        </div>
        
        {(this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_BASIC_DATA_PERMISSION_ID) || this.props.userPermissionIds.includes(permissions.EDIT_STUDENT_DATA_PERMISSION_ID)) &&
          <React.Fragment>

            <HorizontalRule />

            <div className="student-general-data__buttons-container">

              <button
                className="student-general-data__save-button"
                disabled={!this.inputsAreValid()}
                onClick={() => this.saveData()}
              >

                Salvar

              </button>

              <Link
                className="student-general-data__cancel-button"
                to={this.props.onCancelPath}
              >

                Cancelar

              </Link>

            </div>

          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default StudentGeneralData;
