import React from 'react';
import ContentFrame from '../content_frame';
import * as routes from '../../constants';
import {OPERATIONAL_TASK_STATUS_PENDING} from '../../constants';
import {getModels, postModel, setUrlParameters} from '../../utils/functions';
import OperationalTaskData from './operational_task_data';
import * as permissions from '../../permissions';

class OperationalTaskAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operational_task: {
        responsible_id: null,
        target_service: null,
        target_role: null,
        priority: 1,
        status: OPERATIONAL_TASK_STATUS_PENDING,
        task: "",
        commentary: "",
      },
      target_type: null,
      users: [],
      services: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
    };
  }

  async getCoaches() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_COACH_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.COACHES_GET_API, parameters));
  }

  async getReceptionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_RECEPTIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.RECEPTIONISTS_GET_API, parameters));
  }

  async getManagers() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_MANAGER_USER_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.USERS_GET_API, parameters));
  }

  async getNutritionists() {
    if (!this.props.userPermissionIds.includes(permissions.EDIT_NUTRITIONIST_PERMISSION_ID)) {
      return [];
    }

    const parameters = {is_active: true};

    return await getModels(setUrlParameters(routes.NUTRITIONISTS_GET_API, parameters));
  }

  async getServices() {
    return await getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);
  }

  async componentDidMount() {
    const update = {
      loading: false,
    };

    const userIdSet = new Set();
    update.users = [];

    let coaches = this.getCoaches();
    let receptionists = this.getReceptionists();
    let managers = this.getManagers();
    let nutritionists = this.getNutritionists();
    let services = this.getServices();

    coaches = await coaches;

    if (coaches) {
      for (const entry of coaches) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    receptionists = await receptionists;

    if (receptionists) {
      for (const entry of receptionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    managers = await managers;

    if (managers) {
      for (const entry of managers) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    nutritionists = await nutritionists;

    if (nutritionists) {
      for (const entry of nutritionists) {
        if (!userIdSet.has(entry.id)) {
          userIdSet.add(entry.id);
          update.users.push(entry);
        }
      }
    }

    services = await services;

    if (services) {
      update.services = services;
      update.services.sort((a, b) => a.localeCompare(b));
    }

    update.users.sort((a, b) => a.name.localeCompare(b.name));
    this.setState(update);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if (name === 'responsible_id') {
      if (value.length <= 0) {
        value = null;
      }
      else {
        value = parseInt(value);
      }
    }
    else if (name === 'target_service' || name === 'target_role') {
      if (value.length <= 0) {
        value = null;
      }
    }
    else if (name === 'target_type') {
      this.setState({
        target_type: parseInt(value),
        operational_task: {
          ...this.state.operational_task,
          responsible_id: null,
          target_service: null,
          target_role: null
        }
      });

      return;
    }

    const newData = {...this.state.operational_task, [name]: value};

    this.setState({
      operational_task: newData
    });
  }

  inputsAreValid() {
    return this.state.operational_task.task.length > 0;
  }

  async saveData() {
    if (this.state.target_type === null) {
      this.setState({
        highlights: ['target_type'],
        showWarningMessage: true,
        warningMessage: 'Selecione o tipo do responsável pela pendência'
      });

      return;
    }
    if (this.state.operational_task.responsible_id === null &&
        this.state.operational_task.target_service === null &&
        this.state.operational_task.target_role === null) {
      this.setState({
        highlights: ['responsible_id', 'target_service', 'target_role'],
        showWarningMessage: true,
        warningMessage: 'Ao menos um destes campos deve ser especificado: Responsável, Serviço vinculado ou Setor.',
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.operational_task};

    data.priority = parseInt(data.priority);

    try {
      await postModel(routes.OPERATIONAL_TASK_POST_API, data);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.OPERATIONAL_TASK_LIST_PAGE);
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.OPERATIONAL_TASK_LIST_PAGE,
            text: "Listar pendências"
          },
          {
            path: routes.OPERATIONAL_TASK_ADD_PAGE,
            text: "Adicionar pendência"
          },
        ]}
        titleIcon={<i className="fas fa-plus"></i>}
        title="Adicionar pendência"
        loading={this.state.loading}
      >

        <OperationalTaskData
          warningMessage={this.state.warningMessage}
          showWarningMessage={this.state.showWarningMessage}
          users={this.state.users}
          services={this.state.services}
          operational_task={this.state.operational_task}
          target_type={this.state.target_type}
          onSave={() => this.saveData()}
          onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
          enableSave={this.inputsAreValid()}
          handleInputChange={(event) => this.handleInputChange(event)}
          highlights={this.state.highlights}
          onCancelPath={routes.OPERATIONAL_TASK_LIST_PAGE}
        />

      </ContentFrame>
    );
  }
}

export default OperationalTaskAdd;
